<template>
  <div class="column mainBorder">
    <a v-show="false" id="downLink">manual</a>

    <div class="col-11 column">
      <!-- 윗부분 전체 -->
      <div class="col-auto row" style="position:relative;min-height:480px;" :style='isLandscape ? "margin:0 0 12px 0;" : "margin:0 0 0 0;min-height:360px"'>
        <!-- 상단 알람/고장 -->
        <div class="col-auto" :class='isLandscape ? "row" : "column"'
             style="width:100%;height:480px;"
             :style="isLandscape ? 'margin:0;' : 'margin:0 0 12px 0;'">
          <div class="column" :class='isLandscape ? "col-7" : "col"'
              :style='isLandscape ? "margin:0 6px 0 0;" : "margin:0 0 0 0;"'
              v-show="!showEmergencyHistory && !showErrorHistory">
            <div class="col column" style="background-color:white;border-radius:3px;">
              <!-- Current Emergency -->
              <div class="col column no-wrap" style="margin:0 12px 12px 12px;">
                <div class="col-auto row justify-between items-center" style="height:45px;width:100%;">
                  <div class="col" style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('machineDetail.manageEM') }}</div>
                  <div style="font-weight:500;margin:0 12px;" v-show="emCount > 0">[&nbsp;{{ emCount }}&nbsp;]</div>
                  <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('machineDetail.history')"
                         :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="width:auto;padding:0 12px;"
                         @click="showEmHistory(true)">
                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.loadAlarmHistory') }}</q-tooltip>
                  </q-btn>
                </div>

                <div class="col column" style="border-radius: 3px;">
                  <q-scroll-area class="col column" style="width:100%;height:100%;">
                    <!-- table-header-style="position:sticky;top:0;z-index:1;background-color:white;" -->
                    <q-table dense flat bordered
                            ref="emTable"
                            class="col"
                            style="font-size:14px;width:100%;height:100%;"
                            separator="cell"
                            row-key="index"
                            selection="single"
                            :selected="emSelectedRows"
                            virtual-scroll
                            hide-no-data
                            hide-bottom
                            :visible-columns="visibleColumns"
                            :columns="emColumns"
                            :rows="emRows"
                            :rows-per-page-options="[0]"
                            >
                      <template v-slot:header="props">
                        <q-th no-caps
                              class="tableHeader"
                              v-for="col in props.cols"
                              :key="col.name"
                              :style='col.name!="status" ? "border-color: #eeeeee;border-width: 0 1px 1px 0;" : "border-color: #eeeeee;border-width: 0 0 1px 0;"'
                              :props="props">
                            {{ col.label }}
                        </q-th>
                      </template>

                      <template v-slot:body="props">
                        <q-tr :props="props">
                          <q-td key="firstTime" :props="props" style="width:30%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="emColumnClicked(props.row, props.row.index, 0)">
                            <div class="row items-center justify-start no-wrap">
                              <div style="font-size:14px">
                                {{ props.row.firstTime }}
                              </div>
                            </div>
                          </q-td>
                          <!--
                          <q-td key="lastTime" :props="props" style="width:140px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="emColumnClicked(props.row, props.row.index, 1)">
                            <div style="font-size:14px">
                              {{ props.row.lastTime }}
                            </div>
                          </q-td>
                          -->
                          <q-td key="type" :props="props" style="width:45%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="emColumnClicked(props.row, props.row.index, 2)">
                            <div style="font-size:14px;color:#eb0028" v-show="props.row.etype == 1">  <!-- Red -->
                              {{ props.row.type }}
                            </div>
                            <div style="font-size:14px;color:#ffc600" v-show="props.row.etype == 2">  <!-- Yellow -->
                              {{ props.row.type }}
                            </div>
                            <div style="font-size:14px;color:#c97db1" v-show="props.row.etype == 3">  <!-- Violet -->
                              {{ props.row.type }}
                            </div>
                            <div style="font-size:14px;color:#bbbbbb" v-show="props.row.etype == 4">  <!-- Grey -->
                              {{ props.row.type }}
                            </div>
                            <div style="font-size:14px;color:#eb0028" v-show="props.row.etype == null">
                              {{ props.row.type }}
                            </div>
                          </q-td>
                          <q-td key="status" :props="props" style="width:25%;border-color: #eeeeee;border-width: 1px 0 1px 0;cursor: cell;">
                            <div class="row items-center">
                              <q-btn no-caps dense class="col" :color='props.row.mstate == 2 ? "green-7" : "red-7"'
                                    :label="props.row.status"
                                    style="width:100%;margin-left:6px;" @click="emColumnClicked(props.row, props.row.index, 3)">
                              </q-btn>
                              <!--
                              <q-icon name="star" :color='props.row.mstate == 2 ? "green-7" : "red-7"' size="xs"/>
                              <div style="margin-left:6px;font-size:14px;text-decoration: underline;">
                                {{ props.row.status }}
                              </div>s
                              -->
                            </div>
                          </q-td>
                        </q-tr>
                      </template>
                    </q-table>
                  </q-scroll-area>
                </div>
              </div>
            </div>

            <div class="col column" style="margin:12px 0 0 0;background-color:white;border-radius:3px;">
              <!-- Current Error -->
              <div class="col column" style="margin:0 12px 12px 12px;">
                <div class="col-auto row justify-between items-center" style="height:45px">
                  <div class="col" style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('machineDetail.manageER') }}</div>
                  <div style="font-weight:500;margin:0 12px;" v-show="emCount > 0">[&nbsp;{{ brCount }}&nbsp;]</div>
                  <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('machineDetail.history')"
                         :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="width:auto;padding:0 12px;"
                         @click="showBrHistory(true)">
                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.loadErrorHistory') }}</q-tooltip>
                  </q-btn>
                </div>
                <!--
                <div class="col row tableSize" style="position:relative;min-height:141px;border-color:#eeeeee;border-width:0 0 1px 0;border-style: solid;border-radius: 3px;">
                -->
                <div class="col row" style="border-radius: 3px;">
                  <q-scroll-area class="col column" style="width:100%;height:100%;">
                    <!-- table-header-style="position:sticky;top:0;z-index:1;background-color:white;" -->
                    <q-table dense flat bordered
                            ref="brTable"
                            class="col"
                            style="font-size:14px;width:100%;height:100%;"
                            separator="cell"
                            row-key="index"
                            selection="single"
                            :selected="brSelectedRows"
                            virtual-scroll
                            hide-no-data
                            hide-bottom
                            :visible-columns="visibleBrColumns"
                            :columns="brColumns"
                            :rows="brRows"
                            :rows-per-page-options="[0]"
                            >
                      <template v-slot:header="props">
                        <q-th no-caps
                              class="tableHeader"
                              v-for="col in props.cols"
                              :key="col.name"
                              :style='col.name!="status" ? "border-color: #eeeeee;border-width: 0 1px 1px 0;" : "border-color: #eeeeee;border-width: 0 0 1px 0;"'
                              :props="props">
                            {{ col.label }}
                        </q-th>
                      </template>

                      <template v-slot:body="props">
                        <q-tr :props="props">
                          <q-td key="firstTime" :props="props" style="width:140px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;"
                                @click="brColumnClicked(props.row, props.row.index, 0)">
                            <div class="row items-center justify-start no-wrap">
                              <q-checkbox dense color="grey-8" style="margin-right:8px" v-model=props.row.selected></q-checkbox>
                              <div style="font-size:14px;">
                                {{ props.row.firstTime }}&nbsp;(&nbsp;{{ props.row.vut }}&nbsp;h&nbsp;)
                              </div>
                            </div>
                          </q-td>
                          <q-td key="lastTime" :props="props" style="width:140px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;"
                                @click="brColumnClicked(props.row, props.row.index, 1)">
                            <div style="font-size:14px">
                              {{ props.row.lastTime }}&nbsp;(&nbsp;{{ props.row.vut }}&nbsp;h&nbsp;)
                            </div>
                          </q-td>
                          <q-td key="type" :props="props" style="width:180px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;"
                                @click="brColumnClicked(props.row, props.row.index, 2)">
                            <div style="font-size:14px;">
                              {{ props.row.type }}
                            </div>
                          </q-td>
                          <q-td key="manual" style="width:70px;border-color: #eeeeee;border-width: 1px 1px 1px 0;">
                            <div class="row items-center justify-center" style="width:100%;height:100%" v-show="props.row.manual != null">
                              <q-btn dense class="col-auto" color="white" 
                                    @click="brColumnClicked(props.row, props.row.index, 3)">
                                <q-img class="col-auto" width="26px" height="26px"                                        
                                        src="../assets/manage/management_manual_icon_nor.png"></q-img>
                              </q-btn>
                            </div>
                          </q-td>
                          <q-td key="status" :props="props" style="width:100px;border-color: #eeeeee;border-width: 1px 0 1px 0;">
                            <q-btn no-caps dense class="col" color="red-1" 
                                   style="width:100%;margin-left:6px;"
                                   @click="brColumnClicked(props.row, props.row.index, 4)">
                              <div style="font-size:14px;" :style='"color:" + brStateColors[props.row.mstate]'>
                                {{ props.row.status }}
                              </div>
                            </q-btn>
                          </q-td>
                        </q-tr>
                      </template>
                    </q-table>
                  </q-scroll-area>
                </div>
              </div>
            </div>
          </div>

          <!-- 우측공간에 뭔가 추가할 때 col-8로 변경, 우측 마진 6px 필요 -->
          <div class="column" :class='isLandscape ? "col" : "col-auto"'
               :style='isLandscape ? "margin:0;" : "margin:0 0 12px 0;"'
               v-show="showEmergencyHistory && !showErrorHistory">
            <div class="col column" style="border-radius:3px;padding:0 0 0 0;width:100%;min-height:auto;background-color:white;">
              <div class="col column"
                   :style='isLandscape ? "padding:12px 12px 12px 12px;" : "padding:12px;"'>
                <!-- Emergency History -->
                <div class="col-auto row items-cetner justify-between" :class="isLandscape ? 'row items-start' : 'column'"
                     :style='isLandscape ? "min-height:45px;" : "height:auto;"'>
                  <div class="col-auto row items-cetner justify-between">
                    <div class="col-auto" style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('machineDetail.historyEM') }}</div>

                    <q-btn class="col-auto" no-caps unelevated dense color="red-7" icon="close" style="width:32px;height:32px;" v-show="!isLandscape"
                          @click="showBrHistory(false)">
                    </q-btn>
                  </div>

                  <div class="col-auto row items-center justify-center" style="margin-bottom:12px;">
                    <div class="col-auto row items-center justify-center no-wrap">
                      <div class="col row items-center" style="font-size:14px;width:auto;">
                        <div style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;">[&nbsp;{{ (emHistoryStart != '' && emHistoryStart != undefined) ? emHistoryStart : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}}&nbsp;]</div>
                        <q-btn dense flat icon="calendar_month" style="cursor:pointer;">
                          <q-popup-proxy cover ref="emHistoryStartCal" @before-show="popupDateSelect('emHistoryStart')">
                            <q-date flat minimal no-unset color="red-7" v-model="emHistoryDateCal" @update:model-value="afterDateSelect('emHistoryStart')">
                            </q-date>
                          </q-popup-proxy>
                        </q-btn>
                      </div>
                    </div>
                    <div class="col-auto row items-center justify-center">
                      <div class="col">~&nbsp;</div>
                    </div>
                    <div class="col-auto row items-center justify-center no-wrap">
                      <div class="col row items-center" style="font-size:14px;width:auto;height:auto;">
                        <div style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;">[&nbsp;{{ (emHistoryEnd != '' && emHistoryEnd != undefined) ? emHistoryEnd : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}}&nbsp;]</div>
                        <q-btn dense flat icon="calendar_month" style="cursor:pointer;">
                          <q-popup-proxy cover ref="emHistoryEndCal" @before-show="popupDateSelect('emHistoryEnd')">
                            <q-date minimal no-unset color="red-7" v-model="emHistoryDateCal" @update:model-value="afterDateSelect('emHistoryEnd')">
                            </q-date>
                          </q-popup-proxy>
                        </q-btn>
                      </div>
                    </div>
                    <div class="col-auto row items-center">
                      <!-- Landscape, Portrait 에 따라 텍스트버튼, 아이콘 버튼으로 변경-->
                      <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')" style="width:80px;height:32px" @click="refreshEmHistory()" v-show="isLandscape">
                      </q-btn>
                      
                      <q-btn class="col-auto row justify-end" no-caps unelevated dense color="grey-6" text-color="white" icon="search" style="width:32px;" @click="refreshEmHistory()" v-show="!isLandscape">
                      </q-btn>
                    </div>
                  </div>

                  <div class="col-auto justify-end">
                    <q-btn flat dense @click="downloadToExcel('emergency')" :disable="isEditConsumable" v-show="canDownloadToExcel">
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                      <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;"/>
                      <q-popup-proxy ref="excelPopup" style="width:auto;height:220px;background-color: #01010130;border-radius: 3px;position:relative;"
                                      @before-show="onShowExportToExcel">
                        <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                          <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px 24px;">
                            <div class="col-auto row items-center justify-center">
                              <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'" style="font-size:18px;margin:0 0 12px 0;">
                                {{ $t('common.toExcel') }}
                              </div>
                            </div>
                            <div class="col row items-center justify-start" style="width:100%;margin:0;">
                              <table class="col" style="width:100%;margin:0;">
                                <tr style="">
                                  <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.filename') }}</td>
                                  <td width="12px" style=""></td>
                                  <td style="">{{ excelFilename }}</td>
                                </tr>
                                <tr>
                                  <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.from') }}</td>
                                  <td width="12px"></td>
                                  <td>{{ excelFrom }}</td>
                                </tr>
                                <tr>
                                  <td width=25% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.to') }}</td>
                                  <td width="12px"></td>
                                  <td>{{ excelTo }}</td>
                                </tr>
                              </table>
                            </div>
                            <xlsx-workbook class="col-auto row items-center justify-center">
                              <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                              <xlsx-download :filename="excelFilename">
                                <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveEmHistoryToExcelFile">{{ $t('common.download') }}</button>
                              </xlsx-download>
                            </xlsx-workbook>
                          </div>

                          <div class="col row items-center justify-center" v-show="!hasExcelData" style="width:100%;height:100%;position:absolute;left:0;top:0;right:0;bottom:0;background-color:white;">
                            <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'" style="font-size:18px;color:#eb0028">{{ $t('warning.nodata')}}</div>
                          </div>
                          
                        </div>
                        <div class="row items-center justify-center" v-show="makingExcel"
                            style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                          <q-spinner color="red-7" size="70px" thickness="1.5"/>
                        </div>
                      </q-popup-proxy>
                    </q-btn>
                    <q-btn class="col-auto" no-caps unelevated dense color="red-7" icon="close" style="width:auto;" v-show="isLandscape"
                          @click="showBrHistory(false)">
                    </q-btn>
                  </div>
                </div>
                <div class="col row">
                  <div class="col row items-center justify-center" style="border:1px solid #eeeeee" v-show="emHistoryRows.length < 1">
                    <div class="col-auto" style="font-size:18px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                      {{ $t('manage.noEmergencyHistory') }}
                    </div>
                  </div>
                  <q-table dense flat bordered v-show="emHistoryRows.length > 0"
                          separator="none"
                          row-key="index"
                          virtual-scroll
                          hide-no-data
                          style="font-size:14px;width:100%;"
                          :style='isLandscape ? "height:auto;" : "height:auto;"'
                          table-header-style="position:sticky;top:0;z-index:1;background-color:white;"
                          v-model:pagination="emHistoryPage"
                          :visible-columns="visibleEmHistoryColumns"
                          :columns="emHistoryColumns"
                          :rows="emHistoryRows"
                          @update:pagination="updateEmPagination">
                    <template v-slot:header="props">
                      <q-th no-caps
                            class="tableHeader"
                            v-for="col in props.cols"
                            :key="col.name"
                            :style='col.name!="status" ? "border-color: #eeeeee;border-width: 0 1px 1px 0;" : "border-color: #eeeeee;border-width: 0 0 1px 0;"'
                            :props="props">
                          {{ col.label }}
                      </q-th>
                    </template>

                    <template v-slot:body="props">
                      <q-tr :props="props">
                        <q-td key="occurTime" :props="props" style="width:20%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="emHistoryColumnClicked(props.row, props.row.index, 0)">
                          <div class="row items-center justify-start no-wrap">
                            <div style="font-size:14px">
                              {{ props.row.occurTime }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="resolveTime" :props="props" style="width:20%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="emHistoryColumnClicked(props.row, props.row.index, 1)">
                          <div class="row items-center justify-start no-wrap">
                            <div style="font-size:14px">
                              {{ props.row.resolveTime }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="type" :props="props" style="width:22%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="emHistoryColumnClicked(props.row, props.row.index, 2)">
                          <div style="font-size:14px;color:#eb0028" v-show="props.row.etype == 1">  <!-- Red -->
                            {{ props.row.type }}
                          </div>
                          <div style="font-size:14px;color:#ffc600" v-show="props.row.etype == 2">  <!-- Yellow -->
                            {{ props.row.type }}
                          </div>
                          <div style="font-size:14px;color:#c97db1" v-show="props.row.etype == 3">  <!-- Violet -->
                            {{ props.row.type }}
                          </div>
                          <div style="font-size:14px;color:#bbbbbb" v-show="props.row.etype == 4">  <!-- Grey -->
                            {{ props.row.type }}
                          </div>
                          <div style="font-size:14px;color:#eb0028" v-show="props.row.etype == null">
                            {{ props.row.type }}
                          </div>
                        </q-td>
                        <q-td key="resolver" :props="props" style="width:13%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;"
                              @click="emHistoryColumnClicked(props.row, props.row.index, 5)">
                          <div style="font-size:14px;">
                            {{ props.row.resolver }}
                          </div>
                        </q-td>
                        <q-td key="resolveMsg" :props="props" style="width:25%;border-color: #eeeeee;border-width: 1px 0 1px 0;cursor: pointer;"
                              @click="emHistoryColumnClicked(props.row, props.row.index, 5)">
                          <div style="font-size:14px;">
                            {{ props.row.resolveMsg }}
                          </div>
                        </q-td>
                      </q-tr>
                    </template>
                    <template v-slot:bottom>
                      <div class="row items-center justify-end" style="width:100%;">
                        <q-pagination class="col-auto"
                                        v-model="emHistoryPagination"
                                        :max="emHistoryPageCount"
                                        :max-pages='isLandscape ? "6" : "4"'
                                        outline dense
                                        direction-links
                                        boundary-links
                                        gutter="5px"
                                        size="sm"
                                        color="grey-7"
                                        active-design="unelevated"
                                        active-color="red-7"
                                        active-text-color="white"
                                        @update:model-value="emHistoryPageChanged">
                        </q-pagination>
                      </div>
                    </template>
                  </q-table>
                </div>
              </div>
            </div>
          </div>

          <!-- 우측공간에 뭔가 추가할 때 col-8로 변경, 우측 마진 6px 필요 -->
          <div class="column" :class='isLandscape ? "col" : "col-auto"'
               :style='isLandscape ? "margin:0 0 0 0;" : "margin:0 0 0 0;"'
               v-show="!showEmergencyHistory && showErrorHistory">
            <div class="col column" style="border-radius:3px;padding:0 0 0 0;width:100%;min-height:358px;background-color:white;">
              <div class="col column"
                   :style='isLandscape ? "padding:12px 12px 12px 12px;" : "padding:12px;"'>
                <!-- Error History -->
                <div class="col-auto justify-between " :class="isLandscape ? 'row items-start' : 'column'" :style='isLandscape ? "min-height:45px;" : "height:auto;"'>
                  <div class="row justify-between">
                    <div class="col-auto" style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('machineDetail.historyBR') }}</div>
                    <q-btn class="col-auto" no-caps unelevated dense color="red-7" icon="close" style="width:auto;" v-show="!isLandscape"
                           @click="showBrHistory(false)">
                    </q-btn>
                  </div>

                  <div class="col-auto row items-center justify-center" style="margin-bottom:12px;">
                    <div class="col-auto row items-center justify-center no-wrap">
                      <div class="col row items-center" style="font-size:14px;width:auto;">
                        <div style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;">[&nbsp;{{ (brHistoryStart != '' && brHistoryStart != undefined) ? brHistoryStart : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}}&nbsp;]</div>
                        <q-btn dense flat icon="calendar_month" style="cursor:pointer;">
                          <q-popup-proxy cover ref="brHistoryStartCal" @before-show="popupDateSelect('brHistoryStart')">
                            <q-date minimal no-unset color="red-7" v-model="brHistoryDateCal" @update:model-value="afterDateSelect('brHistoryStart')">
                            </q-date>
                          </q-popup-proxy>
                        </q-btn>
                      </div>
                    </div>
                    <div class="col-auto row items-center justify-center">
                      <div class="col">~&nbsp;</div>
                    </div>
                    <div class="col-auto row items-center justify-center no-wrap">
                      <div class="col row items-center" style="font-size:14px;width:auto;height:auto;">
                        <div style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;">[&nbsp;{{ (brHistoryEnd != '' && brHistoryEnd != undefined) ? brHistoryEnd : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}}&nbsp;]</div>
                        <q-btn dense flat icon="calendar_month" style="cursor:pointer;">
                          <q-popup-proxy cover ref="brHistoryEndCal" @before-show="popupDateSelect('brHistoryEnd')">
                            <q-date minimal no-unset color="red-7" v-model="brHistoryDateCal" @update:model-value="afterDateSelect('brHistoryEnd')">
                            </q-date>
                          </q-popup-proxy>
                        </q-btn>
                      </div>
                    </div>
                    <div class="col-auto row items-center">
                      <!-- Landscape, Portrait 에 따라 텍스트버튼, 아이콘 버튼으로 변경-->
                      <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')" style="width:80px;height:32px" @click="refreshBrHistory()" v-show="isLandscape">
                      </q-btn>
                      <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" icon="search" style="width:32px;" @click="refreshBrHistory()" v-show="!isLandscape">
                      </q-btn>
                    </div>
                  </div>

                  <div class="col-auto justify-end">
                    <q-btn flat dense @click="downloadToExcel('error')" :disable="isEditConsumable" v-show="canDownloadToExcel">
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                      <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;"/>
                      <q-popup-proxy ref="excelPopup" style="width:auto;height:220px;background-color: #01010130;border-radius: 3px;position:relative;"
                                      @before-show="onShowExportToExcel">
                        <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                          <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px 24px;">
                            <div class="col-auto row items-center justify-center">
                              <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                                {{ $t('common.toExcel') }}
                              </div>
                            </div>
                            <div class="col row items-center justify-start" style="width:100%;margin:0;">
                              <table class="col" style="width:100%;margin:0;">
                                <tr style="">
                                  <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.filename') }}</td>
                                  <td width="12px" style=""></td>
                                  <td style="">{{ excelFilename }}</td>
                                </tr>
                                <tr>
                                  <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.from') }}</td>
                                  <td width="12px"></td>
                                  <td>{{ excelFrom }}</td>
                                </tr>
                                <tr>
                                  <td width=25% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.to') }}</td>
                                  <td width="12px"></td>
                                  <td>{{ excelTo }}</td>
                                </tr>
                              </table>
                            </div>
                            <xlsx-workbook class="col-auto row items-center justify-center">
                              <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                              <xlsx-download :filename="excelFilename">
                                <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveBrHistoryToExcelFile">{{ $t('common.download') }}</button>
                              </xlsx-download>
                            </xlsx-workbook>
                          </div>

                          <div class="col row items-center justify-center" v-show="!hasExcelData" style="width:100%;height:100%;position:absolute;left:0;top:0;right:0;bottom:0;background-color:white;">
                            <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'" style="font-size:18px;color:#eb0028">{{ $t('warning.nodata')}}</div>
                          </div>
                          
                        </div>
                        <div class="row items-center justify-center" v-show="makingExcel"
                            style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                          <q-spinner color="red-7" size="70px" thickness="1.5"/>
                        </div>
                      </q-popup-proxy>
                    </q-btn>
                    <q-btn class="col-auto" no-caps unelevated dense color="red-7" icon="close" style="width:auto;" v-show="isLandscape"
                          @click="showEmHistory(false)">
                    </q-btn>
                  </div>

                </div>
                <div class="col row">
                  <div class="col row items-center justify-center" style="border:1px solid #eeeeee" v-show="brHistoryRows.length < 1">
                    <div class="col-auto" style="font-size:18px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                      {{ $t('manage.noErrorHistory')}}
                    </div>
                  </div>

                  <q-table dense flat bordered v-show="brHistoryRows.length > 0"
                          separator="none"
                          row-key="index"
                          virtual-scroll
                          hide-no-data
                          style="font-size:14px;width:100%;height:100%;"
                          table-header-style="position:sticky;top:0;z-index:1;background-color:white;"
                          v-model:pagination="brHistoryPage"
                          :visible-columns="visibleBrHistoryColumns"
                          :columns="brHistoryColumns"
                          :rows="brHistoryRows"
                          @update:pagination="updateBrPagination">
                    <template v-slot:header="props">
                      <q-th no-caps
                            class="tableHeader"
                            v-for="col in props.cols"
                            :key="col.name"
                            :style='col.name!="status" ? "border-color: #eeeeee;border-width: 0 1px 1px 0;" : "border-color: #eeeeee;border-width: 0 0 1px 0;"'
                            :props="props">
                          {{ col.label }}
                      </q-th>
                    </template>

                    <template v-slot:body="props">
                      <q-tr :props="props" style="height:44px;margin:0">
                        <q-td key="occurTime" :props="props" style="width:20%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="brHistoryColumnClicked(props.row, props.row.index, 0)">
                          <div class="row items-center justify-start no-wrap">
                            <div style="font-size:14px">
                              {{ props.row.occurTime }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="resolveTime" :props="props" style="width:20%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="brHistoryColumnClicked(props.row, props.row.index, 1)">
                          <div class="row items-center justify-start no-wrap">
                            <div style="font-size:14px">
                              {{ props.row.resolveTime }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="erCode" :props="props" style="width:10%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="brHistoryColumnClicked(props.row, props.row.index, 2)">
                          <div class="row items-center justify-start no-wrap">
                            <div style="font-size:14px">
                              {{ props.row.erCode }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="manual" style="width:70px;border-color: #eeeeee;border-width: 1px 1px 1px 0;">
                          <div class="row items-center justify-center" style="width:100%;height:100%" v-show="props.row.manual">
                            <q-btn dense class="col" color="white" 
                                  @click="brHistoryColumnClicked(props.row, props.row.index, 3)">
                              <q-img class="col-auto" width="26px" height="26px"
                                      src="../assets/manage/management_manual_icon_nor.png"></q-img>
                            </q-btn>
                          </div>
                        </q-td>
                        <q-td key="erMsg" :props="props" style="width:20%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="brHistoryColumnClicked(props.row, props.row.index, 4)">
                          <div class="row items-center justify-start no-wrap">
                            <div style="font-size:14px">
                              {{ props.row.erMsg }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="resolver" :props="props" style="width:13%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;"
                              @click="brHistoryColumnClicked(props.row, props.row.index, 5)">
                          <div style="font-size:14px;">
                            {{ props.row.resolver }}
                          </div>
                        </q-td>
                        <q-td key="resolveMsg" :props="props" style="width:25%;border-color: #eeeeee;border-width: 1px 0 1px 0;cursor: pointer;"
                              @click="brHistoryColumnClicked(props.row, props.row.index, 6)">
                          <div style="font-size:14px;">
                            {{ props.row.resolveMsg }}
                          </div>
                        </q-td>
                      </q-tr>
                    </template>
                    <template v-slot:bottom>
                      <div class="row items-center justify-end" style="width:100%;">
                        <q-pagination class="col-auto"
                                        v-model="brHistoryPagination"
                                        :max="brHistoryPageCount"
                                        :max-pages='isLandscape ? "6" : "4"'
                                        outline dense
                                        direction-links
                                        boundary-links
                                        gutter="5px"
                                        size="sm"
                                        color="grey-7"
                                        active-design="unelevated"
                                        active-color="red-7"
                                        active-text-color="white"
                                        @update:model-value="brHistoryPageChanged">
                        </q-pagination>
                      </div>
                    </template>
                  </q-table>
                </div>
              </div>
            </div>
          </div>

          <div class="col column" :style='isLandscape ? "margin:0 0 0 6px;" : "margin:12px 0 12px 0;"'
               style="border-radius:3px;"
               v-show='(isLandscape || viewEmerErr !== 0) && !(showEmergencyHistory || showErrorHistory)'>
            <div class="col column" style="position:relative;border-radius:3px;padding:0 12px 12px 12px;background-color:white;">
              <div class="col column" v-show="viewEmerErr==2 && !isVisibleBrCOS">
                <!-- Processing History -->
                <div class="col-auto row justify-between items-center" style="height:45px;">
                  <div style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('machineDetail.procHistory')}}</div>
                  <q-btn class="row items-center justify-center" dense color="red-7" 
                         size="13px" style="margin:0 6px 0 0" icon="close"
                         @click="viewEmerErr = 0">
                  </q-btn>
                </div>
                <div class="col column items-center" v-show="oneBrokenData.length > 0">
                  <div class="col column" style="border-radius: 3px;border:1px solid #eeeeee;width:100%;height:100%;min-height:200px;padding-bottom:12px;">
                    <div class="col row no-wrap">
                      <q-scroll-area style="width:100%;height:100%;padding:0 12px;">
                        <q-timeline style="padding:0 12px;font-size:14px;">
                          <q-timeline-entry color='green-8' v-if="oneBrokenData.length > 2">
                            <template v-slot:subtitle>
                              <div class="row items-center justify-between no-wrap">
                                <div class="col-auto" style="border:1px solid #44A047;color:#44A047;border-radius: 3px;padding:0 6px;font-size:14px;">{{ oneBrokenData[2].msg }}</div>
                                <div class="col" v-show="oneBrokenData[2].date != ''" style="height:2px;border-top:2px dotted gray;margin:0 12px"></div>
                                <div class="col-auto" v-show="oneBrokenData[2].date != ''" style="font-size:14px;">{{ oneBrokenData[2].date }}</div>
                              </div>
                            </template>
                            <template v-slot:title>
                              <div style="font-size:14px;margin:0;padding:0" v-show="oneBrokenData[2].context != ''">{{ oneBrokenData[2].context }}</div>
                            </template>

                            <div class="column">
                              <div class="col" v-show="oneBrokenData[2].vut > 0">{{ $t('common.opTime')}}&nbsp;:&nbsp;{{ oneBrokenData[2].vut }}&nbsp;h</div>
                              <div class="col" v-show="oneBrokenData[2].context != ''">{{ oneBrokenData[2].context }}</div>
                              <div class="col" v-show="oneBrokenData[2].manager != ''">{{ oneBrokenData[2].manager }}</div>
                            </div>
                          </q-timeline-entry>

                          <!-- 신고접수 -->
                          <q-timeline-entry color='yellow-8' v-if="oneBrokenData.length > 1">
                            <template v-slot:subtitle>
                              <div class="row items-center justify-between no-wrap">
                                <div class="col-auto" style="border:1px solid #FBC02D;color:#FBC02D;border-radius: 3px;padding:0 6px;font-size:14px;">{{ oneBrokenData[1].msg }}</div>
                                <div class="col" v-show="oneBrokenData[1].date != ''" style="height:2px;border-top:2px dotted gray;margin:0 12px"></div>
                                <div class="col-auto" v-show="oneBrokenData[1].date != ''" style="font-size:14px;">{{ oneBrokenData[1].date }}</div>
                              </div>
                            </template>
                            <template v-slot:title>
                              <div style="font-size:14px;margin:0;padding:0" v-show="oneBrokenData[1].context != ''">{{ oneBrokenData[1].context }}</div>
                            </template>

                            <div class="column">
                              <div class="col" v-show="oneBrokenData[1].vut > 0">{{ oneBrokenData[1].vut }}&nbsp;h</div>
                              <div class="col" v-show="oneBrokenData[1].manager != ''">{{ oneBrokenData[1].manager }}</div>
                            </div>
                          </q-timeline-entry>

                          <!-- 고장 발생 -->
                          <q-timeline-entry color='red-8' v-if="oneBrokenData.length > 0">
                            <template v-slot:subtitle>
                              <div class="row items-center justify-between no-wrap">
                                <div class="col-auto" style="border:1px solid #eb0028;color:#eb0028;border-radius: 3px;padding:0 6px;font-size:14px;">{{ oneBrokenData[0].msg }}</div>
                                <div class="col" v-show="oneBrokenData[0].date != ''" style="height:2px;border-top:2px dotted gray;margin:0 12px"></div>
                                <div class="col-auto" v-show="oneBrokenData[0].date != ''" style="font-size:14px;">{{ oneBrokenData[0].date }}</div>
                              </div>
                            </template>
                            <template v-slot:title>
                              <div style="font-size:14px;margin:0;padding:0" v-show="oneBrokenData[0].context != ''">{{ oneBrokenData[0].context }}</div>
                            </template>
                            <div class="column">
                              <div class="col" v-show="oneBrokenData[0].vut > 0">{{ $t('common.opTime')}}&nbsp;:&nbsp;{{ oneBrokenData[0].vut }}&nbsp;h</div>
                              <div class="col" v-show="oneBrokenData[0].ecode != ''">{{ $t('common.erCode')}}&nbsp;:&nbsp;{{ oneBrokenData[0].ecode }}</div>
                              <div v-show="oneBrokenData[0].manager != ''">{{ oneBrokenData[0].manager }}</div>
                            </div>
                          </q-timeline-entry>

                          <!-- 추가 엔트리가 있어야 정상 마무리 되더라...-->
                          <q-timeline-entry style="display:none">
                          </q-timeline-entry>
                        </q-timeline>
                      </q-scroll-area>
                      <div class="col-auto row">
                        <div class="column items-center justify-start">
                          <q-btn unelevated dense color="grey-3" style="width:32px;height:32px;margin:12px 6px 0 6px"
                                @click="viewBrOccurLocation">
                            <q-img src="../assets/manage/history_location_i.svg" width="16px" fit="fill"/>
                          </q-btn>
                        </div>
                      </div>
                    </div>
                    <!--
                    <div class="col-auto row justify-center items-end" style="height:44px" v-show="isLandscape">
                      <q-btn class="col-auto" no-caps unelevated  dense color="red-7" text-color="white" label="Close" style="width:80px;height:32px"
                              @click="viewEmerErr = 0">
                        <q-tooltip style="font-size:14px;">닫아요.</q-tooltip>
                      </q-btn>
                    </div>
                    -->
                  </div>
                </div>
              </div>

              <div class="col column" v-show="isVisibleLoca" style="z-index:97;background-color:white;"
                    :style='isLandscape ? "position: absolute;left:12px;top:0;right:12px;right:12px;bottom:12px;margin-top:0;" : "margin-top:12px;"'>
                <div class="col column" v-show="hasOccLoca">
                  <!-- Occurrence Location -->
                  <div class="col-auto row justify-between items-center" style="height:45px;">
                    <div style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('manage.occurLoca') }}</div>
                    <q-btn class="col-auto" no-caps unelevated dense color="red-7" icon="close" style="width:auto;"
                          @click="isVisibleLoca = false">
                    </q-btn>
                  </div>
                  <div class="col column items-start justify-center" style="width:100%;height:100%;min-height:160px">
                    <div class="col row" style="width:100%;min-height:200px">
                      <MapView ref="occurLoca" elementid="occurLoca" class="col" @addressReceived="addrReceived"></MapView>
                    </div>
                    <div class="col-auto" style="height:19px;margin:12px 0 0 0;font-size:14px;">{{ $t('common.address') }}&nbsp;:&nbsp;{{ machineAddress }}</div>
                  </div>
                </div>
                <div class="col row justify-center items-center" v-show="!hasOccLoca">
                  <div class="col column justify-center items-center">
                    <div class="col" style="font-size:18px;font-weight: 500;">
                      {{ $t('errorMsg.noLocaInfo') }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col column" v-show="isVisibleEmCOS" style="z-index:98;background-color:white;"
                :style='isLandscape ? "position: absolute;left:12px;top:0;right:12px;right:12px;bottom:12px;margin-top:0;" : "margin-top:12px;"'>
                <div class="col column">
                  <!-- 긴급알림 Change of status -->
                  <div class="col-auto row justify-between items-center" style="height:45px;">
                    <div class="col-auto" style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('machineDetail.changeEM') }}</div>
                    <div class="col" style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'"></div>

                    <!--
                    <q-btn class="col-auto" flat dense color='grey-9' size="16px" icon="edit" @click="isEditEmHistory = true">
                      <q-tooltip style="font-size:14px;">선택한 긴급 알림의 내용을 수정합니다.</q-tooltip>
                    </q-btn>
                    -->
                    <div v-show="!isEditEmHistory">
                      <q-btn outline dense round color="blue-7" padding="5px" size="sm" icon="edit" style="margin-right:12px" @click="isEditEmHistory = true">
                        <q-tooltip style="font-size:14px">{{ $t('tooltip.editJob') }}</q-tooltip>
                      </q-btn>
                    </div>
                    <div v-show="isEditEmHistory">
                      <q-btn class="col" flat dense rounded color="red-5" icon="cancel" @click="isEditEmHistory = false">
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                      </q-btn>
                      <q-btn class="col" flat dense rounded color="blue-8" icon="save" @click="saveEmCOS()">
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                  <div class="col column items-start justify-center" style="width:100%;height:100%;min-height:160px;border-radius:3px"
                      :style='isEditEmHistory ? "border:1px solid #eb0028" : "border:1px solid #eeeeee"'>
                    <div class="col row" style="width:100%;min-height:200px;font-size:14px;padding:12px;">
                      <table class="col">
                        <tr height="32px">
                          <td width="124px">
                            <q-toggle dense color="red-7" v-model="isEmCosResolved" :label="$t('machineDetail.resolved')" v-show="isEmCosResolved" :disable="!isEditEmHistory"></q-toggle>
                            <q-toggle dense color="red-7" v-model="isEmCosResolved" :label="$t('machineDetail.unresolved')" v-show="!isEmCosResolved" :disable="!isEditEmHistory"></q-toggle>
                          </td>
                          <td colspan="2" >
                            <div class="row justify-end" style="font-weight:500;"> 
                              [&nbsp;{{ emCosType }}&nbsp;]
                            </div>
                          </td>
                        </tr>
                        <!-- 20230510 숨겨 놓음 -->
                        <tr height="28px" v-show="false">
                          <td width="124px" class="row items-center">
                            <div class="col-auto" style="font-weight:500;">Occurrence Type</div>
                          </td>
                          <td>
                            <q-toggle dense color="blue-4" v-model="isRealOccur" :label='isRealOccur ? "Real Occurence" : "Just Alarm"' :disable="!isEditEmHistory"></q-toggle>
                          </td>
                        </tr>
                        <tr height="24px">
                          <td>
                            <div style="font-weight:500;">{{ $t('machineDetail.resolvedDate') }}</div>
                          </td>
                          <td>
                            <div class="row justify-start items-end">
                              <div style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;width:120px">{{ emCosDate }}</div>
                                <q-btn dense flat icon="calendar_month" style="cursor:pointer;" :disable="!isEditEmHistory">
                                  <q-popup-proxy @before-show="showEmSolveDate" @hide="hideEmSolveDate" cover ref="emHistoryResolveCal">
                                    <q-date minimal no-unset color="red-7" v-model="emCosDateCal" @update:model-value="selectEmHistoryResolveCal">
                                    </q-date>
                                  </q-popup-proxy>
                                </q-btn>
                            </div>
                          </td>
                        </tr>
                        <tr height="24px">
                          <td>
                            <div style="font-weight:500;">{{ $t('machineDetail.manager') }}</div>
                          </td>
                          <td>
                            <q-input v-model="emCosManager" color="red-7" dense clearable style="width:50%;min-width:150px;font-weight:400;" :readonly="!isEditEmHistory">
                            </q-input>
                            <!-- 수정 불가
                            <div class="row justify-start items-end">
                              <div style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;width:80%">{{ emCosManager }}</div>
                            </div>
                            -->
                          </td>
                        </tr>
                        <tr>
                          <td style="align-items: start;vertical-align:top;">
                              <div class="col" style="font-weight:500;margin:6px 0 0 0;">{{ $t('noticeView.context') }}</div>
                          </td>
                          <td class="column justify-center items-center">
                            <div class="col-auto" style="width:100%;height:104px;border-radius:3px;border:1px solid #aaaaaa;margin:6px 0 0 0;">
                              <q-scroll-area style="width:100%;height:100%;">
                                <q-input v-model="emCosContents" borderless autogrow dense hide-bottom-space type="textarea"
                                         maxlength="100"
                                         style="padding:2px 3px 12px 3px;font-weight:400;" :readonly="!isEditEmHistory"></q-input>
                              </q-scroll-area>
                            </div>
                          </td>
                        </tr>
                        <tr>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <!--
                <div class="col-auto row justify-center items-end" style="height:44px;">
                  <div class="col row justify-center items-end">
                    <q-btn class="col-auto" no-caps unelevated outline dense color="grey-2" text-color="black" :label="$t('common.cancel')" style="width:80px;height:32px;margin-right:6px;"
                         @click="cancelEmCOS">
                      <q-tooltip style="font-size:14px;">작업을 취소합니다.</q-tooltip>
                    </q-btn>
                    <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.save')" style="width:80px;height:32px;margin-left:6px;" :disable="!isEditEmHistory"
                         @click="saveEmCOS()">
                      <q-tooltip style="font-size:14px;">수정한 내용을 저장합니다..</q-tooltip>
                    </q-btn>
                  </div>
                </div>
                -->
              </div>

              <div class="col column" v-show="isVisibleBrCOS" style="z-index:99;background-color:white;"
                    :style='isLandscape ? "position: absolute;left:12px;top:0;right:12px;right:12px;bottom:12px;margin-top:0;" : "margin-top:12px;"'>
                <div class="col column">
                  <!-- 고장알림 Change of status -->
                  <div class="col-auto row justify-between items-center" style="height:45px;">
                    <div class="col-auto" style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('machineDetail.changeBR') }}</div>
                    <div v-show="!isEditBrHistory">
                      <q-btn outline dense round color="blue-7" padding="5px" size="sm" icon="edit" style="margin-right:12px" @click="isEditBrHistory = true">
                        <q-tooltip style="font-size:14px">{{ $t('tooltip.editJob') }}</q-tooltip>
                      </q-btn>
                    </div>
                    <div v-show="isEditBrHistory">
                      <q-btn class="col" flat dense rounded color="red-5" icon="cancel" @click="isEditBrHistory = false">
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                      </q-btn>
                      <q-btn class="col" flat dense rounded color="blue-8" icon="save" @click="saveBrCOS()">
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                  <div class="col column items-start justify-start" style="width:100%;height:100%;min-height:160px;padding:12px;border-radius:3px;"
                      :style='isEditBrHistory ? "border:1px solid #eb0028" : "border:1px solid #eeeeee"'>
                    <div class="col-auto row items-start justify-center no-wrap">
                      <span class="brStateClass" :class='brWorkState == 1 ? "brSelectedStateClass" : "brNormalStateClass"' style="color:#eb0028" 
                            :style='isEditBrHistory ? "pointer-events: initial;" : "pointer-events: none;"'
                            @click="brWorkState = 1">{{$t('TEXT_BROKENSTATE.1')}}</span>
                      <span class="brStateClass" :class='brWorkState == 2 ? "brSelectedStateClass" : "brNormalStateClass"' style="color:#ffc600" 
                            :style='isEditBrHistory ? "pointer-events: initial;" : "pointer-events: none;"'
                            @click="brWorkState = 2">{{$t('TEXT_BROKENSTATE.2')}}</span>
                      <span class="brStateClass" :class='brWorkState == 3 ? "brSelectedStateClass" : "brNormalStateClass"' style="color:#93d500" 
                            :style='isEditBrHistory ? "pointer-events: initial;" : "pointer-events: none;"'
                            @click="brWorkState = 3">{{$t('TEXT_BROKENSTATE.3')}}</span>
                      <span class="brStateClass" :class='brWorkState == 4 ? "brSelectedStateClass" : "brNormalStateClass"' style="color:#50a3d9" 
                            :style='isEditBrHistory ? "pointer-events: initial;" : "pointer-events: none;"'
                            @click="brWorkState = 4">{{$t('TEXT_BROKENSTATE.4')}}</span>
                    </div>
                    <div class="col row" style="position:relative;min-height:200px;width:100%;font-size:14px;padding:8px 0 0 0;">
                      <table class="col" style="margin:12px;">
                        <tr height="32px">
                          <td width="128px">
                            <div style="font-weight:500;">{{ $t('machineDetail.brType') }}</div>
                          </td>
                          <td>
                            <q-select dense options-dense outlined class="col" color="red-7" style="width:50%;min-width:150px;"
                                      v-model="brOccurType" :options="getBrOccurType" :readonly="!isEditBrHistory"></q-select>
                          </td>
                        </tr>
                        <tr height="32px">
                          <td>
                            <div style="font-weight:500;">{{ $t('machineDetail.resolvedDate') }}</div>
                          </td>
                          <td>
                            <div class="row justify-start items-end">
                              <div style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;width:120px">{{ brCosDate }}</div>
                                <q-btn dense flat icon="calendar_month" style="cursor:pointer;" :disable="!isEditBrHistory">
                                  <q-popup-proxy @before-show="showBrSolveDate" @hide="hideBrSolveDate" cover ref="brHistoryResolveCal">
                                    <q-date minimal no-unset color="red-7" v-model="brCosDateCal" @update:model-value="selectBrHistoryResolveCal">
                                    </q-date>
                                  </q-popup-proxy>
                                </q-btn>
                            </div>
                          </td>
                        </tr>
                        <tr height="32px">
                          <td>
                            <div style="font-weight:500;">{{ $t('machineDetail.manager') }}</div>
                          </td>
                          <td>
                            <q-input v-model="brCosManager" color="red-7" dense clearable style="width:50%;min-width:150px;" :readonly="!isEditBrHistory">
                            </q-input>
                            <!-- 수정 불가
                            <div class="row justify-start items-end" style="width:50%;min-width:150px;">
                              <div class="col" style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;width:80%">{{ brCosManager }}</div>
                            </div>
                            -->
                          </td>
                        </tr>
                        <tr>
                          <td style="align-items: start;vertical-align:top;">
                              <div class="col" style="font-weight:500;margin:0 0 0 0;">{{ $t('noticeView.context') }}</div>
                          </td>
                          <td class="column justify-center items-center">
                            <div class="col-auto" style="width:100%;height:80px;border-radius:3px;border:1px solid #aaaaaa;margin:6px 0 0 0;">
                              <q-scroll-area style="width:100%;height:100%;">
                                <q-input v-model="brCosContents" borderless autogrow dense hide-bottom-space 
                                         maxlength="100"
                                         type="textarea" style="padding:2px 3px 12px 3px;font-weight:400;" :readonly="!isEditBrHistory"></q-input>
                              </q-scroll-area>
                            </div>
                          </td>
                        </tr>
                        <tr>
                        </tr>
                      </table>
                      <div style="position: absolute;z-index: 3;left:0;top:12px;right:0;bottom:0;border-radius: 3px;background-color: #30303010;" v-show="brWorkState != 4">
                      </div>
                    </div>
                  </div>
                </div>
                <!--
                <div class="col-auto row justify-center items-end" style="height:44px;">
                  <div class="col row justify-center items-end">
                    <q-btn class="col-auto" no-caps unelevated outline dense color="grey-2" text-color="black" :label="$t('common.cancel')" style="width:80px;height:32px;margin-right:6px;"
                         @click="cancelBrCOS">
                      <q-tooltip style="font-size:14px;">작업을 취소합니다.</q-tooltip>
                    </q-btn>
                    <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.save')" style="width:80px;height:32px;margin-left:6px;" :disable="!isEditBrHistory"
                         @click="saveBrCOS()">
                      <q-tooltip style="font-size:14px;">수정한 내용을 저장합니다..</q-tooltip>
                    </q-btn>
                  </div>
                </div>
                -->
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 아랫부분 소모품 -->
    <div class="col-auto" :class='isLandscape ? "row" : "column"' style="height:auto;position:relative;min-height:120px;" :style='isLandscape ? "" : "padding:0 0 0 0"'>
      <div class="col-7 column" style="border-radius:3px;background-color:white;padding:0 12px 12px 12px;" :style='isLandscape ? "margin:0 6px 0 0;" : "margin:0 0 12px 0;"'>
        <div class="col column" style="width:100%;height:auto;">
          <!-- Consumables 소모품 목록 -->
          <div class="col-auto row justify-between items-center" style="height:45px">
            <div class="col" style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('machineDetail.manageCONS')}}</div>
            <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('machineDetail.history')" style="width:auto;padding:0 12px;" @click="showConsHistory(true)">
              <q-tooltip style="font-size:14px;">{{ $t('tooltip.loadConsHistory')}}</q-tooltip>
            </q-btn>
          </div>
          <div class="col-auto row " style="width:100%;height:auto;margin:0 0 0 -6px;min-height:180px;">
            <div class="col row" >
              <div style="width:256px;height:auto;border:1px solid #ebedf2;padding:12px;margin:6px;" v-for="(item, index) in consumableData" :key="item">
                <div style="width:100%;height:100%;font-size:11pt">
                  <div class="column">
                    <div class="col-auto row justify-between items-center">
                      <q-btn unelevated dense @click="memoClicked(index)">
                        <q-icon name="note_alt" size="sm" :color='(item == undefined) || (item.memo == undefined) || (item.memo == "") ? "grey-7" : "red-7"' />
                        <q-tooltip style="font-size:14px;">{{(item == undefined) || (item.memo == undefined) || (item.memo == "") ? $t('manage.writeMemo') : $t('manage.viewMemo') }}</q-tooltip>
                      </q-btn>
                      <q-btn unelevated dense @click="consChangeClicked(index)">
                        <q-img src="../assets/manage/consumables_replacement_i.svg" width="16px" fit="fill"/>
                      </q-btn>
                    </div>
                    <q-img :src="consImages[item.icon]" width="50px" height="50px" fit="fill"/>
                    <div style="font-size:16px;font-weight: 500;color:#212529">{{getCosumableName(index)}}</div>
                    <div class="col row items-center justify-between no-wrap" >
                      <!--
                      <q-linear-progress buffer="1.0" stripe rounded size="11pt" :value="item.value / 100" color="red-3" class="col"/>
                      -->
                      <ProgressBar :progress="item.guage"></ProgressBar>
                      <div class="col-auto" style="font-size:14px;">{{item.valuePeriod}}/{{item.valueFirst}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto column" style="position: absolute;left:12px;top:0;right:12px;bottom:12px;z-index: 2;background-color: white;" v-show="isVisibleCsHistory">
        <!-- Consumables History 교체 이력  -->
        <div class="col-auto row justify-between items-center" style="height:45px;">
          <div class="col-auto" style="font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">{{ $t('machineDetail.replHistory') }}</div>
          <div class="col-auto row items-center justify-center" >
            <div class="col-auto row items-center justify-center" style="margin-bottom:0;">
              <div class="col-auto row items-center justify-center no-wrap">
                <div class="col row items-center" style="font-size:14px;width:auto;">
                  <div style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;">[&nbsp;{{ (csHistoryStart != '' && csHistoryStart != undefined) ? csHistoryStart : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}}&nbsp;]</div>
                  <q-btn dense flat icon="calendar_month" style="cursor:pointer;">
                    <q-popup-proxy cover ref="csHistoryStartCal" @before-show="popupDateSelect('consHistoryStart')">
                      <q-date minimal no-unset color="red-7" v-model="csHistoryDateCal" @update:model-value="afterDateSelect('consHistoryStart')">
                      </q-date>
                    </q-popup-proxy>
                  </q-btn>
                </div>
              </div>
              <div class="col-auto row items-center justify-center">
                <div class="col">~&nbsp;</div>
              </div>
              <div class="col-auto row items-center justify-center no-wrap">
                <div class="col row items-center" style="font-size:14px;width:auto;height:auto;">
                  <div style="border-color:#bbbbbb;border-width:0 0 1px 0;border-style:solid;">[&nbsp;{{ (csHistoryEnd != '' && csHistoryEnd != undefined) ? csHistoryEnd : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}}&nbsp;]</div>
                  <q-btn dense flat icon="calendar_month" style="cursor:pointer;">
                    <q-popup-proxy cover ref="csHistoryEndCal" @before-show="popupDateSelect('consHistoryEnd')">
                      <q-date minimal no-unset color="red-7" v-model="csHistoryDateCal" @update:model-value="afterDateSelect('consHistoryEnd')">
                      </q-date>
                    </q-popup-proxy>
                  </q-btn>
                </div>
              </div>
              <div class="col-auto row items-center">
                <!-- Landscape, Portrait 에 따라 텍스트버튼, 아이콘 버튼으로 변경-->
                <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')" style="width:80px;height:32px" @click="refreshCsHistory()" v-show="isLandscape">
                </q-btn>
                <q-btn class="col-auto" no-caps unelevated dense color="grey-6" text-color="white" icon="search" style="width:32px;" @click="refreshCsHistory()" v-show="!isLandscape">
                </q-btn>
              </div>
            </div>
          </div>

          <div class="col-auto justify-end">
            <q-btn flat dense @click="downloadToExcel('consumables')" :disable="isEditConsumable" v-show="canDownloadToExcel">
              <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
              <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;"/>
              <q-popup-proxy ref="excelPopup" style="width:auto;height:220px;background-color: #01010130;border-radius: 3px;position:relative;"
                              @before-show="onShowExportToExcel">
                <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                  <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;min-height:160px;border-radius: 3px;padding:12px 24px;">
                    <div class="col-auto row items-center justify-center">
                      <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'" style="font-size:18px;margin:0 0 12px 0;">
                        {{ $t('common.toExcel') }}
                      </div>
                    </div>
                    <div class="col row items-center justify-start" style="width:100%;margin:0;">
                      <table class="col" style="width:100%;margin:0;">
                        <tr style="">
                          <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.filename') }}</td>
                          <td width="12px" style=""></td>
                          <td style="">{{ excelFilename }}</td>
                        </tr>
                        <tr>
                          <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.from') }}</td>
                          <td width="12px"></td>
                          <td>{{ excelFrom }}</td>
                        </tr>
                        <tr>
                          <td width=25% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.to') }}</td>
                          <td width="12px"></td>
                          <td>{{ excelTo }}</td>
                        </tr>
                      </table>
                    </div>
                    <xlsx-workbook class="col-auto row items-center justify-center">
                      <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                      <xlsx-download :filename="excelFilename">
                        <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveCsHistoryToExcelFile">{{ $t('common.download') }}</button>
                      </xlsx-download>
                    </xlsx-workbook>
                  </div>

                  <div class="col row items-center justify-center" v-show="!hasExcelData" style="width:100%;height:100%;position:absolute;left:0;top:0;right:0;bottom:0;background-color:white;">
                    <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'" style="font-size:18px;color:#eb0028">{{ $t('warning.nodata')}}</div>
                  </div>
                  
                </div>
                <div class="row items-center justify-center" v-show="makingExcel"
                    style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                  <q-spinner color="red-7" size="70px" thickness="1.5"/>
                </div>
              </q-popup-proxy>
            </q-btn>
            <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" icon="close" style="width:auto;" @click="isVisibleCsHistory = false">
            </q-btn>
          </div>
        </div>
        
        <div class="col row " style="width:100%;height:auto;">
          <div class="col row" style="position: relative;">
            <div class="col row items-center justify-center" style="border:1px solid #eeeeee" v-show="csHistoryRows.length < 1">
              <div class="col-auto" style="font-size:18px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                {{ $t('manage.noConsHistory')}}
              </div>
            </div>
            <q-table dense flat bordered v-show="csHistoryRows.length > 0"
                      separator="none"
                      row-key="index"
                      virtual-scroll
                      hide-no-data
                      style="font-size:14px;position:absolute;left:0;right:0;top:0;bottom:0;"
                      table-header-style="position:sticky;top:0;z-index:1;background-color:white;"
                      v-model:pagination="csHistoryPage"
                      :visible-columns="visibleCsHistoryColumns"
                      :columns="csHistoryColumns"
                      :rows="csHistoryRows"
                      @update:pagination="updateCsPagination">
              <template v-slot:header="props">
                <q-th no-caps
                      class="tableHeader"
                      v-for="col in props.cols"
                      :key="col.name"
                      style=""
                      :style='col.name!="status" ? "border-color: #eeeeee;border-width: 0 1px 1px 0;" : "border-color: #eeeeee;border-width: 0 0 1px 0;"'
                      :props="props">
                  <span class="col">
                    <span class="col-auto text-center">{{ col.label }}</span>
                  </span>
                </q-th>
              </template>

              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td key="occurTime" :props="props" style="width:110px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="csHistoryColumnClicked(props.row, props.row.index, 0)">
                    <div class="row items-center justify-start no-wrap">
                      <div style="font-size:14px">
                        {{ props.row.occurTime }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="opTimeOcc" :props="props" style="width:90px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="csHistoryColumnClicked(props.row, props.row.index, 1)">
                    <div class="row items-center justify-start no-wrap">
                      <div style="font-size:14px">
                        {{ props.row.opTimeOcc }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="replaceTime" :props="props" style="width:110px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="csHistoryColumnClicked(props.row, props.row.index, 2)">
                    <div class="row items-center justify-start no-wrap">
                      <div style="font-size:14px">
                        {{ props.row.replaceTime }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="opTimeRepl" :props="props" style="width:90px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="csHistoryColumnClicked(props.row, props.row.index, 3)">
                    <div class="row items-center justify-start no-wrap">
                      <div style="font-size:14px">
                        {{ props.row.opTimeRepl }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="category" :props="props" style="width:90px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="csHistoryColumnClicked(props.row, props.row.index, 4)">
                    <div class="row items-center justify-start no-wrap">
                      <div style="font-size:14px">
                        {{ props.row.category }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="partname" :props="props" style="width:90px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="csHistoryColumnClicked(props.row, props.row.index, 4)">
                    <div class="row items-center justify-start no-wrap">
                      <div style="font-size:14px">
                        {{ props.row.partname }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="partnumber" :props="props" style="width:90px;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;" @click="csHistoryColumnClicked(props.row, props.row.index, 4)">
                    <div class="row items-center justify-start no-wrap">
                      <div style="font-size:14px">
                        {{ props.row.partnumber }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="context" :props="props" style="width:25%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;"
                        @click="csHistoryColumnClicked(props.row, props.row.index, 5)">
                    <div style="font-size:14px;">
                      {{ props.row.context }}
                    </div>
                  </q-td>
                  <q-td key="resolver" :props="props" style="width:13%;border-color: #eeeeee;border-width: 1px 1px 1px 0;cursor: pointer;"
                        @click="csHistoryColumnClicked(props.row, props.row.index, 6)">
                    <div style="font-size:14px;">
                      {{ props.row.resolver }}
                    </div>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:bottom>
                <div class="row items-center justify-end" style="width:100%;">
                  <div>{{ csHistoryRows.length }}</div>
                  <!--
                  <q-pagination class="col-auto"
                                  v-model="csHistoryPagination"
                                  :max="cwHistoryPageCount"
                                  :max-pages='isLandscape ? "6" : "4"'
                                  outline dense
                                  direction-links
                                  boundary-links
                                  gutter="5px"
                                  size="sm"
                                  color="grey-7"
                                  active-design="unelevated"
                                  active-color="red-7"
                                  active-text-color="white"
                                  @update:model-value="emHistoryPageChanged">
                  </q-pagination>
                  -->
                </div>
              </template>
            </q-table>
          </div>
        </div>
      </div>

      <div class="col column" style="width:100%;border-radius:3px;background-color:white;padding:0 12px 12px 12px;"
           :style='isLandscape ? "margin:0 0 0 6px;" : "margin:0 0 12px 0;"'>
        <div class="col column" v-show="viewComsumableOption == 1">
          <!-- 소모품 메모 -->
          <div class="col-auto justify-between items-center">
            <div class="row items-center justify-center" style="height:45px;font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
              <div class="col">{{ $t('machineDetail.memo').toUpperCase() }}</div>
              <q-btn class="col-auto" no-caps unelevated dense color="red-7" icon="close" style="width:auto;" @click="closeConsMemo">
              </q-btn>
            </div>
          </div>
          <div class="col column" style="height:100%;">
            <div class="col-auto items-center justify-center" :class='consMemoClass' style="width:100%;height:100%">
              <div class="col-auto row justify-center items-start" :class="consMemoCalClass" :style='isEditConsMemo ? "border:1px solid #eb0028;" : "border:1px solid #eeeeee;"'>
                <q-date no-unset flat minimal borderless color="red-7" :disable="!isEditConsMemo" v-model="consMemoDate" @update:model-value="changeConsMemoDate"/>
              </div>

              <div class="col column justify-start" :class="consMemoViewClass" :style='isEditConsMemo ? "border:1px solid #eb0028;" : "border:1px solid #eeeeee;"'>
                <div class="col-auto row justify-between items-center" style="height:42px;border-width: 0 0 1px 0; border-style: solid;border-color:#eeeeee">
                  <div class="col-auto" style="font-size:14px;color:#eb0028;font-weight: 600;padding:0 10px;">{{ $t('machineDetail.memo').toUpperCase() }}</div>
                  <div class="col row justify-end items-center no-wrap">
                    <div v-show="!isEditConsMemo">
                      <q-btn outline dense round color="blue-7" padding="5px" size="sm" icon="edit" style="margin-right:12px" @click="editConsMemo">
                        <q-tooltip style="font-size:14px">{{ $t('tooltip.editJob') }}</q-tooltip>
                      </q-btn>
                      <q-btn outline dense round color="blue-7" padding="5px" size="sm" icon="delete"  @click="deleteConsMemo">
                        <q-tooltip style="font-size:14px">{{ $t('tooltip.deleteMemo') }}</q-tooltip>
                      </q-btn>
                    </div>
                    <div v-show="isEditConsMemo">
                      <q-btn class="col" flat dense rounded color="red-5" icon="cancel" @click="isEditConsMemo = false">
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                      </q-btn>
                      <q-btn class="col" flat dense rounded color="blue-8" icon="save" @click="saveConsMemo()">
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                </div>
                <div class="col column" style="font-size:14px;padding:12px;">
                  <div class="col-auto" style="font-size:14px;color:black;font-weight: 500;margin:6px;">{{consumableContent}}</div>
                  <div class="col row" style="border:1px solid #eeeeee;border-radius:3px;">
                    <div class="col row" :style='isEditConsMemo ? "background-color:#cccccc10" : "background-color:white;"' style="padding:0 6px;">
                       <q-input v-model="consInputMemo" class="col" color="red-7" dense type="textarea" style="width:100%;height:100%;"
                                maxlength="100"
                                autofocus borderless :readonly="!isEditConsMemo"></q-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <q-resize-observer @resize="onResizeEditMemo"/>
        </div>

        <div class="col column" v-show="viewComsumableOption == 2">
          <!-- Replacement 소모품 교체 화면 -->
          <div class="col-auto justify-between items-center">
            <div class="row items-center justify-center" style="height:45px">
              <div class="col"
                   :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'"
                   style="font-size:16px;">
                {{ $t('machineDetail.replacement') }}
              </div>
              <q-btn class="col-auto" no-caps unelevated dense color="red-7" icon="close" style="width:auto;" @click="closeConsMemo">
              </q-btn>
            </div>
          </div>
          <div class="col row items-start justify-start" style="border-radius: 3px;padding:12px;" :style='isChangeCons ? "border:1px solid #eb0028;" : "border:1px solid #eeeeee;"'>
            <div class="col column" style="width:100%;height:100%;">
              <div class="col-auto row items-center justify-between" style="width:100%;margin:0 0 12px 0;">
                <div class="col-auto row items-center">
                  <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'"
                        style="font-size:16px;">{{ consumableTitle }}</div>
                </div>
                <div class="col row justify-end items-center no-wrap">
                  <div class="col-auto row" v-show="!isChangeCons">
                    <q-btn class="col-auto" outline dense round color="blue-7" padding="5px" size="sm" icon="edit" @click="editConsChange">
                      <q-tooltip style="font-size:14px">{{ $t('tooltip.editJob') }}</q-tooltip>
                    </q-btn>
                  </div>
                  <div class="col-auto row" v-show="isChangeCons">
                    <q-btn class="col" flat dense rounded color="red-5" icon="cancel" @click="isChangeCons = false">
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                    </q-btn>
                    <q-btn class="col" flat dense rounded color="blue-8" icon="save" @click="saveConsChange()">
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                    </q-btn>
                  </div>
                </div>
              </div>

              <div class="col-auto row items-center items-start"  style="height:42px;">
                <!-- 교체일자 -->
                <div class="col-3 items-center justify-start row">
                  <div class="col-auto" style="font-size:14px;font-weight:500;">{{ $t('machineDetail.replaceDate') }}</div>
                </div>
                <div class="col items-center justify-between row" style="min-width:120px;border-bottom:1px solid #eeeeee">
                  <div class="col-auto">{{ consReplDate }}</div>
                  <q-btn flat dense icon="event" color="gray-4" v-show="isChangeCons">
                    <q-popup-proxy ref="consReplDatePopup" @before-show="replaceDateToPopup" cover transition-show="scale" transition-hide="scale">
                      <div class="row q-gutter-sm" style="padding:3px;">
                        <q-date no-unset flat bordered today-btn color="red-7" v-model="consReplDateCal" @update:model-value="updateConsReplDate" :title="consReplTitle" :subtitle="consumableTitle"></q-date>
                        <q-time now-btn flat bordered color="red-7" v-model="consReplTimeCal" @update:model-value="updateConsReplTime"></q-time>
                      </div>
                      <div class="row q-gutter-sm justify-end items-end" style="margin-right:12px">
                        <q-btn class="col-auto" flat dense rounded color="red-5" icon="close" @click="closeConsReplDateTime">
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                        </q-btn>
                        <q-btn class="col-auto" flat dense rounded color="blue-8" icon="done" @click="applyConsReplDateTime">
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.applyJob') }}</q-tooltip>
                        </q-btn>
                      </div>
                    </q-popup-proxy>
                  </q-btn>
                </div>
              </div>

              <div class="col-auto row items-center items-start">
                <!-- 담당자 -->
                <div class="col-3 items-center justify-start row">
                  <div style="font-size:14px;font-weight:500;">{{ $t('machineDetail.manager')}}</div>
                </div>
                <q-input class="col" color="red-7" dense :readonly="!isChangeCons" v-model="consReplManager"></q-input>
              </div>

              <div class="col-auto row items-start items-start" style="margin: 12px 0;">
                <!-- 메모 -->
                <div class="col-3 items-start justify-start row" >
                  <div style="font-size:14px;font-weight:500;">{{ $t('noticeView.context') }}</div>
                </div>
                <div class="col row items-start"
                      style="width:100%;height:100%;min-height:48px;border:1px solid #dddddd;border-radius:3px;padding:3px;position: relative;"
                      :style='isChangeCons ? "background-color:#eeeeee" : "background-color:white;"' >
                  <q-scroll-area style="position:absolute;width:100%;height:100%;">
                    <q-input :borderless="!isChangeCons" :readonly="!isChangeCons" autogrow dense color="red-7"
                              v-model="consReplContents" style="font-size:14px;height:100%;" type="textarea" />                        
                  </q-scroll-area>
                </div>
              </div>

              <div class="col row items-start items-start">
                <!-- 부품명 -->
                <div class="col-3 row items-start justify-start">
                  <div class="col-auto" style="font-size:14px;font-weight:500;">{{ $t('machineDetail.replaceParts') }}</div>
                  <div class="col-auto" style="font-family:Prometo;font-size:14px;font-weight:500;" v-show="consParts.length > 1">&nbsp;[&nbsp;{{ consParts.length }}&nbsp;]</div>
                </div>
                <div class="col column items-start" style="width:100%;height:100%;min-height:64px;">
                  <div class="col column items-start" style="position: relative;width:100%;height:100%;">
                    <div class="col-auto row" v-for="(part, idx) in consParts" :key="part.Name" style="width:100%;">
                      <div class="col column" :style="idx % 2 == 0 ? 'margin:0 6px;' : 'margin:12px 6px;'">
                        <div class="col row">
                          <div style="font-size:14px;font-weight:500;" v-show="consParts.length > 1">{{ idx + 1 }}.&nbsp;</div>
                          <div style="font-size:14px;font-weight:500;">{{ part.Name }}</div>
                        </div>
                        <q-select class="col-auto" dense options-dense color="red-7"
                            style="min-width:160px;margin:0 0 0 12px;"
                            v-show="isChangeCons"
                            :readonly="!isChangeCons"
                            v-model="part.SelectNo" :options="part.PartNo"></q-select>
                      </div>
                    </div>
                      <!--
                    <q-scroll-area style="position: absolute;width:100%;height:100%;border:1px solid #dddddd;">
                    </q-scroll-area>
                    -->
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.mainBorder {
  height:auto;
  background-color:#f2f2f2;
  overflow-y: hidden;
  overflow-x:hidden;
}

.tableSize {
  width:100%;
  height:auto;
}

.progress{
  position:relative;
  width: 0;
  height: 0;
  border-bottom: 16px solid #666666;
  border-left: 3px solid transparent;
  border-right: 0 solid transparent;
}
.progress-bar{
  position:absoulte;
  left:5px;
  top:0;
  width: 0px;
  height: 0px;
  border-bottom: 16px solid white;
  border-left: 3px solid transparent;
  border-right: 3px solid white;
}

.tableHeader {
  background-color: white;
  font-size:14px;
}

.brStateClass {
  font-size:14px;
  font-weight: 500;
  padding:0 16px;
  border:1px solid #eeeeee;
  cursor:pointer;
  height:32px;
  line-height: 32px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;  
}

.brNormalStateClass {
  background-color: #f3f3f3;
}
.brSelectedStateClass {
  background-color: white;
}
.memoCalWidth100 {
  width:100%;
  border-radius:3px;
  border:1px solid #eeeeee;
  margin-right:0;
  margin-bottom:12px;
  height:auto;
}
.memoCalWidthAuto {
  width:auto;
  border-radius:3px;
  border:1px solid #eeeeee;
  margin-right:12px;
  margin-bottom:0;
  height:100%;
}

.memoViewWidth100 {
  width:100%;
  border-radius:3px;
  margin-right:0;
  margin-bottom:0px;
  height:auto;
}
.memoViewWidthAuto {
  width:auto;
  border-radius:3px;
  margin:0 0 0 0;
  height:100%;
}

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'
import { useI18n } from 'vue-i18n'
import TymAws from '@/js/tymaws.js'
import TymCommon from '@/js/tymcommon'
import TymConst from '@/js/tymconstants.js'
import ProgressBar from '@/components/ProgressBar.vue'
import MapView from '../views/MapView.vue'
import moment from 'moment-timezone'
import { XlsxWorkbook, XlsxSheet, XlsxDownload  } from 'vue3-xlsx'
import AWS from 'aws-sdk'

export default ({
  props : {
    info : Object
  },
  components : {
    ProgressBar,
    MapView,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data() {
    return {
    }
  },
  setup() {
    const consumableDate = ref('2023/01/01')
    const proxyDate = ref('2023/01/01')
    const i18n = useI18n()

    return {
      emCount : ref(0),
      emCurPage : ref(0),
      emItemsPerPage : ref(0),
      emSelectedRows : ref([]),
      brCount : ref(0),
      brCurPage : ref(0),
      brItemsPerPage : ref(0),
      brSelectedRows : ref([]),
      consumableData : ref([]),

      EmergencyTypeString :ref([
        i18n.t('constants.emergency_type1'),
        i18n.t('constants.emergency_type2'),
        i18n.t('constants.emergency_type3'),
        i18n.t('constants.emergency_type4'),
        i18n.t('constants.emergency_type5')
      ]),
      visibleColumns: ref([ 'firstTime', 'lastTime', 'type','status']),
      emColumns : ref([
        { name: 'index', required: false },
        { name: 'firstTime',
          required: true,
          label: i18n.t('TABLE_COLUMNS.occDate'),
          align: 'left',
          field: 'firstTime',
          format: val => `${val}`,
          sortable: true
        },
        /*
        { name: 'lastTime',
          required: true,
          label: 'Last Time',
          align: 'left',
          field: 'lastTime',
          format: val => `${val}`,
          sortable: true
        },
        */
        {
          name: 'type',
          label: i18n.t('TABLE_COLUMNS.emergencyContext'),
          align: 'left',
          field: 'type',
          sortable: true
        },
        {
          name: 'status',
          label: i18n.t('TABLE_COLUMNS.status'),
          align: 'left',
          field: 'status'
        }
      ]),
      emRows : ref([]),

      visibleEmHistoryColumns: ref([ 'occurTime', 'resolveTime', 'type', 'resolver', 'resolveMsg']),
      emHistoryColumns : ref([
        { name: 'index', required: false },
        { name: 'occurTime',
          required: true,
          label: i18n.t('TABLE_COLUMNS.occDate'),
          align: 'left',
          field: 'occurTime',
          format: val => `${val}`,
          sortable: true
        },
        { name: 'resolveTime',
          required: true,
          label: i18n.t('TABLE_COLUMNS.resolveDate'),
          align: 'left',
          field: 'resolveTime',
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'type',
          label: i18n.t('TABLE_COLUMNS.emergencyContext'),
          align: 'left',
          field: 'type',
          sortable: true
        },
        {
          name: 'resolver',
          label: i18n.t('TABLE_COLUMNS.manager'),
          align: 'left',
          field: 'resolver',
          sortable: true
        },
        {
          name: 'resolveMsg',
          label: i18n.t('TABLE_COLUMNS.resolveContext'),
          align: 'left',
          field: 'resolveMsg'
        }
      ]),
      emHistoryRows : ref([]),
      visibleBrColumns: ref([ 'firstTime', 'lastTime', 'type', 'manual', 'status']),
      brColumns : ref([
        { name: 'index', required: false },
        { name: 'firstTime',
          required: true,
          label: i18n.t('TABLE_COLUMNS.firstOccurDate'),
          align: 'left',
          field: 'firstTime',
          format: val => `${val}`,
          sortable: true
        },
        { name: 'lastTime',
          required: true,
          label: i18n.t('TABLE_COLUMNS.lastOccurDate'),
          align: 'left',
          field: 'lastTime',
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'type',
          label: i18n.t('TABLE_COLUMNS.errorMsg'),
          align: 'left',
          field: 'type',
          sortable: true
        },
        {
          name: 'manual',
          label: i18n.t('TABLE_COLUMNS.manual'),
          align: 'center',
          field: 'manual',
          sortable: false
        },
        {
          name: 'status',
          label: i18n.t('TABLE_COLUMNS.status'),
          align: 'left',
          field: 'status'
        }
      ]),
      brRows : ref([]),
      visibleBrHistoryColumns: ref([ 'occurTime', 'resolveTime', 'erCode', 'manual', 'erMsg', 'resolver', 'resolveMsg']),
      brHistoryColumns : ref([
        { name: 'index', required: false },
        { name: 'occurTime',
          required: true,
          label: i18n.t('TABLE_COLUMNS.occDate'),
          align: 'left',
          field: 'occurTime',
          format: val => `${val}`,
          sortable: true
        },
        { name: 'resolveTime',
          required: true,
          label: i18n.t('TABLE_COLUMNS.resolveDate'),
          align: 'left',
          field: 'resolveTime',
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'erCode',
          label: i18n.t('TABLE_COLUMNS.errorCode'),
          align: 'left',
          field: 'erCode',
          sortable: true
        },
        {
          name: 'manual',
          label: i18n.t('TABLE_COLUMNS.manual'),
          align: 'center',
          field: 'manual',
          sortable: false
        },
        {
          name: 'erMsg',
          label: i18n.t('TABLE_COLUMNS.errorMsg'),
          align: 'left',
          field: 'erMsg',
          sortable: true
        },
        {
          name: 'resolver',
          label: i18n.t('TABLE_COLUMNS.manager'),
          align: 'left',
          field: 'resolver',
          sortable: true
        },
        {
          name: 'resolveMsg',
          label: i18n.t('TABLE_COLUMNS.resolveContext'),
          align: 'left',
          field: 'resolveMsg'
        }
      ]),
      brHistoryRows : ref([]),

      visibleCsHistoryColumns: ref([ 'occurTime', 'opTimeOcc', 'replaceTime', 'opTimeRepl', 'category', 'partname', 'partnumber', 'context', 'resolver']),
      csHistoryColumns : ref([
        { name: 'index', required: false },
        { name: 'occurTime',
          required: true,
          label: i18n.t('TABLE_COLUMNS.occDate'),
          align: 'left',
          field: 'occurTime',
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'opTimeOcc',
          label: i18n.t('TABLE_COLUMNS.usageOccurrence'),
          align: 'left',
          field: 'opTimeOcc',
          sortable: true
        },
        { name: 'replaceTime',
          required: true,
          label: i18n.t('TABLE_COLUMNS.replaceDate'),
          align: 'left',
          field: 'replaceTime',
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'opTimeRepl',
          label: i18n.t('TABLE_COLUMNS.usageReplace'),
          align: 'left',
          field: 'opTimeRepl',
          sortable: true
        },
        {
          name: 'category',
          label: i18n.t('TABLE_COLUMNS.category'),
          align: 'left',
          field: 'category',
          sortable: true
        },
        {
          name: 'partname',
          label: i18n.t('TABLE_COLUMNS.partname'),
          align: 'left',
          field: 'partname',
          sortable: true
        },
        {
          name: 'partnumber',
          label: i18n.t('TABLE_COLUMNS.partnumber'),
          align: 'left',
          field: 'partnumber',
          sortable: true
        },
        {
          name: 'context',
          label: i18n.t('TABLE_COLUMNS.context'),
          align: 'left',
          field: 'context',
          sortable: true
        },
        {
          name: 'resolver',
          label: i18n.t('TABLE_COLUMNS.manager'),
          align: 'left',
          field: 'resolver',
          sortable: true
        },
      ]),
      csHistoryRows : ref([]),

      brStateColors : ref(['#eb0028', '#ffc600', '#93d500']),    // 검정, 적/황/녹 -> Unknown, 발생/신고/입고
      viewEmerErr : ref(0),
      viewComsumableOption : ref(0),
      consumableIndex : ref(-1),
      consumableTitle : ref(''),
      consumableManger : ref(''),
      consumableContent : ref(''),
      consumableDate,
      proxyDate,
      showEmergencyHistory : ref(false),
      showErrorHistory : ref(false),
      machineInfo : ref(null),
      machineAddress : ref(''),

      isVisibleLoca : ref(false),
      hasOccLoca : ref(true),            // 긴급 -> 위치정보 유무

      oneBrokenData : ref([ {}, {}, {}]),

      emHistoryStart : ref(''),
      emHistoryEnd : ref(''),
      emHistoryDateCal : ref(''),
      emHistoryPagination : ref(1),
      emHistoryPage : ref({
        rowsPerPage: 7,
        sortBy: 'time',
        descending: 'true',
        page : 1
      }),
      emHistoryPageCount : ref(0),

      brHistoryStart : ref(''),
      brHistoryEnd : ref(''),
      brHistoryDateCal : ref(''),
      brHistoryPagination : ref(1),
      brHistoryPage : ref({
        rowsPerPage: 7,
        sortBy: 'time',
        descending: 'true',
        page : 1
      }),
      brHistoryPageCount : ref(0),

      isVisibleEmCOS : ref(false),
      isEmCosResolved : ref(false),
      isRealOccur : ref(true),
      isEditEmHistory : ref(false),
      emCosType : ref(''),
      emCosManager : ref(''),
      emCosDate : ref(''),
      emCosDateCal : ref(''),
      emCosContents : ref(''),

      isVisibleBrCOS : ref(false),
      isEditBrHistory : ref(false),
      brWorkState : ref(0),
      brOccurType : ref(''),
      brCosManager : ref(''),
      brCosDate : ref(''),
      brCosDateCal : ref(''),
      brCosContents : ref(''),

      csHistoryStart : ref(''),
      csHistoryEnd : ref(''),
      csHistoryDateCal : ref(''),
      csHistoryPagination : ref(1),
      csHistoryPage : ref({
        rowsPerPage: 0,
        sortBy: 'time',
        descending: 'true',
        page : 1
      }),
      cwHistoryPageCount : ref(0),

      consMemoClass : ref('row'),
      consMemoCalClass : ref('memoCalWidth100'),
      consMemoViewClass : ref('memoViewWidth100'),

      isVisibleCsHistory : ref(false),
      isEditConsMemo : ref(false),
      consMemoDate : ref(''),
      editConsMemoDate : ref(''),
      consInputMemo : ref(''),
      selectedConsumable : ref(0),

      isChangeCons : ref(false),
      consReplTitle : ref(''),
      curConsumableType : ref(''),
      consReplManager : ref(''),
      consReplDate : ref(''),
      consReplDateCal : ref(''),
      consReplContents : ref(''),

      sheets: ref([]),

      excelData : ref([]),
      excelFilename : ref(''),
      excelFrom : ref(''),
      excelTo : ref(''),
      hasExcelData : ref(true), 
      makingExcel : ref(false),

      consParts : ref([]),
      modelConsData : ref(null),

      hasEmSearched: ref(false),
      hasBrSearched: ref(false),
      hasConsSearched: ref(false)
    }
  },
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    getConsumableTypes() {
      const i18n = useI18n()

      return [
        i18n.t('changeMethod.1'),
        i18n.t('changeMethod.2'),
        i18n.t('changeMethod.3'),
        i18n.t('changeMethod.4'),
      ]
    },
    getBrOccurType() {
      const i18n=useI18n()
      return [
        i18n.t('TEXT_BROKENTYPE.1'),
        i18n.t('TEXT_BROKENTYPE.2'),
        i18n.t('TEXT_BROKENTYPE.3'),
        i18n.t('TEXT_BROKENTYPE.4'),
        i18n.t('TEXT_BROKENTYPE.5')
      ]
    },
    getCosumableName() {
      return (idx) => {
        if(this.consumableData.length > 0)
          return this.consumableData[idx].title
        return ''
      }
    },
    consImages() {
      return TymConst.CONSUMABLE_ICONS
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return store.canDownloadToExcel()
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.updateData(this.info)
    }, 5)
  }, 
  unmounted() {},
  methods : {
    unusedParam() {
      //
    },
    /// Pagination 버튼 비활성화 여부 판단 함수
    /// idx : 0 - 응급알림, 1 - 고장알림
    /// Param : isPrev - 이전버튼이냐(true)
    getBtnState(idx, isPrev) {
      switch(idx) {
        case 0:
          if(isPrev) {
            return (this.emCount <= 0) || (this.emCurPage <= 0)
          } else {
            return (this.emCount <= 0) || ((this.emCurPage + 1) * this.emItemsPerPage > this.emCount)
          }
        case 1:
          if(isPrev) {
            return (this.brCount <= 0) || (this.brCurPage <= 0)
          } else {
            return (this.brCount <= 0) || ((this.brCurPage + 1) * this.brItemsPerPage > this.brCount)
          }
      }
    },
    getLastItem(idx) {
      switch(idx) {
        case 0: // 응급 알림
          if(((this.emCurPage + 1) * this.emItemsPerPage) >= this.emCount)
            return this.emCount;
          else
            return (this.emCurPage + 1) * this.emItemsPerPage
        case 1: // 고장 알림
         if(((this.brCurPage + 1) * this.brItemsPerPage) >= this.brCount)
            return this.brCount;
          else
            return (this.brCurPage + 1) * this.brItemsPerPage
      }
    },
    viewPrevious(idx) {
      switch(idx) {
        case 0: // 응급 알림
          if(this.emCurPage > 0) {
            this.emCurPage--
            this.$refs.emTable.setPagination({
              page : this.emCurPage + 1,
              rowsPerPage : 0 // this.emItemsPerPage
            })
          }
          break;
        case 1: // 고장 알림
          if(this.brCurPage > 0) {
            this.brCurPage--
            this.$refs.brTable.setPagination({
              page : this.brCurPage + 1,
              rowsPerPage : this.brItemsPerPage
            })
          }
          break;
      }
    },
    viewNext(idx) {
      switch(idx) {
        case 0: // 응급 알림
          if(((this.emCurPage + 1) * this.emItemsPerPage) < (this.emCount - 1)) {
            this.emCurPage++
            this.$refs.emTable.setPagination({
              page : this.emCurPage + 1,
              rowsPerPage : this.emItemsPerPage
            })
          }
          break;
        case 1: // 고장 알림
          if(((this.brCurPage + 1) * this.brItemsPerPage) < (this.brCount)) {
            this.brCurPage++
            this.$refs.brTable.setPagination({
              page : this.brCurPage + 1,
              rowsPerPage : this.brItemsPerPage
            })
          }
          console.log('viewNext :', this.brCurPage, this.brItemsPerPage, this.brCount)
          break;
        }
    },

    emTableClicked(evt, row, index) {
      console.log('emTableClicked : ', evt, row, index)
    },
    consumableDateToPopup () {
      this.proxyDate = this.consumableDate
    },
    popupToConsumableDate () {
      this.consumableDate = this.proxyDate
    },
    showEmHistory(showHistory) {
      this.showEmergencyHistory = showHistory
      this.showErrorHistory = false

      this.viewEmerErr = 0  // 보기모드 초기화
      this.isVisibleLoca = false
      this.isVisibleEmCOS = false
      this.isEditEmHistory = false
      this.isVisibleEmCOS = false

      if(showHistory) {
        this.emHistoryDateCal = null
        setTimeout(() => {
          this.refreshEmHistory()
        }, 5)
      } else {
        this.emHistoryRows = []
        //this.emRows = []
      }
    },
    showBrHistory(showHistory) {
      this.showErrorHistory = showHistory
      this.showEmergencyHistory = false

      this.viewEmerErr = 0  // 보기모드 초기화
      this.isVisibleLoca = false
      this.isVisibleEmCOS = false;
      this.isEditEmHistory = false
      this.isVisibleBrCOS = false

      if(showHistory) {
        setTimeout(() => {
          this.refreshBrHistory()
        }, 5)
      } else {
        this.brHistoryRows = []
        //this.brRows = []
      }
    },
    showCsHistory(showHistory) {
      this.isVisibleCsHistory = showHistory

      if(showHistory) {
        setTimeout(() => {
          this.refreshCsHistory()
        }, 5)
      } else {
        this.csHistoryRows = []
      }
    },

    setFirstTerm() {
      let now = new Date()
      let lastDay = new Date()
      lastDay = new Date(lastDay.setDate(lastDay.getDate() - 1))
      this.emHistoryEnd = TymCommon.getDateString(lastDay)
      lastDay = new Date(now.setFullYear(now.getFullYear() - 1))
      this.emHistoryStart = TymCommon.getDateString(lastDay)

      this.brHistoryStart = this.emHistoryStart
      this.brHistoryEnd = this.emHistoryEnd

      this.csHistoryStart = this.emHistoryStart
      this.csHistoryEnd = this.emHistoryEnd
    },
    // 선택한 기대의 응급, 고장, 소모품 알림 가져온다.
    updateData(obj) {
      this.setFirstTerm()

      this.showEmHistory(false)
      this.showBrHistory(false)
      this.showCsHistory(false)

      if(TymCommon.isEmpty(obj)) {
        console.log('machineManage.empty param')
        return
      }
      // console.log('machineManage param', obj)

      const store = useTymictStore()

      let sale = store.SalesData.data.find(x => x.machineNo == obj.machineNo)
      if(sale) {
        let modelInData = null
        if(TymCommon.isEmpty(sale.Info)) {
          let fullInfo = TymCommon.getModelFullname(sale.MON)
          modelInData = store.ModelData.data.find(x => x.model == fullInfo.Model && x.option == fullInfo.Option)
        } else {
          modelInData = store.ModelData.data.find(x => x.model == sale.Info.Model && x.option == sale.Info.Option)
        }
        if(TymCommon.isEmpty(modelInData)) {
          TymCommon.Toast(this.$t('warning.noModelInfo'))
        }
      }
      
      this.emSelectedRows = []

      this.machineInfo = obj
      if(this.machineInfo) {
        setTimeout( () => {
          if(!TymCommon.isEmpty(this.machineInfo.machineNo)) {
            TymAws.getEmergencyAlertByMN(this.machineInfo.machineNo)
            .then(data => {
              this.emRows = []
              let i = 0, idx = 0

              if(TymConst.IS_DEVELOPMENT) {
                console.log('tabManage.updateData.emergency :', data)
              }

              for(i = 0; i < data.length; i++) {
                this.emRows.push({
                  index : idx,
                  atimepn : data[i].ATimePN,
                  firstTime : TymCommon.convertStringToDateTime(data[i].ATimePN), // ATimePN : "20230103:164137.596^1"
                  lastTime : TymCommon.convertStringToDateTime2(data[i].OTime),   // OTime : "2023-01-03 16:41"
                  etype : Number(data[i].ETYPE),
                  mstate : data[i].MSTATE,
                  type : this.EmergencyTypeString[Number(data[i].ETYPE)],
                  status : this.$t("machineDetail.manage"),
                  machineNo : data[i].MN,
                  serialNo : data[i].SN,
                  location : data[i].LOCA
                })
                idx++
              }
              //console.log('tabManage.updateData.alarm :', data)

              this.emCount = this.emRows.length;
              this.emCurPage = 0
            })
            .catch(err => {
              console.log('tabManage.updateData.alarm error :', err)
              this.emRows = []
              this.emCount = 0;
              this.emCurPage = 0
            })
          } else {
            console.log('tabManage.updateData.emergency MN is empty:', obj)
          }
        }, 5)
        
        setTimeout( () => {
          // console.log('TymAws.getBrokenAlertByMN :', this.machineInfo)
          TymAws.getBrokenAlertByMN(this.machineInfo.machineNo)
            .then(data => {
              let i = 0, j=0, idx = 0
              let tempRows = []

              if(TymConst.IS_DEVELOPMENT) {
                console.log('getBrokenAlertByMN :', this.machineInfo, data)
              }

              for(i = 0; i < data.length; i++) {
                if(data[i].ECount == 1) {
                  // console.log('ECount == 1', data[i], data[i].CKEY[0])
                  tempRows.push({
                    index : idx,
                    selected : false,
                    MON : data[i].MON,
                    ckey : data[i].CKEY[0],
                    atime : data[i].ATime,
                    firstTime : TymCommon.convertStringToDateTime(data[i].CKEY[0]),
                    lastTime : TymCommon.isEmpty(data[i].OTime[0]) ? TymCommon.convertStringToDateTime(data[i].CKEY[0]) : TymCommon.convertStringToDateTime2(data[i].OTime[0]),
                    isBlue : data[i].BLUE,
                    type : data[i].DN[0],
                    status : TymCommon.isEmpty(data[i].MSTATE) ? this.$t('TEXT_BROKENSTATE.1') : this.$t('TEXT_BROKENSTATE.' + (data[i].MSTATE + 1)),
                    ecode : data[i].ECODE[0],
                    vut : data[i].VUT[0],
                    mstate : TymCommon.isEmpty(data[i].MSTATE) ? 0 : data[i].MSTATE,
                    mtime : TymCommon.convertStringToDateTime2(data[i].MTime),
                    location : {
                      latitude : TymCommon.isEmpty(data[i].LOCA) ? 0 : data[i].LOCA[0],     // 위도
                      longitude : TymCommon.isEmpty(data[i].LOCA) ? 0 : data[i].LOCA[1],    // 경도
                      datetime : TymCommon.isEmpty(data[i].LOCA) ? '' : data[i].LOCA[2],     // 수신 시간 
                    },
                    manual : null
                  })
                  idx++
                } else {
                  // 여러개의 에러가 있는 경우 한씩 추가하기 위함.
                  for(j = 0; j < data[i].ECount; j++) {
                    tempRows.push({
                      index : idx,
                      selected : false,
                      MON : data[i].MON,
                      ckey : data[i].CKEY[j],
                      atime : data[i].ATime,
                      firstTime : TymCommon.convertStringToDateTime(data[i].CKEY[j]),
                      lastTime : TymCommon.isEmpty(data[i].OTime[j]) ? TymCommon.convertStringToDateTime(data[i].CKEY[j]) : TymCommon.convertStringToDateTime2(data[i].OTime[j]),
                      isBlue : data[i].BLUE,
                      type : data[i].DN[j],
                      status : TymCommon.isEmpty(data[i].MSTATE) ? this.$t('TEXT_BROKENSTATE.1') : '',
                      ecode : data[i].ECODE[j],
                      vut : data[i].VUT[j],
                      mstate : 0,           // 0-고장발생, 1-신고, 2-입고 (여러개 묶인 경우는 고장발생한 경우로)
                      location : {
                        latitude : TymCommon.isEmpty(data[i].LOCA) ? 0 : data[i].LOCA[0],     // 위도
                        longitude : TymCommon.isEmpty(data[i].LOCA) ? 0 : data[i].LOCA[1],    // 경도
                        datetime : TymCommon.isEmpty(data[i].LOCA) ? '' : data[i].LOCA[2],     // 수신 시간 
                      },
                      manual : null
                    })
                    idx++
                  }
                }
              }
              tempRows.sort((a, b) => {
                return b.ckey.localeCompare(a.ckey)
              })
              this.brRows = []
              this.brRows = tempRows
              // console.log('getBrokenAlertByMN 2:', JSON.parse(JSON.stringify(this.brRows)))
              this.brCount = this.brRows.length;
              this.brCurPage = 0

              let self = this
              let ckeys = []
              this.brRows.forEach(ck => {
                ckeys.push(ck.ckey)
              })
              TymAws.getBrokenDetailData(this.info.machineNo, ckeys)
                .then(data => {
                  // console.log(data)
                  self.getBrokenDetailOK(data.Items)
                })
                .catch(er => {
                  console.log('TymAws.getBrokenDetailData : error :', er)
                })
            })
            .catch(err => {
              this.brRows = []
              this.brCount = 0;
              this.brCurPage = 0
              console.log('tabManage.updateData.broken er :', err)
            })
          }, 5)

        this.reloadConsAlert(this.machineInfo)
      } else {
        // 
        console.log('tabManage.updateData : no base-data, clean all')
        this.emRows = []
        this.brRows = []
      }
    },
    reloadConsAlert(obj) {
      TymAws.getConsumablesAlertByMN(obj.machineNo)
          .then(data => {
            //console.log('machineManage CONS :', data)
            this.consumableData = []
            /* data
            [
              {
                "CL1": 50,
                "NOCOUNT": 6,
                "CL3": 500,
                "CL2": 500,
                "CL5": 50,
                "MON": "T115_NONE",
                "CL4": 2000,
                "CL7": 50,
                "OKCOUNT": 3,
                "CL6": 50,
                "CL9": 50,
                "CL8": 50,
                "MA": "0",
                "VUT": 68,
                "MB": "2",
                "MC": "10",
                "MD": "1662537827211-jeonbuk",
                "ASTATE": [
                  true,
                  false,
                  false,
                  false,
                  true,
                  true,
                  true,
                  true,
                  true
                ],
                "MN": "PT11500070",
                "MANAGE": "[{\"MA\":\"0\",\"MB\":\"2\",\"MC\":\"10\",\"MD\":\"1662537827211-jeonbuk\"}]",
                "UINFO": [
                  {
                    "USER": "이종민",
                    "HP": "010-7484-6100",
                    "UID": "leejm12"
                  }
                ],
                "OTime": "2023-09-19 10:52",
                "CC2": 68,
                "CC1": 68,
                "CC4": 68,
                "CC3": 68,
                "CC6": 68,
                "CC5": 68,
                "CC8": 68,
                "CC7": 68,
                "CC9": 68
              }
            ]
            */
            if(data.length > 0) {
              const store = useTymictStore()
              /* this.machineInfo
              {
                "index": 125,
                "name": "이종민",
                "phone": "01074846100",
                "uid": "leejm12",
                "machineNo": "PT11500070",
                "serialNo": "T002230601480148",
                "dealer": "남원대리점",
                "state": 0,
                "desc": "",
                "date": "",
                "historyLast": {
                  "SHUTTLE": "1",
                  "UR_LV": "54.07",
                  "TVER": "10B",
                  "OnTime": "20230920:073018.804",
                  "TMP_MDM": "37.0",
                  "TMP_COA": "28.0",
                  "PWR": "OFF",
                  "SDTime": "20230820:185751.772",
                  "LOCA": [
                    {
                      "HDOP": "1",
                      "Coord": "35.5143200166,127.3667060666",
                      "TTime": "20230920:133926.598"
                    }
                  ],
                  "TMP_MOA": "0",
                  "DRD": "0",
                  "TARE": "0",
                  "TM_TMP": "31",
                  "VUT": "71",
                  "PTO_LAMP": "0",
                  "PTOT": "0",
                  "BRAKE_P": "0",
                  "ET1_1": "67",
                  "OffTime": "20230920:073312.462",
                  "SN": "T002230601480148",
                  "TTime": "20230920:073133",
                  "TFEF": "0.97",
                  "MN": "PT11500070",
                  "DRT": "0",
                  "VUDT": "17",
                  "CLIENTID": "T0022306014800360036",
                  "WAR_AIR": "0",
                  "PTOD": "0",
                  "TMP_CoA": "ERROR",
                  "BATT": "13.33",
                  "V_HOUR": "68.55",
                  "TMP_MoA": "ERROR",
                  "DBV": "102",
                  "ATime": "20230920:073127.441",
                  "LV_FUEL": "98.52"
                },
                "latitude": 35.5143200166,
                "longitude": 127.3667060666,
                "realtimeAddr": "전라북도 남원시 덕과면 사율길 154-23",
                "realtimeInfo": "20230920:133926.598"
              }
              */
              let foundSale = store.SalesData.data.find(x => x.serialNo == obj.serialNo)
              //console.log('updateData ConsumableAlert #1 foundSale:', foundSale)
              let lang = this.isKorean ? 0 : 1;
              if(foundSale) {
                //console.log(foundSale, store.ModelData.data)
                let srcModel = '', srcOption = ''
                if(TymCommon.isEmpty(foundSale.Info)) {
                  let info = TymCommon.getModelFullname(foundSale.MON)
                  srcModel = info.Model
                  srcOption = info.Option
                } else {
                  srcModel = foundSale.Info.Model
                  srcOption = foundSale.Info.Option
                }

                let modelInData = store.ModelData.data.find(x => x.model == srcModel && x.option == srcOption)
                // console.log('updateData ConsumableAlert #2:', srcModel, srcOption, modelInData, this.machineInfo, data)
                /*
                this.machineInfo : 현재 선택된 기대 정보
                modelInData : 현재 기대의 모델 정보
                data : 소모품의 현재값, 허용치 배열
                */
                //console.log('updateData ConsumableAlert #2:', modelInData)
                let i = 0
                if(!TymCommon.isEmpty(modelInData) && !TymCommon.isEmpty(modelInData.citems)) {
                  modelInData.citems.forEach(cItem => {
                    let iconIdx = 0
                    if(!TymCommon.isEmpty(cItem.sn)) {
                      if(cItem.sn.length > 0) {
                        iconIdx = Number(cItem.sn[0].icon)
                      }
                    }
                    let valueCC = data[0]['CC' + (i + 1).toString()]
                    let valueCL = data[0]['CL' + (i + 1).toString()]
                    //let valueCF = parseInt(cItem.cf)
                    let valueCM = ''
                    this.consumableData.push({
                      index : i,
                      icon : iconIdx,
                      valuePeriod : valueCC,
                      valueFirst : valueCL,
                      guage : valueCL != 0 ? Math.round(valueCC / valueCL * 10) * 2 : 0,
                      memo : valueCM,
                      memDate : '',
                      title : cItem.name[lang]
                    })
                    i++
                  })
                } else {
                  for(let i = 0; i < TymConst.MAX_CONSUMABLE_COUNT; i++) {
                    let valueCC = data[0]['CC' + (i + 1).toString()]
                    let valueCL = data[0]['CL' + (i + 1).toString()]
                    let valueCM = data[0]['CM' + (i + 1).toString()]
                    let valueSN = data[0]['SN' + (i + 1).toString()]
                    let title = ''
                    let iconIdx = 0
                    let isJSON = false

                    if(TymCommon.isEmpty(modelInData) || TymCommon.isEmpty(modelInData.citems[i])) {
                      continue
                    }

                    console.log('SN :', i, valueSN)
                    if(!TymCommon.isEmpty(valueSN)) {
                      try {
                        if(Array.isArray(valueSN)) {
                          let abc = JSON.stringify(valueSN)
                          if((abc.length > 2) && !TymCommon.isEmpty(abc)) {
                            isJSON = true
                            iconIdx = obj.citems[i].sn[0].icon
                          }
                        }
                      } catch(e) {
                        console.log('JSON.parse ERROR :', e)
                      }
                    }
                    this.unusedParam(isJSON)

                    // console.log(iconIdx, modelInData)

                    let lang = this.isKorean ? 0 : 1
                    if(!TymCommon.isEmpty(valueCC) && !TymCommon.isEmpty(valueCL)) {
                      if(modelInData) {
                        title = modelInData.citems[i].name[lang]
                      } /*else if(modelInList && (optionIndex >= 0)) {
                        title = modelInList.default_citem[optionIndex].name[i][lang]
                      }*/

                      if(TymCommon.isEmpty(title)) {
                        if(!TymCommon.isEmpty(TymConst.CONSUMABLE_DEFAULTS[i])) {
                          title = TymConst.CONSUMABLE_DEFAULTS[i][lang]
                        }
                      }

                      // 소수첫째짜리에서 반올림해서 정수로 만든 다음 20개의 눈금단위로 나누는 과정
                      // round(a * 10) / 10 --> 이게 그거임
                      // 원래 round(a * 10) / 10 * 20 ==> 이래야 하는데 마지막을 그냥 계산된 2로 쓴 것임
                      this.consumableData.push({
                        index : i,
                        // icon : TymConst.CONSUMABLE_ICONS[iconIdx],
                        icon : iconIdx,
                        valuePeriod : valueCC,
                        valueFirst : valueCL,
                        guage : valueCL != 0 ? Math.round(valueCC / valueCL * 10) * 2 : 0,
                        memo : valueCM,
                        memDate : '',
                        title : title
                      })
                    }
                  }
                }
              }
            }
          })
          .catch(err => {
            this.consumableData = []
            console.log('tabManage.updateData.consum er:', err)
            /*
            let j = 0
            for(j = 0; j < TymConst.MAX_CONSUMABLE_COUNT; j++) {
              console.log('tabManage.updateData.consum er:', j)
              this.consumableData[j].valuePeriod = 0
              this.consumableData[j].valueFirst = 0
              this.consumableData[j].guage = 0
            }
            */
          })
    },
    emColumnClicked(rowData, row, column) {
      const store = useTymictStore()

      this.viewEmerErr = 1;
      this.hasOccLoca = false;
      this.isVisibleBrCOS = false

      let data = this.machineInfo;
      let emData = store.EmergencyData.data.find(x => x.MN == rowData.machineNo)

      if(TymConst.IS_DEVELOPMENT) {
        console.log('emColumnClicked :', rowData, data, emData)
      }

      if(!TymCommon.isEmpty(emData) && !TymCommon.isEmpty(emData.LOCA)) {
        data.latitude = emData.LOCA[0]
        data.longitude = emData.LOCA[1]
      } else if(!TymCommon.isEmpty(rowData.location)) {
        if(TymCommon.isEmpty(rowData.location[0].latitude)) {
          data.latitude = rowData.location[0]
          data.longitude = rowData.location[1]
        } else {
          data.latitude = rowData.location[0].latitude;
          data.longitude = rowData.location[0].longitude;
        }
      } 

      this.brSelectedRows = []

      this.emSelectedRows = []
      this.emSelectedRows.push(rowData)

      if(column < 3) {
        this.isEditEmHistory = false
        this.isVisibleEmCOS = false

        if(TymCommon.isEmpty(data.latitude) || TymCommon.isEmpty(data.longitude) ) {
          TymCommon.Toast(this.$t('errorMsg.noLocaInfo')) // "위치 정보가 없습니다."
          this.isVisibleLoca = true
          this.hasOccLoca = false
          return;
        }
        this.machineAddress = '' // this.machineInfo.address
        this.$refs.occurLoca.updateMapData( [data])
        this.isVisibleLoca = true
        this.hasOccLoca = true
      } else {
        // 맨 오른쪽 컬럼에서 클릭해서 COS로 이동
        // console.log('emColumnClicked COS', rowData)
        // 값 초기화 해야지
        this.isEmCosResolved = rowData.mstate == TymConst.EMER_ALERT_MSTATE_COMPLETE;
        let now = new Date()
        this.emCosDate = TymCommon.getDateString(now)
        this.emCosType = rowData.type
        this.emCosManager = store.idToken['name']
        // this.emCosManager = rowData.resolver
        this.emCosContents = rowData.resolveMsg
        // console.log('User :', store.idToken['name'], store.CurrentUser.username)

        this.isEditEmHistory = false
        this.isVisibleBrCOS = false
        this.isVisibleLoca = false
        this.isEditEmHistory = false
        this.isVisibleEmCOS = true
      }
    },
    brColumnClicked(rowData, index, column) {
      if(column == 3){
        // 고장 매뉴얼 컬럼
        if(!TymCommon.isEmpty(rowData.manual)) {
          this.downloadManual(rowData)
        }
        return
      }
      this.viewEmerErr = 2
      this.isVisibleLoca = false
      this.isEditEmHistory = false
      this.isVisibleEmCOS = false

      this.emSelectedRows = []

      this.brSelectedRows = []
      this.brSelectedRows.push(rowData)
      
      if(column < 3) {
        this.isVisibleBrCOS = false
        this.oneBrokenData = []

        if(TymConst.IS_DEVELOPMENT) {
          console.log('현재고장 선택 :', this.machineInfo, rowData)
        }

        this.oneBrokenData.push({
            state : 0,
            msg : this.$t('TEXT_BROKENSTATE.1'),
            context : rowData.type,
            vut : rowData.vut,
            ecode : rowData.ecode,
            date : rowData.firstTime,
            latitude : TymCommon.isEmpty(rowData.location) ? 0 : rowData.location.latitude,
            longitude : TymCommon.isEmpty(rowData.location) ? 0 : rowData.location.longitude,
            manager : '' // 고장발생은 소유자가 했겠지...
          })

        if(rowData.mstate != 0) {
          TymAws.getBrokenDetailData(this.machineInfo.machineNo, [rowData.ckey])
            .then(data => {
              let item = null
              let resolver = ''

              try {
                if(Array.isArray(data.Items[0]))
                  item = data.Items[0][0]
                else 
                  item = data.Items[0]
              } catch(ex) {
                console.log('getBrokenDetailData ERROR :', ex)  
              }
              
              console.log('getBrokenDetailData :', data)  

              if(item) {
                resolver = item.RESOLVER
                switch(item.MSTATE) {
                  case 1:   // 신고
                    //console.log('신고접수 :', item)
                    this.oneBrokenData.push({
                      state : 1,
                      msg : this.$t('TEXT_BROKENSTATE.2'),
                      date : TymCommon.isEmpty(item.M1Time) ? '' : item.M1Time,
                      vut : item.vut,
                      manager : resolver
                    })
                    break;
                  case 2:
                    //console.log('입고처리 :', item, item.M1Time)
                    this.oneBrokenData.push({
                      state : 1,
                      msg : this.$t('TEXT_BROKENSTATE.2'),
                      date : TymCommon.isEmpty(item.M1Time) ? '' : item.M1Time,
                      vut : item.vut,
                      manager : ''
                    })
                    this.oneBrokenData.push({
                      state : 2,
                      msg : this.$t('TEXT_BROKENSTATE.3'),
                      date : TymCommon.isEmpty(item.M2Time) ? '' : item.M2Time,
                      vut : item.vut,
                      manager : resolver
                    })
                    break;
                  case 3:
                    //console.log('수리완료 :', item)
                    this.oneBrokenData.push({
                      state : 1,
                      msg : this.$t('TEXT_BROKENSTATE.2'),
                      date : TymCommon.isEmpty(item.M1Time) ? '' : item.M1Time,
                      vut : item.vut,
                      manager : ''
                    })
                    this.oneBrokenData.push({
                      state : 2,
                      msg : this.$t('TEXT_BROKENSTATE.3'),
                      date : TymCommon.isEmpty(item.M2Time) ? '' : item.M2Time,
                      vut : item.vut,
                      manager : resolver
                    })
                    this.oneBrokenData.push({
                      state : 3,
                      msg : this.$t('TEXT_BROKENSTATE.4'),
                      date : TymCommon.isEmpty(item.M3Time) ? '' : item.M3Time,
                      vut : item.vut,
                      manager : resolver
                    })
                    break;
                }
              }
              //console.log('getBrokenDetailData All :', this.oneBrokenData)
            })
            .catch(er => {
              console.log('getBrokenDetailData Error :', er)
            })
        }
      } else {
        // 고장 상태 변경
        this.oneBrokenData = []
        this.brOccurType = this.getBrOccurType[0]
        this.brWorkState = rowData.mstate + 1

        let now = new Date()
        this.brCosDate = TymCommon.getDateString(now)
        this.brCosType = rowData.type
        const store = useTymictStore()
        this.brCosManager = store.idToken['name']
        this.brCosContents = rowData.resolveMsg
        this.isVisibleBrCOS = true;
        this.isEditBrHistory = false
      }
    },
    viewBrOccurLocation() {
      this.isVisibleEmCOS = false;

      // 고장알림 -> Processing History -> 위치 클릭시
      if(this.oneBrokenData.length > 0) {
        const store = useTymictStore()
        
        let brData = store.BrokenData.data.find(x => x.MN == this.machineInfo.machineNo)

        if(TymConst.IS_DEVELOPMENT) {
          console.log('viewBrOccurLocation :', this.oneBrokenData, brData, this.machineInfo, this.brRows)
        }

        let data =  this.machineInfo;
        if(!TymCommon.isEmpty(data.latitude) && !TymCommon.isEmpty(data.longitude)) {
          if(data.longitude != 0 && data.longitude != 0) {
            this.machineAddress = this.machineInfo.machineAddress

            this.$refs.occurLoca.updateMapData([data])
            this.isVisibleLoca = true;
            this.hasOccLoca = true;
            return;
          }
        }
      }
      TymCommon.Toast(this.$t('errorMsg.noLocaInfo')) // "위치 정보가 없습니다."
    },

    // 기대번호, 주소
    addrReceived(machineID, addr) {
      this.machineAddress = addr
    },
    
    makeDateString2QueryString(src, isStart) {
      let srcDate = moment(new Date(src))
      let result = null
      if(isStart) {
        result = srcDate.format("YYYY-MM-DD 00:00")
      } else {
        srcDate = srcDate.add(1, 'days')
        //console.log(srcDate.toString())
        result = moment(srcDate).format("YYYY-MM-DD 00:00")
      }
      // console.log('0 :', src, srcDate, result)

      const store = useTymictStore()

      if(store.connectServer != 0) {
        let curTZ = Intl.DateTimeFormat().resolvedOptions().timeZone     // 브라우저 타임존, 한국은 Asia/Seoul
        if(store.timezoneIndex != 0) {
            curTZ = store.timezoneList[store.timezoneIndex].value
        }
        let tmpResult = moment(new Date(src)).tz(curTZ)
        let offset = tmpResult.utcOffset()
        if(offset > 0)
          result = srcDate.subtract(Math.abs(offset), 'minutes').format("YYYY-MM-DD HH:mm")
        else 
          result = srcDate.add(Math.abs(offset), 'minutes').format("YYYY-MM-DD HH:mm")
        if(TymConst.IS_DEVELOPMENT) {
          console.log(isStart, src, offset, result)
        }
      } else {
        if(TymConst.IS_DEVELOPMENT) {
          console.log(isStart, src, result)
        }
      }

      return result
    },
    
    refreshEmHistory() {
      // 긴급알림/History/Search 버튼 누를 때
      TymAws.getEmergencyHistoryByMN(this.machineInfo.machineNo, this.makeDateString2QueryString(this.emHistoryStart, true), this.makeDateString2QueryString(this.emHistoryEnd, false))
        .then(data => {
          this.emHistoryRows = []
          let i = 0, idx = 0

          data.sort((a, b) => {
            return b.RTime.localeCompare(a.RTime)
          })

          if(TymConst.IS_DEVELOPMENT) {
            console.log('getEmergencyHistoryByMN :', this.makeDateString2QueryString(this.emHistoryStart, true),
                      this.makeDateString2QueryString(this.emHistoryEnd, false), data)
          }

          for(i = 0; i < data.length; i++) {
            
            this.emHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime3(data[i].OTime),
              resolveTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime3(data[i].RTime),
              etype : Number(data[i].ETYPE),
              type : this.EmergencyTypeString[Number(data[i].ETYPE)],
              vut : data[i].VUT,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : TymCommon.translateKeyword(data[i].RESOLVER),
              resolveMsg : TymCommon.isEmpty(data[i].RMEMO) ? '' : TymCommon.translateKeyword(data[i].RMEMO),
            })
            idx++
          }

          this.emHistoryPageCount = Math.ceil(this.emHistoryRows.length / this.emHistoryPage.rowsPerPage)
        })
        .catch(er => {
          this.emHistoryRows = []
          this.emHistoryPageCount = 0
          console.log('machineManage.TymAws.getEmergencyHistoryByMN ERROR', er)
        })
    },
    updateEmPagination(newPagination) {
      this.emHistoryRows.sort((a, b) => {
        let result = false
        switch(newPagination.sortBy) {
          case 'occurTime':
            if(a.occurTime == b.occurTime)
              result = a.resolveTime - b.resolveTime
            else
              result = a.occurTime - b.occurTime
            break
          case 'resolveTime':
            if(a.resolveTime == b.resolveTime)
              result = a.occurTime - b.occurTime
            else
              result = a.resolveTime - b.resolveTime
            break
          case 'type':
            if(a.type == b.type)
              result = a.occurTime - b.occurTime
            else
              result = a.type - b.type
            break
          case 'resolver':
            if(a.resolver == b.resolver)
              result = a.occurTime - b.occurTime
            else
              result = a.resolver - b.resolver
            break
        }
        if(newPagination.descending)
          result = 0 - result;

        if(result > 0)
          return 1
        else if(result < 0)
          return -1
        else 
          return 0
      })

      this.emHistoryPagination = newPagination.page
      this.emHistoryPage = newPagination
    },
    emHistoryPageChanged(page) {
      console.log('notiPageChanged : ', page)

      let sortby = this.emHistoryPage.sortBy
      let desc = this.emHistoryPage.descending
      this.emHistoryPage = { 
        sortBy : sortby,
        descending : desc,
        rowsPerPage : 7, 
        page : page
      }
    },

    refreshBrHistory() {
      // 고장/History/Search 버튼 누를 때
      TymAws.getBrokenHistoryByMN(this.machineInfo.machineNo, this.makeDateString2QueryString(this.brHistoryStart, true), this.makeDateString2QueryString(this.brHistoryEnd, false))
        .then(data => {
          /*
          {
            "MN": "NAJU_4_1",
            "ATimePN": "20220503:163257.289^EC6_12^1",
            "BTYPE": "4",
            "MON": "TS130_CP6A",
            "DN": "TCU CAN Rx Error",
            "OTime": "2022-05-03 16:32",
            "ECODE": "EC6_12",
            "MA": "0",
            "RESOLVER": "자동해결",
            "VUT": 12,
            "MB": "1641201354576-fing0828",
            "MC": " ",
            "RMEMO": "자동해결",
            "MD": " ",
            "RTime": "2022-05-03 16:33"       고장 해소시간
          }
          */          
          this.brHistoryRows = []
          let i = 0, idx = 0

          data.sort((a, b) => {
            return b.RTime.localeCompare(a.RTime)
          })

          if(TymConst.IS_DEVELOPMENT) {
            console.log('getBrokenHistoryByMN :', this.makeDateString2QueryString(this.brHistoryStart, true), this.makeDateString2QueryString(this.brHistoryEnd, false), data)
          }
          
          const store = useTymictStore()
          let lang = store.getLanguage()
          let manualInfo = null
          for(i = 0; i < data.length; i++) {
            manualInfo = null
            if(!TymCommon.isEmpty(data[i].MANUAL)) {
              if(data[i].MANUAL.indexOf(lang) >= 0) {
                manualInfo = data[i].MANUAL
              }
            }

            // console.log(data[i].OTime, TymCommon.convertStringToDateTime3(data[i].OTime))

            this.brHistoryRows.push({
              index : idx,
              MON: data[i].MON,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime3(data[i].OTime),
              resolveTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime3(data[i].RTime),
              erType : data[i].BTYPE,     // 고장 부품 표시용 : 0-기타, 1-HCU, 2-미션, 3-엔진, 기타-자동고침시
              erCode : data[i].ECODE,
              erMsg : data[i].DN,
              vut : data[i].VUT,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : TymCommon.translateKeyword(data[i].RESOLVER),
              resolveMsg : TymCommon.isEmpty(data[i].RMEMO) ? '' : TymCommon.translateKeyword(data[i].RMEMO),
              manual : manualInfo
            })
            idx++
          }
          // console.log('getBrokenHistoryByMN :', this.brHistoryRows)

          this.brHistoryPagination = 1
          this.brHistoryPageCount = Math.ceil(this.brHistoryRows.length / 8)
        })
        .catch(() => {
          this.brHistoryRows = []
        })
    },
    updateBrPagination(newPagination) {
      this.brHistoryRows.sort((a, b) => {
        let result = false
        switch(newPagination.sortBy) {
          case 'occurTime':
            if(a.occurTime == b.occurTime)
              result = a.resolveTime - b.resolveTime
            else
              result = a.occurTime - b.occurTime
            break
          case 'resolveTime':
            if(a.resolveTime == b.resolveTime)
              result = a.occurTime - b.occurTime
            else
              result = a.resolveTime - b.resolveTime
            break
          case 'erCode':
            if(a.erCode == b.erCode)
              result = a.occurTime - b.occurTime
            else
              result = a.erCode - b.erCode
            break
          case 'resolver':
            if(a.resolver == b.resolver)
              result = a.occurTime - b.occurTime
            else
              result = a.resolver - b.resolver
            break
        }
        if(newPagination.descending)
          result = 0 - result;

        if(result > 0)
          return 1
        else if(result < 0)
          return -1
        else 
          return 0
      })

      this.brHistoryPagination = newPagination.page
      this.brHistoryPage = newPagination
    },
    brHistoryPageChanged(page) {
      console.log('brHistoryPageChanged : ', page)

      let sortby = this.brHistoryPage.sortBy
      let desc = this.brHistoryPage.descending
      this.brHistoryPage = { 
        sortBy : sortby,
        descending : desc,
        rowsPerPage : 7, 
        page : page
      }
    },
    editEmHistory() {
      this.isEditEmHistory = true;
    },
    
    selectEmHistoryResolveCal() {
      this.$refs.emHistoryResolveCal.hide()
    },

    emHistoryColumnClicked(rowData, row, column) {
      console.log('emHistoryColumnClicked', rowData, row, column)
    },

    showEmSolveDate() {
      try {
        this.emCosDateCal = moment(this.emCosDate).format("YYYY/MM/DD")
      } catch(err) {
        console.log('showEmSolveDate error :', err)
      }
    },
    hideEmSolveDate() {
      try {
        if(!TymCommon.isEmpty(this.emCosDateCal))
          this.emCosDate = TymCommon.getDateString(moment(this.emCosDateCal));
      } catch(err) {
        console.log('hideEmSolveDate error :', err)
      }
    },
    cancelEmCOS() {
      this.isEditEmHistory = false;
      this.isVisibleEmCOS = false;
    },
    // 긴급 Manage -> 저장버튼
    saveEmCOS() {
      const store = useTymictStore()
      if(this.emCosManager == store.idToken['name']) {
        // 로그인한 사용자 이름이랑 적은 이름이 같네
      } else {
        // 로그인한 사용자 이름이랑 적은 이름이 다르네
      }

      // RTIME만들어라
      let evDate = new Date(this.emCosDate)
      let now = new Date()

      if(store.connectServer == 0) {
        evDate.setHours(now.getHours())
        evDate.setMinutes(now.getMinutes())
        evDate.setSeconds(now.getSeconds())
        evDate = moment(evDate).format('YYYY-MM-DD HH:mm');
      } else {
        evDate.setHours(now.getHours())
        evDate.setMinutes(now.getMinutes())
        evDate.setSeconds(now.getSeconds())
        evDate = moment(evDate).utc().format('YYYY-MM-DD HH:mm');
      }

      let memo = TymCommon.isEmpty(this.emCosContents) ? '' : this.emCosContents

      if(TymCommon.IS_DEVELOPMENT) {
        console.log(this.emSelectedRows[0].machineNo, this.emSelectedRows[0].atimepn, this.emCosManager, 
                    this.isEmCosResolved ? TymConst.EMER_ALERT_MSTATE_COMPLETE : TymConst.EMER_ALERT_MSTATE_INCOMPLETE,
                    memo, evDate)
      }
      TymAws.updateAlertState(this.emSelectedRows[0].machineNo, this.emSelectedRows[0].atimepn, this.emCosManager, 
                              this.isEmCosResolved ? TymConst.EMER_ALERT_MSTATE_COMPLETE : TymConst.EMER_ALERT_MSTATE_INCOMPLETE,
                              memo, evDate)
        .then(data => {
          this.unusedParam(data)
          setTimeout(() => {
            this.updateData(this.info)
          }, 5)
        })
        .catch(er => {
          console.log('saveEmCOS error : ', er)
        })
    },
    brHistoryColumnClicked(rowData, row, column) {
      if(column == 3){
        // 매뉴얼 다운로드
        if(TymConst.IS_DEVELOPMENT)
          console.log('brHistoryColumnClicked MANUAL :', rowData, row)

        if(!TymCommon.isEmpty(rowData.manual)) {
          this.downloadManual(rowData)
        }
      }
    },
    selectBrHistoryResolveCal() {
      this.$refs.brHistoryResolveCal.hide()
    },
    showBrSolveDate() {
      try {
        this.brCosDateCal = moment(this.brCosDate).format("YYYY/MM/DD")
      } catch(err) {
        console.log('showBrSolveDate error :', err)
      }
    },
    hideBrSolveDate() {
      try {
        let selectDay = TymCommon.getDateString(moment(this.brCosDateCal));
        if(!TymCommon.isEmpty(selectDay))
          this.brCosDate = selectDay
      } catch(err) {
        console.log('hideBrSolveDate error :', err)
      }
    },
    cancelBrCOS() {
      this.viewEmerErr = 0
      this.isEditBrHistory = false
      this.isVisibleBrCOS = false
    },
    saveBrCOS() {
      const store = useTymictStore()
      // console.log('saveBrCOS()', this.machineInfo, this.brSelectedRows[0], this.brSelectedRows[0].ckey, this.brCosManager, this.brWorkState)
      
      let brType = this.getBrOccurType.findIndex(x => x == this.brOccurType)
      if(brType >= 0) {
        brType = (brType + 1).toString()
      } else {
        brType = '5' // 기타
      }
      let brDate = new Date(this.brCosDate)
      let now = new Date()

      if(store.connectServer == 0) {
        brDate.setHours(now.getHours())
        brDate.setMinutes(now.getMinutes())
        brDate.setSeconds(now.getSeconds())
        brDate = moment(brDate).format('YYYY-MM-DD HH:mm');
      } else {
        brDate.setHours(now.getHours())
        brDate.setMinutes(now.getMinutes())
        brDate.setSeconds(now.getSeconds())
        brDate = moment(brDate).utc().format('YYYY-MM-DD HH:mm');
      }
      // 2023-07-14 14:55",
      let memo = TymCommon.isEmpty(this.brCosContents) ? '' : this.brCosContents

      if(TymConst.IS_DEVELOPMENT)
        console.log('saveBrCOS :', brDate, brType)

      TymAws.updateBrokenState(this.machineInfo.machineNo, this.brSelectedRows[0].ckey, this.brCosManager, this.brWorkState - 1, memo, brDate, brType)
        .then(data => {
          this.unusedParam(data)
          setTimeout(() => {
            this.updateData(this.info)
          }, 5)
        })
        .catch(er => {
          console.log('saveBrCOS error : ', er)
        })

      this.viewEmerErr = 0
      this.isEditBrHistory = false
      this.isVisibleBrCOS = false
    },

    showConsHistory(isVisible) {
      this.isVisibleCsHistory = isVisible
      if(this.isVisibleCsHistory) {
        setTimeout(()=> {
          this.refreshCsHistory()
        },5)
      }
    },
    refreshCsHistory() {
       // 소모품 변경이력
       TymAws.getConsumablesHistoryByMN(this.machineInfo.machineNo, this.makeDateString2QueryString(this.csHistoryStart, true), this.makeDateString2QueryString(this.csHistoryEnd, false))
        .then( data => {
          /*
          {
            "MN": "TX76SD00958",
            "MEMO": "상북농협에서 교환",
            "MON": "TX76_CQPAN",
            "OTime": "2023-07-22 07:42",
            "RVut": 127,
            "CTYPE": "1",
            "OVut": 127,
            "RESOLVER": "울산-김지태",
            "MA": "0",
            "MB": "2",
            "MC": "13",
            "MD": "1684458796330-gyeongnam",
            "ATime": "20230728:122817.002",
            "RTime": "2023-07-24 12:27"
          }
          */
          this.csHistoryRows = []
          let i = 0, idx = 0

          data.sort((a, b) => {
            return b.RTime.localeCompare(a.RTime)
          })
          if(TymConst.IS_DEVELOPMENT) {
            console.log('refreshCsHistory :', data)
          }
          for(i = 0; i < data.length; i++) {
            let consCat = '-'
            if(!TymCommon.isEmpty(data[i].CATEGORY)) {
              if(Array.isArray(data[i].CATEGORY)) {
                consCat = data[i].CATEGORY[this.isKorean ? 0 : 1]
              } else {
                consCat = data[i].CATEGORY
              }
            }
            let partname = '-'
            if(!TymCommon.isEmpty(data[i].PARTSNAME)) {
              if(Array.isArray(data[i].PARTSNAME)) {
                partname = data[i].PARTSNAME[this.isKorean ? 0 : 1]
              } else {
                partname = data[i].PARTSNAME
              }
            }
            let partnumber = '-'
            if(!TymCommon.isEmpty(data[i].PARTNO)) {
              partnumber = data[i].PARTNO
            }

            // console.log('OCCUR : ', data[i].OTime, TymCommon.convertStringToDateTime2(data[i].OTime))
            // console.log('RESOL : ', data[i].RTime, TymCommon.convertStringToDateTime2(data[i].RTime))
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              // consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              category : consCat,
              partname : partname,
              partnumber : partnumber,
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : TymCommon.translateKeyword(data[i].MEMO),
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : TymCommon.translateKeyword(data[i].RESOLVER),
            })
            idx++
            /*
             * 숫자 늘려서 시험용
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            this.csHistoryRows.push({
              index : idx,
              occurTime : TymCommon.isEmpty(data[i].OTime) ? '' : TymCommon.convertStringToDateTime2(data[i].OTime),
              opTimeOcc : data[i].OVut,
              replaceTime : TymCommon.isEmpty(data[i].RTime) ? '' : TymCommon.convertStringToDateTime2(data[i].RTime),
              opTimeRepl : data[i].RVut,
              consName : TymCommon.isEmpty(data[i].CTYPE) ? '' : this.$t('consumables.' + data[i].CTYPE),
              context : TymCommon.isEmpty(data[i].MEMO) ? '' : data[i].MEMO,
              resolver : TymCommon.isEmpty(data[i].RESOLVER) ? '' : data[i].RESOLVER,
            })
            idx++
            */
          }

          this.csHistoryPageCount = Math.ceil(this.csHistoryRows.length / this.csHistoryPage.rowsPerPage)
        })
        .catch(() => {
          this.csHistoryRows = []
        })
    },
    updateCsPagination(newPagination) {
      this.csHistoryRows.sort((a, b) => {
        let result = false
        switch(newPagination.sortBy) {
          case 'occurTime':
            if(a.occurTime == b.occurTime)
              result = a.resolveTime - b.resolveTime
            else
              result = a.occurTime - b.occurTime
            break
          case 'resolveTime':
            if(a.resolveTime == b.resolveTime)
              result = a.occurTime - b.occurTime
            else
              result = a.resolveTime - b.resolveTime
            break
          case 'type':
            if(a.type == b.type)
              result = a.occurTime - b.occurTime
            else
              result = a.type - b.type
            break
          case 'resolver':
            if(a.resolver == b.resolver)
              result = a.occurTime - b.occurTime
            else
              result = a.resolver - b.resolver
            break
        }
        if(newPagination.descending)
          result = 0 - result;

        if(result > 0)
          return 1
        else if(result < 0)
          return -1
        else 
          return 0
      })

      this.csHistoryPagination = newPagination.page
      this.csHistoryPage = newPagination
    },
    csHistoryPageChanged(page) {
      console.log('csHistoryPageChanged : ', page)

      let sortby = this.csHistoryPage.sortBy
      let desc = this.csHistoryPage.descending
      this.csHistoryPage = { 
        sortBy : sortby,
        descending : desc,
        rowsPerPage : 7, 
        page : page
      }
    },

    csHistoryColumnClicked(rowData, row, column) {
      this.unusedParam(rowData)
      this.unusedParam(row)
      this.unusedParam(column)
    },

    // 소모품 메모 작성
    memoClicked(index) {
      this.viewComsumableOption = 1
      this.consumableContent = this.consumableData[index].title
      this.isEditConsMemo = false
      this.selectedConsumable = index

      if(TymCommon.isEmpty(this.consumableData[index].memoDate)) {
        // 데이터가 없으면 오늘
        const vmoment = moment()
        this.consMemoDate = vmoment.format("YYYY/MM/DD")
        this.editConsMemoDate = ''
      } else {
        // 20230327 : YYYY-MM-DD hh:mm 포맷으로 예상함
        const vmoment = moment(this.consumableData[index].memoDate, "YYYY-MM-DD HH:mm")
        this.consMemoDate = vmoment.format("YYYY/MM/DD")
        this.editConsMemoDate = TymCommon.convertStringToDateTime2(this.consumableData[index].memoDate).substring(0, 12)
        // console.log('memoClicked #2:', index, this.consMemoDate)
      }

      this.consInputMemo = this.consumableData[index].memo
    },
    consChangeClicked(index) {
      const store = useTymictStore()

      this.viewComsumableOption = 2
      this.consParts = []

      this.consumableIndex = index
      this.consumableTitle = this.consumableData[index].title
      this.consReplManager = store.idToken['name']
      let now = new Date()
      if(store.connectServer == 0) {
        this.consReplDate = TymCommon.getDateTimeString(now)
      } else {
        let curTZ = ''
        if(store.timezoneIndex == 0) {
            curTZ = Intl.DateTimeFormat().resolvedOptions().timeZone     // 브라우저 타임존, 한국은 Asia/Seoul
        } else {
            curTZ = store.timezoneList[store.timezoneIndex].value
        }

        const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
            hourCycle: "h23",
            year:"numeric",
            month:'2-digit',
            day:'2-digit',
            hour:'2-digit',
            minute:'2-digit',
            timeZone: curTZ
        })
        this.consReplDate = mediumTime.format(now)
      }
      this.consReplContents = ''
      this.isChangeCons = false
      this.modelConsData = null

      let salesData = store.SalesData.data.find(x => x.machineNo == this.machineInfo.machineNo)
      if(salesData) {
        let modelInfo = null
        if(TymCommon.isEmpty(salesData.Info)) {
          modelInfo = TymCommon.getModelFullname(salesData.MON)
        } else {
          modelInfo = salesData.Info
        }

        if(TymCommon.isEmpty(modelInfo.Option)) {
          this.modelConsData = store.ModelData.data.find(x => (x.model == modelInfo.Model && x.option == 'NONE' || x.option == '0'))
        } else {
          this.modelConsData = store.ModelData.data.find(x => (x.model == modelInfo.Model && x.option == modelInfo.Option))
        }
        
        if(this.modelConsData) {
          let langIdx = 0
          if(store.getLanguage() == 'en') {
            langIdx = 1
          }
          let cItems = this.modelConsData.citems[index]

          // console.log(store.ModelData.data, this.modelConsData, index,  cItems)

          cItems.sn.forEach(one => {
            let onePart = {
              Name : one.name[langIdx]              
            }
            onePart.PartNo = Array.from(one.numbers)
            this.consParts.push(onePart)
          })
        }

        this.consParts.forEach(part => {
          part.SelectNo = part.PartNo[0]
          part.PartNo.push(this.$t('machineDetail.notReplace'))
        })
      }

      // console.log(this.consParts)

      // this.curConsumableType = this.getConsumableTypes[3]     // 일단 디폴트 ETC
    },
    onResizeEditMemo(size) {
      if(this.consMemoClass) {
        if(size.width > (size.height * 0.9)) {
          //console.log('onResizeEditMemo : ROW')
          this.consMemoClass = 'row'
          this.consMemoCalClass = 'memoCalWidthAuto'
          this.consMemoViewClass = 'memoViewWidthAuto'
        } else {
          //console.log('onResizeEditMemo : COLUMN')
          this.consMemoClass = 'column'
          this.consMemoCalClass = 'memoCalWidth100'
          this.consMemoViewClass = 'memoViewWidth100'
        }
      }
    },
    changeConsMemoDate(value) {
      const vmoment = moment(value, "YYYY/MM/DD 00:00.000")
      let localTime = TymCommon.getDateString(vmoment)
      //const vm2 = moment(localTime)
      this.editConsMemoDate = localTime
    },
    closeConsMemo() {
      this.isEditConsMemo = false
      this.isChangeCons = false
      this.viewComsumableOption = 0
    },
    editConsMemo() {
      this.isEditConsMemo = true
      if(TymCommon.isEmpty(this.consumableData[this.selectedConsumable].memoDate)) {
        this.editConsMemoDate = TymCommon.convertStringToDateTime2(this.consumableData[this.selectedConsumable].memoDate)
      } else {
        this.editConsMemoDate = TymCommon.convertStringToDateTime2(this.consumableData[this.selectedConsumable].memoDate).substring(0, 12)
      }
      const store = useTymictStore()
      this.emCosManager = store.idToken['name']
      // this.emCosManager = rowData.resolver
    },
    saveConsMemo() {
      console.log('saveConsMemo :')
      TymAws.updateCItemServiceMemo(this.machineInfo.machineNo, this.consumableData[this.selectedConsumable].index + 1, this.consInputMemo)
        .then(data => {
          console.log('saveConsMemo OK :', data)
          this.isEditConsMemo = false
          setTimeout(() => {
            this.updateData(this.info)
          }, 25)
        })
        .catch(er => {
          console.log('saveConsMemo error :', er)
        })
    },
    deleteConsMemo() {
      TymAws.updateCItemServiceMemo(this.machineInfo.machineNo, this.consumableData[this.selectedConsumable].index + 1, '')
        .then(data => {
          console.log('deleteConsMemo OK :', data)
          this.isEditConsMemo = false
          setTimeout(() => {
            this.updateData(this.info)
          }, 25)
        })
        .catch(er => {
          console.log('deleteConsMemo error :', er)
        })
    },

    // 소모품 교체 날짜 변경 팝업이 뜰 때
    replaceDateToPopup () {
      // let now = new Date()
      let nowStr = ''
      const store = useTymictStore()

      if(store.connectServer == 0) {
        this.consReplDateCal = moment(this.consReplDate).format("YYYY/MM/DD")
        this.consReplTimeCal = moment(this.consReplDate).format("HH:mm")

        nowStr = moment(this.consReplDate).format("YYYY-MM-DD HH:mm")
        // console.log('KOR', nouw, this.consReplDateCal, this.consReplTimeCal, nowStr)
      } else {
        this.consReplDateCal = moment(this.consReplDate).format("YYYY/MM/DD")
        this.consReplTimeCal = moment(this.consReplDate).format("HH:mm")

        nowStr = moment(this.consReplDate).format("YYYY-MM-DD HH:mm")
        // console.log('NA ', this.consReplDateCal, this.consReplTimeCal, nowStr)
      }

      /// nowStr : 문자열 형태(yyyy-mm-dd hh:MM)
      this.consReplTitle = TymCommon.convertStringToDate(nowStr)
    },
    updateConsReplDate() {
      this.consReplTitle = TymCommon.convertStringToDate(this.consReplDateCal)
    },
    updateConsReplTime() {
      // console.log('updateConsReplTime :', this.consReplTimeCal)
    },
    closeConsReplDateTime() {
      this.$refs.consReplDatePopup.hide()
    },
    // 소모품 교체 - 날짜 시간 선택 팝업에서 적용버튼 누를 때
    applyConsReplDateTime() {
      const myDate = moment(this.consReplDateCal + ' ' + this.consReplTimeCal, 'YYYY/MM/DD HH:mm').toDate();
      this.consReplDate = TymCommon.getDateTimeString(myDate)
      /*
      console.log('applyConsReplDateTime #1:', this.consReplDateCal, this.consReplTimeCal)
      console.log('applyConsReplDateTime #2:', myDate)
      console.log('applyConsReplDateTime #3:', this.consReplDate)
      */
      this.$refs.consReplDatePopup.hide()
    },
    editConsChange() {
      this.isChangeCons = true
    },
    cancelConsChange() {
      console.log('cancelConsChange :')
    },
    saveConsChange() {
      if(TymCommon.isEmpty(this.modelConsData)) {
        console.log('save change cons : empty model for cons', this.modelConsData)
        TymCommon.Toast(this.$t('warning.noModelInfo'))
        return
      }

      /*
      if(TymCommon.isEmpty(this.consReplDate)) {
        this.consReplDate = moment(new Date()).format('YYYY/MM/DD')
      }
      if(TymCommon.isEmpty(this.consReplTimeCal)) {
        this.consReplTimeCal = moment(new Date()).format('hh:mm')
      }
      const myDate = new Date(this.consReplDate + ' ' + this.consReplTimeCal)
      */
      
      let cItem = this.modelConsData.citems[this.consumableIndex]
      let partInfo = []
      for(let idx = 0; idx < this.consParts.length; idx++) {
        if(this.consParts[idx].SelectNo != this.consParts[idx].PartNo[this.consParts[idx].PartNo.length - 1]) {
          let partNoIdx = this.consParts[idx].PartNo.findIndex(x => x == this.consParts[idx].SelectNo)
          if(partNoIdx >= 0) {
            // console.log(cItem.sn[idx].name, cItem.sn[idx].numbers[partNoIdx])
            partInfo.push({
              partNumber : cItem.sn[idx].numbers[partNoIdx],
              partName : cItem.sn[idx].name
            })
          }
        }
      }

      // RTime : 2022-05-17 11:21
      let RTime = ''
      const store = useTymictStore()
      
      if(store.connectServer == 0) {
        RTime = moment(this.consReplDate).format("YYYY-MM-DD HH:mm")
      } else {
        let d0 = new Date(this.consReplDate)
        let d1 = d0.getFullYear().toString() + '-' + (d0.getMonth() + 1).toString().padStart(2, '0') + '-' + d0.getDate().toString().padStart(2, '0')
        d1 += ' ' + d0.getHours().toString().padStart(2, '0') + ":" + d0.getMinutes().toString().padStart(2, '0') + ":00"
        RTime = moment.tz(d1, TymCommon.getTimeZone()).utc().format("YYYY-MM-DD HH:mm")
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('   > ', TymCommon.getTimeZone())
        console.log('   > ', this.consReplDate, RTime)
        console.log('   > ', this.machineInfo.machineNo, this.consumableIndex + 1, RTime, this.consReplManager, this.consReplContents, cItem.name, partInfo)
      }
      //function updateCItemStateV3(mn, citem, rTime, resolver, rmemo, category, partsInfo)
      TymAws.updateCItemStateV3(this.machineInfo.machineNo, this.consumableIndex + 1, RTime, this.consReplManager, this.consReplContents, cItem.name, partInfo)
        .then(data => {
          // console.log('TymAws.updateCItemState OK :', data)
          this.unusedParam(data)
          this.closeConsMemo()
          setTimeout(() => {
            this.reloadConsAlert(this.machineInfo)
          }, 10)
        })
        .catch(er => {
          console.log('TymAws.updateCItemState ERROR :', er)
          if(er == 'No regist SN') {
            TymCommon.Toast(this.$t('errorMsg.noUsageInfo'), true)
          } else if(er == 'data is null') {
            TymCommon.Toast(this.$t('errorMsg.emptyResult'), true)
          } else if(er == 'Empty result') {
            TymCommon.Toast(this.$t('errorMsg.emptyResult'), true)
          } else {
            TymCommon.Toast(er, true)
          }

          this.isChangeCons = false
        })
    },
    afterDateSelect(option) {
      let selectedDate = null

      switch(option) {
        case 'emHistoryStart':
        case 'emHistoryEnd':
          selectedDate = moment(this.emHistoryDateCal, 'YYYY/MM/DD')
          break
        case 'brHistoryStart':
        case 'brHistoryEnd':
          selectedDate = moment(this.brHistoryDateCal, 'YYYY/MM/DD')
          break
        case 'consHistoryStart':
        case 'consHistoryEnd':
          selectedDate = moment(this.csHistoryDateCal, 'YYYY/MM/DD')
          break
      }

      let dstDate = TymCommon.convertStringToDate(selectedDate)
      if(TymConst.IS_DEVELOPMENT) {
        console.log('afterDateSelect :', option, dstDate)
      }

      switch(option) {
        case 'emHistoryStart':
          this.emHistoryStart = dstDate
          this.emHistoryDateCal = null 
          this.$refs.emHistoryStartCal.hide()
          break
        case 'emHistoryEnd':
          this.emHistoryEnd = dstDate
          this.emHistoryDateCal = null
          this.$refs.emHistoryEndCal.hide()
          break
        case 'brHistoryStart':
          this.brHistoryStart = dstDate
          this.brHistoryDateCal = null
          this.$refs.brHistoryStartCal.hide()
          break
        case 'brHistoryEnd':
          this.brHistoryEnd = dstDate
          this.brHistoryDateCal = null
          this.$refs.brHistoryEndCal.hide()
          break
        case 'consHistoryStart':
          this.csHistoryStart = dstDate
          this.csHistoryDateCal = null
          this.$refs.csHistoryStartCal.hide()
          break
        case 'consHistoryEnd':
          this.csHistoryEnd = dstDate
          this.csHistoryDateCal = null
          this.$refs.csHistoryEndCal.hide()
          break
      }
    },

    popupDateSelect(option) {
      let date = null
      let srcDateStr = '', dstDate = null

      switch(option) {
        case 'emHistoryStart':
          srcDateStr = this.emHistoryStart
          break
        case 'emHistoryEnd':
          srcDateStr = this.emHistoryEnd
          break
        case 'brHistoryStart':
          srcDateStr = this.brHistoryStart
          break
        case 'brHistoryEnd':
          srcDateStr = this.brHistoryEnd
          break
        case 'consHistoryStart':
          srcDateStr = this.csHistoryStart
          break
        case 'consHistoryEnd':
          srcDateStr = this.csHistoryEnd
          break
      }

      srcDateStr = srcDateStr.trim()
      if(TymCommon.isEmpty(srcDateStr)) {
        date = new Date()
      } else {
        date = new Date(srcDateStr)
      }
//      dstDate = TymCommon.getDateString(moment(date, "YYYY/MM/DD"))
      dstDate = moment(date).format("YYYY/MM/DD")
      
      if(TymConst.IS_DEVELOPMENT) {
        console.log('popupDateSelect :', option, '-', srcDateStr, '-', date, '-', dstDate)
      }

      switch(option) {
        case 'emHistoryStart':
          this.emHistoryDateCal = dstDate
          break
        case 'emHistoryEnd':
          this.emHistoryDateCal = dstDate
          break
        case 'brHistoryStart':
          this.brHistoryDateCal = dstDate
          break
        case 'brHistoryEnd':
          this.brHistoryDateCal = dstDate
          break
        case 'consHistoryStart':
          this.csHistoryDateCal = dstDate
          break
        case 'consHistoryEnd':
          this.csHistoryDateCal = dstDate
          break
      }
    },

    downloadToExcel(option) {
      let now = new Date()

      this.hasExcelData = true
      this.makingExcel = true

      switch(option) {
        case 'emergency' :
          this.excelFilename = 'emergencyHist_'
          this.excelFrom = this.emHistoryStart
          this.excelTo = this.emHistoryEnd
          break;
          case 'error' :
          this.excelFilename = 'errorHist_'
          this.excelFrom = this.brHistoryStart
          this.excelTo = this.brHistoryEnd
          break;
        case 'consumables' :
          this.excelFilename = 'consHist_'
          this.excelFrom = this.csHistoryStart
          this.excelTo = this.csHistoryEnd
          break;
      }

      this.excelFilename += this.info.machineNo + '_'

      this.excelFilename += now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += now.getHours().toString() + (now.getMinutes() + 1).toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += ".xlsx"

      this.excelData = []
      let self = this
      switch(option) {
        case 'emergency' :
          TymAws.getEmergencyHistoryByMN(this.machineInfo.machineNo, this.makeDateString2QueryString(this.emHistoryStart, true), this.makeDateString2QueryString(this.emHistoryEnd, false))
            .then(data => {
              if(data.length < 1) {
                self.hasExcelData = false
                self.makingExcel = false
                return;
              }
              self.hasExcelData = true

              data.sort((a, b) => {
                return b.RTime.localeCompare(a.RTime)
              })
              console.log(data)
              self.excelData = data
              self.makingExcel = false
            })
            .catch(er => {
              console.log(er)
              self.hasExcelData = false
              self.makingExcel = false
            })
          break;
        case 'error' :
          TymAws.getBrokenHistoryByMN(this.machineInfo.machineNo, this.makeDateString2QueryString(this.brHistoryStart, true), this.makeDateString2QueryString(this.brHistoryEnd, false))
            .then(data => {
              if(data.length < 1) {
                self.hasExcelData = false
                self.makingExcel = false
                return;
              }
              self.hasExcelData = true

              data.sort((a, b) => {
                return b.RTime.localeCompare(a.RTime)
              })
              self.excelData = data
              self.makingExcel = false
            })
            .catch(er => {
              console.log(er)
              self.hasExcelData = false
              self.makingExcel = false
            })
          break;
        case 'consumables' :
          TymAws.getConsumablesHistoryByMN(this.machineInfo.machineNo, this.makeDateString2QueryString(this.csHistoryStart, true), this.makeDateString2QueryString(this.csHistoryEnd, false))
            .then(data => {
              if(data.length < 1) {
                self.hasExcelData = false
                self.makingExcel = false
                return;
              }
              /*
              {
                "MN": "PS130X00651",
                "MEMO": "",
                "MON": "TS130_CP6ANBV",
                "OTime": "2024-04-11 19:38",
                "PARTSNAME": [
                  "엘레멘트, /25MIC",
                  "Element, /25MIC"
                ],
                "RVut": 188,
                "CTYPE": "8",
                "PARTNO": "10135153110",
                "CATEGORY": [
                  "고압 필터",
                  "High Pressure Filter"
                ],
                "OVut": 188,
                "RESOLVER": "횡성딜러점(전병수)",
                "MA": "0",
                "MB": "2",
                "MC": "6",
                "MD": "1678930580342-gangwon",
                "ATime": "20240416:101607.396",
                "RTime": "2024-03-28 10:16"
              }
              */
              if(TymConst.IS_DEVELOPMENT) {
                console.log('소모품 교체 이력 :', data)
              }
              self.hasExcelData = true

              data.sort((a, b) => {
                return b.RTime.localeCompare(a.RTime)
              })
              self.excelData = data
              self.makingExcel = false
            })
            .catch(er => {
              console.log(er)
              self.hasExcelData = false
              self.makingExcel = false
            })
          break;
      }
      if(!self.hasExcelData) {
        setTimeout(() => {
          if(this.$refs.excelPopup)
            this.$refs.excelPopup.hide()
        }, 2000)
      }
    },
    saveEmHistoryToExcelFile() {
      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 2000)
      if(this.excelData.length < 1) {
        this.hasExcelData = false
        return
      }
      if(!this.hasExcelData) {
        console.log('--------- HAS NO DATA ----------')
        return
      }

      let desc = {
        filename: this.excelFilename,
        vin: this.info.machineNo,
        sn: this.info.serialNo,
        start: this.excelFrom,
        end: this.excelTo,
        keyword: "긴급 알림 이력"
      }
      const store = useTymictStore()
      let tzName = ''
      if(store.connectServer != 0) {
        if(store.timezoneIndex == 0) {
          tzName = Intl.DateTimeFormat().resolvedOptions().timeZone 
        } else {
          tzName = store.timezoneList[store.timezoneIndex].value
        }
        desc.timezone = tzName
      }

      TymAws.writeAction('관리', '관리', desc)

      let xlsRow1 = ['번호', '발생 일자', '해결 일자', '알림 내용', '사용시간', '담당자', '조치 내용']
      let othRows = []
      this.sheets = []

      /*
      {
        "MN": "S130PH00184",
        "ATimePN": "20230717:105358.999^1",
        "MON": "TS130Z_CP6ANB",
        "OTime": "2023-07-17 10:53",
        "MA": "0",
        "RESOLVER": "TYM",
        "VUT": 243,
        "ETYPE": "1",
        "MB": "1641201354576-fing0828",
        "MC": " ",
        "RMEMO": "TEST",
        "MD": " ",
        "RTime": "2023-09-11 00:00"
      }
      */
      if(TymConst.IS_DEVELOPMENT) {
        console.log('saveEmHistoryToExcelFile :', this.excelData)
      }
      let index = 1
      this.excelData.forEach(one => {
        let oTime = TymCommon.convertStringToDateTime2(one.OTime);
        let rTime = TymCommon.convertStringToDateTime2(one.RTime);
        othRows.push([index, oTime, rTime, this.EmergencyTypeString[Number(one.ETYPE)], one.VUT, one.RESOLVER, one.RMEMO])
        index++
      })

      let sheetName = '긴급이력(' + this.info.machineNo + ')'
      sheetName = sheetName.replace('/', '.')

      othRows.splice(0, 0, xlsRow1)
      if(store.connectServer != 0) {
        othRows.splice(0, 0, ['TimeZone', tzName])
      }
      othRows.splice(0, 0, [this.info.machineNo, this.excelFrom + '-' + this.excelTo])

      this.sheets.push({
        name : sheetName,
        data : othRows
      })
    },
    saveBrHistoryToExcelFile() {
      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 2000)
      if(this.excelData.length < 1) {
        this.hasExcelData = false
        return
      }

      let desc = {
        filename: this.excelFilename,
        vin: this.info.machineNo,
        sn: this.info.serialNo,
        start: this.excelFrom,
        end: this.excelTo,
        keyword: "고장 이력"
      }
      const store = useTymictStore()
      let tzName = ''
      if(store.connectServer != 0) {
        if(store.timezoneIndex == 0) {
          tzName = Intl.DateTimeFormat().resolvedOptions().timeZone 
        } else {
          tzName = store.timezoneList[store.timezoneIndex].value
        }
        desc.timezone = tzName
      }

      TymAws.writeAction('관리', '관리', desc)

      let xlsRow1 = ['번호', '발생 일자', '해결 일자', '에러코드', '내용', '사용시간', '담당자', '조치 내용']
      let othRows = []
      this.sheets = []

      /*
      {
        "MN": "S130PH00184",
        "ATimePN": "20230228:132419.446^EC2_38",
        "BTYPE": "1",
        "MON": "TS130Z_CP6ANBV",
        "DN": "3P 작업기 위치센서 이상",
        "OTime": "2023-02-28 13:24",
        "ECODE": "EC2_38",
        "MA": "0",
        "RESOLVER": "TYM",
        "VUT": 217,
        "MB": "1641201354576-fing0828",
        "MC": " ",
        "RMEMO": "test",
        "MD": " ",
        "RTime": "2023-02-28 13:24"
      }
      */
      if(TymConst.IS_DEVELOPMENT) {
        console.log('saveBrHistoryToExcelFile :', this.excelData)
      }
      let index = 1
      this.excelData.forEach(one => {
        let oTime = TymCommon.convertStringToDateTime2(one.OTime);
        let rTime = TymCommon.convertStringToDateTime2(one.RTime);
        othRows.push([index, oTime, rTime, one.ECODE, one.DN, one.VUT, one.RESOLVER, one.RMEMO])
        index++
      })

      let sheetName = '고장이력(' + this.info.machineNo + ')'
      sheetName = sheetName.replace('/', '.')

      othRows.splice(0, 0, xlsRow1)
      if(store.connectServer != 0) {
        othRows.splice(0, 0, ['TimeZone', tzName])
      }
      othRows.splice(0, 0, [this.info.machineNo, this.excelFrom + '-' + this.excelTo])
      console.log(sheetName, othRows)

      this.sheets.push({
        name : sheetName,
        data : othRows
      })
    },
    saveCsHistoryToExcelFile() {
      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 2000)
      if(this.excelData.length < 1) {
        this.hasExcelData = false
        return
      }

      let desc = {
        filename: this.excelFilename,
        vin: this.info.machineNo,
        sn: this.info.serialNo,
        start: this.excelFrom,
        end: this.excelTo,
        keyword: "소모품 교체 이력"
      }
      const store = useTymictStore()
      let tzName = ''
      if(store.connectServer != 0) {
        if(store.timezoneIndex == 0) {
          tzName = Intl.DateTimeFormat().resolvedOptions().timeZone 
        } else {
          tzName = store.timezoneList[store.timezoneIndex].value
        }
        desc.timezone = tzName
      }
      TymAws.writeAction('관리', '관리', desc)

      // let xlsRow1 = ['번호', '발생 일자', '발생시 운행시간', '교체 일자', '발생시 운행시간', '소모품명', '내용', '담당자']
      let xlsRow1 = ['번호', '발생 일자', '발생시 운행시간', '교체 일자', '발생시 운행시간', '카테고리', '부품명', '부품 번호', '내용', '담당자']
      let othRows = []
      this.sheets = []

      /*
      {
        "MN": "TX76SD00958",
        "MEMO": "농협에서교환",
        "MON": "TX76_CQPAN",
        "OTime": "2023-09-23 06:52",
        "RVut": 139,
        "CTYPE": "4",
        "OVut": 139,
        "RESOLVER": "울산-김지태",
        "MA": "0",
        "MB": "2",
        "MC": "13",
        "MD": "1684458796330-gyeongnam",
        "ATime": "20230924:163829.831",
        "RTime": "2023-09-04 16:38"
      }
      */
      if(TymConst.IS_DEVELOPMENT) {
        console.log('saveCsHistoryToExcelFile :', this.excelData)
      }
      let index = 1
      let self = this
      
      this.excelData.forEach(one => {
        let category = TymCommon.isEmpty(one.CTYPE) ? '' : self.$t('consumables.' + one.CTYPE)
        let partname = ''
        if(!TymCommon.isEmpty(one.CATEGORY)) {
          category = one.CATEGORY[this.isKorean ? 0 : 1]
        }
        if(!TymCommon.isEmpty(one.PARTSNAME)) {
          partname = one.PARTSNAME[this.isKorean ? 0 : 1]
        }
        let oTime = TymCommon.convertStringToDateTime2(one.OTime);
        let rTime = TymCommon.convertStringToDateTime2(one.RTime);
        othRows.push([index, oTime, one.OVut, rTime, one.RVut, category, partname, one.PARTNO, one.MEMO, one.RESOLVER])
        index++
      })

      let sheetName = '교체이력(' + this.info.machineNo + ')'
      sheetName = sheetName.replace('/', '.')

      othRows.splice(0, 0, xlsRow1)
      if(store.connectServer != 0) {
        othRows.splice(0, 0, ['TimeZone', tzName])
      }
      othRows.splice(0, 0, [this.info.machineNo, this.excelFrom + '-' + this.excelTo])
      console.log(sheetName, othRows)

      this.sheets.push({
        name : sheetName,
        data : othRows
      })
    },
    getBrokenDetailOK(items) {
      if(!Array.isArray(items)) {
        return
      }
      if(items.length < 1) {
        return
      }
      let self = this
      const store = useTymictStore()
      
      // console.log('getBrokenDetailOK :', items)

      items.forEach(item => {
        item.forEach(one => {
          if(!TymCommon.isEmpty(one.MANUAL)) {
            let found = self.brRows.find(x => x.ckey == one.ATimePN)
            if(found) {
              let oneMan = JSON.parse(one.MANUAL)
              oneMan.forEach(oneManItem => {
                Object.keys(oneManItem).forEach(key => {
                  if(key == store.Language) {
                    found.manual = one.MANUAL
                    return
                  }
                })
              })
            }
          }
        })
      })
    },
    async downloadManualFile(model, lang, eCode, ver) {
      const store = useTymictStore()

      let s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: {
          Bucket: TymConst.BUCKET_MANUAL_NAME
        },
        region: store.cognito[store.connectServer].region
      })

      let filename = model + '_' + lang + '_' + eCode + '_' + ver + '.pdf'
      let fullPath = 'public/' + model + '/' + lang + '/' + ver + '/' + eCode + '.pdf'
      console.log('downloadFile :', filename, fullPath)
      s3.getObject({Key: fullPath}, function(err, obj) {
        if(err) {
          console.log(fullPath + ' was not loaded.')
        }
        else {
          console.log('getObject ', obj)
          var abc = new Blob([obj.Body], {type : obj.ContentType})
          if (window.navigator.msSaveOrOpenBlob)  {
            // window.navigator.msSaveOrOpenBlob(abc, imgPath);
          } else {
            let anchor = document.getElementById('downLink')
            anchor.href = URL.createObjectURL(abc)
            anchor.download = filename
            anchor.click()
          }
        }
      })
    },
    /*
    info
    {
      "index": 0,
      "MON": "K110EZ_NONE",
      "occurTime": "2023. 12. 12. 09:00",
      "resolveTime": "2024. 01. 17. 15:04",
      "erType": "5",
      "erCode": "0_19",
      "erMsg": "CAN 수신 타임아웃 에러",
      "vut": 155,
      "resolver": "TYM국내영업본부",
      "resolveMsg": "TEST",
      "manual": "[{\"en\":\"2\"},{\"ko\":\"1\"}]"
    }
    */
    downloadManual(info) {
      const store = useTymictStore()
      let manual = JSON.parse(info.manual)
      let modelInfo = TymCommon.getModelFullname(info.MON)
      let manLang = null, manVer = null
      //console.log(info, modelInfo)

      manual.forEach(one => {
        Object.keys(one).forEach(key => {
          if(key == store.getLanguage()) {
            manLang = key
            manVer = one[key]
            return
          }
        })
      })

      let errorCode = null
      if(!TymCommon.isEmpty(info.ecode))
        errorCode = info.ecode
      else if(!TymCommon.isEmpty(info.erCode))
        errorCode = info.erCode

      if(TymConst.IS_DEVELOPMENT) {
        console.log('downloadManual info :', info)
        console.log('downloadManual manual :', manual)
        console.log('downloadManual errorCode :', errorCode)
        console.log('downloadManual manLang :', manLang)
        console.log('downloadManual manVer :', manVer)
      }

      this.downloadManualFile(modelInfo.Model, manLang, errorCode, manVer)
        .then(data => {
          console.log('download manual completed.', data)
        })
        .catch(er => {
          console.log('download manual failed.', er)
        })
      /*
      info.forEach(man => {
        man.keys
      })
      */
    }
  }
})
</script>
