<template>
  <div ref="mainLayout" >
    <q-layout view="hHh Lpr LFf" style="width:100%;">
      <q-header elevated class="myHeader shadow-1 row items-center" style="height:74px;">
        <q-toolbar class="col">
          <!-- 3선 메뉴 -->
          <q-btn class="row" flat dense  @click="menuClicked" v-show="!needLongLogo" style="margin-left:3px;">
            <q-img class="col" v-show="isDrawerOpen" src="../assets/menu/main_view_list_on.svg" fit="contain" width="20px" height="20px">
              <!--
              <q-tooltip>메뉴 감추기</q-tooltip>
              -->
            </q-img>
            <q-img class="col" v-show="!isDrawerOpen" src="../assets/menu/main_view_list_non.svg" fit="contain" width="20px" height="20px">
              <!--
              <q-tooltip>메뉴 보이기</q-tooltip>
              -->
            </q-img>            
          </q-btn>
          <!-- TYM & SAM 이미지 -->
          <q-btn flat dense @click="companyClicked" v-show="needLongLogo" style="margin-left:0">
            <img src="~../assets/menu/main_logo_s.svg" />
          </q-btn>
          <q-btn flat dense @click="companyClicked" v-show="!needLongLogo" style="margin:0 0 4px 22px;">
            <img src="~../assets/menu/log_logo_s_tym.svg" />
          </q-btn>

          <q-space />

          <div v-if="isDev" class="row">
            <div class="row q-md-xs">
              <q-input class="col" flat dense outlined clearable borderless="false" color="grey-7" v-model="devInput" placeholder="VIN"></q-input>
              <q-btn class="col-auto" dense label="뭔가 해봐" @click="devDoIt" style="margin:0 0 0 6px;"></q-btn>
            </div>
          </div>
          <q-space v-if="isDev"/>

          <div class="col-auto column justify-between">
            <div class="col-auto row">
              <!-- 사용자 정보 아이콘 -->
              <q-btn flat dense no-caps no-wrap @click="viewMyAccount">
                <div class="row items-center no-wrap" :class="isLandscape ? 'row' : 'column'">
                  <q-img width="22px" height="22px" src="../assets/menu/dashboard_account_icon.svg">
                  </q-img>
                  <div class="text-center"
                      :style="(isLandscape ? 'margin-left:5px;' : 'margin-left:0px;') + (isKorean ? 'font-family:Noto Sans KR;font-size:15px;' : 'font-family:Prometo;font-size:16px;margin-top:1px;')"
                      v-show="needLongLogo && isLandscape">
                    {{ getUserName() }}
                  </div>
                </div>
                <q-tooltip style="font-size:14px;" model-value="showPersonalTooltip" v-show="!needLongLogo">{{ getUserInfo }}</q-tooltip>
              </q-btn>

              <q-separator vertical style="width:10px;background-color: transparent;"/>

              <!-- 앱다운로드 -->
              <q-btn flat dense no-caps no-wrap>
                <q-img width="22px" height="22px" src="../assets/menu/dashboard_down_icon.svg">
                </q-img>
                <q-tooltip style="font-size:14px;" model-value="showPersonalTooltip" v-show="!needLongLogo">{{ $t("loginmenu.downloadApp") }}</q-tooltip>
                <q-menu dense class="column" max-width="156px" style="font-family:'Prometo';background-color: #f2f2f2;overflow-x: hidden;">
                  <div @click="selectAndroid" class="col column items-start"
                       :class="selectedAppMode == 1 ? 'activeBtn' : 'appBtn'">
                    <div class="col" style="font-family:'Prometo';font-size:14px;margin:6px 12px 0px 12px;">Android Mobile</div>
                    <div class="col column justify-start items-center" style="margin:0 12px 6px 12px;">
                      <div class="col-auto row"
                           :style="selectedAppMode == 1 ? 'border:1px solid #f2f2f2;' : 'border:1px solid #f2f2f2;'"
                           style="width:132px;height:48px;border-radius:3px;margin:0;background-color:white;">
                        <q-img class="col" src="../assets/menu/dashboard_google_icon.svg" fit="contain" width="auto" height="auto"></q-img>
                      </div>
                    </div>
                  </div>
                  <div class="col row" v-show="selectedAppMode == 1"
                       style="width:132px;height:140px;background:white;border-radius:3px;margin:6px 12px;border:1px solid #d0d0d0;">
                    <q-img class="col" :src="isKorea ? require('../assets/dashboard/dashboard_qr_kr_android.png') : require('../assets/dashboard/dashboard_qr_us_android.png')"
                           fit="none"></q-img>
                  </div>

                  <div @click="selectIOS" class="col column items-start"
                       :class="selectedAppMode == 2 ? 'activeBtn' : 'appBtn'">
                    <div class="col" style="font-family:'Prometo';font-size:14px;margin:6px 12px 0px 12px;">iOS Mobile</div>
                    <div class="col column justify-start items-center" style="margin:0 12px 6px 12px;">
                      <div class="col-auto row"
                           :style="selectedAppMode == 2 ? 'border:1px solid #f2f2f2;' : 'border:1px solid #f2f2f2;'"
                           style="width:132px;height:48px;border-radius:3px;margin:0;background-color:white;">
                        <q-img class="col" src="../assets/menu/dashboard_ios_icon.svg" fit="contain" width="auto" height="auto"></q-img>
                      </div>
                    </div>
                  </div>
                  <div class="col row" v-show="selectedAppMode == 2"
                       style="width:132px;height:140px;background:white;border-radius:3px;margin:6px 12px 12px 12px;border:1px solid #d0d0d0;">
                    <q-img class="col" :src="isKorea ? require('../assets/dashboard/dashboard_qr_kr_ios.png') : require('../assets/dashboard/dashboard_qr_us_ios.png')"
                           fit="none"></q-img>
                  </div>
                </q-menu>
              </q-btn>

              <q-separator vertical style="width:10px;background-color: transparent;"/>
              <!-- 로그아웃 -->
              <q-btn flat dense no-caps no-wrap @click="doLogout">
                <q-img width="22px" height="22px" src="../assets/menu/dashboard_logout_icon.svg">
                </q-img>
                <q-tooltip style="font-size:14px;">{{ $t("loginmenu.logout") }}</q-tooltip>
              </q-btn>
            </div>
            <div class="col-auto row items-center justify-end" v-if="isDev">
              <div class="col-auto" style="font-family:Prometo;font-size:15px;font-weight:500;color:#808080;margin:6px 0 0 0;">
                {{ watchString }}
              </div>
            </div>
          </div>
          <!-- 언어변경 버튼
          <LanguageManager v-show="true" v-bind:kind="main"/>
          -->
        </q-toolbar>
      </q-header>

      <!--
      <q-footer elevated class="bg-blue-1" style="display:none;max-height:60%;height:auto;overflow-y:auto;">
        <RecentNotiView/>
      </q-footer>
      -->

      <q-drawer v-model="isDrawerOpen" :overlay="isDrawerOverlay" behavior="desktop" :mini="!needLongMenu" class="column justify-between no-wrap">
        <div ref="drawerDiv" class="col-auto">
          <!--:mini="!showMenuText"
          <div class="row justify-end" style="width:100%;padding:5pt">
            <!- 메뉴 크기 변경 ->
            <q-btn dense flat class="col-auto" size="14pt" icon="list"
                    :color="showMenuText ? 'grey-6' : 'red-7'" 
                    @click="showMenuText = !showMenuText">
            </q-btn>
            <!- 푸시핀(오버레이) 버튼 ->
            자동으로 변경 요청
            <q-btn dense flat class="col-auto" size="14pt" icon="push_pin"
                    :color="isDrawerOverlay ? 'grey-6' : 'red-7'" 
                    @click="overlayPinClicked">
            </q-btn>
          </div>
          <q-separator style="margin:0pt 5pt 5pt 5pt"/>
            -->

          <q-item clickable
                  v-for="(item,index) in menuList" :key="item"
                  class="menutext"
                  @click="menuItemClicked(index)"
                  v-show="canShowMenu(index)"
                  :active="selectedMenu == index"
                  :style='needLongMenu ? "height:auto;" : "height:56px;margin-top:5px;"'
                  :active-class='needLongMenu ? "selectedMenuVar" : "unselectedMenuVar"'>

            <q-item-section avatar v-if="needLongMenu">
              <q-img :src="(selectedMenu == index) ? item.iconOn : item.iconOff" width="24px" height="24px"/>
            </q-item-section>
            <q-item-section v-if="needLongMenu" 
                            :style="isKorean ? 'font-family:Noto Sans KR;font-size:16px;font-weight:500;' : 'font-family:Prometo;font-size:17px;font-weight:400;margin-top:1px;'">
              {{getMenuTitle(index)}}
            </q-item-section>

            <q-item-section side v-if="needLongMenu && ((index == 1) ? (machineCount > 0) : false)">
              <!-- 차량 댓수 -->
              <div>{{ machineCount }}</div>
            </q-item-section>
            <q-item-section side v-if="needLongMenu && ((index == 3) ? (salesCount > 0) : false)">
              <!-- 판매현황 -->
              <div v-show="!isLoading && false">{{ salesCount }}</div>
            </q-item-section>
            <q-item-section side v-if="needLongMenu && ((index == 2) ? (manageCount > 0) : false)">
              <!-- 관리 -->
              <div v-show="!isLoading && false">{{ manageCount }}</div>
            </q-item-section>

            <q-item-section side v-if="needLongMenu && ((index == 5) ? (noticeCount > 0) : false)">
              <div v-show="!isLoading">{{ noticeCount }}</div>
            </q-item-section>

            <!-- 작은 사이즈 -->
            <q-item-section avatar v-if="!needLongMenu">
              <div class="column justify-start items-center" style="width:30px;height:30px;">
                <div class="col row justify-center items-center" :class='selectedMenu==index ? "thinMenuSelected" : "thinMenuNormal"' >
                  <q-img :src="item.iconOnly" width="24px" height="24px" v-show="selectedMenu==index"/>
                  <q-img :src="item.iconOff" width="24px" height="24px" v-show="selectedMenu!=index"/>
                </div>
                <div class="col-1" :style="(selectedMenu==index) ? 'color:#eb0028;' : 'color:#212529'" v-show="(index == 1) && (machineCount > 0) && false">{{ machineCount }}</div>
                <div class="col-1" :style="(selectedMenu==index) ? 'color:#eb0028;' : 'color:#212529'" v-show="(index == 3) && (salesCount > 0) && false">{{ salesCount }}</div>
                <div class="col-1" :style="(selectedMenu==index) ? 'color:#eb0028;' : 'color:#212529'" v-show="(index == 2) && (manageCount > 0) && false">{{ manageCount }}</div>
                <div class="col-1" :style="(selectedMenu==index) ? 'color:#eb0028;' : 'color:#212529'" v-show="(index == 5) && (noticeCount > 0)">{{ noticeCount }}</div>
                <!--
                <div class="col-1" v-show="(item.count > 0)">{{item.count}}</div>
                -->
              </div>
            </q-item-section>
          </q-item>
        </div>
        
        <div class="col-auto" v-if="needLongMenu" style="margin:0 0 14px 16px;font-size:15px;font-weight:500; line-height: 22px;color:#eb0028;">
          <span>Designed</span>
          &nbsp;<span>for
            <q-tooltip style="font-family:Prometo;font-size:15px;margin:0;">{{ getVersion() }}</q-tooltip>
          </span>
          &nbsp;<span>your world</span>
        </div>
      </q-drawer>

      <q-page-container style="background-color:#f2f2f2;overflow-y:auto;display:grid;width:100%;height:100%;">
        <q-page style="display:flex;width:100%;height:100%;">
          <div ref="containerDiv" class="pageContainer">
            <router-view  :class="singlePage_class" style="padding:0"/>
            <!-- 가로방향 스플리터 -->
            <q-splitter v-model="horizSplitterValue" :limits="[30, 90]" 
                        v-show="showSplitterPart"
                        :separator-style="horizSplitterStyle"
                        horizontal
                        :disable="horizSplitterDisable"
                        style="padding:0 12px 12px 12px"
                        separator-class="transparent" :class="splitter_class">
              <template v-slot:before>

                <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
                <q-splitter v-model="vertSplitterValue" :limits="[30, 80]" 
                            :separator-style='!isLandscape ? "height:12px" : "width:12px"'
                            :horizontal="!isLandscape" 
                            separator-class="transparent" v-if="isVisibleSplitter" :class="splitter_class" style="overflow-y: hidden;">

                  <template v-slot:before>
                    <router-view name="left" style="height:100%;"/>
                  </template>

                  <template v-slot:separator>
                    <div :class='isLandscape ? "vertSeparator" : "horizSeparator"' style="overflow-y: hidden;"></div>
                  </template>

                  <template v-slot:after >
                    <router-view name="right" :style='isLandscape ? "padding:45px 0 0 0;" : "padding:0 0 0 0;"'  style="overflow-y: none;"/>
                  </template>
                </q-splitter>

              </template>

              <template v-slot:separator>
                <div class="horizSeparator"></div>
              </template>

              <template v-slot:after >
                <router-view name="bottom" style="width:100%;height:100%;"/>
              </template>
            </q-splitter>

            <!--
            <q-page-sticky position="bottom-right" :offset="[40, 20]" style="opacity:30%;">
              <div class="column">
                <q-btn fab icon="keyboard_arrow_up" color="primary" v-show="scrollPosition >= 2" @click="scrollUp"/>
                <q-btn fab dense icon="keyboard_arrow_down" color="primary" v-show="scrollPosition==1 || scrollPosition==2" @click="scrollDown"/>
              </div>
            </q-page-sticky>
            <q-scroll-observer @scroll="onScroll" />
            -->
            <q-resize-observer @resize="onResize" />
          </div>
        </q-page>
      </q-page-container>
    </q-layout>

    <div class="column items-center justify-center"
          v-show="!loadedFirstTime"
          style="position:absolute;z-index:9999;left:0;top:0;right:0;bottom:0;background-color:white;">
      <div class="col-auto waiting" style="">
        <q-img src="../assets/company/title_logo.png" fit="cover"></q-img>
      </div>
    </div>
  </div>

</template>

<style scoped>

.waiting {
  animation: slidein 4s infinite;
}

@keyframes slidein {
  from {
    width:32px;
    height:32px;
  }
  to {
    width:128px;
    height:128px
  }
}
.pageContainer {
  padding:0px;
  width:100%;
/*
  border-radius: 10px;
  border:1px solid red;
*/
}

.horizSeparator {
  width:32px;
  height:4px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.vertSeparator {
  width:4px;
  height:32px;
  background-color:#BBBBBB;
  border-radius: 3px;
}

.appBtn {
  background-color:#f2f2f2;
}
.appBtn:hover {
  background-color:#e0e0e0;
}
.appBtn:active {
  background-color:#f2f2f2;
}
.activeBtn {
  background-color:#d0d0d0;
}
.activeBtn:hover {
  background-color:#c0c0c0;
}
.activeBtn:active {
  background-color:#d0d0d0;
}
/* Full HD 이상
@media all and (min-width:1920px) {
  .pageContainer {
    padding:12px 12px;
    width:100%;
  }
}
 */
/* PC , 테블릿 가로 (해상도 1280px ~ 1919px)
@media all and (min-width:1280px) and (max-width:1919px) {
  .pageContainer {
    padding:12px 12px;
    width:100%;
  }
}
*/
/* PC , 테블릿 가로 (해상도 768px ~ 1279px)
@media all and (min-width:768px) and (max-width:1279px) {
  .pageContainer {
    padding:12px;
    width:100%;
  }
}
*/
/* 테블릿 세로 (해상도 768px ~ 1023px)
@media all and (min-width:768px) and (max-width:1023px) {
  .pageContainer {
    padding:12px;
    width:100%;
  }
}
*/
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)
@media all and (min-width:480px) and (max-width:767px) {
  .pageContainer {
    padding:12px;
    width:100%;
  }
}
*/
/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)
@media all and (max-width : 479px) { 
  .pageContainer {
    padding:12px;
    width:100%;
  }
}
*/
.menutext {
  height:24pt;
  font-size:14px;
  line-height: 22px;
  color:#212529;
}

.selectedMenuVar {
  height:24pt;
  color:#eb0028;
  background: linear-gradient(
    to right,
    #eb0028 4px,
    #f8f8fa 4px
  );
}

.unselectedMenuVar {
  height:24pt;
  color:#bbbbbb;
  background: linear-gradient(
    to right,
    #f8f8fa 4px,
    #f8f8fa 4px
  );
}

.thinMenuSelected {
  width:30px;
  height:30px;
  border-radius: 2px;
  margin-top:-8px;
  background-color : #eb0028;
}

.thinMenuNormal {
  width:30px;
  height:30px;
  border-radius: 2px;
  margin-top:-8px;
  background-color : white;
}

.splitter_desktop {
  height:100%;
  width:100%;
}
.splitter_mobile {
  height:100%;
  width:100%;
}

.singlePage_portrait{
  height:100%;
  width:100%;
}

.singlePage_landscape{
  width:100%;
  height:100%;
}
.singlePage_landscape_mo{
  height:100%;
}

.splitter_desktop_bottom {
  width:100%;
  height:100%;
}
.splitter_mobile_bottom {
  width:100%;
  height:100%;
}

.company {
  font-size:16pt;
  font-weight:bold;
  cursor:grab;
  text-overflow: clip;
}
.scrollButton {
  width:32pt;
  height:32pt;
  position:fixed;
  right:20pt;
  bottom:20pt;
}
.myHeader {
  background:white;
  color:black;
}

.myToolbar {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.container_ls {
  display: grid;
  grid-template-columns: 3fr 320pt;
  grid-template-rows: 48pt 480pt 1fr;
}

.mapview_ls {
  height:calc(100% - 24px);
}
.mapview_ls_only {
  height:calc(100% - 24px);
}

.mapview_mo_ls {
  width:100%;
  /* 32 + 50 */
  height:calc(100% - 24px);
}
.mapview_mo_ls_only {
  width:100%;
  height:calc(100% - 24px);
}
.totalview {
  height:100%;
}
.totalview_none {
  height:0%;
  display:none;
}
.totalview_mo_ls {
  width:100%;
  height:100%;
}

.right_child_ls {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: span 2;
  margin : 5pt 3pt 3pt 3pt;
}

.right_child_mo_ls {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: span 2;
  margin : 3pt 3pt 3pt 3pt;
}

.doc_bottom {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;

  height:auto;
}

</style>

<script>
import { ref } from 'vue'
import { useQuasar, scroll } from 'quasar'
import { Auth } from 'aws-amplify'
//import LanguageManager from '../components/LanguageManager.vue'
import { useTymictStore } from '@/store/tymict'
import { useI18n } from 'vue-i18n'
import AWS from 'aws-sdk'
import TymConst from '@/js/tymconstants'
import TymCommon from '@/js/tymcommon.js'
import TymAws from '@/js/tymaws.js'

export default {
  name: 'LayoutDefault',

  components: {
  },

  computed : {
    isDev() {
      return (TymConst.IS_DEVELOPMENT)
    },
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    isKorea() {
      const store = useTymictStore()
      return (store.connectServer == 0)
    },
    pageChanged() {
      const store = useTymictStore()
      return store.CurrentPage.Now
    },
    selectedMenu() {
      const store = useTymictStore()
      //return this.selectedMenuVar
      return store.CurrentPage.Now
    },
    machineCount() {
      // const store = useTymictStore()
      // return store.machineInfo.length
      return 0
    },
    isVisibleSplitter() {
      // const store = useTymictStore()
      //console.log('--------------', this.selectedMenuVar, store.CurrentPage)

      return (this.selectedMenuVar < 3)
    },
    manageCount() {
      const store = useTymictStore()
      return (store.EmergencyData.data.length + store.BrokenData.data.length + store.ConsumablesData.data.length + store.BatteryAlertData.data.length)
    },
    salesCount() {
      const store = useTymictStore()
      return (store.SalesData.count)
    },
    noticeCount() {
      const store = useTymictStore()
      return (store.noticeCount)
    },
    isLandscape() {
      const store = useTymictStore()
      return store.isLandscape
    },
    needLongLogo() {
      const $q = useQuasar()
      const store = useTymictStore()

      if(store.CurrentPage.Now == TymConst.PAGE_MONITOR)
        return true
      return (($q.screen.sm == true) || ($q.screen.md == true) || ($q.screen.lg == true) || ($q.screen.xl == true))
    },
    needLongMenu() {
      //const store = useTymictStore()
      //return store.isLandscape
      
      const $q = useQuasar()

      if($q.platform.is.mobile) {
        if($q.screen.width < 1024)
          return false
        return (($q.screen.width / $q.screen.height) > 1.2)
      }
      return ($q.screen.width > $q.screen.height)
      /*
      if($q.platform.is.mobile)
        return false;
      // console.log(">>> SCREEN >>>", $q.screen.xs, $q.screen.sm, $q.screen.md, $q.screen.lg, $q.screen.xl)
      return (($q.screen.sm == true) || ($q.screen.md == true) || ($q.screen.lg == true) || ($q.screen.xl == true))
      */
    },
    showSplitterPart() {
      const store = useTymictStore()
      switch(store.CurrentPage.Now)
      {
        case 0:
          return true;
      }
      return false;
    },
    getMenuTitle() {
      return(index) => {
        const i18n=useI18n()
        switch(index) {
          case 0:
            return i18n.t('leftbar.dashboard');
          case 1:
            return i18n.t('leftbar.equipment');
          case 2:
            return i18n.t('leftbar.sales');
          case 3:
            return i18n.t('leftbar.management');
          case 4:
            return i18n.t('leftbar.statistics');
          case 5:
            return i18n.t('leftbar.notice');
          case 6:
            return i18n.t('leftbar.group');
          case 7:
            return i18n.t('leftbar.product');
          case 8:
            return i18n.t('leftbar.customer');
          case 9:
            return i18n.t('leftbar.usim');
          case 10:
            return '실험실'
        }
        return ''
      }
    },
    isShowProduct() {
      const store = useTymictStore()
      return (store.isICTUser || store.isProductUser)
    },
    canShowMenu() {
      const store = useTymictStore()

      return(index) => {
        switch(index) {
          case 0:   // dashboard
            return true
          case 1:   // Machine Management
            return ((!store.isProductUser()) && (store.getUserLevel() < 4))
          case 2:   // Maintenance
            return (!store.isProductUser())
          case 3:   // Sales
            return (!store.isProductUser())
          case 4:   // Statistics
            return (!store.isProductUser())
          case 5:   // Notice, Annoucement
            return (!store.isProductUser())
          case 6:   // Group Management
            return (!store.isProductUser())
          case 7:   // Production
            return ((store.isICTUser() || store.isProductUser()) && (store.getUserLevel() < 2))
          case 8:   // Customer
            return (!store.isProductUser())
          case 9:   // USIM
            return store.isICTUser() && (TymConst.IS_DEVELOPMENT != 0)
          case 10:  // 실험실
            return store.isICTUser() && (TymConst.IS_DEVELOPMENT != 0)
        }
        return ''
      }
    },
    locationChanged : function() {
      const store = useTymictStore()
      return store.loadStateMachine
    },
    getUserInfo() {
      return this.$t('titlearea.userInfo')
    },
    isLoading() {
      const store = useTymictStore()
      return store.loadStateMachine
    },
    isCompletedFirstLoad() {
      // const store = useTymictStore()
      return this.loadedFirstTime
    },
    androidImage() {
      const store = useTymictStore()
      if(store.connectServer == 0) {
        if(this.isKorea)
          return this.downloadQRs[0]
        else 
          return this.downloadQRs[2]
      }
      return ''
    },
    iosImage() {
      const store = useTymictStore()
      if(store.connectServer == 0) {
        if(this.isKorea)
          return this.downloadQRs[0]
        else 
          return this.downloadQRs[2]
      }
      return ''
    },
    currentLanguage() {
      const store = useTymictStore()
      return store.Language
    }
  },
  watch : {
    '$route' (to, from) {
      this.unusedParam(from)
      //console.log('----> rounter :', from, to)
      if(to.fullPath == '/db') {
        this.menuItemClicked(0)
      }
    },
    pageChanged : {
      handler(newVal, oldVal) {
        if(newVal != oldVal) {
          if(this.isHomeMounted && this.loadedFirstTime) {
            // console.log('Home.pageChanged gotoPage :', newVal)
            this.pageChange()
          }
        }
      },
      immediate : true
    },
    locationChanged : {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal)
        if(this.isLoadingData != newVal) {
          // newVal : 로딩 완료 후 false로 된다.
          if(!newVal) {
            this.manageStoreData()
            //const store = useTymictStore()
            //store.manageStoreData()

            if(this.isHomeMounted) {
              if(!this.loadedFirstTime) {
                this.doFirstTime()
              }
            }
            // console.log('HomeView.watch.locationChanged', this.isHomeMounted, this.loadedFirstTime)
          }
          this.isLoadingData = newVal
        }
      },
      immediate : true
    },


    currentLanguage : {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal, newVal)
        if(newVal) {
          this.manageStoreData()
        }
      },
      immediate : true
    },
  },
  setup () {
//    const $q = useQuasar()
    const selectedMenuVar = ref(-1)
    const getUserName = () => {
      try {
        const store = useTymictStore()
        return store.idToken['name']
      } catch(ex) {
        console.log('getUserName() : ', ex)
      }
      return ''
    }
    function machineChanged(idx) {
      const store = useTymictStore()
      switch(idx) {
        case 1: // 기대
          return 0 // store.machineLocaInfo.length
        case 2: // 판매
          return store.SalesData.count
        case 3: // 관리
          return store.manageCount
        case 5: // 공지
          return store.noticeCount
      }
      return 0
    }

    function confirm (titleText, msg, okfunction) {
      this.unusedParam(titleText, msg, okfunction)
/*
      $q.dialog({
        title: titleText,
        message: msg,
        style : "padding:12px;",
        cancel: {
          color: 'grey-4',
          'text-color' : 'black',
        },
        ok: {
          color: 'red-7',
        },
        persistent: true
      }).onOk(() => {
        okfunction()
      }).onCancel(() => {
      }).onDismiss(() => {
      })
*/
    }
    const i18n=useI18n()
    const menuList = [
      {
        title : i18n.t('leftbar.dashboard'),
        iconOn : require('../assets/menu/main_menu_dashboard_on.svg'),
        iconOff : require('../assets/menu/main_menu_dashboard_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_dashboard_on.svg'),
        count : 0
      },
      {
        title : i18n.t('leftbar.equipment'),
        iconOn : require('../assets/menu/main_menu_equipment_on.svg'),
        iconOff : require('../assets/menu/main_menu_equipment_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_equipment_on.svg'),
        count : 0
      },
      {
        title : i18n.t('leftbar.sales'),
        iconOn : require('../assets/menu/main_menu_sales_on.svg'),
        iconOff : require('../assets/menu/main_smenu_sales_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_sales_on.svg'),
        count : 0
      },
      {
        title : i18n.t('leftbar.management'),
        iconOn : require('../assets/menu/main_menu_management_on.svg'),
        iconOff : require('../assets/menu/main_menu_management_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_management_on.svg'),
        count : 0
      },
      {
        title : i18n.t('leftbar.statistics'),
        iconOn : require('../assets/menu/main_menu_statistics_on.svg'),
        iconOff : require('../assets/menu/main_menu_statistics_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_statistics_on.svg'),
        count : 0
      },
      {
        title : i18n.t('leftbar.notice'),
        iconOn : require('../assets/menu/main_menu_notice_on.svg'),
        iconOff : require('../assets/menu/main_menu_notice_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_notice_on.svg'),
        count : 0
      },
      {
        title : i18n.t('leftbar.group'),
        iconOn : require('../assets/menu/main_menu_group_on.svg'),
        iconOff : require('../assets/menu/main_menu_group_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_group_on.svg'),
        count : 0
      },
      {
        title : i18n.t('leftbar.product'),
        iconOn : require('../assets/menu/main_menu_product_on.svg'),
        iconOff : require('../assets/menu/main_menu_product_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_product_on.svg'),
        count : 0
      },
      {
        title : i18n.t('leftbar.customer'),
        iconOn : require('../assets/menu/main_menu_customer_on.svg'),
        iconOff : require('../assets/menu/main_menu_customer_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_customer_on.svg'),
        count : 0
      },
      {
        title : i18n.t('leftbar.usim'),
        iconOn : require('../assets/menu/main_menu_usim_on.svg'),
        iconOff : require('../assets/menu/main_menu_usim_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_usim_on.svg'),
        count : 0
      },
      {
        title : "Lab",
        iconOn : require('../assets/menu/main_menu_customer_on.svg'),
        iconOff : require('../assets/menu/main_menu_customer_non.svg'),
        iconOnly : require('../assets/menu/main_smenu_customer_on.svg'),
        count : 0
      },
    ]

    return {
      selectedMenuVar,
      isDrawerOpen : ref(true),
      isMainLandscape : ref(false),
      scrollPosition : ref(0),           // 스크롤 위치(0 : None, 1 : Top, 2 : Center, 3 : Bottom)
      scrollInfo : ref({}),
      horizSplitterValue : ref(100),
      horizSplitterDisable : ref(true),
      horizSplitterStyle : "height:0px",
      horizSplitterIcon : '',
      vertSplitterValue : ref(70),
      splitter_class : 'splitter_desktop',
      singlePage_class : 'singlePage_landscape',
      mapview_class : 'mapview_ls',
      totalview_class : 'totalview',
      menuList,
      getUserName,
      confirm,
      isDrawerOverlay : ref(false),
      notiExpanded : ref(false),
      // showMenuText : ref(false)          // 메뉴에 텍스트도 보일지, 아이콘만 보일지

      oldMachineCount : ref(0),
      machineChanged,

      mqttClient : ref(null),
      mqttConnTimer : ref(null),

      dataReloadInterval : ref(null),       // 주기적으로 데이터 갱신을 위한 인터벌

      timerAfterAlarm : ref(null),          // 알람 수신후 일정시간후 업데이트하기 위한 타이머

      isHomeMounted : ref(false),
      loadedFirstTime : ref(false),         // 첫번째로 데이터를 다 로드헀느냐?

      // 개발 시험용
      devInput : ref(''),
      watchString: ref(''),

      selectedAppMode: ref(0),             // 0 : None, 1 : Android, 2 : iOS
    }
  },

  created() {
    const store = useTymictStore()
    if(store.docClient == null) {
      store.docClient = new AWS.DynamoDB.DocumentClient({'region': store.cognito[store.connectServer].region})
    }

    if(store.docLambda == null) {
      store.docLambda = new AWS.Lambda( {region : store.lambda[store.connectServer].region, apiVersion : store.lambda[store.connectServer].apiVersion})
    }
    /*
    TymAws.getADSInfo().then(data => {
        console.log('getADSInfo :', data)
      }).catch(er => {
        console.log('getADSInfo ERROR :', er)
      })
*/

    if(store.isICTUser()) {
      //
    }
  },

  mounted() {
    const store = useTymictStore()

    store.isLandscape = (document.body.clientWidth * 1.2) > document.body.clientHeight 

    this.connectMQTT()
    setTimeout(() => {
      TymAws.getGroupNameFromUuid(store.AuthUser.attributes['custom:uuid_a'], store.AuthUser.attributes['custom:uuid_b'], store.AuthUser.attributes['custom:uuid_c'], store.AuthUser.attributes['custom:uuid_d'])
        .then(data => {
          console.log('homeView.mounted GetGroups :', data)
          if(store.isICTUser()) {
            if(!TymCommon.isEmpty(store.tymictGroup))
              store.groupName = store.tymictGroup.group_b
          } else {
            store.groupName = data
          }
        })
        .catch(err => {
          console.log("HomeView mounted... getGroupNameFromUuid failed", err)
        })

        setTimeout(() => {
          this.refreshAllData()
        }, 1)

        if(TymConst.IS_DEVELOPMENT) {
          this.dataReloadInterval = setInterval(() => {
            this.refreshAllData()
          }, TymConst.DATA_RELOAD_INTERVAL_DEV)
        } else {
          this.dataReloadInterval = setInterval(() => {
            this.refreshAllData()
          }, TymConst.DATA_RELOAD_INTERVAL)
        }
    }, 1)
    
    this.isHomeMounted = true
  },

  unmounted() {
    console.log('HomeView unmounted')

    if(this.mqttConnTimer) {
      clearTimeout(this.mqttConnTimer)
      this.mqttConnTimer = null
    }
    if(this.dataReloadInterval) {
      clearInterval(this.dataReloadInterval)
      this.dataReloadInterval = null
    }

    if(this.mqttClient) {
      this.mqttClient.disconnect()
      console.log('mqttClient disconnected')
      this.mqttClient = null
    }

    if(TymConst.IS_DEVELOPMENT) {
      console.log('HomeView.unmounted...')
    }
  },

  methods : {
    unusedParam() {
      //
    },
    getVersion() {
      return TymConst.VERSION
    },
    sendEvent() {
      console.log('HomeView : before Logout')
      this.$emit('logout')
    },
    manageStoreData() {
      const store = useTymictStore()

      store.machineTree = {
        Machine : [],
        Machines : [],
      }

      if(store.ModelData.data.length < 1) {
        return
      }

      let machineTypes = []
      let modelNames = []
      let machTree = []

      store.ModelData.data.forEach(md => {
        // 기기타입만 추출 : 트렉터, 이앙기, ...
        if(!machineTypes.find(x => x == md.type))
          machineTypes.push(md.type)
        // 모델명만 추출해 TX76, TS130, ...
        if(!modelNames.find(x => x == md.model))
          modelNames.push(md.model)

        let mcFound = machTree.find(x => x.Name == md.type)
        if(mcFound) {
          let mdFound = mcFound.Model.find(x => x.Name == md.model)
          if(mdFound) {
            mdFound.Option.push(md.option)
            mdFound.Fullname.push(md.model + md.option)
          } else {
            mcFound.Model.push({
              Name : md.model,
              Option : [md.option],
              Fullname : [md.model + md.option],
            })
          }
        } else {
          machTree.push({
            Name : md.type,
            Model : [{
              Name : md.model,
              Option : [md.option],
              Fullname : [md.model + md.option],
            }]
          })
        }
      })
      machineTypes.sort((a, b) => {
        return a.localeCompare(b)
      })
      modelNames.sort((a, b) => {
        return a.localeCompare(b)
      })
      machTree.sort((a, b) => {
        return a.Name.localeCompare(b.Name)
      })
      machTree.forEach(mach => {
        mach.Model.sort((a,b) => {
          return a.Name.localeCompare(b.Name)
        })
        mach.Model.forEach(mod => {
          mod.Option.sort((a, b) => {
            return a.localeCompare(b)
          })
          mod.Fullname.sort((a, b) => {
            return a.localeCompare(b)
          })
        })
      })

      modelNames.splice(0, 0, this.$t('common.all'))
      machineTypes.splice(0, 0, this.$t('common.all'))
      
      store.machineTree.Machine = machTree
      store.machineTree.Machine.Models = modelNames
      store.machineTree.Machines = machineTypes

      // 판매데이터에 차량 타입을 넣자
      store.SalesData.data.forEach(sale => {
        let modelOption = sale.model.split('_')
        let found = store.ModelData.data.find(x => x.model == modelOption[0] && x.option == modelOption[1])
        if(found) {
          sale.machineType = found.type
        }
      })

      if(store.machineLocaInfo.length < 1) {
        return
      }
      store.machineLocaInfo.forEach(loca => {
        let found = store.SalesData.data.find(x => x.serialNo == loca.serialNo)        
        if(found) {
          loca.MON = found.model
          loca.manDate = found.manDate
          loca.adsLevel = found.adLevel
          loca.ads = found.adsType
          loca.uid = found.uid
          loca.phone = found.mobile
          loca.dealer = found.dealer
          loca.SD = found.SD
          loca.saleDate = found.saleDate
          loca.manage = { 
            MA : found.uuid_a,
            MB : found.uuid_b,
            MC : found.uuid_c,
            MD : found.uuid_d,
          }
        }
      })
    },
    calcScroll(top) {
      if(window.innerHeight >= document.body.scrollHeight)
        this.scrollPosition = 0      // 스크롤없음
      else {
        if(top == 0) {
          this.scrollPosition = 1      // 스크롤필요하고 맨위
        } else if((window.innerHeight + top) < document.body.scrollHeight) {
          this.scrollPosition = 2      // 스크롤 중
        } else if((window.innerHeight + top) >= document.body.scrollHeight) {
          this.scrollPosition = 3      // 맨 아래
        }
      }
    },
    pageChange() {
      if(!this.isHomeMounted) {
        return
      }
      const store = useTymictStore()
      let pages = [
        { path : '/' },     // 0
        { path : '/eq' },     // 1
        { path : '/sale' },   // 2
        { path : '/man' },    // 3
        { path : '/stat' },   // 4
        { path : '/noti' },   // 5
        { path : '/grp' },    // 6
        { path : '/prod' },   // 7
        { path : '/cust' },   // 8
        
        { path : '/usim' },   // 9, USIM
        { path : '/uc' },     // 10, 실험실

        {},                   // 11
        {},
        {},
        {},
        {},                   // 15
        {},
        {},
        {},
        {},                   // 19


        { path : '/man2' },   // 20
        { path : '/mon' },    // 21
        { path : '/ac' },     // 22
      ]

      if(TymConst.IS_DEVELOPMENT) {
        console.log('store.CurrentPage.Now :', store.CurrentPage.Now, pages[store.CurrentPage.Now])
      }

      if(store.firstTime) {
        console.log('>>> HomeView.pageChange FIRST TIME <<<', store.startPage)
        switch(Number(store.startPage)) {
          case 1:   // 관리
            store.CurrentPage.Now = 2
            break
          case 2:   // 판매현황
            store.CurrentPage.Now = 3
            break
          case 3:   // 통계
            store.CurrentPage.Now = 4
            break
          case 4:   // 공지
            store.CurrentPage.Now = 5
            break
          default:
            if(store.CurrentPage.Now < 0)
              store.CurrentPage.Now = 0
          break
        }
        store.firstTime = false
        if(this.isDev) {
          setTimeout(() => {
            this.updateWatch()
          }, 1)
        }
      }

      this.$router.push(pages[store.CurrentPage.Now])

      switch(store.CurrentPage.Now) {
        case TymConst.PAGE_DASHBOARD:   // Dashboard
          if(this.isLandscape) {
            // landscape
            if(this.$q.platform.is.mobile) {
                this.splitter_class = 'splitter_mobile'
              } else {
                this.splitter_class = 'splitter_desktop'
              }
              this.mapview_class = 'mapview_ls'
              this.totalview_class = 'totalview'
          } else {
              // portrait
              if(this.$q.platform.is.mobile) {
                this.splitter_class = 'splitter_mobile'
              } else {
                this.splitter_class = 'splitter_desktop'
              }
              this.mapview_class = 'mapview_mo_ls'
              this.totalview_class = 'totalview_mo_ls'
          }
          this.horizSplitterDisable = false
          this.horizSplitterValue = 72
          this.horizSplitterStyle = "height:12px"
          this.horizSplitterIcon = "more_horiz"
          break;
        case 2:   // management
          if(this.isLandscape) {
            // landscape
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
            } else {
              this.splitter_class = 'splitter_desktop'
            }
          } else {
            // portrait
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
            } else {
              this.splitter_class = 'splitter_desktop'
            }
          }
          this.horizSplitterDisable = true
          this.horizSplitterValue = 100
          this.horizSplitterStyle = "height:0px";
          this.horizSplitterIcon = "none"
          break;
        case 3:   // 통계
          if(this.isLandscape) {
            // landscape
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
            } else {
              this.splitter_class = 'splitter_desktop'
            }
          } else {
            // portrait
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
            } else {
              this.splitter_class = 'splitter_desktop'
            }
          }

          this.horizSplitterDisable = true
          this.horizSplitterValue = 100
          this.horizSplitterStyle = "height:0px";
          this.horizSplitterIcon = "none"
          break;

        case 4:   // 공지
          if(this.isLandscape) {
            // landscape
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
              this.singlePage_class = 'singlePage_landscape_mo'
            } else {
              this.splitter_class = 'splitter_desktop'
              this.singlePage_class = 'singlePage_landscape'
            }
          } else {
            // portrait
            this.singlePage_class = 'singlePage_portrait'
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
            } else {
              this.splitter_class = 'splitter_desktop'
            }
          }
          this.horizSplitterDisable = true
          this.horizSplitterValue = 100
          this.horizSplitterStyle = "height:0px";
          this.horizSplitterIcon = "none"
          break;

        case 1:   // equipment
        case 5:   // notice
        case 6:   // group
        case 7:   // product
        case 8:
        case 9:   // USIM
        case 10:  // 실험실
        case TymConst.PAGE_MANAGE_POPUP:
        case TymConst.PAGE_ACCOUNT:
          if(this.isLandscape) {
            // landscape
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
            } else {
              this.splitter_class = 'splitter_desktop'
            }
          } else {
            // portrait
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
            } else {
              this.splitter_class = 'splitter_desktop'
            }
          }

          this.horizSplitterDisable = true
          this.horizSplitterValue = 100
          this.horizSplitterStyle = "height:0px";
          this.horizSplitterIcon = "none"
          break;
        case TymConst.PAGE_MONITOR:   // 차량관제
          if(this.isLandscape) {
            // landscape
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
              this.singlePage_class = 'singlePage_landscape_mo'
            } else {
              this.splitter_class = 'splitter_desktop'
              this.singlePage_class = 'singlePage_landscape'
            }
          } else {
            // portrait
            this.singlePage_class = 'singlePage_portrait'
            if(this.$q.platform.is.mobile) {
              this.splitter_class = 'splitter_mobile'
            } else {
              this.splitter_class = 'splitter_desktop'
            }
          }

          this.horizSplitterDisable = true
          this.horizSplitterValue = 100
          this.horizSplitterStyle = "height:0px"
          this.horizSplitterIcon = "none"
          break;
        default:
          this.$router.push({path:'/'})
          break;
      }
      if(store.CurrentPage.Now == TymConst.PAGE_MONITOR) {
        this.isDrawerOpen = false;
      } else {
        // console.log('HomeView.pageChange : ', store.isLandscape, this.needLongMenu, this.isDrawerOpen)
        if(this.isDrawerOverlay)
          this.isDrawerOpen = false;
        if(store.CurrentPage.Old == TymConst.PAGE_MONITOR)
          this.isDrawerOpen = true
        else if(store.isLandscape || this.needLongMenu)
          this.isDrawerOpen = true
      }
    },
    menuClicked() {
      this.isDrawerOpen = !this.isDrawerOpen
    },
    companyClicked() {
      this.menuItemClicked(0)
    },

    menuItemClicked(index) {
      switch(index) {
        case 0:
          sessionStorage.removeItem('DB.saved')
          break;
        case 1:
          //console.log('menuItemClicked : Model')
          sessionStorage.removeItem('MODEL.saved')
          break;
        case 2:
          sessionStorage.removeItem('SALE.saved')
          break;
        case 3:
          sessionStorage.removeItem('MAN.saved')
          break;
        case 4:
          //console.log('menuItemClicked : Statistics')
          sessionStorage.removeItem('STAT.saved')
          sessionStorage.removeItem('STAT.sale.saved')
          sessionStorage.removeItem('STAT.usage.saved')
          sessionStorage.removeItem('STAT.em.saved')
          sessionStorage.removeItem('STAT.br.saved')
          sessionStorage.removeItem('STAT.cons.saved')
          sessionStorage.removeItem('STAT.batt.saved')
          break;
        case 5:
          //console.log('menuItemClicked : Noti')
          sessionStorage.removeItem('NOTI.saved')
          break;
        case 6:
          //console.log('menuItemClicked : Group')
          sessionStorage.removeItem('GRP.saved')
          break;
        case 7:
          //console.log('menuItemClicked : Product')
          sessionStorage.removeItem('PROD.saved')
          break;
        case 8:
          //console.log('menuItemClicked : Customer')
          sessionStorage.removeItem('CUST.saved')
          break;
        case 9:
          //console.log('menuItemClicked : UncerCover')
          // sessionStorage.removeItem('UNCOV.saved')
          break;
      }

      this.selectedMenuVar = index

      const store = useTymictStore()
      console.log('menuItemClicked :', index)
      store.setPage(index)
    },
    onScroll(info) {
      this.scrollInfo.value = info
      this.calcScroll(info.position.top)
    },
    onResize(size) {
      this.unusedParam(size)
      this.calcScroll(this.$refs.mainLayout.scrollTop)

      const store = useTymictStore()
      store.isLandscape = (document.body.clientWidth * 1.2) > document.body.clientHeight
      if(this.$q) {
        if(this.$q.platform) {
          if(this.$q.platform.is.mobile) {
            let ratio = this.$q.screen.width / this.$q.screen.height
            if(ratio == 10/16)
              store.isLandscape = false;
            else if(ratio == 3/4)
              store.isLandscape = false;
            else
              store.isLandscape = this.$q.screen.width > this.$q.screen.height
          }
        }
      }
      if(store.CurrentPage.Now == TymConst.PAGE_MONITOR) {
        this.isDrawerOpen = false;
      } else {
        if(store.isLandscape || this.needLongMenu)
          this.isDrawerOpen = true
      }
      /*
      console.log(size)
      if(this.$refs.containerDiv != null) {
        let width = this.$refs.containerDiv.clientWidth
        let height = this.$refs.containerDiv.clientHeight
        if(this.isDrawerOpen) {
          width -= this.$refs.drawerDiv.clientWidth
        }
        store.isLandscape = ((width * 1.2) > height)
      } else {
        const $q = useQuasar()
        store.isLandscape = (($q.screen.width * 1.2) > $q.screen.height)
      }
      */
    },
    selectAndroid() {
      /*
      if(this.isKorea) {
        window.open('https://play.google.com/store/apps/details?id=com.es.tymict_mapp')
      } else {
        window.open('https://play.google.com/store/apps/details?id=com.na.tymict_mapp')
      }
      */
      if(this.selectedAppMode != 1) {
        this.selectedAppMode = 1
      } else {
        this.selectedAppMode = 0
      }
    },
    selectIOS() {
      /*
      if(this.isKorea) {
        window.open('https://apps.apple.com/kr/app/mytym-%EA%B4%80%EB%A6%AC%EC%9E%90/id1631233008')
      } else {
        window.open('https://apps.apple.com/us/app/mytym-admin-na/id6448138972')
      }
      */
      if(this.selectedAppMode != 2) {
        this.selectedAppMode = 2
      } else {
        this.selectedAppMode = 0
      }
    },
    doLogout() {
      // const self = this
      function doOK(){
        const store = useTymictStore()
        
        sessionStorage.setItem('EnterInfo', "")

        async function signOut() {
          try {
            await Auth.signOut({ global: true })
          } catch (error) {
            console.log('homeView : error signing out: ', error);
          }
        }
        
        signOut().then(()=>{
          TymAws.lookup(TymConst.ACTION_LOG_OUT, '', 'MW2')

          store.clearAuthUser()
          setTimeout(() => {
            window.location.reload(true)
          }, 100)
        }) 
      }

      TymCommon.MsgBox(this.$t('common.confirm'), this.$t('loginmenu.dologout'), doOK)
    },

    scrollUp() {
      const { getVerticalScrollPosition, setVerticalScrollPosition } = scroll
      const height = getVerticalScrollPosition(window)
      const duration = 200
      setVerticalScrollPosition(window, height - 200, duration)
    },
    scrollDown() {
      const { getVerticalScrollPosition, setVerticalScrollPosition } = scroll
      const height = getVerticalScrollPosition(window)
      const duration = 200
      setVerticalScrollPosition(window, height + 200, duration)
    },

    overlayPinClicked() {
      this.isDrawerOverlay = !this.isDrawerOverlay
    },

    viewMyAccount() {
      this.menuItemClicked(TymConst.PAGE_ACCOUNT)
    },
    processMessage(message) { 
      const info = JSON.parse(message.payloadString)
      this.processMQTT(info)
    },
    processConnectionLost(ev) {
      console.log('Home.processConnectionLosg :', ev); 
      if(ev.errorCode != 0) {
        this.mqttConnTimer = setTimeout(() => {
          this.connectMQTT()
        }, TymConst.MQTT_PROC_INTERVAL)
      }
    },
    connectMQTT() {
      if(!this.mqttConnTimer) {
        this.mqttClient = this.getClient(false) 
        if(this.mqttClient) {
          this.mqttClient.onMessageArrived = this.processMessage; 
          this.mqttClient.onConnectionLost = this.processConnectionLost
        }
      }
    },
    createEndpoint(regionName, awsIotEndpoint, accessKey, secretKey) {
      function p4(){} 
      p4.sign = function(key, msg) { 
          const hash = window.CryptoJS.HmacSHA256(msg, key); 
          return hash.toString(window.CryptoJS.enc.Hex); 
      }; 
      p4.sha256 = function(msg) { 
          const hash = window.CryptoJS.SHA256(msg); 
          return hash.toString(window.CryptoJS.enc.Hex); 
      }; 
      p4.getSignatureKey = function(key, dateStamp, regionName, serviceName) { 
          const kDate = window.CryptoJS.HmacSHA256(dateStamp, 'AWS4' + key); 
          const kRegion = window.CryptoJS.HmacSHA256(regionName, kDate); 
          const kService = window.CryptoJS.HmacSHA256(serviceName, kRegion); 
          const kSigning = window.CryptoJS.HmacSHA256('aws4_request', kService); 
          return kSigning; 
      };  

      const dt = (new Date()).toISOString().replace(/[^0-9]/g, ""); 
      const ymd = dt.slice(0,8);
      const fdt = `${ymd}T${dt.slice(8,14)}Z` 
      const scope = `${ymd}/${regionName}/iotdevicegateway/aws4_request`; 
      const ks = encodeURIComponent(`${accessKey}/${scope}`); 
      let qs = `X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=${ks}&X-Amz-Date=${fdt}&X-Amz-SignedHeaders=host`; 
      const req = `GET\n/mqtt\n${qs}\nhost:${awsIotEndpoint}\n\nhost\n${p4.sha256('')}`; 
      qs += '&X-Amz-Signature=' + p4.sign(
          p4.getSignatureKey(secretKey, ymd,regionName, 'iotdevicegateway'), 
          `AWS4-HMAC-SHA256\n${fdt}\n${scope}\n${p4.sha256(req)}`
      );
      return `wss://${awsIotEndpoint}/mqtt?${qs}`;
    },
    initClient() {
      const store = useTymictStore()
      var endpoint = this.createEndpoint(
        store.mqtt[store.connectServer].region,
        store.mqtt[store.connectServer].endpoint,
        store.mqtt[store.connectServer].mqttId,
        store.mqtt[store.connectServer].mqttkey
      ); 
      const clientId = Math.random().toString(36).substring(7); 
      let _client = new window.Paho.MQTT.Client(endpoint, clientId);

      _client.publish = function(topic, payload) { 
        let payloadText = JSON.stringify(payload); 
        let message = new window.Paho.MQTT.Message(payloadText); 
        message.destinationName = topic; 
        message.qos = 0; 
        _client.send(message); 
      } 
      return _client; 
    },
    getClient(success) { 
      const self = this
      function onFail(msg) {
        console.log("Home.MQTT CONNECT FAIL", msg);
      }
      function subscribe()
      {
        const store = useTymictStore()

        // let Topic = "+/NAAtoWEB/#"; 북미
        // let Topic = "+/AtoWEB/#";     // 한국 -- 람다에서 긴급/고장...
        // let Topic = "+/Event/#";     // 한국 - 들어오는 지 테스트용.. 죄다 들어와서 큰일남
        let Topic = TymConst.MQTT_TOPIC[store.connectServer]

        if(TymConst.IS_DEVELOPMENT) {
          console.log("Home.MQTT CONNECT OK");
        }
        try{
          // console.log('Home.MQTT Topic :', Topic)
          self.mqttClient.subscribe(Topic);
        }
        catch(err) {
          console.log("SUB ERROR", err);
        }
      }

      if (!success)
        success = ()=> console.log("connected"); 

      let _client = this.initClient(); 
      const connectOptions = { 
        useSSL: true, 
        timeout: 3, 
        mqttVersion: 4, 
        onFailure: onFail,
        onSuccess: subscribe
      }; 
      _client.connect(connectOptions); 
      return _client;
    },
    processMQTT(msg) {
      /*

      {
        "MQTT": "ALRAM",
        "ATYPE": 5,
        "MN": "NT130XP0002",
        "SN": "T001221400550055",
        "ATime": "20231116:091717.404^5",
        "Manage": "[{\"MA\":\"0\",\"MB\":\"1641209837058-wspring\",\"MC\":\"1673221198205-tymicttester\"}]"
      }
      */
      // const store = useTymictStore()
      switch(msg.MQTT) {
        case 'EMERGENCY':
          this.doProcessEmergency(msg)
          break;
          case 'BROKEN':
          if(msg.ECount != 0) {
            this.doProcessBroken(msg)
          }
          break;
        case 'CITEM':
          this.doProcessConsumables(msg)
          break;
        case 'ALRAM':
          if((msg.MN == 'SYS') && (msg.SN == 'SYS')) {
            this.doProcNOTICE(msg)
          }
          break;
        default:
          console.log('Home.msg UNKOWN :', msg)
          break;
      }
      /*
      if(!TymCommon.isEmpty(store.machineLocaInfo)) {
        let foundMachine = store.machineLocaInfo.find(x => x.machineNo == msg.MN)
        if(foundMachine) {
          console.log('Home.msg FOUND :', foundMachine)
        }
      }
      */
    },
    restartReloadTimeout() {
      this.timerAfterAlarm = setTimeout(() => {
        console.log('알람 수신후 10초가 지남...')
      }, 10000)
    },
    // MQTT 긴급 메시지 처리
    doProcessEmergency(msg) {
      /*
      {
        "MQTT": "EMERGENCY",
        "MN": "NFHT00039",
        "SN": "T001221400930093",
        "ATime": "20231116:090019.026^3",

        "ETYPE": "3", 
                  1: 긴급알림, 전복
                  2: 긴급알림, 안심구역이탈, 도난
                  3: 일반알림, 배터리 저전압
                  4: 일반알림, 배터리 저전압, TBOX통신종료
                  5: 일반알림, 배터리 차단, TBOX통신종료
                  6: 공지
                  7: 설문조사
                  8: 고장알림
                  9: 소모품알림
                  10: 일반알림, 시동종료 후 일정시간이 초과, TBOX통신종료

        "LOCA": [
          "36.9790139666",
          "127.0973076833",
          "20231116:085002"
        ],
        "Manage": [
          {
            "MA": "0",
            "MB": "2",
            "MC": "7",
            "MD": "1683529609984-gyeonggi"
          }
        ]
      }
      */
      if(this.timerAfterAlarm) {
        clearTimeout(this.timerAfterAlarm)
        this.timerAfterAlarm = null
      }
      const store = useTymictStore()
      if((msg.ETYPE == "1") || msg.ETYPE == "2") {
        if(!TymCommon.isEmpty(store.machineLocaInfo)) {
          let foundMachine = store.machineLocaInfo.find(x => x.machineNo == msg.MN)
          if(foundMachine) {
            console.log('Home.msg EMERGENCY :', msg, foundMachine)
            foundMachine.latitude = parseFloat(msg.LOCA[0])
            foundMachine.longitude = parseFloat(msg.LOCA[1])
            foundMachine.realtimeInfo = msg.LOCA[2]

            this.restartReloadTimeout()
          }
        }
      }
    },
    showEmergency(mn, message) {
      let htmlMsg = "<div style='font-size:18px;font-weight:bold;width:320px;'>" + mn + "</div>"
      message.forEach(msg => {
        htmlMsg += "<div style='font-size:16px;'>"
        htmlMsg += msg + "</div>"
      })

      let self = this
      this.$q.notify({
        multiLine: true,
        group: false,
        progress: true,
        html: true,
        color: 'brown-8',
        message: htmlMsg,
        // caption: mn,
        position : 'bottom-right',
        avatar: require("../assets/dashboard/dashboard_rollover_icon.png"),
        actions : [{
          label: 'View',
          color: 'white',
          handler: self.gotoEnergency(mn)
        }]
      })
    },
    gotoEnergency(mn) {
      console.log('HomeView.gotoEmergency :', mn)
    },
    doProcessBroken(msg) {
      /*
      {
        "MQTT": "BROKEN",
        "Alram": 8,
        "MN": "PFHT00070",
        "SN": "T001223700260026",
        "ATime": "20231116:091843.681",
        "DN": [
          "오른쪽 드래프트 센서 이상"
        ],
        "ECODE": [
          "520202_20"
        ],
        "ECount": 1,
        "LOCA": [
            "35.5717205166",
            "127.3159973166",
            "20231116:085731.727"
        ],
        "Manage": [
            {
                "MA": "0",
                "MB": "2",
                "MC": "10",
                "MD": "1689216589872-jeonbuk"
            }
        ]
      }
      */

      if(this.timerAfterAlarm) {
        clearTimeout(this.timerAfterAlarm)
        this.timerAfterAlarm = null
      }

      const store = useTymictStore()
      if(!TymCommon.isEmpty(store.machineLocaInfo)) {
        let foundMachine = store.machineLocaInfo.find(x => x.machineNo == msg.MN)
        if(foundMachine) {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('Home.msg BROKEN :', msg, foundMachine)
          }
          foundMachine.latitude = parseFloat(msg.LOCA[0])
          foundMachine.longitude = parseFloat(msg.LOCA[1])
          foundMachine.realtimeInfo = msg.LOCA[2]
          if(TymConst.IS_DEVELOPMENT) {
            this.showBroken(msg.MN, msg.DN)
          }

          this.restartReloadTimeout()
        }
      }
    },
    showBroken(mn, message) {
      let htmlMsg = "<div style='font-size:18px;font-weight:bold;width:320px;'>" + mn + "</div>"
      message.forEach(msg => {
        htmlMsg += "<div style='font-size:16px;'>"
        htmlMsg += msg + "</div>"
      })

      let self = this
      this.$q.notify({
        multiLine: true,
        group: false,
        progress: true,
        html: true,
        color: 'brown-8',
        message: htmlMsg,
        // caption: mn,
        position : 'bottom-right',
        avatar: require("../assets/dashboard/dashboard_error_icon.png"),
        actions : [{
          label: 'View',
          color: 'white',
            handler: self.gotoBroken(mn)
        }]
      })
    },
    gotoBroken(msg) {
      console.log('gotoBroken :', msg)
    },
    doProcessConsumables(msg) {
      /*
      {
        "MQTT": "CITEM",
        "Alram": 9,
        "MN": "PFHT00094",
        "SN": "T001221401270127",
        "MON": "K110E_NONE",
        "OTime": "2023-11-17 10:27",
        "UINFO": [
          {
            "UID": "lbj0507",
            "USER": "이병재",
            "HP": "010-3739-4625"
          }
        ],
        "OKCOUNT": 3,
        "NOCOUNT": 4,
        "MANAGE": [
          {
            "MA": "0",
            "MB": "2",
            "MC": "7",
            "MD": "1683529837865-gyeonggi"
          }
        ],
        "VUT": 98,
        "DETAIL": ", CC1 = :sCc1, CL1 = :sCl1, CC2 = 98, CL2 = 500, CC3 = 98, CL3 = 500, CC4 = 98, CL4 = 2000, CC5 = 98, CL5 = 50, CC6 = 98, CL6 = 50, CC7 = 98, CL7 = 50"
      }
      */

      if(this.timerAfterAlarm) {
        clearTimeout(this.timerAfterAlarm)
        this.timerAfterAlarm = null
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('Home.msg CONSUMABLE :', msg)
      }
      this.restartReloadTimeout()
    },
      // MQTT 공지 메시지 처리
      doProcessNotice(msg) {
      /*
      {
        "MQTT": "ALRAM",
        "ATYPE": 6,
        "MN": "SYS",
        "SN": "SYS",
        "TITLE": "TTT",
        "ATime": "20231116:091849.890",
        "Manage": "{\"MA\":\"0\"}",
        "Receiver": 7
      }
      */
      if(this.timerAfterAlarm) {
        clearTimeout(this.timerAfterAlarm)
        this.timerAfterAlarm = null
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('Home.msg NOTICE :', msg)
      }
      this.restartReloadTimeout()
    },
    refreshAllData() {
      const store = useTymictStore()

      if(!store.loadStateMachine) {
        // let now = new Date()
        // console.log('refreshAllData :', now.toTimeString())
        store.refreshCurrentLocation(true, true)
      }
    },
    doFirstTime() {
      setTimeout(() => {
        const store = useTymictStore()

        //store.CurrentPage.old = -1
        //store.CurrentPage.new = -1
        store.isLoadCompleted = true

        if((store.reloadPage.type != 'RELOAD') || TymCommon.isEmpty(store.reloadPage.page)) {
          let tgtPage = 0
          if(!TymCommon.isEmpty(store.startPage)) {
            tgtPage = Number(store.startPage)
          }
          if(TymConst.IS_DEVELOPMENT) {
            console.log('!!!!! HomeView.doFirstTime NORMAL', tgtPage)
          }
          switch(tgtPage) {
            case 1:
              store.setPage(TymConst.PAGE_MANAGE)
              break;
            case 2:
              store.setPage(TymConst.PAGE_SALES)
              break;
            case 3:
              store.setPage(TymConst.PAGE_STAT)
              break;
            case 4:
              store.setPage(TymConst.PAGE_NOTI)
              break;
            default:
              store.setPage(TymConst.PAGE_DASHBOARD)
              break;
          }
          this.loadedFirstTime = true
        } else {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('!!!!! HomeView.doFirstTime REFRESH', store.reloadPage.page)
          }
          store.firstTime = false
          this.loadedFirstTime = true

          //setTimeout(() => {
            switch(store.reloadPage.page) {
              case 'db':
                store.setPage(TymConst.PAGE_DASHBOARD)
                break;
              case 'eq':
                store.setPage(TymConst.PAGE_MACHINE)
                break;
              case 'man':
                store.setPage(TymConst.PAGE_MANAGE)
                break;
              case 'man2':
                store.setPage(TymConst.PAGE_MANAGE)
                break;
              case 'sale':
                store.setPage(TymConst.PAGE_SALES)
                break;
              case 'stat':
                store.setPage(TymConst.PAGE_STAT)
                break;
                case 'noti':
                store.setPage(TymConst.PAGE_NOTI)
                break;
              case 'grp':
                store.setPage(TymConst.PAGE_GROUP)
                break;
              case 'prod':
                store.setPage(TymConst.PAGE_PRODUCT)
                break;
              case 'ac':
                store.setPage(TymConst.PAGE_ACCOUNT)
                break;
              case 'cust':
                store.setPage(TymConst.PAGE_CUSTOMER)
                break;
              case 'mon':
                store.setPage(TymConst.PAGE_MONITOR)
                break;
              case 'usim':
                store.setPage(TymConst.PAGE_USIM)
                break;
              default:
                store.setPage(TymConst.PAGE_DASHBOARD)
                break;
            }
          //}, 25)
        } 
      }, 100)
    },
    updateWatch() {
      let now = new Date()
      now = TymCommon.convDateTimeToString(now)
      this.watchString = now
      setTimeout(() => {
        this.watchString = TymCommon.convDateTimeToString(new Date())
      }, (60 - now.getSeconds) * 1000)
    },
    // 개발 시험용 함수들
    devDoIt() {
      if(TymCommon.isEmpty(this.devInput)) {
        TymCommon.Toast('뭘 입력해야 해보지!!!', true)
        return
      }
      TymAws.getSNInfo(this.devInput)
        .then(data => {
          console.log('getSNInfo ', data)
        })
        .catch(er => {
          console.log('getSNInfo ERROR :', er)
        })

      const store = useTymictStore()
      if(store.TestFlag == 0)
        store.TestFlag = 1
      else 
        store.TestFlag = 0

      let machine = store.machineLocaInfo.find(x => x.machineNo == this.devInput)
      if(machine) {
        /*
        {
          "name": "이종석",
          "uid": "goseong002",
          "ads": "None",
          "phone": "010-3589-7501",
          "address": "경남 고성군 삼산면 공룡로 1634-32 ",
          "modelNo": "TX76",
          "machineNo": "NT078D01142",
          "manDate": "2022. 12.",
          "serialNo": "T001223700770077",
          "saleDate": "2023. 04. 27.",
          "SD": "2023-04-27T00:00",
          "dealer": "고성-이재범",
          "abnormal": [
            false,
            false,
            true,
            false
          ],
          "latitude": 34.9538752666,
          "longitude": 128.2431298166,
          "hasACU": false,
          "hasTCU": false,
          "hasCPG": false,
          "realtimeInfo": "20231106:142909.341",
          "realtimeAddr": "",
          "PWR": "OFF",
          "MON": "TX76_CQPANV",
          "manage": {
            "MA": "0",
            "MB": "2",
            "MC": "13",
            "MD": "1684457455942-gyeongnam"
          },
          "uuid_a": "0",
          "uuid_b": "2",
          "uuid_c": "13",
          "uuid_d": "1684457455942-gyeongnam",
          "machType": "트랙터"
        }
        */

        /*
        machine.abnormal[0] = false
        machine.abnormal[1] = !machine.abnormal[1]
        machine.abnormal[2] = false
        machine.abnormal[3] = !machine.abnormal[3]

        if(machine.PWR == 'ON')
          machine.PWR = 'OFF'
        else 
          machine.PWR = 'ON'
        */


        //let machine2 = store.machineLocaInfo.find(x => x.machineNo == this.devInput)
        //console.log(JSON.parse(JSON.stringify(machine)), JSON.parse(JSON.stringify(machine2)))
      }
    }
  }
}
</script>
