``<template>
  <div class="column" :style='isLandscape ? "width:100%;height:100%;" : "width:100%;height:100%;"' style="background-color:#F2F2F2">
    <div class="col-auto column items-center justify-start" style="border:1px solid #ebedf2;border-radius:3px;">
      <table class="col" style="width:100%;background-color:white;padding:12px;border-radius:3px">
        <tr>
          <td width="100%">
            <!-- 제목 -->
            <div class="col" style="font-size:18px;font-weight:500;"
                 :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('statView.usage')}}&nbsp;(h)</div>
          </td>
        </tr>
        <tr>
          <td width="100%">
            <div class="col row items-center justify-between no-wrap" style="">
              <div class="col-auto row items-center justify-start ">
                <!-- 조직 선택 -->
                <div class="col-auto row grpBtn items-center justify-between"
                     style="min-width:180px;width:auto;height:40px;padding:0 6px 0 0;">
                  <div class="col row items-center justify-between" style="cursor:pointer;padding:0 0 0 12px;height:100%;border:-1px solid black;" @click="groupPopupDisplayed = !groupPopupDisplayed">
                    <q-popup-proxy ref="groupPopup" style="padding:3px;" @hide="groupPopupDisplayed=false">
                      <div class="column" style="min-width:250px;height:250px;">
                        <div class="col row" style="border:1px solid #cccccc;padding:3px;background-color:white;">
                          <q-scroll-area class="col row" style="width:auto;height:100%;">
                            <q-tree
                              :nodes="groupTreeData"
                              dense
                              no-selection-unset
                              selected-color="red-7"
                              class="col"
                              node-key="uuid"
                              v-model:expanded="expandedTree"
                              v-model:selected="selectedTree"
                              @update:selected="groupTreeSelected"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </q-popup-proxy>
                    <div class="col" >
                      {{selectedTreeLabel}}
                    </div>
                    <q-icon class="col-auto" dense  color="grey-8" :name='groupPopupDisplayed ? "arrow_drop_up" : "arrow_drop_down"' size="24px">
                    </q-icon>
                  </div>
                  <!--
                  <q-btn class="col-auto" dense flat rounded color="grey-7" icon="close" size="md" @click="clearGroupData"/>
                  -->
                </div>

                <!-- 년도 -->
                <q-select dense outlined options-dense v-model="selectedYear" :options="validYears"
                          class="col-auto" color="red-7"
                          hide-bottom-space
                          style="font-size:14px;min-width:100px;margin:0 0 0 6px;">
                </q-select>
                <!-- 월 선택 -->
                <q-select dense outlined options-dense v-model="selectedMonth" :options="getMonthTitle"
                          class="col-auto" color="red-7"
                          hide-bottom-space
                          style="font-size:14px;min-width:100px;margin:0 0 0 6px;">
                </q-select>

                <!-- 기기 종류 선택 -->
                <q-select dense outlined options-dense v-model="catSelected" :options="getCategory"
                          class="col-auto" color="red-7"
                          hide-bottom-space 
                          style="font-size:14px;min-width:160px;margin:0 0 0 6px;">
                </q-select>

                <!-- 작업 종류 선택 -->
                <q-select dense outlined options-dense v-model="workSelected" :options="getWorkTypes"
                          class="col-auto" color="red-7"
                          hide-bottom-space 
                          style="font-size:14px;min-width:120px;margin:0 0 0 6px;">
                </q-select>

                <div class="col row items-center justify-center">
                  <q-btn class="col" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')"
                        style="width:100px;height:40px;margin:0 0 0 6px;" @click="searchData()">
                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.searchData') }}</q-tooltip>
                  </q-btn>
                </div>
              </div>
              <div class="col-1 row justify-end">
                <!-- 엑셀로 저장 -->
                <q-btn flat dense @click="downloadToExcel" v-show="canDownloadToExcel">
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                  <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;"/>
                  <q-popup-proxy ref="excelPopup" style="width:360px;height:240px;background-color: #01010130;border-radius: 3px;position: relative;"
                                  @before-show="onShowExportToExcel">
                    <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                      <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                        <div class="col-auto row items-center justify-center">
                          <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                            {{ $t('common.toExcel') }}
                          </div>
                        </div>
                        <div class="col column" v-show="hasExcelData">
                          <div class="col row items-center justify-start" style="width:100%;margin:0;">
                            <table class="col" style="width:100%;margin:0;">
                              <tr style="">
                                <td width=30% style="text-align: right;">{{ $t('common.filename') }}</td>
                                <td width="12px" style=""></td>
                                <td style="">{{ excelFilename }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.from') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelFrom }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.to') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelTo }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('dashboardView.dealer') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelDealer }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.machineType') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelType }}</td>
                              </tr>
                            </table>
                          </div>
                          <xlsx-workbook class="col-auto row items-center justify-center">
                            <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                            <xlsx-download :filename="excelFilename">
                              <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveToExcelFile">{{ $t('common.download') }}</button>
                            </xlsx-download>
                          </xlsx-workbook>
                        </div>
                        <div class="col row items-center justify-center" v-show="!hasExcelData" style="width:100%;height:100%;">
                          <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:bold;color:#eb0028">{{ $t('warning.nodata')}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row items-center justify-center" v-show="makingExcel"
                        style="position:absolute;z-index:3;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                      <q-spinner color="red-7" size="70px" thickness="1.5"/>
                    </div>
                  </q-popup-proxy>
                </q-btn>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- 차트들 -->
    <div class="col row" style="height:100%;margin:12px 0 0 0;">
      <div class="col row" style="margin:0px;">
        <q-splitter v-model="horizSplitter" :limits="[20, 80]" 
                    horizontal
                    separator-style="height:12px;background-color:red;"
                    class="col"
                    separator-class="transparent">
          <template v-slot:before>
            <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
            <q-splitter v-model="upSplitter" :limits="[20, 80]" 
                        :separator-style='!isLandscape ? "height:12px" : "width:12px"'
                        :horizontal="!isLandscape"
                        separator-class="transparent">
              <template v-slot:before>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 지역별로 변경... 변수는 그대로 사용할까?-->
                    <div class="col column" style="">
                      <div class="col-auto row" style="height:42px;">
                        <div class="col row" style="margin:0 0 4px 0;">
                          <div class="col-auto row items-center">
                            <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500;'" style="font-size:16px;">{{ titleForLocalHours }}</div>
                            <q-separator color="grey-7" vertical style="margin:9px 6px 9px 12px;"></q-separator>
                            <div class="col-auto" style="font-family:Prometo;font-size:15px;color:#eb0028">&nbsp;{{ termByDealer }}</div>
                          </div>
                        </div>
                        <div class="col-auto row items-center justify-center" >
                          <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:15px;">{{ $t('common.unit') }}&nbsp;:&nbsp;(h)</div>
                        </div>
                      </div>
                      <div id="dealerContainer" class="col row q-mt-xs q-pa-sm items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-scroll-area class="col row items-center justify-center" style="position:absolute;left:6px;top:6px;right:6px;bottom:6px;border-radius: 3px;">
                          <div ref="refDealer" id="dealerChart"></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeDealer"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:separator>
                <div style="background-color:#f2f2f2;" class="row items-center justify-center">
                  <div :class='isLandscape ? "vertSeparator" : "horizSeparator"' style=""></div>
                </div>
              </template>
              <template v-slot:after>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 기종/모델별 사용시간 (우측 상단) -->
                    <div class="col column" style="">
                      <div class="col-auto row items-center justify-between" style="height:42px;">
                        <div class="col-auto row items-center justify-start">
                          <div class="col-auto row items-center justify-start">
                            <q-select dense outlined options-dense v-model="selectedModelOptionTab" :options="selectModelOptionTabs"
                                    class="col-auto" color="red-7"
                                    hide-bottom-space
                                    @update:model-value="changedModelOptionTab"
                                    style="color:black;margin:-6px 0 0 0;">
                              <template v-slot:selected>
                                <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500;'" style="font-size:16px;">
                                  {{selectedModelOptionTab}}
                                </div>
                              </template>
                            </q-select>
                          </div>
                          <!--
                          <q-separator color="grey-7" vertical style="margin:3px 3px 3px 6px;"></q-separator>
                          <div class="col-auto" style="font-size:16px;font-weight:400;color:#eb0028;">&nbsp;{{ termByDealer }}</div>
                          -->
                        </div>
                        <div class="col row items-center justify-end">
                          <q-select borderless dense options-dense v-model="selectedModelOption" :options="selectModelOptions"
                                    class="col-auto" color="red-7" hide-bottom-space v-show="!chartForModel"
                                    @update:model-value="changedSelectedModel"
                                    :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:14px;min-width:80px;margin:-12px 0 0 0;height:20px">
                          </q-select>
                        </div>
                      </div>
                      <div id="modelOptionContainer" class="col row q-mt-xs q-pa-sm items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-scroll-area class="col row items-center justify-center" style="position:absolute;left:6px;top:6px;right:6px;bottom:6px;border-radius: 3px;">
                          <div ref="refModelOption" id="modelOptionChart" class="col-auto" style=""></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeModelOption"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </q-splitter>
          </template>
          <template v-slot:separator>
            <div style="background-color:#f2f2f2;width:100%;height:12px;" class="row items-center justify-center">
              <div class="horizSeparator" style=""></div>
            </div>
          </template>
          <template v-slot:after>
            <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
            <q-splitter v-model="downSplitter" :limits="[20, 80]" 
                        :separator-style='!isLandscape ? "height:12px" : "width:12px"'
                        :horizontal="!isLandscape"
                        separator-class="transparent">
              <template v-slot:before>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 기종/모델별 사용시간 (우측 하단) -->
                    <div class="col column" style="">
                      <div class="col-auto row items-center justify-between" style="height:42px;">
                        <div class="col row justify-start">
                          <div class="col-auto row items-center">
                            <q-select dense outlined options-dense v-model="selectedDealerModelOptionTab" :options="selectDealerModelOptionTabs"
                                    class="col-auto" color="red-7"
                                    hide-bottom-space
                                    @update:model-value="changedDealerModelOptionTab"
                                    style="color:black;margin:-6px 0 0 0;">
                              <template v-slot:selected>
                                <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500;'" style="font-size:16px;">
                                  {{selectedDealerModelOptionTab}}
                                </div>
                              </template>
                            </q-select>
                          </div>
                          <!--
                          <q-separator color="grey-7" vertical style="margin:3px 3px 3px 6px;"></q-separator>
                          <div class="col-auto" style="font-size:16px;font-weight:400;color:#eb0028;">&nbsp;{{ termByDealer }}</div>
                          -->
                        </div>
                        <div class="col row items-center justify-end">
                          <q-select borderless dense options-dense v-model="selectedDealerModel" :options="selectDealerModels"
                                    class="col-auto" color="red-7" hide-bottom-space v-show="!tableForModel"
                                    @update:model-value="changedDealerModel"
                                    :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:16px;min-width:80px;margin:-12px 0 0 0;height:20px">
                          </q-select>
                        </div>
                      </div>
                      <div class="col row q-mt-xs items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-table class="col"
                                ref="modelTable"
                                style="position:absolute;left:0;top:0;right:0;bottom:0"
                                dense flat  virtual-scroll hide-no-data
                                separator="none"
                                :columns="tableColumns"
                                :visible-columns="tableVisibleColumns"
                                :rows="tableRows"
                                :rows-per-page-options="[0]"
                                hide-pagination
                                row-key="index">
                          <template v-slot:header="props">
                            <q-th no-caps style="height:auto;background-color: white;border-right:1px solid #e0e0e0;" class="tableHeader"
                                  :style='col.name=="col0" ? "position:sticky;z-index:4;left:0":""'
                                  v-for="col in props.cols" :key="col.name" :props="props">
                              {{ col.label }}
                            </q-th>
                          </template>
                          <template v-slot:body="props">
                            <q-tr :props="props" :style='props.row.index % 2 == 0 ? "background-color:#f8f8f8;" : "background-color:white;"'>
                              <q-td v-for="col in props.cols" :key="col.name" :props="props" style="border-bottom:1px solid #e0e0e0;border-right:1px solid #e0e0e0;"
                                    :style='col.name=="col0" ? "font-weight:500;position:sticky;z-index:2;left:0;":"font-weight:400;"'>
                                {{ col.value }}
                              </q-td>
                            </q-tr>
                          </template>
                        </q-table>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:separator>
                <div style="background-color:#f2f2f2;" class="row items-center justify-center">
                  <div :class='isLandscape ? "vertSeparator" : "horizSeparator"' style=""></div>
                </div>
              </template>
              <template v-slot:after>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 연간 모델별 (우측 하단) -->
                    <div class="col column" style="">
                      <div class="col-auto row items-center justify-between" style="height:42px;">
                        <div class="col row items-center justify-start">
                          <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500'" style="font-size:16px;">{{ $t('statEngineHour.mhfor12months')}}</div>
                          <q-separator color="grey-7" vertical style="margin:3px 3px 3px 6px;" v-show="latestTerm != ''"></q-separator>
                          <div class="col-auto" style="font-family:Prometo;font-size:15px;color:#eb0028" v-show="latestTerm != ''">&nbsp;{{ latestTerm }}</div>
                        </div>
                        <div class="col-auto row items-center justify-center" >
                          <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:15px;">{{ $t('common.unit') }}&nbsp;:&nbsp;(h)</div>
                        </div>
                      </div>
                      <div id="yearModelContainer" class="col row q-mt-xs q-pa-sm items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-scroll-area class="col row items-center justify-center" style="position:absolute;left:6px;top:6px;right:6px;bottom:6px;border-radius: 3px;">
                          <div ref="refYearModel" id="yearModelChart"></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeYearModel"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </q-splitter>
          </template>
        </q-splitter>
      </div>

      <div class="row items-center justify-center" v-show="isLoadingData"
          style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
        <q-spinner-tail dense color="red-7" size="100px"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.grpBtn {
  height:40px;
  border-radius: 3px;
  border:1px solid #cccccc;
}
.grpBtn:hover {
  height:40px;
  border-radius: 3px;
  border:1px solid black;
}
.grpBtn:active {
  height:40px;
  border-radius: 3px;
  border:2px solid #eb0028;
}
.cellBackground {
  width:100%;
  height:100%;
  background-color:white;
  border:1px solid #ebedf2;
  border-radius:3px;
  padding:12px
}
.horizSeparator {
  width:32px;
  height:4px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.vertSeparator {
  width:4px;
  height:32px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.tableHeader {
  position: sticky;
  z-index: 3;
  top:0;
  background-color: white;
  font-size:14px;
  font-weight: 500;
  border-bottom: 1px solid #E0E0E0;
}

</style>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTymictStore } from '@/store/tymict'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'
import TymAws from '@/js/tymaws.js'
import moment from 'moment-timezone'
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue3-xlsx';

export default ({
  props : {
    kind : ref(0)
  },
  components : {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data() {
    return {
    }
  },
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean()  {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    getCategory() {
      const store = useTymictStore()
      return store.getMachineTypes()
    },
    getMonthTitle() {
      const i18n=useI18n()

      let result = []
      result.push(i18n.t('common.month'))
      for(let i = 1; i <= 12; i++) {
        result.push(i18n.t('yearMonth.' + i.toString()))
      }
      return result
    },
    getWorkTypes() {
      const i18n=useI18n()
      return [
        i18n.t('workType.engine'),
        i18n.t('workType.move'),
        i18n.t('workType.pto'),
        i18n.t('workType.plow'),
      ]
    },
    machineCount() {
      const store = useTymictStore()
      return store.machineLocaInfo.length
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return store.canDownloadToExcel()
    }
  },
  setup() {
    const i18n=useI18n()

    return {
      horizSplitter : ref(50),
      upSplitter : ref(49.75),
      downSplitter : ref(49.75),

      isLoadingData : ref(false),
      groupPopupDisplayed : ref(false),

      groupTreeData : ref([]),
      expandedTree : ref([]),
      selectedTree : ref(""),
      selectedTreeLabel : ref(""),
      
      selectedYear : ref(''/*i18n.t('common.all')*/),
      validYears : ref([]),
      catSelected : ref(i18n.t('common.type')),

      selectedMonth : ref(i18n.t('common.month')),
      workSelected : ref(i18n.t('workType.engine')),

      titleForLocalHours : ref(''),
      
      termByDealer : ref(''),
      yearlyLayout : ref({
        // title: 'Number of Graphs Made this Week',
        showlegend: false,
        dragmode : "orbit",
        barmode: 'stack',
        bargap : 0.15,
        // height: 240,
        automargin: true,
        /*
        xaxis: {
          tickangle: -30
        },
        */

      }),
      barChartConfig : ref({
        autosizable : false,
        displayModeBar: false,
        displaylogo: false,
        scrollZoom: false,
        responsive: true,
      }),

      chartForModel : ref(true),
      selectedModelOptionTab : ref(''),
      selectModelOptionTabs : ref([]),

      modelOptionAxisData : ref([]),

      selectedModelOption : ref(''),
      selectModelOptions : ref([]),   // Select에 표시용
      selectModelOptionTree : ref([]),  // Select에서 기종을 골랐을 때 어떤 모델들이 포함되었는지 

      modelOptionData : ref([
        {
          x: [],
          y: [],
          type: 'bar',
          textposition: 'auto',
          hoverinfo: 'none',
          marker: {
            color: '#eb0028',
            line: {
              color: 'black',
              width: 0
            }
          },
        }
      ]),

      latestTerm : ref(''),
      yearTitles : ref([]),
      yearModelLayout : ref({
        showlegend: true,
        dragmode : "orbit",
        barmode: 'stack',
        bargap : 0.15,
        height: 240,
        automargin: true,
        margin: {
          l : 40,
          t : 5,
          r : 5,
          b : 25
        },
      }),

      tableForModel : ref(true),
      selectedDealerModelOptionTab : ref(''),
      selectDealerModelOptionTabs : ref([]),
      tableColumns : ref([]),
      tableVisibleColumns : ref([]),
      tableRows : ref([]),
      dealerModelOptionData : ref([]),
      selectedDealerModel : ref(''),
      selectDealerModels : ref([]),
      selectDealerModelTree : ref([]),  // Select에서 기종을 골랐을 때 어떤 모델들이 포함되었는지 
      
      sheets: ref([]),

      hasExcelData : ref(true),
      makingExcel : ref(true),
      excelFilename : ref(''),
      excelFrom : ref(''),
      excelTo : ref(''),
      excelDealer : ref(''),
      excelType : ref(''),

      yearlyData : ref([]), 
      dealerData : ref([]),
      modelData : ref([]),
      optionData : ref([])
    }
  },
  created() {},
  mounted() {
    const store = useTymictStore()
    let now = new Date()

    let saved = sessionStorage.getItem('STAT.usage.saved')
    //console.log('STAT.usage.saved load0 :', saved)

    if(!TymCommon.isEmpty(saved)) {
      saved = JSON.parse(saved)
      //console.log('STAT.usage.saved load :', saved)
      this.selectedYear = saved.Year
      this.selectedMonth = saved.Month
    } else {
      this.selectedYear = now.getFullYear().toString()
      if(now.getDate() == 1)
        this.selectedMonth = this.getMonthTitle[now.getMonth()]
      else
        this.selectedMonth = this.getMonthTitle[now.getMonth() + 1]
    }

    this.validYears = []
    for(let year = now.getFullYear(); year >= 2021; year--) {
      this.validYears.push(year.toString())
    }
    this.selectModelOptionTabs = [
      this.$t('statEngineHour.hbymodel'),
      this.$t('statEngineHour.hbyoption'),
    ]
    this.selectedModelOptionTab = this.selectModelOptionTabs[0]

    this.selectDealerModelOptionTabs = [
      this.$t('statEngineHour.hbydealermodel'),
      this.$t('statEngineHour.hbydealeroption'),
    ]
    this.selectedDealerModelOptionTab = this.selectDealerModelOptionTabs[0]

    this.updateDealerChart(true)
    this.updateModelOptionChart(true)
    this.updateYearModelChart(true)
    this.updateDealerModelTable(true)

    setTimeout(()=>{
      if(store.groupTree.length > 0) {
        this.groupTreeData = store.groupTree
        
        if(!TymCommon.isEmpty(saved)) {
          if(!TymCommon.isEmpty(saved.Expanded))
            this.expandedTree = saved.Expanded
          if(!TymCommon.isEmpty(saved.Selected))
            this.selectedTree = saved.Selected
          this.selectedTreeLabel = saved.Group
          this.catSelected = saved.Category
          this.workSelected = saved.Type

          this.isLoadingData = true
          this.getYearlyUsage()
          this.searchData()
        } else {
          this.expandedTree = [this.groupTreeData[0].uuid]
          this.selectedTree = this.groupTreeData[0].uuid
          let result = store.findGroupNameByKey(this.selectedTree)
          if(result) {
            this.selectedTreeLabel = result
          }
          this.isLoadingData = true
          this.getYearlyUsage()

          this.getUsageData()
        }
      }
      sessionStorage.removeItem('STAT.usage.saved')
    }, 25)
  }, 
  unmounted() {
    sessionStorage.setItem('STAT.usage.saved', JSON.stringify({
      Expanded: this.expandedTree,
      Selected: this.selectedTree,
      Group: this.selectedTreeLabel,
      Year: this.selectedYear,
      Month: this.selectedMonth,
      Category: this.catSelected,
      Type: this.workSelected
    }))
    if(TymConst.IS_DEVELOPMENT) {
      console.log('STAT.usage.saved',  JSON.stringify({
        Expanded: this.expandedTree,
        Selected: this.selectedTree,
        Group: this.selectedTreeLabel,
        Year: this.selectedYear,
        Month: this.selectedMonth,
        Category: this.catSelected,
        Type: this.workSelected
      }))
    }
  },
  methods : {
    unusedParam() {
      //
    },
    resizeDealer(size) {
      this.unusedParam(size)
      setTimeout(()=>{
        if(this.$refs.refDealer) {
          let container = document.getElementById('dealerContainer')
          if(!container) {
            return
          }
          if(TymCommon.isEmpty(this.$refs.refDealer.data)) {
            console.log('statEngine.resizeDealer... EMPTY DATA')
            return
          }

          let barCount = this.$refs.refDealer.data[0].x.length
          let minWidth = barCount * 70
          minWidth = minWidth >= container.clientWidth ? minWidth : container.clientWidth - 60
          let maxWidth = barCount * 150
          if(barCount <= 2) {
            minWidth += 160
            maxWidth += 160
          }
          if(minWidth > maxWidth)
            minWidth = maxWidth

          window.Plotly.relayout(this.$refs.refDealer, {
            bargap : 0.15,
            width: minWidth,
            height: container.clientHeight - 18,
            margin: {
              l : 40,
              t : 10,
              r : 5,
              b : 65
            }
          })
        }
      }, 1)
    },
    resizeModelOption(size) {
      this.unusedParam(size)
      setTimeout(()=>{
        if(this.$refs.refDealer) {
          let container = document.getElementById('modelOptionContainer')
          if(!container) {
            return
          }
          if(TymCommon.isEmpty(this.$refs.refModelOption.data)) {
            return
          }
          if(this.$refs.refModelOption.data.length < 1) {
            return
          }

          let barCount = this.$refs.refModelOption.data[0].x.length
          let minWidth = barCount * 70
          minWidth = minWidth >= container.clientWidth ? minWidth : container.clientWidth - 60
          let maxWidth = barCount * 150
          if(barCount <= 2) {
            minWidth += 160
            maxWidth += 160
          }
          if(minWidth > maxWidth)
            minWidth = maxWidth

          window.Plotly.relayout(this.$refs.refModelOption, {
            bargap : 0.15,
            width: minWidth,
            height: container.clientHeight - 18,
            margin: {
              l : 40,
              t : 10,
              r : 5,
              b : 65
            }
          })
        }
      }, 1)
    },
    resizeYearModel(size) {
      this.unusedParam(size)
      setTimeout(() => {
        if(this.$refs.refYearModel) {
          let container = document.getElementById('yearModelContainer')
          if(!container) {
            return
          }
          if(TymCommon.isEmpty(this.$refs.refYearModel.data)) {
            //console.log('statEm.resizeModelOption... EMPTY DATA')
            return
          }

          // let barCount = this.$refs.refModelOption.data[0].x.length
          let width = container.clientWidth < 520 ? 520 : container.clientWidth
          let height = container.clientHeight < 240 ? 240 : container.clientHeight

          width -= 30
          height -= 15

          window.Plotly.relayout(this.$refs.refYearModel, {
            width: width,
            height: height,
            margin: {
              l : 40,
              t : 10,
              r : 5,
              b : 65
            }
          })
        }
      }, 1)
    },

    groupTreeSelected(tgt) {
      const store = useTymictStore()

      this.selectedTree = tgt
      let result = store.findGroupNameByKey(this.selectedTree)
      if(result) {
        this.selectedTreeLabel = result
      }
      this.$refs.groupPopup.hide()
    },
    clearGroupData() {
      const store = useTymictStore()

      this.selectedTree = this.groupTreeData[0].uuid
      let result = store.findGroupNameByKey(this.selectedTree)
      if(result) {
        this.selectedTreeLabel = result
      }
    },
    searchData() {
      setTimeout(() => {
        // this.getUsageTimeInfo(false)
        this.getUsageData()
      }, 1)
    },

    // isModel : 기종별이면 True
    changeModelOption(isModel) {
      if(this.chartForModel != isModel) {
        this.chartForModel = isModel

        this.updateModelOptionChart(false, this.chartForModel)
      }
    },
    // isModel : 기종별이면 True
    changeTableOption(isModel) {
      if(this.tableForModel != isModel) {
        this.tableForModel = isModel

        this.updateDealerModelOptionTable(false)
      }
    },
    updateDealerChart(firstTime) {
      if((this.selectedYear == this.$t('common.all')) && (this.selectedMonth == this.$t('common.month'))) {
        this.termByDealer = this.$t('common.all')
      } else if((this.selectedYear == this.$t('common.all')) && (this.selectedMonth != this.$t('common.month'))) {
        this.termByDealer = this.selectedMonth
      } else if((this.selectedYear != this.$t('common.all')) && (this.selectedMonth == this.$t('common.month'))) {
        this.termByDealer = this.selectedYear
      } else if((this.selectedYear != this.$t('common.all')) && (this.selectedMonth != this.$t('common.month'))) {
        let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
        let ymon = new Date(Number(this.selectedYear), monIndex - 1, 2, 12, 0)
        this.termByDealer = TymCommon.getMonthString(ymon)
      }

      this.totalByDealer = 0
      this.totalADSByDealer = 0
      if(firstTime) {
        var value1 = {
          x: [],
          y: [],
          name: 'Normal',
          type: 'bar',
          text: []
        };
        this.dealerAxisData = [value1]
        const div = document.getElementById('dealerChart')
        if(div)
          new window.Plotly.newPlot(div, this.dealerAxisData, this.yearlyLayout, this.barChartConfig)
      } else {
        let xLab = [], yLab = [], textLab = []
        for(let idx = 0; idx < this.dealerData.length; idx++) {
          xLab.push(this.dealerData[idx].Dealer)
          yLab.push(this.dealerData[idx].Hour / 60.0)
          let value = ''
          if(this.dealerData[idx].Hour > 0) {
            let hour = Math.trunc(this.dealerData[idx].Hour / 60.0)
            let minute = this.dealerData[idx].Hour % 60
            value = hour + ':' + minute.toString().padStart(2, '0')
          } else {
            value = this.dealerData[idx].Hour.toString()
          }
          textLab.push(value)
        }
        // textLab = yLab.map(String)

        this.dealerAxisData[0] = {
          x: xLab,
          y: yLab,
          text : textLab,
          hovermode : "closest",
          hovertemplate : '<span style="color:#404040;">%{x}</span> <span style="color:#eb0028;font-weight:bold;">%{text}</span> <extra></extra>',
          hoverlabel: {
            bgcolor: 'white',
            bordercolor: '#eb0028',
          },
          type : 'bar',
          marker: {
            color: '#eb0028',
            line: {
              color: 'black',
              width: 0
            }
          }
        }

        window.Plotly.react('dealerChart', this.dealerAxisData, this.yearlyLayout, this.barChartConfig)
        this.resizeDealer(null)
      }
    },
    updateModelOptionChart(firstTime, isModel) {
      let xLab = [], yLab = []
      if(firstTime) {
        xLab.push('')
        yLab.push()
        this.modelOptionData[0].x = xLab.map(String)
        this.modelOptionData[0].y = yLab
        this.modelOptionData[0].text = yLab.map(String)
          window.Plotly.newPlot('modelOptionChart', this.modelOptionData, this.yearlyLayout, this.barChartConfig)
      } else {
        let chartData = null
        if(isModel) {
          chartData = this.modelData
        } else {
          chartData = []
          this.modelData.forEach(model => {
            model.Options.forEach(option => {
              if(this.selectedModelOption == this.$t('common.all')) {
                let name = model.Model
                if(option.Option != '0' && option.Option != 'NONE') {
                  name += option.Option
                }
                let found = chartData.find(x => x.Model == name)
                if(found) {
                  found.Hour += option.Hour
                } else {
                  chartData.push({
                    Model : name,
                    Hour : option.Hour,
                  })
                }
              } else {
                if(this.selectedModelOption == model.Model) {
                  let name = model.Model
                  if(option.Option != '0' && option.Option != 'NONE') {
                    name += option.Option
                  }
                  let found = chartData.find(x => x.Model == name)
                  if(found) {
                    found.Hour += option.Hour
                  } else {
                    chartData.push({
                      Model : name,
                      Hour : option.Hour
                    })
                  }
                }
              }
            })
          })
        }

        if(!chartData)
          return

        let xLab = [], yLab = [], textLab = []
        for(let idx = 0; idx < chartData.length; idx++) {
          if(chartData[idx].Hour > 0) {
            xLab.push(chartData[idx].Model)
            yLab.push(chartData[idx].Hour / 60)
            let value = ''
            if(chartData[idx].Hour > 0) {
              let hour = Math.trunc(chartData[idx].Hour / 60.0)
              let minute = chartData[idx].Hour % 60
              value = hour + ':' + minute.toString().padStart(2, '0')
            } else {
              value = chartData[idx].Hour.toString()
            }
            textLab.push(value)
          }
        }
        // textLab = yLab.map(String)

        this.modelOptionAxisData[0] = {
          x: xLab,
          y: yLab,
          text: textLab,
          hovermode : "closest",
          hovertemplate : '<span style="color:#404040;">%{x}</span> <span style="color:#eb0028;font-weight:bold;">%{text}</span> <extra></extra>',
          hoverlabel: {
            bgcolor: 'white',
            bordercolor: '#eb0028',
          },
          type : 'bar',
          marker: {
            color: '#eb0028',
            line: {
              color: 'black',
              width: 0
            }
          }
        }

        window.Plotly.react('modelOptionChart', this.modelOptionAxisData, this.yearlyLayout, this.barChartConfig)
        this.resizeModelOption(null)
      }
    },
    
    updateYearModelChart(firstTime) {
      let yearModelAxisData = []

      if(firstTime) {
        let xLab = [], yLab = []
        yearModelAxisData.push({
          x : xLab.map(String),
          y : yLab,
          text : yLab.map(String)
        })
        this.yearModelLayout.width = xLab.length * 50

        const div = document.getElementById('yearModelChart')
        if(div)
          new window.Plotly.newPlot(div, yearModelAxisData, this.yearModelLayout, this.barChartConfig)
      } else {
        if(this.yearlyData.length < 1)
          return

        // console.log('updateYearModelChart :', this.yearlyData)
        let yLab = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.totalByYear = 0

        let model = 0, month = 0
        let titles = [], tooltips = []
        for(model = 0; model < this.yearlyData[0].Models.length; model++) {
          titles = []
          tooltips = []
          for(month = 0; month < this.yearlyData.length; month++) {
            // yLab[month] = this.yearlyData[month].Models[model].TotalVUDT
            //yLab[month] = Math.round(this.yearlyData[month].Models[model].TotalVUDT / 60)
            titles.push(this.yearlyData[month].Title)
            let hour = Math.ceil(this.yearlyData[month].Models[model].TotalVUDT / 60)
            let min = this.yearlyData[month].Models[model].TotalVUDT % 60
            yLab[month] = parseFloat(hour + '.' + min)
            tooltips.push(this.yearlyData[month].Title + ', ' + this.yearlyData[month].Models[model].Name + ' : ' + this.yearlyData[month].Models[model].TotalVUDT)
            // tooltips.push(this.yearlyData[month].Models[model].Name + ' ' + hour.toString() + ':' + min.toString().padStart(2, '0'))
            if(model == 0) {
              this.totalByYear += this.yearlyData[month].TotalVUDT
            }
          }

          let axis = {
            x : titles.map(String),
            y : yLab.map(String),
            mode : "lines+markers",
            type : "scatters",
            marker : {
              size : 7.5
            },
            // hovertemplate: '<i>%{text}</i>', 
            hoverinfo : 'text',
            name : this.yearlyData[0].Models[model].Name,
            text: tooltips,
            hovermode : "closet",
            hoverlabel: { bgcolor: '#fff' },
          }
          yearModelAxisData.push(axis)
        }

        const div = document.getElementById('yearModelChart')
        if(div) {
          this.yearModelLayout.width = div.offsetWidth > 12 * 60 ? div.offsetWidth : 12 * 60
          this.yearModelLayout.hovermode = "closest"
          this.yearModelLayout.hoverlabel = { bgcolor: "#FFF" }
          window.Plotly.react(div, yearModelAxisData, this.yearModelLayout, this.barChartConfig)
        }
      }
    },

    // 테이블 업데이트
    updateDealerModelTable(firstTime) {
      this.tableColumns = []
      this.tableRows = []
      
      if(firstTime || (this.dealerModelOptionData.length < 1)) {
        this.tableVisibleColumns = []
      } else {
        if(this.dealerModelOptionData.length < 1)
          return

        // 첫번째 컬럼(인덱스로 쓰자)
        this.tableColumns.push({
          name: 'index',
          required: false
        })

        //console.log('updateDealerModelTable START ', this.dealerModelOptionData)

        let isAllOptions = true
        let srcData = []
        if(this.tableForModel) {
          this.dealerModelOptionData[0].Models.forEach(model => {
            srcData.push(model.Model)
          })
        }
        else {
          isAllOptions = this.selectedDealerModel == this.selectDealerModels[0]
          
          //console.log('updateDealerModelTable OPTION :', isAllOptions, this.selectedDealerModel, this.selectDealerModels)
          if(isAllOptions) {
            // 전체
            this.dealerModelOptionData[0].FullModels.forEach(model => {
              srcData.push(model.Model)
            })
          } else {
            this.dealerModelOptionData[0].Models.forEach(model => {
              if(model.Model == this.selectedDealerModel) {
                model.Options.forEach(opt => {
                  srcData.push(opt)
                })
              }
            })
          }
        }

        // 컬럼 헤더 부분(맨 윗부분)
        // 첫컬럼은 비워두자... 딜러명 표시컬럼
        let colCount = srcData.length
        this.tableColumns.push({
            name: 'col0',
            field: 'col0',
            label: '',
            align: 'left',
            required: false,
            sortable: false,
          })
        this.tableVisibleColumns.push('col0')

        //console.log('updateDealerModelTable :', colCount, this.dealerModelOptionData, srcData)

        // 기종과 모델별로 표시되는 컬럼들
        let idx = 0
        for(idx = 0; idx < srcData.length; idx++) {
          let model = srcData[idx]
          if(!this.tableForModel && !isAllOptions) {
            model = this.selectedDealerModel
            if(srcData[idx].Option != '0' && srcData[idx].Option != 'NONE')
              model += srcData[idx].Option
          }
          this.tableColumns.push({
            name: 'col' + (idx + 1).toString(),
            field: 'col' + (idx + 1).toString(),
            label: model,
            align: 'center',
            required: false,
            sortable: false,
          })
          this.tableVisibleColumns.push('col' + (idx + 1).toString())
        }

        // 변수 재활용하니 유의바람
        // 마지막 컬럼에 합계 추가
        this.tableColumns.push({
          name: 'col' + (idx + 1).toString(),
          field: 'col' + (idx + 1).toString(),
          label: this.$t('common.sum'),
          align: 'center',
          required: false,
          sortable: false,
        })
        this.tableVisibleColumns.push('col' + (idx + 1).toString())

        let row = 0, col = 0
        let modelTotal = [] // 기종/모델별 합계
        // console.log('updateDealerModelTable :', this.dealerModelOptionData)
        for(row = 0; row < this.dealerModelOptionData.length; row++) {
          let oneRow = []
          oneRow['index'] = row
          let item = this.dealerModelOptionData[row]
          oneRow['col0'] = item.Dealer
          if(!this.tableForModel && !isAllOptions) {
            let opt = this.dealerModelOptionData[row].Models.find(x => x.Model == this.selectedDealerModel)
            item = opt
          }
          let dealerTotal = 0    // 한 지역 합계용
          for(col = 0; col < colCount; col++) {
            if(isNaN(modelTotal[col]))
              modelTotal[col] = 0

            if(this.tableForModel) {
              let value = isNaN(item.Models[col].Hour) ? 0 : item.Models[col].Hour
              // value = Math.round(value / 60)
              dealerTotal += value
              modelTotal[col] += value
              if(item.Models[col].Hour > 0) {
                let hour = Math.trunc(item.Models[col].Hour / 60.0)
                let minute = item.Models[col].Hour % 60
                value = hour + ':' + minute.toString().padStart(2, '0')
              } else {
                value = value.toString()
              }
              oneRow['col' + (col + 1).toString()] = value
            } else {
              if(isAllOptions) {
                let value = isNaN(item.FullModels[col].Hour) ? 0 : item.FullModels[col].Hour
                // value = Math.round(value / 60)
                dealerTotal += value
                modelTotal[col] += value
                if(item.FullModels[col].Hour > 0) {
                  let hour = Math.trunc(item.FullModels[col].Hour / 60.0)
                  let minute = item.FullModels[col].Hour % 60
                  value = hour + ':' + minute.toString().padStart(2, '0')
                } else {
                  value = value.toString()
                }
                oneRow['col' + (col + 1).toString()] = value
              } else {
                let value = isNaN(item.Options[col].Hour) ? 0 : item.Options[col].Hour
                // value = Math.round(value / 60)
                dealerTotal += value
                modelTotal[col] += value
                if(item.Options[col].Hour > 0) {
                  let hour = Math.trunc(item.Options[col].Hour / 60.0)
                  let minute = item.Options[col].Hour % 60
                  value = hour + ':' + minute.toString().padStart(2, '0')
                } else {
                  value = value.toString()
                }
                oneRow['col' + (col + 1).toString()] = value
              }
            }
          }
          // 모델별합계???
          if(isNaN(modelTotal[col]))
            modelTotal[col] = 0
          modelTotal[col] += dealerTotal

          // 우측끝에 합계 추가
          if(dealerTotal > 0) {
            let hour = Math.trunc(dealerTotal / 60.0)
            let minute = dealerTotal % 60
            dealerTotal = hour + ':' + minute.toString().padStart(2, '0')
          } else {
            dealerTotal = 0
          }
          oneRow['col' + (col + 1).toString()] = dealerTotal
          this.tableRows.push(oneRow)
        }

        // 마지막 Row에 합계 추가
        let oneRow = {
          index : row,
          col0 : this.$t('common.sum'),
        }
        for(col = 0; col <= colCount; col++) {
          if(modelTotal[col] > 0) {
            let hour = Math.trunc(modelTotal[col] / 60.0)
            let minute = modelTotal[col] % 60
            modelTotal[col] = hour + ':' + minute.toString().padStart(2, '0')
          }
          oneRow['col' + (col + 1).toString()] = modelTotal[col]
        }

        // console.log(oneRow)
        this.tableRows.push(oneRow)
      }
    },
    changedSelectedModel(value) {
      this.unusedParam(value)
      this.updateModelOptionChart(false, this.chartForModel)
    },
    changedDealerModel(value) {
      this.unusedParam(value)
      this.updateDealerModelTable(false)
    },
    // 기종별/모델별 사용시간 선택시
    changedModelOptionTab(value) {
      this.unusedParam(value)
      this.chartForModel = !this.chartForModel
      this.selectedModelOption = this.selectModelOptions[0]
      this.updateModelOptionChart(false, this.chartForModel)
    },
    changedDealerModelOptionTab(value) {
      this.unusedParam(value)
      this.tableForModel = !this.tableForModel
      this.selectedDealerModel = this.selectDealerModels[0]
      this.updateDealerModelTable(false)
    },

    onShowExportToExcel() {
      this.makingExcel = true;

      let now = new Date()
      this.excelFilename = 'EngineHour_' + now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += '_' + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += '.xlsx'
      let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
      if(monIndex == 0) {
        let from = new Date(this.selectedYear, 0, 1)
        this.excelFrom = TymCommon.getDateString(from)
        if(now.getFullYear().toString() == this.selectedYear) {
          let dst = new Date()
          // dst = new Date(dst.setDate(dst.getDate()))
          this.excelTo = TymCommon.getDateString(dst)
        } else {
          this.excelTo = TymCommon.getDateString(new Date(this.selectedYear, 11, 31))
        }
      } else {
        let from = new Date(this.selectedYear, monIndex - 1, 1)
        this.excelFrom = TymCommon.getDateString(from)
        let dst = null
        if((now.getFullYear() == from.getFullYear()) && (now.getMonth() == from.getMonth())) {
          dst = new Date()
          dst = new Date(dst.setDate(dst.getDate()))
        } else {
          dst = new Date(from.setMonth(from.getMonth() + 1))
          dst = new Date(dst.setDate(dst.getDate()))
          dst -= 24 * 60 * 60
        }
        this.excelTo = TymCommon.getDateString(dst)
      }
      this.excelDealer = this.selectedTreeLabel
      this.excelType = this.catSelected == this.getCategory[0] ? this.$t('common.all') : this.catSelected

      setTimeout(() => {
        try {
          this.sheets = []
          this.makeExcelData2()
        } catch(ex) {
          console.log(ex)
        }
      }, 1)
    },
    makeExcelData2()
    {
      const store = useTymictStore()
      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      let groups = store.getSubGroups(this.selectedTree)
      if(TymCommon.isEmpty(groups))
        return

      let today = moment()
      let year = parseInt(this.selectedYear)
      let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
      let startDay = null, endDay = null

      if(monIndex == 0) {
        startDay = year.toString() + "-01-01 00:00:00"
        endDay =  (year + 1).toString() + "-01-01 00:00:00"
      } else {
        startDay = year.toString() + "-" + monIndex.toString().padStart(2, '0') + "-01 00:00:00"
        endDay = year.toString() + "-" + (monIndex + 1).toString().padStart(2, '0') + "-01 00:00:00"
      }

      /*
      let srcStartDay = startDay
      let srcEndDay = new Date(endDay)
      // srcEndDay -= 60 * 60 * 24     // 하루를 빼서 어제까지 본다.
      srcEndDay = new Date(srcEndDay)
      srcEndDay = srcEndDay.getFullYear().toString() + "-" + (srcEndDay.getMonth() + 1).toString().padStart(2, '0') + "-" + srcEndDay.getDate().toString().padStart(2, '0')
                // + " 00:00:00"
                + ' ' + srcEndDay.getHours().toString().padStart(2, '0') + ':' + srcEndDay.getMinutes().toString().padStart(2, '0') + ':' + srcEndDay.getSeconds().toString().padStart(2, '0')
      */

      let startResult = TymCommon.localTimeToUTC(startDay, "YYYY-MM-DD HH:mm:ss")
      let endResult = TymCommon.localTimeToUTC(endDay, "YYYY-MM-DD HH:mm:ss")

      if(endResult.dateTime > today) {
        endResult = TymCommon.localTimeToUTC(today.format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss")
        
        // srcEndDay = today.subtract(1, 'day')
        //srcEndDay = moment(endResult)
        //srcEndDay = srcEndDay.format("YYYY-MM-DD 00:00:00")
      }
      startDay = startResult.dateTimeStr
      endDay = endResult.dateTimeStr
      if(TymConst.IS_DEVELOPMENT) {
        console.log('GetUsage(EXCEL.start) :', startDay, endDay)
      }

      setTimeout(() => {
        TymAws.getUsageTimeMARIA(group.uuid_a, group.uuid_b, group.uuid_c, group.uuid_d, startDay, endDay, true, false)
          .then(data => {
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GetUsage(EXCEL) :', startDay, endDay, data)
            }
            if(data == 'error' || data.length < 1) {
              this.makingExcel = false
              this.hasExcelData = false
              setTimeout(()=> {
                if(this.$refs.excelPopup)
                  this.$refs.excelPopup.hide()
              }, 2000)
              return
            }
            this.hasExcelData = true

            // console.log('StatEngineHour.ExportExcel ', data)

            let xlsRow1 = []
            xlsRow1.push('No', '기종', '모델명', '기대번호', '사용 시간(h:m)', '이동 시간(h:m)', 'PTO 작업시간(h:m)', '견인 작업시간(h:m)',
                         '고객명', '고객 연락처', '판매 지역', '판매점', '판매점 연락처')
            let rowNo = 1
            let othRows = []

            let models = []
            let dealers = []

            data.forEach(one => {
              if(this.catSelected != this.getCategory[0]) {
                if(this.catSelected != one.type)
                  return
              }
              /* one
              {
                "M_model": "K110E",
                "M_option": "NONE",
                "MN": "PFHT00236",
                "type": "트랙터",
                "VUDT": 12292,
                "MT": 4202,
                "PTOT": 2926,
                "DRT": 656,
                "UName": "전성만",
                "UPhone": "010-5150-5735",
                "MName": [
                  "TYM",
                  "국내영업본부",
                  "강원지점",
                  "양양딜러점(최현탁)"
                ],
                "MPhone": "010-9005-6915"
              }
              */
              if(store.isICTUser()) {
                //                
              }
              let modelFullname = one.M_model
              if(one.M_option != '0' && one.M_option != 'NONE') {
                modelFullname += one.M_option
              }

              let branchName = ''
              let dealerName = ''
              if(!TymCommon.isEmpty(one.MName[3])) {
                dealerName = one.MName[3]
                branchName = one.MName[2]
              } else if(!TymCommon.isEmpty(one.MName[2])) {
                dealerName = one.MName[2]
                branchName = one.MName[1]
              } else if(!TymCommon.isEmpty(one.MName[1])) {
                dealerName = one.MName[1]
                branchName = one.MName[0]
              } else {
                dealerName = one.MName[0]
              }

              // 표를 만들기위해 모델추출 과정
              if(!models.find(x => x == modelFullname)) {
                models.push(modelFullname)
              }
              // 표를 만들기위해 딜러 추출
              if(!dealers.find(x => x == dealerName)) {
                dealers.push(dealerName)
              }
              let vudt = 0, ptot = 0, drt = 0, mt = 0
              /*
              vudt = Math.round((isNaN(one.VUDT) ? 0 : one.VUDT) / 60.0)
              mt = Math.round((isNaN(one.MT) ? 0 : one.MT) / 60.0)
              ptot = Math.round((isNaN(one.PTOT) ? 0 : one.PTOT) / 60.0)
              drt = Math.round((isNaN(one.DRT) ? 0 : one.DRT) / 60.0)
              */
              vudt = isNaN(one.VUDT) ? 0 : one.VUDT
              mt = isNaN(one.MT) ? 0 : one.MT
              ptot = isNaN(one.PTOT) ? 0 : one.PTOT
              drt = isNaN(one.DRT) ? 0 : one.DRT

              let hour = '', minute = ''
              if(vudt > 0) {
                hour = Math.trunc(vudt / 60)
                minute = vudt % 60
                vudt = hour + ':' + minute.toString().padStart(2, '0')
              } else {
                vudt = vudt.toString()
              }
              if(mt > 0) {
                hour = Math.trunc(mt / 60)
                minute = mt % 60
                mt = hour + ':' + minute.toString().padStart(2, '0')
              } else {
                mt = mt.toString()
              } 
              if(ptot > 0) {
                hour = Math.trunc(ptot / 60)
                minute = ptot % 60
                ptot = hour + ':' + minute.toString().padStart(2, '0')
              } else {
                ptot = ptot.toString()
              }
              if(drt > 0) {
                hour = Math.trunc(drt / 60)
                minute = drt % 60
                drt = hour + ':' + minute.toString().padStart(2, '0')
              } else {
                drt = drt.toString()
              }

              othRows.push([rowNo.toString(), one.M_model, modelFullname, one.MN, vudt, mt, ptot, drt,
                            one.UName, one.UPhone, branchName, dealerName, one.MPhone, '', '', ''])
        
              rowNo++
            })
            
            // let dayHour = []
            let startDate = moment(this.excelFrom).format('YYMMDD')
            let endDate = moment(this.excelTo).format('YYMMDD')
            //startDate = dayHour[0]
            //endDate = dayHour[0]

            let sheetName = 'List '
            sheetName += startDate + '~' + endDate
            sheetName = sheetName.replace('/', '.')
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GetUsage(EXCEL.sheetName) :', sheetName)
            }

            othRows.splice(0, 0, xlsRow1)
            this.sheets.push({
              name : sheetName,
              data : othRows
            })

            // 지역별 사용시간 시작
            models.sort((a, b) => {
              return a.localeCompare(b)
            })

            let tableSheet = []
            groups.Names.forEach(name => {
              let oneDealer = []
              models.forEach(model => {
                oneDealer.push({
                  Model : model,
                  MT : 0,
                  PTOT : 0,
                  DRT : 0,
                  VUDT : 0
                })
              })
              tableSheet.push({
                Dealer : name,
                Models : oneDealer,
                MT : 0,
                PTOT : 0,
                DRT : 0,
                VUDT : 0
              })
            })

            data.forEach(one => {
              let dealerName = ''
              switch(groups.Depth) {
                case 0:
                  if(TymCommon.isEmpty(one.MName[1]))
                    dealerName = one.MName[0]
                  else
                    dealerName = one.MName[1]
                  break;
                case 1:
                  if(TymCommon.isEmpty(one.MName[2]))
                    dealerName = one.MName[1]
                  else
                    dealerName = one.MName[2]
                  break;
                case 2:
                  if(TymCommon.isEmpty(one.MName[3]))
                    dealerName = one.MName[2]
                  else
                    dealerName = one.MName[3]
                  break;
                case 3:
                  dealerName = one.MName[4]
                  break;
              }

              let mt = isNaN(one.MT) ? 0 : one.MT
              let ptot = isNaN(one.PTOT) ? 0 : one.PTOT
              let drt = isNaN(one.DRT) ? 0 : one.DRT
              let vudt = isNaN(one.VUDT) ? 0 : one.VUDT
              /*
              mt = Math.round(mt / 60)
              ptot = Math.round(ptot / 60)
              drt = Math.round(drt / 60)
              vudt = Math.round(vudt / 60)
              */

              let dealerFound = tableSheet.find(x => x.Dealer == dealerName)
              if(dealerFound) {
                dealerFound.MT += mt
                dealerFound.PTOT += ptot
                dealerFound.DRT += drt
                dealerFound.VUDT += vudt

                let modelFullname = one.M_model
                if(one.M_option != '0' && one.M_option != 'NONE') {
                  modelFullname += one.M_option
                }

                let modelFound = dealerFound.Models.find(x => x.Model == modelFullname)
                if(modelFound) {
                  modelFound.MT += mt
                  modelFound.PTOT += ptot
                  modelFound.DRT += drt
                  modelFound.VUDT += vudt
                }
              }
            })

            xlsRow1 = ['판매 지역', '이동 시간(h:m)',	'PTO 작업시간(h:m)', '견인 작업시간(h:m)',	'사용 시간(h:m)']
            let modelTotal = [0, 0, 0, 0]

            othRows = []
            for(let idx = 0; idx < tableSheet.length; idx++) {
              let dealer = tableSheet[idx]
              let row = [dealer.Dealer]
              
              /*
              row.push(dealer.MT)
              row.push(dealer.PTOT)
              row.push(dealer.DRT)
              row.push(dealer.VUDT)
              */

              let hour = '', minute = '', value = ''
              if(dealer.MT > 0) {
                hour = Math.trunc(dealer.MT / 60)
                minute = dealer.MT % 60
                value = hour + ':' + minute.toString().padStart(2, '0')
              } else {
                value = dealer.MT.toString()
              }
              row.push(value)

              if(dealer.PTOT > 0) {
                hour = Math.trunc(dealer.PTOT / 60)
                minute = dealer.PTOT % 60
                value = hour + ':' + minute.toString().padStart(2, '0')
              } else {
                value = dealer.PTOT.toString()
              }
              row.push(value)

              if(dealer.DRT > 0) {
                hour = Math.trunc(dealer.DRT / 60)
                minute = dealer.DRT % 60
                value = hour + ':' + minute.toString().padStart(2, '0')
              } else {
                value = dealer.DRT.toString()
              }
              row.push(value)

              if(dealer.VUDT > 0) {
                hour = Math.trunc(dealer.VUDT / 60)
                minute = dealer.VUDT % 60
                value = hour + ':' + minute.toString().padStart(2, '0')
              } else {
                value = dealer.VUDT.toString()
              }
              row.push(value)

              modelTotal[0] += (dealer.MT)
              modelTotal[1] += (dealer.PTOT)
              modelTotal[2] += (dealer.DRT)
              modelTotal[3] += (dealer.VUDT)

              othRows.push(row)
            }
            let totalRow = ['합계']
            modelTotal.forEach(hour => {
              let h = '', min = '', value = ''
              if(hour > 0) {
                h = Math.trunc(hour/ 60)
                min = hour % 60
                this.unusedParam(min)
                value = h + ':' + min.toString().padStart(2, '0')
              } else {
                value = hour.toString()
              }

              totalRow.push(value)
            })
            othRows.push(totalRow)
            sheetName = '지역별 '
            sheetName += startDate + '~' + endDate
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GetUsage(EXCEL.sheetName) :', sheetName)
            }

            othRows.splice(0, 0, xlsRow1)
            this.sheets.push({
              name : sheetName,
              data : othRows
            })
            
            // 지역/모델별 사용시간
            othRows = []
            xlsRow1 = ['구분']
            modelTotal = []
            models.forEach(model => {
              xlsRow1.push(model)
              modelTotal.push(0)
            })
            xlsRow1.push('총 사용시간(h:m)')
            for(let idx = 0; idx < tableSheet.length; idx++) {
              let dealer = tableSheet[idx]
              let row = [dealer.Dealer]
              let rowTotal = 0
              
              for(let mdl = 0; mdl < dealer.Models.length; mdl++) {
                rowTotal += dealer.Models[mdl].VUDT
                modelTotal[mdl] += dealer.Models[mdl].VUDT
                // row.push(dealer.Models[mdl].VUDT)

                let h = '', min = '', value = ''
                if(dealer.Models[mdl].VUDT > 0) {
                  h = Math.trunc(dealer.Models[mdl].VUDT/ 60)
                  min = dealer.Models[mdl].VUDT % 60
                  this.unusedParam(min)
                  value = h + ':' + min.toString().padStart(2, '0')
                } else {
                  value = dealer.Models[mdl].VUDT.toString()
                }
                row.push(value)
              }

              // row.push(rowTotal)
              let h = '', min = '', value = ''
              if(rowTotal > 0) {
                h = Math.trunc(rowTotal / 60)
                min = rowTotal % 60
                this.unusedParam(min)
                value = h + ':' + min.toString().padStart(2, '0')
              } else {
                value = rowTotal.toString()
              }
              row.push(value)

              othRows.push(row)
            }
            totalRow = ['합계']
            let total = 0
            modelTotal.forEach(hour => {
              total += hour
              // totalRow.push(hour)

              let h = '', min = '', value = ''
              if(hour > 0) {
                h = Math.trunc(hour / 60)
                min = hour % 60
                this.unusedParam(min)
                value = h + ':' + min.toString().padStart(2, '0')
              } else {
                value = hour.toString()
              }
              totalRow.push(value)
            })
            // totalRow.push(total)
            let h = '', min = '', value = ''
            if(total > 0) {
              h = Math.trunc(total / 60)
              min = total % 60
              value = h + ':' + min.toString().padStart(2, '0')
            } else {
              value = total.toString()
            }
            totalRow.push(value)

            othRows.push(totalRow)

            sheetName = '지역.모델 사용시간 '
            sheetName += startDate + '~' + endDate
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GetUsage(EXCEL.sheetName) :', sheetName)
            }

            othRows.splice(0, 0, xlsRow1)
            this.sheets.push({
              name : sheetName,
              data : othRows
            })

            // 지역/모델별 이동시간
            othRows = []
            xlsRow1 = ['구분']
            modelTotal = []
            models.forEach(model => {
              xlsRow1.push(model)
              modelTotal.push(0)
            })
            xlsRow1.push('총 이동시간(h:m)')
            for(let idx = 0; idx < tableSheet.length; idx++) {
              let dealer = tableSheet[idx]
              let row = [dealer.Dealer]
              let rowTotal = 0
              
              for(let mdl = 0; mdl < dealer.Models.length; mdl++) {
                rowTotal += dealer.Models[mdl].MT
                modelTotal[mdl] += dealer.Models[mdl].MT
                // row.push(dealer.Models[mdl].MT)

                let h = '', min = '', value = ''
                if(dealer.Models[mdl].MT > 0) {
                  h = Math.trunc(dealer.Models[mdl].MT / 60)
                  min = dealer.Models[mdl].MT % 60
                  this.unusedParam(min)
                  value = h + ':' + min.toString().padStart(2, '0')
                } else {
                  value = dealer.Models[mdl].MT.toString()
                }
                row.push(value)
              }
              //row.push(rowTotal)
              let h = '', min = '', value = ''
              if(rowTotal > 0) {
                h = Math.trunc(rowTotal / 60)
                min = rowTotal % 60
                this.unusedParam(min)
                value = h + ':' + min.toString().padStart(2, '0')
              } else {
                value = rowTotal.toString()
              }
              row.push(value)

              othRows.push(row)
            }
            totalRow = ['합계']
            total = 0
            modelTotal.forEach(hour => {
              total += hour
              // totalRow.push(hour)
              let h = '', min = '', value = ''
              if(hour > 0) {
                h = Math.trunc(hour / 60)
                min = hour % 60
                this.unusedParam(min)
                value = h + ':' + min.toString().padStart(2, '0')
              } else {
                value = hour.toString()
              }
              totalRow.push(value)
            })
            othRows.push(totalRow)
            // totalRow.push(total)
            if(total > 0) {
              h = Math.trunc(total / 60)
              min = total % 60
              this.unusedParam(min)
              value = h + ':' + min.toString().padStart(2, '0')
            } else {
              value = total.toString()
            }
            totalRow.push(value)

            sheetName = '지역.모델 이동시간 '
            sheetName += startDate + '~' + endDate
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GetUsage(EXCEL.sheetName) :', sheetName)
            }

            othRows.splice(0, 0, xlsRow1)
            this.sheets.push({
              name : sheetName,
              data : othRows
            })

            
            // 지역/모델별 PTO작업시간
            othRows = []
            xlsRow1 = ['구분']
            modelTotal = []
            models.forEach(model => {
              xlsRow1.push(model)
              modelTotal.push(0)
            })
            xlsRow1.push('총 PTO작업시간(h:m)')
            for(let idx = 0; idx < tableSheet.length; idx++) {
              let dealer = tableSheet[idx]
              let row = [dealer.Dealer]
              let rowTotal = 0
              
              for(let mdl = 0; mdl < dealer.Models.length; mdl++) {
                rowTotal += dealer.Models[mdl].PTOT
                modelTotal[mdl] += dealer.Models[mdl].PTOT
                // row.push(dealer.Models[mdl].PTOT)
                let h = '', min = '', value = ''
                if(dealer.Models[mdl].PTOT > 0) {
                  h = Math.trunc(dealer.Models[mdl].PTOT / 60)
                  min = dealer.Models[mdl].PTOT % 60
                  this.unusedParam(min)
                  value = h + ':' + min.toString().padStart(2, '0')
                } else {
                  value = dealer.Models[mdl].PTOT.toString()
                }
                row.push(value)
              }
              // row.push(rowTotal)
              let h = '', min = '', value = ''
              if(rowTotal > 0) {
                h = Math.trunc(rowTotal / 60)
                min = rowTotal % 60
                this.unusedParam(min)
                value = h + ':' + min.toString().padStart(2, '0')
              } else {
                value = rowTotal.toString()
              }
              row.push(value)
              
              othRows.push(row)
            }
            totalRow = ['합계']
            total = 0
            modelTotal.forEach(hour => {
              total += hour
              // totalRow.push(hour)
              let h = '', min = '', value = ''
              if(hour > 0) {
                h = Math.trunc(hour / 60)
                min = hour % 60
                this.unusedParam(min)
                value = h + ':' + min.toString().padStart(2, '0')
              } else {
                value = hour.toString()
              }
              totalRow.push(value)
            })
            othRows.push(totalRow)
            // totalRow.push(total)
            if(total > 0) {
              h = Math.trunc(total / 60)
              min = total % 60
              this.unusedParam(min)
              value = h + ':' + min.toString().padStart(2, '0')
            } else {
              value = total.toString()
            }
            totalRow.push(value)

            sheetName = '지역.모델 PTO시간 '
            sheetName += startDate + '~' + endDate
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GetUsage(EXCEL.sheetName) :', sheetName)
            }

            othRows.splice(0, 0, xlsRow1)
            this.sheets.push({
              name : sheetName,
              data : othRows
            })


            // 지역/모델별 견인작업시간
            othRows = []
            xlsRow1 = ['구분']
            modelTotal = []
            models.forEach(model => {
              xlsRow1.push(model)
              modelTotal.push(0)
            })
            xlsRow1.push('총 견인 작업시간(h:m)')
            for(let idx = 0; idx < tableSheet.length; idx++) {
              let dealer = tableSheet[idx]
              let row = [dealer.Dealer]
              let rowTotal = 0
              
              for(let mdl = 0; mdl < dealer.Models.length; mdl++) {
                rowTotal += dealer.Models[mdl].DRT
                modelTotal[mdl] += dealer.Models[mdl].DRT
                // row.push(dealer.Models[mdl].DRT)
                let h = '', min = '', value = ''
                if(dealer.Models[mdl].DRT > 0) {
                  h = Math.trunc(dealer.Models[mdl].DRT / 60)
                  min = dealer.Models[mdl].DRT % 60
                  this.unusedParam(min)
                  value = h + ':' + min.toString().padStart(2, '0')
                } else {
                  value = dealer.Models[mdl].DRT.toString()
                }
                row.push(value)
              }
              //row.push(rowTotal)
              let h = '', min = '', value = ''
              if(rowTotal > 0) {
                h = Math.trunc(rowTotal / 60)
                min = rowTotal % 60
                this.unusedParam(min)
                value = h + ':' + min.toString().padStart(2, '0')
              } else {
                value = rowTotal.toString()
              }
              row.push(value)

              othRows.push(row)
            }
            totalRow = ['합계']
            total = 0
            modelTotal.forEach(hour => {
              total += hour
              // totalRow.push(hour)
              let h = '', min = '', value = ''
              if(hour > 0) {
                h = Math.trunc(hour / 60)
                min = hour % 60
                this.unusedParam(min)
                value = h + ':' + min.toString().padStart(2, '0')
              } else {
                value = hour.toString()
              }
              totalRow.push(value)
            })
            othRows.push(totalRow)
            // totalRow.push(total)
            if(total > 0) {
              h = Math.trunc(total / 60)
              min = total % 60
              value = h + ':' + min.toString().padStart(2, '0')
            } else {
              value = total.toString()
            }
            totalRow.push(value)

            sheetName = '지역.모델 견인시간 '
            sheetName += startDate + '~' + endDate
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GetUsage(EXCEL.sheetName) :', sheetName)
            }

            othRows.splice(0, 0, xlsRow1)
            this.sheets.push({
              name : sheetName,
              data : othRows
            })

            setTimeout(() => {
              // console.log('>>> excel >>> ', tableSheet)
              this.makingExcel = false;
            }, 5)
          })
          .catch(er => {
            console.log('ExportExcel FAIL', er)
            this.hasExcelData = false
            this.makingExcel = false;
            setTimeout(() => {
              setTimeout(()=> {
                if(this.$refs.excelPopup)
                  this.$refs.excelPopup.hide()
              }, 2000)
            }, 5)
          })
      })
    },
    saveToExcelFile() {
      let desc = {
        filename: this.excelFilename,
        start: this.excelFrom,
        end: this.excelTo,
        dealer: this.excelDealer,
        type: this.excelType
      }
      TymAws.writeAction('통계', '사용시간', desc)

      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 500)
    },

    // 최근 1년치 데이터
    getYearlyUsage() {
      const store = useTymictStore()
      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(TymCommon.isEmpty(group))
        return

      let today = moment()
      let startDay = null, endDay = null

      startDay = (today.year() - 1).toString() + "-" + (today.month() + 2).toString().padStart(2, '0') + "-" + (today.date()).toString().padStart(2, '0') + " 00:00:00"
      endDay = today.year().toString() + "-" + (today.month() + 1).toString().padStart(2, '0') + "-" + (today.date()).toString().padStart(2, '0')
             //+ " 00:00:00"
             + ' ' + today.hour().toString().padStart(2, '0') + ':' + today.minute().toString().padStart(2, '0') + ':' + today.second().toString().padStart(2, '0')
      let tgtRange = TymCommon.makeDateTimeRange('2020-01-01 00:00:00', startDay, endDay)
      this.latestTerm = TymCommon.getMonthString(tgtRange.sDate.toDate()) + ' - ' + TymCommon.getMonthString(tgtRange.eDate.toDate())

      setTimeout(() => {
        this.yearlyData = []
        TymAws.getUsageTimeMARIA(group.uuid_a, group.uuid_b, group.uuid_c, group.uuid_d, tgtRange.sDateStr, tgtRange.eDateStr, false, true)
          .then(data => {
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GetUsage(YEARLY) :', tgtRange.sDateStr, tgtRange.eDateStr, data)
            }
            /*
            {
              "YearMonth": "2022-12",
              "M_model": "K110E",
              "totVUDT": 515
            } 
            */
            // let total = 0
            // 모델 추출
            let models = []
            data.forEach(one => {
              let found = models.find(x => x.Name == one.M_model)
              if(!found) {
                models.push({
                  Name : one.M_model,
                  TotalVUDT : 0
                })
              }
            })
            // console.log('Models :', JSON.parse(JSON.stringify(models)))

            data.forEach(one => {
              let month = moment(one.YearMonth)
              let title = TymCommon.getMonthString(month.toDate())
              let model = one.M_model
              let totVUDT = one.totVUDT

              totVUDT = Math.round(totVUDT / 60)
              let found = this.yearlyData.find(x => x.Title == title)
              if(found) {
                // 월 데이터가 존재하네
                found.TotalVUDT += totVUDT
                let foundModel = found.Models.find(x => x.Name == model)
                if(foundModel) {
                  foundModel.TotalVUDT += totVUDT
                }
              } else {
                // 해당 월 최초 데이터
                let oneMonth = {
                  Title : title,
                  Month : one.YearMonth,
                  TotalVUDT : totVUDT,
                  Models : []
                }
                models.forEach(mod => {
                  oneMonth.Models.push({
                    Name : mod.Name,
                    TotalVUDT : model == mod.Name ? totVUDT : 0
                  })
                })
                this.yearlyData.push(oneMonth)
              }
              // total += parseInt(one.Cnt)
            })
            // console.log('FINAL :', this.yearlyData)
            this.updateYearModelChart(false)
          })
          .catch(er => {
            console.log('StatEH.getUsageTimeMARIA ERROR :', er)
          })
      }, 1)
    },
    // 기간 한정 데이터 조회
    getUsageData() {
      const store = useTymictStore()
      if(TymCommon.isEmpty(store.idToken["custom:uuid_a"]))
        return

      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(TymCommon.isEmpty(group))
        return

      let groups = store.getSubGroups(this.selectedTree)
      if(TymCommon.isEmpty(groups))
        return

      this.isLoadingData = true
      

      setTimeout(() => {
        let today = moment()
        let year = parseInt(this.selectedYear)
        let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
        let startDay = null, endDay = null

        if(monIndex == 0) {
          startDay = year.toString() + "-01-01 00:00:00"
          endDay =  (year + 1).toString() + "-01-01 00:00:00"
        } else {
          startDay = year.toString() + "-" + monIndex.toString().padStart(2, '0') + "-01 00:00:00"
          endDay = year.toString() + "-" + (monIndex + 1).toString().padStart(2, '0') + "-01 00:00:00"
        }
        let startResult = TymCommon.localTimeToUTC(startDay, "YYYY-MM-DD HH:mm:ss")
        let endResult = TymCommon.localTimeToUTC(endDay, "YYYY-MM-DD HH:mm:ss")

        if(endResult.dateTime > today) {
          endDay = TymCommon.localTimeToUTC(today.format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss").dateTimeStr
        } else {
          endDay = endResult.dateTimeStr
        }
        startDay = startResult.dateTimeStr

        this.dealerData = []
        this.dealerModelOptionData = []
        groups.Names.forEach(name => {
          this.dealerData.push({
            Dealer : name,
            Hour : 0
          })
        })

        switch(groups.Depth) {
          case 0:
            this.titleForLocalHours = this.$t('statEngineHour.hbybranch')
            break;
          case 1:
            this.titleForLocalHours = this.$t('statEngineHour.hbybranch')
            break;
          case 2:
            this.titleForLocalHours = this.$t('statEngineHour.hbybranch')
            break;
          case 3:
            this.titleForLocalHours = this.$t('statEngineHour.hbydealer')
            break;
        }

        this.modelData = []
        this.optionData = []

        TymAws.getUsageTimeMARIA(group.uuid_a, group.uuid_b, group.uuid_c, group.uuid_d, startDay, endDay, false)
          .then(data => {
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GetUsage(TERM) :', startDay, endDay, data)
            }
            /*
            {
              "M_model": "K110E",
              "M_option": "NONE",
              "VUDT": 45.88,
              "MT": 22.08,
              "PTOT": 4.95,
              "DRT": 0.35,
              "group_a": "TYM",
              "group_b": "국내영업본부",
              "group_c": "강원지점",
              "group_d": "양구딜러점(최윤채)",
              "type": "트랙터"
            }
            */
            if(data == 'error' || data.length < 1) {
              this.updateDealerChart(true)
              this.updateModelOptionChart(true, true)
              this.updateDealerModelTable(true)

              this.isLoadingData = false
              return
            }

            data.forEach(one => {
              if(this.catSelected != this.getCategory[0]) {
                if(one.type != this.catSelected)
                  return
              }

              // dealer 찾기
              let dealerName = ''
              switch(groups.Depth) {
                case 0:
                  if(TymCommon.isEmpty(one.group_b))
                    dealerName = one.group_a
                  else
                    dealerName = one.group_b
                  break;
                case 1:
                  if(TymCommon.isEmpty(one.group_c))
                    dealerName = one.group_b
                  else
                    dealerName = one.group_c
                  break;
                case 2:
                  if(TymCommon.isEmpty(one.group_d))
                    dealerName = one.group_c
                  else
                    dealerName = one.group_d
                  break;
                case 3:
                  dealerName = one.group_d
                  break;
              }

              let hour = 0
              switch(this.workSelected) {
                case this.getWorkTypes[0]:
                  hour = Number(one.VUDT)
                  break
                case this.getWorkTypes[1]:
                  hour = Number(one.MT)
                  break
                case this.getWorkTypes[2]:
                  hour = Number(one.PTOT)
                  break
                case this.getWorkTypes[3]:
                  hour = Number(one.DRT)
                  break
              }
              // 20240201 - 엑셀과 같은 방식을 취하기 위해 나중에 시간 계산하자.
              // hour = Math.round(hour / 60.0)

              let dFound = this.dealerData.find(x => x.Dealer == dealerName)
              if(dFound) {
                dFound.Hour += hour
              } else {
                this.dealerData.push({
                  Dealer : dealerName,
                  Hour : hour
                })
              }

              let mFound = this.modelData.find(x => x.Model == one.M_model)
              if(mFound) {
                mFound.Hour += hour
                let oFound = mFound.Options.find(x => x.Option == one.M_option)
                if(oFound) {
                  oFound.Hour += hour
                } else {
                  mFound.Options.push({
                    Option : one.M_option,
                    Hour : hour
                  })
                }
              } else {
                let oneModel = {
                  Model : one.M_model,
                  Hour : hour,
                  Options : [{
                    Option : one.M_option,
                    Hour : hour
                  }]
                }
                this.modelData.push(oneModel)
              }
            })

            this.modelData.sort((a, b) => {
              if(a.Model == b.Model) {
                return a.Option.localeCompare(b.Option)
              } else {
                return a.Model.localeCompare(b.Model)
              }
            })

            // 테이블 표시를 위한 카운트가 모두 0인 데이터 만든다.
            groups.Names.forEach(name => {
              let models = []
              let fullModels = []

              this.modelData.forEach(model => {
                let options = []
                model.Options.forEach(opt => {
                  options.push({
                    Option : opt.Option,
                    Hour : 0
                  })
                  let fullName = model.Model
                  if(opt.Option != '0' && opt.Option != 'NONE') {
                    fullName += opt.Option
                  }
                  if(!fullModels.find(x => x == fullName))
                    fullModels.push({
                      Model : fullName,
                      Hour : 0
                    })
                })
                models.push({
                  Model : model.Model,
                  Hour : 0,
                  Options : options
                })
              })
              let oneGrp = {
                Dealer : name,
                Models : models,
                FullModels : fullModels
              }

              this.dealerModelOptionData.push(oneGrp)
            })
            // console.log('11111 > ', JSON.parse(JSON.stringify(this.dealerModelOptionData)))

            data.forEach(one => {
              if(this.catSelected != this.getCategory[0]) {
                if(one.type != this.catSelected)
                  return
              }
              let hour = 0
              switch(this.workSelected) {
                case this.getWorkTypes[0]:
                  hour = Number(one.VUDT)
                  break
                case this.getWorkTypes[1]:
                  hour = Number(one.MT)
                  break
                case this.getWorkTypes[2]:
                  hour = Number(one.PTOT)
                  break
                case this.getWorkTypes[3]:
                  hour = Number(one.DRT)
                  break
              }
              // 20240201 - 엑셀과 같은 방식을 취하기 위해 나중에 시간 계산하자.
              //hour = Math.round(hour / 60.0)

              // dealer 찾기
              let dealerName = ''
              switch(groups.Depth) {
                case 0:
                  if(TymCommon.isEmpty(one.group_b))
                    dealerName = one.group_a
                  else
                    dealerName = one.group_b
                  break;
                case 1:
                  if(TymCommon.isEmpty(one.group_c))
                    dealerName = one.group_b
                  else
                    dealerName = one.group_c
                  break;
                case 2:
                  if(TymCommon.isEmpty(one.group_d))
                    dealerName = one.group_c
                  else
                    dealerName = one.group_d
                  break;
                case 3:
                  dealerName = one.group_d
                  break;
              }

              let dmo = this.dealerModelOptionData.find(x => x.Dealer == dealerName)
              if(dmo) {
                let model = dmo.Models.find(x => x.Model == one.M_model)
                if(model) {
                  model.Hour += hour

                  let option = model.Options.find(x => x.Option == one.M_option)
                  if(option) {
                    option.Hour += hour
                  }
                }

                let fullName = model.Model
                if(one.M_option != '0' && one.M_option != 'NONE') {
                  fullName += one.M_option
                }
                model = dmo.FullModels.find(x => x.Model == fullName)
                if(model) {
                  model.Hour += hour
                }
              }
            })

            this.selectModelOptions = [ this.$t('common.all') ]
            this.modelData.forEach(model => {
              this.selectModelOptions.push(model.Model)
            })
            this.selectedModelOptionTab = this.selectModelOptionTabs[0]
            this.selectedModelOption = this.selectModelOptions[0]

            this.selectedDealerModelOptionTab = this.selectDealerModelOptionTabs[0]
            this.selectDealerModels = [ this.$t('common.all') ]
            this.modelData.forEach(model => {
              this.selectDealerModels.push(model.Model)
            })
            this.selectedDealerModel = this.selectDealerModels[0]

            //console.log('DEALER :', this.dealerData)
            //console.log('MODEL :', this.modelData)
            //console.log('DEALER TABLE :', this.dealerModelOptionData)

            this.updateDealerChart(false)
            this.updateModelOptionChart(false, true)
            this.updateDealerModelTable(false)

            this.isLoadingData = false
          })
          .catch(er => {
            this.isLoadingData = false
            console.log('StatEH.getUsageTimeMARIA ERROR :', er)
          })
      }, 1)
    },
  }
})
</script>

