export default {
  "common": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name"])},
    "mobilephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
    "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "ign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignition"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
    "adsOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View ADS"])},
    "opTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
    "erCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "emergency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency"])},
    "breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakdown"])},
    "consumables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumables"])},
    "batterywarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Warning"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horse Power"])},
    "hpUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ps"])},
    "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "loadCons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Select"])},
    "firstTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Cycle (h)"])},
    "cycleTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repl. Cycle (h)"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
    "recentLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Location"])},
    "doingAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "doingEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "doingDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "faveroites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faveroites"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "rollover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollover"])},
    "geofence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geofence"])},
    "emtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency"])},
    "brtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server."])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "vin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIN"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "toExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to Excel"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filename"])},
    "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "machineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine"])},
    "hourUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
    "peopleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
    "vehicleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle"])},
    "agentCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
    "typeCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "salePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])}
  },
  "regions": {
    "kr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea"])},
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North America"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGN IN"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "saveid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember ID"])},
    "forgetid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot ID or Password?"])},
    "clearpw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "doclear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click"])},
    "idForReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID"])},
    "noMatchedID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matched Information"])},
    "matchedID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ID matching your information is as follows."])},
    "yourIDis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ID is ..."])},
    "memberByPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ID with mobile number"])},
    "nameAndPhoneForID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name and mobile number."])},
    "memberByEMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ID with E-mail."])},
    "resetByEMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password with E-mail."])},
    "nameAndEmailForID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name and E-mail address."])},
    "requestAuthCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send verification code first"])},
    "requestingAuthCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send code"])},
    "reRequestingAuthCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-request the verification code"])},
    "setSafePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password"])},
    "passwordGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password rule : 8+ characters with English letters(include at least one lowercase letter), numbers, and special characters."])},
    "inputSamePW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new password again"])},
    "completedChangePW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password was changed successfully."])},
    "waitingAuthCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting verification code"])},
    "findID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ID"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "registerNewManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register new user information"])}
  },
  "loginmenu": {
    "downloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download App"])},
    "personalinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Info"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "dologout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to log out?"])}
  },
  "leftbar": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
    "usim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USIM"])},
    "machineview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Information"])},
    "monitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring"])}
  },
  "titlearea": {
    "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating"])},
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Info"])}
  },
  "totalarea": {
    "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines"])},
    "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Models"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
  },
  "placeholder": {
    "nameonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "nameorvin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name or VIN"])},
    "vinonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIN"])},
    "phoneonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "nameorphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name or Mobile"])},
    "nameorvinorsn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name or VIN or SN"])},
    "titleorname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title or writer"])},
    "modeloroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model or Option"])},
    "vinorsn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIN or SN"])},
    "inputConsCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Name"])},
    "inputConsCategoryKo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean Category Name"])},
    "inputConsCategoryEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Category Name"])},
    "inputpartname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter part name."])},
    "inputpartnameKo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean Part Name"])},
    "inputpartnameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Part Name"])},
    "inputpartnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part number"])},
    "inputYourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "inputPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "inputEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail Address"])},
    "inputPostcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your postcode"])},
    "inputAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your address"])},
    "inputID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "inputNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "inputPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password."])},
    "reInputPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Confirm"])},
    "authCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
    "checkPW4DelAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password to delete the administrator."])},
    "checkPW4DelGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password to delete a group."])}
  },
  "dashboardView": {
    "machinemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Location"])},
    "largemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand map area"])},
    "smallmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View other infos"])},
    "recentNoti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Notifications"])},
    "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
  },
  "noticeView": {
    "newnotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New notice arrived."])},
    "noNewNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new notice."])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "writer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writer"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "writedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "expiredate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire"])},
    "noticetitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "attachedfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file(s)"])},
    "selectall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "selectcommon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
    "selectevent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "selectnotread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not read"])},
    "selectfavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faveroite"])},
    "myNoticeView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent Notice"])},
    "addFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "removeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "deleteCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The notice has been deleted."])},
    "deleteNoticeMsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password to delete the notice."])},
    "deleteNoticeMsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account password does not match."])},
    "max5files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max 5 files"])},
    "inputContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter content."])},
    "deleteSalesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password to delete the sales information."])}
  },
  "resetPWdialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you enter your ID, we will tell you how to reset your password."])},
    "byphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ID with phone-number."])}
  },
  "doResetPWDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear password"])},
    "byemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear password with E-mail"])},
    "byphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear password with phone-number"])},
    "reqauthcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request verification code"])}
  },
  "newPWDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set secure password for account protection."])},
    "password1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passsword Confirm"])},
    "authcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])}
  },
  "loginresult": {
    "invalidUserOrPW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect username or password."])}
  },
  "constants": {
    "emergency_type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solved"])},
    "emergency_type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollover"])},
    "emergency_type3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of safety zone"])},
    "emergency_type4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Low"])},
    "emergency_type5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TBOX Battery Blocked"])}
  },
  "TEXT_CITEM": {
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Oil and Cartridges"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Filter (A)"])},
    "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Filter (B)"])},
    "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Cleaner Element"])},
    "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmission Oil"])},
    "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulic Oil Filter"])},
    "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Axle Oil"])}
  },
  "TEXT_CITEM_MANAGE": {
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
    "caution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caution"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])}
  },
  "TEXT_BROKENSTATE": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occurred"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repairing"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solved"])}
  },
  "TEXT_BROKENTYPE": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmission"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulic"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric(Sensor)"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etc."])}
  },
  "monitor_menu": {
    "reg_machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Machines"])},
    "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
    "emergency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollover, Geofence"])},
    "broken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error (Breakdown)"])},
    "consumablesRepl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumables Replacement"])},
    "consumables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumables Replacement"])},
    "battery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low Battery"])}
  },
  "consumables": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Oil & Cartridge"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Filter (A)"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Filter (B)"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Cleaner Element"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmission Oil"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulic Oil Filter"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Axle Oil"])}
  },
  "errorMsg": {
    "noLocaInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No location information"])},
    "passwordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be 8 characters or more."])},
    "notMatchedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Matched Information."])},
    "checkIDfirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Confirm ID first."])},
    "mismatchSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Serial No."])},
    "requestTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication timeout error with server"])},
    "updateTBOXFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem saving your TBOX information. Please correct your TBOX information again."])},
    "updateACUFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem saving ADS Inforamtion. Please correct the ADS Inforamtion again."])},
    "updateBothFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem saving TBOX and ADS information. Please edit again."])},
    "noUsageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No using time information."])},
    "emptyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result is empty. Contact to your dealer."])},
    "notRegisteredSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S/N not registered in SN_INFO."])},
    "usedMN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIN is already used."])},
    "usedSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S/N is already used."])},
    "failUdpatePD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to change product information."])},
    "failUdpateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to change account information."])}
  },
  "TABLE_COLUMNS": {
    "occDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occur. Date"])},
    "firstOccurDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occur. Date"])},
    "lastOccurDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Occur."])},
    "resolveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved Date"])},
    "emergencyContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Content"])},
    "resolveContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolve Content"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "errorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Code"])},
    "errorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Content"])},
    "usageOccurrence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Hours On Expiration"])},
    "usageReplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Hours On Replacement"])},
    "replaceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replaced"])},
    "consumableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumables"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "partname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part Name"])},
    "partnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part Number"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
  },
  "changeMethod": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC."])}
  },
  "yearMonth": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oct"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dec"])}
  },
  "WEEK": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUN"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MON"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TUE"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WED"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THU"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRI"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAT"])}
  },
  "groupCategory": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own Channel"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])}
  },
  "testc": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])}
  },
  "workType": {
    "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
    "pto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PTO"])},
    "plow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plow"])}
  },
  "manage": {
    "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
    "fuelMileage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Mileage"])},
    "monthlyOpHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Operating Hours"])},
    "monthlyDetailRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Detail Records"])},
    "moveAndWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move & Work Path"])},
    "opHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Hours"])},
    "avgSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Speed"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOVE"])},
    "pto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PTO Work"])},
    "plow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLOW Work"])},
    "engineHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Hours"])},
    "fuelConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Consumption"])},
    "batteryVoltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Voltage"])},
    "batteryWarningLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
    "lowVoltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low Voltage"])},
    "writeMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write memo"])},
    "viewMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read memo"])},
    "occurLoca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occurrence Location"])},
    "usageTooltipMon1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work sheet time includes only move & work data except idle status."])},
    "usageTooltipMon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So time on Work sheet and Usage may vary."])},
    "batteryToolitp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Voltage records only on Key off status."])},
    "engineHourTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage is actual usage time data that includes not only work and move time but also idle time."])},
    "noEmergencyHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No history of emergency"])},
    "noErrorHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No history of error"])},
    "noConsHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No history of replacing consumables"])}
  },
  "statSales": {
    "totalSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total sales"])},
    "sbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Branch"])},
    "sbydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer"])},
    "sbymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Model"])},
    "sbyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Option"])},
    "sybymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Sales"])},
    "sbydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Model"])},
    "sbydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Option"])}
  },
  "statEngineHour": {
    "hbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Branch"])},
    "hbydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer"])},
    "hbymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Model"])},
    "hbyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Option"])},
    "hbydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Model"])},
    "hbydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Option"])},
    "hbymonthlymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By model"])},
    "mhfor12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Engine Hours"])}
  },
  "statEmergency": {
    "embybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Branch"])},
    "embydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer"])},
    "embymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Model"])},
    "embyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Option"])},
    "embydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Model"])},
    "embydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Option"])},
    "embyyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Emergencies"])},
    "emergencyCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])}
  },
  "statErrors": {
    "erbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer"])},
    "erbymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Model"])},
    "erbyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Option"])},
    "erbydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Model"])},
    "erbydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Option"])},
    "erbyyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Errors"])},
    "errorCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])}
  },
  "statCons": {
    "consbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Branch"])},
    "consbydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer"])},
    "consbycategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Category"])},
    "consbypart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Parts"])},
    "consbymodelcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Category"])},
    "consbymodelpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Parts"])},
    "consCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "noName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO NAME"])}
  },
  "statBattery": {
    "battbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" By Branch"])},
    "battbydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer"])},
    "battbymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Model"])},
    "battbyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Option"])},
    "battbydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Model"])},
    "battbydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dealer & Option"])},
    "battbyyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Battery Warnings"])},
    "battCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])}
  },
  "tooltip": {
    "editJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change contents."])},
    "deleteMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erase memo."])},
    "cancelJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "saveJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "applyJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply the changed contents."])},
    "initJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize changed option(s)."])},
    "selectUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user."])},
    "selectModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a model."])},
    "selectDealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a dealer."])},
    "searchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View data with selected condition"])},
    "exportExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to Excel."])},
    "makeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a new group."])},
    "editGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Group Info"])},
    "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Group"])},
    "viewAllRegMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all registered machines."])},
    "viewNormalMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show normal machines"])},
    "moveMonitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed View"])},
    "refreshLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "editConsumables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit consumable information"])},
    "deleteConsumable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "addModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new model."])},
    "deleteModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Model"])},
    "addParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a part."])},
    "editParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit part information."])},
    "eraseParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete part information"])},
    "erasePartNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete part number"])},
    "addPartNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input part number"])},
    "addNewItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new item."])},
    "addNewSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Sale"])},
    "selectIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a icon to use."])},
    "loadMachineByMON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search machine number"])},
    "selectProdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a manufactured date."])},
    "selectSaleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a saled date."])},
    "selectSaleTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a saled time."])},
    "addAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a manager."])},
    "editAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a manager."])},
    "deleteAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete a manager."])},
    "printEULA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print a End-User License Agreement"])},
    "checkValidID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm ID"])},
    "searchPostcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for zip code."])},
    "moveToManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
    "deleteSalesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete sales information."])},
    "checkMN_SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the machine number and serial number."])},
    "loadAlarmHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Emergency History"])},
    "loadErrorHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Error History"])},
    "loadConsHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Consumables History"])},
    "noticeMine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice Sent"])},
    "noticeAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Notice"])},
    "noticeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Notice"])},
    "noticeDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Notice"])},
    "showMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Menu"])},
    "hideMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Menu"])},
    "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Product Info"])},
    "deleteProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Product"])},
    "selectPostcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search zip-code"])},
    "regNotSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage not-registered machines."])}
  },
  "warning": {
    "noaddrinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No address information"])},
    "noTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty title"])},
    "noContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty content"])},
    "noReceiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty receiver"])},
    "tooLargeSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file is too large to attach."])},
    "tooLargeTotalSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The attached files are too large to attach any more."])},
    "inputVIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your machine number."])},
    "noDelSpecialGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete a special group."])},
    "noDelHasChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete a group that has child group(s)."])},
    "noDelHasManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete a group that has manager(s)."])},
    "noOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter client information"])},
    "noDealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty dealer information."])},
    "noMachineInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter machine information"])},
    "noModelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty model information."])},
    "inputSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a serial number."])},
    "notRegisteredSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The serial number is not registered."])},
    "duplicatedSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This serial number is already registered"])},
    "deleteACUInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete autonomous driving information?"])},
    "deleteTBOXInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete TBOX information?"])},
    "noTboxOrACU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TBOX or autonomous driving information is required."])},
    "noTboxSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter TBOX serial number"])},
    "noACUSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter ACU serial number"])},
    "checkTBOXSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the serial number of TBOX."])},
    "checkACUSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the serial number of autonomous driving."])},
    "noOperatingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Available"])},
    "readingWeeklyData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reading weekly data"])},
    "inputYourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Customer's Name"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])},
    "toomanyattempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many tried to change."])},
    "checkid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Check ID first'"])},
    "differentpw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords are not matched."])},
    "oneMoreCons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have to enter at least one consumable information."])},
    "inputMachineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input or select a type of machine."])},
    "inputModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input or select a model."])},
    "inputOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input or select a option."])},
    "registeredMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already registered machine."])},
    "notRegisteredMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not registered machine."])},
    "notRegTBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not registered TBOX machine."])},
    "registeredTBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already registered TBOX machine."])},
    "needPasswordPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password for watching customer's private information."])}
  },
  "notice": {
    "addUserOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User account is successfully added."])},
    "editUserOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User account is successfully changed."])},
    "checkPW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your entered password."])},
    "inputCurPW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your current password."])},
    "attachedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload jpg or pdf file only."])}
  },
  "equipment": {
    "consumableCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "addConsPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a part"])},
    "partInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part Information"])},
    "replTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replacement Time"])},
    "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already registered"])},
    "forDev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Development"])},
    "viewOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set view options"])}
  },
  "group": {
    "parentGrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent group"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Type"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority"])},
    "activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Activation"])},
    "questionDeleteAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete "])},
    "questionDeleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete group?"])},
    "questionDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "listGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
    "listManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager List"])},
    "groupInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Info"])},
    "editGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Group Info"])},
    "addGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Group"])},
    "addManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Manager"])},
    "editManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Manager"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
    "usedID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already used ID"])},
    "usableID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid ID"])},
    "enterGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a group name"])},
    "usedGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name is already used."])},
    "enterAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter address."])},
    "enterMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter phone number."])},
    "invalidInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid input data."])}
  },
  "customer": {
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer List"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer information"])},
    "searchInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Information"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "regDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reg. Date"])},
    "modelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Name"])},
    "machineNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine No."])},
    "manDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prod. Date"])},
    "serialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial No"])},
    "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
    "saleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Date"])},
    "warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty"])},
    "machineState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine State"])},
    "serviceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Start"])},
    "expireDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searvice End"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all user"])},
    "showSoldOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show product registered users"])}
  },
  "batt_status": {
    "noInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No info"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Good"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Low Voltage"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnection From Telematics Service"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Disconnection"])}
  },
  "eula": {
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "privInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information Use and Collection"])},
    "privAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree"])},
    "privPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "locaTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions of Location-Based Services"])},
    "locaAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree"])},
    "locaInfoTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions of Location-Information Services"])},
    "locaInfoAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree."])},
    "agreeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree to the Terms and conditions and Location service agreement to register as a member."])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
  },
  "salesView": {
    "totalSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Sales"])},
    "salesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Information"])},
    "testTimeBeforeSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-sale Engine Hours (h)"])},
    "machineInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Information"])},
    "autoDriveInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Driving Information"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Type"])},
    "adsLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Driving Level"])},
    "autoSteering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Steering Device"])},
    "noSalesVIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register vehicle(s) after sale."])},
    "salesNotRegi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register vehicle(s) after auto-sale."])}
  },
  "account": {
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Info"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "curPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "startpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Page"])},
    "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Zone"])}
  },
  "machineInfo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Info"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "vin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIN"])},
    "manDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufactured Date"])},
    "serialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S/N"])},
    "engHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Hours"])},
    "saleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Date"])},
    "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
    "curLoca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Location"])}
  },
  "machineDetail": {
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
    "worksheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WorK Sheet"])},
    "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
    "batteryVoltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Voltage"])},
    "engineHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Hours"])},
    "lastRunTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Running Time"])},
    "fuelLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Level"])},
    "ureaLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urea Level"])},
    "ADS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonomous Driving"])},
    "tboxFirmware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telematics Firmware"])},
    "manageEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Emergency"])},
    "historyEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency History"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "changeEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Emergency] Change Status"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved"])},
    "unresolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unresolved"])},
    "resolvedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved Date"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
    "manageER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Error"])},
    "historyBR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error History"])},
    "changeBR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Error] Change Status"])},
    "brType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occurrence Type"])},
    "procHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing History"])},
    "replaceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replacement Date"])},
    "replHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replacement History"])},
    "replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replacement"])},
    "replMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
    "notReplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No replacement"])},
    "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])},
    "replaceParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replacement Part(s)"])},
    "manageCONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumables"])}
  },
  "machineStatus": {
    "Ignition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignition Status"])},
    "Battery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Voltage"])},
    "Fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Lv"])},
    "Coolant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coolant"])},
    "ParkingBrake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Brake"])},
    "Direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
    "PTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PTO"])},
    "AirCleaner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Cleaner"])},
    "TMOilFilterTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T/M Oil Temp"])},
    "Urea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urea Lv"])},
    "FourWD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4WD"])},
    "HorizMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal Mode"])},
    "DepthMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth Mode"])},
    "DraftMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft Mode"])},
    "UsingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Hours"])},
    "EngineLoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E/G Load"])},
    "EngineRPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E/G RPM"])},
    "EngineOil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E/G Oil Pressure"])},
    "AmbientAirTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambient Air Temp"])},
    "DPFRegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPF Regen Lv"])},
    "DPFSoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPF Soot Load"])},
    "FuelFilterWater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Filter Water"])},
    "Inducement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inducement"])},
    "RecentAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Running Time"])},
    "KeyOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key On"])},
    "ADS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonomous Driving"])},
    "Telematics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telematics Firmware"])},
    "DPFRegnNormal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NORMAL"])},
    "DPFRegnLowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOW"])},
    "DPFRegnModerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIDDLE"])},
    "DPFRegnHighest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIGH"])},
    "BrakeON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON"])},
    "BrakeOFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFF"])},
    "Normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NORMAL"])},
    "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLOCK"])},
    "WaterFuelNormal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NORMAL"])},
    "WaterFuelWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WARNING"])},
    "Forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORWARD"])},
    "Neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEUTRAL"])},
    "Reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVERSE"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON"])},
    "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFF"])}
  },
  "statView": {
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
    "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Hour"])},
    "emergency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "consumables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumables"])},
    "lowBattery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low Battery"])},
    "normalMach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
    "adsMach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADS"])}
  },
  "productView": {
    "snList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S/N List"])},
    "passok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
    "passfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불합격"])},
    "passneed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "prodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Info"])},
    "prodMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prod. Date"])},
    "regDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
    "lastLoca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Location"])},
    "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Product"])},
    "facOkcheon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okcheon"])},
    "facIksan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iksan"])}
  },
  "USIM": {
    "stateActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "statePaused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paused"])},
    "stateExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
    "stateCloseExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Expired"])},
    "searchUSIMState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USIM Status"])},
    "searchDateOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select option"])},
    "tooltipCloseExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of USIM cards expiring within 30 days"])},
    "noOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No option"])},
    "firstOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USIM Activation Date"])},
    "saleOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telematics Start Date"])},
    "expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telematics Expiration Date"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USIM State"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job"])},
    "state0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
    "state1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre activated"])},
    "state2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])},
    "state3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre inactivated"])},
    "state4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "state5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal stocked"])},
    "optionPopupTitme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option and Search Range"])}
  },
  "Units": {
    "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lv"])},
    "Stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])}
  }
}