import { createRouter, createWebHashHistory } from 'vue-router'
// import { createRouter, createMemoryHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import Dashboard from '../views/DashboardView.vue'
import EquipmentView from '../views/EquipmentView.vue'
import MachineInfoView from '../views/MachineInfoView.vue'
import SelectView from '../views/SelectView.vue'
import RecentNotiView from '../views/RecentNotiView.vue'
import StatView from '../views/StatView.vue'
import SalesView from '../views/SalesView.vue'
import ProductView from '../views/ProductView.vue'
import GroupView from '../views/GroupView.vue'
import MonitorView from '../views/MonitorView.vue'
import AccountView from '../views/AccountView.vue'
import NotificationView from '../views/NotificationView.vue'
import ManagementView from '../views/ManagementView.vue'
import CustomerView from '../views/CustomerView.vue'
import UnderCover from '../views/UnderCover.vue'
import USIMViewer from '../views/USIMViewer.vue'

const routes = [
  {
    path: '/',
    redirect:'/db'
  },
  {
    // dashboard
    path: '/db',
    children : [ 
      {
        path:'',
        components: {
          left : Dashboard,
          right : SelectView,
          bottom : RecentNotiView
        }
      },
    ]
  },
  {
    // Equipment
    path: '/eq',
    children : [ 
      {
        path:'',
        component: EquipmentView
      },
    ]
  },
  {
    // Management
    path: '/man',
    children : [ 
      {
        path:'',
        component: ManagementView
      },
    ]
  },
  {
    // Management - popup
    path: '/man2',
    children : [ 
      {
        path:'',
        component: MachineInfoView
      },
    ]
  },
  {
    // sales
    path: '/sale',
    children : [ 
      {
        path:'',
        component: SalesView
      },
    ]
  },
  {
    // statistics
    path: '/stat',
    children : [ 
      {
        path:'',
        component: StatView
      },
    ]
  },
  {
    // 공지사항
    path: '/noti',
    children : [ 
      {
        path:'',
        component: NotificationView
      },
    ]
  },
  {
    // 조직
    path: '/grp',
    children : [ 
      {
        path:'',
        component: GroupView
      },
    ]
  },
  {
    // product
    path: '/prod',
    children : [ 
      {
        path:'',
        component: ProductView
      },
    ]
  },
  {
    // 차량관제
    path: '/mon',
    children : [ 
      {
        path:'',
        component: MonitorView
      },
    ]
  },
  {
    // Account
    path: '/ac',
    children : [ 
      {
        path:'',
        component: AccountView
      },
    ]
  },
  {
    // Customer
    path: '/cust',
    children : [ 
      {
        path:'',
        component: CustomerView
      },
    ]
  },
  {
    path: '/li',
    component:LoginView,
  },
  {
    path: '/home',
    component:HomeView,
  },
  {
    // 시험용
    path: '/uc',
    children : [ 
      {
        path:'',
        component: UnderCover
      },
    ]
  },
  {
    // USIM
    path: '/usim',
    children : [ 
      {
        path:'',
        component: USIMViewer
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
