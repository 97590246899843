<template>
  <div class="row">
    <table class="col" style="width:100%;"> 
      <tr height="48px">
        <td colspan="2">
          <div class="row justify-between items-center" style="width:100%;height:100%">
            <div class="col-auto" style="font-size:16px;font-weight:600;color:#212529">
              {{ $t('account.resetPassword') }}
            </div>
            <q-btn class="col-auto" no-caps flat dense push color="grey-9" icon="edit" style="width:auto;margin:0 3px;" :disable="isEditMode" @click="editUser">
            </q-btn>
          </div>
        </td>
      </tr>
      <tr class="tr_top">
        <td style="width:150px;font-size:15px;font-weight: 500;">
          {{ $t('account.curPassword') }}
        </td>
        <td width="auto" class="row">
          <q-input class="col-11" dense flat outlined v-model="curPassword" :disable="!isEditMode" color="red-7" type="password" :placeholder="$t('account.curPassword')">
          </q-input>
        </td>
      </tr>
      <tr class="tr_middle">
        <td style="font-size:15px;font-weight: 500;">
          {{ $t('account.newPassword') }}
        </td>
        <td width="auto" class="row">
          <q-input class="col-11" dense flat outlined v-model="newPassword1" :disable="!isEditMode" color="red-7" type="password" :placeholder="$t('newPWDialog.password1')">
          </q-input>
        </td>
      </tr>
      <tr class="tr_middle">
        <td style="font-size:15px;font-weight: 500;">
        </td>
        <td width="auto" class="row">
          <q-input class="col-11" dense flat outlined v-model="newPassword2" :disable="!isEditMode" color="red-7" type="password" :placeholder="$t('newPWDialog.password2')">
          </q-input>
        </td>
      </tr>
      <tr class="tr_bottom" v-show="isEditMode">
        <td colspan="2">
          <div class="col row justify-center items-center">
            <q-btn no-caps unelevated dense color="grey-4" text-color="black" :label="$t('common.cancel')" style="width:76px;height:32px;" @click="cancelEdit">
              <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob')}}</q-tooltip>
            </q-btn>
            <q-btn no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.save')" style="margin-left:8px;width:76px;height:32px;" @click="saveEdit">
              <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob')}}</q-tooltip>
            </q-btn>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

.tr_top {
  border-top: 0px solid #cccccc;
  border-bottom: 0px solid #eeeeee;
  height:48px;
}
.tr_middle {
  border-bottom: 0px solid #eeeeee;
  height:48px;
}
.tr_bottom {
  /*
  border-bottom: 2px solid #cccccc;
  */
  border-top: 0px solid #cccccc;
  height:64px;
}

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'
//import { useI18n } from 'vue-i18n'
import TymAws from '@/js/tymaws.js'
import TymCommon from '@/js/tymcommon.js'

export default ({
  props : {
    kind : ref(0)
  },
  data() {
    return {
    }
  },
  setup() {
    return {
      isEditMode : ref(false),
      curPassword : ref(''),
      newPassword1 : ref(''),
      newPassword2 : ref(''),
    }
  },
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
  },
  created() {},
  mounted() {
    this.isEditMode = false
  }, 
  unmounted() {},
  methods : {
    editUser() {
      this.isEditMode = true
    },
    cancelEdit() {
      this.curPassword = ''
      this.newPassword1 = ''
      this.newPassword2 = ''
      this.isEditMode = false;
    },
    changeOK(data) {
      console.log('change successfully :', data)
      this.curPassword = ''
      this.newPassword1 = ''
      this.newPassword2 = ''
      this.isEditMode = false;
      TymCommon.Toast(this.$t('login.completedChangePW'))
    },
    changeFail(err) {
      console.log('change failed...', err)
      switch(err) {
        case 'NotAuthorizedException':
          TymCommon.Toast(this.$t('noticeView.deleteNoticeMsg2'))
          break;
        case 'LimitExceededException':
          TymCommon.Toast(this.$t('warning.toomanyattempt'))
          break;
      }
    },
    saveEdit() {
      if(TymCommon.isEmpty(this.curPassword)) {
        TymCommon.Toast(this.$t('notice.inputCurPW'))
        return
      }
      if(TymCommon.isEmpty(this.newPassword1) || TymCommon.isEmpty(this.newPassword2)) {
        TymCommon.Toast(this.$t('newPWDialog.title'))
        return
      }
      if(this.newPassword1 != this.newPassword2) {
        TymCommon.Toast(this.$t('login.inputSamePW'))
        return
      }
      
      TymAws.changeUserPassword(this.curPassword, this.newPassword1, this.changeOK, this.changeFail)
    }
  }
})
</script>
