<template>
  <div class="column">
    <div class="col column" style="padding:0 12px 12px 12px">
      <div class="col column">
        <div class="col-auto row items-center" style="height:45px">
          <div class="col-auto"
              :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
            {{ getPageTitle }}
          </div>
        </div>

        <div class="col column" style="position:relative;width:100%;height:100%;border-radius:3px;background-color:white;">
          <div class="col column" style="position:absolute;left:12px;top:12px;right:12px;bottom:12px;">
            <div class="col-auto row justify-between items-center" style="margin:0 0 12px 0;">
              <div class="col-auto row">
                <!-- 공지 아이콘 및 알림메시지 -->
                <div class="col row justify-start items-center" v-show="!isViewMyNoticeList">
                  <q-img src="../assets/icons/notice_bullhorn_on.svg" width="30px" v-show="unreadCount > 0"/>
                  <q-img src="../assets/icons/notice_bullhorn_off.svg" width="30px" v-show="unreadCount < 1"/>
                  <!--
                  <q-icon size="md" name="notifications_active" color="grey-9" v-show="unreadCount > 0"/>
                  <q-icon size="md" name="notifications" v-show="unreadCount < 1"/>
                  -->
                  <div style="color:#eb0028;margin:0 16px;font-size:16px;" v-show="unreadCount > 0">{{ unreadCount }}</div>
                  <div style="font-size:15px;">{{ unreadCount > 0 ? $t('noticeView.newnotice') : $t('noticeView.noNewNotice') }}</div>
                </div>
                <div class="col row justify-start items-center" v-show="isViewMyNoticeList">
                  <div style="font-size:16px;font-weight:500;">{{ $t('noticeView.myNoticeView') }}</div>
                </div>

                <div class="col-auto row justify-center items-center" style="margin:0 36px;position: relative;">
                  <!-- 항목 선택 -->
                  <q-select dense outlined options-dense color="red-7" v-model="catSelected" 
                            style="min-width:120px;margin-right:12px;" :options="isViewMyNoticeList ? getCategory(1) : getCategory(0)"></q-select>
                  <!-- 검색 -->
                  <q-input class="col" color="red-7" dense outlined filled clearable autocomplete="off"
                          id="noticeFilterInput"
                          style="font-size:14px;width:240px;"
                          :placeholder="$t('placeholder.titleorname')"
                          v-model="tableFilter">
                    <template v-slot:append>
                      <q-icon size="sm" dense name="search"/>
                    </template>
                  </q-input>
                  <!-- 자동완성때문에 추가시킴... -->
                  <q-input class="col" color="red-7" dense outlined filled clearable autocomplete="off"
                          id="noticeFilterInput"
                          style="font-size:14px;width:240px;position:absolute;right :0px;width:0px;top:120px;"
                          :placeholder="$t('placeholder.titleorname')"
                          v-model="tableFilter1">
                    <template v-slot:append>
                      <q-icon size="sm" dense name="search"/>
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="col row justify-end items-center" style="" v-show="!isViewMyNoticeList">
                <q-btn dense flat icon="description" color="grey-9" @click="gotoMyNotices">
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.noticeMine')}}</q-tooltip>
                </q-btn>
                <q-btn dense flat icon="add" color="grey-9" @click="addNewNotice">
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.noticeAdd')}}</q-tooltip>
                </q-btn>
              </div>
              <div class="col row justify-end items-center" style="" v-show="isViewMyNoticeList">
                <q-btn dense flat icon="view_headline" color="grey-9" @click="closeMyNotice">
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.noticeAll')}}</q-tooltip>
                </q-btn>
                <q-btn dense flat icon="delete" color="grey-9" @click="deleteQuestion">
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.noticeDelete')}}</q-tooltip>
                </q-btn>
              </div>
            </div>

            <div ref="tableContainer" class="col row justify-start items-start" style="position:relative;">
              <q-resize-observer @resize="onResize" />
                <!-- 공지 리스트 -->
                <!--  -->
                <q-table dense flat bordered
                        separator="cell"
                        selection="none"
                        virtual-scroll
                        hide-no-data
                        style="position:absolute;left:0;top:0;right:0;bottom:0;"
                        v-model:selected="notiSelected"
                        :visible-columns="visibleColumns"
                        row-key="index"
                        :columns="notiListColumns"
                        :rows="noticeItems"
                        @update:pagination="updatePagination"
                        :filter="tableFilter || catSelected"
                        :filter-method="tableFilterMethod"
                        :pagination="noticePagination"
                        ref="notiTable">
                  <template v-slot:header="props">
                    <q-tr :props="props" style="height:32px;">
                      <q-th v-for="col in props.cols"
                            :key="col.name"
                            :props="props" v-show="!(isViewMyNoticeList && col.name == 'Favorite') && !(!isViewMyNoticeList && col.name == 'Selection')"
                            class="items-center" 
                            style="font-size:15px;font-weight:bold;height:32px;position:sticky;z-index:3;top:0;background-color:white;">
                        <q-checkbox dense flat outline size="sm" color="grey-7" style="margin:0 0 0 0;"
                                    v-if="isViewMyNoticeList && col.name == 'Selection'" v-model="selectAllNotice" @update:model-value="changeSelectAll"></q-checkbox>
                          {{ (col.name == 'Favorite') || (col.name == 'Selection') ? '' : col.label  }}
                        <!--
                        <q-icon v-show="col.name == 'Favorite'" dense size="xs" name="star" color="grey-7"/>
                        -->
                        <div v-show="col.name == 'Favorite'">{{ $t('common.faveroites') }}</div>
                      </q-th>
                    </q-tr>
                  </template>
                  <template v-slot:body="props">
                    <q-tr :props="props" :style="(isViewMyNoticeList || props.row.isRead == 1) ? 'background-color:white;' : 'background-color:#F8f8f8'">
                      <q-td key="Selection" :props="props" style="height:32px;border-bottom:1px solid #dddddd;cursor:pointer" class="items-center justify-start"
                            @click="dataRowClicked(props.row, 0)"
                            v-show="isViewMyNoticeList">
                        <div class="items-center justify-center" style="font-size:15px">
                          <q-checkbox dense flat outline size="sm" color="grey-7" style="margin:0 0 0 0;" v-model="props.row.Selection"></q-checkbox>
                        </div>
                      </q-td>
                      <q-td key="Category" :props="props" style="height:32px;border-bottom:1px solid #dddddd;cursor:pointer" class="items-center justify-start" @click="dataRowClicked(props.row, 1)">
                        <div class="text-left" style="font-size:15px">
                          {{ getCategoryArray[props.row.Category + 1] }}
                        </div>
                      </q-td>
                      <q-td key="Favorite" :props="props" style="height:32px;border-bottom:1px solid #dddddd;" class="row items-center justify-start" v-show="!isViewMyNoticeList">
                        <div class="col">
                          <!-- 클릭을 별에서만 props.row.isFavorite -->
                          <q-icon dense size="xs" :name="props.row.isFavorite ? 'star' : 'star_border'" :color="props.row.isFavorite  ? 'amber-7' : 'grey-5'"
                                  style="cursor:pointer;" @click="changeFavoriteClicked(props.row.index, 2)"/>
                        </div>
                      </q-td>
                      <q-td key="Title" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer" @click="dataRowClicked(props.row, 3)">
                        <div class="row items-center justify-start text-left" style="font-size:15px;">
                          {{ props.row.Title }}
                        </div>
                      </q-td>
                      <q-td key="WriterName" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer" @click="dataRowClicked(props.row, 4)">
                        <div class="row items-center justify-start">
                          <div style="font-size:15px">
                            {{ props.row.WriterName }}
                          </div>
                        </div>
                      </q-td>
                      <q-td key="WriteDate" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;" @click="dataRowClicked(props.row, 5)">
                        <div style="font-size:15px">
                          {{ props.row.WriteDate }}
                        </div>
                      </q-td>
                      <q-td key="CloseDate" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer" @click="dataRowClicked(props.row, 6)">
                        <div style="font-size:15px">
                          {{ props.row.CloseDate }}
                        </div>
                      </q-td>
                    </q-tr>
                  </template>
                  <template v-slot:bottom>
                    <div class="col row items-center justify-end" style="width:100%;height:100%;">
                      <!-- -->
                      <q-pagination class="col row items-center justify-end"
                                    :max="pageCount"
                                    :max-pages="6"
                                    v-model="curPagination"
                                    @update:model-value="notiPageChanged" 
                                    outline dense
                                    gutter="3px"
                                    size="12px"
                                    color="grey-9"
                                    direction-links
                                    boundary-links
                                    active-design="unelevated"
                                    active-color="red-7"
                                    active-text-color="white">
                      </q-pagination>
                    </div>
                  </template>
                </q-table>
            </div>
          </div>

          <div class="col column" style="position:absolute;z-index:10;left:12px;top:12px;right:12px;bottom:12px;border-radius:3px;background-color:white;" v-show="isViewNotice">
            <!-- 공지 보기 -->
            <div class="col-auto row items-center" style="border-top:2px solid #eeeeee;border-bottom:1px solid #ebedf2;height:41px;">
              <div class="col-auto" style="font-size:15px;font-weight:bold;min-width:60px;">{{ $t('noticeView.category') }}</div>
              <div class="col-auto" style="font-size:14px;font-weight:normal;margin:0 32px 0 12px;" v-show="!isViewMyNoticeList && !isAddNotice">{{ catMySelected }}</div>
              <q-icon dense size="sm" :name="selectedNotice.isFavorite ? 'star' : 'star_border'"
                      v-show="!isViewMyNoticeList && !isAddNotice"
                      :color="selectedNotice.isFavorite ? 'amber-7' : 'grey-5'" style="cursor:pointer;" @click="changeFavorite(selectedNotice)"/>
              <q-select flat dense options-dense color="red-7" v-model="catMySelected" style="min-width:120px;margin-left:12px;"
                        v-show="isViewMyNoticeList || isAddNotice" :options="getCategory(2)"></q-select>
            </div>

            <!-- 타이틀, 만료일자. -->
            <div class="col-auto row items-center justify-between" style="border-bottom:1px solid #eeeeee;min-height:40px;">
              <div class="col row justify-start items-center">
                <div class="col-auto" style="font-size:15px;font-weight:bold;min-width:60px;margin-right:12px">{{ $t('noticeView.noticetitle') }}</div>
                <div class="col-auto" style="font-size:14px;font-weight:normal;" v-show="!isViewMyNoticeList && !isAddNotice">{{ selectedNotice.Title }}</div>
                <q-input dense clearable color="red-7" v-model="selectedNotice.Title" style="width:80%;" v-show="isViewMyNoticeList || isAddNotice" :placeholder="$t('noticeView.noticetitle')"></q-input>
              </div>
              <div class="col row justify-start items-center">
                <div class="col-auto" style="font-size:15px;font-weight:bold;margin-right:12px;min-width:60px;">{{ $t('noticeView.expiredate') }}</div>
                <div class="row items-center" v-show="isViewMyNoticeList || isAddNotice">
                  <q-checkbox size="sm" color="grey-7" v-model="canExpireNotice" @update:model-value="changedCanExpire"/>
                  <div class="col-auto" v-show="canExpireNotice" style="font-size:14px;font-weight:normal;">{{ newCloseDate }}</div>
                  <q-btn flat dense icon="calendar_month" v-show="canExpireNotice" style="margin:0 12px;">
                    <q-popup-proxy ref="popupDateSelector" @before-show="popupDateSelect()">
                      <q-date minimal no-unset color="red-7" v-model="selectedDate" @update:model-value="afterDateSelect()">
                      </q-date>
                    </q-popup-proxy>
                  </q-btn>
                </div>
                <div class="col-auto" v-show="!isViewMyNoticeList && !isAddNotice" style="font-size:14px;font-weight:normal;">{{ newCloseDate }}</div>
              </div>
            </div>

            <!-- 작성자 -->
            <div class="col-auto row items-center justify-between" style="border-bottom:1px solid #eeeeee;min-height:40px">
              <div class="col row justify-start items-center">
                <div class="col-auto" style="font-size:15px;font-weight:bold;margin-right:12px;min-width:60px;">{{ $t('noticeView.writer') }}</div>
                <div class="col-auto" style="font-size:14px;font-weight:normal;">{{ selectedNotice.WriterName }}</div>
              </div>
              <div class="col row justify-start items-center" v-show="!isAddNotice">
                <div class="col-auto" style="font-size:15px;font-weight:bold;margin-right:12px;min-width:60px;">{{ $t('noticeView.writedate') }}</div>
                <div class="col-auto" style="font-size:14px;font-weight:normal;" v-show="!isAddNotice">{{ selectedNotice.WriteDate }}</div>
              </div>
            </div>

            <!-- 수신자 -->
            <div class="col-auto row items-center justify-between" style="border-bottom:1px solid #eeeeee;height:40px;">
              <div class="col row justify-start items-center">
                <div class="col-auto" style="font-size:15px;font-weight:bold;margin-right:12px;min-width:60px;">{{ $t('noticeView.receiver') }}</div>
                <div class="col-auto" style="font-size:14px;font-weight:normal;" v-show="!isViewMyNoticeList && !isAddNotice">{{ getReceiverNames(selectedNotice.Receiver) }}</div>
                <div class="col-auto row items-center" v-show="isViewMyNoticeList || isAddNotice">
                  <!-- 20231020 이 버튼들은 공지추가시에만 활성화 -->
                  <q-btn :outline="!canRxBranch" dense unelevated :color="canRxBranch ? 'red-7' : 'white'" :text-color="getUserLevel >= 2 ? 'grey-5' : (canRxBranch ? 'white' : 'black')" :label="$t('groupCategory.2')"
                         :disable="getUserLevel >= 2 || isViewMyNoticeList" 
                         style="min-width:90px;" @click="canRxBranch = !canRxBranch"/>
                  <q-btn :outline="!canRxDealer" dense unelevated :color="canRxDealer ? 'red-7' : 'white'" :text-color="getUserLevel >= 3 ? 'grey-5' : (canRxDealer ? 'white' : 'black')" :label="$t('groupCategory.3')"
                         :disable="getUserLevel >= 3  || isViewMyNoticeList"
                         style="min-width:90px;margin:0 6px;" @click="canRxDealer = !canRxDealer"/>
                  <q-btn :outline="!canRxCustomer" dense unelevated :color="canRxCustomer ? 'red-7' : 'white'" :text-color="canRxCustomer ? 'white' : 'black'" :label="$t('groupCategory.4')"
                         :disable="isViewMyNoticeList"
                         style="min-width:90px;" @click="canRxCustomer = !canRxCustomer"/>
                </div>
              </div>
            </div>

            <!-- 첨부파일 부분 -->
            <div class="col-auto row items-center justify-start" style="margin:20px 0 0 0">
              <div style="font-size:15px;font-weight:bold;">{{ $t('noticeView.attachedfile') }}</div>
              <div class="col-auto row items-center justify-start" v-show="isViewMyNoticeList || isAddNotice">
                <q-btn no-caps dense unelevated color="red-7" text-color="white" :label="$t('noticeView.addFile')" 
                      :disable="selectedNotice.AttachedFiles.length >= 5"
                      style="min-width:80px;margin:0 12px;" @click="clickAddFile">
                </q-btn>
                <q-btn no-caps dense unelevated color="grey-4" text-color="black" :label="$t('noticeView.removeFile')"
                      style="min-width:80px;" @click="removeFileFromAttached"/>
                <q-icon size="sm" color="grey-6" name="info" style="margin:0 0 0 12px;">
                  <q-tooltip>
                    <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:14px;">
                      {{ $t('notice.attachedFiles')}}
                    </div>
                  </q-tooltip>
                </q-icon>
                <div style="margin:0 12px;color:#808080;">{{ $t('noticeView.max5files')}}</div>
              </div>
              <q-file ref="refSelector" id="fileSelector"
                      multiple max-files="5" max-file-size="52428800" max-total-size="52428800"
                      accept=".jpg,.pdf"
                      dense color="red-7" text-color="white" v-show="false" v-model="selectedFileToAttached" @update:model-value="fileSelectionOK"/>
            </div>
            <div class="col-auto column items-start justify-start" style="height:auto;margin:3px 0 0 0;">
              <div class="col-auto column" style="border:1px solid #eeeeee;width:100%;padding:6px 12px;" v-show="selectedNotice.AttachedFiles.length > 0">
                  <div class="col row items-center justify-start"  v-for="file in selectedNotice.AttachedFiles" :key="file" style="margin:3px 0;border-bottom:1px solid #eeeeee">
                    <q-checkbox dense flat outline size="sm" color="grey-7" style="margin:0 12px 0 0;"
                                v-show="isViewMyNoticeList || isAddNotice" v-model="file.Selection"></q-checkbox>
                    <!--
                    <q-btn dense flat icon="download" size="sm" v-show="!isViewMyNoticeList && !isAddNotice" style="margin:0 12px;" @click="downloadAttachedFile(file)"></q-btn>
                    -->
                    <span style="margin:3px 0;" :style="!isViewMyNoticeList && !isAddNotice ? 'cursor:pointer;color:#3840C0' : 'cursor:normal;color:black;'"  @click="downloadAttachedFile(file)">{{ file.Filename.Name }}</span>
                    <a v-show="false" id="downLink">{{ file.Filename.Name }}</a>
                  </div>
              </div>
            </div>

            <div class="col-auto" style="font-size:15px;font-weight:bold;margin:20px 0 0 0">{{ $t('noticeView.context') }}</div>
            <div class="col column items-start justify-start" style="margin:3px 0 0 0;">
              <div style="border-radius:3px;width:100%;height:100%;padding:3px 12px 3px 12px;" :style="(isViewMyNoticeList || isAddNotice) ? 'border:1px solid #eb0028;' : 'border:1px solid #eeeeee;'">
                <q-scroll-area style="width:100%;height:100%;padding:3px 3px 6px 3px;" >
                  <q-input v-model="selectedNotice.Contents" borderless autogrow dense hide-bottom-space type="textarea"
                           style="width:100%;height:100%;font-weight:400;resize:none;" :placeholder="$t('noticeView.inputContent')"
                           :readonly="!isViewMyNoticeList && !isAddNotice"></q-input>
                </q-scroll-area>
              </div>
            </div>

            <div class="row items-center justify-center" style="width:100%;margin:12px 0 0 0;">
              <q-btn class="col-auto" dense unelevated color="red-7" no-caps :label="$t('common.list')" style="width:100px;" v-show="!isViewMyNoticeList && !isAddNotice" @click="gotoList"/>
              <q-btn class="col-auto" dense unelevated color="grey-4" text-color="black" no-caps :label="$t('common.cancel')" style="width:100px;margin:0 6px;" v-show="isViewMyNoticeList || isAddNotice" @click="isViewNotice = false"/>
              <q-btn class="col-auto" dense unelevated color="red-7" no-caps :label="$t('common.confirm')" style="width:100px;margin:0 6px;" v-show="isViewMyNoticeList || isAddNotice" @click="updateNotice"/>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- 다이얼로그 - 삭제 완료 -->
    <q-dialog v-model="showDeleteComplete" persistent style="border:1px solid #eeeeee;background-color: #feffff0f;">
      <q-card>
        <q-card-section class="row items-center" style="margin:12px">
          <q-icon class="col-auto" name="info" color="red-7" size="lg" text-color="white"/>
          <div class="col" style="margin:0 12px;font-size:16px;">{{ $t('noticeView.deleteCompleted') }}</div>
        </q-card-section>

        <q-card-actions >
          <div class="col row items-center justify-center" style="margin:0 0 12px 0">
            <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px" :label="$t('common.confirm')" v-close-popup/>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- 다이얼로그  - 비밀번호 확인용 -->
    <q-dialog v-model="showDeletePassword" persistent style="border:1px solid #eeeeee;background-color: #feffff0f;">
      <q-card>
        <q-card-section class="column items-center" style="width:auto;height:100%;margin:12px;">
          <div class="col row items-center justify-start">
            <q-icon class="col-auto" dense name="info" color="red-7" size="64px" text-color="white" style="margin:0 12px 0 0;"/>
            <div class="col column" style="width:100%;">
              <div class="col" style="margin:0;font-size:16px;">{{ eraseNoticeMsg }}</div>
              <q-input class="col" type="password" v-model="inputDeletePassword" clearable outlined dense color="red-7" style="margin:12px 24px 0 0;font-size:16px;"></q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-actions >
          <div class="col row items-center justify-center" style="margin:0 0 12px 0">
            <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup/>
            <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="enteredPassword"/>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="isUploading" persistent style="border:1px solid #eeeeee;background-color: #fefffff;">
      <q-card>
        <q-card-section class="row items-center" style="margin:12px">
          <q-icon class="col-auto" name="upload" color="grey-7" size="lg" text-color="white"/>
          <div class="col" style="margin:0 12px;font-size:16px;">Uploading...</div>
        </q-card-section>

        <q-card-actions >
          <div class="col row items-center justify-center" style="margin:0 0 12px 0">
            <q-spinner-tail dense color="red-7" size="40px"/>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<style scoped>

.title {
  font-size:16px;
  font-weight: bold;
  color:#212529
}
.table_title {
  font-size:11pt;
  font-weight: bold;
}

.notiControl {
  padding : 5pt;
  font-size:11pt;
  font-weight: normal;
  width:100%;
}

.notiList {
  padding : 12px;
  font-size:12px;
}

.notiContext {
  border-radius: 3px;
  background-color : white;
  padding : 12px;
  font-size:14px;
  font-weight: normal;
}

.searchBar {
  color:white;
  background-color: black;
  border-radius:16pt;
  padding : 1pt 2pt 1pt 2pt;
  margin-bottom: 3pt;
  height:32pt;
}
.searchBarInput {
  border-radius:14pt;
  background-color: white;
  padding-left:10pt;
  padding-right:10pt;
  height:28pt;
  width:calc(100% - 32pt);
}

</style>

<script>
import { ref } from 'vue'
// import { useQuasar } from 'quasar'
import TymAws from '@/js/tymaws.js'
import TymFncs from '@/js/tymfunctions.js'
import TymCommon from '../js/tymcommon.js'
import TymConst from '@/js/tymconstants'
import { useTymictStore } from '@/store/tymict';
//import { DynamoDB } from 'aws-sdk'
import AWS from 'aws-sdk'
import { useI18n } from 'vue-i18n'
import moment from 'moment-timezone'

export default ({
  components : {
  },
  data() {
  },
  setup() {
    return {
      isViewNotice : ref(false),        // 공지 보기 모드냐?
      isViewMyNoticeList : ref(false),  // 내공지 보기
      isAddNotice : ref(false),         // 공지 작성

      selectAllNotice : ref(false),

      catSelected : ref(''),
      noticePagination : ref({
        page: 1,
        rowsPerPage: 20,
      }),
      notiSelected : ref([]),
      visibleColumns : ref(['Selection', 'Category', 'Favorite', 'Title', 'WriterName', 'WriteDate', 'CloseDate']),
      notiListColumns : ref([]),
      noticeItems : ref([]),
      curPagination : ref(1),
      maxNotiPerPage : ref(20),
      pageCount : ref(0),
      notiDetailCategory : ref(''),
      notiDetailWriter : ref(''),
      notiDetailWrDate : ref(null),
      notiDetailExpire : ref(null),
      notiDetailTitle : ref(''),
      notiDetailContent : ref(''),
      attachedFileCount : ref(0),
      attachedFilename : ref(''),
      tableFilter : ref(''),

      canRxBranch : ref(false),
      canRxDealer : ref(false),
      canRxCustomer : ref(false),
      selectedNotice : ref({
        AttachedFiles : [
          {
            Selection : false,
            Filename : ''
          }
        ]
      }),
      newCloseDate : ref(null),
      catMySelected : ref(''),
      tableMyFilter : ref(''),
      canExpireNotice : ref(true),
      selectedDate : ref(''),
      //selectedFileToAttachech : ref([null, null, null, null, null]),
      selectedFileToAttached : ref(null),
      backupAttachedFiles : ref([]),
      // fileSelectors : ref([])

      showDeleteComplete : ref(false),
      reenterPassword : ref(false),
      showDeletePassword : ref(false),
      eraseNoticeMsg : ref(''),
      inputDeletePassword : ref(''),

      isUploading : ref(false),
      uploadIndex : ref(0),
      downloadURL : ref(null),
      downloadFilename : ref(''),
    }
  },
  computed: {
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    getCategoryArray() {
      if(this.isViewMyNoticeList) {
        return this.getCategory(1)
      }
      return this.getCategory(0)
    },
    getCategory() {
      const i18n=useI18n()
      return function(index) {
        if(index == 0) {    // 전체보기에서 사용
          return [ i18n.t('common.all'), i18n.t('noticeView.selectcommon'), i18n.t('noticeView.selectevent'), i18n.t('noticeView.selectnotread'), i18n.t('noticeView.selectfavorite')]
        } else if(index == 1) { // 내가 작성한 공지 목록에서 사용
          return [ i18n.t('common.all'), i18n.t('noticeView.selectcommon'), i18n.t('noticeView.selectevent')]
        } else if(index == 2) { // 공지 수정 및 추가용
          return [ i18n.t('noticeView.selectcommon'), i18n.t('noticeView.selectevent')]
        }
      }
    },
    unreadCount() {
      const store = useTymictStore()
      return store.noticeCount
    },
    getUserLevel() {
      const store = useTymictStore()
      return store.getUserLevel()
    },
  },
  created() {
  },
  mounted() {
    const store = useTymictStore()
    if(store.CurrentPage.Now != TymConst.PAGE_NOTI) {
      store.setPage(TymConst.PAGE_NOTI)
    }
  
    this.notiListColumns = [
      { name: 'index', required: false },
      {
        name: 'Selection',
        headerStyle : 'width:60px;',
        required: true,
        label: '',
        align: 'center',
        field: 'Selection',
        format: val => `${val}`
      },
      {
        name: 'Category',
        headerStyle : 'width:120px;',
        required: true,
        label: this.$t('noticeView.category'),
        align: 'left',
        field: 'Category',
        format: val => `${val}`,
        sortable: true
      },
      {
        name: 'Favorite',
        headerStyle : 'width:60px;',
        required: true,
        label: '',
        align: 'center',
        field: 'Favorite',
        sortable: false
      },
      {
        name: 'Title',
        align: 'left',
        label: this.$t('noticeView.noticetitle'),
        field: 'Title',
        sortable: true
      },
      {
        name: 'WriterName',
        headerStyle : 'width:100px;',
        align: 'left',
        label: this.$t('noticeView.writer'),
        field: 'WriterName',
        sortable: true
      },
      {
        name: 'WriteDate',
        headerStyle : 'width:120px;',
        align: 'left',
        label: this.$t('noticeView.writedate'),
        field: 'WriteDate',
        sortable: true
      },
      {
        name: 'CloseDate',
        headerStyle : 'width:120px;',
        align: 'left',
        label: this.$t('noticeView.expiredate'),
        field: 'CloseDate',
        sortable: true
      }
    ]

    setTimeout(() => {
      this.catSelected = this.getCategory(0)[0]

      let saved = sessionStorage.getItem('NOTI.saved')
      if(!TymCommon.isEmpty(saved)) {
        saved = JSON.parse(saved)

        console.log('NOTI.saved load :', saved)

        this.isViewMyNoticeList = saved.Mode == 1
        this.catSelected = saved.Category
        this.tableFilter = saved.Keyword

        sessionStorage.removeItem('NOTI.saved')
      }

    }, 1)
    this.getNoticeData()
  }, 
  unmounted() {
    sessionStorage.setItem('NOTI.saved', JSON.stringify({
      Mode: this.isViewMyNoticeList ? 1 : 0,
      Category: this.catSelected,
      Keyword: this.tableFilter
    }))

    this.isViewMyNoticeList = false
  },
  methods : {
    unusedParam() {
      // return param
    },
    getNoticeData() {
      setTimeout(() => {
        const store = useTymictStore()
        store.getNoticeCount()

        this.getAllNotice(this.isViewMyNoticeList)
          .then(data => {
            this.noticeItems = []
            /*
            data
            {
              "Id": "181",
              "Category": 1,
              "Title": "sdf",
              "WriterName": "전북지점 이창원",
              "OpenTime": "2023-10-16T14:01:43.000Z",
              "WriteTime": "2023-10-16T14:01:43.145Z",
              "CloseTime": "2023-11-15T00:00:00.000Z",
              "isRead": 1,
              "isFavorite": 0,
              "index": 0,
              "Selection": false,
              "WriteDate": "2023. 10. 16.",
              "CloseDate": "2023. 11. 15."
            }
            */
            let curTZ = ''
            if( (parseInt(store.connectServer) == 0) || (store.timezoneIndex == 0)) {
              curTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
            } else {
              curTZ = store.timezoneList[store.timezoneIndex].value
            }

            let idx = 0
            data.Items.forEach(notice => {
              notice.index = idx;
              notice.Selection = false;
              if(this.isViewMyNoticeList) {
                notice.WriterName = store.idToken.name
              }

              let tmpDate = null
              if(store.connectServer == 0) {
                tmpDate = TymCommon.convDateTimeStringToLocalTime(notice.WriteTime)
                notice.WriteDate = TymCommon.getDateString(tmpDate)
              } else {
                // tmpDate = new Date(notice.WriteTime)
                tmpDate = moment.tz(notice.WriteTime, curTZ)
                notice.WriteDate = TymCommon.getDateString(tmpDate)
              }

              if(!TymCommon.isEmpty(notice.CloseTime)) {
                if(store.connectServer == 0) {
                  notice.CloseDate = TymCommon.getDateString(TymCommon.convDateTimeStringToLocalTime(notice.CloseTime))
                } else {
                  tmpDate = moment.tz(notice.CloseTime, curTZ)
                  notice.CloseDate = TymCommon.getDateString(tmpDate)
                }
              }
              if(!TymCommon.isEmpty(notice.OpenTime)) {
                let openTime = null
                if(store.connectServer == 0) {
                  openTime = TymCommon.convDateTimeStringToLocalTime(notice.OpenTime, false)
                  notice.Folder = this.convDateTimeToFolder(openTime)
                } else {
                  // tmpDate = moment.tz(notice.OpenTime, curTZ)
                  tmpDate = moment.utc(notice.OpenTime)
                  // openTime = TymCommon.getDateTimeString(tmpDate)
                  // notice.Folder = this.convDateTimeToFolder(notice.OpenTime)
                  notice.Folder = tmpDate.format('YYYYMMDDHHmmss')
                  // console.log('Open Time :', typeof tmpDate, notice.Folder)
                }
              }

              this.noticeItems.push(notice)
              idx++
            })
            if(TymConst.IS_DEVELOPMENT) {
              console.log('Notice List :', this.isViewMyNoticeList ? "Mine" : "All", this.noticeItems)
            }
            setTimeout(() => {
              this.arrangeTable()
            }, 1)
          })
          .catch(er => {
            this.noticeItems = []
            console.log('get Notice Error :', er)
          })
      }, 25)
    },
    async getAllNotice(myNoticeOnly) {
      const store = useTymictStore()

      this.noticeItems = []
      this.pageCount = 0

      let payload = {
        userId : store.idToken.userid,
        isAdmin : 1,
        MA : store.idToken['custom:uuid_a'],
        receiveLevel : 4,
        isMine : myNoticeOnly ? 1 : 0
      }
      if(!store.isICTUser()) {
        if(!TymCommon.isEmpty(store.idToken['custom:uuid_b'])) {
          payload.receiveLevel = 4
          payload.MB = store.idToken['custom:uuid_b']
          if(!TymCommon.isEmpty(store.idToken['custom:uuid_c'])) {
            payload.receiveLevel = 4
            payload.MC = store.idToken['custom:uuid_c']
            if(!TymCommon.isEmpty(store.idToken['custom:uuid_d'])) {
              payload.receiveLevel = 2
              payload.MD = store.idToken['custom:uuid_d']
            }
          }
        }
      }
      var tpayload = JSON.stringify(payload);
      var lambdaParam = {
          FunctionName : 'tymict_get_notices_list',
          InvocationType : 'RequestResponse',
          Payload: tpayload
      };
      if(TymConst.IS_DEVELOPMENT) {
        console.log('aws.getAllNotice :', tpayload)
      }
      
      var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, async function(err, data) {
          if (err) {
            console.log('getAllNotice result error => ', err)
            reject(err)
          } else if (TymCommon.isEmpty(data)) {
            console.log('getAllNotice data is null')
            reject(err)
          } else {
            let jsonData = JSON.parse(data.Payload)
            // console.log('getAllNotice :', jsonData)
            resolve(jsonData)
          }        
        })
      })
      return result
    },

    notiItemSelected(evt, row, idx) {
      this.unusedParam(evt)
      this.unusedParam(idx)

      this.notiDetailCategory = '' // this.noticeItems[row.index].
      this.notiDetailWriter = this.noticeItems[row.index].sender
      this.notiDetailWrDate = TymCommon.getDateTimeString(this.noticeItems[row.index].datetime)
      this.notiDetailExpire = ''
      this.notiDetailTitle = this.noticeItems[row.index].title
      this.notiDetailContent = this.noticeItems[row.index].message
    },
    getCategoryIndex(value) {
      let categories = []

      if(this.isViewMyNoticeList) {
        categories = this.getCategory(1)
      } else {
        categories = this.getCategory(0)
      }
      let idx = categories.findIndex(cat => cat == value)
      //console.log('getCategoryIndex :', this.isViewMyNoticeList, this.isAddNotice, categories, idx)
      return idx
    },
    tableFilterMethod() {
      // console.log('tableFilterMethod :', this.curPagination)
      let found = this.noticeItems
      if(this.noticeItems.length > 0) {
        let catIndex = this.getCategoryIndex(this.catSelected)
        switch(catIndex) {
          case 1:
          case 2:
            found = found.filter(row => ((row.Category) == (this.getCategoryIndex(this.catSelected)-1)))
            break;
          case 3: // 미확인
            found = found.filter(row => ((row.isRead == 0)))
            break;
          case 4: // 즐겨찾기
            found = found.filter(row => ((row.isFavorite == 1)))
            break;
        }
        if(!TymCommon.isEmpty(this.tableFilter)) {
          found = this.noticeItems.filter(row => ((row.WriterName.toUpperCase().indexOf(this.tableFilter.toUpperCase()) >= 0) || (row.Title.toUpperCase().indexOf(this.tableFilter.toUpperCase()) >= 0)))
        }
      }

      setTimeout(() => {
        //this.curPagination = 1
        //this.noticePagination.page = 1
        this.pageCount = Math.ceil(found.length / this.noticePagination.rowsPerPage)
        // console.log('FilterMethod :', this.pageCount, this.noticePagination.rowsPerPage, this.noticePagination)
      }, 25)
      return found
    },
    updatePagination(newPagination) {
      // console.log('updatePagination :', newPagination, this.noticePagination)
      this.noticePagination = newPagination
      // this.noticePagination.descending = !newPagination.descending
    },
    notiPageChanged(newPage) {
      //console.log('notiPageChanged :', newPage, this.noticePagination)

      setTimeout(() => {
        this.arrangeTable(newPage)
      }, 25)
    },
    onResize(size) {
      this.unusedParam(size)
      this.arrangeTable()
    },
    arrangeTable(newPage) {
      if(!this.$refs.tableContainer) {
        //
      } else {
        let itemsPerPage = Math.floor((this.$refs.tableContainer.clientHeight - 86) / 32)
        this.pageCount = Math.ceil(this.$refs.notiTable.rows.length / itemsPerPage)

        if(!TymCommon.isEmpty(newPage)) {
          this.curPagination = newPage
          this.noticePagination.page = newPage
        } else {
          this.curPagination = 1
          this.noticePagination.page = 1
        }
        this.noticePagination.rowsPerPage = itemsPerPage

        // console.log('arrangeTable :', this.pageCount, itemsPerPage, this.noticePagination)
      }
    },
    changeFavoriteClicked(index, column) {
      this.unusedParam(index, column)
      const store = useTymictStore()

      // console.log('changeFavoriteClicked :', index, column)
      const self = this
      let newVal = !this.noticeItems[index].isFavorite
      store.changeNoticeState([this.noticeItems[index].Id], newVal)
        .then( data => {
          self.unusedParam(data)
          self.getNoticeData()
          // self.arrangeTable()
        })
        .catch()
    },
    dataRowClicked(row, column) {
      const store = useTymictStore()

      this.unusedParam(column)
      this.selectedNotice = {
        AttachedFiles : []
      }

      store.readOneNotice(row.Id)
        .then(data => {
          this.selectedNotice = row
          this.selectedNotice.WriterId = data[0].WriterId
          this.selectedNotice.Category = data[0].Category
          this.selectedNotice.AttachedFiles = []
          this.backupAttachedFiles = []
          if(!TymCommon.isEmpty(data[0].AttachedFiles)) {
            let files = JSON.parse(data[0].AttachedFiles)
            files.forEach(file => {
              let folderAndFile = file.Name.split('/')
              this.selectedNotice.AttachedFiles.push({
                Selection : false,
                Filename : {
                  Name : folderAndFile[1],
                  Size : file.Size
                }
              })
              this.backupAttachedFiles.push({
                Deleted : false,
                Filename : {
                  Name : folderAndFile[1],
                  Size : file.Size
                }
              })
            })
          }
          this.selectedNotice.UserInfo = { 
            MA : data.WriterMA,
            MB : data.WriterMB,
            MC : data.WriterMC,
            MD : data.WriterMD,
          }
          this.selectedNotice.Contents = data[0].Contents
          this.selectedNotice.Receiver = data[0].Receiver

          if(data[0].Receiver & 4)
            this.canRxBranch = true
          else 
            this.canRxBranch = false

          if(data[0].Receiver & 2)
            this.canRxDealer = true
          else 
            this.canRxDealer = false

          if(data[0].Receiver & 1)
            this.canRxCustomer = true
          else 
            this.canRxCustomer = false

          this.isViewNotice = true;
          this.isAddNotice = false
          this.canExpireNotice = !TymCommon.isEmpty(this.selectedNotice.CloseTime)
          this.catMySelected = this.getCategory(2)[this.selectedNotice.Category]
          this.newCloseDate = this.selectedNotice.CloseDate
          if(TymConst.IS_DEVELOPMENT) {
            console.log('dataRowClicked :', row, data, this.selectedNotice, this.newCloseDate, this.newCloseDate)
          }

          store.getNoticeCount()
        })
        .catch(error => {
          console.log('store.readOneNotice ER:', error)
        })

      if(TymConst.IS_DEVELOPMENT) {
        console.log('noticeView.dataRowClicked LIST :', this.noticeItems)
        console.log('noticeView.dataRowClicked SELECTED :', this.selectedNotice)
      }
    },
    getReceiverNames(value) {
      let result = ''
      if(value & 4) { 
        // 지점
        result += this.$t('groupCategory.2')
        //this.canRxBranch = true 
      }
      if(value & 2) {
        // 대리점
        if(!TymCommon.isEmpty(result)) {
          result += ', '
        }
        result += this.$t('groupCategory.3')
      }
      if(value & 1) {
        // 고객
        if(!TymCommon.isEmpty(result)) {
          result += ', '
        }
        result += this.$t('groupCategory.4')
      }
      //console.log('getReceiverNames :', value, result)
      return result
    },
    changeFavorite(row) {
      const store = useTymictStore()
      const self = this
      let newVal = !this.selectedNotice.isFavorite
      this.unusedParam(row)
      store.changeNoticeState([row.Id], newVal)
        .then( data => {
          self.unusedParam(data)
          self.selectedNotice.isFavorite = newVal
          self.getNoticeData()
        })
        .catch()
    },
    gotoMyNotices() {
      let categories = Array.from(this.getCategory(1))
      this.isViewMyNoticeList = true
      this.isAddNotice = false
      this.selectAllNotice = false
      this.tableFilter = ''

      this.catSelected = categories[0]
      this.getNoticeData()
    },
    addNewNotice() {
      let ar = Array.from(this.getCategory(2))
      const store = useTymictStore()

      this.isViewNotice = true;
      this.isAddNotice = true
      this.canRxBranch = false
      this.canRxDealer = false
      this.canRxCustomer = false
      this.canExpireNotice = true
      this.tableFilter = ''

      this.catMySelected = ar[0]

      let expire = new Date()
      expire = new Date(expire.setMonth(expire.getMonth() + 1))
      expire = expire.setDate(expire.getDate() - 1)
      this.selectedNotice = {
        Category : 0,
        Title : "",
        WriterName : store.idToken.name,
        WriteDate : '',
        CloseDate : TymCommon.convertStringToDate(expire),
        isRead : 0,
        isFavorite : 0,
        Selection : false,
        AttachedFiles : [],
        Contents : "",
        Receiver : 0
      }
      this.newCloseDate = this.selectedNotice.CloseDate
      this.backupAttachedFiles = []
    },
    closeMyNotice() {
      this.catSelected = this.getCategory(0)[0]
      this.isViewMyNoticeList = false
      this.isAddNotice = false
      this.getNoticeData()
    },
    changeSelectAll() {
      if(this.noticeItems.length > 0) {
        let found = this.noticeItems
        let catIndex = this.getCategoryIndex(this.catSelected)
        //console.log(catIndex, this.catSelected, found)
        switch(catIndex) {
          case 1:
          case 2:
            found = found.filter(row => (row.Category == catIndex))
            break;
        }
        if(!TymCommon.isEmpty(this.tableFilter)) {
          found = this.noticeItems.filter(row => ((row.WriterName.indexOf(this.tableFilter) >= 0) || (row.Title.indexOf(this.tableFilter) >= 0)))
        }

        found.forEach(notice => {
          // console.log(notice)
          notice.Selection = this.selectAllNotice
        })
      }
    },
    // 삭제시 비밀번호를 묻는 대화상자 표시
    deleteQuestion() {
      if(this.noticeItems.length > 0) {
        // console.log(this.noticeItems)
        let found = this.noticeItems.filter(x => x.Selection == true)
        if(!TymCommon.isEmpty(found)){
          if(found.length > 0) {
            this.inputDeletePassword = ''
            this.reenterPassword = false
            this.eraseNoticeMsg = this.$t('noticeView.deleteNoticeMsg1')
            this.showDeletePassword = true
          }
        }
      }
    },
    // 선택된 녀석들 정말 삭제함
    async deleteMyNotices() {
      const store = useTymictStore()
      let noticeIDs = []

      if(this.noticeItems.length > 0) {
        let found = this.noticeItems
        let catIndex = this.getCategoryIndex(this.catSelected)
        switch(catIndex) {
          case 1:
          case 2:
            found = found.filter(row => (row.Category == catIndex))
            break;
        }
        if(!TymCommon.isEmpty(this.tableFilter)) {
          found = found.filter(row => ((row.WriterName.indexOf(this.tableFilter) >= 0) || (row.Title.indexOf(this.tableFilter) >= 0)))
        }

        // console.log('deleteMyNotices 000:', catIndex, this.tableFilter, this.noticeItems, found)

        found.forEach(notice => {
          if(notice.Selection) {
            let fullPath = 'public/' + store.idToken['userid'] + '-' + notice.Folder + '/'
            this.eraseS3Folder(fullPath)

            noticeIDs.push(notice.Id)
          }
        })
      }
      if(noticeIDs.length > 0) {
        const store = useTymictStore()
        let payload = {
          NoticeIds : noticeIDs
        }
        let tpayload = JSON.stringify(payload)
        let lambdaParam = {
          FunctionName : 'tymict_delete_notice',
          InvocationType : 'RequestResponse',
          Payload: tpayload
        }
        // console.log('deleteMyNotices 111:', lambdaParam)
      
        let self = this
        var result = await new Promise((resolve,reject)=>{
          store.docLambda.invoke(lambdaParam, async function(err, data) {
            if (err) {
              console.log('deleteMyNotices result error => ', err)
              reject(err)
            } else if (TymCommon.isEmpty(data)) {
              console.log('deleteMyNotices data is null')
              reject(err)
            } else {
              let jsonData = JSON.parse(data.Payload)
              
              // console.log('deleteMyNotices data is ', data, jsonData)
              resolve(jsonData)
              setTimeout(() => {
                self.getNoticeData()
                self.showDeleteComplete = true
              }, 25)
            }
          })
        })
        return result;
      }
    },
    popupDateSelect(){
      let expDate = new Date()
      if(!TymCommon.isEmpty(this.newCloseDate)) {
        expDate = new Date(this.newCloseDate)
      }
      this.selectedDate = expDate.getFullYear().toString() + '/' + (expDate.getMonth() + 1).toString().padStart(2, '0') + '/' + expDate.getDate().toString().padStart(2, '0')
    },
    afterDateSelect() {
      let expDate = new Date(this.selectedDate)
      /*
      expDate.setUTCHours(0)
      expDate.setUTCMinutes(0)
      expDate.setUTCSeconds(0)
      expDate.setUTCMilliseconds(0)
      */
      this.newCloseDate = TymCommon.convertStringToDate(expDate)
      this.$refs.popupDateSelector.hide()
    },
    clickAddFile() {
      /*
      let last = this.selectedNotice.AttachedFiles.length

      this.fileSelectors[last].pickFiles()
      console.log("Add :", this.selectedNotice.AttachedFiles)
      */
      this.$refs.refSelector.pickFiles()
    },
    removeFileFromAttached() {
      let arIndex = []

      for(let idx = 0; idx < this.selectedNotice.AttachedFiles.length; idx++) {
        let file = this.selectedNotice.AttachedFiles[idx]
        if(file.Selection) {
          arIndex.push(idx)
        }
      }
      if(arIndex.length > 0) {
        arIndex.reverse().forEach(idx => {
          this.selectedNotice.AttachedFiles.splice(idx, 1)
        })
      }
      // console.log("Remove :", this.selectedNotice.AttachedFiles)
    },
    fileSelectionOK(selectedFiles) {
      let values = null
      if(Array.isArray(selectedFiles)) {
        values = selectedFiles
      } else {
        values = [ selectedFiles ]
      }
      // console.log('fileSelectionOK :', values)
      
      values.forEach(value => {
        // console.log('fileSelectionOK :', value, this.selectedNotice.AttachedFiles)
        if(value.size > TymConst.MAX_ATTACHED_FILESIZE) {
          TymCommon.Toast(this.$t('warning.tooLargeSize'), true)
        } else {
          let totalSize = 0;
          this.selectedNotice.AttachedFiles.forEach(file => {
            totalSize += file.Filename.Size
          })
          totalSize += value.size

          if(totalSize > TymConst.MAX_ATTACHED_FILESIZE) {
            TymCommon.Toast(this.$t('warning.tooLargeTotalSize'), true)
          } else {
            this.selectedNotice.AttachedFiles.push({
              Selection : false,
              Filename : {
                Name : value.name,
                Size : value.size
              },
              Data : value,
            })
            // console.log("Selected :", totalSize, this.backupAttachedFiles, this.selectedNotice.AttachedFiles)
          }
        }
      })
    },
    async downloadFile(filename) {
      const store = useTymictStore()

      let s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: {
          Bucket: TymConst.BUCKET_NOTICE_NAME
        },
        region: store.cognito[store.connectServer].region
      })

      let fullPath = 'public/' + this.selectedNotice.WriterId + '-' + this.selectedNotice.Folder + '/'
      fullPath += filename
      // console.log('downloadFile :', fullPath, this.selectedNotice)
      s3.getObject({Key: fullPath}, function(err, obj) {
        if(err) {
          console.log(filename + ' was not loaded.')
        }
        else {
          // console.log('getObject ', obj)
          var abc = new Blob([obj.Body], {type : obj.ContentType})
          if (window.navigator.msSaveOrOpenBlob)  {
            // window.navigator.msSaveOrOpenBlob(abc, imgPath);
          } else {
            let anchor = document.getElementById('downLink')
            anchor.href = URL.createObjectURL(abc)
            anchor.download = filename
            anchor.click()
          }
        }
      })
    },
    uploadFile(file, data) {
      if(!TymCommon.isEmpty(data)) {
        const store = useTymictStore()

        let folder = 'public/' + store.idToken['userid'] + '-' + this.selectedNotice.Folder + '/'
        // console.log('uploadFile :', this.selectedNotice.OpenTime, file, folder)

        var upload = new AWS.S3.ManagedUpload({
          params : {
            Bucket: TymConst.BUCKET_NOTICE_NAME,
            Key: folder + data.name,
            Body: data, // this.selectedFileToAttachech,
            region: store.cognito[store.connectServer].region
          }
        })

        let self = this
        upload.promise().then(data => {
          setTimeout(() => {
            // console.log('UPLOAD :', data)
            self.unusedParam(data)
            self.uploadIndex++
            self.uploadProc()
          }, 5)
        }).catch(er => {
          console.log(file + ' was not loaded.', er)
          setTimeout(() => {
            self.uploadIndex++
            self.uploadProc()
          }, 5)
        })
      } else {
        setTimeout(() => {
          this.uploadIndex++
          this.uploadProc()
        }, 5)
      }
    },
    eraseS3File(file) {
      const s3 = new AWS.S3()
      const params = {
        Bucket: TymConst.BUCKET_NOTICE_NAME,
        Key: file
      }
      s3.deleteObject(params, function(err, data) {
        if (err) console.log(err, err.stack)
        else     console.log('Delete Success', data)
      })
    },
    async eraseS3Folder(folder) {
      const store = useTymictStore()
      var lambdaParam = {
        FunctionName : 'tymict_del_S3Folder',
        InvocationType : 'RequestResponse',
        Payload: JSON.stringify({
          Folder : folder
        })
      }
      
      var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, async function(err, data) {
          if (err) {
            console.log('eraseS3Folder result error => ', err)
            reject(err)
          } else if (TymCommon.isEmpty(data)) {
            console.log('eraseS3Folder data is null')
            reject(err)
          } else {
            let jsonData = JSON.parse(data.Payload)
            /*
            {
              "deletedCount": 5,
              "message": "Objects deleted successfully"
            }
            */
            // console.log('eraseS3Folder OK :', jsonData)
            resolve(jsonData)
          }        
        })
      })
      return result;
    },
    // 공지, 내공지보기에서 목록으로 전환할 때
    gotoList() {
      this.isViewNotice = false
      this.getNoticeData()
    },
    convDateTimeToFolder(date) {
      const store = useTymictStore()
      let result = ''

      if(parseInt(store.connectServer) === 0) {
        let mDate = moment(date)
        result = mDate.format('YYYYMMDDHHmmss')
      } else {
        let mDate = moment(date)
        result = mDate.format('YYYYMMDDHHmmss')
        // console.log('convDateTimeToFolder not KR :', date, result)
      }
      return result
    },
    convDateTimeToDBString(date, isNew) {
      const store = useTymictStore()
      let result = ''

      if(parseInt(store.connectServer) === 0) {
        if(isNew) {
          let mDate = moment(date)
          result = mDate.format('YYYY-MM-DD HH:mm:ss')  
        } else {
          let mDate = moment.utc(date)
          result = mDate.format('YYYY-MM-DD HH:mm:ss')  
        }
      } else {
        let mDate = moment.utc(date)
        result = mDate.format('YYYY-MM-DD HH:mm:ss')
      }
      // console.log('convDateTimeToDBString FINAL :', date, isNew ? 'NEW' : 'UPDATE', result)
      return result
    },
    async AddOrUpdateNotice(isAddNew) {
      const store = useTymictStore()

      let payload = {
        Category : this.selectedNotice.Category,
        Title : this.selectedNotice.Title,
        Contents : this.selectedNotice.Contents,
        WriterId : store.idToken['userid'],
        WriterName : this.selectedNotice.WriterName,
        WriterMA : store.idToken['custom:uuid_a'],
      }

      if(!isAddNew) {
        payload.NoticeId = this.selectedNotice.Id
      } else {
        // 추가에만 수신자 정보 포함됨
        payload.Receiver = this.selectedNotice.Receiver
      }

      if(!TymCommon.isEmpty(store.idToken['custom:uuid_b'])) {
        payload.receiveLevel = 4
        payload.WriterMB = store.idToken['custom:uuid_b']
        if(!TymCommon.isEmpty(store.idToken['custom:uuid_c'])) {
          payload.receiveLevel = 4
          payload.WriterMC = store.idToken['custom:uuid_c']
          if(!TymCommon.isEmpty(store.idToken['custom:uuid_d'])) {
            payload.receiveLevel = 2
            payload.WriterMD = store.idToken['custom:uuid_d']
          }
        }
      }

      // console.log('AddOrUpdateNotice START:', isAddNew ? 'NEW' : 'UPDATE', payload)

      let folder = this.selectedNotice.Folder
      let openTime = null
      if(!TymCommon.isEmpty(this.selectedNotice.OpenTime)) {
        openTime = TymCommon.convDateTimeStringToLocalTime(this.selectedNotice.OpenTime, false)
        openTime = moment(openTime).toISOString()
        console.log('openTime #0:', this.selectedNotice.OpenTime, openTime)
      }

      // console.log('openTime #1:', this.selectedNotice.OpenTime, openTime)
      // console.log('openTime #2:', folder)

      if(this.selectedNotice.AttachedFiles.length > 0) {
        let fullPath = store.idToken['userid'] + '-' + folder + '/'
        // console.log("Attached #1:", fullPath)
        let attached  = []
        this.selectedNotice.AttachedFiles.forEach(file => {
          //console.log("Attached #2:", file)
          attached.push({
            Name : fullPath + file.Filename.Name,
            Size : file.Filename.Size
          })
        })
        payload.AttachedFiles = JSON.stringify(attached)
      }
      if(!TymCommon.isEmpty(this.selectedNotice.CloseTime)) {
        // payload.CloseTime = this.selectedNotice.CloseTime
        payload.CloseTime = this.convDateTimeToDBString(this.selectedNotice.CloseTime, false)
      }

      if(!TymCommon.isEmpty(this.selectedNotice.OpenTime)) {
        payload.OpenTime = this.convDateTimeToDBString(this.selectedNotice.OpenTime, isAddNew)
        // console.log('AddOrUpdateNotice >> ', isAddNew, this.selectedNotice.OpenTime, payload.OpenTime)
      } else {
        // openTime --> 이건 아직 Date
        payload.OpenTime = this.convDateTimeToDBString(openTime, isAddNew)
      }

      var tpayload = JSON.stringify(payload)
      var lambdaParam = {
          FunctionName : isAddNew ? 'tymict_write_notice' : 'tymict_modify_notice',
          InvocationType : 'RequestResponse',
          Payload: tpayload
      }

      this.selectedNotice.Folder = folder
      // console.log('AddOrUpdateNotice :', this.selectedNotice, folder, payload)

      /*
      if(TymConst.IS_DEVELOPMENT == 0) {
        console.log('*** TEST CODE - REMOVE THIS. ***')
        return
      }
      */

      let self = this
      try {
        var result = await new Promise((resolve,reject)=>{
          store.docLambda.invoke(lambdaParam, async function(err, data) {
            setTimeout(() => {
              self.getNoticeData()
              self.isViewNotice = false
            }, 50)
            if (err) {
              console.log('AddOrUpdateNotice result error => ', err)
              reject(err)
            } else if (TymCommon.isEmpty(data)) {
              console.log('AddOrUpdateNotice data is null')
              reject(err)
            } else {
              let jsonData = JSON.parse(data.Payload)
              if(jsonData.statusCode != 200) {
                reject(jsonData.body)
              } else {
                // console.log('AddOrUpdateNotice OK :', jsonData)
                resolve(jsonData)
              }
            }        
          })
        })
      } catch(ex) {
        console.log('Add or Update notice FAIL :', ex)
      }
      return result;
    },
    // 2024-12-31 00:00:00
    dateStringToDateTimeString(date) {
      let newDate = new Date(date)
      let result = newDate.getFullYear().toString() 
      result += '-' + (newDate.getMonth() + 1).toString().padStart(2, '0')
      result += '-' + newDate.getDate().toString().padStart(2, '0')

      result += ' 00:00:00'

      // console.log(result)
      return result
    },
    changedCanExpire() {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('noticeView.changedCanExpire 111 :', this.newCloseDate, this.selectedNotice.CloseDate)
        console.log('noticeView.changedCanExpire :', JSON.parse(JSON.stringify(this.noticeItems)))
      }

      if(this.canExpireNotice) {
        if(TymCommon.isEmpty(this.selectedNotice.CloseTime)) {
          let now = new Date()
          now = new Date(now.setMonth(now.getMonth() + 1))
          now = now.setDate(now.getDate() -1)
          this.newCloseDate = TymCommon.getDateString(now)
        } else {
          this.newCloseDate = TymCommon.getDateString(new Date(this.selectedNotice.CloseTime))
        }
      } else {
        this.newCloseDate = null
      }
      if(TymConst.IS_DEVELOPMENT) {
        console.log('noticeView.changedCanExpire 222 :', this.newCloseDate, this.selectedNotice.CloseDate)
        console.log('noticeView.changedCanExpire :', JSON.parse(JSON.stringify(this.noticeItems)))
      }
    },
    // 작성 혹은 변경한 공지를 저장한다.
    updateNotice() {
      const store = useTymictStore()

      if(TymCommon.isEmpty(this.selectedNotice.Title)) {
        TymCommon.Toast(this.$t('warning.noTitle'))
        return
      }
      if(TymCommon.isEmpty(this.selectedNotice.Contents)) {
        TymCommon.Toast(this.$t('warning.noContent'))
        return
      }
      let receiver = 0
      if(this.canRxBranch) {
        receiver += 4
      }
      if(this.canRxDealer) {
        receiver += 2
      }
      if(this.canRxCustomer) {
        receiver += 1
      }
      if(receiver == 0) {
        TymCommon.Toast(this.$t('warning.noReceiver'))
        return
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('noticeView.updateNotice :', this.noticeItems)
      }
      // 전체 포함인데?
      this.selectedNotice.Category = this.getCategoryIndex(this.catMySelected) - 1

      if(this.canExpireNotice) {
        if(!TymCommon.isEmpty(this.newCloseDate)) {
          this.selectedNotice.CloseTime = this.dateStringToDateTimeString(this.newCloseDate)
        }
      } else {
        this.selectedNotice.CloseTime = ''
      }
      this.selectedNotice.Receiver = receiver

      // OpenTime 계산... 이것 참 중요함...
      let openTime = null
      if(!TymCommon.isEmpty(this.selectedNotice.Folder)) {
        // console.log('Upload (Update) #1 :', this.selectedNotice)
      } else {
        openTime = new Date()
        if(parseInt(store.connectServer) == 0) {
          this.selectedNotice.Folder = this.convDateTimeToFolder(openTime, true)
          this.selectedNotice.OpenTime = this.convDateTimeToDBString(openTime, true)
        } else {
          openTime = moment.utc()
          this.selectedNotice.Folder = this.convDateTimeToFolder(openTime, true)
          this.selectedNotice.OpenTime = this.convDateTimeToDBString(openTime, true)
        }

        // console.log('Upload (NEW) #1 :', this.isViewMyNoticeList, this.isAddNotice, openTime, this.selectedNotice)
      }

      if(this.backupAttachedFiles.length > 0) {
        // 기존 있던 파일중에 삭제된 녀석들만 마킹하기
        this.backupAttachedFiles.forEach(backup => {
          let found = this.selectedNotice.AttachedFiles.find(x => x.Filename.Name == backup.Filename.Name && x.Filename.Size == backup.Filename.Size)
          if(!found) {
            backup.Deleted = true
          }
        })
      }
      if(this.selectedNotice.AttachedFiles.length > 0) {
        // 기존 첨부된 파일중에 변경되지 않은 것은 지우는 과정
        for(let idx = 0; idx < this.selectedNotice.AttachedFiles.length; idx++) {
          let file = this.selectedNotice.AttachedFiles[idx]
          let found = this.backupAttachedFiles.find(x => x.Filename.Name == file.Filename.Name && x.Filename.Size == file.Filename.Size && !x.Deleted)
          if(found) {
            file.Reserved = true
          } else {
            file.Reserved = false
          }
        }
      }
      // console.log('updateNotice #2 :', this.selectedNotice.Folder, this.backupAttachedFiles)

      this.backupAttachedFiles.forEach(backup => {
        if(backup.Deleted) {
          let fullPath = 'public/' + store.idToken['userid'] + '-' + this.selectedNotice.Folder + '/' + backup.Filename.Name
          // console.log('eraseS3File :', fullPath)
          this.eraseS3File(fullPath)
        }
      })

      if(this.selectedNotice.AttachedFiles.length > 0) {
        this.uploadIndex = 0
        this.isUploading = true
        this.uploadProc()
      } else {
        this.AddOrUpdateNotice(this.isAddNotice)
          .then(data => {
            console.log('AddOrUpdateNotice OK:', data)
          })
          .catch(error => {
            console.log('AddOrUpdateNotice FAIL:', error)
          })
      }
    },
    uploadProc() {
      if(this.selectedNotice.AttachedFiles.length > 0) {
        if(this.uploadIndex >= 0 && this.uploadIndex < this.selectedNotice.AttachedFiles.length) {
          while(this.uploadIndex < this.selectedNotice.AttachedFiles.length) {
            let file = this.selectedNotice.AttachedFiles[this.uploadIndex]
            if(!file.Reserved) {
              // console.log('UPLOAD :', file)
              this.uploadFile(file.Filename, file.Data)
              break;
            } else {
              this.uploadIndex++
            }
          }
        } 
        if(this.uploadIndex >= this.selectedNotice.AttachedFiles.length) {
          this.AddOrUpdateNotice(this.isAddNotice)
          this.isUploading = false
          console.log('UPLOAD completed...')
        }
      }
    },
    checkPasswordOK() {
      // console.log("checkPasswordOK")
      this.deleteMyNotices()
    },
    checkPasswordFailed(code, message) {
      console.log("checkPasswordFailed :", code, message)
      this.inputDeletePassword = ''
      this.reenterPassword = true
      this.eraseNoticeMsg = this.$t('noticeView.deleteNoticeMsg2')
      this.showDeletePassword = true
    },
    enteredPassword() {
      try {
        if(!TymCommon.isEmpty(this.inputDeletePassword)) {
          TymAws.checkPassword(this.inputDeletePassword, this.checkPasswordOK, this.checkPasswordFailed)
        }
      } catch(ex) {
        console.log(ex)
      }
    },
    downloadAttachedFile(file) {
      // console.log("downloadAttachedFile :", file)

      if(!this.isViewMyNoticeList && !this.isAddNew) {
        if(TymCommon.isEmpty(file.Filename.Name))
          this.downloadFile(file.Filename)
        else 
          this.downloadFile(file.Filename.Name)
      }
    },
  }
})
</script>
