<template>
  <div class="column">
    <div class="col column" style="padding:0 12px 12px 12px">
      <div class="col-auto row items-center" style="height:45px;">
        <div class="col-auto"
            :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
          {{ getPageTitle }}
        </div>
      </div>
      <div class="col row" :class='isLandscape ? "row" : "column"'>
        <!-- 제목 아랫부분 전체 -->
        <div class="column" :class='isLandscape ? "col-4" : "col-7"'>
          <!-- 왼쪽 조직도 / 정보창 부분-->
          <div class="col column" style="position:relative;border:0px solid #eeeeee;background-color: white;border-radius: 3px;padding:12px;">
            <div class="col-auto row items-center justify-between">
              <div class="col" :style="isKorean ? 'font=family:Noto Sans KR' : 'Prometo'" style="font-size:15px;font-weight:500;height:34px;">{{ $t('group.listGroup') }}</div>
              <div class="col row items-center justify-end">
                <q-btn flat dense color="grey-9" icon="face" size="md" v-show="isDev" @click="testClicked">
                </q-btn>
                <q-btn flat dense color="grey-9" icon="add" padding="0" size="md" :disable="((selectedTree !== null) && (selectedTree == '')) || isAddGrp || isEditGrp"
                       @click="changeMode('addGrp')">
                  <q-tooltip style="font-size:14px" ref="addTooltip">{{ $t('tooltip.makeGroup') }}</q-tooltip>
                </q-btn>
              </div>
            </div>
            <div class="col row" style="border:1px solid #eeeeee;padding:12px;">
              <q-scroll-area style="width:100%;height:100%;">
                <q-tree :nodes="groupTreeData"
                        style="width:auto;height:auto;"
                        ref="groupTree"
                        dense
                        no-selection-unset
                        selected-color="red-7"
                        node-key="uuid"
                        v-model:expanded="expandedTree"
                        v-model:selected="selectedTree"
                        @update:selected="groupTreeSelected"
                        class="treeContext" >
                </q-tree>
              </q-scroll-area>
            </div>
            <!-- 추가나 수정시 트리 덮기위함 -->
            <div style="position:absolute;z-index:1;left:0;top:0;right:0;bottom:0;border-radius:3px;border:1px solid darkgray;background-color: #B0B0B020;" v-show="isAddGrp || isEditGrp || isAddManager || isEditManager">
            </div>
          </div>

          <div class="col row" style="position:relative;padding-top:12px;">
            <div class="col column" style="padding:12px;background-color: white;border-radius: 3px;" :style='isAddGrp || isEditGrp ? "border:1px solid #eb0028;" : "border:1px solid #eeeeee;"'>
              <!-- 왼쪽 아래 정보-->
              <div class="col-auto row justify-between items-center">
                <div class="col-auto" :style="isKorean ? 'font=family:Noto Sans KR' : 'Prometo'" style="font-size:15px;font-weight: 500;height:34px;">{{ getGroupTitle }}</div>
                <div class="col-auto row justify-end" >
                  <div v-show="((selectedTree != null) && (selectedTree != '')) && !isAddGrp && !isEditGrp">
                    <q-btn flat dense color="blue-7" padding="5px" size="md" icon="edit" @click="changeMode('editGrp')">
                      <q-tooltip style="font-size:14px">{{ $t('tooltip.editGroup') }}</q-tooltip>
                    </q-btn>
                    <q-btn flat dense color="red-7" padding="5px" size="md" icon="delete" @click="changeMode('deleteGrp')">
                      <q-tooltip style="font-size:14px">{{ $t('tooltip.deleteGroup') }}</q-tooltip>
                    </q-btn>
                  </div>
                  <div v-show="isAddGrp || isEditGrp">
                    <q-btn class="col" flat dense color="red-5" icon="cancel" @click="changeMode('cancelGrp')">
                      <!--
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                      -->
                    </q-btn>
                    <q-btn class="col" flat dense color="blue-8" icon="save" @click="saveGroup()">
                      <!--
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                      -->
                    </q-btn>
                  </div>
                </div>
              </div>
              <q-scroll-area class="col" ref="scrGroupInfo"
                             style="width:100%;height:100%;border-top:2px solid #cccccc;border-bottom:2px solid #cccccc;">
                <table style="width:100%;padding:1px;border-bottom:1px solid #cccccc">
                  <tr >
                    <td class="tableTop tableLabel">{{ $t('group.parentGrp') }}</td>
                    <td class="tableTop">
                      <q-input color="red-7" style="width:90%" dense borderless readonly v-model="infoValues[0]">
                      </q-input>
                    </td>
                  </tr>
                  <tr >
                    <td class="tableTop tableLabel">{{ $t('common.groupName') }}</td>
                    <td class="tableTop">
                      <q-input color="red-7" style="width:90%" dense :borderless="!isAddGrp && !isEditGrp" :readonly="!isAddGrp && !isEditGrp" v-model="infoValues[1]">
                      </q-input>
                    </td>
                  </tr>
                  <tr>
                    <td class="tableMiddle tableLabel">{{ $t('group.type') }}</td>
                    <td class="tableTop">
                      <q-input color="red-7" style="width:90%" dense borderless readonly v-model="infoValues[2]">
                      </q-input>
                    </td>
                  </tr>
                  <tr v-show="isKorea">
                    <td class="tableMiddle tableLabel">{{ $t('group.postcode') }}</td>
                    <td class="tableMiddle row no-wrap justify-between items-end">
                      <q-input color="red-7" style="width:65%" dense :borderless="!isAddGrp && !isEditGrp" :readonly="!isAddGrp && !isEditGrp" v-model="infoValues[3]">
                      </q-input>
                      <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')"
                           v-show="isAddGrp || isEditGrp"
                           style="margin-bottom:2px;width:76px;height:30px;" @click="changeMode('searchPost')">
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectPostcode') }}</q-tooltip>
                      </q-btn>
                    </td>
                  </tr>
                  <tr> <!-- v-show="isKorea" -->
                    <td class="tableMiddle tableLabel">{{ $t('group.address1') }}</td>
                    <td class="tableMiddle">
                      <q-input color="red-7" style="width:90%" dense :borderless="!isAddGrp && !isEditGrp" :readonly="!isAddGrp && !isEditGrp" v-model="infoValues[4]">
                      </q-input>
                    </td>
                  </tr>
                  <tr>  <!-- v-show="isKorea && (getServerIndex == 0)"-->
                    <td class="tableMiddle tableLabel">{{ $t('group.address2') }}</td>
                    <td class="tableMiddle">
                      <q-input color="red-7" style="width:60%" dense :borderless="!isAddGrp && !isEditGrp" :readonly="!isAddGrp && !isEditGrp" v-model="infoValues[5]">
                      </q-input>
                    </td>
                  </tr>
                  <tr>
                    <td class=" tableLabel">{{ $t('group.phone') }}</td>
                    <td class="" style="margin-bottom:2px;">
                      <q-input color="red-7" style="width:90%" dense :borderless="!isAddGrp && !isEditGrp" :readonly="!isAddGrp && !isEditGrp" v-model="infoValues[6]">
                      </q-input>
                    </td>
                  </tr>
                </table>
              </q-scroll-area>
            </div>

            <!-- 관리자 추가시 덮기위함 -->
            <div style="position:absolute;z-index:1;left:0;top:12px;right:0;bottom:0;border-radius:3px;border:1px solid darkgray;background-color: #B0B0B020;" v-show="isAddManager || isEditManager">
            </div>
          </div>
        </div>

        <div class="col column" style="position: relative;" :style='isLandscape ? "padding:0 0 0 12px;" : "padding:12px 0 0 0;"'>
          <!-- 오른쪽 부분 -->
          <div class="col row" style="position:relative;background-color:white;border-radius: 3px;" :style='(isAddManager || isEditManager) ? "border:1px solid #eb0028;" : "border:0 solid #eb0028;"'>
            <!-- 관리자 목록부분 -->
            <div class="col row" style="background-color:white;border-radius:3px;width:100%;height:100%;" v-show="!isPostCode">
              <div class="col column">
                <div class="col-auto row items-center justify-between" style="padding:12px 12px 0 12px;">
                  <div class="col-auto title" :style="isKorean ? 'font=family:Noto Sans KR' : 'Prometo'">{{ $t('group.listManager') }}</div>
                  <div class="col-auto column" style="height:40px;width:220px;">
                    <q-input dense outlined clearable color="red-7" ref="searchElement"
                            style="font-size:14px;"
                            :placeholder="$t('placeholder.nameorphone')"
                            v-model="filterKeyword">
                      <template v-slot:append>
                        <q-icon name="search"/>
                      </template>
                    </q-input>
                  </div>
                  <div class="col-auto row">
                    <q-btn flat dense color="grey-9" icon="group_add"
                          :disable="(selectedTree == undefined) || (selectedTree == null) || (selectedTree == '')" @click="changeMode('addMan')">
                      <q-tooltip style="font-size:14px">{{ $t('tooltip.addAdmin') }}</q-tooltip>
                    </q-btn>
                    <q-btn flat dense color="grey-9" icon="edit" v-show="canEditAccount"
                          :disable="modelRowSelection < 0" @click="changeMode('editMan')">
                      <q-tooltip style="font-size:14px">{{ $t('tooltip.editAdmin') }}</q-tooltip>
                    </q-btn>
                    <q-btn flat dense color="grey-9" icon="delete" :disable="modelRowSelection < 0" @click="eraseManager()">
                      <q-tooltip style="font-size:14px">{{ $t('tooltip.deleteAdmin') }}</q-tooltip>
                    </q-btn>
                  </div>
                </div>

                <div class="col row" style="position: relative;">
                  <q-table dense flat bordered
                          class="col treeContext"
                          style="position:absolute;left:12px;right:12px;top:12px;bottom:12px;z-index: 2;"
                          separator="cell"
                          hide-no-data
                          selection="single"
                          row-key="index"
                          virtual-scroll="true"
                          :visible-columns="visibleColumns"
                          v-model:selected="userSelected"
                          :filter="filterKeyword"
                          :filter-method="managerFilterMethod"
                          :columns="columnData"
                          :rows="rowData"
                          :rows-per-page-options="[maxItemPerPage]"
                          @row-click="tableItemClicked">
                    <template v-slot:header="props">
                      <q-th no-caps
                            style="height:36px;font-size:15px"
                            class="thSticky"
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props">
                      {{ col.label }}
                      </q-th>
                    </template>
                    <template v-slot:body="props">
                      <q-tr :props="props">
                        <q-td key="group" :props="props" style="height:34px;border-bottom:1px solid #dddddd;cursor:pointer;margin:0;padding:0;" @click="manageCellClicked(props.row.index, 0, props.row)">
                          <div class="row items-center justify-start no-wrap" style="width:100%;height:100%;margin:0 6px;">
                            <q-radio class="col-auto" v-model="modelRowSelection" size="32px" :val="props.row.index" flat dense color="red" style="margin-right:6px"/>
                            <div class="col" style="font-size:15px">
                              {{ props.row.group }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="name" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;margin:0;padding:0;" @click="manageCellClicked(props.row.index, 1, props.row)">
                          <div class="row items-center justify-start" style="margin:0 6px;">
                            <div style="font-size:15px">
                              {{ props.row.name }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="id" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;margin:0;padding:0;" @click="manageCellClicked(props.row.index, 2, props.row)">
                          <div class="row items-center justify-start" style="margin:0 6px;">
                            <div style="font-size:15px">
                              {{ props.row.id }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="email" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;margin:0;padding:0;" @click="manageCellClicked(props.row.index, 3, props.row)">
                          <div class="row items-center justify-start" style="margin:0 6px;">
                            <div style="font-size:15px">
                              {{ props.row.email }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="mobile" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;margin:0;padding:0;" @click="manageCellClicked(props.row.index, 4, props.row)">
                          <div class="row items-center justify-start" style="margin:0 6px;">
                            <div style="font-size:15px">
                              {{ props.row.mobile }}
                            </div>
                          </div>
                        </q-td>
                        <q-td key="status" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;margin:0;padding:0;" @click="manageCellClicked(props.row.index, 5, props.row)">
                          <div class="row justify-center items-center" style="margin:0 6px;">
                            <q-icon class="col" color="grey-7" :name='props.row.status ? "check_box" : "check_box_outline_blank"' size="sm"/>
                          </div>
                        </q-td>
                      </q-tr>
                    </template>

                    <template v-slot:bottom>
                      <div class="row items-center justify-end" style="width:100%;">
                        <div style="font-size:14px" v-show="modelRowSelection > 0">{{ modelRowSelection + 1 }}&nbsp;/&nbsp;</div>
                        <div style="font-size:14px">{{ rowData.length }}</div>
                        <!--
                        <q-pagination v-model="curPagination"
                                      :max="0" :max-pages="0"
                                      outline dense
                                      gutter="3px"
                                      size="12px"
                                      color="grey-9"
                                      direction-links
                                      boundary-links
                                      active-design="unelevated"
                                      active-color="red-7"
                                      active-text-color="white"
                                      @update:model-value="itemPageChanged">
                        </q-pagination>
                        -->
                      </div>
                    </template>
                  </q-table>
                  <input style="position:absolute;left:16px;right:16px;top:16px;bottom:16px;" autocomplete="off" maxlength="30" name="id_test" type="text" :placeholder="$t('placeholder.nameorphone')"/>
                </div>
              </div>
            </div>

            <!-- 우편번호 검색 -->
            <div class="col column"
                 style="background-color:white;border-radius: 3px;position:absolute;z-index:99;left:0;right:0;top:0;bottom:0;"
                 v-if="isPostCode">
              <div class="col-auto row justify-between items-center" style="margin:12px">
                <div class="col title">■ 주소 검색</div>
                <q-btn class="col-auto" no-caps outline dense color="grey-7" icon="close"
                      @click="closePostCode()">
                </q-btn>
              </div>
              <q-scroll-area class="col" style="padding:0 12px 12px 12px;">
                <VueDaumPostcode  
                            q=""
                            style="width:100%;height:100%;"
                            :animation="false"
                            :no-shorthand="true"
                            :no-auto-mapping="true"
                            :please-read-guide="3"
                            :please-read-guide-timer="2"
                            :max-suggest-items="5"
                            :show-more-h-name="true"
                            :hide-map-btn="true"
                            :hide-eng-btn="true"
                            :always-show-eng-addr="false"
                            :zonecode-only="true"
                            @complete="onComplete">
                </VueDaumPostcode >
              </q-scroll-area>
            </div>
          </div>

          <div class="col row" style="z-index:10;position:absolute;left:12px;top:12px;right:12px;bottom:12px;" v-show="isAddManager || isEditManager">
            <div class="col row" :style='isLandscape ? "padding:0 0 0 12px" : ""' >
              <!-- 관리자 추가 -->
              <div class="col row" style="background-color:white;border-radius: 3px;">
                <div class="col column">
                  <div class="col-auto row">
                    <div class="col row items-center justify-between">
                      <div class="col-auto title">{{ isEditManager ? $t('group.editManager') : $t('group.addManager') }}</div>
                      <div class="col-auto row justify-end" v-show="!isLandscape">
                        <q-btn class="col" flat dense color="red-5" icon="cancel" @click="changeMode('closeAddMan')">
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                        </q-btn>
                        <q-btn class="col" flat dense color="blue-8" icon="save">
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                        </q-btn>
                      </div>
                    </div>
                  </div>
                  <div class="col" style="position: relative;padding:12px 0">
                    <q-scroll-area class="col" ref="scrAddMaanger" style="width:100%;height:100%;margin:0 12px 0 0;">
                      <table style="width:100%;padding:1px;border-top:2px solid #cccccc;border-collapse: collapse;">
                        <tr>
                          <td class="tableTop tableLabel">{{ $t('group.parentGrp') }}</td>
                          <td class="tableTop">
                            <q-input color="red-7" style="width:70%" dense borderless readonly v-model="infoValues[0]">
                            </q-input>
                          </td>
                        </tr>
                        <tr>
                          <td class="tableTop tableLabel">{{ $t('group.name') }}</td>
                          <td class="tableTop">
                            <q-input color="red-7" style="width:90%" dense borderless readonly v-model="infoValues[1]">
                            </q-input>
                          </td>
                        </tr>
                        <tr>
                          <td class="tableMiddle tableLabel">{{ $t('common.name') }}</td>
                          <td class="tableTop">
                            <q-input color="red-7" style="width:50%" dense v-model="managerValue[0]">
                            </q-input>
                          </td>
                        </tr>
                        <tr>
                          <td class="tableMiddle tableLabel">{{ $t('common.id') }}</td>
                          <td class="tableTop">
                            <div class="row items-center" style="width:100%;height:100%;">
                              <div class="col-auto" v-show="isEditManager" style="font-weight:500;font-size:15px;font-family:Prometo;border-bottom:1px solid black;">&nbsp;{{ managerValue[1] }}&nbsp;</div>
                              <q-input color="red-7" style="width:40%" dense v-model="managerValue[1]" v-show="!isEditManager"/>
                              <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('group.check')"
                                    v-show="!isEditManager"
                                    style="width:76px;height:30px;" @click="checkReplicate()">
                                <q-tooltip style="font-size:14px;">{{ $t('tooltip.checkValidID') }}</q-tooltip>
                              </q-btn>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="tableMiddle tableLabel">{{ $t('newPWDialog.password1') }}</td>
                          <td class="tableTop" >
                            <div class="row items-center" style="width:100%;height:100%;">
                              <q-input color="red-7" style="width:40%" dense
                                      :type="!viewPassword1 ? 'password' : 'text'"
                                      @update:model-value="changePassword1"
                                      v-model="managerValue[2]">
                                <template v-slot:append>
                                  <q-icon :name="viewPassword1 ? 'visibility_off' : 'visibility'"
                                          class="cursor-pointer"
                                          @click="viewPassword1 = !viewPassword1" />
                                </template>
                              </q-input>
                              <div class="col row q-gutter-sm items-center" v-show="managerValue[2].length > 0">
                                <q-icon :color='isValidPW1 ? "green-5" : "red-5"' size="sm" :name='isValidPW1 ? "check_circle" : "cancel"'/>
                                <div class="col" style="font-size:14px;color='#888888'">{{ pw1InvalidMsg }}</div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="tableMiddle tableLabel">{{ $t('newPWDialog.password2') }}</td>
                          <td class="tableTop" >
                            <div class="row items-center" style="width:100%;height:100%;">
                              <q-input color="red-7" style="width:40%" dense
                                      :type="!viewPassword2 ? 'password' : 'text'"
                                      @update:model-value="changePassword2"
                                      v-model="managerValue[3]">
                                <template v-slot:append>
                                  <q-icon :name="viewPassword2 ? 'visibility_off' : 'visibility'"
                                          class="cursor-pointer"
                                          @click="viewPassword2 = !viewPassword2" />
                                </template>
                              </q-input>
                              <div class="col row q-gutter-sm items-center" v-show="managerValue[3].length > 0">
                                <q-icon :color='isValidPW2 ? "green-5" : "red-5"' size="sm" :name='isValidPW2 ? "check_circle" : "cancel"'/>
                                <div class="col" style="font-size:14px;color='#888888'">{{ pw2InvalidMsg }}</div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="tableMiddle tableLabel">E-mail</td>
                          <td class="tableTop row no-wrap justify-start items-end" >
                            <q-input color="red-7" style="width:50%" dense v-model="managerValue[4]"/>
                          </td>
                        </tr>
                        <tr>
                          <td class="tableMiddle tableLabel">{{ $t('group.phone') }}</td>
                          <td class="tableTop">
                            <q-input color="red-7" style="width:50%" dense v-model="managerValue[5]"/>
                          </td>
                        </tr>
                        <tr>
                          <td class="tableMiddle tableLabel">{{ $t('group.authority') }}</td>
                          <td class="tableTop" style="margin-bottom:2px;">
                            <!--
                            <div class="q-gutter-md">
                              <q-radio name="userType" v-model="userType" color="red-7" val="admin" label="관리자" />
                              <q-radio name="userType" v-model="userType" color="green-7" val="user" label="사용자" />
                              <q-radio name="userType" v-model="userType" color="blue-7" val="dealer" label="대리점" />
                            </div>
                            -->
                            <q-select dense options-dense :options="getAuthType" style="width:50%" color="red-7" v-model="userType">

                            </q-select>
                          </td>
                        </tr>
                        <tr height="48px">
                          <td class="tableBottom tableLabel">{{ $t('group.activation') }}</td>
                          <td class="tableBottom" style="margin-bottom:2px;">
                            <q-checkbox dense keep-color v-model="isManagerActivated"
                                        style="font-size:14px;"
                                        :label="isManagerActivated ? $t('common.active') : $t('common.inactive')"
                                        :color='isManagerActivated ? "green-7" : "red-5"'/>
                          </td>
                        </tr>
                      </table>

                      <div class="col-auto row justify-center items-center" style="margin:12px 0">
                        <div class="col-auto row q-gutter-xs justify-center items-center"
                             v-show="isLandscape">
                          <q-btn class="col-auto" no-caps unelevated color="grey-4" text-color="black" :label="$t('common.cancel')"
                                 style="width:100px;height:40px;font-size:15px;font-weight:500;margin-right:6px;"
                                 @click="changeMode('closeAddMan')">
                            <!--
                            <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                            -->
                          </q-btn>
                          <q-btn class="col-auto" no-caps unelevated color="red-7" text-color="white" :label="$t('common.save')"
                                 style="width:100px;height:40px;font-size:15px;font-weight:500;margin-left:6px;"
                                 @click="saveManager()">
                            <!-- 
                            <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                            -->
                          </q-btn>
                        </div>
                      </div>
                    </q-scroll-area>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 그룹 제거 확인용 다이얼로그 -->
    <q-dialog v-model="delGrpDlg" persistent>
      <q-card style="padding:12px 24px 12px 12px;min-width:420px">
        <q-card-section class="row items-center" >
          <div class="col row items-center justify-between">
            <div class="col-auto">
              <q-avatar icon="group_remove" color="red-7" text-color="white" style="margin:0 12px 0 0;"/>
            </div>
            <div class="col column">
              <span class="q-ml-sm" style="font-size:14px;margin:0 0 12px 12px;">{{ dialogMessage }}</span>

              <q-input class="q-ml-sm" color="red-7" :type="hideDelUserPW ? 'password' : 'text'" style="font-size:14px" outlined autofocus dense v-model="pwBeforeDelGrp"
                       :placeholder="$t('placeholder.checkPW4DelGroup')">
                <template v-slot:append>
                  <q-icon :name="hideDelUserPW ? 'visibility_off' : 'visibility'"
                          class="cursor-pointer"
                          @click="hideDelUserPW = !hideDelUserPW"/>
                </template>
              </q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup/>
          <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="deleteGroupConfirmed"/>
        </q-card-actions>
      </q-card>
      <!--
      <q-card style="padding:6px 12px 12px 6px;min-width:320px">
        <q-card-section class="row items-center" style="margin:12px 0">
          <q-avatar icon="group_remove" color="red-7" text-color="white" style="margin:0 12px;"/>
          <span class="q-ml-sm">{{ dialogMessage }}</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup/>
          <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="dialogConfirmed"/>
        </q-card-actions>
      </q-card>
      -->
    </q-dialog>

    <!-- 사용자 제거 확인용 다이얼로그 -->
    <q-dialog v-model="delUserDlg" persistent>
      <q-card style="padding:12px 24px 12px 12px;min-width:420px">
        <q-card-section class="row items-center" >
          <div class="col row items-center justify-between">
            <div class="col-auto">
              <q-avatar icon="person_remove" color="red-7" text-color="white" style="margin:0 12px 0 0;"/>
            </div>
            <div class="col column">
              <span class="q-ml-sm" style="font-size:14px;margin:0 0 12px 12px;">{{ dialogMessage }}</span>

              <q-input class="q-ml-sm" color="red-7" :type="hideDelUserPW ? 'password' : 'text'" style="font-size:14px" outlined autofocus dense v-model="pwBeforeDelUser"
                       :placeholder="$t('placeholder.checkPW4DelAdmin')">
              </q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup/>
          <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="eraseUserConfirmed"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped>
.title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

.treeBorder {
  border-radius: 3px;
  background-color: white;
  margin:3pt 0pt 0 3pt;
}

.treeContext {
  font-size : 14px;
}

.tableTop {
  border-bottom:1px solid #cccccc;
  height:40px;
}
.tableMiddle {
  border-bottom:1px solid #cccccc;
  height:40px;
}
.tableBottom {
  border-bottom:2px solid #cccccc;
  height:40px;
}
.tableLabel {
  font-weight:500;
  width:20%;
  height:40px;
}
.thSticky {
  position: sticky;
  z-index: 3;
  top:0;
  background-color: white;
  font-size:14px;
  font-weight: 500;
}

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'
import { VueDaumPostcode  } from 'vue-daum-postcode'
import { useI18n } from 'vue-i18n'
import TymFncs from '@/js/tymfunctions.js'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'
import TymAws from '@/js/tymaws.js'
import AWS from 'aws-sdk'
import { AuthenticationDetails } from "amazon-cognito-identity-js";
//import { AmazonCognitoIdentity } from "amazon-cognito-identity-js";


export default ({
  components : {
    VueDaumPostcode ,
  },
  data() {
    const i18n = useI18n()
    return {
      validatePassword : ref(/^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,32}$/),
      invalidPassMsg : ref(i18n.t('login.passwordGuide'))      
    }
  },
  setup() {
    const i18n = useI18n()

    return {
      selected: ref('None'),
      isAddGrp : ref(false),
      isEditGrp : ref(false),

      isPostCode : ref(false),

      isAddManager : ref(false),
      isEditManager : ref(false),

      expandedTree : ref([]),
      groupTreeData : ref([]),
      selectedTree: ref(""),
      selectedTreeLabel : ref(""),
      infoValues : ref(['','','','','','','']),

      visibleColumns: ref([ 'group', 'name', 'id', 'email', 'mobile', 'status']),
      userSelected : ref([]),
      columnData : ref([
        { name: 'index', required: false },
        { name: 'group',
          required: true,
          label: i18n.t('common.groupName'),
          align: 'left',
          field: 'group',
          format: val => `${val}`,
          sortable: true
        },
        { name: 'name',
          required: true,
          label: i18n.t('common.name'),
          align: 'left',
          field: 'name',
          format: val => `${val}`,
          sortable: true
        },
        { name: 'id', align: 'left', label: 'ID', field: 'id', sortable: true },
        { name: 'email', align: 'left', label: i18n.t('common.email'), field: 'email', sortable: true },
        { name: 'mobile', align: 'left', label: i18n.t('group.phone'), field: 'mobile', sortable: true },
        { name: 'status', align: 'center', label: i18n.t('common.active'), field: 'status', sortable: false },
      ]),
      rowData : ref([]),

      modelRowSelection : ref(-1),

      filterKeyword : ref(''),
      itemSelected : ref([]),
      curPagination : ref(1),
      maxItemPerPage : ref(0),
      itemPageCount : ref(0),

      managerValue : ref(['', '', '', '', '', '']),
      viewPassword1 : ref(false),
      viewPassword2 : ref(false),
      isValidPW1 : ref(false),
      pw1InvalidMsg : ref(''),
      isValidPW2 : ref(false),
      pw2InvalidMsg : ref(''),
      userType : ref(''),
      isManagerActivated : ref(false),
      dialogConfirm : ref(false),
      isValidUserID : ref(false),

      delGrpDlg : ref(false),
      dialogMessage : ref(''),
      pwBeforeDelGrp : ref(''),

      delUserDlg : ref(false),
      hideDelUserPW : ref(true),
      pwBeforeDelUser : ref('')
    }
  },
  computed:{
    isDev() {
      return TymConst.IS_DEVELOPMENT
    },
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean() {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    isKorea() {
      const store = useTymictStore()
      return (store.connectServer == 0)
    },
    canEditAccount() {
      const store = useTymictStore()

      if(store.isICTUser())
        return true
      else {
        if(store.groupName == 'CCC') {
          return true
        } else {
          if(!TymCommon.isEmpty(store.idToken)) {
            if(!TymCommon.isEmpty(store.idToken["userid"])) {
              if( (store.idToken["userid"].toUpperCase() == 'MYTYMCS01')
                  || (store.idToken["userid"].toUpperCase() == 'CCC123')
                  || (store.idToken["userid"].toUpperCase() == 'JAEYEONKIM') ) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    isICTUser() {
      const store = useTymictStore()
      return ((TymConst.IS_DEVELOPMENT != 0) && store.isICTUser())
    },
    getGroupTitle : function() {
      if(this.isAddGrp)
        return this.$t('group.addGroup')
      else if(this.isEditGrp)
        return this.$t('group.editGroup')

      return this.$t('group.groupInfo')
    },
    getGroupTree : function() {
      const store = useTymictStore()
      return store.groupTree
    },
    // 접속한 서버 종류 구하기...(0-KR, 1-US)
    getServerIndex : function() {
      const store = useTymictStore()
      return store.connectServer
    },
    // 그룹 카테고리 이름 구하기(0~3)
    /* 함수가 잘 동작하지 않음... 왜?
    getGroupCategory() {
      return(grpIndex) => {
        const i18n = useI18n()
        return i18n.t('groupCategory.'+grpIndex)
      }
    },
    */
   getAuthType : function() {
    if(this.isKorean)
      return TymConst.authTypeKR
    else 
      return TymConst.authTypeEN
   },
   getGroupChanged : function() {
    const store = useTymictStore()
    return store.managerList
   },
  },
  watch : {
    getGroupTree : {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal)
        this.groupTreeData = newVal
      },
      immediate : true
    },
    getGroupChanged : {
      handler(newVal, oldVal) {
        this.unusedParam(newVal, oldVal)
        this.postGroupChanged()
      },
      immediate : true
    }
  },

  created() {},
  mounted() {
    const store = useTymictStore()
    if(store.CurrentPage.Now != TymConst.PAGE_GROUP) {
      store.setPage(TymConst.PAGE_GROUP)
    }

    if(this.$refs.searchElement) {
      let native = this.$refs.searchElement.getNativeElement()
      if(native) {
        //console.log(this.$refs.searchElement, native)
        native.autocomplete = "off"
      }
    }

    if(TymConst.IS_DEVELOPMENT) {
      console.log('groups List :', store.groupInfo)
      console.log('groups Tree :', store.groupTree)
    }

    setTimeout(() => {
      if(store.groupTree.length > 0) {
        this.groupTreeData = store.groupTree
        this.expandedTree = [this.groupTreeData[0].uuid]
      }

      let saved = sessionStorage.getItem('GRP.saved')
      if(!TymCommon.isEmpty(saved)) {
        saved = JSON.parse(saved)

        if(!TymCommon.isEmpty(saved.Expanded))
          this.expandedTree = saved.Expanded
        if(!TymCommon.isEmpty(saved.Selected)) {
          this.groupTreeSelected(saved.Selected)
          // this.selectedTree = saved.Selected
        }
        this.filterKeyword = saved.Keyword
        this.modelRowSelection = saved.RowIndex

        sessionStorage.removeItem('GRP.saved')
      }
    }, 5)
  }, 
  unmounted() {
    sessionStorage.setItem('GRP.saved', JSON.stringify({
      Expanded: this.expandedTree,
      Selected: this.selectedTree,
      Keyword: this.filterKeyword,
      RowIndex: this.modelRowSelection
    }))

  },
  methods : {
    unusedParam() {
      //
    },
    managerFilterMethod() {
      if(this.isAddMode || this.isEditMode) {
        // this.itemPageCount = Math.ceil(this.itemListRows.length / this.maxItemPerPage)
        return this.rowData;
      }
      let keyword = this.filterKeyword.toUpperCase()
      let filtered = this.rowData.filter(row => 
          (row.name.toUpperCase().indexOf(keyword) >= 0)
          || (row.mobile.toUpperCase().indexOf(keyword) >= 0)
          || (row.id.indexOf(this.filterKeyword) >= 0))

      // console.log('managerFilterMethod final:', keyword, filtered)

      return filtered
    },
    findNodes(node, key, fullpath, depth) {
      // uuid(Key), label
      // console.log('findNodes START :', key, fullpath, node)
      let orgPath = fullpath

      if(!Array.isArray(node) ) {
        if(node.uuid == key) {
          if(fullpath.length != 0)
            fullpath += ' / '

          fullpath += node.label
          //console.log('findNodes 10:', depth, fullpath, orgPath)

          return fullpath
        }
        for(let i = 0; i < node.children.length; i++) {
          if(fullpath.length != 0)
            fullpath += ' / '

          fullpath += node.label

          //console.log('findNodes 12:', depth, fullpath)
          fullpath = this.findNodes(node.children[i], key, fullpath, depth + 1)
          //console.log('findNodes 13:', orgPath, fullpath)
          if((!TymCommon.isEmpty(fullpath)) && (orgPath != fullpath))
            return fullpath
        }
      } else {
        //console.log('findNodes is array:', key, fullpath)
        for(let j = 0; j < node.length; j++) {
          if(node[j].uuid == key) {
            if(fullpath.length != 0)
              fullpath += ' / '

            fullpath += node[j].label
            //console.log('findNodes 20:', depth, fullpath)

            return fullpath
          }

          for(let i = 0; i < node[j].children.length; i++) {
            //console.log('findNodes 21:', j, i, key)
            if(fullpath.length != 0)
              fullpath += ' / '

            fullpath += node[j].label

            //console.log('findNodes 22:', depth, fullpath)
            fullpath = this.findNodes(node[j].children[i], key, fullpath, depth + 1)
            if(!TymCommon.isEmpty(fullpath))
              return fullpath
          }
        }
      }
      return ''
    },
    // tgt는 식별자
    groupTreeSelected(tgt) {
      const store = useTymictStore()
      
      if(this.delUserDlg) {
        return
      }
      this.modelRowSelection = -1
      this.itemSelected = []
      this.filterKeyword = ''

      if(this.isAddMode || this.isEditMode) {
        //this.infoValues[0] = store.findGroupNameByKey(this.selectedTree)
      } else {
        this.selectedTree = tgt
        this.infoValues = ['', '', '', '', '', '','']
        this.rowData = []
        /*
        "postcode": "54576",
        "address": "전북 익산시 왕궁면 왕궁농공단지길 7-40",
        "phoneno": "01046910551",
        "uuid_d": "1658120567829-domesticsales",
        "uuid_c": "1645678596248-fing0828",
        "uuid_b": "2",
        "uuid_a": "0",
        "default": 0,
        "uuid": "1658120567829-domesticsales",
        "group_c": "테스트지점",
        "group_d": "국내CS팀",
        "group_a": "TYM",
        "address2": "",
        "group_b": "국내영업본부"
        */
        let foundGroup = store.groupInfo.find(x => x.uuid == tgt)
        if(TymCommon.isEmpty(foundGroup)) {
          //console.log('groupTreeSelected.empty...')
          return
        }
        //console.log('groupTreeSelected...', foundGroup)
 
        //let level = 4
        //let grpName = '', parentGrpName = ''
        //let grpType = ''

        let groupInfo = this.getGroupInfo(foundGroup)
        if(groupInfo)
          this.selectedTreeLabel = groupInfo.name
        else
          this.selectedTreeLabel = ''

        // 아래 하단 그룹정보 업데이트
        this.$refs.scrGroupInfo.setScrollPercentage('vertical', 0, 0)
        this.infoValues[0] = groupInfo.parent
        this.infoValues[1] = groupInfo.name
        this.infoValues[2] = groupInfo.type
        this.infoValues[3] = foundGroup.postcode
        this.infoValues[4] = foundGroup.address
        this.infoValues[5] = foundGroup.address2
        this.infoValues[6] = TymCommon.parsePhoneNumber(foundGroup.phoneno)

        this.filterKeyword = ''
        this.rowData = []
        let index = 0
        let grp = ''

        // console.log('store DEPTH', groupInfo.level)
        if(groupInfo.level == 1) {    // 트리 depth
          for(let i = 0; i < store.managerList.length; i++) {
            grp = null
            switch(Number(store.managerList[i]['custom:ulevel'])) {
              case 0:
                grp = store.findGroupByUUID(store.managerList[i]['custom:uuid_a'], null, null, null)
                break;
              case 1:
                grp = store.findGroupByUUID(store.managerList[i]['custom:uuid_a'], store.managerList[i]['custom:uuid_b'], null)
                break;
              case 2:
                grp = store.findGroupByUUID(store.managerList[i]['custom:uuid_a'], store.managerList[i]['custom:uuid_b'], store.managerList[i]['custom:uuid_c'], null)
                break;
              default:
                grp = store.findGroupByUUID(store.managerList[i]['custom:uuid_a'], store.managerList[i]['custom:uuid_b'], store.managerList[i]['custom:uuid_c'], store.managerList[i]['custom:uuid_d'])
                break;
            }

            let enable = TymCommon.isEmpty(store.managerList[i]['custom:uenable']) ? false : (store.managerList[i]['custom:uenable'] == 1)

            this.rowData.push({
              index : index,
              group : (Number(store.managerList[i]['custom:ms']) == 99) ? "TYMICT" : grp,
              name : store.managerList[i].name,
              id : store.managerList[i].userid,
              email : store.managerList[i].email,
              mobile : TymCommon.parsePhoneNumber(store.managerList[i]['custom:uphone'], store.connectServer),
              status : enable
            })
            index++
          }
        } else {
          let grpTYMICT = null
          if(store.connectServer == 0)
            grpTYMICT = store.groupInfo.find(x => x.uuid == '1641201354576-fing0828')
          else 
            grpTYMICT = store.groupInfo.find(x => x.uuid == '99')

          for(let i = 0; i < store.managerList.length; i++) {
            //let grp = ''
            let enable = TymCommon.isEmpty(store.managerList[i]['custom:uenable']) ? false : (store.managerList[i]['custom:uenable'] == 1)
            //let userLevel = Number(store.managerList[i]['custom:ulevel'])
            /*
            switch(userLevel) {
              case 0:
                grp = store.findGroupByUUID(store.managerList[i]['custom:uuid_a'], null, null, null)
                break;
              case 1:
                grp = store.findGroupByUUID(store.managerList[i]['custom:uuid_a'], store.managerList[i]['custom:uuid_b'], null, null)
                break;
              case 2:
                grp = store.findGroupByUUID(store.managerList[i]['custom:uuid_a'], store.managerList[i]['custom:uuid_b'], store.managerList[i]['custom:uuid_c'], null)
                break;
              default:
                grp = store.findGroupByUUID(store.managerList[i]['custom:uuid_a'], store.managerList[i]['custom:uuid_b'], store.managerList[i]['custom:uuid_c'], store.managerList[i]['custom:uuid_d'])
                break;
            }
            */
            //console.log('store ##:', userLevel, grp)

            /*
            foundGroup
            {
              "postcode": "32530",
              "address": "충남 공주시 우성면 차동로 476-30",
              "phoneno": "041-851-7700",
              "uuid_d": "",
              "uuid_c": "",
              "uuid_b": "1641201354576-fing0828",
              "uuid_a": "0",
              "default": 1,
              "uuid": "1641201354576-fing0828",
              "group_c": "",
              "group_d": "",
              "group_a": "TYM",
              "address2": "",
              "group_b": "TYMICT"
            }
            grpTYMICT
            {
              "postcode": "32530",
              "address": "충남 공주시 우성면 차동로 476-30",
              "phoneno": "041-851-7700",
              "uuid_d": "",
              "uuid_c": "",
              "uuid_b": "1641201354576-fing0828",
              "uuid_a": "0",
              "default": 1,
              "uuid": "1641201354576-fing0828",
              "group_c": "",
              "group_d": "",
              "group_a": "TYM",
              "address2": "",
              "group_b": "TYMICT"
            }
            */
            if(groupInfo.level == 2) {
              let isICT = false
              if(store.isICTUser()) {
                isICT = Number(store.managerList[i]['custom:ms']) == 99
                if(!isICT) {
                  if(foundGroup.uuid_b == grpTYMICT.uuid_b) {
                    isICT &= store.managerList[i]['custom:uuid_b'] == foundGroup.uuid_b
                  }
                  else
                    isICT = false
                } else {
                  if(foundGroup.uuid_b== grpTYMICT.uuid_b) {
                    isICT = true;
                  } else {
                    isICT = false;
                  }
                }
              }
              if(isICT) {
                // console.log('ICT :', store.managerList[i])
                this.rowData.push({
                  index : index,
                  group : foundGroup.group_b,
                  name : store.managerList[i].name,
                  id : store.managerList[i].userid,
                  email : store.managerList[i].email,
                  mobile : TymCommon.parsePhoneNumber(store.managerList[i]['custom:uphone'], store.connectServer),
                  status : enable
                })
                index++
              } else if(store.managerList[i]['custom:uuid_b'] == foundGroup.uuid_b) {
                this.rowData.push({
                  index : index,
                  group : foundGroup.group_b,// grp,
                  name : store.managerList[i].name,
                  id : store.managerList[i].userid,
                  email : store.managerList[i].email,
                  mobile : TymCommon.parsePhoneNumber(store.managerList[i]['custom:uphone'], store.connectServer),
                  status : enable
                })
                index++
              }
            } else if((groupInfo.level == 3) && (store.managerList[i]['custom:uuid_c'] == foundGroup.uuid_c)) {
              this.rowData.push({
                index : index,
                group : foundGroup.group_c, // grp,
                name : store.managerList[i].name,
                id : store.managerList[i].userid,
                email : store.managerList[i].email,
                mobile : TymCommon.parsePhoneNumber(store.managerList[i]['custom:uphone'], store.connectServer),
                status : enable
              })
              index++
            } else if((groupInfo.level == 4) && (store.managerList[i]['custom:uuid_d'] == foundGroup.uuid_d)) {
              this.rowData.push({
                index : index,
                group : foundGroup.group_d, // grp,
                name : store.managerList[i].name,
                id : store.managerList[i].userid,
                email : store.managerList[i].email,
                mobile : TymCommon.parsePhoneNumber(store.managerList[i]['custom:uphone'], store.connectServer),
                status : enable
              })
              index++
            }
          }
        }
        
        // console.log('groupTreeSelected final :', this.rowData)
      }
    },
    getGroupInfo(foundGroup) {
      const store = useTymictStore()
      let loginLevel = Number(store.AuthUser.attributes['custom:ulevel'])

      let level = 4
      let grpName = '', parentGrpName = ''
      let grpType = ''
      if(!TymCommon.isEmpty(foundGroup.group_d)) {
        level = 4
        grpName = foundGroup.group_d
        if(loginLevel == 0)
          parentGrpName = foundGroup.group_a + ' / ' + foundGroup.group_b + ' / ' + foundGroup.group_c
        else if(loginLevel == 1)
          parentGrpName = foundGroup.group_b + ' / ' + foundGroup.group_c
        else if(loginLevel == 2)
          parentGrpName = foundGroup.group_c
        grpType = this.$t('groupCategory.3')
      } else if(!TymCommon.isEmpty(foundGroup.group_c)) {
        level = 3
        grpName = foundGroup.group_c
        if(loginLevel == 0)
          parentGrpName = foundGroup.group_a + ' / ' + foundGroup.group_b
        else if(loginLevel == 1)
          parentGrpName = foundGroup.group_b
        grpType = this.$t('groupCategory.2')
      } else if(!TymCommon.isEmpty(foundGroup.group_b)) {
        level = 2
        grpName = foundGroup.group_b
        parentGrpName = foundGroup.group_a
        grpType = this.$t('groupCategory.1')
      } else if(!TymCommon.isEmpty(foundGroup.group_a)) {
        level = 1
        grpName = foundGroup.group_a
        parentGrpName = ''
        grpType = this.$t('groupCategory.0')
      }

      // console.log('groupTreeSelected #1:', loginLevel, level, parentGrpName, grpName)
      return {
        'level' : level,
        'parent' : parentGrpName,
        'name' : grpName,
        'type' : grpType
      }
    },
    manageCellClicked(row, column, data) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('menageCellClicked :', row, column, data)
      }
      // 20240717 활성화 기능을 변경불가로 변경함.
      if(column < 6) {
        if(this.modelRowSelection == row)
          this.modelRowSelection = -1
        else
          this.modelRowSelection = row
        this.itemSelected = []
        this.itemSelected.push(data)
      } else {
        // 활성 비활성
        this.rowData[row].status = !this.rowData[row].status
      }
    },
    closePostCode() {
      this.isPostCode = false
    },
    changeMode(cmd) {
      if(TymCommon.isEmpty(cmd)) {
        this.isPostCode = false
        this.isAddGrp = false
        this.isEditGrp = false
        return
      }
      const store = useTymictStore()

      switch(cmd) {
        case 'addGrp':
          {
            let foundGroup = store.groupInfo.find(x => x.uuid == this.selectedTree)
            if(foundGroup) {
              if(!TymCommon.isEmpty(foundGroup.uuid_d)) {
                TymCommon.Toast('더 이상 조직을 추가할 수 없습니다.')
                return
              }
              // 소속그룹 정리
              this.infoValues[0] = TymCommon.isEmpty(this.infoValues[0]) ? this.infoValues[1] : this.infoValues[0] + ' / ' + this.infoValues[1]

              this.infoValues[1] = ''
              this.infoValues[2] = ''
              this.infoValues[3] = ''
              this.infoValues[4] = ''
              this.infoValues[5] = ''
              this.infoValues[6] = ''

              let grpInfo = this.getGroupInfo(foundGroup)
              let grpNames = [
                this.$t('groupCategory.0'),
                this.$t('groupCategory.1'),
                this.$t('groupCategory.2'),
                this.$t('groupCategory.3')
              ]
              this.infoValues[2] = grpNames[grpInfo.level]
            }
          }

          this.isAddGrp = true
          this.isPostCode = false
          this.$refs.addTooltip.hide()
          break
        case 'editGrp':
          this.isEditGrp = true
          break
        case 'deleteGrp':
          this.deleteGroup()
          break
        case 'cancelGrp':
          this.isPostCode = false
          this.isAddGrp = false
          this.isEditGrp = false
          // 편집, 추가 종료시 현재 선택된 놈을 다시한번 선택된 걸로 하기 위함.
          this.groupTreeSelected(this.selectedTree)
          break
        case 'searchPost':
          this.isPostCode = true
          break;
        case 'closeAddMan':
          this.isAddManager = false
          this.isEditManager = false
          break;
        case 'addMan':
          this.addManager()
          this.isManagerActivated = true
          break;
        case 'editMan':
          this.editManager()
          break;
     }
    },
    // 다음 주소 검색에서 선택했을 
    onComplete(result) {
      /*
      {
        "postcode": "",
        "postcode1": "",
        "postcode2": "",
        "postcodeSeq": "",
        "zonecode": "13496",
        "address": "경기도 성남시 분당구 장미로 42",
        "addressEnglish": "42, Jangmi-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Korea",
        "addressType": "R",
        "bcode": "4113510700",
        "bname": "야탑동",
        "bnameEnglish": "Yatap-dong",
        "bname1": "",
        "bname1English": "",
        "bname2": "야탑동",
        "bname2English": "Yatap-dong",
        "sido": "경기도",
        "sidoEnglish": "Gyeonggi-do",
        "sigungu": "성남시 분당구",
        "sigunguEnglish": "Bundang-gu Seongnam-si",
        "sigunguCode": "41135",
        "userLanguageType": "K",
        "query": "장미로 42",
        "buildingName": "야탑리더스",
        "buildingCode": "4113510700103420001039994",
        "apartment": "N",
        "jibunAddress": "경기도 성남시 분당구 야탑동 342-1",
        "jibunAddressEnglish": "342-1, Yatap-dong, Bundang-gu, Seongnam-si, Gyeonggi-do, Korea",
        "roadAddress": "경기도 성남시 분당구 장미로 42",
        "roadAddressEnglish": "42, Jangmi-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Korea",
        "autoRoadAddress": "",
        "autoRoadAddressEnglish": "",
        "autoJibunAddress": "",
        "autoJibunAddressEnglish": "",
        "userSelectedType": "R",  / "J"
        "noSelected": "N",
        "hname": "야탑1동",
        "roadnameCode": "3180048",
        "roadname": "장미로",
        "roadnameEnglish": "Jangmi-ro"
      }
      */
      this.infoValues[3] = result.zonecode
      if(result.userSelectedType == "R") {
        if(!TymCommon.isEmpty(result.address)) {
          this.infoValues[4] = result.address
          if(!TymCommon.isEmpty(result.buildingName)) {
            this.infoValues[4] += ' (' + result.buildingName + ')'
          }
        }
        else 
          this.infoValues[4] = result.jibunAddress
      } else {
        if(!TymCommon.isEmpty(result.jibunAddress))
          this.infoValues[4] = result.jibunAddress
        else {
          this.infoValues[4] = result.address
          if(!TymCommon.isEmpty(result.buildingName)) {
            this.infoValues[4] += ' (' + result.buildingName + ')'
          }
        }
      }
      this.isPostCode = false
    },
    changePassword1(evt) {
      if(evt.match(this.validatePassword)== null) {
        this.pw1InvalidMsg = this.invalidPassMsg
        this.isValidPW1 = false
      } else {
        this.pw1InvalidMsg = this.$t('common.confirm')
        this.isValidPW1 = true
      }
    },
    changePassword2(evt) {
      if(evt.match(this.validatePassword)== null) {
        this.pw2InvalidMsg = this.invalidPassMsg
        this.isValidPW2 = false
      } else {
        this.pw2InvalidMsg = this.$t('common.confirm')
        this.isValidPW2 = true
      }
    },
    findGroupByName(node, name) {
      let result = false

      for(let idx = 0; idx < node.length; idx++) {
        if(node[idx].label == name) {
          result = true
          break
        } else {
          // 
          if(result)
            break
          if(node[idx].children.length > 0 )
            result |= this.findGroupByName(node[idx].children, name)
        }
      }
      return result
    },
    saveGroup() {
      const store = useTymictStore()

      if(TymCommon.isEmpty(this.infoValues[1])) {
        TymCommon.Toast(this.$t('group.enterGroupName'))
        return
      }

      if(this.isAddGrp)  {
        if(store.findGroupByName(this.infoValues[1])) {
          TymCommon.Toast(this.$t('group.usedGroupName'))
          return
        }
      } else if(this.isEditGrp) {
        /*
        let found = this.findGroupByName(store.groupTree, this.infoValues[1])
        if(TymConst.IS_DEVELOPMENT) {
          console.log('개발중!!!!', found)
          return
        }
        */
        if(this.infoValues[1].localeCompare(this.selectedTreeLabel) != 0) {
          if(store.findGroupByName(this.infoValues[1])) {
            console.log(this.infoValues[1], store.groupInfo)
            TymCommon.Toast(this.$t('group.usedGroupName'))
            return
          }
        }
      }

      if(this.isKorea) {
        if(TymCommon.isEmpty(this.infoValues[3]) || TymCommon.isEmpty(this.infoValues[4])) {
          TymCommon.Toast(this.$t('group.enterAddress'))
          return
        }
      }
      if(TymCommon.isEmpty(this.infoValues[6])) {
        TymCommon.Toast(this.$t('group.enterMobile'))
        return
      }

      let jsonData = { 
        "uuid": '',
        "default": 0,
        "postcode": '',
        "address": '',
        "address2": '',
        "group_a": '',
        "group_b": '',
        "group_c": '',
        "group_d": '',
        "uuid_a": '',
        "uuid_b": '',
        "uuid_c": '',
        "uuid_d": '',
        "phoneno": ''
      }
      let foundGroup = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(foundGroup) {
        // let newUUID = TymFncs.getMyUUID()
        //console.log('saveGroup() after add', foundGroup, newUUID)
        if(this.isAddGrp) {
          // 그룹 추가
          if(!TymCommon.isEmpty(foundGroup.group_c)) {
            jsonData.group_a = foundGroup.group_a
            jsonData.group_b = foundGroup.group_b
            jsonData.group_c = foundGroup.group_c
            jsonData.group_d = this.infoValues[1]
            jsonData.uuid_a = foundGroup.uuid_a
            jsonData.uuid_b = foundGroup.uuid_b
            jsonData.uuid_c = foundGroup.uuid_c
            jsonData.uuid = jsonData.uuid_d = TymFncs.getMyUUID()
            jsonData.postcode = this.infoValues[3]
            jsonData.address = this.infoValues[4]
            jsonData.address2 = this.infoValues[5]
            jsonData.phoneno = this.infoValues[6]
          } else if(!TymCommon.isEmpty(foundGroup.group_b)) {
            jsonData.group_a = foundGroup.group_a
            jsonData.group_b = foundGroup.group_b
            jsonData.group_c = this.infoValues[1]
            jsonData.uuid_a = foundGroup.uuid_a
            jsonData.uuid_b = foundGroup.uuid_b

            jsonData.uuid = jsonData.uuid_c = TymFncs.getMyUUID();
            jsonData.postcode = this.infoValues[3]
            jsonData.address = this.infoValues[4]
            jsonData.address2 = this.infoValues[5]
            jsonData.phoneno = this.infoValues[6]
          } else {
            jsonData.group_a = foundGroup.group_a
            jsonData.group_b = this.infoValues[1]
            jsonData.uuid_a = foundGroup.uuid_a

            jsonData.uuid = jsonData.uuid_b = TymFncs.getMyUUID();
            jsonData.postcode = this.infoValues[3]
            jsonData.address = this.infoValues[4]
            jsonData.address2 = this.infoValues[5]
            jsonData.phoneno = this.infoValues[6]
          }
          //console.log('Add Group :', jsonData)

          TymAws.updateGroupInfo(jsonData, true)
            .then(data=>{
              if(TymConst.IS_DEVELOPMENT) {
                console.log('updateGroupInfo OK :', data)
              }
              this.isAddGrp = false
              this.isEditGrp = false
              this.isPostCode = false
              this.infoValues = ['','','','','','','']

              store.loadGroupInfo()
            })
            .catch(er => {
              console.log('updateGroupInfo FAIL :', er)
            })
        } else if(this.isEditGrp) {
          let grpDepth = 0
          let grpUUID = null
          if(!TymCommon.isEmpty(foundGroup.uuid_d)) {
            jsonData.group_a = foundGroup.group_a
            jsonData.group_b = foundGroup.group_b
            jsonData.group_c = foundGroup.group_c
            jsonData.group_d = this.infoValues[1]
            jsonData.uuid_a = foundGroup.uuid_a
            jsonData.uuid_b = foundGroup.uuid_b
            jsonData.uuid_c = foundGroup.uuid_c
            jsonData.uuid_d = foundGroup.uuid_d
            jsonData.uuid = jsonData.uuid_d
            jsonData.postcode = this.infoValues[3]
            jsonData.address = this.infoValues[4]
            jsonData.address2 = this.infoValues[5]
            jsonData.phoneno = this.infoValues[6]
            grpUUID = foundGroup.uuid_d
            grpDepth = 4
          } else if(!TymCommon.isEmpty(foundGroup.group_c)) {
            jsonData.group_a = foundGroup.group_a
            jsonData.group_b = foundGroup.group_b
            jsonData.group_c = this.infoValues[1]
            jsonData.uuid_a = foundGroup.uuid_a
            jsonData.uuid_b = foundGroup.uuid_b
            jsonData.uuid_c = foundGroup.uuid_c
            jsonData.uuid = jsonData.uuid_c
            jsonData.postcode = this.infoValues[3]
            jsonData.address = this.infoValues[4]
            jsonData.address2 = this.infoValues[5]
            jsonData.phoneno = this.infoValues[6]
            grpUUID = foundGroup.uuid_c
            grpDepth = 3
          } else if(!TymCommon.isEmpty(foundGroup.group_b)) {
            jsonData.group_a = foundGroup.group_a
            jsonData.group_b = this.infoValues[1]
            jsonData.uuid_a = foundGroup.uuid_a
            jsonData.uuid_b = foundGroup.uuid_b
            jsonData.uuid = jsonData.uuid_b
            jsonData.postcode = this.infoValues[3]
            jsonData.address = this.infoValues[4]
            jsonData.address2 = this.infoValues[5]
            jsonData.phoneno = this.infoValues[6]
            grpUUID = foundGroup.uuid_b
            grpDepth = 2
          } else if(!TymCommon.isEmpty(foundGroup.group_a)) {
            jsonData.group_a = this.infoValues[1]
            jsonData.uuid_a = foundGroup.uuid_a
            jsonData.uuid = jsonData.uuid_a
            jsonData.postcode = this.infoValues[3]
            jsonData.address = this.infoValues[4]
            jsonData.address2 = this.infoValues[5]
            jsonData.phoneno = this.infoValues[6]
            grpUUID = foundGroup.uuid_a
            grpDepth = 1
          }
          jsonData.default = foundGroup.default

          let founds = null
          switch(grpDepth) {
            case 1:
              founds = store.groupInfo.filter(x => x.uuid_a == grpUUID)
              break
            case 2:
              founds = store.groupInfo.filter(x => x.uuid_b == grpUUID)
              break
            case 3:
              founds = store.groupInfo.filter(x => x.uuid_c == grpUUID)
              break
            case 4:
              founds = store.groupInfo.filter(x => x.uuid_d == grpUUID)
              break
          }
          if(!TymCommon.isEmpty(founds)) {
            let changeItems = []
            for(let idx = 0; idx < founds.length; idx++) {
              if((foundGroup.uuid_a != founds[idx].uuid_a) || (foundGroup.uuid_b != founds[idx].uuid_b) || (foundGroup.uuid_c != founds[idx].uuid_c) || (foundGroup.uuid_d != founds[idx].uuid_d)) {
                changeItems.push(founds[idx])
              }
            }
            
            let updateInfo = []
            changeItems.forEach(item => {
              /*
              let foundUuid = ''
              switch(grpDepth) {
                case 1:
                  foundUuid = item.uuid_a
                  break;
                case 2:
                  foundUuid = item.uuid_b
                  break
                case 3:
                  foundUuid = item.uuid_c
                  break
                case 4:
                  foundUuid = item.uuid_d
                  break
              }
              */
              updateInfo.push({
                uuid: item.uuid,
                default: item.default,
                name: this.infoValues[1]
              })
            })

            TymAws.updateGroupName(grpDepth, updateInfo, this.updateGroupNameCompleted)
          }

          TymAws.updateGroupInfo(jsonData, false)
            .then(data=>{
              if(TymConst.IS_DEVELOPMENT) {
                console.log('updateGroupInfo OK :', data)
              }
              this.isAddGrp = false
              this.isEditGrp = false
              this.infoValues = ['','','','','','','']
              this.selectedTree = ''
              this.selectedTreeLabel = ''

              setTimeout(() => {
                store.loadGroupInfo()
              }, 500)
            })
            .catch(er => {
              console.log('updateGroupInfo FAIL :', er)
            })
        }
      }
    },
    updateGroupNameCompleted(isOK, data, result) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('updateGroupNameCompleted :', isOK, data, result)
      }
    },
    checkReplicate() {
      if(TymCommon.isEmpty(this.managerValue[1])) {
        TymCommon.Toast(this.$t('login.idForReset'), true)
        return
      }

      let userid = this.managerValue[1].trim()
      TymAws.isUserExist(userid, true)
        .then(data => {
          this.isValidUserID = (data != true)
          if(!this.isValidUserID) {
            TymCommon.Toast(this.$t('group.usedID'), true)
            setTimeout(() => {
              this.managerValue[1] = ''
            }, 25)
          } else {
            TymCommon.Toast(this.$t('group.usableID'), false)
          }
        })
        .catch(() => {
          //console.log(err)
          this.isValidUserID = true
          TymCommon.Toast(this.$t('group.usableID'), false)
        })
    },
    addManager() {
      this.managerValue = ['', '', '', '', '', '']

      this.isValidUserID = false
      this.isAddManager = true
      this.userType = this.getAuthType[0]

      const store = useTymictStore()
      let foundGroup = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(foundGroup) {
        let level = 0
        let filters = null
        if(!TymCommon.isEmpty(foundGroup.group_d)) {
          level = 4
        } else if(!TymCommon.isEmpty(foundGroup.group_c)) {
          level = 3
          filters = store.groupInfo.filter(x => x.uuid_c == foundGroup.uuid_c)
        } else if(!TymCommon.isEmpty(foundGroup.group_b)) {
          level = 2
          filters = store.groupInfo.filter(x => x.uuid_b == foundGroup.uuid_b)
        }
        else if(!TymCommon.isEmpty(foundGroup.group_a)) {
          level = 1
          filters = store.groupInfo.filter(x => x.uuid_a == foundGroup.uuid_a)
        }

        let hasChildren = true
        if(filters == null) {
          hasChildren = false 
        } else {
          hasChildren = true
          if(level == 4 && filters.length == 1 && foundGroup.group_d == filters[0].group_d) {
            hasChildren = false
          } else if(level == 3 && filters.length == 1 && foundGroup.group_c == filters[0].group_c) {
            hasChildren = false
          } else if(level == 2 && filters.length == 1 && foundGroup.group_b == filters[0].group_b) {
            hasChildren = false
          } else if(level == 1 && filters.length == 1 && foundGroup.group_a == filters[0].group_a) {
            hasChildren = false
          }
        }

        if(level > 0) {
          if(level == 1) {      // Root 관리자
            this.userType = this.getAuthType[0]
          } else if(level == 4) {   // 맨 끝 = 대리점
            this.userType = this.getAuthType[1]
          } else {
            if(level == 2) {
              if(store.connectServer == 0) { // 한국서버
                switch(foundGroup.group_b) {
                  case 'CCC':
                    this.userType = this.getAuthType[9]
                    break;
                  case '국내C/S':
                    this.userType = this.getAuthType[3]
                    break;
                  case '국내영업본부':
                    this.userType = this.getAuthType[7]
                    break;
                  case '기계생산본부':
                    this.userType = this.getAuthType[4]
                    break;
                  case '본사':
                    this.userType = this.getAuthType[8]
                    break;
                  case '중앙기술연구소':
                    this.userType = this.getAuthType[5]
                    break;
                  case '품질보증':
                    this.userType = this.getAuthType[8]
                    break;
                  case '해외영업본부':
                    this.userType = this.getAuthType[6]
                    break;
                }
              } else {
                // 미국 서버
                // const authTypeEN = ['Administrator', 'Branch Office', 'State', 'Agency']
                this.userType = this.getAuthType[1]
              }
            } else {
              if(hasChildren) {
                // console.log('addManager hasChild :', foundGroup)
                this.userType = this.getAuthType[2]
              } else {
                // console.log('addManager Last Node :', foundGroup)
                if(level == 3)
                  this.userType = this.getAuthType[2]
                else
                  this.userType = this.getAuthType[1]
              }
            }
          }
        }
      }
    },
    editManager() {
      if(this.itemSelected.length != 1) {
        return
      }
      console.log('editManager', this.itemSelected[0])
      this.managerValue = [this.itemSelected[0].name, this.itemSelected[0].id, "", "", this.itemSelected[0].email, this.itemSelected[0].mobile]

      this.isEditManager = true

      this.isValidUserID = false
      this.userType = this.getAuthType[0]
      this.isManagerActivated = this.itemSelected[0].status

      const store = useTymictStore()
      let foundGroup = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(foundGroup) {
        let level = 0
        let filters = null
        if(!TymCommon.isEmpty(foundGroup.group_d)) {
          level = 4
        } else if(!TymCommon.isEmpty(foundGroup.group_c)) {
          level = 3
          filters = store.groupInfo.filter(x => x.uuid_c == foundGroup.uuid_c)
        } else if(!TymCommon.isEmpty(foundGroup.group_b)) {
          level = 2
          filters = store.groupInfo.filter(x => x.uuid_b == foundGroup.uuid_b)
        }
        else if(!TymCommon.isEmpty(foundGroup.group_a)) {
          level = 1
          filters = store.groupInfo.filter(x => x.uuid_a == foundGroup.uuid_a)
        }

        let hasChildren = true
        if(filters == null) {
          hasChildren = false 
        } else {
          hasChildren = true
          if(level == 4 && filters.length == 1 && foundGroup.group_d == filters[0].group_d) {
            hasChildren = false
          } else if(level == 3 && filters.length == 1 && foundGroup.group_c == filters[0].group_c) {
            hasChildren = false
          } else if(level == 2 && filters.length == 1 && foundGroup.group_b == filters[0].group_b) {
            hasChildren = false
          } else if(level == 1 && filters.length == 1 && foundGroup.group_a == filters[0].group_a) {
            hasChildren = false
          }
        }

        if(level > 0) {
          if(level == 1) {      // Root 관리자
            this.userType = this.getAuthType[0]
          } else if(level == 4) {   // 맨 끝 = 대리점
            this.userType = this.getAuthType[1]
          } else {
            if(level == 2) {
              if(store.connectServer == 0) { // 한국서버
                switch(foundGroup.group_b) {
                  case 'CCC':
                    this.userType = this.getAuthType[9]
                    break;
                  case '국내C/S':
                    this.userType = this.getAuthType[3]
                    break;
                  case '국내영업본부':
                    this.userType = this.getAuthType[7]
                    break;
                  case '기계생산본부':
                    this.userType = this.getAuthType[4]
                    break;
                  case '본사':
                    this.userType = this.getAuthType[8]
                    break;
                  case '중앙기술연구소':
                    this.userType = this.getAuthType[5]
                    break;
                  case '품질보증':
                    this.userType = this.getAuthType[8]
                    break;
                  case '해외영업본부':
                    this.userType = this.getAuthType[6]
                    break;
                }
              } else {
                // 미국 서버
                // const authTypeEN = ['Administrator', 'Branch Office', 'State', 'Agency']
                this.userType = this.getAuthType[1]
              }
            } else {
              if(hasChildren) {
                // console.log('addManager hasChild :', foundGroup)
                this.userType = this.getAuthType[2]
              } else {
                // console.log('addManager Last Node :', foundGroup)
                if(level == 3)
                  this.userType = this.getAuthType[2]
                else
                  this.userType = this.getAuthType[1]
              }
            }
          }
        }
      }
    },
    saveManager() {
      if(TymConst.IS_DEVELOPMENT) {
        if(this.isAddManager) {
          console.log('관리자 추가 모드 - 저장')
        }
        if(this.isEditManager) {
          console.log('관리자 수정 모드 - 저장')
        }
      }
      let changePw = true

      if(this.isAddManager) {
        if(!this.isValidUserID) {
          TymCommon.Toast(this.$t('warning.checkid'), true)
          return
        }
      }
      if(TymCommon.isEmpty(this.managerValue[0])) {
        TymCommon.Toast(this.$t('placeholder.inputYourName'), true)
        return
      }
      if(this.isAddManager) {
        if(TymCommon.isEmpty(this.managerValue[2]) || TymCommon.isEmpty(this.managerValue[3])) {
          TymCommon.Toast(this.$t('placeholder.inputPassword'), true)
          return
        }
        if(this.managerValue[2] != this.managerValue[3]) {
          TymCommon.Toast(this.$t('warning.differentpw'), true)
          return
        }
      }
      if(this.isEditManager) {
        changePw = false
        if(!TymCommon.isEmpty(this.managerValue[2]) || !TymCommon.isEmpty(this.managerValue[3])) {
          if(this.managerValue[2] != this.managerValue[3]) {
            TymCommon.Toast(this.$t('warning.differentpw'), true)
            return
          }
          changePw = true
        }
      }

      const store = useTymictStore()
      let foundGroup = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(TymConst.IS_DEVELOPMENT) {
        console.log('saveManager :', foundGroup, store.tymictGroup)
      }
      let authIndex = TymCommon.getAuthIndex(this.userType)

      if(!TymCommon.isEmpty(store.tymictGroup)) {
        if((foundGroup.group_a == store.tymictGroup.group_a) && (foundGroup.group_b == store.tymictGroup.group_b)) {
          authIndex = 99
        }
      }
      let ulevel = String(TymCommon.getUserleveFromAuthIndex(authIndex))

      let uuid_a, uuid_b, uuid_c, uuid_d
      uuid_a = uuid_b = uuid_c = uuid_d = ''
      let uphone = this.managerValue[5]
      let uenable = this.isManagerActivated ? '1' : '0'
      let userid = this.managerValue[1]
      let email = this.managerValue[4]
      let username = this.managerValue[0]

      let grpDepth = 3
      if(!TymCommon.isEmpty(foundGroup.uuid_d)) {
        grpDepth = 3
      } else if(!TymCommon.isEmpty(foundGroup.uuid_c)) {
        grpDepth = 2
      } else if(!TymCommon.isEmpty(foundGroup.uuid_b)) {
        grpDepth = 1
      } else {
        grpDepth = 0
      }
      if(TymConst.IS_DEVELOPMENT) {
        console.log('grpDepth, userLvl:', grpDepth, ulevel, authIndex)
      }

      switch(grpDepth) {
        case 0:
            uuid_a = foundGroup.uuid_a;
            break;
        case 1:
            uuid_a = foundGroup.uuid_a;
            uuid_b = foundGroup.uuid_b;
            break;
        case 2:
            uuid_a = foundGroup.uuid_a;
            uuid_b = foundGroup.uuid_b;
            uuid_c = foundGroup.uuid_c;
            break;
        case 3:
            uuid_a = foundGroup.uuid_a;
            uuid_b = foundGroup.uuid_b;
            uuid_c = foundGroup.uuid_c;
            uuid_d = foundGroup.uuid_d;
            break;
        }

      // 저장해보자.
      let param = {
        UserPoolId: store.cognito[store.connectServer].userPoolId,
        Username: this.managerValue[1],   // ID
        UserAttributes: [
          {
            Name : 'name',
            Value : this.managerValue[0]
          },
          {
            Name : 'custom:uuid_a', 
            Value : uuid_a,
          },
          {
            Name : 'custom:uuid_b', 
            Value : uuid_b,
          },
          {
            Name : 'custom:uuid_c', 
            Value : uuid_c,
          },
          {
            Name : 'custom:uuid_d', 
            Value : uuid_d,
          },
          {
            Name : 'custom:uphone', 
            Value : uphone
          },
          {
            Name : 'custom:ulevel', 
            Value : ulevel,
          },
          {
            Name : 'custom:uenable', 
            Value : uenable
          },
          {
            Name : 'custom:ms',
            Value : String(authIndex)
          }
        ],
      }
      if(this.isAddManager) {
        param.DesiredDeliveryMediums = []
        param.MessageAction = 'SUPPRESS'
      }
      if(!TymCommon.isEmpty(this.managerValue[4])) {
        // email
        param.UserAttributes.push({
          Name : 'email',
          Value : this.managerValue[4]
        })
        param.UserAttributes.push({
          Name : 'email_verified',
          Value : 'true'
        })
      }
      var paramvalue = {
        UserPoolId: store.cognito[store.connectServer].userPoolId,
        Username: this.managerValue[1]   // ID
      }

      let self = this

      console.log(store.managerList)
      function createUserFunc(err, data) {
        if (err) {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('CreateUser Callback :', err, err.code); // an error occurred
          }
          if(err.code == 'UsernameExistsException') {
            TymCommon.Toast(self.$t('group.usedID'), true)
          } else if(err.code == 'InvalidParameterException') {
            TymCommon.Toast(self.$t('group.invalidInput'), true)
          }
        } else {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('User Create OK : ', data, self.managerValue)
          }

          if(changePw == true) {
            //console.log('userPassword :', addedParam)

            var addedParam = {
              UserPoolId : store.cognito[store.connectServer].userPoolId,
              Username : self.managerValue[1],   // ID
              Password : self.managerValue[2],   // PW
              Permanent: true
            }

            cognitoidentityserviceprovider.adminSetUserPassword(addedParam, function(pwerr, pwdata) {
              if(pwerr) {
                console.log(pwerr, pwerr.code); // an error occurred
                if(pwerr.code == 'InvalidPasswordException' || pwerr.code == 'InvalidParameterException') {
                  //tym_alert_yesno(i18n.t('code.ERR_INVALID_PW_LENGTH'), 1);
                  //패스워드 양식오류
                }
                // password에러
                cognitoidentityserviceprovider.adminDeleteUser(paramvalue, function(err, data) {
                  if (err) {
                    console.log(err, err.stack); // an error occurred
                  } else {
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log(data)
                    }
                  }
                });
              } else {
                if(TymConst.IS_DEVELOPMENT) {
                  console.log(pwdata)
                }
                TymAws.SetAdminHistory(userid, 1)
                      
                const dbParam = {
                  'username'  : param.Username,
                  'email' : email,
                  'name'  : username, 
                  'custom:uuid_a' : uuid_a,
                  'custom:uuid_b' : uuid_b,
                  'custom:uuid_c' : uuid_c,
                  'custom:uuid_d' : uuid_d,
                  'custom:uphone' : uphone,
                  'custom:ulevel' : ulevel,
                  'custom:uenable' : uenable,
                  'custom:ms' : String(authIndex)
                }
                if(TymConst.IS_DEVELOPMENT) {
                  console.log(param.UserAttributes);
                }
                var tpayload = JSON.stringify({"PARAMS" : dbParam});
                const lambdaParam = {
                  FunctionName : 'tymict_webmanager_addadmin',
                  InvocationType : 'RequestResponse',
                  Payload: tpayload
                };

                TymAws.changeManagerActivation(self.managerValue[1], uenable == '1', function(result, data) {
                  console.log('----> ', result, data)
                })
                    
                const templambda = new AWS.Lambda({
                  region: store.lambda[store.connectServer].region,
                  apiVersion: store.lambda[store.connectServer].apiVersion
                })

                try {
                  new Promise((resolve, reject)=>{
                    templambda.invoke(lambdaParam, function(err, data) {
                      if (err) {
                        // console.log('addAdmin error :', JSON.stringify(err, null, 2));
                        reject('error')
                      } else if (data == null) {
                        reject('error')
                      } else {
                        if(TymConst.IS_DEVELOPMENT) {
                          console.log("addAdmin OK", data)
                        }
                        setTimeout(() => {
                          addManagerCompleted()
                        }, 500)
                        resolve(data)
                      } 
                    })
                  })
                } catch(exception) {
                  console.log(exception)
                }  
              }   
            });
          } else {
              //tym_alert_yesno(i18n.t('code.TEXT_SUCCESS_EDITUSERINFO'), 1);
              //DeleteAllGrid();
              //$('#subpage_home').load('manage_customer.html'); 
              //
              //document.getElementById('manager_enduser_deletecancle_edit').click();
          }
        }
      }

      function addManagerCompleted() {
        const store = useTymictStore()
        store.getManagerAndUser(false)
        if(TymConst.IS_DEVELOPMENT) {
          console.log('addManagerCompleted()')
        }

        setTimeout(()=>{
          self.groupTreeSelected(self.selectedTree)
          self.isAddManager = false
          self.isEditManager = false
        }, 10)
      }

      let cognitoidentityserviceprovider  =  new AWS.CognitoIdentityServiceProvider()
      if(this.isAddManager) {
        cognitoidentityserviceprovider.adminCreateUser(param, createUserFunc)
      }

      function updateUserFunc(err, data) {
        if(TymConst.IS_DEVELOPMENT) {
          console.log('UpdateUser Callback :', err, data)
        }
        if (err) {
          if(err.code == 'UsernameExistsException') {
            TymCommon.Toast(self.$t('group.usedID'), true)
          } else if(err.code == 'InvalidParameterException') {
            TymCommon.Toast(self.$t('group.invalidInput'), true)
          }
        } else {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('User Update OK : ', changePw, self.managerValue)
          }

          if(changePw == true) {
            var addedParam = {
              UserPoolId : store.cognito[store.connectServer].userPoolId,
              Username : self.managerValue[1],   // ID
              Password : self.managerValue[2],   // PW
              Permanent : true,
            }

            cognitoidentityserviceprovider.adminSetUserPassword(addedParam, function(pwerr, pwdata) {
              if(pwerr) {
                console.log(pwerr, pwerr.code); // an error occurred
                if(pwerr.code == 'InvalidPasswordException' || pwerr.code == 'InvalidParameterException') {
                  //tym_alert_yesno(i18n.t('code.ERR_INVALID_PW_LENGTH'), 1);
                  //패스워드 양식오류
                }    
              } else {
                if(TymConst.IS_DEVELOPMENT) {
                  console.log(pwdata)
                }
                TymAws.SetAdminHistory(userid, 1)

                udpateManagerInfo()
              }   
            });
          } else {
            udpateManagerInfo()
          }
          setTimeout(() => {
            addManagerCompleted()
          }, 500)
        }
      }

      if(this.isEditManager) {
        console.log('Update manager :', param)
        cognitoidentityserviceprovider.adminUpdateUserAttributes(param, updateUserFunc)
      }

      function udpateManagerInfo() {
        const dbParam = {
          'username'  : param.Username,
          'email' : email,
          'name'  : username, 
          'custom:uuid_a' : uuid_a,
          'custom:uuid_b' : uuid_b,
          'custom:uuid_c' : uuid_c,
          'custom:uuid_d' : uuid_d,
          'custom:uphone' : uphone,
          'custom:ulevel' : ulevel,
          'custom:uenable' : uenable,
          'custom:ms' : String(authIndex)
        }
        if(TymConst.IS_DEVELOPMENT) {
          console.log('udpateManagerInfo :', param.UserAttributes);
        }

        TymAws.changeManagerActivation(self.managerValue[1], uenable == '1', function(result, data) {
          console.log('====> ', result, data)
        })

        var tpayload = JSON.stringify({"PARAMS" : dbParam});
        const lambdaParam = {
          FunctionName : 'tymict_webmanager_addadmin',
          InvocationType : 'RequestResponse',
          Payload: tpayload
        };  
            
        const templambda = new AWS.Lambda({
          region: store.lambda[store.connectServer].region,
          apiVersion: store.lambda[store.connectServer].apiVersion
        })

        try {
          new Promise((resolve, reject)=>{
            templambda.invoke(lambdaParam, function(err, data) {
              if (err) {
                console.log('editAdmin error :', JSON.stringify(err, null, 2));
                reject('error')
              } else if (data == null) {
                reject('error')
              } else {
                if(TymConst.IS_DEVELOPMENT) {
                  console.log("addAdmin OK", data)
                }
                resolve(data)
              } 
            })
          })
        } catch(exception) {
          console.log(exception)
        }  
      }
    },

    eraseManager() {
      if(this.modelRowSelection < 0) {
        return
      }
      this.hideDelUserPW = true
      this.pwBeforeDelUser = ''
      this.delUserDlg = true

      const store = useTymictStore()
      let msg = ''
      if(Number(store.connectServer) == 0) {
        if(!TymCommon.isEmpty(this.rowData[this.modelRowSelection].name)) {
          msg = this.rowData[this.modelRowSelection].name + ' '
        }
        msg += this.$t('group.questionDeleteAdmin')
      } else {
        msg = this.$t('group.questionDeleteAdmin')
        if(!TymCommon.isEmpty(this.rowData[this.modelRowSelection].name)) {
          msg += ' ' + this.rowData[this.modelRowSelection].name + ' '
        }
      }
      msg += '?'

      this.dialogMessage = msg
    },
    eraseUserConfirmed() {
      const store = useTymictStore()
      let userInfo = this.rowData[this.modelRowSelection]
      let authenticationData = {
        Username : true, 
        Password : this.pwBeforeDelUser,
      }

      if(TymConst.IS_DEVELOPMENT) {
//        console.log('eraseUserConfirmed :', this.selectedTreeLabel)
      }
      
      let self = this
      store.AuthUser.getSession(function(err, session) {
        if (err) {
            TymCommon.Toast(err)
            return;
        }
        self.unusedParam(session)
        // console.log(session)
        // 내정보 구하기
        store.AuthUser.getUserAttributes(function(err, result) {
          if (err) {
            console.log(err)
            TymCommon.Toast(err)
            return;
          }
          //console.log(result);
          let current_user = result[2].getValue();	// 활성화 여부
          authenticationData.Username = current_user
          // console.log(authenticationData);

          let self2 = self
          // 내 계정 암호 일치했냐?
          store.AuthUser.authenticateUser(new AuthenticationDetails(authenticationData), { 
            onSuccess: function (result) {
              self2.unusedParam(result)
              // console.log(result)
              let self3 = self2
              TymAws.requestDeleteuser(userInfo.id)
                .then(data => {
                  // console.log('Erase user completed...', data)
                  self3.unusedParam(data)
                  store.getManagerAndUser(false)
                  TymAws.lookup(TymConst.ACTION_DEL_USER, userInfo.id, "관리자 계정")
                })
                .catch(error => {
                  console.log('Erase user FAILED...', error)
                })
            },
            onFailure: function(err) {
              console.log('authenticateUser.onFailure :', err.code, err.message)
              if(err.code == 'NotAuthorizedException') {
                TymCommon.Toast(self2.$t('noticeView.deleteNoticeMsg2'))
              } else {
                TymCommon.Toast(err.message)
              }
            }
          })
        })
      })
    },
    postGroupChanged() {
      // console.log('postGroupChanged')
      this.groupTreeSelected(this.selectedTree)
    },
    search(v) {
      console.log('search :', v)
    },
    selectedAuto(v) {
      console.log('selectedAuto :', v)
    },
    clearSearch(value) {
      console.log('clearSearch :', value)
    },
    confirm (titleText, msg, okfunction) {
      this.unusedParam(msg, okfunction)
      this.dialogMessage = titleText
      this.dialogConfirm = true
    },
    callbackDeleateGroup(isOK, result) {
      TymAws.lookup('그룹 삭제 ' + isOK ? "성공" : "실패", this.selectedTreeLabel)
      const store = useTymictStore()

      console.log('callbackDeleateGroup :', this.selectedTreeLabel, isOK ? '성공' : '실패')

      if(isOK) {
        store.loadGroupInfo()
        this.selectedTree = ''
        this.infoValues[0] = ''
        this.infoValues[1] = ''
        this.infoValues[2] = ''
        this.infoValues[3] = ''
        this.infoValues[4] = ''
        this.infoValues[5] = ''
        this.infoValues[6] = ''
      } else {
        console.log('delete group failed...', result)
      }
    },
    checkPasswordOK() {
      const store = useTymictStore()

      let foundGroup = store.groupInfo.find(x => x.uuid == this.selectedTree)

      if(foundGroup) {
        let hasManager = false
        if(!TymCommon.isEmpty(foundGroup.uuid_d)) {
          if(store.managerList.find(x => x['custom:uuid_d'] == foundGroup.uuid_d))
            hasManager = true
        } else if(!TymCommon.isEmpty(foundGroup.uuid_c)) {
          if(store.managerList.find(x => x['custom:uuid_c'] == foundGroup.uuid_c))
            hasManager = true
        } else if(!TymCommon.isEmpty(foundGroup.uuid_b)) {
          if(store.managerList.find(x => x['custom:uuid_b'] == foundGroup.uuid_b))
            hasManager = true
        } else {
          if(store.managerList.find(x => x['custom:uuid_a'] == foundGroup.uuid_a))
            hasManager = true
        }

        if(TymConst.IS_DEVELOPMENT) {
          console.log('dialogConfirmed', this.selectedTree, foundGroup, hasManager)
        }
        if(hasManager) {
          TymCommon.Toast(this.$t('warning.noDelHasManager'))
        } else {
          if(!TymAws.deleteGroup(foundGroup.uuid, this.callbackDeleateGroup)) {
            TymCommon.Toast('그룹을 삭제할 수 없습니다.')
          }
        }
      }
    },
    checkPasswordFailed() {
      TymCommon.Toast(this.$t('noticeView.deleteNoticeMsg2'))
    },
    deleteGroupConfirmed() {
      TymAws.checkPassword(this.pwBeforeDelGrp, this.checkPasswordOK, this.checkPasswordFailed)
    },
    deleteGroup() {
      this.pwBeforeDelGrp = ''
      this.hideDelUserPW = true

      const store = useTymictStore()
      let foundGroup = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(foundGroup) {
        if(foundGroup.default == 1) {
          TymCommon.Toast(this.$t('warning.noDelSpecialGroup'))
        } else {
          let level = 0
          let filters = null
          if(!TymCommon.isEmpty(foundGroup.group_d)) {
            level = 4
          } else if(!TymCommon.isEmpty(foundGroup.group_c)) {
            level = 3
            filters = store.groupInfo.filter(x => x.uuid_c == foundGroup.uuid_c)
          } else if(!TymCommon.isEmpty(foundGroup.group_b)) {
            level = 2
            filters = store.groupInfo.filter(x => x.uuid_b == foundGroup.uuid_b)
          }
          else if(!TymCommon.isEmpty(foundGroup.group_a)) {
            level = 1
            filters = store.groupInfo.filter(x => x.uuid_a == foundGroup.uuid_a)
          }

          let hasChildren = true
          if(filters == null) {
            hasChildren = false 
            console.log('delete Group : NO FILTER', foundGroup)
          } else {
            hasChildren = true
            if(level == 4 && filters.length == 1 && foundGroup.group_d == filters[0].group_d) {
              // 차일드 없으니 지워보자
              hasChildren = false
              //console.log('delete Group : ', level, foundGroup, filters)
            } else if(level == 3 && filters.length == 1 && foundGroup.group_c == filters[0].group_c) {
              hasChildren = false
              //console.log('delete Group : ', level, foundGroup, filters)
            } else if(level == 2 && filters.length == 1 && foundGroup.group_b == filters[0].group_b) {
              hasChildren = false
              //console.log('delete Group : ', level, foundGroup, filters)
            } else if(level == 1 && filters.length == 1 && foundGroup.group_a == filters[0].group_a) {
              hasChildren = false
              //console.log('delete Group : ', level, foundGroup, filters)
            }
          }
          if(hasChildren) {
            TymCommon.Toast(this.$t('warning.noDelHasChild'))
          } else {
            if(level > 0) {
              // 
              // this.confirm('그룹을 제거하시겠습니까?', '그룹 제거', null)
              this.delGrpDlg = true
              this.dialogMessage = this.$t('group.questionDeleteGroup')
            }
          }
        }
      }
    },
    testClicked() {
      this.makeBulkDealer()
    },
    makeBulkDealer() {
      let bulkData = [
      {
        "Branch": "강원지점",
        "Dealer": "강릉딜러점[박종담]",
        "Zipcode": "25416",
        "Address": "강릉시 주문진읍 주문진서당길 32",
        "Phone": "010-5373-1235",
        "ID": "1C03A35",
        "Manager": "박종담"
      },
      {
        "Branch": "전남지점",
        "Dealer": "강진딜러점[김종우]",
        "Zipcode": "59241",
        "Address": "강진군 강진읍 보은로 20-3",
        "Phone": "010-3621-5796",
        "ID": "1G14A50",
        "Manager": "김종우"
      },
      {
        "Branch": "전남지점",
        "Dealer": "강진딜러점[이태준]",
        "Zipcode": "59239",
        "Address": "강진군 강진읍 보은로 56",
        "Phone": "010-3648-4788",
        "ID": "1G14A31",
        "Manager": "이태준"
    },
    {
        "Branch": "경기지점",
        "Dealer": "강화딜러점[김태훈]",
        "Zipcode": "23041",
        "Address": "인천시 강화군 선원면 중앙로 131-12",
        "Phone": "010-6392-4989",
        "ID": "1B16A23",
        "Manager": "김태훈"
    },
    {
        "Branch": "경기지점",
        "Dealer": "강화딜러점[한종광]",
        "Zipcode": "23003",
        "Address": "인천시 강화군 교동면 교동서로 326",
        "Phone": "010-6347-2018",
        "ID": "1B16A60",
        "Manager": "한종광"
    },
    {
        "Branch": "경남지점",
        "Dealer": "거제딜러점[이상석]",
        "Zipcode": "53209",
        "Address": "거제시 연초면 연하해안로 63",
        "Phone": "010-3557-2469",
        "ID": "1I15A61",
        "Manager": "이상석"
    },
    {
        "Branch": "경남지점",
        "Dealer": "거창딜러점[김동현]",
        "Zipcode": "50109",
        "Address": "거창군 마리면 빼재로 10",
        "Phone": "010-8585-9690",
        "ID": "1I09A55",
        "Manager": "김동현"
    },
    {
        "Branch": "경남지점",
        "Dealer": "거창딜러점[변현태]",
        "Zipcode": "50118",
        "Address": "거창군 가조면 지산로 1474",
        "Phone": "010-3844-3335",
        "ID": "1I09A35",
        "Manager": "변현태"
    },
    {
        "Branch": "경남지점",
        "Dealer": "거창딜러점[염영환]",
        "Zipcode": "50146",
        "Address": "거창군 거창읍 거함대로 3451",
        "Phone": "010-4656-0504",
        "ID": "1I09A71",
        "Manager": "염영환"
    },
    {
        "Branch": "경북지점",
        "Dealer": "경주딜러점[김민혜]",
        "Zipcode": "38090",
        "Address": "경주시 승삼신리길 75",
        "Phone": "010-7354-4579",
        "ID": "1H04A41",
        "Manager": "김민혜"
    },
    {
        "Branch": "경북지점",
        "Dealer": "경주딜러점[조정옥]",
        "Zipcode": "38008",
        "Address": "경주시 안강읍 안현로 1583-14",
        "Phone": "010-3820-8833",
        "ID": "1H04A70",
        "Manager": "조정옥"
    },
    {
        "Branch": "경북지점",
        "Dealer": "고령딜러점[김경환]",
        "Zipcode": "40103",
        "Address": "고령군 다산면 호촌길 43-2",
        "Phone": "010-8599-7858",
        "ID": "1H14A82",
        "Manager": "김경환"
    },
    {
        "Branch": "경북지점",
        "Dealer": "고령딜러점[유상민]",
        "Zipcode": "40141",
        "Address": "고령군 고령읍 벽화길 33",
        "Phone": "010-2258-8475",
        "ID": "1H14A50",
        "Manager": "유상민"
    },
    {
        "Branch": "강원지점",
        "Dealer": "고성딜러점[김창식]",
        "Zipcode": "24744",
        "Address": "고성군 죽왕면 동해대로 6495-6",
        "Phone": "010-9852-3288",
        "ID": "1C05A50",
        "Manager": "김창식"
    },
    {
        "Branch": "경남지점",
        "Dealer": "고성딜러점[이재범]",
        "Zipcode": "52930",
        "Address": "고성군 고성읍 죽계1길 61",
        "Phone": "010-8539-7072",
        "ID": "1I07A23",
        "Manager": "이재범"
    },
    {
        "Branch": "강원지점",
        "Dealer": "고성딜러점[허금옥]",
        "Zipcode": "24707",
        "Address": "고성군 현내면 모정 2길 2-21",
        "Phone": "010-2542-6400",
        "ID": "1C05A30",
        "Manager": "허금옥"
    },
    {
        "Branch": "경남지점",
        "Dealer": "고성딜러점[황학주]",
        "Zipcode": "52930",
        "Address": "고성군 고성읍 대평 4길 6",
        "Phone": "010-2278-7695",
        "ID": "1I07A27",
        "Manager": "황학주"
    },
    {
        "Branch": "경기지점",
        "Dealer": "고양딜러점[김지필]",
        "Zipcode": "10201",
        "Address": "고양시 일산서구 송산로 217-9",
        "Phone": "010-5337-1427",
        "ID": "1B04A62",
        "Manager": "김지필"
    },
    {
        "Branch": "전북지점",
        "Dealer": "고창딜러점[유복승]",
        "Zipcode": "56438",
        "Address": "고창군 고창읍 보릿골로 57-8",
        "Phone": "010-3652-2040",
        "ID": "1F07A48",
        "Manager": "유복승"
    },
    {
        "Branch": "전북지점",
        "Dealer": "고창딜러점[정정태]",
        "Zipcode": "56408",
        "Address": "고창군 상하면 상하1길 58",
        "Phone": "010-3612-7091",
        "ID": "1F07A50",
        "Manager": "정정태"
    },
    {
        "Branch": "전남지점",
        "Dealer": "고흥딜러점[김연화]",
        "Zipcode": "59511",
        "Address": "고흥군 과역면 송학큰길 55-5",
        "Phone": "010-9114-4002",
        "ID": "1G10A10",
        "Manager": "김연화"
    },
    {
        "Branch": "전남지점",
        "Dealer": "고흥딜러점[이형식]",
        "Zipcode": "59542",
        "Address": "고흥군 고흥읍 고흥로 1589-3",
        "Phone": "010-8819-1750",
        "ID": "1G10A82",
        "Manager": "이형식"
    },
    {
        "Branch": "전남지점",
        "Dealer": "곡성딜러점[한상태]",
        "Zipcode": "57510",
        "Address": "곡성군 입면 창정길 9",
        "Phone": "010-3631-6174",
        "ID": "1G07A40",
        "Manager": "한상태"
    },
    {
        "Branch": "전남지점",
        "Dealer": "곡성딜러점[허견]",
        "Zipcode": "57507",
        "Address": "곡성군 옥과면 곡순로 2252-7",
        "Phone": "010-4798-1735",
        "ID": "1G07A35",
        "Manager": "허견"
    },
    {
        "Branch": "충남지점",
        "Dealer": "공주딜러점[강광수]",
        "Zipcode": "32531",
        "Address": "공주시 우성면 옥성길 497",
        "Phone": "010-4627-1556",
        "ID": "1E05A45",
        "Manager": "강광수"
    },
    {
        "Branch": "충남지점",
        "Dealer": "공주딜러점[박정헌]",
        "Zipcode": "32538",
        "Address": "공주시 미나리 1길 11-2",
        "Phone": "010-5426-3148",
        "ID": "1E05A52",
        "Manager": "박정헌"
    },
    {
        "Branch": "충남지점",
        "Dealer": "공주딜러점[하종주]",
        "Zipcode": "32517",
        "Address": "공주시 의당면 연수원길 186",
        "Phone": "010-8585-7289",
        "ID": "1E05A51",
        "Manager": "하종주"
    },
    {
        "Branch": "전남지점",
        "Dealer": "광주딜러점[신준호]",
        "Zipcode": "62078",
        "Address": "광주시 서구 용두길 46",
        "Phone": "010-7462-2133",
        "ID": "1G01A12",
        "Manager": "신준호"
    },
    {
        "Branch": "충북지점",
        "Dealer": "괴산딜러점[문현순]",
        "Zipcode": "28055",
        "Address": "괴산군 문광면 모래재로원터3길 29-21",
        "Phone": "010-8830-2753",
        "ID": "1D07A62",
        "Manager": "문현순"
    },
    {
        "Branch": "충북지점",
        "Dealer": "괴산딜러점[정봉훈]",
        "Zipcode": "28055",
        "Address": "괴산군 문광면 읍내로 35-11",
        "Phone": "010-2484-4842",
        "ID": "1D07A70",
        "Manager": "정봉훈"
    },
    {
        "Branch": "전남지점",
        "Dealer": "구례딜러점[박승희]",
        "Zipcode": "57634",
        "Address": "구례군 구례읍 용방로 23",
        "Phone": "010-4118-5795",
        "ID": "1G08A44",
        "Manager": "박승희"
    },
    {
        "Branch": "경북지점",
        "Dealer": "구미딜러점[위종진]",
        "Zipcode": "39141",
        "Address": "구미시 고아읍 선산대로 1045",
        "Phone": "010-5462-7117",
        "ID": "1H17A15",
        "Manager": "위종진"
    },
    {
        "Branch": "경북지점",
        "Dealer": "구미딜러점[유재호]",
        "Zipcode": "39118",
        "Address": "구미시 선산읍 선산대로 1419",
        "Phone": "010-3513-0059",
        "ID": "1H17A63",
        "Manager": "유재호"
    },
    {
        "Branch": "전북지점",
        "Dealer": "군산딜러점[이창환]",
        "Zipcode": "54065",
        "Address": "군산시 개정면 개정안길 45",
        "Phone": "010-3678-7845",
        "ID": "1F02A37",
        "Manager": "이창환"
    },
    {
        "Branch": "전북지점",
        "Dealer": "군산딜러점[조명권]",
        "Zipcode": "54065",
        "Address": "군산시 개정면 운회길 12",
        "Phone": "010-4580-3698",
        "ID": "1F02A36",
        "Manager": "조명권"
    },
    {
        "Branch": "경북지점",
        "Dealer": "군위딜러점[김남렬]",
        "Zipcode": "43110",
        "Address": "군위군 군위읍 중앙길 187",
        "Phone": "010-6413-0046",
        "ID": "1H06A62",
        "Manager": "김남렬"
    },
    {
        "Branch": "경북지점",
        "Dealer": "군위딜러점[최왕식]",
        "Zipcode": "43110",
        "Address": "군위군 군위읍 군청로 316",
        "Phone": "010-6607-0052",
        "ID": "1H06A20",
        "Manager": "최왕식"
    },
    {
        "Branch": "충남지점",
        "Dealer": "금산딜러점[이성헌]",
        "Zipcode": "32753",
        "Address": "금산군 남일면 무금로 2164",
        "Phone": "010-5424-2599",
        "ID": "1E03A50",
        "Manager": "이성헌"
    },
    {
        "Branch": "충남지점",
        "Dealer": "금산딜러점[이준용]",
        "Zipcode": "32738",
        "Address": "금산군 금산읍 뒷담말길 15",
        "Phone": "010-5402-2308",
        "ID": "1E03A30",
        "Manager": "이준용"
    },
    {
        "Branch": "전북지점",
        "Dealer": "김제딜러점[김문식]",
        "Zipcode": "54370",
        "Address": "김제시 월촌공단길 187",
        "Phone": "010-5131-8001",
        "ID": "1F08A35",
        "Manager": "김문식"
    },
    {
        "Branch": "전북지점",
        "Dealer": "김제딜러점[오윤표]",
        "Zipcode": "54370",
        "Address": "김제시 월촌공단길 181",
        "Phone": "010-3682-9990",
        "ID": "1F08A31",
        "Manager": "오윤표"
    },
    {
        "Branch": "경북지점",
        "Dealer": "김천딜러점[김영진]",
        "Zipcode": "39619",
        "Address": "김천시 남김천대로 3453",
        "Phone": "010-3518-9716",
        "ID": "1H03A50",
        "Manager": "김영진"
    },
    {
        "Branch": "경북지점",
        "Dealer": "김천딜러점[서봉기]",
        "Zipcode": "39672",
        "Address": "김천시 아포읍 대신길 168",
        "Phone": "010-8595-2402",
        "ID": "1H03A42",
        "Manager": "서봉기"
    },
    {
        "Branch": "경기지점",
        "Dealer": "김포딜러점[김세환]",
        "Zipcode": "10040",
        "Address": "김포시 대곶면 율생로 103-6",
        "Phone": "010-5305-2391",
        "ID": "1B15A35",
        "Manager": "김세환"
    },
    {
        "Branch": "경기지점",
        "Dealer": "김포딜러점[유영섭]",
        "Zipcode": "10076",
        "Address": "김포시 김포대로 1216번길 84-7",
        "Phone": "010-5320-2148",
        "ID": "1B15A12",
        "Manager": "유영섭"
    },
    {
        "Branch": "경남지점",
        "Dealer": "김해딜러점[손태형]",
        "Zipcode": "50924",
        "Address": "김해시 활천로 5번길 7",
        "Phone": "010-4583-8989",
        "ID": "1I11A22",
        "Manager": "손태형"
    },
    {
        "Branch": "전남지점",
        "Dealer": "나주딜러점[오정애]",
        "Zipcode": "58273",
        "Address": "나주시 예향로 3750",
        "Phone": "010-4660-0956",
        "ID": "1G01A40",
        "Manager": "오정애"
    },
    {
        "Branch": "전남지점",
        "Dealer": "나주딜러점[이대헌]",
        "Zipcode": "58275",
        "Address": "나주시 왕곡면 불로길 5",
        "Phone": "010-3609-2921",
        "ID": "1G01A96",
        "Manager": "이대헌"
    },
    {
        "Branch": "전북지점",
        "Dealer": "남원딜러점[박윤기]",
        "Zipcode": "55744",
        "Address": "남원시 남문로 193",
        "Phone": "010-3659-5283",
        "ID": "1F04A15",
        "Manager": "박윤기"
    },
    {
        "Branch": "전북지점",
        "Dealer": "남원딜러점[윤선호]",
        "Zipcode": "55781",
        "Address": "남원시 주생면 중동리 801",
        "Phone": "010-3677-2409",
        "ID": "1F04A69",
        "Manager": "윤선호"
    },
    {
        "Branch": "경남지점",
        "Dealer": "남해딜러점[박춘식]",
        "Zipcode": "52428",
        "Address": "남해군 남해읍 스포츠로 201",
        "Phone": "010-3576-3179",
        "ID": "1I19A32",
        "Manager": "박춘식"
    },
    {
        "Branch": "경남지점",
        "Dealer": "남해딜러점[지희주]",
        "Zipcode": "52403",
        "Address": "남해군 고현면 남해대로 3307-10",
        "Phone": "010-3212-3690",
        "ID": "1I19A53",
        "Manager": "지희주"
    },
    {
        "Branch": "충남지점",
        "Dealer": "논산딜러점[김영성]",
        "Zipcode": "32920",
        "Address": "논산시 광석면 논산평야로 687-1",
        "Phone": "010-8826-8596",
        "ID": "1E06A65",
        "Manager": "김영성"
    },
    {
        "Branch": "충남지점",
        "Dealer": "논산딜러점[서평원]",
        "Zipcode": "32971",
        "Address": "논산시 계백로 1037",
        "Phone": "010-5453-4342",
        "ID": "1E06A43",
        "Manager": "서평원"
    },
    {
        "Branch": "전남지점",
        "Dealer": "담양딜러점[양오동]",
        "Zipcode": "57312",
        "Address": "담양군 월산면 봉명길 15",
        "Phone": "010-8604-4959",
        "ID": "1G06A22",
        "Manager": "양오동"
    },
    {
        "Branch": "전남지점",
        "Dealer": "담양딜러점[이병문]",
        "Zipcode": "57364",
        "Address": "담양군 담양읍 죽향대로 1139",
        "Phone": "010-8600-3608",
        "ID": "1G06A25",
        "Manager": "이병문"
    },
    {
        "Branch": "충남지점",
        "Dealer": "당진딜러점[양광연]",
        "Zipcode": "31815",
        "Address": "당진시 합덕읍 평야6로 353",
        "Phone": "010-9352-2400",
        "ID": "1E14A20",
        "Manager": "양광연"
    },
    {
        "Branch": "충남지점",
        "Dealer": "당진딜러점[육근범]",
        "Zipcode": "31763",
        "Address": "당진시 구봉로 19",
        "Phone": "010-5209-5756",
        "ID": "1E14A75",
        "Manager": "육근범"
    },
    {
        "Branch": "충남지점",
        "Dealer": "당진딜러점[채인산]",
        "Zipcode": "31749",
        "Address": "당진시 신평면 서해로 6926-4",
        "Phone": "010-6404-6337",
        "ID": "1E14A74",
        "Manager": "채인산"
    },
    {
        "Branch": "경남지점",
        "Dealer": "마산딜러점[서금선]",
        "Zipcode": "51794",
        "Address": "창원시 마산합포구 진동면 미더덕로 463",
        "Phone": "010-2860-2374",
        "ID": "1I01A55",
        "Manager": "서금선"
    },
    {
        "Branch": "전남지점",
        "Dealer": "무안딜러점[김수진]",
        "Zipcode": "58532",
        "Address": "무안군 무안읍 영산로 3064",
        "Phone": "010-8620-7484",
        "ID": "1G17A83",
        "Manager": "김수진"
    },
    {
        "Branch": "전북지점",
        "Dealer": "무주딜러점[김상원]",
        "Zipcode": "55540",
        "Address": "무주군 안성면 안성로 232",
        "Phone": "010-2012-0140",
        "ID": "1F10A28",
        "Manager": "김상원"
    },
    {
        "Branch": "전북지점",
        "Dealer": "무주딜러점[정재환]",
        "Zipcode": "55540",
        "Address": "무주군 안성면 안성로 248",
        "Phone": "010-9428-4982",
        "ID": "1F10A22",
        "Manager": "정재환"
    },
    {
        "Branch": "경북지점",
        "Dealer": "문경딜러점[김영락]",
        "Zipcode": "36937",
        "Address": "문경시 산양면 중앙로 389",
        "Phone": "010-4910-6369",
        "ID": "1H19A46",
        "Manager": "김영락"
    },
    {
        "Branch": "경북지점",
        "Dealer": "문경딜러점[김용흠]",
        "Zipcode": "36939",
        "Address": "문경시 영순면 새발재길 2",
        "Phone": "010-5430-6632",
        "ID": "1H19A70",
        "Manager": "김용흠"
    },
    {
        "Branch": "경남지점",
        "Dealer": "밀양딜러점[김용수]",
        "Zipcode": "50454",
        "Address": "밀양시 하남읍 하남로 135",
        "Phone": "010-4592-1917",
        "ID": "1I06A21",
        "Manager": "김용수"
    },
    {
        "Branch": "경남지점",
        "Dealer": "밀양딜러점[김정수]",
        "Zipcode": "50406",
        "Address": "밀양시 무안면 사명로 716",
        "Phone": "010-4764-8388",
        "ID": "1I06A53",
        "Manager": "김정수"
    },
    {
        "Branch": "경남지점",
        "Dealer": "밀양딜러점[윤수식]",
        "Zipcode": "50432",
        "Address": "밀양시 추화산성길 30",
        "Phone": "010-3885-5041",
        "ID": "1I06A20",
        "Manager": "윤수식"
    },
    {
        "Branch": "경남지점",
        "Dealer": "밀양딜러점[정용철]",
        "Zipcode": "50432",
        "Address": "밀양시 안인로 35-1",
        "Phone": "010-4548-4253",
        "ID": "1I06A55",
        "Manager": "정용철"
    },
    {
        "Branch": "충남지점",
        "Dealer": "보령딜러점[임상영]",
        "Zipcode": "33493",
        "Address": "보령시 남포면 보령남로 278",
        "Phone": "010-9400-8653",
        "ID": "1E09A45",
        "Manager": "임상영"
    },
    {
        "Branch": "전남지점",
        "Dealer": "보성딜러점[문준영]",
        "Zipcode": "59443",
        "Address": "전남 보성군 미력면 흥성로 2659",
        "Phone": "010-3642-3630",
        "ID": "1G11A71",
        "Manager": "문준영"
    },
    {
        "Branch": "전남지점",
        "Dealer": "보성딜러점[송연준]",
        "Zipcode": "59443",
        "Address": "보성군 미력면 미력반룡길 10",
        "Phone": "010-2612-7206",
        "ID": "1G11A95",
        "Manager": "송연준"
    },
    {
        "Branch": "충북지점",
        "Dealer": "보은딜러점[배윤정]",
        "Zipcode": "28960",
        "Address": "보은군 탄부면 보청대로 747-1",
        "Phone": "010-2272-1084",
        "ID": "1D03A81",
        "Manager": "배윤정"
    },
    {
        "Branch": "충북지점",
        "Dealer": "보은딜러점[이돈영]",
        "Zipcode": "28918",
        "Address": "보은군 보은읍 남부로 4743",
        "Phone": "010-8161-1196",
        "ID": "1D03A23",
        "Manager": "이돈영"
    },
    {
        "Branch": "경북지점",
        "Dealer": "봉화딜러점[김중우]",
        "Zipcode": "36236",
        "Address": "봉화군 봉화읍 봉화로 975",
        "Phone": "010-4930-2670",
        "ID": "1H22A75",
        "Manager": "김중우"
    },
    {
        "Branch": "전북지점",
        "Dealer": "부안딜러점[장홍석]",
        "Zipcode": "56320",
        "Address": "부안군 부안읍 용암로 190",
        "Phone": "010-8646-4542",
        "ID": "1F11A70",
        "Manager": "장홍석"
    },
    {
        "Branch": "충남지점",
        "Dealer": "부여딜러점[이호현]",
        "Zipcode": "33119",
        "Address": "부여군 규암면 흥수로 492",
        "Phone": "010-4553-7114",
        "ID": "1E07A75",
        "Manager": "이호현"
    },
    {
        "Branch": "충남지점",
        "Dealer": "부여딜러점[정우원]",
        "Zipcode": "33126",
        "Address": "부여군 규암면 충절로 2045",
        "Phone": "010-7144-3360",
        "ID": "1E07A10",
        "Manager": "정우원"
    },
    {
        "Branch": "경남지점",
        "Dealer": "사천딜러점[박인권]",
        "Zipcode": "52547",
        "Address": "사천시 노례길 13",
        "Phone": "010-5141-1961",
        "ID": "1I05A94",
        "Manager": "박인권"
    },
    {
        "Branch": "경남지점",
        "Dealer": "산청딜러점[김동섭]",
        "Zipcode": "52203",
        "Address": "산청군 생초면 계남로 3",
        "Phone": "010-8533-1529",
        "ID": "1I14A30",
        "Manager": "김동섭"
    },
    {
        "Branch": "경남지점",
        "Dealer": "산청딜러점[박영인]",
        "Zipcode": "52248",
        "Address": "산청군 단성면 강누방목로 17-13",
        "Phone": "010-3845-0441",
        "ID": "1I14A53",
        "Manager": "박영인"
    },
    {
        "Branch": "강원지점",
        "Dealer": "삼척딜러점[이금춘]",
        "Zipcode": "25920",
        "Address": "삼척시 중앙시장2길 22-19",
        "Phone": "010-5378-3789",
        "ID": "1C14A11",
        "Manager": "이금춘"
    },
    {
        "Branch": "강원지점",
        "Dealer": "삼척딜러점[이해숙]",
        "Zipcode": "25933",
        "Address": "삼척시 근덕면 교가길 21",
        "Phone": "010-5038-7289",
        "ID": "1C14A35",
        "Manager": "이해숙"
    },
    {
        "Branch": "경북지점",
        "Dealer": "상주딜러점[서순임]",
        "Zipcode": "37238",
        "Address": "상주시 중앙로 375",
        "Phone": "010-5535-5980",
        "ID": "1H18A36",
        "Manager": "서순임"
    },
    {
        "Branch": "경북지점",
        "Dealer": "상주딜러점[한슨두]",
        "Zipcode": "37240",
        "Address": "상주시 외답 6길 25-12",
        "Phone": "010-3534-8009",
        "ID": "1H18A70",
        "Manager": "한슨두"
    },
    {
        "Branch": "충남지점",
        "Dealer": "서산딜러점[최재운]",
        "Zipcode": "32015",
        "Address": "서산시 인지면 무학로 1768",
        "Phone": "010-2765-7653",
        "ID": "1E13A52",
        "Manager": "최재운"
    },
    {
        "Branch": "충남지점",
        "Dealer": "서산딜러점[홍성권]",
        "Zipcode": "32001",
        "Address": "서산시 서해로 3532",
        "Phone": "010-5453-1540",
        "ID": "1E13A49",
        "Manager": "홍성권"
    },
    {
        "Branch": "충남지점",
        "Dealer": "서천딜러점[윤용태]",
        "Zipcode": "33648",
        "Address": "서천군 서천읍 장항산단북로 450-23",
        "Phone": "010-5483-9726",
        "ID": "1E08A40",
        "Manager": "윤용태"
    },
    {
        "Branch": "경북지점",
        "Dealer": "성주딜러점[임현수]",
        "Zipcode": "40031",
        "Address": "성주군 성주읍 주산로 131",
        "Phone": "010-4509-9788",
        "ID": "1H15A50",
        "Manager": "임현수"
    },
    {
        "Branch": "경북지점",
        "Dealer": "성주딜러점[채영미]",
        "Zipcode": "40025",
        "Address": "성주군 성주읍 성주로 3163",
        "Phone": "010-3517-1157",
        "ID": "1H15A93",
        "Manager": "채영미"
    },
    {
        "Branch": "충남지점",
        "Dealer": "세종딜러점[김경철]",
        "Zipcode": "30083",
        "Address": "세종시 금남면 성덕영곡길 16",
        "Phone": "010-4092-8935",
        "ID": "1E04A50",
        "Manager": "김경철"
    },
    {
        "Branch": "전북지점",
        "Dealer": "순창딜러점[김철호]",
        "Zipcode": "56042",
        "Address": "순창군 순창읍 순창8길 50",
        "Phone": "010-9452-0738",
        "ID": "1F12A20",
        "Manager": "김철호"
    },
    {
        "Branch": "전북지점",
        "Dealer": "순창딜러점[정도성]",
        "Zipcode": "56025",
        "Address": "순창군 유등면 향가로 43",
        "Phone": "010-3677-5223",
        "ID": "1F12A52",
        "Manager": "정도성"
    },
    {
        "Branch": "전남지점",
        "Dealer": "순천딜러점[강동원]",
        "Zipcode": "58027",
        "Address": "순천시 녹색로 1206",
        "Phone": "010-3645-1941",
        "ID": "1G04A72",
        "Manager": "강동원"
    },
    {
        "Branch": "전남지점",
        "Dealer": "순천딜러점[김주영]",
        "Zipcode": "58028",
        "Address": "순천시 야흥고개길 161",
        "Phone": "010-3100-2824",
        "ID": "1G04A80",
        "Manager": "김주영"
    },
    {
        "Branch": "전남지점",
        "Dealer": "신안딜러점[박미자]",
        "Zipcode": "58532",
        "Address": "무안군 무안읍 무안로 654",
        "Phone": "010-2619-3621",
        "ID": "1G03A10",
        "Manager": "박미자"
    },
    {
        "Branch": "전남지점",
        "Dealer": "신안딜러점[최문경]",
        "Zipcode": "58823",
        "Address": "신안군 압해읍 압해로 1317",
        "Phone": "010-3114-8121",
        "ID": "1G03A53",
        "Manager": "최문경"
    },
    {
        "Branch": "충남지점",
        "Dealer": "아산딜러점[심경석]",
        "Zipcode": "31445",
        "Address": "아산시 염치읍 염성길 70-27",
        "Phone": "010-9588-2962",
        "ID": "1E15A95",
        "Manager": "심경석"
    },
    {
        "Branch": "충남지점",
        "Dealer": "아산딜러점[이현구]",
        "Zipcode": "31447",
        "Address": "아산시 염치읍 곡교리길 10번길 35-1",
        "Phone": "010-5423-8873",
        "ID": "1E15A24",
        "Manager": "이현구"
    },
    {
        "Branch": "경북지점",
        "Dealer": "안동딜러점[신영수]",
        "Zipcode": "36611",
        "Address": "안동시 와룡면 퇴계로 669-10",
        "Phone": "010-8544-0531",
        "ID": "1H05A52",
        "Manager": "신영수"
    },
    {
        "Branch": "경기지점",
        "Dealer": "안성딜러점[류진영]",
        "Zipcode": "17603",
        "Address": "안성시 미양면 서미로 326-4",
        "Phone": "010-4853-3420",
        "ID": "1B11K65",
        "Manager": "류진영"
    },
    {
        "Branch": "경기지점",
        "Dealer": "안성딜러점[송인숙]",
        "Zipcode": "17526",
        "Address": "안성시 일죽면 금일로 495-34",
        "Phone": "010-3158-6656",
        "ID": "1B12A67",
        "Manager": "송인숙"
    },
    {
        "Branch": "경기지점",
        "Dealer": "안성딜러점[정원철]",
        "Zipcode": "17596",
        "Address": "안성시 발화대길 70",
        "Phone": "010-5350-9723",
        "ID": "1B12A50",
        "Manager": "정원철"
    },
    {
        "Branch": "강원지점",
        "Dealer": "양구딜러점[최윤채]",
        "Zipcode": "24557",
        "Address": "양구군 국토정중앙면 황강길6-57",
        "Phone": "010-3702-5626",
        "ID": "1C13A25",
        "Manager": "최윤채"
    },
    {
        "Branch": "강원지점",
        "Dealer": "양양딜러점[이건기]",
        "Zipcode": "25020",
        "Address": "양양군 양양읍 거릿말길 6",
        "Phone": "010-9466-2593",
        "ID": "1C04A40",
        "Manager": "이건기"
    },
    {
        "Branch": "강원지점",
        "Dealer": "양양딜러점[최현탁]",
        "Zipcode": "25043",
        "Address": "양양군 손양면 동해대로 2493-1",
        "Phone": "010-9005-6915",
        "ID": "1C04A42",
        "Manager": "최현탁"
    },
    {
        "Branch": "경기지점",
        "Dealer": "양주딜러점[류찬희]",
        "Zipcode": "11413",
        "Address": "양주시 광적면 삼일로 288-11",
        "Phone": "010-5221-0859",
        "ID": "1B02A31",
        "Manager": "류찬희"
    },
    {
        "Branch": "경기지점",
        "Dealer": "양평딜러점[이정수]",
        "Zipcode": "12550",
        "Address": "양평군 양평읍 공산이길 41-1",
        "Phone": "010-5462-7061",
        "ID": "1B08A11",
        "Manager": "이정수"
    },
    {
        "Branch": "경기지점",
        "Dealer": "여주딜러점[배규자]",
        "Zipcode": "12643",
        "Address": "여주시 능서면 농도원1길 115",
        "Phone": "010-9705-0106",
        "ID": "1B09A35",
        "Manager": "배규자"
    },
    {
        "Branch": "경기지점",
        "Dealer": "연천딜러점[유태준]",
        "Zipcode": "11026",
        "Address": "연천군 전곡읍 은대로 19",
        "Phone": "010-3575-8281",
        "ID": "1B05A71",
        "Manager": "유태준"
    },
    {
        "Branch": "전남지점",
        "Dealer": "영광딜러점[김경수]",
        "Zipcode": "57049",
        "Address": "영광군 영광읍 천년로 1418",
        "Phone": "010-9530-4462",
        "ID": "1G20A14",
        "Manager": "김경수"
    },
    {
        "Branch": "경북지점",
        "Dealer": "영덕딜러점[김수연]",
        "Zipcode": "36409",
        "Address": "영덕군 영해면 예주목은길 52",
        "Phone": "010-3818-8825",
        "ID": "1H10A10",
        "Manager": "김수연"
    },
    {
        "Branch": "충북지점",
        "Dealer": "영동딜러점[조재환]",
        "Zipcode": "29152",
        "Address": "영동군 영동읍 학산영동로 1180",
        "Phone": "010-6807-8900",
        "ID": "1D05A70",
        "Manager": "조재환"
    },
    {
        "Branch": "전남지점",
        "Dealer": "영암딜러점[이동호]",
        "Zipcode": "58413",
        "Address": "영암군 영암읍 월출로 29-11",
        "Phone": "010-3642-9527",
        "ID": "1G16A63",
        "Manager": "이동호"
    },
    {
        "Branch": "전남지점",
        "Dealer": "영암딜러점[이지환]",
        "Zipcode": "58413",
        "Address": "영암군 영암읍 월출로 29-2",
        "Phone": "010-3604-1242",
        "ID": "1G16A51",
        "Manager": "이지환"
    },
    {
        "Branch": "강원지점",
        "Dealer": "영월딜러점[정찬익]",
        "Zipcode": "26210",
        "Address": "강원도 영월군 북면 원동재로 515",
        "Phone": "010-5362-3157",
        "ID": "1C08A48",
        "Manager": "정찬익"
    },
    {
        "Branch": "강원지점",
        "Dealer": "영월딜러점[조완용]",
        "Zipcode": "26205",
        "Address": "영월군 주천면 송학주천로 1377",
        "Phone": "010-7148-8729",
        "ID": "1C08A02",
        "Manager": "조완용"
    },
    {
        "Branch": "경북지점",
        "Dealer": "영주딜러점[권오상]",
        "Zipcode": "36064",
        "Address": "영주시 의상로 51",
        "Phone": "010-2400-7071",
        "ID": "1H21A10",
        "Manager": "권오상"
    },
    {
        "Branch": "경북지점",
        "Dealer": "영주딜러점[윤영식]",
        "Zipcode": "36044",
        "Address": "영주시 봉현면 소백로 1851-11",
        "Phone": "010-7179-8548",
        "ID": "1H21A70",
        "Manager": "윤영식"
    },
    {
        "Branch": "경북지점",
        "Dealer": "영천딜러점[우종필]",
        "Zipcode": "38821",
        "Address": "영천시 대전길 12",
        "Phone": "010-5328-7018",
        "ID": "1H11A39",
        "Manager": "우종필"
    },
    {
        "Branch": "충남지점",
        "Dealer": "예산딜러점[강권호]",
        "Zipcode": "32419",
        "Address": "예산군 오가면 황금뜰로 1253",
        "Phone": "010-5434-0866",
        "ID": "1E11A01",
        "Manager": "강권호"
    },
    {
        "Branch": "경북지점",
        "Dealer": "예천딜러점[권태삼]",
        "Zipcode": "36826",
        "Address": "예천군 예천읍 역전길 56-1",
        "Phone": "010-3538-3977",
        "ID": "1H20A30",
        "Manager": "권태삼"
    },
    {
        "Branch": "경북지점",
        "Dealer": "예천딜러점[김명섭]",
        "Zipcode": "36825",
        "Address": "예천군 예천읍 지내길 83-7",
        "Phone": "010-8582-3383",
        "ID": "1H20A51",
        "Manager": "김명섭"
    },
    {
        "Branch": "충북지점",
        "Dealer": "옥천딜러점[김주환]",
        "Zipcode": "29041",
        "Address": "옥천군 옥천읍 매동로77",
        "Phone": "010-5427-6592",
        "ID": "1D04A55",
        "Manager": "김주환"
    },
    {
        "Branch": "충북지점",
        "Dealer": "옥천딜러점[오운희]",
        "Zipcode": "29013",
        "Address": "옥천군 청산면 남부로 2175",
        "Phone": "010-5468-6964",
        "ID": "1D04A32",
        "Manager": "오운희"
    },
    {
        "Branch": "전남지점",
        "Dealer": "완도딜러점[명종필]",
        "Zipcode": "59111",
        "Address": "완도군 완도읍 망석길 12",
        "Phone": "010-6603-0948",
        "ID": "1G22A33",
        "Manager": "명종필"
    },
    {
        "Branch": "전북지점",
        "Dealer": "완주딜러점[장학규]",
        "Zipcode": "55336",
        "Address": "완주군 삼례읍 삼례역로 192-210",
        "Phone": "010-3683-4153",
        "ID": "1F01A16",
        "Manager": "장학규"
    },
    {
        "Branch": "전북지점",
        "Dealer": "완주딜러점[조병연]",
        "Zipcode": "54855",
        "Address": "전주시 덕진구 온고을로 520",
        "Phone": "010-3652-4199",
        "ID": "1F01A28",
        "Manager": "조병연"
    },
    {
        "Branch": "경기지점",
        "Dealer": "용인딜러점[김성우]",
        "Zipcode": "17182",
        "Address": "용인시 처인구 백암면 삼백로 581",
        "Phone": "010-5241-3072",
        "ID": "1B11A55",
        "Manager": "김성우"
    },
    {
        "Branch": "경남지점",
        "Dealer": "울산딜러점[김지태]",
        "Zipcode": "44966",
        "Address": "울산시 울주군 웅촌면 초천길 34",
        "Phone": "010-9807-5183",
        "ID": "1I04A73",
        "Manager": "김지태"
    },
    {
        "Branch": "경남지점",
        "Dealer": "울산딜러점[이태현]",
        "Zipcode": "44940",
        "Address": "울주군 언양읍 남천둑길 104",
        "Phone": "010-8935-6363",
        "ID": "1I04A30",
        "Manager": "이태현"
    },
    {
        "Branch": "강원지점",
        "Dealer": "원주딜러점[원미연]",
        "Zipcode": "26303",
        "Address": "원주시 호저면 호저로370-3",
        "Phone": "010-5371-9696",
        "ID": "1C02A40",
        "Manager": "원미연"
    },
    {
        "Branch": "강원지점",
        "Dealer": "원주딜러점[최종안]",
        "Zipcode": "26492",
        "Address": "원주시 흥업면 대안로 595-9",
        "Phone": "010-6346-2436",
        "ID": "1C02A22",
        "Manager": "최종안"
    },
    {
        "Branch": "충북지점",
        "Dealer": "음성딜러점[김기훈]",
        "Zipcode": "27683",
        "Address": "음성군 금왕읍 유삼길 13",
        "Phone": "010-5036-7415",
        "ID": "1D08A77",
        "Manager": "김기훈"
    },
    {
        "Branch": "충북지점",
        "Dealer": "음성딜러점[장미선]",
        "Zipcode": "27717",
        "Address": "음성군 원남면 반기문로3-18",
        "Phone": "010-7423-4356",
        "ID": "1D08A50",
        "Manager": "장미선"
    },
    {
        "Branch": "경남지점",
        "Dealer": "의령딜러점[성해영]",
        "Zipcode": "52129",
        "Address": "의령군 의령읍 무전로 2길 69",
        "Phone": "010-2584-5140",
        "ID": "1I18A50",
        "Manager": "성해영"
    },
    {
        "Branch": "경남지점",
        "Dealer": "의령딜러점[신해식]",
        "Zipcode": "52128",
        "Address": "의령군 용덕면 덕암로 60",
        "Phone": "010-5310-6421",
        "ID": "1I18A23",
        "Manager": "신해식"
    },
    {
        "Branch": "경북지점",
        "Dealer": "의성딜러점[금상인]",
        "Zipcode": "37340",
        "Address": "의성군 의성읍 구봉길 43",
        "Phone": "010-4119-8066",
        "ID": "1H07A30",
        "Manager": "금상인"
    },
    {
        "Branch": "경북지점",
        "Dealer": "의성딜러점[김상철]",
        "Zipcode": "37340",
        "Address": "의성군 구봉길 82-4",
        "Phone": "010-5005-3438",
        "ID": "1H07A84",
        "Manager": "김상철"
    },
    {
        "Branch": "경북지점",
        "Dealer": "의성딜러점[노영웅]",
        "Zipcode": "37372",
        "Address": "의성군 단북면 도안로 2110",
        "Phone": "010-3548-3759",
        "ID": "1H07A40",
        "Manager": "노영웅"
    },
    {
        "Branch": "경북지점",
        "Dealer": "의성딜러점[최원석]",
        "Zipcode": "37312",
        "Address": "의성군 안계면 도안로 2178",
        "Phone": "010-2590-8887",
        "ID": "1H07A82",
        "Manager": "최원석"
    },
    {
        "Branch": "경기지점",
        "Dealer": "이천딜러점[김문식]",
        "Zipcode": "17415",
        "Address": "이천시 장호원읍 어석로 290",
        "Phone": "010-5352-2211",
        "ID": "1B10A31",
        "Manager": "김문식"
    },
    {
        "Branch": "경기지점",
        "Dealer": "이천딜러점[김재환]",
        "Zipcode": "17408",
        "Address": "이천시 모가면 진상미로1163번길 31",
        "Phone": "010-6363-2733",
        "ID": "1B10A15",
        "Manager": "김재환"
    },
    {
        "Branch": "경기지점",
        "Dealer": "이천딜러점[이창호]",
        "Zipcode": "17307",
        "Address": "이천시 신둔면 수남리 둔터로 78",
        "Phone": "010-5445-3914",
        "ID": "1B10A34",
        "Manager": "이창호"
    },
    {
        "Branch": "경기지점",
        "Dealer": "이천딜러점[한상인]",
        "Zipcode": "17421",
        "Address": "이천시 장호원읍 서동대로 8769-11",
        "Phone": "010-5350-4220",
        "ID": "1B10A20",
        "Manager": "한상인"
    },
    {
        "Branch": "전북지점",
        "Dealer": "익산딜러점[고일석]",
        "Zipcode": "54520",
        "Address": "익산시 여산면 가람로 309",
        "Phone": "010-5693-1755",
        "ID": "1F03A60",
        "Manager": "고일석"
    },
    {
        "Branch": "전북지점",
        "Dealer": "익산딜러점[박은순]",
        "Zipcode": "54578",
        "Address": "익산시 춘포면 궁성로 253",
        "Phone": "010-4643-6979",
        "ID": "1F03A45",
        "Manager": "박은순"
    },
    {
        "Branch": "전북지점",
        "Dealer": "익산딜러점[임성훈]",
        "Zipcode": "54526",
        "Address": "익산시 함열읍 익산대로 1676-28",
        "Phone": "010-4701-3648",
        "ID": "1F03A90",
        "Manager": "임성훈"
    },
    {
        "Branch": "전북지점",
        "Dealer": "익산딜러점[조택민]",
        "Zipcode": "54510",
        "Address": "익산시 함열읍 함열중앙로 102",
        "Phone": "010-9375-4610",
        "ID": "1F03A43",
        "Manager": "조택민"
    },
    {
        "Branch": "강원지점",
        "Dealer": "인제딜러점[안지송]",
        "Zipcode": "24621",
        "Address": "인제군 북면 원통로 102",
        "Phone": "010-5371-3254",
        "ID": "1C16A25",
        "Manager": "안지송"
    },
    {
        "Branch": "전북지점",
        "Dealer": "임실딜러점[박지영]",
        "Zipcode": "55928",
        "Address": "임실군 임실읍 운수로 40",
        "Phone": "010-5369-7848",
        "ID": "1F09A60",
        "Manager": "박지영"
    },
    {
        "Branch": "전남지점",
        "Dealer": "장성딜러점[박동철]",
        "Zipcode": "57212",
        "Address": "전남 장성읍 장재길 61",
        "Phone": "010-8621-9772",
        "ID": "1G21A25",
        "Manager": "박동철"
    },
    {
        "Branch": "전북지점",
        "Dealer": "장수딜러점[유충열]",
        "Zipcode": "55623",
        "Address": "장수군 계남면 장수로 3098",
        "Phone": "010-6779-2024",
        "ID": "1F13A30",
        "Manager": "유충열"
    },
    {
        "Branch": "전남지점",
        "Dealer": "장흥딜러점[김병남]",
        "Zipcode": "59314",
        "Address": "장흥군 장흥읍 흥성로 275-8",
        "Phone": "010-8932-2363",
        "ID": "1G13A23",
        "Manager": "김병남"
    },
    {
        "Branch": "전남지점",
        "Dealer": "장흥딜러점[이종재]",
        "Zipcode": "59334",
        "Address": "장흥군 장흥읍 장흥대로 3436",
        "Phone": "010-3645-0948",
        "ID": "1G13A55",
        "Manager": "이종재"
    },
    {
        "Branch": "전북지점",
        "Dealer": "정읍딜러점[박만수]",
        "Zipcode": "56155",
        "Address": "정읍시 해평복룡길 7",
        "Phone": "010-3655-2858",
        "ID": "1F05A71",
        "Manager": "박만수"
    },
    {
        "Branch": "전북지점",
        "Dealer": "정읍딜러점[임채열]",
        "Zipcode": "56176",
        "Address": "정읍시 서부산업도로 394",
        "Phone": "010-8669-4490",
        "ID": "1F05A11",
        "Manager": "임채열"
    },
    {
        "Branch": "전남지점",
        "Dealer": "제주딜러점[김순자]",
        "Zipcode": "63035",
        "Address": "제주시 애월읍 납읍남로 70",
        "Phone": "010-3696-1881",
        "ID": "1J02A30",
        "Manager": "김순자"
    },
    {
        "Branch": "전남지점",
        "Dealer": "제주딜러점[김형근]",
        "Zipcode": "63027",
        "Address": "제주시 한림읍 한상로 19",
        "Phone": "010-3169-0679",
        "ID": "1J02A43",
        "Manager": "김형근"
    },
    {
        "Branch": "전남지점",
        "Dealer": "제주딜러점[양중화]",
        "Zipcode": "63352",
        "Address": "제주시 구좌읍 세화남 3길 4",
        "Phone": "010-4696-2522",
        "ID": "1J02A50",
        "Manager": "양중화"
    },
    {
        "Branch": "전남지점",
        "Dealer": "제주딜러점[이나현]",
        "Zipcode": "63348",
        "Address": "제주시 구좌읍 김월남길 122",
        "Phone": "010-7373-6497",
        "ID": "1J02A60",
        "Manager": "이나현"
    },
    {
        "Branch": "충북지점",
        "Dealer": "제천딜러점[권석기]",
        "Zipcode": "27201",
        "Address": "제천시 청풍호로 179",
        "Phone": "010-9363-7413",
        "ID": "1D09A82",
        "Manager": "권석기"
    },
    {
        "Branch": "충북지점",
        "Dealer": "제천딜러점[김대현]",
        "Zipcode": "27143",
        "Address": "제천시 의림대로 392",
        "Phone": "010-7525-7200",
        "ID": "1D09A01",
        "Manager": "김대현"
    },
    {
        "Branch": "전남지점",
        "Dealer": "진도딜러점[최경길]",
        "Zipcode": "58928",
        "Address": "진도군 진도읍 남산2길 24",
        "Phone": "010-7174-7805",
        "ID": "1G23A40",
        "Manager": "최경길"
    },
    {
        "Branch": "전남지점",
        "Dealer": "진도딜러점[한근우]",
        "Zipcode": "58928",
        "Address": "진도군 진도읍 남산로 60-3",
        "Phone": "010-3889-3376",
        "ID": "1G23A43",
        "Manager": "한근우"
    },
    {
        "Branch": "전북지점",
        "Dealer": "진안딜러점[송영규]",
        "Zipcode": "55431",
        "Address": "진안군 진안읍 진장로 51-17",
        "Phone": "010-8645-1655",
        "ID": "1F06A50",
        "Manager": "송영규"
    },
    {
        "Branch": "경남지점",
        "Dealer": "진주딜러점[강진식]",
        "Zipcode": "52802",
        "Address": "진주시 공단로 170",
        "Phone": "010-3579-1461",
        "ID": "1I02A13",
        "Manager": "강진식"
    },
    {
        "Branch": "경남지점",
        "Dealer": "진주딜러점[강진옥]",
        "Zipcode": "52621",
        "Address": "진주시 진성면 동부로 1307번길 47 주2동",
        "Phone": "010-6630-1170",
        "ID": "1I02A14",
        "Manager": "강진옥"
    },
    {
        "Branch": "경남지점",
        "Dealer": "진주딜러점[김명열]",
        "Zipcode": "52843",
        "Address": "진주시 금곡면 월아산로 73",
        "Phone": "010-5558-9283",
        "ID": "1I02A90",
        "Manager": "김명열"
    },
    {
        "Branch": "충북지점",
        "Dealer": "진천딜러점[조장주]",
        "Zipcode": "27848",
        "Address": "진천군 진천읍 문화10길 1",
        "Phone": "010-5455-3103",
        "ID": "1D06A55",
        "Manager": "조장주"
    },
    {
        "Branch": "충북지점",
        "Dealer": "진천딜러점[최종미]",
        "Zipcode": "27835",
        "Address": "진천군 진천읍 문화로 534",
        "Phone": "010-5481-0219",
        "ID": "1D06A75",
        "Manager": "최종미"
    },
    {
        "Branch": "경남지점",
        "Dealer": "창녕딜러점[이모형]",
        "Zipcode": "50322",
        "Address": "창녕군 창녕읍 탐하로 210",
        "Phone": "010-4588-9400",
        "ID": "1I12A80",
        "Manager": "이모형"
    },
    {
        "Branch": "경남지점",
        "Dealer": "창녕딜러점[주점근]",
        "Zipcode": "50348",
        "Address": "창녕군 영산면 영산도천로 518",
        "Phone": "010-3842-7568",
        "ID": "1I12A67",
        "Manager": "주점근"
    },
    {
        "Branch": "경남지점",
        "Dealer": "창원딜러점[전용탁]",
        "Zipcode": "51123",
        "Address": "창원시 의창구 대산면 북부로 1",
        "Phone": "010-5020-2069",
        "ID": "1I01A60",
        "Manager": "전용탁"
    },
    {
        "Branch": "충남지점",
        "Dealer": "천안딜러점[김인호]",
        "Zipcode": "31078",
        "Address": "천안시 서북구 공단로 78-21",
        "Phone": "010-3919-6967",
        "ID": "1E02A20",
        "Manager": "김인호"
    },
    {
        "Branch": "충남지점",
        "Dealer": "천안딜러점[류기철]",
        "Zipcode": "31027",
        "Address": "천안시 서북구 입장면 성진로 745",
        "Phone": "010-5433-0908",
        "ID": "1E02A70",
        "Manager": "류기철"
    },
    {
        "Branch": "충남지점",
        "Dealer": "천안딜러점[백항기]",
        "Zipcode": "31213",
        "Address": "천안시 동남구 풍세면 풍세로 474",
        "Phone": "010-6306-4000",
        "ID": "1E02A82",
        "Manager": "백항기"
    },
    {
        "Branch": "강원지점",
        "Dealer": "철원딜러점[김명선]",
        "Zipcode": "24029",
        "Address": "철원군 갈말읍 도창로 8",
        "Phone": "010-8754-6979",
        "ID": "1C11A90",
        "Manager": "김명선"
    },
    {
        "Branch": "강원지점",
        "Dealer": "철원딜러점[송일준]",
        "Zipcode": "24010",
        "Address": "철원군 동송읍 오덕로 35-2",
        "Phone": "010-4018-8331",
        "ID": "1C11A87",
        "Manager": "송일준"
    },
    {
        "Branch": "경북지점",
        "Dealer": "청도딜러점[박세억]",
        "Zipcode": "38335",
        "Address": "청도군 청도읍 새마을로 1361-25",
        "Phone": "010-4533-9399",
        "ID": "1H13A05",
        "Manager": "박세억"
    },
    {
        "Branch": "경북지점",
        "Dealer": "청도딜러점[임선정]",
        "Zipcode": "38304",
        "Address": "청도군 각북면 우산2길 8-8",
        "Phone": "010-4447-7864",
        "ID": "1H13A30",
        "Manager": "임선정"
    },
    {
        "Branch": "경북지점",
        "Dealer": "청송딜러점[김연화]",
        "Zipcode": "37406",
        "Address": "청송군 진보면 진보로 47",
        "Phone": "010-3522-7089",
        "ID": "1H08A91",
        "Manager": "김연화"
    },
    {
        "Branch": "충남지점",
        "Dealer": "청양딜러점[이재후]",
        "Zipcode": "33338",
        "Address": "청양군 청양읍 칠갑산로 130",
        "Phone": "010-6433-2953",
        "ID": "1E10A48",
        "Manager": "이재후"
    },
    {
        "Branch": "충북지점",
        "Dealer": "청주딜러점[손용택]",
        "Zipcode": "28303",
        "Address": "청주시 흥덕구 원평로10번길 104-7",
        "Phone": "010-7120-3961",
        "ID": "1D01A81",
        "Manager": "손용택"
    },
    {
        "Branch": "충북지점",
        "Dealer": "청주딜러점[정종근]",
        "Zipcode": "28805",
        "Address": "청주시 서원구 가장로 338",
        "Phone": "010-5461-2105",
        "ID": "1D01A13",
        "Manager": "정종근"
    },
    {
        "Branch": "강원지점",
        "Dealer": "춘천딜러점[김범준]",
        "Zipcode": "24203",
        "Address": "춘천시 신북읍 신북로 145",
        "Phone": "010-5369-0339",
        "ID": "1C01A70",
        "Manager": "김범준"
    },
    {
        "Branch": "강원지점",
        "Dealer": "춘천딜러점[정덕수]",
        "Zipcode": "24399",
        "Address": "춘천시 동내면 고은길8 가동",
        "Phone": "010-5424-4175",
        "ID": "1C01A12",
        "Manager": "정덕수"
    },
    {
        "Branch": "충북지점",
        "Dealer": "충주딜러점[이성진]",
        "Zipcode": "27456",
        "Address": "충주시 신니면 덕고개로 40",
        "Phone": "010-5485-2209",
        "ID": "1D02A55",
        "Manager": "이성진"
    },
    {
        "Branch": "충북지점",
        "Dealer": "충주딜러점[전제락]",
        "Zipcode": "27476",
        "Address": "충주시 중원대로 3659",
        "Phone": "010-5482-0632",
        "ID": "1D02A60",
        "Manager": "전제락"
    },
    {
        "Branch": "경북지점",
        "Dealer": "칠곡딜러점[강현규]",
        "Zipcode": "39816",
        "Address": "칠곡군 북삼읍 시덕로 306",
        "Phone": "010-6402-1865",
        "ID": "1H16A02",
        "Manager": "강현규"
    },
    {
        "Branch": "경북지점",
        "Dealer": "칠곡딜러점[이윤수]",
        "Zipcode": "39821",
        "Address": "칠곡군 약목면 교1길 9",
        "Phone": "011-503-3150",
        "ID": "1H16A40",
        "Manager": "이윤수"
    },
    {
        "Branch": "충남지점",
        "Dealer": "태안딜러점[윤택상]",
        "Zipcode": "32147",
        "Address": "태안군 태안읍 동백로 401-15",
        "Phone": "010-6431-2385",
        "ID": "1E13A30",
        "Manager": "윤택상"
    },
    {
        "Branch": "충남지점",
        "Dealer": "태안딜러점[최종환]",
        "Zipcode": "32144",
        "Address": "태안군 태안읍 동백로 206",
        "Phone": "010-5457-6864",
        "ID": "1E13A61",
        "Manager": "최종환"
    },
    {
        "Branch": "경기지점",
        "Dealer": "파주딜러점[김화영]",
        "Zipcode": "10818",
        "Address": "파주시 문산읍 사목로 187",
        "Phone": "010-3110-8678",
        "ID": "1B17A33",
        "Manager": "김화영"
    },
    {
        "Branch": "경기지점",
        "Dealer": "파주딜러점[한명근]",
        "Zipcode": "10847",
        "Address": "파주시 평화로 441",
        "Phone": "010-4783-1958",
        "ID": "1B17A50",
        "Manager": "한명근"
    },
    {
        "Branch": "강원지점",
        "Dealer": "평창딜러점[강용대]",
        "Zipcode": "25333",
        "Address": "평창군 진부면 청송로 24",
        "Phone": "010-5166-6003",
        "ID": "1C09A40",
        "Manager": "강용대"
    },
    {
        "Branch": "강원지점",
        "Dealer": "평창딜러점[조남원]",
        "Zipcode": "25361",
        "Address": "평창군 대화면 금당계곡로 16",
        "Phone": "010-5361-8557",
        "ID": "1C09A12",
        "Manager": "조남원"
    },
    {
        "Branch": "경기지점",
        "Dealer": "평택딜러점[김연서]",
        "Zipcode": "17927",
        "Address": "평택시 오성면 교포2길 75",
        "Phone": "010-3782-8934",
        "ID": "1B13A50",
        "Manager": "김연서"
    },
    {
        "Branch": "경기지점",
        "Dealer": "평택딜러점[김의환]",
        "Zipcode": "17934",
        "Address": "평택시 안중읍 서동대로 1639-8",
        "Phone": "010-8797-8005",
        "ID": "1B13A85",
        "Manager": "김의환"
    },
    {
        "Branch": "경기지점",
        "Dealer": "평택딜러점[최희동]",
        "Zipcode": "17927",
        "Address": "평택시 오성면 강변로 1301",
        "Phone": "010-6406-7825",
        "ID": "1B13A51",
        "Manager": "최희동"
    },
    {
        "Branch": "경기지점",
        "Dealer": "평택딜러점[황만영]",
        "Zipcode": "17878",
        "Address": "평택시 신평로 161",
        "Phone": "010-2221-7200",
        "ID": "1B13A82",
        "Manager": "황만영"
    },
    {
        "Branch": "경기지점",
        "Dealer": "포천딜러점[안종훈]",
        "Zipcode": "11157",
        "Address": "포천시 군내면 유교로 292-4",
        "Phone": "010-5308-5426",
        "ID": "1B02A55",
        "Manager": "안종훈"
    },
    {
        "Branch": "경기지점",
        "Dealer": "포천딜러점[장성남]",
        "Zipcode": "11139",
        "Address": "포천시 신북면 호국로 1899-35",
        "Phone": "010-6252-4744",
        "ID": "1B06A82",
        "Manager": "장성남"
    },
    {
        "Branch": "경북지점",
        "Dealer": "포항딜러점[조영철]",
        "Zipcode": "37534",
        "Address": "포항시 북구 흥해읍 신흥로 827",
        "Phone": "010-3521-3116",
        "ID": "1H02A90",
        "Manager": "조영철"
    },
    {
        "Branch": "경북지점",
        "Dealer": "포항딜러점[최창규]",
        "Zipcode": "37516",
        "Address": "포항시 북구 청하면 청하로 125",
        "Phone": "010-3525-3118",
        "ID": "1H02A33",
        "Manager": "최창규"
    },
    {
        "Branch": "경남지점",
        "Dealer": "하동딜러점[고민점]",
        "Zipcode": "52346",
        "Address": "하동군 진교면 진양로 94",
        "Phone": "010-8750-8533",
        "ID": "1I08A80",
        "Manager": "고민점"
    },
    {
        "Branch": "경남지점",
        "Dealer": "하동딜러점[김태남]",
        "Zipcode": "52346",
        "Address": "하동군 진교면 진양로 81",
        "Phone": "010-3867-4583",
        "ID": "1I08A42",
        "Manager": "김태남"
    },
    {
        "Branch": "경남지점",
        "Dealer": "함안딜러점[강석원]",
        "Zipcode": "52031",
        "Address": "함안군 산인면 송산로 28",
        "Phone": "010-9387-2583",
        "ID": "1I16A50",
        "Manager": "강석원"
    },
    {
        "Branch": "경남지점",
        "Dealer": "함안딜러점[조수일]",
        "Zipcode": "52033",
        "Address": "함안군 가야읍 함안대로 766",
        "Phone": "010-6510-2892",
        "ID": "1I16A36",
        "Manager": "조수일"
    },
    {
        "Branch": "경남지점",
        "Dealer": "함양딜러점[강철호]",
        "Zipcode": "50010",
        "Address": "함양군 안의면 거함대로 1611",
        "Phone": "010-7479-7558",
        "ID": "1I17A61",
        "Manager": "강철호"
    },
    {
        "Branch": "경남지점",
        "Dealer": "함양딜러점[백승원]",
        "Zipcode": "50031",
        "Address": "함양군 함양읍 함양로 1259",
        "Phone": "010-4509-5954",
        "ID": "1I17A50",
        "Manager": "백승원"
    },
    {
        "Branch": "전남지점",
        "Dealer": "함평딜러점[박다미]",
        "Zipcode": "57146",
        "Address": "함평군 함평읍 함장로 1202-62",
        "Phone": "010-4921-5386",
        "ID": "1G19A01",
        "Manager": "박다미"
    },
    {
        "Branch": "전남지점",
        "Dealer": "함평딜러점[최진욱]",
        "Zipcode": "57158",
        "Address": "함평군 함평읍 함영로 713-3",
        "Phone": "010-2643-2572",
        "ID": "1G19A50",
        "Manager": "최진욱"
    },
    {
        "Branch": "경남지점",
        "Dealer": "합천딜러점[김남범]",
        "Zipcode": "50227",
        "Address": "합천군 합천읍 대야로 1192",
        "Phone": "010-8700-5575",
        "ID": "1I10A83",
        "Manager": "김남범"
    },
    {
        "Branch": "경남지점",
        "Dealer": "합천딜러점[김명도]",
        "Zipcode": "50239",
        "Address": "합천군 대양면 대야로 657",
        "Phone": "010-3550-6579",
        "ID": "1I10A25",
        "Manager": "김명도"
      },
      {
        "Branch": "전남지점",
        "Dealer": "해남딜러점[고려승]",
        "Zipcode": "59023",
        "Address": "해남군 해남읍 읍학동길 2",
        "Phone": "010-4643-4885",
        "ID": "1G15A45",
        "Manager": "고려승"
      },
      {
        "Branch": "전남지점",
        "Dealer": "해남딜러점[박범재]",
        "Zipcode": "59032",
        "Address": "해남군 해남읍 용머리길 14",
        "Phone": "010-3608-8941",
        "ID": "1G15A80",
        "Manager": "박범재"
      },
      {
        "Branch": "충남지점",
        "Dealer": "홍성딜러점[성명희]",
        "Zipcode": "32242",
        "Address": "홍성군 홍성읍 홍장북로 244",
        "Phone": "010-2402-6488",
        "ID": "1E12A22",
        "Manager": "성명희"
      },
      {
        "Branch": "충남지점",
        "Dealer": "홍성딜러점[이상재]",
        "Zipcode": "32213",
        "Address": "홍성군 구항면 구항길264번길 22",
        "Phone": "010-9520-7111",
        "ID": "1E12A60",
        "Manager": "이상재"
      },
      {
        "Branch": "강원지점",
        "Dealer": "홍천딜러점[김기술]",
        "Zipcode": "25156",
        "Address": "홍천군 화촌면 홍천로 1077",
        "Phone": "010-3397-8764",
        "ID": "1C06A60",
        "Manager": "김기술"
      },
      {
        "Branch": "강원지점",
        "Dealer": "홍천딜러점[안상현]",
        "Zipcode": "25114",
        "Address": "홍천군 북방면 성동로 149-4",
        "Phone": "010-5509-5063",
        "ID": "1C06A62",
        "Manager": "안상현"
      },
      {
        "Branch": "경기지점",
        "Dealer": "화성딜러점[박영국]",
        "Zipcode": "18527",
        "Address": "화성시 팔탄면 마당바위로 19",
        "Phone": "010-3765-0341",
        "ID": "1B01A74",
        "Manager": "박영국"
      },
      {
        "Branch": "경기지점",
        "Dealer": "화성딜러점[이광재]",
        "Zipcode": "18577",
        "Address": "화성시 팔탄면 온천로 284-3",
        "Phone": "010-4351-4654",
        "ID": "1B01A25",
        "Manager": "이광재"
      },
      {
        "Branch": "전남지점",
        "Dealer": "화순딜러점[문상식]",
        "Zipcode": "58140",
        "Address": "화순군 화순읍 덕음로 1014-1",
        "Phone": "010-3633-2640",
        "ID": "1G12A50",
        "Manager": "문상식"
      },
      {
        "Branch": "전남지점",
        "Dealer": "화순딜러점[홍용식]",
        "Zipcode": "58122",
        "Address": "화순군 화순읍 시장길 4",
        "Phone": "010-3603-1673",
        "ID": "1G12A12",
        "Manager": "홍용식"
      },
      {
        "Branch": "강원지점",
        "Dealer": "횡성딜러점[박영민]",
        "Zipcode": "25250",
        "Address": "횡성군 우천면 서동로 163",
        "Phone": "010-5370-8384",
        "ID": "1C07A30",
        "Manager": "박영민"
      },
      {
        "Branch": "강원지점",
        "Dealer": "횡성딜러점[전병수]",
        "Zipcode": "25244",
        "Address": "횡성군 우천면 추용로 56",
        "Phone": "010-3680-5694",
        "ID": "1C07A81",
        "Manager": "전병수"
      }
      ]

      /*
      bulkData = [
        {
          "Branch": "테스트지점",
          "Dealer": "1강릉딜러점[박종담]",
          "Zipcode": "25416",
          "Address": "강릉시 주문진읍 주문진서당길 32",
          "Phone": "010-5373-1235",
          "ID": "A123456",
          "Manager": "박종담"
        },
        {
          "Branch": "테스트지점",
          "Dealer": "1원주딜러점[원미연]",
          "Zipcode": "26303",
          "Address": "원주시 호저면 호저로370-3",
          "Phone": "010-5371-9696",
          "ID": "A123457",
          "Manager": "원미연"
        },
        {
          "Branch": "테스트지점",
          "Dealer": "1원주딜러점[최종안]",
          "Zipcode": "26492",
          "Address": "원주시 흥업면 대안로 595-9",
          "Phone": "010-6346-2436",
          "ID": "A123458",
          "Manager": "최종안"
        },
        {
          "Branch": "테스트지점",
          "Dealer": "1홍천딜러점[김기술]",
          "Zipcode": "25156",
          "Address": "홍천군 화촌면 홍천로 1077",
          "Phone": "010-3397-8764",
          "ID": "A123459",
          "Manager": "김기술"
        },
        {
          "Branch": "테스트지점",
          "Dealer": "1홍천딜러점[안상현]",
          "Zipcode": "25114",
          "Address": "홍천군 북방면 성동로 149-4",
          "Phone": "010-5509-5063",
          "ID": "A123455",
          "Manager": "안상현"
        },
      ]
      */
      const store = useTymictStore()
      console.log(bulkData, store.groupInfo, store.managerList)
      bulkData.forEach(bulk => {
        let manager = store.managerList.find(x => {
          let srcPhone = x['custom:uphone']
          srcPhone = srcPhone.replaceAll('-', '')
          srcPhone = srcPhone.replaceAll('+82', '0')

          let dstPhone = bulk.Phone.replaceAll('-', '')
          return srcPhone == dstPhone
        })
        if(manager) {
          if(!TymCommon.isEmpty(manager.email))
            bulk.Email = manager.email
          bulk['ID_old'] = manager.userid
        }
        let foundGroup = store.groupInfo.find(x => x.group_c == bulk.group_c || TymCommon.isEmpty(x.group_d))
        if(foundGroup) {
          bulk.group_a = foundGroup.group_a
          bulk.group_b = foundGroup.group_b
          bulk.group_c = foundGroup.group_c
          bulk.group_d = bulk.Dealer
          bulk.uuid_a = foundGroup.uuid_a
          bulk.uuid_b = foundGroup.uuid_b
          bulk.uuid_c = foundGroup.uuid_c
          bulk.uuid_d = TymFncs.getMyUUID()
          bulk.Phone = bulk.Phone.replaceAll('-', '')
        }
        this.sleep(3)
      })
      console.log('------> #1', bulkData)

      bulkData.forEach(bulk => {
        let foundGroup = store.groupInfo.find(x => {
          let srcGrp = x.group_d.replaceAll(')', '')
          srcGrp = srcGrp.replaceAll('(', '-')
          let srcGrpNames = srcGrp.split('-')
          let dstGrp = bulk.Dealer.replaceAll(']', '')
          dstGrp = dstGrp.replaceAll('[', '-')
          let dstGrpNames = dstGrp.split('-')
          if(dstGrpNames[0].indexOf(srcGrpNames[0]) >= 0) {
            if((srcGrpNames.length > 1) && (dstGrpNames.length > 1)) {
              if(dstGrpNames[1].indexOf(srcGrpNames[1]) >= 0) {
                return true
              }
              if(srcGrpNames[1].indexOf(dstGrpNames[1]) >= 0) {
                return true
              }
            }
          }
          return x.group_d == bulk.Dealer
        })
        /*
        if(!foundGroup) {
          let manager = store.managerList.find(x => {
            let srcPhone = x['custom:uphone']
            srcPhone = srcPhone.replaceAll('-', '')
            srcPhone = srcPhone.replaceAll('+82', '0')

            let dstPhone = bulk.Phone.replaceAll('-', '')

            return srcPhone == dstPhone
          })
          if(manager) {
            foundGroup = store.groupInfo.find(x => x.uuid_d == manager['custom:uuid_d'])
            console.log('---------->', manager, store.groupInfo, foundGroup)
          }
        }
        */

        if(foundGroup) {
          bulk.isNEW = false
          bulk.group_a = foundGroup.group_a
          bulk.group_b = foundGroup.group_b
          bulk.group_c = foundGroup.group_c
          bulk.uuid_a = foundGroup.uuid_a
          bulk.uuid_b = foundGroup.uuid_b
          bulk.uuid_c = foundGroup.uuid_c
          bulk.uuid_d = foundGroup.uuid_d
          bulk.default = foundGroup.default
          bulk.Dealer_old = foundGroup.group_d
          if(!TymCommon.isEmpty(foundGroup.email)) {
            bulk.Email = foundGroup.email
          }

          //console.log('기존 그룹', bulk.group_d)
          // this.saveBulkManager(bulk)
          return
        }
        let grpC = store.groupInfo.find(x => x.group_c == bulk.Branch)

        bulk.isNEW = true
        let jsonData = { 
          "uuid": '',
          "default": 0,
          "postcode": '',
          "address": '',
          "address2": '',
          "group_a": '',
          "group_b": '',
          "group_c": '',
          "group_d": '',
          "uuid_a": '',
          "uuid_b": '',
          "uuid_c": '',
          "uuid_d": '',
          "phoneno": ''
        }
        jsonData.group_a = bulk.group_a = grpC.group_a
        jsonData.group_b = bulk.group_b = grpC.group_b
        jsonData.group_c = bulk.group_c = grpC.group_c
        jsonData.group_d = bulk.group_d
        jsonData.uuid_a = bulk.uuid_a = grpC.uuid_a
        jsonData.uuid_b = bulk.uuid_b = grpC.uuid_b
        jsonData.uuid_c = bulk.uuid_c = grpC.uuid_c
        jsonData.uuid_d = bulk.uuid_d
        jsonData.uuid = bulk.uuid_d
        jsonData.postcode = bulk.Zipcode
        jsonData.address = bulk.Address
        jsonData.address2 = ''
        jsonData.phoneno = bulk.Phone

        //console.log('새 그룹 ', bulk)
        /*
        TymAws.updateGroupInfo(jsonData, true)
          .then(data=>{
            if(TymConst.IS_DEVELOPMENT) {
              console.log('updateGroupInfo OK :', data, bulk)
            }
            //this.saveBulkManager(bulk)
          })
          .catch(er => {
            console.log('updateGroupInfo FAIL :', er)
          })
        this.sleep(1000)
        */
      })
      bulkData.sort((a, b) => {
        if(a.Branch == b.Branch) {
          return a.Dealer.localeCompare(b.Dealer)
        }
        return a.Branch.localeCompare(b.Branch)
      })
      //let newGrp = bulkData.filter(x => x.isNEW == true)
      console.log('------> #2', bulkData)

      setTimeout(() => {
        store.loadGroupInfo()
      }, 2000)
    },
    saveBulkManager(manager) {
      const store = useTymictStore()
      let authIndex = 1
      let ulevel = 3
      let uuid_a, uuid_b, uuid_c, uuid_d
      let uphone = manager.Phone
      let uenable = '1'
      let userid = manager.ID
      let email = manager.Email
      let username = manager.Manager

      if(TymConst.IS_DEVELOPMENT) {
        console.log('saveBulkManager :', manager)
        return
      }

      uuid_a = manager.uuid_a;
      uuid_b = manager.uuid_b;
      uuid_c = manager.uuid_c;
      uuid_d = manager.uuid_d;

      let changePw = true
      let cognitoidentityserviceprovider  =  new AWS.CognitoIdentityServiceProvider()
      let param = {
        UserPoolId: store.cognito[store.connectServer].userPoolId,
        Username: userid,   // ID
        DesiredDeliveryMediums: [],
        MessageAction: 'SUPPRESS',
        UserAttributes: [
          {
              Name : 'name',
              Value : username
          },
          {
              Name : 'custom:uuid_a', 
              Value : uuid_a,
          },
          {
              Name : 'custom:uuid_b', 
              Value : uuid_b,
          },
          {
              Name : 'custom:uuid_c', 
              Value : uuid_c,
          },
          {
              Name : 'custom:uuid_d', 
              Value : uuid_d,
          },
          {
              Name : 'custom:uphone', 
              Value : uphone
          },
          {
              Name : 'custom:ulevel', 
              Value : String(ulevel),
          },
          {
              Name : 'custom:uenable', 
              Value : uenable
          },
          {
              Name : 'custom:ms',
              Value : String(authIndex)
          }
        ],
      }
      if(!TymCommon.isEmpty(email)) {
        param.UserAttributes.push({
          Name : 'email',
          Value : email
        })
      }

      var paramvalue = {
        UserPoolId: store.cognito[store.connectServer].userPoolId,
        Username: userid   // ID
      }

      let self = this

      function createUserFunc(err, data) {
        if (err) {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('CreateUser Callback :', err, err.code); // an error occurred
          }
          if(err.code == 'UsernameExistsException') {
            TymCommon.Toast(self.$t('group.usedID'), true)
          } else if(err.code == 'InvalidParameterException') {
            TymCommon.Toast(self.$t('group.invalidInput'), true)
          }
        } else {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('User Create OK : ', data, self.managerValue)
          }

          if(changePw == true) {
            //console.log('userPassword :', addedParam)

            var addedParam = {
              UserPoolId : store.cognito[store.connectServer].userPoolId,
              Username : userid,   // ID
              Permanent : true,                  // 최초로그인시 암호변경 필요(false)
              Password : 'tymict12!@',   // PW
            }

            cognitoidentityserviceprovider.adminSetUserPassword(addedParam, function(pwerr, pwdata) {
              if(pwerr) {
                console.log(pwerr, pwerr.code); // an error occurred
                if(pwerr.code == 'InvalidPasswordException' || pwerr.code == 'InvalidParameterException') {
                  //tym_alert_yesno(i18n.t('code.ERR_INVALID_PW_LENGTH'), 1);
                  //패스워드 양식오류
                }    
                // password에러
                cognitoidentityserviceprovider.adminDeleteUser(paramvalue, function(err, data) {
                  if (err) {
                    console.log(err, err.stack); // an error occurred
                  } else {
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log(data)
                    }
                  }
                });
              } else {
                if(TymConst.IS_DEVELOPMENT) {
                  console.log(pwdata)
                }
                TymAws.SetAdminHistory(userid, 1)
                      
                const dbParam = {
                  'username'  : param.Username,
                  'email' : email,
                  'name'  : username, 
                  'custom:uuid_a' : uuid_a,
                  'custom:uuid_b' : uuid_b,
                  'custom:uuid_c' : uuid_c,
                  'custom:uuid_d' : uuid_d,
                  'custom:uphone' : uphone,
                  'custom:ulevel' : ulevel,
                  'custom:uenable' : uenable,
                  'custom:ms' : String(authIndex)
                }
                if(TymConst.IS_DEVELOPMENT) {
                  console.log(param.UserAttributes);
                }
                var tpayload = JSON.stringify({"PARAMS" : dbParam});
                const lambdaParam = {
                  FunctionName : 'tymict_webmanager_addadmin',
                  InvocationType : 'RequestResponse',
                  Payload: tpayload
                };  
                    
                const templambda = new AWS.Lambda({
                  region: store.lambda[store.connectServer].region,
                  apiVersion: store.lambda[store.connectServer].apiVersion
                })

                try {
                  new Promise((resolve, reject)=>{
                    templambda.invoke(lambdaParam, function(err, data) {
                      if (err) {
                        // console.log('addAdmin error :', JSON.stringify(err, null, 2));
                        reject('error')
                      } else if (data == null) {
                        reject('error')
                      } else {
                        if(TymConst.IS_DEVELOPMENT) {
                          console.log("addAdmin OK", data)
                        }
                        addManagerCompleted()
                        resolve(data)
                      } 
                    })
                  })
                } catch(exception) {
                  console.log(exception)
                }  
              }   
            });
          } else {
              //tym_alert_yesno(i18n.t('code.TEXT_SUCCESS_EDITUSERINFO'), 1);
              //DeleteAllGrid();
              //$('#subpage_home').load('manage_customer.html'); 
              //
              //document.getElementById('manager_enduser_deletecancle_edit').click();
          }
        }
      }

      console.log(param)
      cognitoidentityserviceprovider.adminCreateUser(param, createUserFunc);

      function addManagerCompleted() {
        const store = useTymictStore()
        store.getManagerAndUser(false)
        if(TymConst.IS_DEVELOPMENT) {
          console.log('addManagerCompleted()')
        }
      }
    },
    sleep(ms) {
      const wakeUpTime = Date.now() + ms;
      while (Date.now() < wakeUpTime) {
        //
      }
    }
  }
})
</script>
