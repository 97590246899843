/*
 * Vue Pinia 를 이용한 전역 데이터 관리
*/
import { defineStore } from 'pinia'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { useI18n } from 'vue-i18n'
import TymAuth from '@/js/tymauth.js'
//import AWS from 'aws-sdk'
import TymAws from '@/js/tymaws.js'
import TymCommon from '@/js/tymcommon'
import TymConst from '@/js/tymconstants.js'
import moment from 'moment-timezone'
import axios from 'axios'

export const useTymictStore = defineStore('tymict', {
  state: () => ({
    connectServer : 0,    // 어떤 서버에 접속할 것인지 (0-기본 동아시아(서울), 1-미국(오하이오)))
    Language : '',
    timeDiff : 0,
    timezoneIndex : 0,
    firstTime : true,
    startPage : 0,
    reloadPage : {
      type : '',
      page : ''
    },
    localIP : '',
    idToken : [],
    cognito: [{
        userPoolId: 'ap-northeast-2_1Pndkiyzu',
        region: 'ap-northeast-2',
        clientId: '3n60ksrk34bgmeub9kqfok32hr',
        identitypool: "ap-northeast-2:db50ccbb-0300-43fc-a5e9-f9877d4e7573",
        poolkey: "cognito-idp.ap-northeast-2.amazonaws.com/ap-northeast-2_1Pndkiyzu"
      },
      {
        userPoolId: 'us-east-2_BbT9WhNZY',
        region: 'us-east-2',
        clientId: '7puv1m7ddqaqpeal1isb08dtoh',
        identitypool: "us-east-2:60b73625-84b5-46d5-a360-bdb877c03a5b",
        poolkey: "cognito-idp.us-east-2.amazonaws.com/us-east-2_BbT9WhNZY"
      }
    ],
    usercognito: [{
        userPoolId: 'ap-northeast-2_JfS0QBcOy',
        region: 'ap-northeast-2',
        clientId: '3lpnmasaa06eo5f636lcrkpbrb',
        poolkey: "cognito-idp.ap-northeast-2.amazonaws.com/ap-northeast-2_JfS0QBcOy"
      },
      {
        userPoolId: 'us-east-2_ezKN8LiPl',
        region: 'us-east-2',
        clientId: '521ip2vrr096jboiaatpnn2c3v',
        poolkey: "cognito-idp.us-east-2.amazonaws.com/us-east-2_ezKN8LiPl"
      }
    ],  
    lambda : [{
        region: 'ap-northeast-2',
        apiVersion: '2015-03-31'
      },
      {
        region: 'us-east-2',
        apiVersion: '2015-03-31'
      }
    ],
    mqtt : [{
        region: 'ap-northeast-2',
        endpoint: 'a1zitmbotan1gr-ats.iot.ap-northeast-2.amazonaws.com',
        mqttId: 'AKIA3WVJ5ZFLFT74WU4J',
        mqttkey: 'T5bXACVPII/OCs2eqoV1P4UJXggPF25cleW3Du+H'
      },
      {
        region: 'us-east-2',
        endpoint: 'a1zitmbotan1gr-ats.iot.us-east-2.amazonaws.com',
        mqttId: 'AKIA3WVJ5ZFLFT74WU4J',
        mqttkey: 'T5bXACVPII/OCs2eqoV1P4UJXggPF25cleW3Du+H'
      }
    ],
    gateway: {
      option1: [
        'https://aj50kgerv9.execute-api.ap-northeast-2.amazonaws.com/tymict_webuserinfo/option1?v1=',
        'https://ghaj197719.execute-api.us-east-2.amazonaws.com/tymict_webuserinfo/option1?v1='
      ],
      option2: [
        'https://aj50kgerv9.execute-api.ap-northeast-2.amazonaws.com/tymict_webuserinfo/option2?v1=',
        'https://ghaj197719.execute-api.us-east-2.amazonaws.com/tymict_webuserinfo/option2?v1='
      ]
    },

    CurrentUser : null,
    AccessToken : null,
    UserToken : '',

    groupName : '',

    Alarm : {
      count : 0,
    },
    Monitor : {
      monitorState : false
    },
    CurrentPage : {
      Old : 0,
      Now : -1
    },
    isLoadCompleted : false,
    isLandscape : true,

    docClient : null,
    docLambda : null,
    mqttClient : null,

    groupInfo : [],
    groupTree : [],

    loadStateMachine : false,
    machineLocaInfo : [],           // SN, 위, 경도, 시간, MN, TestC, 이름, 모델명의 배열을 포함하는 JSON 형태의 배열

//    manageCount : 0,
    manageInfo : [],

    EmergencyData : {
      lastKey : null,
      data : [],
    },

    BrokenData : {
      count : 0,
      lastKey : null,
      data : [],
    },

    PDListLastKey : null,

    // 사용자 리스트 - Cognito
    userList :[],

    // 관리자 리스트 - Cognito
    managerList : [],

    // 사용자 리스트 - DB
    dbUserList : {
      count : 0,
      data : [],
    },
    usersInMyGroup : [],

    // 그룹내 판매 데이터
    SalesData : {
      count : 0,
      lastKey : null,
      data : [],
    },

    noticeCount : 0,
    noticeInfo : [],

    // 소모품 알림 데이터
    ConsumablesData : {
      lastKey : null,
      data : [],
    },

    BatteryAlertData : {
      lastKey : null,
      data : [],
    },

    CustomerData : [],        // 세일즈에 등록된 사용자
    AllCustomerData : [],     // 모든 사용자

    // 모든 모델정보를 포함함 - 소모품 정보포함(등록된 모델 전체)
    ModelData : {
      count : 0,
      data : [],
    },

    // 모델 관리를 위한 리스트(config)
    ModelList : {
      count : 0,
      data : [],
    },

    timezoneList : [],

    machineTree : {
      Machine : [],
      Machines : [],
    },

    // 관리나 관제에서 사용자별 기대정보로 이동시 임시로 저장해놓는 정보
    selectManageInfo : null,
    tymictGroup : null,
    productGroup : null,

    WebConfig : null,

    TestFlag: 0
  }),

  getters: {
    Name : state => state.UserInfo.Name
  },
  
  actions: {
    initialize() {
      let savedRegion = window.localStorage.getItem('storedRegion');
      if(savedRegion) {
        this.connectServer = savedRegion
      } else {
        this.connectServer = 0
      }

      this.clearAll()
    },
    clearAll() {
      this.idToken = []

      this.localIP = ''

      this.AuthUser = null
      this.CurrentUser = null
      this.AccessToken = null
      this.groupName = ''
      this.UserToken = ''

      this.docClient = null
      this.docLambda = null
      this.mqttClient = null

      this.groupInfo = []
      this.groupTree = []

      this.loadStateMachine = false
      this.machineLocaInfo = []

      this.PDListLastKey = null

//      this.manageCount = 0
      this.manageInfo = []

      this.EmergencyData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      this.BrokenData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      this.userList = {
        count : 0,
        lastKey : null,
        data : [],
      }

      this.managerList = []
      this.dbUserList = {
        count : 0,
        data : [],
      }
      this.usersInMyGroup = []

      this.SalesData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      this.machineTree = {
        Machine : [],
        Machines : [],
      }

      this.noticeCount = 0
      this.noticeInfo = []

      this.ConsumablesData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      this.BatteryAlertData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      this.ModelData = {
        count : 0,
        data : [],
      }
      this.ModelList = {
        count : 0,
        data : [],
      }

      this.CustomerData = []
      this.AllCustomerData = []

      this.timezoneList = []
      this.selectManageInfo = null
      this.tymictGroup = null
      this.productGroup = null

      async function getLocalIP(obj) {
        try {
          const self = obj
          const url = 'https://api64.ipify.org?format=json'
          var result = await new Promise((resolve, reject)=> {
            console.log(typeof(resolve), typeof(reject))
            axios.get(url)
              .then(response => {
                self.localIP = response.data
                console.log("IP #1:", self.localIP)
              })
          })
          return result;
        } catch(ex) {
          console.log('getLocalIP failed :', ex)
        }
      }

      if(TymCommon.isEmpty(this.localIP)) {
        getLocalIP(this)
      }

      this.WebConfig = null
    },
    Toast(msg, isError) {
      const $q = useQuasar()
      let html = '<div style="font-size:12pt;font-weight:600;height:40pt;items;" class="row items-center">'
      html    += '<div class="col">'
      html    += msg
      html    += '</div>'
      html    += '</div>'
  
      let iconType = 'warning'
      let icoColor = 'black'
      let txtColor = 'black'
  
      iconType = isError ? 'cancel' : 'warning';
      icoColor = isError ? 'red' : 'primary';
      txtColor = isError ? 'red' : 'black';
  
      console.log('Store.Toast called.', msg)
  
      $q.notify({
            message: html,
            icon : iconType,
            iconSize : '30pt',
            color : 'grey-4',
            iconColor : icoColor,
            textColor : txtColor,
            progress : true,
            timeout : 2000,
            html: true
          })
    },
    setAuthUser(userinfo, firstTime) {
      this.AuthUser = userinfo
      
      if(TymConst.IS_DEVELOPMENT) {
        console.log('------ setAuthUser ------')
        console.log(userinfo)
      }

      let name = '', value = ''
      let result = []
      let abc = new Promise((resolve, reject) => {
        this.AuthUser.getUserAttributes(function(err, data) {
          if(err) {
            reject(err)
          } else {
            //console.log('Store.setAuthUser.userAttr :', data)

            let i  = 0;
            for (i = 0; i < data.length; i++) {
              name = data[i].getName()
              value = data[i].getValue()
              result[name] = value
            }
            resolve(result)
          }
        })
      })

      if(!TymCommon.isEmpty(firstTime) && !firstTime) {
        abc.then(value => {
          this.idToken = value
          if(!TymCommon.isEmpty(this.CurrentUser)) {
            this.idToken.userid = this.CurrentUser.username
          }
        }).catch(error => {
          console.log('Store.setAuthUser ERROR :', error)
        })
      }
    },
    clearAuthUser() {
      this.AuthUser = null
      this.idToken = []
      // console.log("Clear User")
    },
    setMonitoring(value) {
      this.Monitor.monitorState = value
      // console.log("Monitoring : ", this.Monitor.monitorState)
    },
    setPage(newPage) {
      if(this.isLoadCompleted) {
        console.log("Store.setPage :", newPage)
        this.CurrentPage.Old = this.CurrentPage.Now
        this.CurrentPage.Now = newPage
      }
    },
    getLanguage() {
      if(TymCommon.isEmpty(this.Language)) {
        if(navigator.language.indexOf('ko') >= 0) {
          return 'ko'
        } else {
          return 'en'
        }
      } else {
        return this.Language.toLowerCase()
      }
    },
    /* 응급, 고장, 소모품... 한꺼번이 읽어오기 */
    refreshMainItems(cbEmergency, cbBroken, cbConsumable, cbLowBattery) {
      let manage = [{
        "MA": this.idToken["custom:uuid_a"],
      }]

      if(!TymCommon.isEmpty(this.idToken["custom:uuid_b"])) {
        manage[0]["MB"] = this.idToken["custom:uuid_b"]
      }
      if(!TymCommon.isEmpty(this.idToken["custom:uuid_c"])) {
        manage[0]["MC"] = this.idToken["custom:uuid_c"]
      }
      if(!TymCommon.isEmpty(this.idToken["custom:uuid_d"])) {
        manage[0]["MD"] = this.idToken["custom:uuid_d"]
      }
      manage = JSON.stringify(manage)
  
      if(TymConst.IS_DEVELOPMENT) {
      //  console.log('refreshMainItems getGroupEmergencyData')
      }
      TymAws.getGroupEmergencyData(manage, this.isICTUser(), false)
      .then(data => {
        let newItems = []
        if(Array.isArray(data)) {
          newItems = data
        } else {
          if(!TymCommon.isEmpty(data.body)) {
            let json = JSON.parse(data.body)
            if(TymConst.IS_DEVELOPMENT) {
              if(!TymCommon.isEmpty(json)) {
                console.log('EM --->', json.EXCEL)
              }
            }
            json.EXCEL.forEach(one => {
              /*
              {
                "User_Name": "김동형",
                "MN": "TYMCCC",
                "MON": "TS130Z_CP6ANB",
                "User_Phone": "010-2751-6405",
                "User_ID": "tymccc",
                "SN": "T001201100110011",
                "ATimePN": "20240108:152728.053^2",
                "Manage": "[{\"MA\":\"0\",\"MB\":\"15\"}]",
                "ETYPE": "2",
                "VUT": 5000,
                "LOCA": [
                    "36.4796989666",
                    "127.08979335",
                    "20240108:152635"
                ],
                "Item_Count": 1,
                "DN_Array": [
                    [
                        "안심구역 이탈",
                        "Geofence"
                    ]
                ],
                "OTime_Array": [
                    "2024-01-08 15:27"
                ],
                "Manage_Name": [
                    "CCC",
                    ""
                ],
                "Manage_Phone": ""
              }
              */
              if(this.SalesData.data.length > 0) {
                if(!this.SalesData.data.find(x => x.machineNo == one.MN)) {
                  if(TymConst.IS_DEVELOPMENT) {
                    console.log("not exist in sales :", one)
                  }
                  return
                }
              }
              if(TymConst.IS_DEVELOPMENT) {
                if(one.MN == 'FWV10D') {
                  console.log("EM FWV10D :", one)
                }
              }

              let jsonManage = JSON.parse(one.Manage)
              if(Array.isArray(jsonManage))
                jsonManage = jsonManage[0]

              newItems.push({
                "MN": one.MN,
                "MON": one.MON,
                "VUT" : one.VUT,
                "MANAGE": one.Manage,
                "UINFO": [
                  {
                    "USER": one.User_Name,
                    "HP": one.User_Phone,
                    "UID": one.User_ID
                  }
                ],
                "MA": jsonManage.MA,
                "MB": TymCommon.isEmpty(jsonManage.MB) ? ' ' : jsonManage.MB,
                "MC": TymCommon.isEmpty(jsonManage.MC) ? ' ' : jsonManage.MC,
                "MD": TymCommon.isEmpty(jsonManage.MD) ? ' ' : jsonManage.MD,
                "SN": one.SN,
                "LOCA" : one.LOCA,
                "ETYPE" : one.ETYPE,
                "ECount" : one.Item_Count,
                "DN" : one.DN_Array,
                "OTime" : one.OTime_Array,
                "ATimePN": one.ATimePN,
              })
            })
        
            if(newItems.length > 0) {
              newItems.sort((a,b) => {
                return -a.ATimePN.localeCompare(b.ATimePN)
              })
            }
            // console.log('getGroupEmergencyData() :', json, this.EmergencyData.data)
          }
        }
        if(TymConst.IS_DEVELOPMENT) {
          console.log('getGroupEmergencyData() :', this.TestFlag, newItems)
          if(this.TestFlag == 1) {
            let found = newItems.find(x => x.MN == 'FWV10D')
            if(!found) {
              let now = moment()
              newItems.push({
                "MN": this.connectServer == 0 ? "FWV10D" : "PFPA00002",
                "MON": this.connectServer == 0 ? "TS130Z_CP6ANBVG" : "25125_CH",
                "VUT": 0,
                "MANAGE":  this.connectServer == 0 ? "[{\"MA\":\"3\",\"MB\":\"1641201354576-fing0828\"}]" : "[{\"MA\":\"3\",\"MB\":\"1703037193014-fing828\"}]",
                "UINFO": [
                    {
                        "USER": this.connectServer == 0 ? "사용자십번" : "Jake",
                        "HP": "01047444827",
                        "UID": this.connectServer == 0 ? "t130t10" : "tymtest"
                    }
                ],
                "MA": this.connectServer == 0 ? "0" : "3",
                "MB": "1641201354576-fing0828",
                "MC": " ",
                "MD": " ",
                "SN": "T002230604460446",
                "LOCA": [
                    "36.4796463566",
                    "127.0899685383",
                    now.format("YYYYMMDD:HHmm00")
                ],
                "ETYPE": "1",
                "ECount": 1,
                "DN": [
                    [
                        "차량 전복",
                        "Rollover"
                    ]
                ],
                "OTime": [
                  now.format("YYYY-MM-DD HH:mm")
                ],
                "ATimePN": now.format("YYYYMMDD:HHmm00.000^1")
              })
              newItems.sort((a,b) => {
                return -a.ATimePN.localeCompare(b.ATimePN)
              })
            }
          }
        }
        this.EmergencyData.data = Array.from(newItems)

        if(!TymCommon.isEmpty(cbEmergency)) {
          cbEmergency(true)
        }
      }).catch(err => {
        this.EmergencyData.data = []
        console.log('err getGroupEmergencyData : ', err)
        if(!TymCommon.isEmpty(cbEmergency)) {
          cbEmergency(false)
        }
      })

      /*
      [
        {
          "MN": "TYMICT_A3_12",
          "ECount": 6,
          "MANAGE": "[{\"MA\":\"0\",\"MB\":\"1641201354576-fing0828\"}]",
          "UINFO": [
              {
                  "USER": "사용자십번",
                  "HP": "010-5024-0828",
                  "UID": "t130t10"
              }
          ],
          "MON": "TS130Z_CP6ANB",
          "DN": [
              "연료 메터링 유닛 점검",
              "스로틀 밸브 점검",
              "냉각수 레벨 센서 점검",
              "HCU CAN Rx Error",
              "CLUSTER CAN Rx Error",
              "ECU CAN Rx Error"
          ],
          "OTime": [
              "2022-02-16 15:39",
              "2022-02-16 15:39",
              "2022-02-16 15:39",
              "2022-03-30 16:51",
              "2022-03-30 16:51",
              "2022-03-30 16:51"
          ],
          "CKEY": [
              "20220216:153900.180^7FD5F_5",
              "20220216:153908.157^33_7",
              "20220216:153711.883^6F_1",
              "20220330:165150.643^EC6_13",
              "20220330:165150.643^EC6_14",
              "20220330:165150.643^EC6_11"
          ],
          "ECODE": [
              "523615_5",
              "51_7",
              "111_1",
              "EC6_13",
              "EC6_14",
              "EC6_11"
          ],
          "SD": "2022-01-11T00:00",
          "MA": "0",
          "VUT": [
              43,
              43,
              43,
              0,
              0,
              0
          ],
          "MB": "1641201354576-fing0828",
          "BLUE": true,
          "MC": " ",
          "MD": " ",
          "SN": "A001210300120012",
          "ATime": "20220217:132953.804",
          "PN": [
              "7FD5F_5",
              "33_7",
              "6F_1",
              "EC6_13",
              "EC6_14",
              "EC6_11"
          ]
        }
        ...
      ]
      */
      if(TymConst.IS_DEVELOPMENT) {
      //  console.log('refreshMainItems getGroupBrokenData')
      }

      TymAws.getGroupBrokenData(manage, this.isICTUser(), false)
        .then(data => {
          let newItems = []

          if(Array.isArray(data)) {
            newItems = data
          } else {
            if(!TymCommon.isEmpty(data.body)) {
              let json = JSON.parse(data.body)

              json.EXCEL.forEach(one => {
                if(this.SalesData.data.length > 0) {
                  if(!this.SalesData.data.find(x => x.machineNo == one.MN)) {
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log("not exist in sales :", one)
                    }
                    return
                  }
                }

                let jsonManage = JSON.parse(one.Manage)
                if(Array.isArray(jsonManage))
                  jsonManage = jsonManage[0]

                newItems.push({
                  "MN": one.MN,
                  "MON": one.MON,
                  "VUT" : one.VUT,
                  "MANAGE": one.Manage,
                  "UINFO": [
                    {
                      "USER": one.User_Name,
                      "HP": one.User_Phone,
                      "UID": one.User_ID
                    }
                  ],
                  "MA": jsonManage.MA,
                  "MB": TymCommon.isEmpty(jsonManage.MB) ? ' ' : jsonManage.MB,
                  "MC": TymCommon.isEmpty(jsonManage.MC) ? ' ' : jsonManage.MC,
                  "MD": TymCommon.isEmpty(jsonManage.MD) ? ' ' : jsonManage.MD,
                  "SN": one.SN,
                  "LOCA" : one.LOCA,
                  "ATime" : one.ATime,
                  "ECount" : one.Item_Count,
                  "DN" : one.DN_Array,
                  "OTime": one.OTime_Array,
                  "ECODE" : one.CODE_Array,
                  "PN" : one.CODE_Array
                })
              })
            }
          }
          if(newItems.length > 0) {
            newItems.sort((a,b) => {
              return -a.ATime.localeCompare(b.ATime)
            })
          }
          this.BrokenData.data = Array.from(newItems)

          if(TymConst.IS_DEVELOPMENT) {
            let temp = []
            this.BrokenData.data.forEach(br => {
              if(!temp.find(x => x.MN == br.MN)) {
                temp.push(br)
              }
            })
            console.log('BR -->', JSON.parse(data.body), temp)
          }

          if(!TymCommon.isEmpty(cbBroken)) {
            cbBroken(true)
          }
        }).catch(err => {
          console.log('err getGroupBrokenData : ', err)
          this.BrokenData.data = []
          if(!TymCommon.isEmpty(cbBroken)) {
            cbBroken(true)
          }
        })

      /*
      TymAws.getGroupConsumablesData([{
        MA: this.AuthUser.attributes['custom:uuid_a'], 
        MB: this.AuthUser.attributes['custom:uuid_b'],
        MC: this.AuthUser.attributes['custom:uuid_c'],
        MD: this.AuthUser.attributes['custom:uuid_d']
      }]).then(data => {
      */
        /*
        [
          {
            "CL1": 100,
            "NOCOUNT": 3,
            "CL3": 500,
            "CL2": 500,
            "CL5": 50,
            "MON": "TX76_CQPAN",
            "CL4": 300,
            "CL7": 50,
            "OKCOUNT": 4,
            "CL6": 50,
            "MA": "0",
            "VUT": 62,
            "MB": "2",
            "MC": "13",
            "MD": " ",
            "ASTATE": [
                false,
                false,
                false,
                false,
                true,
                true,
                true
            ],
            "MN": "TX76SD00958",
            "MANAGE": "[{\"MA\":\"0\",\"MB\":\"2\",\"MC\":\"13\"}]",
            "UINFO": [
                {
                    "USER": "정진호",
                    "HP": "010-3884-5714",
                    "UID": "ulsan001"
                }
            ],
            "OTime": "2023-05-10 13:25",
            "CC2": 62,
            "CC1": 62,
            "CC4": 62,
            "CC3": 62,
            "CC6": 62,
            "CC5": 62,
            "CC7": 62
          }
          ...
        ]
        */
      if(TymConst.IS_DEVELOPMENT) {
      //  console.log('refreshMainItems getGroupConsumablesData')
      }
      TymAws.getGroupConsumablesData(manage, this.isICTUser(), false)
        .then(data => {
          if(Array.isArray(data)) {
            this.ConsumablesData.data = data
          } else {
            let tempItems = []
            if(!TymCommon.isEmpty(data.body)) {
              let json = JSON.parse(data.body)

              if(TymConst.IS_DEVELOPMENT) {
                console.log('store.Consumables :', json.EXCEL)
              }
              json.EXCEL.forEach(one => {
                if(this.SalesData.data.length > 0) {
                  if(!this.SalesData.data.find(x => x.machineNo == one.MN)) {
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log("not exist in sales :", one)
                    }
                    return
                  }
                }

                let jsonManage = JSON.parse(one.Manage)
                if(Array.isArray(jsonManage))
                  jsonManage = jsonManage[0]

                tempItems.push({
                  "MN": one.MN,
                  "MON": one.MON,
                  "VUT" : one.VUT,
                  "MANAGE": one.Manage,
                  "UINFO": [
                    {
                      "USER": one.User_Name,
                      "HP": one.User_Phone,
                      "UID": one.User_ID
                    }
                  ],
                  "MA": jsonManage.MA,
                  "MB": TymCommon.isEmpty(jsonManage.MB) ? ' ' : jsonManage.MB,
                  "MC": TymCommon.isEmpty(jsonManage.MC) ? ' ' : jsonManage.MC,
                  "MD": TymCommon.isEmpty(jsonManage.MD) ? ' ' : jsonManage.MD,
                  "ATime" : one.ATime,
                  "NOCOUNT" : one.Item_Count,
                  "DN" : one.DN_Array,
                  "OTime": one.OTime,
                })
                /*
                for(let dnIdx = 0; dnIdx < one.Item_Count; dnIdx++) {
                  this.ConsumablesData.data.push({
                    "MN": one.MN,
                    "MON": one.MON,
                    "VUT" : one.VUT,
                    "MANAGE": one.Manage,
                    "UINFO": [
                      {
                        "USER": one.User_Name,
                        "HP": one.User_Phone,
                        "UID": one.User_ID
                      }
                    ],
                    "MA": jsonManage.MA,
                    "MB": TymCommon.isEmpty(jsonManage.MB) ? ' ' : jsonManage.MB,
                    "MC": TymCommon.isEmpty(jsonManage.MC) ? ' ' : jsonManage.MC,
                    "MD": TymCommon.isEmpty(jsonManage.MD) ? ' ' : jsonManage.MD,
                    "ATime" : one.ATime,
                    "NOCOUNT" : 1, // one.Item_Count,
                    "DN" : one.DN_Array[dnIdx],
                    "OTime": one.OTime,
                  })
                }
                */
              })
              //console.log('getGroupConsumablesData() :', json.EXCEL, this.ConsumablesData.data)

              if(tempItems.length > 0) {
                tempItems.sort((a,b) => {
                  return -a.OTime.localeCompare(b.OTime)
                })
              }
            }
            this.ConsumablesData.data = tempItems
          }
          if(!TymCommon.isEmpty(cbConsumable)) {
            cbConsumable(true)
          }
        }).catch(err => {
          this.ConsumablesData.data = []
          
          console.log('err getGroupConsumablesData : ', err)
          if(!TymCommon.isEmpty(cbConsumable)) {
            cbConsumable(false)
          }
        })

      if(TymConst.IS_DEVELOPMENT) {
      //  console.log('refreshMainItems getBatteryAlert')
      }
      TymAws.getBatteryAlert(manage, this.isICTUser())
        .then(data => {
          if(TymConst.IS_DEVELOPMENT) {
            let json = JSON.parse(data.body)
            console.log('getBatteryAlert :', json)
          }

          /*
          {
            "MN": "ES_2248_2A",
            "MA": "0",
            "MANAGE": "[{\"MA\":\"0\",\"MB\":\"1641201354576-fing0828\"}]",
            "MB": "1641201354576-fing0828",
            "UINFO": [
                {
                    "USER": "조준희",
                    "HP": "010-5064-2608",
                    "UID": "jjh1004"
                }
            ],
            "MC": " ",
            "MD": " ",
            "MON": "RGO-690_0",
            "SN": "A002224800020002",
            "OTime": "2023-06-13 17:53"
          }
          */

          let tempItems = []

          if(!TymCommon.isEmpty(data)) {
            if(!Array.isArray(data)) {
              let json = JSON.parse(data.body)
              json.EXCEL.forEach(one => {
                if(this.SalesData.data.length > 0) {
                  if(!this.SalesData.data.find(x => x.machineNo == one.MN)) {
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log("not exist in sales :", one)
                    }
                    return
                  }
                }

                tempItems.push({
                  "MN": one.MN,
                  "MON": one.MON,
                  "VUT": 0,
                  "MANAGE": JSON.stringify(one.Manage),
                  "UINFO": [
                    {
                      "USER": one.User_Name,
                      "HP": one.User_Phone,
                      "UID": one.User_ID
                    }
                  ],
                  "MA": one.Manage.MA,
                  "MB": TymCommon.isEmpty(one.Manage.MB) ? ' ' : one.Manage.MB,
                  "MC": TymCommon.isEmpty(one.Manage.MC) ? ' ' : one.Manage.MC,
                  "MD": TymCommon.isEmpty(one.Manage.MD) ? ' ' : one.Manage.MD,
                  "SN": one.SN,
                  "OTime": one.OTime_Array[0]
                })
              })
            } else {
              tempItems = data
            }
          } else {
            console.log('getBatteryAlert : EMPTY DATA')
          }
          if(tempItems.length > 0) {
            tempItems.sort((a,b) => {
              return -a.OTime.localeCompare(b.OTime)
            })
          }
          this.BatteryAlertData.data = Array.from(tempItems)

          if(!TymCommon.isEmpty(cbLowBattery)) {
            cbLowBattery(true)
          }
        }).catch(err => {
          console.log('err getBatteryAlert : ', err)
          this.BatteryAlertData.data = []

          if(!TymCommon.isEmpty(cbLowBattery)) {
            cbLowBattery(false)
          }
        })
    },

    getMainInfoOK(data) {
      // console.log('getMainInfoOK EBNCS: ', this.EmergencyData.data.length, this.BrokenData.data.length, this.noticeCount, this.ConsumablesData.data.length, this.SalesData.count)
      return data;
    },
    getMainInfoFail(err) {
      console.log('getMainInfoFail : ', err)
      this.EmergencyData.data = []
      this.BrokenData.data = []
      this.noticeInfo = []
      this.noticeCount = 0
      this.ConsumablesData.data = []
      // this.manageCount = this.EmergencyData.data.length + this.BrokenData.data.length + this.ConsumablesData.data.length + this.BatteryAlertData.data.length
    },
    getPositionOK(data) {
      const jsonobject = JSON.parse(data.Payload)
      /*
      let propCnt = 0
      for(let prop in jsonobject) {
        console.log('getPositionOK :', prop)
        propCnt++
        break;
      }
      if( propCnt < 1) {
        TymCommon.Toast('데이터를 조회할 수 없습니다.\n다시 조회해 주십시오.')
        this.loadStateMachine = false
        return;
      }
      */
      if(TymCommon.isEmpty(jsonobject.body)) {
        TymCommon.Toast('데이터를 조회할 수 없습니다.\n다시 조회해 주십시오.')
        console.log('### location Info ### NO DATA')
        this.loadStateMachine = false
        return;
      }

      let jsonBody = JSON.parse(jsonobject.body)// SN, 위, 경도, 시간, MN, TestC, 이름, 모델명의 배열을 포함하는 배열

      /*
      this.machineLocaInfo = jsonBody['Items']['LOCA']
      */
      let localInfo = jsonBody['Items']['LOCA']

      if(TymConst.IS_DEVELOPMENT) {
        console.log('STORE.getPositionOK :', localInfo)
      }

      let i = 0
      this.machineLocaInfo = []
      //console.log('--------->', localInfo, JSON.parse(JSON.stringify(this.SalesData.data)))

      for(i = 0; i < localInfo.length; i++) {
        let lat = parseFloat(localInfo[i][1])
        let lng = parseFloat(localInfo[i][2])
        let sale = null
        if((this.SalesData.data.length > 0) && !TymCommon.isEmpty(localInfo[i][0])) {
          sale = this.SalesData.data.find(x => x.serialNo == localInfo[i][0])
        }
        this.machineLocaInfo.push({
          // SN, 위, 경도, 시간, MN, CurrentState, 이름, 모델명의 배열을 포함하는 배열
          name : localInfo[i][6],
          uid : '',
          ads : '',
          phone : '',
          address : '',
          MON : TymCommon.isEmpty(sale) ? '' : sale.MON,
          modelNo : TymCommon.isEmpty(sale) ? '' : sale.model, // localInfo[i][7], //모델
          machineNo : localInfo[i][4], //VIN
          manDate : '', //생산일
          serialNo : localInfo[i][0], //S/N
          saleDate : '', //판매
          SD : '',
          dealer : '',  //Dealer
          abnormal : TymAws.containsAbnormalMn([localInfo[i][4]]),// Current State
          latitude : isNaN(lat) ? 0.0 : lat,  // 위도
          longitude : isNaN(lng) ? 0.0 : lng, // 경도
          hasACU : false,
          hasTCU : false,
          hasCPG : false,
          realtimeInfo : localInfo[i][3],  // 시간정보 YYYYMMDD:hhmmss.ttt
          realtimeAddr : '', // 현재위치의 주소
        })
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('STORE.getPositionOK STORE.machineLocaInfo :', this.machineLocaInfo)
      }

      let self = this
      function cbCompleted(data) {
        if(TymConst.IS_DEVELOPMENT) {
          console.log('STORE.getPositionOK getPowerState.cbCompleted :', data)
        }
  
        self.arrangeLocaAndAlarm(data)
        self.loadStateMachine = false
      }
      setTimeout(() => {
        this.getPowerState(cbCompleted, cbCompleted)
      }, 25)

      /*
      if(TymConst.IS_DEVELOPMENT) {
        this.machineLocaInfo.forEach(machine => {
          if(machine.name == '사용자십번') {
            console.log('사용자십번 :', machine.machineNo, machine.abnormal)
          }
        })
      }
      */

    },
    getPositionFail(err) {
      console.log('getPositionFailed : ', err)
      this.machineLocaInfo = []
      this.loadStateMachine = false
    },

    /* 그룹내 모든 기대의 위치를 가져온다
      incGroup : 그룹정보를 갱신할 것인가?
     */
    refreshCurrentLocation(incGroupInfo, incAlarm, cbEmergency, cbBroken, cbConsumable, cbLowBattery) {
      this.loadStateMachine = true;

      /*
      // 배터리 경고는 가장 먼저      
      this.BatteryAlertData = {
        count : 0,
        lastKey : null,
        data : [],
      }
      */
      let manage = [{
        "MA": this.idToken["custom:uuid_a"],
        "MB": '',
        "MC": '',
        "MD": '',
      }]

      if(!TymCommon.isEmpty(this.idToken["custom:uuid_b"])) {
        manage[0]["MB"] = this.idToken["custom:uuid_b"]
      }
      if(!TymCommon.isEmpty(this.idToken["custom:uuid_c"])) {
        manage[0]["MC"] = this.idToken["custom:uuid_c"]
      }
      if(!TymCommon.isEmpty(this.idToken["custom:uuid_d"])) {
        manage[0]["MD"] = this.idToken["custom:uuid_d"]
      }
      manage = JSON.stringify(manage)

      let self = this
      // 타임존
      // this.getTimezoneList()
      TymAws.getTimezoneList()
        .then(data => {
          /*
          {
            "dis_kr": "미드웨이",
            "name_kr": "사모아 시간",
            "dis_en": "Midway",
            "value": "Pacific/Midway",
            "gmt": "GMT-11:00",
            "name_en": "Samoa Time"
          }
          ==>
          {
            display_name : [
              dis_kr
              dis_en
            ],
            name : [
              name_kr,
              name_en
            ],
            value,
            gmt
          }
          */
          let items = []
          if(Array.isArray(data))
            items = data[0].CVAL
          else 
            items = data.CVAL

          //console.log('getTimezoneList #1:', data, items)

          // 자동항목 추가
          const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;     // Asia/Seoul
          const varMoment = moment().tz(currentTimezone)    // KST
          const mtz = moment()
          const currentGmt = "GMT" + varMoment.format("Z");
          console.log("TZ : ", varMoment, mtz, currentGmt)

          self.timezoneList.push( {
            'display_name' : [
              '자동 설정',
              'Automatic Setting'
            ],
            'name' : [
              varMoment,
              varMoment
            ],
            'value' : currentTimezone,
            'gmt' : currentGmt
          })

          for(let i = 0; i < items.length; i++) {
            self.timezoneList.push( {
              'display_name' : [
                TymCommon.isEmpty(items[i].dis_kr) ? '' : items[i].dis_kr,
                TymCommon.isEmpty(items[i].dis_en) ? '' : items[i].dis_en,
              ],
              'name' : [
                TymCommon.isEmpty(items[i].name_kr) ? '' : items[i].name_kr,
                TymCommon.isEmpty(items[i].name_en) ? '' : items[i].name_en,
              ],
              'value' : items[i].value,
              'gmt' : items[i].gmt
            })
          }


          setTimeout(() => {
            self.getADSInfo()
    
            if(incGroupInfo) {
              self.loadGroupInfo()
            }
            self.getNoticeCount()
    
            // 갯수 가져오는 건데??? 20240102 기준으로 필요할까 고민중...
            TymAws.getMainInfo(self.AuthUser.username, 
                self.AuthUser.attributes['custom:ms'],
                self.AuthUser.attributes['custom:uuid_a'], self.AuthUser.attributes['custom:uuid_b'], self.AuthUser.attributes['custom:uuid_c'], self.AuthUser.attributes['custom:uuid_d']
              ).then(self.getMainInfoOK)
              .catch(self.getMainInfoFail)
    
            if(incAlarm) {
              //this.refreshMainItems(cbEmergency, cbBroken, cbConsumable, cbLowBattery)
            }
    
            //console.log('## Manager List(DB) 1 ##')
            TymAws.getAllManagersFromDB().then(data => {
              self.dbUserList.count = data.length
              self.dbUserList.data = data;
              // console.log('Manager List(DB) ::: ', this.dbUserList.count, this.dbUserList.data)
            }).catch(err => {
              console.log('Manager List(DB) ER ', err)
            })
    
            self.getManagerAndUser(true)
    
            function cbLoadModelCompleted() {
              // 판매목록 구하기
              // console.log('## Sales List ##', self.ModelList.data, self.ModelData.data)
              
              self.getGroupSalesData(null, cbEmergency, cbBroken, cbConsumable, cbLowBattery)
            }
            // 모델리스트
            self.getModelList(cbLoadModelCompleted)
          }, 5)
      })
    },

    getManagerAndUser(incLocaInfo) {
      console.log('## Manager List ##')
      TymAws.getAllManagers().then(data => {
        /*
        {
            "createat": "2022-09-16T08:14:32.351Z",
            "userid": "1b01a74",
            "sub": "c7a7784e-e563-4c5c-b8df-2f08dc6bab08",
            "custom:ms": "1",
            "custom:uphone": "01037650341",
            "custom:ulevel": "3",
            "custom:uuid_c": "7",
            "custom:uuid_d": "1663286429913-gyeonggi",
            "name": "화성-박영국",
            "custom:uuid_a": "0",
            "custom:uuid_b": "2",
            "custom:uenable": "1",
            "email": "jiun.jang@tym.world"
        }
        */
        this.managerList = data;
        if(TymConst.IS_DEVELOPMENT) {
          let test = []
          this.managerList.forEach(manager => {
             if(TymCommon.isEmpty(manager["custom:uuid_c"]))
              return

            let grp = null
            let grpcName = '', grpdName

            if(!TymCommon.isEmpty(manager["custom:uuid_d"])) {
              grp = this.groupInfo.find(x => x.uuid_d == manager["custom:uuid_d"])
              if(grp)
                grpdName = grp.group_d
            } 
            if(!TymCommon.isEmpty(manager["custom:uuid_c"])) {
              grp = this.groupInfo.find(x => x.uuid_c == manager["custom:uuid_c"])
              if(grp)
                grpcName = grp.group_c
            }
            if(grp) {
              test.push({
                "name": manager["name"],
                "userid": manager["userid"],
                "custom:ms": manager["custom:ms"],
                "custom:uuid_a": manager["custom:uuid_a"],
                "custom:uuid_b": manager["custom:uuid_b"],
                "custom:uuid_c": manager["custom:uuid_c"],
                "custom:uuid_d": manager["custom:uuid_d"],
                "custom:uphone": manager["custom:uphone"],
                "custom:ulevel": manager["custom:ulevel"],
                "group_c": grpcName,
                "group_d": grpdName
              })
            }
          })
          test.sort((a, b) => {
            if(a["group_c"].localeCompare(b["group_c"]) == 0) {
              return a["name"].localeCompare(b["name"])
            }
            return a["group_c"].localeCompare(b["group_c"])
          })
          console.log('Manager List :', test)
        }
      }).catch(er => {
        console.log('Manager List Error:', er)
      })

      console.log('## User List 2 ##')

      TymAws.getAllUsers().then(data => {
        /*
        {
            "createat": "2022-09-19T05:28:57.100Z",
            "userid": "2476a",
            "sub": "f0d3ad52-e333-4531-a85f-ad374f091d87",
            "custom:cpinpw": "123456",
            "email_verified": "true",
            "gender": "M",
            "custom:cbirthday": "19580901",
            "custom:csn": "T001215100770077",
            "name": "한상래",
            "phone_number_verified": "true",
            "custom:caddress1": " 인천 강화군 양도면 고려왕릉로83번길 38",
            "phone_number": "+821032152476",
            "email": "null@tymict.com"
        }
        */

        this.userList = []
        data.forEach(user => {
          /*
          {
            "createat": "2023-05-18T05:16:55.143Z",
            "userid": "1g10a101",
            "sub": "957ba92e-25dc-402e-85e1-4a9d69434542",
            "custom:cpinpw": "tymict",
            "email_verified": "true",
            "gender": "M",
            "custom:caddress2": "1",
            "custom:cbirthday": "19800101",
            "name": "전광철",
            "phone_number_verified": "true",
            "custom:caddress1": "전남 고흥군 도양읍 용정등넘길 29-16",
            "phone_number": "+821036261596",
            "email": "null@tymict.com"
          }
          */
          // console.log(user)
          this.userList.push(user)
        })
        //this.userList = data;
        console.log('## Manage Sales & Users ##')
        this.arrangeSalesAndUsers()
        // 사용자 목록 만들기
        this.manageGroupUsers(incLocaInfo)
      }).catch(er => {
        console.log('User List ER ', er)
      })
    },

    loadCurrentPos() {
      /*
      var proxyUser = TymAuth.getProxyUserForMain(Number(this.userCustomMS));
      if (proxyUser != null) {    
        console.log("loadCurrentPos --> ", Number(this.userCustomMS), proxyUser)
        this.loadStateMachine = false;
      } else {
        */
        /*
        var poolkey = {}
        poolkey[this.cognito[this.connectServer].poolkey] = this.AuthUser.signInUserSession.idToken.jwtToken

        AWS.config.credentials = new AWS.CognitoIdentityCredentials( {
          IdentityPoolId: this.cognito[this.connectServer].identitypool, // your identity pool id here
          Logins: poolkey
        })
        AWS.config.region = this.cognito[this.connectServer].region;
        */

        let userLevel = Number(this.idToken['custom:ulevel'])
        let uuid = TymAuth.getUuidFromLevel(userLevel, this.idToken)

        console.log('## Store.loadCurrentPos ##', this.idToken['custom:ms'], this.idToken['custom:ulevel'], uuid)
        
        TymAws.getCurPos(userLevel, uuid)
          .then(this.getPositionOK)
          .catch(this.getPositionFail)
    },

    manageGroupTree() {
      this.groupTree = []

      if(this.groupInfo) {
        let myLevel = 4;
        //let level1 = [], level2=[], level3=[], level4=[]

        for(let i = 0; i < this.groupInfo.length; i++) {

          /// TYMICT 노드 날리기 위함(TYMICT 그룹 사용자에게만 조직에서 TYMICT노드가 보일 것임)
          if(this.groupInfo[i].group_b == "TYMICT") {
            if(!this.isICTUser())
              continue;
          }

          if(!TymCommon.isEmpty(this.idToken['custom:uuid_d'])) {
            myLevel = 4
            if(TymCommon.isEmpty(this.groupInfo[i].uuid_d))
              continue;
          } else {            
            if(!TymCommon.isEmpty(this.idToken['custom:uuid_c'])) {
              myLevel = 3
              if(TymCommon.isEmpty(this.groupInfo[i].uuid_c))
                continue;
            } else {
              if(!TymCommon.isEmpty(this.idToken['custom:uuid_b'])) {
                myLevel = 2
                if(TymCommon.isEmpty(this.groupInfo[i].uuid_b))
                  continue;
              } else {
                if(!TymCommon.isEmpty(this.idToken['custom:uuid_a'])) {
                  myLevel = 1
                  if(TymCommon.isEmpty(this.groupInfo[i].uuid_a))
                    continue;
                } 
              }
            }
          }
          if(this.isICTUser())
            myLevel = 1

          if(myLevel == 1) {
            if(!TymCommon.isEmpty(this.groupInfo[i].uuid_a)) {
              let first = this.groupTree.find((x) => x.uuid == this.groupInfo[i].uuid_a)
              if(TymCommon.isEmpty(first)) {
                first = {
                  label : this.groupInfo[i].group_a,
                  uuid : this.groupInfo[i].uuid_a,
                  children : []
                }
                this.groupTree.push(first)
                //console.log('---> 1 FIRST :', first)
              }
              if(!TymCommon.isEmpty(first)) {
                let second = first.children.find((x) => x.uuid == this.groupInfo[i].uuid_b)
                if(TymCommon.isEmpty(second)) {
                  if(!TymCommon.isEmpty(this.groupInfo[i].uuid_b)) {
                    second = {
                      label : this.groupInfo[i].group_b,
                      uuid : this.groupInfo[i].uuid_b,
                      children : []
                    }
                    //console.log('---> 1 SECOND :', first, second)
                    first.children.push(second)
                  }
                }
                if(!TymCommon.isEmpty(second)) {
                  let third = second.children.find((x) => x.uuid == this.groupInfo[i].uuid_c)
                  if(TymCommon.isEmpty(third)) {
                    if(!TymCommon.isEmpty(this.groupInfo[i].uuid_c)) {
                      third = {
                        label : this.groupInfo[i].group_c,
                        uuid : this.groupInfo[i].uuid_c,
                        children : []
                      }
                      //console.log('---> 1 THIRD :', first, second, third)
                      second.children.push(third)
                    }
                  }

                  if(!TymCommon.isEmpty(third)) {
                    let fourth = third.children.find((x) => x.uuid == this.groupInfo[i].uuid_d)
                    if(TymCommon.isEmpty(fourth)) {
                      if(!TymCommon.isEmpty(this.groupInfo[i].uuid_d)) {
                        fourth = {
                          label : this.groupInfo[i].group_d,
                          uuid : this.groupInfo[i].uuid_d,
                          children : []
                        }
                        //console.log('---> 1 FOURTH :', first, second, third, fourth)
                        third.children.push(fourth)
                      }
                    }
                  }
                }
              }
            }
          }

          if(myLevel == 2) {
            if(!TymCommon.isEmpty(this.groupInfo[i].uuid_b)) {
              let first = this.groupTree.find((x) => x.uuid == this.groupInfo[i].uuid_b)
              if(TymCommon.isEmpty(first)) {
                first = {
                  label : this.groupInfo[i].group_b,
                  uuid : this.groupInfo[i].uuid_b,
                  children : []
                }

                //console.log('---> 2 FIRST :', first)
                this.groupTree.push(first)
              }
              if(!TymCommon.isEmpty(first)) {
                let second = first.children.find((x) => x.uuid == this.groupInfo[i].uuid_c)
                if(TymCommon.isEmpty(second)) {
                  if(!TymCommon.isEmpty(this.groupInfo[i].uuid_c)) {
                    second = {
                      label : this.groupInfo[i].group_c,
                      uuid : this.groupInfo[i].uuid_c,
                      children : []
                    }
                    //console.log('---> 2 SECOND :', first, second)

                    first.children.push(second)
                  }
                }
                if(!TymCommon.isEmpty(second)) {
                  let third = second.children.find((x) => x.uuid == this.groupInfo[i].uuid_d)
                  if(TymCommon.isEmpty(third)) {
                    if(!TymCommon.isEmpty(this.groupInfo[i].uuid_d)) {
                      third = {
                        label : this.groupInfo[i].group_d,
                        uuid : this.groupInfo[i].uuid_d,
                        children : []
                      }
                      //console.log('---> 2 THIRD :', first, second, third)
                      second.children.push(third)
                    }
                  }
                }
              }
            }
          }

          if(myLevel == 3) {
            if(!TymCommon.isEmpty(this.groupInfo[i].uuid_c)) {
              let first = this.groupTree.find((x) => x.uuid == this.groupInfo[i].uuid_c)
              if(TymCommon.isEmpty(first)) {
                this.groupTree.push({
                  label : this.groupInfo[i].group_c,
                  uuid : this.groupInfo[i].uuid_c,
                  children : []
                })
              }
              first = this.groupTree.find((x) => x.uuid == this.groupInfo[i].uuid_c)
              if(!TymCommon.isEmpty(first)) {
                let second = first.children.find((x) => x.uuid == this.groupInfo[i].uuid_d)
                if(TymCommon.isEmpty(second)) {
                  if(!TymCommon.isEmpty(this.groupInfo[i].uuid_d)) {
                    first.children.push({
                      label : this.groupInfo[i].group_d,
                      uuid : this.groupInfo[i].uuid_d,
                      children : []
                    })
                  }
                }
              }
            }
          }

          if(myLevel == 4) {
            if(!TymCommon.isEmpty(this.groupInfo[i].group_d)) {
              let first = this.groupTree.find((x) => x.uuid == this.groupInfo[i].uuid_d)
              if(TymCommon.isEmpty(first)) {
                this.groupTree.push({
                  label : this.groupInfo[i].group_d,
                  uuid : this.groupInfo[i].uuid_d,
                  children : []
                })
              }
            }
          }
        }
        
        if(TymConst.IS_DEVELOPMENT) {
          console.log('push final :', this.groupTree)
        }
      }
    },

    findTreeByName(node, name) {
      let result = null

      // console.log(node, name)
      for(let idx = 0; idx < node.length; idx++) {
        if(node[idx].label == name) {
          result = node[idx]
          break
        } else {
          // 
          if(node[idx].children.length > 0 )
            result = this.findTreeByName(node[idx].children, name)
          if(result)
            break
        }
      }
      return result
    },

    // 이름으로 그룹을 찾는데 처음 걸리는 놈임,
    // 원하는 하나가 아니라 리스트에서 조건에 맞는 첫 아이템을 가져온다.
    // 해당 이름의 그룹이 존재하는 지만 확인할 수 있다.
    findGroupByName(grpName) {
      return this.findTreeByName(this.groupTree, grpName)
      /*
      if(this.groupInfo) {
        let found = this.groupInfo.find(x => x.group_d.localeCompare(grpName, undefined, { sensitivity: 'base' }) == 0)
        if(found) {
          console.lg(grpName, found)
          return found
        } else {
          //found = this.groupInfo.find(x => x.group_c == grpName)
          found = this.groupInfo.find(x => x.group_c.localeCompare(grpName, undefined, { sensitivity: 'base' }) == 0)
          if(found) {
            console.lg(grpName, found)
            return found
          } else {
            found = this.groupInfo.find(x => x.group_b.localeCompare(grpName, undefined, { sensitivity: 'base' }) == 0)
            //found = this.groupInfo.find(x => x.group_b == grpName)
            if(found) {
              console.lg(grpName, found)
              return found
            } else {
              found = this.groupInfo.find(x => x.group_a.localeCompare(grpName, undefined, { sensitivity: 'base' }) == 0)
              //found = this.groupInfo.find(x => x.group_a == grpName)
              if(found) {
                console.lg(grpName, found)
                return found
              } 
            } 
          }
        }
      }
      return null
      */
    },
    findGroupByUUID1(uuid) {
      if(this.groupInfo) {
        let found = this.groupInfo.find(x => x.uuid_d == uuid)
        if(found) {
          return found
        } else {
          found = this.groupInfo.find(x => x.uuid_c == uuid)
          if(found) {
            return found
          } else {
            found = this.groupInfo.find(x => x.uuid_b == uuid)
            if(found) {
              return found
            } else {
              found = this.groupInfo.find(x => x.uuid_a == uuid)
              if(found) {
                return found
              } 
            } 
          }
        }
      }
      return null
    },

    findGroupNameByKey(key) {
      if(this.groupInfo) {
        let found = this.groupInfo.find(x => x.uuid == key)
        if(found) {
          if(!TymCommon.isEmpty(found.uuid_d) && (found.uuid_d == key))
            return found.group_d
          else if(!TymCommon.isEmpty(found.uuid_c) && (found.uuid_c == key))
            return found.group_c
          else if(!TymCommon.isEmpty(found.uuid_b) && (found.uuid_b == key))
            return found.group_b
          else if(!TymCommon.isEmpty(found.uuid_a) && (found.uuid_a == key))
            return found.group_a
        }
      }
      return null
    },

    findGroupNamesByKey(key) {
      let found = this.groupInfo.find(x => x.uuid == key)
      let founds = []
      if(found) {
        if(!TymCommon.isEmpty(found.uuid_d)) {
          founds = this.groupInfo.filter(x => x.uuid_d == key)
        } else if(!TymCommon.isEmpty(found.uuid_c)) {
          founds = this.groupInfo.filter(x => x.uuid_c == key)
        } else if(!TymCommon.isEmpty(found.uuid_b)) {
          founds = this.groupInfo.filter(x => x.uuid_b == key)
        } else if(!TymCommon.isEmpty(found.uuid_a)) {
          founds =  this.groupInfo.filter(x => x.uuid_a == key)
        }

        let result = []
        for(let i = 0; i < founds.length; i++) {
          if(!TymCommon.isEmpty(founds[i].uuid_d) && (founds[i].uuid_d == key)) {
            result.push({
              name:founds[i].group_d,
              uuid:founds[i].uuid_d,
              uuid_d:founds[i].uuid_d,
              uuid_c:founds[i].uuid_c,
              uuid_b:founds[i].uuid_b,
              uuid_a:founds[i].uuid_a
            })
          } else if(!TymCommon.isEmpty(founds[i].uuid_c) && (founds[i].uuid_c == key)) {
            if(!TymCommon.isEmpty(founds[i].uuid_d)) {
              result.push({
                name:founds[i].group_d,
                uuid:founds[i].uuid_d,
                uuid_d:founds[i].uuid_d,
                uuid_c:founds[i].uuid_c,
                uuid_b:founds[i].uuid_b,
                uuid_a:founds[i].uuid_a
              })
            } else {
              result.push({
                name:founds[i].group_c,
                uuid:founds[i].uuid_c,
                uuid_d:'',
                uuid_c:founds[i].uuid_c,
                uuid_b:founds[i].uuid_b,
                uuid_a:founds[i].uuid_a
              })
            }
          } else if(!TymCommon.isEmpty(founds[i].uuid_b) && (founds[i].uuid_b == key)) {
            if(!TymCommon.isEmpty(founds[i].uuid_d)) {
              result.push({
                name:founds[i].group_d,
                uuid:founds[i].uuid_d,
                uuid_d:founds[i].uuid_d,
                uuid_c:founds[i].uuid_c,
                uuid_b:founds[i].uuid_b,
                uuid_a:founds[i].uuid_a
              })
            } else if(!TymCommon.isEmpty(founds[i].uuid_c)) {
              result.push({
                name:founds[i].group_c,
                uuid:founds[i].uuid_c,
                uuid_d:'',
                uuid_c:founds[i].uuid_c,
                uuid_b:founds[i].uuid_b,
                uuid_a:founds[i].uuid_a
              })
            } else if(!TymCommon.isEmpty(founds[i].uuid_b)) {
              result.push({
                name:founds[i].group_b,
                uuid:founds[i].uuid_b,
                uuid_d:'',
                uuid_c:'',
                uuid_b:founds[i].uuid_b,
                uuid_a:founds[i].uuid_a
              })
            }
          } else if(!TymCommon.isEmpty(founds[i].uuid_a) && (founds[i].uuid_a == key)) {
            if(!TymCommon.isEmpty(founds[i].uuid_d)) {
              result.push({
                name:founds[i].group_d,
                uuid:founds[i].uuid_d,
                uuid_d:founds[i].uuid_d,
                uuid_c:founds[i].uuid_c,
                uuid_b:founds[i].uuid_b,
                uuid_a:founds[i].uuid_a
              })
            } else if(!TymCommon.isEmpty(founds[i].uuid_c)) {
              result.push({
                name:founds[i].group_c,
                uuid:founds[i].uuid_c,
                uuid_d:'',
                uuid_c:founds[i].uuid_c,
                uuid_b:founds[i].uuid_b,
                uuid_a:founds[i].uuid_a
              })
            } else if(!TymCommon.isEmpty(founds[i].uuid_b)) {
              result.push({
                name:founds[i].group_b,
                uuid:founds[i].uuid_b,
                uuid_d:'',
                uuid_c:'',
                uuid_b:founds[i].uuid_b,
                uuid_a:founds[i].uuid_a
              })
            } else if(!TymCommon.isEmpty(founds[i].uuid_a)) {
              result.push({
                name:founds[i].group_a,
                uuid:founds[i].uuid_a,
                uuid_d:'',
                uuid_c:'',
                uuid_b:'',
                uuid_a:founds[i].uuid_a
              })
            }
          }
        }

        return result;
      }
    },
    getGroupInfoOK(data) {
      /*
      {
        "uuid": "1669600054466-gangwon",
        "default": 0,
        "address": "강원 영월군 북면 원동재로 515",
        "address2": "TYM영월 딜러점",
        "group_a": "TYM",
        "group_b": "국내영업본부",
        "group_c": "강원지점",
        "group_d": "영월딜러점(정찬익)",
        "phoneno": "010-5362-3157",
        "postcode": "26210",
        "uuid_a": "0",
        "uuid_b": "2",
        "uuid_c": "6",
        "uuid_d": "1669600054466-gangwon"
        }
      */
      // this.groupInfo = data.Items;
      // this.groupInfo = data;
      this.groupInfo = []

      // this.groupInfo = data
      // 20240516 이름 중복 제거
      data.forEach(grp => {
        let idx = this.groupInfo.findIndex(x => x.uuid == grp.uuid)
        if(idx < 0) {
          this.groupInfo.push(grp)
        } else {
          if(this.groupInfo[idx].default != 1) {
            this.groupInfo[idx] = grp
          }
        }
      })

      if(TymConst.IS_DEVELOPMENT) {
        console.log('getGroupInfoOK :', this.groupInfo)
      }
      this.groupInfo.sort(function(a, b)  {
        if(a.group_a == b.group_a) {
          if(a.group_b == b.group_b) {
            if(a.group_c == b.group_c) {
              if(a.group_d == b.group_d) {
                return 0
              } else {
                if(a.group_d > b.group_d)
                  return 1
                else if(a.group_d < b.group_d)
                  return -1
              }
            } else {
              if(a.group_c > b.group_c)
                return 1
              else if(a.group_c < b.group_c)
                return -1
            }
          } else {
            if(a.group_b > b.group_b)
              return 1
            else if(a.group_b < b.group_b)
              return -1
          }
        } else {
          if(a.group_a > b.group_a)
            return 1
          else if(a.group_a < b.group_a)
            return -1
        }
        return 0
      })

      let found = this.groupInfo.find(x => x.group_b == "TYMICT")
      if(found)
        this.tymictGroup = found

      found = this.groupInfo.find(x => x.uuid_b == "5")
      if(found)
        this.productGroup = found
  
      TymAws.getGroupNameFromUuid(this.AuthUser.attributes['custom:uuid_a'], this.AuthUser.attributes['custom:uuid_b'], this.AuthUser.attributes['custom:uuid_c'], this.AuthUser.attributes['custom:uuid_d'])
        .then(data => {
          // console.log('getGroupNameFromUuid :', data)
          if(this.isICTUser()) {
            this.groupName = this.tymictGroup.group_b
          } else {
            this.groupName = data
          }
        })
        .catch(er => {
          console.log('TymAws.getGroupNameFromUuid ER :', er)
        })

      // console.log('getGroupInfoOK', this.groupInfo, this.tymictGroup)
      this.manageGroupTree()
    },
    getGroupInfoFail(err) {
      console.log('GroupInfo failed : ', err)
      this.groupInfo = []
      this.groupTree = []
    },
    /* 모든 그룹정보를 가져온다. */
    loadGroupInfo() {
      try {
        TymAws.getAllGroupInfo()
          .then( this.getGroupInfoOK)
          .catch(this.getGroupInfoFail)
      } catch(er) {
        console.log('loadGroupInfo', er)
      }
    },
    findGroupByUUID(uuid_a, uuid_b, uuid_c, uuid_d) {
      if(this.groupInfo.length < 1) {
        return ''
      }

      let findGrp = this.groupInfo.find(item => {
        if(!TymCommon.isEmpty(uuid_a)) {
          if(!TymCommon.isEmpty(uuid_b)) {
            if(!TymCommon.isEmpty(uuid_c)) {
              if(!TymCommon.isEmpty(uuid_d)) {
                if(item.uuid_d == uuid_d)
                  return true
              } else {
                if(item.uuid_c == uuid_c)
                  return true
              }
            } else {
              if(item.uuid_b == uuid_b)
                return true
            }
          } else {
            if(item.uuid_a == uuid_a)
              return true
          }
        }
      })

      let findGrpName = ''
      if(findGrp) {
        if(findGrp.uuid_d == uuid_d) {
          findGrpName = findGrp.group_d
        } else if(findGrp.uuid_c == uuid_c) {
          findGrpName = findGrp.group_c
        } else if(findGrp.uuid_b == uuid_b) {
          findGrpName = findGrp.group_b
        } else if(findGrp.uuid_a == uuid_a) {
          findGrpName = findGrp.group_a
        }
      }
      return findGrpName
    },
    // 조직도 트리에서 group에 담긴 자기 자신과 자식들의 이름들을 구한다.
    // depth : 선택한 그룹의 Depth, 0~3
    getMyGroupNames(depth, group) {
      let myGrpNames = []
      let childGrpName = null, childUUID = null
      let itsMe = null

      this.groupInfo.forEach(x => {
        childGrpName = null
        childUUID = null
        if(this.isICTUser() || x.group_b != 'TYMICT') {
          switch(depth) {
            case 0:
                if (x.uuid_a == group.uuid_a && TymCommon.isEmpty(x.uuid_b)) {
                  if(group.uuid_a != x.uuid_a) {
                    childGrpName = x.group_a
                    childUUID = x.uuid_a
                  } else {
                    itsMe = {
                      Name : group.group_a,
                      UUID : group.uuid_a
                    }
                  }
                } else if(x.uuid_a == group.uuid_a && TymCommon.isEmpty(x.uuid_c)) {
                  childGrpName = x.group_b
                  childUUID = x.uuid_b
                }
              break;
            case 1:
              if (x.uuid_b == group.uuid_b && TymCommon.isEmpty(x.uuid_c)) {
                if(group.uuid_b != x.uuid_b) {
                  childGrpName = x.group_b
                  childUUID = x.uuid_b
                } else {
                  itsMe = {
                    Name : group.group_b,
                    UUID : group.uuid_b
                  }
                }
              } else if(x.uuid_b == group.uuid_b && !TymCommon.isEmpty(x.uuid_c)) {
                childGrpName = x.group_c
                childUUID = x.uuid_c
              }
              break;
            case 2:
              if (x.uuid_c == group.uuid_c && TymCommon.isEmpty(x.uuid_d)) {
                if(group.uuid_c != x.uuid_c) {
                  childGrpName = x.group_c
                  childUUID = x.uuid_c
                } else {
                  itsMe = {
                    Name : group.group_c,
                    UUID : group.uuid_c
                  }
                }
              } else if(x.uuid_c == group.uuid_c) {
                childGrpName = x.group_d
                childUUID = x.uuid_d
              }
              break;
            case 3:
              if(x.uuid_d == group.uuid_d) {
                childGrpName = x.group_d
                childUUID = x.uuid_d
              }
              break;
          }

          if(childGrpName) {
            if(!myGrpNames.find(x => x.Name == childGrpName)) {
              myGrpNames.push({
                Name : childGrpName,
                UUID : childUUID
              })
            }
          }
        }
      })
      
      myGrpNames.sort((a, b) => {
        return a.Name.localeCompare(b.Name)
      })

      if(depth > 1) {
        if(itsMe)
          myGrpNames.splice(0, 0, itsMe)
      } else if(myGrpNames.length < 1) {
        if(itsMe)
          myGrpNames.push(itsMe)
      }
      // console.log('getMyGroupNames', depth, group, myGrpNames)
      return myGrpNames
    },
    findMachineBySN(sn) {
      try {
        if(this.machineLocaInfo) {
          let machines = this.machineLocaInfo.find( x => x.serialNo == sn)
          return machines
        }
      } catch(err) {
        console.log('findMachineBySN error :', err)
      }
      return null
    },
    findMachineIndicesBySN(sn) {
      let indices = null
      try {
        //console.log('findMachineIndicesBySN #1: ', sn, this.machineLocaInfo)
        let i  = 0
        for(i = 0; i < this.machineLocaInfo.length; i++) {
          //console.log('findMachineIndicesBySN #2:', sn, this.machineLocaInfo[i].serialNo)
          if(this.machineLocaInfo[i].serialNo == sn) {
            if(indices == null) {
              indices = []
            }
            indices.push(i)
          }
        }
      } catch(err) {
        indices = null
        console.log('findMachineIndicesBySN error :', err)
      }
      return indices
    },

    // 모델DB에서 잘 가져옴
    getModelInfoOK(data) {
      let tempModelData = []

      if(TymConst.IS_DEVELOPMENT) {
        console.log('STORE.getModelInfoOK :', data)
      }

      for(let i = 0; i < data.length; i++) {
        if(!this.isICTUser()) {
          if(Number(data[i].development) == 1) {
            continue;
          }
        }

        // console.log('model info :', data[i])
        let model = {
          model : data[i].model,
          option : data[i].option,
          type : data[i].type,
          logo : data[i].logo,
          hp : data[i].hp,
          ads_type : data[i].ads_type,
          development : data[i].development,
          description : data[i].description,
          use_st : data[i].use_st,
          citems : [],
          viewOptions: data[i].viewOptions
        }
        for(let j = 1; j <= TymConst.MAX_CONSUMABLE_COUNT; j++) {
          let str = j.toString().padStart(2, '0')
          let field_cc = 'citem'+ str + '_cc'
          let field_cf = 'citem'+ str + '_cf'
          let field_eb = 'citem'+ str + '_eb'
          let field_sn = 'citem'+ str + '_sn'
          let field_nameKr = 'citem'+ str + '_name'
          let field_nameEn = 'citem'+ str + '_name_en'

          if(TymCommon.isEmpty(data[i][field_cc]) && TymCommon.isEmpty(data[i][field_cf]) && TymCommon.isEmpty(data[i][field_eb])) {
            // 세가지 모두 비어있다면 없는 것으로 간주하고 해당 소모품 정보는 버림
          } else {
            let nameKr = '', nameEn = ''
            let found = this.ModelList.data.find(x => x.model == data[i].model)
            if(found) {
              console.log('==> ', found)
            }

            if(TymCommon.isEmpty(data[i][field_nameKr])) {
              // 한글이름이 없어
            } else {
              nameKr = data[i][field_nameKr]
            }
            if(TymCommon.isEmpty(data[i][field_nameEn])) {
              // 영어이름이 없어
            } else {
              nameEn = data[i][field_nameEn]
            }
            model.citems.push({
              cc : data[i][field_cc],
              cf : data[i][field_cf],
              enable : data[i][field_eb],
              name : [
                nameKr,         //'한글'
                nameEn         //'영어'
              ],
              sn : data[i][field_sn],
            })
          }
        }
          
        tempModelData.push(model)
      }
      this.ModelData.data = tempModelData
      this.ModelData.count = tempModelData.length

      if(TymConst.IS_DEVELOPMENT) {
        console.log('Model Info :', data, this.ModelData.data )
      }
    },
    getModelInfoFail(err) {
      console.log('getModelInfoFail', err)
    },

    loadModelDBCompleted(data) {
      this.getModelInfoOK(data)
    },
// 모든 모델 정보 구하기...
    getModelInfo(cbCompleted) {
      try {
        this.ModelData = {
          count : 0,
          data : [],
        }

        // 모델DB에서 가져옴
        TymAws.getModelInfo()
          .then(data => {
            this.loadModelDBCompleted(data)
            if(cbCompleted) {
              cbCompleted(data)
            }
          })
          .catch(this.getModelInfoFail)
      } catch(er) {
        console.log('getModelInfo', er)
      }
    },

    getModelList(cbCompleted) {
      this.ModelList = { count : 0, data : [] }

      TymAws.getModelList()
        .then(data => {
          this.ModelList.data = data[0].CVAL
          if(TymConst.IS_DEVELOPMENT) {
            console.log('store.getModelList FIRST :', this.ModelList.data)
          }

          for(let i = 0; i < this.ModelList.data.length; i++) {
            if(TymCommon.isEmpty(this.ModelList.data[i].default_citem)) {
              this.ModelList.data[i].default_citem = []
              for(let j = 0; j < this.ModelList.data[i].doption.length; j++) {
                this.ModelList.data[i].default_citem.push({})
              }
            }
            for(let j = 0; j < this.ModelList.data[i].doption.length; j++) {
              if(!Object.keys(this.ModelList.data[i].default_citem[j]).length) {
                // 비어 있군요.
                this.ModelList.data[i].default_citem[j].citem_cycle = [
                  [100, 500],
                  [500, 500],
                  [500, 200],
                  [200, 300],
                  [50, 300],
                  [50, 300],
                  [50, 300]
                ]
                this.ModelList.data[i].default_citem[j].name = [
                  ["엔진 오일 및 카트리지", "Engine Oil and Cartridges"],
                  ["연료 필터(A)", "Fuel Filter (A)"],
                  ["연료 필터(B)", "Fuel Filter (B)"],
                  ["에어클리너 엘리먼트", "Air Cleaner Element"],
                  ["트랜스미션 오일", "Transmission Oil"],
                  ["유압 오일 필터", "Hydraulic Oil Filter"],
                  ["프론트 액슬 오일", "Front Axle Oil"]
                ]
              } else {
                //console.log(i, j, 'Not empty :', this.ModelList.data[i].default_citem[j])
              }                
            }
          }
          this.ModelList.count = this.ModelList.data.length

          if(TymConst.IS_DEVELOPMENT) {
            console.log('store.getModelList SECOND :', this.ModelList)
          }

          function loadModelCompleted(data) {
            TymCommon.isEmpty(data)
            if(cbCompleted) {
              cbCompleted()
            }
          }
          // 모델정보
          this.getModelInfo(loadModelCompleted)

      })
        .catch(err => {
          console.log('getModelListFail ', err)
          this.ModelList = { count : 0, data : [] }
        })
    },

    // Sales 리스트 가져옴
    getGroupSalesData(cbFinished, cbEmergency, cbBroken, cbConsumable, cbLowBattery) {
      this.SalesData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      // var proxyUser = TymAuth.getProxyUserForMain(this.AuthUser.attributes['custom:ms']);      
      //console.log('getGroupSalesData :', this, proxyUser, this.AuthUser)

      TymAws.getGroupSalesData(parseInt(this.AuthUser.attributes['custom:ms']),
        this.AuthUser.attributes['custom:uuid_a'], this.AuthUser.attributes['custom:uuid_b'], this.AuthUser.attributes['custom:uuid_c'], this.AuthUser.attributes['custom:uuid_d'])
        .then(data => {
          this.SalesData.data = []
          /*
          {
              "ST": "0",
              "MN": "TYMICT_A2216_15",
              "HP": "010-5064-2608",
              "MON": "RGO700_0",
              "ADS_LV": "2",
              "UID": "jjh1004",
              "SD": "2022-11-08T00:00",
              "MA": "0",
              "PD": "2022-11",
              "MB": "1641201354576-fing0828",
              "ADS_TYPE": "EPS_R",
              "MC": " ",
              "MD": " ",
              "USER": "조준희",
              "SM": "1641201354576-fing0828",
              "SN": "A001221600150015",
              "ADDR": "대전 유성구 계산동 805-14 "
          }
          */
          if(TymConst.IS_DEVELOPMENT) {
            console.log('STORE.getGroupSalesData ###1 :', data, this.ModelList.data, this.ModelData.data)
          }

          let i = 0, idx = 0
          for(i = 0; i < data.length; i++) {
            if(!this.isICTUser()) {
              if(!TymCommon.isEmpty(this.tymictGroup)) {
                if((this.tymictGroup.uuid_a == data[i].MA) && (this.tymictGroup.uuid_b == data[i].MB))
                  continue
              }
            }
            let grp = this.findGroupByUUID(data[i].MA, data[i].MB, data[i].MC, data[i].MD)
            let modelInfo = TymCommon.getModelFullname(data[i].MON)
            let modelData = this.ModelData.data.find(x => x.model == modelInfo.Model)
            let machType = TymCommon.isEmpty(data[i].DTYPE) ? null : data[i].DTYPE

            if(modelData) {
              if(!TymCommon.isEmpty(modelData.DTYPE)) {
                machType = modelData.DTYPE
              }
            } else {
              modelData = this.ModelList.data.find(x => x.dmodel == modelInfo.Model)
              if(modelData) {
                if(!TymCommon.isEmpty(modelData.dtype)) {
                  machType = modelData.dtype
                }
              }
            }


            let expireDate = ' '
            if(TymCommon.isEmpty(data[i].ED)) {
              expireDate = moment(data[i].SD)
              expireDate = expireDate.add(5, 'years')
              expireDate = expireDate.add(-1, 'days')
              expireDate = expireDate.format('YYYY-MM-DDThh:mm')
              //console.log('1111 ', data[i].SD, expireDate)
            } else {
              expireDate = data[i].ED
              //console.log('2222 ', data[i].MN, data[i].SD, data[i].ED)
            }
            this.SalesData.data.push({
              index : idx,
              MON : data[i].MON,
              model : modelInfo.Fullname,
              machineNo : data[i].MN,
              PD : data[i].PD,
              manDate : TymCommon.PD2DateString(data[i].PD),
              serialNo : data[i].SN,
              adsLevel : Number(data[i].ADS_LV),
              adsType : data[i].ADS_TYPE,
              mobile : data[i].HP,
              dealer : grp,
              SD : data[i].SD,
              ED : expireDate,
              saleDate : TymCommon.isEmpty(data[i].SD) ? '' : TymCommon.SD2DateString(data[i].SD),
              address : data[i].ADDR,
              uid : data[i].UID,
              owner : data[i].USER,
              uuid_a : data[i].MA,
              uuid_b : data[i].MB,
              uuid_c : data[i].MC,
              uuid_d : data[i].MD,
              SM : data[i].SM,
              DTYPE : data[i].DTYPE,
              machineType : machType,
              ST : data[i].ST,
            })
            idx++
          }
          
          this.SalesData.count = this.SalesData.data.length

          if(TymConst.IS_DEVELOPMENT) {
            console.log('Sale List : ', this.SalesData)
          }
          this.refreshMainItems(cbEmergency, cbBroken, cbConsumable, cbLowBattery)

          // this.arrangeSalesAndUsers()
          if(!TymCommon.isEmpty(cbFinished)) {
            cbFinished(true)
          }
      })
      .catch(err => {
        console.log('Store.getGroupSalesData ERROR :', err)
        if(!TymCommon.isEmpty(cbFinished)) {
          cbFinished(false)
        }
      })
    },

    // 판매목록에서 Cognito에 포함된 사용자들을 추출한다.
    // 중복 사용자 제거...
    manageGroupUsers(incLocaInfo) {
      if( (this.userList.length < 1) || (this.SalesData.data.length < 1) ) {
        console.log('Store.manageGroupUsers : no user or salesdata.')
        setTimeout(() => {
          if(incLocaInfo) {
            console.log('### loadCurrentPos ###')
            this.loadCurrentPos()
          }
        }, 250)
        return
      }
      // console.log('manageGroupUsers :', this.userList, this.SalesData.data)
      this.usersInMyGroup = []
      for(let i = 0; i < this.SalesData.data.length; i++) {
        let repl = this.usersInMyGroup.find(x => x.uid == this.SalesData.data[i].uid)
        if(repl) {
          continue
        }

        let found = this.userList.find(x => x.userid == this.SalesData.data[i].uid)
        // Cognito에 있으면
        /*
        {
            "createat": "2023-03-07T06:06:52.817Z",
            "userid": "tym9951",
            "sub": "0279f6e4-6563-4ac6-96f8-77e59ab39c1c",
            "custom:cpinpw": "000000",
            "email_verified": "true",
            "gender": "M",
            "custom:cbirthday": "20230307",
            "custom:csn": "T001210400060006",
            "name": "최삼섭",
            "phone_number_verified": "true",
            "custom:caddress1": "전북 정읍시 덕천면 천월길 24-21",
            "phone_number": "+821094449951",
            "email": "null@tymict.com"
        }
        */
        if(found) {
          // console.log('Store.manageGroupUsers :', found, this.SalesData.data[i])
          this.usersInMyGroup.push({
            name : this.SalesData.data[i].owner,
            group : this.SalesData.data[i].dealer,
            uid : this.SalesData.data[i].uid,
            email : found.email,
            email_verified : found.email_verified,
            mobile : this.SalesData.data[i].mobile,
            mobile_verified : found.phone_number_verified,
            address : this.SalesData.data[i].address,
            uuid_a : this.SalesData.data[i].uuid_a,
            uuid_b : this.SalesData.data[i].uuid_b,
            uuid_c : this.SalesData.data[i].uuid_c,
            uuid_d : this.SalesData.data[i].uuid_d,
          })
        }
      }
      // console.log('Store.manageGroupUsers :', this.usersInMyGroup)

      if(incLocaInfo) {
        console.log('### loadCurrentPos ###')
        this.loadCurrentPos()
      }
    },
    canDownloadToExcel() {
      if(this.isICTUser())
        return true

      if(!TymCommon.isEmpty(this.idToken["userid"])) {
        if( (this.idToken["userid"].toUpperCase() == 'MYTYMCS01')
          || (this.idToken["userid"].toUpperCase() == 'CCC123')
          || (this.idToken["userid"].toUpperCase() == 'JAEYEONKIM')
        )
          return true
      }
      
      return false
    },

    isICTUser() {
      try {
        if(!TymCommon.isEmpty(this.idToken['custom:ms'])) {
          let ms = parseInt(this.idToken['custom:ms'])
          return (ms == 99)
        }
      } catch(er) {
        console.log('Store.isICTUser error :', er)
      }
      return false;
    },
    // Kr - TYM/기계생산본부
    // us - TYM NA/Machine Production
    isProductUser() {
      try {
        /*
        if(!TymCommon.isEmpty(this.idToken['custom:ms'])) {
          let ms = parseInt(this.idToken['custom:ms'])
          return (ms == 5)
        }
        */
        if(!TymCommon.isEmpty(this.idToken['custom:uuid_a']) && !TymCommon.isEmpty(this.idToken['custom:uuid_b'])) {
          //console.log('--------------------')
          //console.log(this.idToken['custom:uuid_a'], this.idToken['custom:uuid_b'])
          return ((this.idToken['custom:uuid_a'] == "0") && (this.idToken['custom:uuid_b'] == "5"))
        }
      } catch(er) {
        console.log('Store.isProductUser error :', er)
      }
      return false;
    },

    async getTimezoneList() {
      this.timezoneList = []

      await TymAws.getTimezoneList()
      .then(data => {
        /*
        {
          "dis_kr": "미드웨이",
          "name_kr": "사모아 시간",
          "dis_en": "Midway",
          "value": "Pacific/Midway",
          "gmt": "GMT-11:00",
          "name_en": "Samoa Time"
        }
        ==>
        {
          display_name : [
            dis_kr
            dis_en
          ],
          name : [
            name_kr,
            name_en
          ],
          value,
          gmt
        }
        */
        let items = []
        if(Array.isArray(data))
          items = data[0].CVAL
        else 
          items = data.CVAL

        //console.log('getTimezoneList #1:', data, items)

        // 자동항목 추가
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;     // Asia/Seoul
        const varMoment = moment().tz(currentTimezone)    // KST
        const mtz = moment()
        const currentGmt = "GMT" + varMoment.format("Z");
        console.log("TZ : ", varMoment, mtz, currentGmt)

        this.timezoneList.push( {
          'display_name' : [
            '자동 설정',
            'Automatic Setting'
          ],
          'name' : [
            varMoment,
            varMoment
          ],
          'value' : currentTimezone,
          'gmt' : currentGmt
        })

        for(let i = 0; i < items.length; i++) {
          this.timezoneList.push( {
            'display_name' : [
              TymCommon.isEmpty(items[i].dis_kr) ? '' : items[i].dis_kr,
              TymCommon.isEmpty(items[i].dis_en) ? '' : items[i].dis_en,
            ],
            'name' : [
              TymCommon.isEmpty(items[i].name_kr) ? '' : items[i].name_kr,
              TymCommon.isEmpty(items[i].name_en) ? '' : items[i].name_en,
            ],
            'value' : items[i].value,
            'gmt' : items[i].gmt
          })
        }
        // console.log('getTimezoneList #2:', this.timezoneList)
      })
      .catch(er=>{
        console.log('getTimezoneList error :', er)
      })
    },

    async getLocalIp() 
    {
      var result = await new Promise((resolve, reject)=> {
        console.log(typeof(resolve), typeof(reject))
        fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
            // this.term = ip;
            resolve(ip)
        });
      })
      return result;
    },

    getCustomerData(cbLoadCompleted) {
      try {
        this.AllCustomerData = []
        this.CustomerData = []
        TymAws.getAllUsers()
          .then(data => {
            this.AllCustomerData = data
            // this.CustomerData = data
            this.arrangeSalesAndUsers()
            if(!TymCommon.isEmpty(cbLoadCompleted)) {
              cbLoadCompleted(true)
            }
          }).catch(er => {
            console.log('TymAws.getAllUsers ER:', er)
            if(!TymCommon.isEmpty(cbLoadCompleted)) {
              cbLoadCompleted(false)
            }
          })
        } catch(ex){
          console.log('getWebConfig ERR#2 :', ex)
        }
    },

    getNoticeCount() {
      this.noticeCount = null
      this.getUnreadNotceCount().then(data => {
        this.noticeCount = data.NoticesCount
      })
    },
    async getUnreadNotceCount() {
      let payload = {
        userId : this.idToken.userid,
        isAdmin : 1,
        MA : this.idToken['custom:uuid_a'],
        receiveLevel : 7
      }
      if(!TymCommon.isEmpty(this.idToken['custom:uuid_b'])) {
        payload.MB = this.idToken['custom:uuid_b']
        if(!TymCommon.isEmpty(this.idToken['custom:uuid_c'])) {
          payload.MC = this.idToken['custom:uuid_c']
          if(!TymCommon.isEmpty(this.idToken['custom:uuid_d'])) {
            payload.MD = this.idToken['custom:uuid_d']
            payload.receiveLevel = 4
          }
        }
      }
      console.log('getnoticecount :', payload)
      if(TymCommon.isEmpty(this.idToken.userid)) {
        return
      }
      
      var tpayload = JSON.stringify(payload);
      var lambdaParam = {
          FunctionName : 'tymict_get_new_notices_count',
          InvocationType : 'RequestResponse',
          Payload: tpayload
      };
      
      var result = await new Promise((resolve,reject)=>{
        this.docLambda.invoke(lambdaParam, async function(err, data) {
          if (err) {
            console.log('getUnreadNotceCount result error => ', err)
            reject(err)
          } else if (TymCommon.isEmpty(data)) {
            console.log('getUnreadNotceCount data is null')
            reject(err)
          } else {
            let jsonData = JSON.parse(data.Payload)
            // console.log('getUnreadNotceCount :', jsonData)
            resolve(jsonData)
          }        
        })
      })
      return result;
    },

    async readOneNotice(contentID) {
      let payload = {
        userId : this.idToken.userid,
        isAdmin : 1,
        ContentId : contentID
      }
      var tpayload = JSON.stringify(payload);
      var lambdaParam = {
          FunctionName : 'tymict_get_notice',
          InvocationType : 'RequestResponse',
          Payload: tpayload
      };
      //console.log('readOneNotice :', lambdaParam)
      
      var result = await new Promise((resolve,reject)=>{
        this.docLambda.invoke(lambdaParam, async function(err, data) {
          if (err) {
            console.log('readOneNotice result error => ', err)
            reject(err)
          } else if (TymCommon.isEmpty(data)) {
            console.log('readOneNotice data is null')
            reject("Empty response")
          } else {
            let jsonData = JSON.parse(data.Payload)
            if(!TymCommon.isEmpty(jsonData.Items)) {
              resolve(jsonData.Items)
            } else {
              reject("Empty array.")
            }
          }        
        })
      })
      return result;
    },


    async changeNoticeState(noticeIDs, isFavorite, isHidden) {
      let payload = {
        userId : this.idToken.userid,
        isAdmin : 1,
        AlarmType : 6,
        ContentIds : noticeIDs
      }
      if(!TymCommon.isEmpty(isFavorite)) {
        payload.isFavorite = isFavorite ? 1 : 0
      }
      if(!TymCommon.isEmpty(isHidden)) {
        payload.isHidden = isHidden ? 1 : 0
      }
      let tpayload = JSON.stringify(payload)
      let lambdaParam = {
          FunctionName : 'tymict_set_post_interactions',
          InvocationType : 'RequestResponse',
          Payload: tpayload
      }
      
      var result = await new Promise((resolve,reject)=>{
        this.docLambda.invoke(lambdaParam, async function(err, data) {
          if (err) {
            console.log('changeNoticeState result error => ', err)
            reject(err)
          } else if (TymCommon.isEmpty(data)) {
            console.log('changeNoticeState data is null')
            reject("Empty response")
          } else {
            let jsonData = JSON.parse(data.Payload)
            console.log('changeNoticeState', noticeIDs, isFavorite, isHidden, jsonData)
            if(jsonData.statusCode == 200) {
              resolve('OK')
            } else {
              reject("Empty array.")
            }
          }        
        })
      })
      return result;
    },
    getUserLevel() {
      let ms = 0
      if(!TymCommon.isEmpty(this.idToken['custom:ms'])) {
        ms = parseInt(this.idToken['custom:ms'])
      }

      let level = 0
      if(ms != 99) {    // !ict?
        if(!TymCommon.isEmpty(this.idToken['custom:uuid_d'])) {
          level = 3
        } else if(!TymCommon.isEmpty(this.idToken['custom:uuid_c'])) {
          level = 2
        } else if(!TymCommon.isEmpty(this.idToken['custom:uuid_b'])) {
          level = 1
        }
      }
      return level
    },
    getUserCount() {
      if(this.getUserLevel() == 0) {
        return this.userList.length
      } else {
        return this.usersInMyGroup.length
      }
    },
    getUserList() {
      if(this.getUserLevel() == 0) {
        return this.userList
      } else {
        return this.usersInMyGroup
      }
    },
    arrangeSalesAndUsers() {
      if(this.AllCustomerData.length > 0 && this.SalesData.data.length > 0) {
        let temp = []
        this.AllCustomerData.forEach(customer => {
          let found = this.SalesData.data.find(x => x.uid == customer.userid)
          if(found) {
            temp.push(customer)
          }
        })
        if(temp.length > 0)
          this.CustomerData = temp
      }
      // console.log('Sales & Users :', this.SalesData.data, this.CustomerData)
    },
    getADSInfo() {
      const self = this
      TymAws.getADSInfo()
        .then(data => {
          self.WebConfig = {
            ADSLevel : [],
            ADSValue : [],
            ADSLevelDefault : [],
          }
          let forDisplay = [], forValue = []
          let lvlDisplay = [], lvlValue = []    // Autonomouse Driving LEVEL
          let lvlDefault = []
          let asDisplay = [], asValue = []    // Auto Streer Display, Value용

          data[0].CVAL[0].dtype_dis.forEach(dev => {
            forDisplay.push(dev)
          })
          data[0].CVAL[0].dtype_val.forEach(dev => {
            forValue.push(dev)
          })
          self.WebConfig.MachineTypes = [ forDisplay, forValue]


          data[0].CVAL[0].lv_dis.forEach(level => {
            lvlDisplay.push(level)
          })
          data[0].CVAL[0].lv_val.forEach(level => {
            lvlValue.push(level)
          })
          data[0].CVAL[0].type_def.forEach(defValue => {
            lvlDefault.push(defValue)
          })
          self.WebConfig.ADSLevels = [lvlDisplay, lvlValue]
          self.WebConfig.ADSLevelDefault = lvlDefault

          data[0].CVAL[0].type_dis.forEach(asType => {
            asDisplay.push(asType)
          })
          data[0].CVAL[0].type_val.forEach(asVal => {
            asValue.push(asVal)
          })
          self.WebConfig.AutoSteer = [asDisplay, asValue]
          
          // console.log('ADS :', self.WebConfig)
        })
        .catch(ex => {
          console.log('ADS FAIL:', ex)
        })
    },
    getMachineTypes() {
      const i18n = useI18n()
      let result = []
  
      result.push(i18n.t('common.type'))
  
      if(!TymCommon.isEmpty(this.WebConfig)) {
        for(let idx = 1; idx < this.WebConfig.MachineTypes[0].length; idx++) {
          result.push(this.WebConfig.MachineTypes[0][idx])
        }
      }
      // console.log('store.getMachineTypes()', result)
      return result
    },
    getSubGroups(uuid) {
      if(TymCommon.isEmpty(this.idToken["custom:uuid_a"]))
        return
      let group = this.groupInfo.find(x => x.uuid == uuid)
      if(TymCommon.isEmpty(group))
        return

      let depth = -1
      let subGrpNames = [], tmpGrps = null
      if(TymCommon.isEmpty(group.uuid_d)) {
        if(TymCommon.isEmpty(group.uuid_c)) {
          if(TymCommon.isEmpty(group.uuid_b)) {
            depth = 0

            if(this.isICTUser()) {
              tmpGrps = this.groupInfo.filter(x => x.uuid_a == group.uuid_a && TymCommon.isEmpty(x.uuid_b))
              if(tmpGrps) {
                // TYM
                tmpGrps.forEach(grp => {
                  if(!subGrpNames.find(x => x == grp.group_a)) {
                    if(!TymCommon.isEmpty(grp.group_a))
                      subGrpNames.push(grp.group_a)
                  }
                })
              }
            }

            tmpGrps = this.groupInfo.filter(x => x.uuid_a == group.uuid_a && TymCommon.isEmpty(x.uuid_c))
            if(tmpGrps) {
              tmpGrps.sort((a, b) => {
                return a.group_b.localeCompare(b.group_b)
              })
              tmpGrps.forEach(grp => {
                if(!this.isICTUser() && (grp.uuid_a == this.tymictGroup.uuid_a) && (grp.uuid_b == this.tymictGroup.uuid_b))
                  return

                if(!subGrpNames.find(x => x == grp.group_b)) {
                  if(!TymCommon.isEmpty(grp.group_b))
                    subGrpNames.push(grp.group_b)
                }
              })
            }
          } else {
            depth = 1

            if(this.isICTUser()) {
              tmpGrps = this.groupInfo.filter(x => x.uuid_b == group.uuid_b && TymCommon.isEmpty(x.uuid_c))
              if(tmpGrps) {
                tmpGrps.forEach(grp => {
                  if(!subGrpNames.find(x => x == grp.group_b)) {
                    if(!TymCommon.isEmpty(grp.group_b))
                      subGrpNames.push(grp.group_b)
                  }
                })
              }
            }

            tmpGrps = this.groupInfo.filter(x => x.uuid_b == group.uuid_b && TymCommon.isEmpty(x.uuid_d))
            if(tmpGrps) {
              tmpGrps.sort((a, b) => {
                return a.group_c.localeCompare(b.group_c)
              })
              tmpGrps.forEach(grp => {
                if(!this.isICTUser()) {
                  if(!TymCommon.isEmpty(this.tymictGroup)) {
                    if((grp.uuid_a == this.tymictGroup.uuid_a) && (grp.uuid_b == this.tymictGroup.uuid_b))
                      return
                  }
                }

                if(!subGrpNames.find(x => x == grp.group_c)) {
                  if(!TymCommon.isEmpty(grp.group_c))
                    subGrpNames.push(grp.group_c)
                }
              })
            }
          }
        } else {
          depth = 2
          tmpGrps = this.groupInfo.filter(x => x.uuid_c == group.uuid_c)
          if(tmpGrps) {
            tmpGrps.forEach(grp => {
              if(!subGrpNames.find(x => x == grp.group_c)) {
                subGrpNames.push(grp.group_c)
              }
            })
          }

          tmpGrps = this.groupInfo.filter(x => x.uuid_c == group.uuid_c && !TymCommon.isEmpty(x.uuid_d))
          if(tmpGrps) {
            tmpGrps.sort((a, b) => {
              return a.group_d.localeCompare(b.group_d)
            })
            tmpGrps.forEach(grp => {
              if(!this.isICTUser()) {
                if(!TymCommon.isEmpty(this.tymictGroup)) {
                  if((grp.uuid_a == this.tymictGroup.uuid_a) && (grp.uuid_b == this.tymictGroup.uuid_b))
                    return
                }
              }

              if(!subGrpNames.find(x => x == grp.group_d)) {
                subGrpNames.push(grp.group_d)
              }
            })
          }
        }
      } else {
        depth = 3
        tmpGrps = this.groupInfo.filter(x => x.uuid_d == group.uuid_d)
        if(tmpGrps) {
          tmpGrps.forEach(grp => {
            if(!subGrpNames.find(x => x == grp.group_d)) {
              if(!TymCommon.isEmpty(grp.group_d))
                subGrpNames.push(grp.group_d)
            }
          })
        }
      }

      if(subGrpNames.length < 1)
        return null

      return {
        Depth : depth,
        Names : subGrpNames
      }
    },
    getPowerState(cbSuccess, cbFailure) {
      TymAws.getStartedMachines()
        .then(data => {
          if(!TymCommon.isEmpty(this.machineLocaInfo)) {
            for(let idx = 0; idx < this.machineLocaInfo.length; idx++) {
              let power = data.find(x => x.SN == this.machineLocaInfo[idx].serialNo)
              if(power) {
                this.machineLocaInfo[idx].PWR = 'ON'
              } else {
                this.machineLocaInfo[idx].PWR = 'OFF'
              }
            }
          }
          if(TymConst.IS_DEVELOPMENT) {
            console.log("store.getPowerState :", data, this.machineLocaInfo.filter(x => x.PWR=='ON'))
          }
          if(!TymCommon.isEmpty(cbSuccess)) {
            cbSuccess(data)
          }
        })
        .catch(error => {
          console.log("started Er :", error)
          if(!TymCommon.isEmpty(cbFailure)) {
            cbFailure()
          }
        })
    },
    // 위치정보 수신시 긴급, 고장, 소모품, 저전압 데이터 정리함
    arrangeLocaAndAlarm() {
      let newEM = [], newBR = [], newCONS = [], newLB = []
      let newLOCA = []

      this.machineLocaInfo.forEach(loca => {
        let sale = this.SalesData.data.find(x => x.machineNo == loca.machineNo)
        if(sale) {
          newLOCA.push(loca)
        }
      })

      if(TymConst.IS_DEVELOPMENT) {
        console.log('STORE.arrangeLocaAndAlarm :', newLOCA)
      }

      this.EmergencyData.data.forEach(one => {
        if(this.SalesData.data.find(x => x.machineNo == one.MN)) {
          newEM.push(one)
        }
      })

      this.BrokenData.data.forEach(one => {
        if(this.SalesData.data.find(x => x.machineNo == one.MN)) {
          newBR.push(one)
        } else {
          console.log('NOT found BR ', one)
        }
      })

      this.ConsumablesData.data.forEach(one => {
        if(this.SalesData.data.find(x => x.machineNo == one.MN)) {
          newCONS.push(one)
        }
      })

      this.BatteryAlertData.data.forEach(one => {
        if(this.SalesData.data.find(x => x.machineNo == one.MN)) {
          newLB.push(one)
        } else {
          console.log('NOT found LB ', one)
        }
      })

      this.machineLocaInfo = newLOCA

      this.EmergencyData.data = newEM

      this.BrokenData.data = newBR

      this.ConsumablesData.data = newCONS

      this.BatteryAlertData.data = newLB

      if(TymConst.IS_DEVELOPMENT) {
        console.log('new MACH LOCA DATA :', this.machineLocaInfo)
        // console.log('new EM DATA :', this.EmergencyData.data)
        console.log('new BR DATA :', this.BrokenData.data)
        // console.log('new CONS DATA :', this.ConsumablesData.data)
        // console.log('new LB DATA :', this.BatteryAlertData.data)
      }

      /*
      // V1
      // console.log('arrangeLocaAndAlarm : ', this.EmergencyData.data, this.BrokenData.data, this.machineLocaInfo)
      this.EmergencyData.data.forEach(one => {
        if(this.machineLocaInfo.find(x => x.machineNo == one.MN)) {
          newEM.push(one)
        } else {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('Not Found EM :', one)
          }
        }
      })
      this.EmergencyData.data = newEM
      if(TymConst.IS_DEVELOPMENT) {
        console.log('new EM data :', newEM)
      }

      this.BrokenData.data.forEach(one => {
        if(this.machineLocaInfo.find(x => x.machineNo == one.MN)) {
          newBR.push(one)
        } else {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('Not Found BR:', one)
          }
        }
      })
      this.BrokenData.data = newBR
      if(TymConst.IS_DEVELOPMENT) {
        if(TymConst.IS_DEVELOPMENT) {
          console.log('new BR data :', newBR)
        }
      }

      this.ConsumablesData.data.forEach(one => {
        if(this.machineLocaInfo.find(x => x.machineNo == one.MN)) {
          newCONS.push(one)
        } else {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('Not Found CONS:', one)
          }
        }
      })
      this.ConsumablesData.data = newCONS
      if(TymConst.IS_DEVELOPMENT) {
        console.log('new CONS data :', newCONS)
      }

      this.BatteryAlertData.data.forEach(one => {
        if(this.machineLocaInfo.find(x => x.machineNo == one.MN)) {
          newLB.push(one)
        } else {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('Not Found LB:', one)
          }
        }
      })
      this.BatteryAlertData.data = newLB
      if(TymConst.IS_DEVELOPMENT) {
        console.log('new LB data :', newLB)
      }
      */
    }, 
  }
})
