<template>
  <div class="mapview">
    <div :id="elementid ? elementid : 'machineMap'" style="width: 100%; height: 100%;border-radius: 3px;"
          v-show="!isGoogleMap && (mapMounted || !onUpdating) && updateCompleted">
    </div>
    <GoogleMap ref="gmapRef"
              class="col" api-key="AIzaSyBYsSysdtDdmIi8dZRreKvlFenNeVh6YHY"
              :center="gmapCenter"
              :zoom="gmapZoomLevel"
              :options="gmapOptions"
              :clickable-icons="false"
              :disable-default-ui="false"
              :map-type-control="true"
              :street-view-control="false"
              :rotate-control="false"
              style="width: 100%; height: 100%; border-radius: 3px;"
              v-show="isGoogleMap && (mapMounted || !onUpdating) && updateCompleted"
              @click="gmapClicked"
              map-type-id="terrain">
      <GCluster :zoomOnClick="false" :options="clusterOption">
        <!--  @clusteringbegin="gclusterBegin" @clusteringend="gclusterEnd" -->
        <GMarker :key="marker" v-for="marker in Gmarkers" :options="marker" @click="gmapMarkerClicked(marker)" >
          <img :src="marker.icon" width="32" height="45"/>
        </GMarker>
      </GCluster>
      <GInfoWindow :options="gmapInfoOption" v-model="gmapInfoView"></GInfoWindow>
      <GInfoWindow :options="gclusterInfoOption" v-model="gclusterInfoView" />
      <GCircle :key="circle" v-for="circle in circles" :options="circle" />
    </GoogleMap>

    <q-resize-observer @resize="onResize" />
    <div style="position: absolute;z-index: 2;left: 0;top: 0;right: 0;bottom: 0;background-color: white;border: 1px solid #e0e0e0;border-radius: 3px;"
        v-show="!mapMounted && onUpdating" class="row items-center justify-center" >
      <q-spinner color="red-7" size="70px" thickness="1.5" />
    </div>
  </div>
</template>

<style scoped>
.toggle {
  margin-right: 5px;
  font-size: 12pt;
  font-weight: 500;
}

.mapview {
  position: relative;
  min-width: 50px;
  min-height: 50px;
  border-radius: 5px;
  border:1px solid #e0e0e0;
}

.green-marker {
  color: black;
  background-color: green;
}
.red-marker {
  background: red;
}
.orange-marker {
  background: orange;
}
</style>

<script>
import { ref } from "vue";
import { toRaw } from "vue";
import { useTymictStore } from "../store/tymict";
import TymCommon from "@/js/tymcommon.js";
import TymConst from "@/js/tymconstants.js";
import MarkerCluster from "@/js/MarkerClustering.js";
import TymAws from "@/js/tymaws.js";

import { GoogleMap } from "vue3-google-map";
import { CustomMarker as GMarker } from "vue3-google-map";
import { MarkerCluster as GCluster } from "vue3-google-map";
import { Circle as GCircle } from "vue3-google-map";
import { InfoWindow as GInfoWindow } from "vue3-google-map";

export default {
  props: {
    elementid: String,
    onclkevt: String,
    cluster: String,
  },
  data() {
    return {
      greenMarker: require("@/assets/map/ic_marker_large_green.svg"),
      redMarker: require("@/assets/map/ic_marker_large_red.svg"),
      brownMarker: require("@/assets/map/ic_marker_large_brown.svg"),
      yellowMarker: require("@/assets/map/ic_marker_large_yellow.svg"),
      grayMarker: require("@/assets/map/ic_marker_large_gray.svg"),
    };
  },
  components: {
    GoogleMap,
    GMarker,
    GCluster,
    GCircle,
    GInfoWindow,
  },
  watch: {
    locationChanged: {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal);
        if (!newVal) {
          // this.updateMarkerState()
        }
      },
      immediate: true,
    },
  },
  computed: {
    locationChanged: function () {
      return this.locaItems;
      //const store = useTymictStore()
      //return store.machineLocaInfo
    },
    isGoogleMap: function () {
      const store = useTymictStore();
      return store.connectServer != 0;
    },
    isKorean() {
      const store = useTymictStore();
      return store.getLanguage() == "ko";
    },
    isKorea() {
      const store = useTymictStore();
      return store.connectServer == 0;
    },
    getMapID() {
      if (this.isGoogleMap)
        return "g_" + (this.elementid ? this.elementid : "machineMap");
      else return this.elementid ? this.elementid : "machineMap";
    },
  },
  setup() {
    return {
      gmapClickedTime: ref(null),
      gmapCenter: ref({ lat: 0, lng: 0 }), // {lat: 36.4796956, lng: 127.0900425}
      gmapZoomLevel: ref(10),
      gmapOptions: ref({
        zoomControl: true,
        scrollwheel: true,
        scaleControl: true,
      }),
      gmapInfoView: ref(false),
      gmapInfoOption: ref({
        /*
        position: { lat: 36.689247, lng: 126.844502 },
        anchorPoint: 'BOTTOM_CENTER',
        content: '<DIV>바보들의 행진</DIV>'
        */
      }),
      gclusterInfoView: ref(false),
      gclusterInfoOption: ref({}),
      Gmarkers: ref([]),
      clusterOption: ref({
        /*
        renderer: {
          render:function ({ count, position, markers }) {
            console.log('++++++++++++++++++ renderer DATA', count, position, markers)

            let cmarker = null
            cmarker = new window.google.maps.Marker({
              position,
              label: String(markers.length),
            })
            return cmarker
          },
        },
        */
        onClusterClick: null,
        /*
        onClusterClick: function (evt, cluster) {
          console.log('****************** onClusterClick', this, evt, cluster)
        },
        */
      }),
      circles: ref([
        /*
        {
          center: { lat: 36.689247, lng: 126.044502 },
          radius: 3 * 100,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        },
        {
          center: { lat: 36.689247, lng: 125.044502 },
          radius: 5 * 100,
          strokeColor: 'black',
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: 'black',
          fillOpacity: 1,
        },
        */
      ]),
      gmarkerData: ref(null),
      gmapBounds: ref(null),

      onUpdating: ref(false),
      useCluster: ref(true),
      expandMap: ref(false),
      map: ref(null),
      markers: ref(null), // 뿌려진 마커정보들
      markerCluster: ref(null),
      locaItems: ref(null), // 가져온 데이터
      infoWindow: ref(null),
      checkedViewAll: ref(false), // 지도위에 Check Toggle버튼 값 저장용
      noMarkerPopup: ref(false), // 마커 클릭시 팝업이냐 이벤트냐?
      circleMap: ref(null),
      mapBound: ref(null),
      mapMounted: ref(false),
      mapBounded: ref(false),

      updateCompleted: ref(false),

      googleLoader: ref(null),
      markerOnImgsON: ref([
        require("@/assets/map/ic_marker_large_green.svg"),
        require("@/assets/map/ic_marker_large_red.svg"),
        require("@/assets/map/ic_marker_large_brown.svg"),
        require("@/assets/map/ic_marker_large_yellow.svg"),
        require("@/assets/map/ic_marker_large_gray.svg"),
      ]),
      markerOnImgsOFF: ref([
        require("@/assets/map/ic_marker_off_green.svg"),
        require("@/assets/map/ic_marker_off_red.svg"),
        require("@/assets/map/ic_marker_off_brown.svg"),
        require("@/assets/map/ic_marker_off_yellow.svg"),
        require("@/assets/map/ic_marker_off_gray.svg"),
      ]),
      abnormalColors: ref([
        "rgb(144,204,48)", // Green
        "rgb(219,20,42)", // Red
        "rgb(201,125,49)", // Brown
        "rgb(255,198,0)", // Yellow
        "rgb(187,187,187)", // Gray
      ]),
      clusterCircle: ref([
        // Green
        '<div id="first-child" style="width:32px;height:32px;line-height:30px;text-align:center;background-size:contain;' +
          "border-radius:50%;" +
          "font-size:14px;font-weight:400;color:white;" +
          "text-shadow: -1px 0px lightgray, 0px 1px lightgray, 1px 0px lightgray, 0px -1px lightgray;" +
          '"></div>',

        // Red
        '<div id="first-child" style="width:32px;height:32px;line-height:30px;text-align:center;background-size:contain;' +
          "border-radius:50%;" +
          "font-size:14px;font-weight:400;color:white;" +
          "text-shadow: -1px 0px darkgray, 0px 1px darkgray, 1px 0px darkgray, 0px -1px darkgray;" +
          '"></div>',

        // Brown
        '<div id="first-child" style="width:32px;height:32px;line-height:30px;text-align:center;background-size:contain;' +
          "border-radius:50%;" +
          "font-size:14px;font-weight:400;color:white;" +
          "text-shadow: -1px 0px black, 0px 1px black, 1px 0px black, 0px -1px black;" +
          '"></div>',

        // Yellow
        '<div id="first-child" style="width:32px;height:32px;line-height:30px;text-align:center;background-size:contain;' +
          "border-radius:50%;" +
          "font-size:14px;font-weight:400;color:white;" +
          "text-shadow: -1px 0px darkgray, 0px 1px darkgray, 1px 0px darkgray, 0px -1px darkgray;" +
          '"></div>',

        // Gray...
        '<div id="first-child" style="width:32px;height:32px;line-height:30px;text-align:center;background-size:contain;' +
          "border-radius:50%;" +
          "font-size:14px;font-weight:400;color:white;" +
          "text-shadow: -1px 0px darkgray, 0px 1px darkgray, 1px 0px darkgray, 0px -1px darkgray;" +
          '"></div>',
      ]),
      clusterBorders: ref([
        "4px solid rgb(144,204,48)", // Green
        "4px solid rgb(219,20,42)", // Red
        "4px solid rgb(201,125,49)", // Brown
        "4px solid rgb(255,198,0)", // Yellow
        "4px solid rgb(187,187,187)", // Gray
      ]),
      clusterBGs: ref([
        "radial-gradient(rgb(144,204,48),rgb(144,204,48),rgb(144,204,48),rgb(144,204,48),transparent,transparent,transparent,rgb(144,204,48))",
        "radial-gradient(rgb(219,20,42),rgb(219,20,42),rgb(219,20,42),rgb(219,20,42),transparent,transparent,transparent,rgb(219,20,42))",
        "radial-gradient(rgb(201,125,49),rgb(201,125,49),rgb(201,125,49),rgb(201,125,49),transparent,transparent,transparent,rgb(201,125,49))",
        "radial-gradient(rgb(255,198,0),rgb(255,198,0),rgb(255,198,0),rgb(255,198,0),transparent,transparent,transparent,rgb(255,198,0))",
        "radial-gradient(rgb(187,187,187),rgb(187,187,187),rgb(187,187,187),rgb(187,187,187),transparent,transparent,transparent,rgb(187,187,187))",
      ]),
      clusterIcons: ref([
        require("@/assets/map/cluster-marker-1.png"),
        require("@/assets/map/cluster-marker-3.png"),
        require("@/assets/map/cluster-marker-4.png"),
        require("@/assets/map/cluster-marker-2.png"),
        require("@/assets/map/cluster-marker-5.png"),
      ]),
    };
  },
  created() {},
  mounted() {
    if (this.isGoogleMap) {
      this.clusterOption.renderer = {};
      this.clusterOption.renderer.render = this.gmapRenderer;
      this.clusterOption.onClusterClick = this.clusterClicked;
    }

    if (TymCommon.isEmpty(this.elementid)) {
      this.onUpdating = true;
    }

    const self = this;
    setTimeout(() => {
      if (!TymCommon.isEmpty(this.cluster)) {
        if (
          this.cluster.toUpperCase() == "NONE" ||
          (this.cluster.toUpperCase() == "NO")(
            this.cluster.toUpperCase() == "FALSE"
          )
        ) {
          this.useCluster = false;
        }
      }

      if (this.isGoogleMap) {
        if (!this.map) {
          initGoogleMap(this, this.elementid);
          this.mapMounted = true;
          this.onUpdating = false;
        }
      } else {
        initNaverMap(this, this.elementid);
      }
    }, 1);

    function initGoogleMap(obj, id) {
      self.unusedParam(obj, id);
    }

    // 네이버 지도 만든다...
    function initNaverMap(obj, id) {
      let self = obj;

      if (self.map != null) {
        console.log("map was already created.");
        this.onUpdating = false;
        return;
      }
      if (!document.getElementById(id ? id : "machineMap")) {
        console.log("map div is not created...");
        // this.onUpdating = false
        return;
      }
      self.map = new window.naver.maps.Map(
        document.getElementById(id ? id : "machineMap"),
        {
          center: new window.naver.maps.LatLng(37.4135557, 127.1251959), // 장미로 42 근처
          zoom: 14,
          zoomControl: true,
          zoomControlOptions: {
            style: window.naver.maps.ZoomControlStyle.LARGE, // SMALL은 확대축소만, LARGE는 눈금과 함께...
            position: window.naver.maps.Position.TOP_RIGHT,
          },
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: window.naver.maps.MapTypeControlStyle.BUTTON,
            position: window.naver.maps.Position.TOP_RIGHT,
            mapTypeId: window.naver.maps.MapTypeId.HYBRID,
          },
        }
      );

      const clickedOnMap = (e) => {
        self.unusedParam(e);
        if (self.infoWindow) {
          self.infoWindow.close();
          if (TymConst.IS_DEVELOPMENT) {
            console.log("close infoWindow - clickedOnMap");
          }
          self.infoWindow = null;
        }
        // console.log('clickedOnMap(NAVER)', e)
      };

      const zoomChanged = (e) => {
        // 줌 변경시 infoWindow를 삭제할까 고민했는데 일단 그대로 가자
        //console.log('zoomChanged - NaverMap ', e)
        if (self.infoWindow) {
          self.infoWindow.close();
          console.log("close infoWindow - zoomChanged");
          self.infoWindow = null;
        }

        if (!TymCommon.isEmpty(self.circleMap)) {
          console.log("circleMap - zoomChanged");
          self.updateRouteInfo(self.circleMap, e);
        }
      };

      if (self.map) {
        self.map.addListener("click", clickedOnMap);
        self.map.addListener("click", zoomChanged);
      }
    }
  },
  unmounted() {
    try {
      if (!this.isGoogleMap) {
        if (this.infoWindow) {
          this.infoWindow.close();
          console.log("close infoWindow - unmounted");
          this.infoWindow = null;
        }
        if (this.markerCluster != null) {
          this.markerCluster.setMap(null);
          this.markerCluster = null;
        }

        if (this.markers) {
          let i = 0;
          for (i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
          }
          this.markers = [];
        }
      }
    } catch (ex) {
      console.log("ER) MapView unmounted...", ex);
    }
  },
  methods: {
    unusedParam() {
      //
    },
    gclusterBegin(ev) {
      this.unusedParam(ev);
      console.log("GMap.gclusterBegin :", ev);
    },
    gclusterEnd(ev) {
      this.unusedParam(ev);
      console.log("GMap.gclusterEnd :", ev);
    },
    // 구글지도 클릭 이벤트처리
    gmapClicked(ev) {
      let now = new Date();
      let elapsed = now - this.gmapClickedTime;
      if (TymConst.IS_DEVELOPMENT) {
        console.log("GMap.clicked :", ev, elapsed);
      }

      // 마커 클릭 후에 바로 들어와서 시간 지난후 이벤트 발생시 인포창 숨기기 위함
      if (elapsed >= 25) {
        this.gmapInfoView = false;
      }
      this.gclusterInfoView = false;
    },
    // 구글 지도 내 마커 클릭시
    gmapMarkerClicked(marker) {
      const self = this;
      const geocoder = new window.google.maps.Geocoder();
      const latlng = new window.google.maps.LatLng(
        marker.position.lat,
        marker.position.lng
      );

      // this.gmapCenter = marker.position
      // this.$refs.gmapRef.map.setCenter(marker.position)
      //console.log('gmapMarkerClicked :', this.$refs.gmapRef.map.getCenter())

      geocoder.geocode({ latLng: latlng }, function (res, status) {
        if (status == "OK") {
          self.gmapGeocodeResultFunc(marker, status, res);
        } else {
          console.log("GMap Geocode ERR", status.toString());
          self.gmapGeocodeResultFunc(marker, status, undefined);
        }
      });
    },
    gmapGeocodeResultFunc(marker, status, response) {
      const store = useTymictStore();

      let sale = store.SalesData.data.find((x) => x.machineNo == marker.title);
      let user = "";
      let modelInfo = "";
      let location = "";
      if (status == "OK") {
        if(sale) {
          if (!TymCommon.isEmpty(sale.owner)) {
            user = sale.owner;
          }
          if (!TymCommon.isEmpty(sale.model)) {
            modelInfo = TymCommon.getModelFullname(sale.model);
          }
        }
        if (!TymCommon.isEmpty(response)) {
          if (response.length > 0) {
            location = TymCommon.isEmpty(response[0].formatted_address) ? "" : response[0].formatted_address;
          }
        }
        this.gmapInfoView = true;
      }
      if(TymCommon.isEmpty(modelInfo)) {
        modelInfo = TymCommon.isEmpty(marker.model) ? '' : marker.model
      } else {
        modelInfo = modelInfo.Fullname
      }

      let contentString = "";
      /*
      contentString += '<div class="col-auto column" style="position:absolute;left:0px;top:0px;width:100%;border-radius:3px;padding:3px;z-index:3;">';
      contentString += '<div class="col column no-wrap" style="width:100%;padding:12px 15px;background-color:white;border:1px solid red;">';
      contentString +=   '<div style="width:100%;height:22px;background-color:white;color:black;border-radius:6px;font-family:Prometo;font-size:16px;font-weight:500;">';
      contentString +=   this.$t("common.vin") + "&nbsp;:&nbsp;" + marker.title;
      contentString +=   "</div>";
      contentString +=   '<div style="background-color:' + this.abnormalColors[marker.abnormal] + ';width:100%;height:3px;margin:6px 0;"></div>';
      
      if(!TymCommon.isEmpty(user)) {
        contentString += '<div class="col-auto row" style="width:100%;font-family:Prometo;font-size:16px;margin:2px 0 2px 0;color:black;">';
        contentString +=   '<span style="">' + this.$t("common.user") + "&nbsp;:&nbsp;</span>"
        contentString +=   '<span style="">' + user + "</span>"
        contentString += "</div>"
      }
      
      contentString += '<div class="col-auto row" style="width:100%;font-family:Prometo;font-size:16px;margin:2px 0 2px 0;color:black;">';
      contentString +=   '<span style="">' + this.$t("customer.modelName") + "&nbsp;:&nbsp;</span>";
      contentString +=   '<span style="">' + modelInfo + "</span>";
      contentString += "</div>";
      
      contentString += '<div class="col row no-wrap" style="font-family:Prometo;font-size:16px;margin:2px 0 6px 0;color:black;">';
      contentString +=   '<div class="col-auto" style="min-width:50px;">' + this.$t("common.location") + "&nbsp;:&nbsp;</div>";
      contentString +=   '<div class="col" style="">' + location + "</div>";
      contentString += "</div>";

      contentString += "</div>";
      contentString += "</div>";
      */

      // 크기랑 테두리 부분
      contentString += '<div style="width:380px;min-height:90px;z-index:99999;overflow-y:auto;">'
      contentString += '<div style="position:absolute;left:0px;top:0px;width:100%;height:100%;border-radius:3px;padding:3px;z-index:3;">';
      contentString += '<div style="width:100%;padding:12px 15px;background-color:white;">';
      contentString +=   '<div style="width:100%;height:22px;background-color:white;color:black;font-family:Prometo;font-size:16px;font-weight:500;">';
      contentString +=   this.$t("common.vin") + "&nbsp;:&nbsp;" + marker.title;
      contentString +=   "</div>";
      contentString +=   '<div style="background-color:' + this.abnormalColors[marker.abnormal] + ';width:100%;height:3px;margin:6px 0;"></div>';
      
      if(!TymCommon.isEmpty(user)) {
        contentString += '<div style="width:100%;font-family:Prometo;font-size:16px;margin:2px 0 2px 0;color:black;">';
        contentString +=   '<span style="">' + this.$t("common.user") + "&nbsp;:&nbsp;</span>"
        contentString +=   '<span style="">' + user + "</span>"
        contentString += "</div>"
      }
      
      contentString += '<div style="width:100%;font-family:Prometo;font-size:16px;margin:2px 0 2px 0;color:black;">';
      contentString +=   '<span style="">' + this.$t("customer.modelName") + "&nbsp;:&nbsp;</span>";
      contentString +=   '<span style="">' + modelInfo + "</span>";
      contentString += "</div>";
      
      contentString += '<div class="col row no-wrap" style="font-family:Prometo;font-size:16px;margin:2px 0 6px 0;color:black;">';
      contentString +=   '<span class="col-auto" style="min-width:50px;">' + this.$t("common.location") + "&nbsp;:&nbsp;</span>";
      contentString +=   '<span class="col" style="">' + location + "</span>";
      contentString += "</div>";

      contentString += "</div>";
      contentString += "</div>";
      contentString += '</div>';


      contentString += "<style>";
      contentString += ".gm-style .gm-style-iw-d::-webkit-scrollbar-track, ";
      contentString += ".gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {";
      contentString +=   "background-color: inherit;";
      contentString += "}";
      contentString += ".gm-style .gm-style-iw-c,";
      contentString += ".gm-style .gm-style-iw-t::after {";
      contentString +=   "box-shadow: 0 0 1px 1px rgb(160,160,160)";
      contentString += "}";
      contentString += "</style>";

      this.gmapInfoOption = {
        position: {
          lat: marker.position.lat,
          lng: marker.position.lng,
        },
        anchorPoint: "CENTER",
        disableAnchor: true,
        pixelOffset: new window.google.maps.Size(0, -16),
        content: contentString,
      };

      if (!TymCommon.isEmpty(this.onclkevt)) {
        this.$emit("addressReceived", marker.title, location);
      }

      this.$emit("markerSelected", this.gmarkerData[marker.index]);

      this.gmapClickedTime = new Date();
    },
    clusterClicked(position, cluster, event) {
      this.unusedParam(position, cluster, event)
      // console.log("--- clusterClicked ---", position, cluster, event);

      this.gmapInfoView = false;
      this.gclusterInfoView = true;

      let contentString = "";
      contentString +=
        '<div class="column" style="position:absolute;left:0px;top:0px;width:100%;height:100%;border-radius:3px;padding:0px;z-index:3;">';
      contentString +=
        '<div class="col column no-wrap" style="width:100%;font-family:Prometo;font-size:16px;background-color:white">';
      contentString +=
        '<div class="col column no-wrap" style="padding:3px 3px 3px 6px;border:2px solid #808080;border-radius:6px;margin:3px;">';
      contentString +=
        '<div class="col column no-wrap" style="background-color:white;overflow-y:auto;">';
      for (let idx = 0; idx < cluster.markers.length; idx++) {
        contentString +=
          '<div class="col-auto row items-center clusterPointer" style="cursor:pointer;padding:3px 0;" id="gmapListItem' +
          idx +
          '">';
        contentString +=
          '<img width="36px" height="36px" style="pointer-events:none;" src="' +
          cluster.markers[idx].opts.icon +
          '">';
        contentString +=
          '<div style="margin:0 0;pointer-events:none;">' +
          cluster.markers[idx].opts.title +
          "</div>";
        contentString += "</div>";
        if (idx + 1 < cluster.markers.length) {
          contentString +=
            '<div style="margin:3px 0;border-bottom:1px solid #d0d0d0;"></div>';
        }
      }
      contentString += "</div>";
      contentString += "</div>";
      contentString += "</div>";
      contentString += "</div>";

      contentString += "<style>";
      contentString += ".clusterPointer {";
      contentString += "background-color:white;";
      contentString += "border-radius:3px;";
      contentString += "}";
      contentString += ".clusterPointer:hover {";
      contentString += "background-color:#eb002820;";
      contentString += "}";
      contentString += ".clusterPointer:active {";
      contentString += "background-color:#eb002840;";
      contentString += "}";
      contentString += "</style>";

      // 크기랑 테두리 부분
      if (cluster.markers.length == 2) {
        contentString +=
          '<div style="width:220px;height:72px;margin:6px 0;z-index:99999;font-family:Prometo;font-size:16px;">';
      } else if (cluster.markers.length == 3) {
        contentString +=
          '<div style="width:220px;height:120px;margin:6px 0;z-index:99999;font-family:Prometo;font-size:16px;">';
      } else {
        contentString +=
          '<div style="width:220px;height:170px;margin:6px 0;z-index:99999;font-family:Prometo;font-size:16px;">';
      }
      contentString += "</div>";
      contentString += "<style>";
      contentString += ".gm-style .gm-style-iw-d::-webkit-scrollbar-track, ";
      contentString +=
        ".gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {";
      contentString += "  background-color: inherit;";
      contentString += "}";
      contentString += ".gm-style .gm-style-iw-c,";
      contentString += ".gm-style .gm-style-iw-t::after {";
      contentString += "  box-shadow: 0 0 0.1px 0.1px rgb(192,192,192)";
      contentString += "}";
      contentString += "</style>";

      this.gclusterInfoOption = {
        position: position.latLng,
        anchorPoint: "CENTER",
        disableAnchor: true,
        pixelOffset: new window.google.maps.Size(0, -20),
        content: contentString,
      };

      setTimeout(() => {
        for (let idx = 0; idx < cluster.markers.length; idx++) {
          let element = document.getElementById("gmapListItem" + idx);
          if (element) element.onclick = this.selectedInCluster;
        }
      }, 10);

      this.gmapClickedTime = new Date();
    },
    selectedInCluster(element) {
      // element.srcElement.id
      //console.log('selectedInCluster ', element)
      // console.log('selectedInCluster :', element.srcElement.innerText)
      if (!TymCommon.isEmpty(element.srcElement)) {
        if (!TymCommon.isEmpty(element.srcElement.innerText)) {
          let marker = this.Gmarkers.find(x => x.title.trim() == element.srcElement.innerText);
          if (marker) {
            if(TymConst.IS_DEVELOPMENT) {
              console.log("clicked on cluster :", marker);
            }
            this.gmapCenter = marker.position;
            // this.gmapZoomLevel = 20;
            this.$refs.gmapRef.map.setZoom(19);
          } else {
            console.log("not found :", element.srcElement.innerText, this.Gmarkers );
          }
        }
      }
      this.gclusterInfoView = false;
    },
    gmapRenderer(cluster, clusters, map) {
      this.unusedParam(cluster, clusters, map);

      if (TymConst.IS_DEVELOPMENT) {
        //console.log('++++++++++++++++++ renderer cluster', cluster)
        //console.log('++++++++++++++++++ renderer all clusters', clusters)
        //console.log('++++++++++++++++++ renderer map', map)
      }

      let abnormal = 0,
        tempAB = 0;
      for (let idx = 0; idx < cluster.markers.length; idx++) {
        tempAB = cluster.markers[idx].opts.abnormal;
        if (tempAB == 1) {
          abnormal = 1;
          break;
        } else if (tempAB == 2) {
          if (abnormal != 1) {
            abnormal = 2;
          }
        } else if (tempAB == 3) {
          if (abnormal != 1 && abnormal != 2) abnormal = 3;
        } else if (tempAB == 4) {
          if (abnormal != 1 && abnormal != 2 && abnormal != 3) abnormal = 4;
        }
      }

      const svg = window.btoa(
        `
        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" opacity="0.9">
          <defs>
            <radialGradient id="grad1" cx="50%" cy="50%" r="50%">
              <stop offset="80%" stop-color="` +
          this.abnormalColors[abnormal] +
          `" />
              <stop offset="100%" stop-color="#FFFFFF00"/>
            </radialGradient>
          </defs>
          <circle cx="64" cy="64" r="57" stroke="` +
          this.abnormalColors[abnormal] +
          `" stroke-width="14" fill="transparent"/>
          <circle cx="64" cy="64" r="46" fill="url(#grad1)"/>
          <circle cx="64" cy="64" r="36" fill="` +
          this.abnormalColors[abnormal] +
          `"/>
        </svg>`
      );

      let cmarker = null;
      cmarker = new window.google.maps.Marker({
        position: cluster.position,
        icon: {
          // url: require('@/assets/map/cluster-marker-1.png'),
          url: `data:image/svg+xml;base64,${svg}`,
          scaledSize: new window.google.maps.Size(40, 40),
        },
        label: {
          text: String(cluster.markers.length),
          color: "black",
          fontSize: "12px",
          fontWeight: "bold",
        },
      });
      return cmarker;
    },
    onResize(size) {
      if (this.map != null) {
        this.unusedParam(size);
        if (!this.isGoogleMap) {
          this.map.autoResize();
        }
      }
    },
    expandMapChanged() {
      if (this.expandMap) {
        console.log("expandMap is true");
      } else {
        console.log("expandMap is false");
      }
    },
    onRefreshClicked() {
      const store = useTymictStore();
      store.refreshCurrentLocation(true, true);
    },

    // data : 기대 및 위치정보
    // viewOption : 어떤 상태의 마커를 표시할 건지 여부의 배열 [전체,정상,응급,고장,소모품,배터리]
    // noAddress : Address 요청을 하지 않음
    // cbCompleted : 완료시 호출
    makeMarkers(data, viewOption, requestAddress, isFirstTime, cbCompleted) {
      this.unusedParam(isFirstTime);

      // console.log("MapView.makeMarkers START:", this.map, this.markers)

      if (this.infoWindow) {
        this.infoWindow.close();
        this.infoWindow = null;
      }

      if (this.markers) {
        if (!this.isGoogleMap) {
          for(let idx = 0; idx < this.markers.length; idx++) {
            this.markers[idx].position = new window.naver.maps.LatLng(0, 0)
            this.markers[idx].setMap(null);
          }
        }
      }
      this.markers = []
      this.Gmarkers = [];

      // console.log("MapView.makeMarkers #111:", this.markers, data)

      if (data.length < 1) {
        if (cbCompleted) {
          cbCompleted(false);
        }
        // console.log("MapView.makeMarkers END : NO MARKER")
        if (!this.isGoogleMap) {
          // this.map.refresh(false)
        }
        return;
      }

      if (data.length == 1) {
        if (!TymCommon.isEmpty(requestAddress)) {
          if (requestAddress) {
            this.getAddressAtPosition(0, data[0].latitude, data[0].longitude);
          }
        } else {
          this.getAddressAtPosition(0, data[0].latitude, data[0].longitude);
        }
      }

      let i = 0;
      let minLat = 0,
        minLng = 0;
      let maxLat = 0,
        maxLng = 0;
      this.gmapBounds = null;

      for (i = 0; i < data.length; i++) {
        if (data[i].latitude == 0 && data[i].longitude == 0) continue;
        if (minLat == 0 && maxLat == 0 && minLng == 0 && maxLng == 0) {
          minLat = data[i].latitude;
          maxLat = data[i].latitude;
          minLng = data[i].longitude;
          maxLng = data[i].longitude;
        } else {
          if (minLat > data[i].latitude) minLat = data[i].latitude;
          if (minLng > data[i].longitude) minLng = data[i].longitude;
          if (maxLat < data[i].latitude) maxLat = data[i].latitude;
          if (maxLng < data[i].longitude) maxLng = data[i].longitude;
        }

        let abnormalType = TymAws.containsAbnormalMn([data[i].machineNo]);
        let newAbnormal = 0;
        if (!TymCommon.isEmpty(viewOption)) {
          if (Array.isArray(abnormalType)) {
            if (viewOption[0]) {
              // 전체 - 우선순위 높은 걸로
              for (let i = 0; i < abnormalType.length; i++) {
                if (abnormalType[i]) {
                  newAbnormal = i + 1;
                  break;
                }
              }
            } else {
              if (viewOption[1]) {
                // 정상
                if (
                  !(
                    abnormalType[0] ||
                    abnormalType[1] ||
                    abnormalType[2] ||
                    abnormalType[3]
                  )
                ) {
                  newAbnormal = 0;
                }
              }
            }
            if (viewOption[5]) {
              // 소모품
              if (abnormalType[3]) {
                newAbnormal = 4;
              }
            }
            if (viewOption[4]) {
              // 소모품
              if (abnormalType[2]) {
                newAbnormal = 3;
              }
            }
            if (viewOption[3]) {
              // 고장
              if (abnormalType[1]) {
                newAbnormal = 2;
              }
            }
            if (viewOption[2]) {
              // 긴급
              if (abnormalType[0]) {
                newAbnormal = 1;
              }
            }
          }
        } else {
          if (Array.isArray(abnormalType)) {
            for (let i = 0; i < abnormalType.length; i++) {
              if (abnormalType[i]) {
                newAbnormal = i + 1;
                break;
              }
            }
          }
        }

        let isPwrOn = false;
        if (!TymCommon.isEmpty(data[i].PWR)) {
          isPwrOn = data[i].PWR == "ON";
        } else {
          const store = useTymictStore();
          let mach = store.machineLocaInfo.find(x => x.machineNo == data[i].machineNo);
          if (mach && !TymCommon.isEmpty(mach.PWR)) {
            isPwrOn = mach.PWR == "ON";
          }
        }

        // 마커만들기( GMap and NMap )
        let marker = null;
        let dataLat = parseFloat(data[i].latitude);
        let dataLng = parseFloat(data[i].longitude);
        if (this.isGoogleMap) {
          // { position: { lat: 36.689247, lng: 126.844502 }, anchorPoint: 'BOTTOM_CENTER' },
          if (!isNaN(dataLat) && !isNaN(dataLng)) {
            if (TymCommon.isEmpty(this.gmapBounds)) {
              this.gmapBounds = new window.google.maps.LatLngBounds();
            }

            let modelname = ''
            if(TymCommon.isEmpty(data[i].modelName)) {
              if(!TymCommon.isEmpty(data[i].modelNo)) {
                modelname = data[i].modelNo
              }
            } else {
              modelname = data[i].modelName
            }

            this.Gmarkers.push({
              position: {
                lat: dataLat,
                lng: dataLng,
              },
              title: TymCommon.isEmpty(data[i].machineNo) ? '' : data[i].machineNo,
              icon: isPwrOn ? this.markerOnImgsON[newAbnormal] : this.markerOnImgsOFF[newAbnormal],
              abnormal: newAbnormal,
              serial: TymCommon.isEmpty(data[i].serialNo) ? '' : data[i].serialNo,
              model: modelname,
              index: i,
            });
            
            this.gmapBounds.extend(
              new window.google.maps.LatLng(dataLat, dataLng)
            );
          }
        } else {
          if (TymConst.IS_DEVELOPMENT) {
            // console.log("MapView.makerMarkers :", newAbnormal, data[i].PWR, data[i].abnormal)
          }

          if (!isNaN(dataLat) && !isNaN(dataLng)) {
            marker = new window.naver.maps.Marker({
              map: this.map,
              position: new window.naver.maps.LatLng(
                dataLat,
                data[i].longitude
              ),
              title: TymCommon.isEmpty(data[i].machineNo) ? '' : data[i].machineNo,
              icon: {
                url: isPwrOn
                  ? this.markerOnImgsON[newAbnormal]
                  : this.markerOnImgsOFF[newAbnormal],
                // url: this.markerOnImgsON[newAbnormal],
                size: new window.naver.maps.Size(32, 45),
                origin: new window.naver.maps.Point(0, 0),
                anchor: new window.naver.maps.Point(16, 45),
              },
              abnormal: newAbnormal,
              serial: TymCommon.isEmpty(data[i].serialNo) ? '' : data[i].serialNo,
              model: TymCommon.isEmpty(data[i].modelName) ? '' : data[i].modelName,
              index: i,
            });
            if(this.markers == null) 
              this.markers = []
            this.markers.push(marker);
          }
        }

        const clickOnMarker = (e) => {
          if (this.infoWindow) {
            if (TymConst.IS_DEVELOPMENT) {
              console.log("close infoWindow - clickonmarker");
            }
            this.infoWindow.close();
          }

          let detailInfo = null;
          // let abnormalValue = null
          if (!this.isGoogleMap) {
            detailInfo = data.filter((r) => r.machineNo === e.overlay.title);
            // abnormalValue = e.overlay.abnormal
          }

          /*
          if(!TymCommon.isEmpty(this.onclkevt)) {
            // 마커 테두리만 표시하는 버전
            let contentString = ''
            if(!this.isGoogleMap) {
              contentString = '<div style="border-radius:4px;width:36px;height:50px;'
              switch(abnormalValue) {
                case 0:
                  contentString += "opacity:0.6;border:3px solid green;box-shadow: 0 0 3px 3px rgba(22, 21, 23, 0.4);"
                  break;
                case 1:
                  contentString += "border:3px solid #eb0028;box-shadow: 0 0 3px 3px rgba(22, 21, 23, 0.4);"
                  break;
                case 2:
                  contentString += "border:3px solid #c97d31;box-shadow: 0 0 3px 3px rgba(22, 21, 23, 0.4);"
                  break;
                case 3:
                  contentString += "border:3px solid #ffc600;box-shadow: 0 0 3px 3px rgba(22, 21, 23, 0.4);"
                  break;
                case 4:
                  contentString += "border:3px solid #bbbbbb;box-shadow: 0 0 3px 3px rgba(22, 21, 23, 0.4);"
                  break;
              }
              contentString += '"></div>'
              this.infoWindow = new window.naver.maps.InfoWindow({
                content: contentString,
                disableAnchor : true, 
                backgroundColor: "transparent",
                borderWidth: 0,
                pixelOffset: new window.naver.maps.Point(-2, 0)
              })
              this.infoWindow.open(this.map, e.overlay.position)
            }
          } else {
          */
          const geocodeResultFunc = (status, response) => {
            let additionalData = []
            let address = this.$t('warning.noaddrinfo')

            // console.log('geocodeResultFunc :', this.isGoogleMap, status, response)

            if (this.isGoogleMap) {
              if (!TymCommon.isEmpty(response)) {
                address = response[0].formatted_address;
              }
            } else {
              if (status === window.naver.maps.Service.Status.ERROR) {
                return;
              }
              // 주소 정보 수신완료(NaverMAP)
              /* 사무실 주소 잘 받았군...
              {
                "v2": {
                    "status": {
                        "code": 0,
                        "name": "ok",
                        "message": "done"
                    },
                    "results": [
                        {
                            "name": "roadaddr",
                            "code": {
                                "id": "4113510700",
                                "type": "L",
                                "mappingId": "02135107"
                            },
                            "region": {
                                "area0": {
                                    "name": "kr",
                                    "coords": {
                                        "center": {
                                            "crs": "",
                                            "x": 0,
                                            "y": 0
                                        }
                                    }
                                },
                                "area1": {
                                    "name": "경기도",
                                    "coords": {
                                        "center": {
                                            "crs": "EPSG:4326",
                                            "x": 127.550802,
                                            "y": 37.436318
                                        }
                                    },
                                    "alias": "경기"
                                },
                                "area2": {
                                    "name": "성남시 분당구",
                                    "coords": {
                                        "center": {
                                            "crs": "EPSG:4326",
                                            "x": 127.118926,
                                            "y": 37.38282
                                        }
                                    }
                                },
                                "area3": {
                                    "name": "야탑동",
                                    "coords": {
                                        "center": {
                                            "crs": "EPSG:4326",
                                            "x": 127.130218,
                                            "y": 37.411983
                                        }
                                    }
                                },
                                "area4": {
                                    "name": "",
                                    "coords": {
                                        "center": {
                                            "crs": "",
                                            "x": 0,
                                            "y": 0
                                        }
                                    }
                                }
                            },
                            "land": {
                                "type": "",
                                "number1": "42",
                                "number2": "",
                                "addition0": {
                                    "type": "building",
                                    "value": "야탑리더스"
                                },
                                "addition1": {
                                    "type": "zipcode",
                                    "value": "13496"
                                },
                                "addition2": {
                                    "type": "roadGroupCode",
                                    "value": "411353180048"
                                },
                                "addition3": {
                                    "type": "",
                                    "value": ""
                                },
                                "addition4": {
                                    "type": "",
                                    "value": ""
                                },
                                "name": "장미로",
                                "coords": {
                                    "center": {
                                        "crs": "",
                                        "x": 0,
                                        "y": 0
                                    }
                                }
                            }
                        },
                        {
                            "name": "addr",
                            "code": {
                                "id": "4113510700",
                                "type": "L",
                                "mappingId": "02135107"
                            },
                            "region": {
                                "area0": {
                                    "name": "kr",
                                    "coords": {
                                        "center": {
                                            "crs": "",
                                            "x": 0,
                                            "y": 0
                                        }
                                    }
                                },
                                "area1": {
                                    "name": "경기도",
                                    "coords": {
                                        "center": {
                                            "crs": "EPSG:4326",
                                            "x": 127.550802,
                                            "y": 37.436318
                                        }
                                    },
                                    "alias": "경기"
                                },
                                "area2": {
                                    "name": "성남시 분당구",
                                    "coords": {
                                        "center": {
                                            "crs": "EPSG:4326",
                                            "x": 127.118926,
                                            "y": 37.38282
                                        }
                                    }
                                },
                                "area3": {
                                    "name": "야탑동",
                                    "coords": {
                                        "center": {
                                            "crs": "EPSG:4326",
                                            "x": 127.130218,
                                            "y": 37.411983
                                        }
                                    }
                                },
                                "area4": {
                                    "name": "",
                                    "coords": {
                                        "center": {
                                            "crs": "",
                                            "x": 0,
                                            "y": 0
                                        }
                                    }
                                }
                            },
                            "land": {
                                "type": "1",
                                "number1": "342",
                                "number2": "1",
                                "addition0": {
                                    "type": "",
                                    "value": ""
                                },
                                "addition1": {
                                    "type": "",
                                    "value": ""
                                },
                                "addition2": {
                                    "type": "",
                                    "value": ""
                                },
                                "addition3": {
                                    "type": "",
                                    "value": ""
                                },
                                "addition4": {
                                    "type": "",
                                    "value": ""
                                },
                                "coords": {
                                    "center": {
                                        "crs": "",
                                        "x": 0,
                                        "y": 0
                                    }
                                }
                            }
                        }
                    ],
                    "address": {
                        "jibunAddress": "경기도 성남시 분당구 야탑동  342-1",
                        "roadAddress": "경기도 성남시 분당구 장미로  42 야탑리더스"
                    }
                }
              }
              */
              /* 주소 없는 경우...
              {
                "v2": {
                    "status": {
                        "code": 3,
                        "name": "no results",
                        "message": "요청한 데이타의 결과가 없습니다."
                    },
                    "results": [],
                    "address": {
                        "jibunAddress": "",
                        "roadAddress": ""
                    }
                }
              }
              */
              try {
                if(!TymCommon.isEmpty(response.v2)) {
                  if(response.v2.status.code == 0) {
                    if (!TymCommon.isEmpty(response.v2.address.roadAddress)) {
                      address = response.v2.address.roadAddress;
                    } else if (!TymCommon.isEmpty(response.v2.address.jibunAddress)) {
                      address = response.v2.address.jibunAddress;
                    } else {
                      if(!TymCommon.isEmpty(response.results) && Array.isArray(response.results)) {
                        let addr = "";
                        let i = 0;
                        if (!TymCommon.isEmpty(response.result.items)) {
                          for (i = 0; i < response.result.items.length; i++) {
                            if (response.result.items[i].isAdmAddress) {
                              addr = response.result.items[i].address;
                              break;
                            }
                          }
                        }
                        if (TymCommon.isEmpty(addr)) {
                          let roadAddr = response.v2.results.filter(
                            (r) => r.name === "addr"
                          );
                          address = TymCommon.makeAddress(roadAddr[0]);
                        } else {
                          address = response.result.items[0].address;
                        }
                      }
                    }
                  }
                }
              } catch(ex) {
                console.log(ex, response)
                address = ''
              }
            }

            this.$emit("addressReceived", detailInfo[0].machineNo, address);

            if (data != null) {
              if (detailInfo.length > 0) {
                //console.log('AFTER RX ADDR ', detailInfo[0])

                if (!this.isGoogleMap) {
                  additionalData.push(this.$t("common.vin") + "&nbsp;:&nbsp;" + detailInfo[0].machineNo);
                  additionalData.push(this.$t("common.user") + "&nbsp;:&nbsp;" +  (TymCommon.isEmpty(detailInfo[0].name) ? '' : detailInfo[0].name) );
                  additionalData.push(this.$t("customer.modelName") + "&nbsp;:&nbsp;" + (TymCommon.isEmpty(detailInfo[0].modelNo) ? '' : detailInfo[0].modelNo));
                  if (this.isKorean)
                    additionalData.push(this.$t("common.location") + "&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;" + address);
                  else
                    additionalData.push(this.$t("common.location") + "&nbsp;:&nbsp;" + address );
                }
              }
            }
            let contentString = "";

            if (!this.isGoogleMap) {
              contentString += '<div style="min-width:200px;color:black;background-color:white;box-shadow: 0 0 5px 5px rgba(22, 21, 23, 0.2) ;border-radius: 3px;border: 1px solid #f0f0f0;padding:12px;">';
              contentString += '<p style="font-size:16px;font-weignt:500;">' + additionalData[0] + "</p>";
              contentString += '<hr style="background-color:' + this.abnormalColors[marker.abnormal] + ';height:1px;margin:-12px 0 6px 0;">';
              contentString += '<span style="font-size:14px;font-family:' + (this.isKorean ? "Noto Sans KR" : "Prometo") + '">'
              if(!TymCommon.isEmpty(detailInfo[0].name)) {
                contentString += additionalData[1] + "<br>";
              }
              if(!TymCommon.isEmpty(detailInfo[0].modelNo)) {
                contentString += additionalData[2] + "<br>";
              }
              contentString += additionalData[3];
              contentString += "</span>";
              contentString += "</div>";
            }

            if (!this.isGoogleMap) {
              this.infoWindow = new window.naver.maps.InfoWindow({
                content: contentString,
                disableAnchor: true,
                backgroundColor: "transparent",
                borderWidth: 0,
                pixelOffset: new window.naver.maps.Point(-2, -50),
              });
              this.infoWindow.open(this.map, e.overlay.position);
            }
          };
          // console.log('clickOnMarker - Normal:', e.position, detailInfo)

          if (!this.isGoogleMap) {
            window.naver.maps.Service.reverseGeocode(
              {
                coords: e.overlay.position,
                orders: [
                  window.naver.maps.Service.OrderType.ROAD_ADDR,
                  window.naver.maps.Service.OrderType.ADDR,
                ].join(","),
              },
              geocodeResultFunc
            );
          }
          // }

          if (TymConst.IS_DEVELOPMENT) {
            console.log("MAP markerSelected :", detailInfo[0]);
          }
          this.$emit("markerSelected", detailInfo[0]);
        };

        if (!this.isGoogleMap) {
          if (marker)
            window.naver.maps.Event.addListener(marker, "click", clickOnMarker);
        }
      }
      if (this.isGoogleMap) {
        this.gmarkerData = data;

        if (!TymCommon.isEmpty(this.gmapBounds)) {
          if(!this.mapBounded) {
            if(TymConst.IS_DEVELOPMENT) {
              console.log("MAP markerSelected :", this.gmapBounds);
            }
            this.$refs.gmapRef.map.fitBounds(this.gmapBounds);
            this.mapBounded = true
          }
        }
      }

      if (this.useCluster) {
        const clickOnClusterN = (cluster) => {
          if (this.infoWindow != null) {
            if (TymConst.IS_DEVELOPMENT) {
              console.log("close infoWindow - clickonclusterN");
            }
            this.infoWindow.close();
          }

          let contentString =
            '<div style="margin:0;background-color:#eeeeee;box-shadow: 0 0 5px 5px rgba(22, 21, 23, 0.2);max-height:156pt;overflow-y:auto;font-size:12pt;border: 2px solid darkgray;border-radius:5px;">';
          let i = 0;
          let members = cluster.getClusterMember();
          for (i = 0; i < members.length; i++) {
            contentString += '<div style="text-color:green;background-color:white;border:1px solid #eeeeee;display: flex;justify-content: left;align-items: center;padding:1px 8px 2px 3px;"';
            contentString += ' class="row" id="cls' + i + '">';
            contentString += '<img src="' + members[i].icon.url + '" style="margin:1px 4px 1px 2px;pointer-events:none;">';
            contentString += '<div style="pointer-events:none;">' + members[i].title + "</div>";
            contentString += "</div>";
          }
          contentString += "</div>";

          this.infoWindow = new window.naver.maps.InfoWindow({
            content: contentString,
            borderWidth: 0,
            backgroundColor: "transparent",
            disableAnchor: true,
            pixelOffset: new window.naver.maps.Point(0, -24),
          });
          this.infoWindow.open(this.map, cluster._clusterCenter);

          const onClickCluster = (e) => {
            let index = Number(e.target.id.substring(3));

            this.map.setZoom(20, true);
            this.map.setCenter(members[index].position);

            if (this.infoWindow != null) {
              console.log("close infoWindow - clickOnClusterN");
              this.infoWindow.close();
            }

            return e;
          };

          for (i = 0; i < members.length; i++) {
            let element = document.getElementById("cls" + i);
            element.onmouseover = function (e) {
              element.style.backgroundColor = "rgb(250,231,231)";
              return e;
            };
            element.onmouseout = function (e) {
              element.style.backgroundColor = "white";
              return e;
            };
            element.onclick = onClickCluster;
          }
        };

        const clusterClickFunc = (e) => {
          window.naver.maps.Util.forEach(
            this.markerCluster._clusters,
            function (cluster) {
              if (
                e.overlay.position.x == cluster._clusterCenter.x &&
                e.overlay.position.y == cluster._clusterCenter.y
              ) {
                clickOnClusterN(cluster);
              }
            }
          );
        };

        if (!this.isGoogleMap) {
          // 네이버 지도 클러스터링
          const clusterStylingFunction = (clusterMarker, count, markers) => {
            let abnormal = 0;
            let idx = 0;
            for (idx = 0; idx < count; idx++) {
              if (markers[idx].abnormal == 1) {
                abnormal = 1;
              } else if (markers[idx].abnormal == 2) {
                if (abnormal != 1) {
                  abnormal = 2;
                }
              } else if (markers[idx].abnormal == 3) {
                if (abnormal != 1 && abnormal != 2) {
                  abnormal = 3;
                }
              } else if (markers[idx].abnormal == 4) {
                if (abnormal != 1 && abnormal != 2 && abnormal != 3) {
                  abnormal = 4;
                }
              } else if (markers[idx].abnormal == 5) {
                console.log("lskdjflskdjflsdkjf");
              }
            }

            let elem = clusterMarker.getElement();
            let tgtDiv = elem.querySelector("div:first-child");
            tgtDiv.style.border = this.clusterBorders[abnormal];
            tgtDiv.style.background = this.clusterBGs[abnormal];
            tgtDiv.textContent = count;
            this.unusedParam(markers);
          };

          if (this.markerCluster != null) {
            this.markerCluster.setMap(null);
            this.markerCluster = null;
          }

          let clusterType = 2;
          var htmlCluster = {
            content: this.clusterCircle[clusterType],
            size: window.naver.maps.Size(32, 32),
            anchor: window.naver.maps.Point(16, 16),
          };

          this.markerCluster = new MarkerCluster.MarkerClustering({
            minClusterSize: 2,
            maxZoom: 20,
            map: this.map,
            markers: this.markers,
            disableClickZoom: false,
            gridSize: 100,
            icons: [htmlCluster],
            indexGenerator: [10, 100, 200, 500, 1000],
            stylingFunction: clusterStylingFunction,
            markerClickFunction: clusterClickFunc,
          });
        }

        if (this.markers.length > 1) {
          // 모든 마커가 한 화면에 들어오도록 경계 설정
          if (minLat != 0 && maxLat != 0 && minLng != 0 && maxLng != 0) {
            if (this.isGoogleMap) {
              this.mapBound = new window.google.maps.LatLngBounds();
              this.mapBound.extend({ lat: minLat, lng: minLng });
              this.mapBound.extend({ lat: maxLat, lng: maxLng });
            } else {
              if(minLat < 32.0)
                minLat = 32.0
              if(minLng < 124.520772)
                minLng = 124.520772
              if(maxLat > 38.3)
                maxLat = 38.3
              if(maxLng > 131.874920)
                maxLng = 131.874920
              this.mapBound = new window.naver.maps.LatLngBounds(
                new window.naver.maps.LatLng(minLat, minLng),
                new window.naver.maps.LatLng(maxLat, maxLng)
              );
              // console.log('MapView :', this.mapBound)
              if (this.map) {
                if (!this.mapBounded) {
                  this.map.fitBounds(this.mapBound);
                  this.mapBounded = true;
                  if (TymConst.IS_DEVELOPMENT) {
                    console.log("NMAP fitBounds 1111:", this.mapBound);
                  }
                }
              } else {
                console.log("this.map is null");
              }
            }
          }
        } else if (this.markers.length == 1) {
          if (this.isGoogleMap) {
            //
          } else {
            let markerPos = this.markers[0].getOptions("position");
            this.map.setOptions("zoom", 14);
            this.map.setCenter(markerPos);
          }
        }
      }

      if (cbCompleted) {
        cbCompleted(true);
      }
    },

    changedViewOption(checked) {
      console.log("changedViewOption : ", this.checkedViewAll, checked);
    },
    refreshMap() {
      this.onRefreshClicked();
    },
    madeMarkers() {
      setTimeout(() => {
        if (this.markerCluster) {
          if (this.isGoogleMap) {
            //
          } else {
            let grid = this.markerCluster.getGridSize();
            this.markerCluster.setGridSize(grid);
          }
        }
        this.onUpdating = false;
        this.mapMounted = true;
      }, 100);
      /*
      if (TymConst.IS_DEVELOPMENT) {
        if (this.isGoogleMap) {
          console.log("mapView.GmadeMarkers", this.mapMounted, this.Gmarkers);
        } else {
          console.log("mapView.MmadeMarkers", this.mapMounted, this.markers);
        }
      }
      */
    },
    updateMapData(obj, viewOption, requestAddress, isFirstTime, fitToBound) {
      this.onUpdating = true;
      
      this.gmapInfoView = false;
      this.locaItems = obj;

      // console.log("MapView.updateMapData:", obj)

      if (TymConst.IS_DEVELOPMENT) {
        console.log("MapView.updateMapData :", obj, viewOption)
      }

      let zoomTwice = false;
      if (this.isGoogleMap) {
        if (!TymCommon.isEmpty(this.Gmarkers)) {
          zoomTwice = this.Gmarkers.length < 1;
        }
      }

      this.makeMarkers(this.locaItems, viewOption, requestAddress, isFirstTime, this.madeMarkers);

      if (!TymCommon.isEmpty(fitToBound)) {
        if (fitToBound) {
          this.setBound();
        }
      }
      if (this.locaItems.length == 1) {
        if (
          !TymCommon.isEmpty(this.locaItems[0].latitude) &&
          !TymCommon.isEmpty(this.locaItems[0].longitude)
        ) {
          let lat = parseFloat(this.locaItems[0].latitude);
          let lng = parseFloat(this.locaItems[0].longitude);

          if (!isNaN(lat) && !isNaN(lng)) {
            if (this.isGoogleMap) {
              this.gmapZoomLevel = 18;
              this.gmapCenter = {
                lat: lat,
                lng: lng,
              };
              this.$refs.gmapRef.map.setZoom(this.gmapZoomLevel);
              if (zoomTwice) {
                setTimeout(() => {
                  if(this.$refs.gmapRef)
                    this.$refs.gmapRef.map.setZoom(this.gmapZoomLevel);
                }, 10);
              }
            } else {
              this.map.setCenter({
                lat: lat,
                lng: lng,
              });
            }
          }
        }
      }
      this.updateCompleted = true;
    },
    updateData(index) {
      if (TymConst.IS_DEVELOPMENT) {
        console.log("MapView.updateData : ", index, this.locaItems, this.onUpdating)
      }

      this.unusedParam(index);
      this.makeMarkers(this.locaItems, [true, true, true, true, true], undefined, undefined, this.madeMarkers);
      this.updateCompleted = true;
    },
    //
    updateMachineState(mn, ign, abnormal) {
      this.unusedParam(mn, ign, abnormal);

      if (TymConst.IS_DEVELOPMENT) {
        if(mn == 'FWV10D') {
          console.log("MapView.updateMachineState : ", mn, ign, abnormal);
        }
      }

      if (this.isGoogleMap) {
        if (TymConst.IS_DEVELOPMENT) {
          let foundMarker = this.Gmarkers.find((x) => x.title == mn);
          if (foundMarker) {
            let iconIdx = 0;
            if (abnormal[0]) iconIdx = 1;
            else if (abnormal[1]) iconIdx = 2;
            else if (abnormal[2]) iconIdx = 3;
            else if (abnormal[3]) iconIdx = 4;

            foundMarker.icon = ign
              ? this.markerOnImgsON[iconIdx]
              : this.markerOnImgsOFF[iconIdx];
          }
        }
      } else {
        if (!TymCommon.isEmpty(this.markers)) {
          if (this.markers.length > 0) {
            let foundMarker = this.markers.find((x) => x.title == mn);
            if (foundMarker) {
              let iconIdx = 0;
              if (abnormal[0]) iconIdx = 1;
              else if (abnormal[1]) iconIdx = 2;
              else if (abnormal[2]) iconIdx = 3;
              else if (abnormal[3]) iconIdx = 4;

              if (TymConst.IS_DEVELOPMENT) {
                if(mn == 'FWV10D') {
                  console.log("MapView.updateMachineState :", mn, ign, abnormal, foundMarker );
                }
              }
              foundMarker.setIcon({
                url: ign ? this.markerOnImgsON[iconIdx] : this.markerOnImgsOFF[iconIdx],
              });
            }
          }
        }
      }
    },
    getAddressAtPosition(idx, latitude, longitude) {
      if (TymCommon.isEmpty(latitude) || TymCommon.isEmpty(longitude)) {
        this.$emit("addressReceived", this.locaItems[idx].machineNo, "");
        return;
      }
      if (latitude == 0 && longitude == 0) {
        this.$emit("addressReceived", this.locaItems[idx].machineNo, "");
        return;
      }
      const self = this;
      const geocodeResultFunc = (status, response) => {
        if (TymConst.IS_DEVELOPMENT) {
          console.log("geocodeResultFunc status", status);
          console.log("geocodeResultFunc response", response);
        }

        if (
          !self.isGoogleMap &&
          status === window.naver.maps.Service.Status.ERROR
        ) {
          return;
        }
        if (self.isGoogleMap && status !== "OK") {
          return;
        }

        let address = "";

        if (self.isGoogleMap) {
          if (!TymCommon.isEmpty(response)) {
            if (response.length > 0) {
              address = response[0].formatted_address;
            }
          }
        } else {
          if (!TymCommon.isEmpty(response.v2.address.roadAddress)) {
            address = response.v2.address.roadAddress;
          } else if (!TymCommon.isEmpty(response.v2.address.jibunAddress)) {
            address = response.v2.address.jibunAddress;
          } else {
            let addr = "";
            let i = 0;

            if (!TymCommon.isEmpty(response.result)) {
              if (!TymCommon.isEmpty(response.result.items)) {
                for (i = 0; i < response.result.items.length; i++) {
                  if (response.result.items[i].isAdmAddress) {
                    addr = response.result.items[i].address;
                    break;
                  }
                }
              }
            }
            if (TymCommon.isEmpty(addr)) {
              let roadAddr = response.v2.results.filter(
                (r) => r.name === "addr"
              );
              address = TymCommon.makeAddress(roadAddr[0]);
            } else {
              address = response.result.items[0].address;
            }
          }
        }

        this.$emit("addressReceived", this.locaItems[idx].machineNo, address);
      };

      if (!isNaN(latitude) && !isNaN(longitude)) {
        try {
          if (this.isGoogleMap) {
            const geocoder = new window.google.maps.Geocoder();
            const latlng = new window.google.maps.LatLng(latitude, longitude);
            geocoder.geocode({ latLng: latlng }, function (res, status) {
              if (status == "OK") {
                geocodeResultFunc(status, res);
              } else {
                console.log("GMap Geocode ERR", status.toString());
              }
            });
          } else {
            window.naver.maps.Service.reverseGeocode(
              {
                coords: new window.naver.maps.LatLng(latitude, longitude),
                orders: [
                  window.naver.maps.Service.OrderType.ROAD_ADDR,
                  window.naver.maps.Service.OrderType.ADDR,
                ].join(","),
              },
              geocodeResultFunc
            );
          }
        } catch (ex) {
          console.log("error NMAP geocode :", latitude, longitude, ex);
        }
      }
    },

    getZoomRatio(level) {
      //let traceCircleRadius = TymConst.CIRCLE_RADIUS * Math.pow(2, 18-level)
      let traceCircleRadius = TymConst.CIRCLE_RADIUS * Math.pow(2, this.map.getZoom() - level);
      // console.log("getZoomRatio :", traceCircleRadius);

      return traceCircleRadius;
    },
    // 전달받은 위치들에 점을 찍자...
    /*
    data = [
      {
        "Coord": "36.9260217166,127.4657577",
        "TFID": "0",
        "HDOP": "0.8",
        "TTime": "20230523:114101",
        "State": "A,1,3,A"
      },
      ....
    ]
    */
    updateRouteInfo(data, zoomLevel) {
      if (this.isGoogleMap) {
        this.circles = [];

        if (!TymCommon.isEmpty(data)) {
          let bounds = new window.google.maps.LatLngBounds();

          data.forEach((one) => {
            if (!TymCommon.isEmpty(one.Coord)) {
              let pos = one.Coord.split(",");
              if (pos.length == 2) {
                let lat = parseFloat(pos[0]);
                let lng = parseFloat(pos[1]);

                if (!isNaN(lat) && !isNaN(lng)) {
                  if (lat != 0 && lng != 0) {
                    this.circles.push({
                      center: {
                        lat: lat,
                        lng: lng,
                      },
                      radius: 3,
                      strokeColor:
                        Number(one.TFID) == 0 ? "#303030C0" : "#eb0028",
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor:
                        Number(one.TFID) == 0 ? "#303030C0" : "#eb0028",
                      fillOpacity: 0.8,
                    });

                    bounds.extend(new window.google.maps.LatLng(lat, lng));
                  }
                }
              }
            }
          });
          this.$refs.gmapRef.map.fitBounds(bounds);
        }
      } else {
        if (!TymCommon.isEmpty(this.circleMap) && this.circleMap.length > 0) {
          console.log("Remove circle map...", this.circleMap.length);
          this.circleMap.forEach((r) => toRaw(r).setMap(null));
          this.circleMap = null;
        }

        if(TymConst.IS_DEVELOPMENT) {
          console.log("updateRouteInfo", data, this.circleMap, zoomLevel);
        }

        if (TymCommon.isEmpty(zoomLevel)) {
          let circleZoom = this.getZoomRatio(this.map.zoom);
          this.circleMap = [];
          if (!TymCommon.isEmpty(data) && data.length > 0) {
            let minLat = 0,
              minLng = 0,
              maxLat = 0,
              maxLng = 0;

            for (let i = 0; i < data.length; i++) {
              let circleCoord = null;
              if (!TymCommon.isEmpty(data[i].Coord)) {
                circleCoord = data[i].Coord.split(",");
              }
              let lat = parseFloat(circleCoord[0]);
              let lng = parseFloat(circleCoord[1]);
              if (minLat == 0 && maxLat == 0) {
                minLat = lat;
                maxLat = lat;
              } else {
                if (lat < minLat) minLat = lat;
                if (lat > maxLat) maxLat = lat;
              }
              if (minLng == 0 && maxLng == 0) {
                minLng = lng;
                maxLng = lng;
              } else {
                if (lat < minLng) minLng = lng;
                if (lat > maxLng) maxLng = lng;
              }

              let color = "#303030C0"; // 이동 - 흑색
              let zIndex = 1;
              if (data[i].TFID != 0) {
                // 작업 - 적색
                color = "#eb0028";
                zIndex = 2;
              }

              if (!this.isGoogleMap) {
                this.circleMap.push(
                  new window.naver.maps.Circle({
                    map: this.map,
                    center: new window.naver.maps.LatLng(lat, lng),
                    radius: circleZoom,
                    strokeColor: color,
                    fillColor: color,
                    fillOpacity: 1,
                    zIndex: zIndex,
                  })
                );
              }
            }

            if (data.length > 1) {
              // 모든 마커가 한 화면에 들어오도록 경계 설정
              if (minLat != 0 && maxLat != 0 && minLng != 0 && maxLng != 0) {
                // console.log('Map bound :', minLat, maxLat, minLng, maxLng)
                if (this.isGoogleMap) {
                  this.mapBound = new window.google.maps.LatLngBounds();
                  this.mapBound.extend({ lat: minLat, lng: minLng });
                  this.mapBound.extend({ lat: maxLat, lng: maxLng });
                } else {
                  this.mapBound = new window.naver.maps.LatLngBounds(
                    new window.naver.maps.LatLng(minLat, minLng),
                    new window.naver.maps.LatLng(maxLat, maxLng)
                  );
                }
                if (this.map) {
                  setTimeout(() => {
                    // this.map.panBy(new window.naver.maps.Point(1,0))
                    // console.log("NMAP fitBounds 2222:", this.mapBound);
                    if (!this.mapBounded) {
                      this.map.fitBounds(this.mapBound);
                    }

                    setTimeout(() => {
                      let center = this.map.getCenter();
                      let zoom = this.map.getZoom();
                      if (TymConst.IS_DEVELOPMENT) {
                        console.log("mapView.updateRouteInfo", center, zoom);
                      }
                      if (this.isGoogleMap) {
                        //
                      } else {
                        this.map.updateBy(center, zoom);
                      }
                      this.onUpdating = false;
                    }, 25);
                  }, 1);
                } else {
                  console.log("this.map is null");
                  this.onUpdating = false;
                }
              }
            } else if (data.length == 1) {
              if (TymConst.IS_DEVELOPMENT) {
                console.log("mapView.updateRouteInfo : (data.length == 1)");
              }
              if (!this.isGoogleMap)
                this.map.updateBy(
                  new window.naver.maps.LatLng(37.4135557, 127.1251959),
                  15
                );
              this.onUpdating = false;
            }
          }
          // this.circleMap = circleData
        } else {
          if (TymCommon.isEmpty(data)) {
            this.onUpdating = false;
            return;
          } else if (data.length < 1) {
            this.onUpdating = false;
            return;
          }

          let traceCircleRadius = this.getZoomRatio(zoomLevel);

          for (let idx = 0; idx < data.length; idx++) {
            data[idx].setRadius(traceCircleRadius);
          }
        }
      }
      this.onUpdating = false;
      this.updateCompleted = true;
    },
    updateMarkerState() {
      if (TymConst.IS_DEVELOPMENT) {
        console.log("updateMarkerState", this.markers);
      }
      if (!TymCommon.isEmpty(this.markers)) {
        if (this.markers.length > 0) {
          const store = useTymictStore();
          //
          for (let idx = 0; idx < this.markers.length; idx++) {
            let marker = this.markers[idx];
            let machine = store.machineLocaInfo.find(
              (x) => x.machineNo == marker.title
            );
            if (TymConst.IS_DEVELOPMENT) {
              if (marker.title == "TYMCCC") {
                console.log(
                  "MapView.updateMarkerState MARKER :",
                  marker,
                  machine
                );
              }
            }
            if (machine) {
              /* machine
              {
                "name": "황준열",
                "uid": "fing0828",
                "ads": "None",
                "phone": "01050240828",
                "address": "",
                "modelNo": "TS130Z",
                "machineNo": "TEST240109",
                "manDate": "2024. 1.",
                "serialNo": "T101999900020002",
                "saleDate": "2024. 01. 09.",
                "SD": "2024-01-09T00:00",
                "dealer": "TYMICT",
                "abnormal": [
                  false,
                  true,
                  false,
                  false
                ],
                "latitude": 36.4795371166,
                "longitude": 127.0898262333,
                "hasACU": false,
                "hasTCU": false,
                "hasCPG": false,
                "realtimeInfo": "20240116:112421",
                "realtimeAddr": "",
                "PWR": "OFF",
                "MON": "TS130Z_CP6ANB",
                "manage": {
                  "MA": "0",
                  "MB": "1641201354576-fing0828",
                  "MC": " ",
                  "MD": " "
                }
              }
              */
              let iconIdx = 0;
              if (machine.abnormal[0]) iconIdx = 1;
              else if (machine.abnormal[1]) iconIdx = 2;
              else if (machine.abnormal[2]) iconIdx = 3;
              else if (machine.abnormal[3]) iconIdx = 4;
              else iconIdx = 0;

              let isON = false;
              if (!TymCommon.isEmpty(machine.PWR)) {
                isON = machine.PWR == "ON";
              }
              marker.setIcon(
                isON
                  ? this.markerOnImgsON[iconIdx]
                  : this.markerOnImgsOFF[iconIdx]
              );

              if (TymConst.IS_DEVELOPMENT) {
                if (machine.machineNo == "TYMCCC") {
                  console.log(
                    "MapView.updateMarkerState TYMCCC: ",
                    marker,
                    machine,
                    isON
                  );
                }
              }
            }
          }
        }
      }
    },
    setBound() {
      /*
      if (this.isGoogleMap) {
        //
      } else {
        if (!TymCommon.isEmpty(this.mapBound)) {
          this.map.fitBounds(this.mapBound);
        } else {
          console.log("Map.setBound");
        }
      }
      */
    },
  },
};
</script>
