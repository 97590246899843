const IS_DEVELOPMENT = 0
const VERSION = '2024.08.30.01'

// 페이지 구성 인덱스
const PAGE_DASHBOARD = 0
const PAGE_MACHINE = 1
const PAGE_SALES = 2
const PAGE_MANAGE = 3
const PAGE_STAT = 4
const PAGE_NOTI = 5
const PAGE_GROUP = 6
const PAGE_PRODUCT = 7
const PAGE_CUSTOMER = 8
const PAGE_USIM = 9
const PAGE_UNDERCOVER = 10
const PAGE_MANAGE_POPUP = 20
const PAGE_MONITOR = 21
const PAGE_ACCOUNT = 22

const authTypeKR = ['관리자', '대리점', '지점', '국내C/S', '기계생산', '연구소', '해외영업', '국내영업', '본사', 'CCC']
const authTypeEN = ['Administrator', 'Branch Office', 'State', 'Agency']

const MQTT_TOPIC = [ "+/AtoWEB/#", "+/NAAtoWEB/#"]

const TYMICT_ENCKEY = 'TYMICTESMW2'

// 위치 표시할 때 반지름의 크기 정의
const CIRCLE_RADIUS = 0.75

const MQTT_PROC_INTERVAL = 60000
const DATA_RELOAD_INTERVAL = 120000
const DATA_RELOAD_INTERVAL_DEV = 300000

const TEXT_REPORT_BROKEN_TYPE = ['엔진', '미션', '유압', '전장(센서)', '기타']
const TEXT_ALARM_SELECT_BTN = ['전체선택', '전체해제']
const BUCKET_IMAGE_NAME = 'tymict-manageweb-image-bucket'
const BUCKET_NOTICE_NAME = 'tymict-notice-files'
const BUCKET_MANUAL_NAME = "tym-repair-manual"

const MAX_CONSUMABLE_COUNT = 20

const CONSUMABLE_DEFAULTS = [
    ["엔진 오일 및 카트리지", "Engine Oil and Cartridges"],
    ["연료 필터(A)", "Fuel Filter (A)"],
    ["연료 필터(B)", "Fuel Filter (B)"],
    ["에어클리너 엘리먼트", "Air Cleaner Element"],
    ["트랜스미션 오일", "Transmission Oil"],
    ["유압 오일 필터", "Hydraulic Oil Filter"],
    ["프론트 액슬 오일", "Front Axle Oil"]
]

const CONSUMABLE_ICONS = [
    require('@/assets/manage/consumables_engineoil_icon.svg'),
    require('@/assets/manage/consumables_fuelfilter_icon.svg'), 
    require('@/assets/manage/consumables_aircleaner_icon.svg'), 
    require('@/assets/manage/consumables_transmissionoil_icon.svg'), 
    require('@/assets/manage/consumables_hydraulicoil_icon.svg'), 
    require('@/assets/manage/consumables_frontaxleoil_icon.svg'), 
    require('@/assets/manage/consumables_tmoilfilter_icon.svg'), 
    require('@/assets/manage/consumables_ureapumpfilter_icon.svg'), 
]

// 긴급 - 해결/미해결
const EMER_ALERT_MSTATE_COMPLETE = 2
const EMER_ALERT_MSTATE_INCOMPLETE = 0

const TFID_MODE = {
    MOVE : 0,           // 이동
    TRACTION : 1,       // 견인
    PTO_WORK : 2,       // PTO 작업
    SPARE : 3
}

const ACTION_LOG_IN = "로그인"
const ACTION_LOG_OUT = "로그아웃"
const ACTION_GET_USER = "조회"
const ACTION_ADD_USER = "등록"
const ACTION_EDIT_USER = "수정"
const ACTION_DEL_USER = "삭제"
const ACTION_DEL_SALES = "판매삭제"
const ACTION_DEL_PRODUCT = "생산기대삭제"
const ACTION_DOWNLOAD = "다운로드"
const ACTION_IDCHECK = "체크"

// Allow user-registration with emtpy email. 
const NULL_EMAIL = 'null@tymict.com'

// 첨부파일 갯수 (5개)
const MAX_ATTACHED_FILECOUNT = 5
// 첨부파일 사이즈: 50 * 1024 * 1024
const MAX_ATTACHED_FILESIZE = 52428800


// 차량정보 보여주기 - 모두다
const VIEWOPTIONS = {
    /*
    20240820 작업전
    Ignition: 0,         // 시동상태
    KeyOn: 1,            // KEY 상태
    Battery: 2,          // 배터리 전압
    Coolant: 3,          // 냉각수 온도
    Urea: 4,             // 요소수량
    EngineLoad: 5,       // 엔진부하량
    EngineRPM: 6,        // 엔진회전수
    EngineOil: 7,        // 엔진오일 압력
    AmbientAirTemp: 8,   // 주변공기온도
    DPFRegen: 9,         // DPF 요구단계
    DPFSoot: 10,         // DPF 부하수준
    FuelFilterWater: 11, // 연료필터수분유입
    Inducement: 12,      // 후처리 장치 단계
    UsingTime: 13,       // 사용시간
    RecentAt: 14,        // 최근사용시간
    Fuel: 15,            // 연료량
    ADS: 16,             // 자율주행레벨
    Telematics: 17       // 텔레매틱스 버전
    */
    Ignition: 0,            // 시동상태
    KeyOn: 1,              // KEY 상태
    Battery: 2,             // 배터리 전압
    Fuel: 3,                // 연료량
    Coolant: 4,             // 냉각수 온도
    ParkingBrake: 5,        // 주차 브레이크
    Direction: 6,           // 주행방향
    PTO: 7,                 // PTO
    AirCleaner: 8,          // 에어클리너
    TMOilFilterTemp: 9,         // 변속기 유온
    Urea: 10,               // 요소수량
    FourWD: 11,             // 4WD
    HorizMode: 12,          // 수평
    DepthMode: 13,          // 경심
    DraftMode: 14,          // 견인력
    UsingTime: 15,          // 사용시간
    EngineLoad: 16,         // 엔진부하량
    EngineRPM: 17,          // 엔진회전수
    EngineOil: 18,          // 엔진오일 압력
    AmbientAirTemp: 19,     // 주변공기온도
    DPFRegen: 20,           // DPF 요구단계
    DPFSoot: 21,            // DPF 부하수준
    FuelFilterWater: 22,    // 연료필터수분유입
    Inducement: 23,         // 후처리 장치 단계
    RecentAt: 24,           // 최근사용시간
    ADS: 25,                // 자율주행레벨
    Telematics: 26,         // 텔레매틱스 버전
    Count: 27
}
  

export default {
    IS_DEVELOPMENT,
    VERSION,
    PAGE_DASHBOARD,
    PAGE_MACHINE,
    PAGE_MANAGE,
    PAGE_SALES,
    PAGE_STAT,
    PAGE_NOTI,
    PAGE_GROUP,
    PAGE_PRODUCT,
    PAGE_CUSTOMER,
    PAGE_UNDERCOVER,
    PAGE_MANAGE_POPUP,
    PAGE_MONITOR,
    PAGE_ACCOUNT,
    PAGE_USIM,
    authTypeKR,
    authTypeEN,
    MQTT_TOPIC,
    TYMICT_ENCKEY,
    CIRCLE_RADIUS,
    MQTT_PROC_INTERVAL,
    DATA_RELOAD_INTERVAL,
    DATA_RELOAD_INTERVAL_DEV,
    TEXT_REPORT_BROKEN_TYPE,
    TEXT_ALARM_SELECT_BTN,
    BUCKET_IMAGE_NAME,
    BUCKET_NOTICE_NAME,
    BUCKET_MANUAL_NAME,
    MAX_CONSUMABLE_COUNT,
    CONSUMABLE_DEFAULTS,
    CONSUMABLE_ICONS,
    EMER_ALERT_MSTATE_COMPLETE,
    EMER_ALERT_MSTATE_INCOMPLETE,
    TFID_MODE,
    ACTION_LOG_IN,
    ACTION_LOG_OUT,
    ACTION_GET_USER,
    ACTION_ADD_USER,
    ACTION_EDIT_USER,
    ACTION_DEL_USER,
    ACTION_DEL_SALES,
    ACTION_DEL_PRODUCT,
    ACTION_DOWNLOAD,
    ACTION_IDCHECK,
    NULL_EMAIL,
    MAX_ATTACHED_FILECOUNT,
    MAX_ATTACHED_FILESIZE,
    VIEWOPTIONS
}
