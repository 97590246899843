<template>
  <!-- 관제의 왼쪽 화면 부분 -->
  <div :class='isLandscape ? "row justify-between viewBorder" : "column viewBorder_pt"' style="height:100%;">
    <div class="col column innerBorder" style="padding:0 12px 0 0;">
      <q-item clickable v-ripple class="row items-center justify-between" :class='isLandscape ? "topText_ls" : "topText_pt"'
              :active-class='isLandscape ? "menu_selected_ls" :  "menu_selected_pt"' @click="menuSelected(0)">
        <div class="col" style="font-size:16px;font-weight:500;color:#000000;">
          {{$t('monitor_menu.reg_machine')}}
        </div>
        <q-space/>
        <div style="font-size:20px;color:#212529;" v-show="getItemCount(0)>0">{{ getItemCount(0) }}</div>
        <q-tooltip style="font-size: 14px">{{ $t('tooltip.viewAllRegMachine') }}</q-tooltip>
      </q-item>

      <div class="col-auto" style="height:1px;margin:3px 0 6px 0;background-color: #ededed;"></div>

      <div class="items-center" :class='isLandscape ? "column" : "row justify-center"' style="margin:0 1px 0 0;">
        <!-- 정상 차량 정보 -->
        <div class="col items-center justify-between"
             :class='(isSelected(0) || isSelected(1)) ? (isLandscape ? "  row menuText_ls_sel" : "column menuText_pt_sel") : (isLandscape ? "row menuText_ls" : "column menuText_pt")'
             style="cursor:pointer;"
             :style='isLandscape ? "padding:6px 18px 6px 12px;" : "padding:12px 0 6px 0"'
             @click="menuSelected(1)">
          <div class="col row items-center">
            <q-img src="../assets/dashboard/dashboard_good_icon.svg" width="38px"/>
            <div v-show="isLandscape" style="font-size:16px;font-weight:500;color:#000000;margin-left:12px;">{{ $t('monitor_menu.normal') }}</div>
          </div>
          <div style="font-size:20px;color:#93d500;"
               :style="isLandscape ? 'margin:0 0 0 0;' : 'margin:12px 0 0 0;'">{{ getItemCount(1) > 0 ? getItemCount(1) : ' ' }}</div>
        </div>

        <!-- 긴급알림 차량 정보-->
        <div class="col items-center justify-between"
             :class='(isSelected(0) || isSelected(2)) ? (isLandscape ? "  row menuText_ls_sel" : "column menuText_pt_sel") : (isLandscape ? "row menuText_ls" : "column menuText_pt")'
             style="cursor:pointer;"
             :style='isLandscape ? "padding:6px 18px 6px 12px;" : "padding:12px 0 6px 0"'
             @click="menuSelected(2)">
          <div class="col row items-center">
            <q-img src="../assets/dashboard/dashboard_rollover_icon.svg" width="38px"/>
            <div v-show="isLandscape" style="font-size:16px;font-weight:500;color:#000000;margin-left:12px;">{{ $t('monitor_menu.emergency') }}</div>
          </div>
          <div style="font-size:20px;color:#eb0028;"
               :style="isLandscape ? 'margin:0 0 0 0;' : 'margin:12px 0 0 0;'">{{ getItemCount(2) > 0 ? getItemCount(2) : ' ' }}</div>
        </div>

        <!-- 고장알림 차량 정보-->
        <div class="col items-center justify-between"
             :class='(isSelected(0) || isSelected(3)) ? (isLandscape ? "  row menuText_ls_sel" : "column menuText_pt_sel") : (isLandscape ? "row menuText_ls" : "column menuText_pt")'
             style="cursor:pointer;"
             :style='isLandscape ? "padding:6px 18px 6px 12px;" : "padding:12px 0 6px 0"'
             @click="menuSelected(3)">
          <div class="col row items-center">
            <q-img src="../assets/dashboard/dashboard_error_icon.svg" width="38px"/>
            <div v-show="isLandscape" style="font-size:16px;font-weight:500;color:#000000;margin-left:12px;">{{ $t('monitor_menu.broken') }}</div>
          </div>
          <div style="font-size:20px;color:#c97d31;"
               :style="isLandscape ? 'margin:0 0 0 0;' : 'margin:12px 0 0 0;'">{{ getItemCount(3) > 0 ? getItemCount(3) : ' ' }}</div>
        </div>

        <!-- 소모품교체 알림 차량 정보-->
        <div class="col items-center justify-between"
             :class='(isSelected(0) || isSelected(4)) ? (isLandscape ? "  row menuText_ls_sel" : "column menuText_pt_sel") : (isLandscape ? "row menuText_ls" : "column menuText_pt")'
             style="cursor:pointer;"
             :style='isLandscape ? "padding:6px 18px 6px 12px;" : "padding:12px 0 6px 0"'
             @click="menuSelected(4)">
          <div class="col row items-center">
            <q-img src="../assets/dashboard/dashboard_consumables_icon.svg" width="38px"/>
            <div v-show="isLandscape" style="font-size:16px;font-weight:500;color:#000000;margin-left:12px;">{{ $t('monitor_menu.consumablesRepl') }}</div>
          </div>
          <div style="font-size:20px;color:#ffc600;"
               :style="isLandscape ? 'margin:0 0 0 0;' : 'margin:12px 0 0 0;'">{{ getItemCount(4) > 0 ? getItemCount(4) : ' ' }}</div>
        </div>

        <!-- 배터리경고 차량 정보-->
        <div class="col items-center justify-between"
             :class='(isSelected(0) || isSelected(5)) ? (isLandscape ? "  row menuText_ls_sel" : "column menuText_pt_sel") : (isLandscape ? "row menuText_ls" : "column menuText_pt")'
             style="cursor:pointer;"
             :style='isLandscape ? "padding:6px 18px 6px 12px;" : "padding:12px 0 6px 0"'
             @click="menuSelected(5)">
          <div class="col row items-center">
            <q-img src="../assets/dashboard/dashboard_battery_icon.svg" width="38px"/>
            <div v-show="isLandscape" style="font-size:16px;font-weight:500;color:#000000;margin-left:12px;">{{ $t('monitor_menu.battery') }}</div>
          </div>
          <div style="font-size:20px;color:#bbbbbb;"
               :style="isLandscape ? 'margin:0 0 0 0;' : 'margin:12px 0 0 0;'">{{ getItemCount(5) > 0 ? getItemCount(5) : ' ' }}</div>
        </div>
      </div>
      
      <div class="col-auto" style="height:1px;margin:3px 0 6px 0;background-color: #ededed;"></div>

      <!-- 검색 -->
      <div class="col-auto row justify-end items-center" style="width:auto;" :style='isLandscape ? "margin:3px 0;" : "margin:0 3px 6px 3px;"'>
        <q-form @submit="searchClicked" autocomplete="off" style="width:100%">
          <q-input dense outlined style="font-size:14px;" color="red-7"
                  :placeholder="$t('placeholder.nameorvinorsn')"
                  v-model="searchInput">
            <template v-slot:append>
              <q-btn dense flat icon="search" @click="searchClicked"/>
              <q-btn dense flat icon="close" @click="searchInput = ''"/>
            </template>
          </q-input>
        </q-form>
      </div>

      <div class="col column justify-center items-center" v-show="getSearchCount > 0" style="width:100%;height:100%;padding:0 0;min-height:180px;">
        <!-- 아래 기대정보 -->
        <OneMachine ref="oneMachine" @change="changeView" @view="viewMore"></OneMachine>
      </div>
    </div>
  </div>
</template>

<style scoped>

.selectedMenu {
  background: linear-gradient(
    to right,
    lightgray 2px,
    white 
  );
}
.viewBorder {
  width:380px;
  height:100%;
  background-color: white;
}
.viewBorder_pt {
  width:100%;
  height:auto;
  background-color: white;
}

.innerBorder {
  height:100%;
  margin:0 0 0 12px;
}

.topText_ls {
  height:50px;
  font-size:16px;
  font-weight: bold;
  border-radius: 3px;
  margin:12px 0 3px 0;
}

.topText_pt {
  height:32px;
  font-size:14px;
  font-weight: bold;
  border-radius: 3px;
  margin:3px 0 0 0;
}

.menu_selected_ls {
  background: linear-gradient(
    to right,
    lightgray 2px,
    white 
  );
  border:1px solid lightgray;
}

.menu_selected_pt {
  background: linear-gradient(
    to bottom,
    lightgray 2px,
    white 
  );
  border:1px solid lightgray;
}

.menuText_ls_sel {
  height:64px;
  border-radius: 3px;
  border:1px solid lightgray;
  background: linear-gradient(
    to right,
    lightgray 2px,
    white 
  );
  width:100%;
  margin:3px 0 3px 0;
}
.menuText_ls {
  height:64px;
  border-radius: 3px;
  border:1px solid lightgray;
  background-color:white;
  width:100%;
  margin:3px 0 3px 0;
}
.menuText_pt_sel {
  height:88px;
  font-size:14px;
  font-weight: 500;
  border-radius: 3px;
  border:1px solid lightgray;
  margin:0 3px 3px 3px;
  background: linear-gradient(
    to bottom,
    lightgray 2px,
    white 
  );
}

.menuText_pt {
  height:88px;
  font-size:14px;
  font-weight: 500;
  margin:0 3px 3px 3px;
  border-radius: 3px;
  border:1px solid lightgray;
  background-color:white;
}
</style>

<script>
import { useTymictStore } from '@/store/tymict'
import { ref } from 'vue'
import OneMachine from '../components/OneMachine.vue'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'

export default ({
  props: {
    onoff : Boolean
  },
  data() {
    return {
      itemHeaders : [
        { header : '소유자', content : '' },
        { header : '전화번호', content : '' },
        { header : '주소', content : '' },
        { header : '모델명', content : '' },
        { header : '차대번호', content : '' },
        { header : '제조일', content : '' },
        { header : '시리얼번호', content : '' },
        { header : '판매일', content : '' },
        { header : '판매점', content : '' },
        { header : '현재위치', content : '' }
      ],
      searchInput : ref(''),
      counts : ref([0, 0, 0, 0, 0, 0]),
      locaitems : ref([]),
      searchItems : ref([]),
      curIndex : ref(0)
    }
  },
  components : {
    OneMachine
  },
  computed: {
    isLandscape() {
      const store = useTymictStore()
      return store.isLandscape
    },
    getTotalCount() {
      const store = useTymictStore()
      console.log('getTotalCount : ', store.SalesData.data.length)
      return store.SalesData.data.length
      /*
      if(this.locaitems) {
        return this.locaitems.length;
      }
      return 0
      */
    },
    isSelected() {
      return (idx) => {
        return this.selectedMenu[idx]
      }
    },
    getItemCount() {
      return (idx) => {
        return this.counts[idx]
        /*
        const store = useTymictStore()
         
        switch(idx) {
          case 0:
            return store.SalesData.data.length
          case 1:
            return this.counts[idx]
          case 2:
            return store.EmergencyData.data.length
          case 3:
            return store.BrokenData.data.length
          case 4:
            return store.ConsumablesData.data.length
          case 5:
            return store.BatteryAlertData.data.length
          default:
            return 0
        }
        */
      }
    },
    getSearchCount() {
      return this.searchItems.length
    },
    onoffState() {
      return this.onoff
    },
    isGoogleMap() {
      const store = useTymictStore()
      return (store.connectServer != 0)
    }
  },
  watch : {
    onoffState : {
      handler(newVal, oldVal) {
        if(newVal != oldVal) {
          this.ignitionChanged(newVal)
        }
      },
      immediate : true
    }
  },
  setup() {
    return {
      selectedMenu : ref([false, true, true, true, true, true]),
      curPage : ref(1),
      pageCount : ref(1),
      locationInfo : null,
    }
  },
  created() {},
  mounted() {
    this.searchInput = ''
    this.searchItems = []
  }, 
  unmounted() {
  },
  methods : {
    unusedParam() {
      //
    },
    menuSelected(idx) {
      if(idx == 0) {
        this.selectedMenu[idx] = !this.selectedMenu[idx]
      } else if(this.selectedMenu[0]) {
        this.selectedMenu[0] = false
        let i = 0
        for(i = 1; i < this.selectedMenu.length; i++) {
          this.selectedMenu[i] = i != idx
        }
      } else {
       this.selectedMenu[idx] = !this.selectedMenu[idx]
      }

      this.$emit('locaitems', this.selectedMenu)
    },
    searchClicked() {
      this.searchItems = []
      if(!TymCommon.isEmpty(this.searchInput)) {
        this.$emit('search', this.searchInput)
        if(this.locaitems && (this.locaitems.length > 0)) {
          // let i = 0
          let strInput = this.searchInput.toUpperCase()
          this.searchItems = this.locaitems.filter(x => (x.name.toUpperCase().indexOf(strInput) >= 0) || (x.machineNo.toUpperCase().indexOf(strInput) >= 0) || (x.serialNo.toUpperCase().indexOf(strInput) >= 0) )
          //const store = useTymictStore()
          //let salesItems = store.SalesData.data.filter(x => (x.owner.toUpperCase().indexOf(strInput) >= 0) || (x.machineNo.toUpperCase().indexOf(strInput) >= 0) || (x.serialNo.toUpperCase().indexOf(strInput) >= 0) )

          //store.SalesData.data.filter()
          /*
          for(i = 0; i < this.locaitems.length; i++) {
            let strSrc = String(this.locaitems[i].name)
            if(strSrc.toUpperCase().indexOf(strInput) >= 0) {
              this.searchItems.push(this.locaitems[i])
            } else {
              strSrc = String(this.locaitems[i].machineNo)
              if(strSrc.toUpperCase().indexOf(strInput) >= 0) {
                this.searchItems.push(this.locaitems[i])
              } else {
                strSrc = String(this.locaitems[i].serialNo)
                if(strSrc.toUpperCase().indexOf(strInput) >= 0) {
                  this.searchItems.push(this.locaitems[i])
                }
              }
            }
          }
          */
          //console.log('MonitorInfoView.searchClicked #2:', this.locaitems, this.searchItems, salesItems)  
        }

        if(this.searchItems.length > 0) {
          this.curIndex = 0

          this.$refs.oneMachine.updateData(this.curIndex, this.searchItems)
        }
      }
    },

    // cnt : 전체, 정상, 긴급, 고장, 소모품, 배터리등의 갯수 배열
    // items : 모든 등록 차대의 정보
    updateData(cnt, items) {
      this.counts = cnt
      this.locaitems = items
    },

    changeView(idx) {
      this.curIndex = idx

      if(TymConst.IS_DEVELOPMENT) {
        console.log('MonitorInfoView.changeView :', idx, this.searchItems[idx])
      }
      this.$refs.oneMachine.updateData(this.curIndex, this.searchItems)
    },

    viewMore(idx) {
      this.unusedParam(idx)
      // console.log('MonitorInfoView.viewMore :', this.curIndex, this.searchItems)

      /*
      이렇게 만들어줘
      {
          "index": 0,
          "name": "조창종",
          "phone": "010-5464-6597",
          "uid": "changjong",
          "machineNo": "TX76SD01047",
          "serialNo": "T001215102470247",
          "dealer": "충북직영점",
          "state": 0,
          "desc": "차량 전복",
          "date": "2023. 06. 20. 06:06"
      }
      */
      const store = useTymictStore()
      if(this.searchItems.length == 1) {
        let item = this.searchItems[0]
        item.index = 0
        item.state = 0
        item.desc = ''
        store.selectManageInfo = { kind: -1, data: item};
      } else {
        /*
        let items = []
        for(let idx = 0; idx < this.searchItems.length; idx++) {
          items.push({
            index : idx,
            name : this.searchItems[idx].name,
            phone : this.searchItems[idx].phone,
            uid : this.searchItems[idx].uid,
            machineNo : this.searchItems[idx].machineNo,
            serialNo : this.searchItems[idx].serialNo,
            dealer : this.searchItems[idx].delaer,
            state : 0,
            desc : '',
            date : this.searchItems[idx].saleDate
          })
        }
        */
        let curIdx = 0 - (this.curIndex + 1)    // 0은 인덱스로 쓰일거라 여기선 0이 시작이 아니라 -1이 시작으로 보낸다.
        store.selectManageInfo = {
          kind: curIdx,
          data: this.searchItems
        }
        console.log('---->', store.selectManageInfo)
      }

      // Vue Router 통해 페이지 전환 시킴...
      // 2023.03.10 여길 팝업형태로 바꿔야하는 데 방법이...?
      store.setPage(TymConst.PAGE_MANAGE_POPUP)
    },

    moveToScreen(index) {
      switch(index) {
        case 0:   // Emergency
          break;
        case 1:   // broken
          break;
        case 2:   // Comsumables
          break;
        case 3:   // battery
          break;
      }
    },

    updateMarkerData(item) {
      /*
      {
          "name": "금영옥",
          "uid": "kyo640905",
          "ads": "None",
          "phone": "010-2100-4923",
          "address": "경북 봉화군 봉성면 봉성현길 23 ",
          "modelNo": "K110E",
          "machineNo": "NFHT00033",
          "manDate": "2022. 10.",
          "serialNo": "T001221400620062",
          "saleDate": "2023. 03. 31. 04:51",
          "SD": "2023-03-31T04:51",
          "dealer": "봉화딜러점(김중우)",
          "abnormal": [
              false,
              false,
              true,
              false
          ],
          "latitude": 36.91393525,
          "longitude": 128.7995519833,
          "hasACU": false,
          "hasTCU": false,
          "hasCPG": false,
          "realtimeInfo": "20230828:130806.977",
          "realtimeAddr": "",
          "MON": "K110E_NONE",
          "manage": {
              "MA": "0",
              "MB": "2",
              "MC": "12",
              "MD": "1683704656538-gyeongbuk"
          },
          "uuid_a": "0",
          "uuid_b": "2",
          "uuid_c": "12",
          "uuid_d": "1683704656538-gyeongbuk"
      }
      */

      if(TymConst.IS_DEVELOPMENT) {
        console.log('updateMarkerData : ', item)
      }

      if(TymCommon.isEmpty(item.SD)) {
        console.log('empty SD')
        this.searchItems = [ ]
        this.curIndex = -1
        this.$refs.oneMachine.updateData(this.curIndex, this.searchItems)
        return
      }

      const store = useTymictStore()
      let one = store.SalesData.data.find(x => x.machineNo == item.machineNo)
      if(one) {
        let newItem = item
        /*
        newItem.name = one.owner
        newItem.phone = one.mobile
        newItem.address = one.address
        newItem.modelNo = item.modelNo
        newItem.machineNo = item.machineNo
        newItem.manDate = one.manDate
        newItem.serialNo = one.serialNo
        newItem.saleDate = one.saleDate
        newItem.dealer = one.dealer
        newItem.latitude = item.latitude
        newItem.longitude = item.longitude
        newItem.realtimeAddr = ''
        newItem.abnormal = item.abnormal
        */
        const geocodeResultFunc = (status, response) => {
          let address = ''
          if(this.isGoogleMap) {
            if(status == 'OK') {
              // console.log('GMap Geocode ##2', response, status)
              this.searchItems = [ newItem ]
              this.curIndex = 0
              this.$refs.oneMachine.updateData(this.curIndex, this.searchItems)

              address = response[0].formatted_address
            } else {
              console.log('GMap Geocode ERR', status.toString())
            }
          } else {
            if (status === window.naver.maps.Service.Status.ERROR) {
              this.searchItems = [ newItem ]
              this.curIndex = 0
              this.$refs.oneMachine.updateData(this.curIndex, this.searchItems)
              return;
            }

            if(!TymCommon.isEmpty(response.v2.address.roadAddress)) {
              address = response.v2.address.roadAddress
            } else if(!TymCommon.isEmpty(response.v2.address.jibunAddress)) {
              address = response.v2.address.jibunAddress
            } else{
              let addr = ''
              let i = 0
              let result = null
              if(Array.isArray(response.result)) {
                if(response.result.length > 0)
                  result = response.result[0]
              } else {
                result = response.result
              }

              // console.log('MonitorInfoView.geocodeResultFunc :', response, result)
              if(!TymCommon.isEmpty(result) && !TymCommon.isEmpty(result.items)) {
                for(i = 0; i < result.items.length; i++) {
                  if(result.items[i].isAdmAddress) {
                    addr = result.items[i].address
                    break;
                  }
                }
              }
              if(TymCommon.isEmpty(addr)) {
                let roadAddr = response.v2.results.filter(r => r.name === 'addr');
                address = TymCommon.makeAddress(roadAddr[0])
              } else {
                address = response.result.items[0].address
              }
            }
          }
          newItem.realtimeAddr = address
          this.searchItems = [ newItem ]
          this.curIndex = 0
          this.$refs.oneMachine.updateData(this.curIndex, this.searchItems)
        }

        if(this.isGoogleMap) {
          const geocoder = new window.google.maps.Geocoder()
          const latlng = new window.google.maps.LatLng(item.latitude, item.longitude)

          // console.log('GMAP geocoder.geocode : ', item)

          geocoder.geocode({'latLng' : latlng}, function(res, status) {
            //console.log('GMap Geocode ##2', res, status)
            if(status == 'OK') {
              // console.log('GMap Geocode ##2', res, status)
              geocodeResultFunc(status, res)
            } else {
              console.log('GMap Geocode ERR', status.toString())
            }
          })
        } else {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('NMAP geocoder.geocode : ', item)
          }

          try {
            window.naver.maps.Service.reverseGeocode({
              coords: new window.naver.maps.LatLng(item.latitude, item.longitude), 
              orders: [
                window.naver.maps.Service.OrderType.ROAD_ADDR,
                window.naver.maps.Service.OrderType.ADDR
              ].join(',')
            }, geocodeResultFunc)
          } catch(ex) {
            console.log('NMAP geocode Error :', ex)
          }
        }
      }
    },
    ignitionChanged(isOn) {
      console.log('ignitionChanged', isOn)
    }
  }
})
</script>
