/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = [{ 
        "aws_project_region": "ap-northeast-2",
        "aws_cognito_identity_pool_id": "ap-northeast-2:db50ccbb-0300-43fc-a5e9-f9877d4e7573",
        "aws_cognito_region": "ap-northeast-2",
        "aws_user_pools_id": "ap-northeast-2_1Pndkiyzu",
        "aws_user_pools_web_client_id": "3n60ksrk34bgmeub9kqfok32hr",

        "oauth": {},
        "aws_cognito_username_attributes": [],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [
            "SMS"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ]
    },
    {
        "aws_project_region": "us-east-2",
        "aws_cognito_identity_pool_id": "us-east-2:60b73625-84b5-46d5-a360-bdb877c03a5b",
        "aws_cognito_region": "us-east-2",
        "aws_user_pools_id": "us-east-2_BbT9WhNZY",
        "aws_user_pools_web_client_id": "7puv1m7ddqaqpeal1isb08dtoh",

        "oauth": {},
        "aws_cognito_username_attributes": [],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [
            "SMS"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ]
    }
];


export default awsmobile;
