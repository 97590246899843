<template>
  <div class="column" >
    <div class="col column" style="padding:0 12px 12px 12px">
      <div class="col-auto row items-center justify-between" style="height:45px;width:100%;">
        <div class="col-auto"
            :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
          {{ getPageTitle }}
        </div>
      </div>

      <div class="col column middleBox">
        <div :class=" isLandscape ? 'col-auto row justify-between' : 'col-auto row justify-between items-center'">
          <!-- 딜러점 정보 -->
          <div class="col-auto row justify-start">
            <q-img class="col-auto" style="margin-right: 12px" src="../assets/dashboard/dashboard_dealer_p.png" fit="contain" width="94px" height="58px"/>
            <div class="col-auto column justify-between items-bottom">
              <div class="col" style="font-size: 14px">{{ organName }}</div>
              <div class="col" style="font-size: 16px; font-weight: 500">
                {{ machineCount }}
              </div>
            </div>
          </div>

          <!-- 검색 -->
          <div class="col-auto row justify-end items-center" :style="isLandscape ? 'min-width:320px;' : 'width:auto;'">
            <div class="col row">
              <div class="col row items-center">
                <div class="col row items-center">
                  <div class="col row items-center justify-between treeBorder" style="border-radius: 5px;height:40px;min-width:180px;margin:0 12px 0 0">
                    <!-- 조직 선택-->
                    <q-popup-proxy ref="groupPopup" style="padding:3px;background-color:white;" @before-show="groupPopupDisplayed = true" @before-hide="groupPopupDisplayed = false">
                      <div class="column" style="min-width:240px;height:250px;">
                        <div class="col row" style="border:1px solid #cccccc;padding:3px;">
                          <q-scroll-area class="col row" style="width:auto;height:100%;">
                            <q-tree
                              :nodes="groupTreeData"
                              dense
                              no-selection-unset
                              class="col"
                              node-key="uuid"
                              v-model:expanded="expandedTree"
                              v-model:selected="selectedTree"
                              @update:selected="groupTreeSelected"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </q-popup-proxy>
                    <div class="col" style="margin:0 12px;">
                      {{ selectedTreeLabel }}
                    </div>
                    <q-icon class="col-auto" dense  color="grey-8" :name='groupPopupDisplayed ? "arrow_drop_up" : "arrow_drop_down"' size="24px">
                    </q-icon>
                  </div>

                  <q-input col="col" v-model="searchInput" color="red-7" outlined dense clearable flat autofocus :placeholder="$t('placeholder.nameorvin')"
                           style="width:240px;"
                           @keyup.enter="searchClicked">
                    <template v-slot:append>
                      <q-btn dense flat icon="search" style="margin:0 0;" @click="searchClicked();"/>
                    </template>
                  </q-input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 구분선 -->
        <div style="width: 100%; height: 1px; background-color: #e7e7e7; margin: 12px 0;"></div>

        <div v-show="catCountPerScreen == 1">
          <div class="row justify-end">
            <q-btn dense round flat style="margin: 0 6px" @click="curCategory = 1" >
              <q-img
                src="~../assets/dashboard/dashboard_rollover_icon.svg"
                fit="content"
                width="32px"
                height="32px"
              />
              <q-tooltip style="font-size: 14px">Rollover, Geofence</q-tooltip>
            </q-btn>
            <q-btn
              dense
              round
              flat
              style="margin: 0 6px"
              @click="curCategory = 2"
            >
              <q-img
                src="~../assets/dashboard/dashboard_error_icon.svg"
                fit="content"
                width="32px"
                height="32px"
              />
              <q-tooltip style="font-size: 14px">Error (Breakdown)</q-tooltip>
            </q-btn>
            <q-btn
              dense
              round
              flat
              style="margin: 0 6px"
              @click="curCategory = 3"
            >
              <q-img
                src="~../assets/dashboard/dashboard_consumables_icon.svg"
                fit="content"
                width="32px"
                height="32px"
              />
              <q-tooltip style="font-size: 14px"
                >Consumables Replacement</q-tooltip
              >
            </q-btn>
            <q-btn
              dense
              round
              flat
              style="margin: 0 6px"
              @click="curCategory = 4"
            >
              <q-img
                src="~../assets/dashboard/dashboard_battery_icon.svg"
                fit="content"
                width="32px"
                height="32px"
              />
              <q-tooltip style="font-size: 14px">Battery Voltage &lt; 11.5 V</q-tooltip>
            </q-btn>
          </div>
        </div>

        <div class="col row bottomContainer" style="height: auto">
          <!--- 아랫부분 보기 -->
          <div ref="itemContainer" :class="itemContainerClass" style="height: 100%">
            <div ref="item1" :class="itemClass" v-show=" catCountPerScreen > 1 || (catCountPerScreen == 1 && curCategory === 1)">
              <ManagementItem ref="mana1" :kind="0" @select="selectItem" />
            </div>
            <div ref="item2" :class="itemClass" v-show="catCountPerScreen > 1 ||(catCountPerScreen == 1 && curCategory === 2)">
              <ManagementItem ref="mana2" :kind="1" @select="selectItem" />
            </div>
            <div ref="item3" :class="itemClass" v-show=" catCountPerScreen > 1 || (catCountPerScreen == 1 && curCategory === 3)">
              <ManagementItem ref="mana3" :kind="2" @select="selectItem" />
            </div>
            <div ref="item4" :class="itemClass" v-show="catCountPerScreen > 1 || (catCountPerScreen == 1 && curCategory === 4)">
              <ManagementItem ref="mana4" :kind="3" @select="selectItem" />
            </div>
            <q-resize-observer @resize="onResize" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.middleBox {
  background-color: white;
  padding: 12px;
  border-radius: 3px;
}

.machineTitle {
  font-size: 16px;
  font-weight: 400;
}
.bottomContainer {
  min-height: 400pt;
}

.searchInput {
  border-radius: 3px;
  border:1px solid #cccccc;
  border-radius:3px;
  height:40px;
  cursor:pointer
}
.searchInput:hover {
  border-radius: 3px;
  border:1px solid black;
  border-radius:3px;
  height:40px;
  cursor:pointer
}
.searchInput:active {
  border-radius: 3px;
  border:2px solid #eb0028;
  border-radius:3px;
  height:40px;
  cursor:pointer
}
.treeBorder {
  border:1px solid #cccccc;
}
.treeBorder:hover {
  border:2px solid #cb0008;
}
.treeBorder:active {
  border:2px solid #eb0028;
}

</style>

<script>
import { ref } from "vue";
//import { useQuasar } from 'quasar'
import { useTymictStore } from "@/store/tymict";
import TymFncs from "@/js/tymfunctions.js";
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'
import ManagementItem from "@/components/ManagementItem.vue";
//import TymAws from '@/js/tymaws.js'

export default {
  data() {},
  components: {
    ManagementItem,
  },
  setup() {
    return {
      searchInput: ref(""),
      itemContainerClass: ref("col row"),
      itemClass: ref(""),
      catCountPerScreen: ref(4),
      curCategory: ref(1),

      groupPopupDisplayed : ref(false),
      groupTreeData : ref([]),
      expandedTree : ref([]),
      selectedTree : ref(""),
      selectedTreeLabel : ref(""),
    };
  },
  computed: {
    isLandscape: function () {
        const store = useTymictStore();
      return store.isLandscape;
    },
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    getPageTitle: function () {
      return TymFncs.getPageTitle();
    },
    organName: function () {
      const store = useTymictStore();
      return store.groupName;
    },
    machineCount: function () {
      const store = useTymictStore();
      return (store.EmergencyData.data.length + store.BrokenData.data.length + store.ConsumablesData.data.length + store.BatteryAlertData.data.length)
      // return store.machineLocaInfo.length;
    },
    getGroupTreeData() {
      const store = useTymictStore() 
      //console.log('SaleView.computed.getGroupTreeData', this.groupTreeData)
      return store.groupTree
    },
  },
  watch: {

  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      const store = useTymictStore()
      if(store.CurrentPage.Now != TymConst.PAGE_MANAGE) {
        store.setPage(TymConst.PAGE_MANAGE)
      }
      this.curCategory = 1;

      try {
        if(store.groupTree.length > 0) {
          this.groupTreeData = store.groupTree
          this.expandedTree = [this.groupTreeData[0].uuid]
          this.selectedTree = this.groupTreeData[0].uuid
          this.selectedTreeLabel = this.groupTreeData[0].label
        }

        store.refreshMainItems(this.getEmergencyAlert, this.getBrokenAlert, this.getConsumableAlert, this.getLowBatteryAlert)
        store.getPowerState(this.powerSuccess, this.powerFailed)

        let saved = sessionStorage.getItem('MAN.saved')
        if(!TymCommon.isEmpty(saved)) {
          saved = JSON.parse(saved)
          this.searchInput = saved.Keyword
          if(TymConst.IS_DEVELOPMENT) {
            console.log('MAN.mounted.saved :', saved)
          }
          sessionStorage.removeItem('MAN.saved')

          // this.searchClicked()
        }
        this.groupTreeSelected(this.selectedTree)
      } catch(ex) {
        console.log("ManagementView Exception :", ex)
      }
    }, 1)
  },
  unmounted() {
    sessionStorage.setItem('MAN.saved', JSON.stringify({
      Keyword: this.searchInput
    }))
  },
  methods: {
    unusedParam(param) {
      return param;
    },
    getVersion() {
      return TymConst.VERSION
    },
    onResize(size) {
      // console.log('onResize', size)
      this.unusedParam(size);
      if (this.$refs.item1) {
        if (this.$refs.itemContainer.clientWidth < 800) {
          // only one column
          this.itemContainerClass = "col column";
          this.itemClass = "col";
          this.$refs.item1.style = "padding:0 0 0 0;height:100%;";
          this.$refs.item2.style = "padding:0 0 0 0;height:100%;";
          this.$refs.item3.style = "padding:0 0 0 0;height:100%;";
          this.$refs.item4.style = "padding:0 0 0 0;height:100%;";
          this.catCountPerScreen = 1;

          this.$refs.item1.style.display =
            this.curCategory == 1 ? "block" : "none";
          this.$refs.item2.style.display =
            this.curCategory == 2 ? "block" : "none";
          this.$refs.item3.style.display =
            this.curCategory == 3 ? "block" : "none";
          this.$refs.item4.style.display =
            this.curCategory == 4 ? "block" : "none";
        } else if (this.$refs.itemContainer.clientWidth < 1450) {
          // 2 columns
          this.itemContainerClass = "col row";
          this.itemClass = "col-6";
          this.$refs.item1.style = "padding:0 6px 0 0;";
          this.$refs.item2.style = "padding:0 0 0 6px;";
          this.$refs.item3.style = "padding:12px 6px 0 0;";
          this.$refs.item4.style = "padding:12px 0 0 6px;";
          this.catCountPerScreen = 2;

          this.$refs.item1.style.display = "block";
          this.$refs.item2.style.display = "block";
          this.$refs.item3.style.display = "block";
          this.$refs.item4.style.display = "block";
        } else if (this.$refs.itemContainer.clientWidth >= 1450) {
          // 4columns
          this.itemContainerClass = "col row";
          this.itemClass = "col";
          this.$refs.item1.style = "padding:0 6px 0 0;";
          this.$refs.item2.style = "padding:0 6px;";
          this.$refs.item3.style = "padding:0 6px";
          this.$refs.item4.style = "padding:0 0 0 6px;";
          this.catCountPerScreen = 4;

          this.$refs.item1.style.display = "block";
          this.$refs.item2.style.display = "block";
          this.$refs.item3.style.display = "block";
          this.$refs.item4.style.display = "block";
        }
      }
    },
    powerSuccess(data) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('managementView.powerSuccess :', data)
      }
      if(this.$refs.mana1) {
        this.$refs.mana1.updateData()
        this.$refs.mana2.updateData()
        this.$refs.mana3.updateData()
        this.$refs.mana4.updateData()
      }
    },
    powerFailed() {
      if(this.$refs.mana1) {
        this.$refs.mana1.updateData()
        this.$refs.mana2.updateData()
        this.$refs.mana3.updateData()
        this.$refs.mana4.updateData()
      }
    },
    getEmergencyAlert(hasNoError) {
      this.unusedParam(hasNoError)
      setTimeout(()=> {
        if(this.$refs.mana1) {
          this.$refs.mana1.updateData()
        }
      }, 1)
    },
    getBrokenAlert(hasNoError) {
      this.unusedParam(hasNoError)
      setTimeout(()=> {
        if(this.$refs.mana2) {
          this.$refs.mana2.updateData()
        }
      }, 1)
    },
    getConsumableAlert(hasNoError) {
      this.unusedParam(hasNoError)
      setTimeout(()=> {
        if(this.$refs.mana3) {
          this.$refs.mana3.updateData()
        }
      }, 1)
    },
    getLowBatteryAlert(hasNoError) {
      this.unusedParam(hasNoError)
      setTimeout(()=> {
        if(this.$refs.mana4) {
          this.$refs.mana4.updateData()
        }
      }, 1)
    },
    searchClicked() {
      let input = this.searchInput
      if(!TymCommon.isEmpty(input)) {
        input = input.trim()
      }

      this.$refs.mana1.searchItems(input)
      this.$refs.mana2.searchItems(input)
      this.$refs.mana3.searchItems(input)
      this.$refs.mana4.searchItems(input)
    },

    // 각 리스트에서 아이템을 선택했을 때의 이벤트 핸들러
    // kind : 응급, 고장, 소모품, 배터리
    selectItem(kind, selected) {
      const store = useTymictStore();
      store.selectManageInfo = { kind: kind, data: selected };

      if(TymConst.IS_DEVELOPMENT) {
        console.log('ManagementView.selectItem :', kind, selected, store.selectManageInfo)
      }
      // Vue Router 통해 페이지 전환 시킴...
      // 2023.03.10 여길 팝업형태로 바꿔야하는 데 방법이...?
      store.setPage(TymConst.PAGE_MANAGE_POPUP)
    },
    groupTreeSelected(tgt) {
      const store = useTymictStore()
      let found = store.groupInfo.find(x => x.uuid == tgt)

      this.selectedTree = tgt
      if(found) {
        if(found.uuid_d == tgt) {
          this.selectedTreeLabel = found.group_d
        } else if(found.uuid_c == tgt) {
          this.selectedTreeLabel = found.group_c
        } else if(found.uuid_b == tgt) {
          this.selectedTreeLabel = found.group_b
        } else if(found.uuid_a == tgt) {
          this.selectedTreeLabel = found.group_a
        }
      }

      let keyword = TymCommon.isEmpty(this.searchInput) ? '' : this.searchInput.trim()
      // console.log('---------------------', tgt, this.selectedTreeLabel, found, keyword)

      this.$refs.mana1.searchGroup(found, keyword)
      this.$refs.mana2.searchGroup(found, keyword)
      this.$refs.mana3.searchGroup(found, keyword)
      this.$refs.mana4.searchGroup(found, keyword)

      this.$refs.groupPopup.hide()
    },

  },
};
</script>
