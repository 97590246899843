<template>
  <div class="column" style="height:100%;min-height:480px;">
  <div class="col column" style="background-color:#f2f2f2;">

    <div class="col column" style="position:relative;background-color:white;border-radius:3px;padding:6px;margin:0 0 6px 0;">
      <div class="col-auto row items-center" style="margin:6px;">
        <div class="col-auto" style="font-size:15px;font-weight:500;margin:0 6px">{{ $t('manage.engineHours') }}&nbsp;(h)</div>
        <div class="col row justify-between items-center" >
          <div class="col-auto row">
            <div class="col-auto row items-center justify-between" style="cursor:grab;font-size:15px;border:1px solid #bbbbbb;margin:3px 6px;padding:3px 6px;width:120px;"> {{  engineDate }}
              <q-popup-proxy ref="engineDayPicker" cover transition-show="scale" transition-hide="scale">
                <div class="column" style="margin:12px 6px;">
                  <q-date class="col" flat minimal 
                          emit-immediately
                          color="red-7" 
                          default-view="Months"
                          :years-in-month-view="true"
                          :title="consumableTitle" subtitle="Replacement"
                          v-model="proxyDateEngine"
                          @update:model-value="engineChangeDate"
                          >
                  </q-date>
                  <!--
                  <div class="col row items-center justify-center">
                    <q-btn class="col-auto" outline dense color="red-5" icon="cancel" :label="$t('common.cancel')"
                            style="width:100px;height:36px;font-size:15px;font-weight:500;margin-right:6px;"
                            @click="selectEngineDate('cancel')">
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                    </q-btn>
                    <q-btn class="col-auto" outline dense color="blue-8" :label="$t('common.confirm')" icon="save"
                            style="width:100px;height:36px;font-size:15px;font-weight:500;margin-left:6px;"
                            @click="selectEngineDate('confirm')">
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.applyJob') }}</q-tooltip>
                    </q-btn>
                  </div>
                  -->
                </div>
              </q-popup-proxy>
              <q-icon size="sm" color="grey-8" name="calendar_month"></q-icon>
            </div>
            <div class="col-auto row" style="border:1px solid #bbbbbb;margin:3px 6px 3px 3px">
              <q-btn flat dense size="16px" color="grey-10" icon="search" @click="searchEngineHour" :disable="engineDate == ''">
              </q-btn>
            </div>
            <!-- 이전으로 돌아가는 기능을 만들어주고 싶었으나... Q-Date 이녀석의 영어가 맘에 안들어
            <div class="col-auto row" style="border:1px solid #bbbbbb;margin:3px" v-show="engineDate.length > 4">
              <q-btn flat dense size="16px" color="grey-10" icon="reply" @click="changeToYear('usage')">
              </q-btn>
            </div>
            -->
            <div class="col-auto row justify-center items-center">
              <q-icon size="sm" color="grey-6" name="info" style="margin:0 12px;">
                <q-tooltip>
                  <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:14px;">
                    {{ $t('manage.engineHourTooltip')}}
                  </div>
                </q-tooltip>
              </q-icon>
            </div>
          </div>
          <div class="col-auto row justify-end" v-show="canDownloadToExcel">
            <q-btn class="col-auto" flat dense>
              <q-img src="~../assets/manage/management_excel_icon.svg" fit="fill" width="26px" height="32px"/>
              <q-tooltip style="font-size:14px">{{ $t('tooltip.exportExcel') }}</q-tooltip>
              <q-popup-proxy ref="excelPopupUsage" style="width:360px;height:200px;background-color: #01010130;border-radius: 3px;position: relative;"
                              @before-show="onShowExcelUsage">
                <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                  <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                    <div class="col-auto row items-center justify-center">
                      <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                        {{ $t('common.toExcel') }}
                      </div>
                    </div>
                    <div class="col column" v-show="hasExcelUsage">
                      <div class="col row items-start" style="width:100%;">
                        <table class="col" style="width:100%;margin:0;">
                          <tr style="">
                            <td style="text-align: right;">{{ $t('common.filename') }}</td>
                            <td width="12px" style=""></td>
                            <td style="">{{ excelFilename }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">{{ $t('common.date') }}</td>
                            <td width="12px"></td>
                            <td>{{ excelTerm }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">{{ $t('common.vin') }}</td>
                            <td width="12px"></td>
                            <td>{{ excelVin }}</td>
                          </tr>
                        </table>
                      </div>
                      <xlsx-workbook class="col-auto row items-center justify-center">
                        <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                        <xlsx-download :filename="excelFilename">
                          <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveUsageToExcel">{{ $t('common.download') }}</button>
                        </xlsx-download>
                      </xlsx-workbook>
                    </div>
                    <div class="col row items-center justify-center" v-show="!hasExcelUsage" style="width:100%;height:100%;">
                      <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:bold;color:#eb0028">{{ $t('warning.nodata')}}</div>
                    </div>
                  </div>
                </div>
                <div class="row items-center justify-center" v-show="makingExcel"
                    style="position:absolute;z-index:3;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                  <q-spinner color="red-7" size="70px" thickness="1.5"/>
                </div>
              </q-popup-proxy>
            </q-btn>
          </div>
        </div>
      </div>
      <div :class="isLandscape ? 'row' : 'column'" :style="isLandscape ? 'height:210px' : 'height:360px'" style="margin:0 6px 6px 6px;">
        <div :class="isLandscape ? 'col' : 'col'" class="row items-center justify-center"
             :style="isLandscape ? 'height:210px' : 'height:360px'" style="border:1px solid #dddddd;border-radius:3px;padding:6px;">
          <div id="engineBarContainer" class="col" style="width:100%;height:100%;">
            <!-- 사용시간 막대그래프 -->
            <q-scroll-area style="width:100%;height:100%;">
              <div id="engineBarChart" refs="refsEngineBar"></div>
            </q-scroll-area>
            <q-resize-observer @resize="onResizeEngBar"></q-resize-observer>
          </div>
        </div>

        <div :class="isLandscape ? 'col-4' : 'col'" class="col row" v-if="false">
          <div class="col row items-center justify-between"
               style="position:relative;border:1px solid #dddddd;border-radius:3px;width:100%;height:100%;"
               :style="isLandscape ? 'margin:0 0 0 12px;' : 'margin:12px 0 0 0;'">
            <!-- 사용시간 도넛 차트 -->
            <div id="engineDonutContainer" style="width:100%;height:100%;">
              <div id="engineDonutChart"></div>
              <q-resize-observer @resize="onResizeEngDonut"></q-resize-observer>
            </div>
            <div class="col-auto row items-end justify-start" style="position:absolute;left:12px;top:0;right:0;bottom:0;">
              <div style="font-size:15px;">{{ enginePieTitle }}</div>
            </div>
          </div>
        </div>

      </div>
      <!-- 연간 사용시간 로딩 중 -->
      <div class="row items-center justify-center"
          v-show="loadingEngineHour"
          style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
          <q-spinner-tail dense color="red-7" size="40px"/>
      </div>
    </div>

    <!-- 20240129 소모품을 감추면서 아랫쪽 마진을 6px에서 0으로 변경합-->
    <div class="col column" style="position:relative;background-color:white;border-radius:3px;padding:6px;margin:6px 0 0px 0;">
      <!-- 연료소모량 블록 -->
      <div class="col-auto row items-center" style="margin:6px;">
        <div class="col-auto" style="font-size:15px;font-weight:500;margin:0 6px">{{ $t('manage.fuelConsumption') }} (L)</div>
        <div class="col row justify-between items-center" >
          <div class="col-auto row">
            <div class="col-auto row items-center justify-between" style="cursor:grab;font-size:15px;border:1px solid #bbbbbb;margin:3px 6px;padding:3px 6px;width:120px;"> {{ fuelDate }}
              <q-popup-proxy ref="fuelDayPicker" cover transition-show="scale" transition-hide="scale">
                <div class="column" style="margin:12px 6px;">
                  <q-date class="col" flat minimal 
                          emit-immediately
                          color="red-7" 
                          default-view="Months"
                          :years-in-month-view="true"
                          :title="consumableTitle" subtitle="Replacement"
                          v-model="proxyDateFuel"
                          @update:model-value="fuelChangeDate"
                          >
                  </q-date>
                </div>
              </q-popup-proxy>
              <q-icon size="sm" color="grey-8" name="calendar_month"></q-icon>
            </div>
            <div class="col-auto row" style="border:1px solid #bbbbbb;margin:3px 6px 3px 3px">
              <q-btn flat dense size="16px" color="grey-10" icon="search" @click="searchFuelEff" :disable="fuelDate == ''">
              </q-btn>
            </div>
          </div>
          <div class="col-auto row justify-end" v-show="canDownloadToExcel">
            <q-btn class="col-auto" flat dense>
              <q-img src="~../assets/manage/management_excel_icon.svg" fit="fill" width="26px" height="32px"/>
              <q-tooltip style="font-size:14px">{{ $t('tooltip.exportExcel') }}</q-tooltip>
              <q-popup-proxy ref="excelPopupFuel" style="width:360px;height:200px;background-color: #01010130;border-radius: 3px;position: relative;"
                              @before-show="onShowExcelFuel">
                <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                  <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                    <div class="col-auto row items-center justify-center">
                      <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                        {{ $t('common.toExcel') }}
                      </div>
                    </div>
                    <div class="col column" v-show="hasExcelUsage">
                      <div class="col row items-start" style="width:100%;">
                        <table class="col" style="width:100%;margin:0;">
                          <tr style="">
                            <td style="text-align: right;">{{ $t('common.filename') }}</td>
                            <td width="12px" style=""></td>
                            <td style="">{{ excelFilename }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">{{ $t('common.date') }}</td>
                            <td width="12px"></td>
                            <td>{{ excelTerm }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">{{ $t('common.vin') }}</td>
                            <td width="12px"></td>
                            <td>{{ excelVin }}</td>
                          </tr>
                        </table>
                      </div>
                      <xlsx-workbook class="col-auto row items-center justify-center">
                        <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                        <xlsx-download :filename="excelFilename">
                          <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveFuelToExcel">{{ $t('common.download') }}</button>
                        </xlsx-download>
                      </xlsx-workbook>
                    </div>
                    <div class="col row items-center justify-center" v-show="!hasExcelUsage" style="width:100%;height:100%;">
                      <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:bold;color:#eb0028">{{ $t('warning.nodata')}}</div>
                    </div>
                  </div>
                </div>
                <div class="row items-center justify-center" v-show="makingExcel"
                    style="position:absolute;z-index:3;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                  <q-spinner color="red-7" size="70px" thickness="1.5"/>
                </div>
              </q-popup-proxy>
            </q-btn>
          </div>
        </div>
      </div>
      <div class="col-auto row" style="height:auto;margin:0 6px 6px 6px;">
        <div class="col row items-center justify-center" style="border:1px solid #dddddd;border-radius:3px;padding:6px;">
          <div id="fuelBarContainer" class="col row" style="height:210px">
            <!-- 소모량 & 연비 막대그리프-->
            <q-scroll-area style="width:100%;height:100%">
              <div id="fuelBarChart" class="col-auto" style=""></div>
            </q-scroll-area>
            <q-resize-observer @resize="onResizeFuelBar"></q-resize-observer>
          </div>
        </div>

        <!-- 연료소모량과 연비를 하나에 표시하는 건 불합리하다는 생각 (20230609) -->
        <div class="col column" style="margin-left:12px;" v-show="false">
          <div class="col column items-center justify-between" style="position:relative;border:1px solid #dddddd;border-radius:3px;padding:6px;">
            <div id="fuelDonutChart" class="col-auto" style=""></div>
            <!--
            <q-resize-observer @resize="onResizeEngDonut"></q-resize-observer>
            -->
            <div class="row items-end justify-start" style="position:absolute;left:12px;top:6px;right:6px;bottom:6px">
              <div style="font-size:15px;">{{ fuelPieTitle }}</div>
            </div>
          </div>
        </div>
      </div>


      <div class="col column" style="position:relative;background-color:white;border-radius:3px;padding:0;margin:6px 0 0 0;">
        <!-- 연비 블록 -->
        <div class="col-auto row items-center" style="margin:6px;">
          <div class="col-auto" style="font-size:15px;font-weight:500;margin:0 6px">{{ $t('manage.fuelMileage') }} (L/h)</div>
          <!--
          <div class="col row justify-between items-center" >
            <div class="col-auto row">
              <div class="col-auto row items-center justify-between" style="cursor:grab;font-size:15px;border:1px solid #bbbbbb;margin:3px 6px;padding:3px 6px;width:120px;"> {{ mileageDate }}
                <q-popup-proxy ref="mileageDayPicker" cover transition-show="scale" transition-hide="scale">
                  <div class="column" style="margin:12px 6px;">
                    <q-date class="col" flat minimal 
                            emit-immediately
                            color="red-7" 
                            default-view="Months"
                            :years-in-month-view="true"
                            :title="consumableTitle" subtitle="Replacement"
                            v-model="proxyDateMileage"
                            @update:model-value="mileageChangeDate"
                            >
                    </q-date>
                  </div>
                </q-popup-proxy>
                <q-icon size="sm" color="grey-8" name="calendar_month"></q-icon>
              </div>
              <div class="col-auto row" style="border:1px solid #bbbbbb;margin:3px 6px 3px 3px">
                <q-btn flat dense size="16px" color="grey-10" icon="search" @click="searchFuelMileage" :disable="mileageDate == ''">
                </q-btn>
              </div>
            </div>
            <div class="col-auto row justify-end">
              <q-btn class="col-auto" flat dense>
                <q-img src="~../assets/manage/management_excel_icon.svg" fit="fill" width="26px" height="32px"/>
                <q-tooltip style="font-size:14px">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                <q-popup-proxy ref="excelPopupMileage" style="width:360px;height:200px;background-color: #01010130;border-radius: 3px;position: relative;"
                                @before-show="onShowExcelMileage">
                  <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                    <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                      <div class="col-auto row items-center justify-center">
                        <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                          {{ $t('common.toExcel') }}
                        </div>
                      </div>
                      <div class="col column" v-show="hasExcelUsage">
                        <div class="col row items-start" style="width:100%;">
                          <table class="col" style="width:100%;margin:0;">
                            <tr style="">
                              <td style="text-align: right;">{{ $t('common.filename') }}</td>
                              <td width="12px" style=""></td>
                              <td style="">{{ excelFilename }}</td>
                            </tr>
                            <tr>
                              <td style="text-align: right;">{{ $t('common.date') }}</td>
                              <td width="12px"></td>
                              <td>{{ excelTerm }}</td>
                            </tr>
                            <tr>
                              <td style="text-align: right;">{{ $t('common.vin') }}</td>
                              <td width="12px"></td>
                              <td>{{ excelVin }}</td>
                            </tr>
                          </table>
                        </div>
                        <xlsx-workbook class="col-auto row items-center justify-center">
                          <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                          <xlsx-download :filename="excelFilename">
                            <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveFuelToExcel">{{ $t('common.download') }}</button>
                          </xlsx-download>
                        </xlsx-workbook>
                      </div>
                      <div class="col row items-center justify-center" v-show="!hasExcelUsage" style="width:100%;height:100%;">
                        <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:bold;color:#eb0028">{{ $t('warning.nodata')}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row items-center justify-center" v-show="makingExcel"
                      style="position:absolute;z-index:3;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                    <q-spinner color="red-7" size="70px" thickness="1.5"/>
                  </div>
                </q-popup-proxy>
              </q-btn>
            </div>
          </div>
          -->
        </div>
        <div class="col-auto row" style="height:auto;margin:0 6px 6px 6px;">
          <div class="col row items-center justify-center" style="border:1px solid #dddddd;border-radius:3px;padding:6px;">
            <div id="mileageBarContainer" class="col row" style="height:210px">
              <!-- 연비 막대그리프-->
              <q-scroll-area style="width:100%;height:100%">
                <div id="mileageBarChart" class="col-auto" style=""></div>
              </q-scroll-area>
              <q-resize-observer @resize="onResizeMileageBar"></q-resize-observer>
            </div>
          </div>
        </div>
      </div>

      <!-- 연료소모량 로딩 중 -->
      <div class="row items-center justify-center"
           v-show="loadingFuelEff"
           style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
        <q-spinner-tail dense color="red-7" size="40px"/>
      </div>

    </div>

    <div class="col column" style="position:relative;background-color:white;border-radius:3px;padding:6px;margin:12px 0 3px 0;" v-show="false">
      <!-- 배터리 -->
      <div class="col-auto row items-center" style="margin:6px;">
        <div class="col-auto" style="font-size:15px;font-weight:500;margin:0 6px">{{ $t('manage.batteryVoltage') }}&nbsp;(V)</div>
        <div class="col row justify-between items-center" >
          <div class="col-auto row">
            <div class="col-auto row items-center justify-between" style="cursor:grab;font-size:15px;border:1px solid #bbbbbb;margin:3px 6px;padding:3px 6px;width:120px;"> {{ batteryDate }}
              <q-popup-proxy ref="batteryDayPicker" cover transition-show="scale" transition-hide="scale">
                <div class="column" style="margin:12px 6px;">
                  <q-date class="col" flat minimal 
                          emit-immediately
                          color="red-7" 
                          default-view="Months"
                          :years-in-month-view="true"
                          :title="consumableTitle" subtitle="Replacement"
                          v-model="proxyDateBatt"
                          @update:model-value="batteryChangeDate"
                          >
                  </q-date>
                </div>
              </q-popup-proxy>
              <q-icon size="sm" color="grey-8" name="calendar_month"></q-icon>
            </div>
            <div class="col-auto row" style="border:1px solid #bbbbbb;margin:3px">
              <q-btn flat dense size="16px" color="grey-10" icon="search" @click="searchBattery" :disable="batteryDate == ''">
              </q-btn>
            </div>
            <div class="col-auto row justify-center items-center">
              <q-icon size="sm" color="grey-6" name="info" style="margin:0 12px;">
                <q-tooltip>
                  <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:14px;">
                    {{ $t('manage.batteryToolitp')}}
                  </div>
                </q-tooltip>
              </q-icon>
            </div>
          </div>
          <div class="col-auto row justify-end" v-show="canDownloadToExcel">
            <q-btn class="col-auto" flat dense>
              <q-img src="~../assets/manage/management_excel_icon.svg" fit="fill" width="26px" height="32px"/>
              <q-tooltip style="font-size:14px">{{ $t('tooltip.exportExcel') }}</q-tooltip>
              <q-popup-proxy ref="excelPopupBattery" style="width:360px;height:200px;background-color: #01010130;border-radius: 3px;position: relative;"
                              @before-show="onShowExcelBattery">
                <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                  <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                    <div class="col-auto row items-center justify-center">
                      <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                        {{ $t('common.toExcel') }}
                      </div>
                    </div>
                    <div class="col column" v-show="hasExcelUsage">
                      <div class="col row items-start" style="width:100%;">
                        <table class="col" style="width:100%;margin:0;">
                          <tr style="">
                            <td style="text-align: right;">{{ $t('common.filename') }}</td>
                            <td width="12px" style=""></td>
                            <td style="">{{ excelFilename }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">{{ $t('common.date') }}</td>
                            <td width="12px"></td>
                            <td>{{ excelTerm }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">{{ $t('common.vin') }}</td>
                            <td width="12px"></td>
                            <td>{{ excelVin }}</td>
                          </tr>
                        </table>
                      </div>
                      <xlsx-workbook class="col-auto row items-center justify-center">
                        <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                        <xlsx-download :filename="excelFilename">
                          <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveBatteryToExcel">{{ $t('common.download') }}</button>
                        </xlsx-download>
                      </xlsx-workbook>
                    </div>
                    <div class="col row items-center justify-center" v-show="!hasExcelUsage" style="width:100%;height:100%;">
                      <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:bold;color:#eb0028">{{ $t('warning.nodata')}}</div>
                    </div>
                  </div>
                </div>
                <div class="row items-center justify-center" v-show="makingExcel"
                    style="position:absolute;z-index:3;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                  <q-spinner color="red-7" size="70px" thickness="1.5"/>
                </div>
              </q-popup-proxy>
            </q-btn>
          </div>
        </div>
      </div>
      <div class="col-auto row" style="height:auto;margin:0 6px 6px 6px;">
        <div class="col row items-center justify-center" style="border:1px solid #dddddd;border-radius:3px;padding:6px;">
          <div id="batteryBarContainer" class="col row" style="height:210px">
            <q-scroll-area style="width:100%;height:100%;">
              <div id="batteryBarChart" class="col-auto" style=""></div>
              <q-resize-observer @resize="onResizeBattery"></q-resize-observer>
            </q-scroll-area>
          </div>
        </div>
      </div>

      <!-- 배터리 잔량 로딩 중 -->
      <div class="row items-center justify-center"
           v-show="loadingBattery"
           style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
        <q-spinner-tail dense color="red-7" size="40px"/>
      </div>
    </div>
      
  </div>
  </div>
</template>

<style scoped>

.mainBorder {
  background-color:lightgray;
  height:auto;
  margin:12px;
}

.boxBorder {
  border-radius:3px;
  min-height:300px;
  margin:6px;
}

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'
import { useI18n } from 'vue-i18n'
//import { useQuasar } from 'quasar'
import moment from 'moment-timezone'
import TymCommon from '@/js/tymcommon.js'
import TymAws from '@/js/tymaws.js'
import TymConst from '@/js/tymconstants.js'
import { XlsxWorkbook, XlsxSheet, XlsxDownload  } from 'vue3-xlsx';

export default ({
  props : {
    info : Object
  },
  components : {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data() {
  },
  setup() {
    const i18n = useI18n()
    return {
      machineInfo : ref(null),
      engineDate : ref(''),
      proxyDateEngine: ref(''),
      proxyDateFuel: ref(''),
      proxyDateBatt: ref(''),

      loadingEngineHour : ref(false),
      engineBarData : ref([
        {
          x: [],
          y: [],
          type: 'bar',
          marker: {
            color: '#eb0028',
            size: 10
          }
        }
      ]),
      engineBarLayout : ref({
        // title: 'Number of Graphs Made this Week',
        showlegend: false,
        dragmode : "orbit",
        xaxis: {
          tickangle: 0
        },
        yaxis: {
          zeroline: true,
          gridwidth: 2
        },
        mode: 'text',
        bargap : 0.25,
        width: 420,
        height: 120, 
        margin: {
          l : 25,
          t : 5,
          r : 5,
          b : 25
        },
      }),
      barChartConfig : ref({
        autosizable : true,
        displayModeBar: false,
        displaylogo: false,
        responsive: true,
        scrollZoom: false,
      }),

      enginePieTitle : ref(''),
      engineDonutData : ref([{
        values: [0, 0, 0],
        labels: [
          i18n.t('manage.move'),
          i18n.t('manage.pto'),
          i18n.t('manage.plow')
        ],
        name: 'Engine Hours',
        hoverinfo: 'label+value+name',
        hole: .5,
        type: 'pie'
      }]),
      engineDonutLayout : ref({
        annotations: [{
          font: {
            size: 14,
          },
          showarrow: false,
          text: i18n.t('common.engine'),
          /*
          x: 0.17,
          y: 0.5
          */
        }],
        width: 160,
        height: 120, 
        margin: {
          l : 40,
          t : 30,
          r : 5,
          b : 30
        },
        showlegend: true,
      }),

      fuelDate : ref(''),
      loadingFuelEff : ref(false),
      fuelBarData : ref([
        {
          x: [],
          y: [],
          type: 'bar',
          marker: {
            color: '#28eb00',
            size: 10,
          },
          name : i18n.t('manage.consumption')
        },
        {
          x: [],
          y: [],
          type: 'bar',
          marker: {
            color: '#2800EB',
            size: 10
          },
          name : i18n.t('manage.fuelMileage')
        }
      ]),
      fuelBarLayout : ref({
        // title: 'Number of Graphs Made this Week',
        showlegend: false,
        dragmode : "orbit",
        xaxis: {
          tickangle: 0
        },
        yaxis: {
          zeroline: true,
          gridwidth: 2
        },
        barmode : "group",
        bargap : 0.25,
        width: 420,
        height: 120, 
        margin: {
          l : 25,
          t : 5,
          r : 5,
          b : 25
        },
      }),
      fuelDonutData : ref([{
        values: [0, 0],
        labels: ['소모량', '연비'],
        name: 'FUEL',
        hoverinfo: 'label+value+name',
        hole: .5,
        type: 'pie'
      }]),
      fuelDonutLayout : ref({
        annotations: [{
          font: {
            size: 14,
          },
          showarrow: false,
          text: 'FUEL',
          /*
          x: 0.17,
          y: 0.5
          */
        }],
        width: 160,
        height: 120, 
        margin: {
          l : 5,
          t : 30,
          r : 5,
          b : 30
        },
        showlegend: true,
      }),
      fuelPieTitle : ref(''),

      mileageBarData : ref([
      {
          x: [],
          y: [],
          type: 'bar',
          marker: {
            color: '#2800EB',
            size: 10,
          },
          name : i18n.t('manage.fuelMileage')
        },
        {
          x: [],
          y: [],
          type: 'bar',
          marker: {
            color: '#2800EB',
            size: 10
          },
          name : i18n.t('manage.fuelMileage')
        }
      ]),
      mileageBarLayout : ref({
        // title: 'Number of Graphs Made this Week',
        showlegend: false,
        dragmode : "orbit",
        xaxis: {
          tickangle: 0
        },
        yaxis: {
          zeroline: true,
          gridwidth: 2
        },
        barmode : "group",
        bargap : 0.25,
        width: 420,
        height: 120, 
        margin: {
          l : 25,
          t : 5,
          r : 5,
          b : 25
        },
      }),

      batteryDate : ref(''),
      loadingBattery : ref(false),
      batteryBarData : ref([
        {
          x: [],
          y: [],
          mode:'lines+markers',
          type: 'scatter',
          marker: {
            color: '#eb0028',
            size: 10,
          },
          name : i18n.t('manage.batteryVoltage') + '(V)',
        },
        {
          x: [],
          y: [11.5, 11.5],
          mode:'lines',
          type: 'line',
          marker: {
            color: '#2800EB',
            size: 1,
          },
          line: {
            dash: 'dot',
            width: 2
          },
          name : i18n.t('manage.batteryWarningLevel'),
          connectgaps: false
        }

      ]),
      batteryBarLayout : ref({
        // title: 'Number of Graphs Made this Week',
        showlegend: true,
        dragmode : "orbit",
        xaxis: {
          zeroline: true,
          tickangle: 0
        },
        yaxis: {
          range : [0, 20],
          zeroline: true,
          gridwidth: 2
        },
        barmode : "group",
        bargap : 0.25,
        width: 420,
        height: 120, 
        margin: {
          l : 25,
          t : 5,
          r : 5,
          b : 25
        },
      }),

      sheets: ref([]),

      hasExcelUsage : ref(true),
      hasExcelFuel : ref(true),
      hasExcelBattery : ref(true),
    }
  },
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return store.canDownloadToExcel()
    },
  },
  created() {},
  mounted() {
    this.updateData(this.info)

    let now = new Date()
    this.engineDate = this.fuelDate = this.batteryDate = now.getFullYear().toString()

    /*
    const store = useTymictStore()
    let curTZ = "Asia/Seoul"
    let offset = '+00:00'
    if(store.connectServer == 0) {
      //
    } else {
      if(store.timezoneIndex != 0) {
        curTZ = store.timezoneList[store.timezoneIndex].value
        offset = store.timezoneList[store.timezoneIndex].gmt.replace('GMT', '')
      } else {
        curTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
        let srcOffset = moment.tz('2024-01-01 00:00', curTZ).utcOffset()
        let hour = Math.floor(Math.abs(srcOffset) / 60)
        let min = (Math.abs(srcOffset) % 60)
        offset = (srcOffset < 0 ? '-' : '+') + hour.toString().padStart(2, '0') + ":" + min.toString().padStart(2, '0')
      }
    }
    console.log('-----------------------')
    console.log(store.timezoneList[store.timezoneIndex], curTZ, srcOffset, offset)
    console.log(offset)
    */
    /*
    console.log('-----------------------')
    console.log(this.info.SD)  
    console.log(moment.tz(this.info.SD, 'Asia/Seoul').toDate())//format("YYYY-MM-DD HH:mm:ss"))
    console.log(moment.utc(this.info.SD).tz('Asia/Seoul').toDate())//.format("YYYY-MM-DD HH:mm:ss"))
    */

    this.updateEngineHours()
    this.updateEngineDonut()

    this.updateFuelEff()
    this.updateFuelEffDonut()

    this.updateBattery()
  }, 
  unmounted() {},
  methods : {
    unusedParam() {
      //
    },
    getMonthTitle(month) {
      return this.$t('yearMonth.' + month)
    },
    updateData(obj) {
      this.machineInfo = obj
    },
    engineChangeDate(val, reason, details) {
      this.unusedParam(val)

      // console.log("engineChangeDate :", val, reason, details)
      switch(reason.toUpperCase()) {
        case "YEAR":
          this.engineDate = details.year.toString()
          break;
        case "MONTH":
          {
            // month는 0부터 시작이더라
            let now = new Date(details.year, details.month - 1, details.day)
            this.engineDate = TymCommon.getMonthString(now)
            //this.updateEngineHours('MONTH')
          }
          break;
      }
      this.$refs.engineDayPicker.hide()
    },
    fuelChangeDate(val, reason, details) {
      this.unusedParam(val)

      switch(reason.toUpperCase()) {
        case "YEAR":
          this.fuelDate = details.year.toString()
          break;
        case "MONTH":
          {
            // month는 0부터 시작이더라
            let now = new Date(details.year, details.month - 1, details.day)
            this.fuelDate = TymCommon.getMonthString(now)
            //this.updateEngineHours('MONTH')
          }
          break;
      }
      this.$refs.fuelDayPicker.hide()
    },
    batteryChangeDate(val, reason, details) {
      this.unusedParam(val)

      switch(reason.toUpperCase()) {
        case "YEAR":
          this.batteryDate = details.year.toString()
          break;
        case "MONTH":
          {
            // month는 0부터 시작이더라
            let now = new Date(details.year, details.month - 1, details.day)
            this.batteryDate = TymCommon.getMonthString(now)
            //this.updateEngineHours('MONTH')
          }
          break;
      }
      this.$refs.batteryDayPicker.hide()
    },
    changeToYear(option) {
      if(option == 'usage') {
        let date = new Date(this.engineDate)
        console.log('changeToYear :', this.engineDate, date)
        date = moment(date).toDate()
        this.engineDate = date.getFullYear().toString()
        this.searchEngineHour('YEAR')
      } else if(option == 'fuel') {
        let date = new Date(this.fuelDate)
        this.fuelDate = date.getFullYear().toString()
        this.searchFuelEff('YEAR')
      }
    },
    searchEngineHour() {
      if(TymCommon.isEmpty(this.engineDate))
        return

      let option = 'YEAR'
      let newDate = null
      if(this.engineDate.length >= 5) {
        option = 'MONTH'
        newDate = moment(this.engineDate, ["YYYY. M.", "M-YYYY"])
      } else {
        newDate = moment(this.engineDate, "YYYY")
      }
      console.log(option, this.engineDate, newDate.year(), newDate.month())

      this.updateEngineHours(option, newDate.year(), newDate.month() + 1)
    },
    searchFuelEff() {
      if(TymCommon.isEmpty(this.fuelDate))
        return

      let option = 'MONTH'
      if(this.fuelDate.length < 5) {
        option = 'YEAR'
      }

      this.updateFuelEff(option)
    },
    searchBattery() {
      if(TymCommon.isEmpty(this.batteryDate))
        return

      let option = 'MONTH'
      if(this.batteryDate.length < 5) {
        option = 'YEAR'
      }

      this.updateBattery(option)
    },
    onResizeEngBar(size) {
      this.unusedParam(size)

      setTimeout(()=>{
        let container = document.getElementById('engineBarContainer')
        if(!container) {
          return
        }
        let chart = document.getElementById('engineBarChart')
        if(!chart) {
          return
        }
        let width = 0, height = 0
        if(this.engineBarData[0].x.length < 1) {
          width = container.clientWidth 
          height = container.clientHeight - 18
        } else {
          let barCount = this.engineBarData[0].x.length
          if(this.engineBarData[0].x.length == 12)
            width = barCount * 90
          else 
            width = barCount * 80
          height = container.clientHeight - 18
        }

        if(width < 240)
          width = 240
        if(height < 32)
          height = 32
        if(width < container.clientWidth )
          width = container.clientWidth

        // console.log('Eng Size : ', this.engineBarData, width, height)
        window.Plotly.relayout('engineBarChart', {
          bargap : 0.15,
          width: width,
          height: height,
          margin: {
            l : 40,
            t : 10,
            r : 5,
            b : 20
          }
        })
      }, 25)
    },
    onResizeEngDonut(size) {
      this.unusedParam(size)
      /*
      setTimeout(()=>{
        let container = document.getElementById('engineDonutContainer')
        if(!container) {
          return
        }
        let chart = document.getElementById('engineDonutChart')
        if(!chart) {
          return
        }
        if(this.engineBarData.length < 1) {
          return
        }

        this.engineDonutLayout.width = container.clientWidth - 24
        this.engineDonutLayout.height = container.clientHeight - 24
        if(this.engineDonutLayout.height < 36)
          this.engineDonutLayout.height = 36
        this.engineDonutLayout.margin = {
          l : 40,
          t : 40,
          r : 40,
          b : 10
        }
        window.Plotly.update('engineDonutChart', this.engineDonutData, this.engineDonutLayout, this.barChartConfig)
      }, 25)
      */
    },
    onResizeFuelBar(size) {
      this.unusedParam(size)

      setTimeout(()=>{
        let container = document.getElementById('fuelBarContainer')
        if(!container) {
          return
        }
        let chart = document.getElementById('fuelBarChart')
        if(!chart) {
          return
        }

        let width = 0, height = 0
        if(this.fuelBarData[0].x.length < 1) {
          width = container.clientWidth 
          height = container.clientHeight - 18
        } else {
          let barCount = this.fuelBarData[0].x.length
          if(this.fuelBarData[0].x.length == 12)
            width = barCount * 90
          else 
            width = barCount * 80
          height = container.clientHeight - 18
        }

        if(width < 240)
          width = 240
        if(height < 32)
          height = 32
        if(width < container.clientWidth )
          width = container.clientWidth

        window.Plotly.relayout('fuelBarChart', {
          bargap : 0.15,
          width: width,
          height: height,
          margin: {
            l : 40,
            t : 10,
            r : 5,
            b : 16
          }
        })
      }, 25)
    },
    onResizeMileageBar(size) {
      this.unusedParam(size)

      setTimeout(()=>{
        let container = document.getElementById('mileageBarContainer')
        if(!container) {
          return
        }
        let chart = document.getElementById('mileageBarChart')
        if(!chart) {
          return
        }
        let width = 0, height = 0
        if(this.mileageBarData[0].x.length < 1) {
          width = container.clientWidth 
          height = container.clientHeight - 18
        } else {
          let barCount = this.mileageBarData[0].x.length
          if(this.mileageBarData[0].x.length == 12)
            width = barCount * 90
          else 
            width = barCount * 80
          height = container.clientHeight - 18
        }

        if(width < 240)
          width = 240
        if(height < 32)
          height = 32
        if(width < container.clientWidth )
          width = container.clientWidth

        window.Plotly.relayout('mileageBarChart', {
          bargap : 0.15,
          width: width,
          height: height,
          margin: {
            l : 40,
            t : 10,
            r : 5,
            b : 16
          }
        })
      }, 25)
    },
    onResizeBattery(size) {
      this.unusedParam(size)
      setTimeout(()=>{
        let container = document.getElementById('batteryBarContainer')
        if(!container) {
          return
        }
        let chart = document.getElementById('batteryBarChart')
        if(!chart) {
          return
        }
        if(this.batteryBarData.length < 1) {
          return
        }

        this.batteryBarLayout.width = container.clientWidth - 24
        this.batteryBarLayout.height = container.clientHeight - 24
        if(this.batteryBarLayout.height < 36)
          this.batteryBarLayout.height = 36
        this.batteryBarLayout.margin = {
          l : 40,
          t : 20,
          r : 40,
          b : 20
        }
        window.Plotly.update('batteryBarChart', this.batteryBarData, this.batteryBarLayout, this.barChartConfig)
      }, 25)
    },
    updateEngineHours(option, year, month) {
      if(TymCommon.isEmpty(this.machineInfo))
        return

      let div = document.getElementById('engineBarChart')
      if(!div)
        return

      if(TymCommon.isEmpty(option)) {
        // 최초
        new window.Plotly.newPlot(div, this.engineBarData, this.engineBarLayout, this.barChartConfig)

        const clickOnBarChar = (evt) => {
          // console.log(evt, this.engineDate)

          if(evt.points[0].value > 0) {
            if(this.engineDate.length < 5) {
              // 1년치의 한달 그래프에서 클릭함
              if(evt.points[0].value > 0){
                let monStr = this.engineDate + (evt.points[0].pointIndex + 1).toString().padStart(2, '0')

                let date = moment(monStr, "YYYYMM")
                let newMonth = new Date(date.year(), evt.points[0].pointIndex, date.date())
                this.engineDate = TymCommon.getMonthString(newMonth)
                setTimeout(()=>{
                  // console.log('CLICK on Yearly Month :', date.year(), evt.points[0].pointIndex + 1)
                  this.updateEngineHours('MONTH', date.year(), evt.points[0].pointIndex + 1)
                }, 25)
              }
            } else {
              //
            }
          }
        }
        div.on('plotly_click', clickOnBarChar)
      } else {
        let baseYear = 0
        if(TymCommon.isEmpty(year)) {
          if(this.engineDate.length > 5) {
            let date = moment(this.engineDate, ["YYYY. M.", "M-YYYY"])
            baseYear = date.year()
          } else {
            baseYear = Number(this.engineDate)
          }
        } else {
          baseYear = year
        }

        if(option == 'YEAR') {
          let startDay = baseYear.toString() + "-01-01 00:00:00"
          let lastDay = (baseYear+1).toString() + "-01-01 00:00:00"
          let tgtRange = TymCommon.makeDateTimeRange(this.machineInfo.SD, startDay,  lastDay, 'YYYY-MM-DD HH:mm:ss')
          console.log('YEAR', startDay, lastDay, tgtRange)
          if(TymCommon.isEmpty(tgtRange)) {
            // 판매일 이전 데이터는 없어
            TymCommon.Toast("판매전 데이터는 조회할 수 없습니다.")

            this.engineBarData[0].x = []
            this.engineBarData[0].y = []
            window.Plotly.update('engineBarChart', this.engineBarData, this.engineBarLayout)
            return
          }

          if(TymConst.IS_DEVELOPMENT) {
            console.log('updateEngineHours 2:', option, startDay, lastDay, tgtRange.sDateStr, tgtRange.eDateStr)
          }

          this.loadingEngineHour = true
          TymAws.getYearlyInfobyMNorSN(this.machineInfo.machineNo, this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr)
            .then(data => {
              if(TymConst.IS_DEVELOPMENT) {
                console.log('getYearlyInfobySN UsageTime :', tgtRange.sDateStr, tgtRange.eDateStr, data)
              }
              let yearlyData = data.body.UsageTime

              this.engineBarData[0].x = new Array(12)
              this.engineBarData[0].y = new Array(12)
              this.engineBarData[0].text = new Array(12)
              this.engineBarData[0].hovertext = new Array(12)
              this.engineBarData[0].hoverinfo = "text"

              for(let i = 0; i < yearlyData.length; i++) {
                let hour = Math.trunc(yearlyData[i] / 60)
                let minute = (yearlyData[i] % 60)
                let useTime = hour + ':' + minute.toString().padStart(2, '0')

                this.engineBarData[0].x[i] = this.getMonthTitle(i + 1)
                this.engineBarData[0].y[i] = hour + (minute / 100)//yearlyData[i]
                this.engineBarData[0].text[i] = useTime
                this.engineBarData[0].hovermode = "closest"
                this.engineBarData[0].hovertemplate = '<span style="color:#404040;">%{x}</span> <span style="color:#eb0028;font-weight:bold;">%{text}</span> <extra></extra>'
                this.engineBarData[0].hoverlabel = {
                  bgcolor: 'white',
                  bordercolor: '#eb0028',
                }
              }

              window.Plotly.update('engineBarChart', this.engineBarData, this.engineBarLayout)
              this.onResizeEngBar(null)
              this.loadingEngineHour = false;
            })
            .catch(er => {
              console.log('getYearly Error :', er)
              this.loadingEngineHour = false;
            })
        } else {
          // MONTH
          console.log(this.machineInfo.SD, option, year, month)
          let saleDate = moment(this.machineInfo.SD).toDate()
          let startDay = year.toString() + '-' + (month).toString().padStart(2, '0') + '-' + '01 00:00:00'
          let lastDay = year.toString() + '-' + (month + 1).toString().padStart(2, '0') + '-' + '01 00:00:00'
          
          console.log(saleDate, startDay, lastDay)

          if(lastDay < saleDate) {
            window.Plotly.update('engineBarChart', this.engineBarData, this.engineBarLayout)

            this.onResizeEngBar(null)
            return 
          }

          let tgtRange = TymCommon.makeDateTimeRange(this.machineInfo.SD, startDay, lastDay, 'YYYY-MM-DD HH:mm:ss')

          this.loadingEngineHour = true
          TymAws.getDailyInfobySN(this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr)
            .then(data => {
              // let jsonItems = JSON.parse(data.body)
              let info = data // jsonItems.Items
              let idx = 0

              if(!TymCommon.isEmpty(info)) {
                if(TymConst.IS_DEVELOPMENT) {
                  console.log('MachineUsage.getDailyInfobySN :', this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr, info)
                }

                // 일자별 사용시간 : 이동(MT)/작업(PTOT)/견인(DRT) 시간의 합으로
                let last = moment(lastDay)
                last.subtract(1, 'days')
                let dayCnt = last.date()
                this.engineBarData[0].x = new Array(dayCnt)
                this.engineBarData[0].y = new Array(dayCnt)
                this.engineBarData[0].text = new Array(dayCnt)
                this.engineBarData[0].hovertext = new Array(dayCnt)
                for(let i = 1; i <= dayCnt; i++) {
                  this.engineBarData[0].x[i - 1] = i.toString()
                  if(idx < info.length) {
                    let day = Number(info[idx].DAY.substring(6))
                    if(day == i) {
                      this.engineBarData[0].y[i - 1] = Number(info[idx].MT) + Number(info[idx].PTOT) + Number(info[idx].DRT)// Number(info[idx].VUDT)
                      idx++
                    } else {
                      this.engineBarData[0].y[i - 1] = 0
                    }
                  } else {
                    this.engineBarData[0].y[i - 1] = 0
                  }
                  let hour = Math.trunc(this.engineBarData[0].y[i - 1] / 60)
                  let minute = this.engineBarData[0].y[i - 1] % 60
                  let msg = ''
                  if(this.engineBarData[0].y[i - 1] > 60) {
                    msg = hour + ':' + minute.toString().padStart(2, '0')
                  } else {
                    msg = this.engineBarData[0].y[i - 1].toString() + this.$t('common.minute')
                  }
                  this.engineBarData[0].y[i - 1] = hour + (minute / 100)
                  this.engineBarData[0].text[i - 1] = msg
                  this.engineBarData[0].hovertext[i - 1] = this.engineBarData[0].y[i - 1].toString() + this.$t('common.minute')
                  this.engineBarData[0].hovertemplate = '<span style="color:#404040;">%{x}</span> <span style="color:#eb0028;font-weight:500;">%{text}</span> <extra></extra>'
                  this.engineBarData[0].hoverlabel = {
                    bgcolor: 'white',
                    bordercolor: '#eb0028',
                  }
                }
                window.Plotly.update('engineBarChart', this.engineBarData, this.engineBarLayout)
              }

              /*
              this.enginePieTitle = this.engineDate
              // this.engineDonutLayout.annotations[0].text = this.engineDate
              this.engineDonutData[0].values[0] = totalMT
              this.engineDonutData[0].values[1] = totalPTOT
              this.engineDonutData[0].values[2] = totalDRT
              window.Plotly.update('engineDonutChart', this.engineDonutData, this.engineDonutLayout)
              */
              this.loadingEngineHour = false
              this.onResizeEngBar(null)
            })
            .catch(er => {
              console.log('Daily Info error :', er)

              this.engineBarData[0].x = []
              this.engineBarData[0].y = []
              window.Plotly.update('engineBarChart', this.engineBarData, this.engineBarLayout)

              /*
              this.enginePieTitle = this.engineDate
              
              this.engineDonutData[0].values[0] = 0
              this.engineDonutData[0].values[1] = 0
              this.engineDonutData[0].values[2] = 0
              window.Plotly.update('engineDonutChart', this.engineDonutData, this.engineDonutLayout)
              */

              this.loadingEngineHour = false
            })
        }
      }
    },
    updateEngineDonut(option) {
      let div = document.getElementById('engineDonutChart')
      if(TymCommon.isEmpty(option)) {
        // console.log('ENGINE DONUT CLEAR!!!!');
        if(div) {
          new window.Plotly.newPlot(div, this.engineDonutData, this.engineDonutLayout, this.barChartConfig)
          div.on('plotly_click', function(eventdata) {
            console.log('CLICK! :', eventdata.points[0]);
            if(eventdata.points[0].pointIndex > 0) {
//
            }
          });
        }
      } else {
        if(div) {
          console.log('ENGINE DONUT CHART!!!!');
        }
      }
    },
    updateFuelEff(option) {
      if(TymCommon.isEmpty(this.machineInfo))
        return

      let div = document.getElementById('fuelBarChart')
      let divM = document.getElementById('mileageBarChart')
      if(!div || !divM)
        return

      const store = useTymictStore()
      this.fuelBarData[0].x = []
      this.fuelBarData[0].y = []
      this.fuelBarData[0].text = []
      this.mileageBarData[0].x = []
      this.mileageBarData[0].y = []
      this.mileageBarData[0].text = []

      if(TymCommon.isEmpty(option)) {
        // 최초
        new window.Plotly.newPlot(div, this.fuelBarData, this.fuelBarLayout, this.barChartConfig)
        new window.Plotly.newPlot(divM, this.mileageBarData, this.mileageBarLayout, this.barChartConfig)

        const clickOnBarChar = (evt) => {
          // console.log('FUEL CLICK! :', this.fuelDate, evt.points[0])
          if(evt.points[0].value > 0) {
            if(this.fuelDate.length < 5) {
              // 1년치 월데이터를 선택한 월의 일별데이터로 전환하려함
              if(evt.points[0].value > 0){
                //
                let date = moment(this.fuelDate)
                let newMonth = new Date(date.year(), evt.points[0].pointIndex , date.date())
                this.fuelDate = TymCommon.getMonthString(newMonth)
                setTimeout(()=>{
                  this.updateFuelEff('MONTH')
                }, 5)
              }
            } else {
              //
            }
          }
        }
        div.on('plotly_click', clickOnBarChar)
        divM.on('plotly_click', clickOnBarChar)
      } else {
        let date = null
        let baseYear = 0

        if(this.fuelDate.length > 5) {
          if(store.getLanguage() == 'ko') {
            date = moment(this.fuelDate)
          } else {
            date = moment(this.fuelDate, "MM/YYYY")
          }
          baseYear = date.year()
        } else {
          baseYear = Number(this.fuelDate)
        }

        if(option == 'YEAR') {
          let startDay = baseYear.toString() + "-01-01 00:00:00"
          let lastDay = (baseYear+1).toString() + "-01-01 00:00:00"
          let tgtRange = TymCommon.makeDateTimeRange(this.machineInfo.SD, startDay,  lastDay, 'YYYY-MM-DD HH:mm:ss')

          if(TymCommon.isEmpty(tgtRange)) {
            // 판매일 이전 데이터는 없어
            TymCommon.Toast("판매전 데이터는 조회할 수 없습니다.")

            this.fuelBarData[0].x = []
            this.fuelBarData[0].y = []
            this.fuelBarData[0].text = []
            this.mileageBarData[0].x = []
            this.mileageBarData[0].y = []
            this.mileageBarData[0].text = []
            window.Plotly.update('fuelBarChart', this.fuelBarData, this.fuelBarLayout)
            window.Plotly.update('mileageBarChart', this.mileageBarData, this.mileageBarLayout)
            return
          }

          if(TymConst.IS_DEVELOPMENT) {
            console.log('updateFuelEff 0:', option, startDay, lastDay, tgtRange.sDateStr, tgtRange.eDateStr)
          }

          this.loadingFuelEff = true
          TymAws.getYearlyInfobyMNorSN(this.machineInfo.machineNo, this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr)
            .then(data => {
              if(TymConst.IS_DEVELOPMENT) {
                console.log('getYearlyInfobySN FUEL :', tgtRange.sDateStr, tgtRange.eDateStr, data)
              }
              let yearlyDataTFEA = data.body.TFEA // 평균연비
              let yearlyDataTFEF = data.body.TFEF // 연료소모량
              // let yearlyDataUsage = data.body.UsageTime

              this.fuelBarData[0].x = new Array(12)
              this.fuelBarData[0].y = new Array(12)
              this.fuelBarData[0].text = new Array(12)
              this.mileageBarData[0].x = new Array(12)
              this.mileageBarData[0].y = new Array(12)
              this.mileageBarData[0].text = new Array(12)
              for(let i = 0; i < yearlyDataTFEA.length; i++) {
                let fuel = Math.round(yearlyDataTFEF[i] * 100) / 100
                let fuelMile = Math.round(yearlyDataTFEA[i] * 100) / 100
                this.fuelBarData[0].x[i] = this.getMonthTitle(i + 1)
                this.fuelBarData[0].y[i] = fuel
                this.fuelBarData[0].text[i] = fuel
                this.mileageBarData[0].x[i] = this.getMonthTitle(i + 1)
                this.mileageBarData[0].y[i] = fuelMile
                this.mileageBarData[0].text[i] = fuelMile
              }
              if(store.getLanguage() == 'ko') {
                this.fuelBarData[0].hovertemplate = '<span style="color:#404040;">&nbsp;%{x}&nbsp;'+this.$t('manage.fuelConsumption')+'&nbsp;</span><span style="color:black;font-weight:bold;">%{y}&nbsp;</span><extra></extra>'
                this.mileageBarData[0].hovertemplate = '<span style="color:#404040;">&nbsp;%{x}&nbsp;'+this.$t('manage.fuelMileage')+'&nbsp;</span><span style="color:black;font-weight:bold;">%{y}&nbsp;</span><extra></extra>'
              } else {
                this.fuelBarData[0].hovertemplate = '<span style="color:#404040;">&nbsp;%{x}.&nbsp;'+this.$t('manage.fuelConsumption')+'&nbsp;</span><span style="color:black;font-weight:bold;">%{y}&nbsp;L&nbsp;</span><extra></extra>'
                this.mileageBarData[0].hovertemplate = '<span style="color:#404040;">&nbsp;%{x}.&nbsp;'+this.$t('manage.fuelMileage')+'&nbsp;</span><span style="color:black;font-weight:bold;">%{y}&nbsp;L&nbsp;</span><extra></extra>'
              }
              this.fuelBarData[0].hoverlabel = {
                bgcolor: 'white',
                bordercolor: '#00eb28',
              }
              this.mileageBarData[0].hoverlabel = {
                bgcolor: 'white',
                bordercolor: '#2800eb',
              }

              // console.log('getYearlyInfobySN : ', yearlyData, this.engineBarData)
              
              window.Plotly.update('fuelBarChart', this.fuelBarData, this.fuelBarLayout)
              window.Plotly.update('mileageBarChart', this.mileageBarData, this.mileageBarLayout)

              this.onResizeFuelBar(null)
              this.onResizeMileageBar(null)
              this.loadingFuelEff = false;
            })
            .catch(er => {
              console.log('getYearly Error :', er)
              this.loadingEngineHour = false;
            })
        } else {
          // MONTH
          const myDate = moment(this.fuelDate, ["YYYY. M.", "M-YYYY"]).toDate();
          let year = Number(myDate.getFullYear()), month = Number(myDate.getMonth()) + 1
          let saleDate = moment(this.machineInfo.SD).toDate()
          let startDay = year.toString() + '-' + (month).toString().padStart(2, '0') + '-' + '01 00:00:00'
          let lastDay = year.toString() + '-' + (month + 1).toString().padStart(2, '0') + '-' + '01 00:00:00'
          let tgtRange = TymCommon.makeDateTimeRange(this.machineInfo.SD, startDay,  lastDay, 'YYYY-MM-DD HH:mm:ss')
          if(lastDay < saleDate) {
            window.Plotly.update('fuelBarChart', this.fuelBarData, this.fuelBarLayout)

            this.fuelDonutData[0].values[0] = 0
            this.fuelDonutData[0].values[1] = 0
            window.Plotly.update('fuelDonutChart', this.fuelDonutData, this.fuelDonutLayout)
            this.onResizeFuelBar(null)
            return 
          }

          this.loadingFuelEff = true

          TymAws.getDailyInfobySN(this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr)
            .then(data => {
              if(TymConst.IS_DEVELOPMENT) {
                console.log('manageUsage.getYearlyInfobySN FUEL(daily) :', this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr, data)
              }

              // let jsonItems = JSON.parse(data.body)
              let info = data // jsonItems.Items
              let idx = 0
              let totalFUEL = 0, totalEFF = 0
              let last = moment(lastDay)
              last.subtract(1, 'days')
              let dayCnt = last.date()
              for(let i = 1; i <= dayCnt; i++) {
                this.fuelBarData[0].x[i - 1] = i.toString()
                this.mileageBarData[0].x[i - 1] = i.toString()
                if(idx < info.length) {
                  let day = Number(info[idx].DAY.substring(6))
                  if(day == i) {
                    let tfef = Number(info[idx].TFEF)
                    let tfea = Number(info[idx].TFEA)
                    let fuel = Math.round(tfef * 100) / 100
                    let fuelMile = Math.round(tfea * 100) / 100
                    /*
                    if(store.connectServer == 0)
                      fuelMile = Math.round(tfea * 100) / 100
                    else {
                      fuelMile = Math.round(((tfef / info[idx].VUDT) * 60) * 100) / 100
                    }
                    */

                    this.fuelBarData[0].y[i - 1] = fuel
                    this.fuelBarData[0].text[i - 1] = fuel
                    this.mileageBarData[0].y[i - 1] = fuelMile
                    this.mileageBarData[0].text[i - 1] = fuelMile.toString()
                    totalFUEL += tfef
                    idx++
                  } else {
                    this.fuelBarData[0].y[i - 1] = 0
                    this.mileageBarData[0].y[i - 1] = 0
                  }
                } else {
                  this.fuelBarData[0].y[i - 1] = 0
                  this.mileageBarData[0].y[i - 1] = 0
                }
              }
              console.log('MONTH MILEAGE :', data, this.mileageBarData[0])
              window.Plotly.update('fuelBarChart', this.fuelBarData, this.fuelBarLayout)
              window.Plotly.update('mileageBarChart', this.mileageBarData, this.mileageBarLayout)

              this.fuelPieTitle = this.fuelDate
              this.fuelDonutData[0].values[0] = totalFUEL
              this.fuelDonutData[0].values[1] = totalEFF
              window.Plotly.update('fuelDonutChart', this.fuelDonutData, this.fuelDonutLayout)
              
              this.loadingFuelEff = false
              this.onResizeFuelBar(null)
              this.onResizeMileageBar(null)
            })
            .catch(er => {
              console.log('Daily Info error :', er)

              this.fuelBarData[0].x = []
              this.fuelBarData[0].y = []
              this.fuelBarData[0].text = []
              this.mileageBarData[0].x = []
              this.mileageBarData[0].y = []
              this.mileageBarData[0].text = []
              window.Plotly.update('fuelBarChart', this.fuelBarData, this.fuelBarLayout)
              window.Plotly.update('mileageBarChart', this.mileageBarData, this.fuelBarLayout)

              this.fuelPieTitle = this.fuelDate
              // this.fuelDonutLayout.annotations[0].text = ''
              this.fuelDonutData[0].values[0] = 0
              this.fuelDonutData[0].values[1] = 0
              window.Plotly.update('fuelDonutChart', this.fuelDonutData, this.fuelDonutLayout)

              this.loadingFuelEff = false
              this.onResizeFuelBar(null)
              this.onResizeMileageBar(null)
            })
        }
      }
    },
    updateFuelEffDonut(option) {
      let div = document.getElementById('fuelDonutChart')
      if(TymCommon.isEmpty(option)) {
        //console.log('FUEL DONUT CLEAR!!!!');
        if(div) {
          new window.Plotly.newPlot(div, this.fuelDonutData, this.fuelDonutLayout, this.barChartConfig)
          div.on('plotly_click', function(eventdata) {
            console.log('CLICK! :', eventdata.points[0]);
            if(eventdata.points[0].pointIndex > 0) {
              //
            }
          });
        }
      } else {
        if(div) {
          console.log('ENGINE DONUT CHART!!!!');
        }
      }
    },
    updateBattery(option) {
      if(TymCommon.isEmpty(this.machineInfo))
        return

      let div = document.getElementById('batteryBarChart')
      if(!div)
        return

      const store = useTymictStore()
      this.batteryBarData[0].x = []
      this.batteryBarData[0].y = []
      this.batteryBarData[1].x = []
      this.batteryBarData[1].y = []

      if(TymCommon.isEmpty(option)) {
        // 최초
        new window.Plotly.newPlot(div, this.batteryBarData, this.batteryBarLayout, this.barChartConfig)

        const clickOnBarChar = (evt) => {
          // console.log('BATTERY CLICK! :', this.batteryDate, evt.points[0])
          if(evt.points[0].y > 0) {
            if(this.batteryDate.length < 5) {
              //
              let date = moment(this.batteryDate)
              let newMonth = new Date(date.year(), evt.points[0].pointIndex , date.date())
              this.batteryDate = TymCommon.getMonthString(newMonth)
              setTimeout(()=>{
                this.updateBattery('MONTH')
              }, 25)
            } else {
              //
              let date = moment(this.batteryDate)
              let newDate = new Date(date.year(), date.month(), evt.points[0].pointIndex + 1)
              // console.log('CLICK! BATTERY MONTH :', newDate)

              let sDate = moment(newDate).format("YYYYMMDD")
              TymAws.getDailyInfobySN(this.machineInfo.serialNo, sDate, sDate)
                .then(data => {
                  let jsonItems = JSON.parse(data.body)
                  console.log(jsonItems.Items[0])
                })
                .catch(er => {
                  console.log('Error :', er)
                })
            }
          }
        }
        div.on('plotly_click', clickOnBarChar)
      } else {
        let date = null
        if(store.getLanguage() == 'ko') {
          date = moment(this.batteryDate)
        } else {
          if(this.batteryDate.length > 4) {
            date = moment(this.batteryDate, "MM/YYYY")
          } else {
            date = moment(this.batteryDate)
          }
        }

        console.log(option, date.year(), date.month())
        if(option == 'YEAR') {
          let startDay = ''
          let saleDate = moment(this.machineInfo.SD)
          if(saleDate.year() > date.year()) {
            // 판매일 이전 데이터는 없어
            window.Plotly.update('batteryBarChart', this.batteryBarData, this.batteryBarLayout)
            return
          } else if(saleDate.year() == date.year()) {
            startDay = date.year().toString() + (saleDate.month() + 1).toString().padStart(2, '0') + saleDate.date().toString().padStart(2, '0')
          } else {
            startDay = date.year().toString()+ '0101'
          }

          this.loadingBattery = true
          TymAws.getYearlyInfobyMNorSN(this.machineInfo.serialNo, startDay)
            .then(data => {
              if(Array.isArray(data)) {
                console.log('getYearlyInfobyMNorSN array :', data)
              } else {
                let jsonItems = JSON.parse(data.body)
                let yearlyData = jsonItems.Items[0]

                if(TymConst.IS_DEVELOPMENT) {
                  console.log('ManageUsage.getYearlyInfobyMNorSN :', this.machineInfo.serialNo, startDay, yearlyData)
                }

                this.batteryBarData[0].x = new Array(12)
                this.batteryBarData[0].y = new Array(12)
                //this.batteryBarData[1].x = new Array(12)
                //this.batteryBarData[1].y = new Array(12)
                for(let i = 1; i < yearlyData.BATT.length; i++) {
                  // this.yearMonthHours[i-1] = Number(yearlyData.VUDT[i])
                  this.batteryBarData[0].x[i - 1] = this.getMonthTitle(i)// i.toString()
                  this.batteryBarData[0].y[i - 1] = yearlyData.BATT[i]
                  //this.batteryBarData[1].x[i - 1] = this.getMonthTitle(i)// i.toString()
                  //this.batteryBarData[1].y[i - 1] = 11.5
                }
                this.batteryBarData[0].hovertemplate = " %{x} " + this.$t('manage.batteryVoltage') + ": %{y} <extra></extra>"
                this.batteryBarData[1].hovertemplate = "<extra></extra>"

                console.log('batteryChart : ', this.batteryBarData, this.batteryBarLayout)
                window.Plotly.update('batteryBarChart', this.batteryBarData, this.batteryBarLayout)
              }

              this.loadingBattery = false;
            })
            .catch(er => {
              console.log('getYearly Error :', er)
              this.loadingBattery = false;
            })
        } else {
          // MONTH
          let saleDate = moment(this.machineInfo.SD).toDate()
          let startDay = date.toDate()
          let lastDay = new Date(startDay.getFullYear(), startDay.getMonth() + 1, 0)
          if(startDay < saleDate)
            startDay = saleDate
          if(lastDay < saleDate) {
            window.Plotly.update('batteryBarChart', this.batteryBarData, this.batteryBarLayout)
            return 
          } else {
            let now = new Date()
            if(lastDay > now)
              lastDay = now
          }
          
          let sDate = moment(startDay).format("YYYYMMDD")
          let sMonth = moment(startDay).format("YYYYMM")
          let eDate = moment(lastDay).format("YYYYMMDD")

          console.log(option, sDate, eDate)
          this.loadingBattery = true

          TymAws.getDailyInfobySN(this.machineInfo.serialNo, sDate, eDate)
            .then(data => {
              if(data.body != 'null') {
                let jsonItems = JSON.parse(data.body)
                let info = jsonItems.Items
                let idx = 0, lastData = 0

                if(TymConst.IS_DEVELOPMENT) {
                  console.log('ManageUsage.getDailyInfobySN :', this.machineInfo.serialNo, sDate, eDate, jsonItems)
                }

                lastData = Number(info[0].BATT)
                for(let i = 1; i <= lastDay.getDate(); i++) {
                  this.batteryBarData[0].x[i - 1] = i.toString()
                  //this.batteryBarData[1].x[i - 1] = i.toString()

                  let todays = sMonth + i.toString().padStart(2, '0')
                  if(idx < info.length) {
                    if(sDate <= todays) {
                      let day = Number(info[idx].DAY.substring(6))
                      if(day == i) {
                        lastData = this.batteryBarData[0].y[i - 1] = Number(info[idx].BATT)
                        idx++
                      } else {
                        this.batteryBarData[0].y[i - 1] = lastData
                      }
                    }
                  } else {
                    this.batteryBarData[0].y[i - 1] = lastData
                  }
                  //this.batteryBarData[1].y[i - 1] = 11.5
                }
              }
              window.Plotly.update('batteryBarChart', this.batteryBarData, this.batteryBarLayout)
              this.loadingBattery = false
            })
            .catch(er => {
              console.log('Daily Info error :', er)

              this.batteryBarData[0].x = []
              this.batteryBarData[0].y = []
              //this.batteryBarData[1].x = []
              //this.batteryBarData[1].y = []
              window.Plotly.update('batteryBarChart', this.batteryBarData, this.batteryBarLayout)

              this.loadingBattery = false
            })
        }
      }
    },
    onShowExcelUsage() {
      let now = new Date()
      this.excelFilename = 'usage_'
      this.excelFilename += now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += now.getHours().toString() + (now.getMinutes() + 1).toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += ".xlsx"

      this.excelTerm = this.engineDate
      this.excelVin = this.machineInfo.machineNo

      this.hasExcelUsage = this.engineBarData[0].x.length > 0
      if(!this.hasExcelUsage) {
        setTimeout(() => {
          this.$refs.excelPopupUsage.hide()
        }, 2000)
      } else {
        // console.log('Usage Data :', this.engineBarData[0])
      }
    },
    onShowExcelFuel() {
      let now = new Date()
      this.excelFilename = 'fuelconsumption_'
      this.excelFilename += now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += now.getHours().toString() + (now.getMinutes() + 1).toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += ".xlsx"

      this.excelTerm = this.fuelDate
      this.excelVin = this.machineInfo.machineNo

      this.hasExcelUsage = this.fuelBarData[0].x.length > 0
      if(!this.hasExcelUsage) {
        setTimeout(() => {
          this.$refs.excelPopupUsage.hide()
        }, 2000)
      } else {
        console.log('Fuel Data :', this.fuelBarData)
      }
    },
    onShowExcelBattery() {
      let now = new Date()
      this.excelFilename = 'lowbattery_'
      this.excelFilename += now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += now.getHours().toString() + (now.getMinutes() + 1).toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += ".xlsx"

      this.excelTerm = this.batteryDate
      this.excelVin = this.machineInfo.machineNo

      this.hasExcelUsage = this.batteryBarData[0].x.length > 0
      if(!this.hasExcelUsage) {
        setTimeout(() => {
          this.$refs.excelPopupUsage.hide()
        }, 2000)
      } else {
        console.log('Battery Data :', this.batteryBarData)
      }
    },
    saveUsageToExcel() {
      let desc = {
        filename: this.excelFilename,
        vin: this.machineInfo.machineNo,
        sn: this.machineInfo.serialNo,
        term: this.excelTerm,
        keyword: "사용시간"
      }
      TymAws.writeAction('관리', '사용 시간', desc)

      let xlsRow1 = []
      xlsRow1.push('구분', '사용시간(분)')
      let othRows = []
      let total = 0
      this.sheets = []
      for(let idx = 0; idx < this.engineBarData[0].x.length; idx++) {
        othRows.push([this.engineBarData[0].x[idx], this.engineBarData[0].y[idx]])
        total += parseFloat(this.engineBarData[0].y[idx])
      }
      othRows.push(['합계', total])

      let sheetName = '사용시간 '
      sheetName += '(' + this.excelVin + '_' + this.excelTerm +')'
      sheetName = sheetName.replace('/', '.')

      othRows.splice(0, 0, xlsRow1)
      this.sheets.push({
        name : sheetName,
        data : othRows
      })
      setTimeout(() => {
        this.$refs.excelPopupUsage.hide()
      }, 50)
    },
    saveFuelToExcel() {
      let desc = {
        filename: this.excelFilename,
        vin: this.machineInfo.machineNo,
        sn: this.machineInfo.serialNo,
        term: this.excelTerm,
        keyword: "연료 소모량"
      }
      TymAws.writeAction('관리', '연료 소모량', desc)

      let xlsRow1 = []
      xlsRow1.push('구분', '연료 소모량(L)', '연비')
      let othRows = []
      let total = 0      
      this.sheets = []
      for(let idx = 0; idx < this.fuelBarData[0].x.length; idx++) {
        othRows.push([this.fuelBarData[0].x[idx], this.fuelBarData[0].y[idx], this.mileageBarData[0].y[idx]])
        total += parseFloat(this.fuelBarData[0].y[idx])
      }
      othRows.push(['합계', total])

      let sheetName = '연료 소모량 '
      sheetName += '(' + this.excelVin + '_' + this.excelTerm +')'
      sheetName = sheetName.replace('/', '.')

      othRows.splice(0, 0, xlsRow1)
      this.sheets.push({
        name : sheetName,
        data : othRows
      })
      setTimeout(() => {
        this.$refs.excelPopupFuel.hide()
      }, 50)
    },
    saveBatteryToExcel() {
      let desc = {
        filename: this.excelFilename,
        vin: this.machineInfo.machineNo,
        sn: this.machineInfo.serialNo,
        term: this.excelTerm,
        keyword: "배터리 전압"
      }
      TymAws.writeAction('관리', '배터리 전압', desc)

      let xlsRow1 = []
      xlsRow1.push('구분', '배터리 전압(V)')
      let othRows = []
      this.sheets = []
      for(let idx = 0; idx < this.batteryBarData[0].x.length; idx++) {
        othRows.push([this.batteryBarData[0].x[idx], this.batteryBarData[0].y[idx]])
      }

      let sheetName = '배터리 전압 '
      sheetName += '(' + this.excelVin + '_' + this.excelTerm +')'
      sheetName = sheetName.replace('/', '.')

      othRows.splice(0, 0, xlsRow1)
      this.sheets.push({
        name : sheetName,
        data : othRows
      })
      setTimeout(() => {
        this.$refs.excelPopupBattery.hide()
      }, 50)
    },

  }
})
</script>


