<template>
    <div class="row items-center justify-center" :id="(elementid ? elementid : 'gaugeCtrl') + '_container'" style="position: relative;">
        <div :id="elementid ? elementid : 'gaugeCtrl'">
        </div>
        <q-resize-observer @resize="onResize"></q-resize-observer>
        <div class="row items-center justify-center" style="position:absolute;left:0;top:60%;right:0;bottom:0;">
            <div style="font-family:Prometo;font-size:32px;font-weight:400;">{{ gaugeValue }}</div>
        </div>
    </div>
</template>

<style scoped>


</style>

<script>
import { ref } from 'vue'
import TymCommon from '@/js/tymcommon.js'

export default ({
    props : {
        elementid : String,
    },
    components : {
    },
    computed : {
        valueChanged() {
            //console.log('valueChanged :', this.elementid, this.type, this.value)
            return this.value
        }
    },
    data() {
        return {
            gauge : ref(null)
        }
    },
    setup() {
        return {
            gaugeValue : ref('0'),
            data : ref([
                {
                    type: "indicator",
                    mode: "gauge",
                    value: 0,
                    /*
                    // 차트 위 제목
                    title: {
                        text:"text",
                        font: {
                            color:'black',
                            size: 16
                        }
                    },
                    */
                    gauge: {
                        "axis": {
                            "range": [0, 30 ]
                        },
                        bar: {
                            type:'radial',
                            color: 'red',
                            line: {
                                color:'traceEdges',
                                colorscale: 'Cividis',
                                width:1,
                                color_continuous_scale: 'Inferno'
                            },
                        },
                        bgcolor: 'white',
                        borderwidth: 2,     // 배경 테두리
                        bordercolor: 'lightgray',
                        steps: [
                            { "range": [0, 3], "color": "#519204B0" },
                            { "range": [3, 6], "color": "#5EB006B0" },
                            { "range": [6, 9], "color": "#8CB801B0" },
                            { "range": [9, 12], "color": "#BCBD00B0" },
                            { "range": [12, 15], "color": "#FCB600B0" },
                            { "range": [15, 18], "color": "#FEA201B0" },
                            { "range": [18, 21], "color": "#F97101B0" },
                            { "range": [21, 24], "color": "#E85300B0" },
                            { "range": [24, 27], "color": "#E90100B0" },
                            { "range": [27, 30], "color": "#CB0100B0" }
                        ],
                        threshold: {
                            line: { color: 'red', width: 2},
                            thickness: 1.0,
                            value: 50
                        },
                        gradient :{
                            type:'radial',
                            color:'blue'
                        }
                    }
                }
            ]),
            layout : ref({
                // title: '메롱',  그래프 위 제목
                width: 320,
                height: 150, 
                margin: {
                    l: 18,
                    t: 24,
                    r: 18,
                    b: 18
                },
                modebar : {
                    remove: ['toImage'],
                },
                paper_bgcolor: 'white',
                tickcolor: 'rgb(107, 107, 107)'
            }),
            config: ref({
                displayModeBar: false,
                displaylogo: false,
                responsive: true,
                scrollZoom: true,
                locale: 'en'
            })
        }
    },
    created() {},
    mounted() {
        let div = document.getElementById(this.elementid ? this.elementid : 'gaugeCtrl')
        if(div) {
            this.gauge = new window.Plotly.newPlot(div, this.data, this.layout, this.config)
            /*
            div.on('plotly_relayout', function(eventdata) {
                console.log('ZOOM! :', eventdata);
            });
            */
        }
    }, 
    unmounted() {},
    methods : {
        unusedParam() {
            //
        },
        onResize(size) {
            this.unusedParam(size)
            let divContainer = document.getElementById((this.elementid ? this.elementid : 'gaugeCtrl') + '_container')
            if(divContainer) {
                // console.log('TmyGauge.onResize :', (this.elementid ? this.elementid : 'gaugeCtrl'), divContainer.clientWidth, divContainer.clientHeight)
                let divGauge = document.getElementById(this.elementid ? this.elementid : 'gaugeCtrl')
                if(divGauge) {
                    setTimeout(() => {
                        window.Plotly.relayout(divGauge, {
                            width: divContainer.clientWidth,
                            height: divContainer.clientHeight, 
                            margin: {
                                l: 18,
                                t: 24,
                                r: 18,
                                b: 18
                            },
                            modebar : {
                                remove: ['toImage'],
                            },
                            paper_bgcolor: 'white',
                            tickcolor: 'rgb(107, 107, 107)'
                        })
                        window.Plotly.update(divGauge)
                    }, 1)    
                }
                
                // this.layout.width = divContainer.clientWidth
                // this.layout.height = divContainer.clientHeight
            }
        },
        // type : 어떤 그래프냐? 0~5 
        // value : 몇분?
        updateData(type, value) {
            let colors = [ 
                '#519204B0', '#5EB006B0', '#8CB801B0', '#BCBD00B0', '#FCB600B0', 
                '#FEA201B0', '#F97101B0', '#E85300B0', '#E90100B0', '#CB0100B0'
            ]
            let maxRanges = [60, 20, 20, 100, 100, 100]
            let div = document.getElementById(this.elementid ? this.elementid : 'gaugeCtrl')
            if(div) {
                let dataUpdate = null
                let step = 0
                let hourMin = TymCommon.manageMinutes(value)

                switch(type) {
                    case 0:
                    case 1:
                    case 2:
                        this.gaugeValue = Math.round(value).toString()

                        dataUpdate = {
                            value : Math.floor(value),
                            gauge : {
                                axis : {
                                    range : [0, maxRanges[type]]
                                },
                                bar: {
                                    type:'radial',
                                    color: 'cyan',
                                    line: {
                                        color:'traceEdges',
                                        colorscale: 'Cividis',
                                        width:1,
                                        color_continuous_scale: 'Inferno'
                                    },
                                },
                            }
                        }
                        dataUpdate.gauge.steps = []
                        step = maxRanges[type] / 10
                        for(let i = 0; i < 10; i++) {
                            dataUpdate.gauge.steps.push( { 
                                'range' : [i * step, (i + 1) * step ],
                                'color' : colors[i]
                            })
                        }
                        // console.log('updateData! :', type, value, this.data, this.layout, this.config)
                        break;
                    case 3:
                    case 4:
                    case 5:
                        this.gaugeValue = hourMin.Desc
                        {
                            let maxHours = Math.ceil(value / 60)
                            let hours = Math.floor(value / 60)
                            let minutes = value % 60

                            // console.log('updateData! :', type, value, maxHours, hours, minutes)
                            if(value > 0) {
                                dataUpdate = {
                                    value : hours + (minutes * 0.01),
                                    gauge : {
                                        axis : {
                                            range : [0, maxHours]
                                        },
                                        bar: {
                                            type:'radial',
                                            color: 'cyan',
                                            line: {
                                                color:'traceEdges',
                                                colorscale: 'Cividis',
                                                width:1,
                                                color_continuous_scale: 'Inferno'
                                            },
                                        },
                                    }
                                }
                                dataUpdate.gauge.steps = []
                                step = maxHours / 10
                                for(let i = 0; i < 10; i++) {
                                    dataUpdate.gauge.steps.push( { 
                                                'range' : [i * step, (i + 1) * step ],
                                                'color' : colors[i]
                                            })
                                }
                            } else {
                                dataUpdate = {
                                    value : 0,
                                    gauge : {
                                        axis : {
                                            range : [0, 1]
                                        },
                                        bar: {
                                            type:'radial',
                                            color: 'cyan',
                                            line: {
                                                color:'traceEdges',
                                                colorscale: 'Cividis',
                                                width:1,
                                                color_continuous_scale: 'Inferno'
                                            },
                                        },
                                    }
                                }
                                dataUpdate.gauge.steps = []
                                step = 1 / 10
                                for(let i = 0; i < 10; i++) {
                                    dataUpdate.gauge.steps.push( { 
                                                'range' : [i * step, (i + 1) * step ],
                                                'color' : colors[i]
                                            })
                                }
                            }
                        }
                        break;
                }
                
                window.Plotly.update(div, dataUpdate)
            }
            // this.data[0].gauge.threshold.value  = 50
            // this.data[0].gauge.axis.range  = [0, 80]
            // console.log('updateData! :', type, value, this.data);
        }
    }
})
</script>
