<template>
  <div class="dlgContainer">
    <div :class='isLandscape ? "dlgLogo_ls" : "dlgLogo_pt"'>
      <MainLogo class="col" ></MainLogo>
    </div>
    <div class="row items-center justify-center dlgTabContainer" >
      <div :class='isLandscape ? "dlgInnerBorder_ls" : "dlgInnerBorder_pt"'>
        <div class="col-auto column">
          <div class="col-auto row justify-between items-center">
            <div class="col-auto row justify-start items-center">
              <div :class="currentTab=='findID' ? 'row items-center justify-center activeTabL' : 'row items-center justify-center normalTabL'"
                  @click="clickTab('findID')">
                <div>{{ $t('login.findID') }}</div>
              </div>
              <div :class="currentTab=='resetPW' ? 'row items-center justify-center activeTabR' : 'row items-center justify-center normalTabR'"
                  @click="clickTab('resetPW')">
                <div>{{ $t('login.resetPassword') }}</div>
              </div>
            </div>
            <div class="col row justify-end items-start" style="margin:-10pt -8pt 0 0;">
              <!-- 닫기 버튼 :class='isLandscape ? "closeBtn" : "closeBtn_pt"'-->
              <q-btn dense flat
                    color="red-9" size="14pt" icon="disabled_by_default"
                    @click="closeBtnClicked">
              </q-btn>
            </div>
          </div>
        </div>
        <q-tab-panels v-model="currentTab" animated style="width:auto;height:90%;">
          <q-tab-panel name="findID" style="height:100%;width:100%;">
            <div class="column justify-between" style="font-size:12pt;height:100%;" v-show="findIDStep==0">
              <div class="col-9" >
                <table style="margin:15pt;">
                  <tr style="height:30pt;">
                    <td style="vertical-align:top;" width="32px">
                      <q-radio dense v-model="optionFindID" val="phone" color="red-7" />
                    </td>
                    <td style="vertical-align:center;">
                      <div style="font-size:12pt;font-weight:500;">
                        {{ $t('login.memberByPhone') }}
                      </div>
                      <div v-show="optionFindID=='phone'" style="margin-top:5pt;font-size:11pt;color:#212529">
                        {{ $t('login.nameAndPhoneForID') }}
                      </div>
                    </td>
                  </tr>
                  <tr v-show="optionFindID=='phone'">
                    <td></td>
                    <td>
                      <q-input
                          v-model="inputPhoneName"
                          dense outlined
                          bg-color="white"
                          color="red-7"
                          style="font-size:12pt;"
                          :placeholder="$t('placeholder.inputYourName')">
                      </q-input>
                    </td>
                  </tr>
                  <tr v-show="optionFindID=='phone'">
                    <td></td>
                    <td> 
                      <div class="row q-gutter-sm justify-between">
                        <q-input
                            class="col-3"
                            v-model="inputPhoneCountry"
                            dense outlined
                            bg-color="white"
                            color="red-7"
                            style="font-size:12pt;"
                            placeholder="국가코드">
                        </q-input> 
                        <q-input
                            class="col-8"
                            v-model="inputPhoneNumber"
                            dense outlined
                            bg-color="white"
                            color="red-7"
                            style="font-size:12pt;"
                            :placeholder="$t('placeholder.inputPhone')">
                        </q-input>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align:top">
                      <q-radio dense class="col" v-model="optionFindID" val="email" color="red-7" />
                    </td>
                    <td>
                      <div style="font-size:12pt;font-weight:500;">
                        {{ $t('login.memberByEMail') }}
                      </div>
                      <div v-show="optionFindID=='email'" style="margin-top:5pt;font-size:11pt;color:#212529">
                        {{ $t('login.nameAndEmailForID') }}
                      </div>
                    </td>
                  </tr>
                  <tr v-show="optionFindID=='email'">
                    <td></td>
                    <td>
                      <q-input
                          v-model="inputName"
                          dense outlined
                          bg-color="white"
                          color="red-7"
                          style="font-size:12pt;"
                          :placeholder="$t('placeholder.inputYourName')">
                      </q-input>
                    </td>
                  </tr>
                  <tr v-show="optionFindID=='email'">
                    <td></td>
                    <td> 
                      <q-input
                          v-model="inputEmail"
                          dense outlined
                          bg-color="white"
                          color="red-7"
                          style="font-size:12pt;"
                          :placeholder="$t('placeholder.inputEmail')">
                      </q-input>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="col-2 row justify-center items-start">
                <q-btn
                  class="col-3"
                  dense no-caps
                  text-color="white"
                  style="border-radius:3px;width:64pt;font-weight:500;font-size:12pt;background-color:#eb0028;"
                  :label='findIDOK ? "Close" : "Next"'
                  @click="clickFindID"
                  />
              </div>
            </div>

            <div class="col column justify-between" style="width:100%;height:100%;" v-if="findIDStep==1">
              <FoundUsers v-on:selectDialog="selectDialogFunc" v-bind:users="foundUsers" v-bind:kind="optionFindID" style="width:100%;"/>
            </div>
          </q-tab-panel>

          <q-tab-panel name="resetPW">
            <div class="column" style="height:100%;">
              <!-- 암호 초기화 / ID 입력 -->
              <div class="column justify-between" style="font-size:12pt;height:100%;" v-show="resetStep==0">
                <div class="col-9 row justify-center items-center">
                  <div>
                    <div style="font-size:12pt;font-weight:500;margin-bottom:15pt;">
                      {{  $t('login.idForReset') }}
                    </div>
                    <q-input
                        v-model="pwResetID"
                        dense outlined
                        bg-color="white"
                        color="red-7"
                        style="font-size:12pt;"
                        :placeholder="$t('placeholder.inputID')">
                    </q-input>
                  </div>
                </div>
                <div class="col-2 row justify-center items-start">
                  <q-btn
                    class="col-3"
                    dense no-caps
                    text-color="white"
                    style="border-radius:3px;width:64pt;font-weight:500;font-size:12pt;background-color:#eb0028;"
                    label="Next"
                    @click="clickResetStep()"
                    />
                </div>
              </div>

              <!-- 암호 초기화 / Phone or Email -->
              <div class="column" style="font-size:12pt;height:100%;" v-show="resetStep==1">
                <div class="col column justify-between no-wrap" style="width:100%;height:100%;">
                  <div class="col-9 column" style="margin:15pt;">
                    <table>
                      <!-- 전화번호 인증을 막음
                      <tr style="height:24pt;">
                        <td style="vertical-align:top;" width="32px">
                          <q-radio dense v-model="pwResetOption" val="phone" color="red-7" />
                        </td>
                        <td style="vertical-align:center;">
                          <div style="font-size:12pt;font-weight:500;">
                            {{ $t('login.memberByPhone') }}
                            <div v-show="pwResetOption=='phone'" style="margin-top:5pt;font-size:11pt;color:#212529">
                              {{ $t('login.nameAndEmailForID')}}
                            </div>
                          </div>
                        </td>
                      </tr>
                      -->
                      <tr v-show="pwResetOption=='phone'">
                        <td></td>
                        <td>
                          <q-input
                              v-model="pwResetName"
                              dense outlined
                              bg-color="white"
                              color="red-7"
                              style="font-size:12pt;"
                              :placeholder="$t('placeholder.inputYourName')">
                          </q-input>
                        </td>
                      </tr>
                      <tr v-show="pwResetOption=='phone'">
                        <td></td>
                        <td> 
                          <div class="row q-gutter-sm justify-between">
                            <q-input
                                class="col-3"
                                v-model="pwResetCountry"
                                dense outlined
                                bg-color="white"
                                color="red-7"
                                style="font-size:12pt;"
                                placeholder="국가코드">
                            </q-input> 
                            <q-input
                                class="col-8"
                                v-model="pwResetPhone"
                                dense outlined
                                bg-color="white"
                                color="red-7"
                                style="font-size:12pt;"
                                :placeholder="$t('placeholder.inputPhone')">
                            </q-input>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="vertical-align:top;width:32px;">
                          <q-radio dense class="col" v-model="pwResetOption" val="email" color="red-7" />
                        </td>
                        <td>
                          <div style="font-size:12pt;font-weight:500;">
                            {{ $t('login.resetByEMail') }}
                          </div>
                          <div v-show="pwResetOption=='email'" style="margin-top:5pt;font-size:11pt;color:#212529">
                            {{ $t('login.nameAndEmailForID') }}
                          </div>
                        </td>
                      </tr>
                      <tr v-show="pwResetOption=='email'">
                        <td></td>
                        <td>
                          <q-input
                              v-model="pwResetName"
                              dense outlined
                              bg-color="white"
                              color="red-7"
                              style="font-size:12pt;"
                              :placeholder="$t('placeholder.inputYourName')">
                          </q-input>
                        </td>
                      </tr>
                      <tr v-show="pwResetOption=='email'">
                        <td></td>
                        <td> 
                          <q-input
                              v-model="pwResetEmail"
                              dense outlined
                              bg-color="white"
                              color="red-7"
                              style="font-size:12pt;"
                              :placeholder="$t('placeholder.inputEmail')">
                          </q-input>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-2 row justify-center items-start">
                    <q-btn
                      class="col-auto"
                      dense no-caps
                      text-color="white"
                      style="border-radius:3px;width:64pt;font-weight:500;font-size:12pt;background-color:#eb0028;"
                      label="Next"
                      @click="clickResetStep()"
                      />
                  </div>
                </div>
              </div>

              <!-- 인증번호 요청 -->
              <div class="column justify-between" style="font-size:12pt;height:100%;" v-show="resetStep==2">
                <div class="col-9 column">
                    <div class="col-5 column justify-center items-center">
                      <div class="col row justify-center items-center">
                        <div style="font-size:12pt;text-align: center;">{{ $t('login.requestAuthCode') }}</div>
                      </div>
                      <div class="col row justify-center items-start" style="width:50%;">
                        <q-btn dense no-caps flat
                              text-color="#212529"
                              class="col" @click="requestAuthCode"
                              style="height:30pt;width:70%;border-radius:3px;font-weight:500;font-size:12pt;background-color:#dddddd;"
                              :label='authReqCount > 0 ? $t("login.reRequestingAuthCode") : $t("login.requestingAuthCode")' />
                      </div>
                    </div>

                    <div class="col-5 column justify-center items-center">
                      <div class="col row justify-center items-center">
                        <div style="font-size:12pt;text-align: center;">{{reqInputRxAuthCode}}</div>
                      </div>
                      <q-input 
                              v-model="pwResetAuthCode"
                              dense outlined
                              class="col"
                              bg-color="white"
                              color="red-7"
                              style="width:50%;font-size:12pt"
                              :placeholder="$t('placeholder.authCode')">
                      </q-input>
                    </div>
                </div>
                <div class="col-2 row justify-center items-start">
                  <q-btn
                    class="col-3"
                    dense no-caps
                    text-color="white"
                    style="border-radius:3px;width:64pt;font-weight:500;font-size:12pt;background-color:#eb0028;"
                    label="Next"
                    @click="clickResetStep()"
                    />
                </div>
              </div>

              <!-- 새 암호 -->
              <div class="column justify-between" style="font-size:12pt;height:100%;" v-show="resetStep==3">
                <div class="col-9 column" style="padding: 0;">
                  <div class="col-auto">
                    <div class="col-5 column q-gutter-sm justify-center items-center">
                    </div>
                  </div>
                  <table>
                    <tr>
                      <td>
                        <div class="column q-gutter-sm justify-between">
                          <div class="col-5 column justify-center items-center" style="width:100%;min-width:150pt;">
                            <div style="font-size:12pt;margin-top:5pt;text-align: center;">{{ $t('login.setSafePassword') }}</div>
                            <q-input 
                                    v-model="pwResetNewPassword1"
                                    dense outlined bg-color="white" color="red-7"
                                    style="width:70%;font-size:12pt;"
                                    :placeholder="$t('placeholder.inputNewPassword')"
                                    :error="invalidPW1"
                                    :type="!pwResetView1 ? 'password' : 'text'"
                                    @update:model-value="keypressPW1"
                                    >
                              <template v-slot:append>
                                <q-icon :name="pwResetView1 ? 'visibility_off' : 'visibility'"
                                        class="cursor-pointer"
                                        @click="pwResetView1 = !pwResetView1"/>
                              </template>
                              <template v-slot:error>
                                <div style="font-size:11pt;">{{ $t('login.passwordGuide') }}</div>
                              </template>
                            </q-input>
                            <div class="col-5 row justify-center items-center" style="width:100%;min-width:150pt;margin-top:24px;">
                              <div style="font-size:12pt;margin-top:5pt;text-align: center;">{{ $t('login.inputSamePW')}}</div>
                              <q-input 
                                      v-model="pwResetNewPassword2"
                                      dense outlined
                                      bg-color="white"
                                      color="red-7"
                                      style="width:70%;font-size:12pt;"
                                      :placeholder="$t('placeholder.reInputPassword')"
                                      :error="invalidPW2"
                                      :type="!pwResetView2 ? 'password' : 'text'"
                                      @update:model-value="keypressPW2"
                                      >
                                <template v-slot:append>
                                  <q-icon :name="pwResetView2 ? 'visibility_off' : 'visibility'"
                                          class="cursor-pointer"
                                          @click="pwResetView2 = !pwResetView2" />
                                </template>
                                <template v-slot:error>
                                  <div style="font-size:11pt;">{{ $t('login.passwordGuide') }}</div>
                                </template>
                              </q-input>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-2 row justify-center items-start">
                  <q-btn
                    class="col-3"
                    dense no-caps
                    text-color="white"
                    style="border-radius:3px;width:64pt;font-weight:500;font-size:12pt;background-color:#eb0028;"
                    label="Next"
                    @click="clickResetStep()"
                    />
                </div>
              </div>

              <div class="column justify-between" style="font-size:12pt;height:100%;" v-show="resetStep==4">
                <div class="col-9 column" style="">
                  <div class="col row items-center justify-center">
                    <div style="font-size:12pt;font-weight:500;margin-top:5pt;text-align: center;">{{ $t('login.completedChangePW') }}</div>
                  </div>
                </div>
                <div class="col-2 row justify-center items-start">
                  <q-btn
                    class="col-3"
                    dense no-caps
                    text-color="white"
                    style="border-radius:3px;width:64pt;font-weight:500;font-size:12pt;background-color:#eb0028;"
                    label="Close"
                    @click="clickResetStep()"
                    />
                </div>
              </div>

              <div class="column justify-between" style="font-size:12pt;height:100%;" v-show="resetStep==10">
                <div class="col row items-center justify-center" style="width:100%;height:100%;">
                  <div style="font-size:18px;font-weight:bold;">{{ $t('errorMsg.notMatchedInfo') }}</div>
                </div>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>
  </div>

</template>

<style scoped>
div.groupbox { 
	width: 100%;			/* 박스 너비, 지정하지 않으면 최대치 사용 */
	height: auto;				
	margin: 0em 0 0em 0;		/* 박스 주위 여백 */
	border: 1px solid lightgray;		/* 박스 테두리 색 */
	padding: 0 8px;			/* 박스 여백(padding) */
}
div.groupbox h4 { 
	line-height: 100%; 		/* 위쪽 테두리 선과 내용물 사이의 간격 */
	padding-left: 8pt; 		/* 왼쪽 테두리 선과 라벨 사이의 간격 */
	font-size: 12pt;			/* h4 태그의 텍스트 크기 지정 */
	font-weight: 500;		/* h4 태그의 텍스트 두께 지정 */ 
}
div.groupbox h4 span { 
	background-color: #fff;		/* 배경색과 동일해야 함 */
	padding: 0 5pt;			/* 라벨과 좌우 선 사이의 간격 */
	position: relative; 
	top: -1.8em;			/* 라벨의 상하 위치 조절 */
}
div.groupbox p {
	margin-bottom: 0em;
	line-height: 150%;
}
.normalTabL {
  font-size:12pt;
  width:100pt;
  height:32pt;
  cursor: pointer;
  text-align: center;
  background-color: #f3f3f3;
  color:rgba(0, 0, 0, 0.6);
  border : 1px solid #d3d3d3;
  border-width:1px 0 0 1px;
}
.activeTabL {
  font-size:12pt;
  width:100pt;
  height:32pt;
  cursor: pointer;
  text-align: center;
  background-color: #feffff;
  color:#eb0028;
}

.normalTabR {
  font-size:12pt;
  width:100pt;
  height:32pt;
  cursor: pointer;
  text-align: center;
  background-color: #f3f3f3;
  color:rgba(0, 0, 0, 0.6);
  border : 1px solid #d3d3d3;
  border-width:1px 1px 0 0;
}
.activeTabR {
  font-size:12pt;
  width:100pt;
  height:32pt;
  cursor: pointer;
  text-align: center;
  background-color: #feffff;
  color:#eb0028;
  border-width: 0;
}

.normalTab:hover {
  background-color: #feffff;
}
.normalTab:active {
  color:#eb0028
}


.dlgContainer {
  position:relative;
  width:auto;
  height:100%;
}
.dlgLogo_ls {
  position:absolute;
  left:64px;
  top:64px;
}
@media (max-height: 480px) {
  .dlgLogo_ls {
    position:absolute;
    left:28px;
    top:28px;
  }
}
.dlgLogo_pt {
  position:absolute;
  top:48px;
  left:50%;
  -ms-transform: translate(50%, -50%);
  transform: translateX(-50%);
  width:100%;
  height:auto;
  min-width:210pt;
}

.dlgTabContainer {
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  min-width:210pt;
}

.dlgInnerBorder_ls {
  width:70%;
  height:60%;
  margin-top:100pt;
  max-width:400pt;
  min-height:300pt;
  max-height:500pt;
}
@media (max-height: 480px) {
  .dlgInnerBorder_ls {
    position: absolute;
    left:130px;
    right:30px;
    width:auto;
    margin-top:0;
    top:50%;
    -ms-transform: translate(50%, -50%);
    transform: translateY(-50%);
    min-height:100vh;
    max-height:500pt;
  }
}


.dlgInnerBorder_pt {
  width:85%;
  height:60%;
  margin-top:35%;
  min-width:200pt;
  min-height:70%;
}

.closeBtn {
  position:relative;
  right:64px;
  top:64px;
  width:auto;
  height:auto;
}
.closeBtn_pt {
  position:absolute;
  right:32px;
  top:32px;
  width:auto;
  height:auto;
}

th, td {
  padding: 5pt 0pt;
}
@media (min-width: 481px) {
  th, tr, td {
    padding: 5pt 0pt;
  }
}
@media (max-width: 480px) {
  th, tr, td {
    padding: 2pt 0pt;
  }
}

</style>

<script>
import { ref } from 'vue'
import MainLogo from '@/components/MainLogo.vue'
import FoundUsers from '@/components/FoundUsers.vue'
import TymFncs from '@/js/tymfunctions'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'
import { Auth } from 'aws-amplify'
import { useTymictStore } from '@/store/tymict';

export default ({
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    }
  },
  components : {
    MainLogo,
    FoundUsers
  },
  setup() {
    return {
      currentTab : ref('findID'),

      optionFindID : ref('email'),
      findIDStep : ref(0),
      /*
      inputName : ref('TYMICT 관리용 이창원'),
      inputEmail : ref('cwlee@tymict.com'),
      inputPhoneName : ref('TYMICT 관리용 이창원'),
      inputPhoneCountry : ref('+82'),
      inputPhoneNumber : ref('01029139866'),
      */
      inputName : ref(''),
      inputEmail : ref(''),
      inputPhoneName : ref(''),
      inputPhoneCountry : ref(''),
      inputPhoneNumber : ref(''),
      findIDOK : ref(false),
      foundUsers : ref([]),

      resetStep : ref(3),
      pwResetOption : ref('email'),
      /*
      pwResetID : ref('cwlee'),
      pwResetName : ref('이창원'),
      pwResetPhone : ref('01029139866'),
      pwResetEmail : ref('cwlee@tymict.com'),
      */
      pwResetID : ref(''),
      pwResetName : ref(''),
      pwResetPhone : ref(''),
      pwResetEmail : ref(''),

      authReqCount : ref(0),
      pwResetAuthCode : ref(''),
      pwResetView1 : ref(false),
      pwResetView2 : ref(false),
      pwResetNewPassword1 : ref(''),
      pwResetNewPassword2 : ref(''),
      invalidPW1 : ref(false),
      invalidPW2 : ref(false),
      pwResetAuthResponse : ref(null),
      reqInputRxAuthCode : ref('인증번호 대기 중...')
    }
  },
  mounted() {
    const store = useTymictStore()
    if(store.connectServer == 0) {
      this.inputPhoneCountry = '+82'
      this.pwResetCountry = '+82'
    } else {
      this.inputPhoneCountry = '+1'
      this.pwResetCountry = '+1'
    }
  },
  methods: {
    closeBtnClicked() {
      this.$emit('closeDialog')
    },
    clickTab(name) {
      if(this.currentTab != name) {
        this.currentTab=name

        this.optionFindID = 'email'
        this.findIDStep = 0
        this.findIDOK = false
        this.foundUsers = []
        this.inputName = ''
        this.inputEmail = ''

        if(TymConst.IS_DEVELOPMENT) {
          this.resetStep = 0
          console.log('for DEVELOPMENT !!!')
        } else {
          this.resetStep = 0
        }
        this.pwResetID = ''
        this.pwResetName = ''
        this.pwResetPhone = ''
        this.pwResetEmail = ''
        this.pwResetAuthCode = ''
        this.pwResetView1 = false
        this.pwResetView2 = false
        this.pwResetNewPassword1 = ''
        this.pwResetNewPassword2 = ''
        this.pwResetAuthResponse = null

        // 2023.11.22 SS요청으로 삭제
        // this.reqInputRxAuthCode = this.$t('login.waitingAuthCode')
        this.reqInputRxAuthCode = ''
      }
    },
    clickFindID() {
      if(this.optionFindID == "email") {
        if(TymCommon.isEmpty(this.inputName) || TymCommon.isEmpty(this.inputEmail)) {
          console.log('clickFindID by EMail - empty param...')
          return
        }

        TymFncs.findIDbyEmailOrPhone(this.inputName, this.inputEmail, 0)
        .then(users => {
          console.log('clickFindID :', users)

          if(users.length >= 0) {
            this.foundUsers = users;
            this.findIDStep = 1
          } else {
            this.findIDOK = false;
          }
        })
        .catch(err => {
          console.log('Find ID Error : ', err)
        })
      } else {
        if(TymCommon.isEmpty(this.inputPhoneName) || TymCommon.isEmpty(this.inputPhoneNumber)) {
          console.log('clickFindID by PhoneNumber - empty param...')
          return
        }

        TymFncs.findIDbyEmailOrPhone(this.inputPhoneName, this.inputPhoneNumber, 1)
        .then(users => {
          console.log('Find Phone : ', users)

          if(users.length >= 0) {
            this.foundUsers = users;
            this.findIDStep = 1
          } else {
            this.findIDOK = false;
          }
        })
        .catch(err => {
          console.log('Find ID Error : ', err)
        })
      }
    },
    selectDialogFunc(info) {
      if(info) {
        this.$emit('closeDialog', { found_id : info.id } )
      } else {
        this.$emit('closeDialog')
      }
    },
    selectFoundID(value, evt){
      return evt;
    },

    clickResetStep() {
      setTimeout(() => {
        switch(this.resetStep)
        {
          case 0:
            if(TymCommon.isEmpty(this.pwResetID)) {
              console.log('resetPW : empty ID')
              return;
            }
            this.resetStep = 1
            break;
          case 1:
            if(this.pwResetOption == 'email') {
              if(TymCommon.isEmpty(this.pwResetName) || TymCommon.isEmpty(this.pwResetEmail)) {
                console.log('resetPW : empty Name or Email')
                return;
              }
              TymFncs.findIDbyEmailOrPhone(this.pwResetName, this.pwResetEmail, 0)
                .then(users => {
                  let found = false
                  if(users.length > 0) {
                    let idx = 0;
                    for(idx = 0; idx < users.length; idx++) {
                      console.log('reset-pw find : ', idx, users[idx].id, this.pwResetID)
                      if(users[idx].id == this.pwResetID) {
                        found = true
                        break;
                      }
                    }
                  }
                  if(found) {
                    this.resetStep = 2
                  } else {
                    console.log('Not found member by email : ', this.pwResetName)
                    this.resetStep = 10
                  }
                })
                .catch(err => {
                  console.log('Find ID Error : ', err.code, err.message)
                })
            } else {
              if(TymCommon.isEmpty(this.pwResetName) || TymCommon.isEmpty(this.pwResetPhone)) {
                console.log('resetPW : empty Name or Phone')
                return;
              }
              TymFncs.findIDbyEmailOrPhone(this.pwResetName, this.pwResetPhone, 1)
                .then(users => {
                  let found = false
                  if(users.length > 0) {
                    let idx = 0;
                    for(idx = 0; idx < users.length; idx++) {
                      if(users[idx].id == this.pwResetID) {
                        found = true
                        break;
                      }
                    }
                  }
                  if(found) {
                    this.resetStep = 2
                  } else {
                    console.log('Not found member by phone : ', this.pwResetName)
                    this.resetStep = 10
                  }
                })
                .catch(err => {
                  console.log('Find ID Error : ', err)
                })
            }
            this.resetStep = 2
            break;
          case 2:
            if(TymCommon.isEmpty(this.pwResetAuthCode)) {
              console.log('Empty auth code!!!')
              return;
            } 
            this.resetStep = 3  
            break;
          case 3:
            if(TymCommon.isEmpty(this.pwResetNewPassword1)) {
              console.log('Empty PW1!!!')
              return;
            }
            if(this.pwResetNewPassword1 != this.pwResetNewPassword2) {
              console.log('mismatch PW1 and PW2!!!')
              return;
            }
            // console.log('NewPW : ', this.pwResetAuthCode, this.pwResetNewPassword1)
            Auth.forgotPasswordSubmit(this.pwResetID, this.pwResetAuthCode, this.pwResetNewPassword1)
              .then(res => {
                console.log('forgotPassword => ', res)
                this.resetStep = 4
              })
              .catch(err => {
                console.log('forgotPassword error => ', err)
              })
            break;
          case 4:
            this.resetStep = 0
            this.$emit('closeDialog')
            break;
          case 10:
            // 찾지 못했네
            this.resetStep = 0
            TymCommon.Toast('일치하는 정보를 찾을 수 없습니다.')
            this.$emit('closeDialog')
            break;
        }
      }, 25)
    },
    requestAuthCode() {
      const store = useTymictStore()

      console.log('requestAuthCode => ', this.pwResetID)

      this.authReqCount++
      Auth.forgotPassword(this.pwResetID)
        .then(res => {
          console.log('forgotPassword => ', res)
          if(res.CodeDeliveryDetails) {
            this.pwResetAuthResponse = res.CodeDeliveryDetails
            console.log('forgotPassword => ', res.CodeDeliveryDetails.DeliveryMedium, res.CodeDeliveryDetails.Destination)
            if(store.getLanguage() == 'ko') {
              this.reqInputRxAuthCode = res.CodeDeliveryDetails.DeliveryMedium + "(" + res.CodeDeliveryDetails.Destination + ")" + "을(를) 통해 받은 인증번호를 입력해 주세요"
            } else {
              this.reqInputRxAuthCode = "Enter code sent to your Email " + "(" + res.CodeDeliveryDetails.Destination + ")"
            }
          }
        })
        .catch(err => {
          console.log('forgotPassword err : ', this.pwResetID, err.code, err.message)
        })
    },
    keypressPW1() {
      if(this.pwResetNewPassword1.length > 0) {
        //      let reg = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
        let reg = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
        let abc = this.pwResetNewPassword1.match(reg)
        this.invalidPW1 = (abc == null)
      } else {
        this.invalidPW1 = false
      }
    },
    keypressPW2() {
      if(this.pwResetNewPassword2.length > 0) {
        //      let reg = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
        let reg = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
        let abc = this.pwResetNewPassword2.match(reg)
        this.invalidPW2 = (abc == null)
      } else {
        this.invalidPW2 = false
      }
    }
}
})
</script>
