<template>
  <div class='column' style="background:#f2f2f2;width:100%;height:100%;overflow-y: hidden;overflow-x:hidden;">
    <div class="col-auto row mainBorder bottomContainer" style="width:100%;overflow-x:hidden;">
      <div class="col-auto flex-break itemContainer" v-for="(item, index) in itemValues" :key="item"
           v-show="item.visible"
           style="border:1px solid #cccccc;;box-shadow:1px 1px 2px 3px rgba(192, 192, 192, 0.3);">
        <div class="col-auto column items-center justify-start" style="position:relative;width:200px;height:240px;background-color:white;">
          <div>
            <!--
            <img :src="item.Icon" :width="item.Width" :height="item.Height" :style="index == 0 ? 'margin:9px 0 0px 0;' : 'margin:12px 0 9px 0;'">
            -->
            <img :src="getIcon(item)" :width="item.Width" :height="item.Height" :style="index == 0 ? 'margin:9px 0 0px 0;' : 'margin:12px 0 9px 0;'">
          </div>
          <div class="col-auto row justify-center items-center"
                :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'"
                style="font-size:20px;font-weight:normal;color:black;margin:0 0 0 0;">
            {{ item.Title }}
          </div>
          <div style="position:absolute;left:12px;top:152px;right:12px;height:1px;background-color:#bbbbbb;"></div>
          <div style="position:absolute;left:27px;top:151px;height:3px;width:24px;background-color:#eb0028;"></div>
          <div class="col row justify-center items-center" style="width:100%;height:auto;padding:0 12px;">
            <!-- :class='item.isAbnormal ? "textRedWhite" : "textWhiteBlack"' -->
            <div class="col textValue text-center textWhiteBlack" 
                :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.textValue {
  margin-top:5px;
  font-weight:500;
  font-size:22px;
}

.textWhiteBlack {
  /*
  color: white;
  text-shadow: -1px -1px #222222, 1px 1px #222222, -1px 1px #222222, 1px -1px #222222;
  */
  color: black;
  font-weight:normal;
}
.textRedWhite {
  color:#eb0028;
  text-shadow: -1px -1px #bbbbbb, 1px 1px #bbbbbb, -1px 1px #bbbbbb, 1px -1px #bbbbbb;
}

.mainBorder {
  height:auto;
  background:#f2f2f2;
}

.itemTitle {
  font-size:16px;
  font-weight:500;
}

.itemContainer {
  margin:6px;
  overflow-x:hidden;
}

.statusBorder {
  background-size:contain;
  background-repeat: no-repeat;
}

/* Full HD 이상 */
@media all and (min-width:1280px) {
  .statusBorderRes {
    width:400px;
    height:188px;
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px) */
@media all and (max-width : 1279px) { 
  .statusBorderRes {
    width:300px;
    height:140px;
    /*
    width:240px;
    height:113px;
    */
 }
}

/*
.statusBorder {
  border-radius:3px;
  margin:0;
  padding:0;
  width:400px;
  height:188px;
  position:relative;
}
*/

.filledItem {
  /*
  position:absolute;
  left:0;
  top:0;
  */
  width:auto;
  height:auto;
}

.bottomContainer {
  flex: auto;
  flex-wrap: wrap;
  flex-direction: row;
  height:auto;
}

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'
import TymCommon from '@/js/tymcommon.js'
import TymAws from '@/js/tymaws.js'
import TymConst from '@/js/tymconstants.js'
import { useI18n } from 'vue-i18n'
// import { useQuasar } from 'quasar'

export default ({
  props : {
    info : Object
  },
  data() {
    return {
    }
  },

  setup() {
    // const i18n=useI18n()

    return {
      itemValues : ref([]),
      machineInfo : ref(null),
      machineState : ref(null),

      viewOptions: ref({})
    }

  },
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    getIcon() {
      return (item) => {
        if(item.Index == TymConst.VIEWOPTIONS.Direction) {
          const i18n = useI18n()
          if(item.value == i18n.t('machineStatus.Forward')) {
            return item.IconF
          } else if(item.value == i18n.t('machineStatus.Neutral')) {
            return item.IconN
          } else if(item.value == i18n.t('machineStatus.Reverse')) {
            return item.IconR
          }
        } else {
          if(item.isAbnormal) {
            if(!TymCommon.isEmpty(item.IconAbnormal)) {
              return item.IconAbnormal
            }
          } else {
            if(!TymCommon.isEmpty(item.IconNormal)) {
              return item.IconNormal
            }
          }
        }
        return item.Icon;
      };
    }
  },
  created() {},
  mounted() {
    this.itemValues = []

    for(let idx = 0; idx < TymConst.VIEWOPTIONS.Count; idx++) {
      this.itemValues.push({
      })
    }
    this.itemValues[TymConst.VIEWOPTIONS.Ignition] = {
      // 시동 상태
      Index : TymConst.VIEWOPTIONS.Ignition,
      visible : false,
      Icon: require('@/assets/manage/status_ignition_t.svg'),
      IconAbnormal: require('@/assets/manage/status_ignition_c_on.png'),
      IconNormal: require('@/assets/manage/status_ignition_c_off.png'),
      Width: 98,
      Height: 98,
      Title: this.$t('machineStatus.Ignition'),

      isAbnormal : false,
      value: 'OFF'
    }
    this.itemValues[TymConst.VIEWOPTIONS.Fuel] = {
      // 연료량
      Index : TymConst.VIEWOPTIONS.Fuel,
      visible : false,
      Icon: require('@/assets/manage/status_fuel_t.svg'),
      IconAbnormal: require('@/assets/manage/status_fuel_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.Fuel'),

      isAbnormal : false,
      value: ''
    }
    this.itemValues[TymConst.VIEWOPTIONS.Battery] = {
      // 배터리 전압
      Index : TymConst.VIEWOPTIONS.Battery,
      visible : false,
      Icon: require('@/assets/manage/status_battery_t.svg'),
      IconAbnormal: require('@/assets/manage/status_battery_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.Battery'),

      isAbnormal : false,
      value: '0 V'
    }
    this.itemValues[TymConst.VIEWOPTIONS.Coolant] = {
      // 냉각수 온도
      Index : TymConst.VIEWOPTIONS.Coolant,
      visible : false,
      Icon: require('@/assets/manage/status_cooling_c.svg'),
      IconAbnormal: require('@/assets/manage/status_cooling_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.Coolant'),

      isAbnormal : false,
      value: '0 ℃'
    }
    this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake] = {
      // 주차 브레이크
      Index : TymConst.VIEWOPTIONS.ParkingBrake,
      visible : false,
      Icon: require('@/assets/manage/status_parking_c.svg'),
      IconAbnormal: require('@/assets/manage/status_parking_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.ParkingBrake'),

      isAbnormal : false,
      value: 'OFF'
    }
    this.itemValues[TymConst.VIEWOPTIONS.Direction] = {
      // 주행 방향
      Index : TymConst.VIEWOPTIONS.Direction,
      visible : false,
      Icon: require('@/assets/manage/status_neutral_c.svg'),
      IconF: require('@/assets/manage/status_front_c.svg'),
      IconN: require('@/assets/manage/status_neutral_c.svg'),
      IconR: require('@/assets/manage/status_rear_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.Direction'),

      isAbnormal : false,
      value: '-'
    }
    this.itemValues[TymConst.VIEWOPTIONS.PTO] = {
      // PTO
      Index : TymConst.VIEWOPTIONS.PTO,
      visible : false,
      Icon: require('@/assets/manage/status_pto_c.svg'),
      IconAbnormal: require('@/assets/manage/status_pto_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.PTO'),

      isAbnormal : false,
      value: 'OFF'
    }
    this.itemValues[TymConst.VIEWOPTIONS.AirCleaner] = {
      // 에어클리너
      Index : TymConst.VIEWOPTIONS.AirCleaner,
      visible : false,
      Icon: require('@/assets/manage/status_aircleaner_c.svg'),
      IconAbnormal: require('@/assets/manage/status_aircleaner_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.AirCleaner'),

      isAbnormal : false,
      value: 'OFF'
    }
    this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp] = {
      // 변속기 유온
      Index : TymConst.VIEWOPTIONS.TMOilFilterTemp,
      visible : false,
      Icon: require('@/assets/manage/status_temperature_c.svg'),
      IconAbnormal: require('@/assets/manage/status_temperature_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.TMOilFilterTemp'),

      isAbnormal : false,
      value: ''
    }
    this.itemValues[TymConst.VIEWOPTIONS.Urea] = {
      // 요소수량
      Index : TymConst.VIEWOPTIONS.Urea,
      visible : false,
      Icon: require('@/assets/manage/status_urea_t.svg'),
      IconAbnormal: require('@/assets/manage/status_urea_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.Urea'),

      isAbnormal : false,
      value: '0 %'
    }
    this.itemValues[TymConst.VIEWOPTIONS.FourWD] = {
      // 4WD
      Index : TymConst.VIEWOPTIONS.FourWD,
      visible : false,
      Icon: require('@/assets/manage/status_4wd_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.FourWD'),

      isAbnormal : false,
      value: 'OFF'
    }
    this.itemValues[TymConst.VIEWOPTIONS.HorizMode] = {
      // 수평
      Index : TymConst.VIEWOPTIONS.HorizMode,
      visible : false,
      Icon: require('@/assets/manage/status_leveling_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.HorizMode'),

      isAbnormal : false,
      value: 'OFF'
    }
    this.itemValues[TymConst.VIEWOPTIONS.DepthMode] = {
      // 경심
      Index : TymConst.VIEWOPTIONS.DepthMode,
      visible : false,
      Icon: require('@/assets/manage/status_metacenter_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.DepthMode'),

      isAbnormal : false,
      value: 'OFF'
    }
    this.itemValues[TymConst.VIEWOPTIONS.DraftMode] = {
      // 견인력
      Index : TymConst.VIEWOPTIONS.DraftMode,
      visible : false,
      Icon: require('@/assets/manage/status_power_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.DraftMode'),

      isAbnormal : false,
      value: 'OFF'
    }
    this.itemValues[TymConst.VIEWOPTIONS.UsingTime] = {
      // 사용시간
      Index : TymConst.VIEWOPTIONS.UsingTime,
      visible : false,
      Icon: require('@/assets/manage/status_time_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.UsingTime'),

      isAbnormal : false,
      value: ''
    }
    this.itemValues[TymConst.VIEWOPTIONS.EngineLoad] = {
      // 엔진 부하량
      Index : TymConst.VIEWOPTIONS.EngineLoad,
      visible : false,
      Icon: require('@/assets/manage/status_engineload_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.EngineLoad'),

      isAbnormal : false,
      value: '0 %'
    }
    this.itemValues[TymConst.VIEWOPTIONS.EngineRPM] = {
      // 엔진 회전수
      Index : TymConst.VIEWOPTIONS.EngineRPM,
      visible : false,
      Icon: require('@/assets/manage/status_enginespeed_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.EngineRPM'),

      isAbnormal : false,
      value: '0 RPM'
    }
    this.itemValues[TymConst.VIEWOPTIONS.EngineOil] = {
      // 엔진 오일 압력
      Index : TymConst.VIEWOPTIONS.EngineOil,
      visible : false,
      Icon: require('@/assets/manage/status_engineoilpressure_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.EngineOil'),

      isAbnormal : false,
      value: '0 KPa'
    }
    this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp] = {
      // 주변공기 온도
      Index : TymConst.VIEWOPTIONS.AmbientAirTemp,
      visible : false,
      Icon: require('@/assets/manage/status_airtemperature_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.AmbientAirTemp'),

      isAbnormal : false,
      value: '0 ℃'
    }
    this.itemValues[TymConst.VIEWOPTIONS.DPFRegen] = {
      // DPF 요구단계
      Index : TymConst.VIEWOPTIONS.DPFRegen,
      visible : false,
      Icon: require('@/assets/manage/status_dpfstatus_c.svg'),
      IconAbnormal: require('@/assets/manage/status_dpf_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.DPFRegen'),

      isAbnormal : false,
      value: this.$t('machineStatus.Normal')
    }
    this.itemValues[TymConst.VIEWOPTIONS.DPFSoot] = {
      // DPF 부하수준
      Index : TymConst.VIEWOPTIONS.DPFSoot,
      visible : false,
      Icon: require('@/assets/manage/status_loadlevel_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.DPFSoot'),

      isAbnormal : false,
      value: '0 %'
    }
    this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater] = {
      // 연료필터 수분 유입
      Index : TymConst.VIEWOPTIONS.FuelFilterWater,
      visible : false,
      Icon: require('@/assets/manage/status_moistureinflow_c.svg'),
      IconAbnormal: require('@/assets/manage/status_moistureinflow_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.FuelFilterWater'),

      isAbnormal : false,
      value: this.$t('machineStatus.Normal')
    }
    this.itemValues[TymConst.VIEWOPTIONS.Inducement] = {
      // 후처리 장치 단계
      Index : TymConst.VIEWOPTIONS.Inducement,
      visible : false,
      Icon: require('@/assets/manage/status_postprocessing_c.svg'),
      IconAbnormal: require('@/assets/manage/status_postprocessing_r.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.Inducement'),

      isAbnormal : false,
      value: this.$t('machineStatus.Normal')
    }
    this.itemValues[TymConst.VIEWOPTIONS.RecentAt] = {
      // 최근 사용시간
      Index : TymConst.VIEWOPTIONS.RecentAt,
      visible : false,
      Icon: require('@/assets/manage/status_running_t.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.RecentAt'),

      isAbnormal : false,
      value: ''
    }
    this.itemValues[TymConst.VIEWOPTIONS.KeyOn] = {
      // 키 상태
      Index : TymConst.VIEWOPTIONS.KeyOn,
      visible : false,
      Icon: require('@/assets/manage/status_key_c.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.KeyOn'),

      isAbnormal : false,
      value: 'OFF'
    }
    this.itemValues[TymConst.VIEWOPTIONS.ADS] = {
      // 자율주행 레벨
      Index : TymConst.VIEWOPTIONS.ADS,
      visible : false,
      Icon: require('@/assets/manage/status_autonomous_t.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.ADS'),

      isAbnormal : false,
      value: 'LV 1'
    }
    this.itemValues[TymConst.VIEWOPTIONS.Telematics] = {
      // 텔레매틱스 펌웨어 버전
      Index : TymConst.VIEWOPTIONS.Telematics,
      visible : false,
      Icon: require('@/assets/manage/status_firmware_t.svg'),
      Width: 86,
      Height: 86,
      Title: this.$t('machineStatus.Telematics'),

      isAbnormal : false,
      value: ''
    }

    setTimeout(() => {
      this.updateData(this.info)
    }, 1)
  }, 
  unmounted() {
  },
  methods : {
    updateData(obj) {
      let idx = 0

      if(TymCommon.isEmpty(obj)) {
        console.log('---- EMPTY PARAMETER ---')
        return
      }
      const store = useTymictStore()
      let machineInSales = store.SalesData.data.find(x => x.machineNo == obj.machineNo)

      let foundModel = store.ModelData.data.find(x => {
        let full = x.model
        if(!TymCommon.isEmpty(x.option)) {
          full = full + '_' + x.option
        }
        full = TymCommon.getModelFullname(full)
        return full.Fullname == obj.modelNo
      })

      let hasOption = false
      this.viewOptions = {}
      if(foundModel) {
        if(!TymCommon.isEmpty(foundModel.viewOptions)) {
          this.viewOptions = foundModel.viewOptions
          hasOption = true
        }
      } else {
        console.log('---- NO MODELINFO ---', obj.modelNo)
        return
      }

      let machType = -1
      if(!hasOption) {
        store.WebConfig.MachineTypes.forEach(langIdx => {
          if(machType < 0) {
            let fndIdx = langIdx.findIndex(x => x == foundModel.type)
            if(fndIdx >= 0) {
              machType = fndIdx
            }
          }
        })

        if(machType < 0) {
          console.log('---- NO MACHINE TYPE ---', foundModel.type)
          return
        }

        if(TymConst.IS_DEVELOPMENT) {
          console.log('+-----------------------------------------')
          console.log('| SALEINFO :', machineInSales)
          console.log('| MODEL :', foundModel)
          console.log('| this.viewOptions :', JSON.parse(JSON.stringify(this.viewOptions)))
          console.log('| machType :', machType)
          console.log('+-----------------------------------------')
        }

        switch(machType) {
          case 1: // 트랙터
            this.itemValues[TymConst.VIEWOPTIONS.Ignition].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.KeyOn].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Battery].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Fuel].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Coolant].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Direction].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.PTO].visible = true
            if(machineInSales.MON.indexOf('TS130') == 0) {
              this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].visible = true
              this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].visible = true
            } else {
              this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].visible = false
              this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].visible = false
            }

            if(machineInSales.MON.indexOf('TX76') == 0
              || machineInSales.MON.indexOf('T574') == 0 || machineInSales.MON.indexOf('T25') == 0
              || machineInSales.MON.indexOf('2515') == 0 || machineInSales.MON.indexOf('2610') == 0 || machineInSales.MON.indexOf('5520') == 0
              || machineInSales.MON.indexOf('T224') == 0 || machineInSales.MON.indexOf('T254') == 0 || machineInSales.MON.indexOf('T394') == 0 || machineInSales.MON.indexOf('T554') == 0
              || machineInSales.MON.indexOf('T4058P') == 0
            ) {
              this.itemValues[TymConst.VIEWOPTIONS.Urea].visible = false
            } else {
              this.itemValues[TymConst.VIEWOPTIONS.Urea].visible = true
            }
            this.itemValues[TymConst.VIEWOPTIONS.FourWD].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.HorizMode].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.DepthMode].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.DraftMode].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.UsingTime].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.EngineLoad].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.EngineRPM].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.EngineOil].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.Inducement].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.RecentAt].visible = true
            //this.itemValues[TymConst.VIEWOPTIONS.ADS].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Telematics].visible = true
            break
          case 2: // 이앙기
            this.itemValues[TymConst.VIEWOPTIONS.Ignition].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.KeyOn].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.Battery].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Fuel].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Coolant].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Direction].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.PTO].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Urea].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.FourWD].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.HorizMode].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.DepthMode].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.DraftMode].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.UsingTime].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.EngineLoad].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.EngineRPM].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.EngineOil].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.Inducement].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.UsingTime].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.RecentAt].visible = true
            //this.itemValues[TymConst.VIEWOPTIONS.ADS].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Telematics].visible = true
            break
          case 3: // 콤바인
            this.itemValues[TymConst.VIEWOPTIONS.Ignition].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.KeyOn].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Battery].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Fuel].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.Coolant].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.Direction].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.PTO].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.Urea].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.FourWD].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.HorizMode].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.DepthMode].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.DraftMode].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.UsingTime].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.EngineLoad].visible = true``
            this.itemValues[TymConst.VIEWOPTIONS.EngineRPM].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.EngineOil].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Inducement].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.UsingTime].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.RecentAt].visible = false
            //this.itemValues[TymConst.VIEWOPTIONS.ADS].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Telematics].visible = false
            break
          case 4: // AFTERMARKET
            this.itemValues[TymConst.VIEWOPTIONS.Ignition].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.KeyOn].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Battery].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Fuel].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Coolant].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Direction].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.PTO].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Urea].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.FourWD].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.HorizMode].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.DepthMode].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.DraftMode].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.UsingTime].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.EngineLoad].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.EngineRPM].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.EngineOil].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.Inducement].visible = false
            this.itemValues[TymConst.VIEWOPTIONS.UsingTime].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.RecentAt].visible = true
            //this.itemValues[TymConst.VIEWOPTIONS.ADS].visible = true
            this.itemValues[TymConst.VIEWOPTIONS.Telematics].visible = true
            break
        }
      } else {
        this.itemValues[TymConst.VIEWOPTIONS.Ignition].visible = this.viewOptions.Ignition
        this.itemValues[TymConst.VIEWOPTIONS.KeyOn].visible = this.viewOptions.KeyOn
        this.itemValues[TymConst.VIEWOPTIONS.Battery].visible = this.viewOptions.Battery
        this.itemValues[TymConst.VIEWOPTIONS.Fuel].visible = this.viewOptions.Fuel
        this.itemValues[TymConst.VIEWOPTIONS.Coolant].visible = this.viewOptions.Coolant
        this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].visible = this.viewOptions.ParkingBrake
        this.itemValues[TymConst.VIEWOPTIONS.Direction].visible = this.viewOptions.Direction
        this.itemValues[TymConst.VIEWOPTIONS.PTO].visible = this.viewOptions.PTO
        this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].visible = this.viewOptions.AirCleaner
        this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].visible = this.viewOptions.TMOilFilterTemp
        this.itemValues[TymConst.VIEWOPTIONS.Urea].visible = this.viewOptions.Urea
        this.itemValues[TymConst.VIEWOPTIONS.FourWD].visible = this.viewOptions.FourWD
        this.itemValues[TymConst.VIEWOPTIONS.HorizMode].visible = this.viewOptions.HorizMode
        this.itemValues[TymConst.VIEWOPTIONS.DepthMode].visible = this.viewOptions.DepthMode
        this.itemValues[TymConst.VIEWOPTIONS.DraftMode].visible = this.viewOptions.DraftMode
        this.itemValues[TymConst.VIEWOPTIONS.UsingTime].visible = this.viewOptions.UsingTime
        this.itemValues[TymConst.VIEWOPTIONS.EngineLoad].visible = this.viewOptions.EngineLoad
        this.itemValues[TymConst.VIEWOPTIONS.EngineRPM].visible = this.viewOptions.EngineRPM
        this.itemValues[TymConst.VIEWOPTIONS.EngineOil].visible = this.viewOptions.EngineOil
        this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp].visible = this.viewOptions.AmbientAirTemp
        this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].visible = this.viewOptions.DPFRegen
        this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].visible = this.viewOptions.DPFSoot
        this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater].visible = this.viewOptions.FuelFilterWater
        this.itemValues[TymConst.VIEWOPTIONS.Inducement].visible = this.viewOptions.Inducement
        this.itemValues[TymConst.VIEWOPTIONS.RecentAt].visible = this.viewOptions.RecentAt
        this.itemValues[TymConst.VIEWOPTIONS.ADS].visible = this.viewOptions.ADS
        this.itemValues[TymConst.VIEWOPTIONS.Telematics].visible = this.viewOptions.Telematics
      }

      let lastChar = machineInSales.machineNo.charAt(machineInSales.machineNo.length - 1)
      this.viewOptions.ADS = (!TymCommon.isEmpty(this.viewOptions.ADS) ? this.viewOptions.ADS : true) && (lastChar == 'A')
      this.itemValues[TymConst.VIEWOPTIONS.ADS].visible = this.viewOptions.ADS

      if(TymConst.IS_DEVELOPMENT) {
        console.log('---->', JSON.parse(JSON.stringify(this.itemValues)))
      }
      /*
      if(TymConst.IS_DEVELOPMENT) {
        this.itemValues.forEach(item => {
          item.visible = false
        })

        this.itemValues[TymConst.VIEWOPTIONS.Ignition].visible = true
        this.itemValues[TymConst.VIEWOPTIONS.KeyOn].visible = true
        this.itemValues[TymConst.VIEWOPTIONS.Battery].visible = true
      }
      */
      /*
      if(machineInSales.MON.indexOf('TX76') == 0) {
        this.viewOptions.Urea = false
      } else if(machineInSales.MON.indexOf('RGO') == 0) {
        this.viewOptions.Urea = false
      } else {
        this.viewOptions.Urea = true
      }
      */

      this.machineInfo = obj
      if(!TymCommon.isEmpty(this.info)) {
        TymAws.getStartedMachinesBySN(this.info.serialNo)
          .then(data => {
            let power = 'OFF'
            let state = null
            if(Array.isArray(data)) {
              state = data[0]
            } else {
              state = data
            }
            state = state.Items[0]

            if(TymConst.IS_DEVELOPMENT) {
              console.log('MACHINE STATE :', this.info.serialNo, state)
            }

            if(!TymCommon.isEmpty(state)) {
              if(!TymCommon.isEmpty(state.IGNITION)) {
                let ignSplit = state.IGNITION.split("^");
                if(!TymCommon.isEmpty(ignSplit[0]) && ignSplit[0] == '1') {
                  power = 'ON'
                }
              }

              if(this.itemValues[TymConst.VIEWOPTIONS.KeyOn].visible) {
                if(!TymCommon.isEmpty(state.KEY_ON)) {
                  let parsed = state.KEY_ON.split("^")
                  let key = 'OFF'
                  if(parsed[0] == '1') {
                    key = 'ON'
                  }
                  this.itemValues[TymConst.VIEWOPTIONS.KeyOn].isAbnormal = key == 'ON'
                  this.itemValues[TymConst.VIEWOPTIONS.KeyOn].value = key
                }
              }
            }
            this.updateIgnition(power)
          })
          .catch(er => {
            console.log('==> ', er)
            this.updateIgnition('OFF')
          })
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('tabStatus.updateData :', this.info, this.machineInfo, this.machineState)
      }
      idx = 0
      if(obj && this.machineInfo) {
        // Ignition Status
        this.itemValues[idx].isAbnormal = false
        if(TymConst.IS_DEVELOPMENT) {
          console.log('PWR : ', this.machineInfo.PWR)
        }
        switch(this.machineInfo.PWR) {
          case 'ON':
            this.itemValues[idx].value = 'ON'
            break;
          case 'OFF':
          default:
            this.itemValues[idx].value = 'OFF'
            break;
        }
        idx++

        // Battery Voltage
        /*
        try {
          if(!TymCommon.isEmpty(this.machineInfo.historyLast) && !TymCommon.isEmpty(this.machineInfo.historyLast.BATT)) {
            let conValue = parseFloat(this.machineInfo.historyLast.BATT)
            if(!isNaN(conValue)) {
              // this.itemValues[idx].isAbnormal = conValue <= 11.5
              this.itemValues[TymConst.VIEWOPTIONS.Battery].value = conValue + " V"
            }
          }
        } catch(er) {
          console.log(er, this.machineInfo.historyLast)
        }
        */
        let self = this
        if(this.itemValues[TymConst.VIEWOPTIONS.Battery].visible) {
          // 배터리 상태 가져오자
          TymAws.getVutByMN(this.machineInfo.machineNo)
            .then(data => {
              if(TymConst.IS_DEVELOPMENT) {
                console.log('MachineStatusTab.updateData.getVutByMN :', this.machineInfo.machineNo, data)
              }
              if(!TymCommon.isEmpty(data.L_BATT)) {
                /*
                L_BATT :  2^20231214:154405.522
                0 : 저전압 아닌 정상 상태
                1 : 저전압 상태로 전환
                2 : 저전압 상태가 유지되고 있음
                */
                let battState = data.L_BATT.split('^')
                self.itemValues[TymConst.VIEWOPTIONS.Battery].isAbnormal = (battState.length > 0)
              }
            })
            .catch(er => {
              console.log('TymAws.getVutByMN ERROR :', er)
            })
          }

        if(this.itemValues[TymConst.VIEWOPTIONS.UsingTime].visible) {
          // 사용시간, Engine hours
          this.itemValues[TymConst.VIEWOPTIONS.UsingTime].isAbnormal = false
          // console.log(idx, this.machineInfo)
          if(!TymCommon.isEmpty(this.machineInfo.historyLast)) {
            if(TymCommon.isEmpty(this.machineInfo.historyLast.V_HOUR))
              this.itemValues[TymConst.VIEWOPTIONS.UsingTime].value = ''
            else {
              let value = parseFloat(this.machineInfo.historyLast.V_HOUR)
              if(!isNaN(value)) {
                value = value.toFixed(2)
                this.itemValues[TymConst.VIEWOPTIONS.UsingTime].value = value + ' h'
              }
            }
          }
        }

        if(this.itemValues[TymConst.VIEWOPTIONS.RecentAt].visible) {
          // 최근사용시간, Last Running TIme
          let lastWorking = ''
          if(!TymCommon.isEmpty(this.machineInfo.historyLast) && !TymCommon.isEmpty(this.machineInfo.historyLast.OffTime)) {
            lastWorking = this.machineInfo.historyLast.OffTime
          }
          lastWorking = TymCommon.convertStringToDateTime(lastWorking)
          this.itemValues[TymConst.VIEWOPTIONS.RecentAt].isAbnormal = false
          if(TymCommon.isEmpty(lastWorking))
            this.itemValues[TymConst.VIEWOPTIONS.RecentAt].value = ''
          else
            this.itemValues[TymConst.VIEWOPTIONS.RecentAt].value = lastWorking
          if(TymConst.IS_DEVELOPMENT) {
            console.log('최근 사용시간:', this.itemValues[TymConst.VIEWOPTIONS.RecentAt].value)
          }
        }

        // 연료량
        if(this.itemValues[TymConst.VIEWOPTIONS.Fuel].visible) {
          try {
            if(!TymCommon.isEmpty(this.machineInfo.historyLast) && !TymCommon.isEmpty(this.machineInfo.historyLast.LV_FUEL)) {
              let conValue = parseFloat(this.machineInfo.historyLast.LV_FUEL)
              if(!isNaN(conValue)) {
                conValue = conValue.toFixed(0)
                this.itemValues[TymConst.VIEWOPTIONS.Fuel].isAbnormal = conValue <= 15
                this.itemValues[TymConst.VIEWOPTIONS.Fuel].value = conValue + ' %'
              }
              if(TymConst.IS_DEVELOPMENT) {
                console.log('연료량:', this.itemValues[TymConst.VIEWOPTIONS.Fuel].value)
              }
            }
          } catch(er) {
            console.log(er)
          }
        }

        // 요소수량
        if(this.itemValues[TymConst.VIEWOPTIONS.Urea].visible) {
          try {
            if(!TymCommon.isEmpty(this.machineInfo.historyLast) && !TymCommon.isEmpty(this.machineInfo.historyLast.UR_LV)) {
              let conValue = parseFloat(this.machineInfo.historyLast.UR_LV)
              if(!isNaN(conValue)) {
                conValue = conValue.toFixed(0)
                this.itemValues[TymConst.VIEWOPTIONS.Urea].isAbnormal = conValue <= 25
                this.itemValues[TymConst.VIEWOPTIONS.Urea].value = conValue + ' %'
              }
            }
            if(TymConst.IS_DEVELOPMENT) {
              console.log('요소수량:', this.itemValues[TymConst.VIEWOPTIONS.Urea].value)
            }
          } catch(er) {
            console.log(er)
          }
        }

        // Autonomouse Driving
        this.itemValues[TymConst.VIEWOPTIONS.ADS].isAbnormal = false
        // this.itemValues[TymConst.VIEWOPTIONS.ADS].value = ''

        // Telematics version
        if(this.itemValues[TymConst.VIEWOPTIONS.Telematics].visible && TymCommon.isEmpty(this.itemValues[TymConst.VIEWOPTIONS.Telematics].value)) {
          try {
            this.itemValues[TymConst.VIEWOPTIONS.Telematics].isAbnormal = false
            if(!TymCommon.isEmpty(this.machineInfo.historyLast) && !TymCommon.isEmpty(this.machineInfo.historyLast.TVER))
              this.itemValues[TymConst.VIEWOPTIONS.Telematics].value = 'V ' + this.machineInfo.historyLast.TVER
            else
              this.itemValues[TymConst.VIEWOPTIONS.Telematics].value = ''

            if(TymConst.IS_DEVELOPMENT) {
              console.log('텔레매틱스 펌웨어(M) 2:', this.itemValues[TymConst.VIEWOPTIONS.Telematics].value)
            }
          } catch (er)   {
            console.log(er)
          }
        }
      } else {
        console.log('no machine info ', obj, this.machineInfo)
      }

      setTimeout(() => {
        if(!TymCommon.isEmpty(this.info) && !TymCommon.isEmpty(this.info.machineNo)) {
          this.getLastDataBySN(this.info.serialNo)
            .then(data => {
              if(TymConst.IS_DEVELOPMENT) {
                console.log('=============================================================')
                console.log('tabStatus.getMachineInfoSN', this.info, data)
              }

              this.machineState = data
              setTimeout(() => {
                this.updateBattery(this.machineState)
              }, 1)

              setTimeout(() => {
                this.updateEventHistory(this.info.machineNo, data.Battery, data.History)
              }, 1)

              if(!TymCommon.isEmpty(data.History) && !TymCommon.isEmpty(data.History.HData)) {
                // 냉각수 온도
                if(this.itemValues[TymConst.VIEWOPTIONS.Coolant].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.ET1_1)) {
                    let value = parseFloat(data.History.HData.ET1_1)
                    if(!isNaN(value)) {
                      value = value.toFixed(0)
                      this.itemValues[TymConst.VIEWOPTIONS.Coolant].isAbnormal = value >= 110
                      this.itemValues[TymConst.VIEWOPTIONS.Coolant].value = value + ' ℃'
                    }
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('냉각수 온도 :', this.itemValues[TymConst.VIEWOPTIONS.Coolant].value)
                    }
                  }
                }

                // PTO
                if(this.itemValues[TymConst.VIEWOPTIONS.PTO].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.PTO_LAMP)) {
                    this.itemValues[TymConst.VIEWOPTIONS.PTO].isAbnormal = data.History.HData.PTO_LAMP == '1'
                    this.itemValues[TymConst.VIEWOPTIONS.PTO].value = data.History.HData.PTO_LAMP == '1' ? 'ON' : 'OFF'
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('PTO :', this.itemValues[TymConst.VIEWOPTIONS.PTO].value)
                    }
                  }
                }
                
                // 에어클리너
                if(this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.WAR_AIR)) {
                    this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].isAbnormal = data.History.HData.WAR_AIR == '1'
                    this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].value = data.History.HData.WAR_AIR == '1' ? this.$t('machineStatus.Block') : this.$t('machineStatus.Normal')
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('에어클리너 :', this.itemValues[TymConst.VIEWOPTIONS.AirCleaner].value)
                    }
                  }
                }

                // 변속기 유온
                if(this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.TM_TMP)) {
                    let value = parseFloat(data.History.HData.TM_TMP)
                    if(!isNaN(value)) {
                      value = value.toFixed(0)
                      this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].isAbnormal = value <= 0
                      this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].value = value + ' ℃'
                    }
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('변속기 유온 :', this.itemValues[TymConst.VIEWOPTIONS.TMOilFilterTemp].value)
                    }
                  }
                }

                // 4WD
                if(this.itemValues[TymConst.VIEWOPTIONS.FourWD].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.TCUIP1_2)) {
                    this.itemValues[TymConst.VIEWOPTIONS.FourWD].isAbnormal = false // data.History.HData.TCUIP1_2 == '1'
                    this.itemValues[TymConst.VIEWOPTIONS.FourWD].value = data.History.HData.TCUIP1_2 == '1' ? this.$t('machineStatus.Active') : this.$t('machineStatus.Inactive')
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('4WD :', this.itemValues[TymConst.VIEWOPTIONS.FourWD].value)
                    }
                  }
                }

                // 수평
                if(this.itemValues[TymConst.VIEWOPTIONS.HorizMode].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.LEVEL_CTL)) {
                    this.itemValues[TymConst.VIEWOPTIONS.HorizMode].isAbnormal = false; // data.History.HData.TCUIP1_2 == '1'
                    this.itemValues[TymConst.VIEWOPTIONS.HorizMode].value = data.History.HData.LEVEL_CTL == '1' ? this.$t('machineStatus.Active') : this.$t('machineStatus.Inactive')
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('수평 :', this.itemValues[TymConst.VIEWOPTIONS.HorizMode].value)
                    }
                  }
                }

                // 경심
                if(this.itemValues[TymConst.VIEWOPTIONS.DepthMode].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.DEPTH_MODE)) {
                    this.itemValues[TymConst.VIEWOPTIONS.DepthMode].isAbnormal = false
                    this.itemValues[TymConst.VIEWOPTIONS.DepthMode].value = data.History.HData.DEPTH_MODE == '1' ? this.$t('machineStatus.Active') : this.$t('machineStatus.Inactive')
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('경심 :', this.itemValues[TymConst.VIEWOPTIONS.DepthMode].value)
                    }
                  }
                }

                // 견인력
                if(this.itemValues[TymConst.VIEWOPTIONS.DraftMode].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.TOW)) {
                    this.itemValues[TymConst.VIEWOPTIONS.DraftMode].isAbnormal = false
                    this.itemValues[TymConst.VIEWOPTIONS.DraftMode].value = data.History.HData.TOW == '1' ? this.$t('machineStatus.Active') : this.$t('machineStatus.Inactive')
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('견인력 :', this.itemValues[TymConst.VIEWOPTIONS.DraftMode].value)
                    }
                  }
                }

                // 엔진부하량
                if(this.itemValues[TymConst.VIEWOPTIONS.EngineLoad].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.EEC1_1)) {
                    let value = parseFloat(data.History.HData.EEC1_1)
                    if(!isNaN(value)) {
                      value = value.toFixed(0)
                      this.itemValues[TymConst.VIEWOPTIONS.EngineLoad].isAbnormal = false
                      this.itemValues[TymConst.VIEWOPTIONS.EngineLoad].value = value + ' %'
                    }
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('엔진부하량 :', this.itemValues[TymConst.VIEWOPTIONS.EngineLoad].value)
                    }
                  }
                }

                // 엔진회전수
                if(this.itemValues[TymConst.VIEWOPTIONS.EngineRPM].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.E_RPM)) {
                    let value = parseFloat(data.History.HData.E_RPM)
                    if(!isNaN(value)) {
                      value = value.toFixed(0)
                      this.itemValues[TymConst.VIEWOPTIONS.EngineRPM].isAbnormal = false
                      this.itemValues[TymConst.VIEWOPTIONS.EngineRPM].value = value + ' RPM'
                    }
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('엔진회전수 :', this.itemValues[TymConst.VIEWOPTIONS.EngineRPM].value)
                    }
                  }
                }

                // 엔진오일 압력
                if(this.itemValues[TymConst.VIEWOPTIONS.EngineOil].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.EFLP1_2)) {
                    let value = parseFloat(data.History.HData.EFLP1_2)
                    if(!isNaN(value)) {
                      value = value.toFixed(0)
                      this.itemValues[TymConst.VIEWOPTIONS.EngineOil].isAbnormal = false
                      this.itemValues[TymConst.VIEWOPTIONS.EngineOil].value = value + ' KPa'
                    }
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('엔진오일 압력 :', this.itemValues[TymConst.VIEWOPTIONS.EngineOil].value)
                    }
                  }
                }

                // 주변공기온도
                if(this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp].visible) {
                  if(!TymCommon.isEmpty(data.History.HData.AMB_2)) {
                    let value = parseFloat(data.History.HData.AMB_2)
                    if(!isNaN(value)) {
                      value = value.toFixed(0)
                      this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp].isAbnormal = false
                      this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp].value = value + ' ℃'
                    }
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('주변공기온도 :', this.itemValues[TymConst.VIEWOPTIONS.AmbientAirTemp].value)
                    }
                  }
                }
                
                // Telematics version
                if(this.itemValues[TymConst.VIEWOPTIONS.Telematics].visible && TymCommon.isEmpty(this.itemValues[TymConst.VIEWOPTIONS.Telematics].value)) {
                  this.itemValues[TymConst.VIEWOPTIONS.Telematics].isAbnormal = false
                  if(!TymCommon.isEmpty(data.History.HData.TVER)) {
                    this.itemValues[TymConst.VIEWOPTIONS.Telematics].value = 'V ' + data.History.HData.TVER
                  } else {
                    this.itemValues[TymConst.VIEWOPTIONS.Telematics].value = ''
                  }

                  if(TymConst.IS_DEVELOPMENT) {
                    console.log('텔레매틱스 펌웨어(H) :', this.itemValues[TymConst.VIEWOPTIONS.Telematics].value)
                  }
                }
              }

              if(!TymCommon.isEmpty(data.Battery)) {
                // DPF 요구단계
                if(this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].visible) {
                  if(!TymCommon.isEmpty(data.Battery.DPFC_STATUS)) {
                    let splitted = data.Battery.DPFC_STATUS.split('^')
                    try {
                      switch(splitted[0]) {
                        case '0':
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].isAbnormal = false
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].value = this.$t('machineStatus.DPFRegnNormal')
                          break
                        case '1':
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].isAbnormal = true
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].value = splitted[0] + '. ' + this.$t('machineStatus.DPFRegnLowest')
                          break
                        case '2':
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].isAbnormal = true
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].value = splitted[0] + '. ' + this.$t('machineStatus.DPFRegnModerate')
                          break
                        case '3':
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].isAbnormal = true
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].value = splitted[0] + '. ' + this.$t('machineStatus.DPFRegnHighest')
                          break
                        default:
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].isAbnormal = true
                          this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].value = splitted[0] + ' / Unknown'
                          break
                      }
                    } catch(ex) {
                      this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].isAbnormal = true
                      this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].value = '-'
                    }
                  }
                  if(TymConst.IS_DEVELOPMENT) {
                    console.log('DPF 요구단계 :', this.itemValues[TymConst.VIEWOPTIONS.DPFRegen].value)
                  }
                }

                // 연료필터 수분 유입
                if(this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater].visible) {
                  if(!TymCommon.isEmpty(data.Battery.WATER_FUEL_WAR)) {
                    let splitted = data.Battery.WATER_FUEL_WAR.split('^')
                    this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater].isAbnormal = splitted[0] == '1'
                    this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater].value = splitted[0] == '1' ? this.$t('machineStatus.WaterFuelWarning') : this.$t('Units.WaterFuelNormal')
                  }
                  if(TymConst.IS_DEVELOPMENT) {
                    console.log('연료필터 수분 유입 :', this.itemValues[TymConst.VIEWOPTIONS.FuelFilterWater].value)
                  }
                }

                // 후처리 장치 단계
                if(this.itemValues[TymConst.VIEWOPTIONS.Inducement].visible) {
                  if(!TymCommon.isEmpty(data.Battery.AFTER_WAR)) {
                    let splitted = data.Battery.AFTER_WAR.split('^')
                    try {
                      let temp = parseInt(splitted[0])
                      if(temp == 0) {
                        this.itemValues[TymConst.VIEWOPTIONS.Inducement].isAbnormal = false
                        this.itemValues[TymConst.VIEWOPTIONS.Inducement].value = this.$t('machineStatus.Normal')
                      } else if(temp >= 1 && temp <= 7) {
                        this.itemValues[TymConst.VIEWOPTIONS.Inducement].isAbnormal = true
                        this.itemValues[TymConst.VIEWOPTIONS.Inducement].value = splitted[0] + ' ' + this.$t('Units.Stage')
                      }
                    } catch(ex) {
                      this.itemValues[TymConst.VIEWOPTIONS.Inducement].isAbnormal = true
                      this.itemValues[TymConst.VIEWOPTIONS.Inducement].value = '-'
                    }
                    if(TymConst.IS_DEVELOPMENT) {
                      console.log('후처리 장치 단계 :', this.itemValues[TymConst.VIEWOPTIONS.Inducement].value)
                    }
                  }
                }
              }
            })
          
          TymAws.getMachineInfoByMN(this.info.machineNo)
            .then(data => {
              if(TymConst.IS_DEVELOPMENT) {
                console.log('tabStatus.getMachineInfoMN', this.info.machineNo, data)
              }
            })
            .catch(er => {
              console.log('machine Info FAIL :', er)
            })
            

          TymAws.getSNInfoBySN(this.info.serialNo)
            .then(data => {
              let body = JSON.parse(data.body)
              if(!TymCommon.isEmpty(body.Items)) {
                let item = Array.isArray(body.Items) ? body.Items[0] : body.Items
                let level = TymCommon.isEmpty(item.ADS_LV) ? 0 : Number(item.ADS_LV)
                if(TymConst.IS_DEVELOPMENT) {
                  console.log('tabStatus.updateData.자율주행레벨 :', this.info.serialNo, data, item, level)
                }

                // Autonomouse Driving
                this.itemValues[TymConst.VIEWOPTIONS.ADS].isAbnormal = false
                this.itemValues[TymConst.VIEWOPTIONS.ADS].value = level > 0 ? 'LV ' + level.toString(): '-'
              }
            })
            .catch(er => {
              console.log('getSNInfoBySN FAIL :', er)
            })
        }
      }, 1)

      /*
      if(TymConst.IS_DEVELOPMENT) {
        setTimeout(() => {
          this.itemValues.forEach(item => {
            item.visible = true
            if(!TymCommon.isEmpty(item.IconAbnormal)) {
              item.isAbnormal = true
            }
          })
        }, 2000)
      }
      */
    },
    updateBattery(data) {
      if(TymCommon.isEmpty(data))
        return
      if(TymConst.IS_DEVELOPMENT) {
        console.log('updateBattery :', data)
      }
      /* 
      data.Battery
      {
        "SHUTTLE": "0^20231120:164928.550",
        "IGNITION": "0^20231120:164931.954",
        "LTE_SQ": "-68,-93^20231120:173036.701",
        "KEY_ON": "0^20231120:164931.954",
        "SN": "T001223700260026",
        "BATT": "12.65^20231120:173036.701"
      }
      {
        "SHUTTLE": "0^20240722:200802.461",
        "ADS_SW": "1^20240722:200805.757",
        "SD": "3^20240722:200842.488",
        "TCU_VER": "23^20240722:195143.731",
        "IGNITION": "0^20240722:200808.542",
        "KEY_ON": "0^20240722:200808.542",
        "MTM_SHIFT": "2^20240722:200558.240",
        "BRAKE_P": "0^20240722:195143.731",
        "SN": "T101231200350035"
      }
      data.History
      {
        "SHUTTLE": "0",
        "UR_LV": "99.90",
        "VEP_1": "10.95",
        "TVER": "10C",
        "OnTime": "20240111:140855.186",
        "TMP_MDM": "21.0",
        "TMP_COA": "9.30",
        "PWR": "OFF",
        "SDTime": "20231123:155505.331",
        "LOCA": [
          {
            "Coord": "36.8709456833,127.4849912166",
            "TFID": "0",
            "HDOP": "0.7",
            "TTime": "20240111:143122",
            "State": "A,1,3,A"
          },
          {
            "Coord": "36.8709456333,127.48499145",
            "TFID": "0",
            "HDOP": "0.6",
            "TTime": "20240111:143128",
            "State": "A,1,3,A"
          }
        ],
        "TMP_MOA": "0",
        "DRD": "0",
        "TARE": "0",
        "TM_TMP": "10",
        "VUT": "141.30",
        "PTO_LAMP": "0",
        "PTOT": "0",
        "BRAKE_P": "1",
        "ET1_1": "60",
        "OffTime": "20240111:143424.217",
        "SN": "T001223701690169",
        "TTime": "20240111:143155",
        "TFEF": "0.96",
        "MN": "PFHT00141",
        "DRT": "0",
        "VUDT": "23",
        "CLIENTID": "T00122370169001F0027",
        "WAR_AIR": "ERROR",
        "PTOD": "0",
        "TMP_CoA": "ERROR",
        "BATT": "13.70",
        "V_HOUR": "141.0",
        "TMP_MoA": "ERROR",
        "DBV": "105",
        "ATime": "20240111:143314.316",
        "LV_FUEL": "48.21"
      }
      {
        "MN": "T115T00011",
        "A2": "History",
        "LTime": 1729454867,
        "PT": "TBOX",
        "DBV": "102",
        "HData": {
            "TOP_DN": "0",
            "LOCA": [
                {
                    "Coord": "41.2494888,-84.1293843",
                    "TFID": "0",
                    "HDOP": "0.7",
                    "TTime": "20240722:200739",
                    "State": "A,1,3,A"
                }
            ],
            "LIFT_DAM": "0",
            "CLUTCH": "1",
            "DRT": "0",
            "TVER": "10D",
            "EEC1_1": "21",
            "TMP_COA": "39.59",
            "EEC3_2": "125.5",
            "AUTO_4WD": "0",
            "EEC3_1": "11",
            "DIFF_LOCK": "0",
            "EEC3_4": "130",
            "EEC3_3": "25",
            "DOOR": "0",
            "EEC3_5": "206.60",
            "TMP_MOA": "0",
            "WSPEED": "0",
            "C_REGEN": "0",
            "AF_RUN": "0",
            "BONNET": "1",
            "TURN_UP": "0",
            "MT": "2",
            "VUDT": "7",
            "LIFT_DU": "0",
            "TFEA": "3.52",
            "TFID": "0",
            "LOCA_TR": [
                {
                    "Coord": "41.2494845833,-84.12937965",
                    "TTime": "20240722:200740"
                }
            ],
            "E_RPM": "1567.30",
            "D_CLUCH": "0",
            "VEP_1": "14.02",
            "V_HOUR": "10.65",
            "DPF_SOOT": "7",
            "TOP_UP": "0",
            "TFEF": "0.45",
            "QK_TURN": "0",
            "CREEP": "0",
            "UR_LV": "74.80",
            "T_REGEN": "46633",
            "LV_FUEL": "9.91",
            "LIFT_POS": "35.59",
            "BRAKE_LR": "0",
            "TARE": "0",
            "EEC2_1": "1",
            "EFLP1_2": "391",
            "EEC2_3": "40",
            "TMP_MDM": "45.0",
            "EFLP1_1": "425",
            "TOW_R": "0.61",
            "EEC2_2": "102.0",
            "LOW_SHIFT": "1",
            "PTOT": "0",
            "STM_SHIFT": "2",
            "TOW_L": "1.08",
            "EEC2_4": "102.0",
            "TCUIP1_2": "0",
            "SHUTTLE": "2",
            "IP_VER": "0.10",
            "FSPTCU_1": "48.23",
            "LTE_SQ": "-51,-82",
            "PTO_LAMP": "0",
            "D_BRAKE": "0",
            "BATT": "14.03",
            "DPF_ASH": "0",
            "BRAKE_P": "0",
            "VSPEED": "3.50",
            "ET1_2": "1774.96",
            "ET1_1": "72",
            "IC1_3": "0",
            "IC1_2": "40",
            "IC1_1": "82",
            "WAR_AIR": "0",
            "UR_SP": "0",
            "PTO_STOP": "1",
            "MTM_SHIFT": "2",
            "UR_TMP": "29",
            "DIS": "134.48",
            "HI_SHIFT": "0",
            "IC1_4": "265.0",
            "TM_TMP": "37",
            "DRA": "0",
            "PTOA": "0",
            "REVERSE_UP": "0",
            "VUT": "10.66",
            "DRD": "0",
            "APTO_LAMP": "0",
            "PTOD": "0",
            "AMB_2": "29.00",
            "S_PTO_SW": "0"
        },
        "Day": "20240722",
        "TTime": "20240722:200741",
        "SN": "T101231200350035",
        "ATime": "20240722:200747.590",
        "MODEL": "T115_SPUS",
        "HCount": "89"
      }
      */

      if(this.itemValues[TymConst.VIEWOPTIONS.Battery].visible) {
        let battVolt = NaN, histVolt = NaN
        let keyOn = false

        //console.log(':::: BATTERY Event ::::', data.Battery)
        //console.log(':::: BATTERY History ::::', data.History)
        
        if(!TymCommon.isEmpty(data.Battery)) {
          if(!TymCommon.isEmpty(data.Battery.KEY_ON)) {
            let keyState = data.Battery.KEY_ON.split('^')
            keyOn = (keyState[0] == '1')
          }
        }
        if(!TymCommon.isEmpty(data.History) && !TymCommon.isEmpty(data.History.HData)) {
          let value = null

          if(!TymCommon.isEmpty(data.History.HData.VEP_1)) {
            //console.log(':::: BATTERY H.VEP_1 ::::', data.History.HData.VEP_1)

            try {
              let vep = parseFloat(data.History.HData.VEP_1)
              if(!isNaN(vep) && vep != 0) {
                value = data.History.HData.VEP_1
              }
            } catch(ex) {
              console.log('exception :', data.History.HData.VEP_1, ex)
            }
          }
          if(TymCommon.isEmpty(value) && !TymCommon.isEmpty(data.History.HData.BATT)) {
            //console.log(':::: BATTERY H.BATT ::::', data.History.HData.BATT)
            value = data.History.HData.BATT
          }

          try {
            if(!isNaN(value))
              histVolt = parseFloat(value)
          } catch(ex) {
            console.log('exception :', ex)
          }
        }
        if(!TymCommon.isEmpty(data.Battery) && !TymCommon.isEmpty(data.Battery.BATT)) {
          try {
            //console.log('battery :', data.Battery.BATT)
            let battState = data.Battery.BATT.split('^')
            battVolt = parseFloat(battState[0])
          } catch(ex) {
            console.log('exception :', ex)
          }
        }
        let finalVolt = NaN
        if(keyOn) {
          finalVolt = histVolt
        } else {
          if(isNaN(battVolt))
            finalVolt = histVolt
          else
            finalVolt = battVolt
        }

        if(!isNaN(finalVolt)) {
          this.itemValues[TymConst.VIEWOPTIONS.Battery].value = finalVolt.toFixed(1) + " V"
        } else {
          //console.log('Key, hVolt, bVolt', keyOn, histVolt, battVolt)
          this.itemValues[TymConst.VIEWOPTIONS.Battery].value = ""
        }

        //console.log(':::: BATTERY ::::', keyOn, battVolt, histVolt, finalVolt, this.itemValues[TymConst.VIEWOPTIONS.Battery].value)
      }

      if(this.itemValues[TymConst.VIEWOPTIONS.Urea].visible) {
        // Urea Level
        const store = useTymictStore()
        let machineInSales = store.SalesData.data.find(x => x.machineNo == this.info.machineNo)
        if(!TymCommon.isEmpty(machineInSales)) {
          try {
            if(!TymCommon.isEmpty(this.machineInfo)) {
              if(!TymCommon.isEmpty(this.machineInfo.historyLast) && !TymCommon.isEmpty(this.machineInfo.historyLast.UR_LV)) {
                let conValue = parseFloat(this.machineInfo.historyLast.UR_LV)
                if(!isNaN(conValue)) {
                  this.itemValues[TymConst.VIEWOPTIONS.Urea].isAbnormal = conValue <= 15
                  this.itemValues[TymConst.VIEWOPTIONS.Urea].value = conValue.toFixed(0) + ' %'
                }
              }
            }
          } catch(er) {
            console.log(er)
          }
        }
      }
    },
    updateEventHistory(mn, event, history) {
      //const store = useTymictStore()
      //let saleInfo = store.SalesData.data.find(x => x.machineNo == mn)
      //let modelInfo = TymCommon.getModelFullname(saleInfo.MON)
      let procBrakeP = false
      let procShuttle = false
      let procDpfSoot = false

      if(TymConst.IS_DEVELOPMENT) {
        console.log('updateEventHistory :', mn, event, history)
      }

      if(!TymCommon.isEmpty(event)) {
        // 주차 브레이크
        if(this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].visible) {
          let brakeValue = null
          if(!TymCommon.isEmpty(event.BRAKE_P))
            brakeValue = event.BRAKE_P
          else if(!TymCommon.isEmpty(event.P_HAND))
            brakeValue = event.P_HAND

          if(!TymCommon.isEmpty(brakeValue)) {
            let splitted = brakeValue.split("^");
            this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].isAbnormal = splitted[0] == '1'
            this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].value = splitted[0] == '1' ? this.$t('machineStatus.BrakeON') : this.$t('machineStatus.BrakeOFF')
            if(TymConst.IS_DEVELOPMENT) {
              console.log('주차 브레이크(E) :', this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].value)
            }
            procBrakeP = true
          }  
        }

        if(this.itemValues[TymConst.VIEWOPTIONS.Direction].visible) {
          // 주행 방향
          let value = null
          if(!TymCommon.isEmpty(event.SHUTTLE)) {
            let splitted = event.SHUTTLE.split("^");
            switch(splitted[0]) {
              case '0':
                value = this.$t('machineStatus.Neutral')
                break
              case '1':
                value = this.$t('machineStatus.Forward')
                break
              case '2':
                value = this.$t('machineStatus.Reverse')
                break
            }
          }

          if(!TymCommon.isEmpty(value)) {
            this.itemValues[TymConst.VIEWOPTIONS.Direction].isAbnormal = false
            this.itemValues[TymConst.VIEWOPTIONS.Direction].value = value
            procShuttle = true
          }
          if(TymConst.IS_DEVELOPMENT) {
            console.log('주행 방향(E) :', event.SHUTTLE)
            console.log('주행 방향(E) :', value)
            console.log('주행 방향(E) :', this.itemValues[TymConst.VIEWOPTIONS.Direction].value)
          }
        }
        
        if(this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].visible) {
          // DPF 부하수준
          if(!TymCommon.isEmpty(event.DPF_SOOT)) {
            let splitted = event.DPF_SOOT.split('^')
            this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].isAbnormal = false
            try {
              this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].value = splitted[0] + ' %'
            } catch(ex) {
              this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].value = '-'
            }
            procDpfSoot = true
          }
          if(TymConst.IS_DEVELOPMENT && procDpfSoot) {
            console.log('DPF 부하수준(E) :', this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].value)
          }
        }
      }

      if(!TymCommon.isEmpty(history) && !TymCommon.isEmpty(history.HData)) {
        // 주차 브레이크
        if(this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].visible) {
          let brakeValue = null
          if(!TymCommon.isEmpty(history.HData.BRAKE_P))
            brakeValue = history.HData.BRAKE_P
          else if(!TymCommon.isEmpty(history.HData.P_HAND))
            brakeValue = history.HData.P_HAND
          if(!procBrakeP && !TymCommon.isEmpty(brakeValue)) {
            this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].isAbnormal = brakeValue == '1'
            this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].value = brakeValue == '1' ? this.$t('machineStatus.BrakeON') : this.$t('machineStatus.BrakeOFF')
            if(TymConst.IS_DEVELOPMENT) {
              console.log('주차 브레이크(H) :', this.itemValues[TymConst.VIEWOPTIONS.ParkingBrake].value)
            }
            procBrakeP = true
          }
        }
       
        // 주행 방향
        if(this.itemValues[TymConst.VIEWOPTIONS.Direction].visible) {
          if(!procShuttle && !TymCommon.isEmpty(history.HData.SHUTTLE)) {
            let value = '-'
            switch(history.HData.SHUTTLE) {
              case '0':
                value = this.$t('machineStatus.Neutral')
                break
              case '1':
                value = this.$t('machineStatus.Forward')
                break
              case '2':
                value = this.$t('machineStatus.Reverse')
                break
            }
            this.itemValues[TymConst.VIEWOPTIONS.Direction].isAbnormal = false
            this.itemValues[TymConst.VIEWOPTIONS.Direction].value = value
            if(TymConst.IS_DEVELOPMENT) {
              console.log('주행 방향(H) :', this.itemValues[TymConst.VIEWOPTIONS.Direction].value)
            }
            procBrakeP = true
          }
        }

        // DPF 부하수준
        if(!procDpfSoot && this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].visible) {
          if(!TymCommon.isEmpty(history.HData.DPF_SOOT)) {
            let value = parseFloat(history.HData.DPF_SOOT)
            if(!isNaN(value)) {
              this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].isAbnormal = false
              this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].value = value.toFixed(0) + ' %'
            }
            procDpfSoot = true
          }
          if(TymConst.IS_DEVELOPMENT && procDpfSoot) {
            console.log('DPF 부하수준(H) :', this.itemValues[TymConst.VIEWOPTIONS.DPFSoot].value)
          }
        }
      }
    },
    updateIgnition(ignition) {
      if(TymCommon.isEmpty(ignition))
        return
      if(!TymCommon.isEmpty(this.info)) {
        if(!TymCommon.isEmpty(this.machineInfo)) {
          this.machineInfo.PWR = ignition
        }
        this.itemValues[TymConst.VIEWOPTIONS.Ignition].isAbnormal = ignition == 'ON'
        this.itemValues[TymConst.VIEWOPTIONS.Ignition].value = ignition

        const store = useTymictStore()
        const found = store.machineLocaInfo.find(x => x.serialNo == this.info.serialNo)
        if(found) {
          found.PWR = ignition
        }
      }
    },
    async getLastDataBySN(sn) {
        const store = useTymictStore()
        let param = {
          TableName: "History_Day",
          KeyConditionExpression: "SN = :serial",
          ExpressionAttributeValues: {
            ":serial" : sn
          },
          ScanIndexForward: false,
          Limit: 1
        };
        let fncResult = {}

        await store.docClient.query(param, function(err, data) {
          if (err) {
            console.log('조회에 실패하였습니다. : getLastDataBySN :', sn, err);
          }
          else {
            fncResult.History = data.Items[0]
          }        
        });

        param = {
          TableName: "Event_Last",
          KeyConditionExpression: "SN = :serial",
          ExpressionAttributeValues: {
            ":serial" : sn
          },
          ScanIndexForward: false,
          Limit: 1
        };
        await new Promise((resolve,reject)=>{
          store.docClient.query(param, function(err, data) {
            if (err) {
              console.log('조회에 실패하였습니다. : getLastDataBySN :', sn, err);
              reject('error')
            }
            else {
              // Battery인 이유는 예전 lambda함수에서 쓴 키워드라서 Event가 아닌 Battery임
              fncResult.Battery = data.Items[0]
              if(TymConst.IS_DEVELOPMENT) {
                console.log('getLastDataBySN #2', sn, JSON.parse(JSON.stringify(fncResult)))
              }
              resolve(fncResult)
            }
          })
        })

        return fncResult;  

    }
  }
})
</script>
