<template>
  <!-- 기대정보를 표시하기 위한 페이지, 지도, 사용자 및 기대정보, 기대상태등의 탭 관리용 -->
  <div class="row viewBorder">
    <q-splitter v-model="horizSplitterValue" :limits="[30, 80]" 
              horizontal
              separator-style="height:12px"
              class="col"
              style="padding:0px 12px 12px 12px;"
              separator-class="transparent">
      <template v-slot:before>
        <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
        <q-splitter v-model="vertSplitterValue" :limits="[30, 70]" 
                    :separator-style='!isLandscape ? "height:12px" : "width:12px"'
                    :horizontal="!isLandscape"
                    separator-class="transparent">
          <template v-slot:before>
            <div class="column" style="height:100%;width:100%;">
              <div class="col-auto row items-center" style="height:45px;">
                <div class="col-auto"
                    :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
                  {{ getPageTitle }}
                </div>
              </div>

              <div class="col row" :class='isLandscape ? "row" : "column"' style="width:100%;">
                <div class="col column" style="background-color: white;border-radius: 3px;border:1px solid #ebedf2;padding:6px 12px 12px 12px;">
                  <!-- 지도 위쪽 정보 및 버튼 -->
                  <div class="col-auto row items-center" style="height:32px;">
                    <div style="font-size:14px;line-height:22px;font-weight:normal;margin-right:12px">{{$t('dashboardView.machinemap')}}</div>
                    <!--
                    <q-img class="refreshIcon" src="../assets/dashboard/dashboard_mapre_icon.svg" width="28px" fit="fill" @click="onRefreshClicked">
                      <q-tooltip style="font-size:14px">{{ $t('tooltip.refreshLocation') }}</q-tooltip>
                    </q-img>
                    -->
                  </div>

                  <!-- 지도 -->
                  <div class="col row" style="width:100%;height:100%;">
                    <MapView ref="manageMap" class="col" cluster="none" @markerSelected="selectedMarker"></MapView>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:separator>
            <div :class='isLandscape ? "vertSeparator" : "horizSeparator"'></div>
          </template>

          <template v-slot:after>
            <!-- Machine Info -->
            <div class="column" style="height:100%;">
              <div class="col-auto" :style='isLandscape ? "height:45px;width:20px;" : "height:0;width:0;"'></div>
              <div class="col row">
                <div class="col" style="background-color:white;border-radius:3px;border:1px solid #ebedf2;">
                    <div style="height:100%;padding:0 12px 12px 12px;">
                      <div class="col-auto row justfiy-between items-center" style="font-size:16px;font-weight:500;height:40px;">
                        <div class="col" style="margin:3px 0 0 0;font-size:16px;">{{ $t('machineInfo.title') }}</div>
                        <q-btn flat dense icon="close" @click="clickClose"></q-btn>
                      </div>
                      <div class="col column" style="height:calc(100% - 40px);margin:0 px;">
                        <!-- 상태와 Pagination -->
                        <div class="col-auto tr_top row items-center justify-between no-wrap" style="padding:0 0 3px 0;">
                          <div class="col-auto row">
                            <div v-show="getMachineStateNormal" style="width:26px;height:26px;margin:0 3px;">
                              <q-img :src="require('../assets/dashboard/dashboard_good_icon.svg')" fit="fill" />
                            </div>
                            <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;background-color:white;"
                                v-show="!getMachineStateNormal">
                            </div>

                            <div v-show="getMachineStateEM" style="width:26px;height:26px;margin:0 3px;">
                              <q-img :src="require('../assets/dashboard/dashboard_rollover_icon.svg')" fit="fill" width="26px" height="26px"/>
                            </div>
                            <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;ackground-color:white;"
                                v-show="!getMachineStateEM">
                            </div>

                            <div v-show="getMachineStateBR" style="width:26px;height:26px;margin:0 3px;">
                              <q-img :src="require('../assets/dashboard/dashboard_error_icon.svg')" fit="fill" width="26px" height="26px"/>
                            </div>
                            <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;background-color:white;"
                                v-show="!getMachineStateBR">
                            </div>

                            <div v-show="getMachineStateCONS" style="width:26px;height:26px;margin:0 3px;">
                              <q-img :src="require('../assets/dashboard/dashboard_consumables_icon.svg')" fit="fill" width="26px" height="26px"/>
                            </div>
                            <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;background-color:white;"
                                v-show="!getMachineStateCONS">
                            </div>

                            <div v-show="getMachineStateLowBatt" style="width:26px;height:26px;margin:0 3px;">
                              <q-img :src="require('../assets/dashboard/dashboard_battery_icon.svg')" fit="fill" width="26px" height="26px"/>
                            </div>
                            <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;background-color:white;"
                                v-show="!getMachineStateLowBatt">
                            </div>
                          </div>
                          <div class="col-auto">
                            <!-- pagination -->
                            <div class="col row items-center justify-end" v-show="totalCount > 0">
                              <q-btn flat dense icon="chevron_left" :disable="totalCount <= 0 || curIndex <= 0" @click="viewPrevious"></q-btn>
                              <div style="margin:0 3px;">{{ curIndex + 1 }} / {{ totalCount }}</div>
                              <q-btn flat dense icon="chevron_right" :disable="totalCount <= 0 || curIndex >= (totalCount-1)" @click="viewNext"></q-btn>
                            </div>
                          </div>
                        </div>

                        <q-scroll-area class="col row" style="height:100%; width:100%;" >
                          <table>
                            <tr class="tr_middle">
                              <td class="infoTitle" width="30%">{{ itemHeaders[0] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].name : '' }}</td>
                            </tr>
                            <tr class="tr_middle">
                              <td class="infoTitle">{{ itemHeaders[1] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].phone : '' }}</td>
                            </tr>
                            <tr class="tr_middle" v-show="isKorea">
                              <td class="infoTitle">{{ itemHeaders[2] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].address : '' }}</td>
                            </tr>
                            <tr class="tr_middle">
                              <td class="infoTitle">{{ itemHeaders[3] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].modelNo : '' }}</td>
                            </tr>
                            <tr class="tr_middle">
                              <td class="infoTitle">{{ itemHeaders[4] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].machineNo : '' }}</td>
                            </tr>

                            <tr class="tr_middle">
                              <td class="infoTitle" >{{ itemHeaders[5] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].manDate : '' }}</td>
                            </tr>
                            <tr class="tr_middle">
                              <td class="infoTitle" >{{ itemHeaders[6] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].serialNo : '' }}</td>
                            </tr>
                            <tr class="tr_middle">
                              <td class="infoTitle">{{ itemHeaders[7] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].saleDate : '' }}</td>
                            </tr>
                            <tr class="tr_middle">
                              <td class="infoTitle">{{ itemHeaders[8] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].dealer : '' }}</td>
                            </tr>
                            <tr class="tr_bottom">
                              <td class="infoTitle" rowspan="2">{{ itemHeaders[9] }}</td>
                              <td class="infoContent">{{ totalCount > 0 ? machineValue[curIndex].realtimeAddr : '' }}</td>
                            </tr>
                          </table>
                          <div class="col row">
                            <div class="row items-center justify-center addParts" v-show="hasACU">
                              <div>ACU</div>
                            </div>
                            <div class="row items-center justify-center addParts" style="margin:0 12px;" v-show="hasTCU">
                              <div>TCU</div>
                            </div>
                            <div class="row items-center justify-center addParts" v-show="hasCPG">
                              <div>CPG</div>
                            </div>
                          </div>
                        </q-scroll-area>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </template>
        </q-splitter>
      </template>

      <template v-slot:separator>
        <div class="horizSeparator"></div>
      </template>

      <template v-slot:after>
        <div class="row" style="background-color: white;border-radius: 3px;border:1px solid #ebedf2;margin-bottom:12px;overflow-y: hidden;">
          <q-scroll-area class="col" style="width:100%;height:36px">
            <q-tabs v-model="curTab"
                    dense flat 
                    align="left"
                    indicator-color="transparent"
                    class="text-grey-10 col-auto" 
                    active-color="red-7">
              <!--
                :label="$t('machineDetail.status')"
                :label="$t('machineDetail.manage')"
                :label="$t('machineDetail.worksheet')"
                :label="$t('machineDetail.usage')"
              -->
              <q-tab name="status" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                {{ $t('machineDetail.status') }}
              </q-tab>
              <q-tab name="manage" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                {{ $t('machineDetail.manage') }}
              </q-tab>
              <q-tab name="worksheet" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                {{ $t('machineDetail.worksheet') }}
              </q-tab>
              <q-tab name="usage" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                {{ $t('machineDetail.usage') }}
              </q-tab>
            </q-tabs>
          </q-scroll-area>
        </div>

        <div class="col row bottomArea">
          <q-scroll-area class="col-auto row" style="height:100%;width:100%;" >
            <q-tab-panels dense v-model="curTab">
              <q-tab-panel dense name="status" style="padding:0;overflow-x:hidden;">
                <MachineStatusTab ref="tabStatus" :info="machineValue[curIndex]"></MachineStatusTab>
              </q-tab-panel>

              <q-tab-panel dense name="manage" style="padding:0;overflow-x:hidden;">
                <MachineManageTab ref="tabManage" :info="machineValue[curIndex]"></MachineManageTab>
              </q-tab-panel>

              <q-tab-panel name="worksheet" style="padding:0;overflow-x:hidden;">
                <MachineWorksheetTab ref="tabWorksheet" :info="machineValue[curIndex]"></MachineWorksheetTab>
              </q-tab-panel>

              <q-tab-panel name="usage" style="padding:0;overflow-x:hidden;">
                <MachineUsageTab ref="tabUsage" :info="machineValue[curIndex]"></MachineUsageTab>
              </q-tab-panel>
            </q-tab-panels>
          </q-scroll-area>
        </div>
      </template>
    </q-splitter>

  </div>
</template>

<style scoped>
.refreshIcon {
  border-radius:3px;
  background-color:#fff;
}
.refreshIcon:hover {
  border-radius:3px;
  background-color:#eee;
}
.refreshIcon:active {
  border-radius:3px;
  background-color:#ddd;
}

.bottomArea {
  overflow-y:hidden;
  height:calc(100% - 50px);
}

.viewBorder {
  height:100%;
  width:100%;
  overflow-y: auto;
}
.horizSeparator {
  width:32px;
  height:4px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.vertSeparator {
  width:4px;
  height:32px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.title {
  font-size: 16px;
  font-weight: 600;
}

.infoTitle {
  font-size:14px;
  font-weight: 500;
}

.infoContent {
  font-size:14px;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.tr_top {
  border-bottom: 2px solid #cccccc;
}
.tr_middle {
  border-bottom: 1px solid #eeeeee;
}
.tr_bottom {
  border-bottom: 2px solid #cccccc;
}

td {
  padding: 4px;
}

.addParts {
  border:2px solid #eb0028;
  border-radius:3px;
  width:38px;
  height:38px;
  font-size:14px;
  font-weight: 600;
  color: #eb0028;
}
</style>

<script>
import { ref } from 'vue'
import MapView from './MapView.vue'
// import MachineDetailView from './MachineDetailView.vue'
import TymFncs from '@/js/tymfunctions.js'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'
import { useTymictStore } from '@/store/tymict.js'
import TymAws from '@/js/tymaws.js'
import MachineStatusTab from '@/components/MachineStatusTab.vue'
import MachineManageTab from '@/components/MachineManageTab.vue'
import MachineWorksheetTab from '@/components/MachineWorksheetTab.vue'
import MachineUsageTab from '@/components/MachineUsageTab.vue'
import { useI18n } from 'vue-i18n'

export default ({
  components : {
    MapView,
//    MachineDetailView,
    MachineStatusTab,
    MachineManageTab,
    MachineWorksheetTab,
    MachineUsageTab
  },
  data() {
    return {
      errType : ref(-1),     // 고장 타입(-1 : Unknown, 0 : Emergency, 1 : Broken, 2 : Consumables, 3 : Battery...)
      hostedData : ref({}),  // 이전 페이지에서 전달받은 내용
      machineLocaInfo : ref([]),
    }
  },
  setup() {
    const i18n = useI18n()
    return {
      vertSplitterValue : ref(65),
      horizSplitterValue : ref(40),
      curIndex : ref(0),
      totalCount : ref(0),
      curTab : ref('status'),
      machineState : ref([false, false, false, false, false]),
      stateColors : ref(['#90cc30', '#eb002830', '#c97d31','#ffc600','#bbbbbb']),  // green, red, brown, yellow, gray
      itemHeaders : ref([
        i18n.t('machineInfo.owner'),
        i18n.t('machineInfo.mobile'),
        i18n.t('machineInfo.address'),
        i18n.t('common.model'),
        i18n.t('machineInfo.vin'),
        i18n.t('machineInfo.manDate'),
        i18n.t('machineInfo.serialNo'),
        i18n.t('machineInfo.saleDate'),
        i18n.t('machineInfo.dealer'),
        i18n.t('machineInfo.curLoca')
      ]),
      machineValue : ref([])
    }
  },
  computed : {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    isKorean() {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    isKorea() {
      const store = useTymictStore()
      return (store.connectServer == 0)
    },
    isGoogleMap() {
      const store = useTymictStore()
      return (store.connectServer != 0)
    },
    getMachineStateNormal() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        //console.log(store.machineLocaInfo, this.curIndex, this.machineValue)
        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return !machine.abnormal[0] && !machine.abnormal[1] && !machine.abnormal[2] && !machine.abnormal[3]
        }
      }
      return false
    },
    getMachineStateEM() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        //console.log(store.machineLocaInfo, this.curIndex, this.machineValue)
        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return machine.abnormal[0]
        }
      }
      return false
    },
    getMachineStateBR() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        //console.log(store.machineLocaInfo, this.curIndex, this.machineValue)
        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return machine.abnormal[1]
        }
      }
      return false
    },
    getMachineStateCONS() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        //console.log(store.machineLocaInfo, this.curIndex, this.machineValue)
        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return machine.abnormal[2]
        }
      }
      return false
    },
    getMachineStateLowBatt() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        //console.log(store.machineLocaInfo, this.curIndex, this.machineValue)
        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return machine.abnormal[3]
        }
      }
      return false
    },
    machineInfoChanged() {
      let machineState = []
      if(this.curIndex >= 0) {
        const store = useTymictStore()

        if(this.machineValue.length > 0) {
          this.machineValue.forEach(machine => {
            let abnormal = [false, false, false, false]
            let found = store.machineLocaInfo.find(x => x.machineNo == machine.machineNo)
            if(found) {
              let foundEM = store.EmergencyData.data.find(x => x.MN == machine.machineNo)
              if(foundEM) {
                abnormal[0] = true
              } else {
                let foundBR = store.BrokenData.data.find(x => x.MN == machine.machineNo)
                if(foundBR) {
                  abnormal[1] = true
                } else {
                  let foundCS = store.ConsumablesData.data.find(x => x.MN == machine.machineNo)
                  if(foundCS) {
                    abnormal[2] = true
                  } else {
                    let foundLB = store.BatteryAlertData.data.find(x => x.MN == machine.machineNo)
                    if(foundLB) {
                      abnormal[3] = true
                    } 
                  }
                }
              }

              machineState.push({
                MN: found.machineNo,
                PWR: TymCommon.isEmpty(found.PWR) ? false : found.PWR == 'ON',
                Abnormal: abnormal
              })
            }
          })
        }
      }
              /*
        if((this.machineValue.length > 0) && !TymCommon.isEmpty(this.machineValue[this.curIndex].machineNo)) {
          let found = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
          if(found && !TymCommon.isEmpty(found.PWR)) {
            return found.PWR == 'ON'
          }
        }
        */

      return machineState
    }
  },
  watch : {
    machineInfoChanged : {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal)
        if(oldVal != newVal) {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('MachineInfoView.watch.machineInfoChanged', newVal)
          }
          this.updateIgnState(newVal)
        }
      },
      immediate : true
    }
  },
  created() {},
  mounted() {
    this.updateInfo()       // 처음에 데이터 비우기 위함

    const store = useTymictStore()
    store.CurrentPage.Now = TymConst.PAGE_MANAGE_POPUP

    if(TymConst.IS_DEVELOPMENT) {
      console.log('MachineInfoView.mounted :', store.selectManageInfo)
    }

    if(store.selectManageInfo) {
      this.errType = store.selectManageInfo.kind;
      this.hostedData = store.selectManageInfo.data;

      switch(this.errType) {
        case 0:
        case 1:
        case 2:
        case 3:
          this.curTab = "manage"
          break;
      }
      store.selectManageInfo = { kind : -1, data : {} }      // 읽은 후에 지우는 부분임

      setTimeout(() => {
        this.makeDataByUser()
      }, 1)
    }
  }, 
  unmounted() {},
  methods : {
    unusedParam()  {
      //
    },
    pageChanged() {
      // console.log("MachineInfoView.pageChanged()")
    },
    clickClose() {
      const store = useTymictStore()
      store.setPage(store.CurrentPage.Old)
    },
    makeDataByUser() {
      /* 
      this.errType : 0x0FFF ==> 세일즈에서 전달 
      this.errType < 0 : 관제 페이지에서 여러개의 정보를 보내주는 데 몇번째를 가장먼저 보여줄지... 
                         보여줄 인덱스는 이런 식 : (-(index + 1)) 
      this.errType  : 0 -> 긴급
                      1 -> 고장
                      2 -> 소모품
                      3 -> 배터리 저전압 경고
      */
      if(this.errType < 0) {
        // 여기를 타는 경우는 관제페이지를 통해서이다.
        // errType이 0보다 작을텐도 보낼때(-(index + 1)) 이런 식으로 보내준다.
        if(Array.isArray(this.hostedData)) {
          this.curIndex = Math.abs(this.errType);
          this.curIndex--   // 이쪽으로 올 때 0를 피하려고 1씩을 더했거든
          this.totalCount = this.hostedData.length;
          this.machineValue = this.hostedData
        } else {
          this.totalCount = 1;
          this.curIndex = 0;
          this.machineValue = [this.hostedData]
        }
        if(this.$refs.manageMap) {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('machineInfoView.makeDataByUser MONITOR', this.hostedData, this.machineValue)
          }
          this.$refs.manageMap.updateMapData(this.machineValue, [true, true, true, true, true])
        }
        this.updateInfo()
        return
      }

      const store = useTymictStore()
      if(TymConst.IS_DEVELOPMENT) {
        console.log('MachineInfoView:', this.errType, this.hostedData, store.machineLocaInfo[0])
      }
      if(store.machineLocaInfo.length < 1) {
        console.log('기대 정보 없음 :', this.errType, this.hostedData, store.machineLocaInfo[0])
        return;
      }
      
      // console.log('MachineInfoView.makeDataByUser #1:', this.errType, this.hostedData, store.machineLocaInfo[0])
      let i = 0
      let userDataInSales = null
      if(this.errType == 0x0FFF) {
        userDataInSales = store.SalesData.data.filter(x => x.machineNo == this.hostedData.machineNo)
      } else {
        userDataInSales = store.SalesData.data.filter(x => x.uid == this.hostedData.uid)
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('MachineInfoView.makeDataByUser SALE or MANAGE :', this.errType , this.hostedData, userDataInSales)
      }

      if(userDataInSales) {
        this.totalCount = userDataInSales.length;
        this.curIndex = 0;
        this.machineValue = []

        if(userDataInSales.length == 0) {
          // 판매데이터엔 자료가 없군요... 아마 비정상 케이스?
          if(TymConst.IS_DEVELOPMENT) {
            console.log('makeDataByUser - no SalesData', userDataInSales, this.hostedData)
          }

          this.machineValue.push ({
                name : this.hostedData.name,
                uid : this.hostedData.uid,
                ads : '',
                phone : this.hostedData.phone,
                address : '',
                modelNo : '', //모델
                machineNo : TymCommon.isEmpty(this.hostedData.machineNo) ? '' : this.hostedData.machineNo, //VIN
                manDate :  '', //생산일
                serialNo :  this.hostedData.serialNo, //S/N
                salesDate : '', // 판매일 - 변환후
                SD : '',        // 판매일 - 원본
                dealer : this.hostedData.dealer, //Dealer
                abnormal : [false, false, false, false],
                latitude : 0,  // 위도
                longitude : 0, // 경도
                hasACU : false,
                hasTCU : false,
                hasCPG : false,
                realtimeInfo : '',
                realtimeAddr : '', // 현재위치의 주소
              })
        } else {
          for(i = 0; i < userDataInSales.length; i++) {
            let machine = store.machineLocaInfo.find(x => x.machineNo == userDataInSales[i].machineNo)

            if(machine) {
              let lat = parseFloat(machine.latitude)
              let lng = parseFloat(machine.longitude)
              
              let modelName = TymCommon.getModelFullname(machine.MON)
              if(TymConst.IS_DEVELOPMENT) {
                console.log("userDataInSales : ", i, userDataInSales[i], machine, modelName)
              }
              this.machineValue.push ({
                name : userDataInSales[i].owner,
                uid : userDataInSales[i].uid,
                ads : machine.ads,
                phone : userDataInSales[i].mobile,
                address : userDataInSales[i].address,
                modelNo : TymCommon.isEmpty(modelName) ? '' : modelName.Fullname, // userDataInSales[i].model, //모델
                machineNo : userDataInSales[i].machineNo, //VIN
                manDate :  userDataInSales[i].manDate, //생산월
                serialNo :  userDataInSales[i].serialNo, //S/N
                saleDate : userDataInSales[i].saleDate,
                SD : userDataInSales[i].SD,
                dealer : userDataInSales[i].dealer,  //Dealer
                abnormal : machine.abnormal,
                // abnormal : machine[5],
                latitude : isNaN(lat) ? 0.0 : lat,  // 위도
                longitude : isNaN(lng) ? 0.0 : lng, // 경도
                hasACU : machine.hasACU,
                hasTCU : machine.hasTCU,
                hasCPG : machine.hasCPG,
                realtimeInfo : '',
                realtimeAddr : '', // 현재위치의 주소
                PWR: TymCommon.isEmpty(machine.PWR) ? "OFF" : machine.PWR,
              })
            } else {
              // console.log('Not found main-location data', userSalesInfo[i].SN)
              this.machineValue.push ({
                name : userDataInSales[i].owner,
                uid : userDataInSales[i].uid,
                ads : '',
                phone : userDataInSales[i].mobile,
                address : userDataInSales[i].address,
                modelNo : userDataInSales[i].model, //모델
                machineNo : userDataInSales[i].machineNo, //VIN
                manDate :  userDataInSales[i].manDate, //생산월
                serialNo :  userDataInSales[i].serialNo, //S/N
                saleDate : userDataInSales[i].saleDate,
                SD : userDataInSales[i].SD,
                dealer : userDataInSales[i].dealer,  //Dealer
                abnormal : [false, false, false, false],
                latitude : 0,  // 위도
                longitude : 0, // 경도
                hasACU : false,
                hasTCU : false,
                hasCPG : false,
                realtimeInfo : '',
                realtimeAddr : '', // 현재위치의 주소
                // PWR: TymCommon.isEmpty(machine.PWR) ? "OFF" : machine.PWR,
              })
            }
          }
        }

        this.curIndex = 0
        this.totalCount = this.machineValue.length

        if(TymConst.IS_DEVELOPMENT) {
          console.log('this.machineValue ', this.curIndex, this.totalCount, this.machineValue)
        }

        for(i = 0; i < this.machineValue.length; i++) {
          try {
            this.getAddress(i, false, this.machineValue[i].latitude, this.machineValue[i].longitude)
          } catch(err) {
            console.log('this.getAddress(error}', err)
          }
        }
        
        for(i = 0; i < this.machineValue.length; i++) {
          if(this.machineValue[i].machineNo == this.hostedData.machineNo) {
            //console.log('Found machine', this.machineValue[i].machineNo)
            this.curIndex = i
            break
          }
        }
      } else {
        // 아마 비정상 케이스?
        this.machineValue.push ({
          name : this.hostedData.name,
          uid : this.hostedData.uid,
          ads : '',
          phone : this.hostedData.phone,
          address : '',
          modelNo : '', //모델
          machineNo : this.hostedData.machineNo, //VIN
          manDate :  '', //생산일
          serialNo :  this.hostedData.serialNo, //S/N
          saleDate : '', //판매
          SD : '',
          dealer : this.hostedData.dealer, // grp,  //Dealer
          abnormal : [false, false, false, false],
          latitude : 0,  // 위도
          longitude : 0, // 경도
          hasACU : false,
          hasTCU : false,
          hasCPG : false,
          realtimeInfo : '',
          realtimeAddr : '', // 현재위치의 주소
        })
        this.curIndex = 0
        this.totalCount = this.machineValue.length
        console.log('No Sales Data', this.hostedData.uid)

        for(i = 0; i < this.machineValue.length; i++) {
          try {
            this.getAddress(i, false, this.machineValue[i].latitude, this.machineValue[i].longitude)
          } catch(err) {
            console.log('this.getAddress(error)', err)
          }
        }
      }
      this.updateInfo()
    },

    updateInfo() {
      this.machineState = [false, false, false, false, false]
      if((this.totalCount <= 0) || (this.curIndex < 0 )) {
        // 데이터 비우자...
        return
      }

      if(!TymCommon.isEmpty(this.machineValue[this.curIndex].abnormal)) {
        this.machineState[0] = !(this.machineValue[this.curIndex].abnormal[0] || this.machineValue[this.curIndex].abnormal[1] || this.machineValue[this.curIndex].abnormal[2] || this.machineValue[this.curIndex].abnormal[3])
        this.machineState[1] = this.machineValue[this.curIndex].abnormal[0]
        this.machineState[2] = this.machineValue[this.curIndex].abnormal[1]
        this.machineState[3] = this.machineValue[this.curIndex].abnormal[2]
        this.machineState[4] = this.machineValue[this.curIndex].abnormal[3]
      }
      if(TymConst.IS_DEVELOPMENT) {
        console.log('MachineInfoView.udpateInfo :', this.curIndex, this.machineValue[this.curIndex], this.machineState)
      }
      
      if((this.machineValue[this.curIndex].latitude != 0) && (this.machineValue[this.curIndex].longitude != 0)) {
        try {
          // console.log('MachineInfoView.udpateInfo.getLastPosBySN :', this.machineValue[this.curIndex])
          // 마지막 위치정보 가져오자
          TymAws.getLastPosBySN(this.machineValue[this.curIndex].serialNo)
            .then(data => {
              // console.log('TymAws.getLastPosBySN :', data)
              if(data != 'error') {
                this.machineValue[this.curIndex].realtimeInfo = data[3]
                this.getAddress(this.curIndex, true, parseFloat(data[1]), parseFloat(data[2]))
              }
            })
            .catch( err => {
              // this.machineValue[9] = '위치정보 없음'
              console.log('TymAws.getLastPosBySN exception :', err)
              if(this.$refs.tabStatus)
                this.$refs.tabStatus.updateData(null)
              if(this.$refs.tabManage)
                this.$refs.tabManage.updateData(null)
              if(this.$refs.tabWorksheet)
                this.$refs.tabWorksheet.updateData(null)
              if(this.$refs.tabUsage)
                this.$refs.tabUsage.updateData(null)
            })
          } catch(err) {
            console.log(err)
            if(this.$refs.tabStatus)
                this.$refs.tabStatus.updateData(null)
            if(this.$refs.tabManage)
              this.$refs.tabManage.updateData(null)
            if(this.$refs.tabWorksheet)
              this.$refs.tabWorksheet.updateData(null)
            if(this.$refs.tabUsage)
              this.$refs.tabUsage.updateData(null)
          }
      }

      if(this.machineValue[this.curIndex].historyLast == null) {
        // console.log('update to last location data ', this.machineValue)
        // 최신위치정보를 DB에서 가져옴
        if(!TymCommon.isEmpty(this.machineValue[this.curIndex].serialNo)) {
          TymAws.getLastDataBySN(this.machineValue[this.curIndex].serialNo)
            .then(data => {
              if(data.length > 0) {
                if(TymConst.IS_DEVELOPMENT) {
                  console.log('MachineInfoView.updateInfo :', data)
                }
                let coord = []
                this.machineValue[this.curIndex].historyLast = data[0]
                if(!TymCommon.isEmpty(data[0].LOCA) && data[0].LOCA.length > 0) {
                  if(!TymCommon.isEmpty(data[0].LOCA[data[0].LOCA.length - 1].Coord)) {
                    coord = data[0].LOCA[data[0].LOCA.length - 1].Coord.split(',')
                  }
                }
                let lat = parseFloat(coord[0])
                let lng = parseFloat(coord[1])
                this.getAddress(this.curIndex, true, lat, lng)
              /*
              } else {
                console.log('MachineInfoView.updateInfo :', 'EMPTY')

                if(this.$refs.tabStatus)
                  this.$refs.tabStatus.updateData(null)
                if(this.$refs.tabManage)
                  this.$refs.tabManage.updateData(null)
                if(this.$refs.tabWorksheet)
                  this.$refs.tabWorksheet.updateData(null)
                if(this.$refs.tabUsage)
                  this.$refs.tabUsage.updateData(null)
              */
              }
              if(this.$refs.tabStatus)
                this.$refs.tabStatus.updateData(this.machineValue[this.curIndex])
              if(this.$refs.tabManage)
                this.$refs.tabManage.updateData(this.machineValue[this.curIndex])
              if(this.$refs.tabWorksheet)
                this.$refs.tabWorksheet.updateData(this.machineValue[this.curIndex])
              if(this.$refs.tabUsage)
                this.$refs.tabUsage.updateData(this.machineValue[this.curIndex])
            })
            .catch(err => {
              console.log('getLastData failed... : ', err)
              if(this.$refs.tabStatus)
                this.$refs.tabStatus.updateData(null)
              if(this.$refs.tabManage)
                this.$refs.tabManage.updateData(null)
              if(this.$refs.tabWorksheet)
                this.$refs.tabWorksheet.updateData(null)
              if(this.$refs.tabUsage)
                this.$refs.tabUsage.updateData(null)
          })
        }
      } else {
        if(this.$refs.tabStatus)
          this.$refs.tabStatus.updateData(this.machineValue[this.curIndex])
        if(this.$refs.tabManage)
          this.$refs.tabManage.updateData(this.machineValue[this.curIndex])
        if(this.$refs.tabWorksheet)
          this.$refs.tabWorksheet.updateData(this.machineValue[this.curIndex])
        if(this.$refs.tabUsage)
          this.$refs.tabUsage.updateData(this.machineValue[this.curIndex])
      }
    },

    getAddress(index, onlyOne, latitude, longitude) {
      if(isNaN(latitude) || isNaN(longitude)) {
        this.machineValue[index].realtimeAddr = '위치 정보 없음'
        this.updateInfo()
        if(index >= (this.totalCount - 1))
        return
      }
      if(latitude == 0 || longitude == 0) {
        this.machineValue[index].realtimeAddr = '위치 정보 없음'
        if(onlyOne) {
          // 지도 업데이트 요청
          // console.log('MachineInfoView.makeDataByUser getAddress')
          this.$refs.manageMap.updateData(this.curIndex)
        } else if(index >= (this.totalCount - 1)) {
          // 지도 업데이트 요청
          this.$refs.manageMap.updateMapData(this.machineValue, [true, true, true, true, true])
          this.updateInfo()
        }
        return
      }
      if(this.isGoogleMap) {
        const self = this
        const geocoder = new window.google.maps.Geocoder()
        const latlng = new window.google.maps.LatLng(latitude, longitude)

        geocoder.geocode({'latLng' : latlng}, function(response, status) {
          let addr = ''

          if(status == 'OK') {
            if(TymConst.IS_DEVELOPMENT) {
              console.log('GMap Geocode #2', response, status)
            }
            self.machineValue[index].latitude = latitude
            self.machineValue[index].longitude = longitude

            if(!TymCommon.isEmpty(response)) {
              addr = response[0].formatted_address

              if(TymCommon.isEmpty(addr)) {
                addr = response.result.items[0].address
              }
              self.machineValue[index].realtimeAddr = addr
            }
          } else {
            self.machineValue[index].realtimeAddr = self.$t('warning.noaddrinfo')
            console.log('getAddress failed... : ', latitude, longitude, status)
          }
        })
      } else {
        // console.log('NMap.getAddress : ', index, onlyOne, latitude, longitude)
        window.naver.maps.Service.reverseGeocode({
            coords: new window.naver.maps.LatLng(latitude, longitude), 
            orders: [
              window.naver.maps.Service.OrderType.ROAD_ADDR,
              window.naver.maps.Service.OrderType.ADDR
            ].join(',')
          }, (status, response) => {
            this.machineValue[index].latitude = latitude
            this.machineValue[index].longitude = longitude

            if (status != window.naver.maps.Service.Status.OK) {
              this.machineValue[index].realtimeAddr = this.$t('warning.noaddrinfo')
              console.log('getAddress failed... : ', index, latitude, longitude)
            } else {
              // console.log('address :', response)
              if(response.v2.status.code != 3) {
                if(!TymCommon.isEmpty(response.v2.address.roadAddress)) {
                  this.machineValue[index].realtimeAddr = response.v2.address.roadAddress
                } else if(!TymCommon.isEmpty(response.v2.address.jibunAddress)) {
                  this.machineValue[index].realtimeAddr = response.v2.address.jibunAddress
                } else {
                  let addr = ''
                  let i = 0
                  if(!TymCommon.isEmpty(response.result) && !TymCommon.isEmpty(response.result.items)) {
                    for(i = 0; i < response.result.items.length; i++) {
                      if(response.result.items[i].isAdmAddress) {
                        addr = response.result.items[i].address
                        break;
                      }
                    }
                  }

                  console.log('addr... : ', addr)

                  if(TymCommon.isEmpty(addr)) {
                    addr = response.result.items[0].address
                  }
                  this.machineValue[index].realtimeAddr = addr
                }
              } else {
                this.machineValue[index].realtimeAddr = this.$t('warning.noaddrinfo')
              }
            }
          }
        )
      }
      // console.log('getAddress #2', index, onlyOne, this.machineValue[index].realtimeAddr)
      if(onlyOne) {
        // 지도 업데이트 요청하지 말자... 주소는 거기서 알아서...
        //console.log('MachineInfoView.makeDataByUser : rx Address')
        //this.$refs.manageMap.updateData(this.curIndex)
      } else if(index >= (this.totalCount - 1)) {
        // 지도 업데이트 요청
        // console.log('##### ', this.hostedData, this.machineValue)

        this.$refs.manageMap.updateMapData(this.machineValue, [true, true, true, true, true])
        this.updateInfo()
      }
    },

    viewPrevious() {
      if(this.curIndex > 0) {
        this.curIndex--
        this.updateInfo()
      }
    },
    viewNext() {
      if(this.curIndex < (this.totalCount - 1)) {
        this.curIndex++
        this.updateInfo()
      }
    },
    
    // 마커 클릭시 실행할 함수
    // one : 클릭한 마커에 해당하는 기대 정보
    selectedMarker(one) {
      const store = useTymictStore()

      if(TymConst.IS_DEVELOPMENT) {
        console.log('MachineInfoView.selectedMarker :', one)
      }

      let idx = store.findMachineIndicesBySN(one.serialNo)
      if(idx != null) {
        store.machineLocaInfo[idx[0]] = one
        let i = 0
        for(i = 0; i < this.machineValue.length; i++) {
          if(one.serialNo == this.machineValue[i].serialNo) {
            //console.log('MachineInfoView.selectedMarker :', i, one, this.machineValue)
            this.curIndex = i
            this.updateInfo()
            break;
          }
        }
      }
      //console.log('MachineInfoView.selectedMarker END')
    },
    onRefreshClicked() {
      // console.log('onRefreshClicked()')
      //let backup = this.curIndex
      //this.makeDataByUser()
      //this.curIndex = backup
      this.updateInfo()
    },
    updateIgnState(newState) {  
      if(this.$refs.manageMap) {
        if(TymConst.IS_DEVELOPMENT) {
          console.log('machineInfoView.updateIgnState :', newState, this.machineValue)
        }
        newState.forEach(state => {
          let found = this.machineValue.find(x => x.machineNo == state.MN)
          if(found) {
            if(TymConst.IS_DEVELOPMENT) {
              if(state.MN == "FWV10D") {
                console.log('machineInfoView.updateIgnState FWV10D:', state, found)
              }
            }
            this.$refs.manageMap.updateMachineState(found.machineNo, state.PWR, state.Abnormal)
          }
        })
        /*
        this.$refs.manageMap.updateMachineState(this.machineValue[this.curIndex].machineNo, isON, 
              TymCommon.isEmpty(this.machineValue[this.curIndex].abnormal) ? [false, false, false, false] : this.machineValue[this.curIndex].abnormal)
        */
      }
    }
  }
})
</script>
