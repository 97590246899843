<template>
  <div class="dlgContainer column justify-between">
    <div class="col-9 column items-center" style="width:100%;">
      <div class="col-2 row items-center justify-center" style="font-size:14pt;font-weight:500;" v-if="users.length > 0">
        <div class="col">{{ $t('login.matchedID') }}</div>
      </div>
      <div class="col-9 column" style="width:100%;height:auto;padding:5%;" v-if="users.length > 1">
        <q-table dense flat bordered
                style="width:100%;max-height:220pt;"
                selection="single"
                separator="cell"
                row-key="index"
                virtual-scroll
                hide-no-data
                hide-pagination
                :visible-columns='visibleColumns'
                :columns="memberColumns"
                :rows="memberRows"
                :rows-per-page-options="[0]"
                @row-click="emTableClicked"
                >
          <template v-slot:header="props">
            <q-th no-caps
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props">
              <div style="font-size:12pt">
                {{ col.label }}
              </div>
            </q-th>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="id" :props="props">
                <div class="row items-center justify-start" style="min-width:100pt">
                  <q-radio  v-model="memberSelection" :val="props.row.id"
                            dense color="red" style="margin-right:10pt"
                            />
                  <div style="font-size:12pt">
                    {{ props.row.id }}
                  </div>
                </div>
              </q-td>
              <q-td key="name" :props="props">
                <div style="font-size:12pt">
                  {{ props.row.name }}
                </div>
              </q-td>
              <q-td key="phone" :props="props">
                <div style="font-size:12pt">
                  {{ props.row.phone }}
                </div>
              </q-td>
              <q-td key="email" :props="props">
                <div style="font-size:12pt">
                  {{ props.row.email }}
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <div class="col-auto" style="font-size:14pt;font-weight:500;" v-if="users.length == 1">{{ $t('login.yourIDis') }}</div>

      <div class="col-8 column items-center justify-center" style="width:100%" v-if="users.length == 1">
        <div style="font-size:14pt;font-weight:500;">{{ foundOneID }}</div>
      </div>
      <div class="col-9" style="font-size:14pt;font-weight:500;" v-if="users.length == 0">
        <div class="col row items-center justify-center" style="height:100%;width:100%;">
          <div>{{ $t('login.noMatchedID')}}</div>
        </div>
      </div>
    </div>
    <div class="col-2 row justify-center items-start">
      <q-btn
        class="col-3"
        dense no-caps
        text-color="white"
        style="border-radius:3px;width:64pt;font-weight:500;font-size:12pt;background-color:#eb0028;"
        label="Close"
        @click="clickClose"
        />
      </div>
  </div>
</template>

<style scoped>


.dlgContainer {
  width:100%;
  height:100%;
  background-color: #fff;
}

</style>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default ({
  props: {
    users : [],
    kind : String
  },
  computed: {
  },
  components : {
  },
  setup() {
    const i18n = useI18n()

    return {
      inputName : ref(''),
      inputEmail : ref(''),
      findIDOK : ref(false),
      foundOneID : ref(''),
      visibleColumns: ref([ 'id', 'name', 'phone', 'email']),
      memberColumns : [
        { name: 'id',
          required: true,
          label: 'ID',
          align: 'left',
          field: 'id',
          format: val => `${val}`,
          sortable: false
        },
        { name: 'name',
          required: true,
          label: i18n.t('common.name'),
          align: 'left',
          field: 'name',
          format: val => `${val}`,
          sortable: false
        },
        { name: 'phone',
          required: true,
          label: 'Phone Number',
          align: 'left',
          field: 'phone',
          format: val => `${val}`,
          sortable: false
        },
        { name: 'email',
          required: true,
          label: 'E-Mail',
          align: 'left',
          field: 'email',
          format: val => `${val}`,
          sortable: false
        },
      ],
      memberSelection : ref(''),
      memberRows : ref([])
    }
  },
  mounted() {
    /*
    if(!TymCommon.isEmpty(this.kind)) {
      if(this.kind == 'phone') {
        console.log(this.visibleColumns)
        this.visibleColumns.splice(2,1)
        console.log(this.visibleColumns)
      }
    }
    */
    console.log('FoundUsers-mounted : ', this.kind)

    if(this.users.length == 1) {
      if(this.users[0].id) {
        this.foundOneID = this.users[0].id;
        this.memberSelection = this.users[0].id;
      } 
    } else if(this.users.length > 1) {
      const tempUsers = this.users
      tempUsers.sort( function(a, b) {
        if(a.id > b.id) return 1;
        if(a.id === b.id) return 0;
        if(a.id < b.id) return -1;
      })

      let idx = 0;
      this.memberSelection = this.users[0].id;
      for(idx = 0; idx < tempUsers.length; idx++) {
        this.memberRows.push( {
          index : idx,
          id : tempUsers[idx].id,
          name : tempUsers[idx].name,
          email : tempUsers[idx].email,
          phone : tempUsers[idx].phone
        })
      }
    } else {
      console.log('FoundUsers-mounted : Nothing')
    }
  },
  methods: {
    clickClose() {
      console.log('clickClose', this.memberSelection)
      if(this.memberRows.length > 0) {
        this.foundOneID = this.memberSelection
      }
      if(this.foundOneID) {
        this.$emit('selectDialog', { id : this.foundOneID } )
      } else {
        this.$emit('selectDialog')
      }
    },
  }
})
</script>
