<template>
  <!-- 로고 -->
  <div :class='isLandscape ? "row items-center" : "row items-center justify-center"' style="width:100%;height:80pt;margin-bottom:10%;">
    <div class="imgBorder">
    </div>

    <!--
    <div class="column items-center" style="margin:0 5pt">
      <div style="margin:-2pt 0 0 0;font-size:20pt;font-weight:500;color:#d10928">MYTYM</div>
      <div style="margin:-12pt 0 0 0;font-size:29pt;font-weight:700;color:#d10928;margin-top:-10pt;">S.A.M</div>
      <div style="background-color:#d10928;color:white;margin-top:-5pt;">&nbsp;&nbsp;&nbsp;Administrator System&nbsp;&nbsp;&nbsp;</div>
    </div>
    -->
  </div>
</template>

<style scoped>

.imgBorder {
  background : url("../assets/company/login_logo_b.svg");
  width:290px;
  height:111px;
}

</style>

<script>
import { useQuasar } from "quasar"

export default ({
    computed: {
    isLandscape : function() {
      const $q = useQuasar()
      return (($q.screen.width * 1.4) > $q.screen.height)
    }
  }
})
</script>
