<template>
  <div class="column">
    <div class="col column" style="padding:12px;">
      <div class="col-auto justify-start items-center " >
        <div class="title">{{ $t('account.myAccount')}}</div>
      </div>

      <div class="col-auto row" style="background-color: white;border-radius:3px;border:1px solid #ebedf2;margin-bottom:12px;overflow-y: hidden;">
        <q-scroll-area class="col row" style="width:100%;height:36px">
          <q-tabs v-model="tab"
                  dense flat 
                  align="left"
                  indicator-color="transparent"
                  class="text-grey-10" 
                  active-color="red-7">
            <q-tab name="info" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
              {{ $t('common.info') }}
            </q-tab>
            <q-tab name="password" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
              {{ $t('login.password') }}
            </q-tab>
            <q-tab name="setting" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
              {{ $t('account.setting') }}
            </q-tab>
          </q-tabs>
        </q-scroll-area>
      </div>

      <div class="col row bottomArea" style="background-color: white;border-radius:3px;border:1px solid #ebedf2;padding:12px;">
        <q-scroll-area class="col-auto row" style="height:100%; width:100%;" >
          <div class="col">
            <q-tab-panels dense v-model="tab" >
              <q-tab-panel dense name="info" style="padding:0;overflow-x:hidden;">
                <AccountInfo />
              </q-tab-panel>

              <q-tab-panel dense name="password" style="padding:0;overflow-x:hidden;">
                <AccountPassword />
              </q-tab-panel>

              <q-tab-panel name="setting" style="padding:0;overflow-x:hidden;">
                <AccountSetting />
              </q-tab-panel>

            </q-tab-panels>
          </div>
        </q-scroll-area>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  height:33px;
}
</style>

<script>
import { ref } from 'vue'
import { useQuasar } from 'quasar'
import AccountInfo from '@/components/AccountInfo.vue'
import AccountPassword from '@/components/AccountPassword.vue'
import AccountSetting from '@/components/AccountSetting.vue'
import { useTymictStore } from '@/store/tymict.js'
import TymConst from '@/js/tymconstants.js'

export default ({
  components : {
    AccountInfo,
    AccountPassword,
    AccountSetting
  },
  data() {},
  setup() {
    return {
      tab :ref('info')
    }
  },
  computed:{
    isLandscape : function() {
      const $q = useQuasar()
      return (($q.screen.width * 1.3) > $q.screen.height)
    },
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
  },
  created() {},
  mounted() {
    const store = useTymictStore()
    if(store.CurrentPage.Now != TymConst.PAGE_ACCOUNT) {
      // store.CurrentPage.Now = TymConst.PAGE_ACCOUNT
      store.setPage(TymConst.PAGE_ACCOUNT)
    }
  }, 
  unmounted() {
    const store = useTymictStore()
    console.log('accountView.unmounted :', store.CurrentPage, this.$router)
    //setTimeout(() => {
    //   store.setPage(store.CurrentPage.Old)
    //}, 25)
  },
  methods : {
  }
})
</script>
