//import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import quasarUserOptions from './quasar-user-options'
import i18n from './i18n'
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import VueDaumPostcode from 'vue-daum-postcode'
//import Vue3Lottie from 'vue3-lottie'
//import VueGoogleMaps from '@fawmi/vue-google-maps'

let savedRegion = window.localStorage.getItem('storedRegion');
if(savedRegion) {
    Amplify.configure(awsExports[savedRegion]);
} else {
    Amplify.configure(awsExports[0]);
}

const app = createApp(App)

app.use(i18n)
.use(quasarUserOptions)
.use(Quasar, { 
        plugins : {
            Notify,
            Dialog
        },
        config : {
            notify:{
            },
            dialog: {
            }
        }
    })
.use(createPinia())
.use(router)
.use(VueDaumPostcode)
//.use(Vue3Lottie )
/*
.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBYsSysdtDdmIi8dZRreKvlFenNeVh6YHY',
    },
})
*/
app.mount('#app')
