<template>
  <div class="row" style="width: 100%;" :style="isLandscape ? 'height:100%;' : 'height:130%;'">
    <div :class="isLandscape ? 'col row' : 'col column'" style="width:100%; background-color: white;" :style="isLandscape ? 'height:100%;' : 'height:auto;'">
      <div class="col-auto" v-show="viewInfoPart">
        <!-- 왼쪽 부분-->
        <MonitorInfoView ref="infoView" style="height: 100%;" :locaitems="locaItems" :counts="indCounts" :onoff="viewOperating" :ads="viewADSOnly" @locaitems="locaItemsChanged($event)"/> 
      </div>

      <div class="col column" :class="isLandscape ? 'mapBorder_ls' : 'mapBorder_pt'"
           style="border: 1px" :style="isLandscape ? 'background-color:#ebedf2;padding:12px;' : 'border-width:1px 0 0 0; border-style:solid; border-color:#dddddd;border-radius:3px;margin:6px 0 0 0;'">
        <div class="col column" style="background-color: white;border-radius:3px;">
          <!-- 지도 윗부분 콘트롤들 -->
          <div class="col-auto rown" :style="isLandscape ? 'height:40px;' : 'height:auto;'" style="margin: 9px 12px 9px 12px">
            <div class="col items-center justify-between" :class="isLandscape ? 'row' : 'column'" :style="isLandscape ? '' : 'width:auto'">
              <div class="col-auto row items-center" :class="isLandscape ? '' : 'justify-between'" :style="isLandscape ? 'width:auto;' : 'width:100%;'">
                <div class="col-auto row items-center">
                  <!-- 왼쪽 보이기/감추기 -->
                  <q-btn flat dense @click="viewInfoPart = !viewInfoPart" class="col-auto" style="margin: 0; padding: 0">
                    <q-img src="../assets/monitor/machinemap_size_p.svg" fit="fill" width="32px" v-show="viewInfoPart" />
                    <q-img src="../assets/monitor/machinemap_size_m.svg" fit="fill" width="32px" v-show="!viewInfoPart" />
                    <q-tooltip style="font-size: 14px">{{ viewInfoPart ? $t("tooltip.hideMenu") : $t("tooltip.showMenu") }}</q-tooltip>
                  </q-btn>

                  <!-- 타이틀 & refresh -->
                  <div class="col-auto" style="font-size: 16px; font-weight: 500; margin: 0 5px"> {{ $t("dashboardView.machinemap") }} </div>

                  <div class="col-auto column items-end justify-center">
                    <q-btn dense flat @click="onRefreshClicked" v-show="!isLoading">
                      <q-img src="../assets/dashboard/dashboard_mapre_icon.svg" fit="fill" width="26px" v-show="selectedMenu == index"/>
                      <q-tooltip style="font-size: 14px">{{ $t("tooltip.refreshLocation") }}</q-tooltip>
                    </q-btn>

                    <!-- 기다림 시계 표시용 -->
                    <q-btn dense flat disable v-show="isLoading" class="col items-center justify-center" style="margin: 3pt 0 0 0">
                      <q-spinner-clock dense color="red" size="20px" />
                    </q-btn>
                  </div>

                  <div class="col-auto row items-center justify-center" v-show="!isLandscape">
                    <!-- 세로 모드일 때 - 시동켜진 기대만/전체 -->
                    <q-toggle v-model="viewADSOnly" dense size="54px" left-label color="red-7" class="col" style="margin: 0 12px" checked-icon="agriculture" unchecked-icon="radio_button_unchecked"
                              :label="viewADSOnly ? $t('common.adsOnly') : $t('common.viewAll')"
                              @update:model-value="viewADSChanged"/>
                    <q-toggle v-model="viewOperating" dense size="54px" left-label color="red-7" class="col" style="margin: 0 12px" checked-icon="check" unchecked-icon="clear"
                              :label="viewOperating ? $t('common.ign') : $t('common.all')"
                              @update:model-value="viewOpChanged"/>
                  </div>
                </div>

                <div class="col-auto row items-center justify-center" v-show="!isLandscape">
                  <q-btn flat dense icon="close" class="col-auto" size="18px"></q-btn>
                </div>
              </div>

              <div class="row items-center"
                   :class="isLandscape ? 'col-auto justify-end' : 'col justify-between'"
                   :style="isLandscape ? '' : 'width:100%;'">
                <div class="col row items-center" :class="isLandscape ? 'justify-end' : 'justify-center'" style="width: 100%">
                  <q-toggle v-model="viewADSOnly" dense size="54px" left-label="false" color="red-5" class="col-auto"
                            style="width: auto;font-size: 15px;margin: 0 0;min-width:160px;"
                            v-show="isLandscape" checked-icon="agriculture" unchecked-icon="radio_button_unchecked" :label="viewADSOnly ? $t('common.adsOnly') : $t('common.viewAll')" @update:model-value="viewADSChanged"/>
                  <q-toggle v-model="viewOperating" dense size="54px" left-label="false" color="red-5" class="col-auto"
                            style="width: auto;font-size: 15px;margin: 0 12px;min-width:140px;"
                            v-show="isLandscape" checked-icon="check" unchecked-icon="clear" :label="viewOperating ? $t('common.ign') : $t('common.all')" @update:model-value="viewOpChanged"/>
                  <!-- 타입/종류 -->
                  <q-select outlined dense options-dense class="col-auto" color="red-5"
                            v-model="selectedType" :options="getCategory"
                            style="margin: 0 6px;min-width:150px;" :style="isLandscape ? 'max-width:220px' : 'max-width:auto;'"
                            @update:model-value="typeSelectionChanged">
                  </q-select>
                  <!-- 모델 -->
                  <q-select outlined dense options-dense class="col-auto" color="red-5"
                            v-model="selectedModel" :options="modelValues"
                            style="margin: 0 6px;min-width:160px;" :style="isLandscape ? 'max-width:220px' : 'max-width:auto;'"
                            @update:model-value="modelSelectionChanged">
                  </q-select>
                  <!-- 옵션 -->
                  <q-select outlined dense options-dense class="col-auto" color="red-5"
                            v-model="selectedOption" :options="optionValues"
                            style="margin: 0 6px;min-width:120px;" :style="isLandscape ? 'max-width:200px' : 'max-width:auto;'">
                  </q-select>
                  <q-btn class="col-auto" dense no-caps unelevated color="red-7" text-color="white" size="15px" :label="$t('common.search')"
                          @click="searchClicked"
                          style="margin: 0 0 0 3px; width: 80px; height: 100%;font-size:16px;font-weight:500;"
                          :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"/>
                  </div>
              </div>
            </div>
          </div>

          <!-- 지도 -->
          <div class="col row" :style="isLandscape ? 'padding:0 12px 12px 12px' : 'padding:0 3px 12px 12px'">
            <MapView ref="mapView" onclkevt="event" @markerSelected="selectedMarker" class="col mapview"></MapView>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col row items-center justify-center"
       v-show="isChangingAlarm"
       style="position:absolute;left:0;top:0;width:100%;height:100%;background-color:#ffffff01;z-index: 99999;">
    <q-spinner color="red-7" size="120px" :thickness="2.5" />
  </div>
</template>

<style scoped>
.info_portrait {
  height: 300pt;
  width: 100%;
  background-color: aqua;
}
.info_landscape {
  height: 100%;
  width: 300pt;
  background-color: blue;
}

.mapBorder_ls {
  border-radius: 3px;
  border: 1px solid #ebedf2;
  padding: 0px 0 0 0;
  height: auto;
  width: auto;
}
.mapBorder_pt {
  border-radius: 3px;
  padding: 0;
  height: 480px;
  width: auto;
}
.mapview {
  min-width: 100px;
  min-height: 320px;
  width: 100%;
  height: 100%;
}
</style>

<script>
import { ref } from "vue";
// import { useI18n } from 'vue-i18n'
import { useTymictStore } from "../store/tymict";
import MonitorInfoView from "./MonitorInfoView.vue";
import MapView from "./MapView.vue";
import TymFncs from "@/js/tymfunctions.js";
import TymCommon from "@/js/tymcommon.js";
import TymConst from "@/js/tymconstants.js";
import TymAws from "@/js/tymaws.js";

export default {
  data() {
    return {};
  },
  components: {
    MonitorInfoView,
    MapView,
  },
  watch: {
    locationChanged: {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal);
        if (!newVal) {
          // this.updateLocation()
          this.updateLocationInfo(false);
        }
      },
      immediate: true,
    },
    machineStateChanged: {
      handler(newVal, oldVal) {
        this.unusedParam(newVal, oldVal);

        let newLen = TymCommon.isEmpty(newVal) ? 0 : newVal.length;
        let oldLen = TymCommon.isEmpty(oldVal) ? 0 : oldVal.length;
        let maxLen = newLen > oldLen ? newLen : oldLen;

        let indices = [];
        for (let i = 0; i < maxLen; i++) {
          if (!TymCommon.isEmpty(newVal) && !TymCommon.isEmpty(newVal[i])) {
            if (!TymCommon.isEmpty(oldVal)) {
              if (!TymCommon.isEmpty(newVal[i])) {
                if (newVal[i] != oldVal[i]) {
                  indices.push(i);
                }
              } else {
                indices.push(i);
              }
            } else {
              indices.push(i);
            }
          }
        }
        if (indices.length > 0) {
          this.updateIgnState(indices);
        }
      },
      immediate: true,
    },
    webConfigLoaded: {
      handler(newVal, oldVal) {
        this.unusedParam(newVal, oldVal);
      },
      immediate: true,
    },
    salesDataLoaded: {
      handler(newVal, oldVal) {
        if (newVal.length > 0) {
          this.unusedParam(newVal, oldVal);
        }
      },
      immediate: true,
    },
  },
  computed: {
    isLandscape: function () {
      const store = useTymictStore();
      return store.isLandscape;
    },
    isLoading() {
      const store = useTymictStore();
      return store.loadStateMachine;
    },
    isKorean() {
      const store = useTymictStore();
      return store.getLanguage() == "ko";
    },
    getPageTitle: function () {
      return TymFncs.getPageTitle();
    },
    locationChanged: function () {
      const store = useTymictStore();
      return store.machineLocaInfo;
    },
    machineStateChanged: function () {
      const store = useTymictStore();
      let powerState = [];

      for (let i = 0; i < store.machineLocaInfo.length; i++) {
        powerState.push({
          PWR : TymCommon.isEmpty(store.machineLocaInfo[i].PWR) ? "OFF" : store.machineLocaInfo[i].PWR,
          Abnormal: store.machineLocaInfo[i].abnormal
        })
      }
      return powerState;
    },
    getCategory() {
      const store = useTymictStore();
      return store.getMachineTypes();
    },
    webConfigLoaded() {
      const store = useTymictStore();
      return store.WebConfig;
    },
    salesDataLoaded() {
      const store = useTymictStore();
      return store.SalesData.data;
    },
  },
  setup() {
    return {
      viewInfoPart: ref(true), // 좌측 메뉴 보이기 감추기
      viewADSOnly: ref(false),
      viewOperating: ref(false),
      selectedType: ref(null),
      selectedModel: ref(null),
      selectedOption: ref(null),
      modelValues: ref([]),
      optionValues: ref([]),
      map: ref(null),
      machineStartStates: ref(null),
      locaItems: ref(null), // 가져온 데이터
      indCounts: ref([0, 0, 0, 0, 0, 0]), // 각 타입별 종류 표시용 배열

      selectedAlarmType: ref([false, false, false, false, false, false]),
      startupOptions: ref([]), // 모든 타입, 모델, 옵션을 정리해 놓은 변수
      selectionInfos: ref([]), // 타입, 모델, 옵션이 트리 형태로 저장해 놓음
      modelOptionsInfo: ref([]), // 모델과 옵션만 트리형태로 저장해놓음,

      isChangingAlarm: ref(false),

      loadInterval: ref(null)
    };
  },
  created() {},
  mounted() {
    const store = useTymictStore();

    store.CurrentPage.Now = TymConst.PAGE_MONITOR;

    this.loadInterval = setInterval(() => {
      if(store.ModelData.data.length > 0 &&  store.machineLocaInfo.length > 0) {
        clearInterval(this.loadInterval)
        this.loadInterval = null
        setTimeout(() => {
          this.updateLocationInfo(true);
        }, 5)
      }
    }, 200)
  },
  unmounted() {
    try {
      if (this.infoWindow) {
        this.infoWindow.close();
        this.infoWindow = null;
      }
    } catch (ex) {
      console.log("ER) Dashboard unmounted...", ex);
    }
  },
  methods: {
    unusedParam() {
      //
    },
    updateLocationInfo(isFirstTime) {
      let firstTime = false;
      if (!TymCommon.isEmpty(isFirstTime) && isFirstTime) {
        firstTime = true;
      }

      if (TymConst.IS_DEVELOPMENT) {
        console.log('-----------------------------------------')
        console.log(' monitorView.updateLocationInfo ', isFirstTime)
      }

      TymAws.getStartedMachines()
        .then((data) => {
          if (TymConst.IS_DEVELOPMENT) {
            console.log("started Ok :", data);
          }

          this.machineStartStates = data;
          setTimeout(() => {
            this.getMonitorInfo();
            this.updateLocation(firstTime);
          }, 1);
        })
        .catch((error) => {
          console.log("started Er :", error);
          setTimeout(() => {
            this.getMonitorInfo();
            this.updateLocation(firstTime);
          }, 1);
        });
    },
    onRefreshClicked() {
      const store = useTymictStore();
      store.refreshCurrentLocation(true, true);
    },
    // select 콘트롤들을 위한 값들을 가져온다.
    getMonitorInfo() {
      const store = useTymictStore();
      this.selectionInfos = [];
      this.modelOptionsInfo = [];
      this.startupOptions = {
        Types: [],
        Models: [],
        Options: [],
      };

      if (TymConst.IS_DEVELOPMENT) {
        console.log('-----------------------------------------')
        console.log(' monitorView.getMonitorInfo #111 :', store.ModelData.data)
      }

      store.ModelData.data.forEach((md) => {
        if (!this.startupOptions.Types.find((x) => x == md.type)) {
          this.startupOptions.Types.push(md.type);
        }
        if (!this.startupOptions.Models.find((x) => x == md.model)) {
          this.startupOptions.Models.push(md.model);
        }
        if (md.option != "0" && md.option != "NONE") {
          if (!this.startupOptions.Options.find((x) => x == md.option)) {
            this.startupOptions.Options.push(md.option);
          }
        }

        let fndType = this.selectionInfos.find((x) => x.Name == md.type);
        if (!fndType) {
          fndType = {
            Name: md.type,
            Models: [
              {
                Name: md.model,
                Options: [
                  {
                    Name: md.option,
                    Fullname: md.model + md.option,
                  },
                ],
              },
            ],
          };
          this.selectionInfos.push(fndType);
        } else {
          let mdFound = fndType.Models.find((x) => x.Name == md.model);
          if (!mdFound) {
            mdFound = {
              Name: md.model,
              Options: [
                {
                  Name: md.option,
                  Fullname:
                    md.option != "0" && md.option != "NONE"
                      ? md.model + md.option
                      : md.model,
                },
              ],
            };
            fndType.Models.push(mdFound);
          } else {
            let optFound = mdFound.Options.find((x) => x.Name == md.option);
            if (!optFound) {
              optFound = {
                Name: md.option,
                Fullname:
                  md.option != "0" && md.option != "NONE"
                    ? md.model + md.option
                    : md.model,
              };
              mdFound.Options.push(optFound);
            }
          }
        }

        let mdFound = this.modelOptionsInfo.find((x) => x.Name == md.model);
        if (!mdFound) {
          if (md.option != "0" && md.option != "NONE") {
            mdFound = {
              Name: md.model,
              Options: [
                {
                  Name: md.option,
                  Fullname:
                    md.option != "0" && md.option != "NONE"
                      ? md.model + md.option
                      : md.model,
                },
              ],
            };
            this.modelOptionsInfo.push(mdFound);
          }
        } else {
          if (md.option != "0" && md.option != "NONE") {
            let optFound = mdFound.Options.find((x) => x.Name == md.option);
            if (!optFound) {
              optFound = {
                Name: md.option,
                Fullname:
                  md.option != "0" && md.option != "NONE"
                    ? md.model + md.option
                    : md.model,
              };
              mdFound.Options.push(optFound);
            }
          }
        }
      });

      this.startupOptions.Types.sort((a, b) => {
        return a.localeCompare(b);
      });
      this.startupOptions.Models.sort((a, b) => {
        return a.localeCompare(b);
      });
      this.startupOptions.Options.sort((a, b) => {
        return a.localeCompare(b);
      });
      this.modelOptionsInfo.sort((a, b) => {
        return a.Name.localeCompare(b.Name);
      });

      this.selectionInfos.sort((a, b) => {
        return a.Name.localeCompare(b.Name);
      });
      this.selectionInfos.forEach((info) => {
        info.Models.sort((a, b) => {
          return a.Name.localeCompare(b.Name);
        });
        info.Models.forEach((md) => {
          md.Options.sort((a, b) => {
            return a.Fullname.localeCompare(b.Fullname);
          });
        });
      });
      this.modelOptionsInfo.forEach((model) => {
        model.Options.sort((a, b) => {
          return a.Fullname.localeCompare(b.Fullname);
        });
      });

      // console.log("getMonitorInfo selectionInfos :", this.selectionInfos);
      // console.log('getMonitorInfo getCategory :', this.getCategory)
      if (TymConst.IS_DEVELOPMENT) {
        console.log('-----------------------------------------')
        console.log(' monitorView.getMonitorInfo #selectionInfos:', this.selectionInfos)
      }

      if (!this.getCategory.find((x) => x == this.selectedType))
        this.selectedType = this.getCategory[0];

      this.modelValues = [];
      this.modelValues.push(this.$t("common.model"));
      this.modelValues = this.modelValues.concat(this.startupOptions.Models);
      if (!this.modelValues.find((x) => x == this.selectedModel))
        this.selectedModel = this.modelValues[0];

      this.optionValues = [];
      this.optionValues.push(this.$t("common.option"));
      this.optionValues = this.optionValues.concat(this.startupOptions.Options);
      if (!this.optionValues.find((x) => x == this.selectedOption))
        this.selectedOption = this.optionValues[0];
    },
    calcCount() {
      this.indCounts = [0, 0, 0, 0, 0, 0];
      if(!TymCommon.isEmpty(this.locaItems)) {
        this.locaItems.forEach(item => {
          this.indCounts[1] += !(item.abnormal[0] || item.abnormal[1] || item.abnormal[2] || item.abnormal[3] ) ? 1 : 0;
          this.indCounts[2] += item.abnormal[0] ? 1 : 0;
          this.indCounts[3] += item.abnormal[1] ? 1 : 0;
          this.indCounts[4] += item.abnormal[2] ? 1 : 0;
          this.indCounts[5] += item.abnormal[3] ? 1 : 0;
        })
        this.indCounts[0] = this.locaItems.length;

        console.log('MonitorView.calcCount #FILTER ', this.indCounts)
      } else {
        const store = useTymictStore();
        if (store.machineLocaInfo.length > 0) {
          let i = 0;
          for (i = 0; i < store.machineLocaInfo.length; i++) {
            this.indCounts[1] += !(store.machineLocaInfo[i].abnormal[0] || store.machineLocaInfo[i].abnormal[1] || store.machineLocaInfo[i].abnormal[2] || store.machineLocaInfo[i].abnormal[3] ) ? 1 : 0;
            this.indCounts[2] += store.machineLocaInfo[i].abnormal[0] ? 1 : 0;
            this.indCounts[3] += store.machineLocaInfo[i].abnormal[1] ? 1 : 0;
            this.indCounts[4] += store.machineLocaInfo[i].abnormal[2] ? 1 : 0;
            this.indCounts[5] += store.machineLocaInfo[i].abnormal[3] ? 1 : 0;
          }
          this.indCounts[0] = store.SalesData.data.length;
          // this.indCounts[0] = store.machineLocaInfo.length
          // console.log('MonitorView.calcCount', this.indCounts)

          if(TymConst.IS_DEVELOPMENT) {
            store.SalesData.data.forEach(sale => {
              if(!store.machineLocaInfo.find(x => x.machineNo == sale.machineNo)) {
                console.log('in Sales not in LOCA', sale)
              }
            })
          }
        }
        console.log('MonitorView.calcCount #NO FILTER ', this.indCounts)
      }
    },
    // 왼쪽 MachineInfoView에서 보내는 이벤트
    // selectedItems : 선택한 아이템들의 boolean 배열([전체,정상,응급,고장,소모품,배터리])
    locaItemsChanged(selectedItems, isFirstTime) {
      this.unusedParam(isFirstTime);

      let haveToUpdate = false
      if(!isFirstTime) {
        for(let i = 0; i < selectedItems.length; i++) {
          if(this.selectedAlarmType[i] != selectedItems[i]) {
            if(this.indCounts[i] > 0) {
              haveToUpdate = true
              break
            }
          }
        }
      } else {
        haveToUpdate = true
      }

      if(!haveToUpdate) {
        return
      }
      if(!isFirstTime) {
        this.isChangingAlarm = true
      }

      setTimeout(() => {
        const store = useTymictStore();
        this.locaItems = [];
        this.selectedAlarmType = Array.from(selectedItems)

        if (store.machineLocaInfo.length > 0) {
          let i = 0;
          this.locaItems = Array.from(store.machineLocaInfo)

          if (TymConst.IS_DEVELOPMENT) {
            console.log("monitorView.locaItemsChanged #111 :", selectedItems, this.locaItems);
          }

          if (!TymCommon.isEmpty(this.machineStartStates)) {
            // 시동 상태 업데이트
            for (i = 0; i < this.locaItems.length; i++) {
              let power = this.machineStartStates.find( (x) => x.SN == this.locaItems[i].serialNo);
              if (!TymCommon.isEmpty(power)) {
                if (!TymCommon.isEmpty(power.IGNITION) && power.IGNITION == "1") {
                  this.locaItems[i].PWR = "ON";
                } else {
                  this.locaItems[i].PWR = "OFF";
                }
              } else {
                this.locaItems[i].PWR = "OFF";
              }

              let sale = store.SalesData.data.find((x) => x.machineNo == this.locaItems[i].machineNo);
              if (sale) {
                this.locaItems[i].SD = sale.SD;
                this.locaItems[i].MON = sale.MON;
                this.locaItems[i].address = sale.address;
                this.locaItems[i].dealer = sale.dealer;
                this.locaItems[i].manDate = sale.manDate;
                this.locaItems[i].phone = sale.mobile;
                this.locaItems[i].saleDate = sale.saleDate;
                this.locaItems[i].uid = sale.uid;
                this.locaItems[i].uuid_a = sale.uuid_a;
                this.locaItems[i].uuid_b = sale.uuid_b;
                this.locaItems[i].uuid_c = sale.uuid_c;
                this.locaItems[i].uuid_d = sale.uuid_d;
                this.locaItems[i].machType = this.DTYPEtoMachineType(sale);

                if(TymConst.IS_DEVELOPMENT) {
                  if(this.locaItems[i].machType!='트랙터' && this.locaItems[i].machType!='AFTERMARKET' && this.locaItems[i].machType!='이앙기') {
                    console.log('0-000000', sale, this.DTYPEtoMachineType(sale))
                  }
                }
              }
            }
          }
        }

        if (this.$refs.mapView) {
          if (TymConst.IS_DEVELOPMENT) {
            console.log("monitorView.locaItemsChanged #333 :", this.indCounts, this.locaItems);
          }
          this.$refs.infoView.updateData(this.indCounts, this.locaItems);
        }

        if (isFirstTime) {
          setTimeout(() => {
            if (this.$refs.mapView) {
              if (TymConst.IS_DEVELOPMENT) {
                console.log("MonitorView.locaItemsChanged [FIRSTTIME]", selectedItems, this.locaItems);
              }
              let tempLoca = []
              this.locaItems.forEach(item => {
                if ((selectedItems[0] || selectedItems[2]) && item.abnormal[0]) {
                  tempLoca.push(item);
                } else if ((selectedItems[0] || selectedItems[3]) && item.abnormal[1] ) {
                  tempLoca.push(item);
                } else if ((selectedItems[0] || selectedItems[4]) && item.abnormal[2] ) {
                  tempLoca.push(item);
                } else if ( (selectedItems[0] || selectedItems[5]) && item.abnormal[3] ) {
                  tempLoca.push(item);
                }
                if ((selectedItems[0] || selectedItems[1]) && !( item.abnormal[0] || item.abnormal[1] || item.abnormal[2] || item.abnormal[3] ) ) {
                  tempLoca.push(item);
                }
              })

              this.$refs.mapView.updateMapData(tempLoca, selectedItems, undefined, isFirstTime);
            }

            setTimeout(() => {
              this.searchClicked();
            }, 5)
          }, 5);
        } else {
          setTimeout(() => {
            // console.log(this.selectedAlarmType, this.indCounts)
            this.searchClicked();
          }, 5);
        }
      }, 5)

      setTimeout(() => {
        this.isChangingAlarm = false
      }, 300)
    },

    DTYPEtoMachineType(sale) {
      let result = sale.machineType
      const store = useTymictStore()

      if(TymCommon.isEmpty(sale.machineType) || sale.machineType.toUpperCase() == 'NONE') {
        let model = TymCommon.getModelFullname(sale.MON)
        let modelData = store.ModelData.data.find(x => x.model == model.Model)

        //console.log('--------------------', modelData)
        if(modelData && !TymCommon.isEmpty(modelData.type)) {
          result = modelData.type
        }
      }

      return result
    },
    // 마커 클릭시 실행할 함수
    // one : 클릭한 마커에 해당하는 기대 정보
    selectedMarker(one) {
      if (TymConst.IS_DEVELOPMENT) {
        console.log("MonitorView.selectedMarker :", one);
      }

      if (this.$refs.mapView) {
        this.$refs.infoView.updateMarkerData(one);
      }
    },

    // watch 에 의해 호출됨
    updateLocation(isFirstTime) {
      if (TymConst.IS_DEVELOPMENT) {
        console.log("MonitorView.updateLocation", isFirstTime);
      }
      /*
      if(this.$refs.manageMap) {
        if(TymConst.IS_DEVELOPMENT) {
          console.log('machineInfoView.updateIgnState :', this.machineValue[this.curIndex].machineNo, isON,
          TymCommon.isEmpty(this.machineValue[this.curIndex].abnormal) ? [false, false, false, false] : this.machineValue[this.curIndex].abnormal)
        }
        this.$refs.manageMap.updateMachineState(this.machineValue[this.curIndex].machineNo, isON,
        TymCommon.isEmpty(this.machineValue[this.curIndex].abnormal) ? [false, false, false, false] : this.machineValue[this.curIndex].abnormal)
      }
      */

      if (this.$refs.infoView) {
        this.locaItemsChanged(this.$refs.infoView.selectedMenu, isFirstTime);
      }
    },
    typeSelectionChanged(value) {
      if (value == this.$t("common.type")) {
        this.modelValues = [];
        this.modelValues.push(this.$t("common.model"));
        this.modelValues = this.modelValues.concat(this.startupOptions.Models);
        this.selectedModel = this.modelValues[0];

        this.modelSelectionChanged(this.selectedModel);
      } else {
        let found = this.selectionInfos.find((x) => x.Name == value);
        this.modelValues = [];
        if (found) {
          this.modelValues.push(this.$t("common.model"));
          found.Models.forEach((md) => {
            this.modelValues.push(md.Name);
          });
          this.selectedModel = this.modelValues[0];
          this.modelSelectionChanged(this.selectedModel);
        } else {
          this.selectedModel = "";
          this.modelSelectionChanged("");
        }
      }
    },
    modelSelectionChanged(value) {
      if (value == this.$t("common.model")) {
        this.optionValues = [];
        this.optionValues.push(this.$t("common.option"));
        this.optionValues = this.optionValues.concat(
          this.startupOptions.Options
        );
        this.selectedOption = this.optionValues[0];
      } else {
        let found = this.modelOptionsInfo.find((x) => x.Name == value);
        this.optionValues = [];
        this.optionValues.push(this.$t("common.option"));
        if (found) {
          found.Options.forEach((opt) => {
            this.optionValues.push(opt.Name);
          });
        }
        this.selectedOption = this.optionValues[0];
      }
      if(TymConst.IS_DEVELOPMENT) {
        console.log("Monitor.modelSelectionChanged", value, this.selectedOption, this.optionValues);
      }
    },
    viewOpChanged(value, evt) {
      this.unusedParam(value, evt);
      this.searchClicked();
    },
    viewADSChanged(value, evt) {
      this.unusedParam(value, evt);
      this.searchClicked();
    },
    searchClicked() {
      let filters = [];

      if (TymConst.IS_DEVELOPMENT) {
        console.log("monitorView.searchClicked #111 :", this.selectedType, this.locaItems.filter(x => x.machType != '트랙터' && x.machType != 'AFTERMARKET' && x.machType != '이앙기'));
      }

      if (this.selectedType != this.getCategory[0]) {
        filters = Array.from(this.locaItems.filter((x) => x.machType == this.selectedType));
      } else {
        filters = Array.from(this.locaItems);
      }

      if (this.selectedModel != this.modelValues[0]) {
        filters = filters.filter((x) => {
          let split = x.MON.split("_");
          return this.selectedModel == split[0];
        })
      }

      if (this.selectedOption != this.optionValues[0]) {
        filters = filters.filter((x) => {
          let split = x.MON.split("_");
          if (split.length == 2) {
            return this.selectedOption == split[1];
          } else {
            return this.selectedOption == split[0];
          }
        });
      }

      if (this.viewADSOnly) {
        filters = filters.filter((x) => x.serialNo.startsWith('A'));
      }

      if (this.viewOperating) {
        filters = filters.filter((x) => x.PWR == "ON");
      }

      if (TymConst.IS_DEVELOPMENT) {
        console.log("searchClicked #1:", this.locaItems, this.selectedType, this.selectedModel, this.selectedOption);
        console.log("searchClicked #2:", this.indCounts, filters);
      }
      if (this.$refs.mapView) {
        let tempLoca = []
        filters.forEach(item => {
          if ((this.selectedAlarmType[0] || this.selectedAlarmType[2]) && item.abnormal[0]) {
            tempLoca.push(item);
          } else if ((this.selectedAlarmType[0] || this.selectedAlarmType[3]) && item.abnormal[1] ) {
            tempLoca.push(item);
          } else if ((this.selectedAlarmType[0] || this.selectedAlarmType[4]) && item.abnormal[2] ) {
            tempLoca.push(item);
          } else if ( (this.selectedAlarmType[0] || this.selectedAlarmType[5]) && item.abnormal[3] ) {
            tempLoca.push(item);
          }
          if ((this.selectedAlarmType[0] || this.selectedAlarmType[1]) && !( item.abnormal[0] || item.abnormal[1] || item.abnormal[2] || item.abnormal[3] ) ) {
            tempLoca.push(item);
          }
        })
        
        this.$refs.mapView.updateMapData(tempLoca /*, selectedItems */);
      }

      if (this.$refs.infoView) {
        this.indCounts = [0, 0, 0, 0, 0, 0]
        filters.forEach(one => {
          this.indCounts[1] += !(one.abnormal[0] || one.abnormal[1] || one.abnormal[2] ||one.abnormal[3] ) ? 1 : 0;
          this.indCounts[2] += one.abnormal[0] != false ? 1 : 0;
          this.indCounts[3] += one.abnormal[1] != false ? 1 : 0;
          this.indCounts[4] += one.abnormal[2] != false ? 1 : 0;
          this.indCounts[5] += one.abnormal[3] != false ? 1 : 0;
        })
        /*
        const store = useTymictStore()
        this.indCounts[0] = store.SalesData.data.length
        */
        if(!TymCommon.isEmpty(filters)) {
          this.indCounts[0] = filters.length
        } else {
          this.indCounts[0] = 0
        }

        if (TymConst.IS_DEVELOPMENT) {
          console.log("searchClicked #3:", this.indCounts, filters);
      }

        this.$refs.infoView.updateData(this.indCounts, filters);
      }
    },
    updateIgnState(changes) {
      const store = useTymictStore();

      if (this.$refs.mapView) {
        changes.forEach((chg) => {

          if(store.machineLocaInfo[chg].machineNo == 'FWV10D') {
            console.log("--------------- FWV10D", store.machineLocaInfo[chg].PWR, store.machineLocaInfo[chg].abnormal );
          }

          this.$refs.mapView.updateMachineState(store.machineLocaInfo[chg].machineNo,
                                              TymCommon.isEmpty(store.machineLocaInfo[chg].PWR) ? false : store.machineLocaInfo[chg].PWR == "ON",
                                              store.machineLocaInfo[chg].abnormal);
        });
      }
    },
  },
};
</script>
