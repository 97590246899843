<template>
  <div class="column">
    <div class="col column" style="padding: 0 12px 12px 12px">
      <div class="col column">
        <div class="col-auto row items-center justify-between" style="height: 45px; width: 100%">
          <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
            {{ getPageTitle }}
          </div>
        </div>
        <q-splitter
          v-model="mainSplitter"
          :limits="[30, 80]"
          :horizontal="!isLandscape"
          :separator-style="isLandscape ? 'width:12px' : 'height:12px'"
          class="col"
          style="position: relative"
          separator-class="transparent"
        >
          <template v-slot:before>
            <div
              class="col column"
              style="position: relative;border-radius: 3px;background-color: white;padding: 5px 12px 12px 12px;width: 100%;height: 100%;">
              <!-- 좌측 상단 검색등 -->
              <div class="col-auto row justify-between items-center" style="margin: 0 0 5px 0; min-height: 52px">
                <div class="col-auto row items-center justify-start">
                  <div class="col-auto" style="font-size: 16px; font-weight: 500"
                       :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo'">
                    {{ $t("customer.list") }}
                  </div>
                  <div class="col-auto"
                        style="margin: 0 0 0 24px;font-size: 16px;font-weight: 500;color: #eb0028;"
                        v-show="rowCount > 0"
                        :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo'">
                    {{ rowCount }}
                  </div>
                  <q-toggle
                    class="col-auto"
                    dense
                    color="red-7"
                    style="margin: 0 0 0 24px; padding: 0"
                    :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"
                    v-model="isSoldOnly"
                    :label="isSoldOnly ? $t('customer.showSoldOnly') : $t('customer.showAll')">
                  </q-toggle>
                </div>
                <div class="col-auto row justify-end items-center">
                  <q-form class="col-auto self-center" autocomplete="off" @submit="searchStart" >
                    <q-input
                      class="col-auto"
                      outlined
                      flat
                      dense
                      clearable
                      color="red-7"
                      style="margin: 0 6px; width: 220px"
                      v-model="searchNamePhone"
                      :placeholder="$t('placeholder.nameorphone')"
                    >
                      <template v-slot:append>
                        <q-icon size="sm" name="search"> </q-icon>
                      </template>
                    </q-input>
                  </q-form>

                  <q-btn flat dense icon="add" color="grey-9" :disable="agreePersonalOrg == '' && agreeLocationOrg == '' && agreePolicyOrg == '' && agreeLocationInfoOrg == ''"
                          @click="showEULA()">
                  </q-btn>
                </div>
              </div>
              <div class="col" ref="tableContainer" style="position: relative;font-size: 15px; font-weight: 400;border: 1px solid #cccccc;">
                <q-resize-observer @resize="onResize" />
                <!-- 테이블용 -->
                <q-table
                  dense
                  flat
                  class="col"
                  ref="customerTable"
                  style="position: absolute; width: 100%; height: 100%"
                  separator="cell"
                  selection="single"
                  virtual-scroll
                  hide-no-data
                  row-key="index"
                  :filter="[isSoldOnly, searchNamePhone]"
                  :filter-method="userFilterMethod"
                  :visible-columns="visibleColumns"
                  :columns="columnData"
                  :rows="getRowData"
                  :pagination="curPagination"
                  :rows-per-page-options="maxItemPerPage"
                  @update:pagination="updatePagination"
                >
                  <template v-slot:header="props">
                    <q-th
                      no-caps
                      class="thSticky"
                      v-for="(col, idx) in props.cols"
                      :key="col.name"
                      style="width: 50px"
                      :style="
                        idx == 0 ? 'text-align: left;' : 'text-align: left;'
                      "
                      :props="props"
                    >
                      {{ col.label }}
                    </q-th>
                  </template>
                  <template v-slot:body="props">
                    <q-tr :props="props" @click="dataRowClicked(props.pageIndex, props.row)">
                      <q-td
                        key="rowID"
                        :props="props"
                        style="border-bottom: 1px solid #dddddd;cursor: pointer;width: 30px;"
                      >
                        <div class="row items-center justify-start no-wrap">
                          <q-radio
                            v-model="modelRowSelection"
                            size="sm"
                            :val="props.row.index"
                            dense
                            color="red"
                            style="margin-right: 9px"
                            @update:model-value="changeSelection"
                          />
                          <div
                            style="font-size: 15px"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;'
                                : 'font-family:Prometo;'
                            "
                          >
                            {{ props.rowIndex + 1 }}
                          </div>
                        </div>
                      </q-td>
                      <q-td
                        key="name"
                        :props="props"
                        style="
                          border-bottom: 1px solid #dddddd;
                          cursor: pointer;
                          width: 140px;
                        "
                      >
                        <div class="row items-center justify-start no-wrap">
                          <!--
                          <q-radio v-model="modelRowSelection" size="sm" :val="props.row.index" dense color="red" style="margin-right:9px" @update:model-value="changeSelection"/>
                          -->
                          <div
                            style="font-size: 15px"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;'
                                : 'font-family:Prometo;'
                            "
                          >
                            {{ props.row.name }}
                          </div>
                        </div>
                      </q-td>
                      <q-td
                        key="id"
                        :props="props"
                        style="
                          border-bottom: 1px solid #dddddd;
                          cursor: pointer;
                          width: 140px;
                        "
                      >
                        <div class="row items-center justify-start">
                          <div style="font-size: 15px; font-family: Prometo">
                            {{ props.row.id }}
                          </div>
                        </div>
                      </q-td>
                      <q-td
                        key="phone"
                        :props="props"
                        style="
                          border-bottom: 1px solid #dddddd;
                          cursor: pointer;
                        "
                      >
                        <div class="row items-center justify-start">
                          <div style="font-size: 15px; font-family: Prometo">
                            {{ props.row.phone }}
                          </div>
                        </div>
                      </q-td>
                      <!--
                      <q-td key="mon" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:20%;">
                        <div class="row items-center justify-start">
                          <div style="font-size:15px;font-family:Prometo;">
                            {{ props.row.mon }}
                          </div>
                        </div>
                      </q-td>
                      -->
                      <q-td
                        key="regDate"
                        :props="props"
                        style="
                          border-bottom: 1px solid #dddddd;
                          cursor: pointer;
                        "
                      >
                        <div class="row justify-start items-center">
                          <div style="font-size: 15px; font-family: Prometo">
                            {{ props.row.regDate }}
                          </div>
                        </div>
                      </q-td>
                    </q-tr>
                  </template>
                  <template v-slot:bottom>
                    <div
                      class="col row items-center justify-end"
                      style="width: 100%; height: 100%"
                    >
                      <q-pagination
                        class="col row items-center justify-end"
                        :max="pageCount"
                        :max-pages="5"
                        v-model="currentPageIndex"
                        outline
                        dense
                        gutter="3px"
                        size="12px"
                        color="grey-9"
                        direction-links
                        boundary-links
                        active-design="unelevated"
                        active-color="red-7"
                        active-text-color="white"
                        @update:model-value="itemPageChanged"
                      >
                      </q-pagination>
                    </div>
                  </template>
                </q-table>
              </div>
              <div class="row items-center justify-center"
                    style="position: absolute;z-index: 10;left: 0;top: 0;right: 0;bottom: 0;border-radius: 3px;"
                    :style="isLoadingData || isAddUser || isEditUser ? 'background-color: #eeeeee80;' : 'background-color: #01010101;'"
                    v-show="isLoadingData || isLoadingPersonal || isAddUser || isEditUser">
                <!-- 왼쪽 덮는 목적 -->
                <q-spinner-tail
                  dense
                  color="red-7"
                  size="100px"
                  v-show="!isLoadingPersonal && !isAddUser && !isEditUser"
                />
              </div>
            </div>
          </template>
          <template v-slot:separator>
            <div
              style="background-color: #f2f2f2"
              class="row items-center justify-center"
            >
              <div
                :class="isLandscape ? 'vertSeparator' : 'horizSeparator'"
              ></div>
            </div>
          </template>
          <template v-slot:after>
            <div
              class="row"
              style="
                border-radius: 3px;
                margin: 0 0 0 0;
                position: relative;
                width: 100%;
                height: 100%;
              "
            >
              <q-splitter
                v-model="subSplitter"
                :limits="[30, 70]"
                horizontal
                class="col"
                separator-class="transparent"
              >
                <template v-slot:before>
                  <div
                    class="col column"
                    style="
                      border-radius: 3px;
                      background-color: white;
                      margin: 0;
                      padding: 5px 12px 12px 12px;
                      width: 100%;
                      height: 100%;
                    "
                  >
                    <!-- 고객 정보 -->
                    <div class="col-auto row justify-between items-center">
                      <div
                        class="col-auto row items-center justify-center"
                        style="height: 40px"
                      >
                        <div
                          class="col-auto"
                          style="font-size: 16px; font-weight: 500"
                          :style="
                            isKorean ? 'font-family:Noto Sans KR;' : 'Prometo'
                          "
                        >
                          {{ $t("customer.info") }}
                        </div>
                      </div>
                      <div
                        class="col row justify-end items-end"
                        style="margin: 0 0 6px 0; width: 100%"
                        v-show="selectedUserInfo.ID.length > 0"
                      >
                        <q-btn no-caps unelevated flat dense icon="edit" size="md" color="grey-9" @click="editUser()" >
                          <q-tooltip style="font-size: 14px">{{ $t("tooltip.editJob") }}</q-tooltip>
                        </q-btn>
                        <q-btn
                          no-caps
                          unelevated
                          flat
                          dense
                          icon="article"
                          color="grey-9"
                          @click="printEULA()"
                        >
                          <q-tooltip style="font-size: 14px">{{
                            $t("tooltip.printEULA")
                          }}</q-tooltip>
                        </q-btn>
                      </div>
                    </div>
                    <div class="col row" style="">
                      <q-scroll-area class="col" style="width: 100%;height: 100%;border-top: 2px solid #ebedf2;border-bottom: 2px solid #ebedf2;">
                        <table style="width: 100%; height: auto">
                          <tr style="background-color: #f8f8f8;width: 100%;min-height: 34px;">
                            <td width="110px" style="text-align: left; vertical-align: middle" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">
                              {{ $t("common.name") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto">{{ selectedUserInfo.Name }}</td>
                          </tr>
                          <tr
                            style="
                              width: 100%;
                              min-height: 34px;
                              border-top: 1px solid #ebedf2;
                              border-bottom: 1px solid #ebedf2;
                            "
                          >
                            <td
                              width="110px"
                              style="font-size: 15px; text-align: left"
                              :style="
                                isKorean
                                  ? 'font-family:Noto Sans KR;'
                                  : 'font-family:Prometo;'
                              "
                            >
                              {{ $t("common.id") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td style="font-size: 15px; font-family: Prometo">
                              {{ selectedUserInfo.ID }}
                            </td>
                          </tr>
                          <tr
                            style="
                              width: 100%;
                              min-height: 34px;
                              background-color: #f8f8f8;
                              width: 100%;
                            "
                          >
                            <td width="110px" style="font-size: 15px; text-align: left" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">
                              {{ $t("customer.birth") }}
                            </td>
                            <td width="3px">
                              <div style="background-color: #eee;width: 1px;height: 100%;">&nbsp;</div>
                            </td>
                            <td class="row items-center justify-between" style="font-size: 15px; font-family: Prometo">
                              <div>{{ isShowBirthday ? selectedUserInfo.Birth : ( selectedUserInfo.Birth != '' ? '**********' : '') }}</div>
                              <q-btn flat dense color="grey-7" icon="visibility" v-show="selectedUserInfo.Birth != ''"  @click="doShowBirthday"></q-btn>
                            </td>
                          </tr>
                          <tr style="width: 100%;min-height: 34px;border-top: 1px solid #ebedf2;border-bottom: 1px solid #ebedf2;">
                            <td width="110px" style="font-size: 15px; text-align: left" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">
                              {{ $t("customer.regDate") }}
                            </td>
                            <td width="3px">
                              <div style="background-color: #eee;width: 1px;height: 100%;">&nbsp;</div>
                            </td>
                            <td style="font-size: 15px; font-family: Prometo">
                              {{ selectedUserInfo.RegDate }}
                            </td>
                          </tr>
                          <tr style="width: 100%;min-height: 34px;background-color: #f8f8f8;width: 100%;">
                            <td width="110px" style="font-size: 15px; text-align: left" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">
                              {{ $t("group.phone") }}
                            </td>
                            <td width="3px">
                              <div style="background-color: #eee;width: 1px;height: 100%;">&nbsp;</div>
                            </td>
                            <td style="font-size: 15px; font-family: Prometo">
                              {{ selectedUserInfo.Phone }}
                            </td>
                          </tr>
                          <tr
                            style="
                              width: 100%;
                              min-height: 34px;
                              border-top: 1px solid #ebedf2;
                              border-bottom: 1px solid #ebedf2;
                            "
                          >
                            <td
                              width="110px"
                              style="
                                font-size: 15px;
                                text-align: left;
                                font-family: Prometo;
                              "
                            >
                              E-mail
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td style="font-size: 15px; font-family: Prometo">
                              {{ selectedUserInfo.Email }}
                            </td>
                          </tr>
                          <tr style="width: 100%;min-height: 34px;background-color: #f8f8f8;width: 100%;">
                            <td width="110px" style="font-size: 15px; text-align: left" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">
                              {{ $t("group.address1") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td
                              style="font-size: 15px"
                              :style="
                                isKorean
                                  ? 'font-family:Noto Sans KR;'
                                  : 'font-family:Prometo;'
                              "
                            >
                              {{ selectedUserInfo.Address1 }}
                            </td>
                          </tr>
                          <tr
                            style="
                              width: 100%;
                              min-height: 34px;
                              border-top: 1px solid #ebedf2;
                              border-bottom: 1px solid #ebedf2;
                            "
                          >
                            <td
                              width="110px"
                              style="font-size: 15px; text-align: left"
                              :style="
                                isKorean
                                  ? 'font-family:Noto Sans KR;'
                                  : 'font-family:Prometo;'
                              "
                            >
                              {{ $t("group.address2") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td
                              style="font-size: 15px"
                              :style="
                                isKorean
                                  ? 'font-family:Noto Sans KR;'
                                  : 'font-family:Prometo;'
                              "
                            >
                              {{ selectedUserInfo.Address2 }}
                            </td>
                          </tr>
                          <tr
                            style="
                              width: 100%;
                              min-height: 34px;
                              background-color: #f8f8f8;
                              width: 100%;
                              border-bottom: 1px solid #ebedf2;
                            "
                            v-show="isKorea"
                          >
                            <td
                              width="110px"
                              style="font-size: 15px; text-align: left"
                              :style="
                                isKorean
                                  ? 'font-family:Noto Sans KR;'
                                  : 'font-family:Prometo;'
                              "
                            >
                              {{ $t("customer.gender") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td
                              style="font-size: 15px"
                              :style="
                                isKorean
                                  ? 'font-family:Noto Sans KR;'
                                  : 'font-family:Prometo;'
                              "
                            >
                              {{ selectedUserInfo.Gender }}
                            </td>
                          </tr>
                        </table>
                      </q-scroll-area>
                    </div>
                  </div>
                </template>
                <template v-slot:separator>
                  <div
                    style="background-color: #f2f2f2; width: 100%; height: 12px"
                    class="row items-center justify-center"
                  >
                    <div class="horizSeparator" style=""></div>
                  </div>
                </template>
                <template v-slot:after>
                  <!-- v-show="machinesInfo.length > 0"  -->
                  <div
                    class="col column justify-center items-center"
                    style="
                      width: 100%;
                      height: 100%;
                      padding: 0 0;
                      min-height: 180px;
                      padding: 12px 12px 0 12px;
                      background-color: white;
                    "
                  >
                    <!-- 기대 정보 -->
                    <div
                      class="column"
                      style="height: 100%; width: 100%"
                      :style="isLandscape ? 'margin-bottom:12px;' : 'margin-bottom:3px;'">
                      <div class="col-auto row justify-between">
                        <div class="col-auto row items-center" style="margin: 0 6px 0 0">
                          <q-img width="50px" height="50px" :src="getIgnState"></q-img>
                        </div>

                        <div class="col column items-start justify-start" style="margin: 6px 0 0 3px" >
                          <div
                            class="col"
                            :style="
                              isKorean ? 'font-family:Noto Sans KR;' : 'Prometo'
                            "
                            style="
                              font-size: 18px;
                              font-weight: 500;
                              color: #212529;
                              margin: 0 3px;
                            "
                          >
                            {{ $t("machineInfo.title") }}
                          </div>
                          <div
                            class="col row items-center justify-between"
                            style="margin: 0 0 3px 0"
                          >
                            <!-- 기대 상태 아이콘들, 정상/긴급/고장/소모품/저전압-->
                            <div
                              v-show="getMachineStateNormal"
                              style="width: 26px; height: 26px; margin: 0 3px"
                            >
                              <q-img
                                :src="
                                  require('../assets/dashboard/dashboard_good_icon.svg')
                                "
                                fit="fill"
                              />
                            </div>
                            <div
                              style="
                                border: 1px solid #a0a0a0a0;
                                border-radius: 50%;
                                width: 26px;
                                height: 26px;
                                margin: 0 3px;
                                padding: 1px;
                                background-color: white;
                              "
                              v-show="!getMachineStateNormal"
                            ></div>

                            <div
                              v-show="getMachineStateEM"
                              style="width: 26px; height: 26px; margin: 0 3px"
                            >
                              <q-img
                                :src="
                                  require('../assets/dashboard/dashboard_rollover_icon.svg')
                                "
                                fit="fill"
                                width="26px"
                                height="26px"
                              />
                            </div>
                            <div
                              style="
                                border: 1px solid #a0a0a0a0;
                                border-radius: 50%;
                                width: 26px;
                                height: 26px;
                                margin: 0 3px;
                                padding: 1px;
                                ackground-color: white;
                              "
                              v-show="!getMachineStateEM"
                            ></div>

                            <div
                              v-show="getMachineStateBR"
                              style="width: 26px; height: 26px; margin: 0 3px"
                            >
                              <q-img
                                :src="
                                  require('../assets/dashboard/dashboard_error_icon.svg')
                                "
                                fit="fill"
                                width="26px"
                                height="26px"
                              />
                            </div>
                            <div
                              style="
                                border: 1px solid #a0a0a0a0;
                                border-radius: 50%;
                                width: 26px;
                                height: 26px;
                                margin: 0 3px;
                                padding: 1px;
                                background-color: white;
                              "
                              v-show="!getMachineStateBR"
                            ></div>

                            <div
                              v-show="getMachineStateCONS"
                              style="width: 26px; height: 26px; margin: 0 3px"
                            >
                              <q-img
                                :src="
                                  require('../assets/dashboard/dashboard_consumables_icon.svg')
                                "
                                fit="fill"
                                width="26px"
                                height="26px"
                              />
                            </div>
                            <div
                              style="
                                border: 1px solid #a0a0a0a0;
                                border-radius: 50%;
                                width: 26px;
                                height: 26px;
                                margin: 0 3px;
                                padding: 1px;
                                background-color: white;
                              "
                              v-show="!getMachineStateCONS"
                            ></div>

                            <div
                              v-show="getMachineStateLowBatt"
                              style="width: 26px; height: 26px; margin: 0 3px"
                            >
                              <q-img
                                :src="
                                  require('../assets/dashboard/dashboard_battery_icon.svg')
                                "
                                fit="fill"
                                width="26px"
                                height="26px"
                              />
                            </div>
                            <div
                              style="
                                border: 1px solid #a0a0a0a0;
                                border-radius: 50%;
                                width: 26px;
                                height: 26px;
                                margin: 0 3px;
                                padding: 1px;
                                background-color: white;
                              "
                              v-show="!getMachineStateLowBatt"
                            ></div>
                          </div>
                        </div>
                        <div
                          class="col-auto column justify-end"
                          style="margin: 0 0 0 6px"
                        >
                          <!--
                          <div class="col-auto row items-center justify-end">
                            <q-btn class="col-auto" flat dense rounded icon="more_horiz" @click="viewMore"></q-btn>
                          </div>
                          -->
                          <!-- pagination -->
                          <div class="col-auto row items-center justify-end" v-show="machinesInfo.length > 0" >
                            <q-btn flat dense icon="chevron_left" :disable="machinesInfo.length <= 0 || machineIndex <= 0" @click="changeMachine(false)"></q-btn>
                            <div style="margin: 0 3px">{{ machinesInfo.length > 0 ? machineIndex + 1 : 0 }} / {{ machinesInfo.length }}</div>
                            <q-btn flat dense icon="chevron_right" :disable="machinesInfo.length <= 0 || machineIndex >= machinesInfo.length - 1" @click="changeMachine(true)"></q-btn>
                          </div>
                        </div>
                      </div>

                      <q-scroll-area class="col" style="height: 100%;width: 100%;margin: 6px 0 0 0;border-top: 2px solid #ebedf2;border-bottom: 2px solid #ebedf2;">
                        <table
                          class="col column items-start"
                          style="width: 100%"
                        >
                          <tr
                            style="
                              background-color: #f8f8f8;
                              width: 100%;
                              min-height: 34px;
                            "
                          >
                            <td
                              width="110px"
                              class="items-center justify-end"
                              style="
                                height: 100%;
                                text-align: left;
                                vertical-align: middle;
                              "
                            >
                              {{ $t("customer.modelName") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto">
                              {{
                                machineIndex >= 0
                                  ? machinesInfo[machineIndex].Model
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr
                            style="
                              width: 100%;
                              min-height: 34px;
                              border-top: 1px solid #ebedf2;
                              border-bottom: 1px solid #ebedf2;
                            "
                          >
                            <td
                              width="110px"
                              style="text-align: left; vertical-align: middle"
                            >
                              {{ $t("customer.machineNo") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto">
                              {{
                                machineIndex >= 0
                                  ? machinesInfo[machineIndex].MN
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr
                            style="
                              background-color: #f8f8f8;
                              width: 100%;
                              min-height: 34px;
                            "
                          >
                            <td
                              width="110px"
                              style="text-align: left; vertical-align: middle"
                            >
                              {{ $t("customer.manDate") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto">
                              {{
                                machinesInfo.length > 0
                                  ? machinesInfo[machineIndex].PD
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr
                            style="
                              width: 100%;
                              min-height: 34px;
                              border-top: 1px solid #ebedf2;
                              border-bottom: 1px solid #ebedf2;
                            "
                          >
                            <td
                              width="110px"
                              style="text-align: left; vertical-align: middle"
                            >
                              {{ $t("machineInfo.serialNo") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto">
                              {{
                                machineIndex >= 0
                                  ? machinesInfo[machineIndex].SN
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr
                            style="
                              background-color: #f8f8f8;
                              width: 100%;
                              min-height: 34px;
                            "
                          >
                            <td
                              width="110px"
                              style="text-align: left; vertical-align: middle"
                            >
                              {{ $t("customer.dealer") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto">
                              {{
                                machinesInfo.length > 0
                                  ? machinesInfo[machineIndex].Dealer
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr
                            style="
                              width: 100%;
                              min-height: 34px;
                              border-top: 1px solid #ebedf2;
                              border-bottom: 1px solid #ebedf2;
                            "
                          >
                            <td
                              width="110px"
                              style="text-align: left; vertical-align: middle"
                            >
                              {{ $t("customer.saleDate") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto">
                              {{
                                machineIndex >= 0
                                  ? machinesInfo[machineIndex].SD
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr
                            style="
                              background-color: #f8f8f8;
                              width: 100%;
                              min-height: 34px;
                            "
                          >
                            <td
                              width="110px"
                              style="text-align: left; vertical-align: middle"
                            >
                              {{ $t("customer.warranty") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto">
                              {{
                                machinesInfo.length > 0
                                  ? machinesInfo[machineIndex].Warranty
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr
                            style="
                              width: 100%;
                              min-height: 34px;
                              border-top: 1px solid #ebedf2;
                              border-bottom: 1px solid #ebedf2;
                            "
                          >
                            <td
                              width="110px"
                              style="text-align: left; vertical-align: middle"
                            >
                              {{ $t("customer.machineState") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto" :style="battStateColor">
                              {{
                                machineIndex >= 0
                                  ? machinesInfo[machineIndex].BattState
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr
                            style="
                              background-color: #f8f8f8;
                              width: 100%;
                              min-height: 34px;
                              border-bottom: 1px solid #ebedf2;
                            "
                          >
                            <td
                              width="110px"
                              style="text-align: left; vertical-align: middle"
                            >
                              {{ $t("customer.serviceDate") }}
                            </td>
                            <td width="3px">
                              <div
                                style="
                                  background-color: #eee;
                                  width: 1px;
                                  height: 100%;
                                "
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td width="auto">
                              {{
                                machinesInfo.length > 0
                                  ? machinesInfo[machineIndex].startService
                                  : ""
                              }}
                            </td>
                          </tr>
                        </table>
                      </q-scroll-area>
                    </div>
                  </div>
                </template>
              </q-splitter>

              <div
                style="
                  position: absolute;
                  z-index: 1;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  border-radius: 3px;
                  background-color: white;
                  padding: 6px 12px 12px 12px;
                "
                :style="
                  isAddUser
                    ? 'border:1px solid #eb0028;'
                    : 'border:1px solid #eb0028;'
                "
                class="col column items-start"
                v-show="isAddUser || isEditUser"
              >
                <!-- 사용자 추가 및 편집시 오른쪽 부분을 덮고 만듦 -->
                <div
                  class="col-auto row justify-between items-center"
                  style="height: 40px; width: 100%"
                >
                  <div class="col-auto row items-center justify-start">
                    <div
                      class="col-auto"
                      style="height: 32px; font-size: 18px; font-weight: 500"
                      :style="
                        isKorean
                          ? 'font-family:Noto Sans KR;'
                          : 'font-family:Prometo;'
                      "
                    >
                      {{ $t("customer.info") }}
                    </div>
                    <div
                      class="col-auto"
                      style="
                        height: 32px;
                        font-size: 18px;
                        font-weight: 500;
                        margin: 0 12px;
                      "
                      :style="
                        isKorean
                          ? 'font-family:Noto Sans KR;'
                          : 'font-family:Prometo;'
                      "
                    >
                      [&nbsp;{{
                        isAddUser
                          ? $t("common.doingAdd")
                          : $t("common.doingEdit")
                      }}&nbsp;]
                    </div>
                  </div>
                </div>

                <div class="col column items-start justify-start" style="width:100%; height:100%;position: relative;" >
                  <!-- 사용자 추가 / 수정 -->
                  <div class="col row" style="width:100%;height:100%;padding:1px 0;border-top:2px solid #dddddd;border-bottom: 2px solid #dddddd;">
                    <q-scroll-area class="col row" style="width: auto; height: 100%">
                      <table class="col" style="">
                        <tr style="background-color: #f8f8f8;width: 100%;height: 36px;">
                          <td
                            width="160px"
                            style="font-size: 15px; text-align: left"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;'
                                : 'font-family:Prometo;'
                            "
                          >
                            {{ $t("common.name") }}
                          </td>
                          <td width="12px"></td>
                          <td width="auto">
                            <q-input
                              flat
                              dense
                              clearable
                              color="red-7"
                              style="width: 30%; min-width: 240px"
                              :style="
                                isKorean
                                  ? 'font-family:Noto Sans KR;'
                                  : 'font-family:Prometo;'
                              "
                              v-model="newUserInfo.Name"
                              :placeholder="$t('placeholder.inputYourName')"
                            >
                            </q-input>
                          </td>
                        </tr>
                        <tr style="width: 100%; height: 36px">
                          <td width="" style="font-size: 15px; text-align: left">ID</td>
                          <td width=""></td>
                          <td width="">
                            <q-input
                              flat
                              dense
                              clearable
                              color="red-7"
                              style="width: 30%; min-width: 150px"
                              v-show="isAddUser"
                              v-model="newUserInfo.ID"
                              :placeholder="$t('placeholder.inputID')"
                              @update:model-value="onChangedID"
                            >
                              <template v-slot:after>
                                <q-btn
                                  flat
                                  dense
                                  color="grey-9"
                                  size="md"
                                  :icon="
                                    verifiedID
                                      ? 'check_circle'
                                      : 'account_circle'
                                  "
                                  @click="onCheckID"
                                >
                                  <q-tooltip style="font-size: 14px">{{
                                    $t("tooltip.checkValidID")
                                  }}</q-tooltip>
                                </q-btn>
                              </template>
                            </q-input>
                            <div
                              style="width: 30%; min-width: 150px"
                              v-show="isEditUser"
                            >
                              {{ newUserInfo.ID }}
                            </div>
                          </td>
                        </tr>
                        <tr
                          style="
                            background-color: #f8f8f8;
                            width: 100%;
                            height: 36px;
                          "
                        >
                          <td
                            width=""
                            style="font-size: 15px; text-align: left"
                          >
                            {{ $t("newPWDialog.password1") }}
                          </td>
                          <td width=""></td>
                          <td width="">
                            <q-input
                              flat
                              dense
                              clearable
                              color="red-7"
                              style="width: 50%; min-width: 180px"
                              :type="viewPassword1 ? 'text' : 'password'"
                              :placeholder="$t('placeholder.inputPassword')"
                              @update:model-value="keypressPW(1)"
                              v-model="newUserInfo.Password1"
                            >
                              <template v-slot:append>
                                <q-icon
                                  :name="
                                    viewPassword1
                                      ? 'visibility_off'
                                      : 'visibility'
                                  "
                                  class="cursor-pointer"
                                  @click="viewPassword1 = !viewPassword1"
                                />
                              </template>
                              <template v-slot:error>
                                <div style="font-size: 14px">
                                  {{ $t("errorMsg.passwordLength") }}
                                </div>
                              </template>
                            </q-input>
                          </td>
                        </tr>
                        <tr style="width: 100%; height: 36px">
                          <td
                            width=""
                            style="font-size: 15px; text-align: left"
                          >
                            {{ $t("newPWDialog.password2") }}
                          </td>
                          <td width=""></td>
                          <td width="">
                            <q-input
                              flat
                              dense
                              clearable
                              color="red-7"
                              style="width: 50%; min-width: 180px"
                              :type="viewPassword2 ? 'text' : 'password'"
                              :placeholder="$t('placeholder.reInputPassword')"
                              @update:model-value="keypressPW(2)"
                              v-model="newUserInfo.Password2"
                            >
                              <template v-slot:append>
                                <q-icon
                                  :name="
                                    viewPassword2
                                      ? 'visibility_off'
                                      : 'visibility'
                                  "
                                  class="cursor-pointer"
                                  @click="viewPassword2 = !viewPassword2"
                                />
                              </template>
                              <template v-slot:error>
                                <div
                                  style="font-size: 14px"
                                  v-show="invalidPW2"
                                >
                                  {{ $t("errorMsg.passwordLength") }}
                                </div>
                              </template>
                            </q-input>
                          </td>
                        </tr>
                        <tr style="background-color: #f8f8f8;width: 100%;height: 36px;">
                          <td width="" style="font-size: 15px; text-align: left">
                            {{ $t("customer.birthdate") }}
                          </td>
                          <td width=""></td>
                          <td width="">
                            <div class="row items-end">
                              <div style="border-bottom: 1px solid #bbbbbb;min-width: 120px;">
                                {{ (isShowBirthday) ? newUserInfo.Birth : ( newUserInfo.Birth != '' ? '**********' : '') }}
                              </div>
                              <q-btn flat dense color="grey-7" icon="visibility" v-show="!isAddUser && (selectedUserInfo.Birth != '')" @click="doShowBirthday"></q-btn>

                              <q-btn dense flat color="grey-8" icon="calendar_month" style="margin: 0 12px">
                                <q-popup-proxy cover ref="birthDayPopup" @before-show="popupDateSelect()">
                                  <div class="column">
                                    <q-date flat minimal no-unset color="red-7" v-model="selectBirthday" @update:model-value="afterDateSelect()"></q-date>
                                    <div class="col-auto row items-center justify-center" style="padding: 12px 0">
                                      <q-btn no-caps unelevated dense color="grey-4" text-color="black" :label="$t('common.cancel')" style="width:76px;height:30px" @click="closeDatePicker">
                                      </q-btn>
                                      <q-btn no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.save')" style="margin-left:8px;width:76px;height:30px;" @click="selectDate">
                                      </q-btn>
                                    </div>
                                  </div>
                                </q-popup-proxy>
                              </q-btn>
                            </div>
                          </td>
                        </tr>
                        <tr style="width: 100%; height: 36px">
                          <td
                            width=""
                            style="font-size: 15px; text-align: left"
                          >
                            {{ $t("common.mobilephone") }}
                          </td>
                          <td width=""></td>
                          <td width="">
                            <div class="row items-end">
                              <q-select
                                dense
                                options-dense
                                flat
                                color="red-7"
                                :options="phoneNationCodeList"
                                v-model="phoneNationCode"
                                style="width: 64px"
                              ></q-select>
                              <q-input
                                flat
                                dense
                                clearable
                                color="red-7"
                                style="width: 30%; min-width: 240px"
                                v-model="newUserInfo.Phone"
                                :placeholder="$t('placeholder.inputPhone')"
                              >
                              </q-input>
                            </div>
                          </td>
                        </tr>
                        <tr
                          style="
                            background-color: #f8f8f8;
                            width: 100%;
                            height: 36px;
                          "
                        >
                          <td
                            width=""
                            style="font-size: 15px; text-align: left"
                          >
                            E-mail
                          </td>
                          <td width=""></td>
                          <td width="">
                            <q-input
                              flat
                              dense
                              clearable
                              color="red-7"
                              style="width: 30%; min-width: 240px"
                              v-model="newUserInfo.Email"
                              :placeholder="$t('placeholder.inputEmail')"
                            >
                            </q-input>
                          </td>
                        </tr>
                        <tr style="width: 100%; height: 36px" v-show="false">
                          <td width="" style="font-size: 15px; text-align: left">
                            {{ $t("group.postcode") }}
                          </td>
                          <td width=""></td>
                          <td width="">
                            <div class="row items-center justify-between" style="width:100%;height:100%;">
                              <q-input class="col-auto" flat dense clearable color="red-7" style="width: 30%; min-width: 200px" v-model="newUserInfo.Postcode" :placeholder="$t('placeholder.inputPostcode')">
                              </q-input>
                            </div>
                          </td>
                        </tr>
                        <tr style="width: 100%;height: 36px;">  <!-- background-color: #f8f8f8; -->
                          <td width="" style="font-size: 15px; text-align: left">
                            {{ $t("group.address1") }}
                          </td>
                          <td width=""></td>
                          <td width="" class="row items-center justify-between">
                            <q-input flat dense clearable color="red-7" class="col" style="min-width: 120px;margin:0 12px 0 0" v-model="newUserInfo.Address1" :placeholder="$t('placeholder.inputAddress')">
                            </q-input>
                            <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')" v-show="isKorea"
                                    style="width:76px;height:100%;" @click="searchAddress()">
                              <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectPostcode') }}</q-tooltip>
                            </q-btn>
                          </td>
                        </tr>
                        <tr style="background-color: #f8f8f8; width: 100%; height: 36px">
                          <td width="" style="font-size: 15px; text-align: left">
                            {{ $t("group.address2") }}
                          </td>
                          <td width=""></td>
                          <td width="" class="row items-center justify-between">
                            <q-input flat dense clearable color="red-7" style="min-width: 120px;margin:0 12px 0 0" v-model="newUserInfo.Address2" :placeholder="$t('placeholder.inputAddress')">
                            </q-input>
                          </td>
                        </tr>
                        <tr style="width: 100%;height: 40px;" v-show="isKorea" > <!-- background-color: #f8f8f8; -->
                          <td width="" style="font-size: 15px; text-align: left">
                            {{ $t("customer.gender") }}
                          </td>
                          <td width=""></td>
                          <td width="">
                            <q-select
                              dense
                              options-dense
                              flat
                              color="red-7"
                              :options="genderList"
                              v-model="newUserInfo.Gender"
                              style="width: 64px"
                            >
                            </q-select>
                          </td>
                        </tr>
                      </table>
                    </q-scroll-area>
                  </div>
                  <div class="col-auto row justify-center items-center" style="width: 100%; margin: 12px 0 0 0">
                    <div class="col-auto row justify-center items-center">
                      <q-btn
                        no-caps
                        unelevated
                        dense
                        color="grey-4"
                        text-color="grey-9"
                        :label="$t('common.cancel')"
                        style="width: 76px; height: 32px"
                        @click="closeAddEdit"
                      >
                      </q-btn>
                      <q-btn
                        no-caps
                        unelevated
                        dense
                        color="red-7"
                        text-color="white"
                        :label="$t('common.save')"
                        style="margin-left: 8px; width: 76px; height: 32px"
                        @click="saveEdit"
                      >
                      </q-btn>
                    </div>
                  </div>
                  <div class="col column" style="position: absolute;left:0;top:0;width:100%;height:100%;background-color:white;" v-show="visiblePostCode">
                    <div class="col-auto row justify-between items-center" style="margin:6px 0" >
                      <div class="col title">■ 주소 검색</div>
                      <q-btn class="col-auto" no-caps outline dense color="grey-7" icon="close"
                            @click="closePostCode">
                      </q-btn>
                    </div>
                    <q-scroll-area class="col column" style="">
                      <VueDaumPostcode
                            :q="newUserInfo.Address1"
                            class="col daumPost"
                            animation="false"
                            :no-shorthand="true"
                            :no-auto-mapping="true"
                            :please-read-guide="3"
                            :please-read-guide-timer="2"
                            :max-suggest-items="5"
                            :show-more-h-name="true"
                            :hide-map-btn="true"
                            :hide-eng-btn="true"
                            :always-show-eng-addr="false"
                            :zonecode-only="true"
                            @complete="onComplete">
                      </VueDaumPostcode >
                    </q-scroll-area>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div
            style="
              position: absolute;
              z-index: 999;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              border-radius: 3px;
              background-color: #cccccc80;
            "
            v-show="isShowEULA"
            class="col row items-center justify-center"
          >
            <!-- 고객 추가시 라이선스 확인 팝업용 -->
            <div
              class="col-auto column"
              style="
                min-width: 240px;
                min-height: 240px;
                background-color: black;
                padding: 0 1px 1px 1px;
              "
              :style="
                isKorea ? 'width:60%;height:90%;' : 'width:45%;height:85%;'
              "
            >
              <div
                class="col-auto row items-center justify-between"
                style="height: 46px; padding: 0 12px"
              >
                <div class="col" style="font-size: 20px; color: white">
                  {{ $t("eula.signUp") }}
                </div>
                <q-btn
                  dense
                  outline
                  color="white"
                  size="12px"
                  icon="close"
                  @click="closeEULA"
                />
              </div>

              <div
                class="col column"
                style="background-color: white; padding: 6px 12px 0 12px"
              >
                <!-- 한화면에서 세로 스크롤 방식 -->
                <q-scroll-area
                  class="col-auto row"
                  style="width: 100%; height: 100%"
                >
                  <div class="column" style="height: auto">
                    <!-- 개인정보 수집관련 -->
                    <div
                      class="col column"
                      style="margin: 0 0 0 0"
                      v-show="agreePersonalOrg.length > 0"
                    >
                      <div
                        class="col column"
                        style="
                          border: 1px solid #cccccc;
                          padding: 6px 0 6px 12px;
                        "
                      >
                        <q-input
                          borderless
                          readonly
                          autogrow
                          v-model="agreePersonal"
                          style="
                            font-size: 15px;
                            height: 100%;
                            margin: 0 12px 0 0;
                          "
                          type="textarea"
                        />
                      </div>
                      <div
                        class="col-auto row items-end justify-start"
                        style="height: 28px"
                      >
                        <q-checkbox
                          keep-color
                          dense
                          color="red"
                          class="col-auto"
                          v-model="checkAgreePersonal"
                          style="font-size: 15px; color: #eb0028"
                          >{{ $t("eula.privAgree") }}</q-checkbox
                        >
                      </div>
                    </div>

                    <!-- 정책 -->
                    <div
                      class="col column"
                      style="margin: 12px 0 0 0"
                      v-show="agreePolicyOrg.length > 0"
                    >
                      <div
                        class="col column"
                        style="
                          border: 1px solid #cccccc;
                          padding: 6px 0 6px 12px;
                        "
                      >
                        <q-input
                          borderless
                          readonly
                          autogrow
                          v-model="agreePolicy"
                          style="
                            font-size: 15px;
                            height: 100%;
                            margin: 0 12px 0 0;
                          "
                          type="textarea"
                        />
                      </div>
                      <div
                        class="col-auto row items-end justify-start"
                        style="height: 28px"
                      >
                        <q-checkbox
                          keep-color
                          dense
                          color="red"
                          class="col-auto"
                          v-model="checkAgreePolicy"
                          style="font-size: 15px; color: #eb0028"
                          >{{ $t("eula.privAgree") }}</q-checkbox
                        >
                      </div>
                    </div>

                    <!-- 위치정보 ... -->
                    <div
                      class="col column"
                      style="margin: 12px 0 0 0"
                      v-show="agreeLocationOrg.length > 0"
                    >
                      <div
                        class="col column"
                        style="
                          border: 1px solid #cccccc;
                          padding: 6px 0 6px 12px;
                        "
                      >
                        <q-input
                          borderless
                          readonly
                          autogrow
                          v-model="agreeLocation"
                          style="
                            font-size: 15px;
                            height: 100%;
                            margin: 0 12px 0 0;
                          "
                          type="textarea"
                        />
                      </div>
                      <div
                        class="col-auto row items-end justify-start"
                        style="height: 28px"
                      >
                        <q-checkbox
                          keep-color
                          dense
                          color="red"
                          class="col-auto"
                          v-model="checkAgreeLocation"
                          style="font-size: 15px; color: #eb0028"
                          >{{ $t("eula.locaAgree") }}</q-checkbox
                        >
                      </div>
                    </div>

                    <!-- 위치정보사업 이용약관 ... -->
                    <div
                      class="col column"
                      style="margin: 12px 0 0 0"
                      v-show="agreeLocationInfo.length > 0"
                    >
                      <div
                        class="col column"
                        style="
                          border: 1px solid #cccccc;
                          padding: 6px 0 6px 12px;
                        "
                      >
                        <q-input
                          borderless
                          readonly
                          autogrow
                          v-model="agreeLocationInfo"
                          style="
                            font-size: 15px;
                            height: 100%;
                            margin: 0 12px 0 0;
                          "
                          type="textarea"
                        />
                      </div>
                      <div
                        class="col-auto row items-end justify-start"
                        style="height: 28px"
                      >
                        <q-checkbox
                          keep-color
                          dense
                          color="red"
                          class="col-auto"
                          v-model="checkAgreeLocationInfo"
                          style="font-size: 15px; color: #eb0028"
                          >{{ $t("eula.locaInfoAgree") }}</q-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </q-scroll-area>

                <q-scroll-area
                  class="col-auto row"
                  style="width: 100%; height: 36px"
                  v-show="false"
                >
                  <q-tabs
                    v-model="currentTab"
                    dense
                    flat
                    align="left"
                    indicator-color="transparent"
                    class="text-grey-10"
                    active-color="red-7"
                  >
                    <q-tab
                      name="first"
                      style="font-size: 15px"
                      :style="
                        isKorean
                          ? 'font-family:Noto Sans KR;font-weight:bold;'
                          : 'font-family:Prometo;font-weight:500;'
                      "
                    >
                      {{ $t("eula.privInfo") }}
                    </q-tab>
                    <q-tab
                      name="second"
                      style="font-size: 15px"
                      :style="
                        isKorean
                          ? 'font-family:Noto Sans KR;font-weight:bold;'
                          : 'font-family:Prometo;font-weight:500;'
                      "
                    >
                      {{ $t("eula.privPolicy") }}
                    </q-tab>
                    <q-tab
                      name="third"
                      style="font-size: 15px"
                      :style="
                        isKorean
                          ? 'font-family:Noto Sans KR;font-weight:bold;'
                          : 'font-family:Prometo;font-weight:500;'
                      "
                    >
                      {{ $t("eula.locaTerms") }}
                    </q-tab>
                    <q-tab
                      name="fourth"
                      style="font-size: 15px"
                      :style="
                        isKorean
                          ? 'font-family:Noto Sans KR;font-weight:bold;'
                          : 'font-family:Prometo;font-weight:500;'
                      "
                      v-show="agreeLocationInfoOrg.length > 0"
                    >
                      {{ $t("eula.locaInfoTerms") }}
                    </q-tab>
                  </q-tabs>
                </q-scroll-area>

                <q-tab-panels
                  class="col row"
                  v-model="currentTab"
                  animated
                  v-show="false"
                >
                  <q-tab-panel
                    name="first"
                    class="col row"
                    style="margin: 0; padding: 0"
                  >
                    <!-- 개인정보 수집관련 -->
                    <div class="col column" style="">
                      <div
                        class="col column"
                        style="
                          border: 1px solid #cccccc;
                          padding: 6px 0 6px 12px;
                        "
                      >
                        <q-scroll-area
                          class="col"
                          style="width: 100%; height: 100%"
                        >
                          <q-input
                            borderless
                            readonly
                            autogrow
                            v-model="agreePersonal"
                            style="
                              font-size: 15px;
                              height: 100%;
                              margin: 0 12px 0 0;
                            "
                            type="textarea"
                          />
                        </q-scroll-area>
                      </div>
                      <div
                        class="col-auto row items-end justify-start"
                        style="height: 32px"
                      >
                        <q-checkbox
                          dense
                          color="grey-10"
                          class="col-auto"
                          v-model="checkAgreePersonal"
                          style="font-size: 15px"
                          >{{ $t("eula.privAgree") }}</q-checkbox
                        >
                      </div>
                    </div>
                  </q-tab-panel>

                  <q-tab-panel
                    name="second"
                    class="col row"
                    style="margin: 0; padding: 0"
                  >
                    <!-- 정책 -->
                    <div class="col column" style="">
                      <div
                        class="col column"
                        style="
                          border: 1px solid #cccccc;
                          padding: 6px 0 6px 12px;
                        "
                      >
                        <q-scroll-area
                          class="col"
                          style="width: 100%; height: 100%"
                        >
                          <q-input
                            borderless
                            readonly
                            autogrow
                            v-model="agreePolicy"
                            style="
                              font-size: 15px;
                              height: 100%;
                              margin: 0 12px 0 0;
                            "
                            type="textarea"
                          />
                        </q-scroll-area>
                      </div>
                    </div>
                  </q-tab-panel>

                  <q-tab-panel
                    name="third"
                    class="col row"
                    style="margin: 0; padding: 0"
                  >
                    <!-- 위치정보 ... -->
                    <div class="col column" style="">
                      <div
                        class="col column"
                        style="
                          border: 1px solid #cccccc;
                          padding: 6px 0 6px 12px;
                        "
                      >
                        <q-scroll-area
                          class="col"
                          style="width: 100%; height: 100%"
                        >
                          <q-input
                            borderless
                            readonly
                            autogrow
                            v-model="agreeLocation"
                            style="
                              font-size: 15px;
                              height: 100%;
                              margin: 0 12px 0 0;
                            "
                            type="textarea"
                          />
                        </q-scroll-area>
                      </div>
                      <div
                        class="col-auto row items-end justify-start"
                        style="height: 32px"
                      >
                        <q-checkbox
                          dense
                          color="grey-10"
                          class="col-auto"
                          v-model="checkAgreeLocation"
                          style="font-size: 15px"
                          >{{ $t("eula.locaAgree") }}</q-checkbox
                        >
                      </div>
                    </div>
                  </q-tab-panel>

                  <q-tab-panel
                    name="fourth"
                    class="col row"
                    style="margin: 0; padding: 0"
                    v-show="agreeLocationInfoOrg.length > 0"
                  >
                    <!-- 위치정보사업 이용약관 ... -->
                    <div class="col column" style="">
                      <div
                        class="col column"
                        style="
                          border: 1px solid #cccccc;
                          padding: 6px 0 6px 12px;
                        "
                      >
                        <q-scroll-area
                          class="col"
                          style="width: 100%; height: 100%"
                        >
                          <q-input
                            borderless
                            readonly
                            autogrow
                            v-model="agreeLocationInfo"
                            style="
                              font-size: 15px;
                              height: 100%;
                              margin: 0 12px 0 0;
                            "
                            type="textarea"
                          />
                        </q-scroll-area>
                      </div>
                      <div
                        class="col-auto row items-end justify-start"
                        style="height: 32px"
                      >
                        <q-checkbox
                          dense
                          color="grey-10"
                          class="col-auto"
                          v-model="checkAgreeLocationInfo"
                          style="font-size: 15px"
                          >{{ $t("eula.locaInfoAgree") }}</q-checkbox
                        >
                      </div>
                    </div>
                  </q-tab-panel>
                </q-tab-panels>
              </div>
              <div
                class="col-auto row items-center justify-center"
                style="
                  height: 50px;
                  padding: 0 0 12px 0;
                  background-color: white;
                "
              >
                <q-btn
                  dense
                  no-caps
                  color="red-7"
                  text-color="white"
                  style="font-size: 15px; width: 80px"
                  :label="$t('common.confirm')"
                  :disable="
                    !checkAgreePersonal ||
                    !checkAgreePolicy ||
                    !checkAgreeLocation ||
                    !checkAgreeLocationInfo
                  "
                  @click="addUser()"
                />
              </div>
            </div>
          </div>
        </q-splitter>
      </div>
    </div>

    <!-- 다이얼로그 -->
    <q-dialog v-model="isShowDialog" persistent>
      <q-card>
        <q-card-section class="row items-center" style="margin: 12px">
          <q-icon class="col-auto" name="warning" color="grey-10" size="xl" text-color="white"/>
          <div class="col" style="font-size: 15px; margin: 0 12px">
            {{ dialogMessage }}
          </div>
        </q-card-section>

        <q-card-actions>
          <div class="col row items-center justify-center" style="margin: 0 0 12px 0">
            <q-btn dense no-caps color="red-7" text-color="white" style="width: 80px; height: 32px" :label="$t('common.confirm')" v-close-popup />
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>

      <!-- 비밀번호 확인용 다이얼로그 -->
      <q-dialog v-model="inputPasswordDlg" persistent>
      <q-card style="padding:12px 24px 12px 12px;min-width:420px">
        <q-card-section class="row items-center" >
          <div class="col row items-center justify-between">
            <div class="col-auto">
              <q-avatar icon="group_remove" color="red-7" text-color="white" style="margin:0 12px 0 0;"/>
            </div>
            <div class="col column">
              <span class="q-ml-sm" style="font-size:14px;margin:0 0 12px 12px;">{{ passwordDlgMsg }}</span>

              <q-input class="q-ml-sm" color="red-7" :type="hideDialogPW ? 'password' : 'text'" style="font-size:14px" outlined autofocus dense v-model="dialogInputPassword"
                       :placeholder="$t('placeholder.inputPassword')">
                <template v-slot:append>
                  <q-icon :name="hideDialogPW ? 'visibility_off' : 'visibility'"
                          class="cursor-pointer"
                          @click="hideDialogPW = !hideDialogPW"/>
                </template>
              </q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup/>
          <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="inputPasswordConfirmed"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}

.triangle {
  border-top: 8vw solid transparent;
  border-left: 8vw solid black;
  border-bottom: 0 solid transparent;
}
table {
  table-layout: fixed;
  width: 100%;
  border: none;
  border-collapse: collapse;
}
tr {
  padding: 0 0;
  height: auto;
  border: none;
  border-collapse: collapse;
}
td {
  font-size: 15px;
  padding: 3px 6px;
  border: none;
  border-collapse: collapse;
}
.thSticky {
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: white;
  font-size: 15px;
  font-weight: 500;
}
.horizSeparator {
  width: 32px;
  height: 4px;
  background-color: #bbbbbb;
  border-radius: 3px;
}
.vertSeparator {
  width: 4px;
  height: 32px;
  background-color: #bbbbbb;
  border-radius: 3px;
}
.daumPost {
  width:100%;
  height:480px;
}
</style>

<script>
import { ref } from "vue";
import { useTymictStore } from "@/store/tymict";
import { useI18n } from "vue-i18n";
import TymAws from "@/js/tymaws.js";
import TymCommon from "@/js/tymcommon.js";
import TymConst from "@/js/tymconstants.js";
import TymFncs from "@/js/tymfunctions.js";
import AWS from "aws-sdk";
import moment from "moment";
import { VueDaumPostcode  } from 'vue-daum-postcode'

export default {
  components: {
    VueDaumPostcode
  },
  data() {},
  setup() {
    const i18n = useI18n();
    return {
      mainSplitter: ref(60),
      subSplitter: ref(50),
      isLoadingData: ref(false),
      isShowEULA: ref(false),
      searchNamePhone: ref(""),
      usersData: ref([]),
      pagination: ref({ rowsPerPage: 0 }),

      isSoldOnly: ref(false), // 판매등록된 사용자만?
      visiblePostCode: ref(false), // 주소검색?

      isAddUser: ref(false),
      isEditUser: ref(false),
      visibleColumns: ref(["rowID", "name", "id", "phone", "regDate"]),
      curPagination : ref({
        sortBy : 'none',
        descending : false,
        page : 1,
        rowsPerPage : 20
      }),
      columnData: ref([
        { name: "index", required: false },
        {
          name: "rowID",
          required: true,
          label: i18n.t("TABLE_COLUMNS.order"),
          field: "rowID",
        },
        {
          name: "name",
          required: true,
          label: i18n.t("common.name"),
          align: "left",
          field: "name",
          sortable: true,
          sort: (a, b, rowA, rowB) => rowA.name.localeCompare(rowB.name),
        },
        {
          name: "id",
          align: "left",
          required: true,
          label: "ID",
          field: "id",
          sortable: true,
        },
        {
          name: "phone",
          align: "left",
          required: true,
          label: i18n.t("group.phone"),
          field: "phone",
          sortable: true,
        },
        {
          name: "mon",
          align: "left",
          label: "기대정보",
          field: "mon",
          sortable: true,
        },
        {
          name: "regDate",
          align: "center",
          label: i18n.t("customer.regDate"),
          field: "regDate",
          sortable: true,
          sort: (a, b, rowA, rowB) => rowA.RD - rowB.RD,
        },
      ]),
      rowCount: ref(0),
      rowData: ref([]),
      modelRowSelection: ref(-1),
      pageCount: ref(0),
      maxItemPerPage : ref([5, 15]),
      currentPageIndex: ref(1),

      selectedUserInfo: ref({
        Name: "",
        ID: "",
        Gender: "",
        Birth: "",
        RegDate: "",
        RD: "",
        Phone: "",
        Email: "",
        Address1: "",
        Address2: "",
      }),
      newUserInfo: ref({
        Name: "",
        ID: "",
        Password1: "",
        Password2: "",
        Birth: "",
        Phone: "",
        Email: "",
        Postcode: "",
        Address1: "",
        Address2: "",
        Gender: "",
        RegDate: "",
        RD: "",
      }),
      machineIndex: ref(-1),
      machinesInfo: ref([]),
      machineImageSrc: ref(""),
      battStateColor: ref("color:black;"),

      viewPassword1: ref(false),
      viewPassword2: ref(false),
      invalidPW1: ref(false),
      invalidPW2: ref(false),
      phoneNationCodeList: ref(["+82", "+1"]),
      phoneNationCode: ref("+82"),
      genderList: ref(["남성", "여성"]),
      genderCode: ref("남성"),

      selectBirthday: ref(""),

      agreePersonalOrg: ref(""),
      agreePolicyOrg: ref(""),
      agreeLocationOrg: ref(""),
      agreeLocationInfoOrg: ref(""),

      agreePersonal: ref(""),
      checkAgreePersonal: ref(false),
      agreePolicy: ref(""),
      checkAgreePolicy: ref(false),
      agreeLocation: ref(""),
      checkAgreeLocation: ref(false),
      agreeLocationInfo: ref(""),
      checkAgreeLocationInfo: ref(false),

      verifiedID: ref(false),
      isShowDialog: ref(false),
      dialogMessage: ref(""),

      currentTab: ref("first"),

      isShowBirthday: ref(false),
      inputPasswordDlg: ref(false),
      hideDialogPW: ref(true),
      dialogInputPassword: ref(''),
      passwordDlgMsg: ref('')
    };
  },
  computed: {
    isDev() {
      return TymConst.IS_DEVELOPMENT;
    },
    isLandscape: function () {
      const store = useTymictStore();
      return store.isLandscape;
    },
    isKorean() {
      const store = useTymictStore();
      return store.getLanguage() == "ko";
    },
    isKorea: function () {
      const store = useTymictStore();
      return store.connectServer == 0;
    },
    getPageTitle: function () {
      return TymFncs.getPageTitle();
    },
    getRowData() {
      return this.rowData;
    },
    getIgnState() {
      if (!TymCommon.isEmpty(this.machinesInfo) && this.machineIndex >= 0 && this.machinesInfo.length > 0) {
        const store = useTymictStore();

        let machine = store.machineLocaInfo.find((x) => x.machineNo == this.machinesInfo[this.machineIndex].MN);
        if (machine && !TymCommon.isEmpty(machine.PWR)) {
          return machine.PWR == "ON" ? require("@/assets/monitor/machinemap_on_icon.svg") : require("@/assets/monitor/machinemap_off_icon.svg");
        }
      }
      return require("@/assets/monitor/machinemap_off_icon.svg");
    },
    getMachineStateNormal() {
      if (!TymCommon.isEmpty(this.machinesInfo) && this.machineIndex >= 0 && this.machinesInfo.length > 0) {
        const store = useTymictStore();

        let machine = store.machineLocaInfo.find((x) => x.machineNo == this.machinesInfo[this.machineIndex].MN);
        if (machine) {
          return (!machine.abnormal[0] && !machine.abnormal[1] && !machine.abnormal[2] && !machine.abnormal[3]);
        }
      }
      return false;
    },
    getMachineStateEM() {
      if (
        !TymCommon.isEmpty(this.machinesInfo) &&
        this.machineIndex >= 0 &&
        this.machinesInfo.length > 0
      ) {
        const store = useTymictStore();

        let machine = store.machineLocaInfo.find(
          (x) => x.machineNo == this.machinesInfo[this.machineIndex].MN
        );
        if (machine) {
          return machine.abnormal[0];
        }
      }
      return false;
    },
    getMachineStateBR() {
      if (
        !TymCommon.isEmpty(this.machinesInfo) &&
        this.machineIndex >= 0 &&
        this.machinesInfo.length > 0
      ) {
        const store = useTymictStore();

        let machine = store.machineLocaInfo.find(
          (x) => x.machineNo == this.machinesInfo[this.machineIndex].MN
        );
        if (machine) {
          return machine.abnormal[1];
        }
      }
      return false;
    },
    getMachineStateCONS() {
      if (
        !TymCommon.isEmpty(this.machinesInfo) &&
        this.machineIndex >= 0 &&
        this.machinesInfo.length > 0
      ) {
        const store = useTymictStore();

        let machine = store.machineLocaInfo.find(
          (x) => x.machineNo == this.machinesInfo[this.machineIndex].MN
        );
        if (machine) {
          return machine.abnormal[2];
        }
      }
      return false;
    },
    getMachineStateLowBatt() {
      if (
        !TymCommon.isEmpty(this.machinesInfo) &&
        this.machineIndex >= 0 &&
        this.machinesInfo.length > 0
      ) {
        const store = useTymictStore();

        let machine = store.machineLocaInfo.find(
          (x) => x.machineNo == this.machinesInfo[this.machineIndex].MN
        );
        if (machine) {
          return machine.abnormal[3];
        }
      }
      return false;
    },
  },
  created() {},
  mounted() {
    const store = useTymictStore();
    if (store.CurrentPage.Now != TymConst.PAGE_CUSTOMER) {
      store.setPage(TymConst.PAGE_CUSTOMER);
    }

    setTimeout(() => {
      this.readUserData();
    }, 1);
  },
  unmounted() {
    sessionStorage.setItem(
      "CUST.saved",
      JSON.stringify({
        SoldOnly: this.isSoldOnly ? 1 : 0,
        Keyword: this.searchNamePhone,
        Row: this.modelRowSelection,
      })
    );
  },
  methods: {
    unusedParam(param) {
      return param;
    },
    onResize(size) {
      this.unusedParam(size);
      this.arrangeTable();
    },
    readCustomerResult(readOK) {
      const store = useTymictStore();

      try {
        if (readOK) {
          let idx = 0;
          this.usersData = store.AllCustomerData;

          this.usersData.forEach((user) => {
            // console.log('User :', user.name, user.userid)
            user.index = idx;
            if (!TymCommon.isEmpty(store.SalesData.data)) {
              let foundUser = store.SalesData.data.find((x) => x.uid == user.userid);
              if (foundUser) {
                // console.log(foundUser, user)
                user.uuid_a = foundUser.uuid_a;
                user.uuid_b = foundUser.uuid_b;
                user.uuid_c = foundUser.uuid_c;
                user.uuid_d = foundUser.uuid_d;
              } else {
                // console.log('Not found USER :', user)
              }
            }
            this.rowData.push({
              index: idx,
              name: user.name,
              id: user.userid,
              MOBILE: user.phone_number,
              phone: TymCommon.parsePhoneNumber(user.phone_number),
              mon: "",
              regDate: TymCommon.getDateString(user.createat),
              RD: user.createat,
            });
            idx++;
          });

          this.curPagination = {
            sortBy: "regDate",
            descending: true,
            page: 1,
            rowsPerPage: this.curPagination.rowsPerPage,
          }
          this.arrangeTable()
          this.itemPageChanged(1)

          let saved = sessionStorage.getItem("CUST.saved");
          if (!TymCommon.isEmpty(saved)) {
            saved = JSON.parse(saved);
            // console.log('CUST.saved load :', saved)
            this.isSoldOnly = saved.SoldOnly == 1;
            this.searchNamePhone = saved.Keyword;
            if (!TymCommon.isEmpty(saved.Row) && saved.Row >= 0) {
              this.dataRowClicked(saved.Row, this.rowData[saved.Row]);
            }
          } else {
            // console.log('CUST.reload :', this.selectedUserInfo)
            if(!TymCommon.isEmpty(this.selectedUserInfo.ID)) {
              let found = this.rowData.find(x => x.id == this.selectedUserInfo.ID)
              this.dataRowClicked(null, found)
            }
          }

          this.isLoadingData = false
          this.isLoadingPersonal = false
          this.isAddUser = false;
          this.isEditUser = false;
        }
      } catch (ex) {
        console.log("readCustomerResult EX :", ex);
      }
      this.isLoadingData = false;
    },
    readUserData() {
      this.isAddUser = false;
      this.isEditUser = false;

      if(TymConst.IS_DEVELOPMENT) {
        console.log("readUserData CALL");
      }

      this.usersData = [];
      this.rowData = [];
      try {
        const store = useTymictStore();
        this.isLoadingData = true;

        store.getCustomerData(this.readCustomerResult);

        setTimeout(() => {
          // const store = useTymictStore()
          TymAws.getWebConfig("agreement", null)
            .then((data) => {
              let agree = data.find((x) => x.CKEY == "mw2_agreement_text");
              if (agree) {
                this.agreePersonalOrg = agree.CVAL;
              }
              agree = data.find((x) => x.CKEY == "mw2_location_text");
              if (agree) {
                this.agreeLocationOrg = agree.CVAL;
              }
              agree = data.find((x) => x.CKEY == "mw2_policy_text");
              if (agree) {
                this.agreePolicyOrg = agree.CVAL;
              }
              agree = data.find((x) => x.CKEY == "mw2_location_info_text");
              if (agree) {
                this.agreeLocationInfoOrg = agree.CVAL;
              }
            })
            .catch((er) => {
              console.log("getWebConfig ERR :", er);
            });
        }, 5);

        /*
        TymAws.getAllUsers()
          .then(data => {
            this.usersData = data
            let idx = 0
            this.usersData.forEach(user => {
              user.index = idx
              if(!TymCommon.isEmpty(store.SalesData.data)) {
                let foundUser = store.SalesData.data.find(x => x.uid == user.userid)
                if(foundUser) {
                  // console.log(foundUser, user)
                  user.uuid_a = foundUser.uuid_a
                  user.uuid_b = foundUser.uuid_b
                  user.uuid_c = foundUser.uuid_c
                  user.uuid_d = foundUser.uuid_d
                } else {
                  // console.log('Not found USER :', user)
                }
              }
              this.rowData.push({
                index : idx,
                name : user.name,
                id : user.userid,
                MOBILE : user.phone_number,
                phone : TymCommon.parsePhoneNumber(user.phone_number),
                mon : '',
                regDate : TymCommon.getDateString(user.createat),
                RD : user.createat
              })
              idx++
            })
            // console.log('AllUsers from Cognito & Sales :', this.usersData)

            this.arrangeTable()
            if(this.$refs.customerTable) {
              this.$refs.customerTable.setPagination({
                sortBy: 'regDate',
                descending: true,
                page: 1,
                rowsPerPage: this.curPagination.rowsPerPage
              })
            }
          }).catch(er => {
            console.log('TymAws.getAllUsers ER:', er)
          }).finally(() => {
            this.isLoadingData = false

          })
          */
      } catch (ex) {
        console.log("getWebConfig ERR#2 :", ex);
      }
    },
    dataRowClicked(row, data) {
      const store = useTymictStore()

      this.machineIndex = -1;
      this.machinesInfo = [];

      this.modelRowSelection = data.index;
      this.isLoadingPersonal = true;
      this.isShowBirthday = false
      setTimeout(() => {
        let found = this.usersData.find((x) => x.index == data.index);
        if (found) {
          let birthString = "";
          if (!TymCommon.isEmpty(found["custom:cbirthday"])) {
            let bd = TymCommon.convertStringToDateTime(
              found["custom:cbirthday"]
            );
            birthString = TymCommon.getDateString(new Date(bd));
          }
          let genderString = "";
          if (!TymCommon.isEmpty(found.gender)) {
            if (found.gender == "M" || found.gender == "남성") {
              genderString = this.$t("common.male");
            } else {
              genderString = this.$t("common.female");
            }
          }

          if (found.email == "null@tymict.com")
            found.email = "";

          let splitPhone = TymCommon.splitPhoneNumber(found.phone_number)
          if (TymConst.IS_DEVELOPMENT) {
            console.log("CUST.dataRowClicked :", found.phone_number, splitPhone)
          }

          this.phoneNationCode = TymCommon.isEmpty(splitPhone.nation) ? this.phoneNationCodeList[store.connectServer] : splitPhone.nation
          this.selectedUserInfo = {
            Name: found.name,
            ID: found.userid,
            Gender: genderString,
            Birth: birthString,
            RegDate: data.regDate,
            Phone: TymCommon.parsePhoneNumber(found.phone_number, store.connectServer),
            Email: found.email,
            Address1: found["custom:caddress1"],
            Address2: found["custom:caddress2"],
          };

          this.updateMachineInfo(data, found);
        } else {
          this.selectedUserInfo = {
            Name: "",
            ID: "",
            Gender: "",
            Birth: "",
            RegDate: "",
            Phone: "",
            Email: "",
            Address1: "",
            Address2: "",
          };
          this.isLoadingPersonal = false;
        }
      }, 1);
    },
    // 테이블 라디오버튼 클릭
    changeSelection(value) {
      //console.log('changeSelection :', value)
      this.modelRowSelection = value;
    },
    updatePagination(newPagination) {
      if (TymConst.IS_DEVELOPMENT) {
        console.log("updatePagination :", newPagination);
      }
      this.curPagination = newPagination;
      this.arrangeTable();
      this.curPage = newPagination.page;
    },
    searchStart() {
      //
    },
    userFilterMethod() {
      let tempFiltered = [], filtered = [];
      if (!TymCommon.isEmpty(this.searchNamePhone)) {
        let upper = this.searchNamePhone.trim().toUpperCase();
        tempFiltered = this.rowData.filter(x => x.name.toUpperCase().indexOf(upper) >= 0 || x.id.indexOf(this.searchNamePhone) >= 0 || x.phone.indexOf(this.searchNamePhone) >= 0 )
      } else {
        tempFiltered = Array.from(this.rowData);
      }

      if (!this.isSoldOnly) {
        filtered = tempFiltered;
      } else {
        const store = useTymictStore();

        tempFiltered.forEach((one) => {
          let found = store.SalesData.data.find((x) => x.uid == one.id);
          if (found) {
            filtered.push(one);
          }
        });
      }
      if (TymConst.IS_DEVELOPMENT) {
        console.log("userFilterMethod");
      }

      let doArragne = this.rowCount != filtered.length
      this.rowCount = filtered.length;
      if(doArragne) {
        this.arrangeTable()
      }

      return filtered;
    },
    arrangeTable() {
      if(!this.$refs.tableContainer) {
        this.maxItemPerPage[1] = [15]
      } else {
        let rowsPerPage = Math.floor((this.$refs.tableContainer.clientHeight - 64) / 32)
        this.maxItemPerPage[1] = rowsPerPage
      }
      this.curPagination.rowsPerPage = this.maxItemPerPage[1]
      this.pageCount = Math.ceil(this.rowCount / this.maxItemPerPage[1])

      // console.log('customerView.arrangeTable :', this.pageCount, this.rowCount, this.curPagination)
    },
    itemPageChanged(value) {
      // console.log("itemPageChanged :", value);
      this.curPagination.page = value;
    },
    changeMachine(isNext) {
      if (isNext) {
        if (this.machineIndex < this.machinesInfo.length - 1) {
          this.machineIndex++;
          this.getMachineInfo(this.machineIndex);
        }
      } else {
        if (this.machineIndex > 0) {
          this.machineIndex--;
          this.getMachineInfo(this.machineIndex);
        }
      }
    },
    updateMachineInfo(data, found) {
      const store = useTymictStore();
      this.machineIndex = -1;
      this.machinesInfo = [];

      let machines = store.SalesData.data.filter((x) => x.uid == found.userid);
      if (TymConst.IS_DEVELOPMENT) {
        console.log("updateMachineInfo.updateMachineInfo :", data, found, machines);
      }
      if (!TymCommon.isEmpty(machines)) {
        if (machines.length > 0) {
          machines.forEach((machine) => {
            let modelName = TymCommon.getModelFullname(machine.model);

            if (!TymCommon.isEmpty(modelName)) {
              let wd = new Date(machine.saleDate);
              /* 디폴트 3년(20231215 변경요청)
                국내 24년 이후 생산은 3년, 그전 생산은 5년
                북미 3년
                유럽 거의 3년으로 확정
              */
              let waYear = 3;

              if (this.isKorea) {
                waYear = 5;
                /*
                if (
                  machine.serialNo.length == 16 &&
                  machine.serialNo[0] == "T"
                ) {
                  let manYear = machine.serialNo.substring(4, 6);

                  if (!TymCommon.isEmpty(manYear)) {
                    if (parseInt(manYear) >= 24) waYear = 3;
                  }
                }
                */
              } else {
                waYear = 3;
                //wd = new Date(wd.setFullYear(wd.getFullYear() + 5))
              }
              try {
                wd = moment(wd);
                wd = wd.add(waYear, "years");
                wd = wd.add(-1, "days");
                wd = wd.toDate();
              } catch (ex) {
                wd = new Date(wd.setFullYear(wd.getFullYear() + 3));
                wd -= 24 * 60 * 60;
              }

              let state = false;
              let inLoca = store.machineLocaInfo.find(
                (x) => x.machineNo == machine.machineNo
              );
              if (inLoca) {
                if (!TymCommon.isEmpty(inLoca.PWR)) {
                  state = inLoca.PWR == "ON";
                }
              }

              this.machinesInfo.push({
                Model: modelName.Fullname,
                MN: machine.machineNo,
                MachineState: [true, false, false, false, false],
                ignOn: state,
                PD: machine.manDate,
                SN: machine.serialNo,
                Dealer: machine.dealer,
                SD: machine.saleDate,
                Warranty: TymCommon.convertStringToDate(wd),
                BattState: "",
                startService: "",
              });
            }
          });
          this.machineIndex = 0;

          // console.log('updateMachineInfo :', this.machinesInfo)

          this.getMachineInfo(this.machineIndex);
        } else {
          this.isLoadingPersonal = false;
        }
      } else {
        this.isLoadingPersonal = false;
      }
    },
    getMachineInfo(index) {
      const store = useTymictStore();

      // console.log('getMachineInfo :', index, this.machinesInfo.length)
      if (TymConst.IS_DEVELOPMENT) {
        console.log("updateMachineInfo.getMachineInfo :",index,this.machinesInfo[index]);
      }

      // let imgCtrl = document.getElementById('viewModelImage')
      // imgCtrl.src = ''
      if (index >= 0 && index < this.machinesInfo.length) {
        let foundLoca = store.machineLocaInfo.find(
          (x) => x.machineNo == this.machinesInfo[this.machineIndex].MN
        );
        if (foundLoca) {
          this.machinesInfo[this.machineIndex].MachineState[0] = !(
            foundLoca.abnormal[0] ||
            foundLoca.abnormal[1] ||
            foundLoca.abnormal[2] ||
            foundLoca.abnormal[3]
          );
          this.machinesInfo[this.machineIndex].MachineState[1] =
            foundLoca.abnormal[0];
          this.machinesInfo[this.machineIndex].MachineState[2] =
            foundLoca.abnormal[1];
          this.machinesInfo[this.machineIndex].MachineState[3] =
            foundLoca.abnormal[2];
          this.machinesInfo[this.machineIndex].MachineState[4] =
            foundLoca.abnormal[3];
        } else {
          this.machinesInfo[this.machineIndex].MachineState = [
            true,
            false,
            false,
            false,
            false,
          ];
        }
        // console.log('getMachineInfo', index, this.machinesInfo[this.machineIndex], foundLoca)

        if (!TymCommon.isEmpty(this.machinesInfo[this.machineIndex])) {
          TymAws.getMachineByMN(this.machinesInfo[this.machineIndex].MN)
            .then((data) => {
              // console.log('getMachineByMN', this.machineIndex, this.machinesInfo[this.machineIndex].MN, data)

              // let foundModel = null
              if (Array.isArray(data)) {
                this.machinesInfo[this.machineIndex].startService =
                  TymCommon.convertStringToDate(data[0].OD);
                // foundModel = store.ModelData.data.find(x => (x.model + "_" + x.option) == data[0].MON)
              } else {
                this.machinesInfo[this.machineIndex].startService =
                  TymCommon.convertStringToDate(data.OD);
                // foundModel = store.ModelData.data.find(x => (x.model + "_" + x.option) == data.MON)
              }
            })
            .catch((er) => {
              console.log("TymAws.getMachineByMN(ERROR) :", er);
            });
          TymAws.getVutByMN(this.machinesInfo[this.machineIndex].MN)
            .then((data) => {
              //console.log('TymAws.getVutByMN() :', data, this.machineIndex, this.machinesInfo[this.machineIndex])
              switch (data.BATT_STATUS) {
                case "배터리 정상":
                  this.machinesInfo[this.machineIndex].BattState =
                    this.$t("batt_status.0");
                  this.battStateColor = "color:green";
                  break;
                case "배터리 저전압":
                  this.machinesInfo[this.machineIndex].BattState =
                    this.$t("batt_status.1");
                  this.battStateColor = "color:blue";
                  break;
                case "텔레매틱스 통신 종료":
                  this.machinesInfo[this.machineIndex].BattState =
                    this.$t("batt_status.2");
                  this.battStateColor = "color:orange";
                  break;
                case "배터리 차단":
                  this.machinesInfo[this.machineIndex].BattState =
                    this.$t("batt_status.3");
                  this.battStateColor = "color:red";
                  break;
                default:
                  this.machinesInfo[this.machineIndex].BattState =
                    this.$t("batt_status.noInfo");
                  this.battStateColor = "color:black";
                  break;
              }
              // console.log('TymAws.getVutByMN :', this.machinesInfo[this.machineIndex])
              this.isLoadingPersonal = false;
            })
            .catch((er) => {
              console.log("TymAws.getVutByMN(ERROR) :", er);
              this.isLoadingPersonal = false;
            });
        } else {
          this.isLoadingPersonal = false;
        }
      } else {
        this.isLoadingPersonal = false;
      }
    },
    showEULA() {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('+-----------------------+')
        console.log('| DEVELOPMENT MODE ONLY |')
        console.log('+-----------------------+')
        this.checkAgreePersonal = true
        this.checkAgreePolicy = true
        this.checkAgreeLocation = true
        this.checkAgreeLocationInfo = true

        setTimeout(() => {
          this.addUser()
        }, 25)
      } else {
        if (TymCommon.isEmpty(this.agreePersonalOrg)) {
          this.checkAgreePersonal = true;
        } else {
          this.checkAgreePersonal = false;
        }

        if (TymCommon.isEmpty(this.agreePolicyOrg)) {
          this.checkAgreePolicy = true;
        } else {
          this.checkAgreePolicy = false;
        }

        if (TymCommon.isEmpty(this.agreeLocationOrg)) {
          this.checkAgreeLocation = true;
        } else {
          this.checkAgreeLocation = false;
        }

        if (TymCommon.isEmpty(this.agreeLocationInfoOrg)) {
          this.checkAgreeLocationInfo = true;
        } else {
          this.checkAgreeLocationInfo = false;
        }

        this.currentTab = "first";
        this.isShowEULA = true;

        setTimeout(() => {
          this.agreePersonal = this.agreePersonalOrg;
          this.agreePolicy = this.agreePolicyOrg;
          this.agreeLocation = this.agreeLocationOrg;
          this.agreeLocationInfo = this.agreeLocationInfoOrg;
        }, 5);
      }
    },
    closeEULA() {
      this.isShowDialog = true;
      this.dialogMessage = this.$t("eula.agreeWarning");
      this.isShowEULA = false;
    },
    addUser() {
      this.isShowEULA = false;
      if (!this.checkAgreePersonal || !this.checkAgreeLocation || !this.checkAgreeLocationInfo) {
        return;
      }
      this.isShowBirthday = true

      const store = useTymictStore();
      setTimeout(() => {
        this.viewPassword1 = false;
        this.viewPassword2 = false;
        this.verifiedID = false;

        this.isAddUser = true;
        this.newUserInfo = {
          Name: "",
          ID: "",
          Password1: "",
          Password2: "",
          Birth: "",
          Phone: "",
          Email: "",
          Postcode: "",
          Address1: "",
          Address2: "",
          Gender: this.genderList[0],
          RegDate: "",
          RD: "",
        };
        if (store.connectServer == 0) {
          this.phoneNationCode = "+82";
        } else {
          this.phoneNationCode = "+1";
        }
      }, 5);
    },
    editUser() {
      const store = useTymictStore()

      this.viewPassword1 = false;
      this.viewPassword2 = false;
      this.verifiedID = true;

      let splitPhone = TymCommon.splitPhoneNumber(this.selectedUserInfo.Phone)
      this.isEditUser = true;
      this.newUserInfo.Name = this.selectedUserInfo.Name;
      this.newUserInfo.ID = this.selectedUserInfo.ID;
      this.newUserInfo.Password1 = "";
      this.newUserInfo.Password2 = "";
      this.newUserInfo.Birth = this.selectedUserInfo.Birth;
      this.phoneNationCode = TymCommon.isEmpty(splitPhone.nation) ? this.phoneNationCodeList[store.connectServer] : splitPhone.nation
      this.newUserInfo.Phone = splitPhone.phone
      this.newUserInfo.Email = this.selectedUserInfo.Email;
      this.newUserInfo.Postcode = "";
      this.newUserInfo.Address1 = this.selectedUserInfo.Address1;
      this.newUserInfo.Address2 = this.selectedUserInfo.Address2;
      this.newUserInfo.Gender = this.selectedUserInfo.Gender;

      this.isShowBirthday = false
    },
    closeAddEdit() {
      this.isAddUser = false;
      this.isEditUser = false;
    },
    convStr2Html(input, removeLines) {
      var lines = input.split("\n");
      if (removeLines != undefined && removeLines.length > 0) {
        removeLines = removeLines.sort((a, b) => (a == b ? 0 : a < b ? 1 : -1));
        removeLines.forEach((r) => {
          lines.splice(r - 1, 1);
        });
      }

      var result = lines.join("<br>");
      return result;
    },
    printEULA() {
      let agreementText = this.agreePersonalOrg;
      let policyText = this.agreePolicyOrg;
      let locationText = this.agreeLocationOrg;
      let locationInfoText = this.agreeLocationInfoOrg;

      if(TymConst.IS_DEVELOPMENT) {
        console.log('1 :', this.agreePersonalOrg)
        console.log('2 :', this.agreePolicyOrg)
        console.log('3 :', this.agreeLocationOrg)
        console.log('4 :', this.agreeLocationInfoOrg)
      }
      if (agreementText.length == 0) {
        return;
      }
      //console.log(this.selectedUserInfo)

      const setting = "width=910, height=840";
      const objWin = window.open("", "print", setting);
      objWin.document.open();
      objWin.document.write("<!DOCTYPE html>");
      objWin.document.write(
        "<html><head><title>" + this.$t("eula.privInfo") + "</title>"
      ); /* 개인(신용)정보 수집·이용 및 제3자 제공 동의서 */
      objWin.document.write(
        '<link rel="stylesheet" href="https://netdna.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">'
      );
      objWin.document.write(
        '<link rel="stylesheet" href="../css/manage_customermain.css">'
      );
      objWin.document.write("</head><body>");

      objWin.document.write('<div class="wrap_rprint sicustom">');
      objWin.document.write(
        "<h4>" + this.$t("eula.privInfo") + '</h4><p style="font-size:11px">'
      ); /* 개인(신용)정보 수집·이용 및 제3자 제공 동의서 */
      objWin.document.write(this.convStr2Html(agreementText));
      objWin.document.write("</p></div>");

      if(!TymCommon.isEmpty(policyText)) {
        objWin.document.write('<div class="wrap_rprint sicustom">');
        objWin.document.write(
          "<br><h4>" +
            this.$t("eula.privPolicy") +
            '</h4><p style="font-size:11px">'
        ); /* 개인정보 처리 방침 */
        objWin.document.write(this.convStr2Html(policyText));
        objWin.document.write("</p></div>");
      }

      if(!TymCommon.isEmpty(locationText)) {
        objWin.document.write('<div class="wrap_rprint sicustom">');
        objWin.document.write(
          "<br><h4>" +
            this.$t("eula.locaTerms") +
            '</h4><p style="font-size:11px">'
        ); /* 위치기반서비스 이용약관 */
        objWin.document.write(this.convStr2Html(locationText));
        objWin.document.write("</p></div>");
      }

      if(!TymCommon.isEmpty(locationInfoText)) {
        objWin.document.write('<div class="wrap_rprint sicustom">');
        objWin.document.write(
          "<br><h4>" +
            this.$t("eula.locaInfoTerms") +
            '</h4><p style="font-size:11px">'
        ); /* 위치기반서비스 이용약관 */
        objWin.document.write(this.convStr2Html(locationInfoText));
        objWin.document.write("</p></div>");
      }

      let regDate = new Date(this.selectedUserInfo.RegDate);
      objWin.document.write(
        '<div style="width:300px;margin-top:30px;font-size:17px;float:right;">'
      );
      objWin.document.write(
        "<p>" +
          this.$t("eula.date") +
          " : " +
          regDate.toLocaleDateString() +
          "</p>"
      );
      objWin.document.write(
        "<p>" +
          this.$t("eula.name") +
          " : " +
          this.selectedUserInfo.Name +
          "</p>"
      );
      objWin.document.write("</div>");

      objWin.document.write("</body></html>");
      objWin.focus();
      objWin.document.close();

      setTimeout(() => {
        objWin.print();
        objWin.close();
      }, 1000);
    },
    keypressPW(option) {
      const regEx = new RegExp(
        "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"
      );
      switch (option) {
        case 1:
          if (!TymCommon.isEmpty(this.newUserInfo.Password1)) {
            this.invalidPW1 = !regEx.test(this.newUserInfo.Password1);
          } else {
            this.invalidPW1 = false;
          }
          break;
        case 2:
          if (!TymCommon.isEmpty(this.newUserInfo.Password2)) {
            this.invalidPW2 = !regEx.test(this.newUserInfo.Password2);
          } else {
            this.invalidPW2 = false;
          }
          break;
      }
    },
    popupDateSelect() {
      if (!TymCommon.isEmpty(this.newUserInfo.Birth)) {
        let birth = null
        birth = new Date(this.newUserInfo.Birth);
        /*
        if(this.isShowBirthday) {
          birth = new Date(this.newUserInfo.Birth);
        } else {
          birth = new Date()
        }
        */
        this.selectBirthday = birth.getFullYear().toString() + "/" + (birth.getMonth() + 1).toString().padStart(2, "0") + "/" + birth.getDate().toString().padStart(2, "0"); 
      }
    },
    afterDateSelect() {
      //console.log(this.selectBirthday)
      //this.$refs.birthDayPopup.hide()
    },
    closeDatePicker() {
      this.$refs.birthDayPopup.hide();
    },
    selectDate() {
      if (!TymCommon.isEmpty(this.selectBirthday)) {
        let birth = new Date(this.selectBirthday);
        // console.log(this.selectBirthday, birth)
        this.newUserInfo.Birth = TymCommon.getDateString(birth);
        this.isShowBirthday = true
      }
      this.$refs.birthDayPopup.hide();
    },
    checkInputValid() {
      if (TymCommon.isEmpty(this.newUserInfo.Name)) {
        TymCommon.Toast(this.$t("warning.inputYourName"), true);
        return false;
      }
      if (TymCommon.isEmpty(this.newUserInfo.ID)) {
        TymCommon.Toast(this.$t("placeholder.inputID"), true);
        return false;
      }
      if (TymCommon.isEmpty(this.newUserInfo.Phone)) {
        TymCommon.Toast(this.$t("placeholder.inputPhone"), true);
        return false;
      }
      /*
      if (TymCommon.isEmpty(this.newUserInfo.Email)) {
        TymCommon.Toast(this.$t("placeholder.inputEmail"), true);
        return false;
      }
      if (TymCommon.isEmpty(this.newUserInfo.Postcode)) {
        TymCommon.Toast(this.$t("placeholder.inputPostcode"), true);
        return false;
      }
      if (TymCommon.isEmpty(this.newUserInfo.Address1)) {
        TymCommon.Toast(this.$t("placeholder.inputAddress"), true);
        return false;
      }
      */

      if (this.isAddUser) {
        if (TymCommon.isEmpty(this.newUserInfo.Password1) || TymCommon.isEmpty(this.newUserInfo.Password2)) {
          TymCommon.Toast(this.$t("placeholder.inputPassword"), true);
          return false;
        } else if (this.newUserInfo.Password1 != this.newUserInfo.Password2) {
          TymCommon.Toast(this.$t("login.inputSamePW"), true);
          return false;
        }
        
        if(!TymCommon.checkPassword(this.newUserInfo.Password1)) {
          TymCommon.Toast(this.$t("login.passwordGuide"));
          return false;
        }
      } else if (this.isEditUser) {
        if (!TymCommon.isEmpty(this.newUserInfo.Password1) || !TymCommon.isEmpty(this.newUserInfo.Password2)) {
          if (this.newUserInfo.Password1 != this.newUserInfo.Password2) {
            TymCommon.Toast(this.$t("login.inputSamePW"), true);
            return false;
          } else if(!TymCommon.checkPassword(this.newUserInfo.Password1)) {
            TymCommon.Toast(this.$t("login.passwordGuide"));
            return false;
          }
        }
      }
      return true;
    },
    onChangedID(value) {
      if (!TymCommon.isEmpty(value)) {
        this.newUserInfo.ID = value.replace(/[^A-Za-z0-9]/gi, "");
      } else {
        this.verifiedID = false;
      }
    },
    onCheckID() {
      if (!TymCommon.isEmpty(this.newUserInfo.ID)) {
        if (this.newUserInfo.ID.length >= 4) {
          this.verifiedID =
            this.usersData.find((x) => x.userid == this.newUserInfo.ID) == null;
        } else {
          TymCommon.Toast("Please input at least 4 characters.");
        }
      }
    },
    // 입력한 전화번호를 저장형식으로 바꿈
    makePhoneData(phone) {
      let result = phone;
      if (!TymCommon.isEmpty(phone)) {
        if (this.isKorea) {
          if (phone[0] == "0") {
            result = phone.substring(1);
          }
        } else {
          //
        }
      }
      return result;
    },
    addUserToCognito() {
      let store = useTymictStore();

      let birthday = "";
      let emailEnable = "false";
      let eMail = "";
      let gender = "M";

      if (!TymCommon.isEmpty(this.newUserInfo.Birth)) {
        let birth = new Date(this.newUserInfo.Birth);
        birthday =
          birth.getFullYear().toString() +
          (birth.getMonth() + 1).toString().padStart(2, "0") +
          birth.getDate().toString().padStart(2, "0");
      }
      if (TymCommon.isEmpty(this.newUserInfo.Birth)) {
        emailEnable = "true";
        eMail = TymConst.NULL_EMAIL;
      } else {
        eMail = this.newUserInfo.Email;
      }
      if (!TymCommon.isEmpty(this.newUserInfo.Gender)) {
        if (
          this.newUserInfo.Gender != "Man" &&
          this.newUserInfo.Gender != "남성"
        ) {
          gender = "W";
        }
      }

      let phoneNumber = "";
      phoneNumber = this.phoneNationCode + this.makePhoneData(this.newUserInfo.Phone);

      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let param = {
        UserPoolId: store.usercognito[store.connectServer].userPoolId,
        Username: this.newUserInfo.ID,
        DesiredDeliveryMediums: [],
        MessageAction: "SUPPRESS",
        UserAttributes: [
          {
            Name: "name",
            Value: this.newUserInfo.Name,
          },
          {
            Name: "gender",
            Value: gender,
          },
          {
            Name: "phone_number",
            Value: phoneNumber,
          },
          {
            Name: "custom:caddress1",
            Value: this.newUserInfo.Address1,
          },
          {
            Name: "custom:caddress2",
            Value: TymCommon.isEmpty(this.newUserInfo.Address2)
              ? ""
              : this.newUserInfo.Address2,
          },
          {
            Name: "custom:cbirthday",
            Value: birthday,
          },
          {
            Name: "phone_number_verified",
            Value: "true",
          },
          {
            Name: "email_verified",
            Value: emailEnable,
          },
          {
            Name: "email",
            Value: eMail,
          },
        ],
      };

      // console.log(this.newUserInfo)
      let self = this;
      let selfUserInfo = this.newUserInfo;
      cognitoidentityserviceprovider.adminCreateUser(
        param,
        function (err, data) {
          if (err) {
            //사용자 추가 에러.
            console.log(err, err.code); // an error occurred
            if (err.code == "UsernameExistsException") {
              //tym_alert_yesno(i18n.t('code.ERR_INVALID_ID'), 1);
            } else if (err.code == "InvalidParameterException") {
              //tym_alert_yesno(i18n.t('code.ERR_INVALID_EMAIL'), 1);
              //양식오류 - email
            }
          } else {
            console.log(data, selfUserInfo);

            let addedParam = {
              UserPoolId: store.usercognito[store.connectServer].userPoolId,
              Username: data.User.Username,
              Permanent: true,
              Password: selfUserInfo.Password1,
            };

            let selfUserInfo2 = selfUserInfo;
            cognitoidentityserviceprovider.adminSetUserPassword(addedParam, function (pwerr, pwdata) {
                if (pwerr) {
                  console.log(pwerr, pwerr.code); // an error occurred
                  let paramvalue = {
                    UserPoolId: store.usercognito[store.connectServer].userPoolId,
                    Username: selfUserInfo2.ID,
                  };

                  if (pwerr.code == "InvalidPasswordException" || pwerr.code == "InvalidParameterException" ) {
                    //tym_alert_yesno(i18n.t('code.ERR_INVALID_PW_LENGTH'), 1);
                    //패스워드 양식오류
                  }

                  // password에러
                  cognitoidentityserviceprovider.adminDeleteUser(
                    paramvalue,
                    function (err, data) {
                      if (err) {
                        console.log(err, err.stack); // an error occurred
                      } else {
                        console.log(data);
                      }
                    }
                  );
                } else {
                  console.log(typeof pwdata)
                }
              }
            );
            store.getCustomerData(this.readCustomerResult);

            TymCommon.Toast(self.$t("notice.addUserOK"));
            TymAws.lookup(TymConst.ACTION_ADD_USER, selfUserInfo2.ID);
          }
        }
      );
    },
    updateCognitoUser() {
      let store = useTymictStore();

      let changePW = !TymCommon.isEmpty(this.newUserInfo.Password1);
      let birthday = "";
      let emailEnable = "false";
      let eMail = "";
      let gender = "M";
      /*
      if (TymCommon.isEmpty(this.newUserInfo.Password1) && TymCommon.isEmpty(this.newUserInfo.Password2)) {
        changePW = false;
      } else {
        if (TymCommon.isEmpty(this.newUserInfo.Password1) || TymCommon.isEmpty(this.newUserInfo.Password2)) {
          // 암호 둘중 하나는 비었네?
          TymCommon.Toast(this.$t("notice.checkPW"));
          return;
        } else {
          if (this.newUserInfo.Password1.localeCompare(this.newUserInfo.Password2) != 0) {
            // 암호가 다르게 입력되었구나...
            TymCommon.Toast(this.$t("notice.checkPW"));
            return;
          }
        }
      }
      if(!TymCommon.checkPassword(this.newUserInfo.Password1)) {
        TymCommon.Toast(this.$t("login.passwordGuide"));
        return;
      }
      */
      if (!TymCommon.isEmpty(this.newUserInfo.Birth)) {
        let birth = new Date(this.newUserInfo.Birth);
        birthday = birth.getFullYear().toString() + (birth.getMonth() + 1).toString().padStart(2, "0") + birth.getDate().toString().padStart(2, "0"); 
      }
      if (TymCommon.isEmpty(this.newUserInfo.Birth)) {
        emailEnable = "true";
        eMail = TymConst.NULL_EMAIL;
      } else {
        eMail = this.newUserInfo.Email;
      }
      if (!TymCommon.isEmpty(this.newUserInfo.Gender)) {
        if ( this.newUserInfo.Gender != "Man" && this.newUserInfo.Gender != "남성") {
          gender = "W";
        }
      }

      let phoneNumber = "";
      phoneNumber = this.phoneNationCode + this.makePhoneData(this.newUserInfo.Phone);

      console.log('updateCognitoUser :', this.newUserInfo, phoneNumber);

      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let param = {
        UserPoolId: store.usercognito[store.connectServer].userPoolId,
        Username: this.newUserInfo.ID,
        UserAttributes: [
          {
            Name: "name",
            Value: this.newUserInfo.Name,
          },
          {
            Name: "gender",
            Value: gender,
          },
          {
            Name: "phone_number",
            Value: phoneNumber,
          },
          {
            Name: "custom:caddress1",
            Value: TymCommon.isEmpty(this.newUserInfo.Address1) ? " " : this.newUserInfo.Address1,
          },
          {
            Name: "custom:caddress2",
            Value: TymCommon.isEmpty(this.newUserInfo.Address2) ? " " : this.newUserInfo.Address2,
          },
          {
            Name: "custom:cbirthday",
            Value: birthday,
          },
          {
            Name: "phone_number_verified",
            Value: "true",
          },
          {
            Name: "email_verified",
            Value: emailEnable,
          },
          {
            Name: "email",
            Value: TymCommon.isEmpty(eMail) ? " " : eMail,
          },
        ],
      };

      let selfUserInfo = this.newUserInfo;
      let self = this;
      cognitoidentityserviceprovider.adminUpdateUserAttributes(param,
        function (err, data) {
          if (err) {
            //사용자 추가 에러.
            console.log(err, err.code); // an error occurred
            if (err.code == "UsernameExistsException") {
              //tym_alert_yesno(i18n.t('code.ERR_INVALID_ID'), 1);
              TymCommon.Toast(self.$t('group.usedID'))
            } else {
              TymCommon.Toast(err.message, true)
            }
          } else {
            if (changePW) {
              self.unusedParam(data);
              const store2 = useTymictStore()
              console.log("Change PW");

              let updatedParam = {
                UserPoolId: store2.usercognito[store2.connectServer].userPoolId,
                Username: selfUserInfo.ID,
                Permanent: true,
                Password: selfUserInfo.Password1,
              };

              let selfUserInfo2 = selfUserInfo;
              cognitoidentityserviceprovider.adminSetUserPassword(updatedParam, function (pwerr, pwdata) {
                  if (pwerr) {
                    console.log(pwerr, pwerr.code); // an error occurred
                    let paramvalue = {
                      UserPoolId: store2.usercognito[store2.connectServer].userPoolId,
                      Username: selfUserInfo2.ID,
                    };

                    if (pwerr.code == "InvalidPasswordException" || pwerr.code == "InvalidParameterException") {
                      //tym_alert_yesno(i18n.t('code.ERR_INVALID_PW_LENGTH'), 1);
                      //패스워드 양식오류
                    }

                    // password에러
                    if(!this.isEditUser) {
                      cognitoidentityserviceprovider.adminDeleteUser(
                        paramvalue,
                        function (err, data) {
                          if (err) {
                            console.log(err, err.stack); // an error occurred
                          } else {
                            console.log(data);
                          }
                        }
                      );
                    }
                  } else {
                    console.log('updated Customer OK ;', pwdata)

                    let SignoutParam = {
                      UserPoolId: store2.usercognito[store2.connectServer].userPoolId,
                      Username: selfUserInfo2.ID,
                    };

                    cognitoidentityserviceprovider.adminUserGlobalSignOut(SignoutParam, function (err, data) {
                        if (err) {
                          console.log("Sign OUT err");
                        } else {
                          console.log("Sign OUT SUCCESS :", typeof data);
                        }
                      }
                    );

                    TymAws.lookup(TymConst.ACTION_EDIT_USER, selfUserInfo.ID);
                    setTimeout(() => {
                      self.readUserData();
                      TymCommon.Toast(self.$t('notice.editUserOK'))
                    }, 10);

                    // 사용자 계정 업데이트
                    TymAws.editUserInfo(selfUserInfo.ID, selfUserInfo.Name, selfUserInfo.Phone)
                      .then(data => {
                        self.unusedParam(data)
                      })
                      .catch(er => {
                        console.log(er)
                        TymCommon.Toast(self.$t('errorMsg.failUdpateAccount'))
                      })

                  }
                }
              );
            } else {
              TymAws.lookup(TymConst.ACTION_EDIT_USER, selfUserInfo.ID);

              setTimeout(() => {
                self.readUserData();
                TymCommon.Toast(self.$t('notice.editUserOK'))
              }, 10);
            }
          }
        }
      );
    },
    saveEdit() {
      if (this.checkInputValid()) {
        if (this.isEditUser) {
          this.isShowBirthday = false
          this.updateCognitoUser();
        } else {
          if (!this.verifiedID) {
            TymCommon.Toast(this.$t("errorMsg.checkIDfirst"), true);
            return;
          }

          this.addUserToCognito();

          setTimeout(() => {
            this.readUserData();
          }, 5);
        }
      }
    },
    closePostCode() {
      this.visiblePostCode = false
    },
    searchAddress() {
      this.visiblePostCode = true
    },
    onComplete(result) {
      console.log('DAUM addr :', result)
      if(!TymCommon.isEmpty(result.address)) {
        this.newUserInfo.Address1 = result.address
        this.newUserInfo.Address2 = ''
      } else if(!TymCommon.isEmpty(result.roadAddress)) {
        this.newUserInfo.Address1 = result.roadAddress
        this.newUserInfo.Address2 = ''
      } else if(!TymCommon.isEmpty(result.jibunAddress)) {
        this.newUserInfo.Address1 = result.jibunAddress
        this.newUserInfo.Address2 = ''
      }
      if(!TymCommon.isEmpty(result.zonecode)) {
        this.newUserInfo.Postcode = result.zonecode
      }
      setTimeout(() => {
        this.visiblePostCode = false
      }, 25)
    },
    doShowBirthday() {
      if(this.isShowBirthday) {
        this.isShowBirthday = false
      } else {
        this.dialogInputPassword = ''
        this.passwordDlgMsg = this.$t('warning.needPasswordPrivacy')
        this.inputPasswordDlg = true      
      }
    },
    inputPasswordConfirmed() {
      try {
        if(!TymCommon.isEmpty(this.dialogInputPassword)) {
          TymAws.checkPassword(this.dialogInputPassword, this.checkPasswordOK, this.checkPasswordFailed)
        }
      } catch(ex) {
        console.log(ex)
      }
    },
    checkPasswordOK() {
      this.isShowBirthday = true
    },
    checkPasswordFailed() {
      TymCommon.Toast(this.$t('noticeView.deleteNoticeMsg2'))
    },
  },
};
</script>
