// import axios from 'axios'

import { useTymictStore } from "@/store/tymict";

const DomesticRootAuth = {
    "uLevel": "1",
    "uuid": "2",
    "uuidArray": [{"MA": "0", "MB": "2"}],
    "uuid_a": "0",
    "uuid_b": "2",
    "uuid_c": null,
    "uuid_d": null
};

const RootAuth = {
    "uLevel": "0",
    "uuid": "0",
    "uuidArray": [{"MA": "0"}],
    "uuid_a": "0",
    "uuid_b": null,
    "uuid_c": null,
    "uuid_d": null
};


function getUleveFromAuthIndex(authIndex) {
    const store = useTymictStore()
    authIndex = Number(authIndex);

    var uLevel = 99;

    switch(store.connectServer) {
        case 0:
            switch(authIndex) {
                case 0:    // 관리자
                case 99:    // TYMICT
                    uLevel = 0;
                    break;
                case 2:     // 지점
                    uLevel = 2;
                    break;
                case 1:     // 대리점
                    uLevel = 3;
                    break;
                default:
                    uLevel = 1;        
            }
            break;
        case 1:                 // 북미
            switch(authIndex) {
                case 3:    // 관리자
                case 99:    // TYMICT
                    uLevel = 0;
                    break;
                case 2:     // 지점
                    uLevel = 2;
                    break;
                case 1:     // 대리점
                    uLevel = 3;
                    break;
                default:
                    uLevel = 1;        
            }
            break;
    }

    return uLevel;
}

function getProxyUser(ms) {
    if (ms == undefined)
        return null;

    var authIndex = Number(ms);

    var proxyUser = null;

    switch(authIndex) {
        case 4: // 기계생산
        case 5: // 연구소
        case 8: // 본사
            proxyUser = RootAuth;
            break;        
        case 3: // 국내 C/S
            proxyUser = DomesticRootAuth;
            break;        
    }

    return proxyUser;
}

function getProxyUserForMain(ms) {
    if (ms == undefined)
        return null;

    var authIndex = Number(ms);
    var proxyUser = null;

    switch(authIndex) {
        case 0: // 관리자
        case 3: // 국내 C/S
        case 4: // 기계생산
        case 5: // 연구소
        case 8: // 본사
        case 9: // CCC
            proxyUser = DomesticRootAuth;
            break;        
        case 99: // TYMICT
            proxyUser = RootAuth;
            break;        
    }

    return proxyUser;    
}

function getUuidFromLevel(level, userdata) {
    if(userdata == null)
        return null

    let uuid = ''
    try {
        switch(Number(level)) {
        case 0:
        case 99:
            uuid = userdata["custom:uuid_a"];
            break;
        case 1:
            uuid = userdata["custom:uuid_b"];
            break;
        case 2:
            uuid = userdata["custom:uuid_c"];
            break;
        case 3:
            uuid = userdata["custom:uuid_d"];
            break;
        }
    } catch(ex) {
        console(ex)
    }
    return uuid
}

export default {
    DomesticRootAuth,
    RootAuth,
    getUleveFromAuthIndex,
    getProxyUser,
    getProxyUserForMain,
    getUuidFromLevel,
}
