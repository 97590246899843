<template>

<div class="column">
  <div class="col column" style="padding:0 12px 12px 12px">
    <div class="col-auto row items-center" style="height:45px">
      <div class="col-auto"
          :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
        {{ getPageTitle }}
      </div>
    </div>

    <div class="col-auto row" style="background-color: white;border-radius:3px;border:1px solid #ebedf2;margin:0 0 12px 0;overflow-y: hidden;">
      <q-scroll-area class="col row" style="width:100%;height:36px">
        <q-tabs v-model="selectedTab"
                dense flat 
                align="left"
                indicator-color="transparent"
                class="text-grey-10" 
                active-color="red-7">
          <q-tab name="sales" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            {{ $t('statView.sales') }}
          </q-tab>
          <q-tab name="engine" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            {{ $t('statView.usage') }}
          </q-tab>
          <q-tab name="emergency" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            {{ $t('statView.emergency') }}
          </q-tab>
          <q-tab name="error" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            {{ $t('statView.error') }}
          </q-tab>
          <q-tab name="consumable" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            {{ $t('statView.consumables') }}
          </q-tab>
          <q-tab name="battery" style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            {{ $t('statView.lowBattery') }}
          </q-tab>
        </q-tabs>
      </q-scroll-area>
    </div>

    <div class="col row bottomArea" style="background-color: white;border-radius:3px;">
      <div class="col row">
        <q-tab-panels dense v-model="selectedTab" class="col row" @before-transition="tabBeforeTransition">
          <q-tab-panel dense name="sales" class="col" style="padding:0;overflow-x:hidden;">
            <StatSales />
          </q-tab-panel>
          <q-tab-panel dense name="engine" style="padding:0;overflow-x:hidden;">
            <StatEngineHour />
          </q-tab-panel>
          <q-tab-panel dense name="emergency" style="padding:0;overflow-x:hidden;">
            <StatEmergency />
          </q-tab-panel>
          <q-tab-panel dense name="error" style="padding:0;overflow-x:hidden;">
            <StatErrors />
          </q-tab-panel>
          <q-tab-panel dense name="consumable" style="padding:0;overflow-x:hidden;">
            <StatConsumables />
          </q-tab-panel>
          <q-tab-panel dense name="battery" style="padding:0;overflow-x:hidden;">
            <StatBatteryWarnings />
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>

.title {
  font-size: 16px;
  font-weight: 600;
}
</style>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTymictStore } from '@/store/tymict'
import TymFncs from '@/js/tymfunctions.js'
import TymCommon from '@/js/tymcommon.js'
import StatSales from '@/components/StatSales.vue'
import StatEngineHour from '@/components/StatEngineHour.vue'
import StatEmergency from '@/components/StatEmergency.vue'
import StatErrors from '@/components/StatErrors.vue'
import StatConsumables from '@/components/StatConsumables.vue'
import StatBatteryWarnings from '@/components/StatBatteryWarnings.vue'
import TymConst from '@/js/tymconstants.js'

export default ({
  components : {
    StatSales,
    StatEngineHour,
    StatEmergency,
    StatErrors,
    StatConsumables,
    StatBatteryWarnings
  },
  data() {
    return {
    }
  },
        
  computed : {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean() {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    getCategory() {
      const i18n=useI18n()
      return [
        i18n.t('commom.all'),
        i18n.t('noticeView.selectcommon'),
        i18n.t('noticeView.selectevent'),
        i18n.t('noticeView.selectnotread'),
        i18n.t('noticeView.selectfavorite')
      ]
    }
  },
  setup() {
    return {
      selectedTab : ref('')
    }
  },
  created() {},
  mounted() {
    const store = useTymictStore()
    if(store.CurrentPage.Now != TymConst.PAGE_STAT) {
      store.setPage(TymConst.PAGE_STAT)
    }

    let saved = sessionStorage.getItem('STAT.saved')
    if(!TymCommon.isEmpty(saved)) {
      saved = JSON.parse(saved)
      console.log('STAT.saved load :', saved)
      this.selectedTab = saved.Tab
      sessionStorage.removeItem('STAT.saved')
    } else {
      this.selectedTab = 'sales'
    }
  }, 
  unmounted() {
    sessionStorage.setItem('STAT.saved', JSON.stringify({
      Tab: this.selectedTab
    }))
  },
  methods : {
    tabBeforeTransition(newVal, oldVal) {
      if(TymConst.IS_DEVELOPMENT)
        console.log('tabBeforeTransition :', newVal, oldVal)
      
      if(!TymCommon.isEmpty(oldVal)) {
        switch(newVal) {
          case 'sales':
            sessionStorage.removeItem('STAT.sale.saved')
            break;
          case 'engine':
            sessionStorage.removeItem('STAT.usage.saved')
            break;
          case 'emergency':
            sessionStorage.removeItem('STAT.em.saved')
            break;
          case 'error':
            sessionStorage.removeItem('STAT.br.saved')
            break;
          case 'consumable':
            sessionStorage.removeItem('STAT.cons.saved')
            break;
          case 'battery':
            sessionStorage.removeItem('STAT.batt.saved')
            break;
        }
      }
    }
  }
})
</script>
