<template>
  <div class="column" :style='isLandscape ? "width:100%;height:100%;" : "width:100%;height:100%;"' style="background-color:#F2F2F2">
    <div class="col-auto column items-center justify-start" style="border:1px solid #ebedf2;border-radius:3px;">
      <table class="col" style="width:100%;background-color:white;padding:12px;border-radius:3px">
        <tr>
          <td width="100%">
            <!-- 제목 -->
            <div class="col" style="font-size:18px;font-weight:500;"
                 :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('statView.sales') }}</div>
          </td>
        </tr>
        <tr>
          <td width="100%">
            <div class="col row items-center justify-between no-wrap" style="">
              <div class="col-auto row items-center justify-start ">
                <!-- 조직 선택 -->
                <div class="col-auto row grpBtn items-center justify-between"
                     style="min-width:180px;width:auto;height:40px;padding:0 6px 0 0;">
                  <div class="col row items-center justify-between" style="cursor:pointer;padding:0 0 0 12px;height:100%;" @click="groupPopupDisplayed = !groupPopupDisplayed">
                    <q-popup-proxy ref="groupPopup" style="padding:3px;" @hide="groupPopupDisplayed=false">
                      <div class="column" style="min-width:250px;height:250px;">
                        <div class="col row" style="border:1px solid #cccccc;padding:3px;background-color:white;">
                          <q-scroll-area class="col row" style="width:auto;height:100%;">
                            <q-tree
                              :nodes="groupTreeData"
                              dense
                              no-selection-unset
                              class="col"
                              node-key="uuid"
                              v-model:expanded="expandedTree"
                              v-model:selected="selectedTree"
                              @update:selected="groupTreeSelected"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </q-popup-proxy>
                    <div class="col-auto">
                      {{selectedTreeLabel}}
                    </div>
                    <q-icon class="col-auto" dense  color="grey-8" :name='groupPopupDisplayed ? "arrow_drop_up" : "arrow_drop_down"' size="24px">
                    </q-icon>
                  </div>
                  <!--
                  <q-btn class="col-auto" dense flat rounded color="grey-7" icon="close" size="md" @click="clearGroupData"/>
                  -->
                </div>

                <!-- 년도 -->
                <q-select dense outlined options-dense v-model="selectedYear" :options="dataYears"
                          class="col-auto" color="red-7"
                          hide-bottom-space
                          style="font-size:14px;min-width:100px;margin:0 0 0 6px;">
                </q-select>
                <!-- 월 선택 -->
                <q-select dense outlined options-dense v-model="selectedMonth" :options="getMonthTitle"
                          class="col-auto" color="red-7"
                          hide-bottom-space
                          style="font-size:14px;min-width:100px;margin:0 0 0 6px;">
                </q-select>

                <!-- 기기 종류 선택 -->
                <q-select dense outlined options-dense v-model="catSelected" :options="getCategory"
                          class="col-auto" color="red-7"
                          hide-bottom-space 
                          @update:model-value="changedCategory"
                          style="font-size:14px;min-width:160px;margin:0 0 0 6px;">
                </q-select>

                <div class="col row items-center justify-center">
                  <q-btn class="col" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')"
                        style="width:80px;height:40px;margin:0 0 0 6px;" @click="searchData()">
                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.searchData') }}</q-tooltip>
                  </q-btn>
                </div>
              </div>
              <div class="col-1 row justify-end" v-show="canDownloadToExcel">
                <!-- 엑셀로 저장 -->
                <q-btn flat dense @click="downloadToExcel">
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                  <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;"/>
                  <q-popup-proxy ref="excelPopup" style="width:360px;height:240px;background-color: #01010130;border-radius: 3px;position: relative;"
                                  @before-show="onShowExportToExcel">
                    <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                      <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                        <div class="col-auto row items-center justify-center">
                          <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                            {{ $t('common.toExcel') }}
                          </div>
                        </div>
                        <div class="col column" v-show="hasExcelData">
                          <div class="col row items-center justify-start" style="width:100%;margin:0;">
                            <table class="col" style="width:100%;margin:0;">
                              <tr style="">
                                <td width=30% style="text-align: right;">{{ $t('common.filename') }}</td>
                                <td width="12px" style=""></td>
                                <td style="">{{ excelFilename }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.from') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelFrom }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.to') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelTo }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('dashboardView.dealer') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelDealer }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.machineType') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelType }}</td>
                              </tr>
                            </table>
                          </div>
                          <xlsx-workbook class="col-auto row items-center justify-center">
                            <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                            <xlsx-download :filename="excelFilename">
                              <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveToExcelFile">{{ $t('common.download') }}</button>
                            </xlsx-download>
                          </xlsx-workbook>
                        </div>
                        <div class="col row items-center justify-center" v-show="!hasExcelData" style="width:100%;height:100%;">
                          <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:bold;color:#eb0028">{{ $t('warning.nodata')}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row items-center justify-center" v-show="makingExcel"
                        style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                      <q-spinner color="red-7" size="70px" thickness="1.5"/>
                    </div>
                  </q-popup-proxy>
                </q-btn>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- 하단 차트들 -->
    <div class="col row" style="height:100%;margin:12px 0 0 0;">
      <div class="col row" style="margin:0px;">
        <q-splitter v-model="horizSplitter" :limits="[20, 80]" 
                    horizontal
                    separator-style="height:12px;background-color:red;"
                    class="col"
                    separator-class="transparent">
          <template v-slot:before>
            <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
            <q-splitter v-model="upSplitter" :limits="[20, 80]" 
                        :separator-style='!isLandscape ? "height:12px" : "width:12px"'
                        :horizontal="!isLandscape"
                        separator-class="transparent">
              <template v-slot:before>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 지역별로 변경... 변수는 그대로 사용할까?-->
                    <div class="col column">
                      <div class="col-auto row" style="height:42px;">
                        <div class="col row " style="margin:0 0 4px 0;">
                          <div class="col-auto row items-center">
                            <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500;'" style="font-size:16px;">{{ titleForSales }}</div>
                            <q-separator color="grey-7" vertical style="margin:9px 6px 9px 12px;" v-show="termByDealer.length > 0"></q-separator>
                            <div class="col-auto" style="font-family:Prometo;font-size:15px;color:#eb0028" v-show="termByDealer.length > 0">&nbsp;{{ termByDealer }}</div>
                          </div>
                        </div>
                        <div class="col-auto row items-center" >
                          <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:15px;">{{ $t('statSales.totalSales')}} : {{ totalByDealer }}</div>
                          <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:15px;" v-show="totalADSByDealer > 0">&nbsp;[ADS : {{ totalADSByDealer }}]</div>
                        </div>
                      </div>
                      <div id="dealerContainer" class="col row q-mt-xs q-pa-sm items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-scroll-area class="col column items-center justify-center" style="width:100%;height:100%;border-radius: 3px;">
                          <div ref="refDealer" id="dealerChart" class="col" style="border-radius:3px;margin:0 10px 0 0;"></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeDealer"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:separator>
                <div style="background-color:#f2f2f2;" class="row items-center justify-center">
                  <div :class='isLandscape ? "vertSeparator" : "horizSeparator"' style=""></div>
                </div>
              </template>
              <template v-slot:after>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 기종/모델별 판매 -->
                    <div class="col column" style="">
                      <div class="col-auto row items-center justify-between" style="height:42px;">
                        <div class="col row items-center justify-start">
                          <div class="col-auto row items-center justify-start">
                            <q-select dense outlined options-dense v-model="selectedModelOptionTab" :options="selectModelOptionTabs"
                                    class="col-auto abc" color="red-7"
                                    hide-bottom-space
                                    @update:model-value="changedModelOptionTab"
                                    style="color:black;margin:-6px 0 0 0;">
                              <template v-slot:selected>
                                <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500;'" style="font-size:16px;">
                                  {{selectedModelOptionTab}}
                                </div>
                              </template>
                            </q-select>
                          </div>
                          <!--
                          <q-separator color="grey-7" vertical style="margin:3px 3px 3px 6px;"></q-separator>
                          <div class="col-auto" style="font-size:16px;font-weight:400;color:#eb0028;">&nbsp;{{ termByDealer }}</div>
                          -->
                        </div>
                        <div class="col row items-center justify-end">
                          <q-select borderless dense options-dense v-model="selectedModelOption" :options="selectModelOptions"
                                    class="col-auto" color="red-7" hide-bottom-space v-show="!chartForModel"
                                    @update:model-value="changedSelectedModel"
                                    :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:16px;min-width:80px;width:auto;margin:-12px 0 0 0;height:20px">
                          </q-select>
                          <!-- 
                          <div class="col" style="font-size:14px;font-weight:400;">판매 수량 : {{ totalByModelOption }}</div>
                          <div class="col-auto" style="font-size:14px;font-weight:400;" v-show="totalADSByModelOption > 0">&nbsp;[ADS : {{ totalADSByModelOption }}]</div>
                          -->
                        </div>
                      </div>
                      <div id="modelOptionContainer" class="col row q-mt-xs q-pa-sm items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-scroll-area class="col row items-center justify-center" style="position:absolute;left:6px;top:6px;right:6px;bottom:6px;border-radius: 3px;">
                          <div ref="refModelOption" id="modelOptionChart" class="col-auto" style=""></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeModelOption"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </q-splitter>
          </template>
          <template v-slot:separator>
            <div style="background-color:#f2f2f2;width:100%;height:12px;" class="row items-center justify-center">
              <div class="horizSeparator" style=""></div>
            </div>
          </template>
          <template v-slot:after>
            <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
            <q-splitter v-model="downSplitter" :limits="[20, 80]" 
                        :separator-style='!isLandscape ? "height:12px" : "width:12px"'
                        :horizontal="!isLandscape"
                        separator-class="transparent">
              <template v-slot:before>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 기종/모델별 판매 -->
                    <div class="col column" style="">
                      <div class="col-auto row items-center justify-between" style="height:42px;">
                        <div class="col row items-center justify-start">
                          <div class="col-auto row items-center justify-start">
                            <q-select dense outlined options-dense v-model="selectedDealerModelOptionTab" :options="selectDealerModelOptionTabs"
                                    class="col-auto" color="red-7"
                                    hide-bottom-space 
                                    @update:model-value="changedDealerModelOptionTab"
                                    style="font-size:16px;color:black;margin:-6px 0 0 0;">
                              <template v-slot:selected>
                                <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold':'font-family:Prometo;font-weight:500'" style="font-size:16px;">
                                  {{selectedDealerModelOptionTab}}
                                </div>
                              </template>
                            </q-select>
                            <!--
                            <div class="col-auto" style="font-size:18px;font-weight:500;">지역&nbsp;</div>
                            <div class="col" style="font-size:18px;font-weight:500;cursor: pointer;"
                                  :style='tableForModel ? "color:#eb0028" : "color:black"'
                                  @click="changeTableOption(true)">기종별</div>
                            <div class="col" style="font-size:18px;font-weight:500;cursor: pointer;"
                                  :style='!tableForModel ? "color:#eb0028" : "color:black"'
                                  @click="changeTableOption(false)">모델별</div>
                            <div style="font-size:18px;font-weight:500;color:black">&nbsp;판매</div>
                            -->
                          </div>
                          <!--
                          <q-separator color="grey-7" vertical style="margin:3px 3px 3px 6px;"></q-separator>
                          <div class="col-auto" style="font-size:16px;font-weight:400;color:#eb0028;">&nbsp;{{ termByDealer }}</div>
                          -->
                        </div>
                        <div class="col row items-center justify-end">
                          <q-select borderless dense options-dense v-model="selectedDealerModel" :options="selectDealerModels"
                                    class="col-auto" color="red-7" hide-bottom-space v-show="!tableForModel"
                                    @update:model-value="changedDealerModel"
                                    :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:16px;min-width:80px;margin:-12px 0 0 0;height:20px">
                          </q-select>
                        </div>
                      </div>
                      <div class="col row q-mt-xs items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-table class="col"
                                ref="modelTable"
                                style="position:absolute;left:0;top:0;right:0;bottom:0"
                                dense flat virtual-scroll hide-no-data
                                separator="none"
                                :columns="tableColumns"
                                :visible-columns="tableVisibleColumns"
                                :rows="tableRows"
                                :rows-per-page-options="[0]"
                                hide-pagination
                                row-key="index">
                          <template v-slot:header="props">
                            <q-th no-caps style="height:auto;background-color: white;border-right:1px solid #e0e0e0;" class="tableHeader"
                                  :style='col.name=="col0" ? "position:sticky;z-index:4;left:0":""'
                                  v-for="col in props.cols" :key="col.name" :props="props">
                              {{ col.label }}
                            </q-th>
                          </template>
                          <template v-slot:body="props">
                            <q-tr :props="props" :style='props.row.index % 2 == 0 ? "background-color:#f8f8f8;" : "background-color:white;"'>
                              <q-td v-for="col in props.cols" :key="col.name" :props="props" style="border-bottom:1px solid #e0e0e0;border-right:1px solid #e0e0e0;"
                                    :style='col.name=="col0" ? "font-weight:500;position:sticky;z-index:2;left:0;":"font-weight:400;"'>
                                {{ col.value }}
                              </q-td>
                            </q-tr>
                          </template>
                        </q-table>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:separator>
                <div style="background-color:#f2f2f2;" class="row items-center justify-center">
                  <div :class='isLandscape ? "vertSeparator" : "horizSeparator"'></div>
                </div>
              </template>
              <template v-slot:after>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 연간 모델별 -->
                    <div class="col column">
                      <div class="col-auto row items-center justify-between" style="height:42px;">
                        <div class="col row justify-start">
                          <div class="col-auto row items-center">
                            <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500;'" style="font-size:16px">{{ $t('statSales.sybymodel')}}</div>
                            <q-separator color="grey-7" vertical style="margin:3px 6px 3px 12px;" v-show="latestTerm != ''"></q-separator>
                            <div class="col-auto" style="font-family:Prometo;font-size:15px;color:#eb0028" v-show="latestTerm != ''">&nbsp;{{ latestTerm }}</div>
                          </div>
                        </div>
                        <div class="col-auto row items-center justify-center" >
                          <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:15px;">{{ $t('statSales.totalSales')}} : {{ totalByYear }}</div>
                        </div>
                      </div>
                      <div id="yearModelContainer" class="col row q-mt-xs q-pa-sm items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-scroll-area class="col row items-center justify-center" style="position:absolute;left:6px;top:6px;right:6px;bottom:6px;border-radius: 3px;">
                          <div ref="refYearModel" id="yearModelChart"></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeYearModel"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </q-splitter>
          </template>

        </q-splitter>
      </div>
      <div class="row items-center justify-center" v-show="loadingData"
          style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
        <q-spinner-tail dense color="red-7" size="100px"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.abc {
  font-family: Prometo;
  font-size:32px;
  font-weight: 600;
}
.grpBtn {
  height:40px;
  border-radius: 3px;
  border:1px solid #cccccc;
}
.grpBtn:hover {
  height:40px;
  border-radius: 3px;
  border:1px solid black;
}
.grpBtn:active {
  height:40px;
  border-radius: 3px;
  border:2px solid #eb0028;
}
.cellBackground {
  width:100%;
  height:100%;
  background-color:white;
  border:1px solid #ebedf2;
  border-radius:3px;
  padding:12px
}
.horizSeparator {
  width:32px;
  height:4px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.vertSeparator {
  width:4px;
  height:32px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.tableHeader {
  position: sticky;
  z-index: 3;
  top:0;
  background-color: white;
  font-size:14px;
  font-weight: 500;
  border-bottom: 1px solid #E0E0E0;
}
.comboBoxPopup {
  height:40px;
  border-radius: 3px;
  border:1px solid #cccccc;
}
.comboBoxPopup:hover {
  height:40px;
  border-radius: 3px;
  border:1px solid black;
}
.comboBoxPopup:active {
  height:40px;
  border-radius: 3px;
  border:2px solid #eb0028;
}
</style>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTymictStore } from '@/store/tymict'
import TymConst from '@/js/tymconstants.js'
import TymCommon from '@/js/tymcommon.js'
import moment from 'moment-timezone'
import TymAws from '@/js/tymaws.js'
import { XlsxWorkbook, XlsxSheet, XlsxDownload  } from 'vue3-xlsx';

export default ({
  props : {
    kind : ref(0)
  },
  components : {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data() {
    return {
    }
  },
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean()  {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    getCategory() {
      const store = useTymictStore()
      return store.getMachineTypes()
    },
    getMonthTitle() {
      const i18n=useI18n()

      let result = []
      result.push(i18n.t('common.month'))
      for(let i = 1; i <= 12; i++) {
        result.push(i18n.t('yearMonth.' + i.toString()))
      }
      return result
    },
    machineCount() {
      const store = useTymictStore() 
      return store.machineLocaInfo.length
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return store.canDownloadToExcel()
    },
    groupTreeData() {
      const store = useTymictStore()
      return store.groupTree
    }
  },
  watch : {
    groupTreeData : {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal, newVal)
        if(newVal) {
          if(TymCommon.isEmpty(this.selectedTree) && (newVal.length > 0)) {
            this.expandedTree = [this.groupTreeData[0].uuid]
            this.selectedTree = this.groupTreeData[0].uuid

            // console.log('statSales.groupTreeData changed :', newVal)
            const store = useTymictStore() 
            let result = store.findGroupNameByKey(this.selectedTree)
            if(result) {
              this.selectedTreeLabel = result

              const div = document.getElementById('dealerChart')
              if(div) {
                setTimeout(() => {
                  this.reloadSalesData()
                }, 1)
              }
            }
          }
        }
      },
      immediate : true
    }
  },
  setup() {
    const i18n=useI18n()

    return {
      constBarWidth : ref(0.8),
      constBarPixel : ref(60),
      horizSplitter : ref(50),
      upSplitter : ref(49.75),
      downSplitter : ref(49.75),

      loadingData : ref(false),

      expandedTree : ref([]),
      selectedTree : ref(""),
      selectedTreeLabel : ref(""),
      groupPopupDisplayed : ref(false),
      
      // selectedYear : ref(i18n.t('common.all')), 
      selectedYear : ref(''),
      selectedMonth:ref(i18n.t('common.month')),
      dataYears : ref([]),

      catSelected : ref(i18n.t('common.type')),

      titleForSales : ref(''),

      totalByDealer : ref(0),
      totalADSByDealer : ref(0),
      termByDealer : ref(''),
      yearlyLayout : ref({
        // title: 'Title',
        showlegend: true,
        dragmode : "orbit",
        barmode: 'stack',
        bargap : 0.15,
        // height: 240,
        automargin: true,
        /*
        autorange : true,
        xaxis: {
          width:300,
          color:"ff0000"
        },
        yaxis: {
          height:150
        },
        */
      }),
      barChartConfig : ref({
        autosizable : false,
        displayModeBar: false,
        displaylogo: false,
        scrollZoom: false,
        responsive: false,
      }),

      dealerData : ref([]),
      modelData : ref([]),
      optionData : ref([]),

      chartForModel : ref(true),
      selectedModelOptionTab : ref(''),
      selectModelOptionTabs : ref([]),

      totalByModelOption : ref(0),
      totalADSByModelOption : ref(0),

      selectedModelOption : ref([]),
      selectModelOptions : ref([]),   // Select에 표시용
      selectModelOptionTree : ref([]),  // Select에서 기종을 골랐을 때 어떤 모델들이 포함되었는지 

      modelOptionCount : ref(0),
      modelOptionData : ref([
        {
          x: [],
          y: [],
          type: 'bar',
        }
      ]),

      yearByModel : ref(''),

      totalByYear : ref(0),
      yearModelLayout : ref({
        showlegend: true,
        dragmode : "orbit",
        barmode: 'stack',
        bargap : 0.15,
        height: 240,
        automargin: true,
        margin: {
          l : 40,
          t : 10,
          r : 5,
          b : 25
        },
      }),

      tableForModel : ref(true),
      selectedDealerModelOptionTab : ref(''),
      selectDealerModelOptionTabs : ref([]),

      tableColumns : ref([]),
      tableVisibleColumns : ref([]),
      tableRows : ref([]),
      dealerModelOptionData : ref([]),
      selectedDealerModel : ref(''),
      selectDealerModels : ref([]),
      selectDealerModelTree : ref([]),  // Select에서 기종을 골랐을 때 어떤 모델들이 포함되었는지 

      oneYearForModel : ref(true),
      latestTerm : ref(''),

      sheets: ref([]),

      hasExcelData : ref(true),
      makingExcel : ref(true),
      excelFilename : ref(''),
      excelFrom : ref(''),
      excelTo : ref(''),
      excelDealer : ref(''),
      excelType : ref(''),
    }
  },
  created() {},
  mounted() {
    const now = new Date()

    this.selectModelOptionTabs = [
      this.$t('statSales.sbymodel'),
      this.$t('statSales.sbyoption'),
    ]
    this.selectDealerModelOptionTabs = [
      this.$t('statSales.sbydealermodel'),
      this.$t('statSales.sbydealeroption'),
    ]

    let saved = sessionStorage.getItem('STAT.sale.saved')
    if(!TymCommon.isEmpty(saved)) {
      saved = JSON.parse(saved)
      // console.log('STAT.sale.saved load :', saved)
      this.selectedYear = saved.Year
      this.selectedMonth = saved.Month
    } else {
      this.selectedYear = now.getFullYear().toString() // this.$t('common.all')
      if(now.getDate() == 1)
        this.selectedMonth = this.getMonthTitle[now.getMonth()]
      else
        this.selectedMonth = this.getMonthTitle[now.getMonth() + 1]
    }

    this.dataYears = []
    for(let year = 2021; year <= now.getFullYear(); year++) {
      this.dataYears.push(year.toString())
    }
    this.dataYears.sort((a, b) => {
      return -(a.localeCompare(b))
    })

    setTimeout(()=>{
      if(!TymCommon.isEmpty(saved)) {
        if(!TymCommon.isEmpty(saved.Expanded))
          this.expandedTree = saved.Expanded
        if(!TymCommon.isEmpty(saved.Selected))
          this.selectedTree = saved.Selected
        this.selectedTreeLabel = saved.Group
        this.catSelected = saved.Category
        /*
        this.chartForModel = saved.ChartModel
        this.selectedModelOptionTab = saved.ModelOptionTab
        this.selectedModelOption = saved.ModelOption

        this.tableForModel = saved.TableModel
        this.selectedDealerModelOptionTab = saved.DealerModelOptionTab
        this.selectedDealerModel = saved.DealerModel
        */

        setTimeout(() => {
          this.updateDealerChart(true)
          this.updateModelOptionChart(true)
          this.updateDealerModelTable(true)
          this.updateYearModelChart(true)

          /*
          this.loadingData = true;
          this.getYearlySalesData()

          this.changedModelOptionTab(this.selectedModelOptionTab, false)
          this.changedDealerModelOptionTab(this.selectedDealerModelOptionTab)
    
          this.yearByModel = ''
          this.getSalesData(false)
          */
          this.reloadSalesData()
        }, 5)
      } else {
        this.reloadSalesData()
      }
    }, 1)
    sessionStorage.removeItem('STAT.sale.saved')
  }, 
  unmounted() {
    sessionStorage.setItem('STAT.sale.saved', JSON.stringify({
      Expanded: this.expandedTree,
      Selected: this.selectedTree,
      Group: this.selectedTreeLabel,
      Year: this.selectedYear,
      Month: this.selectedMonth,
      Category: this.catSelected,
      ChartModel: this.tableForModel,
      ModelOptionTab: this.selectedModelOptionTab,
      ModelOption: this.selectedModelOption,
      TableModel: this.chartForModel,
      DealerModel: this.selectedDealerModel,
      DealerModelOptionTab: this.selectedDealerModelOptionTab
    }))

    if(TymConst.IS_DEVELOPMENT) {
      console.log('STAT.sale.saved SAVE :', {
        Expanded: this.expandedTree,
        Selected: this.selectedTree,
        Group: this.selectedTreeLabel,
        Year: this.selectedYear,
        Month: this.selectedMonth,
        Category: this.catSelected,
        ChartModel: this.tableForModel,
        ModelOptionTab: this.selectedModelOptionTab,
        ModelOption: this.selectedModelOption,
        TableModel: this.chartForModel,
        DealerModel: this.selectedDealerModel,
        DealerModelOptionTab: this.selectedDealerModelOptionTab
      })
    }
  },
  methods : {
    unusedParam() {
      //
    },
    reloadSalesData() {
      const store = useTymictStore()

      this.selectedModelOptionTab = this.selectModelOptionTabs[0]
      this.selectedDealerModelOptionTab = this.selectDealerModelOptionTabs[0]

      this.updateDealerChart(true)
      this.updateModelOptionChart(true)
      this.updateDealerModelTable(true)
      this.updateYearModelChart(true)

      if(store.groupTree.length > 0) {
        // this.groupTreeData = store.groupTree
        this.expandedTree = [this.groupTreeData[0].uuid]
        this.selectedTree = this.groupTreeData[0].uuid
        let result = store.findGroupNameByKey(this.selectedTree)
        if(result) {
          this.selectedTreeLabel = result
        }
      }

      this.loadingData = true;
      this.getYearlySalesData()

      this.getSalesData()
    },
    resizeDealer(size) {
      this.unusedParam(size)
      setTimeout(()=>{
        if(this.$refs.refDealer) {
          let container = document.getElementById('dealerContainer')
          if(!container) {
            return
          }
          if(TymCommon.isEmpty(this.$refs.refDealer.data)) {
            // console.log('statCons.resizeDealer... EMPTY DATA')
            return
          }
          let barCount = this.$refs.refDealer.data[0].x.length
          let minWidth = barCount * this.constBarPixel
          minWidth = minWidth >= container.clientWidth ? minWidth : container.clientWidth - 60
          let maxWidth = barCount * 150
          if(barCount <= 2) {
            minWidth += 160
            maxWidth += 160
          }
          if(minWidth > maxWidth)
            minWidth = maxWidth

          window.Plotly.relayout(this.$refs.refDealer, {
            bargap : 0.15,
            width: minWidth,
            height: container.clientHeight - 18,
            margin: {
              l : 40,
              t : 10,
              r : 5,
              b : 65
            }
          })
        }
      }, 1)
    },
    resizeModelOption(size) {
      this.unusedParam(size)
      setTimeout(() => {
        if(this.$refs.refDealer) {
          let container = document.getElementById('modelOptionContainer')
          if(!container) {
            return
          }
          if(TymCommon.isEmpty(this.$refs.refModelOption.data)) {
            return
          }
          if(this.$refs.refModelOption.data.length < 1) {
            return
          }

          let barCount = this.$refs.refModelOption.data[0].x.length
          let minWidth = barCount * this.constBarPixel
          minWidth = minWidth >= container.clientWidth ? minWidth : container.clientWidth - 60
          let maxWidth = barCount * 150
          if(barCount <= 2) {
            minWidth += 160
            maxWidth += 160
          }
          if(minWidth > maxWidth)
            minWidth = maxWidth

          window.Plotly.relayout(this.$refs.refModelOption, {
            bargap : 0.15,
            width: minWidth,
            height: container.clientHeight - 18,
            margin: {
              l : 40,
              t : 10,
              r : 5,
              b : 65
            }
          })
        }
      }, 1)
    },
    resizeYearModel(size) {
      this.unusedParam(size)
      setTimeout(() => {
        if(this.$refs.refYearModel) {
          let container = document.getElementById('yearModelContainer')
          if(!container) {
            return
          }
          if(TymCommon.isEmpty(this.$refs.refYearModel.data)) {
            //console.log('statEm.resizeModelOption... EMPTY DATA')
            return
          }

          // let barCount = this.$refs.refModelOption.data[0].x.length
          let width = container.clientWidth < 520 ? 520 : container.clientWidth
          let height = container.clientHeight < 240 ? 240 : container.clientHeight

          width -= 30
          height -= 15

          window.Plotly.relayout(this.$refs.refYearModel, {
            width: width,
            height: height,
            margin: {
              l : 40,
              t : 10,
              r : 5,
              b : 65
            }
          })
        }
      }, 1)

    },


    groupTreeSelected(tgt) {
      const store = useTymictStore()

      this.selectedTree = tgt
      let result = store.findGroupNameByKey(this.selectedTree)
      if(result) {
        this.selectedTreeLabel = result
      }
      // console.log('groupTreeSelected :', this.selectedTree, result, this.selectedTreeLabel)
      this.$refs.groupPopup.hide()
    },
    clearGroupData() {
      const store = useTymictStore()

      this.selectedTree = this.groupTreeData[0].uuid
      let result = store.findGroupNameByKey(this.selectedTree)
      if(result) {
        this.selectedTreeLabel = result
      }
    },
    calNavigated(view) {
      // console.log('calNavigated :', view)
      this.selectedYear = view.year
      this.$refs.yearPopup.hide()
    },
    changeCalendar(value, reason, details) {
      console.log('changeCalendar :', value, "reason :", reason, "details :", details)
      if(reason == 'year') {
        this.selectedYear = details.year
        this.$refs.yearPopup.hide()
      }
    },
    changedCategory(value, reason, details) {
      this.unusedParam(value, reason, details)
      // console.log('changedCategory :', value, reason, details)
    },
    changedErroPart(value, reason, details) {
      this.unusedParam(value, reason, details)
      // console.log('changedErroPart :', value, reason, details)
    },
    
    onUpdateMonth(value, reason, details) {
      this.unusedParam(value, reason, details)
      // console.log('onUpdateMonth :', value, reason, details)
    },

    searchData() {
      this.yearByModel = ''
      this.getSalesData()
    },
    // 최근 1년치 데이터
    getYearlySalesData() {
      const store = useTymictStore()
      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(TymCommon.isEmpty(group)) {
        console.log('StatSales(YEAR) : group is empty.')
        return
      }

      let today = moment()
      let startDay = null, endDay = null

      startDay = (today.year() - 1).toString() + "-" + (today.month() + 2).toString().padStart(2, '0') + "-" + (today.date()).toString().padStart(2, '0') + " 00:00:00"
      endDay = today.year().toString() + "-" + (today.month() + 1).toString().padStart(2, '0') + "-" + (today.date()).toString().padStart(2, '0')
              + ' ' + today.hour().toString().padStart(2, '0') + ':' + today.minute().toString().padStart(2, '0') + ':' + today.second().toString().padStart(2, '0')
      let tgtRange = TymCommon.makeDateTimeRange('2020-01-01 00:00:00', startDay, endDay)
      this.latestTerm = TymCommon.getMonthString(tgtRange.sDate.toDate()) + ' - ' + TymCommon.getMonthString(tgtRange.eDate.toDate())

      setTimeout(() => {
        this.yearlyData = [] 
        TymAws.getSalesStat(group.uuid_a, group.uuid_b, group.uuid_c, group.uuid_d, tgtRange.sDateStr, tgtRange.eDateStr, false, true)
          .then(data => {
            if(TymConst.IS_DEVELOPMENT) {
              console.log('StatSales(YEAR) :', group, tgtRange.sDateStr, tgtRange.eDateStr, data)
            }
            if(data == 'error') {
              return
            }
            /*
            {
              "YearMonth": "2023-05",
              "M_model": "K110E",
              "M_option": "NONE",
              "Cnt": "48"
            }
            */
            // let total = 0
            let models = []
            data.forEach(sale => {
              let found = models.find(x => x.Name == sale.M_model)
              if(!found) {
                models.push({
                  Name : sale.M_model,
                  Count : 0
                })
              }
            })
            // console.log('Models :', JSON.parse(JSON.stringify(models)))

            data.forEach(sale => {
              let month = moment(sale.YearMonth)
              let title = TymCommon.getMonthString(month.toDate())
              let model = sale.M_model

              let found = this.yearlyData.find(x => x.Title == title)
              if(found) {
                // 월 데이터가 존재하네
                found.Count += parseInt(sale.Cnt)
                let foundModel = found.Models.find(x => x.Name == model)
                if(foundModel) {
                  foundModel.Count += parseInt(sale.Cnt)
                }
              } else {
                // 해당 월 최초 데이터
                let oneYear = {
                  Title : title,
                  Month : sale.YearMonth,
                  Count : parseInt(sale.Cnt)
                }
                oneYear.Models = []
                models.forEach(one => {
                  oneYear.Models.push({
                    Name : one.Name,
                    Count : model == one.Name ? parseInt(sale.Cnt) : 0
                  })
                })
                this.yearlyData.push(oneYear)
              }
              // total += parseInt(sale.Cnt)
            })
            // console.log('FINAL :', total, this.yearlyData)
            
            this.updateYearModelChart(false)
          })
          .catch(er => {
            console.log('StatSales.getSalesStat ERROR :', er)
          })
      }, 1)
    },
    getSubGroups(uuid) {
      const store = useTymictStore()
      if(TymCommon.isEmpty(store.idToken["custom:uuid_a"]))
        return
      let group = store.groupInfo.find(x => x.uuid == uuid)
      if(TymCommon.isEmpty(group))
        return

      let depth = -1
      let subGrpNames = [], tmpGrps = null
      if(TymCommon.isEmpty(group.uuid_d)) {
        if(TymCommon.isEmpty(group.uuid_c)) {
          if(TymCommon.isEmpty(group.uuid_b)) {
            depth = 0
            /*
            if(store.isICTUser()) {
              tmpGrps = store.groupInfo.filter(x => x.uuid_a == group.uuid_a && TymCommon.isEmpty(x.uuid_b))
              if(tmpGrps) {
                // TYM
                tmpGrps.forEach(grp => {
                  if(!subGrpNames.find(x => x == grp.group_a)) {
                    if(!TymCommon.isEmpty(grp.group_a))
                      subGrpNames.push(grp.group_a)
                  }
                })
              }
            }
            */

            tmpGrps = store.groupInfo.filter(x => x.uuid_a == group.uuid_a && TymCommon.isEmpty(x.uuid_c))
            if(tmpGrps) {
              tmpGrps.sort((a, b) => {
                return a.group_b.localeCompare(b.group_b)
              })
              tmpGrps.forEach(grp => {
                if(!store.isICTUser() && (grp.uuid_a == store.tymictGroup.uuid_a) && (grp.uuid_b == store.tymictGroup.uuid_b))
                  return

                if(!subGrpNames.find(x => x == grp.group_b)) {
                  if(!TymCommon.isEmpty(grp.group_b))
                    subGrpNames.push(grp.group_b)
                }
              })
            }
          } else {
            depth = 1

            /*
            if(store.isICTUser()) {
              tmpGrps = store.groupInfo.filter(x => x.uuid_b == group.uuid_b && TymCommon.isEmpty(x.uuid_c))
              if(tmpGrps) {
                tmpGrps.forEach(grp => {
                  if(!subGrpNames.find(x => x == grp.group_b)) {
                    if(!TymCommon.isEmpty(grp.group_b))
                      subGrpNames.push(grp.group_b)
                  }
                })
              }
            }
            */

            tmpGrps = store.groupInfo.filter(x => x.uuid_b == group.uuid_b && TymCommon.isEmpty(x.uuid_d))
            if(tmpGrps) {
              tmpGrps.sort((a, b) => {
                return a.group_c.localeCompare(b.group_c)
              })
              tmpGrps.forEach(grp => {
                if(!store.isICTUser()) {
                  if(!TymCommon.isEmpty(store.tymictGroup)) {
                    if((grp.uuid_a == store.tymictGroup.uuid_a) && (grp.uuid_b == store.tymictGroup.uuid_b))
                      return
                  }
                }

                if(!subGrpNames.find(x => x == grp.group_c)) {
                  if(!TymCommon.isEmpty(grp.group_c))
                    subGrpNames.push(grp.group_c)
                }
              })
            }
          }
        } else {
          depth = 2
          tmpGrps = store.groupInfo.filter(x => x.uuid_c == group.uuid_c)
          if(tmpGrps) {
            tmpGrps.forEach(grp => {
              if(!subGrpNames.find(x => x == grp.group_c && TymCommon.isEmpty(grp.group_d))) {
                subGrpNames.push(grp.group_c)
              }
            })
          }

          tmpGrps = store.groupInfo.filter(x => x.uuid_c == group.uuid_c && !TymCommon.isEmpty(x.uuid_d))
          if(tmpGrps) {
            tmpGrps.sort((a, b) => {
              return a.group_d.localeCompare(b.group_d)
            })
            tmpGrps.forEach(grp => {
              if(!store.isICTUser()) {
                if(!TymCommon.isEmpty(store.tymictGroup)) {
                  if((grp.uuid_a == store.tymictGroup.uuid_a) && (grp.uuid_b == store.tymictGroup.uuid_b))
                    return
                }
              }

              if(!subGrpNames.find(x => x == grp.group_d)) {
                subGrpNames.push(grp.group_d)
              }
            })
          }
        }
      } else {
        depth = 3
        tmpGrps = store.groupInfo.filter(x => x.uuid_d == group.uuid_d)
        if(tmpGrps) {
          tmpGrps.forEach(grp => {
            if(!subGrpNames.find(x => x == grp.group_d)) {
              if(!TymCommon.isEmpty(grp.group_d))
                subGrpNames.push(grp.group_d)
            }
          })
        }
      }

      if(subGrpNames.length < 1)
        return null

      return {
        Depth : depth,
        Names : subGrpNames
      }
    },
    // 기간 한정 데이터 조회
    getSalesData(resetSelected) {
      const store = useTymictStore()
      if(TymCommon.isEmpty(store.idToken["custom:uuid_a"])) {
        this.loadingData = false
        return
      }
      //console.log('getSalesData(111)')

      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(TymCommon.isEmpty(group))
        return
      //console.log('getSalesData(222)', group, this.selectedTree)

      let groups = null
      
      groups = store.getSubGroups(this.selectedTree)
      if(TymCommon.isEmpty(groups)) {
        TymCommon.Toast('No sub group...')
        return
      }

      //console.log('getSalesData(333) :', groups)
      this.loadingData = true

      setTimeout(() => {
        let today = moment()
        let year = parseInt(this.selectedYear)
        let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
        let startDay = null, endDay = null

        if(monIndex == 0) {
          startDay = year.toString() + "-01-01 00:00:00"
          endDay =  (year + 1).toString() + "-01-01 00:00:00"
        } else {
          startDay = year.toString() + "-" + monIndex.toString().padStart(2, '0') + "-01 00:00:00"
          endDay = year.toString() + "-" + (monIndex + 1).toString().padStart(2, '0') + "-01 00:00:00"
        }
        let startResult = TymCommon.localTimeToUTC(startDay, "YYYY-MM-DD HH:mm:ss")
        let endResult = TymCommon.localTimeToUTC(endDay, "YYYY-MM-DD HH:mm:ss")

        if(endResult.dateTime > today) {
          // endDay = TymCommon.localTimeToUTC(today.format("YYYY-MM-DD 00:00:00"), "YYYY-MM-DD HH:mm:ss").dateTimeStr
          endDay = TymCommon.localTimeToUTC(today.format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss").dateTimeStr
        } else {
          endDay = endResult.dateTimeStr
        }
        startDay = startResult.dateTimeStr

        this.dealerData = []
        this.dealerModelOptionData = []
        groups.Names.forEach(name => {
          this.dealerData.push({
            Dealer : name,
            Normal : 0,
            ADS : 0
          })
        })

        switch(groups.Depth) {
          case 0:
            this.titleForSales = this.$t('statSales.sbybranch')
            break;
          case 1:
            this.titleForSales = this.$t('statSales.sbybranch')
            break;
          case 2:
            this.titleForSales = this.$t('statSales.sbybranch')
            break;
          case 3:
            this.titleForSales = this.$t('statSales.sbydealer')
            break;
        }

        this.modelData = []
        this.optionData = []

        TymAws.getSalesStat(group.uuid_a, group.uuid_b, group.uuid_c, group.uuid_d, startDay, endDay, false)
          .then(data => {
            if(TymConst.IS_DEVELOPMENT) {
              console.log('StatSales.getSalesStat(TERM) :', startDay, endDay, group, this.catSelected, data)
            }
            // 
            /*
            {
              "M_model": "T115",
              "M_option": "NONE",
              "IsADS": 0,
              "group_a": "TYM",
              "group_b": "국내영업본부",
              "group_c": "전북지점",
              "group_d": "전북직영점",
              "type": "트랙터",
              "Cnt": "1"
            }
            */
            if(data == 'error' || data.length < 1) {
              this.updateDealerChart(true)
              this.updateModelOptionChart(true, true)
              this.updateDealerModelTable(true)

              this.loadingData = false
              return
            }

            data.forEach(one => {
              if(this.catSelected != this.getCategory[0]) {
                if(one.type != this.catSelected)
                  return
              }

              // dealer 찾기
              let dealerName = ''
              switch(groups.Depth) {
                case 0:
                  if(TymCommon.isEmpty(one.group_b))
                    dealerName = one.group_a
                  else
                    dealerName = one.group_b
                  break;
                case 1:
                  if(TymCommon.isEmpty(one.group_c))
                    dealerName = one.group_b
                  else
                    dealerName = one.group_c
                  break;
                case 2:
                  if(TymCommon.isEmpty(one.group_d))
                    dealerName = one.group_c
                  else
                    dealerName = one.group_d
                  break;
                case 3:
                  dealerName = one.group_d
                  break;
              }

              let count = parseInt(one.Cnt)
              let dFound = this.dealerData.find(x => x.Dealer == dealerName)
              if(dFound) {
                dFound.Normal += one.IsADS ? 0 : count
                dFound.ADS += one.IsADS ? count : 0
              } else {
                this.dealerData.push({
                  Dealer : dealerName,
                  Normal : one.IsADS ? 0 : count,
                  ADS : one.IsADS ? count : 0,
                })
              }

              let mFound = this.modelData.find(x => x.Model == one.M_model)
              if(mFound) {
                mFound.Normal += one.IsADS ? 0 : count
                mFound.ADS += one.IsADS ? count : 0
                let oFound = mFound.Options.find(x => x.Option == one.M_option)
                if(oFound) {
                  oFound.Normal += one.IsADS ? 0 : count
                  oFound.ADS += one.IsADS ? count : 0
                } else {
                  mFound.Options.push({
                    Option : one.M_option,
                    Normal : one.IsADS ? 0 : count,
                    ADS : one.IsADS ? count : 0,
                  })
                }
              } else {
                let oneModel = {
                  Model : one.M_model,
                  Normal : one.IsADS ? 0 : count,
                  ADS : one.IsADS ? count : 0,
                  Options : [{
                    Option : one.M_option,
                    Normal : one.IsADS ? 0 : count,
                    ADS : one.IsADS ? count : 0,
                  }]
                }
                this.modelData.push(oneModel)
              }
            })

            // 테이블 표시를 위한 카운트가 모두 0인 데이터 만든다.
            groups.Names.forEach(name => {
              let models = []
              let fullModels = []

              this.modelData.forEach(model => {
                let options = []
                model.Options.forEach(opt => {
                  options.push({
                    Option : opt.Option,
                    Normal : 0,
                    ADS : 0,
                  })
                  let fullName = model.Model
                  if(opt.Option != '0' && opt.Option != 'NONE') {
                    fullName += opt.Option
                  }
                  if(!fullModels.find(x => x == fullName))
                    fullModels.push({
                      Model : fullName,
                      Normal : 0,
                      ADS : 0,
                    })
                })
                models.push({
                  Model : model.Model,
                  Normal : 0,
                  ADS : 0,
                  Options : options
                })
              })
              let oneGrp = {
                Dealer : name,
                Models : models,
                FullModels : fullModels
              }

              this.dealerModelOptionData.push(oneGrp)
            })

            data.forEach(one => {
              if(this.catSelected != this.getCategory[0]) {
                if(one.type != this.catSelected)
                  return
              }

              let count = parseInt(one.Cnt)
              // dealer 찾기
              let dealerName = ''
              switch(groups.Depth) {
                case 0:
                  if(TymCommon.isEmpty(one.group_b))
                    dealerName = one.group_a
                  else
                    dealerName = one.group_b
                  break;
                case 1:
                  if(TymCommon.isEmpty(one.group_c))
                    dealerName = one.group_b
                  else
                    dealerName = one.group_c
                  break;
                case 2:
                  if(TymCommon.isEmpty(one.group_d))
                    dealerName = one.group_c
                  else
                    dealerName = one.group_d
                  break;
                case 3:
                  dealerName = one.group_d
                  break;
              }

              let dmo = this.dealerModelOptionData.find(x => x.Dealer == dealerName)
              if(dmo) {
                let model = dmo.Models.find(x => x.Model == one.M_model)
                if(model) {
                  model.Normal += one.IsADS ? 0 : count
                  model.ADS += one.IsADS ? count : 0

                  let option = model.Options.find(x => x.Option == one.M_option)
                  if(option) {
                    option.Normal += one.IsADS ? 0 : count
                    option.ADS += one.IsADS ? count : 0
                  }
                }

                let fullName = model.Model
                if(one.M_option != '0' && one.M_option != 'NONE') {
                  fullName += one.M_option
                }
                model = dmo.FullModels.find(x => x.Model == fullName)
                if(model) {
                  model.Normal += one.IsADS ? 0 : count
                  model.ADS += one.IsADS ? count : 0
                }
              }
            })

            this.selectModelOptions = [ this.$t('common.all') ]
            this.modelData.forEach(model => {
              this.selectModelOptions.push(model.Model)
            })
            this.selectDealerModels = [ this.$t('common.all') ]
            this.modelData.forEach(model => {
              this.selectDealerModels.push(model.Model)
            })

            if(!TymCommon.isEmpty(resetSelected) && resetSelected) {
              this.selectedDealerModel = this.selectDealerModels[0]
              this.selectedModelOptionTab = this.selectModelOptionTabs[0]
              this.selectedModelOption = this.selectModelOptions[0]
              this.selectedDealerModelOptionTab = this.selectDealerModelOptionTabs[0]
            }

            /*
            console.log('DEALER :', this.dealerData)
            console.log('MODEL :', this.modelData)
            console.log('MODEL/OPTION :', this.selectModelOptions)
            console.log('DEALER TABLE :', this.dealerModelOptionData)
            */

            this.updateDealerChart(false)
            this.updateModelOptionChart(false, true)
            this.updateDealerModelTable(false)

            this.loadingData = false
          })
          .catch(er => {
            this.loadingData = false
            console.log('StatSales.getSalesStat ERROR :', er)
          })
      }, 1)
    },
    isADS(value) {
      if(TymCommon.isEmpty(value)) {
        return false
      } else if(value == 0 || value == '0' || value.toUpperCase() == 'NONE') {
        return false
      }
      return true
    },
    getDateStr(myDate){
        var year = myDate.getFullYear() - 1;
        var month = ("0"+(myDate.getMonth()+1)).slice(-2);
        var day = "01"
        return new Date(year, month, day)
    },

    changedModelOptionTab(value, resetOption) {
      if(TymCommon.isEmpty(resetOption) || (!TymCommon.isEmpty(resetOption) && resetOption)) {
        this.chartForModel = (this.$t('statSales.sbymodel') == value)
      }
      if(!this.chartForModel) {
        if(TymCommon.isEmpty(resetOption) || (!TymCommon.isEmpty(resetOption) && resetOption)) {
          this.selectedModelOption = this.selectModelOptions[0]
        }
      }
      console.log('changedModelOptionTab :', value, resetOption, this.chartForModel, this.selectedModelOption)
//      this.updateModelOptionChart(false, this.chartForModel)
      this.changedSelectedModel(this.selectedModelOption)
    },

    // isModel : 기종별이면 True
    changeModelOption(isModel) {
      if(this.chartForModel != isModel) {
        this.chartForModel = isModel

        this.updateModelOptionChart(false, this.chartForModel)
      }
    },
    changedDealerModelOptionTab(value) {
      this.tableForModel = (value == this.selectDealerModelOptionTabs[0])
      // console.log('changedDealerModelOptionTab : ', value)
      this.updateDealerModelTable(false)
      this.resizeModelOption(null)
    },
    updateDealerChart(firstTime) {
      let dealerAxisData = []

      if((this.selectedYear == this.$t('common.all')) && (this.selectedMonth == this.$t('common.month'))) {
        this.termByDealer = this.$t('common.all')
      } else if((this.selectedYear == this.$t('common.all')) && (this.selectedMonth != this.$t('common.month'))) {
        this.termByDealer = this.selectedMonth
      } else if((this.selectedYear != this.$t('common.all')) && (this.selectedMonth == this.$t('common.month'))) {
        this.termByDealer = this.selectedYear
      } else if((this.selectedYear != this.$t('common.all')) && (this.selectedMonth != this.$t('common.month'))) {
        let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
        let ymon = new Date(Number(this.selectedYear), monIndex - 1, 2, 12, 0)
        this.termByDealer = TymCommon.getMonthString(ymon)
      }

      this.totalByDealer = 0
      this.totalADSByDealer = 0
      if(firstTime) {
        var value1 = {
          x: [],
          y: [],
          name: this.$t('statView.normalMach'),
          type: 'bar',
          text: []
        };
        var value2 = {
          x: [],
          y: [],
          name: this.$t('statView.adsMach'),
          type: 'bar',
          text: []
        };
        dealerAxisData = [value1, value2]
        new window.Plotly.newPlot('dealerChart', dealerAxisData, this.yearlyLayout, this.barChartConfig)
      } else {
        let xLab = [], yLab = []
        let textLab = []
        /*
          Dealer : name,
          Normal : 0,
          ADS : 0
        */
        for(let idx = 0; idx < this.dealerData.length; idx++) {
          this.totalByDealer += this.dealerData[idx].Normal + this.dealerData[idx].ADS

          xLab.push(this.dealerData[idx].Dealer)
          yLab.push(this.dealerData[idx].Normal)
          textLab.push(this.dealerData[idx].Normal)
        }

        dealerAxisData.push({
          x: xLab,
          y: yLab,
          text:textLab,
          width: this.constBarWidth,
          name: this.$t('statView.normalMach'),
          textposition : 'auto',
          hovermode : "closest",
          hovertemplate : '<span style="color:#404040;">&nbsp;' + this.$t('statView.normalMach') + ',&nbsp;%{x}</span>&nbsp;%{y}&nbsp;<extra></extra>',
          hoverlabel: {
            bgcolor: 'white',
            bordercolor: '#eb0028',
          },
          type : 'bar',
          marker: {
            color: '#eb0028'
          }
        })

        xLab = []
        yLab = []
        textLab = []
        for(let idx = 0; idx < this.dealerData.length; idx++) {
          this.totalADSByDealer += this.dealerData[idx].ADS

          xLab.push(this.dealerData[idx].Dealer)
          yLab.push(this.dealerData[idx].ADS)
          textLab.push(this.dealerData[idx].ADS)
        }

        dealerAxisData.push({
          x: xLab,
          y: yLab,
          text:textLab,
          width: this.constBarWidth,
          name: this.$t('statView.adsMach'),
          type : 'bar',
          textposition : 'auto',
          hovermode : "closest",
          hovertemplate : '<span style="color:#404040;">&nbsp;' + this.$t('statView.adsMach') + ', %{x}</span>&nbsp;<span style="color:#eb0028;">%{y}&nbsp;</span><extra></extra>',
          hoverlabel: {
            bgcolor: 'white',
            bordercolor: '#404040',
          },
          marker: {
            color: '#BBBBBB'
          }
        })

        const div = document.getElementById('dealerChart')
        if(div) {
          // this.yearModelLayout.width = div.offsetWidth > 12 * 60 ? div.offsetWidth : 12 * 60
          window.Plotly.react(div, dealerAxisData, this.yearModelLayout, this.barChartConfig)
        }

        this.resizeDealer(null)
      }
    },
    
    updateModelOptionChart(firstTime, isModel) {
      let modelOptionAxisData = []

      this.totalByModelOption = 0
      this.totalADSByModelOption = 0
      let xLab = [], yLab = []
      if(firstTime) {
        xLab.push('')
        yLab.push()
        this.modelOptionData[0].x = xLab.map(String)
        this.modelOptionData[0].y = yLab
        this.modelOptionData[0].text = yLab.map(String)
        const div = document.getElementById('modelOptionChart')
        if(div)
          new window.Plotly.newPlot(div, this.modelOptionData, this.yearlyLayout, this.barChartConfig)
      } else {
        let chartData = null
        if(isModel) {
          chartData = this.modelData
        } else {
          chartData = []
          this.modelData.forEach(model => {
            //console.log('MODEL :', model)
            model.Options.forEach(option => {
              if(this.selectedModelOption == this.$t('common.all')) {
                let name = model.Model
                if(option.Option != '0' && option.Option != 'NONE') {
                  name += option.Option
                }
                let found = chartData.find(x => x.Model == name)
                if(found) {
                  found.Normal += option.Normal,
                  found.ADS += option.ADS
                } else {
                  chartData.push({
                    Model : name,
                    Normal : option.Normal,
                    ADS : option.ADS
                  })
                }
              } else {
                if(this.selectedModelOption == model.Model) {
                  let name = model.Model
                  if(option.Option != '0' && option.Option != 'NONE') {
                    name += option.Option
                  }
                  let found = chartData.find(x => x.Model == name)
                  if(found) {
                    found.Normal += option.Normal,
                    found.ADS += option.ADS
                  } else {
                    chartData.push({
                      Model : name,
                      Normal : option.Normal,
                      ADS : option.ADS
                    })
                  }
                }
              }
            })
          })
        }
        // console.log(isModel ? 'by Model :' : 'by Option :', chartData)

        let xLab = [], yLab = []
        let textLab = [], widthLab = []
        for(let idx = 0; idx < chartData.length; idx++) {
          this.totalByModelOption += chartData[idx].Count

          xLab.push(chartData[idx].Model)
          yLab.push(chartData[idx].Normal)
          widthLab.push(this.constBarWidth)
        }
        textLab = yLab.map(String)

        modelOptionAxisData.push({
          x: xLab,
          y: yLab,
          width: widthLab,
          text: textLab,
          name: this.$t('statView.normalMach'),
          type : 'bar',
          textposition : 'auto',
          hovermode : "closest",
          hovertemplate : '<span style="color:#404040;">&nbsp;' + this.$t('statView.normalMach') + ',&nbsp;%{x}</span>&nbsp;%{y}&nbsp;<extra></extra>',
          hoverlabel: {
            bgcolor: 'white',
            bordercolor: '#eb0028',
          },
          marker: {
            color: '#eb0028'
          }
        })

        xLab = []
        yLab = []
        textLab = []
        for(let idx = 0; idx < chartData.length; idx++) {
          this.totalADSByModelOption += chartData[idx].ADS

          xLab.push(chartData[idx].Model)
          yLab.push(chartData[idx].ADS)
        }
        textLab = yLab.map(String)
        modelOptionAxisData.push({
          x: xLab,
          y: yLab,
          text:textLab,
          width: widthLab,
          name: this.$t('statView.adsMach'),
          type : 'bar',
          textposition : 'auto',
          hovermode : "closest",
          hovertemplate : '<span style="color:#404040;">&nbsp;' + this.$t('statView.adsMach') + ', %{x}</span>&nbsp;<span style="color:#eb0028;">%{y}&nbsp;</span><extra></extra>',
          hoverlabel: {
            bgcolor: 'white',
            bordercolor: '#404040',
          },
          marker: {
            color: '#bbbbbb'
          }
        })

        window.Plotly.react('modelOptionChart', modelOptionAxisData, this.yearlyLayout, this.barChartConfig)

        this.resizeModelOption(null)
      }
    },
    
    updateYearModelChart(firstTime) {
      this.totalByYear = 0
      let yearModelAxisData = []

      if(firstTime) {
        let xLab = [], yLab = []
        yearModelAxisData.push({
          x : xLab.map(String),
          y : yLab,
          text : yLab.map(String)
        })

        new window.Plotly.newPlot('yearModelChart', yearModelAxisData, this.yearModelLayout, this.barChartConfig)
      } else {
        if( this.yearlyData.length < 1)
          return

        // console.log('updateYearModelChart :', this.yearlyData)
        let yLab = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.totalByYear = 0

        let model = 0, month = 0
        let titles = [], tooltips = []
        for(model = 0; model < this.yearlyData[0].Models.length; model++) {
          titles = []
          tooltips = []
          for(month = 0; month < this.yearlyData.length; month++) {
            yLab[month] = this.yearlyData[month].Models[model].Count
            titles.push(this.yearlyData[month].Title)
            tooltips.push(this.yearlyData[month].Title + ', ' + this.yearlyData[month].Models[model].Name + ' : ' + this.yearlyData[month].Models[model].Count)
            if(model == 0) {
              this.totalByYear += this.yearlyData[month].Count
            }
          }

          let axis = {
            x : titles.map(String),
            y : yLab.map(String),
            mode : "lines+markers",
            type : "scatters",
            hovermode : "closet",
            hoverlabel: { bgcolor: '#fff' },
            marker : {
              size : 7.5
            },
            // hovertemplate: '<i>%{text}</i>', 
            hoverinfo : 'text',
            name : this.yearlyData[0].Models[model].Name,
            text: tooltips,
          }
          yearModelAxisData.push(axis)
        }

        window.Plotly.react('yearModelChart', yearModelAxisData, this.yearModelLayout, this.barChartConfig)
      }
    },

    // 테이블 업데이트
    updateDealerModelTable(firstTime) {
      this.tableColumns = []
      this.tableRows = []

      if(firstTime) {
        this.tableVisibleColumns = []
      } else {
        if(this.dealerModelOptionData.length < 1)
          return

        // 첫번째 컬럼(인덱스로 쓰자)
        this.tableColumns.push({
          name: 'index',
          required: false
        })

        //console.log('updateDealerModelTable +++', this.tableForModel)

        let isAllOptions = true
        let srcData = []
        if(this.tableForModel) {
          this.dealerModelOptionData[0].Models.forEach(model => {
            srcData.push(model.Model)
          })
        }
        else {
          isAllOptions = this.selectedDealerModel == this.selectDealerModels[0]
          
          //console.log('updateDealerModelTable OPTION :', isAllOptions, this.selectedDealerModel, this.selectDealerModels)
          if(isAllOptions) {
            // 전체
            this.dealerModelOptionData[0].FullModels.forEach(model => {
              srcData.push(model.Model)
            })
          } else {
            this.dealerModelOptionData[0].Models.forEach(model => {
              if(model.Model == this.selectedDealerModel) {
                model.Options.forEach(opt => {
                  srcData.push(opt)
                })
              }
            })
          }
        }

        //console.log('updateDealerModelTable :', this.dealerModelOptionData, srcData)

        // 컬럼 헤더 부분(맨 윗부분)
        // 첫컬럼은 비워두자... 딜러명 표시컬럼
        let colCount = srcData.length
        this.tableColumns.push({
            name: 'col0',
            field: 'col0',
            label: '',
            align: 'left',
            required: false,
            sortable: false,
          })
        this.tableVisibleColumns.push('col0')

        //console.log('updateDealerModelTable :', colCount, this.dealerModelOptionData, srcData)

        // 기종과 모델별로 표시되는 컬럼들
        let idx = 0
        for(idx = 0; idx < srcData.length; idx++) {
          let model = srcData[idx]
          if(!this.tableForModel && !isAllOptions) {
            model = this.selectedDealerModel
            if(srcData[idx].Option != '0' && srcData[idx].Option != 'NONE')
              model += srcData[idx].Option
          }
          this.tableColumns.push({
            name: 'col' + (idx + 1).toString(),
            field: 'col' + (idx + 1).toString(),
            label: model,
            align: 'center',
            required: false,
            sortable: false,
          })
          this.tableVisibleColumns.push('col' + (idx + 1).toString())
        }

        // 변수 재활용하니 유의바람
        // 마지막 컬럼에 합계 추가
        this.tableColumns.push({
          name: 'col' + (idx + 1).toString(),
          field: 'col' + (idx + 1).toString(),
          label: this.$t('common.sum'),
          align: 'center',
          required: false,
          sortable: false,
        })
        this.tableVisibleColumns.push('col' + (idx + 1).toString())

        let row = 0, col = 0
        let modelTotal = [] // 기종/모델별 합계
        for(row = 0; row < this.dealerModelOptionData.length; row++) {
          let oneRow = []
          oneRow['index'] = row
          let item = this.dealerModelOptionData[row]
          oneRow['col0'] = item.Dealer
          if(!this.tableForModel && !isAllOptions) {
            let opt = this.dealerModelOptionData[row].Models.find(x => x.Model == this.selectedDealerModel)
            item = opt
          }
          let dealerTotal = 0    // 한 지역 합계용
          for(col = 0; col < colCount; col++) {
            if(isNaN(modelTotal[col]))
              modelTotal[col] = 0

            if(this.tableForModel) {
              oneRow['col' + (col + 1).toString()] = item.Models[col].Normal + item.Models[col].ADS

              dealerTotal += item.Models[col].Normal + item.Models[col].ADS
              modelTotal[col] += item.Models[col].Normal + item.Models[col].ADS
            } else {
              if(isAllOptions) {
                oneRow['col' + (col + 1).toString()] = item.FullModels[col].Normal + item.FullModels[col].ADS

                dealerTotal += item.FullModels[col].Normal + item.FullModels[col].ADS
                modelTotal[col] += item.FullModels[col].Normal + item.FullModels[col].ADS
              } else {
                oneRow['col' + (col + 1).toString()] = item.Options[col].Normal + item.Options[col].ADS

                dealerTotal += item.Options[col].Normal + item.Options[col].ADS
                modelTotal[col] += item.Options[col].Normal + item.Options[col].ADS
              }
            }
          }
          // 우측끝에 합계 추가
          oneRow['col' + (col + 1).toString()] = dealerTotal
          this.tableRows.push(oneRow)

          // 모델별합계???
          if(isNaN(modelTotal[col]))
            modelTotal[col] = 0
          modelTotal[col] += dealerTotal
        }

        // 마지막 Row에 합계 추가
        let oneRow = {
          index : row,
          col0 : this.$t('common.sum'),
        }
        for(col = 0; col <= colCount; col++) {
          oneRow['col' + (col + 1).toString()] = modelTotal[col]
        }

        // console.log(oneRow)
        this.tableRows.push(oneRow)
      }
    },
    changedSelectedModel(value) {
      console.log(value, this.selectedModelOption)

      if(value == this.$t('common.all')) {
        this.updateModelOptionChart(false, this.chartForModel)
      } else {
        this.updateModelOptionChart(false, this.chartForModel)
      }
    },
    changedDealerModel(value) {
      console.log('changedDealerModel : ', value)

      this.updateDealerModelTable(false)
    },
    downloadToExcel() {
    },
    onParsed(param1) {
      this.unusedParam(param1)
    },
    onShowExportToExcel() {
      let now = new Date()
      this.excelFilename = 'Sales_' + now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += '_' + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += '.xlsx'
      let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
      if(monIndex == 0) {
        let from = new Date(this.selectedYear, 0, 1)
        this.excelFrom = TymCommon.getDateString(from)
        if(now.getFullYear().toString() == this.selectedYear) {
          let dst = new Date()
          dst = new Date(dst.setDate(dst.getDate()))
          this.excelTo = TymCommon.getDateString(dst)
        } else {
          this.excelTo = TymCommon.getDateString(new Date(this.selectedYear, 11, 31))
        }
      } else {
        let from = new Date(this.selectedYear, monIndex - 1, 1)
        this.excelFrom = TymCommon.getDateString(from)
        let dst = null
        if((now.getFullYear() == from.getFullYear()) && (now.getMonth() == from.getMonth())) {
          dst = new Date()
          dst = new Date(dst.setDate(dst.getDate()))
        } else {
          dst = new Date(from.setMonth(from.getMonth() + 1))
          dst = new Date(dst.setDate(dst.getDate()))
          dst -= 24 * 60 * 60
        }
        this.excelTo = TymCommon.getDateString(dst)
     }
      this.excelDealer = this.selectedTreeLabel
      this.excelType = this.catSelected == this.getCategory[0] ? this.$t('common.all') : this.catSelected

      this.makingExcel = true;
      setTimeout(() => {
        try {
          this.sheets = []
          this.makeExcelData2()
        } catch(ex) {
          console.log(ex)
          this.makingExcel = false;
        }
      }, 1)
    },
    SD2DateString2(sd) {
      if(!TymCommon.isEmpty(sd)) {
        if(sd == 'Invalid date') {
            return null
        }

        const store = useTymictStore()
        let srcDateTime = new Date(sd)
        const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
          hourCycle: "h23",
          year:"numeric",
          month:'2-digit',
          day:'2-digit',
          hour:'2-digit',
          minute:'2-digit'
        })
        if(store.connectServer != 0) {
          /*
          if(store.timezoneIndex == 0) {
            mediumTime.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          } else {
            mediumTime.timeZone = store.timezoneList[store.timezoneIndex].value
          }
          */
        }
        if(store.connectServer == 0) {
          srcDateTime = new Date(srcDateTime.getUTCFullYear(), srcDateTime.getUTCMonth(), srcDateTime.getUTCDate(), srcDateTime.getUTCHours(), srcDateTime.getUTCMinutes(), srcDateTime.getUTCSeconds())
        } else {
          srcDateTime = new Date(srcDateTime.getUTCFullYear(), srcDateTime.getUTCMonth(), srcDateTime.getUTCDate(), srcDateTime.getUTCHours(), srcDateTime.getUTCMinutes(), srcDateTime.getUTCSeconds())
        }
        return mediumTime.format(srcDateTime)
      }
      return null
    },
    makeExcelData2() {
      const store = useTymictStore()
      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      let groups = store.getSubGroups(this.selectedTree)
      if(TymCommon.isEmpty(groups))
        return

      let today = moment()
      let year = parseInt(this.selectedYear)
      let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
      let startDay = null, endDay = null

      if(monIndex == 0) {
        startDay = year.toString() + "-01-01 00:00:00"
        endDay =  (year + 1).toString() + "-01-01 00:00:00"
      } else {
        startDay = year.toString() + "-" + monIndex.toString().padStart(2, '0') + "-01 00:00:00"
        endDay = year.toString() + "-" + (monIndex + 1).toString().padStart(2, '0') + "-01 00:00:00"
      }

      let srcStartDay = startDay
      let srcEndDay = new Date(endDay)
      // srcEndDay -= 60 * 60 * 24     // 하루를 빼서 어제까지 본다.
      srcEndDay = new Date(srcEndDay)
      srcEndDay = srcEndDay.getFullYear().toString() + "-" + (srcEndDay.getMonth() + 1).toString().padStart(2, '0') + "-" + srcEndDay.getDate().toString().padStart(2, '0')
                // + " 00:00:00"
                + ' ' + srcEndDay.getHours().toString().padStart(2, '0') + ':' + srcEndDay.getMinutes().toString().padStart(2, '0') + ':' + srcEndDay.getSeconds().toString().padStart(2, '0')

      let startResult = TymCommon.localTimeToUTC(startDay, "YYYY-MM-DD HH:mm:ss")
      let endResult = TymCommon.localTimeToUTC(endDay, "YYYY-MM-DD HH:mm:ss")
      console.log(srcEndDay, endResult)
      if(endResult.dateTime > today) {
        endDay = TymCommon.localTimeToUTC(today.format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss").dateTimeStr
        
        // srcEndDay = today.subtract(1, 'day')
        srcEndDay = moment(endDay)
        srcEndDay = srcEndDay.format("YYYY-MM-DD 00:00:00")
      }
      startDay = startResult.dateTimeStr

      if(TymConst.IS_DEVELOPMENT) {
        console.log('getYearlySalesData(EXCEL) :', startDay, endDay)
      }
      
      setTimeout(() => {
        TymAws.getSalesStat(group.uuid_a, group.uuid_b, group.uuid_c, group.uuid_d, startDay, endDay, true, false)
          .then(data => {
            if(TymConst.IS_DEVELOPMENT) {
              console.log('Sales(Excel) :', startDay, endDay, data)
            }
            if(data == 'error' || data.length < 1) {
              this.makingExcel = false
              this.hasExcelData = false
              setTimeout(()=> {
                if(this.$refs.excelPopup)
                  this.$refs.excelPopup.hide()
              }, 2000)
              return
            }

            this.hasExcelData = true

            let xlsRow1 = []
            xlsRow1.push('No', '기종', '모델명', '기대번호', '제조 연월', '시리얼 번호', '판매 일시', 
                         '고객명', '고객 연락처', '판매 지역', '판매점', '판매점 연락처')
            let rowNo = 1
            let othRows = []

            let models = []
            let dealers = []

            data.sort((a, b) => {
              return b.SD.localeCompare(a.SD)
            })
            data.forEach(sale => {
              if(this.catSelected != this.getCategory[0]) {
                if(this.catSelected != sale.type)
                  return
              }
              /* sale
              {
                "M_model": "AFTERMARKET",
                "M_option": "0",
                "MN": "ACU_2216_7A",
                "type": "AFTERMARKET",
                "PD": "2023-09",
                "SN": "A001221600070007",
                "SD": "2023-09-22T00:00:00.000Z",
                "UName": "조준희",
                "UPhone": "010-5064-2608",
                "MName": [
                  "TYM",
                  "TYMICT",
                  "",
                  ""
                ],
                "MPhone": "041-851-7700"
              }
              */
              if(store.isICTUser()) {
                //                
              }
              let modelFullname = sale.M_model
              if(sale.M_option != '0' && sale.M_option != 'NONE') {
                modelFullname += sale.M_option
              }

              let manDate = TymCommon.PD2DateString(sale.PD)
              let branchName = ''
              let dealerName = ''
              if(!TymCommon.isEmpty(sale.MName[3])) {
                dealerName = sale.MName[3]
                branchName = sale.MName[2]
              } else if(!TymCommon.isEmpty(sale.MName[2])) {
                dealerName = sale.MName[2]
                branchName = sale.MName[1]
              } else if(!TymCommon.isEmpty(sale.MName[1])) {
                dealerName = sale.MName[1]
                branchName = sale.MName[0]
              } else {
                dealerName = sale.MName[0]
              }
              /*
              switch(groups.Depth) {
                case 0:
                  branchName = TymCommon.isEmpty(sale.MName[0]) ? '' : sale.MName[0]
                  dealerName = TymCommon.isEmpty(sale.MName[1]) ? '' : sale.MName[1]
                  break;
                case 1:
                  branchName = TymCommon.isEmpty(sale.MName[1]) ? '' : sale.MName[1]
                  dealerName = TymCommon.isEmpty(sale.MName[2]) ? '' : sale.MName[2]
                  break;
                case 2:
                  branchName = TymCommon.isEmpty(sale.MName[2]) ? '' : sale.MName[2]
                  dealerName = TymCommon.isEmpty(sale.MName[3]) ? '' : sale.MName[3]
                  break;
                case 3:
                  dealerName = branchName = TymCommon.isEmpty(sale.MName[3]) ? '' : sale.MName[3]
                  break;
              }
              */

              // 표를 만들기위해 모델추출 과정
              if(!models.find(x => x == modelFullname)) {
                models.push(modelFullname)
              }
              // 표를 만들기위해 딜러 추출
              if(!dealers.find(x => x == dealerName)) {
                dealers.push(dealerName)
              }

              othRows.push([rowNo.toString(), sale.M_model, modelFullname, sale.MN, manDate, sale.SN, this.SD2DateString2(sale.SD), 
                          sale.UName, sale.UPhone, branchName, dealerName, sale.MPhone, '', '', ''])
        
              rowNo++
            })
            
            let dayHour = srcStartDay.split(' ')
            let startDate = dayHour[0]
            let endDate = moment(this.excelTo).format('YYYY-MM-DD HH:mm')
            dayHour = endDate.split(' ')
            endDate = dayHour[0]

            let sheetName = 'List '
            sheetName += startDate + '~' + endDate
            sheetName = sheetName.replace('/', '.')

            othRows.splice(0, 0, xlsRow1)
            this.sheets.push({
              //name: 'List ' + startDay.year + (startDay.month + 1).toString().padStart(2, '0') + startDay.date.toString().padStart(2, '0')
              //      + ' - ' + endDay.year + (endDay.month + 1).toString().padStart(2, '0') + endDay.date.toString().padStart(2, '0'),
              name : sheetName,
              data : othRows
            })

            models.sort((a, b) => {
              return a.localeCompare(b)
            })

            let tableSheet = []
            groups.Names.forEach(name => {
              let oneDealer = []
              models.forEach(model => {
                oneDealer.push({
                  Model : model,
                  Count : 0
                })
              })
              tableSheet.push({
                Dealer : name,
                Models : oneDealer
              })
            })

            data.forEach(sale => {
              let dealerName = ''
              switch(groups.Depth) {
                case 0:
                  if(TymCommon.isEmpty(sale.MName[1]))
                    dealerName = sale.MName[0]
                  else
                    dealerName = sale.MName[1]
                  break;
                case 1:
                  if(TymCommon.isEmpty(sale.MName[2]))
                    dealerName = sale.MName[1]
                  else
                    dealerName = sale.MName[2]
                  break;
                case 2:
                  if(TymCommon.isEmpty(sale.MName[3]))
                    dealerName = sale.MName[2]
                  else
                    dealerName = sale.MName[3]
                  break;
                case 3:
                  dealerName = sale.MName[4]
                  break;
              }

              let dealerFound = tableSheet.find(x => x.Dealer == dealerName)
              if(dealerFound) {
                let modelFullname = sale.M_model
                if(sale.M_option != '0' && sale.M_option != 'NONE') {
                  modelFullname += sale.M_option
                }

                let modelFound = dealerFound.Models.find(x => x.Model == modelFullname)
                if(modelFound) {
                  modelFound.Count++
                }
              }
            })

            xlsRow1 = ['구분']
            models.forEach(model => {
              xlsRow1.push(model)
            })
            xlsRow1.push('총 판매 수')

            let modelTotal = []
            models.forEach(one => {
              this.unusedParam(one)
              modelTotal.push(0)
            })

            othRows = []
            for(let idx = 0; idx < tableSheet.length; idx++) {
              let dealer = tableSheet[idx]
              let rowTotal = 0
              let row = []
              row.push(dealer.Dealer)
              for(let mdIdx = 0; mdIdx < dealer.Models.length; mdIdx++) {
                let model = dealer.Models[mdIdx]
                row.push(model.Count)
                rowTotal += model.Count
                modelTotal[mdIdx] += model.Count
              }
              row.push(rowTotal)
              othRows.push(row)
            }
            let totalRow = ['합계']
            let rowTotal = 0
            modelTotal.forEach(count => {
              totalRow.push(count)
              rowTotal += count
            })
            totalRow.push(rowTotal)
            othRows.push(totalRow)

            sheetName = 'Dealer '
            dayHour = srcStartDay.split(' ')
            sheetName += dayHour[0] + ' ~ '
            sheetName += endDate
            sheetName = sheetName.replace('/', '.')

            othRows.splice(0, 0, xlsRow1)
            this.sheets.push({
              //name: 'List ' + startDay.year + (startDay.month + 1).toString().padStart(2, '0') + startDay.date.toString().padStart(2, '0')
              //      + ' - ' + endDay.year + (endDay.month + 1).toString().padStart(2, '0') + endDay.date.toString().padStart(2, '0'),
              name : sheetName,
              data : othRows
            })
            
            setTimeout(() => {
              if(TymConst.IS_DEVELOPMENT) {
                console.log('>>> excel >>> ', tableSheet)
              }
              this.makingExcel = false;
            }, 5)
          })
          .catch(er => {
            console.log('ExportExcel FAIL', er)
            this.hasExcelData = false
            this.makingExcel = false;
            setTimeout(() => {
              setTimeout(()=> {
                if(this.$refs.excelPopup)
                  this.$refs.excelPopup.hide()
              }, 2000)
            }, 5)
          })
      })
    },
    saveToExcelFile() {
      let desc = {
        filename: this.excelFilename,
        start: this.excelFrom,
        end: this.excelTo,
        dealer: this.excelDealer,
        type: this.excelType
      }
      TymAws.writeAction('통계', '판매', desc)

      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 500)
    }
  }
})
</script>