<template>
  <div class="column" style="height:100%;width:100%;margin-bottom:12px;" :style='isLandscape ? "margin-bottom:12px;" : "margin-bottom:3px;"'>
    <div class="col-auto row justify-between">
      <div class="col-auto row items-center" style="margin:0 6px 0 0;">
        <q-img width="50px" height="50px"
               :src='isMachineOn ? require("../assets/monitor/machinemap_on_icon.svg") : require("../assets/monitor/machinemap_off_icon.svg")'></q-img>
      </div>

      <div class="col column items-start justify-start" style="margin:6px 0 0 3px;padding:0 0 3px 0">
        <div class="col" style="font-size:18px;font-weight:500;color:#212529;;margin:0 3px;">{{ $t('machineInfo.title') }}</div>
        <div class="col row items-center justify-between">

          <div v-show="getMachineStateNormal" style="width:26px;height:26px;margin:0 3px;">
            <q-img :src="require('../assets/dashboard/dashboard_good_icon.svg')" fit="fill" />
          </div>
          <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;background-color:white;"
               v-show="!getMachineStateNormal">
          </div>

          <div v-show="getMachineStateEM" style="width:26px;height:26px;margin:0 3px;">
            <q-img :src="require('../assets/dashboard/dashboard_rollover_icon.svg')" fit="fill" width="26px" height="26px"/>
          </div>
          <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;background-color:white;"
               v-show="!getMachineStateEM">
          </div>

          <div v-show="getMachineStateBR" style="width:26px;height:26px;margin:0 3px;">
            <q-img :src="require('../assets/dashboard/dashboard_error_icon.svg')" fit="fill" width="26px" height="26px"/>
          </div>
          <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;background-color:white;"
               v-show="!getMachineStateBR">
          </div>

          <div v-show="getMachineStateCONS" style="width:26px;height:26px;margin:0 3px;">
            <q-img :src="require('../assets/dashboard/dashboard_consumables_icon.svg')" fit="fill" width="26px" height="26px"/>
          </div>
          <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;background-color:white;"
               v-show="!getMachineStateCONS">
          </div>

          <div v-show="getMachineStateLowBatt" style="width:26px;height:26px;margin:0 3px;">
            <q-img :src="require('../assets/dashboard/dashboard_battery_icon.svg')" fit="fill" width="26px" height="26px"/>
          </div>
          <div style="border:1px solid #a0a0a0a0;border-radius: 50%;width:26px;height:26px;margin:0 3px;padding:1px;background-color:white;"
               v-show="!getMachineStateLowBatt">
          </div>
        </div>
      </div>

      <div class="col-auto column justify-end"  style="margin:0 0 0 6px;">
        <div class="col-auto row items-center justify-end">
          <q-btn class="col-auto" flat dense icon="more_horiz" @click="viewMore"></q-btn>
        </div>
        <!-- pagination -->
        <div class="col-auto row items-center justify-end">
          <q-btn flat dense icon="chevron_left" :disable="totalCount <= 0 || curIndex <= 0" @click="viewPrevious"></q-btn>
          <div style="margin:0 3px;" >{{ (totalCount > 0) ? (curIndex + 1) : 0 }} / {{ totalCount }}</div>
          <q-btn flat dense icon="chevron_right" :disable="totalCount <= 0 || curIndex >= (totalCount-1)" @click="viewNext"></q-btn>
        </div>
      </div>
    </div>

    <q-scroll-area class="col row" style="height:100%;width:100%;">
      <table class="col" style="height:100%;width:100%;border-top: 2px solid #dddddd;border-bottom: 2px solid #dddddd;">
        <tr class="tr_middle ">
          <td class="infoTitle tdRightLine" width="20%" colspan="2">{{ $t('machineInfo.owner') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].name : '' }}</td>
        </tr>
        <tr class="tr_middle trbg_ltgray">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.mobile') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].phone : '' }}</td>
        </tr>
        <tr class="tr_middle" v-show="isKorea">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.address') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].address : '' }}</td>
        </tr>
        <tr class="tr_middle trbg_ltgray">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.model') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].modelNo : '' }}</td>
        </tr>
        <tr class="tr_middle">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.vin') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].machineNo : '' }}</td>
        </tr>

        <tr class="tr_middle trbg_ltgray">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.manDate') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].manDate : '' }}</td>
        </tr>
        <tr class="tr_middle">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.serialNo') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].serialNo : '' }}</td>
        </tr>
        <tr class="tr_middle trbg_ltgray">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.engHour') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].engHour + '&nbsp;h': '' }}</td>
        </tr>
        <tr class="tr_middle">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.saleDate') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].saleDate : '' }}</td>
        </tr>
        <tr class="tr_middle trbg_ltgray">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.dealer') }}</td>
          <td class="infoContent " colspan="3">{{ totalCount > 0 ? machineValue[curIndex].dealer : '' }}</td>
        </tr>
        <tr class="tr_middle">
          <td class="infoTitle tdRightLine" colspan="2">{{ $t('machineInfo.curLoca') }}</td>
          <td class="infoContent" colspan="3">{{ totalCount > 0 ? machineValue[curIndex].realtimeAddr : '' }}</td>
        </tr>
        <tr class="tr_middle" :class="getRowBackground(0)" v-show="currentState[0].length > 0">
          <td class="infoTitle tdRightLine" colspan="5">
            <div class="col row items-center">
              <q-img :src="require('../assets/dashboard/dashboard_rollover_icon.svg')" fit="fill" width="20px" height="20px" style="width:22px;margin:0 6px 0 0;"/>
              <div class="col column">
                <div class="col">{{ currentState[0] }}</div>
              </div>
            </div>
          </td>
        </tr>
        <tr class="tr_middle" :class="getRowBackground(1)" v-show="currentState[1].length > 0">
          <td class="infoTitle tdRightLine" colspan="5">
            <div class="col row items-center justify-start no-wrap">
              <q-img :src="require('../assets/dashboard/dashboard_error_icon.svg')" fit="fill" width="20px" height="20px" style="width:22px;margin:0 6px 0 0;"/>
              <div class="col column">
                <div class="col">{{ currentState[1] }}</div>
              </div>
            </div>
          </td>
        </tr>
        <tr class="tr_middle" :class="getRowBackground(2)" v-show="currentState[2].length > 0">
          <td class="infoTitle tdRightLine" colspan="5">
            <div class="col row items-center">
              <q-img :src="require('../assets/dashboard/dashboard_consumables_icon.svg')" fit="fill" width="20px" height="20px" style="width:22px;margin:0 6px 0 0;"/>
              <div>
                <div class="col">{{ currentState[2] }}</div>
              </div>
            </div>
          </td>
        </tr>
        <tr class="tr_middle" :class="getRowBackground(3)" v-show="currentState[3].length > 0">
          <td class="infoTitle tdRightLine" colspan="5">
            <div class="col row items-center">
              <q-img :src="require('../assets/dashboard/dashboard_battery_icon.svg')" fit="fill" width="20px" height="20px" style="width:22px;margin:0 6px 0 0;"/>
              <div>
                <div class="col">{{ currentState[3] }}</div>
              </div>
            </div>
          </td>
        </tr>

      </table>
    </q-scroll-area>
  </div>
</template>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse ;
}
.tr_top {
  border-top: 2px solid #dddddd;
}
.tr_middle {
  border-bottom: 1px solid #eeeeee;
}

.trbg_ltgray {
  background-color:#f8f8f8
}
.tr_bottom {
  border-bottom: 2px solid #dddddd;
}

.tdRightLine {
  padding: 3px 4px;
  border-right: 1px solid #eeeeee;
}

.infoTitle {
  font-size:14px;
  font-weight: 500;
  color:black;
}

.infoContent {
  font-size:14px;
  padding: 0px 4px;
  color:black;
}

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'
// import { useI18n } from 'vue-i18n'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants'
import TymAws from '@/js/tymaws.js'

export default ({
  props : {
    kind : ref(0),
  },
  data() {
    return {
    }
  },
  setup() {
    // const i18n = useI18n()
    return {
      // isMachineOn : ref(false),
      machineState : ref([false, false, false, false, false]),
      stateColors : ref(['#20d020', '#eb0028', '#c97d31','#ffc600','#bbbbbb']),  // green, red, brown, yellow, gray
      machineValue : ref(null),
      curIndex : ref(0),
      totalCount : ref(0),
      currentState: ref(['', '', '', ''])
    }
  },

  computed: {
    isDev() {
      return TymConst.IS_DEVELOPMENT
    },
    isLandscape() {
      const store = useTymictStore()
      return store.isLandscape
    },
    getItemTitles() {
      return (idx) => {
       return this.itemHeaders[idx]
      }
    },
    isKorea() {
      const store = useTymictStore()
      // console.log('isKorea :', (store.connectServer == 0))
      return (store.connectServer == 0)
    },
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    isGoogleMap() {
      const store = useTymictStore()
      return (store.connectServer != 0)
    },
    isMachineOn() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        //if(TymConst.IS_DEVELOPMENT)
        //  console.log('---->', machine)
        if(machine && !TymCommon.isEmpty(machine.PWR)) {
          return machine.PWR == 'ON'
        }
      }
      return false
    },
    getMachineStateNormal() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        //console.log(store.machineLocaInfo, this.curIndex, this.machineValue)
        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return !machine.abnormal[0] && !machine.abnormal[1] && !machine.abnormal[2] && !machine.abnormal[3]
        }
      }
      return false
    },
    getMachineStateEM() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return machine.abnormal[0]
        }
      }
      return false
    },
    getMachineStateBR() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return machine.abnormal[1]
        }
      }
      return false
    },
    getMachineStateCONS() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return machine.abnormal[2]
        }
      }
      return false
    },
    getMachineStateLowBatt() {
      if(!TymCommon.isEmpty(this.machineValue) && (this.curIndex >= 0) && (this.machineValue.length > 0)) {
        const store = useTymictStore()

        let machine = store.machineLocaInfo.find(x => x.machineNo == this.machineValue[this.curIndex].machineNo)
        if(machine) {
          return machine.abnormal[3]
        }
      }
      return false
    }
  },
  created() {},
  mounted() {
  }, 
  unmounted() {},
  methods : {
    unusedParam() {
      //
    },
    updateData(idx, items) {
      if(idx >= 0) {
        // console.log('OneMachine.updateData', idx, items[idx])
        this.curIndex = idx
        this.totalCount = items.length
        this.machineValue = items
        this.machineValue.forEach(machine => {
          machine.engHour = 0
        })
        // this.isMachineOn = false  // 시동여부

        this.machineState[0] = !(items[idx].abnormal[0] || items[idx].abnormal[1] || items[idx].abnormal[2] || items[idx].abnormal[3])
        this.machineState[1] = items[idx].abnormal[0]
        this.machineState[2] = items[idx].abnormal[1]
        this.machineState[3] = items[idx].abnormal[2]
        this.machineState[4] = items[idx].abnormal[3]
        this.makeItem(idx)
      } else {
        this.machineState = [false, false, false, false, false]
        this.totalCount = 0
        this.curIndex = idx
      }
      if(this.totalCount <= 0) {
        return
      }

      TymAws.getLastDataBySN(this.machineValue[this.curIndex].serialNo)
        .then(data => {
          this.machineValue[this.curIndex].engHour = parseFloat(data[0].V_HOUR)
        })
        .catch(er => {
          console.log('TymAws.getLastDataBySN ER ', this.machineValue[this.curIndex].serialNo, er)
        })

      const store = useTymictStore()

      let em = store.EmergencyData.data.find(x => x.MN == this.machineValue[this.curIndex].machineNo)
      let br = store.BrokenData.data.find(x => x.MN == this.machineValue[this.curIndex].machineNo)
      let cs = store.ConsumablesData.data.find(x => x.MN == this.machineValue[this.curIndex].machineNo)
      let lb = store.BatteryAlertData.data.find(x => x.MN == this.machineValue[this.curIndex].machineNo)

      let language = store.getLanguage() == 'ko' ? 0 : 1
      if(!TymCommon.isEmpty(em)) {
        if(em.ECount > 1) {
          if(this.isKorean)
            this.currentState[0]  = em.DN[0][0] + ' 외 ' + (em.ECount - 1) + ' 건'
          else
            this.currentState[0]  = em.DN[0][1] + ' and ' + (em.ECount - 1) + ' more'
        } else {
          this.currentState[0] =  em.DN[0].length >= language ? em.DN[0][language] : em.DN[0][0]
        }
      } else {
        this.currentState[0] = ''
      }
      if(!TymCommon.isEmpty(br)) {
        if(br.ECount > 1) {
          if(this.isKorean)
            this.currentState[1]  = br.DN[0] + ' 외 ' + (br.ECount - 1) + ' 건'
          else
            this.currentState[1]  = br.DN[0] + ' and ' + (br.ECount - 1) + ' more'
        } else {
          this.currentState[1] =  Array.isArray(br.DN[0]) ? br.DN[0][language] : br.DN[0]
        }
      } else {
        this.currentState[1] = ''
      }
      if(!TymCommon.isEmpty(cs)) {
        if(TymConst.IS_DEVELOPMENT) {
          console.log('CONS ', cs)
        }
        this.currentState[2] = cs.DN[0][language]
        if(cs.DN.length > 1) {
          if(Array.isArray(cs.DN[0])) {
            if(this.isKorean)
              this.currentState[2]  = cs.DN[0][0] + ' 외 ' + (cs.DN.length - 1) + ' 건'
            else
              this.currentState[2]  = cs.DN[0][0] + ' and ' + (cs.DN.length - 1) + ' more'
          } else {
            if(this.isKorean)
              this.currentState[2]  = cs.DN[0] + ' 외 ' + (cs.DN.length - 1) + ' 건'
            else
              this.currentState[2]  = cs.DN[0] + ' and ' + (cs.DN.length - 1) + ' more'
          }
        } else {
          this.currentState[2] =  Array.isArray(cs.DN[0]) ? cs.DN[0][language] : br.DN[0]
        }
      } else {
        this.currentState[2] = ''
      }
      if(!TymCommon.isEmpty(lb)) {
        this.currentState[3] = this.isKorean ? "발생" : "Occurred"
        this.currentState[3] += ' [ '
        this.currentState[3] += TymCommon.convertStringToDateTime2(lb.OTime, 0)
        this.currentState[3] += ' ]'
      } else {
        this.currentState[3] = ''
      }
      // console.log(this.currentState)
    },
    viewPrevious() {
      this.$emit('change',  this.curIndex - 1)
    },
    viewNext() {
      this.$emit('change',  this.curIndex + 1)
    },
    viewMore() {
      this.$emit('view',  this.curIndex)
    },
    getRowBackground(idx) {
      let cnt = 0
      switch(idx) {
        case 0:
          if(this.currentState[0].length > 0) {
            return 'trbg_ltgray'
          }
          break
        case 1:
          if(this.currentState[0].length <= 0) {
            return 'trbg_ltgray'
          }
          break
        case 2:
          if((this.currentState[0].length > 0) )
            cnt = 1
          if((this.currentState[1].length > 0) )
            cnt++

          if(cnt % 2 == 0) {
              return 'trbg_ltgray'
          }
          break
        case 3:
          if((this.currentState[0].length > 0) )
            cnt = 1
          if((this.currentState[1].length > 0) )
            cnt++
          if((this.currentState[2].length > 0) )
            cnt++

          if(cnt % 2 == 0) {
              return 'trbg_ltgray'
          }
          break
      }
      return ''
    },
    makeItem(idx) {
      let self = this
      let item = this.machineValue[idx]

      /*
      {
          "name": "홍성석",
          "phone": "010-2284-3353",
          "address": "충남 아산시 고불로 669번지 초원아파트 204동 201호",
          "modelNo": "TS130",
          "machineNo": "S130SX00486",
          "manDate": "2022. 4.",
          "serialNo": "T001215100550055",
          "salesDate": "2023. 05. 10. 24:00",
          "dealer": "충남직영점",
          "latitude": 36.7389089833,
          "longitude": 127.0523735833,
          "realtimeAddr": "충청남도 아산시 솔치로  62-30",
          "abnormal": [
              false,
              false,
              false,
              false
          ]
      }
      */
      const geocodeResultFunc = (status, response) => {
        let address = ''
        if(this.isGoogleMap) {
          if (status != 'OK') {
            this.searchItems = [ item ]
            this.curIndex = 0
            this.$refs.oneMachine.updateData(this.curIndex, this.searchItems)
            return;
          }
          if(!TymCommon.isEmpty(response)) {
            address = response[0].formatted_address
          }
        } else {
          if (status === window.naver.maps.Service.Status.ERROR) {
            this.searchItems = [ item ]
            this.curIndex = 0
            this.$refs.oneMachine.updateData(this.curIndex, this.searchItems)
            return;
          }
          if(!TymCommon.isEmpty(response.v2.address.roadAddress)) {
            address = response.v2.address.roadAddress
          } else if(!TymCommon.isEmpty(response.v2.address.jibunAddress)) {
            address = response.v2.address.jibunAddress
          } else{
            let addr = ''
            let i = 0
            let result = null
            if(Array.isArray(response.result)) {
              if(response.result.length > 0)
                result = response.result[0]
            } else {
              result = response.result
            }

            // console.log('OneMachine.geocodeResultFunc :', response, result)
            if(!TymCommon.isEmpty(result) && !TymCommon.isEmpty(result.items)) {
              for(i = 0; i < result.items.length; i++) {
                if(result.items[i].isAdmAddress) {
                  addr = result.items[i].address
                  break;
                }
              }
            }
            if(TymCommon.isEmpty(addr)) {
              let roadAddr = response.v2.results.filter(r => r.name === 'addr');
              address = TymCommon.makeAddress(roadAddr[0])
            } else {
              address = response.result.items[0].address
            }
          }
        }  
        self.machineValue[idx].realtimeAddr = address
      }

      const store = useTymictStore()
      let one = store.SalesData.data.find(x => x.machineNo == item.machineNo)
      if(one) {
        if(TymConst.IS_DEVELOPMENT) {
          console.log('makeItem :', this.machineValue[idx], one)
        }

        this.machineValue[idx].SD = one.SD
        this.machineValue[idx].address = one.address
        this.machineValue[idx].dealer = one.dealer
        this.machineValue[idx].phone = one.mobile
        this.machineValue[idx].uid = one.uid
        this.machineValue[idx].manDate = one.manDate
        this.machineValue[idx].saleDate = one.saleDate

        if(this.isGoogleMap) {
          const geocoder = new window.google.maps.Geocoder()
          const latlng = new window.google.maps.LatLng(item.latitude, item.longitude)

          geocoder.geocode({'latLng' : latlng}, function(res, status) {
            //console.log('GMap Geocode ##2', res, status)
            if(status == 'OK') {
              // console.log('GMap Geocode ##2', res, status)
              geocodeResultFunc(status, res)
            } else {
              console.log('GMap Geocode ERR', status.toString())
            }
          })
        } else {
          if(TymCommon.isEmpty(item.realtimeAddr)) {
            if(!isNaN(item.latitude) && !isNaN(item.longitude)) {
              window.naver.maps.Service.reverseGeocode({
                coords: new window.naver.maps.LatLng(item.latitude, item.longitude), 
                orders: [
                  window.naver.maps.Service.OrderType.ROAD_ADDR,
                  window.naver.maps.Service.OrderType.ADDR
                ].join(',')
              }, geocodeResultFunc)
            }
          }
        }
      }
    }
  }
})
</script>
