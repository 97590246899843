<template>
  <div
    class="column"
    :style="isLandscape ? 'width:100%;height:100%;' : 'width:100%;height:100%;'"
    style="background-color: #f2f2f2"
  >
    <div
      class="col-auto column items-center justify-start"
      style="position: relative; border: 1px solid #ebedf2; border-radius: 3px"
    >
      <table
        class="col"
        style="
          width: 100%;
          background-color: white;
          padding: 12px;
          border-radius: 3px;
        "
      >
        <tr>
          <td width="100%">
            <!-- 제목 -->
            <div
              class="col-auto"
              style="font-size: 18px; font-weight: 500"
              :style="
                isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'
              "
            >
              {{ $t("statView.emergency") }}
            </div>
          </td>
        </tr>
        <tr>
          <td width="100%">
            <div class="col row items-center justify-between no-wrap" style="">
              <div class="col-auto row items-center justify-start">
                <!-- 조직 선택 -->
                <div
                  class="col-auto row grpBtn items-center justify-between"
                  style="
                    min-width: 180px;
                    width: auto;
                    height: 40px;
                    padding: 0 6px 0 0;
                  "
                >
                  <div
                    class="col row items-center justify-between"
                    style="cursor: pointer; padding: 0 0 0 12px; height: 100%"
                    @click="groupPopupDisplayed = !groupPopupDisplayed"
                  >
                    <q-popup-proxy
                      ref="groupPopup"
                      style="padding: 3px"
                      @hide="groupPopupDisplayed = false"
                    >
                      <div
                        class="column"
                        style="min-width: 250px; height: 250px"
                      >
                        <div
                          class="col row"
                          style="
                            border: 1px solid #cccccc;
                            padding: 3px;
                            background-color: white;
                          "
                        >
                          <q-scroll-area
                            class="col row"
                            style="width: auto; height: 100%"
                          >
                            <q-tree
                              :nodes="groupTreeData"
                              dense
                              no-selection-unset
                              selected-color="red-7"
                              class="col"
                              node-key="uuid"
                              v-model:expanded="expandedTree"
                              v-model:selected="selectedTree"
                              @update:selected="groupTreeSelected"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </q-popup-proxy>
                    <div class="col">
                      {{ selectedTreeLabel }}
                    </div>
                    <q-icon
                      class="col-auto"
                      dense
                      color="grey-8"
                      :name="
                        groupPopupDisplayed
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                      "
                      size="24px"
                    >
                    </q-icon>
                  </div>
                  <!--
                  <q-btn class="col-auto" dense flat rounded color="grey-7" icon="close" size="md" @click="clearGroupData"/>
                  -->
                </div>

                <!-- 년도 -->
                <q-select
                  dense
                  outlined
                  options-dense
                  v-model="selectedYear"
                  :options="validYears"
                  class="col-auto"
                  color="red-7"
                  hide-bottom-space
                  style="font-size: 14px; min-width: 100px; margin: 0 0 0 6px"
                >
                </q-select>
                <!-- 월 선택 -->
                <q-select
                  dense
                  outlined
                  options-dense
                  v-model="selectedMonth"
                  :options="getMonthTitle"
                  class="col-auto"
                  color="red-7"
                  hide-bottom-space
                  style="font-size: 14px; min-width: 100px; margin: 0 0 0 6px"
                >
                </q-select>

                <q-select
                  dense
                  outlined
                  v-model="catSelected"
                  :options="getCategory"
                  class="col-auto"
                  color="red-7"
                  hide-bottom-space
                  options-dense
                  @update:model-value="changedCategory"
                  style="font-size: 14px; min-width: 160px; margin: 0 0 0 6px"
                >
                </q-select>

                <q-select
                  dense
                  outlined
                  v-model="emTypeSelected"
                  :options="getEmTypes"
                  class="col-auto"
                  color="red-7"
                  hide-bottom-space
                  options-dense
                  @update:model-value="changedErroPart"
                  style="font-size: 14px; min-width: 160px; margin: 0 0 0 6px"
                >
                </q-select>

                <div class="col row items-center justify-center">
                  <q-btn
                    class="col"
                    no-caps
                    unelevated
                    dense
                    color="red-7"
                    text-color="white"
                    :label="$t('common.search')"
                    style="width: 80px; height: 40px; margin: 0 0 0 6px"
                    @click="searchData()"
                  >
                    <q-tooltip style="font-size: 14px">{{
                      $t("tooltip.searchData")
                    }}</q-tooltip>
                  </q-btn>
                </div>
              </div>
              <div class="col-auto row">
                <q-btn
                  flat
                  dense
                  @click="downloadToExcel"
                  v-show="canDownloadToExcel"
                >
                  <q-tooltip style="font-size: 14px">{{
                    $t("tooltip.exportExcel")
                  }}</q-tooltip>
                  <img
                    src="~../assets/manage/management_excel_icon.svg"
                    style="width: 26px; height: 32px"
                  />
                  <q-popup-proxy
                    ref="excelPopup"
                    style="
                      width: 360px;
                      height: 240px;
                      background-color: #01010130;
                      border-radius: 3px;
                      position: relative;
                    "
                    @before-show="onShowExportToExcel"
                  >
                    <div
                      style="
                        border: 1px solid #101010;
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                      "
                    >
                      <div
                        class="column items-center justify-start"
                        style="
                          background-color: white;
                          width: 100%;
                          height: 100%;
                          border-radius: 3px;
                          padding: 12px;
                        "
                      >
                        <div class="col-auto row items-center justify-center">
                          <div
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;'
                                : 'font-family:Prometo;'
                            "
                            style="
                              font-weight: bold;
                              font-size: 18px;
                              margin: 0 0 12px 0;
                            "
                          >
                            {{ $t("common.toExcel") }}
                          </div>
                        </div>
                        <div class="col column" v-show="hasExcelData">
                          <div
                            class="col row items-center justify-start"
                            style="width: 100%; margin: 0"
                          >
                            <table class="col" style="width: 100%; margin: 0">
                              <tr style="">
                                <td width="30%" style="text-align: right">
                                  {{ $t("common.filename") }}
                                </td>
                                <td width="12px" style=""></td>
                                <td style="">{{ excelFilename }}</td>
                              </tr>
                              <tr>
                                <td width="30%" style="text-align: right">
                                  {{ $t("common.to") }}
                                </td>
                                <td width="12px"></td>
                                <td>{{ excelFrom }}</td>
                              </tr>
                              <tr>
                                <td width="30%" style="text-align: right">
                                  {{ $t("common.to") }}
                                </td>
                                <td width="12px"></td>
                                <td>{{ excelTo }}</td>
                              </tr>
                              <tr>
                                <td width="30%" style="text-align: right">
                                  {{ $t("dashboardView.dealer") }}
                                </td>
                                <td width="12px"></td>
                                <td>{{ excelDealer }}</td>
                              </tr>
                              <tr>
                                <td width="30%" style="text-align: right">
                                  {{ $t("common.machineType") }}
                                </td>
                                <td width="12px"></td>
                                <td>{{ excelType }}</td>
                              </tr>
                            </table>
                          </div>
                          <xlsx-workbook
                            class="col-auto row items-center justify-center"
                          >
                            <xlsx-sheet
                              :collection="sheet.data"
                              v-for="sheet in sheets"
                              :key="sheet.name"
                              :sheet-name="sheet.name"
                              @parsed="onParsed"
                            />
                            <xlsx-download :filename="excelFilename">
                              <button
                                :style="
                                  isKorean
                                    ? 'font-family:Noto Sans KR;'
                                    : 'font-family:Prometo;'
                                "
                                style="height: 32px; width: 100px"
                                @click="saveToExcelFile"
                              >
                                {{ $t("common.download") }}
                              </button>
                            </xlsx-download>
                          </xlsx-workbook>
                        </div>
                        <div
                          class="col row items-center justify-center"
                          v-show="!hasExcelData"
                          style="width: 100%; height: 100%"
                        >
                          <div
                            class="col text-center"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;'
                                : 'font-family:Prometo;'
                            "
                            style="
                              font-size: 18px;
                              font-weight: bold;
                              color: #eb0028;
                            "
                          >
                            {{ $t("warning.nodata") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row items-center justify-center"
                      v-show="makingExcel"
                      style="
                        position: absolute;
                        z-index: 3;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 3px;
                        background-color: #80808060;
                      "
                    >
                      <q-spinner color="red-7" size="70px" thickness="1.5" />
                    </div>
                  </q-popup-proxy>
                </q-btn>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- 차트들 -->
    <div
      class="col row"
      style="height: 100%; margin: 12px 0 0 0; background-color: F2F2F2"
    >
      <div class="col row" style="margin: 0px">
        <q-splitter
          v-model="horizSplitter"
          :limits="[20, 80]"
          horizontal
          separator-style="height:12px;background-color:red;"
          class="col"
          separator-class="transparent"
        >
          <template v-slot:before>
            <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
            <q-splitter
              v-model="upSplitter"
              :limits="[20, 80]"
              :separator-style="!isLandscape ? 'height:12px' : 'width:12px'"
              :horizontal="!isLandscape"
              separator-class="transparent"
            >
              <template v-slot:before>
                <div class="row cellBackground">
                  <div class="col row">
                    <!-- 지역별로 변경... 변수는 그대로 사용할까?-->
                    <div class="col column">
                      <div class="col-auto row" style="height: 42px">
                        <div class="col row items-center">
                          <div
                            class="col-auto"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;font-weight:bold'
                                : 'font-family:Prometo;font-weight:500;'
                            "
                            style="font-size: 16px"
                          >
                            {{ titleForDealer }}
                          </div>
                          <q-separator
                            color="grey-7"
                            vertical
                            style="margin: 9px 6px 9px 12px"
                            v-show="termByDealer.length > 0"
                          ></q-separator>
                          <div
                            class="col-auto"
                            style="
                              font-family: Prometo;
                              font-size: 15px;
                              color: #eb0028;
                            "
                            v-show="termByDealer.length > 0"
                          >
                            &nbsp;{{ termByDealer }}
                          </div>
                        </div>
                        <div class="col row items-center justify-end">
                          <div
                            class="col-auto"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;'
                                : 'font-family:Prometo;'
                            "
                            style="font-size: 15px"
                            v-show="countByBranch > 0"
                          >
                            {{
                              $t("statEmergency.emergencyCount")
                            }}&nbsp;:&nbsp;{{ countByBranch }}
                          </div>
                        </div>
                      </div>
                      <div
                        id="dealerContainer"
                        class="col row q-mt-xs q-pa-sm items-center justify-center"
                        style="
                          position: relative;
                          border: 1px solid #dddddd;
                          border-radius: 3px;
                        "
                      >
                        <q-scroll-area
                          class="col row items-center justify-center"
                          style="
                            position: absolute;
                            left: 6px;
                            top: 6px;
                            right: 6px;
                            bottom: 6px;
                            border-radius: 3px;
                          "
                        >
                          <div
                            class="col"
                            ref="refDealer"
                            id="dealerChart"
                          ></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeDealer" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:separator>
                <div
                  style="background-color: #f2f2f2"
                  class="row items-center justify-center"
                >
                  <div
                    :class="isLandscape ? 'vertSeparator' : 'horizSeparator'"
                    style=""
                  ></div>
                </div>
              </template>
              <template v-slot:after>
                <div class="row cellBackground">
                  <div class="col row">
                    <!-- 기종/모델별 (우측 상단) -->
                    <div class="col column" style="">
                      <div
                        class="col-auto row items-center justify-between"
                        style="height: 42px"
                      >
                        <div class="col-auto row items-center justify-start">
                          <div class="col-auto row items-center justify-start">
                            <q-select
                              dense
                              outlined
                              options-dense
                              v-model="selectedModelOptionTab"
                              :options="selectModelOptionTabs"
                              class="col-auto"
                              color="red-7"
                              hide-bottom-space
                              @update:model-value="changedModelOptionTab"
                              style="color: black; margin: -6px 0 0 0"
                            >
                              <template v-slot:selected>
                                <div
                                  :style="
                                    isKorean
                                      ? 'font-family:Noto Sans KR;font-weight:bold;'
                                      : 'font-family:Prometo;font-weight:500;'
                                  "
                                  style="font-size: 16px"
                                >
                                  {{ selectedModelOptionTab }}
                                </div>
                              </template>
                            </q-select>
                            <div
                              class="col-auto"
                              style="
                                font-family: Prometo;
                                font-size: 15px;
                                color: #eb0028;
                                margin: -6px 0 0 12px;
                              "
                              v-show="countByModel > 0"
                            >
                              {{
                                $t("statEmergency.emergencyCount")
                              }}&nbsp;:&nbsp;{{ countByModel }}
                            </div>
                          </div>
                        </div>
                        <div class="col row items-center justify-end">
                          <q-select
                            borderless
                            dense
                            options-dense
                            v-model="selectedModelOption"
                            :options="selectModelOptions"
                            class="col-auto"
                            color="red-7"
                            hide-bottom-space
                            v-show="!chartForModel"
                            @update:model-value="changedSelectedModel"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;'
                                : 'font-family:Prometo;'
                            "
                            style="
                              font-size: 16px;
                              min-width: 80px;
                              margin: -12px 0 0 0;
                              height: 20px;
                            "
                          >
                          </q-select>
                        </div>
                      </div>
                      <div
                        id="modelOptionContainer"
                        class="col row q-mt-xs q-pa-sm items-center justify-center"
                        style="
                          position: relative;
                          border: 1px solid #dddddd;
                          border-radius: 3px;
                        "
                      >
                        <q-scroll-area
                          class="col row items-center justify-center"
                          style="
                            position: absolute;
                            left: 6px;
                            top: 6px;
                            right: 6px;
                            bottom: 6px;
                            border-radius: 3px;
                          "
                        >
                          <div
                            ref="refModelOption"
                            id="modelOptionChart"
                            class="col-auto"
                            style=""
                          ></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeModelOption" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </q-splitter>
          </template>
          <template v-slot:separator>
            <div
              style="background-color: #f2f2f2; width: 100%; height: 12px"
              class="row items-center justify-center"
            >
              <div class="horizSeparator" style=""></div>
            </div>
          </template>
          <template v-slot:after>
            <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
            <q-splitter
              v-model="downSplitter"
              :limits="[20, 80]"
              :separator-style="!isLandscape ? 'height:12px' : 'width:12px'"
              :horizontal="!isLandscape"
              separator-class="transparent"
            >
              <template v-slot:before>
                <div class="row cellBackground">
                  <div class="col row">
                    <!-- 지점별 모델/옵션 (좌측 하단) -->
                    <div class="col column" style="">
                      <div
                        class="col-auto row items-center justify-between"
                        style="height: 42px"
                      >
                        <div class="col-auto row items-center justify-start">
                          <div class="col-auto row items-center justify-start">
                            <q-select
                              dense
                              outlined
                              options-dense
                              standout
                              v-model="selectedDealerModelOptionTab"
                              :options="selectDealerModelOptionTabs"
                              class="col-auto"
                              color="red-7"
                              hide-bottom-space
                              @update:model-value="changedDealerModelOptionTab"
                              style="color: black; margin: -6px 0 0 0"
                            >
                              <template v-slot:selected>
                                <div
                                  :style="
                                    isKorean
                                      ? 'font-family:Noto Sans KR;font-weight:bold;'
                                      : 'font-family:Prometo;font-weight:500;'
                                  "
                                  style="font-size: 16px"
                                >
                                  {{ selectedDealerModelOptionTab }}
                                </div>
                              </template>
                            </q-select>
                          </div>
                        </div>
                        <div class="col row items-center justify-end">
                          <q-select
                            borderless
                            dense
                            options-dense
                            v-model="selectedDealerModel"
                            :options="selectDealerModels"
                            class="col-auto"
                            color="red-7"
                            hide-bottom-space
                            v-show="!tableForModel"
                            @update:model-value="changedDealerModel"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;'
                                : 'font-family:Prometo;'
                            "
                            style="
                              font-size: 16px;
                              min-width: 80px;
                              margin: -12px 0 0 0;
                              height: 20px;
                            "
                          >
                          </q-select>
                        </div>
                      </div>
                      <div
                        class="col row q-mt-xs items-center justify-center"
                        style="
                          position: relative;
                          border: 1px solid #dddddd;
                          border-radius: 3px;
                        "
                      >
                        <q-table
                          class="col"
                          ref="modelTable"
                          style="
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                          "
                          dense
                          flat
                          virtual-scroll
                          hide-no-data
                          separator="none"
                          :columns="tableColumns"
                          :visible-columns="tableVisibleColumns"
                          :rows="tableRows"
                          :rows-per-page-options="[0]"
                          hide-pagination
                          row-key="index"
                        >
                          <template v-slot:header="props">
                            <q-th
                              no-caps
                              style="
                                height: auto;
                                background-color: white;
                                border-right: 1px solid #e0e0e0;
                              "
                              class="tableHeader"
                              :style="
                                col.name == 'col0'
                                  ? 'position:sticky;z-index:4;left:0'
                                  : ''
                              "
                              v-for="col in props.cols"
                              :key="col.name"
                              :props="props"
                            >
                              {{ col.label }}
                            </q-th>
                          </template>
                          <template v-slot:body="props">
                            <q-tr
                              :props="props"
                              :style="
                                props.row.index % 2 == 0
                                  ? 'background-color:#f8f8f8;'
                                  : 'background-color:white;'
                              "
                            >
                              <q-td
                                v-for="col in props.cols"
                                :key="col.name"
                                :props="props"
                                style="
                                  border-bottom: 1px solid #e0e0e0;
                                  border-right: 1px solid #e0e0e0;
                                "
                                :style="
                                  col.name == 'col0'
                                    ? 'font-weight:500;position:sticky;z-index:2;left:0;'
                                    : 'font-weight:400;'
                                "
                              >
                                {{ col.value }}
                              </q-td>
                            </q-tr>
                          </template>
                        </q-table>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:separator>
                <div
                  style="background-color: #f2f2f2"
                  class="row items-center justify-center"
                >
                  <div
                    :class="isLandscape ? 'vertSeparator' : 'horizSeparator'"
                    style=""
                  ></div>
                </div>
              </template>
              <template v-slot:after>
                <div class="row cellBackground">
                  <div class="col row">
                    <!-- 최근 1년동안 기종별 (우측 하단) -->
                    <div class="col column" style="">
                      <div
                        class="col-auto row items-center justify-between"
                        style="height: 42px"
                      >
                        <div class="col row items-center justify-start">
                          <div
                            class="col-auto"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;font-weight:bold;'
                                : 'font-family:Prometo;font-weight:500;'
                            "
                            style="font-size: 16px"
                          >
                            {{ $t("statEmergency.embyyear") }}
                          </div>
                          <q-separator
                            color="grey-7"
                            vertical
                            style="margin: 3px 3px 3px 6px"
                            v-show="latestTerm != ''"
                          ></q-separator>
                          <div
                            class="col-auto"
                            style="
                              font-family: Prometo;
                              font-size: 15px;
                              color: #eb0028;
                            "
                            v-show="latestTerm != ''"
                          >
                            &nbsp;{{ latestTerm }}
                          </div>
                        </div>
                        <div class="col-auto row items-center justify-center">
                          <div
                            class="col-auto"
                            :style="
                              isKorean
                                ? 'font-family:Noto Sans KR;'
                                : 'font-family:Prometo;'
                            "
                            style="font-size: 15px"
                            v-show="countOneYear > 0"
                          >
                            {{
                              $t("statEmergency.emergencyCount")
                            }}&nbsp;:&nbsp;{{ countOneYear }}
                          </div>
                        </div>
                      </div>
                      <div
                        id="yearModelContainer"
                        class="col row q-mt-xs q-pa-sm items-center justify-center"
                        style="
                          position: relative;
                          border: 1px solid #dddddd;
                          border-radius: 3px;
                        "
                      >
                        <q-scroll-area
                          class="col row items-center justify-center"
                          style="
                            position: absolute;
                            left: 6px;
                            top: 6px;
                            right: 6px;
                            bottom: 6px;
                            border-radius: 3px;
                          "
                        >
                          <div
                            class="col"
                            ref="refYearModel"
                            id="yearModelChart"
                          ></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeYearModel" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </q-splitter>
          </template>
        </q-splitter>
      </div>

      <div
        class="row items-center justify-center"
        v-show="loadingData"
        style="
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: 3px;
          background-color: #80808060;
        "
      >
        <q-spinner-tail dense color="red-7" size="100px" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.grpBtn {
  height: 40px;
  border-radius: 3px;
  border: 1px solid #cccccc;
}
.grpBtn:hover {
  height: 40px;
  border-radius: 3px;
  border: 1px solid black;
}
.grpBtn:active {
  height: 40px;
  border-radius: 3px;
  border: 2px solid #eb0028;
}
.cellBackground {
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #ebedf2;
  border-radius: 3px;
  padding: 12px;
}
.horizSeparator {
  width: 32px;
  height: 4px;
  background-color: #bbbbbb;
  border-radius: 3px;
}
.vertSeparator {
  width: 4px;
  height: 32px;
  background-color: #bbbbbb;
  border-radius: 3px;
}
.tableHeader {
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #e0e0e0;
}
</style>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTymictStore } from "@/store/tymict";
import TymCommon from "@/js/tymcommon.js";
import moment from "moment-timezone";
import TymAws from "@/js/tymaws.js";
import TymConst from "@/js/tymconstants.js";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue3-xlsx";

export default {
  props: {
    kind: ref(0),
  },
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {};
  },
  computed: {
    isLandscape: function () {
      const store = useTymictStore();
      return store.isLandscape;
    },
    isKorean() {
      const store = useTymictStore();
      return store.getLanguage() == "ko";
    },
    getCategory() {
      const store = useTymictStore();
      return store.getMachineTypes();
    },
    getEmTypes() {
      const i18n = useI18n();
      let types = [
        i18n.t("common.emtype"),
        i18n.t("common.rollover"),
        i18n.t("common.geofence"),
      ];
      return types;
    },
    getErroPart() {
      const i18n = useI18n();
      let result = [];
      result.push(i18n.t("common.all"));
      result = result.concat([
        i18n.t("TEXT_BROKENTYPE.1"),
        // i18n.t('TEXT_BROKENTYPE.2'),    // 20230612 PPT에는 없어
        i18n.t("TEXT_BROKENTYPE.3"),
        i18n.t("TEXT_BROKENTYPE.4"),
        i18n.t("TEXT_BROKENTYPE.5"),
      ]);
      return result;
    },
    getMonthTitle() {
      const i18n = useI18n();

      let result = [];
      result.push(i18n.t("common.month"));
      for (let i = 1; i <= 12; i++) {
        result.push(i18n.t("yearMonth." + i.toString()));
      }
      return result;
    },
    machineCount() {
      const store = useTymictStore();
      return store.machineLocaInfo.length;
    },
    canDownloadToExcel() {
      const store = useTymictStore();
      return store.canDownloadToExcel();
    },
  },
  setup() {
    const i18n = useI18n();

    return {
      horizSplitter: ref(50),
      upSplitter: ref(49.75),
      downSplitter: ref(49.75),

      loadingData: ref(false), // 데이터 로딩중
      groupPopupDisplayed: ref(false),

      selectedMonth: ref(i18n.t("common.month")),

      groupTreeData: ref([]),
      expandedTree: ref([]),
      selectedTree: ref(""),
      selectedTreeLabel: ref(""),

      catSelected: ref(i18n.t("common.type")),
      emTypeSelected: ref(i18n.t("common.emtype")),

      emergencyHistory: ref(null),
      dealerConvData: ref([]),
      dealerAxisData: ref([]),
      modelOptionAxisData: ref([]),

      titleForDealer: ref(""),
      termByDealer: ref(""),
      countByBranch: ref(0),

      chartForModel: ref(true),
      selectModelOptionTabs: ref([
        i18n.t("statEmergency.embymodel"),
        i18n.t("statEmergency.embyoption"),
      ]),
      selectedModelOptionTab: ref(i18n.t("statEmergency.embymodel")),
      countByModel: ref(0),

      tableForModel: ref(true),
      selectDealerModelOptionTabs: ref([
        i18n.t("statEmergency.embydealermodel"),
        i18n.t("statEmergency.embydealeroption"),
      ]),
      selectedDealerModelOptionTab: ref(
        i18n.t("statEmergency.embydealermodel")
      ),

      modelOptionData: ref([
        {
          x: [],
          y: [],
          type: "bar",
          textposition: "auto",
          hoverinfo: "none",
          marker: {
            color: "#eb0028",
            line: {
              color: "black",
              width: 0,
            },
          },
        },
      ]),

      yearlyLayout: ref({
        // title: 'Number of Graphs Made this Week',
        showlegend: false,
        dragmode: "orbit",
        barmode: "stack",
        bargap: 0.15,
        height: 240,
        margin: {
          l: 40,
          t: 5,
          r: 5,
          b: 35,
        },
      }),
      barChartConfig: ref({
        autosizable: true,
        displayModeBar: false,
        displaylogo: false,
        responsive: true,
        scrollZoom: false,
      }),

      monthlyCount: ref(0),
      yearsForMonth: ref([]),
      selectedYear: ref("" /*i18n.t('common.all')*/),
      validYears: ref([]),
      monthlyChart: ref(null),
      monthlyData: ref([
        {
          x: [],
          y: [],
          type: "bar",
          textposition: "auto",
          hoverinfo: "none",
          marker: {
            color: "#eb0028",
            line: {
              color: "black",
              width: 0,
            },
          },
        },
      ]),

      modelCount: ref(0),
      modelChart: ref(null),
      yearsForModel: ref([]),
      selectedModelYear: ref(null),
      modelData: ref([]),
      modelLayout: ref({
        // title: 'Number of Graphs Made this Week',
        showlegend: false,
        dragmode: "orbit",
        barmode: "stack",
        bargap: 0.15,
        width: 1800,
        height: 250,
        margin: {
          l: 40,
          t: 5,
          r: 5,
          b: 55,
        },
      }),

      yearModelLayout: ref({
        showlegend: true,
        dragmode: "orbit",
        barmode: "stack",
        bargap: 0.15,
        height: 240,
        automargin: true,
        margin: {
          l: 40,
          t: 5,
          r: 5,
          b: 25,
        },
      }),

      tableColumns: ref([]),
      tableVisibleColumns: ref([]),
      tableRows: ref([]),
      dealerModelOptionData: ref([]),

      yearlyStartDate: ref(null),
      yearlyEndDate: ref(null),
      latestTerm: ref(""),
      countOneYear: ref(0),

      sheets: ref([]),

      hasExcelData: ref(true),
      makingExcel: ref(true),
      excelFilename: ref(""),
      excelFrom: ref(""),
      excelTo: ref(""),
      excelDealer: ref(""),
      excelType: ref(""),

      yearlyData: ref([]),
      dealerData: ref([]),
      optionData: ref([]),
    };
  },
  created() {},
  mounted() {
    const store = useTymictStore();
    const now = new Date();

    let saved = sessionStorage.getItem("STAT.em.saved");
    if (!TymCommon.isEmpty(saved)) {
      saved = JSON.parse(saved);
      console.log("STAT.em.saved load :", saved);
      this.selectedYear = saved.Year;
      this.selectedMonth = saved.Month;
    } else {
      this.selectedYear = now.getFullYear().toString();
      if (now.getDate() == 1)
        this.selectedMonth = this.getMonthTitle[now.getMonth()];
      else this.selectedMonth = this.getMonthTitle[now.getMonth() + 1];
    }

    this.validYears = [];
    for (let year = now.getFullYear(); year >= 2021; year--) {
      this.validYears.push(year.toString());
    }

    this.titleForDealer = this.$t("statEmergency.embybranch");

    if (store.groupTree.length > 0) {
      this.groupTreeData = store.groupTree;

      if (!TymCommon.isEmpty(saved)) {
        if (!TymCommon.isEmpty(saved.Expanded))
          this.expandedTree = saved.Expanded;
        if (!TymCommon.isEmpty(saved.Selected))
          this.selectedTree = saved.Selected;
        this.selectedTreeLabel = saved.Group;
        this.catSelected = saved.Category;
        this.emTypeSelected = saved.Type;
      } else {
        this.expandedTree = [this.groupTreeData[0].uuid];
        this.selectedTree = this.groupTreeData[0].uuid;
        let result = store.findGroupNameByKey(this.selectedTree);
        if (result) {
          this.selectedTreeLabel = result;
        }
      }
    }

    this.updateDealerChart(true);
    this.updateModelOptionChart(true);
    this.updateDealerModelTable(true);
    this.updateYearModelChart(true);

    setTimeout(() => {
      // this.getHistoryData(true)
      this.getYearlyHistory();
    }, 5);
    setTimeout(() => {
      // this.getHistoryData(true)
      this.getHistoryData();
    }, 15);
  },
  unmounted() {
    sessionStorage.setItem(
      "STAT.em.saved",
      JSON.stringify({
        Expanded: this.expandedTree,
        Selected: this.selectedTree,
        Group: this.selectedTreeLabel,
        Year: this.selectedYear,
        Month: this.selectedMonth,
        Category: this.catSelected,
        Type: this.emTypeSelected,
      })
    );
  },
  methods: {
    unusedParam() {
      //
    },
    getSelectedMonth() {
      return this.getMonthTitle.findIndex((x) => x == this.selectedMonth);
    },
    resizeDealer(size) {
      this.unusedParam(size);
      setTimeout(() => {
        if (this.$refs.refDealer) {
          let container = document.getElementById("dealerContainer");
          if (!container) {
            return;
          }
          if (TymCommon.isEmpty(this.$refs.refDealer.data)) {
            //console.log('statEm.resizeDealer... EMPTY DATA')
            return;
          }

          let barCount = this.$refs.refDealer.data[0].x.length;
          let minWidth = barCount * 70;
          minWidth =
            minWidth >= container.clientWidth
              ? minWidth
              : container.clientWidth - 60;
          let maxWidth = barCount * 150;
          if (barCount <= 2) {
            minWidth += 160;
            maxWidth += 160;
          }
          if (minWidth > maxWidth) minWidth = maxWidth;

          window.Plotly.relayout(this.$refs.refDealer, {
            bargap: 0.15,
            width: minWidth,
            height: container.clientHeight - 18,
            margin: {
              l: 40,
              t: 10,
              r: 5,
              b: 65,
            },
          });
        }
      }, 1);
    },
    resizeModelOption(size) {
      this.unusedParam(size);
      setTimeout(() => {
        if (this.$refs.refDealer) {
          let container = document.getElementById("modelOptionContainer");
          if (!container) {
            return;
          }
          if (TymCommon.isEmpty(this.$refs.refModelOption.data)) {
            return;
          }
          if (this.$refs.refModelOption.data.length < 1) {
            return;
          }

          let barCount = this.$refs.refModelOption.data[0].x.length;
          let minWidth = barCount * 70;
          minWidth =
            minWidth >= container.clientWidth
              ? minWidth
              : container.clientWidth - 60;
          let maxWidth = barCount * 150;
          if (barCount <= 2) {
            minWidth += 160;
            maxWidth += 160;
          }
          if (minWidth > maxWidth) minWidth = maxWidth;

          window.Plotly.relayout(this.$refs.refModelOption, {
            bargap: 0.15,
            width: minWidth,
            height: container.clientHeight - 18,
            margin: {
              l: 40,
              t: 10,
              r: 5,
              b: 65,
            },
          });
        }
      }, 1);
    },
    resizeYearModel(size) {
      this.unusedParam(size);
      setTimeout(() => {
        if (this.$refs.refYearModel) {
          let container = document.getElementById("yearModelContainer");
          if (!container) {
            return;
          }
          if (TymCommon.isEmpty(this.$refs.refYearModel.data)) {
            return;
          }

          // let barCount = this.$refs.refModelOption.data[0].x.length
          let width = container.clientWidth < 520 ? 520 : container.clientWidth;
          let height =
            container.clientHeight < 240 ? 240 : container.clientHeight;

          width -= 30;
          height -= 15;

          window.Plotly.relayout(this.$refs.refYearModel, {
            width: width,
            height: height,
            margin: {
              l: 40,
              t: 10,
              r: 5,
              b: 65,
            },
          });
        }
      }, 1);
    },
    groupTreeSelected(tgt) {
      const store = useTymictStore();

      this.selectedTree = tgt;
      let result = store.findGroupNameByKey(this.selectedTree);
      if (result) {
        this.selectedTreeLabel = result;
      }
      this.$refs.groupPopup.hide();
    },
    clearGroupData() {
      const store = useTymictStore();

      this.selectedTree = this.groupTreeData[0].uuid;
      let result = store.findGroupNameByKey(this.selectedTree);
      if (result) {
        this.selectedTreeLabel = result;
      }
    },
    changedCategory(value, reason, details) {
      this.unusedParam(value, reason, details);
      // console.log('changedCategory :', value, reason, details)
    },
    changedErroPart(value, reason, details) {
      this.unusedParam(value, reason, details);
      // console.log('changedErroPart :', value, reason, details)
    },
    searchData() {
      this.getHistoryData();
    },
    changedModelOptionTab(value) {
      this.chartForModel = value == this.selectModelOptionTabs[0];
      if (!this.chartForModel) {
        this.selectedModelOption = this.selectModelOptions[0];
      }
      this.updateModelOptionChart(false, this.chartForModel);
    },
    changedDealerModelOptionTab(value) {
      this.tableForModel = value == this.selectDealerModelOptionTabs[0];
      if (!this.tableForModel) {
        this.selectedDealerModel = this.selectDealerModels[0];
      }
      console.log(
        "+++",
        this.tableForModel,
        value,
        this.selectDealerModelOptionTabs
      );
      this.updateDealerModelTable(false);
    },
    updateDealerChart(firstTime) {
      let dealerAxisData = [];

      if (
        this.selectedYear == this.$t("common.all") &&
        this.selectedMonth == this.$t("common.month")
      ) {
        this.termByDealer = this.$t("common.all");
      } else if (
        this.selectedYear == this.$t("common.all") &&
        this.selectedMonth != this.$t("common.month")
      ) {
        this.termByDealer = this.selectedMonth;
      } else if (
        this.selectedYear != this.$t("common.all") &&
        this.selectedMonth == this.$t("common.month")
      ) {
        this.termByDealer = this.selectedYear;
      } else if (
        this.selectedYear != this.$t("common.all") &&
        this.selectedMonth != this.$t("common.month")
      ) {
        let monIndex = this.getMonthTitle.indexOf(this.selectedMonth);
        let ymon = new Date(Number(this.selectedYear), monIndex - 1, 2, 12, 0);
        this.termByDealer = TymCommon.getMonthString(ymon);
      }

      this.countByBranch = 0;
      if (firstTime) {
        var value1 = {
          x: [],
          y: [],
          name: "Normal",
          type: "bar",
          text: [],
        };
        dealerAxisData = [value1];
        const div = document.getElementById("dealerChart");
        if (div)
          new window.Plotly.newPlot(
            div,
            dealerAxisData,
            this.yearlyLayout,
            this.barChartConfig
          );
      } else {
        // console.log('updateDealerChart :', this.dealerData)
        let xLab = [],
          yLab = [],
          textLab = [];
        for (let idx = 0; idx < this.dealerData.length; idx++) {
          xLab.push(this.dealerData[idx].Dealer);
          yLab.push(this.dealerData[idx].Count);
          this.countByBranch += this.dealerData[idx].Count;
        }
        textLab = yLab.map(String);

        dealerAxisData.push({
          x: xLab,
          y: yLab,
          text: textLab,
          hovermode: "closest",
          hovertemplate:
            '<span style="color:#404040;">%{x}</span> <span style="color:#eb0028;font-weight:bold;">%{text}</span> <extra></extra>',
          hoverlabel: {
            bgcolor: "white",
            bordercolor: "#eb0028",
          },
          type: "bar",
          marker: {
            color: "#eb0028",
            line: {
              color: "black",
              width: 0,
            },
          },
        });

        window.Plotly.react(
          "dealerChart",
          dealerAxisData,
          this.yearlyLayout,
          this.barChartConfig
        );
        this.resizeDealer(null);
      }
    },

    changedSelectedModel(value) {
      this.unusedParam(value);
      this.updateModelOptionChart(false, false);
    },
    updateModelOptionChart(firstTime, isModel) {
      this.countByModel = 0;
      let xLab = [],
        yLab = [];
      if (firstTime) {
        xLab.push("");
        yLab.push();
        this.modelOptionData[0].x = xLab.map(String);
        this.modelOptionData[0].y = yLab;
        this.modelOptionData[0].text = yLab.map(String);
        const div = document.getElementById("modelOptionChart");
        if (div)
          new window.Plotly.newPlot(
            div,
            this.modelOptionData,
            this.yearlyLayout,
            this.barChartConfig
          );
      } else {
        let chartData = null;
        if (isModel) {
          chartData = this.modelData;
        } else {
          chartData = [];
          this.modelData.forEach((model) => {
            model.Options.forEach((option) => {
              let name = model.Model;
              if (option.Option != "0" && option.Option != "NONE") {
                name += option.Option;
              }
              if (this.selectedModelOption == this.selectModelOptions[0]) {
                let found = chartData.find((x) => x.Model == name);
                if (found) {
                  found.Count += option.Count;
                } else {
                  chartData.push({
                    Model: name,
                    Count: option.Count,
                  });
                }
              } else {
                if (this.selectedModelOption == model.Model) {
                  let found = chartData.find((x) => x.Model == name);
                  if (found) {
                    found.Count += option.Count;
                  } else {
                    chartData.push({
                      Model: name,
                      Count: option.Count,
                    });
                  }
                }
              }
            });
          });
        }

        // console.log('+++', chartData)
        if (!chartData) return;

        let xLab = [],
          yLab = [],
          textLab = [];
        for (let idx = 0; idx < chartData.length; idx++) {
          if (chartData[idx].Count > 0) {
            xLab.push(chartData[idx].Model);
            yLab.push(chartData[idx].Count);
          }
        }
        textLab = yLab.map(String);

        this.modelOptionAxisData[0] = {
          x: xLab,
          y: yLab,
          text: textLab,
          hovermode: "closest",
          hovertemplate:
            '<span style="color:#404040;">%{x}</span> <span style="color:#eb0028;font-weight:bold;">%{text}</span> <extra></extra>',
          hoverlabel: {
            bgcolor: "white",
            bordercolor: "#eb0028",
          },
          type: "bar",
          marker: {
            color: "#eb0028",
            line: {
              color: "black",
              width: 0,
            },
          },
        };

        window.Plotly.react(
          "modelOptionChart",
          this.modelOptionAxisData,
          this.yearlyLayout,
          this.barChartConfig
        );
        this.resizeModelOption(null);
      }
    },
    changedDealerModel(value) {
      this.unusedParam(value);
      this.updateDealerModelTable(false);
    },
    // 테이블 업데이트
    updateDealerModelTable(firstTime) {
      this.tableColumns = [];
      this.tableRows = [];

      if (firstTime || this.dealerModelOptionData.length < 1) {
        //
      } else {
        if (this.dealerModelOptionData.length < 1) return;

        // 첫번째 컬럼(인덱스로 쓰자)
        this.tableColumns.push({
          name: "index",
          required: false,
        });

        //console.log('updateDealerModelTable START ', this.dealerModelOptionData)

        let isAllOptions = true;
        let srcData = [];
        if (this.tableForModel) {
          this.dealerModelOptionData[0].Models.forEach((model) => {
            srcData.push(model.Model);
          });
        } else {
          isAllOptions = this.selectedDealerModel == this.selectDealerModels[0];

          //console.log('updateDealerModelTable OPTION :', isAllOptions, this.selectedDealerModel, this.selectDealerModels)
          if (isAllOptions) {
            // 전체
            this.dealerModelOptionData[0].FullModels.forEach((model) => {
              srcData.push(model.Model);
            });
          } else {
            this.dealerModelOptionData[0].Models.forEach((model) => {
              if (model.Model == this.selectedDealerModel) {
                model.Options.forEach((opt) => {
                  srcData.push(opt);
                });
              }
            });
          }
        }

        // 컬럼 헤더 부분(맨 윗부분)
        // 첫컬럼은 비워두자... 딜러명 표시컬럼
        let colCount = srcData.length;
        this.tableColumns.push({
          name: "col0",
          field: "col0",
          label: "",
          align: "left",
          required: false,
          sortable: false,
        });
        this.tableVisibleColumns.push("col0");

        //console.log('updateDealerModelTable :', colCount, this.dealerModelOptionData, srcData)

        // 기종과 모델별로 표시되는 컬럼들
        let idx = 0;
        for (idx = 0; idx < srcData.length; idx++) {
          let model = srcData[idx];
          if (!this.tableForModel && !isAllOptions) {
            model = this.selectedDealerModel;
            if (srcData[idx].Option != "0" && srcData[idx].Option != "NONE")
              model += srcData[idx].Option;
          }
          this.tableColumns.push({
            name: "col" + (idx + 1).toString(),
            field: "col" + (idx + 1).toString(),
            label: model,
            align: "center",
            required: false,
            sortable: false,
          });
          this.tableVisibleColumns.push("col" + (idx + 1).toString());
        }

        // 변수 재활용하니 유의바람
        // 마지막 컬럼에 합계 추가
        this.tableColumns.push({
          name: "col" + (idx + 1).toString(),
          field: "col" + (idx + 1).toString(),
          label: this.$t("common.sum"),
          align: "center",
          required: false,
          sortable: false,
        });
        this.tableVisibleColumns.push("col" + (idx + 1).toString());

        let row = 0,
          col = 0;
        let modelTotal = []; // 기종/모델별 합계
        // console.log('updateDealerModelTable :', this.dealerModelOptionData)
        for (row = 0; row < this.dealerModelOptionData.length; row++) {
          let oneRow = [];
          oneRow["index"] = row;
          let item = this.dealerModelOptionData[row];
          oneRow["col0"] = item.Dealer;
          if (!this.tableForModel && !isAllOptions) {
            let opt = this.dealerModelOptionData[row].Models.find(
              (x) => x.Model == this.selectedDealerModel
            );
            item = opt;
          }
          let dealerTotal = 0; // 한 지역 합계용
          for (col = 0; col < colCount; col++) {
            if (isNaN(modelTotal[col])) modelTotal[col] = 0;

            if (this.tableForModel) {
              let value = isNaN(item.Models[col].Count)
                ? 0
                : item.Models[col].Count;
              oneRow["col" + (col + 1).toString()] = value;

              dealerTotal += value;
              modelTotal[col] += value;
            } else {
              if (isAllOptions) {
                let value = isNaN(item.FullModels[col].Count)
                  ? 0
                  : item.FullModels[col].Count;
                oneRow["col" + (col + 1).toString()] = value;

                dealerTotal += value;
                modelTotal[col] += value;
              } else {
                let value = isNaN(item.Options[col].Count)
                  ? 0
                  : item.Options[col].Count;
                oneRow["col" + (col + 1).toString()] = value;

                dealerTotal += value;
                modelTotal[col] += value;
              }
            }
          }
          // 우측끝에 합계 추가
          oneRow["col" + (col + 1).toString()] = dealerTotal;
          this.tableRows.push(oneRow);

          // 모델별합계???
          if (isNaN(modelTotal[col])) modelTotal[col] = 0;
          modelTotal[col] += dealerTotal;
        }

        // 마지막 Row에 합계 추가
        let oneRow = {
          index: row,
          col0: this.$t("common.sum"),
        };
        for (col = 0; col <= colCount; col++) {
          oneRow["col" + (col + 1).toString()] = modelTotal[col];
        }

        // console.log(oneRow)
        this.tableRows.push(oneRow);
      }
    },
    updateYearModelChart(firstTime) {
      let yearModelAxisData = [];

      this.countOneYear = 0;
      if (firstTime) {
        let xLab = [],
          yLab = [];
        yearModelAxisData.push({
          x: xLab.map(String),
          y: yLab,
          text: yLab.map(String),
        });
        this.yearModelLayout.width = xLab.length * 50;

        new window.Plotly.newPlot(
          "yearModelChart",
          yearModelAxisData,
          this.yearModelLayout,
          this.barChartConfig
        );
      } else {
        if (this.yearlyData.length < 1) return;

        // console.log('updateYearModelChart :', this.yearlyData)
        let yLab = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let model = 0,
          month = 0;
        let titles = [],
          tooltips = [];
        for (model = 0; model < this.yearlyData[0].Models.length; model++) {
          titles = [];
          tooltips = [];
          for (month = 0; month < this.yearlyData.length; month++) {
            yLab[month] = this.yearlyData[month].Models[model].Count;
            titles.push(this.yearlyData[month].Title);
            tooltips.push(
              this.yearlyData[month].Title +
                ", " +
                this.yearlyData[month].Models[model].Name +
                " : " +
                this.yearlyData[month].Models[model].Count
            );
            if (model == 0) {
              this.countOneYear += parseInt(this.yearlyData[month].Count);
            }
          }

          let axis = {
            x: titles.map(String),
            y: yLab.map(String),
            mode: "lines+markers",
            type: "scatters",
            marker: {
              size: 7.5,
            },
            // hovertemplate: '<i>%{text}</i>',
            hoverinfo: "text",
            name: this.yearlyData[0].Models[model].Name,
            text: tooltips,
          };
          yearModelAxisData.push(axis);
        }

        const div = document.getElementById("yearModelChart");
        if (div) {
          this.yearModelLayout.width =
            div.offsetWidth > 12 * 60 ? div.offsetWidth : 12 * 60;
          this.yearModelLayout.hovermode = "closest";
          this.yearModelLayout.hoverlabel = { bgcolor: "#FFF" };
          window.Plotly.react(
            div,
            yearModelAxisData,
            this.yearModelLayout,
            this.barChartConfig
          );
        }
        this.resizeYearModel(null);
      }
    },

    onShowExportToExcel() {
      this.makingExcel = true;

      let now = new Date();
      this.excelFilename =
        "Emergency_" +
        now.getFullYear().toString() +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        now.getDate().toString().padStart(2, "0");
      this.excelFilename +=
        "_" +
        now.getHours().toString().padStart(2, "0") +
        now.getMinutes().toString().padStart(2, "0") +
        now.getSeconds().toString().padStart(2, "0");
      this.excelFilename += ".xlsx";
      let monIndex = this.getMonthTitle.indexOf(this.selectedMonth);
      if (monIndex == 0) {
        let from = new Date(this.selectedYear, 0, 1);
        this.excelFrom = TymCommon.getDateString(from);
        if (now.getFullYear().toString() == this.selectedYear) {
          let dst = new Date();
          // dst = new Date(dst.setDate(dst.getDate()));
          this.excelTo = TymCommon.getDateString(dst);
        } else {
          this.excelTo = TymCommon.getDateString(
            new Date(this.selectedYear, 11, 31)
          );
        }
      } else {
        let from = new Date(this.selectedYear, monIndex - 1, 1);
        this.excelFrom = TymCommon.getDateString(from);
        let dst = null;
        if (
          now.getFullYear() == from.getFullYear() &&
          now.getMonth() == from.getMonth()
        ) {
          dst = new Date();
          dst = new Date(dst.setDate(dst.getDate()));
        } else {
          dst = new Date(from.setMonth(from.getMonth() + 1));
          dst = new Date(dst.setDate(dst.getDate()));
          dst -= 24 * 60 * 60
        }
        this.excelTo = TymCommon.getDateString(dst);
      }
      this.excelDealer = this.selectedTreeLabel;
      this.excelType =
        this.catSelected == this.getCategory[0]
          ? this.$t("common.all")
          : this.catSelected;

      setTimeout(() => {
        try {
          this.sheets = [];
          this.makeExcelData2();
        } catch (ex) {
          console.log(ex);
          this.makingExcel = false;
        }
      }, 1);
    },
    makeExcelData2() {
      const store = useTymictStore();
      let group = store.groupInfo.find((x) => x.uuid == this.selectedTree);
      let groups = store.getSubGroups(this.selectedTree);
      if (TymCommon.isEmpty(groups)) return;

      let today = moment();
      let year = parseInt(this.selectedYear);
      let monIndex = this.getMonthTitle.indexOf(this.selectedMonth);
      let startDay = null,
        endDay = null;

      if(monIndex == 0) {
        startDay = year.toString() + "-01-01 00:00:00"
        endDay =  (year + 1).toString() + "-01-01 00:00:00"
      } else {
        startDay = year.toString() + "-" + monIndex.toString().padStart(2, '0') + "-01 00:00:00"
        endDay = year.toString() + "-" + (monIndex + 1).toString().padStart(2, '0') + "-01 00:00:00"
      }

      let startResult = TymCommon.localTimeToUTC(startDay, "YYYY-MM-DD HH:mm:ss" );
      let endResult = TymCommon.localTimeToUTC(endDay, "YYYY-MM-DD HH:mm:ss");

      if (endResult.dateTime > today) {
        endResult = TymCommon.localTimeToUTC(today.format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss" );
      }
      startDay = startResult.dateTimeStr;
      endDay = endResult.dateTimeStr;

      setTimeout(() => {
        TymAws.getEmergencyHistoryMARIA( group.uuid_a, group.uuid_b, group.uuid_c, group.uuid_d, startDay, endDay, true, false)
          .then((data) => {
            if (data == "error" || data.length < 1) {
              this.makingExcel = false;
              this.hasExcelData = false;
              setTimeout(() => {
                if (this.$refs.excelPopup) this.$refs.excelPopup.hide();
              }, 2000);
              return;
            }
            this.hasExcelData = true;
            /*
            {
              "M_model": "TS130",
              "M_option": "CP6A",
              "MN": "SOLVINE_A3_19",
              "type": "트랙터",
              "ETYPE": 1,
              "ENAME": [
                "차량 전복",
                "Rollover"
              ],
              "OTime": "2023-04-26T14:18:00.000Z",
              "RTime": "2023-04-26T14:18:00.000Z",
              "VUT": null,
              "UName": "솔바인",
              "UPhone": "010-2373-2817",
              "MName": [
                "TYM",
                "TYMICT",
                "",
                ""
              ],
              "MPhone": "041-851-7700",
              "RESOLVER": "자동해결"
            }
            */

            // 목록 엑셀 만들기
            let xlsRow1 = [];
            // 20240117 - 일단 해결시 사용시간 제거함(같은 값은 표기하지 않는게 낫다.)
            xlsRow1.push(
              "No",
              "기종",
              "모델명",
              "기대번호",
              "유형",
              "발생 일시",
              "발생 시 사용시간(h)",
              "해결 일시", // '해결 시 사용시간(h)',
              "고객명",
              "고객 연락처",
              "판매 지역",
              "판매점",
              "판매점 연락처",
              "담당자"
            );
            let rowNo = 1;
            let othRows = [];

            let modelsInfo = [];
            // 최근 해결일 역순(최근게 위로)
            data.sort((a, b) => {
              return b.RTime.localeCompare(a.RTime);
            });

            if (TymConst.IS_DEVELOPMENT) {
              console.log("StatEmergency(EXCEL) :", startDay, endDay, data);
            }

            data.forEach((one) => {
              if (this.catSelected != this.getCategory[0]) {
                if (this.catSelected != one.type) return;
              }

              let oTime = TymCommon.convertStringToDateTime3(one.OTime);
              let rTime = TymCommon.convertStringToDateTime3(one.RTime);

              let modelFullname = one.M_model;
              if (one.M_option != "0" && one.M_option != "NONE") {
                modelFullname += one.M_option;
              }

              // 모델명 추출하기
              if (!modelsInfo.find((x) => x == modelFullname)) {
                modelsInfo.push(modelFullname);
              }

              let branchName = "";
              let dealerName = "";
              if (!TymCommon.isEmpty(one.MName[3])) {
                dealerName = one.MName[3];
                branchName = one.MName[2];
              } else if (!TymCommon.isEmpty(one.MName[2])) {
                dealerName = one.MName[2];
                branchName = one.MName[1];
              } else if (!TymCommon.isEmpty(one.MName[1])) {
                dealerName = one.MName[1];
                branchName = one.MName[0];
              } else {
                dealerName = one.MName[0];
              }
              /*
              switch(groups.Depth) {
                case 0:
                  branchName = TymCommon.isEmpty(one.MName[0]) ? '' : one.MName[0]
                  dealerName = TymCommon.isEmpty(one.MName[1]) ? '' : one.MName[1]
                  break;
                case 1:
                  branchName = TymCommon.isEmpty(one.MName[1]) ? '' : one.MName[1]
                  dealerName = TymCommon.isEmpty(one.MName[2]) ? '' : one.MName[2]
                  break;
                case 2:
                  branchName = TymCommon.isEmpty(one.MName[2]) ? '' : one.MName[2]
                  dealerName = TymCommon.isEmpty(one.MName[3]) ? '' : one.MName[3]
                  break;
                case 3:
                  dealerName = branchName = TymCommon.isEmpty(one.MName[3]) ? '' : one.MName[3]
                  break;
              }
              */
              // 20240117 - 일단 해결시 사용시간 제거함(같은 값은 표기하지 않는게 낫다.)
              othRows.push([
                rowNo.toString(),
                one.M_model,
                modelFullname,
                one.MN,
                one.ENAME[store.connectServer],
                oTime,
                one.VUT,
                rTime, // one.VUT,
                one.UName,
                one.UPhone,
                branchName,
                dealerName,
                one.MPhone,
                one.RESOLVER,
              ]);
              rowNo++;
            });
            othRows.splice(0, 0, xlsRow1);
            // console.log('makeExcel (xls list) :', othRows)

            let from = new Date(this.excelFrom);
            let to = new Date(this.excelTo);

            let infoRow = [];
            infoRow.push(
              "통계(긴급 알림) - 조회기간 : " +
                from.getFullYear() +
                "." +
                (from.getMonth() + 1).toString().padStart(2, "0") +
                "." +
                from.getDate().toString().padStart(2, "0") +
                "-" +
                to.getFullYear() +
                "." +
                (to.getMonth() + 1).toString().padStart(2, "0") +
                "." +
                to.getDate().toString().padStart(2, "0")
            );
            othRows.splice(0, 0, infoRow);

            //console.log('makeExcel (xls list) :', from, to, othRows)
            this.sheets.push({
              name:
                "List " +
                from.getFullYear() +
                (from.getMonth() + 1).toString().padStart(2, "0") +
                from.getDate().toString().padStart(2, "0") +
                " - " +
                to.getFullYear() +
                (to.getMonth() + 1).toString().padStart(2, "0") +
                to.getDate().toString().padStart(2, "0"),
              data: othRows,
            });

            // 나머지 시트 작성용 정리
            let byDealer = [];
            let byDealerRollover = [];
            let byDealerGeofence = [];

            groups.Names.forEach((grp) => {
              let model1 = [],
                model2 = [];
              modelsInfo.forEach((mdl) => {
                model1.push({
                  Name: mdl,
                  Count: 0,
                });
                model2.push({
                  Name: mdl,
                  Count: 0,
                });
              });
              byDealer.push({
                Dealer: grp,
                Rollover: 0,
                Geofence: 0,
              });
              byDealerRollover.push({
                Dealer: grp,
                Models: model1,
              });
              byDealerGeofence.push({
                Dealer: grp,
                Models: model2,
              });
            });

            data.forEach((one) => {
              if (this.catSelected != this.getCategory[0]) {
                if (this.catSelected != one.type) return;
              }

              let dealerName = "";
              switch (groups.Depth) {
                case 0:
                  if (TymCommon.isEmpty(one.MName[1]))
                    dealerName = one.MName[0];
                  else dealerName = one.MName[1];
                  break;
                case 1:
                  if (TymCommon.isEmpty(one.MName[2]))
                    dealerName = one.MName[1];
                  else dealerName = one.MName[2];
                  break;
                case 2:
                  if (TymCommon.isEmpty(one.MName[3]))
                    dealerName = one.MName[2];
                  else dealerName = one.MName[3];
                  break;
                case 3:
                  dealerName = one.MName[4];
                  break;
              }

              let modelFullname = one.M_model;
              if (one.M_option != "0" && one.M_option != "NONE") {
                modelFullname += one.M_option;
              }

              let found = byDealer.find((x) => x.Dealer == dealerName);
              if (found) {
                found.Rollover += parseInt(one.ETYPE) == 1 ? 1 : 0;
                found.Geofence += parseInt(one.ETYPE) == 2 ? 1 : 0;
              }

              if (parseInt(one.ETYPE) == 1) {
                found = byDealerRollover.find((x) => x.Dealer == dealerName);
                if (found) {
                  let md = found.Models.find((x) => x.Name == modelFullname);
                  if (md) {
                    md.Count += 1;
                  } else {
                    found.Models.push({
                      Name: modelFullname,
                      Count: 1,
                    });
                  }
                }
              } else {
                found = byDealerGeofence.find((x) => x.Dealer == dealerName);
                if (found) {
                  let md = found.Models.find((x) => x.Name == modelFullname);
                  if (md) {
                    md.Count += 1;
                  } else {
                    if (dealerName == "TYMICT") {
                      console.log(modelFullname);
                    }
                    found.Models.push({
                      Name: modelFullname,
                      Count: 1,
                    });
                  }
                }
              }
            });

            let rowTotal = 0;
            let oneRow = [];
            // 지역별 발생건수
            othRows = [];
            xlsRow1 = [
              "판매 지역",
              this.$t("constants.emergency_type2"),
              this.$t("constants.emergency_type3"),
              "총 처리 건수",
            ];
            let colTotal = [0, 0];
            byDealer.forEach((dealer) => {
              oneRow = [dealer.Dealer, dealer.Rollover, dealer.Geofence];
              rowTotal = dealer.Rollover + dealer.Geofence;
              colTotal[0] += dealer.Rollover;
              colTotal[1] += dealer.Geofence;
              oneRow.push(rowTotal);
              othRows.push(oneRow);
            });

            oneRow = [
              "합계",
              colTotal[0],
              colTotal[1],
              colTotal[0] + colTotal[1],
            ];
            othRows.push(oneRow);

            othRows.splice(0, 0, xlsRow1);

            this.sheets.push({
              name:
                "지역별 발생건수 " +
                (from.getMonth() + 1).toString().padStart(2, "0") +
                from.getDate().toString().padStart(2, "0") +
                " - " +
                (to.getMonth() + 1).toString().padStart(2, "0") +
                to.getDate().toString().padStart(2, "0"),
              data: othRows,
            });

            // Rollover
            colTotal = [];
            othRows = [];
            xlsRow1 = ["구분"];
            byDealerRollover[0].Models.forEach((mdl) => {
              xlsRow1.push(mdl.Name);
              colTotal.push(0);
            });
            xlsRow1.push("총 처리 건수");

            byDealerRollover.forEach((dealer) => {
              rowTotal = 0;
              oneRow = [dealer.Dealer];
              for (let idx = 0; idx < dealer.Models.length; idx++) {
                oneRow.push(dealer.Models[idx].Count);
                rowTotal += dealer.Models[idx].Count;
                colTotal[idx] += dealer.Models[idx].Count;
              }
              oneRow.push(rowTotal);
              othRows.push(oneRow);
            });
            oneRow = ["합계"];
            rowTotal = 0;
            colTotal.forEach((value) => {
              oneRow.push(value);
              rowTotal += value;
            });
            oneRow.push(rowTotal);
            othRows.push(oneRow);

            othRows.splice(0, 0, xlsRow1);

            this.sheets.push({
              name:
                this.$t("constants.emergency_type2") +
                " " +
                (from.getMonth() + 1).toString().padStart(2, "0") +
                from.getDate().toString().padStart(2, "0") +
                " - " +
                (to.getMonth() + 1).toString().padStart(2, "0") +
                to.getDate().toString().padStart(2, "0"),
              data: othRows,
            });

            // Geofence
            colTotal = [];
            othRows = [];
            xlsRow1 = ["구분"];
            byDealerGeofence[0].Models.forEach((mdl) => {
              xlsRow1.push(mdl.Name);
              colTotal.push(0);
            });
            xlsRow1.push("총 처리 건수");

            byDealerGeofence.forEach((dealer) => {
              rowTotal = 0;
              oneRow = [dealer.Dealer];
              for (let idx = 0; idx < dealer.Models.length; idx++) {
                oneRow.push(dealer.Models[idx].Count);
                rowTotal += dealer.Models[idx].Count;
                colTotal[idx] += dealer.Models[idx].Count;
              }
              oneRow.push(rowTotal);
              othRows.push(oneRow);
            });
            oneRow = ["합계"];
            rowTotal = 0;
            colTotal.forEach((value) => {
              oneRow.push(value);
              rowTotal += value;
            });
            oneRow.push(rowTotal);
            othRows.push(oneRow);

            othRows.splice(0, 0, xlsRow1);

            this.sheets.push({
              name:
                this.$t("constants.emergency_type3") +
                " " +
                (from.getMonth() + 1).toString().padStart(2, "0") +
                from.getDate().toString().padStart(2, "0") +
                " - " +
                (to.getMonth() + 1).toString().padStart(2, "0") +
                to.getDate().toString().padStart(2, "0"),
              data: othRows,
            });

            this.makingExcel = false;
          })
          .catch((er) => {
            console.log("ExportExcel FAIL", er);
            this.hasExcelData = false;
            this.makingExcel = false;
            setTimeout(() => {
              setTimeout(() => {
                if (this.$refs.excelPopup) this.$refs.excelPopup.hide();
              }, 2000);
            }, 5);
          });
      }, 1);
    },
    saveToExcelFile() {
      let desc = {
        filename: this.excelFilename,
        start: this.excelFrom,
        end: this.excelTo,
        dealer: this.excelDealer,
        type: this.excelType,
      };
      TymAws.writeAction("통계", "긴급알림", desc);

      setTimeout(() => {
        if (this.$refs.excelPopup) this.$refs.excelPopup.hide();
      }, 500);
    },
    getYearlyHistory() {
      const store = useTymictStore();
      let group = store.groupInfo.find((x) => x.uuid == this.selectedTree);
      if (TymCommon.isEmpty(group)) return;

      let today = moment();
      let startDay = null,
        endDay = null;

      startDay =
        (today.year() - 1).toString() +
        "-" +
        (today.month() + 2).toString().padStart(2, "0") +
        "-" +
        today.date().toString().padStart(2, "0") +
        " 00:00:00";
      endDay =
        today.year().toString() +
        "-" +
        (today.month() + 1).toString().padStart(2, "0") +
        "-" +
        today.date().toString().padStart(2, "0") +
        // + " 00:00:00"
        " " +
        today.hour().toString().padStart(2, "0") +
        ":" +
        today.minute().toString().padStart(2, "0") +
        ":" +
        today.second().toString().padStart(2, "0");
      let tgtRange = TymCommon.makeDateTimeRange(
        "2020-01-01 00:00:00",
        startDay,
        endDay
      );
      this.latestTerm =
        TymCommon.getMonthString(tgtRange.sDate.toDate()) +
        " - " +
        TymCommon.getMonthString(tgtRange.eDate.toDate());

      setTimeout(() => {
        this.yearlyData = [];
        TymAws.getEmergencyHistoryMARIA(
          group.uuid_a,
          group.uuid_b,
          group.uuid_c,
          group.uuid_d,
          tgtRange.sDateStr,
          tgtRange.eDateStr,
          false,
          true
        )
          .then((data) => {
            if (TymConst.IS_DEVELOPMENT) {
              console.log(
                "StatEmergency.getYearlyHistory :",
                tgtRange.sDateStr,
                tgtRange.eDateStr,
                data
              );
            }
            /*
            {
              "YearMonth": "2022-12",
              "M_model": "TX76",
              "Cnt": "1"
            } 
            */
            // let total = 0
            // 모델 추출
            let models = [];
            data.forEach((one) => {
              let found = models.find((x) => x.Name == one.M_model);
              if (!found) {
                models.push({
                  Name: one.M_model,
                  Count: 0,
                });
              }
            });
            // console.log('Models :', JSON.parse(JSON.stringify(models)))

            data.forEach((one) => {
              let month = moment(one.YearMonth);
              let title = TymCommon.getMonthString(month.toDate());
              let model = one.M_model;
              let count = parseInt(one.Cnt);

              let found = this.yearlyData.find((x) => x.Title == title);
              if (found) {
                // 월 데이터가 존재하네
                found.Count += count;
                let foundModel = found.Models.find((x) => x.Name == model);
                if (foundModel) {
                  foundModel.Count += count;
                }
              } else {
                // 해당 월 최초 데이터
                let oneMonth = {
                  Title: title,
                  Month: one.YearMonth,
                  Count: count,
                  Models: [],
                };
                models.forEach((mod) => {
                  oneMonth.Models.push({
                    Name: mod.Name,
                    Count: model == mod.Name ? count : 0,
                  });
                });
                this.yearlyData.push(oneMonth);
              }
              // total += parseInt(count)
            });
            // console.log('FINAL :', this.yearlyData)
            this.updateYearModelChart(false);
          })
          .catch((er) => {
            console.log("StatEmergency.getEmergencyHistoryMARIA ERROR :", er);
          });
      }, 1);
    },
    getHistoryData() {
      const store = useTymictStore();
      if (TymCommon.isEmpty(store.idToken["custom:uuid_a"])) return;

      let group = store.groupInfo.find((x) => x.uuid == this.selectedTree);
      if (TymCommon.isEmpty(group)) return;

      let groups = store.getSubGroups(this.selectedTree);
      if (TymCommon.isEmpty(groups)) return;
      // console.log('getUsageData() :', groups)
      this.loadingData = true;

      setTimeout(() => {
        let today = moment();
        let year = parseInt(this.selectedYear);
        let monIndex = this.getMonthTitle.indexOf(this.selectedMonth);
        let startDay = null,
          endDay = null;

        if (monIndex == 0) {
          startDay = year.toString() + "-01-01 00:00:00";
          endDay = (year + 1).toString() + "-01-01 00:00:00";
        } else {
          startDay =
            year.toString() +
            "-" +
            monIndex.toString().padStart(2, "0") +
            "-01 00:00:00";
          endDay =
            year.toString() +
            "-" +
            (monIndex + 1).toString().padStart(2, "0") +
            "-01 00:00:00";
        }
        let startResult = TymCommon.localTimeToUTC(
          startDay,
          "YYYY-MM-DD HH:mm:ss"
        );
        let endResult = TymCommon.localTimeToUTC(endDay, "YYYY-MM-DD HH:mm:ss");

        if (endResult.dateTime > today) {
          endDay = TymCommon.localTimeToUTC(
            today.format("YYYY-MM-DD HH:mm:ss"),
            "YYYY-MM-DD HH:mm:ss"
          ).dateTimeStr;
        } else {
          endDay = endResult.dateTimeStr;
        }
        startDay = startResult.dateTimeStr;

        this.dealerData = [];
        this.dealerModelOptionData = [];
        groups.Names.forEach((name) => {
          this.dealerData.push({
            Dealer: name,
            Count: 0,
          });
        });

        switch (groups.Depth) {
          case 0:
            this.titleForLocalHours = this.$t("statEngineHour.hbybranch");
            break;
          case 1:
            this.titleForLocalHours = this.$t("statEngineHour.hbybranch");
            break;
          case 2:
            this.titleForLocalHours = this.$t("statEngineHour.hbybranch");
            break;
          case 3:
            this.titleForLocalHours = this.$t("statEngineHour.hbydealer");
            break;
        }

        this.modelData = [];
        this.optionData = [];

        TymAws.getEmergencyHistoryMARIA(
          group.uuid_a,
          group.uuid_b,
          group.uuid_c,
          group.uuid_d,
          startDay,
          endDay,
          false
        )
          .then((data) => {
            if (data == "error" || data.length < 1) {
              this.updateDealerChart(true);
              this.updateModelOptionChart(true, true);
              this.updateDealerModelTable(true);

              this.loadingData = false;
              return;
            }
            if (TymConst.IS_DEVELOPMENT) {
              console.log(
                "getEmergencyHistoryMARIA(TERM) :",
                startDay,
                endDay,
                data
              );
            }

            data.forEach((one) => {
              /*
              {
                "M_model": "K110E",
                "M_option": "NONE",
                "ETYPE": 1,
                "ENAME": [
                  "차량 전복",
                  "Rollover"
                ],
                "group_a": "TYM",
                "group_b": "국내영업본부",
                "group_c": "충북지점",
                "group_d": "충북직영점",
                "type": "트랙터",
                "Cnt": "3"
              }
              */
              // 기대 종류 필터
              if (this.catSelected != this.getCategory[0]) {
                if (one.type != this.catSelected) return;
              }
              // 고장 타입 필터
              switch (this.emTypeSelected) {
                case this.getEmTypes[0]:
                  break;
                case this.getEmTypes[1]:
                  if (one.ETYPE != 1) return;
                  break;
                case this.getEmTypes[2]:
                  if (one.ETYPE != 2) return;
                  break;
              }

              // dealer 찾기
              let dealerName = "";
              switch (groups.Depth) {
                case 0:
                  if (TymCommon.isEmpty(one.group_b)) dealerName = one.group_a;
                  else dealerName = one.group_b;
                  break;
                case 1:
                  if (TymCommon.isEmpty(one.group_c)) dealerName = one.group_b;
                  else dealerName = one.group_c;
                  break;
                case 2:
                  if (TymCommon.isEmpty(one.group_d)) dealerName = one.group_c;
                  else dealerName = one.group_d;
                  break;
                case 3:
                  dealerName = one.group_d;
                  break;
              }

              let count = parseInt(one.Cnt);

              let dFound = this.dealerData.find((x) => x.Dealer == dealerName);
              if (dFound) {
                dFound.Count += count;
              } else {
                this.dealerData.push({
                  Dealer: dealerName,
                  Count: count,
                });
              }

              let mFound = this.modelData.find((x) => x.Model == one.M_model);
              if (mFound) {
                mFound.Count += count;
                let oFound = mFound.Options.find(
                  (x) => x.Option == one.M_option
                );
                if (oFound) {
                  oFound.Count += count;
                } else {
                  mFound.Options.push({
                    Option: one.M_option,
                    Count: count,
                  });
                }
              } else {
                let oneModel = {
                  Model: one.M_model,
                  Count: count,
                  Options: [
                    {
                      Option: one.M_option,
                      Count: count,
                    },
                  ],
                };
                this.modelData.push(oneModel);
              }
            });

            // 테이블 표시를 위한 카운트가 모두 0인 데이터 만든다.
            groups.Names.forEach((name) => {
              let models = [];
              let fullModels = [];

              this.modelData.forEach((model) => {
                let options = [];
                model.Options.forEach((opt) => {
                  options.push({
                    Option: opt.Option,
                    Count: 0,
                  });
                  let fullName = model.Model;
                  if (opt.Option != "0" && opt.Option != "NONE") {
                    fullName += opt.Option;
                  }
                  if (!fullModels.find((x) => x == fullName))
                    fullModels.push({
                      Model: fullName,
                      Count: 0,
                    });
                });
                models.push({
                  Model: model.Model,
                  Count: 0,
                  Options: options,
                });
              });
              let oneGrp = {
                Dealer: name,
                Models: models,
                FullModels: fullModels,
              };

              this.dealerModelOptionData.push(oneGrp);
            });
            //console.log('11111 > ', JSON.parse(JSON.stringify(this.dealerModelOptionData)))

            data.forEach((one) => {
              if (this.catSelected != this.getCategory[0]) {
                if (one.type != this.catSelected) return;
              }
              // 고장 타입 필터
              switch (this.emTypeSelected) {
                case this.getEmTypes[0]:
                  break;
                case this.getEmTypes[1]:
                  if (one.ETYPE != 1) return;
                  break;
                case this.getEmTypes[2]:
                  if (one.ETYPE != 2) return;
                  break;
              }

              // dealer 찾기
              let dealerName = "";
              switch (groups.Depth) {
                case 0:
                  if (TymCommon.isEmpty(one.group_b)) dealerName = one.group_a;
                  else dealerName = one.group_b;
                  break;
                case 1:
                  if (TymCommon.isEmpty(one.group_c)) dealerName = one.group_b;
                  else dealerName = one.group_c;
                  break;
                case 2:
                  if (TymCommon.isEmpty(one.group_d)) dealerName = one.group_c;
                  else dealerName = one.group_d;
                  break;
                case 3:
                  dealerName = one.group_d;
                  break;
              }

              let dmo = this.dealerModelOptionData.find(
                (x) => x.Dealer == dealerName
              );
              if (dmo) {
                let count = parseInt(one.Cnt);
                let model = dmo.Models.find((x) => x.Model == one.M_model);
                if (model) {
                  model.Count += count;

                  let option = model.Options.find(
                    (x) => x.Option == one.M_option
                  );
                  if (option) {
                    option.Count += count;
                  }
                }

                let fullName = model.Model;
                if (one.M_option != "0" && one.M_option != "NONE") {
                  fullName += one.M_option;
                }
                model = dmo.FullModels.find((x) => x.Model == fullName);
                if (model) {
                  model.Count += count;
                }
              }
            });

            this.selectModelOptions = [this.$t("common.all")];
            this.modelData.forEach((model) => {
              this.selectModelOptions.push(model.Model);
            });
            this.selectedModelOptionTab = this.selectModelOptionTabs[0];
            this.selectedModelOption = this.selectModelOptions[0];

            this.selectedDealerModelOptionTab =
              this.selectDealerModelOptionTabs[0];
            this.selectDealerModels = [this.$t("common.all")];
            this.modelData.forEach((model) => {
              this.selectDealerModels.push(model.Model);
            });
            this.selectedDealerModel = this.selectDealerModels[0];

            //console.log('DEALER :', this.dealerData)
            //console.log('MODEL :', this.modelData)
            //console.log('DEALER TABLE :', this.dealerModelOptionData)

            this.updateDealerChart(false);
            this.updateModelOptionChart(false, true);
            this.updateDealerModelTable(false);

            this.loadingData = false;
          })
          .catch((er) => {
            this.loadingData = false;
            console.log("StatEmergency.getSalesStat ERROR :", er);
          });
      }, 1);
    },
  },
};
</script>
