<template>
  <div class="column">
    <div class="col column" style="padding:0 12px 12px 12px">
      <div class="col-auto row items-center" style="height:45px;">
        <div class="col-auto"
            :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
          {{ getPageTitle }}
        </div>
      </div>
      <div class="col column" style="">
        <q-scroll-area class="col column full-height wrap content-start" style="border-radius:3px;">
          <div class="col row q-gutter-md">
            <div class="topLeft col column justify-start">
              <div class="col-auto row items-start justify-start" style="background-color: white;padding:12px 12px; border-radius:3px;">
                <!-- 상단 일반 검색-->
                <div class="col-auto row items-start justify-start">
                  <!-- 조직 선택 -->
                  <div class="col-auto row items-center justify-between" style="min-width:120px;height:40px;padding:0 0 0 0;border:1px solid #cccccc;border-radius:3px;">
                    <div class="col row items-center justify-between" style="cursor:pointer;padding:0 0 0 12px;height:100%;" @click="groupPopupDisplayed = !groupPopupDisplayed">
                      <q-popup-proxy ref="groupPopup" style="padding:3px;" @hide="groupPopupDisplayed=false">
                        <div class="column" style="min-width:250px;height:250px;">
                          <div class="col row" style="border:1px solid #cccccc;padding:3px;background-color:white;">
                            <q-scroll-area class="col row" style="width:auto;height:100%;">
                              <q-tree
                                :nodes="groupTreeData"
                                dense
                                no-selection-unset
                                selected-color="red-7"
                                class="col"
                                node-key="uuid"
                                v-model:expanded="expandedTree"
                                v-model:selected="selectedTree"
                                @update:selected="groupTreeSelected"
                              />
                            </q-scroll-area>
                          </div>
                        </div>
                      </q-popup-proxy>
                      <div class="col-auto">
                        {{selectedTreeLabel}}
                      </div>
                      <q-icon class="col-auto" dense  color="grey-8" :name='groupPopupDisplayed ? "arrow_drop_up" : "arrow_drop_down"' size="24px">
                      </q-icon>
                    </div>
                  </div>
                  <div class="col-auto column items-end justify-end" style="margin:0 6px;">
                    <q-btn class="col-auto" flat dense :icon="searchExpanded ? 'unfold_less_double' : 'unfold_more_double'" color="red-7" size='md' style="width:40px;height:40px;"
                          @click="searchExpanded=!searchExpanded"></q-btn>
                  </div>
                </div>

                <div class="col row items-start justify-start" style="">
                  <!-- 일반 검색 -->
                  <div class="col-auto row items-center justify-start q-gutter-sm" style="" v-show="!searchExpanded">
                    <div class="col row items-center justify-start" style="min-width:80px;">
                      <q-input class="col" dense outlined clearable color="red-7" placeholder="이름" v-model="inputName"></q-input>
                    </div>
                    <div class="col row items-center justify-start" style="min-width:80px;">
                      <q-input class="col" dense outlined clearable color="red-7" placeholder="기대번호 혹은 시리얼번호" v-model="inputSerial"></q-input>
                    </div>
                  </div>

                  <!-- 확장검색 -->
                  <div class="col-auto row items-start justify-start" v-show="searchExpanded">
                    <div class="col-auto row items-start justify-start q-gutter-sm" style="" v-show="searchExpanded">
                      <div class="col row items-center justify-start" style="min-width:80px;">
                        <q-input class="col" dense outlined clearable color="red-7" placeholder="이름" v-model="inputName"></q-input>
                      </div>
                      <div class="col row items-center justify-start" style="min-width:80px;">
                        <q-input class="col" dense outlined clearable color="red-7" placeholder="기대번호" v-model="inputSerial"></q-input>
                      </div>
                      <div class="col row items-center justify-start" style="min-width:80px;">
                        <q-input class="col" dense outlined clearable color="red-7" placeholder="시리얼번호" v-model="inputSerial"></q-input>
                      </div>
                      <div class="col row items-center justify-center" style="min-width:80px;">
                        <div class="col-auto column" style="height:100%;width:100%;">
                          <div class="col divDate" style="border-radius:5px;">{{ prodDate ? prodDate : '판매일'}}</div>
                        </div>
                      </div>
                      <div class="col row items-center justify-start" style="min-width:80px;">
                        <q-input class="col" dense outlined clearable color="red-7" placeholder="판매일" v-model="inputSerial"></q-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-auto row justify-end" style="height:40px;">
                  <div class="col column items-end justify-end" style="min-width:80px;margin:0 0 0 12px;">
                    <q-btn class="col" dense icon='search' color="red-7" size='md' label="검색" style="width:80px;" @click="doSearch"></q-btn>
                  </div>
                </div>
              </div>
              
             
              <div class="col-auto" style="height:12px;"></div>
              <div class="col" style="background-color: white;border-radius:3px;"></div>
            </div>
            <div class="topRight row items-center justify-center" style="background-color: white;border-radius:3px;">
              <div class="col-auto" style="">2</div>
            </div>
          </div> 

          <div class="col row q-gutter-sm" style="padding:12px 0 0 0;">
            <div class="bottomCenter col column items-center justify-center" style="background-color: white;border-radius:3px;">
              <div class="col-auto">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
              <div class="col">3</div>
            </div>
          </div>

        </q-scroll-area>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.divDate {
  border:1px solid #bbbbbb;
}
.divDate:hover {
  border:1px solid #000;
}
.divDate:active {
  border:2px solid #eb0028;
}

@media all and (min-height:1024px) {
  .topLeft {
    height:800px;
  }
  .topRight {
    min-height:320px;
  }
  .bottomCenter {
    height:auto;
  }
}

@media all and (min-height:800px) and (max-height:1023px) {
  .topLeft {
    min-height:640px;
  }
  .topRight {
    min-height:320px;
  }
}

@media all and (min-height:640px) and (max-height:799px) {
  .topLeft {
    min-height:500px;
  }
  .topRight {
    min-height:320px;
  }
}

@media all and (min-height:480px) and (max-height:639px) {
  .topLeft {
    min-height:360px;
  }
  .topRight {
    min-height:70%;
  }
}

@media all and (max-height:479px) {
  .topLeft {
    min-height:320px;
  }
  .topRight {
    min-height:70%;
  }
}

@media all and (min-width:1024px) {
  .topLeft {
    min-width:480px;
  }
  .topRight {
    width:320px;
  }
}

@media all and (min-width:800px) and (max-width:1023px) {
  .topLeft {
    width:100%;
  }
  .topRight {
    width:100%;
    border:1px solid red;
  }
}

@media all and (min-width:640px) and (max-width:799px) {
  .topLeft {
    width:100%;
  }
  .topRight {
    width:100%;
    border:1px solid red;
  }
}

@media all and (min-width:480px) and (max-width:639px) {
  .topLeft {
    width:100%;
  }
  .topRight {
    width:100%;
    border:1px solid blue;
  }
}

.treeBorder {
  border-radius: 3px;
  background-color: white;
  margin:3pt 0pt 0 3pt;
}

.treeContext {
  font-size : 14px;
}

.tableTop {
  border-bottom:1px solid #cccccc;
}
.tableMiddle {
  border-bottom:1px solid #cccccc;
}
.tableBottom {
  border-bottom:2px solid #cccccc;
}
.tableLabel {
  font-weight:500;
  width:20%;
  height:32px;
}
.thSticky {
  position: sticky;
  z-index: 3;
  top:0;
  background-color: white;
  font-size:14px;
  font-weight: 500;
}

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'
// import { useI18n } from 'vue-i18n'
import TymFncs from '@/js/tymfunctions.js'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'
//import TymAws from '@/js/tymaws.js'
//import AWS from 'aws-sdk'
//import { AuthenticationDetails } from "amazon-cognito-identity-js";


export default ({
  components : {
  },
  data() {
    //const i18n = useI18n()
    return {
    }
  },
  setup() {
    // const i18n = useI18n()

    return {
      searchExpanded: ref(false),
      // 조직도 관련
      expandedTree : ref([]),
      selectedTree : ref(""),
      selectedTreeLabel : ref(""),
      groupPopupDisplayed : ref(false),

      inputName: ref(null),
      inputSerial: ref(null),
    }
  },
  computed:{
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean() {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    isKorea() {
      const store = useTymictStore()
      return (store.connectServer == 0)
    },
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    groupTreeData() {
      const store = useTymictStore()
      return store.groupTree
    }
  },
  watch : {
  },

  created() {},
  mounted() {
    const store = useTymictStore()
    if(store.CurrentPage.Now != TymConst.PAGE_UNDERCOVER) {
      store.setPage(TymConst.PAGE_UNDERCOVER)
    }

    if(store.groupTree.length > 0) {
      this.expandedTree = [this.groupTreeData[0].uuid]
      this.selectedTree = this.groupTreeData[0].uuid
      let result = store.findGroupNameByKey(this.selectedTree)
      if(result) {
        this.selectedTreeLabel = result
      }
    }

  }, 
  unmounted() {
    sessionStorage.setItem('LAB.saved', JSON.stringify({
      Expanded: this.expandedTree,
      Selected: this.selectedTree,
      Keyword: this.filterKeyword,
      RowIndex: this.modelRowSelection
    }))

  },
  methods : {
    unusedParam() {
      //
    },
    groupTreeSelected(tgt) {
      const store = useTymictStore()

      this.selectedTree = tgt
      let result = store.findGroupNameByKey(this.selectedTree)
      if(result) {
        this.selectedTreeLabel = result
      }
      this.$refs.groupPopup.hide()
    },
    doSearch() {
      const store = useTymictStore()

      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      let depth = -1
      if(TymCommon.isEmpty(group.uuid_d)) {
        if(TymCommon.isEmpty(group.uuid_c)) {
          if(TymCommon.isEmpty(group.uuid_b)) {
            depth = 0
          } else {
            depth = 1
          }
        } else {
          depth = 2
        }
      } else {
        depth = 3
      }

      let members = []
      switch(depth) {
        case 0:
          members = store.SalesData.data.filter(x => x.uuid_a == this.selectedTree)
          break
        case 1:
          members = store.SalesData.data.filter(x => x.uuid_b == this.selectedTree)
          break
        case 2:
          members = store.SalesData.data.filter(x => x.uuid_c == this.selectedTree)
          break
        case 3:
          members = store.SalesData.data.filter(x => x.uuid_d == this.selectedTree)
          break
        default:
          members = store.SalesData.data
          break
      }

      let keyword = ''
      if(!TymCommon.isEmpty(this.inputName)) {
        keyword = this.inputName.toUpperCase().trim()
      }

      let filtered = null
      if(!TymCommon.isEmpty(keyword)) {
        filtered = members.filter(x => x.owner.toUpperCase().includes(keyword))
      } else {
        filtered = members
      }

      console.log(this.inputName, this.inputSerial)
      if(!TymCommon.isEmpty(this.inputSerial)) {
        keyword = this.inputSerial.toUpperCase().trim()
        if(!TymCommon.isEmpty(keyword)) {
          console.log('------------------', keyword)
          filtered = filtered.filter(x => x.machineNo.toUpperCase().includes(keyword) || x.serialNo.toUpperCase().includes(keyword))
        }
      }

      console.log(this.selectedTreeLabel, this.inputName, this.inputserial, filtered)
    }
  }
})
</script>
