<template>
  <div class="column">
    <div class="col column" style="padding:0 12px 12px 12px;position: relative;">
      <div class="col-auto row items-center" style="height:45px">
        <div class="col-auto"
            :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
          {{ getPageTitle }}
        </div>
      </div>
      <!--
      <div class="col row" style="position:absolute;left:200px;top:0;width:2300px;height:100%;border:1px solid #dddddd;border-radius:3px;padding:6px;z-index:10000;background-color:#00000000">
        <Vue3Lottie :animationData="jsonImageData" width="100%" height="100%" />
        <lottie-player src="../assets/company/agdict_web_login.json" background="transparent" speed="1" loop autoplay></lottie-player>
      </div>
      -->

      <q-splitter v-model="horizSplitterValue" :limits="[30, 80]" 
                  horizontal
                  separator-style="height:12px"
                  class="col" style="position: relative;height:100%;"
                  separator-class="transparent">
        <template v-slot:before >
          <!-- 상단 Selection 부분 -->
          <div class="column" :class='isLandscape ? "col-7" : "col-5"' style="position:relative;width:100%;height:100%;background-color: white;border:1px sold #ebedf2;border-radius: 3px;">
            <div class="col-auto row items-center justify-between" style="min-height:42px;height:auto;margin:12px 0;">
              <div class="col row items-center" style="margin:0 12px;">

                <!-- 자동등록 관련 팝업 메뉴표시 -->
                <q-btn class="col-auto" flat dense icon="menu"
                       style="margin:0 12px 0 0;" v-show="isShowNotRegi">
                  <q-tooltip style="font-size: 14px">{{ $t('tooltip.regNotSales') }}</q-tooltip>
                  <q-popup-proxy ref="reg_menu" style="background-color:white;border:1px solid black;border-radius: 3px;">
                    <div class="column justify-start" style="width:100%;height:100%;border-radius: 3px;">
                      <q-btn class="col row " flat dense size="md" @click="manageMachinesInNotSales">
                        <div class="col row items-center justify-start" style="margin:3px 6px 2px 6px;">
                          <q-icon class="col-auto" style="margin:0 12px 0 0;" name="videogame_asset"/>
                          <div style="font-size:15px;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('salesView.noSalesVIN') }}</div>
                        </div>
                      </q-btn>
                      <q-btn class="col row" flat dense size="md" @click="manageMachinesInAutoSales">
                        <div class="col row items-center justify-start" style="margin:2px 6px 3px 6px;">
                          <q-icon class="col-auto" style="margin:0 12px 0 0;" name="how_to_reg"/>
                          <div style="font-size:15px;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('salesView.salesNotRegi') }}</div>
                        </div>
                      </q-btn>
                    </div>
                  </q-popup-proxy>
                </q-btn>

                <div class="col-auto row" style="margin:0 32px 0 0; font-size:16px;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">
                  <div class="col-auto" style="color:#212529">{{ $t("salesView.totalSales")}}</div>
                  <div class="col-auto" style="font-family:Prometo;color:#eb0028;margin-left:12px;width:80px">{{ salesCount ? salesCount : '' }}</div>
                </div>
                
                <div class="row col-auto grpBtn items-center justify-between" style="min-width:170px;max-width:520px;height:40px;margin:0 12px;padding:0; border-radius: 3px;">
                  <div class="col row items-center justify-between" style="height:100%;border-radius: 3px;padding:0 0 0 12px;">
                    <q-popup-proxy ref="groupPopup" style="padding:3px;background-color:white;" @before-show="groupPopupDisplayed = true" @before-hide="groupPopupDisplayed = false">
                      <div class="column" style="min-width:240px;height:250px;"> 
                        <div class="col row" style="border:1px solid #cccccc;padding:3px;">
                          <q-scroll-area class="col row" style="width:auto;height:100%;">
                            <q-tree
                              :nodes="groupTreeData"
                              dense
                              no-selection-unset
                              selected-color="red-7"
                              class="col"
                              node-key="uuid"
                              v-model:expanded="expandedTree"
                              v-model:selected="selectedTree"
                              @update:selected="groupTreeSelected"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </q-popup-proxy>
                    <div class="col">
                      {{selectedTreeLabel}}
                    </div>
                    <q-icon class="col-auto" dense  color="grey-8" :name='groupPopupDisplayed ? "arrow_drop_up" : "arrow_drop_down"' size="24px">
                    </q-icon>
                  </div>
                  <!--
                  <q-btn dense flat round color="grey-7" icon="close" size="sm" @click="clearGroupData"/>
                  -->
                </div>
                
                <q-form @submit="searchClicked" autocomplete="off">
                  <q-input dense outlined flat autofocus clearable style="font-size:14px;height:40px;width:240px;" color="red-7"
                          :placeholder="$t('placeholder.nameorvinorsn')"
                          v-model="searchInput">
                    <template v-slot:append>
                      <q-icon dense flat name="search"/>
                    </template>
                  </q-input>
                </q-form>
              </div>

              <div class="col-auto row items-center justify-end" style="margin-right:12px;">
                <q-btn class="col" flat dense @click="downloadToExcel" v-show="canDownloadToExcel">
                  <q-tooltip style="font-size: 14px">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                  <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;object-fit: contain;"/>
                  <q-popup-proxy ref="excelPopup" style="width:360px;height:220px;background-color: #01010130;border-radius: 3px;position: relative;"
                                  @before-show="onShowExportToExcel">
                    <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                      <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                        <div class="col-auto row items-center justify-center">
                          <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:normal;'" style="font-size:18px;margin:0 0 12px 0;">
                            {{ $t('common.toExcel')}}
                          </div>
                        </div>
                        <div class="col row items-center justify-start" style="width:100%;margin:0;">
                          <table class="col" style="width:100%;margin:0;">
                            <tr style="">
                              <td width=25% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.filename') }}</td>
                              <td width="12px" style=""></td>
                              <td style="">{{ excelFilename }}</td>
                            </tr>
                            <tr>
                              <td width=25% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('dashboardView.dealer') }}</td>
                              <td width="12px"></td>
                              <td>{{ excelDealer }}</td>
                            </tr>
                            <tr>
                              <td width=25% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.keyword') }}</td>
                              <td width="12px"></td>
                              <td>{{ excelType }}</td>
                            </tr>
                          </table>
                        </div>
                        <xlsx-workbook class="col-auto row items-center justify-center">
                          <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                          <xlsx-download :filename="excelFilename">
                            <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveToExcelFile">{{ $t('common.download') }}</button>
                          </xlsx-download>
                        </xlsx-workbook>
                      </div>
                    </div>
                    <div class="row items-center justify-center" v-show="makingExcel"
                        style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                      <q-spinner color="red-7" size="70px" thickness="1.5"/>
                    </div>
                  </q-popup-proxy>
                </q-btn>

                <q-btn class="col" color="grey-9" icon="add" flat dense @click="addSale" >
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.addNewSale') }}</q-tooltip>
                </q-btn>
              </div>
            </div>
          
            <div class="col column" style="margin:0 12px 12px 12px;">
              <!-- 상단 판매목록 -->
              <div class="col column" ref="tableContainer" style="border:1px solid #cccccc;position:relative">
                <!--
                  @row-click="listItemSelected"
                  @update:selected="updateSelection"
                  v-model:pagination="salesPagination"
                -->
                <q-resize-observer @resize="onResize" />
                <q-table dense flat
                        style="position:absolute;left:0;top:0;right:0;bottom:0;"
                        separator="cell"
                        selection="single"
                        virtual-scroll
                        hide-no-data
                        row-key="index"
                        v-model:selected="itemSelected"
                        :filter="selectedTree || searchInput"
                        :filter-method="salesFilterMethod"
                        :visible-columns="visibleColumns"
                        :columns="itemListColumns"
                        :rows="itemListRows"
                        ref="itemTable"
                        :pagination="curPagination"
                        :rows-per-page-options="maxItemPerPage"
                        @update:pagination="updatePagination"
                        >
                  <template v-slot:header="props">
                    <q-th no-caps
                          style="height:auto;"
                          class="thSticky"
                          v-for="col in props.cols"
                          :key="col.name"
                          :props="props">
                        {{ col.label }}
                    </q-th>
                  </template>
                  <template v-slot:body="props">
                    <q-tr :props="props">
                      <q-td key="model" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;" @click="salesRowClicked(props.row.index, props.row)">
                        <div class="row items-center justify-start" style="width:180px;">
                          <q-radio v-model="salesRowSelection" size="sm" :val="props.row.index" dense color="red" style="margin-right:9px" @update:model-value="changeSelection"/>
                          <div style="font-size:14px;font-family: Prometo;">
                            {{ props.row.model }}
                          </div>
                        </div>
                      </q-td>
                      <q-td key="machineNo" :props="props" style="border-bottom:1px solid #dddddd;width:150px;">
                        <div class="row items-center justify-start">
                          <div class="col" style="font-size:14px;font-family: Prometo;cursor:pointer;" @click="salesRowClicked(props.row.index, props.row)">
                            {{ props.row.machineNo }}
                          </div>
                          <!--
                          <q-btn v-show="isDev" class="col-auto" dense flat size="sm" icon="content_copy" @click="copyToClipboard(props.row.machineNo)"></q-btn>
                          -->
                        </div>
                      </q-td>
                      <q-td key="serialNo" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;">
                        <div class="row items-center justify-start">
                          <div class="col" style="font-size:14px;font-family: Prometo;" @click="salesRowClicked(props.row.index, props.row)">
                            {{ props.row.serialNo }}
                          </div>
                          <!--
                          <q-btn v-show="isDev" class="col-auto" dense flat size="sm" icon="content_copy" @click="copyToClipboard(props.row.serialNo)"></q-btn>
                          -->
                        </div>
                      </q-td>
                      <q-td key="saleDate" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:150px;" @click="salesRowClicked(props.row.index, props.row)">
                        <div class="row items-center justify-start">
                          <div style="font-size:14px;font-family: Prometo;">
                            {{ props.row.saleDate }}
                          </div>
                        </div>
                      </q-td>
                      <q-td key="manDate" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:130px;" @click="salesRowClicked(props.row.index, props.row)">
                        <div class="row items-center justify-start">
                          <div style="font-size:14px;font-family: Prometo;">
                            {{ props.row.manDate }}
                          </div>
                        </div>
                      </q-td>
                      <q-td key="owner" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;min-width:120px;" @click="salesRowClicked(props.row.index, props.row)">
                        <div class="row items-center justify-start">
                          <div style="font-size:14px">
                            {{ props.row.owner }}
                          </div>
                        </div>
                      </q-td>
                      <q-td key="mobile" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:140px;" @click="salesRowClicked(props.row.index, props.row)">
                        <div class="row items-center justify-start">
                          <div style="font-size:14px;font-family: Prometo;">
                            {{ props.row.mobile }}
                          </div>
                        </div>
                      </q-td>
                      <q-td key="dealer" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;min-width:120px;" @click="salesRowClicked(props.row.index, props.row)">
                        <div class="row items-center justify-start">
                          <div style="font-size:14px">
                            {{ props.row.dealer }}
                          </div>
                        </div>
                      </q-td>
                    </q-tr>
                  </template>

                  <template v-slot:bottom>
                    <div class="row items-center justify-end" style="width:100%;">
                      <q-pagination class=""
                                    :max="pageCount"
                                    :max-pages="8"
                                    v-model="currentPageIndex"
                                    outline dense
                                    gutter="3px"
                                    size="12px"
                                    color="grey-9"
                                    direction-links
                                    boundary-links
                                    active-design="unelevated"
                                    active-color="red-7"
                                    active-text-color="white"
                                    @update:model-value="itemPageChanged">
                      </q-pagination>
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
            <div style="z-index:100;position: absolute;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:rgba(128, 128, 128, 0.2)" v-show="isAddMode || isEditMode">
              <!-- 편집모드일 때 상단 테이블 부분을 반투명으로 덮기 위함 -->
            </div>
          </div>
        </template>

        <template v-slot:separator>
          <div class='horizSeparator'></div>
        </template>

        <template v-slot:after>
          <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
          <q-splitter v-model="vertSplitterValue" :limits="[30, 80]" 
                      :separator-style='!isLandscape ? "height:12px" : "width:12px"'
                      :horizontal="!isLandscape"
                      style="position: relative;"
                      separator-class="transparent">
            <template v-slot:before>
              <!-- 아래 왼쪽 정보 표시및 편집용 -->
              <div class="bottomBox column" style="position:relative;width:100%;height:100%;background-color:#f2f2f2;border-radius:3px;"
                    :style="isEditMode || isAddMode ? 'border:1px solid #eb0028;' : 'border:1px solid #f2f2f2'">
                <div class="col-auto row items-center justify-between" style="padding:0 12px;margin:0 0 6px 0;background-color: white;height:42px;border-radius:3px;">
                  <!-- 제목 및 버튼들 -->
                  <div class="col" style="font-family:'Prometo';font-size:16px;font-weight: bold;">{{ $t('salesView.salesInfo')  }}</div>
                  <div class="col row items-center justify-end" v-show="!isAddMode && !isEditMode">
                    <q-btn class="col-auto" flat dense icon="more_horiz" color="grey-9"
                           :disable="itemSelected.length < 1"
                           @click="moveToManagement">
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.moveToManage') }}</q-tooltip>
                    </q-btn>
                    <q-btn class="col-auto" flat dense icon="edit" color="grey-9"
                           :disable="itemSelected.length < 1"
                           @click="editCustomer">
                      <q-tooltip style="font-size:14px;">{{ $t('common.doingEdit') }}</q-tooltip>
                    </q-btn>
                    <q-btn class="col-auto" flat dense icon="delete" color="grey-9"
                           :disable="itemSelected.length < 1"
                           @click="deleteSale">
                      <q-tooltip style="font-size:14px;">{{ $t('common.doingDelete') }}</q-tooltip>
                    </q-btn>
                  </div>
                  <div class="col-auto row justify-end" v-show="isAddMode || isEditMode">
                    <q-btn class="col" flat dense color="red-5" icon="cancel" @click="cancelAdd">
                      <q-tooltip style="font-size:14px;">{{ $t("tooltip.cancelJob") }}</q-tooltip>
                    </q-btn>
                    <q-btn class="col" flat dense color="blue-8" icon="save" @click="saveSaleInfo">
                      <q-tooltip style="font-size:14px;">{{ $t("tooltip.saveJob") }}</q-tooltip>
                    </q-btn>
                  </div>
                </div>

                <div class="col column">
                  <q-scroll-area class="col" style="width:100%;height:100%;background-color:white;border-radius:3px;margin:0 0 0 0;">
                    <div class="" :class="isLandscape ? 'row' : 'column'" style="background-color:#f2f2f2;">
                      <div class="col row" style="border-radius:3px;margin:6px 0 6px 0;min-width:240px;">
                        <!-- 고객 정보 -->
                        <div class="col" style="background-color:white;border-radius:3px;" :style="isLandscape ? 'margin:0 6px 0 0' : 'margin:0 0 0 0'">
                          <table style="padding:12px 12px 6px 12px;width:100%;">
                            <tr class="tr_title row">
                              <td class="infoTitle col row items-center justify-start" colspan="2">
                                <div class="col text-left" style="margin:0 0;font-size:15px;font-weight: bold;">{{ $t('customer.info') }}</div>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center" style="max-width:150px;">
                                <div class="col text-left">{{ $t('common.name') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <div class="col" >{{ infoValue.owner }}</div>
                                <q-btn v-show="canUseClipboard && !(isEditMode || isAddMode) && (infoValue.owner != undefined && infoValue.owner.length > 0)" class="col-auto" dense flat size="sm" icon="content_copy" @click="copyToClipboard(infoValue.owner)"></q-btn>
                                <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')"
                                      v-show="isEditMode || isAddMode"
                                      style="width:76px;" @click="clickSearchOwner">
                                  <q-tooltip style="font-size:14px;">{{ $t("tooltip.selectUser") }}</q-tooltip>
                                </q-btn>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center">
                                <div class="col text-left">{{ $t('common.mobilephone') }}</div>
                              </td>
                              <td class="infoContent col row items-center justify-between" >
                                <div class="col" >{{ infoValue.mobile }}</div>
                                <q-btn class="col-auto" v-show="canUseClipboard && !(isEditMode || isAddMode) && (infoValue.mobile != undefined && infoValue.mobile.length > 0)" dense flat size="sm" icon="content_copy" @click="copyToClipboard(infoValue.mobile)"></q-btn>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row" v-show="isKorea">
                              <td class="infoTitle row items-center">
                                <div class="col text-left">{{ $t('common.address') }}</div>
                              </td>
                              <td class="infoContent col row items-center">
                                <div class="col" >{{ infoValue.address }}</div>
                                <q-btn class="col-auto" v-show="canUseClipboard && !(isEditMode || isAddMode) && (infoValue.address != undefined && infoValue.address.length > 0)" dense flat size="sm" icon="content_copy" @click="copyToClipboard(infoValue.address)"></q-btn>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="col row" style="border-radius:3px;min-width:240px;" :style="isLandscape ? 'margin:6px 0 6px 0' : 'margin:6px 0 6px 0'">
                        <!-- 판매 정보 -->
                        <div class="col" style="background-color:white;border-radius:3px;" :style="isLandscape ? 'margin:0 0 0 6px' : 'margin:0 0 0 0'">
                          <table style="padding:12px 12px 6px 12px;width:100%;">
                            <tr class="tr_title row">
                              <td class="infoTitle col row items-center justify-start" colspan="2">
                                <div class="col text-left" style="margin:0 0;font-size:15px;font-weight: bold;">{{ $t('salesView.salesInfo') }}</div>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center" style="max-width:150px;">
                                <div class="col text-left">{{ $t('customer.dealer') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <div class="col" >{{ infoValue.dealer }}</div>
                                <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')"
                                    v-show="isEditMode || isAddMode"
                                    style="margin-bottom:2px;width:76px;height:30px;" @click="clickSearchDealer">
                                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectDealer') }}</q-tooltip>
                                </q-btn>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center">
                                <div class="col text-left">{{ $t('customer.saleDate') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <div class="col">{{ infoValue.saleDate }}</div>
                                <div class="col-auto row">
                                  <q-btn unelevated dense v-show="isEditMode || isAddMode">
                                    <q-img src="../assets/manage/management_calendar_i.svg" width="16px" fit="fill"/>
                                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectSaleDate') }}</q-tooltip>
                                    <q-popup-proxy cover ref="saleDatePopup" @before-show="beforeSaleDate">
                                      <q-date minimal flat color="red-7" v-model="saleDateValue" @update:model-value="updateSaleDate"
                                              style="margin:0 0 12px 0;">
                                      </q-date>
                                    </q-popup-proxy>
                                  </q-btn>
                                  <q-btn unelevated dense flat color="grey-7" icon="access_time" v-show="isEditMode || isAddMode">
                                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectSaleTime') }}</q-tooltip>
                                    <q-popup-proxy cover ref="saleTimePopup" @before-show="beforeSaleTime">
                                      <div style="background-color: white;border-radius: 3px;margin:0 6px">
                                        <div class="row justify-between items-center" style="height:32px;margin:6px 0 3px 0;">
                                          <div style="font-size:15px;font-weight:500;">Sale Time</div>
                                          <div class="row justify-end">
                                            <q-btn flat dense color="red-7" icon="close" @click="cancelSaleTime"/>
                                            <q-btn flat dense color="blue-7" icon="done" @click="confirmSaleTime"/>
                                          </div>
                                        </div>
                                        <q-time flat color="red-7" v-model="saleTimeValue">
                                        </q-time>
                                      </div>
                                    </q-popup-proxy>
                                  </q-btn>
                                </div>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row" v-show="isDev">
                              <td class="infoTitle row items-center">
                                <div class="col text-left">{{ $t('customer.expireDate') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <div class="col">{{ infoValue.expireDate }}</div>
                                <div class="col-auto row">
                                  <q-btn unelevated dense v-show="isEditMode || isAddMode">
                                    <q-img src="../assets/manage/management_calendar_i.svg" width="16px" fit="fill"/>
                                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectSaleDate') }}</q-tooltip>
                                    <q-popup-proxy cover ref="expireDatePopup" @before-show="beforeExpireDate">
                                      <q-date minimal flat color="red-7" v-model="expireDateValue" @update:model-value="updateExpireDate"
                                              style="margin:0 0 12px 0;">
                                      </q-date>
                                    </q-popup-proxy>
                                  </q-btn>
                                  <!--
                                  <q-btn unelevated dense flat color="grey-7" icon="access_time" v-show="isEditMode || isAddMode">
                                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectSaleTime') }}</q-tooltip>
                                    <q-popup-proxy cover ref="saleTimePopup" @before-show="beforeSaleTime">
                                      <div style="background-color: white;border-radius: 3px;margin:0 6px">
                                        <div class="row justify-between items-center" style="height:32px;margin:6px 0 3px 0;">
                                          <div style="font-size:15px;font-weight:500;">Sale Time</div>
                                          <div class="row justify-end">
                                            <q-btn flat dense color="red-7" icon="close" @click="cancelSaleTime"/>
                                            <q-btn flat dense color="blue-7" icon="done" @click="confirmSaleTime"/>
                                          </div>
                                        </div>
                                        <q-time flat color="red-7" v-model="saleTimeValue">
                                        </q-time>
                                      </div>
                                    </q-popup-proxy>
                                  </q-btn>
                                  -->
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div :class="isLandscape ? 'row' : 'column'" style="background-color:#f2f2f2">
                      <div class="col row" style="border-radius:3px;min-width:240px;" :style="isLandscape ? 'margin:6px 0 0 0' : 'margin:6px 0 6px 0'">
                        <!-- 기대 정보 -->
                        <div class="col" style="background-color:white;border-radius:3px;" :style="isLandscape ? 'margin:0 6px 0 0' : 'margin:0 0 0 0'">
                          <table style="padding:12px 12px 6px 12px;width:100%;">
                            <tr class="tr_title row">
                              <td class="infoTitle col row items-center justify-start" colspan="2">
                                <div class="col text-left" style="margin:0 0;font-size:15px;font-weight: bold;">{{ $t('salesView.machineInfo') }}</div>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center" style="max-width:150px;">
                                <div class="col text-left">{{ $t('customer.machineNo') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <!-- 판매 수정시 MN은 수정 불가 -->
                                <q-input clearable dense hide-bottom-space color="red-7"
                                        style="height:auto"
                                        :borderless="!isAddMode"
                                        :readonly="!isAddMode"
                                        v-model="infoValue.machineNo"/>
                                <q-btn class="col-auto" v-show="canUseClipboard && !(isEditMode || isAddMode) && (infoValue.machineNo != undefined && infoValue.machineNo.length > 0)" dense flat size="sm" icon="content_copy" @click="copyToClipboard(infoValue.machineNo)"></q-btn>
                                <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')"
                                      v-show="isAddMode"
                                      style="margin-bottom:2px;width:76px;height:30px;" @click="searchVIN">
                                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.loadMachineByMON')}}.</q-tooltip>
                                </q-btn>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center">
                                <div class="col text-left">{{ $t('customer.modelName') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <div class="col" >{{ infoValue.modelName }}</div>
                                <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')"
                                      v-show="isEditMode || isAddMode"
                                      style="margin-bottom:2px;width:76px;height:30px;" @click="clickSearchModel">
                                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectModel') }}</q-tooltip>
                                </q-btn>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center">
                                <div class="col text-left">{{ $t('customer.manDate') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <div class="col" >{{ infoValue.prodDate }}</div>
                                <q-btn unelevated dense v-show="isEditMode || isAddMode">
                                  <q-img src="../assets/manage/management_calendar_i.svg" width="16px" fit="fill"/>
                                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectProdDate') }}</q-tooltip>
                                  <q-popup-proxy cover ref="addProductDatePopup" @before-show="beforeSelectProdDate">
                                    <q-date minimal flat color="red-7" v-model="selectedProdDateCtrl" @update:model-value="selectedProdDate">
                                    </q-date>
                                  </q-popup-proxy>
                                </q-btn>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center">
                                <q-checkbox class="col row" color="red-7" round dense v-model="useTBOX" v-show="isEditMode || isAddMode" style="margin:0 6px 0 0;"/>
                                <div style="font-weight:500;">TBOX S/N</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <q-input dense hide-bottom-space color="red-7" v-model="infoValue.serialNo"
                                         :borderless="(!isEditMode && !isAddMode) || !useTBOX" 
                                         maxlength="20" style="max-width:180px;"
                                         :clearable="useTBOX"
                                         v-show="useTBOX" @update:model-value="changedTBOXSN"
                                         :readonly="(!isEditMode && !isAddMode) || !useTBOX">
                                  <!--
                                         :error-message="$t('errorMsg.mismatchSN')"
                                  <template v-slot:error>
                                    <div style="font-size:14px;">{{ $t('errorMsg.mismatchSN') }}</div>
                                  </template>
                                  -->
                                </q-input>
                                <q-btn class="col-auto" v-show="canUseClipboard && !(isEditMode || isAddMode) && (infoValue.serialNo != undefined && infoValue.serialNo.length > 0)" dense flat size="sm" icon="content_copy" @click="copyToClipboard(infoValue.serialNo)"></q-btn>
                                <div class="col row justify-end items-center" v-show="(isEditMode || isAddMode) && useTBOX">
                                  <q-icon name="check_circle" size="sm" color="grey-7" v-show="validTBOXSN"/>
                                  <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.check')"
                                        v-show="(isEditMode || isAddMode) && useTBOX && !validTBOXSN"
                                        style="margin-bottom:2px;width:76px;height:30px;" @click="checkMNandTBOX">
                                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.checkMN_SN')}}.</q-tooltip>
                                  </q-btn>
                                </div>
                              </td>
                            </tr>
                            <!-- 20231117 삭제요청으로 주석처리
                                 20240115 판매정보에 있던 것을 기대정보 부분으로 옮겨옴
                            -->
                            <tr class="tr_middle_add row items-center" v-show="infoValue.use_st">
                              <td class="infoTitle col-auto row items-center">
                                <div class="col text-left">{{ $t('salesView.testTimeBeforeSale') }}</div>
                              </td>
                              <td class="infoContent col row">
                                <div class="col column justify-end" >
                                  <q-input clearable dense hide-bottom-space color="red-7"
                                        class="col"
                                        style="width:auto;"
                                        :borderless="!isEditMode && !isAddMode"
                                        :readonly="!isEditMode && !isAddMode"
                                        v-model="infoValue.testRunTime" />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="col row" style="border-radius:3px;min-width:240px;" :style="isLandscape ? 'margin:6px 0 0 0' : 'margin:6px 0 0 0'">
                        <!-- 자율주행 정보 -->
                        <div class="col" style="background-color:white;border-radius:3px;" :style="isLandscape ? 'margin:0 0 0 6px' : 'margin:0 0 0 0'">
                          <table style="padding:12px 12px 6px 12px;width:100%;">
                            <tr class="tr_title row">
                              <td class="infoTitle col row items-center justify-start" colspan="2">
                                <div class="col text-left" style="margin:0 0;font-size:15px;font-weight: bold;">{{ $t('salesView.autoDriveInfo') }}</div>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center">
                                <q-checkbox class="col row" color="red-7" round dense v-model="useACU" v-show="isEditMode || isAddMode" style="margin:0 6px 0 0;" @update:model-value="checkACU"/>
                                <div style="font-weight:500;">ACU S/N</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <q-input dense hide-bottom-space color="red-7" class="col-auto" v-model="infoValue.acuSN" 
                                         :borderless="(!isEditMode && !isAddMode) || !useACU"
                                         maxlength="20" :clearable="useACU" 
                                         v-show="useACU" @update:model-value="changedACUSN"
                                         :readonly="(!isEditMode && !isAddMode) || !useACU">
                                </q-input>
                                <q-btn class="col-auto" v-show="canUseClipboard && !(isEditMode || isAddMode) && (infoValue.acuSN != undefined && infoValue.acuSN.length > 0)" dense flat size="sm" icon="content_copy" @click="copyToClipboard(infoValue.acuSN)"></q-btn>
                                <div class="col row justify-end items-center" v-show="(isEditMode || isAddMode) && useACU">
                                  <q-icon name="check_circle" size="sm" color="grey-7" v-show="validACUSN"/>
                                  <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.check')"
                                        v-show="(isEditMode || isAddMode) && useACU && !validACUSN"
                                        style="margin-bottom:2px;width:76px;height:30px;" @click="checkMNandACU">
                                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.checkMN_SN')}}.</q-tooltip>
                                  </q-btn>
                                </div>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row">
                              <td class="infoTitle row items-center" style="max-width:150px;">
                                <div class="col text-left">{{ $t('salesView.product') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <div class="col" v-show="useACU && !canEditAMS" style="">{{ infoValue.acuProdType }}</div>
                                <q-select dense options-dense color="red-7" :options="acuProdTypes" v-model="infoValue.acuProdType" style="min-width:120px;width:40%;"
                                          v-show="(isEditMode || isAddMode) && useACU && canEditAMS"/>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row items-center">
                              <td class="infoTitle row items-center">
                                <div class="col text-left">{{ $t('salesView.adsLevel') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <div class="col" v-show="useACU && !canEditAMS" style="">{{ infoValue.adsLevel }}</div>
                                <q-select dense options-dense color="red-7" :options="adsLevels" v-model="infoValue.adsLevel" style="min-width:120px;width:40%;"
                                          v-show="(isEditMode || isAddMode) && useACU && canEditAMS"/>
                              </td>
                            </tr>
                            <tr class="tr_middle_add row items-center">
                              <td class="infoTitle row items-center">
                                <div class="col text-left">{{ $t('salesView.autoSteering') }}</div>
                              </td>
                              <td class="infoContent col row justify-between items-center">
                                <div class="col" v-show="useACU && !canEditAMS" style="">{{ infoValue.steeringDev }}</div>
                                <q-select dense options-dense color="red-7" :options="steeringDevs" v-model="infoValue.steeringDev" style="min-width:120px;width:40%;"
                                          v-show="(isEditMode || isAddMode) && useACU && canEditAMS"/>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div v-show="isLandscape" style="width:100%;height:12px;background-color:#f2f2f2"></div>
                </q-scroll-area>
                </div>
              </div>
            </template>

            <template v-slot:separator>
              <div :class='isLandscape ? "vertSeparator" : "horizSeparator"'></div>
            </template>

            <template v-slot:after>
              <div class="col bottomBox" style="position:relative;width:100%;height:100%;" 
                   :style="(isAddMode || isEditMode) ? 'border:1px solid #eb0028;' : 'border:1px solid #f2f2f2'">
                <div class="col row internalContainer" style="position:relative;">
                  <div class="col column" v-show="(itemSelected.length > 0) && !isAddMode && !isEditMode">
                    <!-- 현재 위치 -->
                    <div class="col-auto row items-center justify-start" style="height:42px;">
                      <!-- 20231107 높이 42px or 30px : 디자인은 42px임 -->
                      <div class="col" style="font-size:16px;font-weight:500;">{{ $t('common.recentLocation') }}</div>
                    </div>
                    <MapView ref="occurLoca" elementid="occurLoca" class="col" @addressReceived="addrReceived"></MapView>
                  </div>
                </div>

                <div class="col column" v-show="(isAddMode || isEditMode) && searchingOwner" style="z-index:10;position: absolute;left:12px;top:12px;right:12px;bottom:12px;">
                  <!-- Customer List -->
                  <div class="col-auto row items-center justify-between" style="margin:0 0 6px 0;">
                    <div class="col" style="font-size:16px;font-weight: 500;">{{ $t('customer.list') }}</div>
                    <div class="col-auto row justify-end" style="width:180px;">
                      <q-form @submit="customerSearchClicked" autocomplete="off">
                        <q-input dense outlined style="font-size:14px;" color="red-7"
                                :placeholder="$t('placeholder.nameorphone')"
                                v-model="customerSearchInput">
                          <template v-slot:append>
                            <q-btn round dense flat icon="search" @click="customerSearchClicked"/>
                          </template>
                        </q-input>
                      </q-form>
                    </div>
                  </div>
                  <div class="col row" style="position: relative;border:1px solid #dddddd">
                    <q-table dense flat
                            separator="cell"
                            class="col-auto"
                            style="position:absolute;left:0;top:0;right:0;bottom:0"
                            :rows-per-page-options="[maxCustomerPerPage]"
                            v-model:selected="customerSelected"
                            virtual-scroll
                            hide-no-data
                            row-key="index"
                            :visible-columns="customerVisColums"
                            :columns="customerColumns"
                            :rows="customerRows"
                            ref="customerTable"
                            @update:selected="updateCustomerSelection"
                            >
                      <template v-slot:header="props">
                        <q-th no-caps
                              style="height:auto"
                              class="thSticky"
                              v-for="col in props.cols"
                              :key="col.name"
                              :props="props">
                            {{ col.label }}
                        </q-th>
                      </template>

                      <template v-slot:body="props">
                        <q-tr :props="props" @click="customerRowClicked(props.row.index, props.row)" style="cursor:pointer">
                          <q-td key="name" :props="props" style="border-bottom:1px solid #dddddd;">
                            <div class="row items-center justify-start" style="min-width:100px">
                              <div style="font-size:14px">
                                {{ props.row.name }}
                              </div>
                            </div>
                          </q-td>
                          <q-td key="userid" :props="props" style="border-bottom:1px solid #dddddd;">
                            <div class="row items-center justify-start" style="min-width:100px">
                              <div style="font-size:14px">
                                {{ props.row.userid }}
                              </div>
                            </div>
                          </q-td>
                          <q-td key="mobile" :props="props" style="border-bottom:1px solid #dddddd;">
                            <div class="row items-center justify-start" style="min-width:100px">
                              <div style="font-size:14px">
                                {{ props.row.mobile }}
                              </div>
                            </div>
                          </q-td>
                          <q-td key="address" :props="props" style="border-bottom:1px solid #dddddd;">
                            <div class="row items-center justify-start">
                              <div style="font-size:14px">
                                {{ props.row.address }}
                              </div>
                            </div>
                          </q-td>
                        </q-tr>
                      </template>

                      <template v-slot:bottom>
                        <div class="row items-center justify-end" style="width:100%;">
                          <q-pagination v-model="customerPagination"
                                        :max="customerPageCount" :max-pages="6"
                                        direction-links
                                        boundary-links
                                        outline dense
                                        gutter="3px"
                                        size="12px"
                                        color="grey-9"
                                        active-design="unelevated"
                                        active-color="red-7"
                                        active-text-color="white"
                                        @update:model-value="customerPageChanged">
                          </q-pagination>
                        </div>
                      </template>
                    </q-table>
                  </div>
                </div>

                <div class="col column" v-show="(isAddMode || isEditMode) && searchingVIN" style="z-index:11;position: absolute;left:12px;top:12px;right:12px;bottom:12px;">
                  <!-- Product List -->
                  <div class="col-auto row items-center justify-between" style="margin:0 0 6px 0;">
                    <div class="col" style="font-size:16px;font-weight: 500;">Machine List</div>
                    <div class="col-auto row justify-end" style="width:180px;">
                      <q-form @submit="VINSearchClicked" autocomplete="off">
                        <q-input dense outlined style="font-size:14px;" color="red-7"
                                :placeholder="$t('placeholder.vinonly')"
                                v-model="VINSearchInput">
                          <template v-slot:append>
                            <q-btn round dense flat icon="search" @click="VINSearchClicked"/>
                          </template>
                        </q-input>
                      </q-form>
                    </div>
                  </div>
                  <div class="col row" style="position: relative;border:1px solid #dddddd">
                    <q-table dense flat
                            separator="cell"
                            class="col-auto"
                            style="position:absolute;left:0;top:0;right:0;bottom:0"
                            :rows-per-page-options="[maxVINPerPage]"
                            v-model:selected="VINSelected"
                            virtual-scroll
                            hide-no-data
                            row-key="index"
                            :visible-columns="VINVisColums"
                            :columns="VINColumns"
                            :rows="VINRows"
                            ref="VINTable"
                            @update:selected="updateVINSelection"
                            >
                      <template v-slot:header="props">
                        <q-th no-caps
                              style="height:auto"
                              class="thSticky"
                              v-for="col in props.cols"
                              :key="col.name"
                              :props="props">
                            {{ col.label }}
                        </q-th>
                      </template>

                      <template v-slot:body="props">
                        <q-tr :props="props" @click="VINRowClicked(props.row)" style="cursor:pointer;">
                          <q-td key="SOLD" :props="props" style="border-bottom:1px solid #dddddd;">
                            <div class="col row items-center justify-center">
                              <q-icon dense class="col-auto" color="grey-7" name="agriculture" size="sm" v-show="props.row.SOLD != 1"></q-icon>
                              <q-icon dense class="col-auto" color="grey-7" name="person" size="sm" v-show="props.row.SOLD == 1"></q-icon>
                            </div>
                          </q-td>
                          <q-td key="MN" :props="props" style="border-bottom:1px solid #dddddd;">
                            <div class="row items-center justify-start" style="min-width:100px">
                              <div style="font-size:14px">
                                {{ props.row.MN }}
                              </div>
                            </div>
                          </q-td>
                          <q-td key="SN" :props="props" style="border-bottom:1px solid #dddddd;">
                            <div class="row items-center justify-start" style="min-width:100px">
                              <div style="font-size:14px">
                                {{ props.row.SN }}
                              </div>
                            </div>
                          </q-td>
                          <q-td key="MODEL" :props="props" style="border-bottom:1px solid #dddddd;">
                            <div class="row items-center justify-start">
                              <div style="font-size:14px">
                                {{ props.row.MODEL }}
                              </div>
                            </div>
                          </q-td>
                        </q-tr>
                      </template>

                      <template v-slot:bottom>
                        <div class="row items-center justify-end" style="width:100%;">
                          <q-pagination v-model="VINPagination"
                                        :max="VINPageCount" :max-pages="6"
                                        direction-links
                                        boundary-links
                                        outline dense
                                        gutter="3px"
                                        size="12px"
                                        color="grey-9"
                                        active-design="unelevated"
                                        active-color="red-7"
                                        active-text-color="white"
                                        @update:model-value="VINPageChanged">
                          </q-pagination>
                        </div>
                      </template>
                    </q-table>
                  </div>
                </div>

                <div class="col column" v-show="(isAddMode || isEditMode)  && searchingModel" style="z-index:20;position: absolute;left:12px;top:12px;right:12px;bottom:12px;">
                  <!-- Model List -->
                  <div class="col-auto row items-center justify-between">
                    <div class="col" style="font-size:16px;font-weight: 500;">Model List</div>
                    <div class="col-auto row justify-end">
                    </div>
                  </div>
                  <div class="col row" style="position: relative;border:1px solid #dddddd">
                    <q-table dense flat
                            separator="cell"
                            class="col-auto"
                            style="position:absolute;left:0;top:0;right:0;bottom:0;" 
                            :rows-per-page-options="[maxModelPerPage]"
                            v-model:selected="modelSelected"
                            virtual-scroll
                            hide-no-data
                            hide-pagination
                            row-key="index"
                            :visible-columns="modelVisColums"
                            :columns="modelColumns"
                            :rows="modelRows"
                            ref="modelTable"
                            @row-click="listModelSelected"
                            @update:selected="updateModelSelection"
                            >
                      <template v-slot:header="props">
                        <q-th no-caps
                              style="height:auto"
                              class="thSticky"
                              v-for="col in props.cols"
                              :key="col.name"
                              :props="props">
                            {{ col.label }}
                        </q-th>
                      </template>
                      <template v-slot:body="props">
                        <q-tr :props="props" @click="modelRowClicked(props.row.index, props.row)">
                          <q-td key="model" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;">
                            <div class="row items-center justify-start">
                              <div style="font-size:14px">
                                {{ props.row.model }}
                              </div>
                            </div>
                          </q-td>
                          <q-td key="option" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;">
                            <div class="row items-center justify-start">
                              <div style="font-size:14px">
                                {{ props.row.option }}
                              </div>
                            </div>
                          </q-td>
                          <q-td key="hp" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;">
                            <div class="row items-center justify-start">
                              <div style="font-size:14px">
                                {{ props.row.hp }}
                              </div>
                            </div>
                          </q-td>
                          <q-td key="type" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;">
                            <div class="row items-center justify-start">
                              <div style="font-size:14px">
                                {{ props.row.type }}
                              </div>
                            </div>
                          </q-td>
                        </q-tr>
                      </template>

                    </q-table>
                  </div>
                </div>

                <div class="col column" v-show="(isAddMode || isEditMode) && searchingDealer" style="z-index:30;position:absolute;left:12px;top:12px;right:12px;bottom:12px;">
                  <div class="col-auto row items-center justify-between">
                    <div class="col" style="font-size:16px;font-weight: 500;">Groups</div>
                    <div class="col-auto row justify-end">
                      <q-btn dense flat round color="grey-7" icon="close" @click="searchingDealer = false"/>
                    </div>
                  </div>
                  <!-- 그룹 트리 -->
                  <div class="col row" style="width:auto;height:100%;border:1px solid #eeeeee;border-radius:3px;padding:6px;">
                    <q-scroll-area class="col column" style="width:auto;height:100%;">
                      <q-tree
                        :nodes="groupTreeData"
                        dense
                        class="col"
                        node-key="uuid"
                        selected-color="red-7"
                        v-model:expanded="expandedSubTree"
                        v-model:selected="selectedSubTree"
                        @update:selected="groupSubTreeSelected"
                      />
                    </q-scroll-area>
                  </div>
                </div>
              </div>
            </template>
            <!-- 아랫부분 가리고 뺑뺑이 -->
            <div v-show="bottomWaiting" class="row items-center justify-center" style="position:absolute;z-index:999;left:0;top:0;right:0;bottom:0;background-color:#80808010">
              <q-spinner-tail dense color="red-7" size="80px"/>
            </div>
          </q-splitter>
        </template>

        <!-- 판매등록되지 않은 기기들 관리용 팝업-->
        <div v-show="isShowNoSalesMachines" style="z-index: 99;position:absolute;left:0;top:0;width:100%;height:100%;">
          <div class="col column items-start justify-start"
              style="height:100%;background-color:white;border:1px solid #d0d0d0;border-radius:3px;">
            <div class="col-auto row items-center justify-between" style="width:100%;padding:3px 3px 3px 6px;border-bottom:1px solid #808080;">
              <div class="col-7 row items-center justify-start">
                <q-icon class="col-auto" size="sm" style="margin:0 12px 0 6px;" name="videogame_asset"/>
                <div style="font-size:16px;font-weight:500;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('salesView.noSalesVIN') }}</div>
              </div>
              <q-btn flat dense icon="close" @click="isShowNoSalesMachines=false"></q-btn>
            </div>
            <div class="col row" style="margin:0px 0;width:100%;padding:6px;">
              <div class="col-auto column" style="height:100%;width:60%;">
                <div class="col-auto row" ref="prodTableContainer" style="height:80%;width:100%;">
                  <!-- 생산에서 가져온 리스트 표시용 -->
                  <q-table class="col" flat dense hide-no-data bordered
                          virtual-scroll
                          style="width:100%;height:100%;"
                          refs="prodTable"
                          :filter="[prodSelectedModel, prodSearchInput]"
                          :filter-method="prodFilterMethod"
                          visible-columns="visibleProdColumns"
                          :columns="prodColumns"
                          :rows="prodRows"
                          :pagination="curProdPagination"
                          :rows-per-page-options="[maxItemPerProd]"
                          @update:pagination="updateProdPagination">
                    <template v-slot:top>
                      <div class="col row items-center justify-between" style="width:100%;height:100%;">
                        <div class="col-auto row">
                          <!--
                          <q-select dense options-dense multiple
                                    v-model="noDisplaySites"
                                    :options="tymFactories"
                                    color="red-7"
                                    ref="manSites"
                                    @update:model-value="changedViewSites"
                                    style="font-size:15px;min-width: 250px;">
                            <template v-slot:prepend>
                              <q-icon name="visibility_off" color="black"></q-icon>
                            </template>
                          </q-select>
                          -->
                          <!--
                          <q-btn flat dense color="red-7" icon='autorenew' size="md">
                            <q-tooltip style="font-size:14px;">위치정보를 업데이트합니다.</q-tooltip>
                          </q-btn>
                          -->
                        </div>
                        <div class="col row items-center justify-end">
                          <div style="border-bottom:1px solid #cccccc;">
                            <q-select borderless dense options-dense no-caps color="red-7" :options="prodModelList" v-model="prodSelectedModel" style="width:160px;margin:-5px 0;padding:0;"></q-select>
                          </div>
                          <div style="width:12px;"></div>
                          <div style="border-bottom:1px solid #cccccc;">
                            <q-input borderless dense debounce="300" placeholder="Search" v-model="prodSearchInput" style="margin:-5px 0;">
                              <template v-slot:append>
                                <q-icon name="search" />
                              </template>
                            </q-input>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:header="props">
                      <q-th no-caps
                            style="border-top:2px solid #cccccc;"
                            :style="(Number(idx) < (props.cols.length-1) ? 'border-right:1px solid #cccccc;' : 'border-right:0px solid #cccccc;')"
                            class="thSticky"
                            v-for="(col, idx) in props.cols"
                            :key="col.name"
                            :props="props">
                            <div v-show="(idx == '0')">
                              <q-checkbox flat dense color="red-6" toggle-indeterminate true-value="all" false-value="none" indeterminate-value="some" v-model="checkProdSelectAll" @update:model-value="prodSelectionChanged"/>
                            </div>
                            {{ (idx == '0') ? '' : col.label }}
                      </q-th>
                    </template>
                    <template v-slot:body="props">
                      <q-tr :props="props" style="cursor: pointer;"  :style="prodSelectionIndex == props.row.Index ? 'background-color:#f0f0f0;' : 'background-color:white;'">
                        <q-td key="Selected" :props="props" style="width:32px;height:32px;border-width:0 1px 1px 0;" @click="props.row.Selected = !props.row.Selected; oneProdSelChanged()">
                          <div class="row items-center justify-center">
                            <q-checkbox dense color="red-6" v-model="props.row.Selected"></q-checkbox>
                          </div>
                        </q-td>
                        <q-td key="MN" :props="props" style="width:96px;border-width:0 1px 1px 0;height:32px;" @click="clickProdRow(1, props.row)" >
                          <div class="row items-center justify-start no-wrap" style="font-size:15px;">
                            {{ props.row.MN }}
                          </div>
                        </q-td>
                        <q-td key="SN" :props="props" style="border-width:0 1px 1px 0;height:32px;" @click="clickProdRow(2, props.row)" >
                          <div class="row items-center justify-start no-wrap" style="font-size:15px;">
                            {{ props.row.SN }}
                          </div>
                        </q-td>
                        <q-td key="MODEL" :props="props" style="border-width:0 1px 1px 0;height:32px;" @click="clickProdRow(2, props.row)" >
                          <div class="row items-center justify-start no-wrap" style="font-size:15px;">
                            {{ props.row.Info.Fullname }}
                          </div>
                        </q-td>
                        <q-td key="OD" :props="props" style="border-width:0 1px 1px 0;height:32px;" @click="clickProdRow(3, props.row)" >
                          <div class="row items-center justify-start no-wrap" style="font-size:15px;">
                            {{ props.row.RegDate }}
                          </div>
                        </q-td>
                        <q-td key="Dealer" :props="props" style="border-width:0 1px 1px 0;height:32px;" @click="clickProdRow(4, props.row)" >
                          <div class="row items-center justify-bwteen no-wrap" style="width:100%;height:100%;">
                            <div class="col" style="font-size:15px;">
                              {{ props.row.Dealer }}
                            </div>
                            <q-btn class="col-auto" flat dense size="sm" icon="delete_forever" v-show="props.row.Dealer.length > 0" @click="props.row.Dealer='';"></q-btn>
                          </div>
                        </q-td>
                        <q-td key="Owner" :props="props" style="border-width:0 0px 1px 0;height:32px;">
                          <div class="row items-center justify-bwteen no-wrap" style="width:100%;height:100%;">
                            <div class="col row items-center justify-start no-wrap" style="font-size:15px;" @click="clickProdRow(5, props.row)">
                              {{ props.row.Owner }}
                            </div>
                            <q-btn class="col-auto" flat dense size="sm" icon="delete_forever" v-show="props.row.Owner.length > 0" @click="props.row.Owner='';"></q-btn>
                          </div>
                        </q-td>
                      </q-tr>
                    </template>
                    <template v-slot:bottom>
                      <div class="row items-center justify-end" style="width:100%;height:30px;">
                        <q-pagination class=""
                                      :max="prodPageCount"
                                      :max-pages="8"
                                      v-model="curProdPageIndex"
                                      outline dense
                                      gutter="3px"
                                      size="12px"
                                      color="grey-9"
                                      direction-links
                                      boundary-links
                                      active-design="unelevated"
                                      active-color="red-7"
                                      active-text-color="white"
                                      @update:model-value="prodPageChanged">
                        </q-pagination>
                        <q-btn unelevated dense color="red-7" size="sm" icon="save" style="width:48px;height:100%;margin:2px 0 0 12px;font-size:14px;"
                              @click="saveProdSales">
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.applyJob') }}</q-tooltip>
                          <q-popup-proxy cover ref="prodPopupConfirm">
                            <div class="col column" style="border:1px solid black;border-radius:3px;width:720px;" :style="getProdPopupHeight">
                              <div class="col-auto row items-center justify-between" style="padding:3px 6px;border-bottom:1px solid #aaa">
                                <div class="col" style="font-size:15px;font-weight:bold;font-family:Noto Sans KR">
                                  변경할 기대의 정보
                                </div>
                                <div>
                                  <q-btn flat dense icon="save" @click="clickedProdApply"></q-btn>
                                  <q-btn flat dense icon="close" v-close-popup></q-btn>
                                </div>
                              </div>
                              <div class="col column" style="position:relative;">
                                <q-scroll-area class="col column" style="position:absolute;left:6px;top:6px;right:1px;bottom:6px;">
                                  <div class="col column" :style="getProdScrollMargin">
                                    <div class="col row items-center justify-start" style="border:1px solid #aaa;">
                                      <table style="width:100%;height:auto;border-collapse:collapse;">
                                        <tr height="28px" style="font-weight:500;">
                                          <td style="border-bottom:1px solid #aaa;border-right:1px solid #dddddd;padding:2px 3px;">{{ $t('common.vin') }}</td>
                                          <td style="border-bottom:1px solid #aaa;border-right:1px solid #dddddd;padding:2px 3px;">{{ $t('customer.serialNo') }}</td>
                                          <td style="border-bottom:1px solid #aaa;border-right:1px solid #dddddd;padding:2px 3px;">{{ $t('customer.modelName') }}</td>
                                          <td style="border-bottom:1px solid #aaa;border-right:1px solid #dddddd;padding:2px 3px;">{{ $t('customer.dealer') }}</td>
                                          <td style="border-bottom:1px solid #aaa;border-right:1px solid #dddddd;padding:2px 3px;">{{ $t('productView.regDate') }}</td>
                                          <td style="border-bottom:1px solid #aaa;border-right:1px solid #dddddd;padding:2px 3px;">{{ $t('customer.saleDate') }}</td>
                                          <td style="border-bottom:1px solid #aaa;border-right:0px solid #dddddd;padding:2px 3px;">{{ $t('TABLE_COLUMNS.status') }}</td>
                                        </tr>
                                        <tr v-for="(item, idx) in itemsForSaleReg" :key="idx">
                                          <td style="border-right:1px solid #dddddd;padding:2px 3px;text-wrap: nowrap;" :style="idx < (itemsForSaleReg.length - 1) ? 'border-bottom:1px solid #f0f0f0' : 'border-bottom:0px solid #bbbbbb'">
                                            {{ item.MN }}
                                          </td>
                                          <td style="border-right:1px solid #dddddd;padding:2px 3px;text-wrap: nowrap;" :style="idx < (itemsForSaleReg.length - 1) ? 'border-bottom:1px solid #f0f0f0' : 'border-bottom:0px solid #bbbbbb'">
                                            {{ item.SN }}
                                          </td>
                                          <td style="border-right:1px solid #dddddd;padding:2px 3px;text-wrap: nowrap;" :style="idx < (itemsForSaleReg.length - 1) ? 'border-bottom:1px solid #f0f0f0' : 'border-bottom:0px solid #bbbbbb'">
                                            {{ item.MON }}
                                          </td>
                                          <td style="border-right:1px solid #dddddd;padding:2px 3px;text-wrap: nowrap;" :style="idx < (itemsForSaleReg.length - 1) ? 'border-bottom:1px solid #f0f0f0' : 'border-bottom:0px solid #bbbbbb'">
                                            {{ item.Dealer }}
                                          </td>
                                          <td style="border-right:1px solid #dddddd;padding:2px 3px;text-wrap: nowrap;" :style="idx < (itemsForSaleReg.length - 1) ? 'border-bottom:1px solid #f0f0f0' : 'border-bottom:0px solid #bbbbbb'">
                                            {{ item.RD }}
                                          </td>
                                          <td style="border-right:1px solid #dddddd;padding:2px 3px;text-wrap: nowrap;" :style="idx < (itemsForSaleReg.length - 1) ? 'border-bottom:1px solid #f0f0f0' : 'border-bottom:0px solid #bbbbbb'">
                                            {{ item.SD }}
                                          </td>
                                          <td style="border-right:0px solid #bbbbbb;padding:2px 3px;text-wrap: nowrap;" :style="idx < (itemsForSaleReg.length - 1) ? 'border-bottom:1px solid #f0f0f0' : 'border-bottom:0px solid #bbbbbb'">
                                            <div :style="item.RESULT != undefined ? (item.RESULT ? 'color:black;' : 'color:red;') : ''">
                                              {{ item.RESULT != undefined ? (item.RESULT ? 'OK' : 'FAILED') : '' }}
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </q-scroll-area>
                              </div>
                            </div>
                          </q-popup-proxy>
                        </q-btn>
                      </div>
                    </template>
                  </q-table>
                  <q-resize-observer @resize="onResizeProdTable"/>
                </div>
                <div class="col-auto" style="height:6px;"></div>
                <div class="col column" style="margin:0 0;border:1px solid #e0e0e0;width:100%;border-radius:3px;margin:0 0px 0px 0;">
                  <!-- 고객 설정용 -->
                  <div class="col-auto row items-center justify-between" style="margin:6px 6px 6px 6px;font-size:16px;height:24px;">
                    <div class="row items-center justify-start">
                      <div>{{ $t("customer.info")}}</div>
                    </div>
                    <div class="col-auto row">
                      <q-input flat dense color="red-7" :placeholder="$t('placeholder.nameonly')" style="margin:-5px 6px;" v-model="prodCustomerInputName">
                        <template v-slot:append>
                          <q-btn flat dense color="grey-6" icon="search" >
                            <q-popup-proxy ref="popupProdUser" @before-show="beforeShowProdCustomer">
                              <div class="column" style="height:362px;width:600px;border:1px solid #808080; border-radius:5px;padding:5px;">
                                <div class="row items-center justify-start" style="border-bottom:2px solid #cccccc;">
                                  <q-icon size="24px" name="person" style="margin:0 6px 0 0;"></q-icon>
                                  <div style="font-size:16px;font-weight:500;padding:3px 0 3px 0;">사용자 선택</div>
                                </div>
                                <div class="col column items-center justify-center" style="padding:1px 12px 3px 3px;" v-show="prodSearchCustomers.length < 1">
                                  <div>찾는 데이터가 없습니다.</div>
                                </div>
                                <q-scroll-area class="col column" style="padding:1px 12px 3px 3px;" v-show="prodSearchCustomers.length > 0">
                                  <q-btn flat dense no-caps v-for="user in prodSearchCustomers" :key="user" class="col row" style="width:100%;border:1px solid #cccccc;margin:0 0 1px 0;" @click="clickedProdSelUser(user)">
                                    <div class="col row items-center items-start">
                                      <div class="col-5 text-left">{{ user.name }}</div>
                                      <div class="col-3 text-left">{{ user.userid }}</div>
                                      <div class="col text-left">{{ user.phone_number }}</div>
                                      <!--
                                      <div class="col-4 text-left">{{ user.email }}</div>
                                      -->
                                    </div>
                                  </q-btn>
                                </q-scroll-area>
                              </div>
                            </q-popup-proxy>
                          </q-btn>
                        </template>
                      </q-input>
                      <q-btn class="col-auto" flat dense icon="publish" @click="apllyCustomerToProd">
                        <q-tooltip style="font-size:14px">고객정보를 적용합니다.</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                  <div class="col row items-center justify-between" style="margin:6px 6px 6px 6px;font-size:15px;height:24px;border-top:2px solid #c0c0c0;border-bottom:2px solid #c0c0c0;">
                    <q-scroll-area class="col" style="width:100%;height:100%;padding:3px 6px 6px 6px;">
                      <table style="width:100%;border-collapse: collapse;">
                        <tr height="28px">
                          <td>{{ $t('common.name') }}</td>
                          <td>{{ prodNoRegUser.Name }}</td>
                        </tr>
                        <tr height="28px" style="background-color:#f0f0f0c0;">
                          <td>{{ $t('common.phoneNo') }}</td>
                          <td>{{ prodNoRegUser.Phone }}</td>
                        </tr>
                        <tr height="28px">
                          <td>{{ $t('common.email') }}</td>
                          <td>{{ prodNoRegUser.EMail }}</td>
                        </tr>
                        <tr height="28px" style="background-color:#f0f0f0c0;">
                          <td>{{ $t('common.address') }}</td>
                          <td>{{ prodNoRegUser.Address }}</td>
                        </tr>
                      </table>
                    </q-scroll-area>
                  </div>
                </div>
              </div>
              <!-- 구분선 -->
              <div class="col-auto" style="width:6px;"></div>
              <div class="col column" style="width:100%;">
                <!-- 오른쪽 그룹 표시용 -->
                <div class="column" :class="isShowProdGrpTree ? 'col-6' : 'com-auto'" style="border:1px solid #e0e0e0;width:100%;border-radius:3px;padding:0 6px;">
                  <div class="col-auto row items-center justify-bwteen" style="margin:6px 3px 12px 3px;font-size:15px;height:24px;">
                    <div class="col row items-center justify-start">
                      <q-btn class="col-auto" flat dense icon="menu_open" @click="applyGroupToProd">
                      </q-btn>
                      <div style="margin:0 0 0 6px;">그룹 선택</div>
                    </div>
                    <q-btn class="col-auto" flat dense icon="expand" @click="isShowProdGrpTree = !isShowProdGrpTree"></q-btn>
                  </div>
                  <q-scroll-area class="col row" style="width:100%;height:100%;padding:6px;border-top:2px solid #c0c0c0;" v-show="isShowProdGrpTree">
                    <q-tree
                      :nodes="groupTreeData"
                      dense
                      class="col"
                      node-key="uuid"
                      selected-color="red-7"
                      style="font-size:15px;margin:0 12px 0 0"
                      v-model:expanded="expandedProdTree"
                      v-model:selected="selectedProdTree"
                      @update:selected="groupProdTreeSelected"
                    />
                  </q-scroll-area>
                </div>
                <div class="col-auto" style="height:6px;"></div>
                <div class="col column" style="border:1px solid #dddddd;width:100%;border-radius:3px;padding:6px 0 0 0;">
                  <div class="col-auto row items-center justify-between"
                       style="font-size:15px;height:32px;margin:0 6px">
                    <div class="col row items-center justify-start">
                      <q-icon class="col-auto" size="sm" name="location_on"></q-icon>
                      <div style="width:6px"></div>
                      <div class="col">{{ $t('productView.lastLoca') }}</div>
                      <!--
                      <div class="col">{{ $t('common.vin') }}&nbsp;:&nbsp;{{ selectedProdMN }}</div>
                      -->
                    </div>

                    <!--
                    <div class="col-auto row items-center justify-start">
                      {{ selectedProdAddr }}
                    </div>
                    -->
                  </div>
                  <div class="col row" style="margin:6px;">
                    <!-- 지도 -->
                    <MapView ref="prodMap" elementid="prodMap" class="col" @addressReceived="addrReceivedProd"></MapView>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 자동등록기대중 사용자 정보가 부정확한 기기들 관리용 팝업-->
        <div v-show="isShowAutoSalesMachines" style="z-index: 99;position:absolute;left:0;top:0;width:100%;height:100%;">
          <div class="col column items-start justify-start"
              style="height:100%;background-color:white;border:1px solid #d0d0d0;border-radius:3px;">
            <div class="col-auto row items-center justify-between" style="width:100%;padding:3px 3px 3px 6px;border-bottom:1px solid #808080;">
              <div class="col row items-center ">
                <q-icon class="col-auto" size="sm" style="margin:0 12px 0 0;" name="how_to_reg"/>
                <div style="font-size:15px;font-weight:500;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('salesView.salesNotRegi') }}</div>
              </div>
              <q-btn flat dense icon="close" @click="isShowAutoSalesMachines=false"></q-btn>
            </div>
            <div class="col row" style="margin:0px 0;width:100%;padding:6px;">
              <q-table class="col-auto" flat dense bordered hide-no-data style="height:100%;width:60%;">
              </q-table>            
              <div class="col-auto" style="width:6px;"></div>
              <div class="col row" style="margin:0 0;border:1px solid #e0e0e0;width:100%;border-radius:3px;margin:0 0px 0px 0;">
            </div>
            </div>
          </div>
        </div>

      </q-splitter>

    </div>
    <!-- 다이얼로그  - 비밀번호 확인용 -->
    <q-dialog v-model="showDeletePassword" persistent style="border:1px solid #eeeeee;background-color: #fefffff;">
        <q-card>
          <q-card-section class="column items-center" style="width:auto;height:100%;margin:12px;">
            <!--
            <q-form @submit="enteredPassword" autocomplete="off">
            -->
              <div class="col row items-center justify-start">
                <q-icon class="col-auto" dense name="info" color="red-7" size="64px" text-color="white" style="margin:0 12px 0 0;"/>
                <div class="col column" style="width:100%;">
                  <div class="col" style="margin:0;font-size:16px;">{{ eraseNoticeMsg }}</div>
                  <q-input class="col" type="password" v-model="inputDeletePassword" clearable outlined dense color="red-7" style="margin:12px 24px 0 0;font-size:16px;"></q-input>
                </div>
              </div>
            <!--
            </q-form>
            -->
          </q-card-section>

          <q-card-actions >
            <div class="col row items-center justify-center" style="margin:0 0 12px 0">
              <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup/>
              <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="enteredPassword"/>
            </div>
          </q-card-actions>
        </q-card>
    </q-dialog>
    <!-- 다이얼로그  - ACU 삭제 -->
    <q-dialog v-model="showDeleteACU" persistent style="border:1px solid #eeeeee;background-color: #fefffff;">
      <q-card>
        <q-card-section class="column items-center" style="width:auto;height:100%;margin:12px;">
          <div class="col row items-center justify-start">
            <q-icon class="col-auto" dense name="help" color="red-7" size="64px" text-color="white" style="margin:0 12px 0 0;"/>
            <div class="col column" style="width:100%;">
              <div class="col" style="margin:0;font-size:16px;">{{ $t('warning.deleteACUInfo') }}</div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions >
          <div class="col row items-center justify-center" style="margin:0 0 12px 0">
            <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup @click="deleteCancelACU"/>
            <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="deleteConfirmACU"/>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- 다이얼로그  - TBOX 삭제 -->
    <q-dialog v-model="showDeleteTBOX" persistent style="border:1px solid #eeeeee;background-color: #fefffff;">
      <q-card>
        <q-card-section class="column items-center" style="width:auto;height:100%;margin:12px;">
          <div class="col row items-center justify-start">
            <q-icon class="col-auto" dense name="help" color="red-7" size="64px" text-color="white" style="margin:0 12px 0 0;"/>
            <div class="col column" style="width:100%;">
              <div class="col" style="margin:0;font-size:16px;">{{ $t('warning.deleteTBOXInfo') }}</div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions >
          <div class="col row items-center justify-center" style="margin:0 0 12px 0">
            <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup @click="deleteCancelTBOX"/>
            <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="deleteConfirmTBOX"/>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- 다이얼로그 - 저장시 ACU 삭제 -->
    <q-dialog v-model="showSaveDeleteACU" persistent style="border:1px solid #eeeeee;background-color: #fefffff;">
      <q-card>
        <q-card-section class="column items-center" style="width:auto;height:100%;margin:12px;">
          <div class="col row items-center justify-start">
            <q-icon class="col-auto" dense name="help" color="red-7" size="64px" text-color="white" style="margin:0 12px 0 0;"/>
            <div class="col column" style="width:100%;">
              <div class="col" style="margin:0;font-size:16px;">{{ $t('warning.deleteACUInfo') }}</div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions >
          <div class="col row items-center justify-center" style="margin:0 0 12px 0">
            <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup @click="saveDeleteCancelACU"/>
            <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="saveDeleteConfirmACU"/>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- 다이얼로그 - 저장시 TBOX 삭제 -->
    <q-dialog v-model="showSaveDeleteTBOX" persistent style="border:1px solid #eeeeee;background-color: #fefffff;">
      <q-card>
        <q-card-section class="column items-center" style="width:auto;height:100%;margin:12px;">
          <div class="col row items-center justify-start">
            <q-icon class="col-auto" dense name="help" color="red-7" size="64px" text-color="white" style="margin:0 12px 0 0;"/>
            <div class="col column" style="width:100%;">
              <div class="col" style="margin:0;font-size:16px;">{{ $t('warning.deleteTBOXInfo') }}</div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions >
          <div class="col row items-center justify-center" style="margin:0 0 12px 0">
            <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup @click="saveDeleteCancelTBOX"/>
            <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="saveDeleteConfirmTBOX"/>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>


  </div>
</template>

<style scoped>

.title {
  font-size: 16px;
  font-weight: bold;
}

.thSticky {
  position: sticky;
  z-index: 3;
  top:0;
  background-color: white;
  font-size:16px;
  font-weight: 500;
}
.bottomBox {
  background-color: white;
  border-radius: 3px;
  height:auto;
}
.tr_title {
  border-bottom: 2px solid #eeeeee;
  min-height:28px;
}

.tr_top {
  border-top: 2px solid #cccccc;
  border-bottom: 1px solid #eeeeee;
  min-height:28px;
}
.tr_middle {
  border-bottom: 1px solid #eeeeee;
  min-height:32px;
}
.tr_middle_add {
  border-bottom: 1px solid #eeeeee;
  min-height:40px;
}
.tr_bottom {
  border-top: 2px solid #cccccc;
  min-height:28px;
}

.infoTitle {
  font-size: 14px;
  font-weight: 500;
  padding:0 12px 0 0;
}
.infoContent {
  height:42px;
}

.internalContainer {
  width:100%;
  height:100%;
  padding:0px 12px 12px 12px;
}
.internalContainer_pt {
  background-color:#ffffff;
  border-radius: 3px;
  width:100%;
  height:100%;
}
.internalContainer_pt2 {
  background-color:#ffffff;
  border-radius: 3px;
  width:100%;
  height:100%;
}

.grpBtn {
  border-radius: 3px;
  border:1px solid #cccccc;
}
.grpBtn:hover {
  height:40px;
  border:1px solid black;
}
.grpBtn:active {
  height:40px;
  border:2px solid #eb0028;
}

.horizSeparator {
  width:32px;
  height:4px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.vertSeparator {
  width:4px;
  height:32px;
  background-color:#BBBBBB;
  border-radius: 3px;
}

</style>

<script>
//import axios from 'axios'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTymictStore } from '@/store/tymict'
import TymFncs from '@/js/tymfunctions.js'
import TymAws from '@/js/tymaws.js'
import TymCommon from '@/js/tymcommon'
import TymConst from '@/js/tymconstants.js'
import moment from 'moment-timezone'
import { XlsxWorkbook, XlsxSheet, XlsxDownload  } from 'vue3-xlsx';
import MapView from '../views/MapView.vue'
//import JSONImage from '../assets/agdict_web_login.json'
//import { AuthenticationDetails } from "amazon-cognito-identity-js";
//import { Vue3Lottie } from 'vue3-lottie'

export default ({
  components : {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    MapView,
//    Vue3Lottie
  },
  data() {
    return {
    }
  },
  computed : {
    isDev() {
      return TymConst.IS_DEVELOPMENT;
    },
    canUseClipboard() {
      const store = useTymictStore()
      return (store.isICTUser() && window.isSecureContext)
    },
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    salesCount1 : function() {
      const store = useTymictStore()
      return store.SalesData.count
    },
    getCategory() {
      const i18n=useI18n()
      return [
        i18n.t('common.all'),
        i18n.t('noticeView.selectcommon'),
        i18n.t('noticeView.selectevent'),
        i18n.t('noticeView.selectnotread'),
        i18n.t('noticeView.selectfavorite')
      ]
    },
    isKorean() {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    isKorea() {
      const store = useTymictStore()
      return (store.connectServer == 0)
    },
    adsLevels() {
      //  : ref(['None', 'Level 1', 'Level 2', 'Level 3', 'Level 4']), 
      const store = useTymictStore()
      if(!TymCommon.isEmpty(store.WebConfig)) {
        // console.log('salesView.adsLevels', store.WebConfig.ADSLevels)
        return store.WebConfig.ADSLevels[0]
      }
      return null
    },
    acuProdTypes() {
      const store = useTymictStore()
      if(!TymCommon.isEmpty(store.WebConfig)) {
        return store.WebConfig.MachineTypes[0]
      }
      return null
    },
    steeringDevs() {
      // ref(['None', 'EHI_T', "EHI_R", "EHI_CN"]),
      const store = useTymictStore()

      if(!TymCommon.isEmpty(store.WebConfig)) {
        // console.log('steeringDevs :', store.WebConfig.AutoSteer)
        return store.WebConfig.AutoSteer[0]
      }
      return null
    },
    canEditAMS() {
      const store = useTymictStore()
      if(this.isAddMode || this.isEditMode)
        return store.isICTUser()
      return false
    },
    getGroupTreeData() {
      const store = useTymictStore() 
      //console.log('SaleView.computed.getGroupTreeData', this.groupTreeData)
      return store.groupTree
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return store.canDownloadToExcel()
    },
    isShowNotRegi() {
      const store = useTymictStore() 
      return (store.isICTUser() && TymConst.IS_DEVELOPMENT)
    },
    getSalesData() {
      const store = useTymictStore()
      return store.SalesData.data
    },
    machineStateChanged() {
      const store = useTymictStore();
      let powerState = [], abnormal = [false, false, false, false]

      if(this.salesRowSelection >= 0 && this.itemSelected.length > 0) {
        let found = store.machineLocaInfo.find(x => x.machineNo == this.itemSelected[0].machineNo)
        let foundEM = store.EmergencyData.data.find(x => x.MN == this.itemSelected[0].machineNo)
        if(foundEM) {
          abnormal[0] = true
        } else {
          let foundBR = store.BrokenData.data.find(x => x.MN == this.itemSelected[0].machineNo)
          if(foundBR) {
            abnormal[1] = true
          } else {
            let foundCS = store.ConsumablesData.data.find(x => x.MN == this.itemSelected[0].machineNo)
            if(foundCS) {
              abnormal[2] = true
            } else {
              let foundLB = store.BatteryAlertData.data.find(x => x.MN == this.itemSelected[0].machineNo)
              if(foundLB) {
                abnormal[3] = true
              } 
            }
          }
        }

        if(TymConst.IS_DEVELOPMENT) {
          console.log('Z Selected', this.itemSelected)
          console.log('  found', found)
          console.log('  abnormal', abnormal)
        }

        if(found) {
          powerState.push({
            PWR : TymCommon.isEmpty(found.PWR) ? "OFF" : found.PWR,
            Abnormal: abnormal
          })
        }
      }
      return powerState
    },
    getProdPopupHeight() {
      let css = "min-height:"
      if(this.itemsForSaleReg.length == 1)
        css += "110px;"
      else if(this.itemsForSaleReg.length == 2)
        css += "136px;"
      else if(this.itemsForSaleReg.length == 3)
        css += "162px;"
      else if(this.itemsForSaleReg.length == 4)
        css += "188px;"
      else if(this.itemsForSaleReg.length == 5)
        css += "214px;"
      else if(this.itemsForSaleReg.length >= 6)
        css += "240px;"
      return css;
    },
    getProdScrollMargin() {
      if(this.itemsForSaleReg.length <= 6)
        return "margin:0 5px 0 0;"
      else
        return "margin:0 12px 0 0;"
    }
  },
  watch : {
    getGroupTreeData : {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal, newVal)
        if(!this.isAddMode && !this.isEditMode) {
          if(newVal) {
            if(this.itemSelected.length < 1) {
              if(newVal.length > 0) {
                const store = useTymictStore() 
                this.groupTreeData = store.groupTree

                if(this.expandedTree.length < 1)
                  this.expandedTree = [this.groupTreeData[0].uuid]
                if(TymCommon.isEmpty(this.selectedTreeLabel))
                  this.selectedTreeLabel = this.groupTreeData[0].label
              } else {
                this.groupTreeData = []
                this.expandedTree = []
                this.selectedTree = ''
                this.selectedTreeLabel = ''
              }
            }
          }
        }
      },
      immediate : true
    },
    getSalesData : {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal, newVal)
        if(!this.isAddMode && !this.isEditMode) {
          if(this.itemSelected.length < 1) {
            if(newVal) {
              if(newVal.length > 0) {
                this.updateSalesInfo()
              }
            }
          }
        }
      },
      immediate : true
    },
    machineStateChanged: {
      handler(newVal, oldVal) {
        this.unusedParam(newVal, oldVal);

        if(TymConst.IS_DEVELOPMENT) {
          console.log('saleview.watch.machineStateChanged :', oldVal, newVal)
        }
        let newLen = TymCommon.isEmpty(newVal) ? 0 : newVal.length;
        let oldLen = TymCommon.isEmpty(oldVal) ? 0 : oldVal.length;
        let maxLen = newLen > oldLen ? newLen : oldLen;

        let indices = [];
        for (let i = 0; i < maxLen; i++) {
          if (!TymCommon.isEmpty(newVal) && !TymCommon.isEmpty(newVal[i])) {
            if (!TymCommon.isEmpty(oldVal)) {
              if (!TymCommon.isEmpty(newVal[i])) {
                let newStr = JSON.stringify(newVal[i])
                let oldStr = JSON.stringify(oldVal[i])
                if (newStr != oldStr) {
                  indices.push(i);
                }
              } else {
                indices.push(i);
              }
            } else {
              indices.push(i);
            }
          }
        }
        if (indices.length > 0) {
          if(!this.firstTimeAfterSelect)
            this.updateIgnState(newVal)
        }
      },
      immediate: true,
    },
  },

  setup() {
    const i18n = useI18n()
    return {
      horizSplitterValue : ref(42),     // FHD기준 쓸만한 비중임
      vertSplitterValue : ref(60),

      PDList : ref([]),

      selectedGroup : ref("TYMICT"),
      groups : ref(["TYM", "TYMICT"]),
      isEditing : ref(false),
      searchInput : ref(''),
      salesCount : ref(0),
      visibleColumns : ref(['model', 'machineNo', 'serial', 'manDate', 'owner', 'mobile', 'dealer', 'saleDate']),
      itemListColumns  : ref([
        { name: 'index', required: false },
        {
          name: 'model',
          required: true,
          label: i18n.t('common.model'),
          align: 'left',
          field: 'model',
          sortable: true
        },
        { 
          name: 'machineNo',
          required: true,
          align: 'left',
          label: i18n.t('common.vin'),
          field: 'machineNo',
          sortable: true
        },
        {
          name: 'serialNo',
          required: true,
          align: 'left',
          label: i18n.t('machineInfo.serialNo'),
          field: 'serialNo',
          sortable: true
        },
        {
          name: 'saleDate',
          align: 'left',
          label: i18n.t('machineInfo.saleDate'),
          field: 'saleDate',
          sortable: true,
          sort : (a, b, rowA, rowB) => {
            return rowA['SD'].localeCompare(rowB['SD'])
          }
        },
        {
          name: 'manDate',
          align: 'left',
          label: i18n.t('machineInfo.manDate'),
          field: 'manDate',
          sortable: true,
          sort : (a, b, rowA, rowB) => {
            return rowA['PD'].localeCompare(rowB['PD'])
          }
        },
        {
          name: 'owner',
          align: 'left',
          label: i18n.t('dashboardView.customer'),
          field: 'owner',
          sortable: true
        },
        {
          name: 'mobile',
          align: 'left',
          label: i18n.t('machineInfo.mobile'),
          field: 'mobile',
          sortable: true
        },
        {
          name: 'dealer',
          align: 'left',
          label: i18n.t('common.salePlace'),
          field: 'dealer',
          sortable: true
        }
      ]),
      itemSelected : ref([]),
      curPagination : ref({
        sortBy : 'none',
        descending : false,
        page : 1,
        rowsPerPage : 20
      }),
      maxItemPerPage : ref([5, 15]),
      currentPageIndex : ref(1),

      pageCount : ref(0),
      itemListRows : ref([]),
      itemHeaders : ref(['Owner', 'Mobile', 'Address', 'Model', 'VIN', 'Prod. Date', 'S/N', 'Sale Date', 'Dealer' /*, 'Real-time Location' */]),
      //infoValue : ref({}),
      infoValue : ref({
        owner : '',
        mobile : '',
        address : '',
        modelName : '',
        MODEL : '',
        OPTION : '',
        machineNo : '',
        prodDate : '',
        serialNo : '',
        saleDate : '',
        dealer : '',
        acuSN : '',
        acuProdType : '',
        adsLevel : '',
        steeringDev : '',
        use_st : false
      }),
      groupPopupDisplayed : ref(false),
      groupTreeData : ref([]),
      expandedTree : ref([]),
      selectedTree : ref(""),
      selectedTreeLabel : ref(""),
      salesRowSelection : ref(-1),

      isViewCustomer : ref(false),
      isAddMode : ref(false),
      isEditMode : ref(false),
      searchingOwner : ref(false),
      searchingVIN : ref(false),
      searchingModel : ref(false),
      searchingDealer : ref(false),

      selectedProdDateCtrl : ref(''),
      saleDateValue : ref(''),
      saleTimeValue : ref(''),
      expireDateVlaue : ref(''),

      expandedSubTree : ref([]),
      selectedSubTree : ref(""),
      selectedSubTreeLabel : ref(""),

      modelName : ref(''),
      modelDesc : ref(''),
      modelType : ref(''),
      modelHP : ref(0),
      modelOption : ref(''),
      modelWeignt : ref(0),
      modelImagePath : ref(''),

      customerVisColums : ref(['name', 'userid', 'mobile', 'address']),
      customerColumns : ref([
        { name: 'index', required: false },
        {
          name: 'name',
          required: true,
          label: 'Name',
          align: 'left',
          field: 'Name',
          sortable: true
        },
        {
          name: 'userid',
          required: true,
          label: 'ID',
          align: 'left',
          field: 'userid',
          sortable: true
        },
        { name: 'mobile', align: 'left', label: 'Mobile', field: 'mobile', sortable: true },
        { name: 'address', align: 'left', label: 'Address', field: 'address', sortable: true },
      ]),
      customerRows : ref([]),
      customerSelected : ref([]),
      customerPagination : ref(1),
      customerPageCount : ref(20),
      maxCustomerPerPage : ref(15),
      customerSearchInput : ref(''),

      VINVisColums : ref(['SOLD', 'MN', 'SN', 'MODEL']),
      VINColumns : ref([
        { name: 'index', required: false },
        {
          name: 'SOLD',
          required: false,
          label: 'SALE',
          align: 'center',
          field: 'SOLD'
        },
        {
          name: 'MN',
          required: true,
          label: 'VIN',
          align: 'left',
          field: 'MN',
          sortable: true
        },
        { name: 'SN', align: 'left', label: 'Serial Number', field: 'SN', sortable: true },
        { name: 'MODEL', align: 'left', label: 'Model Name', field: 'MODEL', sortable: true },
      ]),
      VINRows : ref([]),
      VINSelected : ref([]),
      VINPagination : ref(1),
      VINPageCount : ref(20),
      maxVINPerPage : ref(15),
      VINSearchInput : ref(''),
      
      modelVisColums : ref(['model', 'option', 'hp', "type"]),
      modelColumns : ref([
        { name: 'index', required: false },
        {
          name: 'model',
          required: true,
          label: 'Model',
          align: 'left',
          field: 'model',
          sortable: true
        },
        { name: 'option', align: 'left', label: 'Option', field: 'option', sortable: true },
        { name: 'hp', align: 'left', label: 'HP', field: 'hp', sortable: true },
        { name: 'type', align: 'left', label: 'Type', field: 'type', sortable: true },
      ]),
      modelRows : ref([]),
      modelSelected : ref([]),
      modelPagination : ref(1),
      maxModelPerPage : ref(0),   // 10 -> 0
      modelPageCount : ref(1),

      sheets: ref([]),

      makingExcel : ref(true),
      excelFilename : ref(''),
      excelDealer : ref(''),
      excelType : ref(''),

      useTBOX : ref(false),
      savedTBOX : ref(false),
      useACU : ref(false),
      savedACU : ref(false),

      showDeleteComplete : ref(false),
      reenterPassword : ref(false),
      showDeletePassword : ref(false),
      eraseNoticeMsg : ref(''),
      inputDeletePassword : ref(''),

      showDeleteACU : ref(false),
      showDeleteTBOX : ref(false),
      showSaveDeleteACU : ref(false),
      showSaveDeleteTBOX : ref(false),

      validTBOXSN : ref(false),
      validACUSN : ref(false),

      deleteModuleInfo : ref(null),
      bottomWaiting : ref(false),

      myGroupInfo : ref(null),
      firstTimeAfterSelect: ref(false),

      isShowNoSalesMachines: ref(false),
      checkProdSelectAll: ref('none'),
      expandedProdTree : ref([]),
      selectedProdTree : ref(""),
      selectedProdTreeLabel : ref(""),
      curProdPageIndex : ref(1),
      prodPageCount : ref(0),
      visibleProdColumns : ref(['Selected', 'MN', 'SN', 'MODEL', 'OD', 'Dealer', 'Owner', '']),
      prodColumns : ref([
        { name: 'Index', required: false },
        {
          name: 'Selected',
          required: true,
          field: 'Selected'
        },
        {
          name: 'MN',
          required: true,
          label: i18n.t('common.vin'),
          align: 'left',
          field: 'MN',
          sortable: true
        },
        {
          name: 'SN',
          required: true,
          label: i18n.t('customer.serialNo'),
          align: 'left',
          field: 'SN',
          sortable: true
        },
        {
          name: 'MODEL',
          required: true,
          label: i18n.t('customer.modelName'),
          align: 'left',
          field: 'MODEL',
          sortable: true
        },
        {
          name: 'OD',
          required: true,
          label: i18n.t('productView.regDate'),
          align: 'left',
          field: 'OD',
          sortable: true,
          sort : (a, b, rowA, rowB) => {
            return rowA['OD'].localeCompare(rowB['OD'])
          }
        },
        {
          name: 'Dealer',
          required: true,
          label: i18n.t('common.salePlace'),
          align: 'left',
          field: 'Dealer',
          sortable: true
        },
        {
          name: 'Owner',
          required: true,
          label: i18n.t('dashboardView.customer'),
          align: 'left',
          field: 'Owner',
          sortable: true
        },
      ]),
      prodRows : ref([]),
       curProdPagination : ref({
        sortBy : 'none',
        descending : false,
        page : 1,
        rowsPerPage : 20
      }),
      maxItemPerProd : ref([5, 20]),
      prodSelectedModel: ref(''),
      prodSearchInput: ref(''),
      prodModelList: ref([]),
      isShowProdGrpTree: ref(true),
      prodNoRegUser: ref({}),
      prodCustomerInputName: ref(''),
      prodSearchCustomers: ref([]),
      selectedProdMN: ref('VIN'),
      selectedProdAddr: ref(''),
      prodSelectionIndex: ref(-1),
      itemsForSaleReg: ref([]),
      noDisplaySites: ref(null),
      tymFactories: ref([
        {
          label: i18n.t('productView.facOkcheon'),
          value: 'okcheon',
          lat: 36.2968991,    // 옥천
          lng: 127.5608736
        },
        {
          label: i18n.t('productView.facIksan'),
          value: 'iksan',
          lat: 35.9879589666,   // 익산
          lng: 127.0928535166
        },
      ]),

      jsonImageData: ref(null),

      isShowAutoSalesMachines: ref(false)
    }
  },
  created() {},
  mounted() {
    const store = useTymictStore()
    if(store.CurrentPage.Now != TymConst.PAGE_SALES) {
      store.setPage(TymConst.PAGE_SALES)
    }
    //console.log('=========>', store.SalesData.data)

    if(store.SalesData.data.length < 1) {
      setTimeout(() => {
        // 20231228 진입시 새로 갱신하자.
        store.getGroupSalesData(this.getSalesDataOK)
      }, 1)
    } else {
      setTimeout(() => {
        this.updateSalesInfo()
      }, 5)
    }
  /*
      var config = {
        method: 'get',
        url: 'https://api-uat.io.linksfield.net/cube/v4/sims?status=23&page_no=1&page_size=100',
        headers: { 
            'Accept-Language': 'en-US', 
            'Authorization': '', 
            'X-LF-Signature-Type': '2.0', 
            'User-Agent': 'Apifox/1.0.0 (https://apifox.com)', 
            'Content-Type': 'application/json'
        }
      }

      axios(config)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        })
  */
  }, 
  unmounted() {
    sessionStorage.setItem('SALE.saved', JSON.stringify({
      Group: this.selectedTreeLabel,
      Keyword: this.searchInput,
      Expanded: this.expandedTree,
      Selected: this.selectedTree,
      Selection: this.itemSelected,
      Row: this.salesRowSelection 
    }))

    if(TymConst.IS_DEVELOPMENT) {
      console.log('SALE.saved :', {
        Group: this.selectedTreeLabel,
        Keyword: this.searchInput,
        Expanded: this.expandedTree,
        Selected: this.selectedTree,
        Selection: this.itemSelected,
        Row: this.salesRowSelection 
      })
    }
  },
  methods : {
    unusedParam() {
      //
    },
    onResize(size) {
      this.unusedParam(size)
      this.arrangeTable()
    },
    onResizeProdTable(size) {
      this.unusedParam(size)

      if(this.prodRows.length >= 1) {
        if(!this.$refs.prodTableContainer) {
          this.maxItemPerProd[1] = [15]
        } else {
          let rowsPerPage = Math.floor((this.$refs.prodTableContainer.clientHeight - 118) / 32)
          this.maxItemPerProd[1] = rowsPerPage
        }
        this.curProdPagination.rowsPerPage = this.maxItemPerProd[1]
        this.prodPageCount = Math.ceil(this.prodRows.length / this.maxItemPerProd[1])
      }
    },
    arrangeTable() {
      if(!this.$refs.tableContainer) {
        this.maxItemPerPage[1] = [15]
      } else {
        let rowsPerPage = Math.floor((this.$refs.tableContainer.clientHeight - 68) / 30)
        this.maxItemPerPage[1] = rowsPerPage
      }
      this.curPagination.rowsPerPage = this.maxItemPerPage[1]
      this.pageCount = Math.ceil(this.salesCount / this.maxItemPerPage[1])
    },
    searchClicked() {
    },
    findGroup(node, name) {
      let result = null
      let self = this
      let temp = null
      if(!Array.isArray(node)) {
        temp = [node]
      } else {
        temp = node
      }

      for(let idx = 0; idx < temp.length; idx++) {
        let one = temp[idx]
        if(one.label == name) {
          result = one
          break;
        } else {
          let self2 = self
          for(let sub = 0; sub < one.children.length; sub++) {
            let child = one.children[sub]
            result = self2.findGroup(child, name)
            if(result)
              break
          }
        }
      }
      return result
    },
    getSalesDataOK(result) {
      if(result) {
        //
        this.updateSalesInfo()
      } else {
        //
        this.updateSalesInfo()
      }
      
      let saved = sessionStorage.getItem('SALE.saved')
      if(!TymCommon.isEmpty(saved)) {
        saved = JSON.parse(saved)

        this.expandedTree = saved.Expanded
        this.selectedTree = saved.Selected

        this.selectedTreeLabel = saved.Group
        this.searchInput = saved.Keyword

        if(!TymCommon.isEmpty(saved.Row) && (saved.Row >= 0)) {
          this.itemSelected = saved.Selection
          // this.salesRowSelection = saved.Row  // <-- 이건 다음 함수 안에서 비교에 사용하니 설정 금지
          this.salesRowClicked(saved.Row, this.itemListRows[saved.Row])
        }
        
        sessionStorage.removeItem('SALE.saved')
      }
      
      this.bottomWaiting = false
    },
    updateSalesInfo() {
      const store = useTymictStore()

      if(store.SalesData.data.length < 1)
        return
      if(this.groupTreeData.length < 1)
        return
      if(store.groupTree.length > 0) {
        this.groupTreeData = store.groupTree
        this.expandedTree = [this.groupTreeData[0].uuid]
        this.selectedTree = this.groupTreeData[0].uuid
        this.selectedTreeLabel = this.groupTreeData[0].label
      }
      /*
      {
        "Depth": 0,
        "Names": [
          "TYM NA",
          "Admin",
          "Machine Production",
          "Research",
          "TYM North America",
          "TYMICT"
        ]
      }
      */
      this.itemListRows = []
      let subGrps = store.getSubGroups(this.groupTreeData[0].uuid)
      let groups = store.findGroupNamesByKey(this.groupTreeData[0].uuid)
      switch(subGrps.Depth) {
        case 0:
          groups = groups.filter(x => TymCommon.isEmpty(x.uuid_b))
          break
        case 1:
          groups = groups.filter(x => TymCommon.isEmpty(x.uuid_c))
          break
        case 2:
          groups = groups.filter(x => TymCommon.isEmpty(x.uuid_d))
          break
        case 3:
          groups = groups.filter(x => !TymCommon.isEmpty(x.uuid_d))
          break
      }
      if(groups) {
        groups.forEach(grp => {
          //console.log('SALE 0003', grp, store.SalesData.data[15])
          let found = null
          switch(subGrps.Depth) {
            case 0:
              found = store.SalesData.data.filter(row => row.uuid_a == grp.uuid_a)
              break
            case 1:
              found = store.SalesData.data.filter(row => row.uuid_b == grp.uuid_b)
              break
            case 2:
              found = store.SalesData.data.filter(row => row.uuid_c == grp.uuid_c)
              break
            case 3:
              found = store.SalesData.data.filter(row => row.uuid_d == grp.uuid_d)
              break
          }
          if(found) {
            found.forEach(item => {
              if(TymCommon.isEmpty(item.Info)) {
                item.Info = TymCommon.getModelFullname(item.MON)
              }
              if(!TymCommon.isEmpty(item.mobile)) { 
                item.mobile = TymCommon.parsePhoneNumber(item.mobile, store.connectServer)
              }
              if(TymCommon.isEmpty(item.Info.Fullname)) {
                item.model = 'Unknown'
                console.log('Unknown :', item) 
              } else {
                item.model = item.Info.Fullname
              }
              this.itemListRows.push(item)
            })
          }
        })
      }
      /*
      let tempRows = []
      store.SalesData.data.forEach(item => {
        if(TymCommon.isEmpty(item.Info)) {
          item.Info = TymCommon.getModelFullname(item.model)
        }
        if(!TymCommon.isEmpty(item.mobile)) { 
          item.mobile = TymCommon.parsePhoneNumber(item.mobile, store.connectServer)
        }
        if(TymCommon.isEmpty(item.Info.Fullname)) {
          item.model = 'Unknown'
        } else {
          item.model = item.Info.Fullname
        }

        tempRows.push(item)
      })
      this.itemListRows = tempRows
      */
      
      this.salesCount = this.itemListRows.length
      this.pageCount = Math.ceil(this.itemListRows.length / this.maxItemPerPage[1])
      this.initEnvironment()

      this.arrangeTable()
      if(TymConst.IS_DEVELOPMENT) {
        console.log('salesView listItems :', this.itemListRows)
      }
    },
    groupTreeSelected(tgt) {
      const store = useTymictStore()

      if(this.isAddMode || this.isEditMode) {
        this.infoValue.dealer = store.findGroupNameByKey(this.selectedTree)
        // 
      } else {
        this.selectedTree = tgt
        let result = store.findGroupNameByKey(this.selectedTree)
        if(result) {
          this.selectedTreeLabel = result
        }
        this.$refs.groupPopup.hide()
      }
    },
    // 추가 수정시 - 판매점 트리에서 선택시
    groupSubTreeSelected(tgt) {
      const store = useTymictStore()
      this.unusedParam(tgt)

      if(this.isAddMode || this.isEditMode) {
        this.infoValue.dealer = store.findGroupNameByKey(this.selectedSubTree)
      }
    },
    salesFilterMethod() {
      /* 추가 수정에서도 똑같이 보이게 하자.
      if(this.isAddMode || this.isEditMode) {
        this.pageCount = Math.ceil(this.itemListRows.length / this.maxItemPerPage)
        return this.itemListRows;
      }
      */
      if(TymCommon.isEmpty(this.selectedTree) && TymCommon.isEmpty(this.searchInput)) {
        return this.itemListRows
      }

      const store = useTymictStore()
      let filtered = []
      let subGrps = store.getSubGroups(this.selectedTree)
      let groups = store.findGroupNamesByKey(this.selectedTree)
      if(subGrps) {
        switch(subGrps.Depth) {
          case 0:
            groups = groups.filter(x => TymCommon.isEmpty(x.uuid_b))
            break
          case 1:
            groups = groups.filter(x => TymCommon.isEmpty(x.uuid_c))
            break
          case 2:
            groups = groups.filter(x => TymCommon.isEmpty(x.uuid_d))
            break
          case 3:
            groups = groups.filter(x => !TymCommon.isEmpty(x.uuid_d))
            break
        }
      }

      // console.log('salesFilterMethod #0:', this.selectedTree, subGrps, groups, this.itemListRows)

      if(groups && subGrps) {
        groups.forEach(grp => {
          let found = null
          switch(subGrps.Depth) {
            case 0:
              found = this.itemListRows.filter(row => row.uuid_a == grp.uuid_a)
              break
            case 1:
              found = this.itemListRows.filter(row => row.uuid_b == grp.uuid_b)
              break
            case 2:
              found = this.itemListRows.filter(row => row.uuid_c == grp.uuid_c)
              break
            case 3:
              found = this.itemListRows.filter(row => row.uuid_d == grp.uuid_d)
              break
          }
          // console.log('salesFilterMethod #1:', grp, this.itemListRows)
          if(found) {
            found.forEach(item => {
              if(TymCommon.isEmpty(item.Info)) {
                item.Info = TymCommon.getModelFullname(item.model)
              }
              if(!TymCommon.isEmpty(item.mobile)) { 
                item.mobile = TymCommon.parsePhoneNumber(item.mobile, store.connectServer)
              }
              if(TymCommon.isEmpty(item.Info.Fullname)) {
                item.model = 'Unknown'
              } else {
                item.model = item.Info.Fullname
              }
              filtered.push(item)
            })
          }
        })
      } else {
        filtered = this.itemListRows
      }

      if(TymConst.IS_DEVELOPMENT) {
        // console.log('filter AFTER GROUP :', this.searchInput, filtered)
      }

      let oldCount = this.salesCount
      if(!TymCommon.isEmpty(this.searchInput)) {
        let upperInput = this.searchInput.toUpperCase()
        let found2 = filtered.filter(row =>
            (row.owner.toUpperCase().includes(upperInput) )
            || (row.model.toUpperCase().includes(upperInput) )
            || (row.machineNo.toUpperCase().includes(upperInput) )
            || (row.serialNo.toUpperCase().includes(upperInput) )
            || (row.dealer.toUpperCase().includes(upperInput) ) 
            || (row.mobile.toUpperCase().includes(upperInput) ) )
        if(!TymCommon.isEmpty(found2)) {
          this.salesCount = found2.length 
          if(oldCount != this.salesCount)
            this.arrangeTable()
            return found2
        } else {
          this.salesCount = 0
          if(oldCount != this.salesCount)
            this.arrangeTable()

          return []
        }
      } else {
        this.salesCount = filtered.length
        if(oldCount != this.salesCount) {
          this.arrangeTable()
        }
        return filtered
      }
    },
    itemPageChanged(value) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('slaesView.itemPageChanged :', this.curPagination, value)
      }
      this.curPagination.page = value
    },
    updateSelectionInfo(row) {
      const store = useTymictStore()
      this.infoValue = {}

      if(TymCommon.isEmpty(row))
        return

      if(TymConst.IS_DEVELOPMENT) {
        console.log('slaesView.updateSelectionInfo 1 :', row)
//        console.log('slaesView.updateSelectionInfo 2 :', store.SalesData.data)
//        console.log('slaesView.updateSelectionInfo 3 :', store.ModelData.data)
      }

      let lastChar = row.machineNo.substring(row.machineNo.length - 1)
      let acuData = null, tboxData = null
      let acuSN = null, tboxSN = null
      if(lastChar == 'A') {
        acuData = row
        acuSN = row.machineNo
        if(!TymCommon.isEmpty(row.machineNo)) {
          tboxSN = row.machineNo.substring(0, row.machineNo.length - 1)
          let found = store.SalesData.data.find(x => x.machineNo == tboxSN)
          if(found) {
            tboxData = found
          }
        }
      } else {
        tboxData = row
        tboxSN = row.machineNo
        acuSN = row.machineNo + 'A'
        let found = store.SalesData.data.find(x => x.machineNo == acuSN)
        if(found) {
          acuData = found
        }
      }

      this.infoValue.owner = row.owner
      this.infoValue.mobile = row.mobile
      this.infoValue.address = TymCommon.isEmpty(row.address) ? '' : row.address.trim()
      this.infoValue.modelName = row.model

      try {
        if(TymCommon.isEmpty(store.SalesData.data[row.index].Info)) {
          this.infoValue.MODEL = ''
          this.infoValue.OPTION = ''
        } else {
          this.infoValue.MODEL = store.SalesData.data[row.index].Info.Model
          this.infoValue.OPTION = store.SalesData.data[row.index].Info.Option
        }
      } catch(ex) {
        console.log('salesView EX :', row.index, ex)
      }
      
      let modelInfo = store.ModelData.data.find(x => x.model == row.Info.Model)

      let sdConv = null
      let edConv = null
      if(store.connectServer == 0) {
        sdConv = TymCommon.getDateTimeString(new Date(row.SD))
        // edConv = TymCommon.getDateTimeString(new Date(row.ED))
        edConv = TymCommon.SD2DateString2(new Date(row.ED))
      } else {
        sdConv = TymCommon.convDateTimeStringToLocalTime(row.SD)
        edConv = TymCommon.SD2DateString2(row.ED)
      }
      // console.log('=----->', row.SD, sdConv)

      this.infoValue.machineNo = tboxSN
      this.infoValue.prodDate = row.manDate
      // this.infoValue.saleDate = row.saleDate
      this.infoValue.saleDate = sdConv
      this.infoValue.ORG_SD = row.SD
      this.infoValue.expireDate = edConv
      this.infoValue.ORG_ED = row.ED
      this.infoValue.dealer = row.dealer
      this.infoValue.testRunTime = row.ST
      let foundModel = store.ModelData.data.find(x => x.model == row.Info.Model && x.option == row.Info.Option)
      this.infoValue.use_st = false
      if(foundModel) {
        this.infoValue.use_st = TymCommon.isEmpty(foundModel.use_st) ? false : foundModel.use_st
      }

      if(TymCommon.isEmpty(acuData)) {
        this.infoValue.acuSN = ''
        this.useACU = false
        this.infoValue.adsLevel = TymCommon.isEmpty(this.adsLevels) ? '' : this.adsLevels[0]
        this.infoValue.steeringDev = TymCommon.isEmpty(this.steeringDevs) ? '' : this.steeringDevs[0]
      } else {
        this.infoValue.acuSN = acuData.serialNo
        this.infoValue.adsLevel = this.adsLevels[acuData.adsLevel]
        this.infoValue.steeringDev = acuData.adsType
        this.useACU = true
      }

      if(TymCommon.isEmpty(tboxData)) {
        this.infoValue.serialNo = ''
        this.useTBOX = false
      } else {
        this.infoValue.serialNo = tboxData.serialNo
        if(TymCommon.isEmpty(acuData) && !TymCommon.isEmpty(this.adsLevels)) {
          this.infoValue.adsLevel = this.adsLevels[tboxData.adsLevel]
          this.infoValue.steeringDev = tboxData.DTYPE
        }
        this.useTBOX = true
      }
      if(TymCommon.isEmpty(modelInfo)) {
        this.infoValue.acuProdType = ''
      } else {
        this.infoValue.acuProdType = modelInfo.type
      }
      
      this.isViewCustomer = true;
      this.validTBOXSN = true
      this.validACUSN = true
      // console.log('updateSelectionInfo :', row, this.infoValue)
    },
    updatePagination(newPagination) {
      if(this.$refs.itemTable) {
        if(TymConst.IS_DEVELOPMENT) {
          console.log('saleView.updatePagination :', this.itemListRows[0], newPagination)
        }

        this.itemListRows.sort((a, b) => {
          let result = 0
          switch(newPagination.sortBy) {
            case 'model':
              if(a.Info.Model == b.Info.Model) {
                result = a.Info.Option - b.Info.Option  
              } else {
                result = a.Info.Model - b.Info.Model
              }
              break
            case 'machineNo':
              result = a.machineNo - b.machineNo
              break
            case 'serial':
              result = a.serialNo.localeCompare(b.serialNo)
              break
            case 'saleDate':
              result = a.SD.localeCompare(b.SD)
              break
            case 'manDate':
              result = a.PD.localeCompare(b.PD)
              break
            case 'owner':
              result = a.owner - b.owner
              break
            case 'dealer':
              result = a.dealer - b.dealer
              break
          }
          if(newPagination.descending)
            result = 0 - result
            
          if(result > 0)
            return 1
          else if(result < 0)
            return -1
          else 
            return 0
        })
      }
      this.curPagination = newPagination
      this.curPage = newPagination.page
    },
    updateModelInfo(row) {
      let imgCtrl = document.getElementById('viewModelImage')

      if(!TymCommon.isEmpty(row)) {
        const store = useTymictStore()

        /*
        row
        {
          "index": 4,
          "model": "TS130CP6ANB",
          "machineNo": "S130SX00464",
          "PD": "2022-04",
          "manDate": "4/2022",
          "serialNo": "T001215100560056",
          "adsLevel": 0,
          "adsType": "None",
          "owner": "김동욱",
          "mobile": "01035475781",
          "dealer": "상주딜러점(한승두)",
          "SD": "2023-01-18T12:35",
          "saleDate": "01/18/2023, 12:35",
          "address": "경북 상주시 낙동면 삼봉로 50-4 집",
          "uid": "ehddnr5781",
          "uuid_a": "0",
          "uuid_b": "2",
          "uuid_c": "12",
          "uuid_d": "1673850562813-gyeongbuk",
          "Info": {
            "Model": "TS130",
            "Option": "CP6ANB",
            "Fullname": "TS130CP6ANB"
          }
        }
        */
        if(store.ModelData && store.ModelData.count > 0) {
          // 모델이미지 표시부 - 삭제 20231026
          /*
          let foundModel = store.ModelData.data.find(x => x.model == row.Info.Model && x.option == row.Info.Option)
          if(foundModel) {
            this.modelName = row.model
            this.modelType = foundModel.type
            if(!TymCommon.isEmpty(foundModel.logo)) {
              TymAws.getS3Image(foundModel.logo, imgCtrl)
            } else {
              imgCtrl.src = require('../assets/company/title_logo.png')
            }
            this.modelDesc = foundModel.description
            this.modelHP = foundModel.hp
            this.modelOption = foundModel.option
          } else {
            this.modelName = ''
            this.modelType = ''
            imgCtrl.src = require('../assets/company/title_logo.png')
            this.modelDesc = ''
            this.modelHP = ''
            this.modelOption = ''
          }
          */
        }

        /*
        if(!TymCommon.isEmpty(row.logo)) {
          TymAws.getS3Image(row.logo, imgCtrl)
        } else {
          imgCtrl.src = require('../assets/company/title_logo.png')
        }
        this.modelDesc = ''
        this.modelHP = row.hp
        this.modelOption = row.option
        */
      } else {
        // 클리어
        this.modelName = ''
        this.modelType = ''
        imgCtrl.src = require('../assets/company/title_logo.png')
        this.modelDesc = ''
        this.modelHP = 0
        this.modelOption = ''
      }
    },
    updateCustomerInfo(row) {
      /*
      row
      {
        "index": 0,
        "name": "박홍권",
        "phone_org": "+821056891033",
        "mobile": "01056891033",
        "email": "null@tymict.com",
        "address": " 전남 함평군 대동면 학동로 448"
      }   
      */
      console.log('updateCustomerInfo :', row)
      this.owner = row.name
    },
    salesRowClicked(rowIndex, row) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('salesView.salesRowClicked :', rowIndex, row)
      }
      if(this.salesRowSelection == rowIndex) {
        this.salesRowSelection = -1
        this.itemSelected = []

        this.infoValue = {
          owner : '',
          mobile : '',
          address : '',
          modelName : '',
          MODEL : '',
          OPTION : '',
          machineNo : '',
          prodDate : '',
          serialNo : '',
          saleDate : '',
          expireDate : '',
          dealer : '',
          acuSN : '',
          acuProdType : '',
          adsLevel : '',
          steeringDev : '',
          use_st : false
        }

        return
      }
      this.firstTimeAfterSelect = true

      this.customerSelected = []
      this.salesRowSelection = rowIndex
      this.itemSelected = []
      this.itemSelected.push(row)
      this.updateSelectionInfo(row)
      // 변경된 시나리오에선 모델 정보 필요없음
      // this.updateModelInfo(row)

      TymAws.getLastPosByMN(row.machineNo)
        .then(data => {
          const store = useTymictStore()
          let info = store.machineLocaInfo.find(x => x.machineNo == row.machineNo)

          if(TymConst.IS_DEVELOPMENT) {
            console.log('salesView.salesRowClicked.lastPosition POWER :', data[4], data)
          }

          try {
            let posInfo = {}
            posInfo.machineNo = row.machineNo
            posInfo.address = row.address
            posInfo.name = row.owner
            posInfo.modelNo = row.Info.Fullname
            posInfo.latitude = data[1]
            posInfo.longitude = data[2]
            if(data.length > 4) {
              posInfo.PWR = data[4]
              if(!TymCommon.isEmpty(info)) {
                info.PWR = data[4]

                //let info2 = store.machineLocaInfo.find(x => x.machineNo == row.machineNo)
                //console.log('------------------', info2)
              }
            } else {
              posInfo.PWR = TymCommon.isEmpty(info) ? 'OFF' : info.PWR
            }

            this.$refs.occurLoca.updateMapData([posInfo], undefined, false)
            this.firstTimeAfterSelect = false
          } catch(ex) {
            console.log(ex)
            this.firstTimeAfterSelect = false
          }
        })
        .catch(er => {
          console.log('salesView.salesRowClicked.lastPosition ERROR :', er)
          this.$refs.occurLoca.updateMapData([], undefined, false)
          this.firstTimeAfterSelect = false
        })
    },
    // 모델내 라디오버튼 클릭
    changeSelection(value) {
      console.log('changeSelection :', value)
      this.salesRowSelection = value
      this.updateSelectionInfo(this.itemListRows[value])
      this.updateModelInfo(this.itemListRows[value])
    },

    updateSelection(newSel) {
      console.log('updateSelection : ', newSel.index, newSel, this.itemListRows[newSel.index])
      this.updateSelectionInfo(newSel[0])
      this.updateModelInfo(newSel[0])
    },
    listItemSelected(evt, row, index) {
      this.unusedParam(evt)
      this.unusedParam(index)

      console.log('listItemSelected : ', row, index)

      this.itemSelected = []
      this.itemSelected.push(row)

      this.updateSelectionInfo(row)
      this.updateModelInfo(row)
    },
    addSale() {
      this.itemSelected = []

      this.infoValue = {
        owner : '',
        mobile : '',
        address : '',
        modelName : '',
        machineNo : '',
        prodDate : '',
        serialNo : '',
        saleDate : '',
        dealer : ''
      }
      const now = new Date()
      this.infoValue.prodDate = TymCommon.getMonthString(now)
      this.infoValue.saleDate = TymCommon.getDateTimeString(now)
      this.customerRows = []
      this.isAddMode = true
      this.useTBOX = false
      this.useACU = false
      this.validTBOXSN = false
      this.validACUSN = false
      
      this.modelSelected = []

      this.searchingOwner = false
      this.searchingVIN = false
      this.searchingModel = false
      this.searchingDealer = false

      if(this.groupTreeData.length > 0)
        this.expandedTree = [this.groupTreeData[0].uuid]

      const store = useTymictStore()
      this.infoValue.dealer = store.groupName
      this.infoValue.testRunTime = '0'

      this.myGroupInfo = store.groupInfo.find(x => x.group_a == store.groupName)
      if(TymCommon.isEmpty(this.myGroupInfo)) {
        this.myGroupInfo = store.groupInfo.find(x => x.group_b == store.groupName)
      }
      if(TymCommon.isEmpty(this.myGroupInfo)) {
        this.myGroupInfo = store.groupInfo.find(x => x.group_c == store.groupName)
      }
      if(TymCommon.isEmpty(this.myGroupInfo)) {
        this.myGroupInfo = store.groupInfo.find(x => x.group_d == store.groupName)
      }
      console.log(store.groupName, this.myGroupInfo, this.expandedTree)
      //groupName

      //this.selectedSubTree
    },
    cancelAdd() {
      /*
      this.infoValue = {
        owner : '',
        mobile : '',
        address : '',
        modelName : '',
        machineNo : '',
        prodDate : '',
        serialNo : '',
        saleDate : '',
        dealer : ''
      }

      this.modelName = ''
      this.modelDesc = ''
      this.modelType = ''
      this.modelHP = 0
      this.modelOption = ''
      this.modelWeignt = 0
      this.modelImagePath = ''

      this.salesRowSelection = -1
      this.itemSelected = []

      this.searchingOwner = false;
      this.searchingVIN = false;
      this.searchingModel = false;
      this.searchingDealer = false;

      this.isViewCustomer = false

      this.expandedTree = []
      this.selectedTree = ''
      if(this.groupTreeData.length > 0)
        this.expandedTree = [this.groupTreeData[0].uuid]
      this.selectedTreeLabel = ''
      this.pageCount = Math.ceil(this.itemListRows.length / this.maxItemPerPage[0])
      */
      this.isAddMode = false
      this.isEditMode = false
    },
    customerPageChanged() {
      this.$refs.customerTable.setPagination({
        page:this.customerPagination
      })
    },
    VINPageChanged() {
      this.$refs.VINTable.setPagination({
        page:this.VINPagination
      })
    },
    clickSearchOwner() {
      this.searchingModel = false;
      this.searchingDealer = false;
      this.searchingOwner = true;
      this.searchingVIN = false
      this.customerSearchInput = ''

      setTimeout(() => {
        this.updateCustomerList()
      }, 5)
    },
    customerSearchClicked() {
      this.updateCustomerList()
    },
    VINSearchClicked() {
      // console.log('VINSearchClicked :', this.VINSearchInput)
      this.updateVINList()
    },
    arrangeData() {
      const store = useTymictStore()
      let i = 0

      // console.log('Customers :', store.AllCustomerData)
      store.AllCustomerData.forEach(customer => {
        /*
        {
          "createat": "2023-05-18T05:16:55.143Z",
          "userid": "1g10a101",
          "sub": "957ba92e-25dc-402e-85e1-4a9d69434542",
          "custom:cpinpw": "tymict",
          "email_verified": "true",
          "gender": "M",
          "custom:caddress2": "1",
          "custom:cbirthday": "19800101",
          "name": "전광철",
          "phone_number_verified": "true",
          "custom:caddress1": "전남 고흥군 도양읍 용정등넘길 29-16",
          "phone_number": "+821036261596",
          "email": "null@tymict.com",
          "index": 0,
          "uuid_a": "0",
          "uuid_b": "2",
          "uuid_c": "11",
          "uuid_d": "1646352753958-jeonnam"
        }
        */
        let phnumber = TymCommon.parsePhoneNumber(customer.phone_number, store.connectServer)
        if((customer.name.indexOf(this.customerSearchInput) >= 0) || (phnumber.indexOf(this.customerSearchInput) >= 0) || (customer.userid.indexOf(this.customerSearchInput) >= 0)) {
          this.customerRows.push({
            index : i,
            name : customer.name,
            phone_org : customer.phone_number,
            mobile : phnumber,
            email : customer.email,
            userid : customer.userid,
            //serial : customer.csn,
            address : customer['custom:caddress1']
          })
          i++
        }
      })
      this.customerRows.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
      this.customerPageCount = Math.ceil(this.customerRows.length / this.maxCustomerPerPage)
    },
    loadUserCompleted(isok) {
      this.unusedParam(isok)
      this.arrangeData()
    },
    updateCustomerList() {
      this.customerRows = []
      if(!TymCommon.isEmpty(this.customerSearchInput)) {
        const store = useTymictStore()

        if(store.CustomerData.length < 1) {
          store.getCustomerData(this.loadUserCompleted)
        } else {
          this.arrangeData()
        }
      }
    },
    updateVINList() {
      this.VINRows = []
      // console.log('updateVINList :', this.VINSearchInput, this.PDList)
      if(!TymCommon.isEmpty(this.VINSearchInput)) {
        this.VINRows = this.PDList.filter(x => x.MN.includes(this.VINSearchInput))
      } else {
        this.VINRows = this.PDList
      }
      this.VINPageCount = Math.ceil(this.VINRows.length / this.maxVINPerPage)
    },
    updateCustomerSelection(newSel) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('updateCustomerSelection : ', newSel, this.customerSelected)
      }
      this.updateCustomer(newSel[0])
    },
    customerRowClicked(rowIndex, row) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('customerRowClicked :', rowIndex, row)
      }

      this.customerSelected = []
      this.customerSelected.push(row)

      //console.log('listCustomerSelected : ', row, this.customerSelected)
      this.updateCustomer(row)
    },
    VINRowClicked(row) {
      const store = useTymictStore()

      if(!TymCommon.isEmpty(row.MN)) {
        let lastChar = row.MN.substring(row.MN.length - 1)
        let acuData = null, tboxData = null
        if(lastChar == 'A') {
          acuData = row
          let tboxMN = row.MN.substring(0, row.MN.length - 1)
          let found = this.PDList.find(x => x.MN == tboxMN)
          if(found) {
            tboxData = found
          }
        } else {
          tboxData = row
          let acuMN = row.MN + 'A'
          let found = this.PDList.find(x => x.MN == acuMN)
          if(found) {
            acuData = found
          }
        }

        /* 판매목록에서 정보 구하기
        let salesACU = null, salesTBOX = null
        if((row.SOLD == 1) && !TymCommon.isEmpty(row.MN)) {
          if(!TymCommon.isEmpty(acuData)) {
            salesACU = store.SalesData.data.find(x => x.machineNo == acuData.MN)
          }
          if(!TymCommon.isEmpty(tboxData)) {
              salesTBOX = store.SalesData.data.find(x => x.machineNo == tboxData.MN)
          }

          console.log('ACU, TBOX salesData :', salesACU, salesTBOX)
        }
        */
        
        this.infoValue.modelName = ''
        this.infoValue.prodDate = ''
        this.infoValue.acuProdType = ''
        this.infoValue.adsLevel = ''
        this.infoValue.steeringDev = ''
        this.infoValue.PD = ''

        let modelInfo = null
        if(!TymCommon.isEmpty(row.ModelInfo)) {
          modelInfo = store.ModelData.data.find(x => x.model == row.ModelInfo.Model)
        } else if(!TymCommon.isEmpty(acuData) && !TymCommon.isEmpty(acuData.ModelInfo)) {
          modelInfo = store.ModelData.data.find(x => x.model == acuData.ModelInfo.Model)
        } else if(!TymCommon.isEmpty(tboxData) && !TymCommon.isEmpty(tboxData.ModelInfo)) {
          modelInfo = store.ModelData.data.find(x => x.model == tboxData.ModelInfo.Model)
        }

        console.log('VINRowClicked ACU/TBOX/MODEL :', acuData, tboxData, modelInfo)

        if(!TymCommon.isEmpty(modelInfo)) {
          this.updateAutonomousInfo(modelInfo.type)
        }

        if(!TymCommon.isEmpty(acuData)) {
          this.useACU = true
          this.infoValue.acuSN = acuData.SN

          if(TymCommon.isEmpty(this.infoValue.acuProdType)) {
            if(!TymCommon.isEmpty(modelInfo)) {
              this.infoValue.steeringDev = modelInfo.ads_type
            }
          }

          if(!TymCommon.isEmpty(acuData.PD)) {
            console.log('5555 :', acuData.PD)
            this.infoValue.prodDate = TymCommon.PD2DateString(acuData.PD)
            this.infoValue.PD = acuData.PD
          }

          if(!TymCommon.isEmpty(acuData.ModelInfo)) {
            this.infoValue.MODEL = acuData.ModelInfo.Model
            this.infoValue.OPTION = acuData.ModelInfo.Option
            this.infoValue.modelName = acuData.ModelInfo.Fullname
          }
        } else {
          this.useACU = false
          this.infoValue.acuSN = ''
          /*
          this.infoValue.acuProdType = ''
          this.infoValue.adsLevel = ''
          this.infoValue.steeringDev = ''
          */
        }

        if(!TymCommon.isEmpty(tboxData)) {
          this.useTBOX = true
          this.infoValue.machineNo = tboxData.MN
          this.infoValue.serialNo = tboxData.SN

          if(!TymCommon.isEmpty(tboxData.PD)) {
            console.log('1111 :', tboxData.PD)
            this.infoValue.prodDate = TymCommon.PD2DateString(tboxData.PD)
            this.infoValue.PD = tboxData.PD
          }

          if(!TymCommon.isEmpty(tboxData.ModelInfo)) {
            this.infoValue.MODEL = tboxData.ModelInfo.Model
            this.infoValue.OPTION = tboxData.ModelInfo.Option
            this.infoValue.modelName = tboxData.ModelInfo.Fullname
          }
        } else {
          this.useTBOX = false
          this.infoValue.machineNo = ''
          this.infoValue.manDate = ''
        }

        if(TymCommon.isEmpty(this.infoValue.machineNo) && !TymCommon.isEmpty(acuData)) {
          // ACU만 장착 기대 선택시
          this.infoValue.machineNo = row.MN.substring(0, row.MN.length - 1)
        }

        if(TymCommon.isEmpty(this.infoValue.prodDate)) {
          if(!TymCommon.isEmpty(row.PD)) {
            console.log('2222 :', row.PD)
            this.infoValue.prodDate = TymCommon.PD2DateString(row.PD)
            this.infoValue.PD = row.PD
          } else if(!TymCommon.isEmpty(acuData)) {
            console.log('3333 :', acuData.PD)
            this.infoValue.prodDate = TymCommon.isEmpty(acuData.PD) ? '' : TymCommon.PD2DateString(acuData.PD)
            this.infoValue.PD = TymCommon.isEmpty(acuData.PD) ? '' : acuData.PD
          } else if(!TymCommon.isEmpty(tboxData)) {
            console.log('4444 :', tboxData.PD)
            this.infoValue.prodDate = TymCommon.isEmpty(tboxData.PD) ? '' : TymCommon.PD2DateString(tboxData.PD)
            this.infoValue.PD = TymCommon.isEmpty(tboxData.PD) ? '' : tboxData.PD
          }
        }
      }
    },
    updateCustomer(row) {
      this.infoValue.owner = row.name
      this.infoValue.mobile = row.mobile
      this.infoValue.address = TymCommon.isEmpty(row.address) ? '' : row.address.trim()
      this.infoValue.UserInfo = row
    },

    clickSearchModel() {
      this.searchingVIN = false
      this.searchingOwner = false
      this.searchingDealer = false
      this.searchingModel = true

      setTimeout(() => {
        this.updateModelList()
      }, 50)
    },
    updateModelList() {
      const store = useTymictStore()
      let i = 0

      this.modelRows = []
      for(i = 0; i < store.ModelData.count; i++) {
        /*
        {
            "citem06_sn": "",
            "option": "MARKET",
            "hp": "Unknown",
            "citem03_cc": "500",
            "citem05_cf": "50",
            "citem01_eb": true,
            "development": true,
            "citem01_sn": "",
            "citem03_eb": true,
            "citem03_sn": "",
            "citem05_sn": "",
            "citem07_eb": true,
            "citem05_eb": true,
            "citem07_cc": "300",
            "citem01_cf": "100",
            "citem03_cf": "500",
            "citem05_cc": "300",
            "ads_type": "EPS_CN",
            "citem01_cc": "500",
            "citem07_cf": "50",
            "model": "AFTER",
            "citem07_sn": "",
            "citem04_cf": "200",
            "citem02_eb": true,
            "citem02_sn": "",
            "citem06_eb": true,
            "citem04_sn": "",
            "citem04_eb": true,
            "citem02_cf": "500",
            "citem06_cc": "300",
            "citem04_cc": "200",
            "citem06_cf": "50",
            "citem02_cc": "500",
            "type": "AFTERMARKET"
        }
        */
        
        if(!store.isICTUser() && store.ModelData.data[i].development)
          continue

        let opt = ''
        if(store.ModelData.data[i].option != 'NONE' && store.ModelData.data[i].option != '0')
          opt = store.ModelData.data[i].option
        
        this.modelRows.push( {
          index : i,
          model : store.ModelData.data[i].model,
          ModelInfo : {
            Model : store.ModelData.data[i].model,
            Option : store.ModelData.data[i].option
          },
          option : opt,
          hp : store.ModelData.data[i].hp,
          type : store.ModelData.data[i].type,
        })
      }

      this.modelPagination = 1
      this.modelPageCount = Math.ceil(this.modelRows.length / this.maxItemPerPage[1])
    },
    modelPageChanged() {
      this.$refs.modelTable.setPagination({
        page:this.modelPagination
      })
    },
    updateModelSelection(newSel) {
      console.log('updateModelSelection : ', newSel, this.customerSelected)
      this.updateModel(newSel[0])
    },
    modelRowClicked(index, row) {
      this.modelSelected = []
      this.modelSelected.push(row)

      this.updateModel(row)
    },
    // 하단 우측 - 모델 선택 테이블에서 모델 선택시
    listModelSelected(evt, row, index) {
      this.unusedParam(evt)
      this.unusedParam(index)

      this.modelSelected = []
      this.modelSelected.push(row)

      this.updateModel(row)
    },
    updateModel(row) {
      let res = TymCommon.getModelFullname(row.model + '_' + row.option)
      this.infoValue.modelName = res.Fullname
      this.infoValue.MODEL = row.ModelInfo.Model 
      this.infoValue.OPTION = row.ModelInfo.Option

      this.updateAutonomousInfo(row.type)
    },
    searchVIN() {
      /*
      if(TymCommon.isEmpty(this.infoValue.machineNo)) {
        TymCommon.Toast(this.$t('warning.inputVIN'))
        return
      }
      */
      this.searchingModel = false
      this.searchingDealer = false
      this.searchingOwner = false
      this.searchingVIN = true

      this.VINSearchInput = this.infoValue.machineNo
      if(this.PDList.length == 0) {
        TymAws.getPDList()
          .then(data => {
            this.PDList = data
            // console.log('PDLIST :', this.PDList)
            this.PDList.forEach(pd => {
              if(!TymCommon.isEmpty(pd.MON)) {
                let fullname = TymCommon.getModelFullname(pd.MON)
                pd.MODEL = fullname.Fullname
                pd.ModelInfo = fullname
              }
            })
            this.PDList.sort((a, b) => { return a.MN.localeCompare(b.MN)})

            if(!TymCommon.isEmpty(this.VINSearchInput))
              this.VINSearchClicked()
          })
          .catch(er => {
            console.log('PDLIST fail :', er)
            this.PDList = []
          })
      } else {
        this.VINSearchClicked()
        /*
        if(!TymCommon.isEmpty(this.VINSearchInput))
          this.VINSearchClicked()
        else {
          this.VINSearchClicked()
        }
        */
      }

      /*
      {
        "SOLD": 1,
        "MN": "S130SX00528",
        "PD": "2022-05",
        "TESTC": 1,
        "OD": "2022-05-31",
        "MON": "TS130_CP6ANB",
        "SN": "T001221400190019",
        "UID": "registration"
      }

      TymAws.getMachineByMN(this.infoValue.machineNo)
        .then(data => {
          console.log('getMachineByMN :', data)
          this.infoValue.prodDate = data[0].PD
          this.infoValue.serialNo = data[0].SN
        }).catch(err => {
          console.log('getMachineByMN Error :', err)
        })
      */
    },
    selectedProdDate(value, reason, details) {
      this.unusedParam(value)
      this.unusedParam(reason)

      let selDate = new Date(details.year, details.month - 1, details.day)
      this.infoValue.prodDate = this.selectedProdDateCtrl = TymCommon.getMonthString(selDate)
      // console.log('selectedProdDateCtrl :', details,  "=>", this.selectedProdDateCtrl)
      
      this.$refs.addProductDatePopup.hide()
    },
    clickSearchDealer() {
      this.searchingVIN = false
      this.searchingOwner = false
      this.searchingModel = false
      this.searchingDealer = true
    },
    clearGroupData() {
      this.selectedTree = ''
      this.selectedTreeLabel = ''
      this.salesRowSelection = -1
      //this.$refs.itemTable.clearSelection()
    },
    moveToManagement() {
      const store = useTymictStore()

      /*
      {
          "index": 0,
          "name": "사용자십번",
          "phone": "010-7928-3656",
          "uid": "t130t10",
          "machineNo": "TYMICT_A4_9",
          "serialNo": "A001221500090009",
          "dealer": "TYMICT",
          "state": 0,
          "desc": "차량 전복",
          "date": "2022. 06. 13. 11:00"
      }
      */

      let data = this.itemListRows.find(x => x.index == this.salesRowSelection)
      if(TymCommon.isEmpty(data)) {
        console.log('기대정보 이동 실패 :', this.salesRowSelection)
        return
      }

      let hostData = {
/*
        address : data.address,
        saleDate : data.saleDate,
        SD : data.SD,
        manDate : data.manDate,
        PD : data.PD,
        model : data.model,
*/
        index: data.index,
        name: data.owner,
        phone: data.mobile,
        uid: data.uid,
        machineNo: data.machineNo,
        serialNo: data.serialNo,
        dealer: data.dealer,
        state: 0,
        desc: "",
        date: ""
      }

      store.selectManageInfo = { kind: 0x0FFF, data: hostData };

      // console.log('SalesView.moveToManagement :', hostData, store.selectManageInfo)
      // Vue Router 통해 페이지 전환 시킴...
      // 2023.03.10 여길 팝업형태로 바꿔야하는 데 방법이...?
      store.setPage(TymConst.PAGE_MANAGE_POPUP);
    },
    editCustomer() {
      this.isEditMode = true
      this.modelSelected = []
    },
    beforeSelectProdDate() {
      try {
        const myDate = moment(this.infoValue.prodDate).toDate();
        this.selectedProdDateCtrl = moment(myDate).format("YYYY/MM/DD")
        // console.log('beforeSelectProdDate() :', this.selectedProdDateCtrl, myDate)
      } catch(err) {
        console.log('showEmSolveDate error :', err)
      }
    },
    beforeSaleDate() {
      try {
        const myDate = moment(this.infoValue.saleDate).toDate()
        // const myDate = new Date(this.infoValue.saleDate)
        this.saleDateValue = moment(myDate).format("YYYY/MM/DD")
        // console.log('beforeSaleDate() :', this.infoValue.saleDate, myDate)
      } catch(err) {
        console.log('beforeSaleDate error :', err)
      }
    },
    updateSaleDate(value, reason, details) {
      this.unusedParam(value)
      this.unusedParam(reason)
      if(TymConst.IS_DEVELOPMENT) {
        console.log('sales.updateSaleDate:', value, reason, details)
      }

      try {
        const myDate = moment(this.infoValue.saleDate).toDate();
        myDate.setFullYear(details.year, details.month - 1, details.day)
        this.infoValue.saleDate = this.saleDate = TymCommon.getDateTimeString(myDate)
      } catch(err) {
        console.log('updateSaleDate error :', err)
      }
      
      this.$refs.saleDatePopup.hide()
    },
    beforeSaleTime() {
      try {
        let sdConv = TymCommon.convDateTimeStringToLocalTime(this.infoValue.ORG_SD)
        this.saleTimeValue = moment(sdConv).format("HH:mm")
        // console.log('beforeSaleTime() :', sdConv, this.saleTimeValue)
      } catch(err) {
        console.log('beforeSaleTime error :', err)
      }
    },
    cancelSaleTime() {
      this.$refs.saleTimePopup.hide()
    },
    confirmSaleTime() {
      const myDate = moment(this.infoValue.saleDate);
      const newTime = moment(this.saleTimeValue, "HH:mm")

      myDate.hour(newTime.hour())
      myDate.minute(newTime.minute())

      //console.log('confirmSaleTime :', myDate, this.saleTimeValue, newTime.hours(), newTime.minutes())
      this.infoValue.saleDate = TymCommon.getDateTimeString(myDate.toDate())
      this.$refs.saleTimePopup.hide()
    },
    beforeExpireDate() {
      try {
        const myDate = moment(this.infoValue.expireDate).toDate()
        this.expireDateValue = moment(myDate).format("YYYY/MM/DD")
      } catch(err) {
        console.log('beforeExpireDate error :', err)
      }
    },
    updateExpireDate(value, reason, details) {
      this.unusedParam(value)
      this.unusedParam(reason)
      if(TymConst.IS_DEVELOPMENT) {
        console.log('sales.updateExpireDate:', value, reason, details)
      }

      try {
        const myDate = moment(this.infoValue.expireDate).toDate();
        myDate.setFullYear(details.year, details.month - 1, details.day)
        this.infoValue.expireDate = this.expireDate = TymCommon.getDateString(myDate)
      } catch(err) {
        console.log('updateExpireDate error :', err)
      }
      
      this.$refs.expireDatePopup.hide()
    },

    onShowExportToExcel() {
      this.makingExcel = true;

      let now = new Date()
      this.excelFilename = 'saleslist_' + now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += '_' + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += '.xlsx'
      this.excelDealer = this.selectedTreeLabel
      this.excelType = this.searchInput

      setTimeout(() => {
        try {
          this.sheets = []
          try {
            this.makeExcelData()
          } catch(ex) {
            console.log(ex)
          }
          setTimeout(() => {
            this.makingExcel = false;
          }, 100)
        } catch(ex) {
          console.log(ex)
          this.makingExcel = false;
        }
      }, 25)
    },
    makeExcelData() {
      const store = useTymictStore()

      let filtered = []
      // 딜러점 찾기 : 키(UUID)를 기반으로 대리점이름 리스트 구하기(조직)
      if(!TymCommon.isEmpty(this.selectedTree)) {
        let subGrps = store.getSubGroups(this.selectedTree)
        let groups = store.findGroupNamesByKey(this.selectedTree)
        if(subGrps) {
          switch(subGrps.Depth) {
            case 0:
              groups = groups.filter(x => TymCommon.isEmpty(x.uuid_b))
              break
            case 1:
              groups = groups.filter(x => TymCommon.isEmpty(x.uuid_c))
              break
            case 2:
              groups = groups.filter(x => TymCommon.isEmpty(x.uuid_d))
              break
            case 3:
              groups = groups.filter(x => !TymCommon.isEmpty(x.uuid_d))
              break
          }
        }
        if(groups && subGrps) {
          groups.forEach(grp => {
            let found = null
            switch(subGrps.Depth) {
              case 0:
                found = this.itemListRows.filter(row => row.uuid_a == grp.uuid_a)
                break
              case 1:
                found = this.itemListRows.filter(row => row.uuid_b == grp.uuid_b)
                break
              case 2:
                found = this.itemListRows.filter(row => row.uuid_c == grp.uuid_c)
                break
              case 3:
                found = this.itemListRows.filter(row => row.uuid_d == grp.uuid_d)
                break
            }
            // console.log('salesFilterMethod #1:', grp, this.itemListRows)
            if(found) {
              found.forEach(item => {
                if(TymCommon.isEmpty(item.Info)) {
                  item.Info = TymCommon.getModelFullname(item.model)
                }
                if(!TymCommon.isEmpty(item.mobile)) { 
                  item.mobile = TymCommon.parsePhoneNumber(item.mobile, store.connectServer)
                }
                if(TymCommon.isEmpty(item.Info.Fullname)) {
                  item.model = 'Unknown'
                } else {
                  item.model = item.Info.Fullname
                }
                filtered.push(item)
              })
            }
          })
        } else {
          filtered = this.itemListRows
        }
      } else {
        filtered = this.itemListRows
      }
      //console.log('filter GROUP (Sales) :', filtered)

      if(!TymCommon.isEmpty(this.searchInput)) {
        let upperInput = this.searchInput.toUpperCase()
        filtered = filtered.filter(row =>
            (row.owner.toUpperCase().includes(upperInput) )
            || (row.model.toUpperCase().includes(upperInput) )
            || (row.machineNo.toUpperCase().includes(upperInput) )
            || (row.serialNo.toUpperCase().includes(upperInput) )
            || (row.dealer.toUpperCase().includes(upperInput) ) 
            || (row.mobile.toUpperCase().includes(upperInput) ) )
      }
      
      // 최신이 앞으로
      filtered.sort((a, b) => {
        return b.SD.localeCompare(a.SD)
      })
      // console.log('filter (Model or Option) :', filtered)

      // 전체 긴급알람 리스트 출력
      let xlsRow1 = []
      xlsRow1.push('No', '기종', '모델명', '기대번호',
                  '제조 연월', '시리얼 번호', '판매 일시', 
                  '고객명', '고객 연락처',
                  '판매 지역', '판매점', '판매점 연락처')
      let rowNo = 1
      let othRows = []

      filtered.forEach(one => {
        //let modelName = TymCommon.getModelFullname(one.model)
        let saleDate = TymCommon.SD2DateString(one.SD)
        let myGroup = null
        let myGrpName = ''
        let ownerGrpName = ''
        let grpPhone = ''
        if(!TymCommon.isEmpty(one.uuid_d)) {
          myGroup = store.groupInfo.find(x => x.uuid_d == one.uuid_d)
          if(!TymCommon.isEmpty(myGroup)) {
            grpPhone = myGroup.phoneno
            myGrpName = myGroup.group_d
            ownerGrpName = myGroup.group_c
          }
        } else if(!TymCommon.isEmpty(one.uuid_c)) {
          myGroup = store.groupInfo.find(x => x.uuid_c == one.uuid_c)
          if(!TymCommon.isEmpty(myGroup)) {
            grpPhone = myGroup.phoneno
            myGrpName = myGroup.group_c
            ownerGrpName = myGroup.group_b
          }
        } else if(!TymCommon.isEmpty(one.uuid_b)) {
          myGroup = store.groupInfo.find(x => x.uuid_b == one.uuid_b)
          if(!TymCommon.isEmpty(myGroup)) {
            grpPhone = myGroup.phoneno
            myGrpName = myGroup.group_b
            ownerGrpName = myGroup.group_a
          }
        } else if(!TymCommon.isEmpty(one.uuid_a)) {
          myGroup = store.groupInfo.find(x => x.uuid_a == one.uuid_a)
          if(!TymCommon.isEmpty(myGroup)) {
            myGrpName = myGroup.phoneno
            ownerGrpName = myGroup.group_a
          }
        }
        othRows.push([rowNo.toString(), one.Info.Model, one.Info.Fullname, one.machineNo,
                    one.manDate, one.serialNo, saleDate, /*TymCommon.getDateTimeString(saleDate),*/
                    one.owner, one.mobile,
                    ownerGrpName, myGrpName, grpPhone])
        rowNo++
      })
      othRows.splice(0, 0, xlsRow1)

      othRows.splice(0, 0, [])
      if(!TymCommon.isEmpty(this.excelType)) {
        othRows.splice(0, 0, ['검색 조건', this.excelType])
      }
      othRows.splice(0, 0, ['지점/지사', this.excelDealer])
//      console.log('makeExcel (xls list) :', headerRows)
      this.sheets.push(
        {
          name: '판매목록 ' + this.selectedTreeLabel,
          data: othRows
        }
      )
    },
    saveToExcelFile() {
      let desc = {
        filename: this.excelFilename,
        dealer: this.excelDealer,
        keyword: this.excelType
      }
      TymAws.writeAction('판매', '다운로드', desc)

      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 500)
    },
    checkACU(value, evt) {
      this.unusedParam(value, evt)
      this.updateAutonomousInfo(this.infoValue.acuProdType)
    },
    checkMNandTBOX() {
      if(TymCommon.isEmpty(this.infoValue.serialNo)) {
        TymCommon.Toast(this.$t('warning.inputSN'))
        return
      }
      if(this.isEditMode && this.itemSelected[0].serialNo == this.infoValue.serialNo) {
        this.validTBOXSN = true
      } else {
        const store = useTymictStore()
        if(store.SalesData.data.find(x => x.serialNo == this.infoValue.serialNo)) {
          // 판매등록된 시리얼...
          this.validTBOXSN = false
          TymCommon.Toast(this.$t('warning.duplicatedSN'))
          return
        } else {
          TymAws.getMachineMN(this.infoValue.machineNo, this.infoValue.serialNo)
            .then(data => {
              console.log('getMachineMN :', this.infoValue.serialNo, data)
              if(data.RETURN) {
                this.validTBOXSN = true
              } else {
                this.validTBOXSN = false
                TymCommon.Toast(this.$t('warning.notRegisteredSN'))
              }
            })
            .catch(er => {
              console.log('Not valid TBOX S/N :', er)
              this.validTBOXSN = false
              TymCommon.Toast(this.$t('warning.notRegisteredSN'))
            })
        }
      }
    },
    checkMNandACU() {
      if(TymCommon.isEmpty(this.infoValue.acuSN)) {
        TymCommon.Toast(this.$t('warning.inputSN'))
        return
      }
      const store = useTymictStore()
      if(store.SalesData.data.find(x => x.serialNo == this.infoValue.acuSN)) {
        // 판매등록된 시리얼...
        this.validACUSN = false
        TymCommon.Toast(this.$t('warning.duplicatedSN'))
        return
      } else {
        TymAws.getMachineMN(this.infoValue.machineNo, this.infoValue.acuSN)
          .then(data => {
            console.log('getMachineMN :', this.infoValue.acuSN, data)
            if(data.RETURN) {
              this.validACUSN = true
            } else {
              this.validACUSN = false
              TymCommon.Toast(this.$t('warning.notRegisteredSN'))
            }
          })
          .catch(er => {
            console.log('Not valid TBOX S/N :', er)
            this.validACUSN = false
            TymCommon.Toast(this.$t('warning.notRegisteredSN'))
          })
      }
    },
    // 자율주행 정보 업데이트
    // dType : 트랙터, 이앙기, ....
    updateAutonomousInfo(dType) {
      if(TymCommon.isEmpty(dType))
        return
      const store = useTymictStore()
      if(TymCommon.isEmpty(store.WebConfig))
        return

      let index = store.WebConfig.MachineTypes[store.connectServer].indexOf(dType)
      // console.log('updateAutonomousInfo :', dType, index, store.WebConfig)
      if(index >= 0) {
        let defLevel = parseInt(store.WebConfig.ADSLevelDefault[index])
        if(defLevel >= 0) {
          this.infoValue.adsLevel = this.adsLevels[defLevel]
        }
        this.infoValue.acuProdType = dType
        this.infoValue.steeringDev = store.WebConfig.AutoSteer[store.connectServer][index]
      }
    },
    eraseTBOXsn() {
      this.infoValue.serialNo = ''
    },
    changedTBOXSN(value) {
      if(TymCommon.isEmpty(value)) {
        this.validTBOXSN = false
      } else {
        this.validTBOXSN = false
        if(value[0] != 'T') {
          TymCommon.MsgBox('Error', this.$t('warning.noTboxSerial'), this.eraseTBOXsn, this.eraseTBOXsn)
        }
      }
    },
    eraseACUsn() {
      this.infoValue.acuSN = ''
    },
    changedACUSN(value) {
      if(TymCommon.isEmpty(value)) {
        this.validACUSN = false
      } else {
        this.validACUSN = false
        if(value[0] != 'A') {
          TymCommon.MsgBox('Error', this.$t('warning.noACUSerial'), this.eraseACUsn, this.eraseACUsn)
        }
      }
    },
    /* 의미없음...
    inputVINChanged(value) {
      if(value.length == 1) {
        if(value == 'A') {
          console.log('ACU 모델')
          this.useACU = true
        } else if(value == 'T') {
          console.log('TBOX 모델')
          this.useTBOX = true
        } else {
          console.log('Unknown ...')
        }
      }
    }
    */
    // 삭제 비밀번호 표시
    deleteSale() {
      this.deleteModuleInfo = null
      /*
      if(TymConst.IS_DEVELOPMENT) {
        this.deleteSaleOK()
      } else {
      */
        this.inputDeletePassword = ''
        this.reenterPassword = false
        this.eraseNoticeMsg = this.$t('noticeView.deleteSalesInfo')
        this.showDeletePassword = true
      // }
    },
    // 패스워드 대화상자에서 확인 누름...
    enteredPassword() {
      try {
        if(!TymCommon.isEmpty(this.inputDeletePassword)) {
          TymAws.checkPassword(this.inputDeletePassword, this.deleteSaleOK, this.deleteSaleFailed)
        }
      } catch(ex) {
        console.log(ex)
      }
      this.showDeletePassword = false
    },
    deleteSaleOK() {
      this.deleteModuleInfo = this.getTboxAcuInfo(this.infoValue.machineNo)
      console.log('deleteSale password check OK', this.infoValue, this.deleteModuleInfo)
      if(TymCommon.isEmpty(this.deleteModuleInfo))
        return
      if(!TymCommon.isEmpty(this.deleteModuleInfo.ACU)) {
        this.showDeleteACU = true
      } else if(!TymCommon.isEmpty(this.deleteModuleInfo.TBOX)) {
        this.showDeleteTBOX = true
      }
    },
    deleteSaleFailed(code, msg) {
      console.log('deleteSale password check Failed :', code, msg)
      /*
      switch(code) {
        case 'NotAuthorizedException':
      }
      */
      TymCommon.Toast(msg, true)
    },
    deleteCancelACU() {
      if(!TymCommon.isEmpty(this.deleteModuleInfo.TBOX)) {
        this.showDeleteTBOX = true
      } else {
        // ACU삭제 취소했는데 TBOX정보가 없으면??? 뭘해야 하나?
        setTimeout(() => {
          const store = useTymictStore()
          store.getGroupSalesData(this.getSalesDataOK)
        }, 5)
      }
    },
    deleteConfirmACU() {
      console.log('deleteConfirmACU ', this.deleteModuleInfo.ACU)
      const store = useTymictStore()

      if(!TymCommon.isEmpty(this.deleteModuleInfo.ACU)) {
        TymAws.removeSalesData(this.deleteModuleInfo.ACU.machineNo, this.deleteModuleInfo.ACU.serialNo)
          .then(data => {
            console.log('deleteConfirmACU OK :', data)
            this.deleteModuleInfo.ACU = null
            TymAws.lookup(TymConst.ACTION_DEL_PRODUCT, '')
            setTimeout(() => {
              if(!TymCommon.isEmpty(this.deleteModuleInfo.TBOX)) {
                // TBOX 지우러 가자...
                this.showDeleteTBOX = true
              } else {
                setTimeout(() => {
                  store.getGroupSalesData(this.getSalesDataOK)
                }, 5)
              }
            }, 5)
          })
          .catch(er => {
            console.log('deleteConfirmACU FAILED :', er)
          })
      } else {
        if(!TymCommon.isEmpty(this.deleteModuleInfo.TBOX)) {
          // TBOX 지우러 가자...
          this.showDeleteTBOX = true
        } else {
          setTimeout(() => {
            store.getGroupSalesData(this.getSalesDataOK)
          }, 5)
        }
      }
    },
    deleteCancelTBOX() {
      setTimeout(() => {
        const store = useTymictStore()
        store.getGroupSalesData(this.getSalesDataOK)
      }, 5)
    },
    deleteConfirmTBOX() {
      console.log('deleteConfirmTBOX ', this.deleteModuleInfo.TBOX)
      const store = useTymictStore()

      if(!TymCommon.isEmpty(this.deleteModuleInfo.TBOX)) {
        TymAws.removeSalesData(this.deleteModuleInfo.TBOX.machineNo, this.deleteModuleInfo.TBOX.serialNo)
          .then(data => {
            console.log('deleteConfirmTBOX OK :', data)
            this.deleteModuleInfo.TBOX = null

            setTimeout(() => {
              store.getGroupSalesData(this.getSalesDataOK)
            }, 5)
          })
          .catch(er => {
            console.log('deleteConfirmTBOX FAILED :', er)

            setTimeout(() => {
              store.getGroupSalesData(this.getSalesDataOK)
            }, 5)
          })
      }
    },
// mn 기반으로 TBOX기대와 ACU기대 정보를 가져온다.
    getTboxAcuInfo(mn) {
      const store = useTymictStore()

      let lastChar = mn.substring(mn.length - 1)
      let acuData = null, tboxData = null
      let acuMN = '', tboxMN = ''

      if(lastChar == 'A') {
        acuMN = mn
        tboxMN = mn.substring(0, mn.length - 1)
      } else {
        tboxMN = mn
        acuMN= tboxMN + 'A'
      }

      acuData = store.SalesData.data.find(x => x.machineNo == acuMN)
      tboxData = store.SalesData.data.find(x => x.machineNo == tboxMN)

      //console.log('ACU :', acuMN, acuData, store.SalesData.data)
      //console.log('TBOX :', tboxMN, tboxData)

      return {
        ACU : acuData,
        TBOX : tboxData
      }
    },
    saveSaleInfo() {
      const store = useTymictStore()
      /*
      infoValue : ref({
        owner : '',
        mobile : '',
        address : '',
        modelName : '',
        MODEL : '',
        OPTION : '',
        machineNo : '',
        prodDate : '',
        serialNo : '',
        saleDate : '',
        dealer : '',
        acuSN : '',
        acuProdType : '',
        adsLevel : '',
        steeringDev : ''
      }),
      */

      // 유효성 체크
      if(TymCommon.isEmpty(this.infoValue.owner)) {
        TymCommon.Toast(this.$t('warning.noOwner'))
        return;
      }
      if(TymCommon.isEmpty(this.infoValue.dealer)) {
        TymCommon.Toast(this.$t('warning.noDealer'))
        return;
      }
      if(TymCommon.isEmpty(this.infoValue.machineNo)) {
        TymCommon.Toast(this.$t('warning.noMachineInfo'))
        return;
      }
      if(TymCommon.isEmpty(this.infoValue.modelName)) {
        TymCommon.Toast(this.$t('warning.noModelInfo'))
        return;
      }

      // MN의 마지막 글자가 A가 붙으면 ACU용 기대임...
      let lastChar = this.infoValue.machineNo.substring(this.infoValue.machineNo.length - 1)
      let tboxFound = null, acuFound = null
      if(lastChar == 'A') {
        tboxFound = store.SalesData.data.find(x => x.machineNo == this.infoValue.machineNo.substring(0, this.infoValue.machineNo.length - 1))
        acuFound = store.SalesData.data.find(x => x.machineNo == this.infoValue.machineNo)
      } else {
        tboxFound = store.SalesData.data.find(x => x.machineNo == this.infoValue.machineNo) 
      }

      if(this.isAddMode) {
        if(!TymCommon.isEmpty(tboxFound) || !TymCommon.isEmpty(acuFound)) {
          // 추가모드, 판매데이터에 해당 기대번호가 존재하네?
          TymCommon.Toast(this.$t('warning.registeredMachine'))
          return
        }
      } else if(this.isEditMode)  {
        if(!TymCommon.isEmpty(tboxFound) && !TymCommon.isEmpty(acuFound)) {
          // 편집모드, 판매데이터에 해당 기대번호가 없네?
          TymCommon.Toast(this.$t('warning.notRegisteredMachine'))
          return
        }
      }
      if(!this.useTBOX && !this.useACU) {
        // 둘 중 하나는 등록해야지?
        TymCommon.Toast(this.$t('warning.noTboxOrACU'))
        return
      }
      if(this.useTBOX && !this.validTBOXSN) {
        TymCommon.Toast(this.$t('warning.checkTBOXSerial'))
        return
      }
      if(this.useACU && !this.validACUSN) {
        TymCommon.Toast(this.$t('warning.checkACUSerial'))
        return
      }

      let tboxSN = null, acuSN = null
      tboxSN = store.SalesData.data.find(x => x.serialNo == this.infoValue.serialNo)
      acuSN = store.SalesData.data.find(x => x.serialNo == this.infoValue.acuSN)
      if(this.isAddMode) {
        if(!TymCommon.isEmpty(tboxSN) || !TymCommon.isEmpty(acuSN)) {
          TymCommon.Toast(this.$t('warning.duplicatedSN'))
          return
        }
      } else if(this.isEditMode)  {
        /*
        if(TymCommon.isEmpty(tboxSN) && TymCommon.isEmpty(acuSN)) {
          console.log('Edit mode, not registered..', tboxSN, acuSN)
          TymCommon.Toast(this.$t('warning.notRegisteredSN'))
          return
        }
        */
      }

      let snBox = null
      if(this.useTBOX) {
        if(TymCommon.isEmpty(this.infoValue.serialNo)) {
          TymCommon.Toast(this.$t('warning.noTBOXSerial'))
          return;
        } else if(this.infoValue.serialNo[0] != 'T') {
          TymCommon.Toast(this.$t('warning.checkTBOXSerial'))
          return;
        } else {
          snBox = store.SalesData.data.find(x => x.serianNo == this.infoValue.serialNo)
          //console.log('saveSaleInfo #1-1 TBOX ', snBox, this.isEditMode, this.isAddMode, this.infoValue.serialNo, store.SalesData.data)
          if(this.isEditMode && !TymCommon.isEmpty(snBox)) {
            TymCommon.Toast(this.$t('warning.notRegTBOX'))
            return
          } else if(this.isAddMode && !TymCommon.isEmpty(snBox)) {
            TymCommon.Toast(this.$t('warning.registeredTBOX'))
            return
          }
        }
      }

      if(this.useACU) {
        if(TymCommon.isEmpty(this.infoValue.acuSN)) {
          TymCommon.Toast(this.$t('warning.noACUSerial'))
          return;
        } else if(this.infoValue.acuSN[0] != 'A') {
          TymCommon.Toast(this.$t('warning.checkACUSerial'))
          return;
        } else {
          //
        }
      }

      let acuTboxInfo = this.getTboxAcuInfo(this.infoValue.machineNo)
      if(!TymCommon.isEmpty(acuTboxInfo.ACU) && !this.useACU) {
        console.log('Remove old ACU!!!')
        this.showSaveDeleteACU = true
        return
      }
      if(!TymCommon.isEmpty(acuTboxInfo.TBOX) && !this.useTBOX) {
        console.log('Remove old TBOX!!!')
        this.showSaveDeleteTBOX = true
        return
      }
      this.saveSaleInfo2Cloud()
    },
    saveDeleteCancelACU() {
      console.log('saveDeleteCancelACU')
    },
    saveDeleteConfirmACU() {
      console.log('saveDeleteConfirmACU')

      let info = this.getTboxAcuInfo(this.infoValue.machineNo)
      if(!TymCommon.isEmpty(info.ACU)) {
        TymAws.removeSalesData(info.ACU.machineNo, info.ACU.serialNo)
          .then(data => {
            console.log('saveDeleteConfirmACU OK :', data)
            setTimeout(() => {
              this.saveSaleInfo2Cloud()
            }, 5)
          })
          .catch(er => {
            console.log('saveDeleteConfirmACU FAILED :', er)
            setTimeout(() => {
              this.saveSaleInfo2Cloud()
            }, 5)
          })
      }
    },
    saveDeleteCancelTBOX() {
      console.log('saveDeleteCancelTBOX')
      setTimeout(() => {
        this.saveSaleInfo2Cloud()
      }, 5)
    },
    saveDeleteConfirmTBOX() {
      console.log('saveDeleteConfirmTBOX')

      const store = useTymictStore()
      let info = this.getTboxAcuInfo(this.infoValue.machineNo)
      if(!TymCommon.isEmpty(info.TBOX)) {
        TymAws.removeSalesData(info.TBOX.machineNo, info.TBOX.serialNo)
          .then(data => {
            console.log('saveDeleteConfirmTBOX OK :', data)
            setTimeout(() => {
              this.saveSaleInfo2Cloud()
            }, 5)
          })
          .catch(er => {
            console.log('saveDeleteConfirmTBOX FAILED :', er)
            setTimeout(() => {
              store.getGroupSalesData(this.getSalesDataOK)
            }, 5)
          })
      } else {
        setTimeout(() => {
          store.getGroupSalesData(this.getSalesDataOK)
        }, 5)
      }
    },
    makeKrPhoneNumber(value) {
      value = value.replace(/[^0-9]/g, "");
      return value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")
    },
    saveSaleInfo2Cloud() {
      const store = useTymictStore()
      
      this.bottomWaiting = true

      let sm = store.findGroupByName(this.infoValue.dealer)
      if(sm) {
        sm = store.groupInfo.find(x => x.uuid == sm.uuid)
      }

      if(TymCommon.isEmpty(sm.uuid_d)) {
        if(TymCommon.isEmpty(sm.uuid_c)) {
          if(TymCommon.isEmpty(sm.uuid_b)) {
            sm = sm.uuid_a
          } else {
            sm = sm.uuid_b
          }
        } else {
          sm = sm.uuid_c
        }
      } else {
        sm = sm.uuid_d
      }

      let SD = new Date(this.infoValue.saleDate)
      let ED = new Date(this.infoValue.expireDate)
      let curTZ = ''
      if( (parseInt(store.connectServer) == 0) || (store.timezoneIndex == 0)) {
        curTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else {
        curTZ = store.timezoneList[store.timezoneIndex].value
      }

      let localTime = null
      if(store.connectServer == 0) {
        localTime = moment(SD)
        SD = localTime.format('YYYY-MM-DDTHH:mm')

        localTime = moment(ED)
        ED = localTime.format('YYYY-MM-DDT23:59')
     }
      else {
        localTime = moment.tz(this.infoValue.saleDate, curTZ).utc()
        localTime = localTime.format().replace('Z', '')
        SD = moment.tz(localTime, curTZ).utc().format('YYYY-MM-DDTHH:mm')

        localTime = moment.tz(this.infoValue.expireDate, curTZ).utc()
        localTime = localTime.format().replace('Z', '')
        ED = moment.tz(localTime, curTZ).utc().format('YYYY-MM-DDT23:59')
      }
      console.log('saveSaleInfo2Cloud sm/sd/ed :', sm, SD, ED)

      let PD = new Date()
      if(!this.isAddMode) {
        let convDate = ''
        if(this.isKorean) {
          convDate = this.infoValue.prodDate + '1.'
          PD = new Date(convDate)
        } else {
          let splitted = this.infoValue.prodDate.split('/')
          PD = new Date(parseInt(splitted[1]), parseInt(splitted[0]) - 1, 1)
        }
      }
      localTime = moment.tz(PD, curTZ);
      PD = localTime.format("YYYY-MM")
      
      let level = this.adsLevels.indexOf(this.infoValue.adsLevel)
      let modelFullname = ''
      if(this.isAddMode) {
        modelFullname = this.infoValue.MODEL + '_' + this.infoValue.OPTION
      } else {
        if(this.modelSelected.length > 0) {
          modelFullname = this.modelSelected[0].ModelInfo.Model + "_" + this.modelSelected[0].ModelInfo.Option
        } else {
          modelFullname = this.infoValue.MODEL + "_" + this.infoValue.OPTION
        }
      }

      // this.infoValue 변경된 모델이네
      // this.itemSelected[0] - 현재 선택 판매 정보
      // this.infoValue - 섞였네?
      // this.customerSelected - 고객리스트에서 선택한 아이템
      // this.selectedSubTree - 선택한 딜러 정보
      if(TymConst.IS_DEVELOPMENT) {
        console.log('개발 시험용.. 여기서 리턴... saveSaleInfo2Cloud 1 :', this.itemSelected[0], this.infoValue, this.customerSelected)
      }

      this.savedTBOX = false
      this.savedACU = false

      let customerMobile = '', customerName = '', customerID = ''
      if(this.customerSelected.length > 0) {
        // 고객정보 변경
        customerName = this.customerSelected[0].name
        customerMobile = this.customerSelected[0].mobile
        customerID = this.customerSelected[0].userid
      } else {
        customerName = this.itemSelected[0].owner
        customerMobile = this.itemSelected[0].mobile
        customerID = this.itemSelected[0].uid
      }

      if(this.isKorea) {
        customerMobile.replace('-', '')
        customerMobile = this.makeKrPhoneNumber(customerMobile)
      }

      // 딜러정보 변경
      
      // let newDealer = store.findGroupByUUID1(this.selectedSubTree)
      let newDealer = store.findGroupByName(this.infoValue.dealer)
      if(newDealer) {
        newDealer = store.groupInfo.find(x => x.uuid == newDealer.uuid)
      }
      if(TymConst.IS_DEVELOPMENT) {
        console.log('saveSaleInfo2Cloud 2 :', this.selectedSubTree, customerMobile)
        console.log('saveSaleInfo2Cloud 3 :', newDealer, this.myGroupInfo)
      }

      /*
      let dealerInfo = null
      if(!TymCommon.isEmpty(this.selectedSubTree)) {
        dealerInfo = store.findGroupByUUID1(this.selectedSubTree)
      }
      if(TymCommon.isEmpty(dealerInfo)) {
        dealerInfo = this.myGroupInfo
      }
      if(TymConst.IS_DEVELOPMENT == 0) {
        console.log('saveSaleInfo2Cloud 4 :', this.selectedSubTree, dealerInfo)
      }
      */

      let MA = null, MB = null, MC = null, MD = null
      if(!TymCommon.isEmpty(newDealer)) {
        MA = TymCommon.isEmpty(newDealer.uuid_a) ? ' ' : newDealer.uuid_a
        MB = TymCommon.isEmpty(newDealer.uuid_b) ? ' ' : newDealer.uuid_b
        MC = TymCommon.isEmpty(newDealer.uuid_c) ? ' ' : newDealer.uuid_c
        MD = TymCommon.isEmpty(newDealer.uuid_d) ? ' ' : newDealer.uuid_d
      } else {
        // 내 정보를 사용하자.
        MA = TymCommon.isEmpty(store.idToken["custom:uuid_a"]) ? ' ' : store.idToken["custom:uuid_a"]
        MB = TymCommon.isEmpty(store.idToken["custom:uuid_b"]) ? ' ' : store.idToken["custom:uuid_b"]
        MC = TymCommon.isEmpty(store.idToken["custom:uuid_c"]) ? ' ' : store.idToken["custom:uuid_c"]
        MD = TymCommon.isEmpty(store.idToken["custom:uuid_d"]) ? ' ' : store.idToken["custom:uuid_d"]
      }
      /*
      MA = TymCommon.isEmpty(dealerInfo.uuid_a) ? ' ' : dealerInfo.uuid_a
      MB = TymCommon.isEmpty(dealerInfo.uuid_b) ? ' ' : dealerInfo.uuid_b
      MC = TymCommon.isEmpty(dealerInfo.uuid_c) ? ' ' : dealerInfo.uuid_c
      MD = TymCommon.isEmpty(dealerInfo.uuid_d) ? ' ' : dealerInfo.uuid_d
      */

      let acuTypeIdx = this.acuProdTypes.indexOf(this.infoValue.acuProdType)
      let acuTypeNameDB = store.WebConfig.MachineTypes[1][acuTypeIdx]
      // console.log(acuTypeNameDB, this.infoValue.acuProdType, this.acuProdTypes, store.WebConfig)

      if(TymConst.IS_DEVELOPMENT) {
        if(this.useTBOX) {
          console.log('saveSaleInfo TBOX', this.isEditMode ? 'EDIT ' : 'ADD ', this.infoValue.machineNo, this.infoValue.serialNo, sm, this.infoValue.dealer, modelFullname,
                      customerID, customerMobile, customerName,
                      PD, SD, ED, TymCommon.isEmpty(this.infoValue.testRunTime) ? "0" : this.infoValue.testRunTime,
                      MA, MB, MC, MD,
                      'None', '0', 'None')
        }
        if(this.useACU) {
          console.log('saveSaleInfo ACU', this.isEditMode ? 'EDIT ' : 'ADD ', this.isEditMode, this.infoValue.machineNo + 'A', this.infoValue.acuSN, sm, this.infoValue.dealer, modelFullname,
                      customerID, customerMobile, customerName,
                      PD, SD, ED, TymCommon.isEmpty(this.infoValue.testRunTime) ? "0" : this.infoValue.testRunTime,
                      MA, MB, MC, MD,
                      this.infoValue.steeringDev, level, acuTypeNameDB)
        }
      }
      
      /*
      if(TymConst.IS_DEVELOPMENT) {
        console.log('saveSaleInfo2Cloud END - developer')
        this.bottomWaiting = false
        return
      }
      */

      if(this.useTBOX) {
        // function modifySalesData(isEdit, mn, sn, sm, smTitle, mon, uid, hp, user, pd, sd, st, ma, mb, mc, md, adsType, adsLv, adsDType)
        TymAws.modifySalesData(this.isEditMode, this.infoValue.machineNo, this.infoValue.serialNo, sm, this.infoValue.dealer, modelFullname,
                              customerID, customerMobile, customerName,
                              PD, SD, ED, TymCommon.isEmpty(this.infoValue.testRunTime) ? "0" : this.infoValue.testRunTime,
                              MA, MB, MC, MD,
                              'None', '0', 'None')
          .then(data => {
            console.log('TymAws.modifySalesData TBOX OK:', data)
            this.savedTBOX = true

            if(this.useACU){
              setTimeout(() => {
                // function modifySalesData(isEdit, mn, sn, sm, smTitle, mon, uid, hp, user, pd, sd, st, ma, mb, mc, md, adsType, adsLv, adsDType)
                TymAws.modifySalesData(this.isEditMode, this.infoValue.machineNo + 'A', this.infoValue.acuSN, sm, this.infoValue.dealer, modelFullname,
                                      customerID, customerMobile, customerName,
                                      PD, SD, ED, TymCommon.isEmpty(this.infoValue.testRunTime) ? "0" : this.infoValue.testRunTime,
                                      MA, MB, MC, MD,
                                      this.infoValue.steeringDev, level, acuTypeNameDB)
                  .then(data => {
                    console.log('TymAws.modifySalesData ACU OK:', data)
                    this.savedACU = true
                    setTimeout(() => {
                      store.getGroupSalesData(this.getSalesDataOK)
                    }, 5)
                  })
                  .catch(er => {
                    console.log('TymAws.modifySalesData ACU FAILED :', er)
                    if(!TymCommon.isEmpty(er.errorMessage)) {
                      TymCommon.Toast(TymCommon.convertErrorMessage(er.errorMessage))
                    } else if(!TymCommon.isEmpty(er.REASON)) {
                      TymCommon.Toast(TymCommon.convertErrorMessage(er.REASON))
                    }
                    setTimeout(() => {
                      this.dispalyUpdateError()
                    }, 500)
                    this.bottomWaiting = false
                  })
                }, 5)
            } else {
              setTimeout(() => {
                store.getGroupSalesData(this.getSalesDataOK)
              }, 5)
            }
           })
          .catch(er => {
            console.log('TymAws.modifySalesData TBOX FAILED :', er)
            if(!TymCommon.isEmpty(er.errorMessage)) {
              TymCommon.Toast(TymCommon.convertErrorMessage(er.errorMessage))
            } else if(!TymCommon.isEmpty(er.REASON)) {
              TymCommon.Toast(TymCommon.convertErrorMessage(er.REASON))
            }

            if(this.useACU){
              setTimeout(() => {
                // function modifySalesData(isEdit, mn, sn, sm, smTitle, mon, uid, hp, user, pd, sd, st, ma, mb, mc, md, adsType, adsLv, adsDType)
                TymAws.modifySalesData(this.isEditMode, this.infoValue.machineNo + 'A', this.infoValue.acuSN, sm, this.infoValue.dealer, modelFullname,
                                      customerID, customerMobile, customerName,
                                      PD, SD, ED, TymCommon.isEmpty(this.infoValue.testRunTime) ? "0" : this.infoValue.testRunTime,
                                      MA, MB, MC, MD,
                                      this.infoValue.steeringDev, level, acuTypeNameDB)
                  .then(data => {
                    console.log('TymAws.modifySalesData ACU OK:', data)
                    this.savedACU = true
                    this.dispalyUpdateError()

                    setTimeout(() => {
                      store.getGroupSalesData(this.getSalesDataOK)
                    }, 5)
                  })
                  .catch(er => {
                    console.log('TymAws.modifySalesData ACU FAILED :', er)
                    if(!TymCommon.isEmpty(er.errorMessage)) {
                      TymCommon.Toast(TymCommon.convertErrorMessage(er.errorMessage))
                    } else if(!TymCommon.isEmpty(er.REASON)) {
                      TymCommon.Toast(TymCommon.convertErrorMessage(er.REASON))
                    }
                    setTimeout(() => {
                      this.dispalyUpdateError()
                    }, 500)

                    this.bottomWaiting = false
                  })
              }, 5)
            } else {
              setTimeout(() => {
                store.getGroupSalesData(this.getSalesDataOK)
              }, 5)
            }
          })
      } else if(this.useACU) {
        setTimeout(() => {
          // function modifySalesData(isEdit, mn, sn, sm, smTitle, mon, uid, hp, user, pd, sd, st, ma, mb, mc, md, adsType, adsLv, adsDType)
          TymAws.modifySalesData(this.isEditMode, this.infoValue.machineNo + 'A', this.infoValue.acuSN, sm, this.infoValue.dealer, modelFullname,
                                customerID, customerMobile, customerName,
                                PD, SD, ED, TymCommon.isEmpty(this.infoValue.testRunTime) ? "0" : this.infoValue.testRunTime,
                                MA, MB, MC, MD,
                                this.infoValue.steeringDev, level, acuTypeNameDB)
            .then(data => {
              console.log('TymAws.modifySalesData ACU OK:', data)
              this.savedACU = true
              setTimeout(() => {
                store.getGroupSalesData(this.getSalesDataOK)
              }, 5)
            })
            .catch(er => {
              console.log('TymAws.modifySalesData ACU FAILED :', er)
              if(!TymCommon.isEmpty(er.errorMessage)) {
                TymCommon.Toast(TymCommon.convertErrorMessage(er.errorMessage))
              } else if(!TymCommon.isEmpty(er.REASON)) {
                TymCommon.Toast(TymCommon.convertErrorMessage(er.REASON))
              }
              setTimeout(() => {
                this.dispalyUpdateError()
              }, 500)

              this.bottomWaiting = false
            })
          }, 5)
        }
    },
    initEnvironment() {
      this.isAddMode = false
      this.isEditMode = false

      this.useTBOX = false
      this.useACU = false
      this.validTBOXSN = false
      this.validACUSN = false

      this.searchingOwner = false
      this.searchingVIN = false
      this.searchingModel = false
      this.searchingDealer = false

      this.infoValue = {}
      this.itemSelected = []
      this.salesRowSelection = -1
    },
    updateIgnState(changedValue) {
      const store = useTymictStore()
      
      if(TymConst.IS_DEVELOPMENT) {
        console.log('salesView.updateIgnState', this.salesRowSelection, changedValue, this.itemSelected[0])
      }

      //let locaItems = []
      let found = store.machineLocaInfo.find(x => x.machineNo == this.itemSelected[0].machineNo)
      if(found) {
        this.$refs.occurLoca.updateMapData([found], undefined, false)
      }
      /*
      try {
            let posInfo = {}
            posInfo.machineNo = row.machineNo
            posInfo.address = row.address
            posInfo.name = row.owner
            posInfo.modelNo = row.Info.Fullname
            posInfo.latitude = data[1]
            posInfo.longitude = data[2]
            if(data.length > 4) {
              posInfo.PWR = data[4]
              if(!TymCommon.isEmpty(info)) {
                info.PWR = data[4]

                //let info2 = store.machineLocaInfo.find(x => x.machineNo == row.machineNo)
                //console.log('------------------', info2)
              }
            } else {
              posInfo.PWR = TymCommon.isEmpty(info) ? 'OFF' : info.PWR
            }

            this.$refs.occurLoca.updateMapData([posInfo], undefined, false)
          } catch(ex) {
            console.log(ex)
          }
          */

    },
    copyToClipboard(text) {
      try {
        if(!TymCommon.isEmpty(navigator.clipboard)) {
          navigator.clipboard.writeText(text)
        } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;
              
          textArea.style.position = "absolute";
          textArea.style.left = "-999999px";
              
          document.body.prepend(textArea);
          textArea.select();

          try {
              document.execCommand('copy');
          } catch (error) {
              console.error(error);
          } finally {
              textArea.remove();
          }
        }
      } catch(ex) {
        console.log('copyToClipboard ER :', ex)
      }
    },
    dispalyUpdateError() {
      if((this.useTBOX && !this.savedTBOX) && (this.useACU && !this.savedACU)){
        TymCommon.MsgBoxOK('Error', this.$t('errorMsg.updateBothFailed'))
      } else if((this.useTBOX && !this.savedTBOX) && (!this.useACU || this.savedACU)) {
        TymCommon.MsgBoxOK('Error', this.$t('errorMsg.updateTBOXFailed'))
      } else if((!this.useTBOX || this.savedTBOX) && (this.useACU && !this.savedACU)) {
        TymCommon.MsgBoxOK('Error', this.$t('errorMsg.updateACUFailed'))
      }
    },
    readCustomerResult(res) {
      if(res) {
        this.getUnregisterUser()
      }
    },
    getUnregisterUser() {
      const store = useTymictStore()
      this.prodNoRegUser = {}

      if(store.AllCustomerData.length > 0) {
        let foundUser = store.AllCustomerData.find(x => x.userid == 'noreguser')
        if(foundUser) {
          let addr = TymCommon.isEmpty(foundUser['custom:caddress1']) ? '' : foundUser['custom:caddress1']
          if(!TymCommon.isEmpty(foundUser['custom:caddress2'])) {
            addr += ' ' + foundUser['custom:caddress2']
          }
          this.prodNoRegUser = {
            Name: foundUser.name,
            ID: foundUser.userid,
            Phone: foundUser.phone_number,
            EMail: foundUser.email,
            Address: addr
          }
        }
      }
    },
    manageMachinesInNotSales() {
      this.prodRows = []
      this.prodModelList = [ this.$t('common.all')  ]
      this.prodSelectedModel = this.prodModelList[0]

      const store = useTymictStore();
      this.$refs.prodMap.updateMapData([], undefined, false)

      if(store.AllCustomerData.length < 1) {
        store.getCustomerData(this.readCustomerResult);
      } else {
        this.getUnregisterUser()
      }

      TymAws.getAllProductData()
        .then(data => {
          let idx = 0
          data.forEach(one => {
            let modelInfo = TymCommon.getModelFullname(one.MON)

            one.Index = idx++
            one.Selected = false
            one.Dealer = ''
            one.Owner = ''
            one.Info = modelInfo
            one.RegDate = TymCommon.SD2DateString2(one.OD)
            this.prodRows.push(one)
            
            if(!this.prodModelList.find(x => x == modelInfo.Model)) {
              this.prodModelList.push(modelInfo.Model)
            }            
          })
          
          this.onResizeProdTable(null)
          this.isShowNoSalesMachines = true;
          this.checkProdSelectAll = 'none'
          this.prodSelectionIndex = -1
          this.prodSearchInput = ''

          if(this.expandedProdTree.length == 0) {
            this.expandedProdTree = [this.getGroupTreeData[0].uuid]
          }
        })
        .catch(er => {
          console.log("load product data filed :", er)
          TymCommon.Toast('생산 정보를 가져올 수 없습니다.')
        })

      setTimeout(() => {
        this.$refs.reg_menu.hide()
      }, 5)
    },
    groupProdTreeSelected(tgt) {
      this.unusedParam(tgt)
    },
    applyGroupToProd() {
      if(!TymCommon.isEmpty(this.selectedProdTree)) {
        const store = useTymictStore()

        let treeName = store.findGroupNameByKey(this.selectedProdTree)
        if(treeName) {
          this.prodRows.forEach(prod => {
            if(prod.Selected) {
              prod.Dealer = treeName
              prod.UUID = this.selectedProdTree
            }
          })
        }
      }
    },
    clickProdRow(column, rowData) {
      this.unusedParam(column, rowData)
      
      setTimeout(() => {
        this.selectedProdMN = rowData.MN
        this.selectedProdAddr = ''
      }, 5)

      if(TymCommon.isEmpty(this.checkProdSelectAll))
        this.checkProdSelectAll = 'all'
      else {
        let selectedCnt = 0
        this.prodRows.forEach(prod => {
          if(prod.Selected)
            selectedCnt++
        })
    
        this.checkProdSelectAll = selectedCnt > 0 ? 'some' : 'none'
      }

      this.prodSelectionIndex = rowData.Index

      /*
      console.log(JSONImage)
      if(TymCommon.isEmpty(this.jsonImageData))
        this.jsonImageData = JSONImage
      */
      console.log('clickProdRow :', column, rowData)

      TymAws.getLastPosBySN(rowData.SN)
          .then(data => {
            let longitude = parseFloat(data[2])
            let latitude = parseFloat(data[1])

            let locaInfo = { 
              machineNo : rowData.MN,
              longitude :longitude,
              latitude : latitude,
              serialNo : rowData.SN,
              modelNo: rowData.Info.FullName
            }
            // console.log('viewLocation OK :', data, locaInfo)
            this.$refs.prodMap.updateMapData([locaInfo], undefined, false)
          }).catch(er => {
            console.log('viewLocation ER :', er)
            this.$refs.prodMap.updateMapData([], undefined, false)
          })
    },
    prodSelectionChanged(value, evt) {
      this.unusedParam(value, evt)

      console.log('prodSelectionChanged :', value, evt)

      let selectedCnt = 0
      this.prodRows.forEach(prod => {
        if(prod.Selected)
          selectedCnt++
      })

      if(selectedCnt == this.prodRows.length) {
        this.prodRows.forEach(prod => {
          prod.Selected = false
        })
        this.checkProdSelectAll = 'none'
      } else {
        this.prodRows.forEach(prod => {
          prod.Selected = true
        })
        this.checkProdSelectAll = 'all'
      }
    },
    oneProdSelChanged(value, evt) {
      this.unusedParam(value, evt)

      let selectedCnt = 0
      this.prodRows.forEach(prod => {
        if(prod.Selected)
          selectedCnt++
      })
 
      if(selectedCnt == this.prodRows.length) {
        this.checkProdSelectAll = 'all'
      } else if(selectedCnt == 0) {
        this.checkProdSelectAll = 'none'
      } else {
        this.checkProdSelectAll = 'some'
      }
    },
    prodPageChanged(value) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('salesView.prodPageChanged :', value, this.curProdPagination)
      }
      this.curProdPagination.page = value
      this.curProdPageIndex = value
    },
    updateProdPagination(newPagination) {
      if(this.$refs.prodTable) {
        this.prodRows.sort((a, b) => {
          let result = 0
          switch(newPagination.sortBy) {
            case 'MN':
              if(a.MN == b.MN) {
                result = a.SN - b.SN
              } else {
                result = a.MN - b.MN
              }
              break
            case 'SN':
            if(a.SN == b.SN) {
                result = a.MN - b.MN
              } else {
                result = a.SN - b.SN
              }
              break
            case 'MODEL':
              result = a.Info.Fullname.localeCompare(b.Info.Fullname)
              break
            case 'OD':
              result = a.OD.localeCompare(b.OD)
              break
            case 'Dealer':
              result = a.Dealer.localeCompare(b.Dealer)
              break
            case 'Owner':
              result = a.Owner.localeCompare(b.Owner)
              break
          }
          if(newPagination.descending)
            result = 0 - result
            
          if(result > 0)
            return 1
          else if(result < 0)
            return -1
          else 
            return 0
        })
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('salesView.updateProdPagination :', newPagination)
      }

      this.curProdPagination = newPagination
      this.curProdPageIndex = newPagination.page
    },
    prodFilterMethod(rows, terms, cols) {
      if(TymCommon.isEmpty(terms))
        return rows
      if(terms.length < 1)
        return rows

      this.unusedParam(cols)

      let temp = rows
      if(terms[0] != this.prodModelList[0]) {
        temp = rows.filter(x => x.Info.Model == terms[0])
      }
      if(!TymCommon.isEmpty(terms[1])) {
        let upper = terms[1].toUpperCase()
        temp = rows.filter(x => x.MN.includes(upper) || x.SN.includes(upper) || x.Dealer.toUpperCase().includes(upper) || x.Owner.toUpperCase().includes(upper))
      }

      return temp
    },
    beforeShowProdCustomer() {
      this.prodSearchCustomers = []

      if(!TymCommon.isEmpty(this.prodCustomerInputName)) {
        const store = useTymictStore() 
        let filter = store.AllCustomerData.filter(x => (TymCommon.isEmpty(x.name) ? false : x.name.includes(this.prodCustomerInputName))
                                                     || (TymCommon.isEmpty(x.userid) ? false : x.userid.includes(this.prodCustomerInputName))
                                                     || (TymCommon.isEmpty(x.email) ? false : x.email.includes(this.prodCustomerInputName))
                                                     || (TymCommon.isEmpty(x.phone_number) ? false : x.phone_number.includes(this.prodCustomerInputName)))
        this.prodSearchCustomers = filter
      }
    },
    clickedProdSelUser(user){
      console.log('clickedProdSelUser :', user)

      let addr = TymCommon.isEmpty(user['custom:caddress1']) ? '' : user['custom:caddress1']
      if(!TymCommon.isEmpty(user['custom:caddress2'])) {
        addr += ' ' + user['custom:caddress2']
      }
      this.prodNoRegUser = {
        Name: user.name,
        ID: user.userid,
        Phone: user.phone_number,
        EMail: user.email,
        Address: addr
      }

      setTimeout(() => {
        this.$refs.popupProdUser.hide()
      }, 5)
    },
    apllyCustomerToProd() {
      if(!TymCommon.isEmpty(this.prodNoRegUser.Name)) {
        this.prodRows.forEach(prod => {
          if(prod.Selected) {
            prod.Owner = this.prodNoRegUser.Name
            prod.UserInfo = this.prodNoRegUser
          }
        })
      }
    },
    changedViewSites(value) {
      console.log('changedViewSites :', value)
      this.$refs.manSites.hide()
    },
    addrReceivedProd(mn, addr) {
      this.selectedProdMN = mn
      this.selectedProdAddr= addr
    },
    async getFirstDaySN(sn) {
      const store = useTymictStore()
      let param = {
          TableName: "History_Day",
          KeyConditionExpression: "SN = :serial",
          ExpressionAttributeValues: {
              ":serial" : sn
          },
          Limit: 5
      }

      var result = await new Promise((resolve,reject)=>{
          store.docClient.query(param, function(err, data) {
              if (err) {
                  reject('error');
                  console.log('조회에 실패하였습니다. : getLastDataBySN :', sn, err);
              }
              else {
                  if (TymCommon.isEmpty(data.Items) || (data.Items.length == 0)) {
                      resolve('error')
                  } else {
                      console.log('getFirstDaySN', sn, data)
                      resolve(data);                         
                  }
              }        
          });
      });

      var resultSet = [];
      if (result !== 'error')
          resultSet = result.Items;

      return resultSet;  
    },
    getLastPos(sn) {
      TymAws.getLastPosBySN(sn)
        .then(data => {
          let longitude = parseFloat(data[2])
          let latitude = parseFloat(data[1])

          if(!isNaN(longitude) && !isNaN(latitude)) {
            let factoryPos = [
              { // 옥천
                lat: 36.2968991,
                lng: 127.5608736
              },
              { // 왕궁
                lat: 35.9879589666,
                lng: 127.0928535166
              },
            ]

            let inFactory = false
            factoryPos.forEach(factory => {
              let distance = TymCommon.getDistance(factory.lat, factory.lng, latitude, longitude)
              if(distance < 0.5) {
                inFactory = true
              }
            })
            if(!inFactory) {
              console.log('getLastPos OK :', sn, longitude, latitude)
            }
          }
        }).catch(er => {
          console.log('getLastPos ER :', er)
        })
    },
    saveProdSales() {
      //console.log('saveProdSales :', this.prodRows, this.prodNoRegUser)
      let selCount = 0
      let updateItems = []
      this.prodRows.forEach(prod => {
        if(!TymCommon.isEmpty(prod.Dealer) && !TymCommon.isEmpty(prod.Owner)) {
          selCount++
          updateItems.push(prod)
        }
      })
      this.itemsForSaleReg = []

      if(selCount < 1) {
        setTimeout(() => {
          this.$refs.prodPopupConfirm.hide()
        }, 5)
        TymCommon.Toast('업데이트할 기대가 없습니다.')
        return
      }

      /*
      let factoryPos = [
        { // 옥천
          lat: 36.2968991,
          lng: 127.5608736
        }
      ]
      */
      const store = useTymictStore()
      let now = new Date()
      let SD = TymCommon.getDateString(now)
      for(let idx = 0; idx < selCount; idx++) {
        let item = updateItems[idx]
        let foundGrp = store.groupInfo.find(x => x.uuid == item.UUID)
        let foundMod = store.ModelData.data.find(x => x.model == item.Info.Model && x.option == item.Info.Option)
        //console.log(idx, item, foundGrp, foundMod)
        // this.getFirstDaySN(item.SN)
        // this.getLastPos(item.SN)
        this.itemsForSaleReg.push({
          MN: item.MN,
          SN: item.SN,
          MON: item.Info.Fullname,
          RD: item.RegDate,
          Dealer: item.Dealer,
          SD: SD,
          DATA: item,
          GROUP: foundGrp,
          MODEL: foundMod
        })
      }
    },
    checkAllOK() {
      let result = true
      this.itemsForSaleReg.forEach(item => {
        if(!TymCommon.isEmpty(item.RESULT)) {
          result &= item.RESULT
        }
      }) 
      return result
    },
    clickedProdApply() {
      for(let idx = 0; idx < this.itemsForSaleReg.length; idx++) {
        let regItem = this.itemsForSaleReg[idx]
        let item = regItem.DATA
        // let model = regItem.MODEL
        let group = regItem.GROUP
        let isACU = item.SN[0] == 'A'
        let now = new Date()
        let SD = TymCommon.getDateString(now)
        let ED = moment(now)
        if(this.isKorea) {
          ED = ED.add(5, 'years')
        } else {
          ED = ED.add(3, 'years')
        }
        ED = ED.add(-1, 'days')
        ED = TymCommon.getDateString(ED.toDate())
        regItem.TYPE = isACU ? 'ACU' : 'TBOX'

        if(isACU) {
          TymAws.getDataFromSNInfo(item.MN)
            .then(data => {
              let self = this
              let snData = data.Items[0]

              TymAws.modifySalesData(false, item.MN, item.SN, item.UUID, item.Dealer, item.MON,
                              item.UserInfo.ID, item.UserInfo.Phone, item.UserInfo.Name,
                              item.PD, SD, ED, 0,
                              group.uuid_a, TymCommon.isEmpty(group.uuid_b) ? ' ' : group.uuid_b, TymCommon.isEmpty(group.uuid_c) ? ' ' : group.uuid_c, TymCommon.isEmpty(group.uuid_d) ? ' ' : group.uuid_d,
                              snData.ASU_TYPE, snData.ADS_LV, snData.D_TYPE)
                .then(data => {
                  self.unusedParam(data)
                  regItem.RESULT = true

                  if(idx == (self.itemsForSaleReg.length - 1)) {
                    console.log('LAST ACU :', self.checkAllOK())
                    if(self.checkAllOK()) {
                      setTimeout(() => {
                        self.$refs.prodPopupConfirm.hide()
                      }, 500)
                    }
                  }
                })
                .catch(er => {
                  console.log('TymAws.modifySalesData ACU FAILED :', er)
                  regItem.RESULT = false
                })
            })
            .catch(er => {
              console.log('SN_Info ERROR :', er, item)
              regItem.RESULT = false
            })
        } else {
          let self = this

          TymAws.modifySalesData(false, item.MN, item.SN, item.UUID, item.Dealer, item.MON,
                              item.UserInfo.ID, item.UserInfo.Phone, item.UserInfo.Name,
                              item.PD, SD, ED, 0,
                              group.uuid_a, TymCommon.isEmpty(group.uuid_b) ? ' ' : group.uuid_b, TymCommon.isEmpty(group.uuid_c) ? ' ' : group.uuid_c, TymCommon.isEmpty(group.uuid_d) ? ' ' : group.uuid_d,
                              'None', '0', 'None')
            .then(data => {
              self.unusedParam(data)
              regItem.RESULT = true

              if(idx == (self.itemsForSaleReg.length - 1)) {
                console.log('LAST TBOX :', self.checkAllOK())
                if(self.checkAllOK()) {
                  setTimeout(() => {
                    self.$refs.prodPopupConfirm.hide()
                  }, 500)
                }
              }
            })
            .catch(er => {
              console.log('TymAws.modifySalesData TBOX FAILED :', er, item)
              regItem.RESULT = false
            })
        }
      }
    },

    manageMachinesInAutoSales() {
      this.isShowAutoSalesMachines = true

      setTimeout(() => {
        this.$refs.reg_menu.hide()
      }, 5)
    }
  }
})
</script>
