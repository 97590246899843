<template>
    <div class="row" style="position: relative;height:16px;width:100%;overflow: hidden;">
        <div class="progress" v-for="n in 20" :key="n" 
             :style="'left:' + String((n-1) * foreWidth) +'px;width:8px;' + (n <= progress ? ('border-bottom: 16px solid ' + ((progress > 20) ? pgColors[19] : pgColors[n-1]) + ';') : 'border-bottom: 16px solid #eeeeee;')">
            <div class="progressBackground " >
            </div>
        </div>
    </div>
</template>

<style scoped>

.progress{
  position: absolute;
  width: 0;
  height:16px;
  border-bottom: 16px solid #aaaaff;
  border-left: 4px solid transparent;
  border-right: 0 solid transparent;
}

.progressBackground {
  position:absoulte;
  top:0;
  width: 0px;
  height: 0px;
  border-bottom: 16px solid white;
  border-left: 4px solid transparent;
  border-right: 3px solid white;
}

</style>

<script>
import { ref } from 'vue'
    export default ({
        props : {
            progress : ref(0)
        },
        data() {
            return {
                pgColors : ref([
                    '#9bc304',
                    '#9fb805',
                    '#a2b007',
                    '#a7a509',
                    '#a9a00a',

                    '#b0900d',
                    '#b5840f',
                    '#bb7312',
                    '#b97911',
                    '#bf6a14',

                    '#c16315',
                    '#C55A18',
                    '#CA5119',
                    '#CD491B',
                    '#D13F1C',

                    '#D4381F',
                    '#D82E21',
                    '#DC2421',
                    '#E11924',
                    '#EC0027',
                ]),
                foreWidth : ref(5)
            }
        },
        setup() {},
        created() {},
        mounted() {}, 
        unmounted() {},
        methods : {
        }
})
</script>
