<template>
  <div class="viewBorder row">
    <div ref="confainer" class="col column" >
      <div class="col-auto row justify-between" style="height:48px;">
        <div class="col row items-center">
          <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'" style="font-size:16px;color:#212529;">{{ $t('dashboardView.recentNoti') }}</div>
          <div style="font-family:Prometo;font-size:16px;font-weight:500;color:#212529;" v-show="totalCount > 0">&nbsp;&nbsp;[&nbsp;{{(curPage)}}&nbsp;/&nbsp;{{Math.ceil(totalCount / maxItemPerPage)}}&nbsp;]</div>
          <!--
          <div v-show="totalCount > 0">&nbsp;[{{(curPage-1) * maxItemPerPage + 1}}/{{totalCount}}]</div>
          -->
        </div>
        <div class="col-auto row justify-end">
          <q-select dense options-dense 
                    transition-show="jump-down"
                    transition-hide="jump-up"
                    color="red-7"
                    v-model="catSelected" :props="props" :options="getCategory" ref="catSelector"
                    style="color:#212529;">
            <template v-slot:option="props">
              <q-item dense clickable class="row items-center justify-start" style="height:32px;width:100%;" @click="selectedCategory(props)">
                <q-img :src="props.opt.icon" width="22px" height="22px" style="margin:0px 6px 0 0;"/>
                <div :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:400">{{props.opt.label}}</div>
              </q-item>
            </template>
            <template v-slot:selected>
              <q-item dense clickable class="row items-center justify-start" style="width:100%;min-width:240px;" >
                <q-img class="col-auto" :src="catSelected.icon" width="24px" height="24px" style="margin-right:5px"/>
                <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;color:#212529;">{{ catSelected.label }}</div>
              </q-item>
            </template>
          </q-select>
          <!-- 20240408 제거 : https://tymict1.atlassian.net/browse/BUG-235
          <q-btn dense flat outline icon="refresh" style="margin:0 0 0 12px;" @click="onRefreshClicked"></q-btn>
          -->
        </div>
        <!--
        <q-badge dense color="pink" style="font-size:12pt;height:18pt">123</q-badge>
        <q-btn dense flat outline text-color="black" :icon="notiExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'" @click="(notiExpanded=!notiExpanded)"></q-btn>
        -->
      </div>
      <div ref="tableContainer" class="col row" style="position:relative;">
        <q-resize-observer @resize="onResize" />

        <q-table dense flat bordered
                style="position: absolute; top: 0px; left: 0; width:100%; height:100%;"
                ref="notiTable"
                separator="cell"
                selection="single"
                :rows="rowData"
                :columns="columnData"
                row-key="time"
                virtual-scroll
                hide-no-data
                class="col"
                :pagination="curPagination"
                :rows-per-page-options="[maxItemPerPage]"
                @update:pagination="updatePagination"
                >
                <!--
                hide-bottom="true"
                -->
          <template v-slot:header="props">
            <q-th no-caps
                  style="height:auto"
                  class="tableHeader"
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props">
                {{ col.label }}
            </q-th>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props" style="cursor:pointer;" @click="dataRowClicked(props.row)">
              <q-td key="time" :props="props" style="width:180px;border-bottom:1px solid #dddddd;">
                <div class="row items-center justify-start no-wrap">
                  <q-img :src='getAlarmImage(props.row.kind)'
                         fit="contain" width="20px" height="20px" style="margin-right:10px;"/>
                  <!--
                  <q-radio  v-model="memberSelection" :val="props.row.id"
                            size="30px" dense color="red-4" style="margin-right:10pt"
                            />
                  -->
                  <div style="font-size:14px">
                    {{ props.row.time }}
                  </div>
                </div>
              </q-td>
              <q-td key="dealer" :props="props" style="width:180px;border-bottom:1px solid #dddddd;">
                <div style="font-size:14px">
                  {{ props.row.dealer }}
                </div>
              </q-td>
              <q-td key="name" :props="props" style="width:180px;border-bottom:1px solid #dddddd;">
                <div style="font-size:14px">
                  {{ props.row.name }}
                </div>
              </q-td>
              <q-td key="vin" :props="props" style="width:160px;border-bottom:1px solid #dddddd;">
                <div style="font-size:14px">
                  {{ props.row.vin }}
                </div>
              </q-td>
              <q-td key="contents" :props="props" style="width:auto;border-bottom:1px solid #dddddd;">
                <div style="font-size:14px">
                  {{ props.row.contents }}
                </div>
              </q-td>
            </q-tr>
          </template>
          <template v-slot:bottom>
            <div class="row items-center justify-end" style="width:100%;">
              <q-pagination class="col-auto"
                            :max="pageCount"
                            v-model="curPage"
                            :max-pages='isLandscape ? "6" : "4"'
                            outline dense
                            :direction-links="isLandscape"
                            :boundary-links="isLandscape"
                            gutter="5px"
                            size="sm"
                            color="grey-7"
                            active-design="unelevated"
                            active-color="red-7"
                            active-text-color="white"
                            @update:model-value="itemPageChanged">
              </q-pagination>
            </div>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.viewBorder {
  width:100%;
  padding: 3px 12px 12px 12px;
  border-radius: 3px;
  background-color: #fff;
}

.tableHeader {
  position:sticky;
  z-index:1;
  top:0;
  background-color: white;
  font-size:14px;
}

</style>

<script>
import { ref } from 'vue'
import { useI18n} from 'vue-i18n'
import { useTymictStore } from '@/store/tymict';
//import { DynamoDB } from 'aws-sdk'
//import AWS from 'aws-sdk'
import TymCommon from '../js/tymcommon.js'
import TymConst from '../js/tymconstants.js'

export default ({
  data() {
    return {
      notificationLastKey : ref(null),
      noticeItems : [],
      totalCount : ref(0),
      curPage : ref(0),
    }
  },
  setup() {
    const i18n = useI18n()
    return {
      maxItemPerPage : ref(25),
      columnData : ref([
        {
          name: 'time',
          required: true,
          label: i18n.t('common.date'),
          align: 'left',
          field: 'time',
          format: (val) => val,
          sortable: true,
          sort : (a, b, rowA, rowB) => {
            return rowA['ETIME'] - rowB['ETIME']
          }
        },
        {
          name: 'dealer',
          align: 'left',
          label: i18n.t('dashboardView.dealer'),
          field: 'dealer',
          sortable: true
        },
        {
          name: 'name',
          align: 'left',
          label: i18n.t('dashboardView.customer'),
          field: 'name',
          sortable: true
        },
        {
          name: 'vin',
          align: 'left',
          label: i18n.t('common.vin'),
          field: 'vin',
          sortable: true
        },
        {
          name: 'contents',
          align: 'left',
          label: i18n.t('noticeView.context'),
          field: 'contents',
          sortable: true
        },
      ]),
      rowData : ref([]),
      pageCount : ref(0),
      curPagination : ref({
        sortBy: 'time',
        descending: 'true',
        page : 1
      }),
      typeImages : ref([
        require('@/assets/dashboard/dashboard_all_icon.svg'),
        require('@/assets/dashboard/dashboard_rollover_icon.svg'),
        require('@/assets/dashboard/dashboard_error_icon.svg'),
        require('@/assets/dashboard/dashboard_consumables_icon.svg'),
        require('@/assets/dashboard/dashboard_battery_icon.svg')
      ]),
      catSelected : ref({ 
          label : '전체',
          value : 'all',
          icon : require('@/assets/dashboard/dashboard_all_icon.svg'),
        }),
    }
  },
  watch : {
    dataChanged : {
      handler(newVal, oldVal) {
        this.unusedParam(oldVal)
        if(!newVal) {
          //this.catSelected = this.getCategory[0]
          this.makeTable()
        }
      },
      immediate : true
    }
  },
  computed: {
    dataChanged : function() {
      const store = useTymictStore()
      return store.loadStateMachine
    },
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean() {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    getCategory() {
      //const i18n=useI18n()

      return [
        { 
          label : this.$t('common.all'),
          value : 'all',
          icon : require('@/assets/dashboard/dashboard_all_icon.svg'),
        },
        { 
          label : this.$t('monitor_menu.emergency'),
          value : 'emergency',
          icon : require('@/assets/dashboard/dashboard_rollover_icon.svg'),
        },
        { 
          label : this.$t('monitor_menu.broken'),
          value : 'broken',
          icon : require('@/assets/dashboard/dashboard_error_icon.svg'),
        },
        { 
          label : this.$t('monitor_menu.consumablesRepl'),
          value : 'consumable',
          icon : require('@/assets/dashboard/dashboard_consumables_icon.svg'),
        },
        { 
          label : this.$t('monitor_menu.battery'),
          value : 'battery',
          icon : require('@/assets/dashboard/dashboard_battery_icon.svg'),
        },
        /*
        i18n.t('common.all'),
        i18n.t('noticeView.selectcommon'),
        i18n.t('noticeView.selectevent'),
        i18n.t('noticeView.selectnotread'),
        */
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      this.catSelected = this.getCategory[0]
      this.makeTable()
    }, 50)
    
  },
  methods : {
    unusedParam() {
      //
    },
    onResize(size) {
      this.unusedParam(size)
      this.arrangeTable()
    },
    arrangeTable() {
      if(!this.$refs.tableContainer)
        this.maxItemPerPage = 25
      else {
        this.curPagination.rowsPerPage = Math.floor((this.$refs.tableContainer.clientHeight - 64) / 30)
        this.maxItemPerPage = this.curPagination.rowsPerPage
      }
      this.pageCount = Math.ceil(this.rowData.length / this.maxItemPerPage)
    },
    onRefreshClicked() {
      this.catSelected = this.getCategory[0]
      this.makeTable()
    },
    makeTable() {
      const store = useTymictStore()
      let i = 0
      this.rowData = []
      /*
      [
        {
          kind : 0/1/2/3 : 응급, 고장, 소모품, 배터리
          time: '2023-01-01 15:00',
          dealer : '전북지점',
          name : '홍길동',
          vin : 'S130PH11111',
          contents : '냉각수 레벨 센서 외 1건의 고장이 발생하였습니다'
        },
      ]
      */
      let types = [
        this.$t('constants.emergency_type1'),
        this.$t('constants.emergency_type2'),
        this.$t('constants.emergency_type3'),
        this.$t('constants.emergency_type4'),
        this.$t('constants.emergency_type5'),
      ]

      if(this.catSelected.value == 'all' || this.catSelected.value == 'emergency') {
        if(store.EmergencyData.data != null) {
          for(i = 0; i < store.EmergencyData.data.length; i++) {
            let grp = store.findGroupByUUID(store.EmergencyData.data[i].MA, store.EmergencyData.data[i].MB, store.EmergencyData.data[i].MC, store.EmergencyData.data[i].MD)
            let typeString = types[store.EmergencyData.data[i].ETYPE]
            this.rowData.push( {
              kind : 0,
              time : TymCommon.convertStringToDateTime(store.EmergencyData.data[i].ATimePN),
              dealer : grp,
              name : store.EmergencyData.data[i].UINFO[0].USER,
              vin : store.EmergencyData.data[i].MN,
              contents : typeString,
              ETIME : new Date(TymCommon.convertStringToDateTime(store.EmergencyData.data[i].ATimePN))
            })
          }
        }
      }
      if(this.catSelected.value == 'all' || this.catSelected.value == 'broken') {
        if(store.BrokenData.data != null) {
          for(i = 0; i < store.BrokenData.data.length; i++) {

            //console.log('Broken : ', i , store.BrokenData.data[i])

            let grp = store.findGroupByUUID(store.BrokenData.data[i].MA, store.BrokenData.data[i].MB, store.BrokenData.data[i].MC, store.BrokenData.data[i].MD)

            let contentStr = Array.isArray(store.BrokenData.data[i].DN) ? store.BrokenData.data[i].DN[0] : store.BrokenData.data[i].DN
            if(store.BrokenData.data[i].ECount > 1) {
              if(this.isKorean)
                contentStr = contentStr + ' 외 ' + (store.BrokenData.data[i].ECount - 1) + ' 건'
              else
                contentStr = contentStr + ' and ' + (store.BrokenData.data[i].ECount - 1) +' more'
            }

            this.rowData.push( {
              kind : 1,
              time : TymCommon.convertStringToDateTime(store.BrokenData.data[i].ATime),
              dealer : grp,
              name : store.BrokenData.data[i].UINFO[0].USER,
              vin : store.BrokenData.data[i].MN,
              // contents : store.BrokenData.data[i].DN[0],
              contents : contentStr,
              ETIME : new Date(TymCommon.convertStringToDateTime(store.BrokenData.data[i].ATime)),
            })
          }
        }
      }
      if(this.catSelected.value == 'all' || this.catSelected.value == 'consumable') {
        let contentStr = ''

        if(store.ConsumablesData.data != null) {
          for(i = 0; i < store.ConsumablesData.data.length; i++) {
            contentStr = ''
            //let okCount = store.ConsumablesData.data[i].OKCOUNT
            let noCount = store.ConsumablesData.data[i].NOCOUNT
            if(noCount == 0)
              continue

            let grp = store.findGroupByUUID(store.ConsumablesData.data[i].MA, store.ConsumablesData.data[i].MB, store.ConsumablesData.data[i].MC, store.ConsumablesData.data[i].MD)

            // contentStr = store.ConsumablesData.data[i].DN[0]
            if(Array.isArray(store.ConsumablesData.data[i].DN))
              contentStr = store.ConsumablesData.data[i].DN[0]
            else 
              contentStr = store.ConsumablesData.data[i].DN

            if(Array.isArray(contentStr))
              contentStr = contentStr[this.isKorean ? 0 : 1]

            if(noCount > 1) {
              if(this.isKorean)
                contentStr = contentStr + ' 외 ' + (noCount - 1) + ' 건 확인 필요'
              else
                contentStr = 'Check ' + contentStr + ' and ' + (noCount - 1) +' more'
            } else {
              if(this.isKorean)
                contentStr = contentStr + ' 확인 필요'
              else
                contentStr = 'Check ' + contentStr
            }

            let otime = TymCommon.convertStringToDateTime2(store.ConsumablesData.data[i].OTime)
            this.rowData.push({
              kind : 2,
              time : otime,
              dealer : grp,
              name : store.ConsumablesData.data[i].UINFO[0].USER,
              vin : store.ConsumablesData.data[i].MN,
              contents : contentStr,
              ETIME : new Date(otime),
            })
          }
        }
      }
      if(this.catSelected.value == 'all' || this.catSelected.value == 'battery') {
        if(store.BatteryAlertData.data != null) {
          for(i = 0; i < store.BatteryAlertData.data.length; i++) {
            let grp = store.findGroupByUUID(store.BatteryAlertData.data[i].MA, store.BatteryAlertData.data[i].MB, 
                                            store.BatteryAlertData.data[i].MC, store.BatteryAlertData.data[i].MD)
            // console.log('Battery : ', i + 1 , store.BatteryAlertData.data[i], grp)
            let otime = TymCommon.convertStringToDateTime2(store.BatteryAlertData.data[i].OTime)
            let typeString = this.$t('statView.lowBattery') // store.connectServer == 0 ? "배터리 저전압 경고" : "Low battery warning"
            this.rowData.push( {
              kind : 3,
              time : otime,
              dealer : grp,
              name : store.BatteryAlertData.data[i].UINFO[0].USER,
              vin : store.BatteryAlertData.data[i].MN,
              contents : typeString,
              ETIME : new Date(otime),
            })
          }
        }
      }

      this.rowData.sort((a, b) => {
        return b.ETIME - a.ETIME
      })

      this.pageCount = Math.ceil(this.rowData.length / this.maxItemPerPage)
      this.totalCount = this.rowData.length
      this.curPage = 1
      this.curPagination = { 
        sortBy: 'time',
        descending: true,
        rowsPerPage : this.maxItemPerPage ,
        page : this.curPage
      }
    },
    updatePagination(newPagination) {
      // console.log('updatePagination :', newPagination)
      this.curPagination = newPagination
      this.curPage = newPagination.page
      /*
      switch(newPagination.sortBy) {
        case 'time':
          this.rowData.sort((a, b) => {
            if(a.ETIME == b.ETIME)
              return a.name.localeCompare(b.name)
            else
              return a.ETIME - b.ETIME
          })
          break
      }
      */
    },
    getAlarmImage(kind) {
      // kind : 0 ~ 3
      // 배열의 첫번째에는 All에 대한 아이콘이 들어 있으니 +1을 해준 것임
      return this.typeImages[kind + 1]
    },
    itemPageChanged(page) {
      // console.log('itemPageChanged : ', page)
      let sortby = this.curPagination.sortBy
      let desc = this.curPagination.descending
      this.curPagination = { 
        sortBy : sortby,
        descending : desc,
        rowsPerPage : this.maxItemPerPage, 
        page : page
      }
    },
    selectedCategory(category) {
      this.catSelected = category.opt
      this.makeTable()
      this.$refs.catSelector.hidePopup()
    },
    dataRowClicked(rowData) {
      const store = useTymictStore()
      //console.log('RecentNoti :', rowData)

      /*
      rowData
      {
        "kind": 2,
        "time": "2023. 11. 13. 10:55",
        "dealer": "충남직영점",
        "name": "김길중",
        "machineNo": "S130SX00504",
        "contents": "엔진 오일 및 필터 외 4 건 확인 필요",
        "ETIME": "2023-11-13T01:55:00.000Z"
      }
      */

      /*
      store.SalesData.data[xxx]
      {
        "index": 505,
        "model": "K110E_NONE",
        "machineNo": "PFHT00235",
        "PD": "2023-04",
        "manDate": "2023. 4.",
        "serialNo": "T002230600300030",
        "adsLevel": 0,
        "adsType": "None",
        "mobile": "010-8532-5564",
        "dealer": "서제주종합딜러점",
        "SD": "2023-06-23T00:00",
        "saleDate": "2023. 06. 23. 00:00",
        "address": "제주특별자치도 제주시 애월읍 유수암리 1161 ",
        "uid": "ek10433",
        "owner": "문명기",
        "uuid_a": "0",
        "uuid_b": "2",
        "uuid_c": "11",
        "uuid_d": "1669609766687-jeonnam",
        "SM": "1669609766687-jeonnam",
        "DTYPE": "None",
        "ST": "2.3",
        "machineType": "트랙터"
      }
      */

      let hostData = {}
      let machienSaleInfo = store.SalesData.data.find(x => x.machineNo == rowData.vin)
      if(!machienSaleInfo) {
        hostData = {
          index: 0,
          name: rowData.name,
          phone: '',
          uid: '',
          machineNo: rowData.vin,
          serialNo: '', // rowData.serialNo,
          dealer: rowData.dealer,
          state: '0',
          desc: rowData.contents,
          date: rowData.ETIME
        }
      } else {
        hostData = {
          index: machienSaleInfo.index,
          name: machienSaleInfo.owner,
          phone: machienSaleInfo.mobile,
          uid: machienSaleInfo.uid,
          machineNo: machienSaleInfo.machineNo,
          serialNo: machienSaleInfo.serialNo,
          dealer: machienSaleInfo.dealer,
          state: '0',
          desc: rowData.contents,
          date: rowData.ETIME
        }
      }

      store.selectManageInfo = { kind: rowData.kind, data: hostData };

      // console.log('SalesView.moveToManagement :', hostData, store.selectManageInfo)
      // Vue Router 통해 페이지 전환 시킴...
      // 2023.03.10 여길 팝업형태로 바꿔야하는 데 방법이...?
      store.setPage(TymConst.PAGE_MANAGE_POPUP);
    }
  }
})
</script>
