<template>
  <div class="column">
    <div class="col-auto row items-center justify-between" style="height:48px">
      <div style="font-size:16px;font-weight:600;color:#212529">
        {{ $t('account.userInfo')}}
      </div>
      <div class="row justify-end items-center">
        <q-btn class="col-auto" no-caps flat dense push color="grey-9" icon="edit" style="width:auto;margin:0 3px;" :disable="isEditMode" @click="editUser">
        </q-btn>
        <!-- 20230725 요청으로 삭제style="width:120px;
        <q-btn class="col-auto" no-caps flat dense push color="grey-9" icon="delete" style="width:auto;margin:0 0 0 3px;" @click="deleteUser">
        </q-btn>
        -->
      </div>
    </div>

    <table class="col" style="width:100%;"> 
      <tr class="tr_top">
        <td style="width:150px;font-size:15px;font-weight: 500;">
          {{ $t('common.id') }}
        </td>
        <td width="auto">
          <div class="row">
            <q-input dense flat borderless readonly v-model="userID">
            </q-input>
          </div>
        </td>
      </tr>
      <tr class="tr_middle">
        <td style="ont-size:15px;font-weight: 500;">
          {{ $t('common.name') }}
        </td>
        <td width="auto">
          <div class="col">
            <q-input dense flat color="red-7" :borderless="!isEditMode" :readonly="!isEditMode" v-model="userName" style="width:80%">
            </q-input>
          </div>
        </td>
      </tr>
      <tr class="tr_middle">
        <td style="ont-size:15px;font-weight: 500;">
          {{ $t('totalarea.group') }}
        </td>
        <td width="auto">
          <div class="row">
            <q-input dense flat borderless readonly v-model="userGroup">
            </q-input>
          </div>
        </td>
      </tr>
      <tr class="tr_middle">
        <td style="ont-size:15px;font-weight: 500;">
          {{ $t('common.email') }}
        </td>
        <td width="auto">
          <div class="row">
            <q-input dense flat color="red-7" :borderless="!isEditMode" :readonly="!isEditMode" v-model="userEmail" style="width:80%">
            </q-input>
          </div>
        </td>
      </tr>
      <tr :class='isEditMode ? "tr_middle" : "tr_middle_bottom"'>
        <td style="ont-size:15px;font-weight: 500;">
          {{ $t('common.mobilephone') }}
        </td>
        <td width="auto">
          <div class="row">
            <q-input dense flat color="red-7" :borderless="!isEditMode" :readonly="!isEditMode" v-model="userMobile" style="width:80%">
            </q-input>
          </div>
        </td>
      </tr>
      <tr class="tr_bottom" v-show="isEditMode">
        <td style="ont-size:15px;font-weight: 500;">
        </td>
        <td width="auto">
          <div class="col row justify-center items-center">
            <q-btn no-caps unelevated dense color="grey-4" text-color="black" :label="$t('common.cancel')" style="width:76px;height:32px;" @click="cancelEdit">
              <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
            </q-btn>
            <q-btn no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.save')" style="margin-left:8px;width:76px;height:32px;" @click="saveEdit">
              <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
            </q-btn>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

.tr_top {
  border-top: 2px solid #cccccc;
  border-bottom: 1px solid #eeeeee;
  height:40px;
}
.tr_middle {
  border-bottom: 1px solid #eeeeee;
  height:40px;
}
.tr_middle_bottom {
  border-bottom: 2px solid #cccccc;
  height:40px;
}
.tr_bottom {
  /*
  border-bottom: 2px solid #cccccc;
  */
  border-top: 2px solid #cccccc;
  height:64px;
}

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'
import TymAws from '@/js/tymaws.js'

export default ({
  props : {
    kind : ref(0)
  },
  data() {
    return {
    }
  },
  setup() {
    return {
      isEditMode : ref(false),
      userID : ref(''),
      userName : ref(''),
      userGroup : ref('.'),
      userEmail : ref(''),
      userMobile : ref('')
    }
  },
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
  },
  created() {},
  mounted() {
    this.isEditMode = false

    setTimeout(()=>{
      const store = useTymictStore()
      if(store.CurrentUser != null) {
        // console.log('--> ', store.CurrentUser)

        this.userID = store.CurrentUser.username
        this.userName = store.idToken['name']
        this.userGroup = store.groupName
        this.userEmail = store.idToken['email']
        this.userMobile = store.idToken['custom:uphone']

        
      }
    }, 50)
  }, 
  unmounted() {},
  methods : {
    editUser() {
      this.isEditMode = true
    },
    deleteUser() {
    },
    cancelEdit() {
      this.isEditMode = false
    },
    saveEdit() {
      if(this.isEditMode) {
        this.isEditMode = false

        console.log('AccountView saveEdit...', this.userID, this.userName, this.userMobile ,this.userEmail)
        TymAws.changeUserInfo(this.userID, this.userName, this.userMobile, this.userEmail, this.changeCompleted)
      }
    },
    changeCompleted(result) {
      if(result) {
        const store = useTymictStore()
        store.idToken['name'] = this.userName

        let userInfo = {
          userid : this.userID,
          username :this.userName,
          email : this.userEmail,
          uphone : this.userMobile,
          uenable : store.idToken['custom:uenable'],
          ulevel : store.idToken['custom:ulevel'],
          uuid_a : store.idToken['custom:uuid_a']
        }

        console.log('changeCompleted :', result, store.idToken, userInfo)
        TymAws.updateUserInfoFromDB(userInfo)
          .then(data => {
            console.log('TymAws.updateUserInfoFromDB OK', data)
          })
          .catch(er => {
            console.log('TymAws.updateUserInfoFromDB ER', er)
          })
      }
    },
  }
})
</script>
