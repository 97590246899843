<template>
  <div class="row mainBorder" style="overflow-x:hidden;margin:3px 0 0 0;">
    <div class="col row justify-between">
      <div class="col row justify-start items-center" style="padding:0 6px 0 0;border-radius: 3px;overflow: hidden;" >
        <div class="col" style="position: relative;background-color:white;">
          <div class="col btn btn2"></div>
          <div class="item item2 row no-wrap" v-show="userCount >= 0" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            <div class="col-auto">{{userCount}}</div>
            <div class="col-auto">&nbsp;{{ $t('common.peopleCount')}}</div>
          </div>
          <div class="title title2" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:normal;' : 'font-family:Prometo;font-weight:500;'">{{$t('totalarea.users')}}</div>
        </div>
      </div>

      <div class="col row justify-start items-center" style="padding:0 6px 0 6px;border-radius: 3px;overflow: hidden;">
        <div class="col " style="position: relative;background-color:white;">
          <div class="col btn btn1"></div>
          <div class="item item2 row no-wrap" v-show="machineCount >= 0" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            <div class="col-auto">{{machineCount}}</div>
            <div class="col-auto">&nbsp;{{ $t('common.vehicleCount')}}</div>
            <div v-show="!isKorean && (machineCount > 1)">s</div>
          </div>
          <div class="title title2" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:normal;' : 'font-family:Prometo;font-weight:500;'">{{$t('totalarea.equipment')}}</div>
        </div>
      </div>

      <div class="col row justify-start items-center" style="padding:0 6px 0 6px;border-radius: 3px;overflow: hidden;">
        <div class="col btn " style="position: relative;background-color:white;">
          <div class="col btn btn4"></div>
          <div class="item item2 row no-wrap" v-show="groupCount >= 0" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            <div class="col-auto">{{groupCount}}</div>
            <div class="col-auto">&nbsp;{{ $t('common.agentCount')}}</div>
            <div v-show="!isKorean && (groupCount > 1)">s</div>
          </div>
          <div class="title title2" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:normal;' : 'font-family:Prometo;font-weight:500;'">{{$t('totalarea.group')}}</div>
        </div>
      </div>

      <div class="col row justify-start items-center" style="padding:0 0 0 6px;border-radius: 3px;overflow: hidden;">
        <div class="col btn " style="position: relative;background-color:white;">
          <div class="col btn btn3"></div>
          <div class="item item2 row no-wrap" v-show="modelCount >= 0" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
            <div class="col-auto">{{modelCount}}</div>
            <div class="col-auto">&nbsp;{{ $t('common.typeCount')}}</div>
            <div v-show="!isKorean && (modelCount > 1)">s</div>
          </div>
          <div class="title title2" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:normal;' : 'font-family:Prometo;font-weight:500;'">{{$t('totalarea.models')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.itemSep {
  position: absolute;
  left:1px;
  top:14px;
  width:142px;
  height:1px;
  background-repeat: no-repeat;
  resize:none;
}

.btn {
  position:relative;
  width:auto;
  height:auto;
  min-width:200px;
  min-height:94px;
  background-size:cover;
  background-repeat: no-repeat;
  resize: horizontal;
}

.item {
  position: absolute;
  left:18px; 
  top:45px;
  width:130px; 
  height:30px;
  font-size:22px;
}
.title {
  position: absolute;
  left:18px;
  top:15px;
  width:130px;
  height:30px;
  font-size:20px;
}

/* Full HD 이상 */
@media all and (min-width:1921px) {
  .btn1 {
    background-image: url('../assets/dashboard/dashboard_machines_i@2x.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn2 {
    background-image: url('../assets/dashboard/dashboard_users_i@2x.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn3 {
    background-image: url('../assets/dashboard/dashboard_models_i@2x.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn4 {
    background-image: url('../assets/dashboard/dashboard_groups_i@2x.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .itemSepBG {
    /* background-image: url('../assets/dashboard/dashboard_p_line@2x.png'); */
  }
}

@media all and (min-width:1025px) and (max-width:1920px) {
  .btn1 {
    background: url('../assets/dashboard/dashboard_machines_i.png') right no-repeat;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn2 {
    background: url('../assets/dashboard/dashboard_users_i.png') right no-repeat;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn3 {
    background-image: url('../assets/dashboard/dashboard_models_i.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn4 {
    background-image: url('../assets/dashboard/dashboard_groups_i.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .itemSepBG {
    /*  background-image: url('../assets/dashboard/dashboard_p_line.png'); */
  }
}

@media all and (max-width:1024px) {
  .btn1 {
    background: url('../assets/dashboard/dashboard_machines_i.png') right no-repeat;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn2 {
    background: url('../assets/dashboard/dashboard_users_i.png') right no-repeat;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn3 {
    background-image: url('../assets/dashboard/dashboard_models_i.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn4 {
    background-image: url('../assets/dashboard/dashboard_groups_i.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .itemSepBG {
    /*  background-image: url('../assets/dashboard/dashboard_p_line@05x.png'); */
  }
}

.item1 {
  color : #c41b32;
}
.item2 {
  color : #c41b32;
  text-shadow: -0.5px -0.5px 0 white, 0.5px -0.5px 0 white, -0.5px 0.5px 0 white, 0.5px 0.5px 0 white;
}
.item3 {
  color : #c41b32;
}
.item4 {
  color : #ffffff;
}
.title1 {
  color : #ebedf2;
  text-shadow: -0.5px -0.5px 0 #444444, 0.5px -0.5px 0 #444444, -0.5px 0.5px 0 #444444, 0.5px 0.5px 0 #444444;
}
.title2 {
  color : #212529;
  text-shadow: -0.5px -0.5px 0 white, 0.5px -0.5px 0 white, -0.5px 0.5px 0 white, 0.5px 0.5px 0 white;
  margin:0 0 3px 0;
}


.mainBorder {
  height:94px;
  width:auto;
}

.rowContainer {
  width:auto;
  height:100%;
}

.itemOutline {
  flex: auto;
  height:100%;
}

.itemIcon {
  flex: auto;
}

.itemCount {
  flex: auto;
  border-radius: 2px;
  font-size:14pt;
}

.bgBlue {
  background-color:rgb(0,128,255);
  color:white;
}
.bgRed {
  background-color:Red;
  color:white;
}
.bgGreen {
  background-color:green;
  color:white;
}
.bgOrange {
  background-color:orange;
  color:white;
}

.itemName {
  flex: auto;
  text-align: center;
  vertical-align: bottom;
  font-size:12pt;
  color:black;
}

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'

export default ({
  data() {},
  setup() {
    return {
      count : ref(0),
      itemCounts : ref([0, 0, 0, 0])
    }
  },
  computed: {
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    machineCount() {
      const store = useTymictStore()
      // return store.machineLocaInfo.length
      return store.SalesData.count
    },
    userCount() {
      const store = useTymictStore()
      return store.getUserCount()
    },
    manageCount() {
      const store = useTymictStore()
      return store.manageInfo.length
    },
    modelCount() {
      const store = useTymictStore()
      return store.ModelData.count
    },
    groupCount() {
      const store = useTymictStore()
      return store.groupInfo.length
    },
  },
  created() {},
  mounted() {}, 
  unmounted() {},
  methods : {
    buttonPressed(index) {
      console.log('TotalView.buttonPressed(',index, ')')
      switch(index) {
        case 0:
          //this.$router.push({path:'/mach'})
          break;
      }
    }
  }
})
</script>

