import { useTymictStore } from '@/store/tymict.js'
import moment from 'moment-timezone'
import AWS from 'aws-sdk'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants'
// import { Amplify } from 'aws-amplify';
import { AuthenticationDetails } from "amazon-cognito-identity-js"
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

/*
import {
    ChallengeNameType,
    CognitoIdentityProviderClient,
    RespondToAuthChallengeCommand,
  } from "aws-sdk/client-cognito-identity-provider";
*/

// 모든 기대의 위치정보를 구한다.
// SN, 위, 경도, 시간, MN, CurrentState, 이름, 모델명 => 총 8개 아이의 배열
async function getCurPos(userlevel, uuid) {
    const store = useTymictStore()

    var tpayload = JSON.stringify({"LV" : String(userlevel), "UUID" : String(uuid)})
    var lambdaParam = {
        FunctionName : 'tymict_webmanager_getCurrentPos_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }
    // console.log('getCurPos :', lambdaParam)
    
    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, async function(err, data) {
            if (err) {
                console.log('getCurPos result error => ', err)
                reject(err)
            } else if (data == null) {
                console.log('getCurPos data is null')
                reject(err)
            } else {
                //console.log('getCurPos :', data.Payload)
                resolve(data)
            }        
        })
    })

    return result
}

async function getLastPosByMN(mn) {
    const store = useTymictStore()

    var tpayload = JSON.stringify({"MN" : mn});
    var lambdaParam = {
        FunctionName : 'tymict_webmanager_getLastPos',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                reject('error');
                console.log('getLastPosByMN failed', mn, err);
            } else if (data == null) {
                console.log('getLastPosByMN data is null');
                reject('error')
            } else {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('AWS.getLastPsoByMN :', mn, data)
                }
                var jsonObj = JSON.parse(data.Payload);
                if (jsonObj.body == null) {
                    reject('error')
                } else {
                    const innerJsonObj = JSON.parse(jsonObj.body);
                    resolve(innerJsonObj);
                } 
            }        
        });
    });

    if (result === 'error') 
        return 'error'
    if (result.Count == 0)
        return 'error'

    return result.Items.LOCA[0]
}

async function getLastPosBySN(sn) {
    const store = useTymictStore()

    var tpayload = JSON.stringify({"SN" : sn});
    var lambdaParam = {
        FunctionName : 'tymict_webmanager_getLastPos',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                reject('error');
                console.log('getLastPosBySN failed', sn, err);
            } else if (data == null) {
                console.log('getLastPosBySN data is null');
                reject('error')
            } else {
                var jsonObj = JSON.parse(data.Payload);
                //console.log('getLastPosBySN data :', jsonObj);
                if (jsonObj.body == null) {
                    reject('error')
                } else {
                    const innerJsonObj = JSON.parse(jsonObj.body);
                    resolve(innerJsonObj);
                } 
            }        
        });
    });

    if (result === 'error') 
        return 'error'
    if (result.Count == 0)
        return 'error'

    return result.Items.LOCA[0]
}

// 항목별 갯수 구한다.
// EM, BROKEN, CITEM, NOTIS, SALES
async function getMainInfo(uid, ms, ma, mb, mc, md) {
    const store = useTymictStore()

    var tpayload = JSON.stringify({
        "UID" : TymCommon.isEmpty(uid) ? '' : String(uid),
        "MS" : TymCommon.isEmpty(ms) ? '' : String(ms),
        "MA" : TymCommon.isEmpty(ma) ? '' : String(ma),
        "MB" : TymCommon.isEmpty(mb) ? '' : String(mb),
        "MC" : TymCommon.isEmpty(mc) ? '' : String(mc),
        "MD" : TymCommon.isEmpty(md) ? '' : String(md)
    })
    var lambdaParam = {
        FunctionName : 'mapp_getmaininfo',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }

    let lambda = new AWS.Lambda( {
        region : store.lambda[store.connectServer].region,
        apiVersion : store.lambda[store.connectServer].apiVersion
    })

    var result = await new Promise((resolve,reject)=>{
        lambda.invoke(lambdaParam, async function(err, data) {
            if (err) {
                console.log('getMainInfo result error => ', err)
                reject(err)
            } else if (data == null) {
                console.log('getMainInfo data is null')
                reject(err)
            } else {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('TymAws.getMainInfo', data)
                }

                resolve(data)
            }        
        })
    })

    return result;
}

async function getGroupNameFromUuid(uuid_a, uuid_b, uuid_c, uuid_d) 
{
    const store = useTymictStore()
    
    // console.log('getGroupNameFromUuid', store, uuid_a, uuid_b, uuid_c, uuid_d)
    var searchUuid = null;
    var uLevel = 0;
    if ( !TymCommon.isEmpty(uuid_d) ) {
        searchUuid = uuid_d;
        uLevel = 3;
    } else if ( !TymCommon.isEmpty(uuid_c) ) {
        searchUuid = uuid_c;
        uLevel = 2;
    } else if ( !TymCommon.isEmpty(uuid_b) ) {
        searchUuid = uuid_b;
        uLevel = 1;
    } else {
        searchUuid = uuid_a;
    }

    var param = {
        TableName: "tymict_manage_group"
    };

    var result = await new Promise((resolve,reject)=>{
        store.docClient.scan(param, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                // console.log(uuid_a, uuid_b, uuid_c, uuid_d);
                reject('error');
            }
            else {
                if (data.Items.length == 0) {
                    resolve('error')
                } else {
                    resolve(data.Items)
                }                        
            }        
        });
    }); 

    if ( result === 'error' || result.length == 0 )
        return '정보 없음';
    
    var filtered = result.filter(r => (r.uuid === searchUuid));

    if (filtered.length == 0) {
        return '정보 없음';
    } else {
        switch(uLevel) {
            case 0 :
                return filtered[0]['group_a'];
            case 1 :
                return filtered[0]['group_b'];
            case 2 :
                return filtered[0]['group_c'];
            case 3 :
                return filtered[0]['group_d'];        
        }
    }
}

async function getAllGroupInfo() 
{
    const store = useTymictStore()
    var param = {
        TableName: "tymict_manage_group",
    }

    var result = await new Promise((resolve,reject)=>{
        store.docClient.scan(param, function(err, data) {
            if (err) {
                console.log('getAllGroupInfo Failed :', JSON.stringify(err, null, 2))
                reject('error')
            }
            else {
                // console.log('getAllGroupInfo() ', data)
                if (data.Items.length == 0) {
                    resolve('error : no data')
                } else {
                    var jsonitems = data['Items']
                    let myGrpUsers = []
                    for(let i = 0; i < jsonitems.length; i++) {
                        if(store.isICTUser()) {
                            myGrpUsers.push(jsonitems[i])
                        } else {
                            if(!TymCommon.isEmpty(store.idToken['custom:uuid_d'])) {
                                if(store.idToken['custom:uuid_d'] === jsonitems[i].uuid_d) {
                                    myGrpUsers.push(jsonitems[i])
                                }
                            } else if(!TymCommon.isEmpty(store.idToken['custom:uuid_c'])) {
                                if(store.idToken['custom:uuid_c'] === jsonitems[i].uuid_c) {
                                    myGrpUsers.push(jsonitems[i])
                                }
                            } else if(!TymCommon.isEmpty(store.idToken['custom:uuid_b'])) {
                                if(store.idToken['custom:uuid_b'] === jsonitems[i].uuid_b) {
                                    myGrpUsers.push(jsonitems[i])
                                }
                            } else if(!TymCommon.isEmpty(store.idToken['custom:uuid_a'])) {
                                if(store.idToken['custom:uuid_a'] === jsonitems[i].uuid_a) {
                                    myGrpUsers.push(jsonitems[i])
                                }
                            }
                        }
                    }
                    // console.log("Succeeded getAllGroupInfo", data, myGrpUsers)
    
                    resolve(myGrpUsers)
                }                        
            }        
        });
    }); 

    if ( result === 'error' || result.length == 0 )
        return '정보 없음'
    return result
}

// 기대번호를 통해 고장 타입 구하기
// 우선순위 : 긴급1/고장2/소모품3/배터리4
// 20230616 : 우선순위가 아니라 배열에 발생여부를 정리해 넣는다(true or false)
//            [긴급, 고장, 소모품, 배터리]
function containsAbnormalMn(mnlist) 
{
    //console.log("tymaws.containsAbnormalMn : ", mnlist)

    if (TymCommon.isEmpty(mnlist)) {
        return 0
    }
    if(mnlist.length < 1) {
        return 0
    }
    if (TymCommon.isEmpty(mnlist[0])) {
        return 0
    }

    let result = [false, false, false, false]
    // let i = 0
    const store = useTymictStore()
    mnlist.forEach(r => {
        if(store.EmergencyData.data.find(x => x.MN == r))
            result[0] |= true
        /*
        for(i = 0; i < store.EmergencyData.data.length; i++) {
            if(String(store.EmergencyData.data[i].MN) == String(r)) {
                result[0] = true
                break
            }
        }
        */
        if(store.BrokenData.data.find(x => x.MN == r))
            result[1] |= true
        /*
        for(i = 0; i < store.BrokenData.data.length; i++) {
            if(r.includes(store.BrokenData.data[i].MN)) {
                result[1] = true
                break
            }
        }
        */
        if(store.ConsumablesData.data.find(x => x.MN == r))
            result[2] |= true
        /*
        for(i = 0; i < store.ConsumablesData.data.length; i++) {
            if(store.ConsumablesData.data[i].MN == r) {
                if(store.ConsumablesData.data[i].NOCOUNT > 0) {
                    result[2] = true
                }
                break
            }
        }
        */
        if(store.BatteryAlertData.data.find(x => x.MN == r))
            result[3] |= true
        /*
        for(i = 0; i < store.BatteryAlertData.data.length; i++) {
            if(store.BatteryAlertData.data[i].MN == r) {
                result[3] = true
                break
            }
        }
        */
    })       

    return result;
}

async function getGroupBrokenData(manage, isTymict, forExcel) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "MANAGE": manage,
        "TYMICT" : isTymict,
        "EXCEL" : TymCommon.isEmpty(forExcel) ? false : forExcel
    })
    let lambdaParam = {
        FunctionName : 'tymict_errorcurrent_report',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log(data)
                let json = JSON.parse(data.Payload)
                resolve(json);                         
            }        
        })
    })

    return result;
}

async function getGroupBrokenData2(uuidArray) {
    const store = useTymictStore()
    var uLevel = TymCommon.getLevelFromUuid(uuidArray[0].MB, uuidArray[0].MC, uuidArray[0].MD);

    var param = {
        TableName: "tymict_manager_Error_v2",
        FilterExpression: "attribute_not_exists(MSTATE) OR MSTATE < :mstate",
        ExpressionAttributeValues: {
            ":mstate" : 3
        }
    };

    switch(uLevel) {
        case 0 :
            param.IndexName = 'MA-ATime-index';
            param.KeyConditionExpression = 'MA = :uuid';
            param.ExpressionAttributeValues[":uuid"] = uuidArray[0].MA;
            break;
        case 1 :
            param.IndexName = 'MB-ATime-index';
            param.KeyConditionExpression = 'MB = :uuid';
            param.ExpressionAttributeValues[":uuid"] = uuidArray[0].MB;
            break;
        case 2 :
            param.IndexName = 'MC-ATime-index';
            param.KeyConditionExpression = 'MC = :uuid';
            param.ExpressionAttributeValues[":uuid"] = uuidArray[0].MC;
            break;
        case 3 :
            param.IndexName = 'MD-ATime-index';
            param.KeyConditionExpression = 'MD = :uuid';
            param.ExpressionAttributeValues[":uuid"] = uuidArray[0].MD;
            break;
    }    

    if (store.BrokenData.lastKey != null)
        param.ExclusiveStartKey = store.BrokenData.lastKey;

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                reject('error');
                console.log('조회에 실패하였습니다.', err);
            }
            else {
                if (typeof data.LastEvaluatedKey != "undefined")
                    store.BrokenData.lastKey = data.LastEvaluatedKey;
                else 
                    store.BrokenData.lastKey = null;

                resolve(data);                         
            }        
        });
    });

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    if (store.BrokenData.lastKey != null) {
        var consecutiveResult = await getGroupBrokenData2(uuidArray);
        resultSet = resultSet.concat(consecutiveResult);
    }

    return resultSet;  
}


async function getGroupEmergencyData(manage, isTymict, forExcel) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "MANAGE": manage,
        "TYMICT" : isTymict,
        "EXCEL" : TymCommon.isEmpty(forExcel) ? false : forExcel
    })
    if(TymConst.IS_DEVELOPMENT) {
        console.log('getGroupEmergencyData :', tpayload)
    }
    let lambdaParam = {
        FunctionName : 'tymict_emergencycurrent_report',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                console.log('error : data is empty')
                reject('error : data is empty')
            } else {
                /*
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('TymAws.getEMData :', data.Payload)
                }
                */
                let json = JSON.parse(data.Payload)
                resolve(json);                         
            }        
        })
    })

    return result;
}

async function getGroupEmergencyData2(uuidArray) {
    const store = useTymictStore()
    var uLevel = TymCommon.getLevelFromUuid(uuidArray[0].MB, uuidArray[0].MC, uuidArray[0].MD);

    var param = {
        TableName: "tymict_manager_Emergency_v2",
        FilterExpression: "attribute_not_exists(webDel) or webDel = :webDel",
        ExpressionAttributeValues: {
            ":webDel" : false
        }
    };
    
    switch(uLevel) {
        case 0 :
            param.IndexName = 'MA-ATimePN-index';
            param.KeyConditionExpression = 'MA = :uuid';
            param.ExpressionAttributeValues[":uuid"] = uuidArray[0].MA;
            break;
        case 1 :
            param.IndexName = 'MB-ATimePN-index';
            param.KeyConditionExpression = 'MB = :uuid';
            param.ExpressionAttributeValues[":uuid"] = uuidArray[0].MB;
            break;
        case 2 :
            param.IndexName = 'MC-ATimePN-index';
            param.KeyConditionExpression = 'MC = :uuid';
            param.ExpressionAttributeValues[":uuid"] = uuidArray[0].MC;
            break;
        case 3 :
            param.IndexName = 'MD-ATimePN-index';
            param.KeyConditionExpression = 'MD = :uuid';
            param.ExpressionAttributeValues[":uuid"] = uuidArray[0].MD;
            break;
    }   
        
    if (store.EmergencyData.lastKey != null)
        param.ExclusiveStartKey = store.EmergencyData.lastKey;

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log('조회에 실패하였습니다.', err);
                reject('error');
            }
            else {
                if (typeof data.LastEvaluatedKey != "undefined")
                    store.EmergencyData.lastKey = data.LastEvaluatedKey;
                else 
                    store.EmergencyData.lastKey = null;

                //console.log('getGroupEmergencyData2 :', data)
                resolve(data);                       
            }        
        });
    });

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    if (store.EmergencyData.lastKey != null) {
        var consecutiveResult = await getGroupEmergencyData2(uuidArray);
        resultSet = resultSet.concat(consecutiveResult);
    }    

    return resultSet;  
}

async function getGroupConsumablesData(manage, isTymict, forExcel) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "MANAGE": manage,
        "TYMICT" : isTymict,
        "EXCEL" : TymCommon.isEmpty(forExcel) ? false : forExcel
    })
    let lambdaParam = {
        FunctionName : 'tymict_citemcurrent_report',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log(data)
                let json = JSON.parse(data.Payload)
                resolve(json);                         
            }        
        })
    })

    return result;
}

async function getGroupConsumablesData2(uuidArray) {
    const store = useTymictStore()
    let uLevel = TymCommon.getLevelFromUuid(uuidArray[0].MB, uuidArray[0].MC, uuidArray[0].MD)
    let uuid = '0'

    if(!TymCommon.isEmpty(uuidArray[0].MD)) {
        uuid = uuidArray[0].MD
    } else if(!TymCommon.isEmpty(uuidArray[0].MC)) {
        uuid = uuidArray[0].MC
    } else if(!TymCommon.isEmpty(uuidArray[0].MB)) {
        uuid = uuidArray[0].MB
    } else if(!TymCommon.isEmpty(uuidArray[0].MA)) {
        uuid = uuidArray[0].MA
    }
    var param = {
        TableName: "tymict_manager_Citem_v2",
        ExpressionAttributeValues: {
            ":uuid": uuid
        }  
    };

    switch (uLevel) {
        case 0 :
            param.IndexName = "MA-index";
            param.KeyConditionExpression = "MA = :uuid";
            break;
        case 1 :
            param.IndexName = "MB-index";
            param.KeyConditionExpression = "MB = :uuid";
            break;
        case 2 :
            param.IndexName = "MC-index";
            param.KeyConditionExpression = "MC = :uuid";
            break;
        case 3 :
            param.IndexName = "MD-index";
            param.KeyConditionExpression = "MD = :uuid";
            break;
        default : 
            return [];
    }
        
    if (store.ConsumablesData.lastKey != null)
        param.ExclusiveStartKey = store.ConsumablesData.lastKey;

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                reject('error');
                console.log('조회에 실패하였습니다.', err);
            }
            else {
                if (typeof data.LastEvaluatedKey != "undefined")
                    store.ConsumablesData.lastKey = data.LastEvaluatedKey;
                else 
                    store.ConsumablesData.lastKey = null;

                resolve(data);                        
            }        
        });
    });

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    if (store.ConsumablesData.lastKey != null) {
        var consecutiveResult = await getGroupConsumablesData2(uuidArray);
        resultSet = resultSet.concat(consecutiveResult);
    }    

    return resultSet; 
}

async function getSalesByUserId(userId)
{
    const store = useTymictStore()
    var param = {
        TableName: "tymict_manage_saleslist",
        IndexName: "tymict_manage_saleslist_uid",
        KeyConditionExpression: "UID = :userId",
        ExpressionAttributeValues: {
            ":userId": userId
        }        
    }

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
            }
            else {
                resolve(data);                        
            }        
        });
    });

    if (result === 'error')
        return 'error';
    else 
        return result;
}


async function getLastDataBySN(sn) {
    const store = useTymictStore()
    var param = {
        TableName: "History_Last",
        KeyConditionExpression: "SN = :serial",
        ExpressionAttributeValues: {
            ":serial" : sn
        }
    };

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                reject('error');
                console.log('조회에 실패하였습니다. : getLastDataBySN :', sn, err);
            }
            else {
                if (TymCommon.isEmpty(data.Items) || (data.Items.length == 0)) {
                    resolve('error')
                } else {
                    // console.log('getLastDataBySN', sn, data)
                    resolve(data);                         
                }
            }        
        });
    });

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    return resultSet;  

}

// 모든 모델의 정보를 구함
async function getModelInfo() {
    const store = useTymictStore()

    var param = {
        TableName: "tymict_managerweb_model",
    }

    var result = await new Promise((resolve,reject)=>{
        store.docClient.scan(param, function(err, data) {
            if (err) {
                reject('error');
                console.log('getModelInfo - 조회에 실패하였습니다.', err);
            }
            else {
                if (data.Items.length == 0) {
                    resolve('error')
                } else {
                    // console.log('getModelInfo', data)
                    resolve(data);                         
                }
            }        
        });
    });
    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    return resultSet;  
}

// imgPath 경로의 png파일을 imgObj의 HTML img 태그 오브젝트에 그린다.
function getS3Image(imgPath, imgObj) {
    var s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: TymConst.BUCKET_IMAGE_NAME},
        region: 'ap-northeast-2'
    });

    s3.getObject({Key: imgPath}, function(err, file) { 
        if(err) {
            // $("#btn_upload").attr("src", "../res/model_default.png");
            console.log(imgPath + ' was not loaded.')
        }
        else {
            var s3url = "data:image/png;base64," + TymCommon.encode(file.Body);
            imgObj.src = s3url
        }
    })
}


// 모델의 기본정보를 가져온다.
async function getModelList() {
    const store = useTymictStore()
    var param = {
        TableName: "tymict_web_config",
        FilterExpression : 'CKEY = :ckey',
        ExpressionAttributeValues: {
            ':ckey' : 'model'
        },
    }

    var result = await new Promise((resolve,reject)=>{
        store.docClient.scan(param, function(err, data) {
            if (err) {
                reject('error');
                console.log('getModelList 조회에 실패하였습니다.', err);
            }
            else {
                if (data.Items.length == 0) {
                    resolve('error')
                } else {
                    resolve(data);                         
                }
            }        
        });
    });

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    return resultSet;  
}

// config에서 ADS 정보를 가져온다.
async function getADSInfo() {
    const store = useTymictStore()
    var param = {
        TableName: "tymict_web_config",
        FilterExpression : 'CKEY = :ckey',
        ExpressionAttributeValues: {
            ':ckey' : 'setting'
        },
    }

    var result = await new Promise((resolve,reject)=>{
        store.docClient.scan(param, function(err, data) {
            if (err) {
                reject('error');
                console.log('getADSInfo 조회에 실패하였습니다.', err);
            }
            else {
                if (data.Items.length == 0) {
                    resolve('error')
                } else {
                    resolve(data);                         
                }
            }        
        });
    });

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    return resultSet;  
}


async function getTimezoneList() {
    const store = useTymictStore()
    var param = {
        TableName: "tymict_web_config",
        KeyConditionExpression: "#class = :class and CKEY = :ckey",
        ExpressionAttributeNames: {
            "#class": 'CLASS'
        },
        ExpressionAttributeValues: {
            ":class": 'reference',
            ":ckey": 'timezone',
        }        
    };

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log('getTimezoneList 조회에 실패하였습니다.', err);
                reject('error');
            }
            else {
                if (data.Items.length == 0) {
                    resolve('error')
                } else {
                    resolve(data);                         
                }
            }        
        });
    });

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    return resultSet;  
}

async function getWebConfig(className, cKey) {
    const store = useTymictStore()
    var param = {
        TableName: "tymict_web_config",
        KeyConditionExpression: "#class = :keyClass",
        ExpressionAttributeNames: {
            "#class": 'CLASS'
        },
        ExpressionAttributeValues: {
            ":keyClass": className
        }
    }

    if(!TymCommon.isEmpty(cKey)) {
        param.KeyConditionExpression += " and CKEY = :ckey";
        param.ExpressionAttributeValues[':ckey'] = cKey;
    }

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log('getWebConfig 실패 :', err);
                reject('error');
            }
            else {
                if (data.Items.length == 0) {
                    resolve('error')
                } else {
                    resolve(data);                         
                }
            }        
        })
    })

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    return resultSet;  
}

async function getEmergencyAlertByMN(machineNumber) {
    const store = useTymictStore()
    var param = {
        TableName: "tymict_manager_Emergency_v2",
        KeyConditionExpression : 'MN = :mn',
        ExpressionAttributeValues: {
            ':mn' : machineNumber
        },
    }        

    let result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log('getEmergencyAlertByMN 조회에 실패하였습니다.', JSON.stringify(err, null, 2));
                reject('error');
            }
            else {
                if (data.Items.length == 0) {
                    reject('No data')
                } else {
                    // console.log('getEmergencyAlertByMN :', data);
                    resolve(data.Items);                       
                }                       
            }        
        });
    });


    return result
}

async function getEmergencyHistoryByMN(machineNumber, startday, endday) {
    const store = useTymictStore()
    
    var param = {
        TableName: "tymict_manager_Emergency_History",
        IndexName: "MANAGE-MN-index",
        KeyConditionExpression: "MN = :mn",
        ExpressionAttributeValues: {
            ":mn": machineNumber
        },
    };

    /*
    let sDay = '', eDay = ''
    if(!TymCommon.isEmpty(startday)) {
        let srcDateTime = new Date(startday)
        const vmoment = moment(srcDateTime)
        sDay = vmoment.format("YYYY-MM-DD")
        sDay += ' 00:00'
    }
    if(!TymCommon.isEmpty(endday)) {
        let srcDateTime = new Date(endday)
        const vmoment = moment(srcDateTime)
        //vmoment.add(1, 'day')
        eDay = vmoment.format("YYYY-MM-DD")
        eDay += ' 00:00'
    }
    */

    if(TymConst.IS_DEVELOPMENT) {
        console.log('TymAws.getEmergencyHistoryByMN :', machineNumber, startday, endday)
    }

    if(!TymCommon.isEmpty(startday)) {
        if(!TymCommon.isEmpty(endday)) {
            param.FilterExpression = 'RTime >= :start AND RTime < :end';
            param.ExpressionAttributeValues = {
                ":mn": machineNumber,
                ':start' : startday,
                ':end' : endday
            }
        } else {
            param.FilterExpression = 'RTime >= :start';
            param.ExpressionAttributeValues = {
                ":mn": machineNumber,
                ':start' : startday
            }
        }
    } else {
        if(!TymCommon.isEmpty(endday)) {
            param.FilterExpression = 'RTime < :end';
            param.ExpressionAttributeValues = {
                ":mn": machineNumber,
                ':end' : endday
            }
        }
    }

    let result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                reject('error');
                console.log('getEmergencyHistoryByMN 조회에 실패하였습니다.', machineNumber, err);
            }
            else {
                if (data.Items.length == 0) {
                    reject('No data')
                } else {
                    resolve(data.Items);                       
                }                       
            }        
        });
    });

    return result
}

async function getBrokenAlertByMN(machineNumber) {
    const store = useTymictStore()
    var param = {
        TableName: "tymict_manager_Error_v2",
        KeyConditionExpression : 'MN = :mn',
        ExpressionAttributeValues: {
            ':mn' : machineNumber
        },
    }        

    let result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                // console.log('getBrokenAlertByMN 조회에 실패하였습니다.', machineNumber, JSON.stringify(err, null, 2));
                reject(err);
            }
            else {
                if (data.Items.length == 0) {
                    reject('No data')
                } else {
                    // console.log('getBrokenAlertByMN :', data)
                    resolve(data.Items);                       
                }                       
            }        
        })
    })

    return result
}

async function getBrokenHistoryByMN(machineNumber, sDay, eDay) {
    const store = useTymictStore()
    var param = {
        TableName: "tymict_manager_Error_History",
        IndexName: "MANAGE-MN-index",
        KeyConditionExpression: "MN = :mn",
        ExpressionAttributeValues: {
            ":mn": machineNumber
        },
    };

    /*
    let sDay = '', eDay = ''
    if(!TymCommon.isEmpty(startday)) {
        let srcDateTime = new Date(startday)
        const vmoment = moment(srcDateTime)
        sDay = vmoment.format("YYYY-MM-DD")
        sDay += ' 00:00'
    }
    if(!TymCommon.isEmpty(endday)) {
        let srcDateTime = new Date(endday)
        const vmoment = moment(srcDateTime)
        //vmoment.add(1, 'day')
        eDay = vmoment.format("YYYY-MM-DD")
        eDay += ' 00:00'
    }
    */

    if(!TymCommon.isEmpty(sDay)) {
        if(!TymCommon.isEmpty(eDay)) {
            param.FilterExpression = 'RTime >= :start AND RTime < :end';
            param.ExpressionAttributeValues = {
                ":mn": machineNumber,
                ':start' : sDay,
                ':end' : eDay
            }
        } else {
            param.FilterExpression = 'RTime >= :start';
            param.ExpressionAttributeValues = {
                ":mn": machineNumber,
                ':start' : sDay
            }
        }
    } else {
        if(!TymCommon.isEmpty(eDay)) {
            param.FilterExpression = 'RTime < :end';
            param.ExpressionAttributeValues = {
                ":mn": machineNumber,
                ':end' : eDay
            }
        }
    }

    if(TymConst.IS_DEVELOPMENT) {
        console.log('aws.getBrokenHistoryByMN :', param)
    }

    let result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
                console.log('getBrokenHistoryByMN 조회에 실패하였습니다.', machineNumber, err);
            }
            else {
                if (data.Items.length == 0) {
                    reject('No data')
                } else {
                    // console.log('getBrokenHistoryByMN OK :', machineNumber, startday, endday, data.Items);
                    resolve(data.Items);                       
                }                       
            }        
        })
    })

    return result
}

async function getConsumablesAlertByMN(machineNumber) {
    const store = useTymictStore()
    let param = {
        TableName: "tymict_manager_Citem_v2",
        FilterExpression : 'MN = :mn',
        ExpressionAttributeValues: {
            ':mn' : machineNumber
        },
    }

    let result = await new Promise((resolve,reject)=>{
        store.docClient.scan(param, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
                console.log('getConsumablesAlertByMN 조회에 실패하였습니다.', err);
            }
            else {
                // console.log('getConsumablesAlertByMN :', data);

                if (data.Items.length == 0) {
                    reject('No data')
                } else {
                    resolve(data.Items);                       
                }                       
            }        
        })
    })

    // console.log("getConsumablesAlertByMN result:", result)
    return result
}

async function getConsumablesHistoryByMN(machineNumber, startday, endday) {
    const store = useTymictStore()
    var param = {
        TableName: "tymict_manager_Citem_History",
        IndexName: "MN-ATime-index",
        KeyConditionExpression: "MN = :mn",
        ExpressionAttributeValues: {
            ":mn": machineNumber
        },
    }

    let sDay = startday, eDay = endday
    /*
    // 2023-12-27 10:59
    if(!TymCommon.isEmpty(startday)) {
        let srcDateTime = new Date(startday)
        const vmoment = moment(srcDateTime)
        sDay = vmoment.format("YYYY-MM-DD 00:00")
    }
    if(!TymCommon.isEmpty(endday)) {
        let srcDateTime = new Date(endday)
        const vmoment = moment(srcDateTime)
        //vmoment.add(1, 'day')
        eDay = vmoment.format("YYYY-MM-DD 00:00")
    }
    */

    if(!TymCommon.isEmpty(sDay)) {
        if(!TymCommon.isEmpty(eDay)) {
            param.FilterExpression = 'RTime >= :start AND RTime < :end';
            param.ExpressionAttributeValues = {
                ":mn": machineNumber,
                ':start' : sDay,
                ':end' : eDay
            }
        } else {
            param.FilterExpression = 'RTime >= :start';
            param.ExpressionAttributeValues = {
                ":mn": machineNumber,
                ':start' : sDay
            }
        }
    } else {
        if(!TymCommon.isEmpty(eDay)) {
            param.FilterExpression = 'RTime < :end';
            param.ExpressionAttributeValues = {
                ":mn": machineNumber,
                ':end' : eDay
            }
        }
    }

    if(TymConst.IS_DEVELOPMENT) {
        console.log('TymAws.getConsumablesHistoryByMN :', machineNumber, param)
    }

    let result = await new Promise((resolve, reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
                console.log('getConsumablesHistoryByMN 조회에 실패하였습니다.', err)
            }
            else {
                // console.log('getConsumablesHistoryByMN :', data)
                if (data.Items.length == 0) {
                    reject('No data')
                } else {
                    // console.log('getConsumablesHistoryByMN :', startday, endday, data.Items)
                    resolve(data.Items)                   
                }                       
            }        
        })
    })

    // console.log("getConsumablesHistoryByMN result:", result)
    return result
}


async function getConsumablesHistoryMARIA(ma, mb, mc, md, start, end, forExcel) {
    const store = useTymictStore()
    let offset = 0 // TymCommon.getTimezoneOffset(), 받은쪽에서 타임존 계산함
    let tpayload = {
        "MA" :  ma,
        "STIME": start,
        "ETIME": end,
        "TYMICT": store.isICTUser(),
        "EXCEL": TymCommon.isEmpty(forExcel) ? false : forExcel,
        "OFFSET": String(offset)
    }
    if(!TymCommon.isEmpty(mb))
        tpayload.MB = mb
    if(!TymCommon.isEmpty(mc))
        tpayload.MC = mc
    if(!TymCommon.isEmpty(md))
        tpayload.MD = md

    let lambdaParam = {
        // FunctionName : 'tymict_citemhistory_report_V3_MariaDB',
        FunctionName : 'tymict_citemhistory_report_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: JSON.stringify(tpayload)
    } 

    if(TymConst.IS_DEVELOPMENT)
        console.log('getConsumablesHistoryMARIA :', tpayload)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log('getUsageTimeMARIA :', data)
                if(TymCommon.isEmpty(data.Payload)) {
                    reject('error : Payload is empty')
                } else {
                    var jsonObj = JSON.parse(data.Payload)
                    if(TymConst.IS_DEVELOPMENT)
                        console.log('getConsHistory :', jsonObj)
                    if(!TymCommon.isEmpty(jsonObj)) {
                        if(!TymCommon.isEmpty(jsonObj.Items)) {
                            resolve(jsonObj.Items)
                            return
                        }
                    }
                    reject('error : empty Items')
                }
            }        
        })
    })

    return result;
}


async function getBatteryAlert(manage, isTymict) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "MANAGE": manage,
//        "S_START": start,
//        "S_END": end,
//        "EXCEL" : forExcel,
        "TYMICT" : isTymict
    })
    let lambdaParam = {
        FunctionName : 'tymict_batterycurrent_report',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log(data)
                let json = JSON.parse(data.Payload)
                resolve(json);                         
            }        
        })
    })

    return result;
}

async function getBatteryAlert2() {
    const store = useTymictStore()
    let param = {
        TableName: "tymict_manager_Battery",
    }

    let result = await new Promise((resolve,reject)=>{
        store.docClient.scan(param, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
                console.log('getBatteryAlert 조회에 실패하였습니다.', err);
            }
            else {
                // console.log('getBatteryAlert :', data);

                if (data.Items.length == 0) {
                    reject('No data')
                } else {
                    resolve(data.Items);                       
                }                       
            }        
        })
    })

    // console.log("getConsumablesAlertByMN result:", result)
    return result
}

function changeUserInfo(userid, name, phone, email, cbCompleted) {
    if(TymCommon.isEmpty(userid) || TymCommon.isEmpty(name) || TymCommon.isEmpty(phone) || TymCommon.isEmpty(email)) {
        console.log('changeUserInfo failed...')
        if(!TymCommon.isEmpty(cbCompleted)) {
            cbCompleted(false)
        }
    }

    const store = useTymictStore()
    let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
    let param = {
        UserPoolId : store.CurrentUser.pool.userPoolId,
        Username: userid,
        UserAttributes: [
            {
                Name : 'name',
                Value : name
            },
            {
                Name : 'custom:uphone',
                Value : phone
            },
            {
                Name : 'email',
                Value : email
            },
            {
                Name : 'email_verified',
                Value : 'true'
            }
        ]
    };

    cognitoidentityserviceprovider.adminUpdateUserAttributes(param, function(err, data) {
        if(err)
        {
            console.log("changeUserInfo", err)
            if(!TymCommon.isEmpty(cbCompleted)) {
                cbCompleted(false)
            }
        }
        else{
            // console.log("changeUserInfo", data, userid, name, phone, email)
            if(!TymCommon.isEmpty(cbCompleted)) {
                cbCompleted(true)
            }
            return data
        }
    });
}


async function getGroupSalesData(findoption, ma, mb, mc, md) {
    var param = {
        TableName: "tymict_manage_saleslist",
        KeyConditionExpression: "MA = :uuid",
        ExpressionAttributeValues: {
            ":uuid": ma
        }        
    }
    const store = useTymictStore()

    // TYMICT, 본사
    if(findoption == 99)
        findoption = 0
    else {
        findoption = store.getUserLevel()
    }
    switch (findoption) {
        case 0 :
            param.IndexName = "tymict_manage_saleslist_ma";
            param.KeyConditionExpression = "MA = :uuid";
            param.ExpressionAttributeValues[":uuid"] = ma;
            break;
        case 1 :
            param.IndexName = "tymict_manage_saleslist_mb";
            param.KeyConditionExpression = "MB = :uuid";
            param.ExpressionAttributeValues[":uuid"] = mb;
            break;
        case 2 :
            param.IndexName = "tymict_manage_saleslist_mc";
            param.KeyConditionExpression = "MC = :uuid";
            param.ExpressionAttributeValues[":uuid"] = mc;
            break;
        case 3 :
            param.IndexName = "tymict_manage_saleslist_md";
            param.KeyConditionExpression = "MD = :uuid";
            param.ExpressionAttributeValues[":uuid"] = md;
            break;
    }
        
    if (store.SalesData.lastKey != null)
        param.ExclusiveStartKey = store.SalesData.lastKey;

    // console.log('getGroupSalesData #2 :', findoption, param); 

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log('getGroupSalesData error :', err)
                reject('error');
            }
            else {
                if (typeof data.LastEvaluatedKey != "undefined")
                    store.SalesData.lastKey = data.LastEvaluatedKey;
                else 
                    store.SalesData.lastKey = null;
                
                // console.log('getGroupSalesData :', data); 
                resolve(data);                        
            }        
        });
    });

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items;

    if (store.SalesData.lastKey != null) {
        var consecutiveResult = await getGroupSalesData(findoption, ma, mb, mc, md);
        resultSet = resultSet.concat(consecutiveResult);
    }    

    return resultSet; 
}

function getAttributes(attributes, createAt, userId) {
    var jsonObj = {};
    jsonObj["createat"] = createAt;
    jsonObj["userid"] = userId;
    attributes.forEach((obj) => {
        jsonObj[obj.Name] = obj.Value;
    });
    return jsonObj;
}

// Cognito로부터 사용자 불러오기
async function getAllUsers() {
    const store = useTymictStore()
    var param = {
        UserPoolId: store.usercognito[store.connectServer].userPoolId,
        Limit: 0,
    }

    let userList = []
    let cisp =  new  AWS.CognitoIdentityServiceProvider(); 
    let cogResult = await cisp.listUsers(param).promise()
    if(cogResult) {
        for(let i = 0; i < cogResult.Users.length; i++){
            userList.push(getAttributes(cogResult.Users[i].Attributes, cogResult.Users[i].UserCreateDate, cogResult.Users[i].Username))
        }

        while(cogResult.PaginationToken != undefined) {
            param.PaginationToken = cogResult.PaginationToken
            cogResult = await cisp.listUsers(param).promise()
            // console.log('getAllUsers #2:', cogResult.length)
            cogResult.Users.forEach(user => {
                userList.push(getAttributes(user.Attributes, user.UserCreateDate, user.Username))
            })
        }
    } else {
        console.log('getAllUsers error')
    }

    //console.log('getAllUsers final :', userList.length)
    return userList;
}

// Cognito로부터 관리자 불러오기
async function getAllManagers() {
    const store = useTymictStore()
    var param = {
        UserPoolId: store.cognito[store.connectServer].userPoolId,
        Limit: 0,
    }
    //console.log('getAllManagers #0')

    let userList = []
    let cisp =  new  AWS.CognitoIdentityServiceProvider(); 
    let cogResult = await cisp.listUsers(param).promise()
    if(cogResult) {
        // console.log('getAllManagers #1 :', cogResult)
        for(let i = 0; i < cogResult.Users.length; i++){
            userList.push(getAttributes(cogResult.Users[i].Attributes, cogResult.Users[i].UserCreateDate, cogResult.Users[i].Username))
        }

        while(cogResult.PaginationToken != undefined) {
            param.PaginationToken = cogResult.PaginationToken
            cogResult = await cisp.listUsers(param).promise()
            // console.log('getAllManagers #2:', cogResult.length)
            for(let i = 0; i < cogResult.Users.length; i++){
                userList.push(getAttributes(cogResult.Users[i].Attributes, cogResult.Users[i].UserCreateDate, cogResult.Users[i].Username))
            }
        }
    } else {
        console.log('getAllManagers error')
    }

    // console.log('getAllManagers final :', userList.length)
    return userList;
}

async function getAllManagersFromDB() {
    const store = useTymictStore()
    var params = {
        TableName:"tymict_managerweb_user",
    };

    var result = await new Promise((resolve,reject) => {
        store.docClient.scan(params, function(err, data) {
            if(err) {
                console.log('getAllManagersFromDB #Error:', JSON.stringify(err, null, 2))
                reject(err)
            } else {
                if(data.Items.length > 0) {
                    let jsonitems = data['Items']
                    let myGrpUsers = []

                    // 내가 어느 레벨에 있나 보는 거임.
                    for(let i = 0; i < jsonitems.length; i++) {
                        if(!TymCommon.isEmpty(store.idToken['custom:uuid_d'])) {
                            if(store.idToken['custom:uuid_d'] === jsonitems[i].uuid_d) {
                                myGrpUsers.push(jsonitems[i])
                            }
                        } else if(!TymCommon.isEmpty(store.idToken['custom:uuid_c'])) {
                            if(store.idToken['custom:uuid_c'] === jsonitems[i].uuid_c) {
                                myGrpUsers.push(jsonitems[i])
                            }
                        } else if(!TymCommon.isEmpty(store.idToken['custom:uuid_b'])) {
                            if(store.idToken['custom:uuid_b'] === jsonitems[i].uuid_b) {
                                myGrpUsers.push(jsonitems[i])
                            }
                        } else if(!TymCommon.isEmpty(store.idToken['custom:uuid_a'])) {
                            if(store.idToken['custom:uuid_a'] === jsonitems[i].uuid_a) {
                                myGrpUsers.push(jsonitems[i])
                            }
                        }
                    }
                    // console.log('getAllManagersFromDB #1:', myLevel, myGrpUsers)
                    resolve(myGrpUsers)
                }
            }
        })
    })

    return result;
}

async function isUserExist(userId, isAdmin) {
    const store = useTymictStore()
    const  cognitoidentityserviceprovider  =  new AWS.CognitoIdentityServiceProvider();

    var searchfilter = "username = \"" +userId + "\"";
    var searchparam = {};            

    if (isAdmin) {
        searchparam = {
            UserPoolId: store.cognito[store.connectServer].userPoolId,
            Limit: 1,
            Filter : searchfilter
        };
    } else {
        searchparam = {
            UserPoolId: store.usercognito[store.connectServer].userPoolId,
            Limit: 1,
            Filter : searchfilter
        };
    }

    var isUserExist = false;
    const cognitodata = cognitoidentityserviceprovider.listUsers(searchparam).promise();
    await cognitodata.then(
        function(cogdata) {
            if (cogdata.Users.length > 0 )
                isUserExist = true;
        }
    );

    return isUserExist;    
}

/// DB (tymict_manage_pdlist)에서 해당 기대번호의 기대에 대한 정보를 구한다.
async function getMachineByMN(machineNumber) {
    const store = useTymictStore()
    var param = {
        TableName: "tymict_manage_pdlist",
        KeyConditionExpression: 'MN = :MN',
        ExpressionAttributeValues: { 
            ':MN': machineNumber
        }
    }        

    let result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log('getMachineByMN 조회에 실패하였습니다.', JSON.stringify(err, null, 2));
                reject('error');
            }
            else {
                // console.log('getMachineByMN :', data);
                if (data.Items.length == 0) {
                    reject('No data')
                } else {
                    resolve(data.Items);                       
                }                       
            }        
        });
    });

    return result
}


async function getPDList() 
{
    const store = useTymictStore()
    
    var param = {
        TableName: "tymict_manage_pdlist"
    };

    let result = await new Promise((resolve,reject)=>{
        store.docClient.scan(param, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
            }
            else {
                if (typeof data.LastEvaluatedKey != "undefined")
                    store.PDListLastKey = data.LastEvaluatedKey;
                else 
                    store.PDListLastKey = null;

                resolve(data)
            }        
        });
    }); 

    var resultSet = [];
    if (result !== 'error')
        resultSet = result.Items

    if (store.PDListLastKey != null) {
        var consecutiveResult = await getPDList()
        resultSet = resultSet.concat(consecutiveResult)
    }

    return resultSet
}

// nAction : 1 -> 패스워드 변경
//           2 -> 사용자 삭제
async function SetAdminHistory(userid, nAction)
{
    const store = useTymictStore()
    const dbParam = {
        'id'  : userid
    }

    if(nAction == 1) {
        dbParam['pwChange'] = "1";
    }
    else if(nAction == 2) {
        dbParam['deleteuser'] = "1";
    }

    var tpayload = JSON.stringify(dbParam);
    const lambdaParam = {
        FunctionName : 'securityhistory_admin',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    };  
    
    let lambda = new AWS.Lambda( {
        region : store.lambda[store.connectServer].region,
        apiVersion : store.lambda[store.connectServer].apiVersion
    })

    try{
        var result = await lambda.invoke(lambdaParam).promise();    
        const jsonObj = JSON.parse(result.Payload);
        if(jsonObj.RETURN == true) {
           console.log("save history")
        }
        else {
            console.log("save fail history")
        }
    }
    catch(exception) {
        console.log("save failhistory exception", exception)
    }  
}

async function requestDeleteuser(userid)
{
    const store = useTymictStore()
    let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(); 
    let paramvalue = {
        UserPoolId: store.cognito[store.connectServer].userPoolId,
        Username: userid
    }

    await SetAdminHistory(userid, 2);
    let result = await new Promise((resolve, reject)=>{
        cognitoidentityserviceprovider.adminDeleteUser(paramvalue, function(err, data) {
            if (err) {
                console.log(err, err.stack); // an error occurred
                reject(err)
            } else {
                console.log(data);
                //update DB
                const table = "tymict_managerweb_user";
                var dynamoparams = {
                    TableName:table,
                    Key:{
                        "username":paramvalue.Username
                    }
                };
                store.docClient.delete(dynamoparams, function(err, data) 
                {
                    if (err) {
                        console.log(JSON.stringify(err, undefined, 2));
                        reject(err)
                    }
                    else 
                    {
                        console.log(data);
                        resolve(data)
                    }
                })
            }
        })
    })
    return result
}

async function getAllProductData() {
    const store = useTymictStore()

    var param = {
        TableName: "tymict_manage_pdlist",
        IndexName: "SOLD-OD-index",
        ScanIndexForward: false,
        KeyConditionExpression: "SOLD = :sold",        
        ExpressionAttributeValues: {
            ":sold": 0
        }  
    };

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                reject('error');
            }
            else {
                if (data.Items.length == 0)
                    resolve('error');
                else
                    resolve(data);                        
            }        
        });
    });

    if (result === 'error')
        return [];
    else
        return result.Items; 
}

async function updateAlertState(mn, atimepn, resolver, mstate, rmemo, rtime) {
    const store = useTymictStore()
    let tpayload = {}

    if ( mstate < TymConst.EMER_ALERT_MSTATE_COMPLETE) {
        tpayload = JSON.stringify({
            "MN" : String(mn), 
            "ATime": String(atimepn),
            "EM": String("EM"),
            "RESOLVER": String(resolver),
            "MSTATE": Number(mstate)
        })
    } else {
        tpayload = JSON.stringify({
            "MN" : String(mn), 
            "ATime": String(atimepn),
            "EM": String("EM"),
            "RESOLVER": String(resolver),
            "MSTATE": Number(mstate),
            "RMEMO": String(rmemo),
            "RTime": String(rtime)
        })
    }
    var lambdaParam = {
        FunctionName : 'tymict_webmanager_error_resolve',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }

    if(TymConst.IS_DEVELOPMENT) {
        console.log('updateAlertState :', tpayload)
    }

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log('updateAlertState error :', JSON.stringify(err, null, 2));
                reject('error')
            } else if (data == null) {
                reject('error')
            } else {
                var jsonObj = JSON.parse(data.Payload);
                if (jsonObj.body == null) {
                    resolve('error');
                } else {
                    //const innerJsonObj = JSON.parse(jsonObj.body);
                    console.log("updateAlertState OK", data)
                    resolve(data);
                } 
            }        
        });
    });

    if (result === 'error') 
        return 'error';

    return result;
}

// 고장알림 데이터 수정
async function updateBrokenState(mn, atimepn, resolver, mstate, rmemo, rtime, btype) {
    const store = useTymictStore()
    let tpayload = {}

    if ( mstate < 3) {
        tpayload = JSON.stringify({
            "MN" : String(mn), 
            "ATime": String(atimepn),
            "RESOLVER": String(resolver),
            "MSTATE": Number(mstate)
        });
    } else {
        tpayload = JSON.stringify({
            "MN" : String(mn), 
            "ATime": String(atimepn),
            "RESOLVER": String(resolver),
            "MSTATE": Number(mstate),
            "RMEMO": String(rmemo),
            "RTime": String(rtime),
            "BTYPE": String(btype)
        });
    }
    var lambdaParam = {
        FunctionName : 'tymict_webmanager_error_resolve',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    };  

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
            } else if (data == null) {
                reject('error');
            } else {
                var jsonObj = JSON.parse(data.Payload);
                if (jsonObj.body == null) {
                    resolve('error');
                } else {
                    console.log("updateBrokenState - OK :", data);
                    resolve(data); 
                } 
            }        
        });
    });

    if (result === 'error') 
        return 'error';

    return result;
}


async function getBrokenDetailData(mn, atime_pns) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({"MN" : String(mn), "CKEY" : atime_pns})
    let lambdaParam = {
        FunctionName : 'tymict_webmanager_error_get',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    if(TymConst.IS_DEVELOPMENT) {
        console.log('getBrokenDetailData :', mn, atime_pns)
    }
    let result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log('getBrokenDetailData Error :', JSON.stringify(err, null, 2));
                reject('error');
            } else if (data == null) {
                resolve('error');
            } else {
                var jsonObj = JSON.parse(data.Payload);
                if (jsonObj.body == null) {
                    resolve('error');
                } else {
                    const innerJsonObj = JSON.parse(jsonObj.body);
                    if(TymConst.IS_DEVELOPMENT) {
                        console.log("getBrokenDetailData OK :", innerJsonObj);
                    }
                    resolve(innerJsonObj); 
                }                       
            }        
        });
    });

    if (result !== 'error') {
        result.Items.sort(function(a,b) {
            if (a[0].OTime > b[0].OTime) return -1;
            else return 1;
        });
    }
    //console.log("getBrokenDetailData return :", result);
    return result;
}

// 특정 기대의 소모품인덱스의 메모를 변경한다.
async function updateCItemServiceMemo(mn, index, memo) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "MN" : String(mn),
        "INDEX" : String(index),
        "MEMO" : String(memo)
    })

    let lambdaParam = {
        FunctionName : 'tymict_update_citem_service_memo',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    let result = await new Promise((resolve, reject) => {
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log('updateCItemServiceMemo Error :', err);
                reject('error');
            } else if (data == null) {
                resolve('error');
            } else {
                var jsonObj = JSON.parse(data.Payload);
                console.log('updateCItemServiceMemo OK :', jsonObj);
                resolve(jsonObj); 
            }        
        })
    })

    if (result === 'error') 
        return false

    return true
}

async function updateCItemState(mn, citem, rtime, resolver, rmemo) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "MN" : String(mn), 
        "CITEM": String(citem),
        "RTIME": String(rtime),
        "RESOLVER": String(resolver),
        "MEMO": String(rmemo)   
    })
    let lambdaParam = {
        FunctionName : 'tymict_webmanager_citem_resolve',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error')
            } else {
                var jsonObj = JSON.parse(data.Payload)

                //console.log('updateCItemState', data)
                if (jsonObj.Items == undefined || jsonObj.Items.length == 0)
                    resolve('error');
                else
                    resolve(jsonObj.Items);
            }        
        })
    })

    if (result === 'error') 
        return 'error'

    return result;
}

async function updateCItemStateV3(mn, citem, rTime, resolver, rmemo, category, partsInfo) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "MN" : String(mn), 
        "CITEM": String(citem),
//        "ATime" : String(aTime),
        "RTIME": String(rTime),
        "RESOLVER": String(resolver),
        "MEMO": String(rmemo),
        "CATEGORY" : category,
        "PARTSINFO" : partsInfo
    })
    let lambdaParam = {
        FunctionName : 'tymict_webmanager_citem_resolve_v3',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    if(TymConst.IS_DEVELOPMENT) {
        console.log('updateCItemStateV3 :', JSON.parse(tpayload))
    }
    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject(err)
            } else if (data == null) {
                reject('data is null')
            } else {
                var jsonObj = JSON.parse(data.Payload)
                // console.log(jsonObj, typeof(jsonObj))
                if(typeof(jsonObj) == 'string') {
                    reject(jsonObj)
                } else {
                    if (jsonObj.Items == undefined || jsonObj.Items.length == 0)
                        reject('Empty result');
                    else
                        resolve(jsonObj.Items);
                }
            }        
        })
    })

    if (result === 'error') 
        return 'error'

    return result;
}

// 연간 사용시간
// startTime : YYYY-MM-DD HH:mm:ss
// endTime : YYYY-MM-DD HH:mm:ss
async function getYearlyInfobyMNorSN(mn, sn, startTime, endTime) {
    const store = useTymictStore()
    let offset = TymCommon.getTimezoneOffset()
    let STime = '', ETime = ''

    if(TymCommon.isEmpty(endTime)) {
        let srcDate = moment(startTime).toDate()
        let endDate = moment(startTime).toDate()
        srcDate.setMonth(0)
        srcDate.setDate(1)
        srcDate.setHours(0, 0, 0)
        endDate.setFullYear(endDate.getFullYear() + 1)
        endDate.setMonth(0)
        endDate.setDate(1)
        endDate.setHours(0, 0, 0)
        STime = moment(srcDate).format("YYYY-MM-DD HH:mm:ss")
        ETime = moment(endDate).format("YYYY-MM-DD HH:mm:ss")
    } else {
        STime = startTime
        ETime = endTime
    }

    /* DynamoDB용
    let tpayload = JSON.stringify({
        "SN": sn,
        "START": startDay
    })
    */
    /*
    RDS버전
    "MN": "ES_FAKE_MN_11",
    "STIME": "2023-01-01 00:00:00.000",
    "ETIME": "2023-12-31 23:59:59.999",
    "OFFSET": "-540",
    "설명": [
        "SN(SN, MN 둘중 하나 필수) - 국내 검색 시 키 값",
        "MN(SN, MN 둘중 하나 필수) - 국외 검색 시 키 값",
        "STIME - YYYY-MM-DD HH:mm:ss.fff, 통계 시작 날짜(포함), 국내: 한국시간, 국외: UTC 0",
        "ETIME - YYYY-MM-DD HH:mm:ss.fff, 통계 종료 날짜(포함), 국내: 한국시간, 국외: UTC 0",
        "OFFSET - GMT OFFSET, 부호(+/-/생략)+분단위, ex) 130, -950, +230, 국내: X, 국외: 필수"
    ]
    */

    let tpayload = {
//        "MN": mn,
        "STIME": STime,
        "ETIME": ETime,
        "OFFSET": String(offset)
    }
    if(store.connectServer == 0) {
        tpayload.SN = sn
    } else {
        tpayload.MN = mn
    }

    tpayload = JSON.stringify(tpayload)

    let lambdaParam = {
        FunctionName : 'tymict_webmanager_yearlyinfo_MW2_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }
    if(TymConst.IS_DEVELOPMENT) {
        console.log('getYearlyInfobyMNorSN 11:', tpayload)
    }

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject(err)
            } else if (data == null) {
                reject('Empty data')
            } else {
                var jsonObj = JSON.parse(data.Payload)
                // console.log('getYearlyInfobySN', jsonObj)
                if ( jsonObj.body == undefined)
                    resolve('Data is not invalid');
                else
                    resolve( jsonObj);
            }        
        })
    })

    if (result === 'error') 
        return 'error'

    return result;
}

// start : "20230301"
// end : "20230331"
async function getUsingInfobyMNorSN(mn, sn, startTime, endTime) {
    const store = useTymictStore()
    let offset = TymCommon.getTimezoneOffset()
    let STime = '', ETime = ''

    if(TymCommon.isEmpty(endTime)) {
        let srcDate = moment(startTime).toDate()
        let endDate = moment(startTime).toDate()
        srcDate.setMonth(0)
        srcDate.setDate(1)
        srcDate.setHours(0, 0, 0)
        endDate.setFullYear(endDate.getFullYear() + 1)
        endDate.setMonth(0)
        endDate.setDate(1)
        endDate.setHours(0, 0, 0)
        STime = moment(srcDate).format("YYYY-MM-DD HH:mm:ss")
        ETime = moment(endDate).format("YYYY-MM-DD HH:mm:ss")
    } else {
        STime = startTime
        ETime = endTime
    }
    /*
    {
        "SN": "T001215101370137",
        "STIME": "2023-03-01 00:00:00.000",
        "ETIME": "2023-12-31 23:59:59.999",
        "설명": [
            "SN(SN, MN 둘중 하나 필수) - 국내 검색 시 키 값",
            "MN(SN, MN 둘중 하나 필수) - 국외 검색 시 키 값",
            "STIME - YYYY-MM-DD hh:mm:ss.fff, 통계 시작 날짜(포함), 국내: 한국시간, 국외: UTC 0",
            "ETIME - YYYY-MM-DD hh:mm:ss.fff, 통계 종료 날짜(포함), 국내: 한국시간, 국외: UTC 0",
            "OFFSET - GMT OFFSET, 부호(+/-/생략)+분단위, ex) 130, -950, +230, 국내: X, 국외: 필수"
        ]
    }
    */
    let tpayload = {
//        "MN": mn,
        "STIME": STime,
        "ETIME": ETime,
        "OFFSET": String(offset)
    }
    if(store.connectServer == 0) {
        tpayload.SN = sn
    } else {
        tpayload.MN = mn
    }
    tpayload = JSON.stringify(tpayload)
    
    let lambdaParam = {
        FunctionName : 'tymict_webmanager_monthlyInfo_MW2_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('Data is empty.')
            } else {
                var jsonObj = JSON.parse(data.Payload)
                //console.log('getUsingInfobySN', jsonObj)
                if ( jsonObj.body == undefined)
                    reject('Palyload is empty.');
                else {
                    //console.log('getUsingInfobySN :', jsonObj)
                    resolve( jsonObj);
                }
            }        
        })
    })

    if (result === 'error') 
        return 'error'

    return result;
}

// start : "2023-03-01"
// end : "2023-03-31"
async function getLocaInfobySN_Maria(sn, start, end) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
            "SN": sn,
            "STIME": start,
            "ETIME" : end
    })
    let lambdaParam = {
        FunctionName : 'tymict_webmanager_getRouteTraceSN_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject(err)
            } else if (data == null) {
                reject('error')
            } else {
                var jsonObj = JSON.parse(data.Payload)
                // console.log('aws.getLocaInfobySN_Maria', jsonObj)
                if ( jsonObj.body == undefined)
                    reject('error');
                else {
                    // console.log('getLocaInfobySN :', jsonObj)
                    resolve( jsonObj);
                }
            }        
        })
    })

    if (result === 'error') 
        return 'error'

    return result;
}

// start : "20230301"
// end : "20230331"
// MARIA DB start : "2023-03-01",  end : "2023-03-31"
async function getDailyInfobySN(sn, start, end) {
    const store = useTymictStore()
    let offset = TymCommon.getTimezoneOffset()
    let tpayload = JSON.stringify({
            "SN": sn,
            "STIME": start,
            "ETIME" : end,
            "OFFSET": String(offset)
        })
    let lambdaParam = {
        // FunctionName : 'tymict_webmanager_dailyInfo',
        FunctionName : 'tymict_webmanager_dailyInfo_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject(err)
            } else if (data == null) {
                reject('Response is empty.')
            } else {
                var jsonObj = JSON.parse(data.Payload)
                if(TymCommon.isEmpty(jsonObj)) {
                    reject('Empty data');
                } else {
                    if(TymConst.IS_DEVELOPMENT) {
                        console.log('getDailyInfobySN', start, end, data, jsonObj)
                    }

                    resolve(jsonObj.Items);
                }
                /*
                if ( jsonObj.body == undefined)
                    reject('error');
                else {
                    // console.log('getLocaInfobySN :', jsonObj)
                    resolve( jsonObj);
                }
                */
            }        
        })
    })

    if (result === 'error') 
        return 'error'

    return result;
}

async function updateGroupInfo(groupInfo, isAddNew) {
    const store = useTymictStore()
    var putParam = {
        TableName: "tymict_manage_group",
        Key:{
            "uuid" : String(groupInfo.uuid),
            "default" : groupInfo.default
        },
        UpdateExpression:"set address = :address, address2 = :address2, postcode = :postcode, phoneno = :phoneno, uuid_a = :uuid_a, group_a = :group_a, uuid_b = :uuid_b, group_b = :group_b, uuid_c = :uuid_c, group_c = :group_c, uuid_d = :uuid_d, group_d = :group_d",
        ExpressionAttributeValues: {
            ":address" : groupInfo.address,
            ":address2" : groupInfo.address2,
            ":postcode" : groupInfo.postcode,
            ":phoneno" : groupInfo.phoneno,
            ":uuid_a" : groupInfo.uuid_a,
            ":group_a" : groupInfo.group_a,
            ":uuid_b" : groupInfo.uuid_b,
            ":group_b" : groupInfo.group_b,
            ":uuid_c" : groupInfo.uuid_c,
            ":group_c" : groupInfo.group_c,
            ":uuid_d" : groupInfo.uuid_d,
            ":group_d" : groupInfo.group_d
        },
        ReturnValues: isAddNew ? "ALL_NEW" : "UPDATED_NEW"
    };

    var result = await new Promise((resolve,reject)=>{
        store. docClient.update(putParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
            }
            else {
                resolve(data)                   
            }        
        });
    });

    if (result === 'error')
        return false;
    return true;
}

// depth : 그룹의 Depth 1~4
// updateInfo = [
//    {
//         uuid: 
//         default: 0 or 1
//         name
//     },
//     ....
// ]
async function updateGroupName(depth, updateInfo, cbResult) {
    const store = useTymictStore()
    let putParam = {
        TableName: "tymict_manage_group",
        /*
        Key:{
            "uuid" : String(groupInfo.uuid),
            "default" : groupInfo.default
        },
        UpdateExpression:"set group_a = :group_a, group_b = :group_b, group_c = :group_c, group_d = :group_d",
        ExpressionAttributeValues: {
            ":group_a" : groupInfo.group_a,
            ":group_b" : groupInfo.group_b,
            ":group_c" : groupInfo.group_c,
            ":group_d" : groupInfo.group_d
        },
        */
        ReturnValues: "UPDATED_NEW"
    }

    let result = false
    for(let idx = 0; idx < updateInfo.length; idx++) {
        let one = updateInfo[idx]

        putParam['Key'] = {
            "uuid" : String(one.uuid),
            "default" : one.default
        }
        switch(depth) {
            case 1:
                putParam["UpdateExpression"] = "set group_a = :group_a"
                putParam["ExpressionAttributeValues"] = {
                    ":group_a" : one.name
                }
                break
            case 2:
                putParam["UpdateExpression"] = "set group_b = :group_b"
                putParam["ExpressionAttributeValues"] = {
                    ":group_b" : one.name
                }
                break
            case 3:
                putParam["UpdateExpression"] = "set group_c = :group_c"
                putParam["ExpressionAttributeValues"] = {
                    ":group_c" : one.name
                }
                break
            case 4:
                putParam["UpdateExpression"] = "set group_d = :group_d"
                putParam["ExpressionAttributeValues"] = {
                    ":group_d" : one.name
                }
                break
        }
        result = await new Promise((resolve,reject)=>{
            store. docClient.update(putParam, function(err, data) {
                if (err) {
                    console.log(JSON.stringify(err, null, 2));
                    reject(err)
                    if(!TymCommon.isEmpty(cbResult)) {
                        cbResult(false, one, err)
                    }
                }
                else {
                    resolve(data)                   
                    if(!TymCommon.isEmpty(cbResult)) {
                        cbResult(true, one, data)
                    }
                }        
            });
        });
    }
    return result
}

function deleteGroup(uuid, cbResult)
{
    const store = useTymictStore()
    const table = "tymict_manage_group";
    var dynamoparams = {
        TableName:table,
        Key:{
            "uuid":uuid,
            "default": 0
        }
    };
    let result = store.docClient.delete(dynamoparams, function(err, data) 
    {
        if (err) {
            console.log(JSON.stringify(err, undefined, 2));
            if(!TymCommon.isEmpty(cbResult)) {
                cbResult(false, err)
            }
            return false
        }
        else 
        {
            // console.log("delteGroupItem  Success", data);
            if(!TymCommon.isEmpty(cbResult)) {
                cbResult(true, data)
            }
            return true
        }
    });

    return result;
}

async function getSalesStat(ma, mb, mc, md, start, end, forExcel, forMonthly) {
    const store = useTymictStore()
    let offset = TymCommon.getTimezoneOffset()
    let tpayload = {
      "MA" :  ma,
      "STIME": start,
      "ETIME": end,
      "TYMICT": store.isICTUser(),
      "EXCEL": TymCommon.isEmpty(forExcel) ? false : forExcel,
      "MONTHLY": TymCommon.isEmpty(forMonthly) ? false : forMonthly,
      "OFFSET": String(offset)
    }
    if(!TymCommon.isEmpty(mb))
        tpayload.MB = mb
    if(!TymCommon.isEmpty(mc))
        tpayload.MC = mc
    if(!TymCommon.isEmpty(md))
        tpayload.MD = md

    var lambdaParam = {
        FunctionName : 'tymict_saleslist_report_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: JSON.stringify(tpayload)
    }

    if(TymConst.IS_DEVELOPMENT)
        console.log('TymAws.getSalesStat :', tpayload)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                reject('error');
                console.log('getSalesStat failed', err)
            } else if (data == null) {
                console.log('getSalesStat data is null')
                reject('error')
            } else {
                var jsonObj = JSON.parse(data.Payload)
                // console.log('getSalesStat :', jsonObj)
                resolve(jsonObj)
            }        
        })
    })

    if (result === 'error') 
        return 'error'
    if (result.Count == 0)
        return 'error'

    return result.Items
}

// 통계데이터 고장이력 구하는 것
// start, end : OTime 포맷 : YYYY-MM-DD hh:mm
async function getErrorHistory(manage, start, end, isTymict, forExcel) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
            "MANAGE": manage,
            "S_START": start,
            "S_END": end,
            "TYMICT" : isTymict,
            "EXCEL" : forExcel
    })
    let lambdaParam = {
        FunctionName : 'tymict_errorhistory_report',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 
    // console.log('getErrorHistory :', tpayload)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log(data)
                let json = JSON.parse(data.Payload)
                resolve(json);                         
            }        
        })
    })

    return result;
}

// 통계데이터 긴급이력 구하는 것
// start, end : OTime 포맷 : YYYY-MM-DD hh:mm
// 
async function getEmergencyHistory(manage, start, end, isTymict, forExcel) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
            "MANAGE": manage,
            "S_START": start,
            "S_END": end,
            "TYMICT" : isTymict,
            "EXCEL" : forExcel
    })
    let lambdaParam = {
        FunctionName : 'tymict_emergencyhistory_report',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 
    //console.log('getEmergencyHistory :', tpayload)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log(data)
                let json = JSON.parse(data.Payload)
                resolve(json);                         
            }        
        })
    })

    return result;
}

async function getConsumablesHistory(manage, start, end, isTymict, forExcel) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
            "MANAGE": manage,
            "S_START": start,
            "S_END": end,
            "TYMICT" : isTymict,
            "EXCEL" : forExcel
    })
    // 20240213 - 미사용 함수임
    let lambdaParam = {
        FunctionName : 'tymict_citemhistory_report_V3',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 
    //console.log('getConsumablesHistory :', tpayload)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log(data)
                let json = JSON.parse(data.Payload)
                resolve(json);                         
            }        
        })
    })

    return result;
}

async function UNUSED_getBatteryWarningHistory(manage, start, end, isTymict, forExcel) {
    const store = useTymictStore()
    let offset = 0 // TymCommon.getTimezoneOffset(), 받은쪽에서 타임존 계산함
    let tpayload = {
        "STIME": start,
        "ETIME": end,
        "TYMICT": isTymict,
        "EXCEL": forExcel,
        "OFFSET": String(offset)
    }
    if(!TymCommon.isEmpty(manage.MA))
        tpayload.MA = manage.MA
    if(!TymCommon.isEmpty(manage.MB))
        tpayload.MB = manage.MB
    if(!TymCommon.isEmpty(manage.MC))
        tpayload.MC = manage.MC
    if(!TymCommon.isEmpty(manage.MD))
        tpayload.MD = manage.MD

    let lambdaParam = {
        FunctionName : 'tymict_batteryhistory_report',
        InvocationType : 'RequestResponse',
        Payload: JSON.stringify(tpayload)
    }
    /*
    let tpayload = JSON.stringify({
        "MANAGE": manage,
        "S_START": start,
        "S_END": end,
        "TYMICT" : isTymict,
        "EXCEL" : forExcel
    })
    let lambdaParam = {
        FunctionName : 'tymict_batteryhistory_report',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }
    */
    console.log('getBatteryWarningHistory START :', lambdaParam)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                console.log('getBatteryWarningHistory :', data)
                if(data.StatusCode == 200) {
                    let json = JSON.parse(data.Payload)
                    resolve(json);                         
                } else {
                    reject('error battery history - Query failed...')
                }
            }        
        })
    })

    return result;
}

/*
 * 모든 기대의 시동여부
*/
async function getStartedMachines() {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "POWER": "ON",
    })
    let lambdaParam = {
        FunctionName : 'tymict_webmanager_getMachineStartState',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('TymAws.getStartedMachines :', err)
                }
                reject('error')
            } else if (data == null) {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('TymAws.getStartedMachines : data is null')
                }
                reject('error : data is empty')
            } else {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('TymAws.getStartedMachines :', data)
                }
                if(TymCommon.isEmpty(data.Payload)) {
                    reject('error : Payload is empty')
                } else {
                    let json = JSON.parse(data.Payload)
                    if(!TymCommon.isEmpty(json)) {
                        if(!TymCommon.isEmpty(json.body)) {
                            let body = JSON.parse(json.body)
                            resolve(body)
                            return true;
                        }
                    }
                }
                reject('error : data is not valid')
            }        
        })
    })

    return result;
}


async function getStartedMachinesBySN(sn) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "SN" : sn,
        "POWER": "ON",
    })
    let lambdaParam = {
        FunctionName : 'tymict_webmanager_getMachineStartState',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('TymAws.getStartedMachinesBySN :', err)
                }
                reject('error')
            } else if (data == null) {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('TymAws.getStartedMachinesBySN : data is null')
                }
                reject('error : data is empty')
            } else {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('TymAws.getStartedMachinesBySN :', data)
                }
                if(TymCommon.isEmpty(data.Payload)) {
                    reject('error : Payload is empty')
                } else {
                    let json = JSON.parse(data.Payload)
                    if(!TymCommon.isEmpty(json)) {
                        if(!TymCommon.isEmpty(json.body)) {
                            let body = JSON.parse(json.body)
                            resolve(body)
                            return true;
                        }
                    }
                }
                reject('error : data is not valid')
            }        
        })
    })

    return result;
}

async function getUsageTime(manage, start, end, isTymict, forExcel) {
    // 
    /*
    {
        "MANAGE": "[{\"MA\":\"0\",\"MB\":\"2\", \"MC\":\"11\"}]",
        "S_START": "2023-01-01 00:00",
        "S_END": "2023-12-31 24:00",
        "TYMICT": false,
        "EXCEL" : false
    }
    */
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "MANAGE": manage,
        "S_START": start,
        "S_END": end,
        "TYMICT": isTymict,
        "EXCEL" : forExcel
    })
    let lambdaParam = {
        FunctionName : 'tymict_usagehistory_report',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    // console.log(manage, start, end, isICT)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log('tymict_usagehistory_report :', data)
                if(TymCommon.isEmpty(data.Payload)) {
                    reject('error : Payload is empty')
                } else {
                    let json = JSON.parse(data.Payload)
                    if(!TymCommon.isEmpty(json.body)) {
                        let body = JSON.parse(json.body)
                        resolve(body)
                    } else {
                        reject(json)
                    }
                }
            }        
        })
    })

    return result;
}

async function getUsageTimeMARIA(ma, mb, mc, md, start, end, forExcel, forMonthly) {
    const store = useTymictStore()
    let offset = 0 // TymCommon.getTimezoneOffset(), 받은쪽에서 타임존 계산함
    let tpayload = {
        "MA" :  ma,
        "STIME": start,
        "ETIME": end,
        "TYMICT": store.isICTUser(),
        "EXCEL": TymCommon.isEmpty(forExcel) ? false : forExcel,
        "MONTHLY": TymCommon.isEmpty(forMonthly) ? false : forMonthly,
        "OFFSET": String(offset)
      }
      if(!TymCommon.isEmpty(mb))
          tpayload.MB = mb
      if(!TymCommon.isEmpty(mc))
          tpayload.MC = mc
      if(!TymCommon.isEmpty(md))
          tpayload.MD = md

      let lambdaParam = {
        FunctionName : 'tymict_usagehistory_report_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: JSON.stringify(tpayload)
    } 

    if(TymConst.IS_DEVELOPMENT)
        console.log('getUsageTimeMARIA :', tpayload)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log('getUsageTimeMARIA :', data)
                if(TymCommon.isEmpty(data.Payload)) {
                    reject('error : Payload is empty')
                } else {
                    var jsonObj = JSON.parse(data.Payload)
                    if(TymConst.IS_DEVELOPMENT)
                        console.log('getUsageTimeStat :', jsonObj)
                    if(!TymCommon.isEmpty(jsonObj)) {
                        if(!TymCommon.isEmpty(jsonObj.Items)) {
                            resolve(jsonObj.Items)
                            return
                        }
                    }
                    reject('error : empty Items')
                }
            }        
        })
    })

    return result;
}

async function getEmergencyHistoryMARIA(ma, mb, mc, md, start, end, forExcel, forMonthly) {
    const store = useTymictStore()
    let offset = 0 // TymCommon.getTimezoneOffset(), 받은쪽에서 타임존 계산함
    let tpayload = {
        "MA" :  ma,
        "STIME": start,
        "ETIME": end,
        "TYMICT": store.isICTUser(),
        "EXCEL": TymCommon.isEmpty(forExcel) ? false : forExcel,
        "MONTHLY": TymCommon.isEmpty(forMonthly) ? false : forMonthly,
        "OFFSET": String(offset)
      }
      if(!TymCommon.isEmpty(mb))
          tpayload.MB = mb
      if(!TymCommon.isEmpty(mc))
          tpayload.MC = mc
      if(!TymCommon.isEmpty(md))
          tpayload.MD = md

      let lambdaParam = {
        FunctionName : 'tymict_emergencyhistory_report_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: JSON.stringify(tpayload)
    } 

    if(TymConst.IS_DEVELOPMENT)
        console.log('getEmergencyHistoryMARIA :', tpayload)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log('getUsageTimeMARIA :', data)
                if(TymCommon.isEmpty(data.Payload)) {
                    reject('error : Payload is empty')
                } else {
                    var jsonObj = JSON.parse(data.Payload)
                    if(TymConst.IS_DEVELOPMENT)
                        console.log('getEmergencyStat :', jsonObj)
                    if(!TymCommon.isEmpty(jsonObj)) {
                        if(!TymCommon.isEmpty(jsonObj.Items)) {
                            resolve(jsonObj.Items)
                            return
                        }
                    }
                    reject('error : empty Items')
                }
            }        
        })
    })

    return result;
}

async function getBrokenHistoryMARIA(ma, mb, mc, md, start, end, forExcel, forMonthly, brokenTypeIndex) {
    const store = useTymictStore()
    let offset = 0 // TymCommon.getTimezoneOffset(), 받은쪽에서 타임존 계산함
    let tpayload = {
        "MA" :  ma,
        "STIME": start,
        "ETIME": end,
        "TYMICT": store.isICTUser(),
        "EXCEL": TymCommon.isEmpty(forExcel) ? false : forExcel,
        "MONTHLY": TymCommon.isEmpty(forMonthly) ? false : forMonthly,
        "OFFSET": String(offset)
    }
    if(!TymCommon.isEmpty(mb))
        tpayload.MB = mb
    if(!TymCommon.isEmpty(mc))
        tpayload.MC = mc
    if(!TymCommon.isEmpty(md))
        tpayload.MD = md
    if(!TymCommon.isEmpty(brokenTypeIndex))
        tpayload.BTYPE = brokenTypeIndex

      let lambdaParam = {
        FunctionName : 'tymict_errorhistory_report_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: JSON.stringify(tpayload)
    } 

    if(TymConst.IS_DEVELOPMENT)
        console.log('getBrokenHistoryMARIA :', tpayload)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log('getUsageTimeMARIA :', data)
                if(TymCommon.isEmpty(data.Payload)) {
                    reject('error : Payload is empty')
                } else {
                    if(TymConst.IS_DEVELOPMENT)
                        console.log('getErrorStat :', data)
                    var jsonObj = JSON.parse(data.Payload)
                    if(!TymCommon.isEmpty(jsonObj)) {
                        if(!TymCommon.isEmpty(jsonObj.Items)) {
                            resolve(jsonObj.Items)
                            return
                        }
                    }
                    reject('error : empty Items')
                }
            }        
        })
    })

    return result;
}

async function addUpdateModel(modelInfo) {
    const store = useTymictStore()
    let tpayload = JSON.stringify(modelInfo)
    let lambdaParam = {
        FunctionName : 'tymict_mw2_model_add',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    } 

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log('addUpdateModel :', data)
                if(TymCommon.isEmpty(data.Payload)) {
                    reject('error : Payload is empty')
                } else {
                    let json = JSON.parse(data.Payload)
                    if(!TymCommon.isEmpty(json.body)) {
                        let body = JSON.parse(json.body)
                        resolve(body)
                    } else {
                        reject(json)
                    }
                }
            }        
        })
    })

    return result;
}

async function lookup(action, targetUser, desc) {
    const store = useTymictStore()

    let userid = TymCommon.isEmpty(store.AuthUser['username']) ? '' : store.AuthUser['username'];
    let ipaddr = TymCommon.isEmpty(store.localIP) ? '' : store.localIP.ip;

    let tpayload = JSON.stringify({
        "userid" : String(userid),
        "action" : String(action),
        "ipaddr" : String(ipaddr),
        "lookup" : String(targetUser),
        "desc" : String(TymCommon.isEmpty(desc) ? '' : desc)
    })

    let lambdaParam = {
        FunctionName : 'tymict_webmanager_lookup',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }  

    let result = await new Promise((resolve, reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log('Failed in updating lookup ', action)
                reject(err);                
            } else {
                // console.log('Succeeded in updating lookup')
                const innerJsonObj = JSON.parse(data.Payload);
                if(innerJsonObj['statusCode'] == 400) {
                    resolve('error')
                } else {
                    resolve('success')
                }
            }        
        });
    });

    return (result === 'error')?false:true;
}

async function getVutByMN(mn) {
    const store = useTymictStore()

    var tpayload = JSON.stringify( { "MN" : String(mn) } );
    var lambdaParam = {
        FunctionName : 'tymict_webmanager_getCurrentVUT',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    };  

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
            } else if (data == null) {
                resolve('error');
            } else {
                var jsonObj = JSON.parse(data.Payload);
                // console.log("Succeeded in fetching VUT by MN");
                resolve(jsonObj); 
            }        
        });
    });

    if (result === 'error') 
        return 'error';
    if (result.Count == 0)
        return 'error';

    return result.Items;
}

async function checkPassword2(user, username, password, cbSuccess, cbFailure, cbNewPasswordRequired) {
    const store = useTymictStore()
    let authenticationData = {
        Username : username,
        Password : password,
    }

    let data = { 
        UserPoolId: store.cognito[store.connectServer].userPoolId,
        ClientId: store.cognito[store.connectServer].clientId,
    }
    let userPool = new CognitoUserPool(data);
    var userData = {
        Username : username,
        Pool : userPool,
    }
    let cogUser = await new CognitoUser(userData);
    if(!cogUser)
        cogUser = user

    if(TymConst.IS_DEVELOPMENT) {
        console.log('checkPassword2 START :', username, user, cogUser)
    }
    
    cogUser.authenticateUser(new AuthenticationDetails(authenticationData), { 
        onSuccess: function (result) {
            // 내 계정 암호 일치했냐?
            if(TymConst.IS_DEVELOPMENT) {
                console.log('authenticateUser.onSuccess :', result)
            }
            if(cbSuccess) {
                cbSuccess(result)
            }
        },
        onFailure: function(err) {
            if(TymConst.IS_DEVELOPMENT) {
                console.log('authenticateUser.onFailure :', err)
            }
            cbFailure(TymCommon.isEmpty(err.code) ? '' : err.code, TymCommon.isEmpty(err.message) ? '' : err.message)
        },
        newPasswordRequired: function(userAttributes, requiredAttributes) {
            if(TymConst.IS_DEVELOPMENT) {
                console.log('checkPassword2.newPasswordRequired :', userAttributes, requiredAttributes)
            }
            if(!TymCommon.isEmpty(cbNewPasswordRequired))
                cbNewPasswordRequired(userAttributes, requiredAttributes)
        },
    })
}

// 로그인 후에... 사용할 수 있는 함
function checkPassword(password, cbSuccess, cbFailure, cbNewPasswordRequired) {
    const store = useTymictStore()

    let authenticationData = {
        Username : true, 
        Password : password,
    }

    store.AuthUser.getSession(function(err /*, session */) {
        if (err) {
            TymCommon.Toast(err)
            return
        }
        // 내정보 구하기
        store.AuthUser.getUserAttributes(function(err /*, result */) {
            if (err) {
                console.log(err)
                if(cbFailure) {
                    cbFailure(TymCommon.isEmpty(err.code) ? '' : err.code, TymCommon.isEmpty(err.message) ? '' : err.message)
                }
                return
            }

            store.AuthUser.authenticateUser(new AuthenticationDetails(authenticationData), { 
                onSuccess: function (result) {
                    // 내 계정 암호 일치했냐?
                    if(TymConst.IS_DEVELOPMENT) {
                        console.log('authenticateUser.onSuccess :', result)
                    }
                    if(cbSuccess) {
                        cbSuccess(result)
                    }
                },
                onFailure: function(err) {
                    if(TymConst.IS_DEVELOPMENT) {
                        console.log('authenticateUser.onFailure :', err)
                    }
                    cbFailure(TymCommon.isEmpty(err.code) ? '' : err.code, TymCommon.isEmpty(err.message) ? '' : err.message)
                },
                newPasswordRequired: function(userAttributes, requiredAttributes) {
                    if(TymConst.IS_DEVELOPMENT) {
                        console.log('authenticateUser.newPasswordRequired :', userAttributes, requiredAttributes)
                    }
                    if(!TymCommon.isEmpty(cbNewPasswordRequired))
                        cbNewPasswordRequired(userAttributes, requiredAttributes)
                },
            })
        })
    })
}


async function modifySalesData(isEdit, mn, sn, sm, smTitle, mon, uid, hp, user, pd, sd, ed, st, ma, mb, mc, md, adsType, adsLv, adsDType)
{
    const store = useTymictStore()
    var rawJson = {
        "MN" : String(mn), 
        "SN": String(sn),
        "SM": String(sm),
        "SMTITLE": String(smTitle),
        "MON": String(mon),
        "UID": String(uid),
        "HP": String(hp),
        "USER": String(user),
        "SD": String(sd),
        "ED": String(ed),
        "PD": String(pd),
        "ST": String(st),
        "MA": String(ma),
        "MB": String(mb),
        "MC": String(mc),
        "MD": String(md),
        "ADSTYPE" : String(adsType),
        "ADSLV" : String(adsLv),
        "DTYPE" : String(adsDType)
    }
    if (isEdit != undefined && isEdit == true)
        rawJson["EDIT"] = true

    if(TymConst.IS_DEVELOPMENT) {
        console.log('modifySalesData 0:', rawJson)
    }
    var tpayload = JSON.stringify(rawJson)

    var lambdaParam = {
        FunctionName : 'tymict_sales_add',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log('Add sale info : FAILED :', err)
                reject(err);
            } else if (data == null) {
                reject('error');
            } else {
                var jsonObj = JSON.parse(data.Payload);
                if(jsonObj.RETURN)
                    resolve(jsonObj)
                else {
                    if(TymConst.IS_DEVELOPMENT) {
                        console.log('Add sale info :', data)
                    }
                    reject(jsonObj)
                }
            }        
        })
    })

    if (result === 'error') {
        console.log('Unknown Error')  //알 수 없는 에러입니다
        return false;
    }
    
    if (result.RETURN == true) {
        return true;
    } else {
        if (result.REASON == undefined) {
            console.log('Unknown Error')   //알 수 없는 에러입니다
            return false;
        }
        if (result.REASON.includes('SN_INFO'))
        {
            console.log('Invalid serial number.')  //유효하지 않은 시리얼번호입니다
        }
        else if ( result.REASON.includes('OVERLAP') )
        {
            console.log('Already registered MN')    //이미 등록된 기대번호입니다
        }
        else
        {
            console.log('Unknown Error')   //알 수 없는 에러입니다
        }
        return false
    }
}


async function removeSalesData(mn, sn, bBackup) {
    const store = useTymictStore()
    var tpayload = JSON.stringify({
        "MN" : String(mn),
        "SN" : String(sn),
        "DEL" : 'true'
    })
    if(bBackup) {
        tpayload['BACKUP'] = true;
    }

    var lambdaParam = {
        FunctionName : 'tymict_sales_add',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log('removeSalesData : FAILED :', err)
                reject(err)
            } else if (data == null) {
                resolve('error')
            } else {
                var jsonObj = JSON.parse(data.Payload);
                console.log("Succeeded in deleting sales data!");
                resolve(jsonObj)
            }        
        })
    })

    if (result === 'error') 
        return false

    return true
}

async function getMachineMN(mn, sn) {
    const store = useTymictStore()
    var rawJson = {
        "MN" : String(mn),
        // "DUAL_INFO" : false,     // 동시장착에 대한 것이냐?
        "SN_INFO": '1'
    }
    if(!TymCommon.isEmpty(sn)) {
        rawJson.SN = sn
        rawJson.SN_INFO = "1"
    }
    var tpayload = JSON.stringify(rawJson)
    var lambdaParam = {
        FunctionName : 'tymict_check_snmn_valid',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log('getMachineMN : FAILED :', err)
                reject('error');
            } else if (data == null) {
                reject('error');
            } else {
                var jsonObj = JSON.parse(data.Payload);
                // console.log('getMachineMN : OK :', jsonObj)
                resolve(jsonObj);
            }        
        })
    })

    return result
}


async function getMachineInfoByMN(mn) {
    const store = useTymictStore()
    var rawJson = {
        "MN" : String(mn)
    };

    var tpayload = JSON.stringify(rawJson);
    var lambdaParam = {
        FunctionName : 'tymict_webmanager_getCurrentStateByMN',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    };  

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log('getMachineInfoByMN : FAILED :', err)
                reject('error');
            } else if (data == null) {
                reject('error');
            } else {
                var jsonObj = JSON.parse(data.Payload);
                // console.log('getMachineInfoByMN : OK :', jsonObj)
                resolve(jsonObj);
            }        
        })
    })

    return result
}

async function updateUserInfoFromDB(userInfo) {
    const store = useTymictStore()

    var param = {
        TableName: "tymict_managerweb_user",
        Key: {
            "username" : String(userInfo.userid)
        },
        UpdateExpression:"set #username = :username, email = :email, uphone = :uphone, uenable = :uenable, ulevel = :ulevel, uuid_a = :uuid_a",
        ExpressionAttributeNames: {
            "#username" : 'name'
        },
        ExpressionAttributeValues: {            
            ":username" : String(userInfo.username),
            ":email" : String(userInfo.email),
            ":uphone" : String(userInfo.uphone),
            ":uenable" : userInfo.uenable,
            ":ulevel" : userInfo.ulevel,
            ":uuid_a" : String(userInfo.uuid_a)
        },
        ReturnValues:"UPDATED_NEW"       
    }

    if (!TymCommon.isEmpty(userInfo.uuid_b)) {
        param.UpdateExpression = param.UpdateExpression.concat(', uuid_b = :uuid_b');
        param.ExpressionAttributeValues[":uuid_b"] = String(userInfo.uuid_b);
    }
    if (!TymCommon.isEmpty(userInfo.uuid_c)) {
        param.UpdateExpression = param.UpdateExpression.concat(', uuid_c = :uuid_c');
        param.ExpressionAttributeValues[":uuid_c"] = String(userInfo.uuid_c);
    }
    if (!TymCommon.isEmpty(userInfo.uuid_d)) {
        param.UpdateExpression = param.UpdateExpression.concat(', uuid_d = :uuid_d');
        param.ExpressionAttributeValues[":uuid_d"] = String(userInfo.uuid_d);
    }

    console.log('updateUserInfoFromDB :', userInfo)
    var result = await new Promise((resolve,reject)=>{
        store.docClient.update(param, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
            }
            else {
                console.log("Succeeded in adding notification");
                resolve(data);                        
            }        
        })
    })

    return result
}

async function getBatteryWarningHistoryMARIA(ma, mb, mc, md, start, end, forExcel, forMonthly) {
    const store = useTymictStore()
    let offset = 0 // TymCommon.getTimezoneOffset(), 받은쪽에서 타임존 계산함
    let tpayload = {
        "MA" :  ma,
        "STIME": start,
        "ETIME": end,
        "TYMICT": store.isICTUser(),
        "EXCEL": TymCommon.isEmpty(forExcel) ? false : forExcel,
        "MONTHLY": TymCommon.isEmpty(forMonthly) ? false : forMonthly,
        "OFFSET": String(offset)
    }
    if(!TymCommon.isEmpty(mb))
        tpayload.MB = mb
    if(!TymCommon.isEmpty(mc))
        tpayload.MC = mc
    if(!TymCommon.isEmpty(md))
        tpayload.MD = md

      let lambdaParam = {
        FunctionName : 'tymict_batteryhistory_report_MariaDB',
        InvocationType : 'RequestResponse',
        Payload: JSON.stringify(tpayload)
    } 

    if(TymConst.IS_DEVELOPMENT)
        console.log('getBatteryWarningHistory :', tpayload)

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2))
                reject('error')
            } else if (data == null) {
                reject('error : data is empty')
            } else {
                // console.log('getUsageTimeMARIA :', data)
                if(TymCommon.isEmpty(data.Payload)) {
                    reject('error : Payload is empty')
                } else {
                    var jsonObj = JSON.parse(data.Payload)
                    if(TymConst.IS_DEVELOPMENT)
                        console.log('getBatteryWarningHistory :', jsonObj)
                    if(!TymCommon.isEmpty(jsonObj)) {
                        if(!TymCommon.isEmpty(jsonObj.Items)) {
                            resolve(jsonObj.Items)
                            return
                        }
                    }
                    reject('error : empty Items')
                }
            }        
        })
    })

    return result;
}


async function removeProductData(mn, sn) {
    const store = useTymictStore()
    var tpayload = JSON.stringify({
        "MN" : String(mn),
        "SN" : String(sn),
        "OD" : 'DEL',
        "DEL" : 'true'
    })
    var lambdaParam = {
        FunctionName : 'tymict_sales_add',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                reject('error');
            } else if (data == null) {
                resolve('error');
            } else {
                var jsonObj = JSON.parse(data.Payload);
                resolve(jsonObj); 
            }        
        })
    })

    if (result === 'error') 
        return false

    return true;  
}

async function updateProductData(mn, mon, sn, uid, sold, testc, od, pd, adstype, adslv, adsdtype) {
    const store = useTymictStore()
    const rawJson = {
        'MN' : String(mn),
        'SN' : String(sn),
        'MON' : String(mon), 
        'UID' : String(uid),
        'PD' : String(pd),
        'OD' : String(od),
        'SOLD' : Number(sold),
        'TESTC' : Number(testc),
        'EDIT' : true,
        'ADSTYPE' : String(adstype),
        'ADSLV' : String(adslv),
        'DTYPE' : String(adsdtype)
    }
    // console.log("updateProductData", rawJson);
    var tpayload = JSON.stringify(rawJson);
    var lambdaParam = {
        FunctionName : 'tymict_sales_add',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    };

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                reject(err)
            } else if (data == null) {
                reject('error')
            } else {
                var jsonObj = JSON.parse(data.Payload)
                console.log('updateProductData :', jsonObj)
                resolve(jsonObj)
            }        
        })
    })

    return result
}

function changeUserPassword(oldPW, newPW, cbOK, cbFail) {
    const store = useTymictStore()
    var params = {
        AccessToken : store.AccessToken,
        PreviousPassword : oldPW,
        ProposedPassword : newPW
    }
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

    cognitoidentityserviceprovider.changePassword(params, function(err, data) {
        if(err)
        {
            //console.log(err.code)
            if(cbFail)
                cbFail(err.code)
        }
        else
        {
            if(cbOK)
                cbOK(data)
        }
    })
}

function unuseParam() {
//
}

async function AwsValidSync(cogUser, username, password, strHp, strEMail, strNew) {
    unuseParam(username, password, strHp, strEMail, strNew)

    var authenticationData = {
        Username: username,
        Password: password,
    }
    var authenticationDetails = new AuthenticationDetails(
        authenticationData
    )

    cogUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
            console.log('cogUser.authenticateUser', result)
            return true
        },
        onFailure: error => {
            console.log('cogUser.authenticateUser error', error)
            return false
        },
        newPasswordRequired: function(userAttributes, requiredAttributes)
        {
            console.log('newPasswordRequired', userAttributes, requiredAttributes)
            delete userAttributes.email_verified;
            delete userAttributes.email;
            var sessionUserAttributes = userAttributes
            sessionUserAttributes['custom:uphone'] = strHp
            //sessionUserAttributes['email'] = strEMail //email은 직접 바꿀 수 없네요. 
            //sessionUserAttributes['email_verified'] = "true"
            cogUser.completeNewPasswordChallenge(strNew, sessionUserAttributes, {
                onSuccess:(resultPassword) => {
                    console.log(resultPassword)
                    return true
                },
                onFailure:(err) => 
                {
                    console.log("PASSWORD CHANGE ERROR", err)
                    return false
                }
            })
        }
    })
    return false
}
/*
   최초 로그인시 암호... 변경하기 위한 함수
   cogUser
   strUser
   strHp
   strEMail
   strNew
*/
async function registerNewUser(cogUser, strUser, strPassword, strHp, strEMail, strNew) {
    unuseParam(cogUser, strNew)
    const store = useTymictStore()
    const bSync = await AwsValidSync(cogUser, strUser, strPassword, strHp, strEMail, strNew)
    
    if(bSync)
    {
        const dbParam = {
            'username'  : strUser,
            'email' : strEMail,
            'uphone' : strHp,
            'pw' : strNew
        }
        
        var tpayload = JSON.stringify({"PARAMS" : dbParam, "FIRST" : true});
        const lambdaParam = {
            FunctionName : 'tymict_webmanager_addadmin',
            InvocationType : 'RequestResponse',
            Payload: tpayload
        }
        try {
            let lambda = new AWS.Lambda({
                region : store.lambda[store.connectServer].region,
                apiVersion : store.lambda[store.connectServer].apiVersion
            })
    
            var result = await new Promise((resolve,reject)=>{
                lambda.invoke(lambdaParam, function(err, data) {
                    if (err) {
                        console.log('registerNewUser failed', err)
                        reject('error');
                    } else if (data == null) {
                        console.log('registerNewUser data is null')
                        reject('error')
                    } else {
                        var jsonObj = JSON.parse(data.Payload)
                        if (jsonObj.body == null) {
                            reject('error')
                        } else {
                            const innerJsonObj = JSON.parse(jsonObj.body)
                            resolve(innerJsonObj);
                        } 
                    }        
                })
            })

            return result
        }
        catch(exception)
        {
            // tym_alert(TEXT_ERROR_NETWORK);
            //document.getElementById("btn_confirminputok").disabled = false;
        }
    }

    return null
}

async function getSNInfoBySN(sn) {
    const store = useTymictStore()

    var tpayload = JSON.stringify({
        "queryStringParameters" : {
            "SN" : sn
        }
    })
    var lambdaParam = {
        FunctionName : 'tymict_userapp_sn_info',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }
    
    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, async function(err, data) {
            if (err) {
                console.log('getSNInfoBySN result error => ', err)
                reject(err)
            } else if (data == null) {
                console.log('getSNInfoBySN data is null')
                reject(err)
            } else {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('getSNInfoBySN :', data)
                }

                if(data.StatusCode == 200) {
                    let payload = JSON.parse(data.Payload)
                    resolve(payload)
                } else {
                    reject('error : Invalid Status')
                }
            }        
        })
    })

    return result
}

// 작업 내역 기록용
// WorkType , 화면의 내용이면 좋을 듯
async function writeAction(workType, action, desc) {
    /*
    {
        "platform": "MW2",
        "userid": "test계정",
        "ip_address": "1.214.20.202",
        "target": "통계",
        "action": "판매",
        "detail": "{\"MA\":\"TYM\", \"YEAR\":2024, \"MONTH\":3}"
    }
    */
    const store = useTymictStore()

    let userid = TymCommon.isEmpty(store.AuthUser['username']) ? '' : store.AuthUser['username'];
    let ipaddr = TymCommon.isEmpty(store.localIP) ? '' : store.localIP.ip;

    var tpayload = {
        "platform": "MW2", 
        "userid": userid,
        "ip_address": ipaddr,
        "target": workType ? workType : '',
        "action": action ? action : '',
    }
    
    if(!TymCommon.isEmpty(desc)) {
        /*
        서버별로 테이블이 별도라 굳이 명시하지 않아도 된다... 
        if(TymCommon.isEmpty(desc.server)) {
            switch(store.connectServer) {
            case 0:
                desc.server = '국내'
                break
            case 1:
                desc.server = '북미'
                break
            default:
                desc.server = 'Unknown'
                break
            }
        }
        */
        tpayload.detail = JSON.stringify(desc)
    }

    tpayload = JSON.stringify(tpayload)

    var lambdaParam = {
        FunctionName : 'telematics_write_action',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }
    if(TymConst.IS_DEVELOPMENT) {
        console.log('WRITE ACTION', tpayload, store.docLambda)
    }
    
    if(store.docClient == null) {
        store.docClient = new AWS.DynamoDB.DocumentClient({'region': store.cognito[store.connectServer].region})
    }
    if(store.docLambda == null) {
        store.docLambda = new AWS.Lambda( {region : store.lambda[store.connectServer].region, apiVersion : store.lambda[store.connectServer].apiVersion})
    }

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, async function(err, data) {
            if (err) {
                console.log('getSNInfoBySN result error => ', err)
                reject(err)
            } else if (data == null) {
                console.log('getSNInfoBySN data is null')
                reject(err)
            } else {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('getSNInfoBySN :', data)
                }

                if(data.StatusCode == 200) {
                    let payload = JSON.parse(data.Payload)
                    resolve(payload)
                } else {
                    reject('error : Invalid Status')
                }
            }        
        })
    })

    return result
}

async function getSNInfo(sn)
{
    const store = useTymictStore()
    var param = {
        TableName: "SN_Info",
        KeyConditionExpression: "SN = :sn",
        ExpressionAttributeValues: {
            ":sn": sn
        }        
    }

    var result = await new Promise((resolve,reject)=>{
        store.docClient.query(param, function(err, data) {
            if (err) {
                console.log(JSON.stringify(err, null, 2));
                reject('error');
            }
            else {
                resolve(data);                        
            }        
        });
    });

    if (result === 'error')
        return 'error';
    else 
        return result;
}

async function editUserInfo(id, name, hp) {
    const store = useTymictStore()
    let tpayload = JSON.stringify({
        "ID": id,
        "NAME": name,
        "HP": hp
    })
    let lambdaParam = {
        FunctionName : 'tymict_userinfo_edit',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    }
    
    let result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, async function(err, data) {
            if (err) {
                console.log('getSNInfoBySN result error => ', err)
                reject(err)
            } else if (data == null) {
                console.log('editUserInfo data is null')
                reject(err)
            } else {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('editUserInfo :', data)
                }
                resolve(data)
                /*

                if(data.StatusCode == 200) {
                    let payload = JSON.parse(data.Payload)
                    resolve(payload)
                } else {
                    reject('error : Invalid Status')
                }
                */
            }        
        })
    })

    return result
}

async function getUSIM(iccid, start, end) {
    const store = useTymictStore()
    let lambdaParam = {
        FunctionName : 'tymict_get_USIM',
        InvocationType : 'RequestResponse',
        Payload: ''
    }
    let tpayload = {}

    if(!TymCommon.isEmpty(iccid)) {
        tpayload.ICCID = iccid
    }
    if(!TymCommon.isEmpty(start)) {
        tpayload.START = start
    }
    if(!TymCommon.isEmpty(end)) {
        tpayload.END = end
    }
    console.log(Object.keys(tpayload), iccid, start, end)
    if(Object.keys(tpayload).length > 0) {
        lambdaParam.Payload = JSON.stringify(tpayload)
    }
    let result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, async function(err, data) {
            if (err) {
                console.log('getUSIM result error => ', err)
                reject(err)
            } else if (data == null) {
                console.log('getUSIM data is null')
                reject('Data is empty.')
            } else {
                if(TymConst.IS_DEVELOPMENT) {
                    console.log('getUSIM :', data)
                }
                if(!TymCommon.isEmpty(data.Payload)) {
                    let payload = JSON.parse(data.Payload)
                    if(payload.isSucceeded) {
                        if(payload.result.length > 0) {
                            resolve(payload.result)
                        } else {
                            reject('Data is empty.')
                        }
                    } else {
                        reject('Function failed.')
                    }
                } else {
                    reject('Data is empty.')
                }
            }        
        })
    })

    return result
}

async function getSNInfoData() {
    const store = useTymictStore()
    let param = {
      TableName: "SN_Info"
    };

    let result = await new Promise((resolve,reject)=>{
      store.docClient.scan(param, function(err, data) {
        if (err) {
          console.log(err);
          reject(err);
        }
        else {
          // console.log(data)
          if (data.Count == 0) {
            reject("Empty data.")
          } else {
            resolve(data)
          }                        
        }        
      });
    }); 

    return result
}

async function getDataFromSNInfo(mn) {
    const store = useTymictStore()
    let param = {
        TableName: "SN_Info",
        IndexName: "MN-index",
        KeyConditionExpression: "MN = :mn",
        ExpressionAttributeValues: {
            ":mn": mn
        }        
    }

    let result = await new Promise((resolve,reject)=>{
      store.docClient.query(param, function(err, data) {
        if (err) {
          console.log(err);
          reject(err);
        }
        else {
          console.log(data)
          if (data.Count == 0) {
            reject("Empty data.")
          } else {
            resolve(data)
          }                        
        }        
      });
    }); 

    return result
}

function changeManagerActivation(userid, toEnable, cbResult) {
    const store = useTymictStore()
    let paramValue = {
        UserPoolId: store.cognito[store.connectServer].userPoolId,
        Username: userid
    };
    let  cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

    if(toEnable) {
        cognitoidentityserviceprovider.adminEnableUser(paramValue, function(err, data) {
            if (err) {
                console.log(err, err.stack); // an error occurred
                if(!TymCommon.isEmpty(cbResult)) {
                    cbResult(false, err)
                }
            } else {
                if(!TymCommon.isEmpty(cbResult)) {
                    cbResult(false, data)
                }
            }
        });
    } else {
        cognitoidentityserviceprovider.adminDisableUser(paramValue, function(err, data) {
            if (err) {
                console.log(err, err.stack); // an error occurred
                if(!TymCommon.isEmpty(cbResult)) {
                    cbResult(false, err)
                }
            } else {
                if(!TymCommon.isEmpty(cbResult)) {
                    cbResult(false, data)
                }
            }
        });
    }
}


async function editSalesInfo(info) {
    const store = useTymictStore()
    const rawJson = {}

    if(!TymCommon.isEmpty(info.MN)) {
        rawJson.MN = info.MN
    } else if(!TymCommon.isEmpty(info.SN)) {
        rawJson.SN = info.SN
    }
    rawJson.KV = info.KV
    console.log("editSalesInfo", rawJson);

    var tpayload = JSON.stringify(rawJson);
    var lambdaParam = {
        FunctionName : 'tymict_sales_edit',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    };

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                reject(err)
            } else if (data == null) {
                reject('error')
            } else {
                var jsonObj = JSON.parse(data.Payload)
                console.log('editSalesInfo :', jsonObj)
                resolve(jsonObj)
            }        
        })
    })

    return result
}


async function requestUdpateUSIM(iccid) {
    const store = useTymictStore()
    const rawJson = {
        DeviceId: iccid
    }

    var tpayload = JSON.stringify(rawJson);
    var lambdaParam = {
        FunctionName : 'tymict_request_USIM_Info',
        InvocationType : 'RequestResponse',
        Payload: tpayload
    };

    var result = await new Promise((resolve,reject)=>{
        store.docLambda.invoke(lambdaParam, function(err, data) {
            if (err) {
                reject(err)
            } else if (data == null) {
                reject('error')
            } else {
                var jsonObj = JSON.parse(data.Payload)
                console.log('requestUdpateUSIM :', jsonObj)
                resolve(jsonObj)
            }        
        })
    })

    return result
}

export default {
    getCurPos,
    getLastPosByMN,
    getLastPosBySN,
    getMainInfo,
    getGroupNameFromUuid,
    getAllGroupInfo,
    containsAbnormalMn,
    getGroupBrokenData,
    getGroupBrokenData2,
    getGroupEmergencyData,
    getGroupEmergencyData2,
    getGroupConsumablesData,
    getGroupConsumablesData2,
    getSalesByUserId,
    getLastDataBySN,
    getModelInfo,
    getS3Image,
    getModelList,
    getADSInfo,
    getTimezoneList,
    getWebConfig,
    getEmergencyAlertByMN,
    getEmergencyHistoryByMN,
    getBrokenAlertByMN,
    getBrokenHistoryByMN,
    getConsumablesAlertByMN,
    getConsumablesHistoryByMN,
    getConsumablesHistoryMARIA,
    getBatteryAlert,
    getBatteryAlert2,
    changeUserInfo,
    getGroupSalesData,
    getAllUsers,
    getAllManagers,
    getAllManagersFromDB,
    isUserExist,
    getMachineByMN,
    getPDList,
    SetAdminHistory,
    requestDeleteuser,
    getAllProductData,
    updateAlertState,
    updateBrokenState,
    getBrokenDetailData,
    updateCItemServiceMemo,
    updateCItemState,
    updateCItemStateV3,
    getYearlyInfobyMNorSN,
    getUsingInfobyMNorSN,
    getLocaInfobySN_Maria,
    getDailyInfobySN,
    updateGroupInfo,
    updateGroupName,
    deleteGroup,
    getSalesStat,
    getErrorHistory,
    getEmergencyHistory,
    getConsumablesHistory,
    getStartedMachines,
    getStartedMachinesBySN,
    getUsageTime,
    addUpdateModel,
    UNUSED_getBatteryWarningHistory,
    lookup,
    getVutByMN,
    checkPassword2,
    checkPassword,
    modifySalesData,
    removeSalesData,
    getMachineMN,
    getMachineInfoByMN,
    updateUserInfoFromDB,
    getUsageTimeMARIA,
    getEmergencyHistoryMARIA,
    getBrokenHistoryMARIA,
    getBatteryWarningHistoryMARIA,
    removeProductData,
    updateProductData,
    changeUserPassword,
    AwsValidSync,
    registerNewUser,
    getSNInfoBySN,
    writeAction,
    getSNInfo,
    editUserInfo,
    getUSIM,
    getSNInfoData,
    getDataFromSNInfo,
    changeManagerActivation,
    editSalesInfo,
    requestUdpateUSIM
}
