import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import { useTymictStore } from "@/store/tymict"
import moment from 'moment-timezone'
import TymConst from '@/js/tymconstants'

async function Toast(msg, isError) {
    let html = '<div style="font-size:12pt;font-weight:600;height:40pt;" class="row items-center">'
    html    += '<div class="col">'
    html    += msg
    html    += '</div>'
    html    += '</div>'

    let iconType = 'warning'
    let icoColor = 'black'
    let txtColor = 'black'

    iconType = isError ? 'cancel' : 'warning';
    icoColor = isError ? 'red-7' : 'primary';
    txtColor = isError ? 'red-7' : 'black';

    try {
        Notify.create({
            message: html,
            icon : iconType,
            iconSize : '32px',
            color : 'grey-4',
            iconColor : icoColor,
            textColor : txtColor,
/*            
            progress : true,
            timeout : 2000,
*/
            html: true
          })
      } catch(err) {
      console.log(err)
    }
}

const MsgBox = (titleText, msg, okFunction, cancelFunc, dismissFunc) => {
    Dialog.create({
        title: titleText,
        style : "padding:12px;",
        message: msg,
        cancel: {
            color: 'grey-4',
            'text-color' : 'black'
        },
        ok: {
            color: 'red-7'
        },
        persistent: true
    }).onOk(() => {
        if(!isEmpty(okFunction)) {
            okFunction()
        }
    }).onCancel(() => {
        if(!isEmpty(cancelFunc)) {
            cancelFunc()
        }
    }).onDismiss(() => {
        if(!isEmpty(dismissFunc)) {
            dismissFunc()
        }
    })
}

const MsgBoxOK = (titleText, msg, okFunction) => {
    Dialog.create({
        title: titleText,
        style : "padding:12px;",
        message: msg,
        ok: {
            color: 'red-7'
        },
        persistent: true
    }).onOk(() => {
        if(!isEmpty(okFunction)) {
            okFunction()
        }
    })
}

const isEmpty = (str) => {
    if((str === 'undefined') || (typeof str === 'undefined') || (str == null) || (str === '') || (str === ' ') || (str === undefined)) {
        return true
    }
    return false
}

function getUserUuidSingle(uuid_a, uuid_b, uuid_c, uuid_d)
{
    if(!isEmpty(uuid_d))
        return uuid_d

    if(!isEmpty(uuid_c))
        return uuid_c

    if(!isEmpty(uuid_b))
        return uuid_b

    return uuid_a; 
}

function makeUuidArray(uuid_a, uuid_b, uuid_c, uuid_d)
{
    var MObject = new Object();
    var MArray = new Array();
    if(!isEmpty(uuid_a))
        MObject.MA = uuid_a;

    if(!isEmpty(uuid_b))
        MObject.MB = uuid_b;

    if(!isEmpty(uuid_c))
        MObject.MC = uuid_c;

    if(!isEmpty(uuid_d))
        MObject.MD = uuid_d;

    MArray.push(MObject);

    return MArray;
}

function getLevelFromUuid(uuid_b, uuid_c, uuid_d)
{
    if(!isEmpty(uuid_d))
        return 3;

    if(!isEmpty(uuid_c))
        return 2;

    if(!isEmpty(uuid_b))
        return 1;

    return 0; 
}


function leadingZeros(n, digits) {

    var zero = '';
    n = n.toString();

    if (n.length < digits) {
        for (var i = 0; i < digits - n.length; i++)
            zero += '0';
    }
    return zero + n;
}

// 타임존 이름 구하기
function getTimeZone() {
    const store = useTymictStore()
    let curTZ = ''
    if(store.connectServer == 0) {
        curTZ = "Asia/Seoul"
    } else {
        if(store.timezoneIndex == 0) {
            curTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
        } else {
            curTZ = store.timezoneList[store.timezoneIndex].value
        }
    }
    return curTZ
}

function getSTime() {
    var curr = new Date()
    var utc = curr.getTime() + (curr.getTimezoneOffset() * 60000)
    const currentday = new Date(utc + 32400000)
    const SDay = new Date(currentday.setMonth(currentday.getMonth() - 12))       // 12개월
    const d = new Date(SDay);  
    var rValue = leadingZeros(d.getFullYear(), 4) +
        leadingZeros(d.getMonth() + 1, 2) +
        leadingZeros(d.getDate(), 2) + ':' +
        leadingZeros(d.getHours(), 2) + 
        leadingZeros(d.getMinutes(), 2) +
        leadingZeros(d.getSeconds(), 2)  + '.' + 
        leadingZeros(d.getMilliseconds(), 3)
    return rValue
}

function getETime() {
    var curr = new Date()
    var utc = curr.getTime() + (curr.getTimezoneOffset() * 60000)
    const currentday = new Date(utc + 32400000)
    const d = new Date(currentday)
    var rValue = leadingZeros(d.getFullYear(), 4) +
        leadingZeros(d.getMonth() + 1, 2) +
        leadingZeros(d.getDate(), 2) + ':' +
        leadingZeros(d.getHours(), 2) + 
        leadingZeros(d.getMinutes(), 2) +
        leadingZeros(d.getSeconds(), 2)  + '.' + 
        leadingZeros(d.getMilliseconds(), 3)
    return rValue
}

////////////////////////////////
/// 문자열(yyyymmdd.hhMMss.TTT^n)을 Date 타입으로 변환
/// 국내 - KST
/// 국외 - UTC
function stringToDate(str) {
    try {
        let date = str.substring(0, 8)
        let time = str.substring(9, 9 + 10)
        let daytime = null
        const store = useTymictStore()
        if(store.connectServer == 0) {
            daytime = new Date(date.substring(0, 4) +"-"+ date.substring(4, 6) +"-"+ date.substring(6, 8)
                    + " " + time.substring(0, 2) + ":" + time.substring(2, 4) + ":" + time.substring(4, 6) + "." + time.substring(8, 11)
            )
        } else {
            daytime = new Date(Date.UTC(date.substring(0, 4), date.substring(4, 6) - 1, date.substring(6, 8),
                                        time.substring(0, 2), time.substring(2, 4), time.substring(4, 6), time.substring(8, 11)))
        }

        return daytime
    } catch(ex) {
        console.log("stringToDate error : ", str, ex)
        return undefined
    }
}

////////////////////////////////
/// 문자열(yyyy-mm-dd hh:MM)을 Date 타입으로 변환
/// 국내 - KST
/// 국외 - UTC
function stringToDateTime(str) {
    try {
        let daytime = null

        const store = useTymictStore()
        if(store.connectServer == 0) {
            daytime = new Date(str + ".000")
        } else {
            let srcOrgDate = str.replaceAll('-', ':')
            srcOrgDate = srcOrgDate.replaceAll('.', ':')
            srcOrgDate = srcOrgDate.replaceAll(' ', ':')
            let dtArray = srcOrgDate.split(':')

            daytime = new Date(Date.UTC(dtArray[0], dtArray[1] - 1, dtArray[2], dtArray[3], dtArray[4], 0, 0))
        }
        return daytime
    } catch(ex) {
        console.log("stringToDateTime error : ", str, ex)
        return undefined
    }
}

////////////////////////////////
/// Date 형 자료를 로케일을 적용한 Short/24시간제 문자열로 변환해준다.
/// 인자 
///     datetime : 날짜형 (yyyy/mm/dd hh:MMxxx)
/// 리턴
///     각 로케일별 날짜, ko인 경우 2022.03.24
function getDateString(datetime) {
    try {
        const store = useTymictStore()
        const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
            // hour12: false,
            hourCycle: "h23",
            year:"numeric",
            month:'2-digit',
            day:'2-digit',
            /*
            timeStyle: "short",         // 초까지 보려면 medium
            dateStyle: "short",
            */
        })
        // console.log('getDateString', datetime, mediumTime)
        return mediumTime.format(datetime)
    } catch(ex) {
        console.log('getDateString error', datetime, ex)
    }
    return ''
}

function getMonthString(datetime) {
    try {
        if(!this.isEmpty(datetime)) {
            const store = useTymictStore()
            const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                // hour12: false,
                hourCycle: "h23",
                year:"numeric",
                month:'numeric',
                /*
                day:'2-digit',
                timeStyle: "short",         // 초까지 보려면 medium
                dateStyle: "short",
                */
            })
            // console.log('getMonthString', datetime, mediumTime)
            return mediumTime.format(datetime)
        }
    } catch(ex) {
        console.log('getMonthString error', '[' + datetime + ']', ex)
    }
    return ''
}

////////////////////////////////
/// Date 형 자료를 로케일을 적용한 Short/24시간제 문자열로 변환해준다.
/// 인자 
///     datetime : 날짜형 (yyyy/mm/dd hh:MMxxx)
/// 리턴
///     각 로케일별 날짜/시간, ko인 경우 2022.3.24 13:02
function getDateTimeString(datetime) {
    if(!this.isEmpty(datetime)) {
        try {
            const store = useTymictStore()
            const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                hourCycle: "h23",
                year:"numeric",
                month:'2-digit',
                day:'2-digit',
                hour:'2-digit',
                minute:'2-digit',
            })
            return mediumTime.format(datetime)
        } catch(ex) {
            console.log('getDateTimeString', datetime, ex)
        }
    }
    return ''
}

///
/// datetime : yyyymmdd:HHMMSS.ttt^xx
function convertStringToDateTime(datetime) {
    if(!this.isEmpty(datetime)) {
        const store = useTymictStore()
        if(store.connectServer == 0) {
            try {
                const srcDateTime = stringToDate(datetime)
                const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                    // hour12: false,
                    hourCycle: "h23",
                    year:"numeric",
                    month:'2-digit',
                    day:'2-digit',
                    hour:'2-digit',
                    minute:'2-digit',
                    //timeZone: "America/Los_Angeles",
                })
                return mediumTime.format(srcDateTime)
            } catch(ex) {
                console.log('getDateTimeString', datetime, ex)
            }
        } else {
            try {
                let curTZ = this.getTimeZone()

                let srcDateTime = stringToDate(datetime)
                const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                    // hour12: false,
                    hourCycle: "h23",
                    year:"numeric",
                    month:'2-digit',
                    day:'2-digit',
                    hour:'2-digit',
                    minute:'2-digit',
                    timeZone: curTZ
                })
                srcDateTime = mediumTime.format(srcDateTime)
                return srcDateTime
            } catch(ex) {
                console.log('convertStringToDateTime', datetime, ex)
            }
        }
    }
    return ''
}

/// datetime : 문자열 형태(yyyy-mm-dd hh:MM)
function convertStringToDateTime2(datetime) {
    if(!this.isEmpty(datetime)) {
        try {
            const store = useTymictStore()
            let srcDateTime = null

            if(store.connectServer == 0) {
                srcDateTime = this.convDateTimeStringToLocalTime(datetime)
                const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                    // hour12: false,
                    hourCycle: "h23",
                    year:"numeric",
                    month:'2-digit',
                    day:'2-digit',
                    hour:'2-digit',
                    minute:'2-digit',
                })
                return mediumTime.format(srcDateTime)
            } else {
                let curTZ = this.getTimeZone()
                srcDateTime = moment.utc(datetime).tz(curTZ).toDate()

                const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                    hourCycle: "h23",
                    year:"numeric",
                    month:'2-digit',
                    day:'2-digit',
                    hour:'2-digit',
                    minute:'2-digit',
                    timeZone: curTZ
                })
                srcDateTime = mediumTime.format(srcDateTime)

                /*
                let dt1 = moment.utc(datetime).format()
                let dt2 = moment.utc(datetime).tz(curTZ).format()
                console.log('---> ', curTZ, datetime, dt1, dt2, srcDateTime)
                */

                return srcDateTime
            }
        } catch(ex) {
            console.log('convertStringToDateTime2', datetime, ex)
        }
    }
    return ''
}

// 타임존 적용없이 그냥 보내자
function convertStringToDateTime3(datetime) {
    const store = useTymictStore()

    let srcDateTime = new Date(datetime)
    const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
        // hour12: false,
        hourCycle: "h23",
        year:"numeric",
        month:'2-digit',
        day:'2-digit',
        hour:'2-digit',
        minute:'2-digit',
    })
    if(store.connectServer == 0) {
        srcDateTime = this.convDateTimeStringToLocalTime(datetime)
    } else {
        let curTZ = this.getTimeZone()
        let utcCutoff = moment.utc(String(datetime), 'YYYY-MM-DD HH:mm');
        srcDateTime = utcCutoff.clone().tz(curTZ);

        return new Intl.DateTimeFormat('en-us', {
            hourCycle: "h23",
            hour12: false,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: curTZ,
          }).format(srcDateTime)
    }

    return mediumTime.format(srcDateTime)
}

function convertOTimeToDateTime(datetime) {
    const store = useTymictStore()

    let srcDateTime = new Date(datetime)
    const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
        // hour12: false,
        hourCycle: "h23",
        year:"numeric",
        month:'2-digit',
        day:'2-digit',
        hour:'2-digit',
        minute:'2-digit',
    })
    /*
    if(store.connectServer == 0) {
        srcDateTime = this.convertStringToDate(String(datetime))
    } else {
        srcDateTime = this.convertStringToDate(String(datetime))
    }
    */
    console.log(datetime, srcDateTime)

    return mediumTime.format(srcDateTime)
}

/// datetime : 문자열 형태(yyyy-mm-dd hh:MM)
function convertStringToDate(datetime) {
    if(!this.isEmpty(datetime)) {
        try {
            const store = useTymictStore()
            let srcDateTime = new Date(datetime)
            const vmoment = moment(srcDateTime)

            if(store.timeDiff != 0) {
                srcDateTime = vmoment.add(store.timeDiff, 'minutes')
            }

            const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                // hour12: false,
                hourCycle: "h23",
                year:"numeric",
                month:'2-digit',
                day:'2-digit',
            })
            return mediumTime.format(srcDateTime)
        } catch(ex) {
            console.log('convertStringToDate', datetime, ex)
        }
    }
    return ''
}

// srcTime : 2023-10-26T14:50:44.232Z
//           2023-10-06 13:22
// return : 타임존 적용해 변환된 Date
function convDateTimeStringToLocalTime(srcTime) {
    if(!this.isEmpty(srcTime)) {
        if(srcTime == 'Invalid date') {
            return null
        }
        const store = useTymictStore()
        let srcOrgDate = null
        let tgtDateTime = null

        srcOrgDate = srcTime.replaceAll('-', ':')
        srcOrgDate = srcOrgDate.replaceAll('.', ':')
        srcOrgDate = srcOrgDate.replaceAll('T', ':')
        srcOrgDate = srcOrgDate.replaceAll('Z', '')
        srcOrgDate = srcOrgDate.replaceAll(' ', ':')

        let dtArray = srcOrgDate.split(':')
        if(store.connectServer == 0) {
            // Korea (GMT + 9) 그대로 쓰자...
            srcOrgDate = new Date(dtArray[0], dtArray[1] - 1, dtArray[2], this.isEmpty(dtArray[3]) ? 0 : dtArray[3], this.isEmpty(dtArray[4]) ? 0 : dtArray[4], this.isEmpty(dtArray[5]) ? 0 : dtArray[5], this.isEmpty(dtArray[6]) ? 0 : dtArray[6])
            /*
            const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                hour12: false,
                hourCycle: "h23",
                year:"numeric",
                month:'2-digit',
                day:'2-digit',
                hour:'2-digit',
                minute:'2-digit',
                timeZone: 'Asia/Seoul'
            })
            tgtDateTime = mediumTime.format(srcOrgDate)
            */
            tgtDateTime = srcOrgDate
            // console.log('1111 :', dtArray, srcOrgDate, tgtDateTime)
        } else {
            srcOrgDate = new Date(Date.UTC(dtArray[0], dtArray[1] - 1, dtArray[2], this.isEmpty(dtArray[3]) ? 0 : dtArray[3], this.isEmpty(dtArray[4]) ? 0 : dtArray[4], this.isEmpty(dtArray[5]) ? 0 : dtArray[5], this.isEmpty(dtArray[6]) ? 0 : dtArray[6]))

            let curTZ = this.getTimeZone()

            const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                hour12: false,
                hourCycle: "h23",
                year:"numeric",
                month:'2-digit',
                day:'2-digit',
                hour:'2-digit',
                minute:'2-digit',
                timeZone: curTZ
            })
            tgtDateTime = mediumTime.format(srcOrgDate)
            // console.log('convDateTimeStringToLocalTime 22222222 :', dtArray, curTZ, srcOrgDate, mediumTime, tgtDateTime)
        }
        return tgtDateTime
    }
    return null
}

// 날짜형을 ISO문자형으로 바꿈
function convDateTimeToString(date) {
    const store = useTymictStore()
    let result = ''
    if(store.connectServer == 0) {
        result = (new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getUTCMilliseconds()))).toISOString()
        // console.log('convDateTimeToString()', date, date.toISOString(), result)
    } else {
        let curTZ = this.getTimeZone()
        let options = {
            // year: 'numeric',
            month:'2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            // second: 'numeric',
            hour12: false,
            timeZone: curTZ
        };
        result = new Intl.DateTimeFormat(store.getLanguage(), options).format(date)
        console.log('out of KOREA', result)
    }
    return result
}

// 2023-09 형태의 생산월을 날짜 포맷에 맞게 한국어의 경우 2023. 09. 형태의 문자열로 리턴
function PD2DateString(pd) {
    let prodDate = ''
    if(!this.isEmpty(pd)) {
        const store = useTymictStore()

        let tempPD = pd + '-02'
        if(store.connectServer == 0) {
            //let newPD = new Date(pd)
            let options = {
                year: 'numeric',
                month: 'numeric',
                // day: 'numeric',
                // hour: 'numeric', minute: 'numeric', second: 'numeric',
                hour12: false,
                //timeZone: store.timezoneList[store.timezoneIndex].value,
                // timeZoneName: 'short'
            };
            try {
                prodDate = new Intl.DateTimeFormat(store.getLanguage(), options).format(new Date(tempPD))
            } catch(ex) {
                console.log('PD2DateString ERROR :', "[" + pd + "]", ex)
            }
        } else {
            let curTZ = this.getTimeZone()

            // console.log('PD2DateString NA:', store.timezoneIndex, store.timezoneList[store.timezoneIndex])
            let options = {
                year: 'numeric',
                month:'2-digit',
                // day: 'numeric',
                // hour: 'numeric', minute: 'numeric', second: 'numeric',
                hour12: false,
                timeZone: curTZ,
                // timeZoneName: 'short'
            };
            prodDate = new Intl.DateTimeFormat(store.getLanguage(), options).format(new Date(tempPD))
        }
    }
    return prodDate
}

// 2023-01-09T10:36 형태의 판매일을 날짜 포맷에 맞게 Date 타입으로 리턴
function SD2DateString(sd) {
    if(!this.isEmpty(sd)) {
        if(sd == 'Invalid date') {
            return null
        }
        const store = useTymictStore()
        if(store.connectServer == 0) {
            let options = {
                year: 'numeric', 
                month:'2-digit',
                day:'2-digit',
                // hour: 'numeric', minute: 'numeric', second: 'numeric',
                hour12: false,
                // timeZone: store.timezoneList[store.timezoneIndex].value,
                // timeZoneName: 'short'
            };
            return new Intl.DateTimeFormat(store.getLanguage(), options).format(new Date(sd))
        } else {
            let daytime = moment.tz(sd, 'UTC').toDate()
            /*
            let daytime = new Date(Date.UTC(sd.substring(0, 4), sd.substring(5, 7) - 1, sd.substring(8, 10),
                                            sd.substring(11, 13), sd.substring(14, 16), sd.substring(17, 19)))
            */
            // console.log(sd, daytime)
            let curTZ = this.getTimeZone()

            const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                hour12: false,
                // hourCycle: "h23",
                year:"numeric",
                month:'2-digit',
                day:'2-digit',
                // hour:'2-digit',
                // minute:'2-digit',
                timeZone: curTZ
            })
            daytime = mediumTime.format(daytime)

            // console.log(curTZ, sd, daytime)
            return daytime
        }
    }
    return null
}

function SD2DateString2(sd) {
    if(!this.isEmpty(sd)) {
        if(sd == 'Invalid date') {
            return null
        }
        const store = useTymictStore()
        if(store.connectServer == 0) {
            let options = {
                year: 'numeric', 
                month:'2-digit',
                day:'2-digit',
                // hour: 'numeric', minute: 'numeric', second: 'numeric',
                hour12: false,
                // timeZone: store.timezoneList[store.timezoneIndex].value,
                // timeZoneName: 'short'
            };
            return new Intl.DateTimeFormat(store.getLanguage(), options).format(new Date(sd))
        } else {
            let srcDateTime = new Date(sd)
            let daytime = new Date(srcDateTime.getUTCFullYear(), srcDateTime.getUTCMonth(), srcDateTime.getUTCDate(), srcDateTime.getUTCHours(), srcDateTime.getUTCMinutes(), srcDateTime.getUTCSeconds())

            /*
            let daytime = new Date(Date.UTC(sd.substring(0, 4), sd.substring(5, 7) - 1, sd.substring(8, 10),
                                            sd.substring(11, 13), sd.substring(14, 16), sd.substring(17, 19)))
            */

            let curTZ = this.getTimeZone()

            const mediumTime = new Intl.DateTimeFormat(store.getLanguage(), {
                hour12: false,
                // hourCycle: "h23",
                year:"numeric",
                month:'2-digit',
                day:'2-digit',
                // hour:'2-digit',
                // minute:'2-digit',
                timeZone: curTZ
            })
            daytime = mediumTime.format(daytime)

            return daytime
        }
    }
    return null
}

function hasArea(area) {
    return !!(area && area.name && area.name !== '');
}

function hasData(data) {
    return !!(data && data !== '');
}

function checkLastString (word, lastString) {
    return new RegExp(lastString + '$').test(word);
}

function hasAddition (addition) {
    return !!(addition && addition.value);
}

function makeAddress(item) {
    if (!item) {
        return;
    }

    console.log('makeAddress', item)
    var name = item.name,
        region = item.region,
        land = item.land,
        isRoadAddress = name === 'roadaddr';

    var sido = '', sigugun = '', dongmyun = '', ri = '', rest = '';

    if (hasArea(region.area1)) {
        sido = region.area1.name;
    }

    if (hasArea(region.area2)) {
        sigugun = region.area2.name;
    }

    if (hasArea(region.area3)) {
        dongmyun = region.area3.name;
    }

    if (hasArea(region.area4)) {
        ri = region.area4.name;
    }

    if (land) {
        if (hasData(land.number1)) {
            if (hasData(land.type) && land.type === '2') {
                rest += '산';
            }

            rest += land.number1;

            if (hasData(land.number2)) {
                rest += ('-' + land.number2);
            }
        }

        if (isRoadAddress === true) {
            if (checkLastString(dongmyun, '면')) {
                ri = land.name;
            } else {
                dongmyun = land.name;
                ri = '';
            }

            if (hasAddition(land.addition0)) {
                rest += ' ' + land.addition0.value;
            }
        }
    }

    return [sido, sigugun, dongmyun, ri, rest].join(' ');
}

function encode(data)
{
    var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
}

function parsePhoneNumber(src, area) {
    if(isEmpty(src))
        return ''

    let tmp = src.trim()
    let server = 0

    if(!this.isEmpty(area)) {
        server = area
    }
    // 20230904 전화번호 규칙을 몰라서 일단 -만 제거하고 표시하는 것으로 함
    tmp = tmp.replaceAll('-', '')

    if(server == 0) {
        tmp = tmp.replace('+821', '01')
    } else {
        tmp = tmp.replace('+1', '')
    }
    //console.log('parsePhoneNumber :', server, src, tmp)
    return tmp;
}

function splitPhoneNumber(src) {
    let result = {
        nation: '',
        phone: src,
    }
    if(isEmpty(src))
        return result

    const store = useTymictStore()
    let tmp = src.trim()

    // 20230904 전화번호 규칙을 몰라서 일단 -만 제거하고 표시하는 것으로 함
    tmp = tmp.replaceAll('-', '')
    if(src.indexOf('+82') >= 0) {
        result.nation = '+82'
        result.phone = tmp.replace('+820', '0')
        result.phone = result.phone.replace('+82', '0')
    } else  if(src.indexOf('+1') >= 0) {
        result.nation = '+1'
        result.phone = tmp.replace(result.nation, '')
    }

    if(TymConst.IS_DEVELOPMENT) {
        console.log('splitPhoneNumber :', store.connectServer, src, result)
    }
    return result;
}

function getAuthIndex(authString) {
    // Add TYMICT MS(99)
    if (authString === 'TYMICT')
        return 99;
    //
    const store = useTymictStore()

    if(store.getLanguage() == 'ko') {
        for (let i = 0; i < TymConst.authTypeKR.length; i++) {
            if (TymConst.authTypeKR[i] == authString)
                return i;
        }
    } else {
        for (let i = 0; i < TymConst.authTypeEN.length; i++) {
            if (TymConst.authTypeEN[i] == authString)
                return i;
        }
    }

    return -1;
}

function getAuthString(authIndex) {
    authIndex = Number(authIndex);

    // Add TYMICT MS(99)
    if (authIndex == 99)
        return 'TYMICT';
    //
    const store = useTymictStore()

    if(store.getLanguage() == 'ko') {
        return TymConst.authTypeKR[authIndex]
    } else {
        return TymConst.authTypeEN[authIndex]
    }
}

function getUserleveFromAuthIndex(authIndex) {
    authIndex = Number(authIndex);

    var uLevel = 99;
    switch(authIndex) {
        case 0:    // 관리자
        case 99:    // TYMICT
            uLevel = 0;
            break;
        case 2:     // 지점
            uLevel = 2;
            break;
        case 1:     // 대리점
            uLevel = 3;
            break;
        default:
            uLevel = 1;        
    }

    return uLevel;
}

function getModelFullname(MON) {
    let name = null
    if(!isEmpty(MON)) {
        let modelOption = MON.split('_')
        name = {
            Model : modelOption[0],
            Option : '',
            Fullname : modelOption[0]
        }
        if(!isEmpty(modelOption[1])) {
            name.Option = modelOption[1]
            if(modelOption[1] != 0 && modelOption[1] != '0' && modelOption[1].toUpperCase() != 'NONE') {
                name.Fullname += modelOption[1]
            }
        }
    }
    return name
}
/* 분을 입력받아서 분해해 리턴
    return : {
        Hour : 몇시간
        Minute : 몇분
        Desc : '시간:분' 
    }
*/
function manageMinutes(minutes) {
    let result = {
        Hour : 0,
        Minute : 0,
        Desc : '0',
        byLang : []
    }
    if(!isNaN(minutes) && (minutes > 0)) {
        result.Hour = Math.floor(minutes / 60)
        result.Minute = (minutes % 60).toFixed(0)
        result.Desc = result.Hour + ':' + result.Minute.toString().padStart(2, '0')
        result.byLang.push(result.Hour + '시간 ' + result.Minute.toString().padStart(2, '0') + '분')
        result.byLang.push(result.Hour + 'h ' + result.Minute.toString().padStart(2, '0') + 'm')
    }
    // console.log(result)
    return result
}

// 현재 접속한 서버/타임존 설정에 따라 타임존 옵셋을 구한다.
// 분단위 정수값.
function getTimezoneOffset() {
    const store = useTymictStore()
    let curTZ = this.getTimeZone()
    let offset = '+00:00'
    if(store.connectServer == 0) {
      //
    } else {
        let srcOffset = moment.tz('2024-01-01 00:00', curTZ).utcOffset()
        offset = srcOffset
    }

    return (offset)
}

function applyTimeZoneMoment(date) {
    const store = useTymictStore()
    let result = date.clone()

    if(store.connectServer != 0) {
        let curTZ = Intl.DateTimeFormat().resolvedOptions().timeZone     // 브라우저 타임존, 한국은 Asia/Seoul
        if(store.timezoneIndex != 0) {
            curTZ = store.timezoneList[store.timezoneIndex].value
        }
        result = result.tz(curTZ)

        console.log('applyTimeZoneMoment :', curTZ, date.format(), result.format())
    } else {
        // KOREA
    }

    return result
}

function localTimeToUTC(dayTime, formatStr) {
    const store = useTymictStore()
    let curTZ = 'Asia/Seoul'
    let result = null

    if(store.connectServer == 0) {
        result = moment.tz(dayTime, curTZ)
    } else {
        curTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
        if(store.timezoneIndex != 0) {
            curTZ = store.timezoneList[store.timezoneIndex].value
        }
        result = moment.tz(dayTime, curTZ).utc()
    }

    // result = isEmpty(formatStr) ? result.format('YYYY-MM-DD HH:mm:ss') : result.format(formatStr)

    return {
        dateTime : result,
        dateTimeStr : isEmpty(formatStr) ? result.format('YYYY-MM-DD HH:mm:ss') : result.format(formatStr)
    }
}

function makeDateTimeRange(minDateStr, startDay, endDay, formatStr) {
    const store = useTymictStore()
    let curTZ = this.getTimeZone()
    let minDate = null, sDate = null, eDate = null

    if(store.connectServer == 0) {
        minDate = moment.tz(minDateStr, curTZ)
        sDate = moment.tz(startDay, curTZ)
        eDate = moment.tz(endDay, curTZ)
    } else {
        minDate = moment.tz(minDateStr, curTZ).utc()
        sDate = moment.tz(startDay, curTZ).utc()
        eDate = moment.tz(endDay, curTZ).utc()
    }

    if(sDate < minDate)
        sDate = minDate
    if(minDate > eDate)
        return null

    return {
        sDate: sDate,
        eDate: eDate,
        sDateStr: isEmpty(formatStr) ? sDate.format('YYYY-MM-DD HH:mm:ss') : sDate.format(formatStr),
        eDateStr: isEmpty(formatStr) ? eDate.format('YYYY-MM-DD HH:mm:ss') : eDate.format(formatStr)
    }
}


function translateKeyword(keyword) {
    const store = useTymictStore()
    const reserved = [
        ['자동해결', 'Auto resolved'],
        ['사용자가 비활성화되었습니다.', 'User is disabled.']
    ]
    let bacKeyword = keyword.trim()
    let found = reserved.find(x => x[0] == bacKeyword)
    if(found) {
        let idx = 0
        switch (store.Language) {
        case "ko":
        case "kr":
        case "korean":
            idx = 0
            break
        case "en":
        case "en-us":
        case "en-uk":
        case "english":
            idx = 1
            break
        default:
            if(navigator.language.indexOf('ko') >= 0) {
                idx = 0
            } else {
                idx = 1
            }
            break
        }
        return found[idx]
    } else {
        found = reserved.find(x => x[1] == bacKeyword)
        if(found) {
            let idx = 0
            switch (store.Language) {
            case "ko":
            case "kr":
            case "korean":
                idx = 0
                break
            case "en":
            case "en-us":
            case "en-uk":
            case "english":
                idx = 1
                break
            default:
                if(navigator.language.indexOf('ko') >= 0) {
                    idx = 0
                } else {
                    idx = 1
                }
                break
            }
            return found[idx]
        }
    }

    return ''
}

function convertErrorMessage(msg) {
    const store = useTymictStore()
    const reserved = [
        {
            Msg: 'Task timed out after',
            Value: [
                '작업시간을 초과했습니다.',
                'Task timed out.'
            ]
        },
        {
            Msg: 'NOT FOUND SN: SN_INFO',
            Value: [
                '등록되지 않은 S/N입니다.',
                'Not registered S/N.'
            ]
        },
    ]
    let orgMsg = msg.trim()
    let found = reserved.find(x => x.Msg.indexOf(orgMsg) >= 0)
    if(found) {
        let idx = 0
        switch (store.Language) {
        case "ko":
        case "kr":
        case "korean":
            idx = 0
            break
        case "en":
        case "en-us":
        case "en-uk":
        case "english":
            idx = 1
            break
        default:
            if(navigator.language.indexOf('ko') >= 0) {
                idx = 0
            } else {
                idx = 1
            }
            break
        }
        return found.Value[idx]
    }

    return msg
}


function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // 지구 반지름 (단위: km)
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
}

function checkPassword(pw) {
    if(this.isEmpty(pw))
        return false
    else if(pw.length < 8)
        return false
    else {
        let reg = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
        return (pw.match(reg) != null)
    }
}
export default {
    Toast,
    MsgBox,
    MsgBoxOK,
    isEmpty,
    getUserUuidSingle,
    makeUuidArray,
    getLevelFromUuid,
    getTimeZone,
    getSTime,
    getETime,
    stringToDate,
    getDateString,
    getMonthString,
    getDateTimeString,
    stringToDateTime,
    convertStringToDateTime,
    convertStringToDateTime2,
    convertStringToDateTime3,
    convertOTimeToDateTime,
    convertStringToDate,
    convDateTimeStringToLocalTime,
    convDateTimeToString,
    PD2DateString,
    SD2DateString,
    SD2DateString2,
    makeAddress,
    encode,
    parsePhoneNumber,
    splitPhoneNumber,
    getAuthIndex,
    getAuthString,
    getUserleveFromAuthIndex,
    getModelFullname,
    manageMinutes,
    getTimezoneOffset,
    applyTimeZoneMoment,
    makeDateTimeRange,
    localTimeToUTC,
    translateKeyword,
    convertErrorMessage,
    deg2rad,
    getDistance,
    checkPassword
}
