<template style="position: relative;">
    <LoginView v-if="option == 0" v-on:loginok='loginResult'></LoginView>
    <HomeView ref="homeView" v-if="option == 1" v-on:logout='logoutProcess'/>

  <q-dialog v-model="isLoading" persistent class="column items-center justify-center" style="width:100%;height:100%;background-color:white;">
    <div class="col-auto waiting" style="">
      <q-img src="/assets/company/title_logo.png" fit="cover"></q-img>
    </div>
  </q-dialog>
</template>

<style lang="scss">
@import url("./fonts/tymictmw2fonts.css");

body, #app {
  font-family: "Noto Sans KR", "Prometo", sans-serif;
}

.waiting {
  animation: slidein 4s infinite;
}

</style>

<script>
import { ref } from 'vue'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import { useTymictStore } from '@/store/tymict'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'
import CryptoJS from "crypto-js"
import TymAws from '@/js/tymaws.js'
import AWS from 'aws-sdk'

export default ({
  components : {
    HomeView,
    LoginView,
  },
  watch : {
    /*
    'showLogin' : {
      handler() {
        const store = useTymictStore()

        console.log('App.watch.showLogin', store.UserInfo)

        if(store.UserInfo)
          this.option = 1
        else
          this.option = 0
      },
      immediate : false
    }
    */
  },
  computed : {
    /*
    showLogin() {
      console.log('App.computed.showLogin')
      return 0
    },
    */
  },
  setup() {
    return {
      isLoading : ref(false),
      option : ref(-1),     // 0인 경우 로그인화면으로, 1이면 홈으로
      inputID : ref(''),
      inputPW : ref(''),
    }
  },
  created() {
    console.log("AppView created")
    const store = useTymictStore()
    store.initialize()

    window.onpageshow = function(event) {
      if ( event.persisted ) {
        // console.log('onpageshow 1: ', event)
      } else {
        // console.log('onpageshow  2: ', event)
      }
    }
  },
  mounted() {
    console.log("AppView mounted")
    this.doStartUp()
  }, 
  unmounted() {
    console.log("AppView unmounted")
  },
  methods : {
    unusedParam() {
      //
    },
    decrypt(data, key) {
      return CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8)
    },
    loginResult() {
      const store = useTymictStore()

      if(store.docClient == null) {
        store.docClient = new AWS.DynamoDB.DocumentClient({'region': store.cognito[store.connectServer].region})
      }

      if(store.docLambda == null) {
        store.docLambda = new AWS.Lambda( {region : store.lambda[store.connectServer].region, apiVersion : store.lambda[store.connectServer].apiVersion})
      }

      this.saveLookup()
    },
    saveLookup() {
      let self = this
      TymAws.lookup(TymConst.ACTION_LOG_IN, '', 'MW2')
        .then(data => {
          console.log(TymConst.ACTION_LOG_IN, ' ==> ', data)
          self.unusedParam(data)
          self.doNextStep()
        })
        .catch(er => {
          console.log(TymConst.ACTION_LOG_IN, er.code, 'ERJROR =>', er.message, er)
          switch(er.code) {
            case 'CredentialsError':
              setTimeout(() => {
                self.saveLookup()
              }, 50)
              break;
          }
        })
    },
    doNextStep() {
      this.isLoading = false
      this.option = 1

      sessionStorage.removeItem('DB.saved')
      sessionStorage.removeItem('MODEL.saved')
      sessionStorage.removeItem('MAN.saved')
      sessionStorage.removeItem('SALE.saved')
      sessionStorage.removeItem('STAT.saved')
      sessionStorage.removeItem('STAT.sale.saved')
      sessionStorage.removeItem('STAT.usage.saved')
      sessionStorage.removeItem('STAT.em.saved')
      sessionStorage.removeItem('STAT.br.saved')
      sessionStorage.removeItem('STAT.cons.saved')
      sessionStorage.removeItem('STAT.batt.saved')
      sessionStorage.removeItem('NOTI.saved')
      sessionStorage.removeItem('GRP.saved')
      sessionStorage.removeItem('PROD.saved')
      sessionStorage.removeItem('CUST.saved')
    },
    logoutProcess() {
      const store = useTymictStore()
      
      this.option = 0;

      store.idToken = []

      store.AuthUser = null
      store.CurrentUser = null
      store.AccessToken = null
      store.groupName = ''

      store.docClient = null
      store.docLambda = null
      store.mqttClient = null

      store.groupInfo = []
      store.groupTree = []

      store.loadStateMachine = false
      store.machineLocaInfo = []

      // store.manageCount = 0
      store.manageInfo = []

      store.EmergencyData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      store.BrokenData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      store.userList = {
        count : 0,
        lastKey : null,
        data : [],
      }

      store.managerList = []
      store.dbUserList = {
        count : 0,
        data : [],
      }
      store.usersInMyGroup = []

      store.SalesData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      store.noticeCount = 0
      store.noticeInfo = []

      store.ConsumablesData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      store.BatteryAlertData = {
        count : 0,
        lastKey : null,
        data : [],
      }

      store.ModelData = {
        count : 0,
        data : [],
      }
      store.ModelList = {
        count : 0,
        data : [],
      }

      store.timezoneList = []
      store.selectManageInfo = null
      store.tymictGroup = null
    },
    async doStartUp() {
      console.log('doStartUp(START)')
      
      const store = useTymictStore()
      try {
        const startPage = localStorage.getItem('startup')
        const language = localStorage.getItem('language')
        const timezone = localStorage.getItem('timezone')

        store.startPage = TymCommon.isEmpty(startPage) ? 0 : startPage
        if(TymCommon.isEmpty(language)) {
          if(navigator.language.indexOf('ko') >= 0) { 
            store.Language = 'ko'
          } else {
            store.Language = 'en'
          }
        }
        store.Language = language
        store.timezoneIndex = TymCommon.isEmpty(timezone) ? 0 : timezone

        if(TymConst.IS_DEVELOMENT) {
          console.log('app.doStartUp store :', store)
          console.log('app.doStartUp performance.navigation.type :', performance.navigation.type)
          console.log('app.doStartUp startPage :', startPage)
          console.log('app.doStartUp language :', language)
          console.log('app.doStartUp timezone :', timezone)
        }
        
        store.reloadPage = {
          type : '',
          page : ''
        }

        if(TymConst.IS_DEVELOPMENT >= 0) {
          /*
          페이지가 어떻게 시작되었나?
          0 -> TYPE_NAVIGATE
          1 -> TYPE_RELOAD
          2 -> TYPE_BACK_FORWARD
          */
          switch(performance.navigation.type) {
            case 0:
              store.reloadPage.type = 'NAVIGATE'
              break;
            case 1:
              store.reloadPage.type = 'RELOAD'
              {
                let lastSlash = window.location.href.lastIndexOf('/')
                if(lastSlash > 0) {
                  let tgtPage = window.location.href.substring(lastSlash + 1)
                  if(!TymCommon.isEmpty(tgtPage) && tgtPage.length > 1)
                    store.reloadPage.page = tgtPage
                }
              }
              break;
            case 2:
              store.reloadPage.type = 'BACK_FORWARD'
              break;
            default:
              store.reloadPage.type = 'UNKNOWN'
              break;
          }
        }

        let isRefresh = false
        if((store.reloadPage.type == 'RELOAD') && !TymCommon.isEmpty(store.reloadPage.page)) {
          let sesStorage = sessionStorage.getItem('EnterInfo')
          if(!TymCommon.isEmpty(sesStorage)) {
            if(TymConst.IS_DEVELOPMENT) {
              console.log('==> ', sesStorage)
            }
            let decKey = this.decrypt(sesStorage, TymConst.TYMICT_ENCKEY)
            if(!TymCommon.isEmpty(decKey)) {
              decKey = JSON.parse(decKey)
              let saved = new Date(decKey.INF0)
              let now = new Date()
              if((now - saved) <= (23*60*1000)) {
                // 23시간보다 크면 새로 하자
                // 재로드(F5)
                isRefresh = true
                store.reloadPage.INF1 = decKey.INF1
                store.reloadPage.INF2 = decKey.INF2

                this.inputID = decKey.INF1
                this.inputPW = decKey.INF2

                if(TymConst.IS_DEVELOPMENT) {
                  console.log('===> :', store.reloadPage)
                  console.log('===> :', decKey, now - saved)
                }

                this.isLoading = false
                this.option = 0
                // this.loginProcess()
              }
            }
          }
        }
        if(!isRefresh) {
          this.isLoading = false
          this.option = 0
        }
      } catch(ex) {
        console.log(ex)
      }
    },
  }
})
</script>
