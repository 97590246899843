<template>
  <div class="column justify-end">
    <div class="row q-gutter-md items-center">
      <div class="col row items-center" v-if="kind=='login'">
        <div class="col" style="margin:0 12px 0 0;">{{ $t("common.server") }}</div>
        <q-select outlined dense options-dense color="red-7" style="min-width:120px;" :options="serverList" v-model="serverLocation" @update:model-value="changedServer"/>
      </div>
      <q-icon v-show="this.$i18n.locale != 'ko'" class="text-dark" size="lg" @click="changeToKorean">
        <img src="~../res/login_language_e.svg" />
      </q-icon>
      <q-icon v-show="this.$i18n.locale != 'en'" size="lg" @click="changeToUnitedStates">
        <img src="~../res/login_language_k.svg" />
      </q-icon>
    </div>
    <q-btn dense no-caps class="col-auto" v-show="isDev" size="md" label="Clear Cache" style="margin:12px 0;" @click="clearCache">
    </q-btn>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useTymictStore } from '../store/tymict'
import { useQuasar } from 'quasar'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'
import { useI18n } from 'vue-i18n'

export default ({
  props: {
    kind : String
  },
  computed : {
    isDev() {
      return (TymConst.IS_DEVELOPMENT)
    },
    serverList() {
      const i18n = useI18n()
      return [i18n.t('regions.kr'), i18n.t('regions.na')]
    }
  },
  data() {},
  setup() {
    const $q = useQuasar()

    const toKorean = () => {
      import("quasar/lang/ko-KR").then((lang) => {
        $q.lang.set(lang.default)
      })
    }
    const toEnglish = () => {
      import("quasar/lang/en-US").then((lang) => {
        $q.lang.set(lang.default)
      })
    }
    return {
      serverLocation : ref(''),
      toKorean,
      toEnglish
    }
  },
  created() {
  },
  mounted() {
    const store = useTymictStore()
    let langOpt = 0, tgtLang = 0

    switch(store.getLanguage().toLocaleLowerCase()) {
      case 'ko':
      case 'kr':
      case 'korean':
        langOpt = 1
        tgtLang = 0
        break
      case 'en':
      case 'en-us':
      case 'en-uk':
      case 'english':
        langOpt = 2
        tgtLang = 1
        break;
    }

    if(langOpt == 0) {
      if(navigator.language.indexOf('ko') >= 0) {
        tgtLang = 0
      } else {
        tgtLang = 1
      }
    }

    if(tgtLang == 0)
      this.changeToKorean(false)
    else
      this.changeToUnitedStates(false)

    // 접속할 서버 정보
    let server = window.localStorage.getItem('storedRegion');
    if(!TymCommon.isEmpty(server)) {
      if(server == 0 || server == 1) {
        store.connectServer = parseInt(server)
      } else {
        if(navigator.language.indexOf('ko') >= 0) {
          store.connectServer = 0
        } else {
          store.connectServer = 1
        }
      }
    }
    this.serverLocation = this.serverList[store.connectServer]
  }, 
  unmounted() {},
  methods : {
    changeToKorean(showToast) {
      this.$i18n.locale = 'ko'
      const store = useTymictStore()
      store.Language = 'ko'
      this.toKorean()
      if(showToast)
        TymCommon.Toast('한국어로 변경합니다.')
      setTimeout(() => {
        this.serverLocation = this.serverList[store.connectServer]
      }, 5)
    },
    changeToUnitedStates(showToast){
      this.$i18n.locale = 'en'
      const store = useTymictStore()
      store.Language = 'en'
      this.toEnglish()
      if(showToast)
        TymCommon.Toast('Language has been changed to English.')
      setTimeout(() => {
        this.serverLocation = this.serverList[store.connectServer]
      }, 5)
    },

    changedServer() {
      switch(this.serverLocation) {
        case this.serverList[0]:
          // 대한민국
          window.localStorage.setItem('storedRegion', 0);
          // this.changeToKorean(true)
          break;
        case this.serverList[1]:
          // North America
          window.localStorage.setItem('storedRegion', 1);
          break;
      }
      this.$router.go(this.$router.currentRoute)
    },

    clearCache() {
      localStorage.setItem('startup', null)
      localStorage.setItem('language', null)
      localStorage.setItem('timezone', null)
    }
  }
})
</script>

