red;<template>
  <div class="col row" :class='isLandscape ? "viewBorder_ls" : "viewBorder_pt"' style="overflow-y:none;">
    <div style="width:100%;height:100%;position: relative;" v-show="!isLandscape">
      <!-- 세로 모드용 -->
      <q-scroll-area style="position: absolute; left:0; top:0; right:0; bottom:0;background-color:white;border-radius:3px;">
        <div class="column no-wrap" :class='isLandscape ? "viewContainer_ls" : "viewContainer_pt"' style="border-radius:3px;height:100%;">
          <!--
          <div class="col-auto row justify-end " v-show="isLandscape" style="height:34px;margin:0 7px 0 0;">
            <q-btn class="machineTitle" clickable rounded dense flat icon="more_horiz" size="18px" @click="viewDetail">
              <q-tooltip style="font-size: 14px">More!!!</q-tooltip>
            </q-btn>
          </div>
          <img src="../assets/dashboard/dashboard_dealer_t.png" fit="contain" width="348" height="182" style="float:left"/>

          -->
          <div class="col-auto column" style="height:182px;" :style='isLandscape ? "margin:12px 0 0 0;" : "margin:0"'>
            <div class="col row no-wrap items-start justify-start" style="position:relative;background-color:#060203;border-radius:3px;height:100%;;width:100%;" >
              <div class="tractor_bg" style="position: absolute;left:0;;top:0;width:348px;height:182px;z-index:1;"></div>
              <div class="col row justify-end items-center" style="position: absolute;left:0;top:0;width:100%;height:182px;;z-index:2;">
                <!--
                <q-btn class="col-auto" v-show="!isLandscape" flat rounded dense style="margin:0;" size="18px" icon="more_horiz" @click="viewDetail">
                  <q-tooltip style="font-size: 14px">More</q-tooltip>
                </q-btn>
                <div class="col-auto" style="height:16px" v-show="isLandscape">&nbsp;</div>
                -->
                <div class="col-auto column justify-between" style="height:50%;margin:0 12px 0 0">
                  <div class="col-auto row items-end justify-end" style="width:100%;" :style='isLandscape ? "padding:0 4px 0 0" : "padding:0 9px 0 0"'>
                    <div style="font-size:20px; font-weight:bold;color: white">{{organName}}</div>
                  </div>
                  <div class="col-auto row items-end justify-end"
                        v-show="!isLoading"
                        style="width:100%;" :style='isLandscape ? "padding:0 4px 0 0" : "padding:0 9px 0 0"'>
                    <div style="font-size:24px; font-weight:normal;color: white">{{machineCount}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <q-separator style="height:1px;color:#ededed;margin:12px 0;"></q-separator>

          <div class="col column justify-between no-wrap">
            <q-item clickable flat dense class="col column" style="min-height:68px;" @click="itemClicked(0)">
              <div class="col row items-center justify-between">
                <div class="col-9 row no-wrap items-center justify-start">
                  <q-img class="col-auto" src="../assets/dashboard/dashboard_rollover_icon.svg" fit="cover" width="50px" height="50px" style="margin-right:10px;"/>
                  <div class="text-left " style="font-size:16px;">{{ $t('monitor_menu.emergency') }}</div>
                </div>
                <div class="col text-right"
                      v-show="!isLoading"
                      style="color:#eb0028;font-size:24px">
                  {{emergencyCount}}
                </div>
              </div>
            </q-item>
            <q-separator style="height:1px;color:#ededed;margin:12px 0;"></q-separator>

            <q-item clickable flat dense class="col column" style="min-height:68px;" @click="itemClicked(1)">
              <div class="col row items-center justify-between">
                <div class="col-9 row no-wrap items-center justify-start">
                  <q-img class="col-auto" src="../assets/dashboard/dashboard_error_icon.svg" fit="cover" width="50px" height="50px" style="margin-right:10px;"/>
                  <div class="text-left" style="font-size:16px;font-weight:400;">{{ $t('monitor_menu.broken') }}</div>
                </div>
                <div class="col text-right"
                      v-show="!isLoading"
                      style="color:#c97d31;font-size:24px">
                  {{brokenCount}}
                </div>
              </div>
            </q-item>
            <q-separator style="height:1px;color:#ededed;margin:12px 0;"></q-separator>

            <q-item clickable flat dense class="col column" style="min-height:68px;" @click="itemClicked(2)">
              <div class="col row items-center justify-between">
                <div class="col-9 row no-wrap items-center justify-start">
                  <q-img class="col-auto" src="../assets/dashboard/dashboard_consumables_icon.svg" fit="cover" width="50px" height="50px" style="margin-right:10px;"/>
                  <div class="text-left" style="font-size:16px;font-weight:400;">{{ $t('monitor_menu.consumablesRepl') }}</div>
                </div>
                <div class="col text-right"
                      v-show="!isLoading"
                      style="color:#ffc600;font-size:24px">
                  {{consumablesCount}}
                </div>
              </div>
            </q-item>
            <q-separator style="height:1px;color:#ededed;margin:12px 0;"></q-separator>
            <q-item clickable flat dense class="col column" style="min-height:68px;" @click="itemClicked(3)">
              <div class="col row items-center justify-between">
                <div class="col-9 row no-wrap items-center justify-start">
                  <q-img class="col-auto" src="../assets/dashboard/dashboard_battery_icon.svg" fit="cover" width="50px" height="50px" style="margin-right:10px;"/>
                  <div class="text-left" style="font-size:16px;font-weight:400;">{{ $t('monitor_menu.battery') }}</div>
                </div>
                <div class="col text-right"
                      v-show="!isLoading"
                      style="color:#bbbbbb;font-size:24px">
                  {{batteryWarnCount}}
                </div>
              </div>
            </q-item>
          </div>
        </div>
      </q-scroll-area>
    </div>

    <div class="col column no-wrap viewContainer_ls" v-show="isLandscape" style="overflow-y:auto;">
      <!-- 가로 모드용 -->
        <div class="col-auto column" style="height:182px;" :style='isLandscape ? "margin:12px 0 0 0;" : "margin:0"'>
          <div class="col row no-wrap items-start justify-start" style="position:relative;background-color:#060203;border-radius:3px;height:100%;;width:100%;" >
            <div class="tractor_bg" style="position: absolute;left:0;;top:0;right:0;height:182px;z-index:1;"></div>
            <div class="col row justify-end items-center" style="position: absolute;left:0;top:0;width:100%;height:182px;;z-index:2;">
              <!--
              <q-btn class="col-auto" v-show="!isLandscape" flat rounded dense style="margin:0;" size="18px" icon="more_horiz" @click="viewDetail">
                <q-tooltip style="font-size: 14px">More</q-tooltip>
              </q-btn>
              <div class="col-auto" style="height:16px" v-show="isLandscape">&nbsp;</div>
              -->
              <div class="col-auto column justify-between" style="height:50%;margin:0 12px 0 0">
                <div class="col-auto row items-end justify-end" style="width:100%;" :style='isLandscape ? "padding:0 4px 0 0" : "padding:0 9px 0 0"'>
                  <div style="font-size:20px; font-weight:bold;color: white">{{organName}}</div>
                </div>
                <div class="col-auto row items-end justify-end"
                      v-show="!isLoading"
                      style="width:100%;" :style='isLandscape ? "padding:0 4px 0 0" : "padding:0 9px 0 0"'>
                  <div style="font-size:24px; font-weight:normal;color: white">{{machineCount}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <q-separator style="height:1px;color:#ededed;margin:12px 0;"></q-separator>

        <div class="col column justify-between no-wrap">
          <q-item clickable flat dense class="col column" style="min-height:68px;" @click="itemClicked(0)">
            <div class="col row items-center justify-between">
              <div class="col-9 row no-wrap items-center justify-start">
                <q-img class="col-auto" src="../assets/dashboard/dashboard_rollover_icon.svg" fit="cover" width="50px" height="50px" style="margin-right:10px;"/>
                <div class="text-left " style="font-size:16px;">{{ $t('monitor_menu.emergency') }}</div>
              </div>
              <div class="col text-right"
                    v-show="!isLoading"
                    style="color:#eb0028;font-size:24px">
                {{emergencyCount}}
              </div>
            </div>
          </q-item>
          <q-separator style="height:1px;color:#ededed;margin:12px 0;"></q-separator>

          <q-item clickable flat dense class="col column" style="min-height:68px;" @click="itemClicked(1)">
            <div class="col row items-center justify-between">
              <div class="col-9 row no-wrap items-center justify-start">
                <q-img class="col-auto" src="../assets/dashboard/dashboard_error_icon.svg" fit="cover" width="50px" height="50px" style="margin-right:10px;"/>
                <div class="text-left" style="font-size:16px;font-weight:400;">{{ $t('monitor_menu.broken') }}</div>
              </div>
              <div class="col text-right"
                    v-show="!isLoading"
                    style="color:#c97d31;font-size:24px">
                {{brokenCount}}
              </div>
            </div>
          </q-item>
          <q-separator style="height:1px;color:#ededed;margin:12px 0;"></q-separator>

          <q-item clickable flat dense class="col column" style="min-height:68px;" @click="itemClicked(2)">
            <div class="col row items-center justify-between">
              <div class="col-9 row no-wrap items-center justify-start">
                <q-img class="col-auto" src="../assets/dashboard/dashboard_consumables_icon.svg" fit="cover" width="50px" height="50px" style="margin-right:10px;"/>
                <div class="text-left" style="font-size:16px;font-weight:400;">{{ $t('monitor_menu.consumablesRepl') }}</div>
              </div>
              <div class="col text-right"
                    v-show="!isLoading"
                    style="color:#ffc600;font-size:24px">
                {{consumablesCount}}
              </div>
            </div>
          </q-item>
          <q-separator style="height:1px;color:#ededed;margin:12px 0;"></q-separator>
          <q-item clickable flat dense class="col column" style="min-height:68px;" @click="itemClicked(3)">
            <div class="col row items-center justify-between">
              <div class="col-9 row no-wrap items-center justify-start">
                <q-img class="col-auto" src="../assets/dashboard/dashboard_battery_icon.svg" fit="cover" width="50px" height="50px" style="margin-right:10px;"/>
                <div class="text-left" style="font-size:16px;font-weight:400;">{{ $t('monitor_menu.battery') }}</div>
              </div>
              <div class="col text-right"
                    v-show="!isLoading"
                    style="color:#bbbbbb;font-size:24px">
                {{batteryWarnCount}}
              </div>
            </div>
          </q-item>
        </div>
    </div>
  </div>
</template>

<style scoped>
.viewContainer_ls {
  overflow-y:none;
  padding: 0 12px 12px 12px;
  background-color: white;
  height:100%;
}
.viewContainer_pt {
  overflow-y:none;
  padding: 12px 12px 12px 12px;
  background-color: white;
}

.viewBorder_ls {
  margin: 0 0px 0px 0px;
  height:100%;
  overflow-y : none;
}
.viewBorder_pt {
  padding: 0;
  height:100%;
  overflow-y : none;
}

.tractor_bg {
  background : url("../assets/dashboard/dashboard_dealer_t.png") left no-repeat;
  background-size:none;
}

.machineTitle {
  font-size:20px;
  font-weight: bold;
  color: white;
  /*
  color:#212529;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  -moz-text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  -webkit-text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  */
}
</style>

<script>
import { useTymictStore } from '../store/tymict'
import TymConst from '@/js/tymconstants.js'

export default ({
  data() {},
  setup() {
  },
  watch : {
  },
  computed: {
    isLoading : function() {
      const store = useTymictStore()
      return store.loadStateMachine
    },
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    organName : function() {
      const store = useTymictStore()
      return store.groupName
    },
    machineCount : function() {
      const store = useTymictStore()
      return (store.EmergencyData.data.length + store.BrokenData.data.length + store.ConsumablesData.data.length + store.BatteryAlertData.data.length)
      // return store.SalesData.count
    },
    emergencyCount() {
      const store = useTymictStore()
      return store.EmergencyData.data.length
    },
    brokenCount() {
      const store = useTymictStore()
      return store.BrokenData.data.length
    },
    consumablesCount() {
      const store = useTymictStore()
      return store.ConsumablesData.data.length
    },
    batteryWarnCount() {
      const store = useTymictStore()
      return store.BatteryAlertData.data.length
    },
  },
  created() {},
  mounted() {}, 
  unmounted() {},
  methods : {
    unusedParam() {
      //
    },
    viewDetail() {
      const store = useTymictStore()
      store.setPage(TymConst.PAGE_MANAGE)
    },
    itemClicked(idx) {
      this.unusedParam(idx)
      const store = useTymictStore()
      store.setPage(TymConst.PAGE_MANAGE)
    }
  }
})
</script>
