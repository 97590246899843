1<template>
  <!-- 모델관리 - 처음시작할 때에는 Equipment였음... -->
  <div class="column" style="margin-bottom: 12px;">
    <div class="col column" style="margin:0 12px 12px 12px;">
      <div class="col-auto row items-center" style="height:45px">
        <div class="col row items-center">
          <!-- 상단 제목 및 메뉴 -->
          <div class="col-auto"
              :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
            {{ getPageTitle }}
          </div>
        </div>
      </div>
      <!-- 중간 모델목록 /세부모델 -->
      <q-splitter v-model="horizSplitterValue" :limits="[30, 80]" 
                  horizontal
                  separator-style="height:12px"
                  class="col" style="position: relative;"
                  separator-class="transparent">
        <template v-slot:before>
          <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
          <q-splitter v-model="vertSplitterValue" :limits="[35, 70]" 
                      :separator-style='!isLandscape ? "height:12px" : "width:12px"'
                      :horizontal="!isLandscape"
                      separator-class="transparent">
            <template v-slot:before>
              <!-- 왼쪽 표 관련 부분 -->
              <div :class='isLandscape ? "col column internalContainer" : "col column internalContainer_pt"'>
                <div class="justify-between" :class='isLandscape ? "col-auto row items-center" : "col-auto column items-start"' style="margin:12px;">
                  <!-- 좌상단 콤보 & 버튼들-->
                  <div class="row" :class='isLandscape ? "col-auto" : "col justify-center"' style="">
                    <div class="col row">
                      <q-select dense options-dense outlined v-model="catSelected" :options="getCategory" :disable="isAddModel || isEditConsumable"
                                class="col"
                                color="red-7"
                                @update:model-value="changedCategory"
                                style="font-size:14px;margin-right:12px;max-width:200px;"></q-select>
                      <q-input class="col-auto" dense outlined clearable flat autofocus :disable="isAddModel || isEditConsumable"
                              :placeholder="$t('placeholder.modeloroption')"
                              style="font-size:14px;width:180px;" 
                              color="red-7" v-model="tableFilter">
                        <template v-slot:append>
                          <q-icon dense flat name="search"/>
                        </template>
                      </q-input>
                    </div>
                  </div>

                  <div class="row justify-end items-center" :class='isLandscape ? "col-auto no-wrap" : "col"' v-show="canDownloadToExcel">
                    <div class="col row" style="">
                      <q-btn flat dense @click="downloadToExcel" :disable="isEditConsumable || !canDownloadToExcel" :style="canDownloadToExcel ? 'width:auto;height:auto;' : 'width:1px;height:1px'">
                        <div v-show="canDownloadToExcel">
                          <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;">
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                        </div>
                        <q-popup-proxy ref="excelPopup" style="width:360px;height:220px;background-color: #01010130;border-radius: 3px;position: relative;"
                                        @before-show="onShowExportToExcel">
                          <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                            <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                              <div class="col-auto row items-center justify-center">
                                <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                                  {{ $t('common.toExcel') }}
                                </div>
                              </div>
                              <div class="col row items-center justify-start" style="width:100%;margin:0;">
                                <table class="col" style="width:100%;margin:0;">
                                  <tr style="">
                                    <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.filename') }}</td>
                                    <td width="12px" style=""></td>
                                    <td style="">{{ excelFilename }}</td>
                                  </tr>
                                  <tr>
                                    <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.machineType') }}</td>
                                    <td width="12px"></td>
                                    <td>{{ excelType }}</td>
                                  </tr>
                                  <tr>
                                    <td width=25% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.keyword') }}</td>
                                    <td width="12px"></td>
                                    <td>{{ excelKeyword }}</td>
                                  </tr>
                                </table>
                              </div>
                              <xlsx-workbook class="col-auto row items-center justify-center">
                                <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                                <xlsx-download :filename="excelFilename">
                                  <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveToExcelFile">{{ $t('common.download') }}</button>
                                </xlsx-download>
                              </xlsx-workbook>
                            </div>
                          </div>
                          <div class="row items-center justify-center" v-show="makingExcel"
                              style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                            <q-spinner color="red-7" size="70px" thickness="1.5"/>
                          </div>
                        </q-popup-proxy>
                      </q-btn>

                      <q-btn flat dense color='grey-9' size="16px" icon="add" @click="addModel" :disable="isAddModel || isEditConsumable" v-show="canAddEdit()">
                        <q-tooltip style="font-size:14px;" ref="addModelTooltip">{{ $t('tooltip.addModel') }}</q-tooltip>
                      </q-btn>
                      <!-- MW1에선 수정 기능 없네? 
                      <q-btn flat dense color='grey-9' size="16px" icon="edit" @click="editModel">
                        <q-tooltip style="font-size:14px;">선택한 모델의 정보를 수정합니다.</q-tooltip>
                      </q-btn>
                      -->
                      <q-btn flat dense color='grey-9' size="16px" icon="delete" @click="deleteModel" :disable="(selectedRowIndex < 0) || isEditConsumable" v-show="canAddEdit()">
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.deleteModel') }}</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                </div>

                <div class="col row" style="margin:0 12px 12px 12px;height:100%;position:relative;">
                  <div style="background-color:white;position: absolute;left:0;top:0;width:100%;height:100%;">
                    <q-table class="col"
                            ref="modelTable"
                            dense flat bordered
                            separator="cell"
                            hide-no-data
                            style="height:auto;max-height:100%;"
                            :data="tableData"
                            :filter="tableFilter || catSelected"
                            :filter-method="tableFilterMethod"
                            :rows="updatedModel"
                            :columns="columns"
                            :visible-columns="visibleColumns"
                            v-model:selected="rowModelSelected"
                            row-key="index"
                            :pagination="curPagination"
                            @row-click="tableRowClicked"
                            @update:pagination="updatePagination"
                            :rows-per-page-options="[itemsPerPage]">
                      <template v-slot:header="props">
                        <q-th no-caps
                            style="height:auto"
                            class="tableHeader"
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props">
                          {{ col.label }}
                        </q-th>
                      </template>
                      <template v-slot:bottom>
                        <div class="col row items-end justify-end">
                          <div class="" style="font-size:15px;" v-show="listupItemCount >= 0">{{ listupItemCount }}</div>
                        </div>
                      </template>
                    </q-table>
                    <div style="z-index:3;position:absolute;left:0;top:0;width:100%;height:100%;background-color:rgb(224, 224, 224);opacity:0.2;" v-show="isAddModel || isEditConsumable">
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:separator>
              <div :class='isLandscape ? "vertSeparator" : "horizSeparator"'></div>
            </template>

            <template v-slot:after >
              <div class="col row internalContainer">
                <div class="col row" style="margin:12px" v-show="(rowModelSelected.length > 0) || isAddModel">
                  <div class="col row" v-show="!isAddModel">
                    <div class="col column">
                      <!-- 오른쪽 - 모델 보기 화면-->
                      <div class="col-auto row" style="min-height:220px;padding:0 0 12px 0">
                        <div class="col-auto row" v-show="hasLogoImage || isEditModelInfo" style="position:relative;">
                          <div class="col-auto row">
                            <!-- 모델 이미지 -->
                            <div class="col-auto row items-center justify-center"
                                  style="border-radius:3px;padding:0 12px;width:250px;height:184px;">
                              <img id="viewModelImage" class="col"/>
                              <q-img style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;background-color: white;border-radius:3px;" spinner-color="white"
                                    :src="logoImgUrl" fit="contain" position="50% 0"
                                    v-show="isEditModelInfo && (modelImageFilename.length > 0)">
                              </q-img>
                            </div>
                          </div>
                          <div class="col column items-center justify-center imgSelector"
                                v-show="isEditModelInfo"
                                @click="modelImageClicked('EDIT')"
                                style="position:absolute;z-index:3;left:0;top:0;width:100%;height:100%;padding:12px;background-color:#ffffff80;">
                            <div class="col-auto row items-center" :style='isLandscape ? "min-height:160px;" : "min-height:100px;"'>
                              <q-icon class="col" size="xl" name="add"></q-icon>
                            </div>
                            <q-file class="col" ref="refSelectorE" max-files="1" max-file-size="52428800" max-total-size="52428800" dense color="red-7" text-color="white"
                                    v-show="false"
                                    v-model="selectModelImage" @update:model-value="fileSelectionOK('EDIT')"/>
                          </div>
                        </div>

                        <div class="col row" style="position: relative;">
                          <div class="col-auto row items-start justify-end" v-show="canEditModel" style="position: absolute;top:0;right:0;width:72px;height:32px;z-index: 99;">
                            <q-btn flat dense color="grey-8" icon="edit" v-show="!isEditModelInfo" @click="editModelInfo" :disable="isEditConsumable"></q-btn>
                            <q-btn flat dense color="red-5" icon="cancel" v-show="isEditModelInfo" @click="cancelEditModelInfo"></q-btn>
                            <q-btn flat dense color="blue-8" icon="save" v-show="isEditModelInfo" @click="saveModelInfo"></q-btn>
                          </div>
                          <!-- 모델 텍스트 정보 -->
                          <div class="col column" style="width:100%;height:100%;">
                            <div class="col column" style="padding:0 12px 0 12px;" :style="canAddEdit() ? 'margin-top:0;' : 'margin-top:12px;'">
                              <q-input dense flat :outlined="isAddModel" color="red-7" v-model="modelName" borderless readonly
                                      class="col-auto" style="font-family:Prometo;font-size:32px;font-weight:bold;"/>
                              <div :class="canEditModel ? 'col' : 'col-auto'" class="row items-start" style="position: relative;padding:12px 0 0 0;">
                                <table :class="canEditModel ? 'col' : 'col'" style="border-collapse:collapse;width:100%;">
                                  <tr height="12px" style="border-top:2px solid black;"></tr>
                                  <tr height="32px">
                                    <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'"
                                        style="font-size:15px;font-weight:500;">
                                      {{ $t('common.type')}}
                                    </td>
                                    <td ></td>
                                    <td style="min-width:120px;">
                                      <div style="font-size:14px;">{{ modelType }}</div>
                                    </td>
                                    <td width="12px"></td>
                                  </tr>
                                  <tr height="32px">
                                    <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:500">{{ $t('common.option')}}</td>
                                    <td></td>
                                    <td>
                                      <div>{{ modelOption }}</div>
                                    </td>
                                  </tr>
                                  <tr height="32px">
                                    <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:500">{{ $t('common.hp')}}</td>
                                    <td></td>
                                    <td>
                                      <div class="row items-center" style="font-size:14px;">
                                        <div>{{modelHP}}&nbsp;ps</div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr height="32px" v-show="forDevModel">
                                    <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:500">{{ $t('equipment.forDev')}}</td>
                                    <td></td>
                                    <td>
                                      <div class="row items-center" style="font-size:14px;">
                                        <q-icon size="sm" color="red-7" name="check"></q-icon>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr height="32px" v-show="!isKorea && modelUseST">
                                    <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'"
                                        style="font-size:15px;font-weight:500">
                                      {{ $t('manage.engineHours')}}
                                    </td>
                                    <td></td>
                                    <td>
                                      <div class="row items-center justify-center">
                                        <q-checkbox v-model="modelUseST" disable dense color="red-7"></q-checkbox>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                                <div class="col-auto">{{ modelDetailComment }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col row">
                        <table :class="canEditModel ? 'col' : 'col'" style="border-collapse:collapse;width:100%;">
                          <!-- 모델별로 보기 옵션 설정 -->
                          <tr height="32px" v-show="canEditModel" style="border-top:2px solid black;border-bottom:1px solid #ddd;">
                            <td colspan="5" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'"
                                style="font-size:15px;font-weight:500;">
                              {{ $t('equipment.viewOptions')}}
                            </td>
                          </tr>
                          <tr height="auto" v-show="canEditModel" style="position:relative;border-bottom:2px solid black;">
                            <q-scroll-area class="col column" style="position: absolute;left:12px;top:0;bottom:0;right:0;">
                              <div class="row items-center justify-between" v-for="(option, index) in viewOptions" :key="option"
                                    style="margin:5px 12px 5px 0;" :style="index != 0 ? 'border-top:1px solid #ddd;' : 'border-top:0px solid #ddd;'">
                                <div :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:normal">
                                  {{ option.Title }}
                                </div>
                                <div>
                                  <div class="row items-center justify-center">
                                    <q-icon size="sm" :color="option.Visible ? 'red-7' : 'grey-7'" :name="option.Visible ? 'visibility' : 'visibility_off'" v-show="!isEditModelInfo"></q-icon>
                                    <q-checkbox v-model="option.Visible" dense color="red-7" v-show="isEditModelInfo"></q-checkbox>
                                  </div>
                                </div>
                              </div>
                            </q-scroll-area>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="col column" style="width:100%;height:100%;" v-show="isAddModel">
                    <div class="col" :class='isLandscape ? "row" : "column"' style="padding:0 0 12px 0">
                      <!-- 오른쪽 위 - 모델 추가 화면  -->
                      <div class="col row justify-center items-center imgSelector" style="cursor:pointer;width:100%;height:100%;position:relative;"
                            @click="modelImageClicked('ADD')">
                        <q-img style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;background-color: white;border-radius:3px;" spinner-color="white"
                              :src="logoImgUrl" fit="contain" position="50% 0"
                              v-show="modelImageFilename.length > 0">
                        </q-img>
                        <div class="col column items-center justify-center" style="width:100%;height:100%;padding:12px;background-color:#eeeeee;border-radius:3px;">
                          <div class="col-auto row items-center "  :style='isLandscape ? "min-height:160px;" : "min-height:100px;"'>
                            <q-icon class="col" size="xl" name="add"></q-icon>
                          </div>
                          <q-file class="col" ref="refSelector" max-files="1" max-file-size="52428800" max-total-size="52428800" dense color="red-7" text-color="white"
                                  v-show="false"
                                  v-model="selectModelImage" @update:model-value="fileSelectionOK('ADD')"/>
                        </div>
                      </div>

                      <div class="col column" style="padding:0 12px 0 12px;">
                        <!-- 우측 모델 정보 -->
                        <table class="col-auto" style="border-collapse:separate;">
                          <tr height="32px">
                            <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'"
                                style="font-size:15px;font-weight:500;">
                              {{ $t('common.type')}}
                            </td>
                            <td colspan="2">
                              <div class="row items-center" style="">
                                <q-input dense color="red-7" maxlength="20" v-model="modelTypeAdd"></q-input>
                                <q-select flat dense options-dense :options="getCategoryDevOnly"
                                          color="red-7" style="font-size:14px;"
                                          @update:model-value="updateDevType">
                                </q-select>
                              </div>
                            </td>
                          </tr>
                          <tr height="32px">
                            <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:500">{{ $t('common.model')}}</td>
                            <td colspan="2">
                              <div class="row items-center">
                                <q-input dense color="red-7" maxlength="19" v-model="modelNameAdd"></q-input>
                                <q-select flat dense options-dense :options="modelList"
                                          color="red-7" style="font-size:14px;"
                                          @update:model-value="updateModelName">
                                </q-select>
                              </div>
                            </td>
                          </tr>
                          <tr height="32px">
                            <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:500">{{ $t('common.option')}}</td>
                            <td colspan="2">
                              <div class="row items-center">
                                <q-input dense color="red-7" maxlength="19" v-model="modelOptionAdd"></q-input>
                                <q-select flat dense options-dense :options="optionList"
                                          color="red-7" style="font-size:14px;"
                                          @update:model-value="updateModelOption">
                                </q-select>
                              </div>
                            </td>
                          </tr>
                          <tr height="32px">
                            <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:500;">{{ $t('common.hp')}}</td>
                            <td colspan="2">
                              <div class="row items-center" style="font-size:14px;">
                                <q-input flat dense color="red-7" v-model="modelHPAdd" mask="######"  style="width:100px;"/>
                                <div>&nbsp;ps</div>
                              </div>
                            </td>
                          </tr>
                          <tr height="32px" v-show="!isKorea">
                            <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:500">{{ $t('manage.engineHours')}}</td>
                            <td colspan="2">
                              <div class="row items-center">
                                <q-checkbox v-model="modelUseSTAdd" dense color="red-7"></q-checkbox>
                              </div>
                            </td>
                          </tr>
                          <tr height="32px" v-show="canEditModel">
                            <td colspan="2" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:500">{{ $t('equipment.forDev')}}</td>
                            <td>
                              <div class="row items-center justify-start">
                                <q-checkbox v-model="forDevModel" dense color="red-7"></q-checkbox>
                              </div>
                            </td>
                          </tr>
                        </table>
                        <div class="col-auto">{{ modelDetailComment }}</div>
                      </div>
                    </div>
                    <div class="col row">
                      <table class="col" style="border-collapse:separate;border-bottom:2px solid black;">
                        <!-- 모델별로 보기 옵션 설정 -->
                        <tr height="32px" v-show="isAddModel">
                            <td colspan="5" :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'"
                                style="font-size:15px;font-weight:500;border-top:2px solid black;border-bottom:1px solid #ddd;">
                              {{ $t('equipment.viewOptions')}}
                            </td>
                          </tr>
                          <tr height="100%" v-show="isAddModel" style="position:relative;">
                            <q-scroll-area class="col column" style="position: absolute;left:12px;top:0;bottom:0;right:0;">
                              <div class="row items-center justify-between" v-for="(option, index) in viewOptions" :key="option"
                                  style="margin:5px 12px 5px 0;" :style="index != 0 ? 'border-top:1px solid #ddd;' : 'border-top:0px solid #ddd;'">
                                <div :style="isKorean ? 'font-family:Noto Sans KR' : 'font-family:Prometo;'" style="font-size:15px;font-weight:normal">
                                  {{ option.Title }}
                                </div>
                                <div>
                                  <div class="row items-center justify-center">
                                  <q-checkbox v-model="option.Visible" dense color="red-7"></q-checkbox>
                                  </div>
                                </div>
                              </div>
                            </q-scroll-area>
                          </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </q-splitter>
        </template>

        <template v-slot:separator>
          <div class="horizSeparator"></div>
        </template>

        <template v-slot:after >
          <!-- 아랫부분 소모품 관련 -->
          <div class="col column" :class='isLandscape ? "internalContainer" : "internalContainer_pt2"'>
            <div class="col-auto row items-center" style="height:45px;margin:0 12px">
              <div class="col row items-center justify-start">
                <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:16px;margin-right:12px;">{{ $t('common.consumables') }}</div>
                <!-- 소모품 불러오기 -->
                <q-btn no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.loadCons')"
                       v-show="canAddEdit() && (isAddModel || isEditConsumable)"
                       style="width:auto;height:32px;padding:0 12px;">
                  <!-- 소모품 불러오기 팝업 -->
                  <q-popup-proxy ref="consModelPopup" style="border-radius:3px;box-shadow: 0 0 9px 4px rgba(64 64 64 / 20%);height:auto"
                                 @before-show="beforeConsModelPopup">
                    <div class="col-auto column"
                         style="width:250px;background-color: white;padding:0 3px;border-radius:3px;height:300px">
                      <div class="col-auto row items-center justify-center" style="width:100%;height:40px;border-bottom:1px solid #eeeeee">
                        <div class="col-auto" style="font-family:'Noto Sans KR';font-size:14px;font-weight:bold;">모델 선택</div>
                      </div>
                      <div class="col row" style="margin:6px 0 6px 12px">
                        <q-scroll-area class="col" style="width:100%;height:100%;">
                          <!-- 트리 구조는 적합하지 않아 보여서 리스트 형태로 바꿈 -->
                          <!--
                          <q-tree :nodes="modelTreeData"
                                  style="width:auto;height:auto;padding:0"
                                  ref="groupTree"
                                  dense
                                  selected-color="red-7"
                                  node-key="index"
                                  v-model:expanded="expandedTree"
                                  v-model:selected="selectedTree"
                                  @update:selected="groupTreeSelected"
                                  class="treeContext" >
                          </q-tree>
                          -->
                          <!-- 리스트를 통해 타입과 모델 분리함 -->
                          <!--
                          <div v-for="(mType, typeIdx) in modelTreeList" :key="typeIdx">
                            <div class="row items-center justify-start" style="margin:6px 12px 0 0;border-bottom:1px solid #e4e4e4;">
                              <q-btn flat dense rounded icon="add" size="12px" v-show="!mType.expanded" @click="mType.expanded = !mType.expanded;"></q-btn>
                              <q-btn flat dense rounded icon="remove" size="12px" v-show="mType.expanded" @click="mType.expanded = !mType.expanded"></q-btn>
                              <div style="font-size:14px;font-weight:bold;font-family:'Prometo';">{{ mType.type }}</div>
                            </div>
                            
                            <div v-show="mType.expanded" style="margin:5px 12px 5px 22px;height:auto">
                              <div class="modelList" v-for="(model, modelIdx) in mType.models" :key="modelIdx"
                                   style="padding:1 0 1px 0;margin:3px 0;border-bottom:1px dashed #eeeeee"
                                   @click="modelTreeSelected(typeIdx, modelIdx)">
                                <div style="margin:0 6px;font-family:'Prometo';" :style='model.selected ? "color:#eb0028;" : "color:black;"'>{{ model.fullname }}</div>
                              </div>
                            </div>
                          </div>
                          -->
                          <!-- 트리 + 리스트 조합 -->
                          <div v-for="(mType, typeIdx) in modelTreeData" :key="typeIdx">
                            <div class="row items-center justify-start" style="margin:6px 12px 0 0;border-bottom:1px solid #e4e4e4;">
                              <q-btn flat dense icon="add" color="grey-9" size="12px" v-show="!mType.expanded" @click="mType.expanded = !mType.expanded;"></q-btn>
                              <q-btn flat dense icon="remove" color="grey-9" size="12px" v-show="mType.expanded" @click="mType.expanded = !mType.expanded"></q-btn>
                              <div style="font-size:14px;font-weight:bold;font-family:Prometo;">{{ mType.label }}</div>
                            </div>
                            
                            <div class="col column items-center justify-start" v-show="mType.expanded" style="margin:3px 12px 3px 22px;height:auto;">
                              <div class="col column items-center justify-start" v-for="(tModel, modelIdx) in mType.children" :key="modelIdx"
                                   style="width:100%;padding:1px 0 1px 0;margin:3px 0;">
                                <div class="col row items-center justify-start" style="width:100%;border-bottom:1px solid #eeeeee;">
                                  <q-btn flat dense icon="add" size="12px" color="grey-9" v-if="!tModel.expanded" @click="tModel.expanded = !tModel.expanded;"></q-btn>
                                  <q-btn flat dense icon="remove" size="12px" color="grey-9" v-if="tModel.expanded" @click="tModel.expanded = !tModel.expanded"></q-btn>
                                  <div style="margin:0 6px;font-family:Prometo;">{{ tModel.label }}</div>
                                </div>

                                <div class="col column items-center justify-start" v-show="tModel.expanded" style="width:100%;padding:0 0 0 33px;">
                                  <div class="col column items-center justify-start" style="width:100%;">
                                    <div class="col column items-center justify-start modelList" v-for="(option, optionIdx) in tModel.children" :key="optionIdx"
                                        style="width:100%;padding:3px 3px;cursor:pointer;border-bottom:1px solid #eeeeee"
                                        @click="modelTreeSelected(typeIdx, modelIdx, optionIdx)">
                                      <div class="col justify-start" style="width:100%;font-family:'Prometo';" :style='option.selected ? "color:#eb0028;" : "color:black;"'>{{ option.label }}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </q-scroll-area>
                      </div>
                      <div class="col-auto row items-center justify-center" style="width:100%;height:56px;border-top:1px solid #eeeeee">
                        <div class="col-10 row items-center justify-center" style="height:80%;margin:0">
                          <q-btn no-caps dense unelevated color="grey-4" text-color="black"
                                 style="width:80px;height:32px;margin:0 6px 0 0;font-family:'Noto Sans KR';" :label="$t('common.cancel')"
                                 @click="hideConsModelPopup(0)">
                          </q-btn>
                          <q-btn no-caps dense unelevated color="red-7" text-color="white"
                                 style="width:80px;height:32px;margin:0 0 0 6px;font-family:'Noto Sans KR';" :label="$t('common.confirm')"
                                 @click="hideConsModelPopup(1)"></q-btn>
                        </div>
                      </div>
                    </div>
                  </q-popup-proxy>
                  <!--
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.editConsumables')}}</q-tooltip>
                  -->
                </q-btn>
              </div>

              <!-- 소모품 편집 버튼 -->
              <div class="col row justify-end " v-show="canAddEdit() && (selectedRowIndex >= 0) && !isAddModel && !isEditConsumable">
                <q-btn flat dense color="grey-8" icon="edit" @click="editConsumable" :disable="isEditModelInfo">
                  <q-tooltip style="font-size:14px">{{ $t('tooltip.editConsumables')}}</q-tooltip>
                </q-btn>
              </div>

              <!-- 소모품 편집 모드에서 취소, 저장 버튼 -->
              <div class="col-auto row justify-end" v-show="isAddModel || isEditConsumable">
                <q-btn class="col" flat dense color="red-5" icon="cancel" @click="cancelEdit">
                  <q-tooltip style="font-size:14px;">{{ $t("tooltip.cancelJob") }}</q-tooltip>
                </q-btn>
                <q-btn class="col" flat dense color="blue-8" icon="save" @click="saveModel">
                  <q-tooltip style="font-size:14px;">{{ $t('common.save') }}</q-tooltip>
                </q-btn>
              </div>
            </div>

            <q-scroll-area class="col" style="width:100%;height:100%; margin-bottom:12px;" v-show="isAddModel || (rowModelSelected.length > 0)">
              <!-- 소모품 리스트 -->
              <div class="col-auto row bottomContainer">
                <div class="col-auto flex-break" :class='isAddModel || isEditConsumable ? "consEdit" : "consNormal"' v-for="(item, index) in consumableData" :key="index"
                     style="position:relative;margin:6px;width:220px;height:150px;border-radius:3px;">
                  <table style="position:absolute;z-index:1;left:0;top:3px;right:0;bottom:3px;width:100%;height:100%;padding:0;"
                         :style='(isAddModel || isEditConsumable) ? "pointer-events: auto;" : "pointer-events: none;"'>
                    <tr>
                      <td  width="170px" rowspan="2" @click="clickOnConsumable(index, item)" style="cursor:pointer">
                        <div class="row" style="width:100%;height:100%;">
                          <q-img :src="consImages[item.icon]" width="50px" height="50px" style="margin:0 0 0 12px"/>
                        </div>
                      </td>
                      <td align="right" valign="top" class="row items-start justify-end">
                        <!-- 사용여부 및 삭제버튼 -->
                        <div class="row" style="width:auto:height:auto;margin:12px 12px 0 0;">
                          <div class="col-auto" style="" v-show="!isAddModel && !isEditConsumable">
                            <q-icon :color='item.usage ? "green-4" : "grey-4"' name="circle"/>
                          </div>
                          <q-btn class="col-auto" style="margin:-6px -12px 0 0;" flat dense rounded color='grey-8' size="16px" icon="delete"
                                 v-show="isAddModel || isEditConsumable" @click="deleteConsumable(index)">
                            <q-tooltip style="font-size:14px;">{{ $t('tooltip.deleteConsumable') }}</q-tooltip>
                          </q-btn>
                        </div>
                      </td>
                    </tr>
                    <tr @click="clickOnConsumable(index, item)" style="cursor:pointer"></tr>
                    <tr @click="clickOnConsumable(index, item)" style="cursor:pointer">
                      <td colspan="2">
                        <div style="margin:0 0 0 12px;font-size:16px;font-weight:500;color:#212529;">{{ getItemTitle(item) }}</div>
                      </td>
                    </tr>
                    <!-- 별명같은 용도(정확히는 모름)였는데 추가된 가변 -->
                    <!--
                    <tr v-show="item.partsInfo.length > 0">
                      <td colspan="2">
                        <div style="font-size:16px;font-weight:400;color:#414549;">{{item.partsInfo}}</div>
                      </td>
                    </tr>
                    -->
                    <tr @click="clickOnConsumable(index, item)" style="cursor:pointer;">
                      <td colspan="2" style="font-size:12px;color:rgba(33, 37, 41, 0.9);">
                        <div class="flex-break bottomValue" style="width:100%;height:100;margin:0 12px;" >
                          {{ isKorean ? '주기' : 'Per' }}&nbsp;{{ item.valuePeriod }}{{ isKorean ? '시간 / 최초' : 'h / First' }}&nbsp;{{ item.valueFirst }}{{ isKorean ? '시간' : 'h' }}
                        </div>
                      </td>
                    </tr>
                  </table>

                  <!-- 소모품 아이템의 배경으로 깔려 있는 것으로 선택한 소모품 정보를 표시하는 팝업을 표시하는 데 사용된다. -->
                  <div style="position:absolute;z-index:0;left:0;top:0;right:0;bottom:0;background-color:transparent;border-radius:3px;"
                       :style='(isAddModel || isEditConsumable) ? "display:none;pointer-events: none;" : "display:auto;pointer-events:auto;"'
                       @click="showConsInfoPopup(index, item)">
                    <!-- 선택된 항목의 소모품 자세히 보기 팝업 -->
                    <q-popup-proxy ref="consumablePopup" style="border-radius:3px;box-shadow: 0 0 12px 6px rgba(64 64 64 / 20%);height:auto;overflow-y:hidden;">
                      <div style="border:1px solid darkgray;padding:0;border-radius:3px;width:auto;">
                        <div class="col-auto column"
                             style="min-width:300px;width:333px;background-color: white;padding:12px;border-radius:3px;"
                             :style='consHasParts ? "min-height:340px;height:auto;" : "min-height:200px;height:244px;"'>
                          <!-- 최상단 아이콘과 버튼 -->
                          <div class="col-auto row items-start justify-between" style="height:84px;margin:0 0 12px 0;">
                            <div class="col-auto row items-center justify-center" style="width:84px;height:84px;margin:0 0 12px 0;border:1px solid lightgray;border-radius:3px">
                              <!-- 소모품 아이콘 자리 -->
                              <q-img :src="consImages[item.icon]" width="50px" height="50px"/>
                            </div>
                            <div class="col column" style="width:100%;height:100%;">
                              <div class="col-auto row items-start justify-end">
                                <div class="col-auto row">
                                  <q-btn class="col row items-center justify-center" no-caps dense unelevated rounded color="red-7" size="12px" style="margin:0 0 0 0;"
                                        @click="closeConsumableInfo">
                                    <q-icon name="close" size="xs"/>
                                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob')}}</q-tooltip>
                                  </q-btn>
                                </div>
                              </div>
                              <div class="col row items-end justify-end" style="width:100%;height:100%;font-size:16px;font-weight:500;margin:0 0 1px 0;">
                                <div class="col row items-center justify-end">
                                  <div class="col-auto">{{ currentModelOption }}</div>
                                  <q-icon :color='consEnable ? "green-4" : "grey-4"' name="circle" style="margin:0 0 0 12px;"/>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- 소모품 카테고리 -->
                          <div class="col-auto row items-start justify-between" style="width:100%;height:auto;margin:0 0 12px 0;">
                            <div class="col row items-between justify-start" style="height:100%;margin:0 0;padding:0;">
                              <div class="col column">
                                <div class="col" style="font-family:'Noto Sans KR';font-size:15px;font-weight:bold;">{{ consCategory }}</div>
                              </div>
                            </div>
                          </div>

                          <div class="col-auto row items-start"
                              style="position:relative;width:100%;height:auto;margin:0 0 12px 0;border-color: #eeeeee;border-width: 1px 0 1px 0;border-style: solid;"
                              :style="consPartsInfo.length > 1 ? 'min-height:196px;' : 'min-height:106px;'"
                              v-show="consHasParts">
                            <!-- 부품정보들 -->
                            <q-scroll-area style="position:absolute;left:0;top:0;right:0;bottom:0;padding:6px 0;">
                              <div class="column" style="margin:0 0 6px 0" v-for="(part) in consPartsInfo" :key="part">
                                <div class="row items-center justify-center">
                                  <div class="col" style="font-family:'Noto Sans KR';font-size:15px;font-weight:bold;">{{ getPartName(part.name) }}</div>
                                </div>
                                <div class="col row items-center justify-between" style="width:100%;"
                                    v-for="(partno) in part.numbers" :key="partno">
                                    <div class="col" style="font-family:'Noto Sans KR';font-size:14px;border-bottom:1px solid #eeeeee;padding:0 0 3px 0;"
                                        :style='partno==1 ? "margin:0 12px 0 12px;" : "margin:6px 12px 0 12px;"'>
                                      {{ partno }}
                                    </div>
                                </div>
                              </div>
                            </q-scroll-area>
                          </div>

                          <div class="col-auto column items-start justify-start" style="height:auto;width:100%;">
                            <!-- 교환 주기 -->
                            <div class="col" style="font-family:'Noto Sans KR';font-size:15px;font-weight:bold;margin:0 0 4px 0;">{{ $t('equipment.replTime') }}</div>
                            <div class="col column items-start justify-start" style="width:100%;">
                              <div class="col row" style="width:100%;margin:0 0 4px 0;">
                                <div class="col" style="font-family:'Noto Sans KR';font-size:14px;border-bottom:1px solid #eeeeee;margin:0 0 0 12px;">
                                  {{  $t('common.firstTime') }}&nbsp;:&nbsp;{{ consValueFirst }}
                                </div>
                              </div>
                              <div class="col row" style="width:100%;margin:0 0 4px 0;">
                                <div class="col" style="font-family:'Noto Sans KR';font-size:14px;border-bottom:1px solid #eeeeee;margin:6px 0 0 12px;">
                                  {{  $t('common.cycleTime') }}&nbsp;:&nbsp;{{ consValueCycle }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </q-popup-proxy>
                  </div>
                </div>

                <!-- 소모품 추가 버튼 -->
                <div class="col-auto flex-break" :class='isAddModel || isEditConsumable ? "consEdit" : "consNormal"'
                     v-show="(isAddModel || isEditConsumable) && consumableData.length < 20"
                     style="margin:6px;width:220px;height:150px;border-radius:3px;"
                     @click="addConsumableToModel">
                  <div class="col row items-center justify-center" style="height:100%;">
                    <q-icon name="add" size="sm"></q-icon>
                  </div>
                </div>
              </div>
            </q-scroll-area>
          </div>
        </template>

        <!-- 소모품 추가 수정용 팝업형태 콘트롤 -->
        <div class="row items-center justify-center" v-show="showEditConsPopupMode > 0"
             style="position:absolute;left:0;top:0;right:0;bottom:0;z-index:2;background-color:#d0d0d030;border:1px solid #dddddd;border-radius: 3px;">
          <div class="col-auto column"
                style="min-width:280px;height:75%;box-shadow:0 6px 3px rgba(64 64 64 / 20%);background-color: white;padding:12px"
                :style="isLandscape ? 'width:333px;' : 'width:100%;'">
            <!-- 최상단 아이콘과 버튼 -->
            <div class="col-auto row items-start justify-between" style="height:84px;margin:0 0 12px 0;">
              <div class="col-auto row" style="width:84px;height:84px;margin:0 0 12px 0;border:1px solid lightgray">
                <q-btn class="col row items-center justify-center" flat dense color='grey-9'>
                  <q-icon class="col-auto" name="add" v-show="consImgSrc == ''"></q-icon>
                  <q-img class="col-auto" width="64px" height="64px" :src="consImgSrc" v-show="consImgSrc != ''"></q-img>
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.selectIcon') }}</q-tooltip>
                  <!-- 이미지 선택 팝업 -->
                  <q-popup-proxy ref="iconSelectPopup" style="border-radius:3px;box-shadow: 0 0 9px 4px rgba(64 64 64 / 20%);height:auto"
                                @before-show="beforeShowConsImgPopup">
                    <div class="col-auto column"
                         style="width:436px;background-color: white;padding:0 3px;border-radius:3px;height:196px">
                      <div class="col-auto row items-center justify-center" style="width:100%;height:40px;border-bottom:1px solid #eeeeee">
                        <div class="col-auto" style="font-family:'Noto Sans KR';font-size:14px;font-weight:bold;">Select Image</div>
                      </div>
                      <div class="col row" style="margin:0 0 0 6px;padding:6px 6px 0 0">
                        <q-scroll-area class="col" style="width:100%;height:100%;">
                          <div class="row" style="width:100%;height:auto">
                            <q-btn-group outline class="col row">
                              <div class="col-auto row consEdit" v-for="(item, index) in consImages" :key="index" style="width:72px;height:72px;margin:6px;"
                                   :style='index == consImgPopupSrc ? "border:2px solid red;" : "border:1px solid #eeeeee"'
                                   @click="consImgPopupSrc = index">
                                <q-img class="col" fit="none" :src="item"></q-img>
                              </div>
                            </q-btn-group>
                          </div>
                        </q-scroll-area>
                      </div>
                      <div class="col-auto row items-center justify-center" style="width:100%;height:56px;border-top:1px solid #eeeeee">
                        <div class="col-10 row items-center justify-center" style="height:80%;margin:0">
                          <q-btn no-caps dense unelevated color="grey-4" text-color="black"
                                 style="width:80px;height:32px;margin:0 6px 0 0;font-family:'Noto Sans KR';" :label="$t('common.cancel')"
                                 @click="hideConsImgPopup(0)">
                          </q-btn>
                          <q-btn no-caps dense unelevated color="red-7" text-color="white"
                                 style="width:80px;height:32px;margin:0 0 0 6px;font-family:'Noto Sans KR';" :label="$t('common.confirm')"
                                 @click="hideConsImgPopup(1)"></q-btn>
                        </div>
                      </div>
                    </div>
                  </q-popup-proxy>
                </q-btn>
              </div>
              <div class="col column items-start justify-between" style="width:100%;height:100%;">
                <div class="col row items-start justify-between" style="width:100%;">
                  <div class="col row items-start justify-end">
                    <q-btn class="col-auto row items-center justify-center" no-caps dense unelevated rounded color="red-7" size="12px" style="margin:0 12px 0 0;" @click="cancelEditConsPopup">
                      <q-icon name="close" size="xs"/>
                      <!--
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob')}}</q-tooltip>
                      -->
                    </q-btn>
                    <q-btn class="col-auto row items-center justify-center" no-caps dense unelevated rounded color="green-7" size="12px" style="margin:0 0 0 0;" @click="saveOneCons">
                      <q-icon name="check" size="xs"/>
                      <!--
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                      -->
                    </q-btn>
                  </div>
                </div>
                <div class="col row items-end justify-end" style="width:100%;height:100%;font-size:16px;font-weight:500;margin:0 0 1px 0;">
                  <div class="col-auto">{{ currentModelOption }}</div>
                </div>
              </div>
            </div>
            <div class="col-auto row items-center justify-start" style="width:100%;margin:0 0 12px 0;"> 
              <q-toggle class="col justify-start" dense color="red-7" :label='consEnable? $t("common.enable") : $t("common.disable")' v-model="consEnable" />
            </div>

            <!-- 소모품 카테고리 -->
            <fieldset style="border:1px solid #dddddd;width:100%;height:auto;margin:0 0 12px 0;">
              <legend style="padding:0 6px;">
                <div class="col row items-center">
                  <div class="col-auto" style="margin:0 6px 0 0;font-size:16px;font-family:'Noto Sans KR';font-weight:bold;">{{ $t('equipment.consumableCategory') }}</div>
                  <q-btn class="col-auto" flat dense rounded @click="catEditmode = !catEditmode">
                    <q-icon name="edit" size="20px" color="grey-7"></q-icon>
                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.editParts') }}</q-tooltip>
                  </q-btn>
                  <q-btn class="col-auto" flat dense rounded @click="selectConsCategory">
                    <q-icon name="delete" size="20px" color="grey-7"></q-icon>
                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.eraseParts') }}</q-tooltip>
                  </q-btn>
                </div>
              </legend>
              <div class="col row items-start justify-start" style="width:100%;height:100%;">
                <div v-show="!catEditmode" class="col row items-center justify-start">
                  <div style="font-family:'Noto Sans KR';font-size:14px;">{{ consCategory }}</div>
                </div>
                <div class="col column items-end justify-between" style="height:auto;margin:-3px 0 0 0;" v-show="catEditmode">
                  <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputConsCategoryKo')"
                           style="width:100%;" v-model="consCategoryKr">
                    <template v-slot:before>
                      <div style="font-family:'Noto Sans KR';font-size:14px;color:red;width:20px;">한</div>
                    </template>
                  </q-input>
                  <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputConsCategoryEn')"
                           style="width:100%;" v-model="consCategoryEn">
                    <template v-slot:before>
                      <div style="font-family:'Noto Sans KR';font-size:14px;color:blue;width:20px;">En</div>
                    </template>
                  </q-input>
                </div>
              </div>
            </fieldset>

            <div class="col row items-start" style="width:100%;margin:0 0 12px 0;">
              <!-- 부품정보들 -->
              <q-scroll-area style="width:100%;height:100%;padding:0 0 6px 0;">
                <fieldset style="border:1px solid #dddddd;width:100%;height:auto;margin:0 0 12px 0;" v-for="(part, partIdx) in consPartsNames" :key="partIdx">
                  <legend class="row items-center justify-between" style="padding:0 0;width:auto;background-color:transparent">
                    <div class="col row items-center" style="margin:0 6px;">
                      <div class="col-auto" style="font-size:16px;font-family:'Noto Sans KR';font-weight:bold;">{{ $t('equipment.partInfo') }}</div>
                    </div>
                  </legend>

                  <!-- 부품 이름 - 표시용 -->
                  <div class="col row items-center justify-between" style="width:100%;height:auto;margin:0 0 0 0;" v-show="!part.editmode">
                    <div class="col" style="font-size:15px;font-family:'Noto Sans KR';font-weight:500">{{ getPartName(part.name) }}</div>
                    <div class="col-auto row">
                      <q-btn class="col-auto" flat dense rounded @click="part.editmode = !part.editmode">
                        <q-icon name="edit" size="20px" color="grey-7"></q-icon>
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.editParts') }}</q-tooltip>
                      </q-btn>
                      <q-btn class="col-auto" flat dense rounded @click="eraseConsPart(partIdx)">
                        <q-icon name="delete" size="20px" color="grey-7"></q-icon>
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.eraseParts') }}</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                  <!-- 부품 이름 2개(편집) -->
                  <div class="col row items-center justify-between">
                    <div class="col column items-end justify-between" style="height:auto;margin:-3px 0 0 0;" v-show="part.editmode">
                      <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputpartnumber')"
                              style="width:100%;"
                              maxlength="50"
                              v-model="part.name[0]">
                        <template v-slot:before>
                          <div style="font-family:'Noto Sans KR';font-size:14px;color:red;width:20px;">한</div>
                        </template>
                      </q-input>
                      <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputpartnumber')"
                              style="width:100%;"
                              maxlength="50"
                              v-model="part.name[1]">
                        <template v-slot:before>
                          <div style="font-family:'Noto Sans KR';font-size:14px;color:blue;width:20px;">En</div>
                        </template>
                      </q-input>
                    </div>
                    <div class="col-auto row" style="margin:0 0 0 6px;" v-show="part.editmode">
                      <q-btn class="col-auto" flat dense rounded @click="part.editmode=false">
                        <q-icon name="check" size="20px" color="grey-7"></q-icon>
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.addPartNo') }}</q-tooltip>
                      </q-btn>
                      <!--
                      <q-btn class="col-auto" flat dense rounded @click="indexOfPartToAddPartNo=-1;addNewPartNumber=''">
                        <q-icon name="close" size="20px" color="grey-7"></q-icon>
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                      </q-btn>
                      -->
                    </div>
                  </div>

                  <!-- 부품번호들 -->
                  <div class="col row items-end justify-between" style="height:auto;margin:-3px 0 0 26px;"
                       v-for="(partno, noIdx) in consPartsNumbers[partIdx]" :key="noIdx">
                    <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputpartnumber')"
                             style="width:100%;border-color:#cccccc"
                             maxlength="50"
                             v-model="consPartsNumbers[partIdx][noIdx]">
                      <!--
                      <template v-slot:prepend>
                        <div class="row items-end justify-end" style="width:30px;">
                          <q-btn class="col-auto" flat dense rounded @click="indexOfPartToAddPartNo = partIdx" v-show="noIdx+1 == consPartsNumbers[partIdx].length">
                            <q-icon name="add" size="20px" color="grey-7"></q-icon>
                            <q-tooltip style="font-size:14px;">{{ $t('tooltip.addPartNo') }}</q-tooltip>
                          </q-btn>
                        </div>
                      </template>
                      -->
                      <template v-slot:append>
                        <q-btn class="col-auto" flat dense rounded style="margin:0 0 0 24px" @click="eraseConsPartNumber(partIdx, noIdx)">
                          <q-icon name="remove" size="20px" color="grey-7"></q-icon>
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.erasePartNo') }}</q-tooltip>
                        </q-btn>
                      </template>
                    </q-input>
                  </div>

                  <!-- 부품번호 추가용 버튼 -->
                  <div class="col row items-end justify-center" style="width:100%;height:100;" v-show="indexOfPartToAddPartNo != partIdx">
                    <q-btn class="col" flat dense @click="indexOfPartToAddPartNo = partIdx" style="margin:3px 0 0 26px;">
                      <q-icon name="add" size="20px" color="grey-7"></q-icon>
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.addPartNo') }}</q-tooltip>
                    </q-btn>
                  </div>
                  <!-- 부품번호 추가할 수 있도록 항목 추가 -->
                  <div class="col row items-end justify-between" style="height:auto;margin:-3px 0 0 0;" v-show="indexOfPartToAddPartNo == partIdx">
                    <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputpartnumber')"
                             style="width:100%;border-color:#cccccc;margin:0 0 0 26px;"
                             maxlength="50"
                             v-model="addNewPartNumber">
                      <template v-slot:append>
                        <q-btn class="col-auto" flat dense rounded @click="addNewPartNoToPart(partIdx)">
                          <q-icon name="check" size="20px" color="grey-7"></q-icon>
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.addPartNo') }}</q-tooltip>
                        </q-btn>
                        <q-btn class="col-auto" flat dense rounded @click="indexOfPartToAddPartNo=-1;addNewPartNumber=''">
                          <q-icon name="close" size="20px" color="grey-7"></q-icon>
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                        </q-btn>
                      </template>
                    </q-input>
                  </div>
                </fieldset>

                <!-- 새 부품 추가 -->
                <fieldset :style='addingParts ? "border:1px solid #dddddd;" : "border:0 solid black;"' style="width:100%;height:auto;margin:0 0 12px 0;">
                  <legend class="row items-center justify-between" style="padding:0 0;width:auto;background-color:transparent">
                    <div class="col row items-center">
                      <div class="col-auto" style="margin:0 6px;font-size:15px;font-family:'Noto Sans KR';font-weight:bold;">{{ $t('equipment.addConsPart')}}</div>
                      <q-btn class="col-auto" flat dense rounded v-show="!addingParts"
                              @click="addingParts = !addingParts">
                        <q-icon name="add" size="20px" color="grey-7"></q-icon>
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.addParts') }}</q-tooltip>
                      </q-btn>
                      <q-btn class="col-auto" flat dense rounded v-show="addingParts"
                              @click="cancelAddPart">
                        <q-icon name="close" size="20px" color="grey-7"></q-icon>
                        <!--
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                        -->
                      </q-btn>
                      <q-btn class="col-auto" flat dense rounded v-show="addingParts"
                              @click="addPartToCons">
                        <q-icon name="check" size="20px" color="grey-7"></q-icon>
                        <!--
                        <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                        -->
                      </q-btn>
                    </div>
                  </legend>

                  <!-- 부품 이름 -->
                  <div class="col column items-end justify-between" style="height:auto;margin:-3px 0 0 0;">
                    <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputpartnameKo')"
                             style="width:100%;" v-show="addingParts"
                             maxlength="50"
                             v-model="addingPartsNameKR">
                      <template v-slot:before>
                        <div style="font-family:'Noto Sans KR';font-size:14px;color:red;width:20px;">한</div>
                      </template>
                    </q-input>
                    <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputpartnameEn')"
                             style="width:100%;" v-show="addingParts"
                             maxlength="50"
                             v-model="addingPartsNameEN">
                      <template v-slot:before>
                        <div style="font-family:'Noto Sans KR';font-size:14px;color:blue;width:20px;">En</div>
                      </template>
                    </q-input>
                  </div>

                  <!-- 부품번호들 -->
                  <div class="col row items-end justify-between" style="height:auto;margin:-3px 0 0 24px"
                       v-for="(partNo, noIdx) in addPartNumberData" :key="noIdx">
                    <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputpartnumber')"
                             style="width:100%;"
                             maxlength="50"
                             v-model="addPartNumberData[noIdx]">
                      <template v-slot:append>
                        <q-btn class="col-auto" flat dense rounded @click="eraseAddedPartNumber(noIdx)">
                          <q-icon name="remove" size="20px" color="grey-7"></q-icon>
                          <q-tooltip style="font-size:14px;">{{ $t('tooltip.erasePartNo') }}</q-tooltip>
                        </q-btn>
                      </template>
                    </q-input>
                  </div>

                  <!-- 부품번호 추가용 버튼 -->
                  <div class="col row items-end justify-center" style="width:100%;height:100;" v-show="addingParts && !addingPartPartNo">
                    <q-btn class="col" flat dense @click="addingPartPartNo=true" style="margin:3px 0 0 26px;">
                      <q-icon name="add" size="20px" color="grey-7"></q-icon>
                      <q-tooltip style="font-size:14px;">{{ $t('tooltip.addPartNo') }}</q-tooltip>
                    </q-btn>
                  </div>

                  <!-- 부품번호 추가할 수 있도록 항목 추가 -->
                  <div class="col row items-end justify-between" style="height:auto;margin:-3px 0 0 26px;" v-show="addingParts && addingPartPartNo">
                    <q-input dense autofocus hide-bottom-space color="red-7" :placeholder="$t('placeholder.inputpartnumber')"
                             style="width:100%;border-color:#cccccc"
                             maxlength="50"
                             v-model="addedPartNumber">
                      <template v-slot:append>
                        <q-btn class="col-auto" flat dense rounded @click="addingPartPartNo=false;addedPartNumber=''">
                          <q-icon name="close" size="20px" color="grey-7"></q-icon>
                          <q-tooltip style="font-size:14px;">{{ $t('common.cancel') }}</q-tooltip>
                        </q-btn>
                        <q-btn class="col-auto" flat dense rounded @click="addNewPartNoToPart()">
                          <q-icon name="check" size="20px" color="grey-7"></q-icon>
                          <q-tooltip style="font-size:14px;">{{ $t('common.save') }}</q-tooltip>
                        </q-btn>
                      </template>
                    </q-input>
                  </div>

                </fieldset>
              </q-scroll-area>
            </div>

            <!-- 교환 주기 -->
            <fieldset style="border:1px solid #dddddd;width:100%;height:auto;margin:0 0 12px 0;">
              <legend style="padding:0 6px;">
                <div style="font-family:'Prometo';font-weight:bold;">{{ $t('equipment.replTime') }}</div>
              </legend>
              <div class="col row items-start justify-start" style="width:100%;height:100%;">
                <div class="col column">
                  <q-input dense autofocus hide-bottom-space color="red-7" style="width:100%;" v-model="consValueFirst">
                    <template v-slot:prepend>
                      <div style="width:125px;font-size:14px;">{{ $t('common.firstTime') }}</div>
                    </template>
                  </q-input>
                  <q-input dense autofocus hide-bottom-space color="red-7" style="width:100%;" v-model="consValueCycle">
                    <template v-slot:prepend>
                      <div style="width:125px;font-size:14px;">{{ $t('common.cycleTime') }}</div>
                    </template>
                  </q-input>
                </div>
              </div>
            </fieldset>

          </div>
        </div>
      </q-splitter>
    </div>
    
    <!-- 모델 제거 확인용 다이얼로그 -->
    <q-dialog v-model="delModelDlg" persistent>
      <q-card style="padding:12px 24px 12px 12px;min-width:420px">
        <q-card-section class="row items-center" >
          <div class="col row items-center justify-between">
            <div class="col-auto">
              <q-avatar icon="agriculture" color="red-7" text-color="white" style="margin:0 12px 0 0;"/>
            </div>
            <div class="col column">
              <span class="q-ml-sm" style="font-size:14px;margin:0 0 6px 12px;">{{ $t('common.model')}}&nbsp;:&nbsp;{{ dlgModel }}</span>
              <span class="q-ml-sm" style="font-size:14px;margin:0 0 6px 12px;" v-show="dlgOption.length > 0">{{ $t('common.option')}}&nbsp;:&nbsp;{{ dlgOption }}</span>
              <span class="q-ml-sm" style="font-size:14px;margin:0 0 12px 12px;">{{ dlgMsg }}</span>

              <q-input color="red-7" :type="hideDelUserPW ? 'password' : 'text'" style="margin:0 0 0 12px;font-size:14px" outlined autofocus dense v-model="pwBeforeDelUser"
                       :placeholder="$t('placeholder.inputPassword')">
                <template v-slot:append>
                  <q-icon :name="hideDelUserPW ? 'visibility_off' : 'visibility'"
                          class="cursor-pointer"
                          @click="hideDelUserPW = !hideDelUserPW" />
                </template>
              </q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup/>
          <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="delModelConfirmed"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped>
.imgSelector {
  border-radius: 3px;
  border:1px solid #bbb;
}
.imgSelector:hover {
  border-radius: 3px;
  border:1px solid #eb0028;
}
.imgSelector:active {
  border-radius: 3px;
  border:2px solid #eb0028;
}

.tableHeader {
  position: sticky;
  z-index: 3;
  top:0;
  background-color: white;
  font-size:14px;
  font-weight: 500;
}
.horizSeparator {
  width:32px;
  height:4px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.vertSeparator {
  width:4px;
  height:32px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.title {
  font-size:16px;
  font-weight:bold;
}
.internalContainer {
  background-color:#ffffff;
  border-radius: 3px;
  width:100%;
  height:100%;
}
.internalContainer_pt {
  background-color:#ffffff;
  border-radius: 3px;
  width:100%;
  height:100%;
}
.internalContainer_pt2 {
  background-color:#ffffff;
  border-radius: 3px;
  width:100%;
  height:100%;
}

.bottomContainer {
  background-color:#ffffff;
  flex: auto;
  flex-wrap: wrap;
  flex-direction: row;
  height:auto;
  margin:-6px 6px 6px 6px;  
}

.bottomTitle {
  font-size:16px;
}
.bottomValue {
  font-size:14px;
}
.consNormal {
  border:1px solid lightgray;
}
.consNormal:hover {
  border:1px solid Black;
}
.consNormal:active {
  border:2px solid black;
}

.consEdit {
  border:1px solid lightgray;
}
.consEdit:hover {
  border:1px solid red;
}
.consEdit:active {
  border:2px solid red;
}

.modelList {
  background-color:white;
}
.modelList:hover {
  background-color:#eeeeee;
}
.modelList:active {
  color:#eb0028;
  background-color:#eeeeee;
}
/*
th, td {
    border: 1px solid black;
}
*/

.viewOptionClass{
  height:100%;
  position: relative;
  border:1px solid green;
}
</style>

<script>
import { ref } from 'vue'
// import { toRaw } from 'vue'
import { useTymictStore } from '@/store/tymict'
import { useI18n} from 'vue-i18n'
import TymFncs from '@/js/tymfunctions.js'
import TymCommon from '@/js/tymcommon'
import TymAws from '@/js/tymaws.js'
import TymConst from '@/js/tymconstants.js'
import { XlsxWorkbook, XlsxSheet, XlsxDownload  } from 'vue3-xlsx';
import AWS from 'aws-sdk'

//import CryptoJS from "crypto-js"
// import axios from 'axios'

/*
import { GoogleMap } from 'vue3-google-map'
import { CustomMarker as GMarker } from 'vue3-google-map'
import { MarkerCluster as GCluster } from 'vue3-google-map'
import { Circle as GCircle } from 'vue3-google-map'
import { InfoWindow as GInfoWindow } from 'vue3-google-map'
*/

export default ({
  components : {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload

/*
    ,GoogleMap
    ,GMarker
    ,GCluster
    ,GCircle
    ,GInfoWindow
*/
  },
  data() {
    return {
    }
  },
  setup() {
    const i18n=useI18n()
    return {
/*      
      center: ref({lat: 37.4135557, lng: 127.1251959}),
      gmapOptions: ref({
        zoomControl: true,
        scrollwheel: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }),
      markers: ref([
        { position: { lat: 36.689247, lng: 126.844502 }, anchorPoint: 'BOTTOM_CENTER' },
        { position: { lat: 36.589247, lng: 126.944502 }, anchorPoint: 'BOTTOM_CENTER' },
        { position: { lat: 36.489247, lng: 127.044502 }, anchorPoint: 'BOTTOM_CENTER' },
        { position: { lat: 36.389247, lng: 127.144502 }, anchorPoint: 'BOTTOM_CENTER' },
        { position: { lat: 36.289247, lng: 127.244502 }, anchorPoint: 'BOTTOM_CENTER' },
        { position: { lat: 36.189247, lng: 127.144502 }, anchorPoint: 'BOTTOM_CENTER' },
        { position: { lat: 36.089247, lng: 127.044502 }, anchorPoint: 'BOTTOM_CENTER' },
      ]),
      clusterOption: ref({
        renderer: {
          render:function ({ count, position, markers }) {
//            console.log('++++++++++++++++++ renderer DATA', count, position, markers)

//            let cmarker = null
//            cmarker = new window.google.maps.Marker({
//              position,
//              label: String(markers.length),
//            })
//            return cmarker
          },
        },
        onClusterClick: null
        //onClusterClick: function (evt, cluster) {
        //  console.log('****************** onClusterClick', this, evt, cluster)
        //},
      }),
      circles: ref([
      {
          center: { lat: 36.689247, lng: 126.044502 },
          radius: 3 * 100,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        },
        {
          center: { lat: 36.689247, lng: 125.044502 },
          radius: 5 * 100,
          strokeColor: 'black',
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: 'black',
          fillOpacity: 1,
        },
      ]),
*/
      isAddModel : ref(false),
      isEditConsumable : ref(false),
      horizSplitterValue : ref(60),
      vertSplitterValue : ref(55),
      columns : ref([
        {
          name: 'index',
          required: false
        },
        {
          name: 'model',
          required: true,
          label: i18n.t('common.model'),
          align: 'left',
          field: 'model',
          sortable: true
        },
        {
          name: 'option',
          align: 'left',
          label: i18n.t('common.option'),
          field: 'option',
          sortable: true
        },
        {
          name: 'hp',
          align: 'left',
          label: 'ps', // i18n.t('common.hpUnit'),
          field: 'hp',
          sortable: true
        },
        {
          name: 'type',
          align: 'left',
          label: i18n.t('common.type'),
          field: 'type',
          sortable: true
        },
      ]),
      rows : ref([]),
      rowModelSelected : ref([]),
      visibleColumns : ref(['model', 'option', 'hp', 'type']),
      listItemCount : ref(0),
      pageCount : ref(0),
      itemsPerPage : ref(0),
      curPage : ref(0),
      curPagination : ref({
        sortBy: 'model',
        descending: 'true',
        page : 1
      }),
      tableFilter : ref(''),
      selectedRowIndex : ref(-1),
      catSelected : ref(i18n.t('common.type')),
      modelName : ref(''),
      modelDesc : ref(''),
      modelType : ref(''),
      modelHP : ref(0),
      modelOption : ref(''),
      modelWeight : ref(0),
      modelImagePath : ref(''),
      modelUseST : ref(false),

      modelTreeData : ref([]),
      expandedTree : ref([]),
      selectedTree : ref(''),
      modelTreeList : ref([]),
      modelSelectedOrder : ref([]),

      modelList : ref([]),
      modelTypeAdd : ref(''),
      modelNameAdd : ref(''),
      modelDescAdd : ref(''),
      optionList : ref([]),
      modelOptionAdd : ref(''),
      modelHPAdd : ref(0),
      modelUseSTAdd : ref(false),
      modelWeightAdd : ref(0),
      forDevModel : ref(false),

      consumableData : ref([]),

      showEditConsPopupMode : ref(0),    // 추가/편집 팝업 모드 (0:None, 1:Edit, 2:Add)
      selectedConsumableIndex : ref(-1),  // 보기에서 누른 소모품의 인덱스(0~)
      editConsumableNameKR : ref(''),
      editConsumableNameEN : ref(''),

      addedTypeValue : ref(''),

      consImgPopupSrc : ref(0),
      consImgSrc : ref(''),             // 소모품 개별 보기/수정 시 이미지 경로
      consEnable : ref(false),
      consCategory : ref(''),           // 소모품 카테고리(이름)
      consCategoryKr : ref(''),
      consCategoryEn : ref(''),
      consValueFirst : ref(0),          // 소모품 최초 교체시간
      consValueCycle : ref(0),          // 소모품 교체 주기
      consHasParts : ref(false),        
      consPartsInfo : ref([]),           // 
      consPartsNames : ref(null),
      consPartsNumbers : ref(null),

      addingParts : ref(false),         // 부품 편집 팝업에서 부품 추가
      addingPartPartNo : ref(false),    // 부품 편집 팝업에서 부품 추가=>파트넘버 추가모드
      addingPartsNameKR : ref(''),
      addingPartsNameEN : ref(''),
      catEditmode : ref(false),          // 카테고리 편집모드
      addNewPartNumber : ref(''),         // 추가되는 부품번호
      indexOfPartToAddPartNo : ref(-1),
      addPartNumberData : ref([]),       // 새로 추가되는 부품의 파트넘버들
      addedPartNumber : ref(''),
      
      sheets: ref([]),

      makingExcel : ref(true),
      excelFilename : ref(''),
      excelType : ref(''),
      excelKeyword : ref(''),

      modelImageFilename : ref(''),
      selectModelImage : ref(''),
      logoImgUrl : ref(''),

      hideDelUserPW : ref(true),
      pwBeforeDelUser : ref(''),
      delModelDlg : ref(false),
      dlgModel : ref(''),
      dlgOption : ref(''),
      dlgMsg : ref(''),

      hasLogoImage : ref(false),
      isEditModelInfo : ref(false),
      viewOptions: ref([]),
      /*
      viewOptions: ref([
        {
          Title: i18n.t('machineStatus.Ignition'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.KeyOn'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.Battery'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.Coolant'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.Urea'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.EngineLoad'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.EngineRPM'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.EngineOil'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.AmbientAirTemp'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.DPFRegen'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.DPFSoot'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.FuelFilterWater'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.Inducement'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.UsingTime'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.RecentAt'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.Fuel'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.ADS'),
          Visible: false
        }, 
        {
          Title: i18n.t('machineStatus.Telematics'),
          Visible: false
        }
      ]) 
      */
    }
  },
  computed : {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    isKorean() {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    isKorea() {
      const store = useTymictStore()
      return (store.connectServer == 0)
    },
    isDev() {
      return (TymConst.IS_DEVELOPMENT != 0)
    },
    getCategory() {
      const store = useTymictStore()
      const i18n = useI18n()
      let cfgTypes = store.getMachineTypes()

      if(!TymCommon.isEmpty(store.machineTree) && !TymCommon.isEmpty(store.machineTree.Machines)) {
        store.machineTree.Machines.forEach(one => {
          if(one != i18n.t('common.all')) {
            if(!cfgTypes.find(x => x == one)) {
              cfgTypes.push(one)
            }
          }
        })
      }

      return cfgTypes
    },
    getCategoryDevOnly() {
      let result = []
      let mTypes = this.getCategory
      if(!TymCommon.isEmpty(mTypes)) {
        for(let idx = 1; idx < mTypes.length; idx++) {
          result.push(mTypes[idx])
        }
      }

      return result
    },
    selectedItemIndex() {
      return this.selectedRowIndex
    },
    listupItemCount() {
      return this.listItemCount
    },
    onlyDeveloper() {
      const store = useTymictStore()
      return store.isICTUser()
    },
    updatedModel() {
      /* 20240117 - 자동으로 갱신하면 아래 살리는데 이 경우 작업중에 작업내용이 쵸기화된다.
      this.applyData()
      this.makeModelTree()
      */
      return this.rows
    },
    currentModelOption() {
      let result = this.modelName
      if(!TymCommon.isEmpty(this.modelOption)) {
        if(this.modelOption != '0' && this.modelOption != 'D' && this.modelOption != 'NONE') {
          result += this.modelOption
        }
      }
      return result
    },
    consImages() {
      return TymConst.CONSUMABLE_ICONS
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return (store.canDownloadToExcel())
    },
    // watch를 위한 함수임...
    modelListState() {
      const store = useTymictStore()
      return store.ModelData.data
    },
    canEditModel() {
      const store = useTymictStore()
      // return ((TymConst.IS_DEVELOPMENT != 0) && store.isICTUser())
      return store.isICTUser()
    }
  },
  watch: {
    modelListState : {
      handler(newVal, oldVal) {
        if(!TymCommon.isEmpty(oldVal)) {
          if(!this.isAddModel && !this.isEditConsumable && !this.isEditModelInfo) {
            if(newVal != oldVal) {
              if(!TymCommon.isEmpty(newVal) && (newVal.length > 0)) {
                setTimeout(() => {
                  this.applyData()
                  this.makeModelTree()
                }, 1)
              }
            }
          }
        }
      },
      immediate : true
    }
  },
  created() {
  },
  mounted() {
    this.viewOptions = []
    for(let idx = 0; idx < TymConst.VIEWOPTIONS.Count; idx++) {
      this.viewOptions.push({
        Title: '',
        Visible: false
      })
    }

    this.viewOptions[TymConst.VIEWOPTIONS.Ignition] = {
      Title: this.$t('machineStatus.Ignition'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.Battery] = {
      Title: this.$t('machineStatus.Battery'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.Fuel] = {
      Title: this.$t('machineStatus.Fuel'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.Coolant] = {
      Title: this.$t('machineStatus.Coolant'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.ParkingBrake] = {
      Title: this.$t('machineStatus.ParkingBrake'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.Direction] = {
      Title: this.$t('machineStatus.Direction'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.PTO] = {
      Title: this.$t('machineStatus.PTO'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.AirCleaner] = {
      Title: this.$t('machineStatus.AirCleaner'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.TMOilFilterTemp] = {
      Title: this.$t('machineStatus.TMOilFilterTemp'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.Urea] = {
      Title: this.$t('machineStatus.Urea'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.FourWD] = {
      Title: this.$t('machineStatus.FourWD'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.HorizMode] = {
      Title: this.$t('machineStatus.HorizMode'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.DepthMode] = {
      Title: this.$t('machineStatus.DepthMode'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.DraftMode] = {
      Title: this.$t('machineStatus.DraftMode'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.UsingTime] = {
      Title: this.$t('machineStatus.UsingTime'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.EngineLoad] = {
      Title: this.$t('machineStatus.EngineLoad'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.EngineRPM] = {
      Title: this.$t('machineStatus.EngineRPM'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.EngineOil] = {
      Title: this.$t('machineStatus.EngineOil'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.AmbientAirTemp] = {
      Title: this.$t('machineStatus.AmbientAirTemp'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.DPFRegen] = {
      Title: this.$t('machineStatus.DPFRegen'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.DPFSoot] = {
      Title: this.$t('machineStatus.DPFSoot'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.FuelFilterWater] = {
      Title: this.$t('machineStatus.FuelFilterWater'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.Inducement] = {
      Title: this.$t('machineStatus.Inducement'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.RecentAt] = {
      Title: this.$t('machineStatus.RecentAt'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.KeyOn] = {
      Title: this.$t('machineStatus.KeyOn'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.ADS] = {
      Title: this.$t('machineStatus.ADS'),
      Visible: false
    }, 
    this.viewOptions[TymConst.VIEWOPTIONS.Telematics] = {
      Title: this.$t('machineStatus.Telematics'),
      Visible: false
    }
    /*
    this.clusterOption.renderer = {
    }
    this.clusterOption.renderer.render = this.gmapRenderer
    this.clusterOption.onClusterClick = this.clusterClicked
    */

    /*
    if(TymConst.IS_DEVELOPMENT == 3) {
      // 링스필드 SIM 정보 시험용
      console.log('--------------------------------------------------------------')

      const now = new Date()
      const timestamp = String(Math.floor(now.getTime() / 1000))
      const baseURL = 'https://apiv3.linksfield.net'
      const queryDev = '/device/queryDevice'
      let signContent = {
        "access_code": "KR00000016",
        "page_no": 1,
        "page_size": 50,
        "version": "2.0",
        "timestamp": timestamp
      }

      const secret = 'gGCzv4QHptlFFBZCP36gJg5evkR8eMgQ'
      const jsonSignContent = JSON.stringify(signContent, Object.keys(signContent).sort());

      var hash = CryptoJS.HmacSHA1(jsonSignContent, secret)
      console.log(hash)

      var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA1, secret)
      hmac.update(jsonSignContent);
      const signBase64 = hmac.digest('base64')
      console.log(signBase64)

      let body = {
        "access_code": "KR00000016",
        "page_no": 1,
        "page_size": 50,
        "version": "2.0",
        "timestamp": timestamp,
        "sign" : signBase64
      }

      console.log(signContent, signBase64, body)

      axios.post(baseURL + queryDev, body, {
        headers: {
          "authorization": "token",
          "Access-Control-Allow-Origin": "*",
          "Context-Type": "application/json",
        },
      }).then(data => {
        console.log('++++++++++++++++>', data)
      })
      .catch(er => {
        console.log('---------------->', er)
      })
    }
    */
    const store = useTymictStore()
    if(store.CurrentPage.Now != TymConst.PAGE_MACHINE) {
      store.setPage(TymConst.PAGE_MACHINE)
    }

    setTimeout(() => {
      this.applyData()
      this.makeModelTree()

      let saved = sessionStorage.getItem('MODEL.saved')
      if(!TymCommon.isEmpty(saved)) {
        saved = JSON.parse(saved)
        this.catSelected = saved.Category
        this.tableFilter = saved.Keyword
        if(!TymCommon.isEmpty(saved.Selected)) {
          if(saved.RowIndex >= 0) {
            if(Array.isArray(saved.Selected))
              this.tableRowClicked(null, saved.Selected[0], saved.RowIndex)
            else
              this.tableRowClicked(null, saved.Selected, saved.RowIndex)
          }
        }
        sessionStorage.removeItem('MODEL.saved')
      }
    }, 5)
  }, 
  unmounted() {
    sessionStorage.setItem('MODEL.saved', JSON.stringify({
      Category: this.catSelected,
      Keyword: this.tableFilter,
      RowIndex: this.selectedRowIndex,
      Selected: this.rowModelSelected
    }))
  },
  methods : {
    unusedParam() {
      //
    },
    clusterClicked(ev1, ev2) {
      console.log('--- clusterClicked ---', ev1, ev2)
    },
    gmapRenderer({ count, position }, stats) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('++++++++++++++++++ renderer 1ST', count)
        console.log('++++++++++++++++++ renderer 2ND', position)
        console.log('++++++++++++++++++ renderer 3RD', stats)
        console.log('++++++++++++++++++ renderer COLUMNS', this.columns)
      }
      const svg = window.btoa(`
        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" opacity="0.9">
          <defs>
            <radialGradient id="grad1" cx="50%" cy="50%" r="50%">
              <stop offset="80%" stop-color="#00FF00" />
              <stop offset="100%" stop-color="#FFFFFF00"/>
            </radialGradient>
          </defs>
          <circle cx="64" cy="64" r="57" stroke="#00FF00" stroke-width="14" fill="transparent"/>
          <circle cx="64" cy="64" r="46" fill="url(#grad1)"/>
          <circle cx="64" cy="64" r="38" fill="#00FF00"/>
        </svg>`)
      let cmarker = null
      cmarker = new window.google.maps.Marker({
        position,
        icon: {
          // url: require('@/assets/map/cluster-marker-1.png'),
          url:  `data:image/svg+xml;base64,${svg}`,
          scaledSize: new window.google.maps.Size(40, 40),
        },
        label: {
          text: String(count*38),
          color: "black",
          fontSize: "12px",
          fontWeight:"bold",
        },
      })
      return cmarker
    },
    getItemTitle(item) {
      if(this.isKorean)
        return item.titleKr
      else
        return item.titleEn
    },
    canAddEdit() {
      const store = useTymictStore()
      return store.isICTUser()
    },
    convMachineType(type) {
      const store = useTymictStore()

      if(!TymCommon.isEmpty(store.WebConfig)) {
        let typeIdx = store.WebConfig.MachineTypes[0].findIndex(x => x == type)
        if(typeIdx < 0)
          typeIdx = store.WebConfig.MachineTypes[1].findIndex(x => x == type)

        if(typeIdx >= 0) {
          if(this.isKorean) {
            return store.WebConfig.MachineTypes[0][typeIdx]
          } else {
            return store.WebConfig.MachineTypes[1][typeIdx]
          }
        }
      }
      return null
    },
    applyData(viewType) {
      const store = useTymictStore()
      this.rows = []
      this.rowModelSelected = []

      this.convMachineType()

      if(TymConst.IS_DEVELOPMENT) {
        console.log('EqView.applyData :', store.ModelData.data, TymConst.VIEWOPTIONS, Object.keys(TymConst.VIEWOPTIONS))
        
      }

      if(store.ModelData && store.ModelData.data.length > 0) {
        let i = 0;
        let categories = this.getCategory
        for(i = 0; i < store.ModelData.data.length;i++) {
          if(!store.isICTUser()) {
            if(store.ModelData.data[i].development)
              continue
          }
          let opt = ''
          if(store.ModelData.data[i].option != 'NONE' && store.ModelData.data[i].option != '0')
            opt = store.ModelData.data[i].option

          if(TymCommon.isEmpty(viewType) || (viewType == categories[0])) {
            // console.log(i, store.ModelData.data[i])
            this.rows.push({
              index : i,
              model : store.ModelData.data[i].model,
              option : opt,
              hp : Number(store.ModelData.data[i].hp),
              type : this.convMachineType(store.ModelData.data[i].type), // store.ModelData.data[i].type
              logo : store.ModelData.data[i].logo,
              desc : store.ModelData.data[i].description,
              use_st : TymCommon.isEmpty(store.ModelData.data[i].use_st) ? false : store.ModelData.data[i].use_st,
              development : store.ModelData.data[i].development,
              viewOptions : store.ModelData.data[i].viewOptions,
            })
          } else {
            if(viewType == store.ModelData.data[i].type) {
              this.rows.push({
                index : i,
                model : store.ModelData.data[i].model,
                option : opt,
                hp : Number(store.ModelData.data[i].hp),
                type : store.ModelData.data[i].type,
                logo : store.ModelData.data[i].logo,
                desc : store.ModelData.data[i].description,
                use_st : TymCommon.isEmpty(store.ModelData.data[i].use_st) ? false : store.ModelData.data[i].use_st,
                development : store.ModelData.data[i].development,
                viewOptions : store.ModelData.data[i].viewOptions,
              })
            }
          }          
        }
        this.curPage = 1
      } else {
        this.curPage = 0
      }

      this.totalCount = this.rows.length
      if(this.itemsPerPage > 0)
        this.pageCount = Math.ceil(this.rows.length / this.itemsPerPage)

      this.listItemCount = this.rows.length
    },
    makeModelTree() {
      const store = useTymictStore()
      /*
      {
        "model": "T115",
        "option": "NONE",
        "type": "Tractor",
        "logo": "modelImage/T115NONE.png",
        "hp": "115",
        "ads_type": "EHI_T",
        "development": true,
      }
      */
      this.modelTreeData = []
      this.modelTreeList = []
      let idx = 0, arIndex = 0

      store.ModelData.data.forEach(one => {
        let foundType = this.modelTreeData.find(x => x.label == one.type)
        if(foundType) {
          let foundModel = foundType.children.find(x => x.label == one.model)
          if(foundModel) {
            let findOption = foundModel.children.find(x => x.label == one.model + one.option)
            if(findOption) {
              //
            } else {
              foundModel.children.push({
                label : one.model + one.option,
                index : idx++,
                selected : false,
                expanded : false,
                position : arIndex++,
                model : one.model,
                option : one.option,
                children : null
              })
            }
          } else { 
            foundType.children.push({
              label : one.model,
              index : idx++,
              position : arIndex,
              children : [{
                label : one.model + one.option,
                index : idx++,
                selected : false,
                expanded : false,
                position : arIndex,
                model : one.model,
                option : one.option,
                children : []
              }]
            })
            arIndex++
          }
        } else {
          this.modelTreeData.push({
            label : one.type,
            index : idx++,
            selected : false,
            expanded : false,
            position : arIndex,
            children : [{
              label : one.model,
              index : idx++,
              selected : false,
              expanded : false,
              position : arIndex,
              children : [{
                label : one.model + one.option,
                index : idx++,
                selected : false,
                expanded : false,
                position : arIndex,
                model : one.model,
                option : one.option,
                children : []
              }]
            }]
          })
          //console.log(this.modelTreeData)
          arIndex++
        }
      })

      /*
      arIndex = 0
      store.ModelData.data.forEach(one => {
        let foundType = this.modelTreeList.find(x => x.type == one.type)
        if(foundType) {
          let foundModel = foundType.models.find(x => x.fullname == one.model + one.option)
          if(!foundModel) {
            foundType.models.push({
              fullname : one.model + one.option,
              selected : false,
              model : one.model,
              option : one.option
            })
          }

        } else {
          this.modelTreeList.push({
            type : one.type,
            expanded : false,
            models : [{
              fullname : one.model + one.option,
              selected : false,
              model : one.model,
              option : one.option
            }]
          })
        }
      })
      */
      // console.log('this.modelTreeData : ', this.modelTreeData)
      // console.log('this.modelTreeList : ', this.modelTreeList)
      // this.expandedTree = [this.modelTreeData[0].index]
      // this.selectedTree = this.modelTreeData[0].index
    },
    updatePagination(newPagination) {
      this.rows.sort((a, b) => {
        let result = 0
        switch(newPagination.sortBy) {
          case 'model':
            result = a.model - b.model
            break
          case 'option':
            if(a.option == b.option)
              result = a.model - b.model
            else
              result = a.option - b.option
            break
          case 'hp':
            {
              let aVal = parseFloat(a.hp)
              let bVal = parseFloat(b.hp)
              if(isNaN(aVal)) {
                if(isNaN(bVal))
                  result = 0;
                else
                  result = -1
              } else if(isNaN(bVal)) {
                if(isNaN(aVal))
                  result = 0;
                else
                  result = 1
              } else {
                result = aVal - bVal
              }
            }
            break
        }
        if(newPagination.descending)
          result = 0 - result;
          
        if(result > 0)
          return 1
        else if(result < 0)
          return -1
        else 
          return 0
      })

      this.curPagination = newPagination
      this.curPage = newPagination.page

      // console.log('updatePagination', newPagination, this.rows)
    },
    notiPageChanged(page) {
      console.log('notiPageChanged : ', page)
      let sortby = this.curPagination.sortBy
      let desc = this.curPagination.descending
      this.curPagination = { 
        sortBy : sortby,
        descending : desc,
        rowsPerPage : this.itemsPerPage, 
        page : page
      }
    },
    tableRowClicked(evt, row, index) {
      this.unusedParam(evt)
      this.selectedRowIndex = index
      setTimeout(()=> {
        this.rowModelSelected = [row]
        this.changeSelection()
      }, 5)
      // console.log('tableRowClicked', index, this.selectedRowIndex)
    },
    changeSelection() {
      const store = useTymictStore()

      this.viewOptions.forEach(option => {
        option.Visible = false
      })
      this.forDevModel = false

      if(this.rowModelSelected.length > 0) {
        if(!TymCommon.isEmpty(this.rowModelSelected[0])) {
          this.isEditModelInfo = false

          let imgCtrl = document.getElementById('viewModelImage')

          this.modelName = this.rowModelSelected[0].model
          this.modelDesc = TymCommon.isEmpty(this.rowModelSelected[0].desc) ? '' : this.rowModelSelected[0].desc
          this.modelType = this.rowModelSelected[0].type
          this.modelUseST = this.rowModelSelected[0].use_st
          if(!TymCommon.isEmpty(this.rowModelSelected[0].logo)) {
            TymAws.getS3Image(this.rowModelSelected[0].logo, imgCtrl)
            this.hasLogoImage = true
          } else {
            // 20231214 TYM 로고 지워달라네(by 전준혁)
            // imgCtrl.src = require('../assets/company/title_logo.png')
            imgCtrl.src = ''
            this.hasLogoImage = false
          }

          this.modelHP = this.rowModelSelected[0].hp
          this.modelOption = this.rowModelSelected[0].option

          this.forDevModel = this.rowModelSelected[0].development

          if(!TymCommon.isEmpty(store.ModelData.data[this.rowModelSelected[0].index].viewOptions)) {
            let viewOptions = store.ModelData.data[this.rowModelSelected[0].index].viewOptions
            let viewKeys = Object.keys(TymConst.VIEWOPTIONS)
            for(let idx = 0; idx < TymConst.VIEWOPTIONS.Count; idx++) {
              if(TymCommon.isEmpty(viewOptions[viewKeys[idx]])) {
                this.viewOptions[idx].Visible = true
              } else {
                this.viewOptions[idx].Visible = viewOptions[viewKeys[idx]]
              }
            }
          } else {
            this.viewOptions.forEach(option => {
              option.Visible = true
            })
          }
          if(TymConst.IS_DEVELOPMENT) {
            console.log('changeSelection :', this.rowModelSelected[0], store.ModelData.data[this.rowModelSelected[0].index])
          }

          this.updateConsumable(store.ModelData.data[this.rowModelSelected[0].index])
          return
        }
      } 
      // 클리어
      this.modelName = ''
      this.modelType = ''
      // imgCtrl.src = require('../assets/company/title_logo.png')
      this.modelDesc = ''
      this.modelHP = 0
      this.modelOption = ''
      this.updateConsumable(null)
    },
    tableFilterMethod() {
      let found = []
      if(this.getCategory.length > 1) {
        if(this.catSelected == this.getCategory[0]) {
          found = this.rows
        } else {
          found = this.rows.filter(row => (this.catSelected.localeCompare(row.type, undefined, { sensitivity: 'base' }) === 0))
        }

        if(!TymCommon.isEmpty(this.tableFilter)) {
          let upperFilter = this.tableFilter.toUpperCase().trim()
          if(!TymCommon.isEmpty(this.tableFilter)) {
            found = found.filter(row => (row.model.indexOf(upperFilter) >= 0) || (row.option.indexOf(this.tableFilter) >= 0))
          }
        }
      }

      if(this.itemsPerPage > 0)
        this.pageCount = Math.ceil(found.length / this.itemsPerPage)

      this.listItemCount = found.length
      return found
    },
    getConsumableDefault(index) {
      let consText = [
        this.$t('TEXT_CITEM.item1'),
        this.$t('TEXT_CITEM.item2'),
        this.$t('TEXT_CITEM.item3'),
        this.$t('TEXT_CITEM.item4'),
        this.$t('TEXT_CITEM.item5'),
        this.$t('TEXT_CITEM.item6'),
        this.$t('TEXT_CITEM.item7'),
      ]

      return consText[index]
    },
    getPartName(name) {
      const store = useTymictStore()

      if(Array.isArray(name)) {
        return name[store.connectServer]
      } else {
        return name
      }
    },
    // 특정 모델의 소모품 데이터 가져온다.
    updateConsumable(obj) {
      this.consumableData = []

      if(!TymCommon.isEmpty(obj)) {
        const store = useTymictStore()

        for(let i = 0; i < obj.citems.length; i++) {
          let nameKr = '', nameEn = ''
          if(TymCommon.isEmpty(obj.citems[i].name[store.connectServer])) {
            let isFound = false, optionIndex = -1
            let foundModel = store.ModelList.data.find(x => x.dmodel == obj.model)
            if(foundModel) {
              optionIndex = foundModel.doption.indexOf(obj.option)
              if(optionIndex >= 0) {
                isFound = true
              }
            }

            if(isFound) {
              // 모델리스트에서 찾음
              // console.log('update consumables FOUND', foundModel, optionIndex)
              nameKr = foundModel.default_citem[optionIndex].name[i][0]
              nameEn = foundModel.default_citem[optionIndex].name[i][1]
            } else {
              // 모델리스트에도 없으니 디폴트로 사용
              nameKr = TymConst.CONSUMABLE_DEFAULTS[i][0]
              nameEn = TymConst.CONSUMABLE_DEFAULTS[i][1]
            }
          } else {
            nameKr = obj.citems[i].name[0]
            nameEn = obj.citems[i].name[1]
          }

          let isJSON = false;
          let abc = null
          let iconIdx = 0
          if(!TymCommon.isEmpty(obj.citems[i].sn)) {
            try {
              if(Array.isArray(obj.citems[i].sn)) {
                abc = JSON.stringify(obj.citems[i].sn)
                if((abc.length > 2) && !TymCommon.isEmpty(abc)) {
                  isJSON = true
                  iconIdx = obj.citems[i].sn[0].icon
                }
              }
            } catch(e) {
              console.log('JSON.parse ERROR :', e)
            }
          }

          // console.log(i, isJSON, obj.citems[i].sn)
          this.consumableData.push({
            icon : iconIdx,
            titleKr : nameKr,
            titleEn : nameEn,
            hasParts : isJSON,
            partsInfo : isJSON ? obj.citems[i].sn : '',
            usage : obj.citems[i].enable,
            valuePeriod : obj.citems[i].cc,
            valueFirst : obj.citems[i].cf
          })
        }
        // console.log('update consumables FINAL', this.consumableData)
      }
      // console.log('updateConsumable :', JSON.stringify(obj))
    },
    changedCategory() {
      this.selectedRowIndex = -1
      this.rowModelSelected = []
      this.changeSelection()
    },
    searchClicked() {

    },
    editModel() {
      if(this.rowModelSelected.length < 1) {
        TymCommon.Toast('수정할 모델을 먼저 선택하세요.')
        return
      }
    },
    cancelEdit() {
      this.isAddModel = false
      this.isEditConsumable = false;

      this.selectedRowIndex = -1
      this.rowModelSelected = []
      this.changeSelection()
      this.$refs.addModelTooltip.hide()
    },
    editConsumable() {
      console.log('editConsumable :', this.isEditModelInfo, this.isEditConsumable)
      this.isEditConsumable = true;
    },
    modelImageClicked(option) {
      if(TymCommon.isEmpty(option)) {
        this.$refs.refSelector.pickFiles()
      } else {
        switch(option) {
          case 'ADD':
            this.$refs.refSelector.pickFiles()
            break
          case 'EDIT':
            this.$refs.refSelectorE.pickFiles()
            break
        }
      }
    },
    /// 이앙기, 트랙터, AFTER... 선택시
    addModelCategoryChanged(value) {
      console.log('addModelCategoryChanged :', value)
      const store = useTymictStore()
      this.modelList = []
      let found = store.ModelList.data.filter(x => x.dtype == value)
      if(found) {
        for(let i = 0; i < found.length; i++) {
          this.modelList.push(found[i].dmodel)
        }
        this.modelList.sort((a, b) => {
          return a.localeCompare(b)
        })
        this.modelNameAdd = this.modelList[0]
        this.updateModelName(this.modelNameAdd)
      }
    },
    checkPasswordOK() {
      const store = useTymictStore()
      let delModel = store.ModelData.data[this.rowModelSelected[0].index]
      const table = "tymict_managerweb_model"
      let dynamoParams = {
        TableName:table,
        Key:{
          "model" : delModel.model,
          "option" : delModel.option
        }
      }
      // console.log(store.ModelData.data[this.rowModelSelected[0].index])
      let self = this
      store.docClient.delete(dynamoParams, function(err, data) {
        if (err) {
          console.log(JSON.stringify(err, undefined, 2));
        } else  {
          self.unusedParam(data)
          store.getModelInfo()
        }
      })
    },
    checkPasswordFailed() {
      TymCommon.Toast(this.$t('noticeView.deleteNoticeMsg2'))
    },
    delModelConfirmed() {
      try {
        if(!TymCommon.isEmpty(this.pwBeforeDelUser)) {
          TymAws.checkPassword(this.pwBeforeDelUser, this.checkPasswordOK, this.checkPasswordFailed)
        }
      } catch(ex) {
        console.log(ex)
      }
    },
    deleteModel() {
      if(this.rowModelSelected.length < 1) {
        TymCommon.Toast('삭제 모델을 먼저 선택하세요.')
        return
      }

      this.hideDelUserPW = true
      this.pwBeforeDelUser = ''
      this.delModelDlg = true
      this.dlgModel = this.rowModelSelected[0].model
      this.dlgOption = this.rowModelSelected[0].option
      this.dlgMsg = this.$t('group.questionDelete') + '?'
    },
    updateConsumables() {
      const store = useTymictStore()
      let modelName = this.isAddModel ? this.modelNameAdd : this.modelName
      let modelOption = this.isAddModel ? this.modelOptionAdd : this.modelOption

      /*
      let dynamoParams = {
        TableName:"tymict_managerweb_model",
        Key:{
            "model" : modelName,
            "option" : modelOption,
        },
        UpdateExpression:"set \
            citem01_eb = :oe, citem01_sn = :os, citem01_cf = :of, citem01_cc = :oc, \
            citem02_eb = :ae, citem02_sn = :as, citem02_cf = :af, citem02_cc = :ac, \
            citem03_eb = :be, citem03_sn = :bs, citem03_cf = :bf, citem03_cc = :bc, \
            citem04_eb = :ce, citem04_sn = :cs, citem04_cf = :cf, citem04_cc = :cc, \
            citem05_eb = :me, citem05_sn = :ms, citem05_cf = :mf, citem05_cc = :mc, \
            citem06_eb = :he, citem06_sn = :hs, citem06_cf = :hf, citem06_cc = :hc, \
            citem07_eb = :fe, citem07_sn = :fs, citem07_cf = :ff, citem07_cc = :fc", 
        ExpressionAttributeValues:{
            ":oe":oe,":os":os,":of":of,":oc":oc,
            ":ae":ae,":as":as,":af":af,":ac":ac,
            ":be":be,":bs":bs,":bf":bf,":bc":bc,
            ":ce":ce,":cs":cs,":cf":cf,":cc":cc,
            ":me":me,":ms":ms,":mf":mf,":mc":mc,
            ":he":he,":hs":hs,":hf":hf,":hc":hc,
            ":fe":fe,":fs":fs,":ff":ff,":fc":fc
        },
        ReturnValues:"ADD"
      };
      */

      let dynamoParams = {
        TableName:"tymict_managerweb_model",
        Key:{
            "model" : modelName,
            "option" : modelOption,
        },
        UpdateExpression:"set ",
        ExpressionAttributeValues:{ },
        ReturnValues:"ALL_NEW"
      }

      // update에 대한 콜백 - method 호출을 위해 따로 뺌
      const updateResult = (err, data) => {
        if (err) {
            console.log(JSON.stringify(err, undefined, 2));
        } else {
          console.log("updateConsumables OK :", data);
        }
        store.getModelInfo()
        this.cancelEdit()
      }

      /*
        icon : this.defaultIcons[i % 7],
        title : name,
        desc : obj.citems[i].sn,
        usage : obj.citems[i].enable,
        valuePeriod : obj.citems[i].cc,
        valueFirst : obj.citems[i].cf
      */

      for(let i = 0; i < this.consumableData.length; i++) {
        let itemStr = (i + 1).toLocaleString().padStart(2, '0')
        dynamoParams.UpdateExpression += "citem" + itemStr + '_eb = :en' + i + ', '
        dynamoParams.UpdateExpression += "citem" + itemStr + '_sn = :sn' + i + ', '
        dynamoParams.UpdateExpression += "citem" + itemStr + '_cf = :cf' + i + ', '
        dynamoParams.UpdateExpression += "citem" + itemStr + '_cc = :cc' + i + ', '
        dynamoParams.UpdateExpression += "citem" + itemStr + '_name = :nameKr' + i + ', '
        dynamoParams.UpdateExpression += "citem" + itemStr + '_name_en = :nameEn' + i
        if((i + 1) < this.consumableData.length)
          dynamoParams.UpdateExpression += ", "

        dynamoParams.ExpressionAttributeValues[':en' + i] = this.consumableData[i].usage
        dynamoParams.ExpressionAttributeValues[':sn' + i] = this.consumableData[i].partNo
        dynamoParams.ExpressionAttributeValues[':cf' + i] = this.consumableData[i].valueFirst
        dynamoParams.ExpressionAttributeValues[':cc' + i] = this.consumableData[i].valuePeriod
        dynamoParams.ExpressionAttributeValues[':nameKr' + i] = this.consumableData[i].titleKr
        dynamoParams.ExpressionAttributeValues[':nameEn' + i] = this.consumableData[i].titleEn
      }

      console.log('updateConsumables', dynamoParams)
      store.docClient.update(dynamoParams, updateResult);
    },

    // 소모품 편집 후 저장버튼 누를 때...
    saveConsumables() {
      console.log('saveConsumables')
      this.updateConsumables()
    },
    addConsumableToModel() {
      this.selectedConsumableIndex = this.consumableData.length

      this.consImgSrc = ''
      this.consEnable = true
      this.consCategory = ''
      this.consCategoryKr = ''
      this.consCategoryEn = ''
      this.consValueFirst = 100
      this.consValueCycle = 500
      this.consHasParts = false
      this.consPartsInfo = []

      this.consPartsNames = []
      this.consPartsNumbers = []

      this.catEditmode = true

      this.showEditConsPopupMode = 2
    },
    cancelEditConsPopup() {
      this.showEditConsPopupMode = 0
    },
    closeConsumableInfo() {
      // console.log('closeConsumableInfo :', this.$refs.consumablePopup)
      if(Array.isArray(this.$refs.consumablePopup)) {
        if(this.selectedConsumableIndex >= 0)
          this.$refs.consumablePopup[this.selectedConsumableIndex].hide()
      } else {
        this.$refs.consumablePopup.hide()
      }
      
    },
    showConsInfoPopup(index, item) {
      if(!this.isAddModel && !this.isEditConsumable) {
        //const store = useTymictStore()
        // console.log('showConsInfoPopup :', index, item)

        this.selectedConsumableIndex = index

        this.consImgPopupSrc = item.icon
        this.consImgSrc = TymConst.CONSUMABLE_ICONS[item.icon]
        this.consEnable = item.usage
        this.consCategory = this.getItemTitle(item)
        this.consValueFirst = item.valueFirst
        this.consValueCycle = item.valuePeriod
        this.consHasParts = item.hasParts
        if(item.hasParts)
          this.consPartsInfo = item.partsInfo
        else
          this.consPartsInfo = []
          
        // console.log('showConsInfoPopup VIEW :', index, item, this.consumableData[this.selectedConsumableIndex], this.consPartsInfo)
      }
    },
    clickOnConsumable(index, item) {
      if(this.isAddModel || this.isEditConsumable) {
        this.selectedConsumableIndex = index

        // console.log('clickOnConsumable :', index, item.icon, item)
        this.consImgPopupSrc = item.icon
        this.consImgSrc = TymConst.CONSUMABLE_ICONS[item.icon]
        this.consEnable = item.usage
        this.consCategory = this.getItemTitle(item)
        this.consCategoryKr = item.titleKr
        this.consCategoryEn = item.titleEn
        this.consValueFirst = item.valueFirst
        this.consValueCycle = item.valuePeriod
        this.consHasParts = item.hasParts

        this.consPartsNames = []
        this.consPartsNumbers = []
        if(item.hasParts) {
          // this.consPartsInfo = item.partsInfo
          for(let partIdx = 0; partIdx < item.partsInfo.length; partIdx++) {
            this.consPartsNames.push({
              editmode : false,
              name : item.partsInfo[partIdx].name
            })
            this.consPartsNumbers.push([])
            for(let partNo = 0; partNo < item.partsInfo[partIdx].numbers.length; partNo++) {
              this.consPartsNumbers[partIdx].push(item.partsInfo[partIdx].numbers[partNo])
            } 
          }
        } else{
//
        }
        this.consPartsInfo = []

        // console.log('clickOnConsumable EDIT :', item, this.consPartsNames, this.consPartsNumbers)
        this.showEditConsPopupMode = 1
      }
    },
    eraseConsPart(partIndex) {
      this.consPartsNames.splice(partIndex, 1)
      this.consPartsNumbers.splice(partIndex, 1)
      //console.log('eraseConsPart :', partIndex, this.consPartsNames, this.consPartsNumbers)
    },
    eraseConsPartNumber(partIndex, noIndex) {
      //console.log('eraseConsPartNumber :', partIndex, noIndex, JSON.stringify(this.consPartsNumbers))
      this.consPartsNumbers[partIndex].splice(noIndex, 1)
      //console.log('eraseConsPartNumber :', partIndex, noIndex, this.consPartsNumbers)
    },
    /*
    changeVal(val, idx, field) {
      console.log('changeVal :', val, idx, field)
      //this.consPartsInfo[idx].numbers[field] = val
    }
    */
    groupTreeSelected(tgt) {
      console.log('groupTreeSelected :', tgt)
    },
    beforeConsModelPopup(evt) {
      this.unusedParam(evt)
      for(let i = 0; i < this.modelTreeData.length; i++) {
        this.modelTreeData[i].expanded = false
        for(let j = 0; j < this.modelTreeData[i].children.length; j++) { 
          this.modelTreeData[i].children[j].expanded = false
          for(let k = 0; k < this.modelTreeData[i].children[j].children.length; k++) {
            this.modelTreeData[i].children[j].children[k].selected = false
          }
        }
      }
      this.modelTreeData[0].expanded = true
    },
    modelTreeSelected(typeIdx, modelIdx, optionIdx) {
      /*
      for(let i = 0; i < this.modelTreeList.length; i++) {
        for(let j = 0; j < this.modelTreeList[i].models.length; j++) {
          this.modelTreeList[i].models[j].selected = (i == typeIdx && j == modelIdx)
        }
      }
      */

      // 선택된 녀석만 표시 다르게
      for(let i = 0; i < this.modelTreeData.length; i++) {
        for(let j = 0; j < this.modelTreeData[i].children.length; j++) { 
          for(let k = 0; k < this.modelTreeData[i].children[j].children.length; k++) {
            this.modelTreeData[i].children[j].children[k].selected = ((i === typeIdx) && (j === modelIdx) && (k === optionIdx))
          }
        }
      }

      if(this.modelTreeData[typeIdx].children[modelIdx].children[optionIdx].selected)
        this.modelSelectedOrder = [typeIdx, modelIdx, optionIdx]
      else 
        this.modelSelectedOrder = []
    },
    addModel() {
      //let imgCtrl = document.getElementById('viewModelImage')
      //imgCtrl.src = require('../assets/company/title_logo.png')

      // 클리어
      this.selectModelImage = ''
      this.modelImageFilename = ''
      this.modelName = ''
      this.modelNameAdd = ''
      this.modelType = ''
      this.modelTypeAdd = ''
      this.modelDesc = ''
      this.modelHP = 0
      this.modelOption = ''
      this.modelOptionAdd = ''

      this.viewOptions.forEach(option => {
        option.Visible = true
      })
      this.forDevModel = false

      this.updateConsumable(null)

      this.modelTypeAdd = ''
      this.modelDescAdd = ''
      this.modelHPAdd = 0
      this.modelWeightAdd = 0
      this.modelUseSTAdd = false
      this.isAddModel = true
      setTimeout(() => {
        this.$refs.addModelTooltip.hide()
        this.updateDevType(this.modelTypeAdd)
      }, 150)
    },
    /// 기대 타입 변경시
    updateDevType(value) {
      const store = useTymictStore()
      this.modelTypeAdd = value
      this.modelList = []

      let mach = store.machineTree.Machine.find(x => x.Name == value)

      if(mach) {
        mach.Model.forEach(md => {
          this.modelList.push(md.Name)
        })
      }
      this.modelNameAdd = this.modelList[0]

      this.updateModelName(this.modelNameAdd)
    },
    /// 모델 선택시
    updateModelName(value) {
      const store = useTymictStore()
      this.optionList = []

      let machine = store.machineTree.Machine.find(x => x.Name == this.modelTypeAdd)
      if(machine) {
        let md = machine.Model.find(x => x.Name == value)
        //console.log('updateModelName :', value, machine, md)
        if(md) {
          this.optionList = Array.from(md.Option)
        }
      }
      
      this.modelNameAdd = value
      this.updateModelOption(this.optionList[0])
    },
    // 옵션변경시
    updateModelOption(value) {
      const store = useTymictStore()

      this.consumableData = []

      let found = store.ModelList.data.find(x => x.dtype == this.modelTypeAdd && x.dmodel == this.modelNameAdd)
      if(found) {
        this.modelOptionAdd = value

        found = store.ModelData.data.find(x => x.model == this.modelNameAdd && x.option == this.modelOptionAdd )
        if(found) {
          this.modelHPAdd = parseInt(found.hp)
          found.citems.forEach(item => {
            let iconIdx = 0
            let hasParts = false
            if(!TymCommon.isEmpty(item.sn)) {
              if(item.sn.length > 0) {
                hasParts = true
                iconIdx = item.sn[0].icon
              }
            }
            this.consumableData.push({
              icon : iconIdx,
              titleKr : item.name[0],
              titleEn : item.name[1],
              usage : item.enable,
              valuePeriod : item.cf,
              valueFirst : item.cc,
              hasParts : hasParts,
              partsInfo : item.sn,
            })
            /*
            this.consumableData.push({
              icon : iconIdx,
              titleKr : item.name[0],
              titleEn : item.name[1],
              hasParts : isJSON,
              partsInfo : item.sn,
              usage : item.enable,
              valueFirst : item.cf,
              valuePeriod : item.cc
            })
            */
          })
        }
      }
    },
    // 모델 선택해서 팝업 사라질 때
    // option : 0 - cancel, 1 - confirm
    hideConsModelPopup(option) {
      if(option == 1) {
        // Confirm
        this.consumableData = []
        if(this.modelSelectedOrder.length == 3) {
          const store = useTymictStore()

          // 기대타입, 모델, 옵션
          let i = this.modelSelectedOrder[0]
          let j = this.modelSelectedOrder[1]
          let k = this.modelSelectedOrder[2]

          const  modelOption = this.modelTreeData[i].children[j].children[k]
          let foundModel = store.ModelData.data.find(x => x.model == modelOption.model && x.option == modelOption.option )
          if(foundModel) {
            if(!TymCommon.isEmpty(foundModel.citems)) {
              foundModel.citems.forEach(item => {
                let isJSON = false
                let iconIdx = 0
                if(!TymCommon.isEmpty(item.sn)) {
                  try {
                    if(Array.isArray(item.sn)) {
                      let abc = JSON.stringify(item.sn)
                      if((abc.length > 2) && !TymCommon.isEmpty(abc)) {
                        isJSON = true
                        iconIdx = item.sn[0].icon
                      }
                    }
                  } catch(e) {
                    console.log('JSON.parse ERROR :', e)
                  }
                }

                this.consumableData.push({
                  icon : iconIdx,
                  titleKr : item.name[0],
                  titleEn : item.name[1],
                  hasParts : isJSON,
                  partsInfo : item.sn,
                  usage : item.enable,
                  valueFirst : item.cf,
                  valuePeriod : item.cc
                })
              })
            }
          } else if(this.modelTreeData[i].children[j].children[k].selected) {
            const findModel = store.ModelList.data.find(x => x.dmodel == modelOption.model)
            if(findModel) {
              if(findModel.doption && findModel.default_citem) {
                let optIdx = findModel.doption.findIndex(x => x == modelOption.option)
                if(optIdx >= 0) {
                  if(!TymCommon.isEmpty(findModel.default_citem[optIdx])) {
                    let srcCons = findModel.default_citem[optIdx]
                    for(let idx = 0; idx < srcCons.name.length; idx++)  {
                      this.consumableData.push({
                        icon : idx % TymConst.CONSUMABLE_ICONS.length,
                        titleKr : srcCons.name[idx][0],
                        titleEn : srcCons.name[idx][1],
                        hasParts : false,
                        partsInfo : '',
                        usage : true,
                        valueFirst : srcCons.citem_cycle[idx][0],
                        valuePeriod : srcCons.citem_cycle[idx][1],
                      })
                    }
                  }
                }
              }
            }
          }
        }
      }

      this.$refs.consModelPopup.hide()
    },
    hideConsImgPopup(option) {
      if(option == 1) {
        this.consImgSrc = this.consImages[this.consImgPopupSrc]
      }
      this.$refs.iconSelectPopup.hide()
    },
    beforeShowConsImgPopup(evt) {
      this.unusedParam(evt)
      // console.log('beforeShowConsImgPopup :', this.consImgPopupSrc)
      // this.consImgPopupSrc = 0
    },
    // 소모품 하나 삭제
    deleteConsumable(index) {
      this.consumableData.splice(index, 1)
      console.log('deleteConsumable', index, this.consumableData)
    },
    cancelAddPart() {
      console.log('cancelAddPart')
      this.addingPartsNameKR = ''
      this.addingPartsNameEN = ''
      this.addPartNumberData = []
      this.addedPartNumber = ''
      this.addingParts = false
    },
    // 파트 추가중 파트넘버 삭제
    eraseAddedPartNumber(index) {
      this.addPartNumberData.splice(index, 1)
    },
    // 파트 추가중 새 파트넘버 추가
    addNewPartNoToPart(part) {
      console.log('addNewPartNoToPart :', part)

      if(TymCommon.isEmpty(part)) {
        if(TymCommon.isEmpty(this.addedPartNumber)) {
          TymCommon.Toast('Empty Data')
          return
        }
        this.addPartNumberData.push(this.addedPartNumber)
        this.addedPartNumber = ''
        this.addNewPartNumber = ''
        this.addingPartPartNo = false
      } else {
        if(TymCommon.isEmpty(this.addNewPartNumber)) {
          TymCommon.Toast('Empty Data')
          return
        }
        console.log(part, this.addNewPartNumber, this.consPartsNumbers)
        this.consPartsNumbers[part].push(this.addNewPartNumber)
        this.addNewPartNumber = ''
        this.indexOfPartToAddPartNo = -1
      }
    },
    // 팝업된 소모품에 부품을 추가한다.
    addPartToCons() {
      if(TymCommon.isEmpty(this.addingPartsNameKR) || TymCommon.isEmpty(this.addingPartsNameEN)) {
        TymCommon.Toast('Enter both part names')
        return
      }
      if(this.addPartNumberData.length < 1) {
        TymCommon.Toast('Enter at least one part number')
        return
      }
      if(this.consPartsNumbers == null)
        this.consPartsNumbers = []

      if(!TymCommon.isEmpty(this.addedPartNumber)) {
        this.addPartNumberData.push(this.addedPartNumber)
      }

      this.consPartsNames.push({
        editmode : false,
        name : [this.addingPartsNameKR, this.addingPartsNameEN ]
      })

      let partIdx = this.consPartsNames.length - 1
      this.consPartsNumbers.push([])
      this.addPartNumberData.forEach(partNo => {
        this.consPartsNumbers[partIdx].push(partNo)
      })

      // console.log('addPartToCons #2', this.consPartsInfo, this.consPartsNumbers)

      this.addPartNumberData = []
      this.addedPartNumber = ''
      this.addingParts = false
    },
    // 수정, 추가한 소모품 하나 저장하기 - 실제 DB에 저장하는 건 아니고~ 팝업의 내용을 변수로~
    saveOneCons() {
      if(TymCommon.isEmpty(this.consCategoryKr) || TymCommon.isEmpty(this.consCategoryKr)) {
        TymCommon.Toast('Enter both category names')
        return
      }
      if(this.consPartsNames.length < 1) {
        TymCommon.Toast('Enter at least one part.')
        return
      }
      if(this.consPartsNumbers[0].length < 1) {
        TymCommon.Toast('Enter at least one part number.')
        return
      }

      if(this.selectedConsumableIndex == this.consumableData.length){
        // 추가
        this.consumableData.push({})
        /*
        this.consumableData.push({
            icon : i % TymConst.CONSUMABLE_ICONS.length,
            titleKr : nameKr,
            titleEn : nameEn,
            hasParts : isJSON,
            partsInfo : isJSON ? obj.citems[i].sn : '',
            usage : obj.citems[i].enable,
            valuePeriod : obj.citems[i].cc,
            valueFirst : obj.citems[i].cf
          })
          */
      } else {
        // 변경
      }
      let consSN = []
      for(let idx = 0; idx < this.consPartsNames.length; idx++) {
        let srcPart = this.consPartsNames[idx]
        let onePart = {
          name : srcPart.name,
          icon : this.consImgPopupSrc,
          numbers : []
        }
        this.consPartsNumbers[idx].forEach(partNo => {
          onePart.numbers.push(partNo)
        })
        consSN.push(onePart)
      }
      this.consumableData[this.selectedConsumableIndex].icon = this.consImgPopupSrc
      this.consumableData[this.selectedConsumableIndex].usage = this.consEnable
      this.consumableData[this.selectedConsumableIndex].titleKr = this.consCategoryKr
      this.consumableData[this.selectedConsumableIndex].titleEn = this.consCategoryEn
      this.consumableData[this.selectedConsumableIndex].valueFirst = this.consValueFirst
      this.consumableData[this.selectedConsumableIndex].valuePeriod = this.consValueCycle
      this.consumableData[this.selectedConsumableIndex].hasParts = true
      this.consumableData[this.selectedConsumableIndex].partsInfo = consSN
      
      this.showEditConsPopupMode = 0
      // console.log('saveOneCons :', this.consImgPopupSrc, this.consPartsNames, this.consPartsNumbers, this.consumableData)
    },
    saveModel() {
      const store = useTymictStore()
      let jsonValue = null

      if(this.isAddModel) {
        let found = store.ModelData.data.find(x => (x.model == this.modelNameAdd) && (x.option == this.modelOptionAdd))
        if(found) {
          TymCommon.Toast(this.$t('equipment.alreadyRegistered'))
          return;
        }
        if(TymCommon.isEmpty(this.modelTypeAdd)) {
          // TymCommon.Toast('Empty machine type.')
          TymCommon.Toast(this.$t('warning.inputMachineType'))
          return
        }
        if(TymCommon.isEmpty(this.modelNameAdd)) {
          //TymCommon.Toast('Empty model name.')
          TymCommon.Toast(this.$t('warning.inputModel'))
          return
        }
        if(TymCommon.isEmpty(this.modelOptionAdd)) {
          // TymCommon.Toast('Empty option name.')
          TymCommon.Toast(this.$t('warning.inputOption'))
          return
        }
        if(this.consumableData.length < 1) {
          TymCommon.Toast(this.$t('warning.oneMoreCons'))
          return
        }

        jsonValue = {
          "model" : this.modelNameAdd,
          "option" : this.modelOptionAdd,
          "info" : {
            "hp" : this.modelHPAdd,
            "type" : this.modelTypeAdd,
          },
          "usee_st" : this.modelUseSTAdd,
          "citem" : []
        }

        if(!TymCommon.isEmpty(this.selectModelImage)) {
          if(!TymCommon.isEmpty(this.selectModelImage.name)) {
            jsonValue.info['logo'] = 'modelImage/' + this.selectModelImage.name
          }
        }
      } else {
        const selModel = store.ModelData.data[this.rowModelSelected[0].index]
        if(TymConst.IS_DEVELOPMENT) {
          console.log('saveModel(EDIT) :', this.rowModelSelected[0], selModel)
        }

        let logo = TymCommon.isEmpty(selModel.logo) ? '' : selModel.logo
        jsonValue = {
          "model" : selModel.model,
          "option" : selModel.option,
          "info" : {
            "hp" : selModel.hp,
            "logo" : logo,
            "type" : selModel.type,
          },
          "use_st" : TymCommon.isEmpty(this.rowModelSelected[0].use_st) ? false : this.rowModelSelected[0].use_st,
          "citem" : []
        }
      }

      for(let consIdx = 0; consIdx < this.consumableData.length; consIdx++) {
        let oneSrc = this.consumableData[consIdx]
        let oneCons = {
          "icon" : oneSrc.icon,
          "category" : [ oneSrc.titleKr, oneSrc.titleEn ],
          "firstcitem" : oneSrc.valueFirst,
          "cyclecitem" : oneSrc.valuePeriod,
          "check" : oneSrc.usage,
          "parts" : []
        }
        // console.log(consIdx, oneSrc)
        if(!TymCommon.isEmpty(oneSrc.partsInfo)) {
          oneSrc.partsInfo.forEach(partInfo => {
            oneCons.parts.push(partInfo)
          })
        }

        jsonValue.citem.push(oneCons)
      }

      jsonValue.info.development = this.forDevModel
      let viewKeys = Object.keys(TymConst.VIEWOPTIONS)
      if(TymCommon.isEmpty(jsonValue.info.viewOptions)) {
        jsonValue.info.viewOptions = {}
      }
      for(let idx = 0; idx < TymConst.VIEWOPTIONS.Count; idx++) {
        jsonValue.info.viewOptions[viewKeys[idx]] = this.viewOptions[idx].Visible
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('------ IS DEV ADDMODEL ------ ')
        console.log('JSON :', jsonValue)
      }

      TymAws.addUpdateModel(jsonValue)
        .then(data => {
          // console.log('addUpdateModel :', data)
          this.unusedParam(data)
          this.cancelEdit()
          this.uploadFile(this.selectModelImage)
          store.getModelInfo()
          // this.isAddModel = false
          // this.isEditConsumable = false
        })
        .catch(er => {
          console.log('add or update FAIL :', er)
        })
    },

    onShowExportToExcel() {
      this.makingExcel = true;

      let now = new Date()
      this.excelFilename = 'model_' + now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += '_' + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += '.xlsx'
      this.excelType = this.catSelected
      this.excelKeyword = this.tableFilter

      setTimeout(() => {
        try {
          this.sheets = []
          try {
            this.makeExcelData()
          } catch(ex) {
            console.log(ex)
          }
          setTimeout(() => {
            this.makingExcel = false;
          }, 100)
        } catch(ex) {
          console.log(ex)
          this.makingExcel = false;
        }
      }, 1)
    },
    makeExcelData() {
      const store = useTymictStore()

      let filtered = []

      if(this.catSelected == this.getCategory[0])
        filtered = store.ModelData.data
      else
        filtered = store.ModelData.data.filter(one => (this.catSelected == one.type) && ((one.model.indexOf(this.tableFilter) >= 0) || (one.option.indexOf(this.tableFilter) >= 0)))
      //console.log('model (Type) :', filtered)

      if(!TymCommon.isEmpty(this.tableFilter)) {
        let found2 = filtered.filter(row =>
            (row.model.toUpperCase().includes(this.tableFilter.toUpperCase()) )
            || (row.option.toUpperCase().includes(this.tableFilter.toUpperCase()) ) )
        if(!TymCommon.isEmpty(found2)) {
          filtered = found2
        } else {
          filtered = []
        }
      }
      filtered.sort((a, b) => {
        let ahp = Number(a.hp), bhp = Number(b.hp)
        if(isNaN(ahp) && !isNaN(bhp)) {
          return 1
        } else if(!isNaN(ahp) && isNaN(bhp)) {
          return -11
        } else if(!isNaN(ahp) && !isNaN(bhp)) {
          if(ahp > bhp)
            return 1
          else if(ahp < bhp)
            return -1 
        }
        let cmpModel = a.model.localeCompare(b.model)
        if(cmpModel == 0) {
          return a.option.localeCompare(b.option)
        } else {
          return cmpModel
        }
      })
      //console.log('filter (sort by model) :', filtered)

      // 전체 긴급알람 리스트 출력
      let xlsRow1 = []
      xlsRow1.push('No', '모델명', '옵션', '마력(HP)', '타입', '소모품')
      let othRows = []
      let rowNo = 1

      filtered.forEach(one => {
        let firstRow = [], secondRow = [], thirdRow = []

        firstRow = [rowNo, one.model, one.option, one.hp, one.type, '소모품 항목', ]
        secondRow = ['', '', '', '', '', '최초 교체(h)', ]
        thirdRow = ['', '', '', '', '', '교체 주기(h)', ]

        one.citems.forEach(item => {
          firstRow.push(item.name[store.connectServer])
          secondRow.push(item.cf)
          thirdRow.push(item.cc)
        })
        othRows.push(firstRow)
        othRows.push(secondRow)
        othRows.push(thirdRow)

        rowNo++
      })
      othRows.splice(0, 0, xlsRow1)

      let fnFooter = ''
      if(!TymCommon.isEmpty(this.catSelected)) {
        if(this.catSelected != this.getCategory[0])
          fnFooter = ' ' + this.catSelected
      }
      fnFooter = fnFooter.replace('/', '.')

      this.sheets.push(
        {
          name: '모델목록' + fnFooter,
          data: othRows
        }
      )

    },
    saveToExcelFile() {
      let desc = {
        filename: this.excelFilename,
        type: this.excelType == this.getCategory[0] ? '모든타입' : this.excelType,
        keyword: this.excelKeyword
      }
      TymAws.writeAction('모델', '다운로드', desc)

      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 500)
    },
    // 로고이미지 선택 완료시
    fileSelectionOK(option) {
      this.unusedParam(option)
      this.modelImageFilename = this.selectModelImage.name
      this.logoImgUrl = URL.createObjectURL(this.selectModelImage)
    },
    uploadFile(data) {
      if(!TymCommon.isEmpty(data)) {
        const store = useTymictStore()

        let folder = 'modelImage/'
        var upload = new AWS.S3.ManagedUpload({
          params : {
            Bucket: TymConst.BUCKET_IMAGE_NAME,
            Key: folder + data.name,
            Body: data, // this.selectedFileToAttachech,
            region: store.cognito[store.connectServer].region
          }
        })

        // let self = this
        upload.promise().then(data => {
          console.log('upload OK :', data.ETag)
        }).catch(er => {
          console.log('upload FAIL :', er)
        })
      }
    },
    editModelInfo() {
      console.log('editModelInfo :', this.isEditModelInfo, this.isEditConsumable)

      this.isEditModelInfo = true
    },
    cancelEditModelInfo() {
      this.isEditModelInfo = false
      this.changeSelection()
    },
    saveModelInfo() {
      const store = useTymictStore()
      const selModel = store.ModelData.data[this.rowModelSelected[0].index]
      if(TymConst.IS_DEVELOPMENT) {
        console.log('saveModelInfo(EDIT) :', this.modelImageFilename, selModel)
      }

      let logo = ''
      if(TymCommon.isEmpty(this.selectModelImage.name))
        logo = this.rowModelSelected[0].logo
      else
        logo = 'modelImage/' + this.selectModelImage.name

      let jsonValue = {
        "model" : selModel.model,
        "option" : selModel.option,
        "info" : {
          "hp" : selModel.hp,
          "logo" : logo,
          "type" : selModel.type,
        },
        "use_st" : TymCommon.isEmpty(this.rowModelSelected[0].use_st) ? false : this.rowModelSelected[0].use_st,
        "citem" : []
      }

      for(let consIdx = 0; consIdx < this.consumableData.length; consIdx++) {
        let oneSrc = this.consumableData[consIdx]
        let oneCons = {
          "icon" : oneSrc.icon,
          "category" : [ oneSrc.titleKr, oneSrc.titleEn ],
          "firstcitem" : oneSrc.valueFirst,
          "cyclecitem" : oneSrc.valuePeriod,
          "check" : oneSrc.usage,
          "parts" : []
        }

        if(!TymCommon.isEmpty(oneSrc.partsInfo)) {
          oneSrc.partsInfo.forEach(partInfo => {
            oneCons.parts.push(partInfo)
          })
        }

        jsonValue.citem.push(oneCons)
      }

      jsonValue.info.development = this.forDevModel
      let viewKeys = Object.keys(TymConst.VIEWOPTIONS)
      if(TymCommon.isEmpty(jsonValue.info.viewOptions)) {
        jsonValue.info.viewOptions = {}
      }
      for(let idx = 0; idx < TymConst.VIEWOPTIONS.Count; idx++) {
        jsonValue.info.viewOptions[viewKeys[idx]] = this.viewOptions[idx].Visible
      }

      if(TymConst.IS_DEVELOPMENT) {
        console.log('------ IS DEV UPDATE ------ ')
        console.log('JSON :', jsonValue)
      }

      TymAws.addUpdateModel(jsonValue)
        .then(data => {
          console.log('addUpdateModelInfo :', data)
          this.unusedParam(data)
          this.cancelEdit()
          this.uploadFile(this.selectModelImage)
          store.getModelInfo()
          this.isEditModelInfo = false
        })
        .catch(er => {
          console.log('add or update FAIL :', er)
          this.isEditModelInfo = false
        })

    }
  }
})
</script>
