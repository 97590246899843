<template>
  <div class="column" :style='isLandscape ? "width:100%;height:100%;" : "width:100%;height:100%;"' style="background-color:#F2F2F2">
    <div class="col-auto column items-center justify-start" style="position:relative;border:1px solid #ebedf2;border-radius:3px;">
      <table class="col" style="width:100%;background-color:white;padding:12px;border-radius:3px">
        <tr>
          <td width="100%">
            <!-- 제목 -->
            <div class="col-auto" style="font-size:18px;font-weight:500;"
                 :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('statView.consumables')}}</div>
          </td>
        </tr>
        <tr>
          <td width="100%">
            <div class="col row items-center justify-between no-wrap" style="">
              <div class="col-auto row items-center justify-start ">
                <!-- 조직 선택 -->
                <div class="col-auto row grpBtn items-center justify-between"
                     style="min-width:180px;width:auto;height:40px;padding:0 6px 0 0;">
                  <div class="col row items-center justify-between" style="cursor:pointer;padding:0 0 0 12px;height:100%;" @click="groupPopupDisplayed = !groupPopupDisplayed">
                    <q-popup-proxy ref="groupPopup" style="padding:3px;" @hide="groupPopupDisplayed=false">
                      <div class="column" style="min-width:250px;height:250px;">
                        <div class="col row" style="border:1px solid #cccccc;padding:3px;background-color:white;">
                          <q-scroll-area class="col row" style="width:auto;height:100%;">
                            <q-tree
                              :nodes="groupTreeData"
                              dense
                              no-selection-unset
                              selected-color="red-7"
                              class="col"
                              node-key="uuid"
                              v-model:expanded="expandedTree"
                              v-model:selected="selectedTree"
                              @update:selected="groupTreeSelected"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </q-popup-proxy>
                    <div class="col">
                      {{selectedTreeLabel}}
                    </div>
                    <q-icon class="col-auto" dense  color="grey-8" :name='groupPopupDisplayed ? "arrow_drop_up" : "arrow_drop_down"' size="24px">
                    </q-icon>
                  </div>
                  <!--
                  <q-btn class="col-auto" dense flat rounded color="grey-7" icon="close" size="md" @click="clearGroupData"/>
                  -->
                </div>

                <!-- 년도 -->
                <q-select dense outlined options-dense v-model="selectedYear" :options="validYears"
                          class="col-auto" color="red-7"
                          hide-bottom-space
                          style="font-size:14px;min-width:100px;margin:0 0 0 6px;">
                </q-select>
                <!-- 월 선택 -->
                <q-select dense outlined options-dense v-model="selectedMonth" :options="getMonthTitle"
                          class="col-auto" color="red-7"
                          hide-bottom-space
                          style="font-size:14px;min-width:100px;margin:0 0 0 6px;">
                </q-select>

                <!-- 기기타입 -->
                <q-select dense outlined v-model="catSelected" :options="getCategory"
                          class="col-auto" color="red-7"
                          hide-bottom-space options-dense
                          @update:model-value="changedCategory"
                          style="font-size:14px;min-width:160px;margin:0 0 0 6px;">
                </q-select>

                <!-- 모델 -->
                <q-select dense outlined v-model="modelSelected" :options="modelsData"
                          class="col-auto" color="red-7"
                          hide-bottom-space options-dense
                          @update:model-value="changedErroPart"
                          style="font-size:14px;min-width:160px;margin:0 0 0 6px;">
                </q-select>

                <div class="col row items-center justify-center">
                  <q-btn class="col" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')"
                        style="width:80px;height:40px;margin:0 0 0 6px;" @click="searchData()">
                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.searchData') }}</q-tooltip>
                  </q-btn>
                </div>
              </div>
              <div class="col-auto row">
                <q-btn flat dense @click="downloadToExcel" v-show="canDownloadToExcel">
                  <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                  <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;"/>
                  <q-popup-proxy ref="excelPopup" style="width:360px;height:240px;background-color: #01010130;border-radius: 3px;position: relative;"
                                  @before-show="onShowExportToExcel">
                    <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                      <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                        <div class="col-auto row items-center justify-center">
                          <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                            {{ $t('common.toExcel') }}
                          </div>
                        </div>
                        <div class="col column" v-show="hasExcelData">
                          <div class="col row items-center justify-start" style="width:100%;margin:0;">
                            <table class="col" style="width:100%;margin:0;">
                              <tr style="">
                                <td width=30% style="text-align: right;">{{ $t('common.filename') }}</td>
                                <td width="12px" style=""></td>
                                <td style="">{{ excelFilename }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.from') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelFrom }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.to') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelTo }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('dashboardView.dealer') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelDealer }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.machineType') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelType }}</td>
                              </tr>
                            </table>
                          </div>
                          <xlsx-workbook class="col-auto row items-center justify-center">
                            <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                            <xlsx-download :filename="excelFilename">
                              <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveToExcelFile">{{ $t('common.download') }}</button>
                            </xlsx-download>
                          </xlsx-workbook>
                        </div>
                        <div class="col row items-center justify-center" v-show="!hasExcelData" style="width:100%;height:100%;">
                          <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:bold;color:#eb0028">{{ $t('warning.nodata')}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row items-center justify-center" v-show="makingExcel"
                        style="position:absolute;z-index:3;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                      <q-spinner color="red-7" size="70px" thickness="1.5"/>
                    </div>
                  </q-popup-proxy>
                </q-btn>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- 차트들 -->
    <div class="col row" style="height:100%;margin:12px 0 0 0;background-color: F2F2F2;">
      <div class="col row" style="margin:0px;">
        <q-splitter v-model="horizSplitter" :limits="[20, 80]" 
                    horizontal
                    separator-style="height:12px;background-color:red;"
                    class="col"
                    separator-class="transparent">
          <template v-slot:before>
            <!-- 해상도에 따라 가로<->세로 변경 스플리터 -->
            <q-splitter v-model="upSplitter" :limits="[20, 80]" 
                        :separator-style='!isLandscape ? "height:12px" : "width:12px"'
                        :horizontal="!isLandscape"
                        separator-class="transparent">
              <template v-slot:before>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 지역별로 변경... 변수는 그대로 사용할까?-->
                    <div class="col column">
                      <div class="col-auto row" style="height:42px;">
                        <div class="col row " style="margin:0 0 4px 0;">
                          <div class="col-auto row items-center">
                            <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500;'" style="font-size:16px;">{{ titleForDealer }}</div>
                            <q-separator color="grey-7" vertical style="margin:9px 6px 9px 12px;" v-show="termByDealer.length > 0"></q-separator>
                            <div class="col-auto" style="font-family:Prometo;font-size:15px;color:#eb0028" v-show="termByDealer.length > 0">&nbsp;{{ termByDealer }}</div>
                          </div>
                        </div>
                        <div class="col-auto row items-center">
                          <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:15px;" v-show="countByBranch > 0">{{ $t("statCons.consCount") }}&nbsp;:&nbsp;{{ countByBranch }}</div>
                        </div>
                      </div>

                      <div id="dealerContainer" class="col row q-mt-xs q-pa-sm items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-scroll-area class="col row items-center justify-center" style="position:absolute;left:6px;top:6px;right:6px;bottom:6px;border-radius: 3px;">
                          <div ref="refDealer" id="dealerChart"></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeDealer"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:separator>
                <div style="background-color:#f2f2f2;" class="row items-center justify-center">
                  <div :class='isLandscape ? "vertSeparator" : "horizSeparator"' style=""></div>
                </div>
              </template>
              <template v-slot:after>
                <div class="row cellBackground">
                  <div class="col row" >
                    <!-- 기종/모델별 (우측 상단) -->
                    <div class="col column" style="">
                      <div class="col-auto row items-center justify-between" style="height:42px;">
                        <div class="col-auto row items-center justify-start">
                          <div class="col-auto row items-center justify-start">
                            <q-select dense outlined options-dense v-model="selectedCategoryPartsTab" :options="selectCategoryPartsTabs"
                                    class="col-auto" color="red-7"
                                    hide-bottom-space
                                    @update:model-value="changedModelOptionTab"
                                    style="color:black;margin:-6px 0 0 0;">
                              <template v-slot:selected>
                                <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500;'" style="font-size:16px;">
                                  {{selectedCategoryPartsTab}}
                                </div>
                              </template>
                            </q-select>
                          </div>
                        </div>
                        <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:16px;" v-show="countByModel > 0">{{ $t("statCons.consCount") }}&nbsp;:&nbsp;{{ countByModel }}</div>
                      </div>
                      <div id="modelOptionContainer" class="col row q-mt-xs q-pa-sm items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                        <q-scroll-area class="col row items-center justify-center" style="position:absolute;left:6px;top:6px;right:6px;bottom:6px;border-radius: 3px;">
                          <div ref="refModelOption" id="modelOptionChart" class="col-auto" style=""></div>
                        </q-scroll-area>
                        <q-resize-observer @resize="resizeModelOption"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </q-splitter>
          </template>
          <template v-slot:separator>
            <div style="background-color:#f2f2f2;width:100%;height:12px;" class="row items-center justify-center">
              <div class="horizSeparator" style=""></div>
            </div>
          </template>
          <template v-slot:after>
            <div class="row cellBackground">
              <div class="col row" >
                <!-- 모델 - 카테고리/부품명 (하단) -->
                <div class="col column" style="">
                  <div class="col-auto row items-center justify-between" style="height:42px;">
                    <div class="col-auto row items-center justify-start">
                      <div class="col-auto row items-center justify-start">
                        <q-select dense outlined options-dense v-model="selectedDealerModelOptionTab" :options="selectDealerCategoryPartsTabs"
                                  class="col-auto" color="red-7"
                                  hide-bottom-space
                                  @update:model-value="changedDealerCategoryPartsTab"
                                  style="color:black;margin:-6px 0 0 0;">
                              <template v-slot:selected>
                                <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;':'font-family:Prometo;font-weight:500;'" style="font-size:16px;">
                                  {{selectedDealerModelOptionTab}}
                                </div>
                              </template>
                        </q-select>
                      </div>
                    </div>
                    <div class="col-auto" :style="isKorean ? 'font-family:Noto Sans KR;':'font-family:Prometo;'" style="font-size:16px;" v-show="countByDealerCat > 0">{{ $t("statCons.consCount") }}&nbsp;:&nbsp;{{ countByDealerCat }}</div>
                  </div>
                  <div class="col row q-mt-xs items-center justify-center" style="position:relative;border:1px solid #dddddd;border-radius: 3px;">
                    <q-table class="col"
                            ref="modelTable"
                            style="position:absolute;left:0;top:0;right:0;bottom:0"
                            dense flat  virtual-scroll hide-no-data
                            separator="none"
                            :columns="tableColumns"
                            :visible-columns="tableVisibleColumns"
                            :rows="tableRows"
                            :rows-per-page-options="[0]"
                            hide-pagination
                            row-key="index">
                      <template v-slot:header="props">
                        <q-th no-caps style="height:auto;background-color: white;border-right:1px solid #e0e0e0;" class="tableHeader"
                              :style='col.name=="col0" ? "position:sticky;z-index:4;left:0":""'
                              v-for="col in props.cols" :key="col.name" :props="props">
                          {{ col.label }}
                        </q-th>
                      </template>
                      <template v-slot:body="props">
                        <q-tr :props="props" :style='props.row.index % 2 == 0 ? "background-color:#f8f8f8;" : "background-color:white;"'>
                          <q-td v-for="col in props.cols" :key="col.name" :props="props" style="border-bottom:1px solid #e0e0e0;border-right:1px solid #e0e0e0;"
                                :style='col.name=="col0" ? "font-weight:500;position:sticky;z-index:2;left:0;":"font-weight:400;"'>
                            {{ col.value }}
                          </q-td>
                        </q-tr>
                      </template>
                    </q-table>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </q-splitter>
      </div>

      <div class="row items-center justify-center" v-show="loadingData"
          style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
        <q-spinner-tail dense color="red-7" size="100px"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.grpBtn {
  height:40px;
  border-radius: 3px;
  border:1px solid #cccccc;
}
.grpBtn:hover {
  height:40px;
  border-radius: 3px;
  border:1px solid black;
}
.grpBtn:active {
  height:40px;
  border-radius: 3px;
  border:2px solid #eb0028;
}
.cellBackground {
  width:100%;
  height:100%;
  background-color:white;
  border:1px solid #ebedf2;
  border-radius:3px;
  padding:12px
}
.horizSeparator {
  width:32px;
  height:4px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.vertSeparator {
  width:4px;
  height:32px;
  background-color:#BBBBBB;
  border-radius: 3px;
}
.tableHeader {
  position: sticky;
  z-index: 3;
  top:0;
  background-color: white;
  font-size:14px;
  font-weight: 500;
  border-bottom: 1px solid #E0E0E0;
}

</style>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTymictStore } from '@/store/tymict'
import TymCommon from '@/js/tymcommon.js'
import moment from 'moment-timezone'
import TymAws from '@/js/tymaws.js'
import TymConst from '@/js/tymconstants.js'
import { XlsxWorkbook, XlsxSheet, XlsxDownload  } from 'vue3-xlsx';

export default ({
  props : {
    kind : ref(0)
  },
  components : {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data() {
    return {
    }
  },
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean()  {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    getCategory() {
      const store = useTymictStore()
      return store.getMachineTypes()
    },
    getMonthTitle() {
      const i18n=useI18n()

      let result = []
      result.push(i18n.t('common.month'))
      for(let i = 1; i <= 12; i++) {
        result.push(i18n.t('yearMonth.' + i.toString()))
      }
      return result
    },
    machineCount() {
      const store = useTymictStore()
      return store.machineLocaInfo.length
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return store.canDownloadToExcel()
    }
  },
  setup() {
    const i18n=useI18n()

    return {
      horizSplitter : ref(50),
      upSplitter : ref(49.75),
      downSplitter : ref(49.75),

      loadingData : ref(false),   // 데이터 로딩중
      groupPopupDisplayed : ref(false),

      selectedMonth : ref(i18n.t('common.month')),

      groupTreeData : ref([]),
      expandedTree : ref([]),
      selectedTree : ref(""),
      selectedTreeLabel : ref(""),

      modelsData : ref([]),    // 모델 종류들

      viewYear : ref(0),
      selectedYear : ref(''/*i18n.t('common.all')*/),
      validYears : ref([]),
      catSelected : ref(i18n.t('common.type')),
      modelSelected : ref(i18n.t('common.model')),
      
      titleForDealer : ref(''),
      termByDealer : ref(''),
      countByBranch : ref(0),

      chartForCategory : ref(true),
      selectCategoryPartsTabs : ref([i18n.t('statCons.consbycategory'), i18n.t('statCons.consbypart')]),
      selectedCategoryPartsTab : ref(i18n.t('statCons.consbycategory')),
      countByModel : ref(0),

      tableForCategory : ref(true),
      selectDealerCategoryPartsTabs : ref([i18n.t('statCons.consbymodelcategory'), i18n.t('statCons.consbymodelpart')]),
      selectedDealerModelOptionTab : ref(i18n.t('statCons.consbymodelcategory')),

      yearlyLayout : ref({
        // title: 'Number of Graphs Made this Week',
        showlegend: false,
        dragmode : "orbit",
        barmode: 'stack',
        bargap : 0.15,
        height: 240,
        margin: {
          l : 40,
          t : 5,
          r : 5,
          b : 35
        },
      }),
      barChartConfig : ref({
        autosizable : true,
        displayModeBar: false,
        displaylogo: false,
        responsive: true,
        scrollZoom: false,
      }),

      monthlyCount : ref(0),

      modelCount : ref(0),

      tableColumns : ref([]),
      tableVisibleColumns : ref([]),
      tableRows : ref([]),
      modelCategoryPartsData : ref([]),

      yearlyStartDate : ref(null),
      yearlyEndDate : ref(null),
      modelCharTerm : ref(''),
      countOneYear : ref(0),

      countByDealerCat : ref(0),

      sheets: ref([]),

      hasExcelData : ref(true),
      makingExcel : ref(true),
      excelFilename : ref(''),
      excelFrom : ref(''),
      excelTo : ref(''),
      excelDealer : ref(''),
      excelType : ref(''),

      dealerData : ref([]),
      categoryData : ref([]),
      partsnameData : ref([])
    }
  },
  created() {},
  mounted() {
    const store = useTymictStore()
    const now = new Date()
    this.selectedYear = now.getFullYear().toString()
    this.validYears = []
    for(let year = now.getFullYear(); year >= 2021; year--) {
      this.validYears.push(year.toString())
    }
    let saved = sessionStorage.getItem('STAT.cons.saved')
    if(!TymCommon.isEmpty(saved)) {
      saved = JSON.parse(saved)
      console.log('STAT.cons.saved load :', saved)
      this.selectedYear = saved.Year
      this.selectedMonth = saved.Month
    } else {
      if(now.getDate() == 1)
        this.selectedMonth = this.getMonthTitle[now.getMonth()]
      else
        this.selectedMonth = this.getMonthTitle[now.getMonth() + 1]
    }

    this.titleForDealer = this.$t('statCons.consbybranch')

    if(store.groupTree.length > 0) {
      this.groupTreeData = store.groupTree

      if(!TymCommon.isEmpty(saved)) {
        if(!TymCommon.isEmpty(saved.Expanded))
          this.expandedTree = saved.Expanded
        if(!TymCommon.isEmpty(saved.Selected))
          this.selectedTree = saved.Selected
        this.selectedTreeLabel = saved.Group
        this.catSelected = saved.Category
        this.modelSelected = saved.Model
      } else {
        this.expandedTree = [this.groupTreeData[0].uuid]
        this.selectedTree = this.groupTreeData[0].uuid
        let result = store.findGroupNameByKey(this.selectedTree)
        if(result) {
          this.selectedTreeLabel = result
        }
      }
    }
    this.modelsData.push(this.$t('common.model'))
    if(!TymCommon.isEmpty(store.ModelData)) {
      store.ModelData.data.forEach(md => {
        if(!this.modelsData.find(x => x == md.model))
          this.modelsData.push(md.model)
      })
    }
    
    this.updateDealerChart(true)
    this.updateCategoryPartsChart(true)
    this.updateDealerCategoryPartsTable(true)

    setTimeout(() => {
      this.getHistoryData()
    }, 5)
  }, 
  unmounted() {
    sessionStorage.setItem('STAT.cons.saved', JSON.stringify({
      Expanded: this.expandedTree,
      Selected: this.selectedTree,
      Group: this.selectedTreeLabel,
      Year: this.selectedYear,
      Month: this.selectedMonth,
      Category: this.catSelected,
      Model: this.modelSelected
    }))
  },
  methods : {
    unusedParam() {
      //
    },
    getSelectedMonth() {
      return this.getMonthTitle.findIndex(x => x == this.selectedMonth)
    },
    resizeDealer(size) {
      this.unusedParam(size)
      setTimeout(()=>{
        if(this.$refs.refDealer) {
          let container = document.getElementById('dealerContainer')
          if(!container) {
            return
          }
          if(TymCommon.isEmpty(this.$refs.refDealer.data)) {
            return
          }

          let barCount = this.$refs.refDealer.data[0].x.length
          let minWidth = barCount * 70
          minWidth = minWidth >= container.clientWidth ? minWidth : container.clientWidth - 60
          let maxWidth = barCount * 150
          if(barCount <= 2) {
            minWidth += 160
            maxWidth += 160
          }
          if(minWidth > maxWidth)
            minWidth = maxWidth

          window.Plotly.relayout(this.$refs.refDealer, {
            bargap : 0.15,
            width: minWidth,
            height: container.clientHeight - 18,
            margin: {
              l : 40,
              t : 10,
              r : 5,
              b : 65
            }
          })
        }
      }, 1)
    },
    resizeModelOption(size) {
      this.unusedParam(size)
      setTimeout(()=>{
        if(this.$refs.refDealer) {
          let container = document.getElementById('modelOptionContainer')
          if(!container) {
            return
          }
          if(TymCommon.isEmpty(this.$refs.refModelOption.data)) {
            return
          }
          if(this.$refs.refModelOption.data.length < 1) {
            return
          }

          if(!TymCommon.isEmpty(this.$refs.refModelOption.data[0])) {
            let barCount = this.$refs.refModelOption.data[0].x.length
            let minWidth = barCount * 70
            minWidth = minWidth >= container.clientWidth ? minWidth : container.clientWidth - 60
            let maxWidth = barCount * 150
            if(barCount <= 2) {
              minWidth += 160
              maxWidth += 160
            }
            if(minWidth > maxWidth)
              minWidth = maxWidth

            window.Plotly.relayout(this.$refs.refModelOption,{
              bargap : 0.15,
              width: minWidth,
              height: container.clientHeight - 18,
              margin: {
                l : 40,
                t : 10,
                r : 5,
                b : 65
              }
            })
          }
        }
      }, 1)
    },
    resizeYearModel(size) {
      this.unusedParam(size)
      setTimeout(()=>{
        if(this.$refs.refYearModel) {
          let container = document.getElementById('yearModelContainer')
          if(!container) {
            return
          }
          if(TymCommon.isEmpty(this.$refs.refYearModel.data)) {
            //console.log('statEm.resizeModelOption... EMPTY DATA')
            return
          }

          // let barCount = this.$refs.refModelOption.data[0].x.length
          let width = container.clientWidth < 520 ? 520 : container.clientWidth
          let height = container.clientHeight < 240 ? 240 : container.clientHeight

          width -= 30
          height -= 15

          window.Plotly.relayout(this.$refs.refYearModel, {
            width: width,
            height: height,
            margin: {
              l : 40,
              t : 10,
              r : 5,
              b : 65
            }
          })
        }
      }, 25)
    },
    groupTreeSelected(tgt) {
      const store = useTymictStore()

      this.selectedTree = tgt
      let result = store.findGroupNameByKey(this.selectedTree)
      if(result) {
        this.selectedTreeLabel = result
      }
      this.$refs.groupPopup.hide()
    },
    clearGroupData() {
      const store = useTymictStore()

      this.selectedTree = this.groupTreeData[0].uuid
      let result = store.findGroupNameByKey(this.selectedTree)
      if(result) {
        this.selectedTreeLabel = result
      }
    },
    changedCategory(value, reason, details) {
      this.unusedParam(value, reason, details)
      // console.log('changedCategory :', value, reason, details)
    },
    changedErroPart(value, reason, details) {
      this.unusedParam(value, reason, details)
      // console.log('changedErroPart :', value, reason, details)
    },
    searchData() {
      this.getHistoryData()
    },
    getHistoryData() {
      const store = useTymictStore()
      if(TymCommon.isEmpty(store.idToken["custom:uuid_a"]))
        return

      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(TymCommon.isEmpty(group))
        return

      let groups = store.getSubGroups(this.selectedTree)
      if(TymCommon.isEmpty(groups))
        return

      this.selectedCategoryPartsTab = this.selectCategoryPartsTabs[0]
      this.selectedDealerModelOptionTab = this.selectDealerCategoryPartsTabs[0]

      setTimeout(() => {
        let today = moment()
        let year = parseInt(this.selectedYear)
        let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
        let startDay = null, endDay = null

        if(monIndex == 0) {
          startDay = year.toString() + "-01-01 00:00:00"
          endDay =  (year + 1).toString() + "-01-01 00:00:00"
        } else {
          startDay = year.toString() + "-" + monIndex.toString().padStart(2, '0') + "-01 00:00:00"
          endDay = year.toString() + "-" + (monIndex + 1).toString().padStart(2, '0') + "-01 00:00:00"
        }
        let startResult = TymCommon.localTimeToUTC(startDay, "YYYY-MM-DD HH:mm:ss")
        let endResult = TymCommon.localTimeToUTC(endDay, "YYYY-MM-DD HH:mm:ss")

        if(endResult.dateTime > today) {
          endDay = TymCommon.localTimeToUTC(today.format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss").dateTimeStr
        } else {
          endDay = endResult.dateTimeStr
        }
        startDay = startResult.dateTimeStr

        this.dealerData = []
        this.modelCategoryPartsData = []
        this.categoryData = []
        this.partsnameData = []

        groups.Names.forEach(name => {
          this.dealerData.push({
            Dealer : name,
            Count : 0
          })
        })

        switch(groups.Depth) {
          case 0:
            this.titleForLocalHours = this.$t('statEngineHour.hbybranch')
            break;
          case 1:
            this.titleForLocalHours = this.$t('statEngineHour.hbybranch')
            break;
          case 2:
            this.titleForLocalHours = this.$t('statEngineHour.hbybranch')
            break;
          case 3:
            this.titleForLocalHours = this.$t('statEngineHour.hbydealer')
            break;
        }
        this.loadingData = true

        //console.log('getCSHistory ', start, end)
        TymAws.getConsumablesHistoryMARIA(group.uuid_a, group.uuid_b, group.uuid_c, group.uuid_d, startDay, endDay, false)
          .then(data => {
            if(TymConst.IS_DEVELOPMENT) {
              console.log('getCS(TERM) :', startDay, endDay, data)
            }
            /*
            {
              "M_model": "TX76",
              "M_option": "CQPAN",
              "CATEGORY": "[\"엔진 오일 및 카트리지\",\"Engine Oil and Cartridges\"]",
              "PARTSNAME": "[\"부품3\",\"Parts3\"]",
              "group_a": "TYM",
              "group_b": "국내영업본부",
              "group_c": "강원지점",
              "group_d": "횡성딜러점(박영민)",
              "type": "트랙터",
              "Cnt": "2"
            }
            */
            if(data == 'error' || data.length < 1) {
              this.updateDealerChart(true)
              this.updateCategoryPartsChart(true, true)
              this.updateDealerCategoryPartsTable(true)
              this.loadingData = false
              return
            }

            let categories = []
            let partnames = []
            data.forEach(one => {
            // 선택기대 타입 아니면 버려
              if(this.catSelected != this.getCategory[0]) {
                if(one.type != this.catSelected)
                  return
              }
              // 선택모델 아니면 버려
              if(this.modelSelected != this.modelsData[0]) {
                if(one.M_model != this.modelSelected)
                  return
              }

              let langIdx = this.isKorean ? 0 : 1
              let category = JSON.parse(one.CATEGORY)
              let partsname = TymCommon.isEmpty(one.PARTSNAME) ? [] : JSON.parse(one.PARTSNAME)
              category = TymCommon.isEmpty(category) ? '' : category[langIdx]
              partsname = partsname.length > 0 ? partsname[langIdx] : ''

              if(!categories.find(x => x == category)) {
                categories.push(category)
              }
              if(!partnames.find(x => x == partsname)) {
                partnames.push(partsname)
              }
            })

            categories.sort((a, b) => {
              return a.localeCompare(b)
            })
            partnames.sort((a, b) => {
              return a.localeCompare(b)
            })
            // console.log(categories, partnames)

            data.forEach(one => {
            // 선택기대 타입 아니면 버려
              if(this.catSelected != this.getCategory[0]) {
                if(one.type != this.catSelected)
                  return
              }
              // 선택모델 아니면 버려
              if(this.modelSelected != this.modelsData[0]) {
                if(one.M_model != this.modelSelected)
                  return
              }
              // dealer 찾기
              let dealerName = ''
              switch(groups.Depth) {
                case 0:
                  if(TymCommon.isEmpty(one.group_b))
                    dealerName = one.group_a
                  else
                    dealerName = one.group_b
                  break;
                case 1:
                  if(TymCommon.isEmpty(one.group_c))
                    dealerName = one.group_b
                  else
                    dealerName = one.group_c
                  break;
                case 2:
                  if(TymCommon.isEmpty(one.group_d))
                    dealerName = one.group_c
                  else
                    dealerName = one.group_d
                  break;
                case 3:
                  dealerName = one.group_d
                  break;
              }

              let langIdx = this.isKorean ? 0 : 1
              let count = parseInt(one.Cnt)
              let category = JSON.parse(one.CATEGORY)
              let partsname = TymCommon.isEmpty(one.PARTSNAME) ? [] : JSON.parse(one.PARTSNAME)
              category = TymCommon.isEmpty(category) ? '' : category[langIdx]
              partsname = partsname.length > 0 ? partsname[langIdx] : ''
              if(TymCommon.isEmpty(category))
                category = this.$t('statCons.noName')
              if(TymCommon.isEmpty(partsname))
                partsname = this.$t('statCons.noName')

              let dFound = this.dealerData.find(x => x.Dealer == dealerName)
              if(dFound) {
                dFound.Count += count
              } else {
                this.dealerData.push({
                  Dealer : dealerName,
                  Count : count
                })
              }
              // 카테고리별
              let found = this.categoryData.find(x => x.Name == category)
              if(found) {
                found.Count += count
              } else {
                this.categoryData.push({
                  Name : category,
                  Count : count
                })
              }
              // 파트네임별
              found = this.partsnameData.find(x => x.Name == partsname)
              if(found) {
                found.Count += count
              } else {
                this.partsnameData.push({
                  Name : partsname,
                  Count : count
                })
              }

              // 모델 - 카테고리/파트네임
              let mFound = this.modelCategoryPartsData.find(x => x.Model == one.M_model)
              if(mFound) {
                mFound.Count += count
                let cFound = mFound.Category.find(x => x.Name == category)
                if(cFound) {
                  cFound.Count += count
                } else {
                  mFound.Category.push({
                    Name : category,
                    Count : count
                  })
                }

                let pFound = mFound.Partsname.find(x => x.Name == partsname)
                if(pFound) {
                  pFound.Count += count
                } else {
                  mFound.Partsname.push({
                    Name : partsname,
                    Count : count
                  })
                }
              } else {
                let cat = [], part = []
                categories.forEach(one => {
                  cat.push({
                    Name: one,
                    Count: one == category ? count : 0
                  })
                })
                partnames.forEach(one => {
                  part.push({
                    Name: one,
                    Count: one == partsname ? count : 0
                  })
                })
                this.modelCategoryPartsData.push({
                  Model : one.M_model,
                  Count : count,
                  Category : cat,
                  Partsname : part
                })

                /*
                this.modelCategoryPartsData.push({
                  Model : one.M_model,
                  Count : count,
                  Category : [{
                    Name : category,
                    Count : count
                  }],
                  Partsname : [{
                    Name : partsname,
                    Count : count
                  }]
                })
                */
              }
            })
            this.categoryData.sort((a, b) => {
              return a.Name.localeCompare(b.Name)
            })
            this.partsnameData.sort((a, b) => {
              return a.Name.localeCompare(b.Name)
            })
            // console.log(this.modelCategoryPartsData)
            this.modelCategoryPartsData.forEach(one => {
              one.Category.sort((a, b) => {
                return a.Name.localeCompare(b.Name)
              })
              one.Partsname.sort((a, b) => {
                return a.Name.localeCompare(b.Name)
              })
            })
            /*
            this.selectModelOptions = [ this.$t('common.all') ]
            this.modelData.forEach(model => {
              this.selectModelOptions.push(model.Model)
            })
            this.selectedModelOptionTab = this.selectCategoryPartsTabs[0]
            this.selectedModelOption = this.selectModelOptions[0]
            */

            //console.log('DEALER :', this.dealerData)
            //console.log('CATEGORY :', this.categoryData)
            //console.log('PARTSNAME :', this.partsnameData)
            //console.log('MODEL/CATEGORY/PARTS TABLE :', this.modelCategoryPartsData)

            this.updateDealerChart(false)
            this.updateCategoryPartsChart(false, true)
            this.updateDealerCategoryPartsTable(false)

            this.loadingData = false
          })
          .catch(er => {
            console.log('StatCons.getSalesStat ERROR :', er)
            this.loadingData = false
          })
      }, 1)
    },
    changedModelOptionTab(value) {
      this.chartForCategory = value == this.selectCategoryPartsTabs[0]
      /*
      if(!this.chartForCategory) {
        this.selectedCatPart = this.selectCatParts[0]
      }
      */
      this.updateCategoryPartsChart(false, this.chartForCategory)
    },
    changedDealerCategoryPartsTab(value) {
      this.tableForCategory = value == this.selectDealerCategoryPartsTabs[0]
      this.updateDealerCategoryPartsTable(false)
    },
    updateDealerChart(firstTime) {
      let dealerAxisData = []

      if((this.selectedYear == this.$t('common.all')) && (this.selectedMonth == this.$t('common.month'))) {
        this.termByDealer = this.$t('common.all')
      } else if((this.selectedYear == this.$t('common.all')) && (this.selectedMonth != this.$t('common.month'))) {
        this.termByDealer = this.selectedMonth
      } else if((this.selectedYear != this.$t('common.all')) && (this.selectedMonth == this.$t('common.month'))) {
        this.termByDealer = this.selectedYear
      } else if((this.selectedYear != this.$t('common.all')) && (this.selectedMonth != this.$t('common.month'))) {
        let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
        let ymon = new Date(Number(this.selectedYear), monIndex - 1, 2, 12, 0)
        this.termByDealer = TymCommon.getMonthString(ymon)
      }

      this.countByBranch = 0
      if(firstTime) {
        var value1 = {
          x: [],
          y: [],
          name: 'Normal',
          type: 'bar',
          text: []
        };
        dealerAxisData = [value1]
        new window.Plotly.newPlot('dealerChart', dealerAxisData, this.yearlyLayout, this.barChartConfig)
      } else {
        let xLab = [], yLab = [], textLab = []
        for(let idx = 0; idx < this.dealerData.length; idx++) {
          xLab.push(this.dealerData[idx].Dealer)
          yLab.push(this.dealerData[idx].Count)
          this.countByBranch += this.dealerData[idx].Count
        }
        textLab = yLab.map(String)

        dealerAxisData[0] = {
          x: xLab,
          y: yLab,
          text : textLab,
          hovermode : "closest",
          hovertemplate : '<span style="color:#404040;">%{x}</span> <span style="color:#eb0028;font-weight:bold;">%{text}</span> <extra></extra>',
          hoverlabel: {
            bgcolor: 'white',
            bordercolor: '#eb0028',
          },
          type : 'bar',
          marker: {
            color: '#eb0028',
            line: {
              color: 'black',
              width: 0
            }
          }
        }

        window.Plotly.react('dealerChart', dealerAxisData, this.yearlyLayout, this.barChartConfig)
        this.resizeDealer(null)
      }
    },

    changedSelectedModel(value) {
      this.unusedParam(value)
      this.updateCategoryPartsChart(false, false)
    },
    updateCategoryPartsChart(firstTime, isModel) {
      this.countByModel = 0
      let modelOptionAxisData = [{}]
      let xLab = [], yLab = []
      if(firstTime) {
        xLab.push('')
        yLab.push()
        modelOptionAxisData[0].x = xLab.map(String)
        modelOptionAxisData[0].y = yLab
        modelOptionAxisData[0].text = yLab.map(String)
        new window.Plotly.newPlot('modelOptionChart', this.modelOptionAxisData, this.yearlyLayout, this.barChartConfig)
      } else {
        let chartData = null
        if(isModel) {
          chartData = this.categoryData
        } else {
          chartData = this.partsnameData
        }
        if(!chartData)
          return

        let xLab = [], yLab = [], textLab = []
        for(let idx = 0; idx < chartData.length; idx++) {
          if(chartData[idx].Count > 0) {
            xLab.push(chartData[idx].Name)
            yLab.push(chartData[idx].Count)
          }
        }
        textLab = yLab.map(String)

        modelOptionAxisData[0] = {
          x: xLab,
          y: yLab,
          text: textLab,
          hovermode : "closest",
          hovertemplate : '<span style="color:#404040;">%{x}</span> <span style="color:#eb0028;font-weight:bold;">%{text}</span> <extra></extra>',
          hoverlabel: {
            bgcolor: 'white',
            bordercolor: '#eb0028',
          },
          type : 'bar',
          marker: {
            color: '#eb0028',
            line: {
              color: 'black',
              width: 0
            }
          }
        }

        window.Plotly.react('modelOptionChart', modelOptionAxisData, this.yearlyLayout, this.barChartConfig)
        this.resizeModelOption(null)
      }
    },
    changedDealerModel(value) {
      this.unusedParam(value)
      this.updateDealerCategoryPartsTable(false)
    },
    // 테이블 업데이트
    updateDealerCategoryPartsTable(firstTime) {
      this.tableColumns = []
      this.tableRows = []
      this.countByDealerCat = 0

      if(TymConst.IS_DEVELOPMENT) {
        console.log('updateDealerCategoryPartsTable :', firstTime, this.modelCategoryPartsData)
      }

      if(firstTime || (this.modelCategoryPartsData.length < 1)) {
        //
      } else {
        this.tableColumns.push({
          name: 'index',
          required: false
        })

        let srcData = []
        if(this.tableForCategory)
          srcData = this.modelCategoryPartsData[0].Category
        else {
          srcData = this.modelCategoryPartsData[0].Partsname
        }

        // 컬럼 헤더 부분(맨 윗부분)
        // 첫컬럼은 비워두자... 딜러명 표시컬럼
        let colCount = srcData.length
        this.tableColumns.push({
            name: 'col0',
            field: 'col0',
            label: '',
            align: 'left',
            required: false,
            sortable: false,
          })
        this.tableVisibleColumns.push('col0')

        // 카테고리 및 파트네임
        let idx = 0
        let columnTotal = [] // 컬럼별 합계

        for(idx = 0; idx < colCount; idx++) {
          let model = srcData[idx]
          this.tableColumns.push({
            name: 'col' + (idx + 1).toString(),
            field: 'col' + (idx + 1).toString(),
            label: model.Name,
            align: 'center',
            required: false,
            sortable: false,
          })
          this.tableVisibleColumns.push('col' + (idx + 1).toString())
          columnTotal.push(0)
        }
        columnTotal.push(0)   // 합계용

        // 변수 재활용하니 유의바람
        // 마지막 컬럼에 합계 추가
        this.tableColumns.push({
          name: 'col' + (idx + 1).toString(),
          field: 'col' + (idx + 1).toString(),
          label: this.$t('common.sum'),
          align: 'center',
          required: false,
          sortable: false,
        })
        this.tableVisibleColumns.push('col' + (idx + 1).toString())

        let row = 0, col = 0
        let rowTotal = 0    // row 합계용
        let oneRow = []
        for(row = 0; row < this.modelCategoryPartsData.length; row++) {
          oneRow = []
          rowTotal = 0
          oneRow['index'] = row
          oneRow['col0'] = this.modelCategoryPartsData[row].Model

          let item = null
          if(this.tableForCategory) {
            item = this.modelCategoryPartsData[row].Category
          } else {
            item = this.modelCategoryPartsData[row].Partsname
          }

          for(col = 0; col < colCount; col++) {
            if(!TymCommon.isEmpty(item[col])) {
              oneRow['col' + (col + 1).toString()] = item[col].Count
              rowTotal += item[col].Count
              columnTotal[col] += item[col].Count
            } else {
              oneRow['col' + (col + 1).toString()] = 0
            }
          }
          // 우측끝에 합계 추가
          oneRow['col' + (col + 1).toString()] = rowTotal
          this.tableRows.push(oneRow)
        }

        // 마지막 Row에 합계 추가
        rowTotal = 0
        let totalRow = []
        totalRow['col0'] = this.$t('common.sum')
        for(col = 0; col < colCount; col++) {
          totalRow['col' + (col + 1).toString()] = columnTotal[col]
          rowTotal += columnTotal[col]
        }
        totalRow['col' + (col + 1).toString()] = rowTotal
        this.tableRows.push(totalRow)
      }
    },

    onShowExportToExcel() {
      this.makingExcel = true;

      let now = new Date()
      this.excelFilename = 'Consumables_' + now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += '_' + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += '.xlsx'
      let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
      let viewYear = parseInt(this.selectedYear)

      // console.log('cons.excel :', monIndex, viewYear)

      if(monIndex == 0) {
        let from = new Date(viewYear, 0, 1)
        this.excelFrom = TymCommon.getDateString(from)
        if(now.getFullYear().toString() == viewYear) {
          let dst = new Date()
          // dst = new Date(dst.setDate(dst.getDate()))
          this.excelTo = TymCommon.getDateString(dst)
        } else {
          this.excelTo = TymCommon.getDateString(new Date(viewYear, 11, 31))
        }
      } else {
        let from = new Date(this.selectedYear, monIndex - 1, 1)
        this.excelFrom = TymCommon.getDateString(from)
        let dst = null
        if((now.getFullYear() == from.getFullYear()) && (now.getMonth() == from.getMonth())) {
          dst = new Date()
          dst = new Date(dst.setDate(dst.getDate()))
        } else {
          dst = new Date(from.setMonth(from.getMonth() + 1))
          dst = new Date(dst.setDate(dst.getDate()))
          dst -= 24 * 60 * 60
        }
        this.excelTo = TymCommon.getDateString(dst)
      }
      this.excelDealer = this.selectedTreeLabel
      this.excelType = this.catSelected == this.getCategory[0] ? this.$t('common.all') : this.catSelected

      setTimeout(() => {
        try {
          this.sheets = []
          // this.getHistoryExcel()
          this.makeExcelData2()
        } catch(ex) {
          console.log(ex)
        }
      }, 1)
    },
    makeExcelData2() {
      const store = useTymictStore()
      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      let groups = store.getSubGroups(this.selectedTree)
      if(TymCommon.isEmpty(groups))
        return

        let today = moment()
      let year = parseInt(this.selectedYear)
      let monIndex = this.getMonthTitle.indexOf(this.selectedMonth)
      let startDay = null, endDay = null

      if(monIndex == 0) {
        startDay = year.toString() + "-01-01 00:00:00"
        endDay =  (year + 1).toString() + "-01-01 00:00:00"
      } else {
        startDay = year.toString() + "-" + monIndex.toString().padStart(2, '0') + "-01 00:00:00"
        endDay = year.toString() + "-" + (monIndex + 1).toString().padStart(2, '0') + "-01 00:00:00"
      }

      let startResult = TymCommon.localTimeToUTC(startDay, "YYYY-MM-DD HH:mm:ss")
      let endResult = TymCommon.localTimeToUTC(endDay, "YYYY-MM-DD HH:mm:ss")

      if(endResult.dateTime > today) {
        endResult = TymCommon.localTimeToUTC(today.format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss")
      }
      startDay = startResult.dateTimeStr
      endDay = endResult.dateTimeStr

      setTimeout(() => {
        TymAws.getConsumablesHistoryMARIA(group.uuid_a, group.uuid_b, group.uuid_c, group.uuid_d, startDay, endDay, true)
          .then(data => {
            if(data == 'error' || data.length < 1) {
              this.makingExcel = false
              this.hasExcelData = false
              setTimeout(()=> {
                if(this.$refs.excelPopup)
                  this.$refs.excelPopup.hide()
              }, 2000)
              return
            }
            this.hasExcelData = true

            /*
            {
              "M_model": "TX76",
              "M_option": "CQPAN",
              "MN": "TX76SD01024",
              "CATEGORY": [
                "엔진 오일 및 카트리지",
                "Engine Oil and Cartridges"
              ],
              "PARTNO": "부품번호1-1",
              "PARTSNAME": [
                "부품1",
                "Parts1"
              ],
              "OTime": "2023-07-28T14:55:00.000Z",
              "OVut": 241,
              "RTime": "2023-07-28T02:55:00.000Z",
              "RVut": 241,
              "UName": "정운승",
              "UPhone": "010-5361-2110",
              "MName": [
                "TYM",
                "국내영업본부",
                "강원지점",
                "횡성딜러점(박영민)"
              ],
              "MPhone": "010-9465-9041",
              "RESOLVER": "TYM"
            }
            */
            // 최근 해결일 역순(최근게 위로)
            data.sort((a, b) => {
              return b.RTime.localeCompare(a.RTime)
            })

            if(TymConst.IS_DEVELOPMENT) {
              console.log('statConsumables.Excel :', startDay, endDay, data)
            }

            // 목록 엑셀 만들기
            let xlsRow1 = []
            xlsRow1.push('No', '기종', '모델명', '기대번호',
                        '카테고리','부품명', '부품번호', '발생 일시', '발생 시 사용시간(h)', '교체 일시', '교체 시 사용시간(h)',
                        '고객명', '고객 연락처',
                        '판매 지역', '판매점', '판매점 연락처', '담당자')
            let rowNo = 1
            let othRows = []

            // 나머지 시트 작성용 정리
            let categories = []     // 카테고리명 백업
            let partsnames = []     // 파트네임
            let partsNumbers = []   // 파트넘버

            data.forEach(one => {
              if(this.catSelected != this.getCategory[0]) {
                if(this.catSelected  != one.type)
                  return
              }
              if(this.modelSelected != this.modelsData[0]) {
                if(one.M_model != this.modelSelected)
                  return
              }
              // const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;     // Asia/Seoul
              // console.log("TZ #1: ", currentTimezone)     

              let oTime = TymCommon.convertStringToDateTime3(one.OTime)
              let rTime = TymCommon.convertStringToDateTime3(one.RTime)

              let modelFullname = one.M_model
              if(one.M_option != '0' && one.M_option != 'NONE') {
                modelFullname += one.M_option
              }

              let branchName = ''
              let dealerName = ''
              if(!TymCommon.isEmpty(one.MName[3])) {
                dealerName = one.MName[3]
                branchName = one.MName[2]
              } else if(!TymCommon.isEmpty(one.MName[2])) {
                dealerName = one.MName[2]
                branchName = one.MName[1]
              } else if(!TymCommon.isEmpty(one.MName[1])) {
                dealerName = one.MName[1]
                branchName = one.MName[0]
              } else {
                dealerName = one.MName[0]
              }

              if(TymConst.IS_DEVELOPMENT) {
                if(TymCommon.isEmpty(one.PARTSNAME)) {
                  console.log('EMPTY PARTNAME :', one.PARTSNAME, one)
                }
              }
              let langIdx = this.isKorean ? 0 : 1
              let category = JSON.parse(one.CATEGORY)
              let partname = TymCommon.isEmpty(one.PARTSNAME) ? [] : JSON.parse(one.PARTSNAME)
              category = TymCommon.isEmpty(category) ? '' : category[langIdx]
              partname = partname.length > 0 ? partname[langIdx] : '이름 없음'

              let ovut = one.OVut
              let rvut = one.RVut
              // 20240105 - OVut가 RVut가 더 큰 경우에는 잘못 넣은 경우라 바꿈...(DB가 그렇게 생김...)
              if(one.OVut > one.RVut) {
                ovut = one.RVut
                rvut = one.OVut
              }
              othRows.push([rowNo.toString(), one.M_model, modelFullname, one.MN,
                          category, partname, one.PARTNO, oTime, ovut, rTime, rvut,
                          one.UName, one.UPhone,
                          branchName, dealerName, one.MPhone, one.RESOLVER])
              rowNo++

              let partno = TymCommon.isEmpty(one.PARTNO) ? this.$t('statCons.noName') : one.PARTNO

              if(!categories.find(x => x == category))
                categories.push(category)

              if(!partsnames.find(x => x == partname))
                partsnames.push(partname)
              
              if(!partsNumbers.find(x => x == partno))
                partsNumbers.push(partno)
            })
            categories.sort((a, b) => {
              return a.localeCompare(b)
            })
            partsnames.sort((a, b) => {
              return a.localeCompare(b)
            })
            partsNumbers.sort((a, b) => {
              return a.localeCompare(b)
            })
            othRows.splice(0, 0, xlsRow1)
            
            let from = new Date(this.excelFrom)
            let to = new Date(this.excelTo)
      
            let infoRow = []
            infoRow.push('통계(소모품 교체) - 조회기간 : ' + from.getFullYear() + '.' + (from.getMonth() + 1).toString().padStart(2, '0') + '.' + from.getDate().toString().padStart(2, '0')
            + '-'  + to.getFullYear() + '.' + (to.getMonth() + 1).toString().padStart(2, '0') + '.' + to.getDate().toString().padStart(2, '0'))
            othRows.splice(0, 0, infoRow )

            this.sheets.push({
              name: 'List ' + from.getFullYear() + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                    + ' - ' + to.getFullYear() + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
              data: othRows
            })

            // 나머지 시트 정렬
            categories.sort((a, b) => {
              return a.localeCompare(b)
            })
            partsnames.sort((a, b) => {
              return a.localeCompare(b)
            })
            partsNumbers.sort((a, b) => {
              return a.localeCompare(b)
            })

            let byDealer = []   // 딜러, 카테고리/파트네임/파트넘버
            groups.Names.forEach(name => {
              let cats = []
              categories.forEach(name => {
                cats.push({
                  Name : name,
                  Count : 0
                })
              })
              let parts = []
              partsnames.forEach(name => {
                parts.push({
                  Name : name,
                  Count : 0
                })
              })
              let partsNos = []
              partsNumbers.forEach(name => {
                partsNos.push({
                  Name : name,
                  Count : 0
                })
              })

              byDealer.push({
                Dealer : name,
                  Count : 0,
                  Category : cats,
                  Partsname : parts,
                  PartsNumber : partsNos
              })
            })

            /*
            console.log('-------------')
            console.log('Dealer :', byDealer)
            console.log('PartName :', partsnames)
            console.log('PartNumber :', partsNumbers)
            */

            data.forEach(one => {
              if(this.catSelected != this.getCategory[0]) {
                if(this.catSelected  != one.type)
                  return
              }
              if(this.modelSelected != this.modelsData[0]) {
                if(one.M_model != this.modelSelected)
                  return
              }

              let dealerName = ''
              switch(groups.Depth) {
                case 0:
                  if(TymCommon.isEmpty(one.MName[1]))
                    dealerName = one.MName[0]
                  else
                    dealerName = one.MName[1]
                  break;
                case 1:
                  if(TymCommon.isEmpty(one.MName[2]))
                    dealerName = one.MName[1]
                  else
                    dealerName = one.MName[2]
                  break;
                case 2:
                  if(TymCommon.isEmpty(one.MName[3]))
                    dealerName = one.MName[2]
                  else
                    dealerName = one.MName[3]
                  break;
                case 3:
                  dealerName = one.MName[4]
                  break;
              }

              let langIdx = this.isKorean ? 0 : 1
              let category = JSON.parse(one.CATEGORY)
              let partname = TymCommon.isEmpty(one.PARTSNAME) ? [] : JSON.parse(one.PARTSNAME)
              category = TymCommon.isEmpty(category) ? '' : category[langIdx]
              partname = partname.length > 0 ? partname[langIdx] : '이름 없음'
              let partno = TymCommon.isEmpty(one.PARTNO) ? this.$t('statCons.noName') : one.PARTNO

              let found = byDealer.find(x => x.Dealer == dealerName)
              if(found) {
                found.Count++
                let subFound = found.Category.find(x => x.Name == category)
                if(subFound) {
                  subFound.Count++
                }
                subFound = found.Partsname.find(x => x.Name == partname)
                if(subFound) {
                  subFound.Count++
                }
                subFound = found.PartsNumber.find(x => x.Name == partno)
                if(subFound) {
                  subFound.Count++
                }
              }
            })

            this.sheets.push({
              name: 'List ' + from.getFullYear() + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                    + ' - ' + to.getFullYear() + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
              data: othRows
            })

            // 지역별,카테고리별 발생건수
            let idx = 0, cnt = 0
            let rowTotal = 0
            let oneRow = []
            let colTotal = []

            othRows = []
            xlsRow1 = ['판매 지역']
            byDealer[0].Category.forEach(item => {
              xlsRow1.push(item.Name)
              colTotal.push(0)
            })
            xlsRow1.push('총 처리 건수')

            byDealer.forEach(dealer => {
              oneRow = [dealer.Dealer]
              rowTotal = 0

              for(idx = 0; idx < dealer.Category.length; idx++) {
                cnt = dealer.Category[idx].Count
                oneRow.push(cnt)
                rowTotal += cnt
                colTotal[idx] += cnt
              }
              oneRow.push(rowTotal)
              othRows.push(oneRow)
            })

            oneRow = ['합계']
            rowTotal = 0
            colTotal.forEach(cnt => {
              oneRow.push(cnt)
              rowTotal += cnt
            })
            oneRow.push(rowTotal)
            othRows.push(oneRow)
            
            othRows.splice(0, 0, xlsRow1)

            this.sheets.push({
              name: '지역.카테고리별 ' + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                    + ' - ' + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
              data: othRows
            })

            // 지역별,파트네임별 발생건수
            idx = 0, cnt = 0
            rowTotal = 0
            oneRow = []
            colTotal = []

            othRows = []
            xlsRow1 = ['판매 지역']
            byDealer[0].Partsname.forEach(item => {
              xlsRow1.push(item.Name)
              colTotal.push(0)
            })
            xlsRow1.push('총 처리 건수')

            byDealer.forEach(dealer => {
              oneRow = [dealer.Dealer]
              rowTotal = 0

              for(idx = 0; idx < dealer.Partsname.length; idx++) {
                cnt = dealer.Partsname[idx].Count
                oneRow.push(cnt)
                rowTotal += cnt
                colTotal[idx] += cnt
              }
              oneRow.push(rowTotal)
              othRows.push(oneRow)
            })

            oneRow = ['합계']
            rowTotal = 0
            colTotal.forEach(cnt => {
              oneRow.push(cnt)
              rowTotal += cnt
            })
            oneRow.push(rowTotal)
            othRows.push(oneRow)
            
            othRows.splice(0, 0, xlsRow1)

            // console.log('--------------------', othRows)

            this.sheets.push({
              name: '지역.파트네임별 ' + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                    + ' - ' + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
              data: othRows
            })

            // 지역별,파트넘버별 발생건수
            idx = 0, cnt = 0
            rowTotal = 0
            oneRow = []
            colTotal = []

            othRows = []
            xlsRow1 = ['판매 지역']
            byDealer[0].PartsNumber.forEach(item => {
              xlsRow1.push(item.Name)
              colTotal.push(0)
            })
            xlsRow1.push('총 처리 건수')

            byDealer.forEach(dealer => {
              oneRow = [dealer.Dealer]
              rowTotal = 0

              for(idx = 0; idx < dealer.PartsNumber.length; idx++) {
                cnt = dealer.PartsNumber[idx].Count
                oneRow.push(cnt)
                rowTotal += cnt
                colTotal[idx] += cnt
              }
              oneRow.push(rowTotal)
              othRows.push(oneRow)
            })

            oneRow = ['합계']
            rowTotal = 0
            colTotal.forEach(cnt => {
              oneRow.push(cnt)
              rowTotal += cnt
            })
            oneRow.push(rowTotal)
            othRows.push(oneRow)
            
            othRows.splice(0, 0, xlsRow1)

            this.sheets.push({
              name: '지역.파트넘버별 ' + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                    + ' - ' + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
              data: othRows
            })
            this.makingExcel = false
          })
          .catch(er => {
            console.log('ExportExcel FAIL', er)
            this.hasExcelData = false
            this.makingExcel = false;
            setTimeout(() => {
              setTimeout(()=> {
                if(this.$refs.excelPopup)
                  this.$refs.excelPopup.hide()
              }, 2000)
            }, 5)
          })
      }, 1)
    },
    getHistoryExcel() {
      const store = useTymictStore()
      let manageInfo = null
      let foundGrp = store.groupInfo.find(x => x.uuid == this.selectedTree)
      if(foundGrp) {
        if(!TymCommon.isEmpty(foundGrp.uuid_d)) {
          manageInfo = {
            MA : foundGrp.uuid_a,
            MB : foundGrp.uuid_b,
            MC : foundGrp.uuid_c,
            MD : foundGrp.uuid_d
          }
        } else if(!TymCommon.isEmpty(foundGrp.uuid_c)) {
          manageInfo = {
            MA : foundGrp.uuid_a,
            MB : foundGrp.uuid_b,
            MC : foundGrp.uuid_c,
          }
        } else if(!TymCommon.isEmpty(foundGrp.uuid_b)) {
          manageInfo = {
            MA : foundGrp.uuid_a,
            MB : foundGrp.uuid_b,
          }
        } else if(!TymCommon.isEmpty(foundGrp.uuid_a)) {
          manageInfo = {
            MA : foundGrp.uuid_a,
          }
        }
      }

      let startDay = new Date(this.excelFrom)
      let endDay = new Date(this.excelTo)
      startDay = startDay.getFullYear().toString() + "-" + (startDay.getMonth() + 1).toString().padStart(2, '0') + "-" + startDay.getDate().toString().padStart(2, "0")
      startDay += " 00:00"
      endDay = endDay.getFullYear().toString() + "-" + (endDay.getMonth() + 1).toString().padStart(2, '0') + "-" + endDay.getDate().toString().padStart(2, "0")
      endDay += " 24:00"

      // console.log("getConsumable (Excel) :", manageInfo, startDay, endDay)
      TymAws.getConsumablesHistory(JSON.stringify([manageInfo]), startDay, endDay, store.isICTUser(), true)
        .then(data => {
          try {
            if(!TymCommon.isEmpty(data.body)) {
              data = JSON.parse(data.body)
              this.makeExcelData(data)
            }
          } catch(ex) {
            console.log(ex)
          }
          setTimeout(() => {
            this.makingExcel = false;
          }, 100)
        })
        .catch(er => {
          console.log(er)
          setTimeout(() => {
            this.makingExcel = false;
          }, 100)
        })
    },
    makeExcelData(data) {
      const store = useTymictStore()

      // 기기 종류(트랙터? 이앙기?)
      let typeFiltered = []
      if(this.catSelected != this.$t('common.type')) {
        typeFiltered = data.filter(x => x.TYPE == this.catSelected)
      } else {
        typeFiltered = data
      }
      typeFiltered.forEach(one => {
        let modelOption = one.MON.split('_')
        let modelFullname = modelOption[0]
        if(!TymCommon.isEmpty(modelOption[1])) {
          if(modelOption[1] != 0 && modelOption[1] != '0' && modelOption[1].toUpperCase() != 'NONE')
            modelFullname += modelOption[1]
        }
        one.ModelName = modelOption[0]
        one.OptionName = modelFullname
      })
      typeFiltered.sort((a, b) => {
        return (a.OTime.localeCompare(b.OTime))
      })

      if(TymConst.IS_DEVELOPMENT) {
        console.log('makeExcel (type Filted) :', typeFiltered)
      }

      // 그룹으로 나누자
      let group = store.groupInfo.find(x => x.uuid == this.selectedTree)
      let depth = -1
      if(TymCommon.isEmpty(group.uuid_d)) {
        if(TymCommon.isEmpty(group.uuid_c)) {
          if(TymCommon.isEmpty(group.uuid_b)) {
            depth = 0
          } else {
            depth = 1
          }
        } else {
          depth = 2
        }
      } else {
        depth = 3
      }

      // 모델이름, 카테고리, 파트명, 파트넘버 추출
      let modelNames = []
      let categories = []
      let partNames = []
      let partNumbers = []
      typeFiltered.forEach(item => {
        if(!modelNames.find(x => x == item.OptionName)) {
          modelNames.push(item.OptionName)
        }
        if(!categories.find(x => x == item.CATEGORY[store.connectServer])) {
          categories.push(item.CATEGORY[store.connectServer])
        }
        if(!partNames.find(x => x == item.PARTSNAME[store.connectServer])) {
          partNames.push(item.PARTSNAME[store.connectServer])
        }
        if(!partNumbers.find(x => x == item.PARTNO)) {
          partNumbers.push(item.PARTNO)
        }
      })
      modelNames.sort((a, b) => {
        return a.localeCompare(b)
      })
      categories.sort((a, b) => {
        return a.localeCompare(b)
      })
      partNames.sort((a, b) => {
        return a.localeCompare(b)
      })
      partNumbers.sort((a, b) => {
        return a.localeCompare(b)
      })

      let dealerFiltered = []
      let modelFiltered = []
      modelNames.forEach(md => {
        let one = {
          Name : md,
          Category : [],
          PartName : [],
          PartNo : []
        }
        categories.forEach(item => {
          one.Category.push({
            Name : item,
            Count : 0
          })
        })
        partNames.forEach(item => {
          one.PartName.push({
            Name : item,
            Count : 0
          })
        })
        partNumbers.forEach(item => {
          one.PartNo.push({
            Name : item,
            Count : 0
          })
        })
        modelFiltered.push(one)
      })
      let meAndSubGrps = store.getMyGroupNames(depth, group)
      meAndSubGrps.forEach(grp => {
        let one = {
          Dealer : grp.Name,
          uuid : grp.UUID,
          Category : [],
          PartName : [],
          PartNo : []
        }
        categories.forEach(item => {
          one.Category.push({
            Name : item,
            Count : 0
          })
        })
        partNames.forEach(item => {
          one.PartName.push({
            Name : item,
            Count : 0
          })
        })
        partNumbers.forEach(item => {
          one.PartNo.push({
            Name : item,
            Count : 0
          })
        })

        dealerFiltered.push(one)
      })

      // console.log('makeExcel (START) :', depth, typeFiltered, dealerFiltered, modelFiltered)

      typeFiltered.forEach(item => {
        let grpName = null
        switch(depth) {
          case 0:
            if(!TymCommon.isEmpty(item.Manage.MB))
              grpName = store.findGroupNameByKey(item.Manage.MB)
            else
              grpName = store.findGroupNameByKey(item.Manage.MA)
            break;
          case 1:
            if(!TymCommon.isEmpty(item.Manage.MC))
              grpName = store.findGroupNameByKey(item.Manage.MC)
            else
              grpName = store.findGroupNameByKey(item.Manage.MB)
            break;
          case 2:
            if(!TymCommon.isEmpty(item.Manage.MD))
              grpName = store.findGroupNameByKey(item.Manage.MD)
            else
              grpName = store.findGroupNameByKey(item.Manage.MC)
            break;
          case 3:
            grpName = item.Manage_Name[1]
            break;
        }
      
        let category = item.CATEGORY[store.connectServer]
        let partname = item.PARTSNAME[store.connectServer]
        let partno = item.PARTNO

        let foundDealer = dealerFiltered.find(x => x.Dealer == grpName)
        if(foundDealer) {
          let catFound = foundDealer.Category.find(x => x.Name == category)
          if(catFound) {
            catFound.Count++
          } else {
            foundDealer.Category.push({
              Name : category,
              Count : 1
            })
          }

          let nameFound = foundDealer.PartName.find(x => x.Name == partname)
          if(nameFound) {
            nameFound.Count++
          } else {
            foundDealer.PartName.push({
              Name : partname,
              Count : 1
            })
          }

          let numberFound = foundDealer.PartNo.find(x => x.Name == partno)
          if(numberFound) {
            numberFound.Count++
          } else {
            foundDealer.PartNo.push({
              Name : partno,
              Count : 1
            })
          }
        }

        let foundModel = modelFiltered.find(x => x.Name == item.OptionName)
        if(foundModel) {
          let catFound = foundModel.Category.find(x => x.Name == category)
          if(catFound) {
            catFound.Count++
          } else {
            foundModel.Category.push({
              Name : category,
              Count : 1
            })
          }

          let nameFound = foundModel.PartName.find(x => x.Name == partname)
          if(nameFound) {
            nameFound.Count++
          } else {
            foundModel.PartName.push({
              Name : partname,
              Count : 1
            })
          }

          let numberFound = foundModel.PartNo.find(x => x.Name == partno)
          if(numberFound) {
            numberFound.Count++
          } else {
            foundModel.PartNo.push({
              Name : partno,
              Count : 1
            })
          }
        }
      })
      //console.log('makeExcel (Dealer) :', dealerFiltered)
      //console.log('makeExcel (Model) :', modelFiltered)
      // -- 분류 끝 -- //


      // 전체 긴급알람 리스트 출력
      let xlsRow1 = []
      xlsRow1.push('No', '기종', '모델명', '기대번호',
                  '카테고리','부품명', '무품번호', '발생 일시', '발생 시 사용시간(h)', '교체 일시', '교체 시 사용시간(h)',
                  '고객명', '고객 연락처',
                  '판매 지역', '판매점', '판매점 연락처', '담당자')
      let rowNo = 1
      let othRows = []
      typeFiltered.forEach(one => {
        let oTime = new Date(one.OTime)
        let rTime = new Date(one.OTime)
        othRows.push([rowNo.toString(), one.ModelName, one.OptionName, one.MN,
                    one.CATEGORY[store.connectServer], one.PARTSNAME[store.connectServer], one.PARTNO, TymCommon.getDateTimeString(oTime), one.VUT, TymCommon.getDateTimeString(rTime), one.VUT,
                    one.User_Name, one.User_Phone,
                    one.Manage_Name[0], one.Manage_Name[1], one.Manage_Phone, one.Resolver])
        rowNo++
      })
      othRows.splice(0, 0, xlsRow1)

      let from = new Date(this.excelFrom)
      let to = new Date(this.excelTo)

      let infoRow = []
      /*
      infoRow.push('판매지점 : ' + this.excelDealer)
      othRows.splice(0, 0, infoRow )

      infoRow = []
      */
      infoRow.push('통계(소모품 교체) - 조회기간 : ' + from.getFullYear() + '.' + (from.getMonth() + 1).toString().padStart(2, '0') + '.' + from.getDate().toString().padStart(2, '0')
      + '-'  + to.getFullYear() + '.' + (to.getMonth() + 1).toString().padStart(2, '0') + '.' + to.getDate().toString().padStart(2, '0'))
      othRows.splice(0, 0, infoRow )
      // console.log('makeExcel (Table Rows) :', othRows)

      // console.log('makeExcel (xls list) :', this.excelFrom, this.excelTo, othRows)
      this.sheets.push(
        {
          name: 'List ' + from.getFullYear() + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                + ' - ' + to.getFullYear() + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
          data: othRows
        }
      )

      // 지역별, 카테고리별
      xlsRow1 = ['판매 지역']
      othRows = []
      let colTotal = []
      dealerFiltered[0].Category.forEach(item => {
        colTotal.push(0)
        xlsRow1.push(item.Name)
      })
      xlsRow1.push('총 처리 건수')

      let rowTotal = 0
      let oneRow = []
      for(let idx = 0; idx < dealerFiltered.length; idx++) {
        let dealer = dealerFiltered[idx]
        oneRow = []
        oneRow.push(dealer.Dealer)
        rowTotal = 0
        for(let itemIdx = 0; itemIdx < dealer.Category.length; itemIdx++) {
          let data = dealer.Category[itemIdx]
          oneRow.push(data.Count)
          rowTotal += data.Count
          colTotal[itemIdx] += data.Count
        }
        oneRow.push(rowTotal)
        othRows.push(oneRow)
      }

      oneRow = ['합계']
      rowTotal = 0
      colTotal.forEach(col => {
        oneRow.push(col)
        rowTotal += col
      })
      oneRow.push(rowTotal)
      othRows.push(oneRow)
      othRows.splice(0, 0, xlsRow1)
      this.sheets.push(
        {
          name: '지역-카테고리별 처리건수 ' + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                + ' - ' + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
          data: othRows
        }
      )

      // 지역별, 부품명별
      xlsRow1 = ['판매 지역']
      othRows = []
      colTotal = []
      dealerFiltered[0].PartName.forEach(item => {
        colTotal.push(0)
        xlsRow1.push(item.Name)
      })
      xlsRow1.push('총 처리 건수')

      rowTotal = 0
      oneRow = []
      for(let idx = 0; idx < dealerFiltered.length; idx++) {
        let dealer = dealerFiltered[idx]
        oneRow = []
        oneRow.push(dealer.Dealer)
        rowTotal = 0
        for(let itemIdx = 0; itemIdx < dealer.PartName.length; itemIdx++) {
          let data = dealer.PartName[itemIdx]
          oneRow.push(data.Count)
          rowTotal += data.Count
          colTotal[itemIdx] += data.Count
        }
        oneRow.push(rowTotal)
        othRows.push(oneRow)
      }

      oneRow = ['합계']
      rowTotal = 0
      colTotal.forEach(col => {
        oneRow.push(col)
        rowTotal += col
      })
      oneRow.push(rowTotal)
      othRows.push(oneRow)
      othRows.splice(0, 0, xlsRow1)
      this.sheets.push(
        {
          name: '지역-파트명별 처리건수 ' + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                + ' - ' + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
          data: othRows
        }
      )      

      // 지역별, 부품번호별
      xlsRow1 = ['판매 지역']
      othRows = []
      colTotal = []
      dealerFiltered[0].PartNo.forEach(item => {
        colTotal.push(0)
        xlsRow1.push(item.Name)
      })
      xlsRow1.push('총 처리 건수')

      rowTotal = 0
      oneRow = []
      for(let idx = 0; idx < dealerFiltered.length; idx++) {
        let dealer = dealerFiltered[idx]
        oneRow = []
        oneRow.push(dealer.Dealer)
        rowTotal = 0
        for(let itemIdx = 0; itemIdx < dealer.PartNo.length; itemIdx++) {
          let data = dealer.PartNo[itemIdx]
          oneRow.push(data.Count)
          rowTotal += data.Count
          colTotal[itemIdx] += data.Count
        }
        oneRow.push(rowTotal)
        othRows.push(oneRow)
      }

      oneRow = ['합계']
      rowTotal = 0
      colTotal.forEach(col => {
        oneRow.push(col)
        rowTotal += col
      })
      oneRow.push(rowTotal)
      othRows.push(oneRow)
      othRows.splice(0, 0, xlsRow1)
      this.sheets.push(
        {
          name: '지역-파트넘버별 처리건수 ' + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                + ' - ' + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
          data: othRows
        }
      )

      
      // 모델별, 카테고리별
      xlsRow1 = ['모델별']
      othRows = []
      colTotal = []
      modelFiltered[0].Category.forEach(item => {
        colTotal.push(0)
        xlsRow1.push(item.Name)
      })
      xlsRow1.push('총 처리 건수')

      rowTotal = 0
      oneRow = []
      for(let idx = 0; idx < modelFiltered.length; idx++) {
        let model = modelFiltered[idx]
        oneRow = []
        oneRow.push(model.Name)
        rowTotal = 0
        for(let itemIdx = 0; itemIdx < model.Category.length; itemIdx++) {
          let data = model.Category[itemIdx]
          oneRow.push(data.Count)
          rowTotal += data.Count
          colTotal[itemIdx] += data.Count
        }
        oneRow.push(rowTotal)
        othRows.push(oneRow)
      }

      oneRow = ['합계']
      rowTotal = 0
      colTotal.forEach(col => {
        oneRow.push(col)
        rowTotal += col
      })
      oneRow.push(rowTotal)
      othRows.push(oneRow)
      othRows.splice(0, 0, xlsRow1)
      this.sheets.push(
        {
          name: '모델-카테고리별 처리건수 ' + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                + ' - ' + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
          data: othRows
        }
      )

      // 모델별, 부품명별
      xlsRow1 = ['모델별']
      othRows = []
      colTotal = []
      modelFiltered[0].PartName.forEach(item => {
        colTotal.push(0)
        xlsRow1.push(item.Name)
      })
      xlsRow1.push('총 처리 건수')

      rowTotal = 0
      oneRow = []
      for(let idx = 0; idx < modelFiltered.length; idx++) {
        let model = modelFiltered[idx]
        oneRow = []
        oneRow.push(model.Name)
        rowTotal = 0
        for(let itemIdx = 0; itemIdx < model.PartName.length; itemIdx++) {
          let data = model.PartName[itemIdx]
          oneRow.push(data.Count)
          rowTotal += data.Count
          colTotal[itemIdx] += data.Count
        }
        oneRow.push(rowTotal)
        othRows.push(oneRow)
      }

      oneRow = ['합계']
      rowTotal = 0
      colTotal.forEach(col => {
        oneRow.push(col)
        rowTotal += col
      })
      oneRow.push(rowTotal)
      othRows.push(oneRow)
      othRows.splice(0, 0, xlsRow1)
      this.sheets.push(
        {
          name: '모델-파트명별 처리건수 ' + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                + ' - ' + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
          data: othRows
        }
      )      

      // 모델별, 부품번호별
      xlsRow1 = ['모델 지역']
      othRows = []
      colTotal = []
      modelFiltered[0].PartNo.forEach(item => {
        colTotal.push(0)
        xlsRow1.push(item.Name)
      })
      xlsRow1.push('총 처리 건수')

      rowTotal = 0
      oneRow = []
      for(let idx = 0; idx < modelFiltered.length; idx++) {
        let model = modelFiltered[idx]
        oneRow = []
        oneRow.push(model.Name)
        rowTotal = 0
        for(let itemIdx = 0; itemIdx < model.PartNo.length; itemIdx++) {
          let data = model.PartNo[itemIdx]
          oneRow.push(data.Count)
          rowTotal += data.Count
          colTotal[itemIdx] += data.Count
        }
        oneRow.push(rowTotal)
        othRows.push(oneRow)
      }

      oneRow = ['합계']
      rowTotal = 0
      colTotal.forEach(col => {
        oneRow.push(col)
        rowTotal += col
      })
      oneRow.push(rowTotal)
      othRows.push(oneRow)
      othRows.splice(0, 0, xlsRow1)
      this.sheets.push(
        {
          name: '모델-파트넘버별 처리건수 ' + (from.getMonth() + 1).toString().padStart(2, '0') + from.getDate().toString().padStart(2, '0')
                + ' - ' + (to.getMonth() + 1).toString().padStart(2, '0') + to.getDate().toString().padStart(2, '0'),
          data: othRows
        }
      )
    },
    saveToExcelFile() {
      let desc = {
        filename: this.excelFilename,
        start: this.excelFrom,
        end: this.excelTo,
        dealer: this.excelDealer,
        type: this.excelType
      }
      TymAws.writeAction('통계', '소모품', desc)

      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 500)
    }
  }
})
</script>
