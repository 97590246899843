 <template>
  <div class="column">
    <div class="col column" style="padding:0 12px 12px 12px">
      <div class="col-auto row items-center" style="height:45px;">
        <div class="col-auto"
            :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
          {{ getPageTitle }}
        </div>
      </div>
      <div class="col column" style="border-radius:3px;">
        <div class="col-auto column">
          <div class="col row items-center justify-start" style="background-color:white;margin:0 0 12px 0;padding:0 0;">
            <div class="col-3 row" style="margin:0 0 0 12px;">
              <table class="col" style="border-collapse:collapse;font-size:15px;">
                <tr v-for="usim in usimCounters" :key="usim">
                  <td width="140px">
                    <div>
                      {{ usim.Title}}
                    </div>
                  </td>
                  <td width="60px" class="col row items-center justify-end">
                    <div class="col-auto" style="margin:0 6px 0 0;font-weight:bold;font-family:Prometo;" :style="'color:' + usim.Color">
                      {{ usim.Count }}
                    </div>
                  </td>
                  <td width="200px">
                    <div class="row" style="width:100%;height:100%;" v-show="usim.Percent >= 0">
                      <q-linear-progress class="col" :value="usim.Percent" rounded :color="usim.PGColor" track-color="grey-5"  size="15px">
                      </q-linear-progress>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td width="140px">
                    <div class="expireBtn"> 
                      {{ usimCloseExpired.Title}}
                    </div>
                  </td>
                  <td width="60px" class="col row items-center justify-end">
                    <div class="col-auto" style="margin:0 6px 0 0;font-weight:bold;font-family:Prometo;" :style="'color:' + usimCloseExpired.Color" @click="clickedCloseExpire">
                      {{ usimCloseExpired.Count }}
                    </div>
                  </td>
                  <td width="200px">
                    <q-icon name="info" size="20px" color="grey-8" style="cursor: pointer;">
                      <q-tooltip style="font-size:14px;">
                        {{ $t('USIM.tooltipCloseExpire')}}
                      </q-tooltip>
                    </q-icon>
                  </td>
                </tr>

              </table>
            </div>
            <div style="width:64px;"></div>
            <div class="col-auto row items-end justify-between">
              <!-- 상단 일반 검색-->
              <div class="col row">
                <div class="col column items-start justify-start">
                  <!-- 일반 검색 -->
                  <div class="col row" style="width:100%;">
                    <div class="col-auto row items-center justify-start" style="min-width:160px;margin:0 12px 0 0;">
                      <!-- USIM 상태 -->
                      <q-select outlined dense options-dense class="col" color="red-7"
                                :label="$t('USIM.searchUSIMState')" 
                                v-model="selectedUSIMState" :options="usimStates">
                      </q-select>
                    </div>

                    <div class="col-auto row items-center justify-start" style="min-width:160px;margin:0 12px 0 0;">
                      <!-- 모델 -->
                      <q-select outlined dense options-dense class="col" color="red-7"
                                :label="$t('common.model')" 
                                v-model="selectedModel" :options="modelValues">
                      </q-select>
                    </div>

                    <div class="col row items-center justify-start" style="min-width:140px;max-width:240px;">
                      <q-input class="col" dense clearable outlined color="red-7" :placeholder="$t('common.keyword')" v-model="inputKeyword">
                      </q-input>
                    </div>

                    <!--
                    <div class="col-auto column items-center justify-center" v-show="isDev">
                      <q-btn no-caps unelevated dense color="blue-7" icon='smartphone' style="width:60px;height:40px;margin:0 0 0 12px;" @click="doUpdatePhone">
                        <q-tooltip>시험용(관리자 전화번호 관리)</q-tooltip>
                      </q-btn>
                    </div>

                    <div class="col-auto column items-center justify-center" v-show="isDev">
                      <q-btn no-caps unelevated dense color="green-7" size="md" icon='sim_card' style="width:60px;height:40px;margin:0 0 0 12px;" @click="doListUSIM">
                        <q-tooltip>시험용(USIM 전부 읽기)</q-tooltip>
                      </q-btn>
                    </div>
                    -->
                  </div>
                  <div class="col row items-center justify-start" style="width:100%;margin:12px 0 0 0;">
                    <q-select outlined dense options-dense class="col" color="red-7" style="max-width:200px;"
                              :label="$t('USIM.searchDateOption')" @update:model-value="changedSearchDateOption"
                              v-model="selectedDateOption" :options="usimDateOptions">
                    </q-select>

                    <!-- 검색기준 및 날짜 선택 -->
                    <div class="col-auto column items-end justify-center" style="margin:0 6px 0 6px;height:100%;">
                      <q-icon class="col-auto" name="calendar_month" color="grey-7" size="md"></q-icon>
                      <!--
                      <q-btn class="col-auto" no-caps unelevated dense color="grey-8" disable icon='calendar_month' style="width:36px;height:36px;">
                        <q-popup-proxy cover ref="datePopup" style="margin:12px;" @before-show="popupDateSelect('datePopup')">
                          <div class="column" style="width:100%;height:100%;padding:6px;">
                            <div class="col row items-center justify-between" style="width:100%;background-color:black;color:white;padding:3px 6px 3px 9px;">
                              <div style="font-size:15px;font-weight:bold;">{{ $t('USIM.optionPopupTitme') }}</div>
                              <div>
                                <q-btn flat dense icon="search_off" @click="resetSearchingTerm">
                                  <q-tooltip style="font-size:14px">
                                    {{ $t('tooltip.initJob')}}
                                  </q-tooltip>
                                </q-btn>
                                <q-btn flat dense icon="assignment_return" @click="applySearchingTerm">
                                  <q-tooltip style="font-size:14px">
                                    {{ $t('tooltip.applyJob')}}
                                  </q-tooltip>
                                </q-btn>
                                <q-btn flat dense icon="close" v-close-popup>
                                  <q-tooltip style="font-size:14px">
                                    {{ $t('tooltip.cancelJob')}}
                                  </q-tooltip>
                                </q-btn>
                              </div>
                            </div>
                            <div class="col-auto column" style="width:auto;min-width:300px;font-size:15px;margin:0 0 6px 0;">
                              <q-tabs v-model="dateType" align="left" dense active-color="red-7" indicator-color="red-7" class="col row items-center justify-start">
                                <q-tab name="first" no-caps style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                                  {{ $t('USIM.firstOpen') }}
                                </q-tab>
                                <q-tab name="sale" no-caps style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                                  {{ $t('USIM.saleOpen') }}
                                </q-tab>
                                <q-tab name="expire" no-caps style="font-size: 15px;" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'">
                                  {{ $t('USIM.expire') }}
                                </q-tab>
                              </q-tabs>
                            </div>
                            <div class="col row" style="">
                              <div class="col column" style="border:1px solid #dddddd;border-radius:3px;padding:0 0;margin:0 3px 0 0;font-size:15px;">
                                <div class="col row items-center justify-between" style="cursor: pointer;" @click="showStartDate = !showStartDate">
                                  <div class="col-auto" style="margin:6px;">시작일</div>
                                  <div class="col-auto" style="margin:6px;">{{ popupStartDateTmp }}</div>
                                </div>
                                <div style="border-top:1px solid #dddddd;margin:0 6px;"></div>
                                <q-date minimal flat no-unset calendar="month" color="red-7" v-model="selectedStartDate" @update:model-value="udpateStartDate"></q-date>
                              </div>

                              <div class="col column" style="border:1px solid #dddddd;border-radius:3px;padding:0 0;margin:0 0 0 3px;font-size:15px;">
                                <div class="col row items-center justify-between" style="cursor: pointer;" @click="showEndDate = !showEndDate">
                                  <div class="col-auto" style="margin:6px;">마지막</div>
                                  <div class="col-auto" style="margin:6px;">{{ popupEndDateTmp }}</div>
                                </div>
                                <div style="border-top:1px solid #dddddd;margin:0 6px;"></div>
                                <q-date minimal flat no-unset calendar="month" color="red-7" v-model="selectedEndDate" @update:model-value="udpateEndDate"></q-date>
                              </div>
                            </div>
                          </div>
                        </q-popup-proxy>
                      </q-btn>
                      -->
                    </div>

                    <div class="col row items-center justify-start">
                      <!-- 시작날짜 -->
                      <div class="col-auto row items-center justify-center dateBorder" :style="selectedDateOption == usimDateOptions[0] ? 'pointer-events: none;' : 'pointer-events: auto;'" style="min-width:100px;height:40px;border-radius:3px;">
                        <div :style="searchDateStart.length > 0 ? 'color:black;' : 'color:#606060'" >{{ searchDateStart.length > 0 ? searchDateStart : $t('common.from') }}</div>
                        <q-popup-proxy @before-show="atShowStartDate('start')">
                          <!-- z-index를 이용해 트릭을 쓴 부분이 있음... 
                           디자인은 월/년도를 선택할 수 없게 나왔는데, Q-Date는 월/년도 선택이 가능하게 되어 있어서 그걸 위로 올리고, 월/년 선택부분을 가려버림
                           -->
                          <div class="column" style="width:290px;height:330px;padding:0;position: relative;">
                            <div class="col column items-start justify-center" style="position: absolute;z-index:1;left:0;top:-24px;right:0;bottom:6px;">
                              <q-date class="col-auto" minimal flat no-unset calendar="month" color="red-7" v-model="selectedStartDate" @update:model-value="udpateStartDate"></q-date>
                            </div>
                            <!--적용버튼-->
                            <div class="col row items-end justify-between" style="pointer-events: none;position: absolute;z-index:2;left:12px;top:0;right:12px;bottom:12px;background-color:transparent;">
                              <q-btn class="col-auto" size="md" flat color="grey-7" style="pointer-events: all;width:40px;" icon="today" @click="toToday('start')">
                                <q-tooltip style="font-size:14px;">{{ $t('common.today') }}</q-tooltip>
                              </q-btn>
                              <q-btn no-caps color="red-7" text-color="white" style="pointer-events: all;width:60px;" :label="$t('common.apply')"  @click="doApply('start')" v-close-popup> </q-btn>
                            </div>
                            <!--상단 월 변경 버튼-->
                            <div class="col-auto row items-center justify-between" style="z-index:9;background-color:white;border-bottom:1px solid #cccccc;padding:6px;">
                              <div class="col-auto row items-center justify-start">
                                <q-btn flat dense color="grey-8" icon="keyboard_double_arrow_left" @click="toPrevYear('start')"></q-btn>
                                <q-btn flat dense color="grey-8" icon="chevron_left" @click="toPrevMonth('start')"></q-btn>
                              </div>
                              <div style="font-size:15px;color:#eb0028;font-weight:bold;">{{ displayDate(selectedStartDate) }}</div>
                              <div class="col-auto row items-center justify-end">
                                <q-btn flat dense color="grey-8" icon="chevron_right" @click="toNextMonth('start')"></q-btn>
                                <q-btn flat dense color="grey-8" icon="keyboard_double_arrow_right" @click="toNextYear('start')"></q-btn>
                              </div>
                            </div>
                            <!--테두리용-->
                            <div class="col row items-end justify-end" style="pointer-events: none;position: absolute;z-index:10;left:0;top:0;right:0;bottom:0;border:1px solid #a0a0a0;border-radius:4px"></div>
                          </div>
                        </q-popup-proxy>
                      </div>
                      <div class="col-auto" style="">&nbsp;~&nbsp;</div>
                      <div class="col-auto row items-center justify-center dateBorder" :style="selectedDateOption == usimDateOptions[0] ? 'pointer-events: none;' : 'pointer-events: auto;'" style="min-width:100px;height:40px;border-radius:3px;z-index:1000">
                        <div :style="searchDateStart.length > 0 ? 'color:black;' : 'color:#606060'">{{ searchDateEnd.length > 0 ? searchDateEnd : $t('common.to') }}</div>
                        <q-popup-proxy @before-show="atShowStartDate('end')">
                          <!-- z-index를 이용해 트릭을 쓴 부분이 있음... 
                           디자인은 월/년도를 선택할 수 없게 나왔는데, Q-Date는 월/년도 선택이 가능하게 되어 있어서 그걸 위로 올리고, 월/년 선택부분을 가려버림
                           -->
                          <div class="column" style="width:290px;height:330px;padding:0;position: relative;">
                            <div class="col column items-start justify-center" style="position: absolute;z-index:1;left:0;top:-24px;right:0;bottom:6px;">
                              <q-date class="col-auto" minimal flat no-unset calendar="month" color="red-7" v-model="selectedEndDate" @update:model-value="udpateStartDate"></q-date>
                            </div>
                            <!--적용버튼-->
                            <div class="col row items-end justify-between" style="pointer-events: none;position: absolute;z-index:2;left:12px;top:0;right:12px;bottom:12px;background-color:transparent;">
                              <q-btn class="col-auto" size="md" flat color="grey-7" style="pointer-events: all;width:40px;" icon="today" @click="toToday('end')">
                                <q-tooltip style="font-size:14px;">{{ $t('common.today') }}</q-tooltip>
                              </q-btn>
                              <q-btn no-caps color="red-7" text-color="white" style="pointer-events: all;width:60px;" :label="$t('common.apply')"  @click="doApply('end')" v-close-popup> </q-btn>
                            </div>
                            <!--상단 월 변경 버튼-->
                            <div class="col-auto row items-center justify-between" style="z-index:9;background-color:white;border-bottom:1px solid #cccccc;padding:6px;">
                              <div class="col-auto row items-center justify-start">
                                <q-btn flat dense color="grey-8" icon="keyboard_double_arrow_left" @click="toPrevYear('end')"></q-btn>
                                <q-btn flat dense color="grey-8" icon="chevron_left" @click="toPrevMonth('end')"></q-btn>
                              </div>
                              <div style="font-size:15px;color:#eb0028;font-weight:bold;">{{ displayDate(selectedEndDate) }}</div>
                              <div class="col-auto row items-center justify-end">
                                <q-btn flat dense color="grey-8" icon="chevron_right" @click="toNextMonth('end')"></q-btn>
                                <q-btn flat dense color="grey-8" icon="keyboard_double_arrow_right" @click="toNextYear('end')"></q-btn>
                              </div>
                            </div>
                            <!--테두리용-->
                            <div class="col row items-end justify-end" style="pointer-events: none;position: absolute;z-index:10;left:0;top:0;right:0;bottom:0;border:1px solid #a0a0a0;border-radius:4px"></div>
                          </div>
                        </q-popup-proxy>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width:6pxx;"></div>
                <div class="col-auto row items-top justify-end" style="margin:6px 0 0 0;">
                  <q-btn class="col-auto" no-caps flat dense color="grey-8" icon='search' style="width:36px;height:36px;">
                  </q-btn>
                </div>
              </div>

            </div>
            <div class="col"></div>
            <!-- 엑셀 다운로드 버튼 + 팝업 -->
            <div class="col-auto column items-end justify-end" style="height:100%;">
              <q-btn class="col-auto" flat dense @click="downloadToExcel('emergency')" :disable="isEditConsumable" v-show="canDownloadToExcel" style="margin: 0 12px 0 0;">
                <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;"/>
                <q-popup-proxy ref="excelPopup" style="width:auto;height:220px;background-color: #01010130;border-radius: 3px;position:relative;"
                                @before-show="onShowExportToExcel">
                  <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                    <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px 24px;">
                      <div class="col-auto row items-center justify-center">
                        <div :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'" style="font-size:18px;margin:0 0 12px 0;">
                          {{ $t('common.toExcel') }}
                        </div>
                      </div>
                      <div class="col row items-center justify-start" style="width:100%;margin:0;">
                        <table class="col" style="width:100%;margin:0;">
                          <tr style="">
                            <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.filename') }}</td>
                            <td width="12px" style=""></td>
                            <td style="">{{ excelFilename }}</td>
                          </tr>
                          <tr>
                            <td width=30% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.from') }}</td>
                            <td width="12px"></td>
                            <td>{{ excelFrom }}</td>
                          </tr>
                          <tr>
                            <td width=25% style="text-align: right;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'">{{ $t('common.to') }}</td>
                            <td width="12px"></td>
                            <td>{{ excelTo }}</td>
                          </tr>
                        </table>
                      </div>
                      <xlsx-workbook class="col-auto row items-center justify-center">
                        <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                        <xlsx-download :filename="excelFilename">
                          <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveEmHistoryToExcelFile">{{ $t('common.download') }}</button>
                        </xlsx-download>
                      </xlsx-workbook>
                    </div>

                    <div class="col row items-center justify-center" v-show="!hasExcelData" style="width:100%;height:100%;position:absolute;left:0;top:0;right:0;bottom:0;background-color:white;">
                      <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;' : 'font-family:Prometo;font-weight:500;'" style="font-size:18px;color:#eb0028">{{ $t('warning.nodata')}}</div>
                    </div>
                    
                  </div>
                  <div class="row items-center justify-center" v-show="makingExcel"
                      style="position:absolute;z-index: 2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                    <q-spinner color="red-7" size="70px" thickness="1.5"/>
                  </div>
                </q-popup-proxy>
              </q-btn>
            </div>
          </div>
        </div> 

        <div class="col column" style="border-radius:3px;">
          <div class="col-8 row items-center justify-center" ref="tableContainer" style="background-color:white;border-radius:3px;margin:0 0 12px 0;padding:12px;">
            <q-resize-observer @resize="onResizeTable"></q-resize-observer>
            <q-table dense flat bordered class="col" style="height:100%;"
                      ref="usimTable"
                      separator="cell"
                      virtual-scroll
                      hide-no-data
                      :pagination="curPagination"
                      :rows-per-page-options="maxItemPerPage"
                      :visible-columns="visibleColumns"
                      :columns="itemColumns"
                      :rows="snListItems"
                      :filter="[selectedUSIMState, selectedModel, inputKeyword, selectedDateOption, searchDateStart, searchDateEnd]"
                      :filter-method="tableFilterMethod"
                      @update:pagination="updatePagination">
              <template v-slot:header="props">
                <q-th no-caps
                      style="height:auto;font-size:14px;white-space: normal;" 
                      class="thSticky"
                      v-for="col in props.cols"
                      :key="col.name"
                      :props="props">
                    {{ col.label }}
                </q-th>
              </template>
              <template v-slot:body="props">
                <q-tr :props="props" :style="(selectedRowData != null) && (selectedRowData.Index == props.row.Index) ? 'background-color:#f0f0f0;' : 'background-color:white;'">
                  <q-td key="SN" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;" @click="clickedRow(props.row)">
                    <div class="row items-center justify-start" style="width:180px;">
                      <div style="font-size:14px;">
                        {{ props.row.SN }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="MODEL" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:150px;" @click="clickedRow(props.row)">
                    <div class="row items-center justify-start">
                      <div class="col" style="font-size:14px;">
                        {{ props.row.MODEL }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="MN" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;" @click="clickedRow(props.row)">
                    <div class="row items-center justify-start">
                      <div class="col" style="font-size:14px;">
                        {{ props.row.MN }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="SALE_NAME" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;" @click="clickedRow(props.row)">
                    <div class="row items-center justify-start">
                      <div class="col" style="font-size:14px;" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family: Prometo;'">
                        {{ props.row.SALE_NAME }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="USIM_ICCID" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;" @click="clickedRow(props.row)">
                    <div class="row items-center justify-start">
                      <div class="col" style="font-size:14px;">
                        {{ props.row.USIM_ICCID }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="USIM_FIRST" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;" @click="clickedRow(props.row)">
                    <div class="row items-center justify-start">
                      <div class="col" style="font-size:14px;">
                        {{ getUSIMDate(props.row.USIM_FIRST) }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="SALE_SD" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;" @click="clickedRow(props.row)">
                    <div class="row items-center justify-start" v-show="props.row.USIM_ICCID != null">
                      <div class="col" style="font-size:14px;" >
                        {{ getUSIMDate(props.row.SALE_SD) }}
                      </div>
                    </div>
                  </q-td>
                  <q-td key="SALE_ED" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;padding:0 6px;">
                    <div class="row flex wrap items-center" style="width:100%;height:100%;">
                      <div class="col" style="font-size:14px;" v-show="props.row.USIM_ICCID != null && props.row.SALE_ED != null" @click="clickedRow(props.row)">
                        {{ getUSIMDate(props.row.SALE_ED) }}
                      </div>
                      <div class="col-auto row items-center justify-end" style="margin:3px 0 0 0;">
                        <q-btn unelevated flat dense color="grey-8" size="sm" icon="edit_calendar" v-show="props.row.USIM_ICCID != null && props.row.SALE_SD != null" >
                          <q-popup-proxy @before-show="atShowStartDate('svcExpire', props.row)">
                            <!-- z-index를 이용해 트릭을 쓴 부분이 있음... 
                            디자인은 월/년도를 선택할 수 없게 나왔는데, Q-Date는 월/년도 선택이 가능하게 되어 있어서 그걸 위로 올리고, 월/년 선택부분을 가려버림
                            -->
                            <div class="column" style="width:290px;height:330px;padding:0;position: relative;">
                              <div class="col column items-start justify-center" style="position: absolute;z-index:1;left:0;top:-24px;right:0;bottom:6px;">
                                <q-date class="col-auto" minimal flat no-unset calendar="month" color="red-7" v-model="selectedStartDate" @update:model-value="udpateStartDate"></q-date>
                              </div>
                              <!--적용버튼-->
                              <div class="col row items-end justify-between" style="pointer-events: none;position: absolute;z-index:2;left:12px;top:0;right:12px;bottom:12px;background-color:transparent;">
                                <q-btn class="col-auto" size="md" flat color="grey-7" style="pointer-events: all;width:40px;" icon="today" @click="toToday('start')">
                                  <q-tooltip style="font-size:14px;">{{ $t('common.today') }}</q-tooltip>
                                </q-btn>
                                <q-btn no-caps color="red-7" text-color="white" style="pointer-events: all;width:60px;" :label="$t('common.apply')"  @click="doApply('svcExpire', props.row)" v-close-popup> </q-btn>
                              </div>
                              <!--상단 월 변경 버튼-->
                              <div class="col-auto row items-center justify-between" style="z-index:9;background-color:white;border-bottom:1px solid #cccccc;padding:6px;">
                                <div class="col-auto row items-center justify-start">
                                  <q-btn flat dense color="grey-8" icon="keyboard_double_arrow_left" @click="toPrevYear('start')"></q-btn>
                                  <q-btn flat dense color="grey-8" icon="chevron_left" @click="toPrevMonth('start')"></q-btn>
                                </div>
                                <div style="font-size:15px;color:#eb0028;font-weight:bold;">{{ displayDate(selectedStartDate) }}</div>
                                <div class="col-auto row items-center justify-end">
                                  <q-btn flat dense color="grey-8" icon="chevron_right" @click="toNextMonth('start')"></q-btn>
                                  <q-btn flat dense color="grey-8" icon="keyboard_double_arrow_right" @click="toNextYear('start')"></q-btn>
                                </div>
                              </div>
                              <!--테두리용-->
                              <div class="col row items-end justify-end" style="pointer-events: none;position: absolute;z-index:10;left:0;top:0;right:0;bottom:0;border:1px solid #a0a0a0;border-radius:4px"></div>
                            </div>
                          </q-popup-proxy>
                        </q-btn>
                        <!--
                        <div class="editBtn col-auto" style="width:40px;border:1px solid #eb0028;padding:0 6px;border-radius:3px;text-align:center" v-show="props.row.USIM_ICCID != null && props.row.SALE_SD != null" @click="editExpireDate(props.row)">
                          {{ $t('common.doingEdit') }}
                        </div>
                        -->
                      </div>
                    </div>
                  </q-td>
                  <q-td key="USIM_STATE" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;padding:0 6px;">
                    <div class="row wrap items-center" style="width:100%;height:100%;">
                      <div class="col" style="font-size:14px;" :style="getUSIMStateColor(props.row)" v-show="props.row.USIM_ICCID != null && props.row.SALE_ED != null" @click="clickedRow(props.row)">
                        {{ getUSIMState(props.row.USIM_STATE) }}
                      </div>
                      <div class="col-auto row items-center justify-end" style="margin:3px 0 0 0;">
                        <q-btn unelevated flat dense color="grey-8" size="sm" icon="refresh" v-show="props.row.USIM_ICCID != null && props.row.SALE_SD != null" @click="refreshUSIMInfo(props.row)">
                        </q-btn>
                        <!--
                        <div class="editBtn col-auto" style="width:40px;border:1px solid #eb0028;padding:0 6px;border-radius:3px;text-align:center" >
                          {{ $t('common.doingEdit') }}
                        </div>
                        -->
                      </div>
                    </div>
                  </q-td>
                  <q-td key="ACTION" :props="props" style="border-bottom:1px solid #dddddd;width:75px;padding:-3px;;margin:0;">
                    <div class="actionBtn col row items-center justify-center" style="border-radius:3px;width:70px;cursor:pointer;">
                      <div class="" style="color:white;font-size:14px;" @click="clickedAction(props.row)">액션</div>
                    </div>
                  </q-td>
              </q-tr>
              </template>

              <template v-slot:bottom>
                <div class="row items-center justify-end" style="width:100%;">
                  <q-pagination class=""
                                :max="pageCount"
                                :max-pages="8"
                                v-model="currentPageIndex"
                                outline dense
                                gutter="3px"
                                size="12px"
                                color="grey-9"
                                direction-links
                                boundary-links
                                active-design="unelevated"
                                active-color="red-7"
                                active-text-color="white"
                                @update:model-value="itemPageChanged">
                  </q-pagination>
                </div>
              </template>
            </q-table>
          </div>

          <div class="col row items-start justify-start" style="background-color:white;border-radius:3px;padding:12px;">
            <div class="col-4 column" style="padding:12px;border:1px solid #dddddd;border-radius:3px;min-width:360px;height:100%;">
              <div class="col-auto" style="font-size:15px;font-weight:500;margin:0 0 6px 0;">판매 정보</div>
              <div class="col column" style="position:relative;">
                <q-scroll-area class="col row" style="border-radius:0px;border-top:2px solid #808080;border-bottom:2px solid #808080;padding:3px 12px 3px 3px;">
                  <table cellspacing="0" style="width:100%;font-size:15px;">
                    <tr height="32px">
                      <td width="80px;" class="saleInfoCell">{{ $t('machineInfo.owner') }}</td>
                      <td class="saleInfoCell">{{ (selectedRowData != null) ? selectedRowData.SALE_NAME : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell saleInfoTable">{{ $t('machineInfo.mobile') }}</td>
                      <td class="saleInfoCell saleInfoTable">{{ (selectedRowData != null) ? selectedRowData.SALE_PHONE : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell">{{ $t('machineInfo.address') }}</td>
                      <td class="saleInfoCell">{{ (selectedRowData != null) ? selectedRowData.SALE_ADDRESS : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell saleInfoTable">{{ $t('machineInfo.model') }}</td>
                      <td class="saleInfoCell saleInfoTable">{{ (selectedRowData != null) ? selectedRowData.MODEL : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell">{{ $t('machineInfo.vin') }}</td>
                      <td class="saleInfoCell">{{ (selectedRowData != null) ? selectedRowData.MN : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell saleInfoTable">{{ $t('machineInfo.manDate') }}</td>
                      <td class="saleInfoCell saleInfoTable">{{ (selectedRowData != null) ? getProdDate(selectedRowData.SALE_PD) : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell">{{ $t('machineInfo.saleDate') }}</td>
                      <td class="saleInfoCell">{{ (selectedRowData != null) ? getSaleDate(selectedRowData.SALE_SD) : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell saleInfoTable">{{ $t('common.salePlace') }}</td>
                      <td class="saleInfoCell saleInfoTable">{{ (selectedRowData != null) ? selectedRowData.SALE_DEALER : ''}}</td>
                    </tr>
                  </table>
                </q-scroll-area>
              </div>
            </div>
            <div class="col-3 column" style="margin:0 12px;padding:12px;border:1px solid #dddddd;border-radius:3px;min-width:360px;height:100%;">
              <div class="col-auto" style="font-size:15px;font-weight:500;margin:0 0 6px 0;">단말기 정보</div>
              <div class="col column" style="position:relative;">
                <q-scroll-area class="col row" style="border-radius:0px;border-top:2px solid #808080;border-bottom:2px solid #808080;padding:3px 12px 3px 3px;">
                  <table cellspacing="0" style="width:100%;font-size:15px;">
                    <tr height="32px">
                      <td width="130px" class="saleInfoCell">{{ $t('machineInfo.serialNo') }}</td>
                      <td class="saleInfoCell">{{ (selectedRowData != null) ? selectedRowData.SN : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell saleInfoTable">ICCID</td>
                      <td class="saleInfoCell saleInfoTable">{{ (selectedRowData != null) ? selectedRowData.USIM_ICCID : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell">USIM</td>
                      <td class="saleInfoCell">{{ (selectedRowData != null) ? selectedRowData.USIM : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell saleInfoTable">{{ $t('USIM.firstOpen') }}</td>
                      <td class="saleInfoCell saleInfoTable">{{ (selectedRowData != null) ? getUSIMDate(selectedRowData.USIM_FIRST) : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell">{{ $t('USIM.saleOpen') }}</td>
                      <td class="saleInfoCell">{{ (selectedRowData != null) ? getUSIMDate(selectedRowData.SALE_SD) : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell saleInfoTable">{{ $t('USIM.expire') }}</td>
                      <td class="saleInfoCell saleInfoTable">{{ (selectedRowData != null) ? getUSIMDate(selectedRowData.SALE_ED) : ''}}</td>
                    </tr>
                    <tr height="32px">
                      <td class="saleInfoCell">{{ $t('USIM.state') }}</td>
                      <td class="saleInfoCell" :style="getUSIMStateColor(selectedRowData)">{{ (selectedRowData != null) ? getUSIMState(selectedRowData.USIM_STATE) : ''}}</td>
                    </tr>
                  </table>
                </q-scroll-area>
              </div>
            </div>
            <div class="col column" style="padding:12px;border:1px solid #dddddd;border-radius:3px;min-width:360px;height:100%;">
              <div class="col-auto" style="font-size:15px;font-weight:500;margin:0 0 6px 0;">단말기 이력</div>
              <div class="col column" style="position:relative;">
                <q-scroll-area class="col row" style="border-radius:0px;border-top:2px solid #808080;border-bottom:2px solid #808080;">
                  
                </q-scroll-area>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.green-7 {
  color:#00ffff;
}
.title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.expireBtn {
  color: black;
  cursor:pointer;
}
.expireBtn:hover {
  color: #eb0028b0;
}
.expireBtn:active {
  color: #eb0028;
}
.dateBorder {
  border:1px solid #cccccc;
  cursor:pointer;
}
.dateBorder:hover {
  border:1px solid #eb0028b0;
  cursor:pointer;
}
.dateBorder:active {
  border:2px solid #eb0028;
  cursor:pointer;
}
.editBtn {
  background-color: transparent;
  color:black;
  font-weight: 400;
}
.editBtn:hover {
  background-color: #eb002880;
  color:black;
  font-weight: 500;
}
.editBtn:active {
  background-color: #eb0028;
  color:white;
}

.divDate {
  border:1px solid #bbbbbb;
}
.divDate:hover {
  border:1px solid #000;
}
.divDate:active {
  border:2px solid #eb0028;
}
.actionBtn {
  background-color: #cccccc;
}
.actionBtn:hover {
  background-color: #eb0028;
}
.actionBtn:active {
  background-color: #db0018;
}  
.saleInfoCell {
  padding: 0 6px 0 3px;
}
.saleInfoTable {
  border-top:1px solid #cccccc;
  border-bottom:1px solid #cccccc;
  background-color: #eeeeeeA0
}
@media all and (min-height:1024px) {
  .topLeft {
    height:800px;
  }
  .topRight {
    min-height:320px;
  }
  .bottomCenter {
    height:auto;
  }
}

@media all and (min-height:800px) and (max-height:1023px) {
  .topLeft {
    min-height:640px;
  }
  .topRight {
    min-height:320px;
  }
}

@media all and (min-height:640px) and (max-height:799px) {
  .topLeft {
    min-height:500px;
  }
  .topRight {
    min-height:320px;
  }
}

@media all and (min-height:480px) and (max-height:639px) {
  .topLeft {
    min-height:360px;
  }
  .topRight {
    min-height:70%;
  }
}

@media all and (max-height:479px) {
  .topLeft {
    min-height:320px;
  }
  .topRight {
    min-height:70%;
  }
}

@media all and (min-width:1024px) {
  .topLeft {
    min-width:480px;
  }
  .topRight {
    width:320px;
  }
}

@media all and (min-width:800px) and (max-width:1023px) {
  .topLeft {
    width:100%;
  }
  .topRight {
    width:100%;
    border:1px solid red;
  }
}

@media all and (min-width:640px) and (max-width:799px) {
  .topLeft {
    width:100%;
  }
  .topRight {
    width:100%;
    border:1px solid red;
  }
}

@media all and (min-width:480px) and (max-width:639px) {
  .topLeft {
    width:100%;
  }
  .topRight {
    width:100%;
    border:1px solid blue;
  }
}

.treeBorder {
  border-radius: 3px;
  background-color: white;
  margin:3pt 0pt 0 3pt;
}

.treeContext {
  font-size : 14px;
}

.tableTop {
  border-bottom:1px solid #cccccc;
}
.tableMiddle {
  border-bottom:1px solid #cccccc;
}
.tableBottom {
  border-bottom:2px solid #cccccc;
}
.tableLabel {
  font-weight:500;
  width:20%;
  height:32px;
}
.thSticky {
  position: sticky;
  z-index: 3;
  top:0;
  background-color: white;
  font-size:14px;
  font-weight: 500;
}

</style>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTymictStore } from '@/store/tymict'
import TymFncs from '@/js/tymfunctions.js'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants.js'
import TymAws from '@/js/tymaws.js'
import AWS from 'aws-sdk'
//import { AuthenticationDetails } from "amazon-cognito-identity-js";
import moment from 'moment-timezone'

export default ({
  components : {
  },
  data() {
    //const i18n = useI18n()
    return {
    }
  },
  setup() {
    const i18n = useI18n()

    return {
      snListItems: ref([]),
      inputKeyword: ref(''),

      usimStates: ref([
        i18n.t('common.all'),
        i18n.t('USIM.stateActive'),
        i18n.t('USIM.statePaused'),
        i18n.t('USIM.stateExpired'),
      ]),
      selectedUSIMState: ref(i18n.t('common.all')),
      modelValues: ref([]),
      selectedModel: ref(i18n.t('common.all')),

      dateType: ref('expire'),
      popupStartDate: ref(null),
      popupEndDate: ref(null),
      selectedStartDate: ref(''),
      selectedEndDate: ref(''),
      popupStartDateTmp: ref(''),
      popupEndDateTmp: ref(''),

      searchDateStart: ref(''),
      searchDateEnd: ref(''),

      usimCounters: ref([
        {
          Title: i18n.t('USIM.stateActive'),
          Color: '#00b000',
          PGColor: 'green-4',
          Count: 60,
          Percent: 0.7
        },
        {
          Title: i18n.t('USIM.statePaused'),
          Color: '#d08000',
          PGColor: 'brown-4',
          Count: 20,
          Percent: 0.2
        },
        {
          Title: i18n.t('USIM.stateExpired'),
          Color: '#eb0028',
          PGColor: 'red-6',
          Count: 31,
          Percent: 0.31
        },
      ]),
      usimCloseExpired: ref({
        Title: i18n.t('USIM.stateCloseExpired'),
        Color: '#000',
        Count: 20,
        Percent: -1     // 이 값은 0보다 작아야 함...
      }),

      selectedDateOption: ref(i18n.t('USIM.noOption')),
      usimDateOptions: ref([
        i18n.t('USIM.noOption'),
        i18n.t('USIM.firstOpen'),
        i18n.t('USIM.saleOpen'),
        i18n.t('USIM.expire')
      ]),
      selectedRowData: ref(null),
      curPagination : ref({
        sortBy : 'none',
        descending : false,
        page : 1,
        rowsPerPage : 20
      }),
      maxItemPerPage : ref([5, 15]),
      currentPageIndex: ref(1),
      visibleColumns: ref(['SN', 'MODEL', 'MN', 'USER', 'USIM_ICCID', 'USIM_FIRST', 'SALE_SD', 'SALE_ED', "USIM_STATE" /*, "ACTION" */]),
      itemColumns: ref([
        { name: 'index', required: false },
        {
          name: 'SN',
          required: true,
          label: i18n.t('customer.serialNo'),
          align: 'left',
          field: 'SN',
          sortable: true
        },
        {
          name: 'MODEL',
          required: true,
          label: i18n.t('customer.modelName'),
          align: 'left',
          field: 'MODEL',
          sortable: true
        },
        { 
          name: 'MN',
          required: true,
          align: 'left',
          label: i18n.t('common.vin'),
          field: 'MN',
          sortable: true
        },
        {
          name: 'SALE_NAME',
          required: true,
          align: 'left',
          label: i18n.t('dashboardView.customer'),
          field: 'SALE_NAME',
          sortable: true
        },
        {
          name: 'USIM_ICCID',
          align: 'left',
          label: 'ICCID',
          field: 'USIM_ICCID',
          sortable: true,
        },
        {
          name: 'USIM_FIRST',
          align: 'left',
          label: i18n.t('USIM.firstOpen'),
          field: 'USIM_FIRST',
          sortable: true,
          sort: (a, b, rowA, rowB) => rowA.USIM_FIRST.localeCompare(rowB.USIM_FIRST)
        },
        {
          name: 'SALE_SD',
          align: 'left',
          label: i18n.t('USIM.saleOpen'),
          field: 'SALE_SD',
          sortable: true,
          sort: (a, b, rowA, rowB) => {
            return rowA.SALE_SD.localeCompare(rowB.SALE_SD)
          },
        },
        {
          name: 'SALE_ED',
          align: 'left',
          label: i18n.t('USIM.expire'),
          field: 'SALE_ED',
          sortable: true,
          sort: (a, b, rowA, rowB) => {
            return rowA.SALE_ED.localeCompare(rowB.SALE_ED)
          },
        },
        {
          name: 'USIM_STATE',
          align: 'left',
          label: i18n.t('USIM.state'),
          field: 'USIM_STATE',
          sortable: true,
          sort: (a, b, rowA, rowB) => {
            if(TymCommon.isEmpty(rowA.SALE_SD) && TymCommon.isEmpty(rowB.SALE_SD)) {
              return 0 // rowA.SN.localeCompare(rowB.SN)
            } else {
              if(TymCommon.isEmpty(rowA.SALE_SD)) {
                return -1
              } else if(TymCommon.isEmpty(rowB.SALE_SD)) {
                return 1
              }

              return rowA.USIM_STATE.localeCompare(rowB.USIM_STATE)
            }
          },
        },
        {
          name: 'ACTION',
          align: 'left',
          label: i18n.t('USIM.work'),
          field: 'ACTION',
          sortable: false
        }
      ]),
    }
  },
  computed:{
    isDev() {
      return (TymConst.IS_DEVELOPMENT)
    },
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean() {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    isKorea() {
      const store = useTymictStore()
      return (store.connectServer == 0)
    },
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return store.canDownloadToExcel()
    },
    getUSIMDate : function() {
      return function(d) {
        if(!TymCommon.isEmpty(d)) {
          return TymCommon.getDateString(new Date(d))
        }
      }
    },

    getUSIMState : function() {
      /*
      "state1": "사전 활성화",
      "state2": "서비스 정상",
      "state3": "서비스 중단",
      "state4": "서비스 만료",
      "state5": "서비스 오류",
      */
      const i18n=useI18n()
      return function(v) {
        if(!TymCommon.isEmpty(v)) {
          switch(Number(v)) {
            case 1:
              return i18n.t('USIM.state1')
            case 2:
              return i18n.t('USIM.state2')
            case 3:
              return i18n.t('USIM.state3')
            case 4:
              return i18n.t('USIM.state4')
            case 5:
              return i18n.t('USIM.state5')
          }
        }
        return i18n.t('USIM.state0')
      }
    },
  },
  watch : {
  },

  created() {},
  mounted() {
    const store = useTymictStore()
    if(store.CurrentPage.Now != TymConst.PAGE_USIM) {
      store.setPage(TymConst.PAGE_USIM)
    }
    setTimeout(() => {
      this.reloadData()
    }, 5)    
  }, 
  unmounted() {
    /*
    sessionStorage.setItem('USIM.saved', JSON.stringify({
      Keyword: this.filterKeyword,
    }))
    */
  },
  methods : {
    unusedParam() {
      //
    },
    getProdDate(d) {
      return TymCommon.PD2DateString(d)
    },
    getSaleDate(d) {
      return TymCommon.SD2DateString2(d)
    },
    getUSIMStateColor(row) {
      if(!TymCommon.isEmpty(row)) {
        if(!TymCommon.isEmpty(row.USIM_STATE)) {
          /*
          "state1": "사전 활성화",
          "state2": "서비스 정상",
          "state3": "서비스 중단",
          "state4": "서비스 만료",
          "state5": "서비스 오류",
          */
          switch(Number(row.USIM_STATE)) {
            case 1:
              return "color:gray;"
            case 2:
              return "color:green;"
            case 3:
              return "color:brown;"
            case 4:
              return "color:red;"
            case 5:
              return "color:blue;"
          }
        }
      }
      return "color:black;"
    },
    onResizeTable() {
      if(!this.$refs.tableContainer) {
        this.maxItemPerPage[1] = 15
      } else { 
        // 48 36
        let rowsPerPage = Math.floor((this.$refs.tableContainer.clientHeight - 72) / 32)
        this.maxItemPerPage[1] = rowsPerPage
      }
      this.curPagination.rowsPerPage = this.maxItemPerPage[1]
      this.pageCount = Math.ceil(this.snListItems.length / this.maxItemPerPage[1])
      console.log('USIM.onResizeTable :', this.curPagination, this.pageCount)
    },
    itemPageChanged(value) {
      console.log('USIM.itemPageChanged :', this.curPagination, value)
      this.curPagination.page = value
    },
    updatePagination(newPagination) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('USIMViewer.updatePagination :', newPagination)
      }
      if(this.$refs.itemTable) {
        this.itemListRows.sort((a, b) => {
          let result = 0
          switch(newPagination.sortBy) {
            case 'model':
              if(a.Info.Model == b.Info.Model) {
                result = a.Info.Option - b.Info.Option  
              } else {
                result = a.Info.Model - b.Info.Model
              }
              break
            case 'machineNo':
              result = a.machineNo - b.machineNo
              break
            case 'serial':
              result = a.serialNo.localeCompare(b.serialNo)
              break
            case 'saleDate':
              result = a.SD.localeCompare(b.SD)
              break
            case 'manDate':
              result = a.PD.localeCompare(b.PD)
              break
            case 'owner':
              result = a.owner - b.owner
              break
            case 'dealer':
              result = a.dealer - b.dealer
              break
          }
          if(newPagination.descending)
            result = 0 - result
            
          if(result > 0)
            return 1
          else if(result < 0)
            return -1
          else 
            return 0
        })
      }
      this.curPagination = newPagination
      this.curPage = newPagination.page
    },
    reloadData() {
      const store = useTymictStore()

      this.modelValues.push(this.$t('common.all'))
      let tempAr = []
      store.ModelData.data.forEach(md => {
        if (!tempAr.find(x => x == md.model)) {
          tempAr.push(md.model)
        }
      })
      tempAr.sort((a, b) => a.localeCompare(b))
      this.modelValues = this.modelValues.concat(tempAr)

      /*
      전화번호 빈 관리자 찾기 - 시험용 코드
      let emptyPhone = store.managerList.filter(x => TymCommon.isEmpty(x['custom:uphone']))
      console.log(emptyPhone)
      */
      this.snListItems = []
      /*
      TymAws.getAllProductData()
        .then(data => {
          console.log('reloadData from PDList :', data)
          // {
          //   "SOLD": 0,
          //   "MN": "RFVC00148",
          //   "PD": "2024-07",
          //   "TESTC": 1,
          //   "OD": "2024-07-09",
          //   "MON": "K110E_NONE",
          //   "SN": "T002241900060006",
          //   "UID": "registration1"
          // }
        })
        .catch(er => {
          console.log('reloadData from PDList ERROR :', er)
        })
      */
      TymAws.getSNInfoData()
        .then(data => {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('reloadData from SN_Info :', data)
          }

          /*
          {
            "Update": "2024-05-24T16:24",
            "MN": "RS130X00084",
            "ASU_TYPE": "0",
            "D_TYPE": "None",
            "USIM": "Global",
            "MW": "2417",
            "ADS_LV": "0",
            "SN": "T002241700140014",
            "MODEL": "TS130_CP6ANBV"
          }
          */
          data.Items.forEach(item => {
            if(TymCommon.isEmpty(item.ICCID)) {
              // 20240724 - ICCID가 없으면 표시하지 말자. 단순 내 생각임...
              // console.log('NO ICCID :', item)
              return
            }

            let upDate = ''
            if(!TymCommon.isEmpty(item.Update)) {
              if(item.Update.length > 8) {
                upDate = TymCommon.convertStringToDateTime2(item.Update)
              } else {
                let temp = moment.utc(item.Update).format("YYYY-MM-DD HH:mm")
                upDate = TymCommon.convertStringToDateTime2(temp)
              }
            }
            let model = null
            if(!TymCommon.isEmpty(item.MODEL))
              model = TymCommon.getModelFullname(item.MODEL)
            else {
              console.log('NO MODEL :', item.SN, item.MODEL)
            }

            let snItem = {
              MN: item.MN,
              SN: item.SN,
              MON: item.MODEL,
              MODEL: TymCommon.isEmpty(model) ? '' : model.Fullname,
              MW: item.MW,
              UPD: upDate,
              Update: item.Update,
              USIM: item.USIM,
              USIM_ICCID: TymCommon.isEmpty(item.ICCID) ? null : item.ICCID,
              USIM_FIRST: null,    // 최초개통일자
              USIM_SALE: null,     // 판매개통일자
              USIM_EXPIRE: null,   // 만료일자
              USIM_STATE: null,
              Group: null,
              SALE_ID: null,
              SALE_NAME: null,
              SALE_PHONE: null,
              SALE_ADDRESS: null,
              SALE_DEALER: null,
              SALE_SD: null,
              SALE_PD: null,       // 생산일
              SALE_ED: null        // 만료일
            }
            let sale = store.SalesData.data.find(x => x.serialNo == item.SN)
            if(sale) {
              snItem.Group = {
                MA: sale.uuid_a,
                MB: TymCommon.isEmpty(sale.uuid_b) ? '' : sale.uuid_b,
                MC: TymCommon.isEmpty(sale.uuid_c) ? '' : sale.uuid_c,
                MD: TymCommon.isEmpty(sale.uuid_d) ? '' : sale.uuid_d
              }
              snItem.SALE_ID = sale.uid
              snItem.SALE_NAME = sale.owner
              snItem.SALE_PHONE = sale.mobile
              snItem.SALE_ADDRESS = sale.address
              snItem.SALE_DEALER = sale.dealer
              snItem.SALE_SD = sale.SD
              snItem.SALE_PD = sale.PD

              let ed = ''
              if(TymCommon.isEmpty(sale.ED)) {
                let saleDate = moment(sale.SD)
                if(this.isKorea) {
                  ed = saleDate.add(5, 'years')
                } else {
                  ed = saleDate.add(3, 'years')
                }
                ed.add(-1, 'days')
                //ed = this.getUSIMDate(ed.toDate())
                console.log(sale.SD, ed)
              } else {
                ed = sale.ED
              }

              snItem.SALE_ED = ed
            }
            this.snListItems.push(snItem)
          })
          this.snListItems.sort((a, b) => {
            if(TymCommon.isEmpty(a.Update))  {
              return 1
            } else if(TymCommon.isEmpty(b.Update))  {
              return -1
            }
            return b.Update.localeCompare(a.Update)
          })

          for(let idx = 0; idx < this.snListItems.length; idx++) {
            this.snListItems[idx].Index = idx
          }
          
          if(TymConst.IS_DEVELOPMENT) {
            console.log('SNInfo + Sales :', this.snListItems)
          }
          this.applyUSIMInfo()

          this.onResizeTable()
        })
        .catch(er => {
          console.log('this.getSNInfoData ERRPR :', er)
          this.onResizeTable()
        })
    },
    clickedRow(row) {
      if(TymCommon.isEmpty(this.selectedRowData)) {
        this.selectedRowData = row
      } else {
        if(this.selectedRowData.Index == row.Index) {
          this.selectedRowData = null
        } else {
          this.selectedRowData = row
        }
      }

      this.updateOneDevice(this.selectedRowData)
    },
    updateOneDevice(row) {
      console.log('updateOneDevice ', row)
    },
    clickedAction(row) {
      console.log('clickedAction :', row)
    },
    makePhone(src) {
      const store = useTymictStore();
      let srcPhone = src.replaceAll('-', '')
      let tmpPrefix = ''
      let dstPhone = {
        local: '',
        global: ''
      }

      srcPhone = srcPhone.trim()

      if(srcPhone[0] == '+') {
        switch(store.connectServer) {
          case 0:
            if(srcPhone[1] == '1') {
              srcPhone = srcPhone.replaceAll('+', '0')
            } else {
              srcPhone = srcPhone.replaceAll('+', '')
            }
            break
          case 1:
          if(srcPhone[1] == '1') {
            srcPhone = srcPhone.replaceAll('+1', '')
          } else {
            srcPhone = srcPhone.replaceAll('+', '')
          }
          break
        }
      } else {
        switch(store.connectServer) {
          case 0:
            if(srcPhone[0] != '0') {
              srcPhone = '0' + srcPhone
            }
            if(srcPhone[1] != '1') {
              // 휴대전화 아니면?

            }
            break
        }
      }

      switch(store.connectServer) {
        case 0:
        tmpPrefix = '+82'
          break
        case 1:
          tmpPrefix = '+1'
          break
      }

      dstPhone.local = srcPhone
      dstPhone.global = tmpPrefix + srcPhone
      return dstPhone
    },
    // Cognito 관리자 전화번호 업데이트용
    doUpdatePhone() {
      const store = useTymictStore();

      console.log(store.managerList)

      let tgtID = ''
      let srcPhone = ''
      let phoneNumber = {}

      let tempManager = []
      store.managerList.forEach(manager => {
        srcPhone = manager['custom:uphone']
        phoneNumber = this.makePhone(srcPhone)
        tgtID = manager.userid

        /*
        if(phoneNumber.local != srcPhone) {
          console.log('ID/NEW PHONE: ', tgtID, manager.email, srcPhone, phoneNumber.local, phoneNumber.global)
        } else {
          console.log('ID/SAME PHONE: ', tgtID, manager.email, srcPhone, phoneNumber.local, phoneNumber.global)
        }
        */
        tempManager.push({
          ID: tgtID,
          Name: manager.name,
          Email: manager.email,
          Origin: "'" + srcPhone, 
          Local: "'" + phoneNumber.local, 
          Global: "'" + phoneNumber.global
        })
        if(TymConst.IS_DEVELOPMENT == 0) {
          let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
          let param = {
            UserPoolId: store.cognito[store.connectServer].userPoolId,
            Username: tgtID,
            UserAttributes: [
              {
                Name: "phone_number",
                Value: phoneNumber.global,
              },
              {
                Name: "phone_number_verified",
                Value: "true",
              },
            ],
          };

          if(phoneNumber.local != srcPhone) {
            param.UserAttributes.push({
              Name: "custom:uphone",
              Value: phoneNumber.local,
            })
          }
          let self = this;
          cognitoidentityserviceprovider.adminUpdateUserAttributes(param,
            function (err, data) {
              if (err) {
                //사용자 추가 에러.
                console.log(err, err.code); // an error occurred
                if (err.code == "UsernameExistsException") {
                  //tym_alert_yesno(i18n.t('code.ERR_INVALID_ID'), 1);
                  TymCommon.Toast(self.$t('group.usedID'))
                } else {
                  TymCommon.Toast(err.message, true)
                }
              } else {
                console.log('update OK', data)
              }
            }
          );
        }
      })
      console.log('ID/PHONE LIST :', tempManager) 
    },
    // API를 통해 받은 USIM상태를 화면에 표시하도록 변환한다.
    // usim : USIM테이블 데이터의 아이템
    // snItem : SN_info에 들어 있는 해당 아이템
    convUsimState(usim, snItem) {
      let res = 0
      if(!TymCommon.isEmpty(usim)) {
        const store = useTymictStore()
        if(!TymCommon.isEmpty(usim.state)) {
          let sale = store.SalesData.data.find(x => x.machineNo == snItem.MN)
          if(!TymCommon.isEmpty(sale)) {
            let now = moment()
            let sd = moment(sale.SD)
            let ed = moment(sale.ED)

            /*
            "state1": "사전 활성화",
            "state2": "서비스 정상",
            "state3": "서비스 중단",
            "state4": "서비스 만료",
            "state5": "서비스 오류",
            */
            switch(Number(usim.state)) {
              case 1: // pre activated
                res = 1
                break
              case 2:// activated
                if(sd <= now && now <= ed) {
                  res = 2
                } else {
                  res = 3
                }
                break
              case 3: // pre inactivated
                break
              case 4:// Closed
                break
              case 5: // Internal stocked
                break
            }
          }
        }
      }
      return res
    },
    applyUSIMInfo() {
      TymAws.getUSIM()
        .then(data => {
          // console.log('getUSIM : ', data)
          if(this.snListItems.length > 0) {
            data.forEach(usim => {
              let found = this.snListItems.find(x => x.USIM_ICCID == usim.device_id)
              if(found) {
                if(!TymCommon.isEmpty(usim.package_order))
                  found.package_order = JSON.parse(usim.package_order)
                if(!TymCommon.isEmpty(usim.usim_data))
                  found.usim_data = JSON.parse(usim.usim_data)

                if(!TymCommon.isEmpty(usim.start_time)) {
                  let state = this.convUsimState(usim, found)
                  // console.log('STATE :', usim.device_id, usim.state, state)
                  /*
                  "state0": "Unknown",
                  "state1": "Pre activated",
                  "state2": "Activated",
                  "state3": "Pre inactivated",
                  "state4": "Closed",
                  "state5": "Internal stocked",
                  */
                  state = usim.state
                  found.USIM_FIRST = usim.start_time
                  found.USIM_EXPIRE = usim.end_time
                  found.USIM_STATE = state
                }
              }
            })
          }
          //let cwlee = this.snListItems.filter(x => x.SN.indexOf('CWLEE') >= 0)
          //console.log(data, cwlee)
        })
        .catch(er => {
          console.log('AWS.getUSIM error :', er)
        })
    },
    doClosePopup() {
      this.$refs.datePopup.hide()
    },
    popupDateSelect(option) {
      switch(option) {
      case 'datePopup':
        this.resetSearchingTerm()
        break;
      }
    },
    udpateStartDate(value, reason, detail){
      this.unusedParam(value, reason, detail)
      let selDate = new Date(detail.year, detail.month - 1, detail.day, 0, 0, 0, 0)
      this.popupStartDateTmp = TymCommon.getDateString(selDate)
    },
    udpateEndDate(value, reason, detail){
      this.unusedParam(value, reason, detail)
      let selDate = new Date(detail.year, detail.month - 1, detail.day, 0, 0, 0, 0)
      this.popupEndDateTmp = TymCommon.getDateString(selDate)
    },
    resetSearchingTerm() {
      let now = new Date

      this.popupEndDateTmp = TymCommon.getDateString(now)
      this.selectedEndDate = moment(now).format('YYYY/MM/DD')
      now.setDate(1)
      this.popupStartDateTmp = TymCommon.getDateString(now)
      this.selectedStartDate = moment(now).format('YYYY/MM/DD')
      this.dateType = 'expire'

      this.searchDateStart = ''
      this.searchDateEnd = ''
    },
    applySearchingTerm() {
      /*
      switch(this.dateType) {
      case 'first':
        this.searchDateOption = this.$t('USIM.firstOpen')
        break
      case 'sale':
        this.searchDateOption = this.$t('USIM.saleOpen')
        break
      case 'expire':
        this.searchDateOption = this.$t('USIM.expire')
        break
      default:
        this.searchDateOption = ''
        break
      }
      */

      this.searchDateStart = TymCommon.getDateString(moment(this.selectedStartDate).toDate())
      this.searchDateEnd = TymCommon.getDateString(moment(this.selectedEndDate).toDate())

      console.log(this.searchDateOption, this.searchDateStart, this.searchDateEnd)
      this.$refs.datePopup.hide()
    },
    // 검색 날짜 옵션 변경시 from q-select
    changedSearchDateOption(value) {
      console.log('changedSearchDateOption :', value)
      if(value == this.usimDateOptions[0]) {
        this.searchDateStart = ''
        this.searchDateEnd = ''
      } else {
        let day = moment()
        this.searchDateEnd = TymCommon.getDateString(day.toDate())
        day.date(1)
        this.searchDateStart = TymCommon.getDateString(day.toDate())
      }
    },
    displayDate(date) {
      if(!TymCommon.isEmpty(date))
        return TymCommon.getDateString(moment(date).toDate())
      return ''
    },
    atShowStartDate(option, rowData) {
      let day = null
      switch(option) {
        case 'start':
          if(!TymCommon.isEmpty(this.searchDateStart)) {
            day = moment(this.searchDateStart).format('YYYY/MM/DD')
          } else {
              day = moment(new Date())
              day.date(1)
              day = day.format('YYYY/MM/DD')
          }
          this.selectedStartDate = day
          break
        case 'end':
          if(!TymCommon.isEmpty(this.searchDateEnd)) {
            day = moment(this.searchDateEnd).format('YYYY/MM/DD')
          } else {
              day = moment(new Date())
              day = day.format('YYYY/MM/DD')
          }
          this.selectedEndDate = day
          break
        case 'svcExpire':
          console.log('svcExpire', rowData)
          if(!TymCommon.isEmpty(rowData.SALE_ED)) {
            day = moment(rowData.SALE_ED).format('YYYY/MM/DD')
          } else {
            day = moment(new Date())
            day = day.format('YYYY/MM/DD')
          }
          break
      }
    },
    toPrevMonth(option) {
      if(TymCommon.isEmpty((option == 'start') ? this.selectedStartDate : this.selectedEndDate))
        return
      let day = moment((option == 'start') ? this.selectedStartDate : this.selectedEndDate)
      day = day.add(-1, 'months')
      if(option == 'start')
        this.selectedStartDate = day.format('YYYY/MM/DD')
      else
        this.selectedEndDate = day.format('YYYY/MM/DD')
    },
    toPrevYear(option) {
      if(TymCommon.isEmpty((option == 'start') ? this.selectedStartDate : this.selectedEndDate))
        return
      let day = moment((option == 'start') ? this.selectedStartDate : this.selectedEndDate)
      day = day.add(-1, 'years')
      if(option == 'start')
        this.selectedStartDate = day.format('YYYY/MM/DD')
      else
        this.selectedEndDate = day.format('YYYY/MM/DD')
    },
    toNextMonth(option) {
      if(TymCommon.isEmpty((option == 'start') ? this.selectedStartDate : this.selectedEndDate))
        return
      let day = moment((option == 'start') ? this.selectedStartDate : this.selectedEndDate)
      day = day.add(1, 'months')
      if(option == 'start')
        this.selectedStartDate = day.format('YYYY/MM/DD')
      else
        this.selectedEndDate = day.format('YYYY/MM/DD')
    },
    toNextYear(option) {
      if(TymCommon.isEmpty((option == 'start') ? this.selectedStartDate : this.selectedEndDate))
        return
      let day = moment((option == 'start') ? this.selectedStartDate : this.selectedEndDate)
      day = day.add(1, 'years')
      if(option == 'start')
        this.selectedStartDate = day.format('YYYY/MM/DD')
      else
        this.selectedEndDate = day.format('YYYY/MM/DD')
    },
    toToday(option) {
      let day = moment()

      switch(option) {
        case 'start':
          this.selectedStartDate = day.format('YYYY/MM/DD')
          break
        case 'end':
          this.selectedEndDate = day.format('YYYY/MM/DD')  
          break
        default:
          this.selectedStartDate = day.format('YYYY/MM/DD')
          break
      }
    },
    doApply(option, row) {
      console.log('doApply :', option, row)
      let day = null
      switch(option) {
        case 'start':
          day = moment(this.selectedStartDate).toDate()
          this.searchDateStart = TymCommon.getDateString(day)
          break
        case 'end':
          day = moment(this.selectedEndDate).toDate()
          this.searchDateEnd = TymCommon.getDateString(day)
          break
        case 'svcExpire':
          // "2029-07-15T09:00"
          day = moment.utc(this.selectedStartDate)
          day = day.format('YYYY-MM-DDT23:59:59')
          row.SALE_ED = day
          {
            let info = {
              MN: row.MN,
              KV: [
                {
                  key: 'ED',
                  value: row.SALE_ED
                }
              ]
            }
            TymAws.editSalesInfo(info)
              .then(data => {
                this.unusedParam(data)
                // console.log('----- OK ', data)
              })
              .catch(er => {
                console.log('----- ER ', er)
              })
          }
          this.selectedStartDate = day

          break
      }
    },

    // 유심만료예정 - 클릭
    // 시나리오상에선 만료 예정 30일이하인 기대만 표시
    clickedCloseExpire() {

    },
    refreshUSIMInfo(row) {
      console.log('refreshUSIMInfo :', row)
      TymAws.requestUdpateUSIM(row.USIM_ICCID)
        .then(data => {
          console.log('request USIM :', row.USIM_ICCID, data)
        })
        .catch(er => {
          console.log('request USIM FAILED :', row.USIM_ICCID, er)
        })
    },
    tableFilterMethod(src, filter, columns) {
      let dstItems = src

      console.log('tableFilterMethod :', filter)
      if(!TymCommon.isEmpty(filter[0])) {
        let usimState = this.usimStates.findIndex(x => x == filter[0])
        switch(usimState) {
          case 0:
            console.log('전체')
            break
          case 1:
            console.log('사용중인 녀석듪')
            dstItems = src.filter(x => !TymCommon.isEmpty(x.USIM_STATE))
            break
          case 2:
            console.log('중지된 녀석듪')
            break
          case 3:
            console.log('만료된 녀석듪')
            break
          default:
            console.log('모르는 녀석듪')
            break
        }
      } else {
        console.log('유심 종류 없음')
      }

      if(!TymCommon.isEmpty(filter[1])) {
        // 모델
        let modelIndex = this.modelValues.findIndex(x => x == filter[1])
        if(modelIndex > 0) {
          let tempAr = []
          dstItems.forEach(item => {
            let modelInfo = TymCommon.getModelFullname(item.MON)

            if(modelInfo.Model == this.selectedModel) {
              tempAr.push(item)
            }
          })
          dstItems = tempAr
        }
      } else {
        console.log('유심 종류 없음')
      }

      if(!TymCommon.isEmpty(filter[2])) {
        // Keyword
        let upper = filter[2].toUpperCase()
        dstItems = dstItems.filter(x => (!TymCommon.isEmpty(x.MN) ? x.MN.includes(upper) : false) || (!TymCommon.isEmpty(x.SN) ? x.SN.includes(upper) : false) || (!TymCommon.isEmpty(x.SALE_NAME) ? x.SALE_NAME.includes(upper) : false))
      } else {
        console.log('유심 종류 없음')
      }

      if(!TymCommon.isEmpty(filter[3])) {
        // 기간 옵션
        if(!TymCommon.isEmpty(filter[4]) && !TymCommon.isEmpty(filter[5])) {
          let start = moment(filter[4]).format('YYYY-MM-DDT00:00')
          let end = moment(filter[5]).format('YYYY-MM-DDT24:00')
          if(filter[3] == this.usimDateOptions[1]) {
            // 유심 개통일자
            dstItems = dstItems.filter(x => (!TymCommon.isEmpty(x.USIM_FIRST) ? x.USIM_FIRST >= start && x.USIM_FIRST < end : false))
          } else if(filter[3] == this.usimDateOptions[2]) {
            // 서비스 시작일자(판매일자)
            dstItems = dstItems.filter(x => (!TymCommon.isEmpty(x.SALE_SD) ? x.SALE_SD >= start && x.SALE_SD < end : false))
          } else if(filter[3] == this.usimDateOptions[3]) {
            // 서비스 만료일자
            dstItems = dstItems.filter(x => (!TymCommon.isEmpty(x.SALE_ED) ? x.SALE_ED >= start && x.SALE_ED < end : false))
          }
        } else {
          console.log('selected date option, but not selected term', filter[3], filter[4], filter[5])
        }
      }

      console.log('++++ FILTER :', dstItems, filter, columns)

      return dstItems
    }
  }
})
</script>
