export default {
  "common": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디"])},
    "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 명"])},
    "mobilephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처"])},
    "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적용"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
    "ign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시동"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
    "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체보기"])},
    "adsOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행기대 보기"])},
    "opTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용시간"])},
    "erCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장코드"])},
    "emergency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응급"])},
    "breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장"])},
    "consumables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품"])},
    "batterywarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 경고"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종류"])},
    "hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔진 출력"])},
    "hpUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ps"])},
    "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세설명"])},
    "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합계"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단위"])},
    "loadCons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품 불러오기"])},
    "firstTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최초 교체 시간(h)"])},
    "cycleTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체 주기 시간(h)"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성화되지 않음"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용함"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미사용"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록"])},
    "recentLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 위치"])},
    "doingAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가"])},
    "doingEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정"])},
    "doingDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
    "faveroites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남성"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여성"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
    "rollover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차량 전복"])},
    "geofence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안심 구역 이탈"])},
    "emtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴급알림"])},
    "brtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장타입"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버."])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일시"])},
    "vin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대번호"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
    "toExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀로 추출"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다운로드"])},
    "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일명"])},
    "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["키워드"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 시작일"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 종료일"])},
    "machineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기종"])},
    "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔진"])},
    "hourUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검사"])},
    "peopleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명"])},
    "vehicleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대"])},
    "agentCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개소"])},
    "typeCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종"])},
    "salePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매점"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘"])}
  },
  "regions": {
    "kr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대한민국"])},
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["북미"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
    "saveid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 저장"])},
    "forgetid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디를 잊으셨나요?"])},
    "clearpw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 초기화할까요?"])},
    "doclear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["클릭"])},
    "idForReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 초기화할 아이디를 입력하세요."])},
    "noMatchedID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객님의 정보와 일치하는 아이디가 없습니다."])},
    "matchedID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객님의 정보와 일치하는 아이디는 아래와 같습니다."])},
    "yourIDis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "memberByPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원정보에 등록한 핸드폰 번호로 찾기"])},
    "nameAndPhoneForID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원정보에 등록한 핸드폰 번호와 입력한 핸드폰 번호가 동일해야 아이디를 찾을 수 있습니다."])},
    "memberByEMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원정보에 등록한 이메일로 찾기"])},
    "resetByEMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원정보에 등록한 이메일을 통해 초기화하기"])},
    "nameAndEmailForID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원정보에 등록한 이메일 주소와 입력한 이메일 주소가 동일해야 아이디를 찾을 수 있습니다."])},
    "setSafePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 보호를 위해 안전한 비밀번호로 설정해 주세요."])},
    "passwordGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호는 영문(소문자 1자 이상), 숫자, 특수문자 조합(8자 이상)으로 입력해 주세요."])},
    "inputSamePW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["같은 비밀번호를 입력해주세요."])},
    "completedChangePW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하신 비밀번호로 설정되었습니다."])},
    "requestAuthCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호를 먼저 요청하세요."])},
    "requestingAuthCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 요청"])},
    "reRequestingAuthCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 재요청"])},
    "waitingAuthCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 대기중"])},
    "findID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 찾기"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 초기화"])},
    "registerNewManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규 사용자 정보등록"])}
  },
  "loginmenu": {
    "downloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앱 다운로드"])},
    "personalinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보조회"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
    "dologout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃 하시겠습니까?"])}
  },
  "leftbar": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현황"])},
    "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델"])},
    "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통계"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생산"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객"])},
    "usim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유심 관리"])},
    "machineview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대 정보"])},
    "monitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차량관제"])}
  },
  "titlearea": {
    "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운행중인 장비 보기"])},
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 정보"])}
  },
  "totalarea": {
    "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
    "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객"])}
  },
  "placeholder": {
    "nameonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "nameorvin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름이나 기대번호"])},
    "vinonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대번호"])},
    "phoneonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
    "nameorphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름이나 전화번호"])},
    "nameorvinorsn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름, 기대번호, 시리얼번호"])},
    "titleorname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목이나 작성자"])},
    "modeloroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델이나 옵션"])},
    "vinorsn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대번호나 시리얼번호"])},
    "inputConsCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 이름"])},
    "inputConsCategoryKo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 이름"])},
    "inputConsCategoryEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 이름(영어)"])},
    "inputpartname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품명을 입력하세요."])},
    "inputpartnameKo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품 이름"])},
    "inputpartnameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품 이름(영어)"])},
    "inputpartnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품번호를 입력하세요."])},
    "inputYourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름을 입력하세요."])},
    "inputPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대전화 번호를 입력하세요."])},
    "inputEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail을 입력하세요."])},
    "inputPostcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호를 입력하세요."])},
    "inputAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소를 입력하세요."])},
    "inputID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디를 입력하세요."])},
    "inputPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 입력하세요."])},
    "inputNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호"])},
    "reInputPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 다시 입력하세요."])},
    "authCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호"])},
    "checkPW4DelAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 삭제를 위해 비밀번호를 입력하세요."])},
    "checkPW4DelGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 삭제를 위해 비밀번호를 입력하세요."])}
  },
  "dashboardView": {
    "machinemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대위치 현황"])},
    "largemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지도 크게 보기"])},
    "smallmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 정보도 보기"])},
    "recentNoti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 알림"])},
    "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지점"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객"])}
  },
  "noticeView": {
    "newnotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 공지사항이 있습니다."])},
    "noNewNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 공지사항이 없습니다."])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분류"])},
    "writer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성자"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수신자"])},
    "writedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성일자"])},
    "expiredate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지마감"])},
    "noticetitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
    "attachedfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부파일"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개"])},
    "selectcommon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반"])},
    "selectevent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트"])},
    "selectnotread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미확인"])},
    "selectfavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기"])},
    "myNoticeView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 공지사항"])},
    "addFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일추가"])},
    "removeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
    "deleteCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항 삭제를 완료하였습니다."])},
    "deleteNoticeMsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항 삭제를 위해 비밀번호를 입력하세요."])},
    "deleteNoticeMsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 비밀번호가 일치하지 않습니다."])},
    "max5files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일은 5개까지 첨부할 수 있습니다."])},
    "inputContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용을 입력하세요."])},
    "deleteSalesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 정보 삭제를 위해 비밀번호를 입력하세요."])}
  },
  "resetPWdialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디를 입력하시면, 비밀번호를 초기화할 수 있는 방법을 알려드립니다."])},
    "byphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 정보에 등록된 핸드폰 번호로 ID찾기"])}
  },
  "doResetPWDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 초기화"])},
    "byemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 정보에 등록한 E-mail로 초기화"])},
    "byphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 정보에 등록한 핸드폰 번호로 초기화"])},
    "reqauthcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증코드 받기"])}
  },
  "newPWDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 비밀번호를 입력하세요."])},
    "password1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인"])},
    "authcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호"])}
  },
  "loginresult": {
    "invalidUserOrPW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 혹은 비밀번호가 일치하지 않습니다."])}
  },
  "mainpage": {
    "sales_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매현황(월)"])},
    "machine_loc_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대위치 현황"])},
    "trouble_chart_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장 발생 현황(월)"])},
    "trouble_list_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장현황"])},
    "notice_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항"])},
    "emergency_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴급 현황"])},
    "alarm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알람 리스트"])},
    "brkcol1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발생일시"])},
    "brkcol2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대번호"])},
    "brkcol3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객명"])},
    "brkcol4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장내용"])},
    "emgcol4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용"])},
    "brkcol5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운행시간"])},
    "brkcol6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세"])}
  },
  "constants": {
    "emergency_type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결"])},
    "emergency_type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차량 전복"])},
    "emergency_type3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안심구역 이탈"])},
    "emergency_type4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 저전압"])},
    "emergency_type5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TBOX 배터리 차단"])}
  },
  "TEXT_CITEM": {
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔진 오일 및 카트리지"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연료 필터(A)"])},
    "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연료 필터(B)"])},
    "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에어클리너 엘리먼트"])},
    "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["트랜스미션 오일"])},
    "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유압 오일 필터"])},
    "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프론트 액슬 오일"])}
  },
  "TEXT_CITEM_MANAGE": {
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["양호"])},
    "caution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체"])}
  },
  "TEXT_BROKENSTATE": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장발생"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고접수"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입고처리"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료처리"])}
  },
  "TEXT_BROKENTYPE": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔진"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유압"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전장(센서)"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])}
  },
  "monitor_menu": {
    "reg_machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 차량"])},
    "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정상 차량"])},
    "emergency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전복, 안심구역 이탈"])},
    "broken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장"])},
    "consumablesRepl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품 교체 필요"])},
    "consumables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품 교체"])},
    "battery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 저전압 경고"])}
  },
  "consumables": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔진 오일 및 카트리지"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연료 필터(A)"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연료 필터(B)"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에어클리너 엘리먼트"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션 오일"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유압 오일 필터"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프론트 액슬 오일"])}
  },
  "errorMsg": {
    "noLocaInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치 정보가 없습니다."])},
    "passwordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호는 8글자 이상입니다."])},
    "notMatchedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치하는 정보를 찾을 수 없습니다."])},
    "checkIDfirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 검사를 수행해주세요."])},
    "mismatchSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시리얼 번호 오류"])},
    "requestTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버와 통신 시간 초과 오류"])},
    "updateTBOXFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TBOX 정보를 저장하는 데 문제가 발생했습니다. TBOX 정보를 다시 수정해 주세요."])},
    "updateACUFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행 정보를 저장하는 데 문제가 발생했습니다. 자율주행 정보를 다시 수정해 주세요."])},
    "updateBothFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TBOX 및 자율주행 정보를 저장하는 데 문제가 발생했습니다. 다시 수정해 주세요."])},
    "noUsageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 기대의 사용시간 정보가 없습니다."])},
    "emptyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실행결과를 알 수 없습니다."])},
    "notRegisteredSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SN_INFO에 등록되지 않은 S/N입니다."])},
    "usedMN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용중인 기대번호입니다."])},
    "usedSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용중인 S/N 입니다."])},
    "failUdpatePD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대 정보 변경에 실패했습니다."])},
    "failUdpateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 정보 변경에 실패했습니다."])}
  },
  "TABLE_COLUMNS": {
    "occDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발생일"])},
    "firstOccurDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최초 발생 일자"])},
    "lastOccurDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 발생 일자"])},
    "resolveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결 일자"])},
    "emergencyContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림 내용"])},
    "resolveContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조치 내용"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
    "errorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에러코드"])},
    "errorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용"])},
    "usageOccurrence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발생 시 사용 시간"])},
    "usageReplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체 시 사용 시간"])},
    "replaceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체일"])},
    "consumableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품명"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명서"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
    "partname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품명"])},
    "partnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품 번호"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서"])}
  },
  "changeMethod": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점 교체"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사설 교체"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 교체"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])}
  },
  "yearMonth": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1월"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2월"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3월"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4월"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5월"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6월"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7월"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8월"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9월"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10월"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11월"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12월"])}
  },
  "WEEK": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["화"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토"])}
  },
  "groupCategory": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본부"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지점"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객"])}
  },
  "testc": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필요"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불합격"])}
  },
  "workType": {
    "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용시간"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이동"])},
    "pto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PTO"])},
    "plow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["견인"])}
  },
  "manage": {
    "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모량"])},
    "fuelMileage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연비"])},
    "monthlyOpHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월간 사용 시간"])},
    "monthlyDetailRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월간 사용 기록"])},
    "moveAndWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이동 & 작업 경로"])},
    "opHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 시간"])},
    "avgSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 속도"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이동"])},
    "pto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PTO 작업"])},
    "plow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["견인 작업"])},
    "engineHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 시간"])},
    "fuelConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연료 소모량"])},
    "batteryVoltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 전압"])},
    "batteryWarningLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 경고 기준"])},
    "lowVoltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 저전압 경고"])},
    "writeMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모를 작성합니다."])},
    "viewMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼을 눌러 메모 내용을 확인합니다."])},
    "occurLoca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발생 위치"])},
    "usageTooltipMon1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 데이터는 이동&작업 시간만 측정되므로 정차 상태에서의 공회전 시간은 포함되지 않습니다."])},
    "usageTooltipMon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["따라서 작업 일지의 시간과 실제 사용 시간 집계는 달라질 수 있습니다."])},
    "batteryToolitp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 데이터는 Key off 상태에서만 기록됩니다."])},
    "engineHourTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 데이터는 이동&작업 시간과 정차 상태에서의 공회전 시간도 포함된 실제 사용 시간입니다."])},
    "noEmergencyHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴급 알림 이력이 없습니다."])},
    "noErrorHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장 발생 이력이 없습니다."])},
    "noConsHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품을 교체한 이력이 없습니다."])}
  },
  "statSales": {
    "totalSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 수량"])},
    "sbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지점별 판매"])},
    "sbydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점별 판매"])},
    "sbymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기종별 판매"])},
    "sbyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델별 판매"])},
    "sybymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 12개월간 판매"])},
    "sbydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/기종별 판매"])},
    "sbydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/모델별 판매"])}
  },
  "statEngineHour": {
    "hbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지점별 사용시간"])},
    "hbydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점별 사용시간"])},
    "hbymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기종별 사용시간"])},
    "hbyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델별 사용시간"])},
    "hbydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/기종별 사용시간"])},
    "hbydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/모델별 사용시간"])},
    "hbymonthlymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델별 월간 사용시간"])},
    "mhfor12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 12개월간 모델별 사용시간"])}
  },
  "statEmergency": {
    "embybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지점별 발생건수"])},
    "embydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점별 발생건수"])},
    "embymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기종별 발생건수"])},
    "embyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델별 발생건수"])},
    "embydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/기종별 발생건수"])},
    "embydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/모델별 발생건수"])},
    "embyyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 12개월간 발생건수"])},
    "emergencyCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발생 건수"])}
  },
  "statErrors": {
    "erbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지점별 발생건수"])},
    "erbydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점별 발생건수"])},
    "erbymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기종별 발생건수"])},
    "erbyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델별 발생건수"])},
    "erbydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/기종별 발생건수"])},
    "erbydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/모델별 발생건수"])},
    "erbyyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 12개월간 발생건수"])},
    "errorCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발생 건수"])}
  },
  "statCons": {
    "consbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지점별 소모품 교환건수"])},
    "consbydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점별 소모품 교환건수"])},
    "consbycategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리별 소모품 교환건수"])},
    "consbypart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품별 소모품 교환건수"])},
    "consbymodelcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델과 카테고리별 소모품 교환건수"])},
    "consbymodelpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델과 부품별 소모품 교환건수"])},
    "consCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환 건수"])},
    "noName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름 없음"])}
  },
  "statBattery": {
    "battbybranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지점별 배터리 저전압 경고건수"])},
    "battbydealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점별 배터리 저전압 경고건수"])},
    "battbymodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기종별 배터리 저전압 경고건수"])},
    "battbyoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델별 배터리 저전압 경고건수"])},
    "battbydealermodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/기종별 배터리 저전압 경고건수"])},
    "battbydealeroption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별/모델별 배터리 저전압 경고건수"])},
    "battbyyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 12개월간 배터리 저전압 경고건수"])},
    "battCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 저전압 경고건수"])}
  },
  "tooltip": {
    "editJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용을 변경합니다."])},
    "deleteMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모를 삭제합니다."])},
    "cancelJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업을 취소합니다."])},
    "saveJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 내용을 저장합니다."])},
    "applyJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용을 적용합니다."])},
    "initJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정을 초기화합니다."])},
    "selectUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자를 선택합니다."])},
    "selectModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델을 선택합니다."])},
    "selectDealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["딜러를 선택합니다."])},
    "searchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 조건으로 데이터를 조회합니다."])},
    "exportExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀로 저장합니다."])},
    "makeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 조직을 만듭니다."])},
    "editGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 조직의 정보를 수정합니다."])},
    "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 조직을 삭제합니다."])},
    "viewAllRegMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 모든 기대를 보여줍니다."])},
    "viewNormalMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정상 운행중인 기대를 보여집니다."])},
    "moveMonitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차량 관제로 이동"])},
    "refreshLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대 위치 조회"])},
    "editConsumables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 모델의 소모품 정보를 변경합니다."])},
    "deleteConsumable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 소모품 정보를 삭제합니다."])},
    "addModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 모델을 추가합니다."])},
    "deleteModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 모델을 삭제합니다."])},
    "addParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품을 추가합니다."])},
    "editParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품의 정보를 변경합니다."])},
    "eraseParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품을 삭제합니다."])},
    "erasePartNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품 번호를 삭제합니다."])},
    "addPartNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품 번호를 추가합니다."])},
    "addNewItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 항목을 추가합니다."])},
    "addNewSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 판매 정보를 추가합니다."])},
    "selectIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 아이콘을 선택해 주세요."])},
    "loadMachineByMON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 기대번호의 기대정보를 불러옵니다."])},
    "selectProdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생산일자를 선택하세요."])},
    "selectSaleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매일자를 선택하세요."])},
    "selectSaleTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매시간을 선택하세요."])},
    "addAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 계정을 추가합니다."])},
    "editAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 계정을 변경합니다."])},
    "deleteAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 계정을 삭제합니다."])},
    "printEULA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 수집 동의서 인쇄"])},
    "checkValidID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디를 사용할 수 있는지 확인합니다."])},
    "searchPostcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호를 검색합니다."])},
    "moveToManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대관리 페이지로 이동합니다."])},
    "deleteSalesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 정보를 삭제합니다."])},
    "checkMN_SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대번호와 시리얼번호를 확인합니다."])},
    "loadAlarmHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알람 이력을 확인합니다."])},
    "loadErrorHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장 이력을 확인합니다."])},
    "loadConsHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품 변경 이력을 확인합니다."])},
    "noticeMine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성한 공지 보기"])},
    "noticeAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 공지 작성"])},
    "noticeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 공지 보기"])},
    "noticeDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지 삭제"])},
    "showMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 보이기"])},
    "hideMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 감추기"])},
    "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 기대의 정보를 수정합니다."])},
    "deleteProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 기대의 정보를 삭제합니다."])},
    "selectPostcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호를 검색해 주세요."])},
    "regNotSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매등록되지 않은 기대를 관리합니다."])}
  },
  "warning": {
    "noaddrinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소 정보 없음"])},
    "noTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목 없음"])},
    "noContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용 없음"])},
    "noReceiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수신자 없음"])},
    "tooLargeSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일이 너무 커서 첨부할 수 없습니다."])},
    "tooLargeTotalSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부 파일들의 용량이 너무 커서 더이상 첨부할 수 없습니다."])},
    "inputVIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대번호를 입력해 주세요."])},
    "noDelSpecialGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주요 그룹은 지울 수 없습니다."])},
    "noDelHasChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서브 그룹이 있어 선택한 그룹을 지울 수 없습니다."])},
    "noDelHasManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 관리자가 있어 선택한 그룹을 지울 수 없습니다."])},
    "noOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객의 정보가 없습니다."])},
    "noDealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매점의 정보가 없습니다."])},
    "noMachineInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대 정보가 없습니다."])},
    "noModelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델 정보가 없습니다."])},
    "inputSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시리얼 번호를 입력하세요."])},
    "notRegisteredSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록되지 않은 시리얼 번호입니다."])},
    "duplicatedSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 시리얼 번호입니다."])},
    "deleteACUInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행 정보를 삭제하시겠습니까?"])},
    "deleteTBOXInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TBOX 정보를 삭제하시겠습니까?"])},
    "noTboxOrACU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TBOX나 자율주행 정보가 필요합니다."])},
    "noTboxSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TBOX의 시리얼 번호를 입력하세요."])},
    "noACUSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행 정보의 시리얼 번호를 입력하세요."])},
    "checkTBOXSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TBOX의 시리얼 번호를 확인하세요."])},
    "checkACUSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행 정보의 시리얼 번호를 확인하세요."])},
    "noOperatingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운행 정보가 없습니다"])},
    "readingWeeklyData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주간 데이터를 읽고 있습니다"])},
    "inputYourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름을 입력하세요."])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 없음"])},
    "toomanyattempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["너무 많이 시도했습니다. 다음에 시도하십시오."])},
    "checkid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 체크를 먼저 해주세요."])},
    "differentpw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호가 같지 않습니다."])},
    "oneMoreCons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나 이상의 소모품 정보를 입력하세요."])},
    "inputMachineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기기을 종류를 선택하거나 입력하세요."])},
    "inputModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델을 선택하거나 입력하세요."])},
    "inputOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션을 선택하거나 입력하세요."])},
    "registeredMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 기대입니다."])},
    "notRegisteredMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록되지 않은 기대입니다."])},
    "notRegTBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록되지 않은 TBOX 기대입니다."])},
    "registeredTBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 TBOX 기대입니다."])},
    "needPasswordPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보를 확인하기 위해서는 비밀번호가 필요합니다."])}
  },
  "notice": {
    "addUserOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 계정이 추가되었습니다."])},
    "editUserOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 계정정보가 변경되었습니다."])},
    "checkPW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 확인해주세요."])},
    "inputCurPW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 사용중인 비밀번호를 입력하세요."])},
    "attachedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확장자는 jpg와 pdf만 가능합니다."])}
  },
  "equipment": {
    "consumableCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품 카테고리"])},
    "addConsPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품 추가"])},
    "partInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부품 정보"])},
    "replTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체 주기"])},
    "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 등록된 모델입니다."])},
    "forDev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개발용 모델"])},
    "viewOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보기 옵션 설정"])}
  },
  "group": {
    "parentGrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상위그룹"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹명"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹유형"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호"])},
    "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처"])},
    "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한"])},
    "activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성여부"])},
    "questionDeleteAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자를 삭제하시겠습니까"])},
    "questionDeleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹을 삭제하시겠습니까?"])},
    "questionDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제하시겠습니까"])},
    "listGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 목록"])},
    "listManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 목록"])},
    "groupInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 정보"])},
    "editGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 정보 수정"])},
    "addGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 추가"])},
    "addManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 추가"])},
    "editManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 수정"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
    "usedID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 사용중인 아이디입니다."])},
    "usableID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 수 있는 아이디입니다."])},
    "enterGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹의 이름을 입력하세요."])},
    "usedGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹의 이름이 이미 사용중입니다."])},
    "enterAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소 정보를 입력하세요."])},
    "enterMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호를 입력하세요."])},
    "invalidInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력데이터에 오류가 있습니다."])}
  },
  "customer": {
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 조회"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 정보"])},
    "searchInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객정보 조회"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생년월일"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생년월일"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성별"])},
    "regDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입일자"])},
    "modelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델명"])},
    "machineNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대번호"])},
    "manDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제조연월"])},
    "serialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시리얼번호"])},
    "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매점"])},
    "saleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매일자"])},
    "warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 기간"])},
    "machineState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차량 상태"])},
    "serviceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개통일자"])},
    "expireDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료일자"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체보기"])},
    "showSoldOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매등록 사용자만 보기"])}
  },
  "batt_status": {
    "noInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보 없음"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 정상"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 저전압"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["텔레매틱스 통신 종료"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 차단"])}
  },
  "eula": {
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 가입"])},
    "privInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인(신용)정보 수집·이용 및 제3자 제공 동의서"])},
    "privAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인(신용)정보 수집·이용 및 제3자 제공에 동의합니다."])},
    "privPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 처리 방침"])},
    "locaTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치기반서비스 이용약관"])},
    "locaAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치기반서비스 이용약관에 동의합니다."])},
    "locaInfoTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치정보사업 이용약관"])},
    "locaInfoAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치정보사업 이용약관에 동의합니다."])},
    "agreeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 수집·이용 및 위치기반서비스 이용약관 및 위치정보사업 이용약관에 동의하셔야 회원 가입이 가능합니다"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성명"])}
  },
  "salesView": {
    "totalSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 수량"])},
    "salesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 정보"])},
    "testTimeBeforeSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 전 시운전시간(h)"])},
    "machineInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대 정보"])},
    "autoDriveInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행 정보"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품"])},
    "adsLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행 레벨"])},
    "autoSteering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동조향 장치"])},
    "noSalesVIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생산후 미등록 기대"])},
    "salesNotRegi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동판매 후 미등록 기대"])}
  },
  "account": {
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 계정"])},
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 정보"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타 설정"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경"])},
    "curPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 비밀번호"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호"])},
    "startpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작 페이지"])},
    "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간대"])}
  },
  "machineInfo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대 정보"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핸드폰"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델"])},
    "vin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대번호"])},
    "manDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제조연월"])},
    "serialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S/N"])},
    "engHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용시간"])},
    "saleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매일자"])},
    "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대리점"])},
    "curLoca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 위치"])}
  },
  "machineDetail": {
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리"])},
    "worksheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업일지"])},
    "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용량"])},
    "engineHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 시간"])},
    "lastRunTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 사용시간"])},
    "fuelLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연료량"])},
    "ureaLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요소수량"])},
    "ADS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행"])},
    "manageEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴급 알림 현황"])},
    "historyEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴급알림 이력"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이력"])},
    "changeEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[긴급알림] 상태변경"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결"])},
    "unresolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미해결"])},
    "resolvedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결 일자"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])},
    "manageER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장 현황"])},
    "historyBR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장 이력"])},
    "changeBR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[고장] 상태변경"])},
    "brType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장 타입"])},
    "procHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 상황"])},
    "replaceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체 일자"])},
    "replHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체 이력"])},
    "replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체"])},
    "replMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체 종류"])},
    "notReplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체 없음"])},
    "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모"])},
    "replaceParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교체할 부품"])},
    "manageCONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품 현황"])}
  },
  "machineStatus": {
    "Ignition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시동 상태"])},
    "Battery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 전압"])},
    "Fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연료량"])},
    "Coolant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["냉각수 온도"])},
    "ParkingBrake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주차 브레이크"])},
    "Direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주행 방향"])},
    "PTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PTO"])},
    "AirCleaner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에어클리너"])},
    "TMOilFilterTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변속기 유온"])},
    "Urea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요소수량"])},
    "FourWD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4WD"])},
    "HorizMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수평"])},
    "DepthMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경심"])},
    "DraftMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["견인력"])},
    "UsingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 시간"])},
    "EngineLoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔진 부하량"])},
    "EngineRPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔진 회전수"])},
    "EngineOil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔진 오일 압력"])},
    "AmbientAirTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주변 공기온도"])},
    "DPFRegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPF 요구단계"])},
    "DPFSoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPF 부하수준"])},
    "FuelFilterWater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연료필터 수분유입"])},
    "Inducement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후처리 장치 단계"])},
    "RecentAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 사용시간"])},
    "KeyOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEY 상태"])},
    "ADS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행"])},
    "Telematics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["텔레매틱스 펌웨어"])},
    "DPFRegnNormal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정상"])},
    "DPFRegnLowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["낮음"])},
    "DPFRegnModerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중간"])},
    "DPFRegnHighest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["높음"])},
    "BrakeON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잠김"])},
    "BrakeOFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해제"])},
    "Normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정상"])},
    "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["막힘"])},
    "WaterFuelNormal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정상"])},
    "WaterFuelWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유입"])},
    "Forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전진"])},
    "Neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중립"])},
    "Reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후진"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동작"])},
    "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멈춤"])}
  },
  "statView": {
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
    "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용시간"])},
    "emergency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴급알림"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고장"])},
    "consumables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소모품 교체"])},
    "lowBattery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배터리 저전압 경고"])},
    "normalMach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반"])},
    "adsMach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자율주행"])}
  },
  "productView": {
    "snList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S/N 목록"])},
    "passok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격"])},
    "passfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불합격"])},
    "passneed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필요"])},
    "prodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대 정보"])},
    "prodMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생산연월"])},
    "regDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록일자"])},
    "lastLoca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 위치"])},
    "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기대 정보 수정"])},
    "facOkcheon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옥천"])},
    "facIksan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["익산"])}
  },
  "USIM": {
    "stateActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유심 정상"])},
    "statePaused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유심 중단"])},
    "stateExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유심 만료"])},
    "stateCloseExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유심 만료 예정"])},
    "searchUSIMState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유심 상태 조회"])},
    "searchDateOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션 선택"])},
    "tooltipCloseExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30일 내에 만료되는 유심의 수량을 나타냅니다."])},
    "noOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용하지 않음"])},
    "firstOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유심 개통 일자"])},
    "saleOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 시작 일자"])},
    "expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 만료 일자"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유심 상태"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업"])},
    "state0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "state1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사전 활성화"])},
    "state2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 정상"])},
    "state3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 중단"])},
    "state4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 만료"])},
    "state5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 오류"])},
    "optionPopupTitme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션 및 검색 범위 설정"])}
  },
  "Units": {
    "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단계"])},
    "Stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단계"])}
  }
}