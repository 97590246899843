<template>
  <div class="column">
    <div class="col column" style="padding:0 12px 12px 12px">
      <div class="col column">
        <div class="col-auto row items-center" style="height:45px">
          <div class="col-auto"
              :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
            {{ getPageTitle }}
          </div>
        </div>

        <div class="column" :class='isLandscape ? "col-7" : "col-6"' style="position:relative;background-color: white;border:1px sold #ebedf2;border-radius: 3px;margin:0 0 12px 0;">
          <div class="col-auto row items-center justify-between" style="min-height:42px;height:auto;margin:12px 0;">
            <div class="col row items-center" style="margin:0 12px;">
              <div class="col-auto row" style="margin:0 32px 0 0; font-size:14px;">
                <div class="col-auto" style="color:#212529">{{ $t('productView.snList') }}</div>
                <div class="col-auto" style="color:#eb0028;margin-left:12px;" v-show="salesCount > 0">{{ salesCount }}</div>
              </div>
              
              <q-select dense outlined v-model="siteSelected" :options="getSiteList"
                        class="col-auto q-ma-xs" color="red-7"
                        hide-bottom-space options-dense disable
                        @update:model-value="updateSite"
                        style="font-size:14px;min-width:150px;margin:0 6px;">
              </q-select>
              
              <q-form autocomplete="off">
                <q-input dense outlined style="font-size:14px;height:40px" color="red-7"
                        :placeholder="$t('placeholder.vinorsn')"
                        v-model="searchInput">
                  <template v-slot:append>
                    <q-btn flat dense icon="close" @click="searchInput=''"/>
                    <!--
                    <q-btn round dense flat icon="search" @click="searchClicked"/>
                    -->
                  </template>
                </q-input>
              </q-form>
            </div>

            <div class="col-auto row items-center justify-end" style="margin-right:12px;" v-show="canDownloadToExcel">
              <q-btn class="col" flat dense @click="downloadToExcel" >
                <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;"/>
                <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
                <q-popup-proxy ref="excelPopup" style="width:360px;height:200px;background-color:#01010130;border-radius: 3px;position: relative;"
                               @before-show="onShowExportToExcel">
                    <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                      <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                        <div class="col-auto row items-center justify-center">
                          <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                            {{ $t('common.toExcel') }}
                          </div>
                        </div>
                        <div class="col column" v-show="hasExcelData">
                          <div class="col row items-center justify-start" style="width:100%;margin:0;">
                            <table class="col" style="width:100%;margin:0;">
                              <tr style="">
                                <td width=30% style="text-align: right;">{{ $t('common.filename') }}</td>
                                <td width="12px" style=""></td>
                                <td style="">{{ excelFilename }}</td>
                              </tr>
                              <tr>
                                <td width=30% style="text-align: right;">{{ $t('common.keyword') }}</td>
                                <td width="12px"></td>
                                <td>{{ excelKeyword }}</td>
                              </tr>
                            </table>
                          </div>
                          <xlsx-workbook class="col-auto row items-center justify-center">
                            <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed"/>
                            <xlsx-download :filename="excelFilename">
                              <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveToExcelFile">{{ $t('common.download') }}</button>
                            </xlsx-download>
                          </xlsx-workbook>
                        </div>
                        <div class="col row items-center justify-center" v-show="!hasExcelData" style="width:100%;height:100%;">
                          <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:bold;color:#eb0028">{{ $t('warning.nodata')}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row items-center justify-center" v-show="makingExcel"
                        style="position:absolute;z-index:3;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                      <q-spinner color="red-7" size="70px" thickness="1.5"/>
                    </div>
                  </q-popup-proxy>
              </q-btn>
            </div>
          </div>
          
          <div class="col column" style="margin:0 12px 12px 12px;">
            <div class="col column" style="border:1px solid #cccccc;position:relative">
              <q-table dense flat
                      separator="cell"
                      virtual-scroll
                      style="position:absolute;left:0;top:0;right:0;bottom:0"
                      :rows-per-page-options="[0]"
                      v-model:selected="itemSelected"
                      v-model:pagination="pagination"
                      hide-no-data
                      row-key="index"
                      :visible-columns="visibleColumns"
                      :columns="itemListColumns"
                      :rows="productList"
                      :filter="siteSelected || searchInput"
                      :filter-method="productFilterMethod"
                      ref="itemTable"
                      @update:selected="updateSelection"
                      @update:pagination="updatePagination"
                      > <!--  -->
                <template v-slot:header="props">
                  <q-th no-caps
                        auto-width="false"
                        style="height:auto"
                        class="thSticky"
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props">
                      {{ col.label }}
                  </q-th>
                </template>

                <template v-slot:body="props">
                  <q-tr :props="props" auto-width="false" @click="productRowClicked(props.pageIndex, props.row)">
                    <q-td key="status" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:120px;">
                      <div class="row items-center justify-start">
                        <div style="font-size:14px">
                          {{ getStatusLabelM(props.row.TESTC) }}
                        </div>
                      </div>
                    </q-td>
                    <q-td key="model" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:180px;">
                      <div class="row items-center justify-start">
                        <div style="font-size:14px">
                          {{ props.row.model }}
                        </div>
                      </div>
                    </q-td>
                    <q-td key="vin" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:200px;">
                      <div class="row items-center justify-start">
                        <div style="font-size:14px">
                          {{ props.row.machineNo }}
                        </div>
                      </div>
                    </q-td>
                    <q-td key="manDate" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:120px;">
                      <div class="row items-center justify-start">
                        <div style="font-size:14px">
                          {{ props.row.manDate }}
                        </div>
                      </div>
                    </q-td>
                    <q-td key="serial" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;width:200px;">
                      <div class="row items-center justify-start">
                        <div style="font-size:14px">
                          {{ props.row.serial }}
                        </div>
                      </div>
                    </q-td>
                    <q-td key="regDate" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;">
                      <div class="row items-center justify-start">
                        <div style="font-size:14px">
                          {{ props.row.regDate }}
                        </div>
                      </div>
                    </q-td>
                  </q-tr>
                </template>
                <template v-slot:bottom>
                  <div class="row items-center justify-center" style="width:100%;">
                    <div style="font-size:15px;">{{ productPagination }}</div>
                  </div>
                </template>
              </q-table>
            </div>
          </div>
          <div style="z-index:100;position: absolute;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:rgba(128, 128, 128, 0.2)" v-show="isEditMode">
            <!-- 편집모드일 때 상단 테이블 부분을 반투명으로 덮기 위함 -->
          </div>
        </div>

        <div class="col" :class='isLandscape ? "row" : "column"'>
          <div class="col bottomBox column" style="position:relative" :style='isLandscape ? "margin:0 6px 0 0;" : "margin:0 0 6px 0;"'>
            <!-- 왼쪽 아래 ; 기대정보 -->
            <div class="col column" style="position:absolute;left:12px;top:12px;right:12px;bottom:12px;z-index:2;background-color:white;" v-show="productSelectedRow >= 0">
              <div class="col-auto row items-center justify-between" style="height:42px;">
                <div class="col" style="font-size:16px;font-weight: 500;">{{ isEditMode ? $t('productView.editProduct') : $t('productView.prodInfo')}}</div>

                <div class="col-auto row justify-end " v-show="!isEditMode">
                  <q-btn outline dense color="grey-8" padding="5px" size="12px" icon="edit" style="margin-right:6px" @click="startEdit">
                    <q-tooltip style="font-size:14px">{{ $t('tooltip.editProduct') }}</q-tooltip>
                  </q-btn>
                  <q-btn outline dense color="red-7" padding="5px" size="12px" icon="delete" @click="deleteMachine">
                    <q-tooltip style="font-size:14px">{{ $t('tooltip.deleteProduct') }}</q-tooltip>
                  </q-btn>
                  <!--
                  <q-btn outline dense color="blue-7" padding="5px" size="12px" icon="map" @click="viewLocation">
                    <q-tooltip style="font-size:14px">선택한 기대의 위치를 확인합니다.</q-tooltip>
                  </q-btn>
                  -->
                </div>

                <div class="col-auto row justify-end" v-show="isEditMode">
                  <q-btn outline dense color="red-5" padding="5px" size="12px" icon="close" style="margin-right:6px" @click="cancelEdit">
                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.cancelJob') }}</q-tooltip>
                  </q-btn>
                  <q-btn outline dense color="blue-8" padding="5px" size="12px" icon="save" @click="saveEdit">
                    <q-tooltip style="font-size:14px;">{{ $t('tooltip.saveJob') }}</q-tooltip>
                  </q-btn>
                </div>
              </div>
              <q-scroll-area class="col row" style="height:100%; width:100%;padding:3px 6px 0 6px;border:1px solid #eeeeee;">
                <table style="width:100%;">
                  <tr class="tr_middle_add row">
                    <td class="infoTitle col-3 row justify-start items-center" width="24%">
                      <div class="col">{{ itemHeaders[0] }}</div>
                    </td>
                    <td class="infoContent col-9 row justify-between items-center" width="75%">
                      <div class="col" >{{ infoValues[0] }}</div>
                      <q-btn class="col-auto" no-caps unelevated dense color="red-7" text-color="white" :label="$t('common.search')"
                            v-show="isEditMode"
                            style="margin-bottom:2px;width:76px;height:30px;" @click="clickSearchModel">
                        <!--
                        <q-tooltip style="font-size:14px;">모델을 선택합니다.</q-tooltip>
                        -->
                      </q-btn>
                    </td>
                  </tr>
                  <tr class="tr_middle_add row">
                    <td class="infoTitle col-3 row justify-start items-center" width="24%">
                      <div class="col">{{ itemHeaders[1] }}</div>
                    </td>
                    <td class="infoContent col-9 row justify-between items-center" width="75%">
                      <div class="col" >{{ infoValues[1] }}</div>
                    </td>
                  </tr>
                  <tr class="tr_middle_add row">
                    <td class="infoTitle col-3 row justify-start items-center" width="24%">
                      <div class="col">{{ itemHeaders[2] }}</div>
                    </td>
                    <td class="infoContent col-9 row justify-start items-center" width="75%">
                      <div class="col-auto" style="min-width:75px;">{{ infoValues[2] }}</div>
                      <q-btn unelevated dense v-show="isEditMode">
                        <q-img src="../assets/manage/management_calendar_i.svg" width="16px" fit="fill"/>
                        <!--
                        <q-tooltip style="font-size:14px;">생산일자를 선택하세요.</q-tooltip>
                        -->
                        <q-popup-proxy cover ref="prodDatePopup" @before-show="beforeSelectProdDate">
                          <q-date minimal flat color="red-7" v-model="selectedProdDateCtrl" @update:model-value="selectedProdDate">
                          </q-date>
                        </q-popup-proxy>
                      </q-btn>
                    </td>
                  </tr>
                  <tr class="tr_middle_add row">
                    <td class="infoTitle col-3 row justify-start items-center" width="24%">
                      <div class="col">{{ itemHeaders[3] }}</div>
                    </td>
                    <td class="infoContent col-9 row justify-between items-center" width="75%">
                      <q-input dense color="red-7" :borderless="!isEditMode"  :readonly="!isEditMode" v-model="infoValues[3]"></q-input>
                    </td>
                  </tr>

                  <tr class="tr_middle_add row">
                    <td class="infoTitle col-3 row justify-start items-center" width="24%">
                      <div class="col">{{ itemHeaders[4] }}</div>
                    </td>
                    <td class="infoContent col-9 row justify-start items-center" width="75%">
                      <div class="col-auto" style="min-width:130px;">{{ infoValues[4] }}</div>
                      <q-btn unelevated dense v-show="isEditMode">
                        <q-img src="../assets/manage/management_calendar_i.svg" width="16px" fit="fill"/>
                        <!--
                        <q-tooltip style="font-size:14px;">등록일자를 선택하세요.</q-tooltip>
                        -->
                        <q-popup-proxy cover ref="regDatePopup" @before-show="beforeSelectRegDate">
                          <q-date minimal flat color="red-7" v-model="selectedRegDateCtrl" @update:model-value="selectedRegDate"
                                  style="margin:0 0 12px 0;">
                          </q-date>
                        </q-popup-proxy>
                      </q-btn>
                    </td>
                  </tr>

                </table>
              </q-scroll-area>
            </div>
          </div>

          <div class="col bottomBox" style="position:relative" :style='isLandscape ? "margin:0 0 0 6px;" : "margin:6px 0 0 0;"'>
            <div class="col column" style="z-index:97;background-color:white;" v-show="isViewLocationInfo && (productSelectedRow >= 0)" 
                  :style='isLandscape ? "position: absolute;left:12px;top:0;right:12px;right:12px;bottom:12px;margin-top:0;" : "margin-top:12px;"'>
              <div class="col column no-wrap">
                <!-- Occurrence Location -->
                <div class="col-auto row justify-between items-center" style="height:45px;">
                  <div class="itemTitle">{{ $t('productView.lastLoca') }}</div>
                  <q-btn class="col-auto" no-caps unelevated dense color="red-7" icon="close" style="width:auto;"
                        v-show="!isLandscape"
                        @click="viewEmerErr = 0">
                  </q-btn>
                </div>
                <div class="col column items-start justify-center no-wrap" style="width:100%;height:100%;min-height:160px">
                  <div class="col row" style="width:100%;min-height:120px">
                    <MapView ref="locationMap" elementid="prodLocation" class="col" @addressReceived="addrReceived"></MapView>
                  </div>
                  <div class="col-auto" style="height:19px;margin:12px 0 0 0;font-size:14px;">{{ $t('common.address') }}&nbsp;:&nbsp;{{ machineAddress }}</div>
                </div>
              </div>
              <!--
              <div class="col row justify-center items-center" v-show="isViewLocationInfo && !isValidLocation">
                <div class="col column justify-center items-center">
                  <div class="col" style="font-size:18px;font-weight: 500;">
                    {{ $t('errorMsg.noLocaInfo') }}
                  </div>
                </div>
              </div>
              -->

            </div>

            <div class="col column" v-show="isEditMode && searchingModel" style="z-index:20;position: absolute;left:12px;top:6px;right:12px;bottom:12px;">
              <!-- Model List -->
              <div class="col-auto row items-center justify-between" style="height:auto;">
                <div class="col-auto" style="font-size:16px;font-weight: 500;">Model List</div>
                <div  class="col-auto row">
                  <q-select class="col-auto q-mx-sm" outlined dense options-dense color="red-7"
                            style="margin:0 0 6px 0;min-width:120px;"
                            v-model="modelOption" :options="getCategory">
                  </q-select>
                  <q-input class="col-auto q-mx-sm" dense outlined color="red-7"
                          :placeholder="$t('placeholder.modeloroption')"
                          style="font-size:14px;max-width:200px;"
                          v-model="modelFilter">
                    <template v-slot:append>
                      <q-btn  dense flat icon="close" @click="modelFilter=''"/>
                    </template>
                  </q-input>
                </div>

                <div class="col-auto row justify-end">
                  <q-btn outline dense color="red-5" padding="5px" size="12px" icon="close" @click="searchingModel = false">
                    <q-tooltip style="font-size:14px;">{{ $t('cancelJob') }}</q-tooltip>
                  </q-btn>
                </div>
              </div>
              <div class="col row" style="position: relative;border:1px solid #eeeeee">
                <q-table dense flat
                        separator="cell"
                        class="col-auto"
                        style="position:absolute;left:0;top:0;right:0;bottom:0"
                        :rows-per-page-options="[0]"
                        v-model:selected="modelSelected"
                        virtual-scroll
                        hide-no-data
                        row-key="index"
                        :visible-columns="modelVisColums"
                        :columns="modelColumns"
                        :rows="modelRows"
                        :filter="modelOption || modelFilter"
                        :filter-method="modelFilterMethod"
                        ref="modelTable"
                        @row-click="listModelSelected"
                        @update:selected="updateModelSelection"
                        >
                  <template v-slot:header="props">
                    <q-th no-caps
                          style="height:auto"
                          class="thSticky"
                          v-for="col in props.cols"
                          :key="col.name"
                          :props="props">
                        {{ col.label }}
                    </q-th>
                  </template>

                  <template v-slot:body="props">
                    <q-tr :props="props" @click="modelRowClicked(props)">
                      <q-td key="model" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;">
                        <div class="row items-center justify-start">
                          <div style="font-size:14px">
                            {{ props.row.model }}
                          </div>
                        </div>
                      </q-td>
                      <q-td key="option" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;">
                        <div class="row items-center justify-start">
                          <div style="font-size:14px">
                            {{ props.row.option }}
                          </div>
                        </div>
                      </q-td>
                      <q-td key="hp" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;">
                        <div class="row items-center justify-start">
                          <div style="font-size:14px">
                            {{ props.row.hp }}
                          </div>
                        </div>
                      </q-td>
                      <q-td key="type" :props="props" style="border-bottom:1px solid #dddddd;cursor:pointer;">
                        <div class="row items-center justify-start">
                          <div style="font-size:14px">
                            {{ props.row.type }}
                          </div>
                        </div>
                      </q-td>
                    </q-tr>
                  </template>

                  <template v-slot:bottom>
                    <div class="row items-center justify-center" style="width:100%;">
                      <div style="font-size:15px;">{{ modelPagination }}</div>
                    </div>
                  </template>
                </q-table>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <!-- 사용자 제거 확인용 다이얼로그 -->
    <q-dialog v-model="delProdDlg" persistent>
      <q-card style="padding:12px 24px 12px 12px;min-width:420px">
        <q-card-section class="row items-center" >
          <div class="col row items-center justify-between">
            <div class="col-auto">
              <q-avatar icon="agriculture" color="red-7" text-color="white" style="margin:0 12px 0 0;"/>
            </div>
            <div class="col column">
              <span class="q-ml-sm" style="font-size:14px;margin:0 0 6px 12px;">{{ $t('common.vin')}}&nbsp;:&nbsp;{{ dlgMN }}</span>
              <span class="q-ml-sm" style="font-size:14px;margin:0 0 6px 12px;">{{ $t('machineInfo.serialNo')}}&nbsp;:&nbsp;{{ dlgSN }}</span>
              <span class="q-ml-sm" style="font-size:14px;margin:0 0 12px 12px;">{{ dlgMsg }}</span>

              <q-input color="red-7" :type="hideDelUserPW ? 'password' : 'text'" style="margin:0 0 0 12px;font-size:14px" outlined autofocus dense v-model="pwBeforeDelUser"
                       :placeholder="$t('placeholder.inputPassword')">
                <template v-slot:append>
                  <q-icon :name="hideDelUserPW ? 'visibility_off' : 'visibility'"
                          class="cursor-pointer"
                          @click="hideDelUserPW = !hideDelUserPW" />
                </template>
              </q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn dense no-caps color="grey-3" text-color="black" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.cancel')" v-close-popup/>
          <q-btn dense no-caps color="red-7" text-color="white" style="width:80px;height:32px;margin:0 6px;" :label="$t('common.confirm')" v-close-popup @click="eraseUserConfirmed"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped>

.title {
  font-size: 16px;
  font-weight: bold;
}

.thSticky {
  position: sticky;
  z-index: 3;
  top:0;
  background-color: white;
  font-size:14px;
  font-weight: 500;
}

.bottomBox {
  background-color: white;
  border:1px sold #ebedf2;
  border-radius: 3px;
  height:auto;
}

.tr_top {
  border-top: 2px solid #cccccc;
  border-bottom: 1px solid #eeeeee;
  min-height:28px;
}
.tr_middle {
  border-bottom: 1px solid #eeeeee;
  min-height:28px;
}
.tr_middle_add {
  border-bottom: 1px solid #eeeeee;
  min-height:36px;
}
.tr_bottom {
  border-top: 2px solid #cccccc;
  min-height:28px;
}

.infoTitle {
  font-size: 14px;
  font-weight: 500;
}
.infoContent {
  font-size: 14px;
  font-weight: 400;
}
.itemTitle {
  font-size:16px;
  font-weight:500;
}

</style>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTymictStore } from '@/store/tymict'
import TymFncs from '@/js/tymfunctions.js'
import TymAws from '@/js/tymaws.js'
import TymCommon from '@/js/tymcommon'
import TymConst from '@/js/tymconstants.js'
import moment from 'moment-timezone'
import MapView from '../views/MapView.vue'
//import { AuthenticationDetails } from "amazon-cognito-identity-js";
import { XlsxWorkbook, XlsxSheet, XlsxDownload  } from 'vue3-xlsx';


export default ({
  computed : {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    isKorean() {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    isKorea() {
      const store = useTymictStore()
      return (store.connectServer == 0)
    },
    getCategory() {
      const store = useTymictStore()
      return store.getMachineTypes()
    },
    getSiteList() {
      return ['전체', '익산 공장', '옥천 공장']
    },
    getStatusLabel() {
      return(index) => {
        const i18n=useI18n()
        switch(index) {
          case 0:// 필요
            return i18n.t('testc.0');
          case 1:// 합격
            return i18n.t('testc.1');
          case 2:// 불합격
            return i18n.t('testc.2');
        }
        return ''
      }
    },
    productPagination() {
      if(this.productSelectedRow >= 0) {
        let foundIdx = this.$refs.itemTable.filteredSortedRows.findIndex(x => x.machineNo == this.productList[this.productSelectedRow].machineNo)

        if(foundIdx >= 0) {
          return (foundIdx + 1) + ' / ' + this.productCount
        } else {
          // return (this.productSelectedRow + 1) + ' / ' + this.productCount
          return this.productCount
        }
      } else {
        return this.productCount
      }
    },
    modelPagination() {
      if(this.modelSelectedRow >= 0)
        return (this.modelSelectedRow + 1) + ' / ' + this.modelFiltedCount
      else
        return this.modelFiltedCount
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return store.canDownloadToExcel()
    }
  },
  components : {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    MapView
  },
  data() {
    return {
    }
  },
  setup() {
    const i18n = useI18n()
    return {
      isEditing : ref(false),
      siteSelected : ref(''),
      searchInput : ref(''),

      visibleColumns : ref(['status', 'model', 'vin', 'manDate', 'serial', 'regDate']),
      itemListColumns : ref([
        {
          name: 'index',
          required: false
        },
        {
          name: 'status',
          required: true,
          label: i18n.t('machineDetail.status'),
          align: 'left',
          field:'TESTC',
          sortable: true
        },
        {
          name: 'model',
          required: true,
          label: i18n.t('common.model'),
          align: 'left',
          field: 'model',
          sortable: true
          //sort: (a, b, rowA, rowB) => rowA.model.localeCompare(rowB.model),
        },
        {
          name: 'vin',
          required: true,
          align: 'left',
          label: i18n.t('common.vin'),
          field:'machineNo',
          sortable: true
        },
        { 
          name: 'manDate',
          required: true,
          align: 'left',
          label: i18n.t('productView.prodMonth'),
          field:'PD',
          sortable: true
          //ort: (a, b, rowA, rowB) => rowA.PD.localeCompare(rowB.PD),
          /*
          sort: (a, b, rowA, rowB) => {
            return rowA['PD'].localeCompare(rowB['PD'])
          }
          */
        },
        {
          name: 'serial',
          required: true,
          align: 'left',
          label: i18n.t('machineInfo.serialNo'),
          field:'serial',
          sortable: true
        },
        {
          name: 'regDate',
          required: true,
          align: 'left',
          label: i18n.t('productView.regDate'),
          field:'OD',
          sortable: true
          /*
          sort : (a, b, rowA, rowB) => {
            return rowA['OD'].localeCompare(rowB['OD'])
          }
          */
        },
      ]),
      productList : ref([]),
      itemHeaders : ref([
        i18n.t('common.model'),
        i18n.t('common.vin'),
        i18n.t('productView.prodMonth'),
        i18n.t('machineInfo.serialNo'),
        i18n.t('productView.regDate')
      ]),
      infoValues : ref(['','','','','']),

      pagination:  ref({
        rowsPerPage: 0
      }),
      itemSelected : ref([]),
      maxItemPerPage : ref(0),
      itemPageCount : ref(0),
      productSelectedRow : ref(-1),
      productCount : ref(0),

      isEditMode : ref(false),
      searchingModel : ref(false),

      selectedProdDateCtrl : ref(''),
      selectedRegDateCtrl : ref(''),

      modelVisColums : ref(['model', 'option', 'hp', "type"]),
      modelColumns : ref([
        {
          name: 'index',
          required: false
        },
        {
          name: 'model',
          required: true,
          label: i18n.t('common.model'),
          align: 'left',
          field: 'model',
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'option',
          align: 'left',
          label: i18n.t('common.option'),
          field: 'option',
          ortable: true
        },
        {
          name: 'hp',
          align: 'left',
          label: 'ps', // i18n.t('common.hpUnit'),
          field: 'hp',
          sortable: true
        },
        {
          name: 'type',
          align: 'left',
          label: i18n.t('common.type'),
          field: 'type',
          sortable: true
        },
      ]),
      modelRows : ref([]),
      modelOption : ref(''),
      modelSelected : ref([]),
      modelSelectedRow : ref(-1),
      modelFilter : ref(''),
      modelFiltedCount : ref(0),

      isViewLocationInfo : ref(false),
      isValidLocation : ref(false),
      machineAddress : ref(''),

      hideDelUserPW : ref(true),
      delProdDlg : ref(false),
      pwBeforeDelUser : ref(''),
      dlgMN : ref(''),
      dlgSN : ref(''),
      dlgMsg : ref(''),

      sheets: ref([]),
      hasExcelData : ref(true),
      excelFilename : ref(''),
      excelKeyword : ref('')
    }
  },
  created() {},
  mounted() {
    const store = useTymictStore()
    if(store.CurrentPage.Now != TymConst.PAGE_PRODUCT) {
      store.setPage(TymConst.PAGE_PRODUCT)
    }

    this.siteSelected = this.getSiteList[0]
    setTimeout(() => {
      this.updateProductList()

      let saved = sessionStorage.getItem('PROD.saved')
      if(!TymCommon.isEmpty(saved)) {
        saved = JSON.parse(saved)

        this.siteSelected = saved.Category
        this.searchInput = saved.Keyword

        setTimeout(() => {
          if(!TymCommon.isEmpty(saved.Row) && (saved.Row >= 0)) {
            this.productSelectedRow = -1
            this.productRowClicked(saved.Row, Array.isArray(saved.Selected) ? saved.Selected[0] : saved.Selected)
          }
        }, 50)
      }
    }, 5)
  }, 
  unmounted() {
    sessionStorage.setItem('PROD.saved', JSON.stringify({
      Category: this.siteSelected,
      Keyword: this.searchInput,
      Row: this.productSelectedRow,
      Selected: this.itemSelected
    }))
  },
  methods : {
    unusedParam() {
      //
    },
    updateSite() {

    },
    updateProductList() {
      this.productList = []

      TymAws.getAllProductData()
        .then(data => {
          // console.log(data)
          /*
          {
            "SOLD": 0,
            "MN": "PEP000008A",
            "PD": "2023-04",
            "TESTC": 0,
            "OD": "2023-04-25",
            "MON": "RGO-690_0",
            "SN": "A002230600010001",
            "UID": "jjh1004"
          }
          */
          for(let i = 0; i < data.length; i++) {
            /*
            let date = ''
            if(!TymCommon.isEmpty(data[i].PD)) {
              let dateStr = data[i].PD.substring(0, 4) +"-"+ data[i].PD.substring(5, 7) +"-01"
              date = TymCommon.getMonthString(new Date(dateStr.trim()))
            }
            */
            let modelName = TymCommon.getModelFullname(data[i].MON)

            this.productList.push({
              index : i,
              ModelInfo : modelName,
              model : modelName.Fullname, // data[i].MON,      // 모델명
              machineNo : data[i].MN,     // 기대번호
              PD : data[i].PD,
              manDate : TymCommon.PD2DateString(data[i].PD),     // 생산연월
              serial : data[i].SN,      // 시리얼 번호
              OD : data[i].OD,
              regDate : TymCommon.isEmpty(data[i].OD) ? '' : TymCommon.convertStringToDate(data[i].OD),     // 등록일자
              // status : this.getStatusLabelM(data[i].TESTC),   // 테스트 필요여부 0, 1, 2(필요,합격,불합격)
              uid : data[i].UID,
              TESTC : Number(data[i].TESTC)
            })
          }

          // console.log('getAllProductData :', this.productList)
        }).catch(er => {
          console.log('ProductView.getAllProductData ER :', er)
        })

      this.productCount = this.productList.length
    },
    getStatusLabelM(index) {
      const value = [this.$t('testc.0'), this.$t('testc.1'), this.$t('testc.2')]
//      console.log('getStatusLabelM :', index, value[index])
      return value[index]
    },
    productFilterMethod() {
      if(this.isEditMode) {
        this.productCount = this.productList.length 
        return this.productList
      }
      let filtered = this.productList

      if(!TymCommon.isEmpty(this.searchInput)) {
        let inputData = this.searchInput.toUpperCase().trim()
          let found2 = filtered.filter(row => (row.serial.toUpperCase().includes(inputData)) || (row.machineNo.toUpperCase().includes(inputData)) || (row.model.toUpperCase().includes(inputData) ))
          if(!TymCommon.isEmpty(found2)) {
            this.productCount = found2.length 
            return found2
          } else {
            this.productCount = 0
            return []
          }
      } else {
        this.productCount = this.productList.length 
        return this.productList
      }
    },
    updateSelectionInfo(row) {
      let idx = 0;

      if(TymCommon.isEmpty(row)) {
        this.infoValues[idx] = ''
        idx++

        this.infoValues[idx] = ''
        idx++

        this.infoValues[idx] = ''
        idx++

        this.infoValues[idx] = ''
        idx++

        this.infoValues[idx] = ''
        idx++

        this.isViewLocationInfo = false
        this.isValidLocation = false
      } else {
        this.infoValues[idx] = row.model
        idx++

        this.infoValues[idx] = row.machineNo
        idx++

        this.infoValues[idx] = row.manDate
        idx++

        this.infoValues[idx] = row.serial
        idx++

        this.infoValues[idx] = row.regDate
        idx++

        // this.isViewLocationInfo = false
        this.isValidLocation = false

        this.viewLocation()
      }
    },
    updatePagination(newPagination) {
      this.unusedParam(newPagination)
      //console.log('updatePagination :', this.pagination, newPagination)
    },
    productRowClicked(rowIndex, row) {
      if((this.itemSelected.length < 1) || (this.itemSelected[0].machineNo != row.machineNo)) {
        // console.log('productRowClicked :', rowIndex, row, this.itemSelected)

        this.productSelectedRow = rowIndex
        this.itemSelected = []
        this.itemSelected.push(row)

        this.updateSelectionInfo(row)
      }
    },
    updateSelection(newSel) {
      // console.log('updateSelection : ', newSel, this.itemSelected)
      this.updateSelectionInfo(newSel[0])
    },
    startEdit() {
      this.isEditMode = true
      this.searchingModel = false
      this.modelSelectedRow = -1
      this.modelSelected = []

      this.isViewLocationInfo = false
      this.isValidLocation = false
    },
    cancelEdit() {
      this.isEditMode = false
    },
    deleteMachine() {
      this.hideDelUserPW = true
      this.pwBeforeDelUser = ''
      this.delProdDlg = true
      this.dlgMN = ''
      this.dlgSN = ''
      if(!TymCommon.isEmpty(this.itemSelected[0].machineNo)) {
        this.dlgMN = this.itemSelected[0].machineNo
      } 
      if(!TymCommon.isEmpty(this.itemSelected[0].serial)) {
        this.dlgSN = this.itemSelected[0].serial
      }
      this.dlgMsg = this.$t('group.questionDelete') + '?'
    },
    checkPasswordOK() {
      console.log("checkPasswordOK")
      TymAws.removeProductData(this.itemSelected[0].machineNo, this.itemSelected[0].serial)
        .then(data => {
          console.log('Erase product ok :', data)
          TymAws.lookup(TymConst.ACTION_DEL_PRODUCT, this.itemSelected[0].machineNo);

          setTimeout(() => {
            this.searchInput = ''
            this.updateSelectionInfo(null)
            this.updateProductList()
          }, 5)
        })
        .catch(er => {
          console.log('Erase product failed :', er)
        })
    },
    checkPasswordFailed(code, message) {
      console.log('checkPasswordFailed... :', code, message)
      TymCommon.Toast(this.$t('noticeView.deleteNoticeMsg2'))
    },
    eraseUserConfirmed() {
      if(!TymCommon.isEmpty(this.pwBeforeDelUser,)) {
        TymAws.checkPassword(this.pwBeforeDelUser, this.checkPasswordOK, this.checkPasswordFailed)
      }
    },
    viewLocation() {
      this.machineAddress = ''

      if(!TymCommon.isEmpty(this.infoValues[3])) {
        TymAws.getLastPosBySN(this.infoValues[3])
          .then(data => {
            this.isViewLocationInfo = true

            let longitude = parseFloat(data[2])
            let latitude = parseFloat(data[1])

            let locaInfo = { 
              machineNo : this.infoValues[1],
              longitude :longitude,
              latitude : latitude,
              serialNo : this.infoValues[3],
              modelNo: this.infoValues[0]
            }
            // console.log('viewLocation OK :', data, locaInfo)
            this.$refs.locationMap.updateMapData([locaInfo])
          }).catch(er => {
            console.log('viewLocation ER :', er)
            this.isViewLocationInfo = false
          })
      } else {
        this.isViewLocationInfo = false
      }
    },

    clickSearchModel() {
      this.searchingModel = true

      setTimeout(() => {
        this.updateModelList()
      }, 50)
    },
    updateModelList() {
      const store = useTymictStore()
      let i = 0

      this.modelRows = []
      for(i = 0; i < store.ModelData.count; i++) {
        /*
        {
            "citem06_sn": "",
            "option": "MARKET",
            "hp": "Unknown",
            "citem03_cc": "500",
            "citem05_cf": "50",
            "citem01_eb": true,
            "development": true,
            "citem01_sn": "",
            "citem03_eb": true,
            "citem03_sn": "",
            "citem05_sn": "",
            "citem07_eb": true,
            "citem05_eb": true,
            "citem07_cc": "300",
            "citem01_cf": "100",
            "citem03_cf": "500",
            "citem05_cc": "300",
            "ads_type": "EPS_CN",
            "citem01_cc": "500",
            "citem07_cf": "50",
            "model": "AFTER",
            "citem07_sn": "",
            "citem04_cf": "200",
            "citem02_eb": true,
            "citem02_sn": "",
            "citem06_eb": true,
            "citem04_sn": "",
            "citem04_eb": true,
            "citem02_cf": "500",
            "citem06_cc": "300",
            "citem04_cc": "200",
            "citem06_cf": "50",
            "citem02_cc": "500",
            "type": "AFTERMARKET"
        }
        */
        let modelFullName = TymCommon.getModelFullname(store.ModelData.data[i].model + '_' + store.ModelData.data[i].option)
        
        let opt = ''
        if(store.ModelData.data[i].option != 'NONE' && store.ModelData.data[i].option != '0')
            opt = store.ModelData.data[i].option
        this.modelRows.push( {
          index : i,
          model : store.ModelData.data[i].model,
          option : opt,
          hp : store.ModelData.data[i].hp,
          type : store.ModelData.data[i].type,
          Fullname : modelFullName
        })

        /*
        let foundModel = this.getCategory.find(x => x == store.ModelData.data[i].type)
        if(TymCommon.isEmpty(foundModel)) {
          this.modelOptions.push(store.ModelData.data[i].type)
        }
        */
      }
      this.modelOption = this.getCategory[0]
      this.modelFiltedCount = this.modelRows.length
    },
    modelPageChanged() {
      this.$refs.modelTable.setPagination({
        page:this.modelPagination
      })
    },
    updateModelSelection(newSel) {
      // console.log('updateModelSelection : ', newSel, this.customerSelected)
      this.updateModel(newSel[0])
    },
    listModelSelected(evt, row, index) {
      this.unusedParam(evt)
      this.unusedParam(index)

      this.modelSelectedRow = index
      this.modelSelected = []
      this.modelSelected.push(row)

      //console.log('listModelSelected : ', row, this.modelSelectedRow, this.modelSelected)
      this.updateModel(row)
    },
    modelRowClicked(prop) {
      //console.log('modelRowClicked :', prop)

      this.modelSelectedRow = prop.rowIndex
      this.modelSelected = [prop.row]

      this.updateModel(prop.row)
    },
    updateModel(row) {
      this.infoValues[0] = row.model
      if(!TymCommon.isEmpty(row.option)) {
        this.infoValues[0] += row.option
      }
    },
    beforeSelectProdDate() {
      try {
        // const store = useTymictStore()
        const myDate = moment(this.infoValues[2], ["YYYY. M.", "M-YYYY"]).toDate();
        this.selectedProdDateCtrl = moment(myDate).format("YYYY/MM/DD")
        //console.log('beforeSelectProdDate() :', this.infoValues[2], myDate, this.selectedProdDateCtrl)
      } catch(err) {
        console.log('beforeSelectProdDate error :', err)
      }
    },
    selectedProdDate(value, reason, details) {
      this.unusedParam(value)
      this.unusedParam(reason)

      let selDate = new Date(details.year, details.month - 1, details.day)
      this.infoValues[2] = this.selectedProdDateCtrl = TymCommon.getMonthString(selDate)
      //console.log('selectedProdDate :', details,  "=>", this.selectedProdDateCtrl)
      
      this.$refs.prodDatePopup.hide()
    },

    beforeSelectRegDate() {
      try {
        // const store = useTymictStore()
        const myDate = moment(this.infoValues[4], ["YYYY. M. D.", "M-D-YYYY"]).toDate();
        this.selectedRegDateCtrl = moment(myDate).format("YYYY/MM/DD")
        // console.log('beforeSelectRegDate() :', this.infoValues[4], this.selectedRegDateCtrl, myDate)
      } catch(err) {
        console.log('beforeSelectRegDate error :', err)
      }
    },
    selectedRegDate(value, reason, details) {
      this.unusedParam(value)
      this.unusedParam(reason)

      let selDate = new Date(details.year, details.month - 1, details.day)
      this.infoValues[4] = this.selectedRegDateCtrl = TymCommon.getDateString(selDate)
      // console.log('selectedRegDate :', details,  "=>", this.selectedRegDateCtrl)
      
      this.$refs.regDatePopup.hide()
    },
    modelFilterMethod() {
      if(TymCommon.isEmpty(this.modelOption))
        return this.modelRows;

      let found = []
      // 전체 선택시
      if(this.modelOption == this.getCategory[0])
        found = this.modelRows;
      else
        found = this.modelRows.filter(row => (row.type.toUpperCase() == this.modelOption.toUpperCase()))

      if(found && (!TymCommon.isEmpty(this.modelFilter))) {
        // console.log('modelFilterMethod :', this.modelFilter,  "=>", found)
        let upperFilter = this.modelFilter.toUpperCase()
        found = found.filter(row => (row.model.toUpperCase().indexOf(upperFilter) >= 0) || (row.option.toUpperCase().indexOf(upperFilter) >= 0))
      }

      this.modelSelectedRow = -1
      this.modelSelected = []
      this.modelFiltedCount = found.length
      return found
    },
    addrReceived(machineID, addr) {
      this.unusedParam(machineID)

      if(TymCommon.isEmpty(addr)) {
        this.isValidLocation = false
        return
      }
      this.isValidLocation = true
      this.machineAddress = addr
    },
    saveEdit() {
      let found = this.productList.find(x => x.machineNo == this.itemSelected[0].machineNo)
      if(found) {
        console.log('Save #1:', found, this.itemSelected[0], this.modelSelected)

        let manDate = moment(this.infoValues[2], ["YYYY. M.", "M-YYYY"]).toDate();
        let regDate = moment(this.infoValues[4], ["YYYY. M. D.", "M-D-YYYY"]).toDate();

        /*
        if(this.isKorean) {
          manDate = new Date(found.manDate)
        } else {
          manDate = new Date('1/' + found.manDate)
        }
        */
        manDate = manDate.getFullYear().toString() + '-' + (manDate.getMonth() + 1).toString().padStart(2, '0')
        regDate = regDate.getFullYear().toString() + '-' + (regDate.getMonth() + 1).toString().padStart(2, '0') + '-' + regDate.getDate().toString().padStart(2, '0')

        // console.log('>>>>>>>>>>>>>>>>>>>', this.infoValues)

        const store = useTymictStore()
        let mdFound = store.ModelData.data.find(x => x.model == found.ModelInfo.Model && x.option == found.ModelInfo.Option)
        let typeIdx = store.WebConfig.MachineTypes[0].indexOf(mdFound.type)
        let adsLevel = store.WebConfig.ADSLevelDefault[typeIdx]
        let autoSteer = mdFound.ads_type

        let modelInfo = {}
        if(this.modelSelected.length > 0) {
          modelInfo = this.modelSelected[0].Fullname
        } else {
          modelInfo = this.itemSelected[0].ModelInfo
        }
        console.log('Save manDate, regDate:', this.itemSelected[0].machineNo, modelInfo.Model + '_' + modelInfo.Option,
                                 this.infoValues[3], this.itemSelected[0].uid,  0, this.itemSelected[0].TESTC, regDate, manDate, autoSteer, adsLevel, mdFound.type)
        TymAws.updateProductData(this.itemSelected[0].machineNo, modelInfo.Model + '_' + modelInfo.Option,
                                this.infoValues[3], this.itemSelected[0].uid,  0, this.itemSelected[0].TESTC, regDate, manDate, autoSteer, adsLevel, mdFound.type)
          .then(data => {
            if(!data.RETURN) {
              let msg = ''
              switch(data.REASON) {
                case 'NOT FOUND SN: SN_INFO':
                  msg = this.$t('errorMsg.notRegisteredSN')
                  break;
                case 'MN IS OVERLAP':
                  msg = this.$t('errorMsg.usedMN')
                  break;
                case 'SN IS OVERLAP':
                  msg = this.$t('errorMsg.usedSN')
                  break;
                case 'UPDATE FAIL':
                default:
                  msg = this.$t('errorMsg.failUdpatePD')
                  break;
              }
              this.isEditMode = false
              TymCommon.Toast(msg)
              return
            }
            setTimeout(() => {
              this.updateProductList()
              this.isEditMode = false
            }, 25)
          })
          .catch(er => {
            console.log('update prod failed :', er)
            this.isEditMode = false
            TymCommon.Toast(this.$t('errorMsg.failUdpatePD'))
          })
      }
    },
    onShowExportToExcel() {
      // console.log('onShowExportToExcel()')
      let now = new Date()
      this.excelFilename = 'productlist_' + now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += '_' + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += '.xlsx'
      if(TymCommon.isEmpty(this.searchInput))
        this.excelKeyword = this.$t('common.all')
      else 
        this.excelKeyword = this.searchInput

      if(this.productList.length < 1) {
        this.hasExcelData = false
        return
      }
      let filters = []
      if(TymCommon.isEmpty(this.searchInput))
        filters = this.productList
      else {
        let inputData = this.searchInput.toUpperCase().trim()
        let found2 = this.productList.filter(row => (row.serial.toUpperCase().includes(inputData)) || (row.machineNo.toUpperCase().includes(inputData)) || (row.model.toUpperCase().includes(inputData) ))
        if(found2) {
          filters = found2
        }
      }

      if(filters.length < 1) {
        this.hasExcelData = false
        return
      }

      this.hasExcelData = true
      let xlsRow1 = []
      xlsRow1.push('No', '상태', '기종', '모델', '기대번호', 'S/N', '생산연월', '등록일자')
      let rowNo = 1
      let othRows = []
      filters.forEach(one => {
        let oneRow = [rowNo.toString(), this.getStatusLabelM(one.TESTC), one.ModelInfo.Model, one.ModelInfo.Fullname, one.machineNo, one.serial, one.manDate, one.regDate ]
        othRows.push(oneRow)
        rowNo++
      })
      othRows.splice(0, 0, xlsRow1)

      this.sheets.push(
        {
          name: '생산(목록)',
          data: othRows
        }
      )
    },
    saveToExcelFile() {
      let desc = {
        filename: this.excelFilename,
        keyword: this.excelKeyword
      }
      TymAws.writeAction('생산', '다운로드', desc)

      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 500)
    },
  }
})
</script>
