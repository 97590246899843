<template>
  <div class="column">
    <div class="col-auto row items-center justify-between" style="height:45px;width:100%;">
      <div class="col-auto"
          :style="isKorean ? 'font-family:Noto Sans KR;font-weight:bold;font-size:17px;' : 'font-family:Prometo;font-weight:500;font-size:19px;'">
        {{ getPageTitle }}
      </div>
    </div>

    <!-- 지도영역 -->
    <div class="col column mapBorder">
      <!-- 지도 제목 및 Refresh 등 -->
      <div class="col-auto row" v-show="viewTopControl">
        <div class="col row items-center justify-start">
          <div class="col-auto column items-start justify-center" style="height:36px;">
            <div class="col row items-center">
              <div class="col-auto" style="font-size:14px;font-weight:normal;margin-right:5px">{{$t('dashboardView.machinemap')}}</div>
              <!--
              <q-btn dense flat no-caps text-color="black" class="col-auto"
                      style="font-size:14px;line-height:22px;font-weight:normal;"
                      >{{$t('dashboardView.machinemap')}}
              </q-btn>
              -->
              <q-btn class="col-auto" dense flat @click="onMachineViewClicked" style="width:32px;height:auto;margin:0;padding:0;">
                <q-img src="../assets/monitor/machinemap_size_p.svg" fit="fill" v-show="selectedMenu==index" style="margin:0;padding:0;"/>
                <q-tooltip style="font-size:14px;">{{ $t('tooltip.moveMonitor') }}</q-tooltip>
              </q-btn>
            </div>
          </div>
          <div class="col column items-end justify-center">
            <q-btn class="col-auto" dense flat @click="onRefreshClicked" v-show="!isLoadingData" style="width:32px;height:auto;margin:0;padding:0;">
              <q-img src="../assets/dashboard/dashboard_mapre_icon.svg" fit="fill" v-show="selectedMenu==index"/>
              <q-tooltip style="font-size:14px;">{{ $t('tooltip.refreshLocation') }}</q-tooltip>
            </q-btn>

            <!-- 기다림 시계 표시용 -->
            <q-btn dense flat disable v-show="isLoadingData" class="col items-center justify-center" style="margin:3pt 0 0 0;">
              <q-spinner-clock dense color="red" size="20px"/>
            </q-btn>
          </div>
        </div>
      </div>

      <!-- 지도 -->
      <div class="col">
        <MapView ref="mapView" class="mapClass"></MapView>
      </div>
    </div>

    <!-- 아래 네개의 항목 버튼 보기 -->
    <div class="col-auto" v-show="viewBottomControl" style="padding:6px 0 0 0">
      <TotalView ref="totalView"></TotalView>
    </div>
  </div>
</template>

<style scoped>

.title {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}

.toggle {
  margin-right:5px;
  font-size:12pt;
  font-weight: 500;
}

.mapBorder {
  background-color:white;
  padding:0px 12px 12px 12px;
  border-radius: 3px;
}
.mapClass {
  min-width:100px;
  min-height:100px;
  width:100%;  
  height:100%;  
}

.green-marker {
  color :black;
  background-color:green;
}
.red-marker {
  background :red;
}
.orange-marker {
  background :orange;
}

</style>

<script>
import { ref } from 'vue'
//import { useQuasar } from 'quasar'
import { useTymictStore } from '../store/tymict'
import TotalView from '@/views/TotalView.vue'
// import TymCommon from '@/js/tymcommon.js'
import TymFncs from '@/js/tymfunctions.js'
import TymConst from '@/js/tymconstants.js'
//import AWS from 'aws-sdk'
//import TymAuth from '@/js/tymauth.js'
import MapView from './MapView.vue'

export default ({
  data() {
    return {
    }
  },
  components : {
    TotalView,
    MapView,
    /*
    GoogleMap,
    GMarker,
    InfoWindow,
    MarkerCluster,
    GCircle
    */
  },
  watch : {
    locationChanged : {
      handler(newVal, oldVal) {
        if(TymConst.IS_DEVELOPMENT) {
          console.log('Dashboard.watch.locationChanged', oldVal, newVal)
        }
        this.unusedParam(oldVal)
        if((this.isLoadingData != newVal) && !newVal) {
          this.updateLocation()
        }
        this.isLoadingData = newVal
      },
      immediate : true
    }
  },
  computed: {
    locationChanged : function() {
      const store = useTymictStore()
      return store.loadStateMachine
    },
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    getPageTitle : function() {
      return TymFncs.getPageTitle()
    },
    viewTitle : function() {
      const store = useTymictStore()
      return (store.CurrentPage.Now < 4)
    },
    viewTopControl : function() {
      const store = useTymictStore()
      if(store.CurrentPage.Now >= 4)
        return false;
      return !this.expandMap
    },
    viewBottomControl : function() {
      const store = useTymictStore()
      let show = true;
      switch(store.CurrentPage.Now)
      {
        case 0:
          show = !this.expandMap
          break;
        default:
          show = false
          break;
      }
      return show
    }
  },
  setup() {
    return {
      viewOperating : ref(false),
      searchInput : ref(''),
      isLoadingData : ref(false),
      checkedViewAll : ref(false),   // 지도위에 Check Toggle버튼 값 저장용

      completedEmergency : ref(false),
      completedBroken : ref(false),
      completedConsumable : ref(false),
      completedLowBattery : ref(false),
    }
  },
  created() {
    /*
    this.ontransitionend  = function(event) {
      console.log('dashboard.ontransitionend :', event)
    }
    */
  },
  mounted() {
    const store = useTymictStore()
    if(TymConst.IS_DEVELOPMENT) {
      console.log('Dashboard.mounted', store.CurrentPage.Now)
    }

    setTimeout(() => {
      if(store.CurrentPage.Now != TymConst.PAGE_DASHBOARD) {
        store.setPage(TymConst.PAGE_DASHBOARD)
      }

      this.updateLocation(true)
    }, 25)
  }, 
  unmounted() {
    try {
      if(this.infoWindow) {
        this.infoWindow.close()
        this.infoWindow = null
      }

      if(this.markers) {
        this.markers = null
      }
    } catch (ex) {
      console.log('Dashboard unmounted... ER', ex)
    }
  },
  methods : {
    unusedParam() {
      //
    },
    getVersion() {
      return TymConst.VERSION
    },
    onMachineViewClicked() {
      const store = useTymictStore();

      store.setPage(TymConst.PAGE_MONITOR)
    },
    onRefreshClicked() {
      const store = useTymictStore()

      this.completedEmergency = false
      this.completedBroken = false
      this.completedConsumable = false
      this.completedLowBattery = false

      store.refreshCurrentLocation(true, true, this.getEmergencyAlert, this.getBrokenAlert, this.getConsumableAlert, this.getLowBatteryAlert)
    },
    getEmergencyAlert(hasNoError) {
      this.unusedParam(hasNoError)
      this.completedEmergency = true

      console.log('end of getEmergencyAlert')
      setTimeout(() => {
        this.doCompleteRefresh()
      }, 1)
    },
    getBrokenAlert(hasNoError) {
      this.unusedParam(hasNoError)
      this.completedBroken = true

      console.log('end of getBrokenAlert')
      setTimeout(() => {
        this.doCompleteRefresh()
      }, 1)
    },
    getConsumableAlert(hasNoError) {
      this.unusedParam(hasNoError)
      this.completedConsumable = true

      console.log('end of getConsumableAlert')
      setTimeout(() => {
        this.doCompleteRefresh()
      }, 1)
    },
    getLowBatteryAlert(hasNoError) {
      this.unusedParam(hasNoError)
      this.completedLowBattery = true

      console.log('end of getLowBatteryAlert')
      setTimeout(() => {
        this.doCompleteRefresh()
      }, 1)
    },
    doCompleteRefresh() {
      if(this.completedEmergency && this.completedBroken && this.completedConsumable && this.completedLowBattery) {
        console.log('refresh alarm completed....')
      }
    },
    changedViewOption(checked) {
      console.log('changedViewOption : ', this.checkedViewAll, checked)
    },
    refreshMap() {
      this.onRefreshClicked()
    },
    updateLocation(isFirstTime) {
      const store = useTymictStore()
      if(this.$refs.mapView) {
        if(TymConst.IS_DEVELOPMENT) {
          // connsole.log('Dashboard.changedViewOption :', isFirstTime, store.machineLocaInfo)
        }

        // console.log('Dashboard.updateLocation :', store.machineLocaInfo)

        if(this.$refs.mapView) {
          this.$refs.mapView.updateMapData(store.machineLocaInfo, undefined, undefined, isFirstTime, true)
        }
      }
    }
  }
})
</script>
