import axios from 'axios'
import { useTymictStore } from '@/store/tymict'
import { useI18n } from 'vue-i18n'
import TymCommon from '@/js/tymcommon.js'
import TymConst from '@/js/tymconstants'

/// 이름과 이메일, 이름과 전화번호를 통해 ID를 찾는다.
/// findoption_index : 0 - Email, 1 - Phone
async function findIDbyEmailOrPhone(value1, value2, findoption_index){
    const store = useTymictStore()
    let url = "";
    if(findoption_index == 0) {
        //url = 'https://aj50kgerv9.execute-api.ap-northeast-2.amazonaws.com/tymict_webuserinfo/option1?v1='+value1+'&v2='+value2;
        url = store.gateway.option1[store.connectServer] + value1 + '&v2=' + value2;
    } else if(findoption_index == 1) {
        //url = 'https://aj50kgerv9.execute-api.ap-northeast-2.amazonaws.com/tymict_webuserinfo/option2?v1='+value1+'&v2='+value2;
        url = store.gateway.option2[store.connectServer] + value1 + '&v2=' + value2;
    }
    let res = []
    const resOK = (response) => {
        try {
            let index = 0;
            for(index = 0; index < response.data.Count; index++) {
                if( findoption_index == 0) {
                    res.push( {
                        id : response.data.Items[index].username.S,
                        name : response.data.Items[index].name.S,
                        email : response.data.Items[index].email.S,
                        phone : response.data.Items[index].uphone.S,
                    }) 
                } else {
                    res.push( {
                        id : response.data.Items[index].username.S,
                        name : response.data.Items[index].name.S,
                        email : '',
                        phone : response.data.Items[index].uphone.S,
                    }) 
                }
            }
            if(TymConst.IS_DEVELOPMENT) {
                console.log("resOK  : ", url, response);
            }
        } catch(er) {
            console.log("findIDbyEmailOrPhone exception  : ", er);
        }
    }
    await axios.get(url)
        .then(resOK)
        .catch(function(error) {
            console.log(error);
        })

    // console.log("결과: ", res)
    return res
}

    /*
    let types = [
        i18n.t('constants.emergency_type1'),
        i18n.t('constants.emergency_type2'),
        i18n.t('constants.emergency_type3'),
        i18n.t('constants.emergency_type4'),
        i18n.t('constants.emergency_type5'),
    ]
    */

function getEmergencyTypeString() {
    console.log('tymfunctions.getEmergencyTypeString is called ...')
    /*
    const i18n=useI18n()

    return i18n.t('constants.emergency_type5');
    */
}

function getPageTitle() {
    const store = useTymictStore()
    const i18n=useI18n()

    // console.log("tymfunctions.getPageTitle is called :", store.CurrentPage.Now)
    let titleName = ""
    switch(store.CurrentPage.Now)
    {
    case 0:
        titleName = i18n.t('leftbar.dashboard')
        break
    case 1:
        titleName = i18n.t('leftbar.equipment')
        break
    case 2:
        titleName = i18n.t('leftbar.sales')
        break
    case 3:
        titleName = i18n.t('leftbar.management')
        break
    case 4:
        titleName = i18n.t('leftbar.statistics')
        break
    case 5:
        titleName = i18n.t('leftbar.notice')
        break
    case 6:
        titleName = i18n.t('leftbar.group')
        break
    case 7:
        titleName = i18n.t('leftbar.product')
        break
    case 8:
        titleName = i18n.t('leftbar.customer')
        break
    case TymConst.PAGE_UNDERCOVER:
        titleName = '실험실!!'
        break
    case TymConst.PAGE_MANAGE_POPUP:
        titleName = i18n.t('leftbar.machineview')
        break
    case TymConst.PAGE_MONITOR:
        titleName = i18n.t('leftbar.monitor')
        break
    case TymConst.PAGE_USIM:
        titleName = i18n.t('leftbar.usim')
        break
    }

    return titleName;
}

function convertAdsType(strType) {
    if((strType === "트랙터") || (strType.toUpperCase() === "TRACTOR"))
    {
        return "EHI_T"
    }
    else if((strType === "이앙기") || (strType.toUpperCase() === "RICEPLANTER") || (strType.toUpperCase() === "RICETRANSPLANTER"))
    {
        return "EPS_R"
    }
    else if((strType === "콤바인") || (strType.toUpperCase() === "COMBINE"))
    {
        return "EPS_R"
    }
    else if(strType === "AFTERMARKET")
    {
        return "EPS_CN"
    }
    else 
    {
        return "0"
    }
}

function convertDevelopment(strModel) {
    const strLast = strModel.substring(strModel.length - 1);
    const strLast2 = strModel.substring(strModel.length - 2);
    if(strLast === "Z" || strLast === "A" || strLast2 === "KT") {
        return true;
    }
    else return false;
}

function getMyUUID() {
    const store = useTymictStore()
    var rtMyid

    //console.log(store, store.CurrentUser)
    if(!TymCommon.isEmpty(store.CurrentUser)) {
        var createdAt = new Date()

        rtMyid = String(createdAt.getTime()) + "-" + store.CurrentUser['username'];
        return rtMyid
    }
    return uuidv4()
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) 
    {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
          return v.toString(16)
    })
}


export default {
    findIDbyEmailOrPhone,
    getEmergencyTypeString,
    getPageTitle,
    convertAdsType,
    convertDevelopment,
    getMyUUID
}
