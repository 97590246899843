<template>
  <div class="column listContainer">
    <!-- 상단 카테고리 정보 -->
    <div class="row items-center" style="margin:0 0 6px 0;height:64px;">
      <img class="col-auto" :src="itemIcon[kind]" style="width:50px;height:50px;margin-right:5pt;"/>

      <div class="col column">
        <div class="col" style="font-size:14px;">{{itemTitle[kind]}}</div>
        <div class="col row">
          <div class="col" ref="divCount">{{ itemCount }}</div>
          <div class="row no-wrap justify-end items-end" style="height:100%;width:100%;">
            <div class="col row items-center justify-end" v-show="itemCount > 0">
              <q-btn flat dense icon="chevron_left" :disable="getBtnState(true)" @click="viewPrevious"></q-btn>
              <div style="margin:0 3px;">{{ curPage * itemsPerPage + 1 }} - {{ getLastItem() }}</div>
              <q-btn flat dense icon="chevron_right" :disable="getBtnState(false)" @click="viewNext"></q-btn>
            </div>
            <q-btn flat dense @click="downloadToExcel" style="margin-top:-5px;" v-show="canDownloadToExcel">
              <q-tooltip style="font-size:14px;">{{ $t('tooltip.exportExcel') }}</q-tooltip>
              <img src="~../assets/manage/management_excel_icon.svg" style="width:26px;height:32px;"/>
              <q-popup-proxy ref="excelPopup" style="width:480px;height:200px;background-color: #01010130;border-radius: 3px;position: relative;"
                              @before-show="onShowExportToExcel">
                <div style="border:1px solid #101010;width:100%;height:100%;border-radius: 3px;">
                  <div class="column items-center justify-start" style="background-color: white;width:100%;height:100%;border-radius: 3px;padding:12px;">
                    <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-weight:bold;font-size:18px;margin:0 0 12px 0;">
                      {{ $t('common.toExcel')}}
                    </div>
                    <div class="col column" style="width:100%;height:100%;" v-show="hasExcelData">
                      <div class="col column items-center justify-center">
                        <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:500;color:#eb0028;text-decoration : underline">
                          {{itemTitle[kind]}}
                        </div>
                      </div>
                      <div class="col column items-center justify-center" v-show="searchKeyword.length > 0">
                        <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:16px;">
                          [{{ searchKeyword }}]
                        </div>
                      </div>
                      <div class="col row items-center justify-start" style="width:100%;margin:0;">
                        <div class="col text-center" style="font-family:Prometo;width:100%;margin:0;">
                          {{ excelFilename }}
                        </div>
                      </div>
                      <xlsx-workbook class="col-auto row items-center justify-center">
                        <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" @parsed="onParsed">
                        </xlsx-sheet>
                        <xlsx-download :filename="excelFilename">
                          <button :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="height:32px;width:100px;" @click="saveToExcelFile">{{ $t('common.download') }}</button>
                        </xlsx-download>
                      </xlsx-workbook>
                    </div>
                    <div class="col row items-center justify-center" style="width:100%;height:100%;" v-show="!hasExcelData">
                      <div class="col text-center" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:18px;font-weight:bold;color:#eb0028">{{ $t('warning.nodata')}}</div>
                    </div>
                  </div>
                </div>
                <div class="row items-center justify-center" v-show="makingExcel"
                    style="position:absolute;z-index:3;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                  <q-spinner color="red-7" size="70px" thickness="1.5"/>
                </div>
              </q-popup-proxy>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="col row itemContainer">
      <q-scroll-area class="col" >
        <q-list dense style="overflow-y: Auto;">
          <q-item style="padding:0 5px 0 10px" :class="itemBorder" clickable v-show="itemCount > 0" v-for="(item, index) in viewItems" :key="item" @click="onClickItem(index)">
            <table style="width:100%;margin:0 6px 0 0;">
              <tr>
                <td colspan="3">
                  <q-item-label style="font-size:13px;">{{item.name}} ({{ item.phone }})</q-item-label>
                </td>
                <td align="right" valign="top">
                  <q-icon style="align-items: end;" name="circle" size="14px" :color="item.state ? getStateColor(item.state) : 'white'" />
                </td>
              </tr>
              <tr>
                <td align="right" valign="center" colspan="4">
                  <q-item-label style="font-size:13px;text-overflow: ellipsis;white-space: nowrap;">{{ item.desc }}</q-item-label>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <q-item-label style="font-size:13px;">{{ item.machineNo }}</q-item-label>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <q-item-label style="font-size:13px;">{{$t('dashboardView.dealer')}} : {{ item.dealer }}</q-item-label>
                </td>
                <td align="right" valign="center" colspan="2">
                  <q-item-label style="font-size:13px;text-align:end;">{{ item.date }}</q-item-label>
                </td>
              </tr>
            </table>
            <!--
            <div class="column" style="width:100%;margin:0 0 6px 0;height:auto;">
              <div class="col row justify-between">
                <q-item-section class="col-auto">
                  <q-item-label style="font-size:14px;">{{item.name}} ({{ item.phone }}))</q-item-label>
                </q-item-section>
                <q-item-section side top class="col">
                  <q-icon name="circle" size="14px" :color="(index % 3) == 0 ? 'green' : 'gray'" />
                </q-item-section>
              </div>

              <div class="col row justify-between">
                <q-item-section class="col-auto">
                  <q-item-label style="font-size:4px;">&nbsp;</q-item-label>
                  <q-item-label style="font-size:12px;">{{ item.serial }}</q-item-label>
                  <q-item-label style="font-size:12px;">Dealer : {{ item.dealer }}</q-item-label>
                </q-item-section>
                <q-item-section side top class="col-auto">
                  <q-item-label style="font-size:12px;">{{ item.desc }}</q-item-label>
                  <q-item-label style="font-size:4px;">&nbsp;</q-item-label>
                  <q-item-label style="font-size:12px;">{{ item.date }}</q-item-label>
                </q-item-section>
              </div>
            </div>
            -->
          </q-item>
        </q-list>
      </q-scroll-area>
    </div>
  </div>
</template>

<style scoped>

.listContainer {
  border-radius:3px;
  height:100%;
}

.itemContainer {
  background-color:#f2f2f2;
  border-radius: 3px;
  padding:6px;
}
.itemBorder1 {
  border-color : #e7e7e7 #e7e7e7 #e7e7e7 #eb0028;
  border-style: solid;
  border-width : 1px 1px 1px 3px;
  border-radius: 4px 3px 3px 5px;
  margin-bottom:4px;
  background-color: white;
  height:92px;
}
.itemBorder2 {
  border-color : #e7e7e7 #e7e7e7 #e7e7e7 #c97d31;
  border-style: solid;
  border-width : 1px 1px 1px 3px;
  border-radius: 4px 3px 3px 5px;
  margin-bottom:4px;
  background-color: white;
  height:92px;
}
.itemBorder3 {
  border-color : #e7e7e7 #e7e7e7 #e7e7e7 #ffc600;
  border-style: solid;
  border-width : 1px 1px 1px 3px;
  border-radius: 4px 3px 3px 5px;
  margin-bottom:4px;
  background-color: white;
  height:86px;
}
.itemBorder4 {
  border-color : #e7e7e7 #e7e7e7 #e7e7e7 #bbbbbb;
  border-style: solid;
  border-width : 1px 1px 1px 3px;
  border-radius: 4px 3px 3px 5px;
  margin-bottom:4px;
  background-color: white;
  height:86px;
}
</style>

<script>
import { useTymictStore } from '@/store/tymict'
import { ref } from 'vue'
import TymCommon from '@/js/tymcommon'
import TymAws from '@/js/tymaws'
import TymConst from '@/js/tymconstants.js'
import { XlsxWorkbook, XlsxSheet, XlsxDownload  } from 'vue3-xlsx';
import { useI18n } from 'vue-i18n'

/* 데이터 구조
{
  index : row index
  name : 사용자 성명
  phone : 연락처
  uid : 사용자 ID
  machineNo : 기대번호
  serialNo : 시리얼 번호
  dealer : 딜러명
  state : 상태 (0-none/gray, 1-운행중/green, 2-정지/red)
  desc : 설명
  date : 발생일
}
*/
export default ({
  props : {
    kind : ref(0),
  },
  components : {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  computed: {
    isKorean() {
      const store = useTymictStore()
      return (store.getLanguage() == 'ko')
    },
    canDownloadToExcel() {
      const store = useTymictStore() 
      return store.canDownloadToExcel()
    },
    itemCount() {
      return this.itemCounts
    }, 
    itemCountEx() {
      const store = useTymictStore() 
      let count = 0
      switch(this.kind) {
        case 0:
          count = store.EmergencyData.data.length
          break
        case 1:
          count = store.BrokenData.data.length
          break
        case 2:
          count = store.ConsumablesData.data.length
          break
        case 3:
          count = store.BatteryAlertData.data.length
          break
      }
      // console.log('itemCount :', this.kind, count)
      return count
    }
  },
  watch : {
    itemCountEx : {
      handler(newVal, oldVal) {
        if(newVal != oldVal) {
          /*
          if(this.changeTimeoutID != null) {
            clearTimeout(this.changeTimeoutID)
            this.changeTimeoutID == null
          }
          let self = this
          this.changeTimeoutID = setTimeout(() => {
            self.delayedUpdate()
          }, 1)\
          */
          if(newVal > 0) {
            // console.log('MenuItem.watch.itemCount :', this.kind, oldVal, newVal)
            this.updateData()
          }
        }
      },
      immediate : true
    },
  },
  data() {},
  setup() {
    const i18n = useI18n()

    return {
      itemBorder : ref('itemBorder1'),
      curPage : ref(0),
      itemsPerPage : ref(15),
      itemIcon : ref([
        require('@/assets/dashboard/dashboard_rollover_icon.svg'),
        require('@/assets/dashboard/dashboard_error_icon.svg'),
        require('@/assets/dashboard/dashboard_consumables_icon.svg'),
        require('@/assets/dashboard/dashboard_battery_icon.svg')
      ]),
      itemTitle : ref([
        i18n.t('monitor_menu.emergency'),
        i18n.t('monitor_menu.broken'),
        i18n.t('monitor_menu.consumables'),
        i18n.t('monitor_menu.battery'), // i18n.t('manage.lowVoltage'),
      ]),
      viewItems : ref([]),
      totalItems : ref([]),
      searchKeyword : ref(''),
      searchGroupInfo : ref(null),

      sheets: ref([]),

      makingExcel : ref(true),
      excelFilename : ref(''),
      hasExcelData : ref(false),

      changeTimeoutID : ref(null),
      itemCounts : ref(0)
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.updateData()
    }, 1)
  }, 
  unmounted() {},
  methods : {
    unusedParam() {
      //
    },
    getStateColor(idx) {
      if(idx == 1)
        return 'green-6'
      return 'grey-5'
    },
    getLastItem() {
      if(((this.curPage + 1) * this.itemsPerPage) >= this.itemCount)
        return this.itemCount;
      else
        return (this.curPage + 1) * this.itemsPerPage
    },

    /// Pagination 버튼 비활성화 여부 판단 함수
    /// Param : isPrev - 이전버튼이냐(true)
    getBtnState(isPrev) {
      if(isPrev) {
        return (this.itemCount <= 0) || (this.curPage <= 0)
      } else {
        return (this.itemCount <= 0) || ((this.curPage + 1) * this.itemsPerPage > this.itemCount)
      }
    },
    updateData() {
      if(this.$refs.divCount) {
        let element = this.$refs.divCount
        switch(this.kind) {
          case 0:
            this.makeEmergency()
            element.style = 'font-size:24px;font-weight:500;color:#eb0028'
            this.itemBorder = 'itemBorder1'
            break;
          case 1:
            this.makeBroken()
            element.style = 'font-size:24px;font-weight:500;color:#c97d31'
            this.itemBorder = 'itemBorder2'
            break;
          case 2:
            this.makeConsumables()
            element.style = 'font-size:24px;font-weight:500;color:#ffc600'
            this.itemBorder = 'itemBorder3'
            break;
          case 3:
            this.makeBatteryAlert()
            element.style = 'font-size:24px;font-weight:500;color:#bbbbbb'
            this.itemBorder = 'itemBorder4'
            break;
        }
        this.itemCounts = this.totalItems.length
      }

      this.curPage = 0
      this.updatePage()
    },
    makeEmergency() {
      const store = useTymictStore()
      let i = 0
      let add = false;
      let types = [
        this.$t('constants.emergency_type1'),
        this.$t('constants.emergency_type2'),
        this.$t('constants.emergency_type3'),
        this.$t('constants.emergency_type4'),
        this.$t('constants.emergency_type5'),
      ]

      this.totalItems = []
      if(store.EmergencyData.data != null) {
        let comDst = ''
        if(!TymCommon.isEmpty(this.searchKeyword)) {
          comDst = this.searchKeyword.toUpperCase()
        }

        let tempData = []
        if(!TymCommon.isEmpty(this.searchGroupInfo)) {
          if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_d)) {
            tempData = store.EmergencyData.data.filter(x => x.MD == this.searchGroupInfo.uuid_d)
          } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_c)) {
            tempData = store.EmergencyData.data.filter(x => x.MC == this.searchGroupInfo.uuid_c)
          } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_b)) {
            tempData = store.EmergencyData.data.filter(x => x.MB == this.searchGroupInfo.uuid_b)
          } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_a)) {
            tempData = store.EmergencyData.data.filter(x => x.MA == this.searchGroupInfo.uuid_a)
          }
        } else {
          tempData = store.EmergencyData.data
        }

        // console.log( store.EmergencyData.data)
        for(i = 0; i < tempData.length; i++) {
          let grp = store.findGroupByUUID(tempData[i].MA, tempData[i].MB, tempData[i].MC, tempData[i].MD)
          add = false
          if(!TymCommon.isEmpty(comDst)) {
            if(tempData[i].UINFO[0].USER.toUpperCase().includes(comDst)) {
              add = true
            } else if(tempData[i].MN.toUpperCase().includes(comDst) || tempData[i].SN.toUpperCase().includes(comDst)) {
              add = true
            } else if(grp.toUpperCase().includes(comDst)) {
              add = true
            }
            if(!add) {
              let phone = TymCommon.parsePhoneNumber(tempData[i].UINFO[0].HP, store.connectServer)
              if(phone.includes(comDst)) {
                add = true
              }
            }
          } else {
            add = true
          }

          if(add) {
            let typeString = types[tempData[i].ETYPE]
            let count = tempData[i].ECount
            if((count > 1) && !TymCommon.isEmpty(tempData[i].DN)) {
              if(this.isKorean)
                typeString = tempData[i].DN[0][store.connectServer] + ' 외 ' + (tempData[i].ECount - 1) + ' 건'
              else
                typeString = tempData[i].DN[1][store.connectServer] + ' and ' + (tempData[i].ECount - 1) + ' more'
            }

            let state = 0
            let inLoca = store.machineLocaInfo.find(x => (x.machineNo == tempData[i].MN))
            if(inLoca) {
              if(!TymCommon.isEmpty(inLoca.PWR)) {
                state = inLoca.PWR == 'ON' ? 1 : 0
              }
            }

            // console.log(store.EmergencyData.data[i])
            // let oTime = TymCommon.convertStringToDateTime(store.EmergencyData.data[i].ATimePN)
            let oTime = TymCommon.convertStringToDateTime2(tempData[i].OTime[0])
            this.totalItems.push({
              index : i,
              name : tempData[i].UINFO[0].USER,
              phone : TymCommon.parsePhoneNumber(tempData[i].UINFO[0].HP, store.connectServer),
              uid : tempData[i].UINFO[0].UID,
              machineNo : tempData[i].MN,
              serialNo : tempData[i].SN,
              dealer : grp,
              state : state,
              desc : typeString,
              date : oTime
            })
          }
        }
      }
    },
    makeBroken() {
      const store = useTymictStore()
      let i = 0
      let add = false, inECode = false
      let comDst = ''
    
      if(!TymCommon.isEmpty(this.searchKeyword)) {
        comDst = this.searchKeyword.toUpperCase()
      }

      let tempData = []
      if(!TymCommon.isEmpty(this.searchGroupInfo)) {
        if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_d)) {
          tempData = store.BrokenData.data.filter(x => x.MD == this.searchGroupInfo.uuid_d)
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_c)) {
          tempData = store.BrokenData.data.filter(x => x.MC == this.searchGroupInfo.uuid_c)
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_b)) {
          tempData = store.BrokenData.data.filter(x => x.MB == this.searchGroupInfo.uuid_b)
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_a)) {
          tempData = store.BrokenData.data.filter(x => x.MA == this.searchGroupInfo.uuid_a)
        }
      } else {
        tempData = store.BrokenData.data
      }

      this.totalItems = []
      if(tempData != null) {
        for(i = 0; i < tempData.length; i++) {
          /*
          console.log('Brok : ',
            i + 1 ,
            tempData[i].MN,
            tempData[i].ATimePN,
            tempData[i].SN,
            tempData[i].ECount,
            tempData[i].DN,
            tempData[i].UINFO[0].USER)
          */
          let grp = store.findGroupByUUID(tempData[i].MA, tempData[i].MB, tempData[i].MC, tempData[i].MD)
          inECode = false
          add = false
          if(!TymCommon.isEmpty(comDst)) {
            tempData[i].ECODE.forEach(x => {
              if(x.indexOf(this.searchKeyword) >= 0) {
                inECode = true
                return
              }
            })
            if(!inECode) {
              if(tempData[i].UINFO[0].USER.toUpperCase().includes(comDst)) {
                add = true
              } else if(tempData[i].MN.toUpperCase().includes(comDst) || tempData[i].SN.toUpperCase().includes(comDst)) {
                add = true
              } else if(grp.toUpperCase().includes(comDst)) {
                add = true
              }
              if(!add) {
                let phone = TymCommon.parsePhoneNumber(tempData[i].UINFO[0].HP, store.connectServer)
                if(phone.includes(comDst)) {
                  add = true
                }
              }
            }
          } else {
            add = true
          }

          if(add) {
            let contentStr = ''
            if(Array.isArray(tempData[i].DN)) {
              contentStr = tempData[i].DN[0]
            } else {
              contentStr = tempData[i].DN
            }

            //let contentStr = tempData[i].DN[0]
            if(tempData[i].ECount > 1) {
              if(this.isKorean)
                contentStr = contentStr + ' 외 ' + (tempData[i].ECount - 1) + ' 건'
              else
                contentStr = contentStr + ' and ' + (tempData[i].ECount - 1) +' more'
            }

            let state = 0
            let inLoca = store.machineLocaInfo.find(x => x.machineNo == tempData[i].MN)
            if(inLoca) {
              if(!TymCommon.isEmpty(inLoca.PWR)) {
                state = inLoca.PWR == 'ON' ? 1 : 0
              }
            }

            this.totalItems.push( {
              index : i,
              name : tempData[i].UINFO[0].USER,
              phone : TymCommon.parsePhoneNumber(tempData[i].UINFO[0].HP, store.connectServer),
              uid : tempData[i].UINFO[0].UID,
              machineNo : tempData[i].MN,
              serialNo : tempData[i].SN,
              dealer : grp,
              state : state,
              desc : contentStr,
              date : TymCommon.convertStringToDateTime(tempData[i].ATime),
            })
          }
        }
      }
    },
    makeConsumables() {
      const store = useTymictStore()
      let i = 0
      let add = false;
      let contentStr = ''
      let comDst = ''
    
      if(!TymCommon.isEmpty(this.searchKeyword)) {
        comDst = this.searchKeyword.toUpperCase()
      }

      let tempData = []
      if(!TymCommon.isEmpty(this.searchGroupInfo)) {
        if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_d)) {
          tempData = store.ConsumablesData.data.filter(x => x.MD == this.searchGroupInfo.uuid_d)
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_c)) {
          tempData = store.ConsumablesData.data.filter(x => x.MC == this.searchGroupInfo.uuid_c)
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_b)) {
          tempData = store.ConsumablesData.data.filter(x => x.MB == this.searchGroupInfo.uuid_b)
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_a)) {
          tempData = store.ConsumablesData.data.filter(x => x.MA == this.searchGroupInfo.uuid_a)
        }
      } else {
        tempData = store.ConsumablesData.data
      }

      this.totalItems = []
      if(tempData != null) {
        for(i = 0; i < tempData.length; i++) {
          // console.log('------------', tempData[i])
          contentStr = ''
          let noCount = tempData[i].NOCOUNT
          if(noCount == 0)
            continue

          let grp = store.findGroupByUUID(tempData[i].MA, tempData[i].MB, tempData[i].MC, tempData[i].MD)
          add = false
          try {
            if(!TymCommon.isEmpty(comDst)) {
              if(tempData[i].UINFO[0].USER.toUpperCase().includes(comDst)) {
                add = true
              } else if(tempData[i].MN.toUpperCase().includes(comDst) /* || tempData[i].SN.toUpperCase().includes(comDst) */) {
                // 20240411 - DB에 SN이 없네
                add = true
              } else if(grp.toUpperCase().includes(comDst)) {
                add = true
              }

              if(!add) {
                let phone = TymCommon.parsePhoneNumber(tempData[i].UINFO[0].HP, store.connectServer)
                if(phone.includes(comDst)) {
                  add = true
                }
              }
            } else {
              add = true
            }
          } catch (ex) {
            console.log(ex)
            add = false
          }
          
          if(add) {
            if(Array.isArray(tempData[i].DN))
              contentStr = tempData[i].DN[0]
            else 
              contentStr = tempData[i].DN

            if(Array.isArray(contentStr))
              contentStr = contentStr[this.isKorean ? 0 : 1]

            if(noCount > 1) {
              if(this.isKorean)
                contentStr = contentStr + ' 외 ' + (noCount - 1) + ' 건 확인 필요'
              else
                contentStr = 'Check ' + contentStr + ' and ' + (noCount - 1) +' more'
            } else {
              if(this.isKorean)
                contentStr = contentStr + ' 확인 필요'
              else
                contentStr = 'Check ' + contentStr
            }

            let newSN = tempData[i].SN
            if(TymCommon.isEmpty(newSN)) {
              let inSales = store.SalesData.data.find(x => x.machineNo == tempData[i].MN)
              if(inSales) {
                newSN = inSales.serialNo
              }
            } else {
              newSN = tempData[i].SN
            }
            
            let state = 0
            let inLoca = store.machineLocaInfo.find(x => x.machineNo == tempData[i].MN)
            if(inLoca) {
              if(!TymCommon.isEmpty(inLoca.PWR)) {
                state = inLoca.PWR == 'ON' ? 1 : 0
              }
            }
            this.totalItems.push( {
              index : i,
              name :  tempData[i].UINFO[0].USER,
              phone : TymCommon.parsePhoneNumber(tempData[i].UINFO[0].HP, store.connectServer),
              uid : tempData[i].UINFO[0].UID,
              machineNo : tempData[i].MN,
              serialNo : newSN,
              dealer : grp,
              state : state,
              desc : contentStr,
              date : TymCommon.convertStringToDateTime2(tempData[i].OTime, 0),
            })
          }
        }
      }
    },
    makeBatteryAlert() {
      const store = useTymictStore()
      let i = 0
      let add = false;
      let contentStr = ''
      let comDst = ''
    
      if(!TymCommon.isEmpty(this.searchKeyword)) {
        comDst = this.searchKeyword.toUpperCase()
      }
      
      let tempData = []
      if(!TymCommon.isEmpty(this.searchGroupInfo)) {
        if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_d)) {
          tempData = store.BatteryAlertData.data.filter(x => x.MD == this.searchGroupInfo.uuid_d)
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_c)) {
          tempData = store.BatteryAlertData.data.filter(x => x.MC == this.searchGroupInfo.uuid_c)
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_b)) {
          tempData = store.BatteryAlertData.data.filter(x => x.MB == this.searchGroupInfo.uuid_b)
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_a)) {
          tempData = store.BatteryAlertData.data.filter(x => x.MA == this.searchGroupInfo.uuid_a)
        }
      } else {
        tempData = store.BatteryAlertData.data
      }

      this.totalItems = []
      if(tempData != null) {
        for(i = 0; i < tempData.length; i++) {
          contentStr = ''
          let noCount = tempData[i].NOCOUNT
          if(noCount == 0)
            continue

          let grp = store.findGroupByUUID(tempData[i].MA, tempData[i].MB, tempData[i].MC, tempData[i].MD)
          add = false
          try {
            if(!TymCommon.isEmpty(comDst)) {
              // console.log('BATTERY ', comDst, grp, tempData[i])
              if(tempData[i].UINFO[0].USER.toUpperCase().includes(comDst)) {
                add = true
              } else if(tempData[i].MN.toUpperCase().includes(comDst) || tempData[i].SN.toUpperCase().includes(comDst)) {
                add = true
              } else if(grp.toUpperCase().includes(comDst)) {
                add = true
              }
              if(!add) {
                let phone = TymCommon.parsePhoneNumber(tempData[i].UINFO[0].HP, store.connectServer)
                if(phone.includes(comDst)) {
                  add = true
                }
              }
            } else {
              add = true
            }
          } catch (ex) {
            console.log(ex)
            add = false
          }

          if(add) {
            let state = 0
            let inLoca = store.machineLocaInfo.find(x => x.machineNo == tempData[i].MN)
            if(inLoca) {
              if(!TymCommon.isEmpty(inLoca.PWR)) {
                state = inLoca.PWR == 'ON' ? 1 : 0
              }
            }

            this.totalItems.push( {
              index : i,
              name :  tempData[i].UINFO[0].USER,
              phone : TymCommon.parsePhoneNumber(tempData[i].UINFO[0].HP, store.connectServer),
              uid : tempData[i].UINFO[0].UID,
              machineNo : tempData[i].MN,
              serialNo : tempData[i].SN,
              dealer : grp,
              state : state,
              desc : contentStr,
              date : TymCommon.convertStringToDateTime2(tempData[i].OTime, 0),
            })
          }
        }
      }
    },
    searchItems(keyword) {
      this.searchKeyword = keyword
      this.updateData()
    },
    searchGroup(group, keyword) {
      this.searchGroupInfo = group
      this.searchKeyword = keyword
      this.updateData()
    },
    onClickItem(index) {
      this.unusedParam(index)
      //console.log('ManageItem.onClickItem :', this.kind, this.viewItems[index])
      this.$emit('select', this.kind, this.viewItems[index])
    },
    viewPrevious() {
      if(this.curPage > 0) {
        this.curPage--
        this.updatePage()
      }
    },
    viewNext() {
      if(((this.curPage + 1) * this.itemsPerPage) <= (this.itemCount - 1)) {
        this.curPage++
        this.updatePage()
      }
    },
    updatePage() {
      if(this.itemCount <= 0)
        return

      let i = 0
      let start = this.curPage * this.itemsPerPage

      if(this.totalItems.length > 0) {
        this.viewItems = []
        for(i = 0; i < this.itemsPerPage; i++) {
          if((start + i) >= this.itemCount)
            break;

          this.viewItems.push(this.totalItems[start + i])
        }
      }
    },

    // 부모 페이지로부터 기대의 시동이 켜진 차량의 정보를 전달받는다.
    /*
    {
        "SN": "T002230600910091",
        "MN": "PT078D01244",
        "PWR": "ON",
        "ATime": "20230516:152343.790",
        "LOCA": [
            {
                "HDOP": "1",
                "Coord": "35.987043550,127.0956438666",
                "TTime": "20230521:142404.930"
            }
        ],
        "OnTime": "20230522:143019.661",
        "OffTime": "20230516:152454.859"
    }
    */
    powerSuccess(data) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('powerSuccess :', data)
      }
      this.updatePage()
    },
    powerFailed() {
      this.updatePage()
    },
    setMachinePowerState(data) {
      this.unusedParam(data)
      const store = useTymictStore()
      store.getPowerState(this.powerSuccess, this.powerFailed)
    },

    onShowExportToExcel() {
      this.makingExcel = true;
      this.hasExcelData = true;
      let now = new Date()
      switch(this.kind) {
        case 0:
          this.excelFilename = 'Maintenance_Emergency_'
          break;
        case 1:
        this.excelFilename = 'Maintenance_Error_'
          break;
        case 2:
        this.excelFilename = 'Maintenance_Consumables_'
          break;
        case 3:
        this.excelFilename = 'Maintenance_Battery_'
          break;
      }

      this.excelFilename += now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0')
      this.excelFilename += '_' + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0') + now.getSeconds().toString().padStart(2, '0')
      this.excelFilename += '.xlsx'

      setTimeout(() => {
        try {
          this.sheets = []
          this.getHistoryExcel()
        } catch(ex) {
          console.log(ex)
        }
      }, 1)
    },
    getHistoryExcel() {
      const store = useTymictStore()
      let manage = [{
        "MA": store.idToken["custom:uuid_a"],
        "MB": '',
        "MC": '',
        "MD": '',
      }]

      if(!TymCommon.isEmpty(this.searchGroupInfo)) {
        if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_b)) {
          manage[0]["MB"] = this.searchGroupInfo.uuid_b
        }
        if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_c)) {
          manage[0]["MC"] = this.searchGroupInfo.uuid_c
        }
        if(!TymCommon.isEmpty(this.searchGroupInfo.uuid_d)) {
          manage[0]["MD"] = this.searchGroupInfo.uuid_d
        }
      } else {
        if(!TymCommon.isEmpty(store.idToken["custom:uuid_b"])) {
          manage[0]["MB"] = store.idToken["custom:uuid_b"]
        }
        if(!TymCommon.isEmpty(store.idToken["custom:uuid_c"])) {
          manage[0]["MC"] = store.idToken["custom:uuid_c"]
        }
        if(!TymCommon.isEmpty(store.idToken["custom:uuid_d"])) {
          manage[0]["MD"] = store.idToken["custom:uuid_d"]
        }
      }
      manage = JSON.stringify(manage)

      switch(this.kind) {
        case 0:
          TymAws.getGroupEmergencyData(manage, store.isICTUser(), true)
            .then(this.emergencyResponseOK)
            .catch(this.responseFail)
          break;
        case 1:
          TymAws.getGroupBrokenData(manage, store.isICTUser(), true)
            .then(this.brokenResponseOK)
            .catch(this.responseFail)
          break;
        case 2:
          TymAws.getGroupConsumablesData(manage, store.isICTUser(), true)
            .then(this.consumablesResponseOK)
            .catch(this.responseFail)
          break;
        case 3:
          TymAws.getBatteryAlert(manage, store.isICTUser())
            .then(this.batteryResponseOK)
            .catch(this.responseFail)
          break;
      }
    },
    responseFail(er) {
      console.log(er)

      this.hasExcelData = false
      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 2000)

      setTimeout(() => {
        this.makingExcel = false;
      }, 100)
    },
    saveToExcelFile() {
      const store = useTymictStore()
      let tzName = ''
      if(store.connectServer != 0) {
        if(store.timezoneIndex == 0) {
          tzName = Intl.DateTimeFormat().resolvedOptions().timeZone 
        } else {
          tzName = store.timezoneList[store.timezoneIndex].value
        }
      }
      let catName = ''
      switch(this.kind) {
        case 0:
          catName = '긴급 알림'
          break
        case 1:
          catName = '고장 알림'
          break
        case 2:
          catName = '소모품 알림'
          break
        case 3:
          catName = '배터리저전압 알림'
          break
      }

      let desc = {
        category: catName,
        filename: this.excelFilename,
      }
      if(!TymCommon.isEmpty(this.searchGroupInfo)) {
        if(!TymCommon.isEmpty(this.searchGroupInfo.group_d.trim())) {
          desc.group = this.searchGroupInfo.group_d.trim()
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.group_c.trim())) {
          desc.group = this.searchGroupInfo.group_c.trim()
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.group_b.trim())) {
          desc.group = this.searchGroupInfo.group_b.trim()
        } else if(!TymCommon.isEmpty(this.searchGroupInfo.group_a.trim())) {
          desc.group = this.searchGroupInfo.group_a.trim()
        }
      }
      if(!TymCommon.isEmpty(this.searchKeyword)) {
        desc.keyword = this.searchKeyword
      }
      if(!TymCommon.isEmpty(tzName)) {
        desc.timzone = tzName
      }

      TymAws.writeAction('관리', '다운로드' , desc)

      setTimeout(() => {
        if(this.$refs.excelPopup)
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 500)
    },
    emergencyResponseOK(data) {
      const store = useTymictStore()
      let json = null
      if(!TymCommon.isEmpty(data.body)) {
        json = JSON.parse(data.body)
      }

      if(!TymCommon.isEmpty(json) && (json.EXCEL.length > 0)) {
        let excelData = []
        let keyword = this.searchKeyword.toUpperCase().trim()

        json.EXCEL.forEach(one => {
          let modelName = TymCommon.getModelFullname(one.MON)
          let add = false
          let dealer = TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1]
          
          dealer = dealer.toUpperCase()
          if(one.MN.includes(keyword)) 
            add = true
          else if(one.MON.includes(keyword)) 
            add = true
          else if(modelName.Fullname.includes(keyword)) 
            add = true
          else if(one.User_Name.toUpperCase().includes(keyword)) 
            add = true
          else if(one.User_Phone.toUpperCase().includes(keyword)) 
            add = true
          else if(dealer.includes(keyword)) 
            add = true
          else if(one.Manage_Name[0].toUpperCase().includes(keyword)) 
            add = true

          if(!store.SalesData.data.find(x => x.machineNo == one.MN)) {
            add = false
          }

          if(add) {
            if(one.Item_Count == 1) {
              excelData.push({
                DN : one.DN_Array[0][store.connectServer],
                MN : one.MN,
                MON : one.MON,
                ModelName : modelName.Model,
                Fullname : modelName.Fullname,
                Branch : one.Manage_Name[0], 
                Dealer : TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1],
                DealerPhone : one.Manage_Phone,
                OTime : one.OTime_Array[0],
                UserName : one.User_Name,
                UserPhone : one.User_Phone,
                VUT : one.VUT
              }) 
            } else if(one.Item_Count > 1) {
              for(let idx = 0; idx < one.Item_Count; idx++) {
                excelData.push({
                  DN : one.DN_Array[idx][store.connectServer],
                  MN : one.MN,
                  MON : one.MON,
                  ModelName : modelName.Model,
                  Fullname : modelName.Fullname,
                  Branch : one.Manage_Name[0], 
                  Dealer : TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1],
                  DealerPhone : one.Manage_Phone,
                  OTime : one.OTime_Array[idx],
                  UserName : one.User_Name,
                  UserPhone : one.User_Phone,
                  VUT : one.VUT
                }) 
              }
            }
          }
        })

        // 최신이 앞으로
        excelData.sort((a, b) => {
          return b.OTime.localeCompare(a.OTime)
        })

        this.hasExcelData = (excelData.length > 0)
        if(this.hasExcelData)
          this.makeEmergencyExcelData(excelData)
      } else {
        this.hasExcelData = false
        setTimeout(() => {
          if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
        }, 2000)
      }

      setTimeout(() => {
        this.makingExcel = false;
      }, 100)
    },
    makeEmergencyExcelData(data) {
      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 2000)

      if(data.length < 1)
        return

      // 긴급알람 리스트 출력
      let xlsRow1 = []

      xlsRow1.push('No', '기종', '모델명', '기대번호',
                  '유형', /*'발생 위치',*/ '발생 일시', '발생 시 사용시간(h)',
                  '고객명', '고객 연락처',
                  '판매 지역', '판매점', '판매점 연락처')
      let rowNo = 1
      let othRows = []
      if(!TymCommon.isEmpty(this.searchKeyword)) {
        othRows.push(['검색', this.searchKeyword])
      }
      data.forEach(one => {
        let oTime = TymCommon.convertStringToDateTime2(one.OTime)

        othRows.push([rowNo.toString(), one.ModelName, one.Fullname, one.MN,
                    one.DN, /*발생위치,*/ oTime, one.VUT,
                    one.UserName, one.UserPhone,
                    one.Branch, one.Dealer, one.DealerPhone])
        rowNo++
      })
      othRows.splice(0, 0, xlsRow1)

      const store = useTymictStore()
      let tzName = ''
      if(store.connectServer != 0) {
        if(store.timezoneIndex == 0) {
          tzName = Intl.DateTimeFormat().resolvedOptions().timeZone 
        } else {
          tzName = store.timezoneList[store.timezoneIndex].value
        }
        othRows.splice(0, 0, ['TimeZone', tzName])
      }

      // console.log('makeExcel (xls list) :', this.excelFrom, this.excelTo, othRows)
      this.sheets.push(
        {
          name: '관리(긴급 알림)',
          data: othRows
        }
      )
    },
    
    brokenResponseOK(data) {
      const store = useTymictStore()
      let json = null
      if(!TymCommon.isEmpty(data.body)) {
        json = JSON.parse(data.body)
      }
      
      if(!TymCommon.isEmpty(json) && (json.EXCEL.length > 0)) {
        let excelData = []
        let keyword = this.searchKeyword.toUpperCase().trim()

        if(TymConst.IS_DEVELOPMENT) {
          console.log('Broken [EXCEL]:', json.EXCEL)
        }

        json.EXCEL.forEach(one => {
          let modelName = TymCommon.getModelFullname(one.MON)
          let add = false
          let dealer = TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1]

          dealer = dealer.toUpperCase()
          if(one.MN.includes(keyword)) 
            add = true
          else if(one.MON.includes(keyword)) 
            add = true
          else if(modelName.Fullname.includes(keyword)) 
            add = true
          else if(one.User_Name.toUpperCase().includes(keyword)) 
            add = true
          else if(one.User_Phone.toUpperCase().includes(keyword)) 
            add = true
          else if(dealer.includes(keyword)) 
            add = true
          else if(one.Manage_Name[0].toUpperCase().includes(keyword)) 
            add = true

          if(!store.SalesData.data.find(x => x.machineNo == one.MN)) {
            add = false
          }

          if(add) {
            if(one.Item_Count == 1) {
              excelData.push({
                DN : one.DN_Array[0],
                CODE : one.CODE_Array[0],
                MN : one.MN,
                MON : one.MON,
                ModelName : modelName.Model,
                Fullname : modelName.Fullname,
                Branch : one.Manage_Name[0], 
                Dealer : TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1],
                DealerPhone : one.Manage_Phone,
                OTime : one.OTime_Array[0],
                UserName : one.User_Name,
                UserPhone : one.User_Phone,
                VUT : one.VUT[0]
              }) 
            } else if(one.Item_Count > 1) {
              for(let idx = 0; idx < one.Item_Count; idx++) {
                excelData.push({
                  DN : one.DN_Array[idx],
                  CODE : one.CODE_Array[idx],
                  MN : one.MN,
                  MON : one.MON,
                  ModelName : modelName.Model,
                  Fullname : modelName.Fullname,
                  Branch : one.Manage_Name[0], 
                  Dealer : TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1],
                  DealerPhone : one.Manage_Phone,
                  OTime : one.OTime_Array[idx],
                  UserName : one.User_Name,
                  UserPhone : one.User_Phone,
                  VUT : one.VUT[idx]
                }) 
              }
            }
          } else {
           console.log('???', one) 
          }
        })

        // 최신이 앞으로
        excelData.sort((a, b) => {
          return b.OTime.localeCompare(a.OTime)
        })

        // console.log('errors :', excelData)
        this.hasExcelData = (excelData.length > 0)
        if(this.hasExcelData)
          this.makeBrokenExcelData(excelData)
      } else {
        this.hasExcelData = false
        setTimeout(() => {
          if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
        }, 2000)
      }

      setTimeout(() => {
        this.makingExcel = false;
      }, 100)
    },
    makeBrokenExcelData(data) {
      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 2000)

      if(data.length < 1)
        return

      // 고장 리스트 출력
      let xlsRow1 = []

      xlsRow1.push('No', '기종', '모델명', '기대번호',
                  '고장 코드', '고장명', '발생 일시', '발생 시 사용시간(h)',
                  '고객명', '고객 연락처',
                  '판매 지역', '판매점', '판매점 연락처')
      let rowNo = 1
      let othRows = []
      if(!TymCommon.isEmpty(this.searchKeyword)) {
        othRows.push(['검색', this.searchKeyword])
      }
      data.forEach(one => {
        let oTime = TymCommon.convertStringToDateTime2(one.OTime);
        othRows.push([rowNo.toString(), one.ModelName, one.Fullname, one.MN,
                    one.CODE, one.DN, oTime, one.VUT,
                    one.UserName, one.UserPhone,
                    one.Branch, one.Dealer, one.DealerPhone])
        rowNo++
      })
      othRows.splice(0, 0, xlsRow1)

      const store = useTymictStore()
      let tzName = ''
      if(store.connectServer != 0) {
        if(store.timezoneIndex == 0) {
          tzName = Intl.DateTimeFormat().resolvedOptions().timeZone 
        } else {
          tzName = store.timezoneList[store.timezoneIndex].value
        }
        othRows.splice(0, 0, ['TimeZone', tzName])
      }

      this.sheets.push(
        {
          name: '관리(고장)',
          data: othRows
        }
      )
    },
    
    consumablesResponseOK(data) {
      const store = useTymictStore()
      let json = null
      if(!TymCommon.isEmpty(data.body)) {
        json = JSON.parse(data.body)
      }
      if(TymConst.IS_DEVELOPMENT) {
        console.log('CONS EXCEL :', json)
      }

      if(!TymCommon.isEmpty(json) && (json.EXCEL.length > 0)) {
        let excelData = []
        let keyword = this.searchKeyword.toUpperCase().trim()

        json.EXCEL.forEach(one => {
          let modelName = TymCommon.getModelFullname(one.MON)
          let add = false
          let dealer = TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1]
          let itmCnt = 0
          let newDN = []
          if(one.MN.includes(keyword)) 
            add = true
          else if(one.MON.includes(keyword)) 
            add = true
          else if(modelName.Fullname.includes(keyword)) 
            add = true
          else if(one.User_Name.toUpperCase().includes(keyword)) 
            add = true
          else if(one.User_Phone.toUpperCase().includes(keyword)) 
            add = true
          else if(dealer.includes(keyword)) 
            add = true
          else if(one.Manage_Name[0].toUpperCase().includes(keyword)) 
            add = true

          if(!store.SalesData.data.find(x => x.machineNo == one.MN)) {
            add = false
          }

          if(!add) {
            return
          }

          dealer = dealer.toUpperCase()

          if(TymCommon.isEmpty(one.Item_Count)) {
            if(TymCommon.isEmpty(one.DN_Array)) {
              if(TymCommon.isEmpty(one.DN)) {
                itmCnt = one.DN.length
                newDN = one.DN
              } 
            } else {
              itmCnt = one.DN_Array.length
              newDN = one.DN_Array
            }
          } else {
            itmCnt = one.Item_Count
            newDN = one.DN_Array
          }

          for(let idx = 0; idx < itmCnt; idx++) {
            if(!TymCommon.isEmpty(newDN[idx]) && !TymCommon.isEmpty(newDN[idx][0])) {
              let oTime = TymCommon.convertStringToDateTime2(one.OTime);
              excelData.push({
                DN : newDN[idx], // one.DN_Array[idx],
                MN : one.MN,
                MON : one.MON,
                ModelName : modelName.Model,
                Fullname : modelName.Fullname,
                Branch : one.Manage_Name[0], 
                Dealer : TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1],
                DealerPhone : one.Manage_Phone,
                OTime : oTime,
                UserName : one.User_Name,
                UserPhone : one.User_Phone,
                VUT : one.VUT
              })
            }
          }
        })

        if(excelData.length > 0) {
          // 최신이 앞으로
          excelData.sort((a, b) => {
            return b.OTime.localeCompare(a.OTime)
          })
          this.hasExcelData = true

          this.makeConsumablesExcelData(excelData)
        }
      } else {
        this.hasExcelData = false
        setTimeout(() => {
          if(this.$refs.excelPopup)
            this.$refs.excelPopup.hide()
        }, 2000)
      }
      setTimeout(() => {
        this.makingExcel = false;
      }, 100)
    },
    makeConsumablesExcelData(data) {
      setTimeout(() => {
        if(this.$refs.excelPopup)
          this.$refs.excelPopup.hide()
      }, 2000)

      if(data.length < 1)
        return

      // 긴급알람 리스트 출력
      let xlsRow1 = []

      xlsRow1.push('No', '기종', '모델명', '기대번호',
                  '카테고리', '발생 일시', '발생 시 사용시간(h)',
                  '고객명', '고객 연락처',
                  '판매 지역', '판매점', '판매점 연락처')
      let rowNo = 1
      let othRows = []
      if(!TymCommon.isEmpty(this.searchKeyword)) {
        othRows.push(['검색', this.searchKeyword])
      }
      data.forEach(one => {
        let oTime = new Date(one.OTime)
        let catName = null
        if(Array.isArray(one.DN)) {
          catName = one.DN[this.isKorean ? 0 : 1]
        } else {
          catName = one.DN
        }

        if(TymConst.IS_DEVELOPMENT) {
          if(one.MN == 'PS130X00628') {
            console.log('PS130X00628 :', one)
          }
        }
        othRows.push([rowNo.toString(), one.ModelName, one.Fullname, one.MN,
                    catName, TymCommon.getDateTimeString(oTime), one.VUT,
                    one.UserName, one.UserPhone,
                    one.Branch, one.Dealer, one.DealerPhone])
        rowNo++
      })
      othRows.splice(0, 0, xlsRow1)
      
      const store = useTymictStore()
      let tzName = ''
      if(store.connectServer != 0) {
        if(store.timezoneIndex == 0) {
          tzName = Intl.DateTimeFormat().resolvedOptions().timeZone 
        } else {
          tzName = store.timezoneList[store.timezoneIndex].value
        }
        othRows.splice(0, 0, ['TimeZone', tzName])
      }

      this.sheets.push(
        {
          name: '관리(소모품)',
          data: othRows
        }
      )
      //console.log('makeExcel (xls list) :', data, this.sheets)
    },

    batteryResponseOK(data) {
      const store = useTymictStore()
      let json = null
      if(!TymCommon.isEmpty(data.body)) {
        json = JSON.parse(data.body)
      }

      if(!TymCommon.isEmpty(json) && json.EXCEL.length > 0) {
        let excelData = []
        let keyword = this.searchKeyword.toUpperCase().trim()

        json.EXCEL.forEach(one => {
          let modelName = TymCommon.getModelFullname(one.MON)
          let add = false
          let dealer = TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1]

          dealer = dealer.toUpperCase()
          if(one.MN.includes(keyword)) 
            add = true
          else if(one.MON.includes(keyword)) 
            add = true
          else if(modelName.Fullname.includes(keyword)) 
            add = true
          else if(one.User_Name.toUpperCase().includes(keyword)) 
            add = true
          else if(one.User_Phone.toUpperCase().includes(keyword)) 
            add = true
          else if(dealer.includes(keyword)) 
            add = true
          else if(one.Manage_Name[0].toUpperCase().includes(keyword)) 
            add = true

          if(!store.SalesData.data.find(x => x.machineNo == one.MN)) {
            add = false
          }
          if(!add) {
            return
          }

          for(let idx = 0; idx < one.Item_Count; idx++) {
            excelData.push({
              DN : one.DN_Array[idx][store.connectServer],
              MN : one.MN,
              MON : one.MON,
              ModelName : modelName.Model,
              Fullname : modelName.Fullname,
              Branch : one.Manage_Name[0], 
              Dealer : TymCommon.isEmpty(one.Manage_Name[1]) ? '' : one.Manage_Name[1],
              DealerPhone : one.Manage_Phone,
              OTime : one.OTime_Array[idx],
              UserName : one.User_Name,
              UserPhone : one.User_Phone,
              VUT : one.VUT
            }) 
          }
        })

        // 최신이 앞으로
        excelData.sort((a, b) => {
          return b.OTime.localeCompare(a.OTime)
        })

        this.hasExcelData = (excelData.length > 0)

        if(TymConst.IS_DEVELOPMENT) {
          console.log('makeExcel (batteryResponseOK) :', excelData)
        }

        if(excelData.length > 0) {
          this.makeBatteryExcelData(excelData)
        }
      } else {
        this.hasExcelData = false
        setTimeout(() => {
          if(this.$refs.excelPopup)
            this.$refs.excelPopup.hide()
        }, 2000)
      }

      setTimeout(() => {
        this.makingExcel = false;
      }, 100)
    },
    makeBatteryExcelData(data) {
      if(data.length < 1)
        return

      const store = useTymictStore()
      let xlsRow1 = []

      xlsRow1.push('No', '기종', '모델명', '기대번호',
                  '유형', '발생 일시', '발생 시 사용시간(h)',
                  '고객명', '고객 연락처',
                  '판매 지역', '판매점', '판매점 연락처')
      let rowNo = 1
      let othRows = []
      if(!TymCommon.isEmpty(this.searchKeyword)) {
        othRows.push(['검색', this.searchKeyword])
      }
      data.forEach(one => {
        let oTime = TymCommon.convertStringToDateTime2(one.OTime);
        othRows.push([rowNo.toString(), one.ModelName, one.Fullname, one.MN,
                    one.DN, oTime, one.VUT,
                    one.UserName, one.UserPhone,
                    one.Branch, one.Dealer, one.DealerPhone])
        rowNo++
      })
      othRows.splice(0, 0, xlsRow1)
      
      let tzName = ''
      if(store.connectServer != 0) {
        if(store.timezoneIndex == 0) {
          tzName = Intl.DateTimeFormat().resolvedOptions().timeZone 
        } else {
          tzName = store.timezoneList[store.timezoneIndex].value
        }
        othRows.splice(0, 0, ['TimeZone', tzName])
      }

      this.sheets.push({
        name: '관리(배터리)',
        data: othRows
      })
    },
    delayedUpdate() {
      const store = useTymictStore() 

      switch(this.kind) {
        case 0:
          this.itemCounts = store.EmergencyData.data.length
          break
        case 1:
          this.itemCounts = store.BrokenData.data.length
          break
        case 2:
          this.itemCounts = store.ConsumablesData.data.length
          break
        case 3:
          this.itemCounts = store.BatteryAlertData.data.length
          break
      }
      this.updateData()
      console.log('delayedUpdate()', this.itemCounts)
    }
  }
})
</script>
