<template>
  <div style="background-color: #f2f2f2;">
    <div class="column" style="background-color:white;border-radius:3px;padding:12px;margin-bottom: 12px;">
      <!-- 월간 사용 시간 -->
      <div class="col-auto row">
        <div class="col-auto itemTitle">{{ $t('manage.monthlyOpHours') }}</div>
        <q-icon size="sm" color="grey-6" name="info" style="margin:0 12px;">
          <q-tooltip>
            <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:14px;">
              {{ $t('manage.usageTooltipMon1')}}
            </div>
            <div :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'" style="font-size:14px;">
              {{ $t('manage.usageTooltipMon2')}}
            </div>
          </q-tooltip>
        </q-icon>
      </div>
      <div class="col">
        <q-scroll-area style="width:100%;height:76px;">
          <table style="min-width:560px;width:100%;height:70px;font-size:14px;margin-top:6px;">
            <tr height="50%" style="font-weight:500">
              <td width="13%" >
                <div class="row items-center justify-center">
                  <q-btn flat dense icon="chevron_left" :disable="disableLastYear" @click="viewPreviousYear"></q-btn>
                  <div style="margin:2px 3px 0 3px;">{{ currentYear }}</div>
                  <q-btn flat dense icon="chevron_right" @click="viewNextYear"></q-btn>
                </div>
              </td>
              <td width="7.2%" v-for="month in 12" :key="month">
                <div class="row items-center justify-center monthBorder" @click="monthClicked(month)" style="cursor:pointer;width:100%;height:100%;">
                  <div class="col">
                    {{getMonthTitle(month)}}
                  </div>
                </div>
              </td>
            </tr>

            <tr height="50%">
              <!--
              <td>{{ Math.round(yearMonthHours.reduce((a, b) => a.Minutes + b.Minutes, 0)).toFixed(2) }}&nbsp;(h)</td>
              -->
              <td>{{ getYearTotalHour() }}</td>
              <td height="50%" v-for="(item, index) in yearMonthHours" :key="index">
                <div class="row items-center justify-center monthBorder" @click="monthClicked(index + 1)" style="cursor:pointer;width:100%;height:100%;">
                  <div class="col">
                    {{ item != undefied ? (item.Desc != undefined ? item.Desc : '') : '' }}
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </q-scroll-area>
      </div>
    </div>

    <div style="position:relative;background-color:white;border-radius:3px;padding:12px;margin-bottom: 12px;">
      <!-- 주간 사용시간 -->
      <div class="itemTitle">{{ $t('manage.monthlyDetailRecord') }}</div>
      <table style="width:100%;font-size:11pt;margin-top:2pt;">
        <tr height="32px">
          <td width="130px">
            <div class="row items-center justify-center" v-show="currentMonth > 0">
              <!--
              <q-btn flat dense icon="chevron_left" :disable="currentWeek <= 0" @click="viewPreviousWeek" v-show="false"></q-btn>
              -->
              <div style="margin:2px 3px 0 3px;">{{ monthMsg }} </div>
              <!--
              <q-btn flat dense icon="chevron_right" :disable="!canNextWeek" @click="viewNextWeek" v-show="false"></q-btn>
              -->
            </div>
          </td>
          <td>00:00 ~ 06:00</td>
          <td>06:00 ~ 12:00</td>
          <td>12:00 ~ 18:00</td>
          <td>18:00 ~ 24:00</td>
        </tr>
      </table>
      <div class="column " style="min-height:240px;position: relative;">
        <q-scroll-area class="col column" style="position:absolute;width:100%;height:100%;border-color:#D3D3D3;border-style: solid;border-width: 0px 1px 1px 1px;">
          <div class="col-auto row"
               style="width:100%;height:30px;"
               :style="((idx+1) < oneMonthData.length) ? 'border-bottom:1px solid #D3D3D3' : 'border-bottom:0px solid #D3D3D3'"
               v-for="(one, idx) in oneMonthData" :key="idx">
            <div class="col-auto row items-center justify-center" style="width:130px;height:100%;border-right:1px solid #D3D3D3">
              <div>{{ getDayAndDate(idx + 1) }}</div>
            </div>
            <div class="col row" style="margin:2px 0">
              <div class="col minute" v-for="(usage, idx) in one.split('')" :key="idx"
                   :class="usageClass(usage)">
              </div>
            </div>
          </div>
        </q-scroll-area>
      </div>
      
      <!-- 주간 사용시간 로딩 중 -->
      <div class="row items-center justify-center"
           v-show="loadingMonthlyData"
           style="position:absolute;z-index:2;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
        <q-spinner-tail ref="weeklyUsage" id="weeklyUsage" dense color="red-7" size="40px"/>
      </div>
    </div>

    <div style="background-color:white;border-radius:3px;padding:12px;">
      <!-- 이동 & 작업경로 -->
      <div class="col-auto row items-center justify-between" style="margin:0 0 6px 0;">
        <div class="itemTitle col">{{ $t('manage.moveAndWork') }}</div>
        <div class="col-auto row">
          <div class="col-auto row items-end justify-center">
            <q-input class="col-auto items-center justify-center" dense readonly style="width:100%;margin:0 6px;" v-model="routeRangeTxt">
              <template v-slot:prepend>
                <q-btn dense flat icon="calendar_month" color="grey-10" :disable="loadingRouteData">
                  <q-popup-proxy cover ref="routRangePopup" @before-show="popupDateSelect('routeRange')">
                    <q-date flat bordered minimal no-unset range color="red-7" v-model="routeRangeDate" @update:model-value="afterDateSelect('routeRange')">
                    </q-date>
                  </q-popup-proxy>  
                </q-btn>
              </template>
              <template v-slot:append>
                <q-btn dense flat icon="search" :color='loadingRouteData ? "grey-7" : "red-7"' :disable="loadingMonthlyData || loadingRouteData" @click="requestRouteWithRange">
                </q-btn>
              </template>
            </q-input>
          </div>
        </div>
      </div>

      <div class="q-gutter-sm" :class='isLandscape ? "row" : "column"'>
        <div :class='isLandscape ? "col-6 row" : "col-auto row"' :style='isLandscape ? "" : "height:240px"'>
          <div class="col column">
            <div class="col column">
              <!-- 지도 -->
              <div class="col row" style="position:relative;">
                <div class="col row items-center justify-center" style="border:1px solid lightgray;border-radius:3px;hewidth:100%;height:100%;">
                  <MapView ref="routeView" elementid="routeView" class="col-auto" style="width:100%;height:100%;"></MapView>
                </div>
                <!-- 위치정보 로딩 중 -->
                <div class="row items-center justify-center"
                     v-show="loadingRouteData"
                     style="position:absolute;z-index: 999;left:0;top:0;right:0;bottom:0;border-radius:3px;background-color:#80808060">
                  <q-spinner ref="routeTracer" id="routeTracer" dense color="red-7" :thickness="2" size="60px"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col row" style="height:auto;min-height:480px;">
          <div class="col column" style="height:100%;">
            <div class="col column" style="height:100%;">
              <!-- Operating Hours -->
              <table style="border: 1px solid #aaaaaa;border-radius: 3px;">
                <tr height="32px">
                  <th class="table_left_cell" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"
                      style="font-size:16px;font-weight:500;">{{$t('manage.opHours')}}</th>
                </tr>
                <tr height="200px">
                  <td class="table_left_cell">
                    <div class="column" style="position:absolute;left:6px;top:6px;right:6px;bottom:30px;">
                      <TymGauge class="col-auto" ref="opMove" elementid="opMove" style="width:100%;height:100%;" />
                      <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"
                            style="font-size:14px;font-weight:500;">{{$t('manage.move')}}&nbsp;(h)</div>
                    </div>
                  </td>
                </tr>
                <tr height="200px">
                  <td class="table_left_cell">
                    <div class="column" style="position:absolute;left:6px;top:6px;right:6px;bottom:30px;">
                      <TymGauge class="col-auto" ref="opPto" elementid="opPto" style="width:100%;height:100%;" />
                      <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"
                            style="font-size:14px;font-weight:500;">{{$t('manage.pto')}}&nbsp;(h)</div>
                    </div>
                  </td>
                </tr>
                <tr height="200px">
                  <td class="table_left_cell">
                    <div class="column" style="position:absolute;left:6px;top:6px;right:6px;bottom:30px;">
                      <TymGauge class="col-auto" ref="opPlow" elementid="opPlow" style="width:100%;height:100%;" />
                      <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"
                            style="font-size:14px;font-weight:500;">{{$t('manage.plow')}}&nbsp;(h)</div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="col row" style="height:auto;min-height:480px;">
          <div class="col column" style="height:100%;">
            <div class="col column" style="height:100%;">
              <!-- Average Speed -->
              <table style="border: 1px solid #aaaaaa;border-radius: 3px;">
                <tr height="32px">
                  <th class="table_left_cell" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"
                      style="font-size:16px;font-weight:500;">{{$t('manage.avgSpeed')}}</th>
                </tr>
                <tr height="200px">
                  <td class="table_left_cell">
                    <div class="column" style="position:absolute;left:6px;top:6px;right:6px;bottom:30px;">
                      <TymGauge class="col-auto" ref="moveSpeed" elementid="moveSpeed" style="width:100%;height:100%;" />
                      <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"
                            style="font-size:14px;font-weight:500;">{{$t('manage.move')}}&nbsp;(km/h)</div>
                    </div>
                  </td>
                </tr>
                <tr height="200px">
                  <td class="table_left_cell">
                    <div class="column" style="position:absolute;left:6px;top:6px;right:6px;bottom:30px;">
                      <TymGauge class="col-auto" ref="ptoSpeed" elementid="ptoSpeed" style="width:100%;height:100%;" />
                      <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"
                            style="font-size:14px;font-weight:500;">{{$t('manage.pto')}}&nbsp;(km/h)</div>
                    </div>
                  </td>
                </tr>
                <tr height="200px">
                  <td class="table_left_cell">
                    <div class="column" style="position:absolute;left:6px;top:6px;right:6px;bottom:30px;">
                      <TymGauge class="col-auto" ref="plowSpeed" elementid="plowSpeed" :type='2' :value="avgDRspeed" style="width:100%;height:100%;" />
                      <div class="col" :style="isKorean ? 'font-family:Noto Sans KR;' : 'font-family:Prometo;'"
                            style="font-size:14px;font-weight:500;">{{$t('manage.plow')}}&nbsp;(km/h)</div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>


.itemTitle {
  font-size:16px;
  font-weight:500;
}

table, td {
  border : 1px solid lightgray;
  border-collapse : collapse;
  align-items: center;
  text-align: center;
};

.monthBorder {
  border:1px solid lightgray;
}
.monthBorder:hover {
  border:1px solid red;
}
.monthBorder:active {
  border:2px solid red;
}

.chart {
  font-size:14pt;
}

.table_left_cell {
  position:relative;
  border-left:1px solid #aaaaaa;
  border-right:1px solid #aaaaaa;
  border-top:1px dashed #dddddd;
  border-bottom:1px dashed #dddddd;
}
.table_right_cell {
  position:relative;
  border-left:1px solid #aaaaaa;
  border-right:1px solid #aaaaaa;
  border-top:1px dashed #dddddd;
  border-bottom:1px dashed #dddddd;
}

.minute {
    display: inline-block;
}
.blue { background-color: blue; }
.lightgray { background-color: lightgray; }
.empty { background-color: transparent; }

</style>

<script>
import { ref } from 'vue'
import { useTymictStore } from '@/store/tymict'
import { useI18n } from 'vue-i18n'
//import { GChart } from 'vue-google-charts'
import TymCommon from '@/js/tymcommon.js'
//import { useQuasar } from 'quasar'
import TymAws from '@/js/tymaws.js'
import moment from 'moment-timezone'
import MapView from '@/views/MapView.vue'
import TymGauge from '@/components/TymGauge.vue'
import TymConst from '@/js/tymconstants.js'

export default ({
  props : {
    info : Object
  },
  components: {
    MapView,
//    GChart,
    TymGauge
  },
  data() {
    return {
    }
  },
  setup() {
    return {
      weekCounts : ref(0),
      machineInfo : ref(null),
      currentYear : ref(0),
      yearTotalHours : ref(0),
      yearMonthHours : ref([{
        Desc :'',
        Minutes : 0
      }]),
      yearlyData : ref([]),  // 해당 기대의 1년치 데이터
      saleDate : ref(null), // 해당 기대 판매일

      currentMonth : ref(0),  // 1~12
      currentWeek : ref(0),
      canNextWeek : ref(false),

      loadingMonthlyData : ref(false),
      monthlyTimeline : ref([]),
      oneMonthData : ref([]),

      loadingRouteData : ref(false),
      routeRangeTxt : ref(''),
      routeRangeDate : ref({}),

      avgSpeedMoveData : ref([]),
      avgSpeedMoveOption : ref({
            legend: {       // 범례 표시 없음-Bar, Column 차트에서
              position : "none"
            },
            /*
            hAxis: {
              textPosition : 'none'
            },
            vAxis: {
              textPosition : 'none'
            },
            */
            width:'100%',     // 갯수에 맞게 변경해줘야할 듯
            height:'auto',
            colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
            bar: {
                groupWidth: '60%'   // 막대 폭
            },
            chartArea: {
                left : '5%',
                top: '5%',
                right: 5,
                bottom:'5%',
                width:'100%'
            },
      }),
    }
  },
  computed: {
    isLandscape : function() {
      const store = useTymictStore()
      return store.isLandscape
    },
    isKorean()  {
      const store = useTymictStore()
      return store.getLanguage()=='ko'
    },
    getMonthTitle() {
      return(month) => {
        const i18n=useI18n()
        return i18n.t('yearMonth.' + month)
      }
    },
    /*
    hostDataChanged() {
      console.log('hostDataChanged', this.machineInfo.serialNo, this.info.serialNo)
      return (this.machineInfo != this.info)
    }
    */
    getDayAndDate() {
      return(day) => {
        const store = useTymictStore()
        let tmp = moment([this.currentYear, this.currentMonth - 1, day, 0, 0])
        let result = day +  ' ('
        if(store.Language == 'ko') {
          // result = i18n.t('yearMonth.' + this.currentMonth) + ' ' + (this.currentWeek + 1).toString() + '주'
          // result = i18n.t('yearMonth.' + this.currentMonth)
          result += tmp.locale('ko').format("ddd")
        } else {
          // let weekNames = ['1st', '2nd', '3rd', '4th', '5th', '6th']
          //result = i18n.t('yearMonth.' + this.currentMonth) + ' ' + weekNames[this.currentWeek] + " Week"
          result += tmp.locale('en-us').format("ddd")
        }
        result += ')'
        return result
      }
    },
    getDayUsage() {
      return(day) => {
        console.log('getDayUsage :', day, day.split(''))
        return day.split('')
      }
    },
    monthMsg() {
      const store = useTymictStore()

      //const i18n = useI18n()
      let result = ''
      if(this.currentMonth > 0) {
        let tmp = moment([this.currentYear, this.currentMonth - 1, 1, 0, 0])
        if(store.Language == 'ko') {
          // result = i18n.t('yearMonth.' + this.currentMonth) + ' ' + (this.currentWeek + 1).toString() + '주'
          // result = i18n.t('yearMonth.' + this.currentMonth)
          result = tmp.locale('ko').format("MMMM")
        } else {
          // let weekNames = ['1st', '2nd', '3rd', '4th', '5th', '6th']
          //result = i18n.t('yearMonth.' + this.currentMonth) + ' ' + weekNames[this.currentWeek] + " Week"
          result = tmp.locale('en-us').format("MMMM")
        }
      }
      return result
    },
    disableLastYear() {
      if(TymCommon.isEmpty(this.saleDate))
        return false
      
      return this.saleDate.getFullYear() >= this.currentYear
    }
  },
  created() {},
  mounted() {
    if(TymCommon.isEmpty(this.info)) {
      return
    }
    this.machineInfo = this.info
    // this.machineInfo.SD ==> 2023-05-11T00:00
    this.saleDate = new Date(this.machineInfo.SD)

    this.yearMonthHours = new Array(12)
    let now = new Date()
    this.currentYear = now.getFullYear()
    let momNow = moment(now)
    let yesterday = moment(now)

    yesterday.subtract(1,'d')
    this.routeRangeDate = { 
      from : yesterday.format('YYYY/MM/DD'),
      to : momNow.format('YYYY/MM/DD')
    }
    // console.log(this.routeRangeDate)
    this.afterDateSelect('routeRange')

    this.updateData(this.info)

    this.avgSpeedMoveData = []
    this.avgSpeedMoveData.push(['지점명', '판매수', {type:'number', role:'annotation'}])
    this.avgSpeedMoveData.push(['#1', 20, 70])
    this.avgSpeedMoveData.push(["#2", 30, 90])
  }, 
  unmounted() {},
  methods : {
    unusedParam() {
      //
    },
    usageClass(val) {
      switch (val) {
        case '0': return 'lightgray';
        case '1': return 'blue';
        case '2': return 'blue';
        default: return 'empty';
      }
    },
    updateData(obj) {
      if(TymConst.IS_DEVELOPMENT) {
        console.log('Worksheet.updateData : ', obj)
      }
      this.machineInfo = obj
      setTimeout(() => {
        let now = new Date()
        this.currentYear = now.getFullYear()
        this.getYearUsingHours(this.currentYear)
      }, 10)
    },
    viewPreviousYear() {
      this.currentYear--
      setTimeout(() => {
        this.getYearUsingHours(this.currentYear)
      }, 10)
    },
    viewNextYear() {
      this.currentYear++
      setTimeout(() => {
        this.getYearUsingHours(this.currentYear)
      }, 10)
    },
    getYearTotalHour() {
      let total = 0
      this.yearMonthHours.forEach(month => {
        total += month.Minutes
      })
      let hourMin = TymCommon.manageMinutes(total)
      return hourMin.Desc
    },
    /// month : 1 ~ 12
    monthClicked(month) {
      if(this.currentMonth == month)
        return
      if(this.loadingMonthlyData || this.loadingRouteData)
        return

      this.currentMonth = month
      this.currentWeek = 0
      if(this.yearMonthHours[month - 1].Minutes <= 0) {
        console.log('no op time in this month :', month)
        // this.currentMonth = 0
        this.currentWeek = 0
        this.oneMonthData = []
        TymCommon.Toast(this.$t('warning.noOperatingInfo'))
        return
      }
      if(this.loadingMonthlyData) {
        TymCommon.Toast(this.$t('warning.readingWeeklyData'))
        return
      }

      this.monthlyTimeline = new Map()
      this.refreshMonthlyData()
    },

    /// time : 2023-05-01T13:59:43.000Z
    TimeToDate(time) {
      if(time.length < 15)
        return null;
      let year = Number(time.substring(0, 4));
      let month = Number(time.substring(5, 7));
      let day = Number(time.substring(8, 10));
      let hour = Number(time.substring(11, 13));
      let minute = Number(time.substring(14, 15));
      let second = Number(time.substring(17, 19));
      
      return new Date(year, month - 1, day, hour, minute, second);
    },

    refreshMonthlyData() {
      let start = new Date(this.currentYear, this.currentMonth - 1, 1, 0, 0, 0)
      let end = new Date(this.currentYear, this.currentMonth, 1, 0, 0, 0)
      let temp = new Date(this.currentYear, this.currentMonth, 1, 0, 0, 0)
      temp -= 60 * 60 * 24
      temp = new Date(temp)
      this.loadingMonthlyData = true
      this.oneMonthData = []
      for(let i = 0; i < temp.getDate(); i++) {
        this.oneMonthData.push('')
      }

      setTimeout(() => {
        let startDay = start.getFullYear() + "-" + (start.getMonth() + 1).toString().padStart(2, '0') + "-01 00:00:00"
        let lastDay = end.getFullYear() + "-" + (end.getMonth() + 1).toString().padStart(2, '0') + "-01 00:00:00"
        let tgtRange = TymCommon.makeDateTimeRange(this.machineInfo.SD, startDay,  lastDay, 'YYYY-MM-DD HH:mm:ss')

        if(TymCommon.isEmpty(tgtRange)) {
          // 판매일 이전 데이터는 없어
          TymCommon.Toast("판매전 데이터는 조회할 수 없습니다.")
          return
        }

        if(TymConst.IS_DEVELOPMENT) {
          console.log('refreshMonthlyData 2:', startDay, lastDay, tgtRange.sDateStr, tgtRange.eDateStr)
        }

        TymAws.getUsingInfobyMNorSN(this.machineInfo.machineNo, this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr)
          .then(data => {
            if(TymCommon.isEmpty(data.body)) {
              // 
              return
            }
            let items = data.body
            if(TymConst.IS_DEVELOPMENT) {
              console.log('ManageWorkSheet.getUsingInfobyMNorSN :', tgtRange.sDateStr, tgtRange.eDateStr, items)
            }
            for(let i = 0; i < items.length; i++) {
              let day = Number(Object.keys(items[i])[0])
              let dayValue = items[i][Object.keys(items[i])[0]]
              this.oneMonthData[day - 1] = dayValue
            }
            this.loadingMonthlyData = false
          })
          .catch(er => {
            console.log('getUsingInfobySN err :', er)
            this.loadingMonthlyData = false
          })
        }, 10)
    },
    getYearUsingHours(year) {
      this.yearMonthHours = new Array(12)
      this.yearlyData = []

      this.currentMonth = 0
      this.oneMonthData = []

      let startDay = ''

      if(this.saleDate.getYear() > year) {
        // 판매일 이전 데이터는 없어
        return
      } else if(this.saleDate.getFullYear() == year) {
        startDay = year.toString() + (this.saleDate.getMonth() + 1).toString().padStart(2, '0') + this.saleDate.getDate().toString().padStart(2, '0')
      } else {
        startDay = year.toString()+ '0101'
      }
      let sale = this.saleDate.getFullYear().toString() + (this.saleDate.getMonth() + 1).toString().padStart(2, '0') + this.saleDate.getDate().toString().padStart(2, '0')

      if(sale.localeCompare(startDay) > 0) {
        startDay = sale
      }

      setTimeout(() => {
        if(!TymCommon.isEmpty(this.machineInfo)) {
          let startDay = this.currentYear.toString() + "-01-01 00:00:00"
          let lastDay = (this.currentYear+1).toString() + "-01-01 00:00:00"
          let tgtRange = TymCommon.makeDateTimeRange(this.machineInfo.SD, startDay,  lastDay, 'YYYY-MM-DD HH:mm:ss')

          if(TymCommon.isEmpty(tgtRange)) {
            TymCommon.Toast("판매전 데이터는 조회할 수 없습니다.")
            return
          }

          TymAws.getYearlyInfobyMNorSN(this.machineInfo.machineNo, this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr)
            .then(data => {
              if(TymConst.IS_DEVELOPMENT) {
                console.log('getYearlyInfobyMNorSN :', data)
              }

              let jsonItems = data.body.UsageTime

              if(TymConst.IS_DEVELOPMENT) {
                console.log('getYearlyInfobyMNorSN :', this.machineInfo.machineNo, this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr, jsonItems)
              }

              this.yearlyData = jsonItems
              let total = 0

              for(let i = 0; i < this.yearlyData.length; i++) {
                let workTime = Number(this.yearlyData[i])
                let hourMin = TymCommon.manageMinutes(workTime)

                total += workTime

                this.yearMonthHours[i] = {
                  Desc : hourMin.Desc,
                  Minutes : Number(workTime)
                }
              }
              if(TymConst.IS_DEVELOPMENT && (total > 0)) {
                console.log('getYearUsingHours :', tgtRange.sDateStr, tgtRange.eDateStr, total, this.yearMonthHours)
              }
            })
            .catch(er => {
              console.log('err : ', er)
            })
        }
      }, 10)
    },
    viewPreviousWeek() {
      if(this.currentWeek < 0)
        return

      this.currentWeek--
      this.refreshMonthlyData()
    },
    viewNextWeek() {
      this.currentWeek++
      this.refreshMonthlyData()
    },
    popupDateSelect(option) {
      this.unusedParam(option)
      // console.log(option)
      // routeRangeTxt --> routeRangeDate
    },
    afterDateSelect(option) {
      switch(option) {
        case 'routeRange':
          // console.log('routeRange :', this.routeRangeDate, this.routeRangeDate.from, this.routeRangeDate.to)
          if(TymCommon.isEmpty(this.routeRangeDate.from) && TymCommon.isEmpty(this.routeRangeDate.to)) {
            if(!TymCommon.isEmpty(this.routeRangeDate)) {
              // 같은 날 선택
              let tempRtDate = {
                from : this.routeRangeDate,
                to : this.routeRangeDate
              }
              this.routeRangeDate = tempRtDate
            }
          } 
          
          {
            let sDay = moment(this.routeRangeDate.from, 'YYYY/MM/DD')
            let eDay = moment(this.routeRangeDate.to, 'YYYY/MM/DD')
            let start = TymCommon.getDateString(sDay)
            let end = TymCommon.getDateString(eDay)
            this.routeRangeTxt = start + ' ~ ' + end
          }
          break
      }

      // routeRangeDate --> routeRangeTxt
      this.$refs.routRangePopup.hide()
    },
    requestRouteWithRange() {
      if(TymCommon.isEmpty(this.machineInfo))
        return

      this.loadingRouteData = true;

      this.$refs.moveSpeed.updateData(0, 0)
      this.$refs.ptoSpeed.updateData(1, 0)
      this.$refs.plowSpeed.updateData(2, 0)
      this.$refs.opMove.updateData(3, 0)
      this.$refs.opPto.updateData(4, 0)
      this.$refs.opPlow.updateData(5, 0)

      let days = this.routeRangeDate.from.split('/')
      let startDay = days[0] + "-" + days[1] + "-" + days[2] + " 00:00:00"
      days = this.routeRangeDate.to.split('/')
      let endDay = days[0] + "-" + days[1] + "-" + days[2] + " 24:00:00"
      let tgtRange = TymCommon.makeDateTimeRange(this.saleDate, startDay, endDay)

      if(TymConst.IS_DEVELOPMENT) {
        console.log('requestRouteWithRange SALE :', this.saleDate)
        console.log('requestRouteWithRange TZ/OOFFSET :', TymCommon.getTimeZone(), TymCommon.getTimezoneOffset())
        console.log('requestRouteWithRange START :', startDay, tgtRange.sDateStr)
        console.log('requestRouteWithRange END :', endDay, tgtRange.eDateStr)
      }

      setTimeout(() => {
        this.$refs.routeView.updateRouteInfo(null)
        TymAws.getDailyInfobySN(this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr)
          .then(data => {
            let json = data // JSON.parse(data.body)
            if(TymConst.IS_DEVELOPMENT) {
              console.log('Manage.WorkSheet.getDailyInfobySN :', this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr, data)
            }
            if(!TymCommon.isEmpty(json)) {
              /*
              {
                "DAY": "20230514",
                "DRD": "0",
                "DRA": "0",
                "DRT": "0",
                "PTOD": "6407",
                "PTOA": "16.66",
                "PTOT": "75",
                "MT": "89",
                "DIS": "18926.27",
                "TFEA": "12.42",
                "TFEF": "37.70",
                "VSPEED": "20.59",
                "WSPEED": "5.10"
              }
              */
              let items = json // json.Items

              let mvDist = 0      // 누적 이동거리
              let mvMinutes = 0   // 누적 이동시간
              let ptoDist = 0      // 누적 작업거리
              let ptoMinutes = 0   // 누적 작업시간
              let drDist = 0      // 누적 견인거리
              let drMinutes = 0   // 누적 견인시간

              let hour = 0, minute = 0
              for(let i = 0; i < items.length; i++) {
                // 누적 이동시간
                mvMinutes += Number(items[i].MT)
                hour = Math.floor(items[i].MT / 60)
                minute = items[i].MT % 60
                // mvDist += (hour * items[i].VSPEED) + (minute / 60 * items[i].VSPEED)
                if(Number(items[i].MT) > 0) {
                  mvDist += Number(items[i].DIS)
                }

                // 누적 작업시간
                ptoMinutes += Number(items[i].PTOT)
                hour = Math.floor(items[i].PTOT / 60)
                minute = items[i].PTOT % 60
                ptoDist += (hour * items[i].WSPEED) + (minute / 60 * items[i].WSPEED)

                // 누적 작업시간
                drMinutes += Number(items[i].DRT)
                hour = Math.floor(items[i].DRT / 60)
                minute = items[i].DRT % 60
                drDist += (hour * items[i].WSPEED) + (minute / 60 * items[i].WSPEED)
              }

              if(mvMinutes > 0) {
                hour = Math.floor(mvMinutes / 60)
                minute = mvMinutes % 60
                let avgSpeed = mvDist / (hour + minute / 60)
                avgSpeed /= 1000    // meter -> kilo meter

                this.$refs.moveSpeed.updateData(0, avgSpeed)
                this.$refs.opMove.updateData(3, mvMinutes)
              }             
              if(ptoMinutes > 0) {
                hour = Math.floor(ptoMinutes / 60)
                minute = ptoMinutes % 60
                let avgSpeed = ptoDist / (hour + minute / 60)

                this.$refs.ptoSpeed.updateData(1, avgSpeed)
                this.$refs.opPto.updateData(4, ptoMinutes)
              }             
              if(drMinutes > 0) {
                hour = Math.floor(drMinutes / 60)
                minute = drMinutes % 60
                let avgSpeed = drDist / (hour + minute / 60)
                //console.log('Avg DR Speed :', drMinutes, drDist, avgSpeed)
                this.$refs.plowSpeed.updateData(2, avgSpeed)
                this.$refs.opPlow.updateData(5, drMinutes)
              }             
            }
          })
          .catch(er => {
            console.log('getDailyInfobySN err :', er)
          })

        // console.log('---- ', start, end)
        TymAws.getLocaInfobySN_Maria(this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr)
          .then(data => {
            console.log('route...')

            let json = JSON.parse(data.body)
            if(TymConst.IS_DEVELOPMENT) {
              console.log('ManageWorksheet.getLocaInfobySN 1111 :', this.machineInfo.serialNo, tgtRange.sDateStr, tgtRange.eDateStr, data, json)
            }

            if(!TymCommon.isEmpty(json)) {
              /*
              let items = []
              json.Items.forEach(item => {
                let found = items.find(x => x.Coord == item.coord)
                if(found) {
                  if(Number(found.TFID) < Number(item.TFID)) {
                    found = item
                  }
                } else {
                  items.push(item)
                }
              })
              */

              if(TymConst.IS_DEVELOPMENT) {
                // console.log('ManageWorksheet.getLocaInfobySN 2222 :', items)
              }

              if(json.Items.length > 0) {
                json.Items.sort((a, b) => {
                  return(a.TTime - b.TTime)
                })
                setTimeout(() => {
                  this.$refs.routeView.updateRouteInfo(json.Items)
                  setTimeout(() => {
                    this.loadingRouteData = false;
                  }, 50)
                }, 5)
              } else {
                setTimeout(() => {
                  this.loadingRouteData = false;
                }, 50)
              }
            } else {
              console.log('No route...')
              setTimeout(() => {
                this.loadingRouteData = false;
              }, 50)
            }
          })
          .catch(er => {
            console.log('getMonthlyUsingbySN err :', er)
            
            setTimeout(() => {
              this.loadingRouteData = false;
            }, 5)
          })
        }, 1)
    },
  }
})
</script>
