<template>
  <div class="fade-in-box" v-show="needLogin">
    <!-- 색깔 배경 -->
    <div class="wholebg">
        <div :class='isLandscape ? "rightSide" : "centerimage"'></div>
    </div>
    <div class="mainContainer">
      <div :class='isLandscape ? "leftSide row items-center" : "fullsize_input row"'>
        <div :class='isLandscape ? "col-6 row items-center justify-center" : "col row justify-center"'>
          <div class="col-6 column items-center"
                :style='isLandscape ? "width:90%;max-width:340pt;" : "width:100%;min-width:180pt;max-width:320pt;"'>
            <!-- 로고 <MainLogo ></MainLogo> -->
            <div :class='isLandscape ? "col-auto row items-center" : "col-auto row items-center justify-center"' style="margin-bottom:5%;">
              <div class="company_logo"></div>
            </div>
            
            <div class="input_ani items-center" :style='isLandscape ? "width:70%;min-width:220pt;max-width:320pt;" : "width:75%;min-width:180pt;max-width:320pt;"'>
              <q-form class="col self-center" autocomplete="off" style="margin-bottom:10pt;" @submit="formSubmit">
                <!-- ID -->
                <q-input v-model="inputID"
                        dense outlined clearable
                        color="black"
                        bg-color="white"
                        style="font-size:12pt;margin-bottom:10pt;"
                        :placeholder="$t('placeholder.inputID')"
                        tabindex='1'
                        >
                </q-input>

                <!-- 비밀번호 -->
                <q-input v-model="inputPW"
                        dense outlined clearable autocomplete="off"
                        color="black"
                        bg-color="white"
                        style="font-size:12pt;margin-bottom:10pt;"
                        :error="invalidPW"
                        :placeholder="$t('login.password')"
                        :type="!viewPassword ? 'password' : 'text'"
                        @update:model-value="keypressPW"
                        tabindex='2'
                        >
                  <template v-slot:append>
                    <q-icon :name="viewPassword ? 'visibility_off' : 'visibility'"
                            class="cursor-pointer"
                            @click="viewPassword = !viewPassword" />
                  </template>
                  <template v-slot:error>
                    <div style="font-size:11pt;">{{ $t('errorMsg.passwordLength') }}</div>
                  </template>
                </q-input>

                <!-- ID저장 옵션등 -->
                <div class="row justify-between items-center" style="margin:2pt 0 8pt 0;">
                  <!-- ID 저장-->
                  <div class="col-auto" style="height:17pt;cursor:pointer;" @click="valueSaveID=!valueSaveID" tabindex='3'>
                      <q-icon dense v-show="valueSaveID != true" name="panorama_fish_eye" size="sm" ></q-icon>
                      <q-icon dense v-show="valueSaveID == true" name="task_alt" size="sm"></q-icon>
                      <span style="margin-left:2pt;font-size:12pt;">{{$t('login.saveid')}}</span>
                  </div>
                  <div >&nbsp;</div>
                  <div class="col-auto" style="height:17pt;background:transparent;text-decoration: underline;cursor:pointer;font-size:12pt;color:#4b9ada"
                        tabindex='4'
                        @click="findIDName='';findIDValue='';showDialogPopup=true">
                      {{$t('login.forgetid')}}
                  </div>
                  <!--
                  <div style="margin-bottom:5px">
                      <span style="text-decoration: none;">{{$t('login.clearpw')}}</span>
                      <span style="margin-left:10px;background:transparent;text-decoration: underline;cursor:pointer;" @click="findDialogOption=0;findIDName=''; doResetPWpopup = !doResetPWpopup">
                          {{$t('login.doclear')}}
                      </span>
                  </div>
                  -->
                </div>

                <q-btn color="black"
                      dense no-caps
                      text-color="white"
                      type="submit"
                      style="border-radius:4px;width:100%;height:auto;font-weight:bolder;font-size:18px;"
                      :label="$t('login.title')"
                      tabindex='5'
                      @click="doSubmit" />
              </q-form>
            </div>
          </div>
        </div>
      </div>

      <LanguageManager class="changeLang" kind="login"/>
    </div>

    <q-dialog v-model="showNewPasswordDlg" persistent>
      <q-card>
        <q-card-section class="column items-center justify-start" style="margin:0;padding:0;width:auto;min-width:480px;">
          <div class="col items-center justify-between row" style="width:100%;background-color:black;">
            <div class="col" style="margin:6px 12px 6px 12px;font-size:16px;font-weight:bold;color:white">{{ $t('login.registerNewManager') }}</div>
            <div class="col-auto">
              <q-btn dense style="margin:0 6px 0 0;" @click="changeLanguage(this.$i18n.locale == 'en')">
                <q-img v-show="this.$i18n.locale == 'en'" src="~../res/login_language_e.svg" fit="fill" width="22px"/>
                <q-img v-show="this.$i18n.locale != 'en'" src="~../res/login_language_k.svg" fit="fill" width="22px"/>
              </q-btn>
            </div>
          </div>
          <div class="col items-center justify-center row" style="margin:12px 12px 0 12px;">
            <q-icon class="col-auto" name="lock" color="red-7" size="xl" text-color="white"/>
            <div class="col column" style="margin:0 0 0 12px;">
              <table>
                <tr>
                  <td>
                    <div style="margin:0 12px">{{ $t('common.email') }}</div>
                  </td>
                  <td>
                    <q-input v-model="inputNewEmail"
                            dense outlined clearable autocomplete="off"
                            color="red-7"
                            style="font-size:14px;margin:3px 0;"
                            :placeholder="$t('common.email')">
                    </q-input>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="margin:0 12px">{{ $t('common.phoneNo') }}</div>
                  </td>
                  <td>
                    <q-input v-model="inputNewPhone"
                            dense outlined clearable autocomplete="off"
                            color="red-7"
                            style="font-size:14px;margin:3px 0;"
                            :placeholder="$t('common.phoneNo')">
                    </q-input>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="margin:0 12px">{{ $t('login.password') }}</div>
                  </td>
                  <td>
                    <q-input v-model="inputNewPW1"
                            dense outlined autocomplete="off"
                            color="red-7"
                            style="font-size:14px;margin:3px 0;"
                            :placeholder="$t('newPWDialog.password1')"
                            :type="!viewNewPassword1 ? 'password' : 'text'">
                      <template v-slot:append>
                        <q-icon :name="viewNewPassword1 ? 'visibility_off' : 'visibility'"
                                class="cursor-pointer"
                                @click="viewNewPassword1 = !viewNewPassword1" />
                      </template>
                    </q-input>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="margin:0 12px">{{ $t('login.password') }}</div>
                  </td>
                  <td>
                    <q-input v-model="inputNewPW2"
                            dense outlined autocomplete="off"
                            color="red-7"
                            style="font-size:14px;margin:3px 0 0 0;"
                            :placeholder="$t('newPWDialog.password2')"
                            :type="!viewNewPassword2 ? 'password' : 'text'">
                      <template v-slot:append>
                        <q-icon :name="viewNewPassword2 ? 'visibility_off' : 'visibility'"
                                class="cursor-pointer"
                                @click="viewNewPassword2 = !viewNewPassword2" />
                      </template>
                    </q-input>
                  </td>
                </tr>
              </table>
            </div>
          </div> 
        </q-card-section>

        <q-card-actions >
          <div class="col row items-center justify-center" style="margin:0 0 12px 0">
            <q-btn dense no-caps color="red-7" text-color="white" style="width:86px;height:36px" :label="$t('common.confirm')" @click="confirmNewPassword"/>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>

  <!--
  <div class="wholebg" v-if="showDialogPopup">
    <LoginDialog v-on:closeDialog="closeDialog"></LoginDialog>
  </div>
  -->
  <q-dialog persistent :maximized="true"
            transition-show="slide-up"
            transition-hide="slide-down"
            transition-duration="400"
            style="font-family: 'Noto Sans KR', 'Prometo', sans-serif;"
            v-model="showDialogPopup">
    <q-card style="background-color:#f2f2f2;">
      <LoginDialog v-on:closeDialog="closeDialog"></LoginDialog>
    </q-card>
  </q-dialog>
</template>

<style scoped>

.wholebg {
	align-items: center;
	justify-content: center;
  position: absolute;
  left : 0;
  top : 0;
  width:100%;
  height:100%;
  background-color:#f2f2f2;
  animation: back-ani 1.5s linear forwards;
}
@keyframes back-ani {
	from {
		transform: translate(0, 40%);
	}
	to {
		transform: translate(0, 0);
	}
}
@keyframes mainc-ani {
	from {
		transform: translate(50%, 0);
	}
	to {
		transform: translate(0, 0);
	}
}

.company_logo {
  background : url("../assets/company/login_logo_b.svg");
  width:290px;
  height:111px;
  animation: logo-ani 1.5s linear forwards;
}
@keyframes logo-ani {
	from {
		transform: translate(-50%, 0);
    transform: scale(0.1, 0.1);
	}
	to {
		transform: translate(0, 0);
    transform: scale(1, 1);
	}
}

.input_ani {
  animation: zoom-in 1.5s linear forwards;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.1, 0.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.mainContainer {
  position: absolute;
  left : 0;
  top : 0;
  width:100%;
  height:100%;
  /*
  animation: mainc-ani 2s linear forwards;
  */
}
.leftSide {
  width : 70%;
  height : 100%;
  min-width: 280pt;
}
.fullsize_input {
  width : 100%;
  height : 100%;
  padding-top:28%;
}

.inputborder {
  margin:0 0 10pt 0;
}
.rightSide {
  position: absolute;
  right: 0;
  top: 50%;
  -ms-transform: translate(50%, -50%);
  transform: translateY(-50%);
  background : url("../assets/login/login_tractors_b.svg") center no-repeat;
  background-size:contain;
  resize: both;
  width:100%;
  height:100%;
  max-width:70%;
  max-height:100%;
}

.centerimage {
  position: relative;
  background : url("../assets/login/login_tractors_b.svg") center no-repeat;
  background-size: contain;
  resize: both;
  left:50%;
  -ms-transform: translate(50%,-50%);
  transform: translateX(-50%);
  top:65%;
  width:100%;
  min-width: 30%;
  min-height: 30%;
  max-width:100%;
  max-height:100%;
}

.changeLang {
  width:auto;
  position:absolute;
  right:48px;
  top:48px;
}
.fade-in-box {
  display: inline-block;
  animation: fadein 2s;
  -moz-animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -o-animation: fadein 2s;
  background-color : #f2f2f2;
}
@keyframes fadein {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}
</style>

<script>
import { ref } from 'vue'
import { Auth } from 'aws-amplify'
import { config } from 'aws-sdk'
import { Config, CognitoIdentityCredentials } from 'aws-sdk'
import { CognitoUserPool } from "amazon-cognito-identity-js";
//import { AmazonCognitoIdentity } from "amazon-cognito-identity-js";
import { useTymictStore } from '../store/tymict'
import { useQuasar } from "quasar"
import LanguageManager from "../components/LanguageManager.vue"
import TymCommon from '@/js/tymcommon.js'
import TymAws from '@/js/tymaws.js'
import TymConst from '@/js/tymconstants.js'
import LoginDialog from '@/components/LoginDialog.vue'
import { useI18n } from 'vue-i18n'
import CryptoJS from "crypto-js"

export default ({
  data() {
    return {
    }
  },
  setup() {
    return {
      needLogin : ref(false),

      inputID : ref(''),
      inputPW : ref(''),
      invalidID : ref(false),   // ID는 길이제한없음
      invalidPW : ref(false),   // 처음에 빈 상태는 에러가 아님
      viewPassword : ref(false),
      valueSaveID : ref(false),
      showDialogPopup : ref(false),
      needLogout : ref(true),

      showNewPasswordDlg : ref(false),
      viewNewPassword1 : ref(false),
      viewNewPassword2 : ref(false),

      inputNewEmail : ref(''),
      inputNewPhone : ref(''),
      inputNewPW1 : ref(''),
      inputNewPW2 : ref(''),
    }
  },
  computed: {
    isLandscape : function() {
      const $q = useQuasar()
      return (($q.screen.width * 1.3) > $q.screen.height)
    },
    invalidIDorPW()  {
      const i18n = useI18n()
      return i18n.t('loginresult.invalidUserOrPW')
    }
  },
  created() {
  },
  mounted() {
    document.body.style="background-color:#f2f2f2;overflow:hidden;"

    console.log("LoginView mounted")
    let savedID = localStorage.getItem('authenticateUser');
    if(!TymCommon.isEmpty(savedID)) {
      this.inputID = savedID
      this.valueSaveID = true;
    }

    if(TymConst.IS_DEVELOPMENT) {
      this.inputID = 'cwlee'
      this.inputPW = 'ekdrms09!@'
    }

    const store = useTymictStore()

    if(store.reloadPage.type == "RELOAD") {
      if(!TymCommon.isEmpty(store.reloadPage.INF1) && !TymCommon.isEmpty(store.reloadPage.INF2)) {
        this.inputID = store.reloadPage.INF1
        this.inputPW = store.reloadPage.INF2

        let tgtLang = 0, langOpt = 0
        switch(store.getLanguage().toLocaleLowerCase()) {
          case 'ko':
          case 'kr':
          case 'korean':
            langOpt = 1
            tgtLang = 0
            break
          case 'en':
          case 'en-us':
          case 'en-uk':
          case 'english':
            langOpt = 2
            tgtLang = 1
            break;
        }

        if(langOpt == 0) {
          // 언어 자동
          if(navigator.language.indexOf('ko') >= 0) {
            tgtLang = 0
          } else {
            tgtLang = 1
          }
        }

        if(tgtLang == 0)
          this.toKorean()
        else
          this.toEnglish()

        this.loginProcess()
        return
      }
    }

    this.needLogin = true
  }, 
  unmounted() {
    console.log("LoginView unmounted")
  },
  components:{
    LanguageManager,
    LoginDialog
  },
  methods : {
    encrypt(data, key) {
      return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()
    },
    decrypt(data, key) {
      return CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8)
    },
    toKorean() {
      this.$i18n.locale = 'ko'

      import("quasar/lang/ko-KR").then((lang) => {
        console.log('import Korean ok')
        this.$q.lang.set(lang.default)
      })
      console.log('to Korean')
    },
    toEnglish() {
      this.$i18n.locale = 'en'

      import("quasar/lang/en-US").then((lang) => {
        console.log('import English ok')
        this.$q.lang.set(lang.default)
      })
      console.log('to English')
    },
    sendEvent() {
      if(this.valueSaveID) {
        localStorage.setItem('authenticateUser', this.inputID);
      } else {
        localStorage.setItem('authenticateUser', "");
      }

      setTimeout(() => {
        // const cryptojs = inject('cryptojs')
        let encInfo = this.encrypt({
          INF0 : new Date().toISOString(),
          INF1 : this.inputID,
          INF2 : this.inputPW
        }, TymConst.TYMICT_ENCKEY)

        sessionStorage.setItem('EnterInfo', encInfo)

        /*
        let decInfo = this.decrypt(encInfo, TymConst.TYMICT_ENCKEY)
        decInfo = JSON.parse(decInfo)
        console.log('---> DEC : ', decInfo)
        */

        this.$emit('loginok')
      }, 25)
    },
    keypressPW(evt) {
      this.viewPassword = false
      if(TymCommon.isEmpty(this.inputPW)) {
        this.invalidPW = false
      } else if((this.inputPW.length > 0) && (this.inputPW.length < 8)) {
        this.invalidPW = true
      } else {
        this.invalidPW = false
      } 
      return evt;
    },
    formSubmit() {
      // console.log('formSubmit')
    },
    doSubmit() {
      // console.log('doSubmit')

      // 로그인 버튼을 눌렀으니 정상적인 순서대로 페이지 로딩...
      const store = useTymictStore()
      store.reloadPage.type = 'NAVIGATE'

      if(TymCommon.isEmpty(this.inputID) || TymCommon.isEmpty(this.inputPW)) {
        TymCommon.Toast('Empty ID or PW.', true)
        return;
      }

      this.loginProcess()
    },
    async loginProcess() {
      let self2 = this
      async function newSignIn() {
        try {
          let self = self2
          const store = useTymictStore()
          let signInResult = await Auth.signIn(self.inputID, self.inputPW)
            .then(user => {
              store.AuthUser = user
              if(TymConst.IS_DEVELOPMENT) {
                console.log('newSignIn.signIn :', self.inputID, user)
              }
              TymAws.checkPassword2(store.AuthUser, self.inputID, self.inputPW, self.checkPasswordOK, self.checkPasswordFail, self.newPasswordRequired)
            })
            .catch(err => {
              // err.code : NotAuthorizedException
              // err.message : Incorrect username or password.
              //               Access Token has been revoked
              switch(err.code) {
                case 'NotAuthorizedException':
                  if(err.message == 'Incorrect username or password.') {
                    TymCommon.Toast(self.$t('loginresult.invalidUserOrPW'), true)
                    self.inputID = self.inputPW = ''
                  } else {
                    self.revokeUser()
                  }
                  break;
                case 'UserNotFoundException':
                case 'InvalidParameterException':
                  console.log('newSignIn exception :', err.code, err.message)
                  TymCommon.Toast(err.message)
                  break;
              }
            })
            if(TymConst.IS_DEVELOPMENT) {
              console.log('signInResult :', signInResult, store.AuthUser)
            }
        } catch (er) {
          console.log('error signIn without singOut : ', er);
        }
      }

      async function signOut(self) {
        try {
          if(self.needLogout) {
            console.log('Sign out started...')
            await Auth.signOut({ global: true })
              .then(() => {
                console.log('sign out completed before login')
                newSignIn()
              })
              .catch(err => {
                console.error('Auth.signOut failed. ', err)
                newSignIn()
              })
          } else {
            console.log('Start login without logout.')
            newSignIn()
          }
        } catch (error) {
          console.log('error signing out: ', error);
          if(error == 'Error: Network error')
            console.log('error signing out: ', error);
            throw new Error('Network error')
        }
      }

      console.log('signed out : STARTED')
      await signOut(this)
        .then(()=> {
          /*
          */
        })
        .catch(err => {
          console.log('sign out #2 catched...', err)
        })
    },
    closeDialog(obj) {
      if(obj) {
        if(!TymCommon.isEmpty(obj.found_id)){
          this.inputID = obj.found_id
        } else {
          this.inputID = ''
        }
        this.inputPW = ''
      } else {
        this.inputID = ''
        this.inputPW = ''
      }
      this.showDialogPopup = false;    
    },
    checkPasswordOK(session) {
      const store = useTymictStore()

      if(TymConst.IS_DEVELOPMENT) {
        console.log('checkPasswordOK :', session, store.AuthUser)
      }

      store.setAuthUser(store.AuthUser, false) 
      this.SetUserData()
      this.sendEvent()
    },
    checkPasswordFail(code, message) {
      console.log('checkPasswordFail :', code, message)
    },
    changeLanguage(isEng) {
      console.log('changeLanguage :', isEng)
      const store = useTymictStore()

      if(isEng) {
        this.$i18n.locale = 'ko'
        store.Language = 'ko'
      } else {
        this.$i18n.locale = 'en'
        store.Language = 'en'
      }
    },
    SetUserData() {
      const store = useTymictStore()
      var data = { 
          UserPoolId : store.cognito[store.connectServer].userPoolId,
          ClientId : store.cognito[store.connectServer].clientId
      }

      // console.log('SetUserData : ', data)
      try {
        //var userPool = new AWS.AmazonCognitoIdentity.CognitoUserPool(data)
        var userPool = new CognitoUserPool(data)
        store.CurrentUser = userPool.getCurrentUser()
      } catch(err) {
        console.log('SetUserData() cognitoUserPool Faied : ', err)
      }

      if (store.CurrentUser != null){
        store.CurrentUser.getSession(function(err, session) {
          if (err) {
            console.log("getSession() failed : ", err)
            return false
          }

          store.AccessToken = session.getAccessToken().getJwtToken()
          // console.log('make credential :', store.AccessToken)
          try {
            Config.region = store.cognito[store.connectServer].region

            var poolkey = [null, null], tokenKey = [null, null]
            poolkey[store.cognito[store.connectServer].poolkey] = store.AccessToken
            tokenKey[store.cognito[store.connectServer].poolkey] = store.AuthUser.signInUserSession.idToken.jwtToken

            Config.credentials = new CognitoIdentityCredentials({
              IdentityPoolId: store.cognito[store.connectServer].identitypool, // your identity pool id here
              Logins : poolkey
            })
            config.credentials = new CognitoIdentityCredentials({
              IdentityPoolId: store.cognito[store.connectServer].identitypool, // your identity pool id here
              Logins: tokenKey
            })
            
            config.region = store.cognito[store.connectServer].region
          } catch(err) {
            console.log('make credential error : ', err)
          }
        });
      }
      return true;
    },
    revokeUser() {
      console.log('User is revoked...')
      
      let self = this
      Auth.signIn(this.inputID, this.inputPW)
        .then(user => {
          const store = useTymictStore()

          if(TymConst.IS_DEVELOPMENT) {
            console.log('revokeUser :', user)
          }
          store.setAuthUser(user, false)
          self.SetUserData()
          self.sendEvent()
        })
        .catch(err => {
          console.log('revoke.signIn failed : ', err.code, err.message)
          // NotAuthorizedException User is disabled
          let msg = TymCommon.translateKeyword(err.message)
          TymCommon.Toast(msg, true)
        })
    },
    checkValidPassword(password) {
      const regEx = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$")
      
      if(!TymCommon.isEmpty(password)) {
        if(password.length >= 8) {
          return regEx.test(password)
        }
      }
      return false
    },
    // 새로운 암호를 넣어야 하는 경우다.
    newPasswordRequired(userAttr, reqAttr) {
      this.viewNewPassword1 = false
      this.viewNewPassword2 = false
      this.inputNewEmail = userAttr['email']
      this.inputNewPhone = userAttr['custom:uphone']
      if(TymConst.IS_DEVELOPMENT) {
        this.inputNewPW1 = 'ekdrms09!@'
        this.inputNewPW2 = 'ekdrms09!@'
      } else {
        this.inputNewPW1 = ''
        this.inputNewPW2 = ''
      }
      this.showNewPasswordDlg = true
      if(TymConst.IS_DEVELOPMENT) {
        console.log('newPasswordRequired :', userAttr, reqAttr)
      }
    },
    confirmNewPassword() {
      if(!this.checkValidPassword(this.inputNewPW1)) {
        this.inputNewPW1 = ''
        console.log("new Password 1 is not valid")
        return
      }

      if(!this.checkValidPassword(this.inputNewPW1)) {
        this.inputNewPW1 = ''
        console.log("new Password 1 is not valid")
        return
      }
      if(!this.checkValidPassword(this.inputNewPW2)) {
        this.inputNewPW2 = ''
        console.log("new Password 2 is not valid")
        return
      }
      const store = useTymictStore()
      let self = this
      console.log('confirmNewPassword ===> ', store.AuthUser)

      TymAws.registerNewUser(store.AuthUser, this.inputID, this.inputNewPW1, this.inputNewPhone, this.inputNewEmail, this.inputNewPW1)
        .then(data => {
          if(TymConst.IS_DEVELOPMENT) {
            console.log('LoginView.registerNewUser :', data)
          }
          if(!TymCommon.isEmpty(data.Payload)) {
            let payload = JSON.parse(data.Payload)
            if(payload.RETURN) {
              self.showNewPasswordDlg = false
              store.setAuthUser(store.AuthUser, false)
              self.SetUserData()
              self.sendEvent()
            }
          }
        })
        .catch(er => {
          console.log('LoginView.registerNewUser Failed :', er)
        })
    },
  }
})
</script>

