<template>
  <div class="row">
    <table class="col" style="width: 100%">
      <tr height="48px">
        <td colspan="2">
          <div
            class="row justify-between items-center"
            style="width: 100%; height: 100%"
          >
            <div
              class="col-auto"
              style="font-size: 16px; font-weight: 600; color: #212529"
            >
              {{ $t("account.setting") }}
            </div>
            <q-btn
              class="col-auto"
              no-caps
              flat
              dense
              push
              color="grey-9"
              icon="edit"
              style="width: auto; margin: 0 3px"
              :disable="isEditMode"
              @click="editUser"
            >
            </q-btn>
          </div>
        </td>
      </tr>
      <tr class="tr_top">
        <td style="width: 150px; font-size: 15px; font-weight: 500">
          {{ $t("account.startpage") }}
        </td>
        <td width="auto" class="row">
          <q-select
            :class="isLandscape ? 'col-11' : 'col'"
            color="red-7"
            dense
            options-dense
            flat
            outlined
            :disable="!isEditMode"
            v-model="startPage"
            :options="startPages"
          >
          </q-select>
        </td>
      </tr>
      <tr class="tr_middle">
        <td style="width: 120px; font-size: 15px; font-weight: 500">
          {{ $t("common.language") }}
        </td>
        <td width="auto" class="row">
          <q-select
            :class="isLandscape ? 'col-11' : 'col'"
            color="red-7"
            dense
            options-dense
            flat
            outlined
            :disable="!isEditMode"
            v-model="curLanguage"
            :options="languages"
          >
          </q-select>
        </td>
      </tr>
      <tr class="tr_middle">
        <td style="width: 120px; font-size: 15px; font-weight: 500">
          {{ $t("account.timezone") }}
        </td>
        <td width="auto" class="row">
          <q-select
            :class="isLandscape ? 'col-11' : 'col'"
            color="red-7"
            dense
            options-dense
            flat
            outlined
            :disable="!isEditMode || isKorea"
            v-model="curTimezone"
            :options="getTimezones"
          >
          </q-select>
        </td>
      </tr>
      <tr class="tr_bottom" v-show="isEditMode">
        <td colspan="2">
          <div class="col row justify-center items-center">
            <q-btn
              no-caps
              unelevated
              dense
              color="grey-4"
              text-color="black"
              :label="$t('common.cancel')"
              style="width: 76px; height: 32px"
              @click="cancelEdit"
            >
              <q-tooltip style="font-size: 14px">{{
                $t("tooltip.cancelJob")
              }}</q-tooltip>
            </q-btn>
            <q-btn
              no-caps
              unelevated
              dense
              color="red-7"
              text-color="white"
              :label="$t('common.save')"
              style="margin-left: 8px; width: 76px; height: 32px"
              @click="saveEdit"
            >
              <q-tooltip style="font-size: 14px">{{
                $t("tooltip.saveJob")
              }}</q-tooltip>
            </q-btn>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

.tr_top {
  border-top: 0px solid #cccccc;
  border-bottom: 0px solid #eeeeee;
  height: 48px;
}
.tr_middle {
  border-bottom: 0px solid #eeeeee;
  height: 48px;
}
.tr_bottom {
  /*
  border-bottom: 2px solid #cccccc;
  */
  border-top: 0px solid #cccccc;
  height: 64px;
}
</style>

<script>
import { ref } from "vue";
import { useTymictStore } from "@/store/tymict";
import { useI18n } from "vue-i18n";
//import { useQuasar } from 'quasar'
//import TymCommon from '@/js/tymcommon.js'
// import moment from 'moment'
// import momentTimezone from 'moment-timezone'
import TymConst from "@/js/tymconstants.js";

export default {
  props: {
    kind: ref(0),
  },
  data() {
    return {};
  },
  setup() {
    return {
      isEditMode: ref(false),
      startPage: ref("Dashboard"),

      curLanguage: ref("Korean"),
      languagesKR: ref(["기본", "한국어", "영어"]),
      languagesEN: ref(["Default", "Korean", "English"]),
      timezones: ref([]),
      curTimezone: ref([]),
    };
  },
  computed: {
    isLandscape: function () {
      const store = useTymictStore();
      return store.isLandscape;
    },
    startPages() {
      const i18n = useI18n();
      return [
        i18n.t("leftbar.dashboard"),
        i18n.t("leftbar.sales"),
        i18n.t("leftbar.management"),
        i18n.t("leftbar.statistics"),
        i18n.t("leftbar.notice"),
      ];
    },
    getTimezones() {
      return this.timezones;
    },
    isKorean() {
      const store = useTymictStore();
      return store.getLanguage() == "ko";
    },
    isKorea() {
      const store = useTymictStore();
      return store.connectServer == 0;
    },
    languages() {
      if (this.isKorean) return this.languagesKR;
      else return this.languagesEN;
    },
  },
  created() {},
  mounted() {
    this.isEditMode = false;

    this.loadPageData();
  },
  unmounted() {},
  methods: {
    editUser() {
      this.isEditMode = true;
    },
    cancelEdit() {
      this.isEditMode = false;
    },
    loadPageData() {
      setTimeout(() => {
        const store = useTymictStore();

        let start = 0;
        if (store.startPage >= 0 && store.startPage < 5)
          start = store.startPage;
        this.startPage = this.startPages[start];

        // const resOp = Intl.DateTimeFormat().resolvedOptions()
        // console.log('Setting #1:', resOp.timeZone)

        // const mtz  = momentTimezone()
        // const varMoment = moment().tz(resOp.timeZone)
        // console.log('Setting #2:', varMoment)
        //console.log('Setting #3:', mtz)
        // var zone_name =  varMoment.zoneName();
        //

        switch (store.Language) {
          case "ko":
          case "kr":
          case "korean":
            this.curLanguage = this.languagesKR[1];
            break;
          case "en":
          case "en-us":
          case "en-uk":
          case "english":
            this.curLanguage = this.languagesEN[2];
            break;
          default:
            if (this.isKorean) this.curLanguage = this.languagesKR[0];
            else this.curLanguage = this.languagesEN[0];
            break;
        }

        if (TymConst.IS_DEVELOPMENT) {
          console.log("==> ", store.timezoneList);
        }

        let language = this.isKorean ? 0 : 1;
        this.timezones = [];
        for (let i = 0; i < store.timezoneList.length; i++) {
          this.timezones.push(
            store.timezoneList[i].display_name[language] +
              " (" +
              store.timezoneList[i].gmt +
              ")"
          );
        }

        // Timezone
        let tzIndex = 0;
        if (
          store.timezoneIndex >= 0 &&
          store.timezoneIndex < store.timezoneList.length
        ) {
          tzIndex = store.timezoneIndex;
        }
        this.curTimezone = this.timezones[tzIndex];

        // console.log("AccountSetting.loadPageData :", store.startPage, store.getLanguage(), store.timezoneIndex);
      }, 1);
    },
    saveEdit() {
      const store = useTymictStore();

      const pages = this.startPages;
      store.startPage = pages.indexOf(this.startPage);

      let oldLang = store.Language;
      const curLang = this.languages.indexOf(this.curLanguage);
      switch (curLang) {
        case 1: // korean
          store.Language = "ko";
          break;
        case 2:
          store.Language = "en";
          break;
        default:
          store.Language = "";
          break;
      }

      store.timezoneIndex = this.timezones.indexOf(this.curTimezone);
      localStorage.setItem("startup", store.startPage);
      localStorage.setItem("language", store.getLanguage());
      localStorage.setItem("timezone", store.timezoneIndex);
      /*
      localStorage.setItem('startup', null)
      localStorage.setItem('language', null)
      localStorage.setItem('timezone', null)
      */

      // console.log("saveEdit :", store.startPage, oldLang, store.getLanguage(), store.timezoneIndex);
      if (store.Language != oldLang) {
        switch (store.getLanguage()) {
          case "ko":
          case "kr":
            this.toKorean();
            break;
          case "en":
            this.toEnglish();
            break;
        }
      }
      this.isEditMode = false;
    },
    toKorean() {
      this.loadPageData();
      this.$i18n.locale = "ko";

      import("quasar/lang/ko-KR").then((lang) => {
        console.log("import Korean ok");
        this.$q.lang.set(lang.default);
      });

      console.log("to Korean");
    },
    toEnglish() {
      this.loadPageData();
      this.$i18n.locale = "en";

      import("quasar/lang/en-US").then((lang) => {
        console.log("import English ok");
        this.$q.lang.set(lang.default);
      });

      console.log("to English");
    },
  },
};
</script>
